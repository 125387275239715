<template>
  <div>
    <el-breadcrumb>
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>系统设置</el-breadcrumb-item>
      <el-breadcrumb-item>设备管理</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <el-row>
        <el-col>
          <el-button type="primary" v-on:click="addDialogeVisible = true">添加</el-button>
        </el-col>
      </el-row>
      <el-table :data="machineList" border stripe>
        <el-table-column type="index" label="#"></el-table-column>
        <el-table-column prop="company_name" label="所属企业"> </el-table-column>
        <el-table-column prop="station_name" label="所属网点"> </el-table-column>
        <el-table-column prop="terminal_no" label="枪号"> </el-table-column>
        <el-table-column prop="tank_no" label="罐号"> </el-table-column>
        <el-table-column prop="pump_qty" label="升累计"> </el-table-column>
        <el-table-column label="操作" width="180">
          <template slot-scope="scope">
            <el-button type="primary" icon="el-icon-edit" size="mini" v-on:click="showEditDialoge(scope.row.id)"></el-button>
            <el-button type="danger" icon="el-icon-delete" size="mini" v-on:click="deleteRow(scope.row.id)"></el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <!-- 添加对话框 -->
    <el-dialog title="添加品类" :visible.sync="addDialogeVisible" width="50%" v-on:closed="addDialogeClosed">
      <el-form :model="addForm" :rules="formRules" ref="addFormRef" label-width="100px">
        <el-form-item label="枪号" prop="terminal_no">
          <el-input v-model="addForm.terminal_no"></el-input>
        </el-form-item>
        <el-form-item label="罐号" prop="tank_no">
          <el-input v-model="addForm.tank_no"></el-input>
        </el-form-item>
        <el-form-item label="升累计" prop="pump_qty">
          <el-input v-model.number="addForm.pump_qty"></el-input>
        </el-form-item>
        <el-form-item label="品牌" prop="brand">
          <el-input v-model="addForm.brand"></el-input>
        </el-form-item>
        <el-form-item label="型号" prop="serial">
          <el-input v-model="addForm.serial"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogeVisible = false">取 消</el-button>
        <el-button type="primary" @click="addRow">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 修改对话框 -->
    <el-dialog title="修改品类" :visible.sync="editDialogeVisible" width="50%" v-on:closed="editDialogeClosed">
      <el-form :model="editForm" :rules="formRules" ref="editFormRef" label-width="100px">
        <el-form-item label="枪号" prop="terminal_no">
          <el-input v-model="editForm.terminal_no" disabled></el-input>
        </el-form-item>
        <el-form-item label="罐号" prop="tank_no">
          <el-input v-model="editForm.tank_no"></el-input>
        </el-form-item>
        <el-form-item label="升累计" prop="pump_qty">
          <el-input v-model.number="editForm.pump_qty"></el-input>
        </el-form-item>
        <el-form-item label="品牌" prop="brand">
          <el-input v-model="editForm.brand"></el-input>
        </el-form-item>
        <el-form-item label="型号" prop="serial">
          <el-input v-model="editForm.serial"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogeClosed">取 消</el-button>
        <el-button type="primary" @click="editSave">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      machineList: [],
      addDialogeVisible: false,
      addForm: {
        terminal_no: '',
        tank_no: '',
        pump_qty: 0,
        brand: '',
        serial: ''
      },
      formRules: {
        terminal_no: [{ required: true, message: '请输入枪号', trigger: 'blur' }],
        tank_no: [{ required: true, message: '请输入罐号', trigger: 'blur' }],
        pump_qty: [
          { required: true, message: '请输入升累计', trigger: 'blur' },
          { type: 'number', message: '必须为数字', trigger: 'blur' }
        ],
        brand: [{ required: true, message: '请输入品牌', trigger: 'blur' }],
        serial: [{ required: true, message: '请输入型号', trigger: 'blur' }]
      },
      editDialogeVisible: false,
      editForm: {}
    }
  },
  created() {
    this.getTerminalList()
  },
  methods: {
    getTerminalList: async function() {
      const { data: res } = await this.$http.get('/Terminal/List')
      if (res.status !== 1) {
        return this.$message.error('获取设备列表失败')
      }
      console.log(res.data)
      this.machineList = res.data
    },
    addDialogeClosed: function() {
      this.$refs.addFormRef.resetFields()
    },
    addRow: function() {
      this.$refs.addFormRef.validate(async valid => {
        if (!valid) return
        const { data: res } = await this.$http.post('/Terminal/Add', this.addForm)
        if (res.status !== 1) return this.$message.error('添加失败，' + res.msg)
        this.$message.success('添加成功')
        this.addDialogeVisible = false
        this.getTerminalList()
      })
    },
    showEditDialoge: async function(id) {
      const { data: res } = await this.$http.get(`/Terminal/One?id=${id}`)
      if (res.status !== 1) return this.$message.error('获取设备信息失败')
      this.editForm = res.data
      this.editDialogeVisible = true
    },
    editDialogeClosed: function() {
      this.$refs.editFormRef.resetFields()
      this.editDialogeVisible = false
    },
    editSave: function() {
      this.$refs.editFormRef.validate(async valid => {
        if (!valid) return
        const { data: res } = await this.$http.put('/Terminal/Edit?id=' + this.editForm.id, { tank_no: this.editForm.tank_no, pump_qty: this.editForm.pump_qty, brand: this.editForm.brand, serial: this.editForm.serial })
        if (res.status !== 1) {
          return this.$message.error('更新失败')
        }
        this.editDialogeVisible = false
        this.getTerminalList()
        this.$message.success('更新成功')
      })
    },
    deleteRow: async function(id) {
      const confirmResult = await this.$confirm('此操作将永久删除该设备, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(err => err)
      if (confirmResult !== 'confirm') {
        return this.$message('已取消删除')
      }
      const { data: res } = await this.$http.delete('/Terminal/Del?id=' + id)
      if (res.status !== 1) {
        return this.$message.error('删除失败')
      }
      this.getTerminalList()
      this.$message.success('删除成功')
    }
  }
}
</script>
