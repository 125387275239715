<template>
  <div>
    <el-breadcrumb>
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>运营数据</el-breadcrumb-item>
      <el-breadcrumb-item>异常记录</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <el-form :inline="true" :model="queryParam">
        <el-form-item label="网点">
          <el-select v-model="queryParam.station_id" placeholder="请选择网点" clearable v-on:clear="getErrorList" size="small">
            <el-option v-for="item in stationList" :key="item.id" :label="item.name" :value="item.id"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="异常时间">
          <el-date-picker v-model="queryParam.daterange" type="daterange" align="center" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptions" value-format="yyyy-MM-dd" size="small"> </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-search" @click="getErrorList" size="small">搜索</el-button>
        </el-form-item>
      </el-form>
      <el-table :data="errorList" border stripe>
        <el-table-column type="index" label="#"></el-table-column>
        <el-table-column prop="station_name" label="网点"> </el-table-column>
        <el-table-column prop="error_name" label="异常类型"></el-table-column>
        <el-table-column label="异常时间">
          <template slot-scope="scope">
            {{ scope.row.add_time | dateformat }}
          </template>
        </el-table-column>
      </el-table>
      <el-pagination :hide-on-single-page="true" @size-change="handleSizeChange" @current-change="handleCurrentChange" v-bind:current-page="queryParam.pagenum" v-bind:page-sizes="this.pageOption" v-bind:page-size="queryParam.pagesize" layout="total, sizes, prev, pager, next, jumper" v-bind:total="total"> </el-pagination>
    </el-card>
  </div>
</template>
<script>
export default {
  props: ['day'],
  data() {
    return {
      queryParam: {
        station_id: '',
        daterange: [],
        pagenum: 1,
        pagesize: this.pageOption[0]
      },
      total: 0,
      errorList: [],
      stationList: [],
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            }
          }
        ],
        disabledDate: time => {
          return time > new Date()
        }
      }
    }
  },
  created() {
    if (this.day) {
      this.queryParam.daterange.push(this.day)
      this.queryParam.daterange.push(this.day)
    }
    this.getStationList()
    this.getErrorList()
  },
  methods: {
    getErrorList: async function() {
      const { data: res } = await this.$http.get('/Error/List', { params: this.queryParam })
      if (res.status !== 1) {
        return this.$message.error('获取异常列表失败')
      }
      console.log(res.data)
      this.errorList = res.data.list
      this.total = res.data.total
    },
    handleSizeChange: function(newSize) {
      this.queryParam.pagesize = newSize
      this.getErrorList()
    },
    handleCurrentChange: function(newPage) {
      this.queryParam.pagenum = newPage
      this.getErrorList()
    },
    getStationList: async function() {
      const { data: res } = await this.$http.get('/PartnerPerson/Station')
      if (res.status !== 1) return this.$message.error('获取网点信息失败')
      this.stationList = res.data
    }
  }
}
</script>
