<template>
  <div>
    <el-breadcrumb>
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>运营数据</el-breadcrumb-item>
      <el-breadcrumb-item>加注记录</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <el-form :inline="true" :model="queryParam">
        <el-form-item label="网点">
          <el-select v-model="queryParam.station_id" placeholder="请选择网点" clearable v-on:clear="getTradeList" size="mini">
            <el-option v-for="item in stationList" :key="item.id" :label="item.name" :value="item.id"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="加注时间">
          <el-date-picker v-model="queryParam.daterange" type="daterange" align="center" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptions" value-format="yyyy-MM-dd" size="mini"> </el-date-picker>
        </el-form-item>
        <el-form-item label="用户">
          <el-input placeholder="请输入用户名" v-model="queryParam.user_name" clearable v-on:clear="getTradeList" size="mini" />
        </el-form-item>
        <el-form-item label="订单状态">
          <el-radio-group v-model="queryParam.order_status" size="mini">
            <el-radio-button :label="3">已完成</el-radio-button>
            <el-radio-button :label="5">已找零</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item style="margin-left: 20px">
          <el-button type="primary" icon="el-icon-search" @click="getTradeList" size="mini">搜索</el-button>
          <el-button icon="el-icon-refresh-right" @click="reset" size="mini">重置</el-button>
          <el-button icon="el-icon-download" @click="exportExcel" size="mini">导出</el-button>
        </el-form-item>
      </el-form>
      <el-table :data="tradeList" border stripe id="aaa" height="630">
        <!-- <el-table-column type="index" label="#"></el-table-column> -->
        <el-table-column prop="station_name" label="网点" width="130"> </el-table-column>
        <el-table-column label="定量类型">
          <template slot-scope="scope">
            <el-tag size="mini" v-if="scope.row.auth_type === 1" type="primary">定金额</el-tag>
            <el-tag size="mini" v-else type="warning">定升数</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="auth_value" label="定量值" width="60"> </el-table-column>
        <el-table-column label="加注时间" width="150">
          <template slot-scope="scope">
            {{ scope.row.add_time | dateformat }}
          </template>
        </el-table-column>
        <el-table-column prop="qty" label="加注数量"> </el-table-column>
        <el-table-column prop="money_sale" label="订单金额"> </el-table-column>
        <el-table-column prop="money" label="加注金额"> </el-table-column>
        <el-table-column prop="price" label="单价"> </el-table-column>
        <el-table-column prop="coupon_use_money" label="优惠金额"> </el-table-column>
        <el-table-column prop="user_identity" label="用户" width="90"> </el-table-column>
        <el-table-column label="支付方式">
          <template slot-scope="scope">
            <el-tag size="mini" v-if="scope.row.pay_way === '1'" type="primary">微信</el-tag>
            <el-tag size="mini" v-else-if="scope.row.pay_way === '0'" type="info">电子钱包</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="order_status" label="订单状态">
          <template slot-scope="scope">
            <el-tag size="mini" v-if="scope.row.order_status === 3" type="primary">已完成</el-tag>
            <el-tag size="mini" v-else-if="scope.row.order_status === 5" type="primary">已找零</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="m1_card_no" label="卡号" width="160"> </el-table-column>
      </el-table>
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" v-bind:current-page="queryParam.pagenum" v-bind:page-sizes="this.pageOption" v-bind:page-size="queryParam.pagesize" layout="total, sizes, prev, pager, next, jumper" v-bind:total="total"> </el-pagination>
    </el-card>
  </div>
</template>
<script>
import htmlToExcel from '@/utils/htmlToExcel'
export default {
  props: ['day'],
  data() {
    return {
      queryParam: {
        station_id: '',
        user_name: '',
        order_status: '',
        daterange: [],
        pagenum: 1,
        pagesize: this.pageOption[0]
      },
      total: 0,
      tradeList: [],
      stationList: [],
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            }
          }
        ],
        disabledDate: time => {
          return time > new Date()
        }
      }
    }
  },
  created() {
    if (this.day) {
      this.queryParam.daterange.push(this.day)
      this.queryParam.daterange.push(this.day)
    }
    this.getStationList()
    this.getTradeList()
  },
  methods: {
    getTradeList: async function() {
      const { data: res } = await this.$http.get('/Trade/List', { params: this.queryParam })
      if (res.status !== 1) {
        return this.$message.error('获取加注列表失败')
      }
      console.log(res.data)
      this.tradeList = res.data.list
      this.total = res.data.total
    },
    handleSizeChange: function(newSize) {
      this.queryParam.pagesize = newSize
      this.getTradeList()
    },
    handleCurrentChange: function(newPage) {
      this.queryParam.pagenum = newPage
      this.getTradeList()
    },
    getStationList: async function() {
      const { data: res } = await this.$http.get('/PartnerPerson/Station')
      if (res.status !== 1) return this.$message.error('获取网点信息失败')
      this.stationList = res.data
    },
    reset: function() {
      this.queryParam.station_id = ''
      this.queryParam.user_name = ''
      this.queryParam.order_status = ''
      this.queryParam.daterange = []
    },
    exportExcel: function() {
      htmlToExcel.getExcel('#aaa', '加注明细')
    }
  }
}
</script>
