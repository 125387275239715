<template>
  <div>
    <el-breadcrumb>
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>运营数据</el-breadcrumb-item>
      <el-breadcrumb-item>资金对账</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="title_section">
      <transition name="el-zoom-in-top">
        <el-card v-show="showPartnerFlag">
          <div slot="header" class="clearfix">
            <span class="title"><i class="iconfont iconhehuoren"></i>{{ partner.partner_name }} </span>
            <span class="remark"
              ><ul>
                <li>合伙网点：{{ partner.station_name }}</li>
                <li v-if="partner.share_type === 1">分账类型：按比例分账，每笔销售收入分账比例{{ partner.share_value }}%</li>
                <li v-else>分账类型：按固定金额分账，每笔销售收入分账金额为{{ partner.share_value }}元</li>
              </ul></span
            >
          </div>
          <div class="content">
            <div class="box">
              <div class="item">
                <span class="left">账户余额：</span>
                <span class="right"
                  ><ul>
                    <li>
                      <span class="bold">{{ partner.balance }}</span
                      >元
                    </li>
                    <li>
                      <span class="link"><el-button type="text" size="mini" v-on:click="handleCash">提现</el-button></span>
                    </li>
                  </ul></span
                >
              </div>
              <div class="item">
                <span class="left">不可用余额：</span>
                <span class="right"
                  ><ul>
                    <li>
                      <span class="bold">{{ partner.locked_balance }}</span
                      >元
                    </li>
                    <li>
                      <span class="link"><el-button type="text" size="mini" v-on:click="handleLockedBalance">查看</el-button></span>
                    </li>
                  </ul></span
                >
              </div>
            </div>
            <div class="box">
              <div class="item">
                <span class="left">今日入账：</span>
                <span class="right"
                  ><ul>
                    <li>
                      <span class="bold">{{ partner.today_transaction_num }}</span
                      >笔
                    </li>
                    <li>
                      <span class="bold">{{ partner.today_transaction_money }}</span
                      >元
                    </li>
                  </ul></span
                >
              </div>
              <div class="item">
                <span class="left">今日提现：</span>
                <span class="right"
                  ><ul>
                    <li>
                      <span class="bold">{{ partner.today_cash_num }}</span
                      >笔
                    </li>
                    <li>
                      <span class="bold">{{ partner.today_cash_money }}</span
                      >元
                    </li>
                  </ul></span
                >
              </div>
            </div>
            <div class="box">
              <div class="item">
                <span class="left">累计入账：</span>
                <span class="right"
                  ><ul>
                    <li>
                      <span class="bold">{{ partner.transaction_num }}</span
                      >笔
                    </li>
                    <li>
                      <span class="bold">{{ partner.transaction_money }}</span
                      >元
                    </li>
                  </ul></span
                >
              </div>
              <div class="item">
                <span class="left">累计提现：</span>
                <span class="right"
                  ><ul>
                    <li>
                      <span class="bold">{{ partner.cash_num }}</span
                      >笔
                    </li>
                    <li>
                      <span class="bold">{{ partner.cash_money }}</span
                      >元
                    </li>
                  </ul></span
                >
              </div>
            </div>
            <div class="box" id="main" style="width: 100%;height: 100%;"></div>
          </div>
        </el-card>
      </transition>
    </div>
    <div class="table_section">
      <div class="left">
        <div class="partner_table">
          <el-divider>合伙人列表</el-divider>
          <el-form :model="queryParamPartner" size="mini" label-width="60px" :inline="true">
            <el-form-item label="网点">
              <el-select v-model="queryParamPartner.station_id" placeholder="请选择网点" clearable v-on:clear="getPartnerList">
                <el-option v-for="item in stationList" :key="item.id" :label="item.name" :value="item.id"> </el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button icon="el-icon-search" @click="getPartnerList" plain>搜索</el-button>
            </el-form-item>
          </el-form>
          <el-table :data="partnerList" border stripe highlight-current-row ref="partnerTable" v-on:row-click="handlePartnerRowClick">
            <el-table-column type="index"></el-table-column>
            <el-table-column prop="station_name" label="网点"> </el-table-column>
            <el-table-column prop="name" label="合伙人"> </el-table-column>
          </el-table>
          <el-pagination background :hide-on-single-page="true" @current-change="handleCurrentChangePartner" v-bind:current-page="queryParamPartner.pagenum" v-bind:page-size="queryParamPartner.pagesize" layout="prev, pager, next" v-bind:total="totalPartner"> </el-pagination>
        </div>
      </div>
      <div class="right">
        <div class="cash_table">
          <el-divider>提现记录</el-divider>
          <el-form :model="queryParamCash" size="mini" label-width="60px" :inline="true">
            <el-form-item label="时间">
              <el-date-picker v-model="queryParamCash.daterange" type="daterange" align="center" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptions" value-format="yyyy-MM-dd"> </el-date-picker>
            </el-form-item>
            <el-form-item>
              <el-button icon="el-icon-search" @click="getCashList" plain>搜索</el-button>
              <el-button icon="el-icon-refresh-right" @click="reset">重置</el-button>
            </el-form-item>
          </el-form>
          <el-table :data="cashList" stripe highlight-current-row ref="cashTable" show-summary v-on:row-click="handleRowClick">
            <el-table-column type="index"></el-table-column>
            <el-table-column prop="name" label="合伙人"> </el-table-column>
            <el-table-column prop="add_time" label="时间" width="150">
              <template slot-scope="scope">
                {{ scope.row.add_time | dateformat }}
              </template>
            </el-table-column>
            <el-table-column prop="amount" label="提现金额（元）" align="center">
              <template slot-scope="scope">
                {{ scope.row.amount }}&nbsp;<el-button size="mini" v-if="scope.row.result === 0" type="text">待审核</el-button>
                <el-button size="mini" v-else-if="scope.row.result === 1" type="text">审核通过</el-button>
                <el-button size="mini" v-else type="text">审核不通过</el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination background :hide-on-single-page="true" @current-change="handleCurrentChange" v-bind:current-page="queryParamCash.pagenum" v-bind:page-size="queryParamCash.pagesize" layout="prev, pager, next" v-bind:total="total"> </el-pagination>
        </div>
        <div class="transaction_table">
          <el-divider>入账记录</el-divider>
          <el-table :data="transactionList" stripe>
            <el-table-column type="index"></el-table-column>
            <el-table-column prop="name" label="合伙人"> </el-table-column>
            <el-table-column prop="add_time" label="时间" width="150">
              <template slot-scope="scope">
                {{ scope.row.add_time | dateformat }}
              </template>
            </el-table-column>
            <el-table-column prop="last_balance" label="入账前余额（元）" align="center"> </el-table-column>
            <el-table-column prop="money" label="入账金额（元）" align="center"> </el-table-column>
            <el-table-column prop="balance" label="入账后余额（元）" align="center"> </el-table-column>
          </el-table>
          <el-pagination background :hide-on-single-page="true" @current-change="handleCurrentChangeTransaction" v-bind:current-page="queryParamTransaction.pagenum" v-bind:page-size="queryParamTransaction.pagesize" layout="prev, pager, next" v-bind:total="totalTransaction"> </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      queryParamCash: {
        station_id: '',
        partner_id: '',
        daterange: [],
        status: '',
        pagenum: 1,
        pagesize: 10
      },
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            }
          }
        ],
        disabledDate: time => {
          return time > new Date()
        }
      },
      stationList: [],
      partnerList: [],
      cashList: [],
      total: 0,
      queryParamTransaction: {
        cash_log_id: '',
        pagenum: 1,
        pagesize: 10
      },
      transactionList: [],
      totalTransaction: 0,
      row: null,
      showPartnerFlag: false,
      partner: {
        partner_id: '',
        partner_name: '',
        station_name: '',
        share_type: '',
        share_value: '',
        balance: '',
        locked_balance: '',
        today_transaction_num: '',
        today_transaction_money: '',
        today_cash_num: '',
        today_cash_money: '',
        transaction_num: '',
        transaction_money: '',
        cash_num: '',
        cash_money: '',
        today_sale_money: ''
      },
      queryParamPartner: {
        station_id: '',
        pagenum: 1,
        pagesize: 10
      },
      totalPartner: 0,
      row_partner: null,
      echart: null
    }
  },
  created() {
    // var beginDate = this.formatDate(new Date().getTime() - 3600 * 1000 * 24 * 30)
    // var endDate = this.formatDate(new Date().getTime())
    // this.queryParam.daterange.push(beginDate)
    // this.queryParam.daterange.push(endDate)
    this.getStationList()
    this.getPartnerList()
  },
  mounted() {
    // this.initChart()
  },
  methods: {
    getCashList: async function() {
      const { data: res } = await this.$http.get('/Cash/List', { params: this.queryParamCash })
      if (res.status !== 1) return this.$message.error('获取提现列表失败')
      this.cashList = res.data.list
      this.total = res.data.total
      this.row = null
      this.transactionList = []
    },
    formatDate: function(val) {
      const dt = new Date(val)
      const y = dt.getFullYear().toString()
      const m = (dt.getMonth() + 1).toString().padStart(2, '0')
      const d = dt
        .getDate()
        .toString()
        .padStart(2, '0')
      return `${y}-${m}-${d}`
    },
    handleCurrentChange: function(newPage) {
      this.queryParamCash.pagenum = newPage
      this.getCashList()
    },
    getStationList: async function() {
      const { data: res } = await this.$http.get('/PartnerPerson/Station')
      if (res.status !== 1) return this.$message.error('获取网点信息失败')
      this.stationList = res.data
    },
    getPartnerList: async function() {
      const { data: res } = await this.$http.get('/PartnerPerson/List?queryJson=' + this.queryParamPartner.station_id)
      if (res.status !== 1) return this.$message.error('获取合伙人信息失败')
      this.partnerList = res.data
      this.cashList = []
      this.transactionList = []
      this.row_partner = null
      this.showPartnerFlag = false
      this.row = null
    },
    reset: function() {
      this.queryParamCash.daterange = []
    },
    handleCurrentChangeTransaction: function(newPage) {
      this.queryParamTransaction.pagenum = newPage
      this.getTransactionList()
    },
    getTransactionList: async function() {
      const { data: res } = await this.$http.get('/Transaction/List', { params: this.queryParamTransaction })
      if (res.status !== 1) {
        return this.$message.error('获取分账列表失败')
      }
      console.log(res.data)
      this.transactionList = res.data.list
      this.total1 = res.data.total
    },
    handleRowClick: function(row, column, event) {
      if (this.row) {
        this.row = null
        this.$refs.cashTable.setCurrentRow()
        this.transactionList = []
      } else {
        this.row = row
        this.queryParamTransaction.cash_log_id = row.id
        this.getTransactionList()
      }
    },
    getPartnerBill: async function(id) {
      const { data: res } = await this.$http.get('/Partner/Bill?partner_id=' + id)
      if (res.status !== 1) return this.$message.error('获取合伙人信息失败')
      this.partner = res.data
      this.chart()
    },
    handleCash: async function() {
      // if (parseInt(this.partner.balance) <= 500) return this.$message.error('当前账户余额不足500元，不能提现')
      const confirmResult = await this.$alert('您确定要将该合伙人账户所有余额提现吗', '申请提现', {
        confirmButtonText: '确定'
      }).catch(err => err)
      console.log(confirmResult)
      if (confirmResult !== 'confirm') {
        return this.$message('操作已取消')
      }
      const { data: res } = await this.$http.post('/Cash/Add?partner_id=' + this.partner.partner_id)
      if (res.status !== 1) return this.$message.error('申请提现失败')
      this.$message.success('申请提现已提交')
      this.getCashList()
      this.getPartnerBill(this.partner.partner_id)
    },
    handleLockedBalance: function() {
      this.reset()
      this.queryParamCash.status = 0
      this.getCashList()
    },
    handlePartnerRowClick: function(row, column, event) {
      if (this.row_partner) {
        this.row_partner = null
        this.$refs.partnerTable.setCurrentRow()
        this.cashList = []
        this.transactionList = []
        this.showPartnerFlag = false
        this.queryParamCash.station_id = ''
        this.queryParamCash.partner_id = ''
      } else {
        this.row_partner = row
        this.queryParamCash.station_id = row.station_id
        this.queryParamCash.partner_id = row.id
        this.getCashList()
        this.showPartnerFlag = true
        this.getPartnerBill(row.id)
      }
    },
    handleCurrentChangePartner: function(newPage) {
      this.queryParamPartner.pagenum = newPage
      this.getPartnerList()
      this.row_partner = null
      this.showPartnerFlag = false
      this.cashList = []
      this.transactionList = []
    },
    chart: function() {
      if (!this.echart) {
        var echarts = require('echarts/lib/echarts')
        // 引入柱状图
        require('echarts/lib/chart/pie')
        // 引入组件
        require('echarts/lib/component/tooltip')
        // require('echarts/lib/component/grid')
        require('echarts/lib/component/legend')
        var chartDom = document.getElementById('main')
        var myChart = echarts.init(chartDom)
        this.echart = myChart
      }
      var option

      option = {
        tooltip: {
          trigger: 'item'
        },
        legend: {
          top: 0,
          left: 'center'
        },
        series: [
          {
            name: '收益占比',
            type: 'pie',
            radius: ['40%', '70%'],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: false,
                fontSize: '20',
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data: [
              { value: this.partner.today_transaction_money, name: '今日入账（元）' },
              { value: this.partner.today_sale_money, name: '今日网点销售（元）' }
            ]
          }
        ]
      }

      option && this.echart.setOption(option)
    }
  }
}
</script>
<style lang="less" scoped>
.title_section {
  .clearfix {
    display: flex;
    .title {
      display: flex;
      align-items: center;
      .iconfont {
        font-size: 1.5rem;
        margin-right: 10px;
        color: gray;
      }
    }
    .remark {
      ul {
        list-style: none;
        font-size: 0.8rem;
      }
    }
  }
  /deep/.el-card__header {
    padding: 6px 20px;
  }
  .content {
    display: flex;
    .box {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .item {
        color: #666;
        display: flex;
        justify-content: center;
        align-items: center;
        .right {
          ul {
            list-style: none;
            .bold {
              font-size: 1.2rem;
              font-weight: 600;
              padding-right: 10px;
            }
            .link {
              display: flex;
              justify-content: flex-end;
            }
          }
        }
      }
      .item:nth-child(1) {
        border-bottom: 1px solid #eee;
      }
    }
    .box:nth-child(1),
    .box:nth-child(2),
    .box:nth-child(3) {
      border-right: 1px solid #eee;
    }
    .box:nth-child(4) {
      flex: 1.2;
      height: 200px !important;
    }
  }
}
.table_section {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  margin-top: 5px;
  background-color: #fff;
  padding: 18px 20px;
  border-radius: 5px;
  overflow: auto;
  .left {
    flex: 2;
    margin-right: 15px;
    border-right: 1px solid #eee;
    padding-right: 15px;
  }
  .right {
    flex: 3;
    .transaction_table {
      margin-top: 15px;
      padding-top: 15px;
    }
  }
}
</style>
