<template>
  <div>
    <el-breadcrumb>
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>运营设置</el-breadcrumb-item>
      <el-breadcrumb-item>充值优惠</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <el-row :gutter="20">
        <el-col :span="8">
          <el-input placeholder="请输入内容" v-model="queryParam.queryJson" clearable v-on:clear="getStrategyList" size="small">
            <el-button slot="append" icon="el-icon-search" v-on:click="getStrategyList" size="small"></el-button>
          </el-input>
        </el-col>
        <el-col :span="6">
          <el-button type="primary" v-on:click="addDialogeVisible = true" v-has="'chargestrategy:add'" size="small">添加</el-button>
        </el-col>
      </el-row>
      <el-table :data="strategyList" border stripe @expand-change="handleTableExpand" ref="tableRef" row-key="id" :expand-row-keys="expand_row_keys">
        <el-table-column type="expand">
          <template slot-scope="scope" v-has="'chargestrategy:condition'">
            <el-button type="warning" icon="el-icon-plus" circle v-on:click="handleAddStrategyItem(scope.row.id)" v-has="'chargestrategy:condition'"></el-button>
            <el-table :data="strategyItemList" border>
              <el-table-column label="充值条件">
                <template slot-scope="scope">满{{ scope.row.amount }}元<br />优惠券价值：{{ scope.row.coupon_amount }}元</template>
              </el-table-column>
              <el-table-column label="赠送优惠券">
                <template slot-scope="scope">
                  <!-- <span v-for="item in scope.row.couponList" v-bind:key="item.id">满{{ item.use_limit }}减{{ item.amount }}元 x{{ item.num }}</span> -->
                  <!-- <div class="coupon_item">
                    <div class="title">
                      满<span>50</span>减<span>5</span>元
                    </div>
                    <div class="content">
                      <span v-if="flag">有效期：20年1月1日 12:00 - 20年1月2日 12:00</span>
                      <span v-else>有效期：自领取后15天内有效</span>
                      <div>数量：3张</div>
                    </div>
                    <div class="foot"><span>删除</span></div>
                  </div> -->
                  <div class="box">
                    <div class="grid1" v-for="item in scope.row.couponList" v-bind:key="item.id">
                      <div class="left">
                        <span
                          ><span class="title">{{ item.amount }}</span
                          ><span>元</span></span
                        >
                        <span class="sub_title">满{{ item.use_limit }}元可用</span>
                      </div>
                      <div class="right">
                        <span v-if="item.valid_type === 0"
                          >有效期：<br />{{ item.valid_value.split('|')[0] }} <br />
                          {{ item.valid_value.split('|')[1] }}</span
                        >
                        <span v-else>有效期：自领取后{{ item.valid_value }}天</span>
                        <div class="num">x {{ item.num }}</div>
                        <div class="icon" v-on:click="removeCoupon(item.id, scope.row.id)" v-has="'chargestrategy:couponremove'"><i class="el-icon-circle-close"></i></div>
                      </div>
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="操作">
                <template slot-scope="scope">
                  <el-button type="warning" icon="el-icon-plus" size="mini" v-on:click="handleStrategyItemCoupon(scope.row)" v-has="'chargestrategy:couponadd'">添加优惠券</el-button>
                  <el-button type="primary" icon="el-icon-edit" size="mini" v-on:click="ShowEditStrategyItemDialoge(scope.row.id)" v-has="'chargestrategy:couponedit'">修改</el-button>
                  <el-button type="danger" icon="el-icon-delete" size="mini" v-on:click="delStrategyItemRow(scope.row.id)" v-has="'chargestrategy:couponremove'">删除</el-button>
                </template>
              </el-table-column>
            </el-table>
          </template>
        </el-table-column>
        <el-table-column type="index" label="#"></el-table-column>
        <el-table-column prop="begin_time" label="开始时间">
          <template slot-scope="scope">
            {{ scope.row.begin_time | dateformat }}
          </template>
        </el-table-column>
        <el-table-column prop="end_time" label="结束时间">
          <template slot-scope="scope">
            {{ scope.row.end_time | dateformat }}
          </template>
        </el-table-column>
        <!-- <el-table-column prop="member_level" label="会员级别"> </el-table-column> -->
        <el-table-column prop="status" label="活动状态">
          <template slot-scope="scope">
            <span v-if="scope.row.status === 0" style="color: #9e9e9e">未开始</span>
            <span v-if="scope.row.status === 1" style="color: #4caf50">进行中</span>
            <span v-if="scope.row.status === 2" style="color: #ff9800">已停止</span>
            <span v-if="scope.row.status === 3" style="color: #2196f3">已结束</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="260">
          <template slot-scope="scope">
            <el-button type="primary" icon="el-icon-edit" size="mini" v-on:click="showEditDialoge(scope.row.id)" v-has="'chargestrategy:edit'"></el-button>
            <el-button type="danger" icon="el-icon-delete" size="mini" v-on:click="deleteRow(scope.row.id)" v-has="'chargestrategy:remove'"></el-button>
            <el-tooltip effect="dark" content="停止" placement="top" v-bind:enterable="false" v-if="scope.row.status === 1" v-has="'chargestrategy:state'">
              <el-button type="info" icon="el-icon-video-pause" size="mini" v-on:click="stop(scope.row)"></el-button>
            </el-tooltip>
            <el-tooltip effect="dark" content="开始" placement="top" v-bind:enterable="false" v-if="scope.row.status === 2" v-has="'chargestrategy:state'">
              <el-button type="success" icon="el-icon-video-play" size="mini" v-on:click="start(scope.row)"></el-button>
            </el-tooltip>
            <el-tooltip effect="dark" content="设置优惠" placement="top" v-bind:enterable="false" v-has="'chargestrategy:couponadd'">
              <el-button type="warning" icon="el-icon-setting" size="mini" v-on:click="handleStrategyItem(scope.row)"></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination :hide-on-single-page="true" @size-change="handleSizeChange" @current-change="handleCurrentChange" v-bind:current-page="queryParam.pagenum" v-bind:page-sizes="[5, 10, 20, 30]" v-bind:page-size="queryParam.pagesize" layout="total, sizes, prev, pager, next, jumper" v-bind:total="total"> </el-pagination>
    </el-card>
    <!-- 添加对话框 -->
    <el-dialog title="添加活动" :visible.sync="addDialogeVisible" width="50%" v-on:closed="addDialogeClosed">
      <el-form :model="addForm" :rules="addFormRules" ref="addFormRef" label-width="100px" label-position="left">
        <el-form-item label="活动时间" prop="date_range">
          <el-date-picker v-model="addForm.date_range" type="datetimerange" start-placeholder="开始日期" end-placeholder="结束日期" :default-time="['12:00:00', '12:00:00']" :picker-options="pickerOptions" value-format="yyyy-MM-dd HH:mm:ss" style="width:100%;"> </el-date-picker>
        </el-form-item>
        <!-- <el-form-item label="结束时间" prop="end_time">
          <el-input v-model="addForm.end_time"></el-input>
        </el-form-item> -->
        <!-- <el-form-item label="会员级别" prop="member_level">
          <el-input v-model="addForm.member_level"></el-input>
        </el-form-item> -->
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogeVisible = false">取 消</el-button>
        <el-button type="primary" @click="addRow">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 修改对话框 -->
    <el-dialog title="修改活动" :visible.sync="editDialogeVisible" width="50%" v-on:closed="editDialogeClosed">
      <el-form :model="editForm" :rules="editFormRules" ref="editFormRef" label-width="100px" label-position="left">
        <el-form-item label="活动时间" prop="date_range">
          <el-date-picker v-model="editForm.date_range" type="datetimerange" start-placeholder="开始日期" end-placeholder="结束日期" :default-time="['12:00:00', '12:00:00']" value-format="yyyy-MM-dd HH:mm:ss" style="width:100%;"> </el-date-picker>
        </el-form-item>
        <!-- <el-form-item label="结束时间" prop="end_time">
          <el-input v-model="editForm.mobile"></el-input>
        </el-form-item> -->
        <!-- <el-form-item label="会员级别" prop="member_level">
          <el-input v-model="editForm.master"></el-input>
        </el-form-item> -->
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogeClosed">取 消</el-button>
        <el-button type="primary" @click="editSave">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 添加充值条件对话框 -->
    <el-dialog title="添加充值金额" :visible.sync="addStrategyItemDialogeVisible" width="50%" v-on:closed="addStrategyItemDialogeClosed">
      <el-form :model="addStrategyItemForm" :rules="addStrategyItemFormRules" ref="addStrategyItemFormRef" label-width="100px" label-position="left">
        <el-form-item label="充值金额" prop="amount">
          <el-input v-model="addStrategyItemForm.amount"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addStrategyItemDialogeVisible = false">取 消</el-button>
        <el-button type="primary" @click="addStrategyItemRow">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 修改充值条件对话框 -->
    <el-dialog title="修改充值金额" :visible.sync="editStrategyItemDialogeVisible" width="50%" v-on:closed="editStrategyItemDialogeClosed">
      <el-form :model="editStrategyItemForm" :rules="editStrategyItemFormRules" ref="editStrategyItemFormRef" label-width="100px" label-position="left">
        <el-form-item label="充值金额" prop="amount">
          <el-input v-model="editStrategyItemForm.amount"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editStrategyItemDialogeVisible = false">取 消</el-button>
        <el-button type="primary" @click="editStrategyItemRow">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 添加优惠券对话框 -->
    <el-dialog title="添加优惠券" :visible.sync="addStrategyItemCouponDialogeVisible" width="50%" v-on:closed="addStrategyItemCouponDialogeClosed">
      <el-form :model="addStrategyItemCouponForm" :rules="addStrategyItemCouponFormRules" ref="addStrategyItemCouponFormRef" label-width="100px" label-position="left">
        <el-form-item label="优惠券面值" prop="amount">
          <el-input v-model="addStrategyItemCouponForm.amount"></el-input>
        </el-form-item>
        <el-form-item label="数量(张)" prop="num">
          <el-input v-model="addStrategyItemCouponForm.num"></el-input>
        </el-form-item>
        <el-form-item label="满多少可用" prop="use_limit">
          <el-input v-model="addStrategyItemCouponForm.use_limit"></el-input>
        </el-form-item>
        <el-form-item label="有效期" prop="valid_type">
          <el-radio-group v-model="addStrategyItemCouponForm.valid_type" size="medium" @change="handleValidTypeChange">
            <el-radio border label="0">固定有效期</el-radio>
            <el-radio border label="1">领取后X天内有效</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="有效天" prop="valid_day" v-if="addStrategyItemCouponForm.valid_type === '1'">
          <el-input-number v-model="addStrategyItemCouponForm.valid_day" controls-position="right" :min="1" :max="60"></el-input-number>
        </el-form-item>
        <el-form-item label="时间" prop="date_range" v-else>
          <el-date-picker v-model="addStrategyItemCouponForm.date_range" type="datetimerange" start-placeholder="开始日期" end-placeholder="结束日期" :default-time="['12:00:00', '12:00:00']" value-format="yyyy-MM-dd HH:mm:ss"> </el-date-picker>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addStrategyItemCouponDialogeVisible = false">取 消</el-button>
        <el-button type="primary" @click="addStrategyItemCouponRow">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    const checkNumber = (rule, value, callback) => {
      const pattern = /((^[1-9]\d*)|^0)(\.\d{0,2}){0,1}$/
      if (pattern.test(value)) {
        return callback()
      }
      callback(new Error('请输入正确的金额'))
    }
    var checkInteger = (rule, value, callback) => {
      const pattern = /^[1-9]\d*$/
      if (pattern.test(value)) {
        return callback()
      }
      callback(new Error('请输入正确的数量'))
    }
    return {
      queryParam: {
        queryJson: '',
        pagenum: 1,
        pagesize: 5
      },
      strategyList: [],
      total: 0,
      addDialogeVisible: false,
      addForm: {
        date_range: []
      },
      addFormRules: {
        date_range: [{ required: true, message: '请选择日期', trigger: 'blur' }]
      },
      editDialogeVisible: false,
      editForm: {},
      editFormRules: {
        date_range: [{ required: true, message: '请选择日期', trigger: 'blur' }]
      },
      pickerOptions: {
        disabledDate: time => {
          const dt = new Date(new Date() - 24 * 60 * 60 * 1000)
          return time < dt
        }
      },
      strategyItemList: [],
      addStrategyItemDialogeVisible: false,
      addStrategyItemForm: {
        amount: '',
        strategy_id: ''
      },
      addStrategyItemFormRules: {
        amount: [
          { required: true, message: '请输入充值金额', trigger: 'blur' },
          { validator: checkNumber, trigger: 'blur' }
        ]
      },
      editStrategyItemDialogeVisible: false,
      editStrategyItemForm: {},
      editStrategyItemFormRules: {
        amount: [
          { required: true, message: '请输入充值金额', trigger: 'blur' },
          { validator: checkNumber, trigger: 'blur' }
        ]
      },
      addStrategyItemCouponDialogeVisible: false,
      addStrategyItemCouponForm: {
        amount: '',
        num: '',
        use_limit: '',
        valid_type: '0',
        valid_day: '',
        date_range: [],
        valid_value: '',
        strategy_item_id: ''
      },
      addStrategyItemCouponFormRules: {
        amount: [
          { required: true, message: '请输入金额', trigger: 'blur' },
          { validator: checkNumber, trigger: 'blur' }
        ],
        num: [
          { required: true, message: '请输入数量', trigger: 'blur' },
          { validator: checkInteger, trigger: 'blur' }
        ],
        use_limit: [{ required: true, message: '请输入金额', trigger: 'blur' }],
        date_range: [{ required: true, message: '请选择日期', trigger: 'blur' }],
        valid_type: [{ required: true, message: '请选择有效期类型', trigger: 'change' }],
        valid_day: [{ required: true, message: '请输入天数', trigger: 'blur' }]
      },
      flag: true,
      // strategy_id: '',
      expand_row_keys: []
    }
  },
  created() {
    this.getStrategyList()
  },
  methods: {
    getStrategyList: async function() {
      const { data: res } = await this.$http.get('/Charge/StrategyList', { params: this.queryParam })
      if (res.status !== 1) {
        return this.$message.error('获取列表失败')
      }
      console.log(res.data)
      this.strategyList = res.data.list
      this.total = res.data.total
      this.strategyList.forEach(v => (v.expand = false))
    },
    handleSizeChange: function(newSize) {
      this.queryParam.pagesize = newSize
      this.getCouponTemplateList()
    },
    handleCurrentChange: function(newPage) {
      this.queryParam.pagenum = newPage
      this.getCouponTemplateList()
    },
    addRow: function() {
      this.$refs.addFormRef.validate(async valid => {
        if (!valid) return
        const { data: res } = await this.$http.post('/Charge/AddStrategy', this.addForm)
        if (res.status !== 1) return this.$message.error('添加失败,' + res.msg)
        this.$message.success('添加成功')
        this.addDialogeVisible = false
        this.getStrategyList()
      })
    },
    addDialogeClosed: function() {
      this.$refs.addFormRef.resetFields()
    },
    showEditDialoge: async function(id) {
      const { data: res } = await this.$http.get(`/Charge/Strategy?id=${id}`)
      if (res.status !== 1) return this.$message.error('获取信息失败')
      this.editForm = res.data
      console.log(this.editForm)
      this.editForm.date_range = []
      this.editForm.date_range.push(this.editForm.begin_time.replace('T', ' '))
      this.editForm.date_range.push(this.editForm.end_time.replace('T', ' '))
      this.editDialogeVisible = true
    },
    editSave: function() {
      this.$refs.editFormRef.validate(async valid => {
        if (!valid) return
        const { data: res } = await this.$http.put('/Charge/EditStrategy?id=' + this.editForm.id, {
          date_range: this.editForm.data_range
        })
        if (res.status !== 1) {
          return this.$message.error('更新失败')
        }
        this.editDialogeVisible = false
        this.getStrategyList()
        this.$message.success('更新成功')
      })
    },
    editDialogeClosed: function() {
      this.$refs.editFormRef.resetFields()
      this.editDialogeVisible = false
    },
    deleteRow: async function(id) {
      const confirmResult = await this.$confirm('此操作将永久删除该活动, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(err => err)
      if (confirmResult !== 'confirm') {
        return this.$message('已取消删除')
      }
      const { data: res } = await this.$http.delete('/Charge/DelStrategy?id=' + id)
      if (res.status !== 1) {
        return this.$message.error('删除失败')
      }
      this.getStrategyList()
      this.$message.success('删除成功')
    },
    changeStatus: async function(row, status) {
      const { data: res } = await this.$http.put(`/Charge/Status?id=${row.id}&status=${status}`)
      if (res.status !== 1) {
        return this.$message.error('更新失败')
      }
      this.getStrategyList()
      this.$message.success('更新成功')
    },
    start: function(row) {
      this.changeStatus(row, 1)
    },
    stop: function(row) {
      this.changeStatus(row, 2)
    },
    handleDateRangeChange: function(arr) {
      if (arr) {
        console.log(arr)
        this.addForm.begin_time = arr[0]
        this.addForm.end_time = arr[1]
      }
    },
    handleStrategyItem: function(row) {
      console.log(row)
      const expand = row.expand
      row.expand = !expand
      if (row.expand) {
        this.strategyList.forEach(v => {
          if (v.id !== row.id) {
            v.expand = false
          }
        })
      }
      this.$refs.tableRef.toggleRowExpansion(row, !expand)
    },
    handleTableExpand: function(row) {
      // const index = this.strategyList.findIndex(v => v.id === row.id)
      // const item = this.strategyList[index]
      const index = this.expand_row_keys.findIndex(v => v === row.id)
      if (index === -1) {
        row.expand = true
        this.strategyList.forEach(v => {
          if (v.id !== row.id) {
            v.expand = false
          }
        })
        this.getStrategyItemList(row.id)
        this.expand_row_keys = [row.id]
      } else {
        row.expand = false
      }
      console.log(row, this.expand_row_keys, this.strategyList)
    },
    handleAddStrategyItem: function() {
      this.addStrategyItemDialogeVisible = true
      this.addStrategyItemForm.strategy_id = this.expand_row_keys[0]
    },
    getStrategyItemList: async function(id) {
      const { data: res } = await this.$http.get('/Charge/StrategyItemList?id=' + id)
      if (res.status !== 1) {
        return this.$message.error('获取列表失败')
      }
      console.log(res.data)
      this.strategyItemList = res.data
    },
    getStrategyItem: async function(id) {
      const { data: res } = await this.$http.get('/Charge/StrategyItem?id=' + id)
      if (res.status !== 1) {
        return this.$message.error('获取数据失败')
      }
      console.log(res.data)
      this.editStrategyItemForm = res.data
    },
    handleStrategyItemCoupon: function(row) {
      this.addStrategyItemCouponDialogeVisible = true
      this.addStrategyItemCouponForm.strategy_item_id = row.id
    },
    ShowEditStrategyItemDialoge: function(id) {
      this.editStrategyItemDialogeVisible = true
      this.getStrategyItem(id)
    },
    addStrategyItemDialogeClosed: function() {
      this.$refs.addStrategyItemFormRef.resetFields()
    },
    addStrategyItemRow: function() {
      this.$refs.addStrategyItemFormRef.validate(async valid => {
        if (!valid) return
        const { data: res } = await this.$http.post('/Charge/AddStrategyItem', this.addStrategyItemForm)
        if (res.status !== 1) return this.$message.error('添加失败,' + res.msg)
        this.$message.success('添加成功')
        this.addStrategyItemDialogeVisible = false
        this.getStrategyItemList(this.addStrategyItemForm.strategy_id)
      })
    },
    addStrategyItemCouponDialogeClosed: function() {
      this.$refs.addStrategyItemCouponFormRef.resetFields()
    },
    editStrategyItemRow: async function() {
      this.$refs.editStrategyItemFormRef.validate(async valid => {
        if (!valid) return
        const { data: res } = await this.$http.put(`/Charge/EditStrategyItem?id=${this.editStrategyItemForm.id}&amount=${this.editStrategyItemForm.amount}`)
        if (res.status !== 1) {
          return this.$message.error('更新失败')
        }
        this.editStrategyItemDialogeVisible = false
        this.getStrategyItemList(this.expand_row_keys[0])
        this.$message.success('更新成功')
      })
    },
    editStrategyItemDialogeClosed: function() {
      this.$refs.editStrategyItemCouponFormRef.resetFields()
    },
    delStrategyItemRow: async function(id) {
      const confirmResult = await this.$confirm('此操作将永久删除该设置, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(err => err)
      if (confirmResult !== 'confirm') {
        return this.$message('已取消删除')
      }
      const { data: res } = await this.$http.delete('/Charge/DelStrategyItem?id=' + id)
      if (res.status !== 1) {
        return this.$message.error('删除失败')
      }
      this.getStrategyItemList(this.expand_row_keys[0])
      this.$message.success('删除成功')
    },
    addStrategyItemCouponRow: function() {
      this.$refs.addStrategyItemCouponFormRef.validate(async valid => {
        if (!valid) return
        if (this.addStrategyItemCouponForm.valid_type === '0') {
          this.addStrategyItemCouponForm.valid_value = this.addStrategyItemCouponForm.date_range[0] + '|' + this.addStrategyItemCouponForm.date_range[1]
        } else {
          this.addStrategyItemCouponForm.valid_value = this.addStrategyItemCouponForm.valid_day
        }
        const { data: res } = await this.$http.post('/Charge/AddStrategyItemCoupon', this.addStrategyItemCouponForm)
        if (res.status !== 1) return this.$message.error('添加失败,' + res.msg)
        this.$message.success('添加成功')
        this.addStrategyItemCouponDialogeVisible = false
        this.getStrategyItemCouponList(this.addStrategyItemCouponForm.strategy_item_id)
      })
    },
    getStrategyItemCouponList: async function(id) {
      const { data: res } = await this.$http.get('/Charge/StrategyItemCouponList?id=' + id)
      if (res.status !== 1) {
        return this.$message.error('获取列表失败')
      }
      console.log(res.data)
      const index = this.strategyItemList.findIndex(v => v.id === id)
      const strategyItem = this.strategyItemList[index]
      strategyItem.couponList = res.data.list
      let sum = 0
      res.data.list.forEach(v => {
        sum += v.amount * v.num
      })
      strategyItem.coupon_amount = sum
    },
    handleValidTypeChange: function(val) {
      this.addStrategyItemCouponForm.date_range = []
      this.addStrategyItemCouponForm.valid_day = ''
      this.$refs.addStrategyItemCouponFormRef.clearValidate(['date_range', 'valid_day'])
    },
    removeCoupon: async function(strategyItemCouponId, strategyItemId) {
      const confirmResult = await this.$confirm('此操作将永久删除该优惠券, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(err => err)
      if (confirmResult !== 'confirm') {
        return this.$message('已取消删除')
      }
      const { data: res } = await this.$http.delete('/Charge/DelStrategyItemCoupon?id=' + strategyItemCouponId)
      if (res.status !== 1) {
        return this.$message.error('删除失败')
      }
      this.getStrategyItemCouponList(strategyItemId)
      this.$message.success('删除成功')
    }
  }
}
</script>
<style lang="less" scoped>
.coupon_item {
  padding: 15px;
  background-color: #f56c6c;
  border-radius: 5px;
  color: #fff;
  width: 150px;
  height: 100px;
  font-size: 12px;
  line-height: 20px;
}
.box {
  padding: 10px;
  box-sizing: border-box;
}
.grid1 {
  width: 270px;
  height: 100px;
  background: radial-gradient(circle at right top, transparent 10px, #28a4f2 0) top left / 80px 51% no-repeat, radial-gradient(circle at right bottom, transparent 10px, #28a4f2 0) bottom left / 80px 51% no-repeat, radial-gradient(circle at left top, transparent 10px, #eeeeee 0) top right / 190px 51% no-repeat, radial-gradient(circle at left bottom, transparent 10px, #eeeeee 0) bottom right / 190px 51% no-repeat;
  filter: drop-shadow(3px 3px 3px rgba(0, 0, 0, 0.3));
  display: flex;
  margin-bottom: 15px;
  .left {
    width: 80px;
    padding: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fff;
    .title {
      font-size: 28px;
    }
    .sub_title {
      font-size: xx-small;
    }
  }
  .right {
    padding: 10px;
    line-height: 20px;
    .icon {
      position: absolute;
      right: -10px;
      top: -10px;
      font-size: 20px;
    }
    .icon:hover {
      color: #28a4f2;
    }
    .num {
      font-size: 20px;
      font-style: italic;
      position: absolute;
      right: 10px;
      bottom: 10px;
    }
  }
}
</style>
