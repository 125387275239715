<template>
  <el-container class="container">
    <el-header>
      <div v-on:click="toggleCollapse" class="title_box">
        <img src="../assets/censtar.png" alt v-on:click="toggleCollapse" />
        <span>能源加注平台</span>
      </div>
      <el-menu background-color="#0b5bbc" text-color="#fff" active-text-color="#ffe940" unique-opened v-bind:router="true" v-bind:default-active="activePath" mode="horizontal" menu-trigger="click">
        <!-- <div class="toggleButton" v-on:click="toggleCollapse">|||</div> -->
        <!-- <el-menu-item v-bind:index="'/bigdata'">大屏数据</el-menu-item> -->
        <!-- 一级菜单 -->
        <el-submenu v-bind:index="item.id + ''" :key="item.id" v-for="item in menuList">
          <!-- 一级菜单模板区域 -->
          <template slot="title">
            <!-- 图标 -->
            <!-- <i class="iconfont icon-shangpin"></i> -->
            <!-- 文本 -->
            <span>{{ item.name }}</span>
          </template>
          <!-- 二级菜单 -->
          <el-menu-item v-bind:index="'/' + child.path" :key="child.id" v-for="child in item.children" v-on:click="saveNavPath('/' + child.path)">
            <!-- 图标 -->
            <i class="el-icon-menu"></i>
            <!-- 文本 -->
            <span>{{ child.name }}</span>
          </el-menu-item>
        </el-submenu>
      </el-menu>
      <div class="login_box">
        <div class="user_box" v-cloak>
          <!-- <span class="user"><i class="el-icon-user"></i>当前用户：{{ userInfo.userName }}</span>
          <span class="role"
            ><div>{{ userInfo.userType }}</div>
            <div>{{ userInfo.roleName }}</div></span
          > -->
          <el-avatar size="medium" icon="el-icon-user-solid"> </el-avatar>
          <div class="user">
            <div>{{ userInfo.userName }}</div>
            <div>{{ date }}</div>
          </div>
        </div>
        <div><el-button type="info" v-on:click="logout" size="mini">退出</el-button></div>
        <!-- <div class="fullscreen_box" @click="handleFullScreen">
          <el-tooltip :content="isFullScreen ? '取消全屏' : '全屏'" effect="dark" placement="bottom">
            <i class="el-icon-full-screen"></i>
          </el-tooltip>
        </div> -->
      </div>
    </el-header>
    <el-container class="main_container">
      <!-- 侧边栏 -->
      <!-- <el-aside v-bind:width="isCollapse ? '64px' : '200px'"> -->
      <!-- 侧边栏菜单区域 -->
      <!-- </el-aside> -->
      <el-main>
        <router-view></router-view>
      </el-main>
    </el-container>
  </el-container>
</template>
<script>
import screenfull from 'screenfull'
export default {
  data: function() {
    return {
      menuList: [],
      iconfontObj: {
        3: 'iconfont icon-users',
        103: 'iconfont icon-tijikongjian',
        101: 'iconfont icon-shangpin',
        102: 'iconfont icon-danju',
        145: 'iconfont icon-baobiao'
      },
      isCollapse: false,
      activePath: '',
      userInfo: {
        userName: '',
        userType: '',
        roleName: ''
      },
      isFullScreen: false,
      date: '',
      timer: ''
    }
  },
  created: function() {
    this.getMenuList()
    this.activePath = window.sessionStorage.getItem('activePath')
    this.showTime()
    this.timer = setInterval(() => {
      this.showTime()
    }, 1000)
  },
  mounted() {
    this.getUserInfo()
    this.getPermissionList()
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  methods: {
    logout: function() {
      window.sessionStorage.clear()
      this.$router.push('/login')
    },
    getMenuList: async function() {
      var { data: res } = await this.$http.get('/Account/Menus')
      if (res.status !== 1) {
        this.$message.error(res.msg)
      } else {
        this.menuList = res.data
        console.log(this.menuList)
      }
    },
    getUserInfo: async function() {
      const { data: res } = await this.$http.get('/Account/UserInfo')
      console.log(res.data)
      this.userInfo = res.data
    },
    toggleCollapse: function() {
      this.isCollapse = !this.isCollapse
    },
    saveNavPath: function(path) {
      window.sessionStorage.setItem('activePath', path)
    },
    getPermissionList: async function() {
      const { data: res } = await this.$http.get('/Account/Permissions')
      console.log(res.data)
      var obj = {}
      res.data.forEach(item => {
        obj[item] = true
      })
      window.localStorage.setItem('permissions', JSON.stringify(obj))
    },
    handleFullScreen() {
      if (!screenfull.isEnabled) {
        // 如果不支持进入全屏，发出不支持提示
        this.$message({
          message: '您的浏览器版本过低不支持全屏显示！',
          type: 'warning'
        })
        return false
      }
      screenfull.toggle()
      this.isFullScreen = !this.isFullScreen
    },
    showTime() {
      var week = ['星期一', '星期二', '星期三', '星期四', '星期五', '星期六', '星期日']
      var date = new Date()
      var year = date.getFullYear()
      var month = date.getMonth() + 1
      var day = date.getDate()
      var hour = date.getHours()
      var minutes = date.getMinutes()
      var second = date.getSeconds()
      this.date = year + '.' + (month < 10 ? '0' + month : month) + '.' + (day < 10 ? '0' + day : day) + '' + ' ' + (hour < 10 ? '0' + hour : hour) + ':' + (minutes < 10 ? '0' + minutes : minutes) + ':' + (second < 10 ? '0' + second : second) + ' ' + (week[date.getDay() - 1] || week[6])
    }
  }
}
</script>
<style lang="less" scoped>
.container {
  height: 100%;
}
.el-header {
  background-color: #0b5bbc;
  color: #fff;
  padding-left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .title_box {
    display: flex;
    align-items: center;
    font-size: 20px;
    img {
      // width: 50px;
      height: 30px;
    }
    span {
      margin-left: 15px;
      font-weight: bold;
    }
  }
  /deep/ .el-submenu__title i {
    color: #fff;
  }
  .login_box {
    display: flex;
    align-items: center;
    .user_box {
      display: flex;
      align-items: center;
      margin-right: 10px;
      font-size: 0.8rem;
      .user {
        margin-left: 5px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        div:first-child {
          font-size: 15px;
          font-weight: bolder;
        }
      }
    }
    .fullscreen_box {
      margin-left: 20px;
    }
  }
}
.el-aside {
  background-color: #333744;
  .iconfont {
    margin-right: 10px;
  }
  .el-menu {
    border: none;
  }
}
.main_container {
  height: calc(100vh - 60px);

  .el-main {
    background-color: #eaedf1;

    div:first-child {
      height: 100%;
      overflow: hidden;
    }
  }
}

/deep/.el-breadcrumb {
  height: 30px;
  line-height: 30px;
}

.toggleButton {
  background-color: #4a5064;
  letter-spacing: 0.3rem;
  font-size: 10px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  cursor: pointer;
  color: #fff;
}
</style>
