<template>
  <div>
    <el-breadcrumb>
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>运营数据</el-breadcrumb-item>
      <el-breadcrumb-item>数据统计</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <el-form :inline="true" :model="queryParam">
        <el-form-item label="网点">
          <el-select v-model="queryParam.station_id" placeholder="请选择网点" clearable v-on:clear="getReportList" size="small">
            <el-option v-for="item in stationList" :key="item.id" :label="item.name" :value="item.id"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="统计时间">
          <el-date-picker v-model="queryParam.daterange" type="daterange" align="center" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptions" value-format="yyyy-MM-dd" size="small"> </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-search" @click="getReportList" size="small">搜索</el-button>
        </el-form-item>
      </el-form>
      <el-table :data="list" border show-summary :fixed="true">
        <el-table-column prop="day" label="日期"> </el-table-column>
        <el-table-column prop="trade_count" label="加注次数" class-name="trade"></el-table-column>
        <el-table-column prop="trade_qty" label="加注数量" class-name="trade"> </el-table-column>
        <el-table-column prop="trade_money" label="加注金额" class-name="trade"> </el-table-column>
        <el-table-column prop="pay_count" label="付款次数" class-name="pay"> </el-table-column>
        <el-table-column prop="pay_money" label="付款金额" class-name="pay"> </el-table-column>
        <el-table-column prop="account_count" label="分账次数" class-name="account"> </el-table-column>
        <el-table-column prop="account_money" label="分账金额" class-name="account"> </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button type="text" v-on:click="onDetailClick(scope.row.day)">查看明细</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
  </div>
</template>
<script>
export default {
  data() {
    return {
      queryParam: {
        station_id: '',
        daterange: []
      },
      list: [],
      stationList: [],
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            }
          }
        ],
        disabledDate: time => {
          return time > new Date()
        }
        // onPick: ({ maxDate, minDate }) => {
        //   console.log(this.queryParam.daterange)
        //   console.log(maxDate)
        //   console.log(minDate)
        //   this.maxDate = maxDate
        //   this.minDate = minDate
        // }
      }
    }
  },
  created() {
    var beginDate = this.formatDate(new Date().getTime() - 3600 * 1000 * 24 * 15)
    var endDate = this.formatDate(new Date().getTime())
    this.queryParam.daterange.push(beginDate)
    this.queryParam.daterange.push(endDate)
  },
  mounted() {
    this.getStationList()
    this.getReportList()
  },
  methods: {
    getReportList: async function() {
      console.log(this.queryParam)
      const { data: res } = await this.$http.get('/Report/List', { params: this.queryParam })
      if (res.status !== 1) {
        return this.$message.error('获取统计列表失败')
      }
      console.log(res.data)
      this.list = res.data
    },
    getStationList: async function() {
      const { data: res } = await this.$http.get('/PartnerPerson/Station')
      if (res.status !== 1) return this.$message.error('获取网点信息失败')
      this.stationList = res.data
    },
    formatDate: function(val) {
      const dt = new Date(val)
      const y = dt.getFullYear().toString()
      const m = (dt.getMonth() + 1).toString().padStart(2, '0')
      const d = dt
        .getDate()
        .toString()
        .padStart(2, '0')
      return `${y}-${m}-${d}`
    },
    onDetailClick: function(day) {
      this.$router.push(`/trade/${day}`)
    }
  }
}
</script>
<style lang="less" scoped>
.el-table {
  overflow: visible;
  // margin-bottom: 50px;
}
.el-table-column {
  background-color: rgb(138, 20, 20);
}
/deep/ .trade {
  color: black !important;
  text-align: center;
  background-color: #5d7dd6;
}
/deep/ .pay {
  color: black !important;
  text-align: center;
  background-color: rgb(31, 214, 113);
}
/deep/ .account {
  color: black !important;
  text-align: center;
  background-color: #c3b60e;
}
</style>
