<template>
  <div>
    <el-row>
      <el-col>
        <el-button type="primary" v-on:click="handleAdd" v-has="'partners:add'" size="small">添加</el-button>
      </el-col>
    </el-row>
    <el-table :data="partnerPersonList" border stripe>
      <el-table-column type="index" label="#"></el-table-column>
      <el-table-column prop="station_name" label="合伙网点"> </el-table-column>
      <el-table-column prop="user_name" label="名称">
        <template slot-scope="scope">
          <div class="user_name">{{ scope.row.user_name }}<img v-if="scope.row.id === 8" src="../../assets/renzheng.png" class="renzheng" /></div
        ></template>
      </el-table-column>
      <el-table-column prop="state" label="状态">
        <template slot-scope="scope">
          <i-switch size="large" v-model="scope.row.state" v-on:on-change="handlePersonState(scope.row)" v-has="'partners:state'">
            <span slot="open">启用</span>
            <span slot="close">禁用</span>
          </i-switch>
        </template>
      </el-table-column>
      <el-table-column prop="share_type" label="分账" width="300px">
        <template slot-scope="scope">
          <el-tag size="medium" v-if="scope.row.share_type === 1">每笔销售金额扣除支付手续费后按{{ scope.row.share_value }}%分账</el-tag>
          <el-tag size="medium" v-else type="success">每笔销售金额扣除支付手续费后按{{ scope.row.share_value }}元分账</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="balance" label="累计分账金额"> </el-table-column>
      <el-table-column label="操作" width="500">
        <template slot-scope="scope">
          <el-button type="text" icon="el-icon-edit" size="mini" v-on:click="handleUpdate(scope.row)" v-has="'partners:edit'">修改</el-button>
          <el-button type="text" icon="el-icon-delete" size="mini" v-on:click="handleDelete(scope.row.id)" v-has="'partners:remove'">删除</el-button>
          <!-- <el-button type="text" icon="el-icon-s-custom" size="mini" v-on:click="handlePersonRegister(scope.row.id)" v-has="'partners:remove'">开户注册</el-button> -->
          <!-- <el-button type="text" icon="el-icon-s-claim" size="mini" v-on:click="showPicPersonDialoge(scope.row.id)" v-has="'partners:remove'">图片认证</el-button> -->
        </template>
      </el-table-column>
    </el-table>
    <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" v-bind:current-page="queryParam.pagenum" v-bind:page-sizes="this.pageOption" v-bind:page-size="queryParam.pagesize" layout="total, sizes, prev, pager, next, jumper" v-bind:total="total"> </el-pagination>
    <!-- 添加对话框 -->
    <el-dialog title="添加分账人" :visible.sync="dialogeVisible" width="50%">
      <el-form :model="form" :rules="formRules" ref="formRef" label-width="150px">
        <el-row>
          <el-col :span="12">
            <el-form-item label="分账网点" prop="station_id">
              <el-select v-model="form.station_id" placeholder="请选择分账网点">
                <el-option v-for="item in stationList" :key="item.id" :label="item.name" :value="item.id"> </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="分账类型" prop="share_type">
              <el-radio-group v-model="form.share_type">
                <el-radio v-bind:label="1">按百分比分账</el-radio>
                <el-radio v-bind:label="2" disabled>按固定值分账</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12"
            ><el-form-item label="分账数值" prop="share_value"> <el-input v-model.number="form.share_value" type="number" :max="100" :step="1"></el-input> </el-form-item
          ></el-col>
          <el-col :span="12"
            ><el-form-item label="姓名" prop="user_name"> <el-input v-model="form.user_name"></el-input> </el-form-item
          ></el-col>
        </el-row>
        <el-row>
          <el-col :span="12"
            ><el-form-item label="身份证号" prop="user_card_no"> <el-input v-model="form.user_card_no"></el-input> </el-form-item
          ></el-col>
          <el-col :span="12"
            ><el-form-item label="手机号" prop="mobile"> <el-input v-model="form.mobile"></el-input> </el-form-item
          ></el-col>
        </el-row>
        <el-row>
          <el-col :span="12"
            ><el-form-item label="证件开始日期" prop="user_card_startdate"> <el-input v-model="form.user_card_startdate"></el-input> </el-form-item
          ></el-col>
          <el-col :span="12"
            ><el-form-item label="证件结束日期" prop="user_card_enddate"> <el-input v-model="form.user_card_enddate"></el-input> </el-form-item
          ></el-col>
        </el-row>
        <el-row>
          <el-col :span="12"
            ><el-form-item label="开户银行" prop="bank"> <el-cascader ref="personBankRef" filterable :key="resetCascader" v-model="form.bank" :props="cascade_props"> </el-cascader> </el-form-item
          ></el-col>
          <el-col :span="12"
            ><el-form-item label="银行卡号" prop="bank_cardno"> <el-input v-model="form.bank_cardno"></el-input> </el-form-item
          ></el-col>
        </el-row>
        <el-row>
          <el-col :span="12"
            ><el-form-item label="账户名称" prop="acct_nm"> <el-input v-model="form.acct_nm"></el-input> </el-form-item
          ></el-col>
          <el-col :span="12"
            ><el-form-item label="预留手机号" prop="reserved_phone"> <el-input v-model="form.reserved_phone"></el-input> </el-form-item
          ></el-col>
        </el-row>
        <el-row>
          <el-col :span="12"
            ><el-form-item label="身份证人像面" prop="idcard_front">
              <el-upload :action="uploadUrl" list-type="picture-card" :multiple="false" :on-preview="handleIdCardFrontPreview" :on-remove="handleIdCardFrontRemove" :on-success="handleIdCardFrontSuccess" :class="{ disabled: this.form.idcard_front ? true : false }" :file-list="idCardFrontFileList">
                <i class="el-icon-plus"></i>
              </el-upload>
              <el-dialog :visible.sync="dialogIdCardFrontVisible" append-to-body>
                <img width="100%" :src="dialogIdCardFrontImageUrl" alt="" />
              </el-dialog> </el-form-item
          ></el-col>
          <el-col :span="12"
            ><el-form-item label="身份证国徽面" prop="idcard_back">
              <el-upload :action="uploadUrl" list-type="picture-card" :multiple="false" :on-preview="handleIdCardBackPreview" :on-remove="handleIdCardBackRemove" :on-success="handleIdCardBackSuccess" :class="{ disabled: this.form.idcard_back ? true : false }" :file-list="idCardBackFileList">
                <i class="el-icon-plus"></i>
              </el-upload>
              <el-dialog :visible.sync="dialogIdCardBackVisible" append-to-body>
                <img width="100%" :src="dialogIdCardBackImageUrl" alt="" />
              </el-dialog> </el-form-item
          ></el-col>
        </el-row>
        <el-row>
          <el-col :span="12"
            ><el-form-item label="手持身份证照片" prop="person_with_card">
              <el-upload :action="uploadUrl" list-type="picture-card" :multiple="false" :on-preview="handlePersonWithCardPreview" :on-remove="handlePersonWithCardRemove" :on-success="handlePersonWithCardSuccess" :class="{ disabled: this.form.person_with_card ? true : false }" :file-list="personWithCardFileList">
                <i class="el-icon-plus"></i>
              </el-upload>
              <el-dialog :visible.sync="dialogPersonWithCardVisible" append-to-body>
                <img width="100%" :src="dialogPersonWithCardImageUrl" alt="" />
              </el-dialog> </el-form-item
          ></el-col>
          <el-col :span="12"
            ><el-form-item label="结算银行卡照片" prop="bank_card">
              <el-upload :action="uploadUrl" list-type="picture-card" :multiple="false" :on-preview="handleBankCardPreview" :on-remove="handleBankCardRemove" :on-success="handleBankCardSuccess" :class="{ disabled: this.form.bank_card ? true : false }" :file-list="bankCardFileList">
                <i class="el-icon-plus"></i>
              </el-upload>
              <el-dialog :visible.sync="dialogBankCardVisible" append-to-body>
                <img width="100%" :src="dialogBankCardImageUrl" alt="" />
              </el-dialog> </el-form-item
          ></el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="submitForm">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import _ from 'lodash'
export default {
  data() {
    const that = this
    const checkBank = (rule, value, callback) => {
      if (!this.form.bank.length) {
        callback(new Error('请选择开户银行'))
      } else {
        callback()
      }
    }
    return {
      stationList: [],
      dialogeVisible: false,
      form: {},
      formRules: {
        station_id: [{ required: true, message: '请选择网点', trigger: 'blur' }],
        share_type: [{ required: true, message: '请选择分账类型', trigger: 'change' }],
        share_value: [
          { required: true, message: '请输入分账数值', trigger: 'blur' },
          { type: 'number', message: '必须为数字', trigger: 'blur' }
        ],
        user_name: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
        user_card_no: [{ required: true, message: '请输入身份证号', trigger: 'blur' }],
        mobile: [{ required: true, message: '请输入手机号', trigger: 'blur' }],
        user_card_startdate: [{ required: true, message: '请输入证件开始日期，格式例如 20230612', trigger: 'blur' }],
        user_card_enddate: [{ required: true, message: '请输入证件结束日期，格式例如 20230612', trigger: 'blur' }],
        bank: [{ validator: checkBank, trigger: 'blur' }],
        bank_cardno: [{ required: true, message: '请输入银行卡号', trigger: 'blur' }],
        acct_nm: [{ required: true, message: '请输入账户名称', trigger: 'blur' }],
        reserved_phone: [{ required: true, message: '请输入预留手机号', trigger: 'blur' }],
        idcard_front: [{ required: true, message: '请上传身份证人像面', trigger: 'blur' }],
        idcard_back: [{ required: true, message: '请上传身份证国徽面', trigger: 'blur' }],
        person_with_card: [{ required: true, message: '请上传手持身份证照片', trigger: 'blur' }]
      },
      partnerPersonList: [],
      total: 0,
      queryParam: {
        queryJson: 'a',
        pagenum: 1,
        pagesize: this.pageOption[0]
      },
      cascade_props: {
        lazy: true,
        // checkStrictly: true, // 可以选择任意节点
        expandTrigger: 'click',
        async lazyLoad(node, resolve) {
          const { data, level } = node
          console.log(node, data, level)
          const pname = data && data.label ? data.label : ''
          const prev = node.parent && node.parent.data && node.parent.data.label ? node.parent.data.label : ''
          const res = await that.$http.get('/PartnerPerson/GetCascader', {
            params: { label: pname, level, prev: prev }
          })
          const nodes = res.data.data.map(item => ({
            value: item.value,
            label: item.label,
            leaf: level >= 3
          }))
          resolve(nodes)
        }
      },
      resetCascader: 0,
      uploadUrl: 'http://localhost:9789/api/PartnerPerson/UploadPic',
      dialogIdCardFrontImageUrl: '',
      dialogIdCardFrontVisible: false,
      dialogIdCardBackImageUrl: '',
      dialogIdCardBackVisible: false,
      dialogPersonWithCardImageUrl: '',
      dialogPersonWithCardVisible: false,
      dialogBankCardImageUrl: '',
      dialogBankCardVisible: false,
      idCardFrontFileList: [],
      idCardBackFileList: [],
      personWithCardFileList: [],
      bankCardFileList: [],

      activeStep: 0
    }
  },
  mounted() {
    this.getPartnerPersonList()
    this.getStationList()
  },
  methods: {
    getStationList: async function() {
      const { data: res } = await this.$http.get('/PartnerPerson/Station')
      if (res.status !== 1) return this.$message.error('获取网点信息失败')
      this.stationList = res.data
    },
    getPartnerPersonList: async function() {
      const { data: res } = await this.$http.get('/PartnerPerson/List', { params: this.queryParam })
      if (res.status !== 1) {
        return this.$message.error('获取分账人列表失败')
      }
      this.partnerPersonList = res.data.list
      this.total = res.data.total
    },
    handleSizeChange: function(newSize) {
      this.queryParam.pagesize = newSize
      this.getPartnerPersonList()
    },
    handleCurrentChange: function(newPage) {
      this.queryParam.pagenum = newPage
      this.getPartnerPersonList()
    },
    reset() {
      this.form = {
        share_type: 1,
        share_value: '',
        user_type: 1,
        station_id: '',
        user_name: '',
        user_card_no: '',
        mobile: '',
        user_card_startdate: '',
        user_card_enddate: '',
        acct_nm: '',
        bank_cardno: '',
        reserved_phone: '',
        bank_code: '',
        idcard_front: '',
        idcard_back: '',
        person_with_card: '',
        bank_card: '',
        bank: []
      }
      if (this.$refs.formRef) {
        this.$refs.formRef.resetFields()
      }
      this.idCardFrontFileList = []
      this.idCardBackFileList = []
      this.personWithCardFileList = []
      this.bankCardFileList = []
    },
    cancel() {
      this.dialogeVisible = false
      this.reset()
    },
    handleAdd: function() {
      this.reset()
      this.dialogeVisible = true
    },
    async handleUpdate(row) {
      this.reset()
      const { data: res } = await this.$http.get(`/PartnerPerson/One?id=${row.id}`)
      this.form = res.data
      this.dialogeVisible = true
      this.idCardFrontFileList.push({ url: res.data.idcard_front })
      this.idCardBackFileList.push({ url: res.data.idcard_back })
      this.personWithCardFileList.push({ url: res.data.person_with_card })
      this.bankCardFileList.push({ url: res.data.bank_card })
      const { data: bank } = await this.$http.get('/PartnerPerson/GetCascaderItem', {
        params: { name: res.data.acct_z_banknm }
      })
      this.form.bank = bank.data
      this.resetCascader++
    },
    submitForm() {
      this.$refs.formRef.validate(async valid => {
        if (valid) {
          if (this.form.id != null) {
            const form = _.cloneDeep(this.form)
            const nodeList = this.$refs.personBankRef.getCheckedNodes()
            console.log(nodeList)
            this.$set(form, 'acct_bankno', nodeList[0].value.split('|')[1])
            this.$set(form, 'acct_banknm', nodeList[0].value.split('|')[0])
            this.$set(form, 'acct_z_bankno', nodeList[0].value.split('|')[3])
            this.$set(form, 'acct_z_banknm', nodeList[0].value.split('|')[2])
            this.$set(form, 'acct_z_bankcode', nodeList[0].value.split('|')[4])
            const { data: res } = await this.$http.put('/PartnerPerson/Edit?id=' + form.id, form)
            if (res.status !== 1) {
              return this.$message.error('更新失败：' + res.msg)
            }
            this.dialogeVisible = false
            this.getPartnerPersonList()
            this.$message.success('更新成功')
          } else {
            const form = _.cloneDeep(this.form)
            const nodeList = this.$refs.personBankRef.getCheckedNodes()
            console.log(nodeList)
            this.$set(form, 'acct_bankno', nodeList[0].value.split('|')[1])
            this.$set(form, 'acct_banknm', nodeList[0].value.split('|')[0])
            this.$set(form, 'acct_z_bankno', nodeList[0].value.split('|')[3])
            this.$set(form, 'acct_z_banknm', nodeList[0].value.split('|')[2])
            this.$set(form, 'acct_z_bankcode', nodeList[0].value.split('|')[4])
            const { data: res } = await this.$http.post('/PartnerPerson/Add', form)
            if (res.status !== 1) return this.$message.error('添加失败，' + res.msg)
            this.$message.success('添加成功')
            this.dialogeVisible = false
            this.getPartnerPersonList()
          }
        }
      })
    },
    async handleDelete(id) {
      const confirmResult = await this.$confirm('此操作将永久删除该分账人, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(err => err)
      if (confirmResult !== 'confirm') {
        return this.$message('已取消删除')
      }
      const { data: res } = await this.$http.delete('/PartnerPerson/Del?id=' + id)
      if (res.status !== 1) {
        return this.$message.error('删除失败')
      }
      this.getTabData()
      this.$message.success('删除成功')
    },
    handlePersonState: async function(row) {
      console.log(row)
      const { data: res } = await this.$http.put(`/PartnerPerson/State?id=${row.id}&state=${row.state}`)
      if (res.status !== 1) {
        row.state = row.state === 0 ? 1 : 0
        this.$message.error('更新状态失败' + res.msg)
      }
      this.$message.success('更新状态成功')
    },
    handleIdCardFrontRemove(file, fileList) {
      this.form.idcard_front = ''
    },
    handleIdCardFrontPreview(file) {
      this.dialogIdCardFrontImageUrl = file.url
      this.dialogIdCardFrontVisible = true
    },
    handleIdCardFrontSuccess(res, file) {
      this.form.idcard_front = res.data
    },
    handleIdCardBackRemove(file, fileList) {
      this.form.idcard_back = ''
    },
    handleIdCardBackPreview(file) {
      this.dialogIdCardBackImageUrl = file.url
      this.dialogIdCardBackVisible = true
    },
    handleIdCardBackSuccess(res, file) {
      this.form.idcard_back = res.data
    },
    handlePersonWithCardRemove(file, fileList) {
      this.form.person_with_card = ''
    },
    handlePersonWithCardPreview(file) {
      this.dialogPersonWithCardImageUrl = file.url
      this.dialogPersonWithCardVisible = true
    },
    handlePersonWithCardSuccess(res, file) {
      this.form.person_with_card = res.data
    },
    handleBankCardRemove(file, fileList) {
      this.form.bank_card = ''
    },
    handleBankCardPreview(file) {
      this.dialogBankCardImageUrl = file.url
      this.dialogBankCardVisible = true
    },
    handleBankCardSuccess(res, file) {
      this.form.bank_card = res.data
    }
  }
}
</script>
<style lang="less" scoped>
.user_name {
  display: flex;
  align-items: center;
}
.renzheng {
  width: 25px;
  // position: absolute;
  // right: 0px;
  // top: 0px;
  background-color: lightgreen;
  margin-left: 10px;
}
.el-cascader {
  width: 100%;
}
/deep/.disabled .el-upload--picture-card {
  display: none !important;
}
.el-table {
  height: 610px;
}
</style>
