<template>
  <div>
    <el-breadcrumb>
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>运营数据</el-breadcrumb-item>
      <el-breadcrumb-item>入账记录</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- <el-page-header @back="$router.go(-1)" content="入账记录"></el-page-header> -->
    <el-card>
      <el-alert title="入账金额为扣除支付手续费后的金额" type="warning" show-icon :closable="false" class="tip"> </el-alert>
      <el-form :inline="true" :model="queryParam">
        <el-form-item label="网点">
          <el-select v-model="queryParam.station_id" placeholder="请选择网点" clearable v-on:clear="getTransactionList" size="small">
            <el-option v-for="item in stationList" :key="item.id" :label="item.name" :value="item.id"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="时间">
          <el-date-picker v-model="queryParam.daterange" type="daterange" align="center" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptions" value-format="yyyy-MM-dd" size="small"> </el-date-picker>
        </el-form-item>
        <el-form-item label="合伙人">
          <el-input placeholder="请输入合伙人姓名" v-model="queryParam.user_name" clearable v-on:clear="getTransactionList" size="small" />
        </el-form-item>
        <el-form-item label="结算状态">
          <el-radio-group v-model="queryParam.status" size="mini">
            <el-radio-button :label="0">未结算</el-radio-button>
            <el-radio-button :label="2">结算中</el-radio-button>
            <el-radio-button :label="1">已结算</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-search" @click="getTransactionList" size="small">搜索</el-button>
          <el-button icon="el-icon-refresh-right" @click="reset" size="small">重置</el-button>
        </el-form-item>
      </el-form>
      <el-table :data="transactionList" border stripe>
        <el-table-column type="index" label="#"></el-table-column>
        <!-- <el-table-column prop="company_name" label="企业"> </el-table-column> -->
        <el-table-column prop="station_name" label="网点"> </el-table-column>
        <el-table-column prop="name" label="合伙人"> </el-table-column>
        <el-table-column prop="add_time" label="入账时间" width="150">
          <template slot-scope="scope">
            {{ scope.row.add_time | dateformat }}
          </template>
        </el-table-column>
        <el-table-column prop="last_balance" label="入账前余额（元）"> </el-table-column>
        <el-table-column prop="money" label="入账金额（元）"> </el-table-column>
        <el-table-column prop="balance" label="入账后余额（元）"> </el-table-column>
        <!-- <el-table-column prop="trade_id" label="加注交易id"> </el-table-column> -->
        <el-table-column label="是否结算">
          <template slot-scope="scope">
            <el-tag size="small" v-if="scope.row.is_complete === 1" type="success" effect="plain">已结算</el-tag>
            <el-tag size="small" v-else-if="scope.row.is_complete === 0" effect="plain">未结算</el-tag>
            <el-tag size="small" v-else type="warning" effect="plain">结算中</el-tag>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination :hide-on-single-page="true" @size-change="handleSizeChange" @current-change="handleCurrentChange" v-bind:current-page="queryParam.pagenum" v-bind:page-sizes="this.pageOption" v-bind:page-size="queryParam.pagesize" layout="total, sizes, prev, pager, next, jumper" v-bind:total="total"> </el-pagination>
    </el-card>
  </div>
</template>
<script>
export default {
  props: ['cash_log_id'],
  data() {
    return {
      queryParam: {
        station_id: '',
        user_name: '',
        daterange: [],
        status: '',
        pagenum: 1,
        pagesize: this.pageOption[0],
        cash_log_id: ''
      },
      total: 0,
      transactionList: [],
      stationList: [],
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            }
          }
        ],
        disabledDate: time => {
          return time > new Date()
        }
        // onPick: ({ maxDate, minDate }) => {
        //   console.log(this.queryParam.daterange)
        //   console.log(maxDate)
        //   console.log(minDate)
        //   this.maxDate = maxDate
        //   this.minDate = minDate
        // }
      }
    }
  },
  created() {
    if (this.cash_log_id) {
      this.queryParam.cash_log_id = this.cash_log_id
    } else {
      var beginDate = this.formatDate(new Date().getTime() - 3600 * 1000 * 24 * 15)
      var endDate = this.formatDate(new Date().getTime())
      this.queryParam.daterange.push(beginDate)
      this.queryParam.daterange.push(endDate)
    }
    this.getStationList()
    this.getTransactionList()
  },
  methods: {
    getTransactionList: async function() {
      const { data: res } = await this.$http.get('/Transaction/List', { params: this.queryParam })
      if (res.status !== 1) {
        return this.$message.error('获取入账列表失败')
      }
      console.log(res.data)
      this.transactionList = res.data.list
      this.total = res.data.total
    },
    handleSizeChange: function(newSize) {
      this.queryParam.pagesize = newSize
      this.getTransactionList()
    },
    handleCurrentChange: function(newPage) {
      this.queryParam.pagenum = newPage
      this.getTransactionList()
    },
    getStationList: async function() {
      const { data: res } = await this.$http.get('/PartnerPerson/Station')
      if (res.status !== 1) return this.$message.error('获取网点信息失败')
      this.stationList = res.data
    },
    reset: function() {
      this.queryParam.station_id = ''
      this.queryParam.user_name = ''
      this.queryParam.status = ''
      this.queryParam.daterange = []
      this.queryParam.cash_log_id = ''
    },
    formatDate: function(val) {
      const dt = new Date(val)
      const y = dt.getFullYear().toString()
      const m = (dt.getMonth() + 1).toString().padStart(2, '0')
      const d = dt
        .getDate()
        .toString()
        .padStart(2, '0')
      return `${y}-${m}-${d}`
    }
  }
}
</script>
<style lang="less" scoped>
.tip {
  margin-bottom: 20px;
}
.el-card {
  box-sizing: border-box;
}
.el-page-header {
  margin-bottom: 15px;
  /deep/.el-page-header__content {
    font-size: 0.8rem;
  }
}
</style>
