<template>
  <div>
    <el-breadcrumb>
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>权限管理</el-breadcrumb-item>
      <el-breadcrumb-item>权限列表</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <el-row>
        <el-col>
          <el-button type="primary" v-on:click="showAddDialoge" v-has="'rights:add'">添加</el-button>
        </el-col>
      </el-row>
      <el-table :data="rightList" border stripe>
        <el-table-column type="index" label="#"></el-table-column>
        <el-table-column prop="name" label="权限名称"> </el-table-column>
        <el-table-column prop="path" label="路径"> </el-table-column>
        <el-table-column prop="sort" label="排序"> </el-table-column>
        <el-table-column prop="is_use" label="启用">
          <template slot-scope="scope">
            <el-switch v-model="scope.row.is_use" @change="stateChanged(scope.row)"> </el-switch>
          </template>
        </el-table-column>
        <el-table-column label="等级">
          <template slot-scope="scope">
            <!-- <el-tag v-if="scope.row.level === 1">一级</el-tag>
            <el-tag v-else-if="scope.row.level === 2" type="success">二级</el-tag>
            <el-tag v-else type="warning">三级</el-tag> -->
            <el-tag :type="scope.row.level | setLevel | setLevelStyle" size="medium">{{ scope.row.level | setLevel }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button type="primary" icon="el-icon-edit" size="mini" v-on:click="showEditDialoge(scope.row.id)" v-has="'rights:edit'"></el-button>
            <el-button type="danger" icon="el-icon-delete" size="mini" v-on:click="deleteRow(scope.row.id)" v-has="'rights:remove'"></el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <!-- 添加对话框 -->
    <el-dialog title="添加权限" :visible.sync="addDialogeVisible" width="50%" v-on:closed="addDialogeClosed">
      <el-form :model="addForm" :rules="addFormRules" ref="addFormRef" label-width="80px">
        <el-form-item label="权限名称" prop="name">
          <el-input v-model="addForm.name"></el-input>
        </el-form-item>
        <el-form-item label="权限路径" prop="path">
          <el-input v-model="addForm.path"></el-input>
        </el-form-item>
        <el-form-item label="父级权限" prop="parent">
          <el-cascader :options="parentList" :props="cascaderProps" v-model="addForm.parent" clearable></el-cascader>
        </el-form-item>
        <el-form-item label="排序">
          <el-input v-model="addForm.sort"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogeVisible = false">取 消</el-button>
        <el-button type="primary" @click="addRow">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 修改对话框 -->
    <el-dialog title="修改权限" :visible.sync="editDialogeVisible" width="50%" v-on:closed="editDialogeClosed">
      <el-form :model="editForm" :rules="editFormRules" ref="editFormRef" label-width="80px">
        <el-form-item label="权限名称" prop="name">
          <el-input v-model="editForm.name"></el-input>
        </el-form-item>
        <el-form-item label="权限路径" prop="path">
          <el-input v-model="editForm.path"></el-input>
        </el-form-item>
        <el-form-item label="排序" prop="sort">
          <el-input v-model="editForm.sort"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogeClosed">取 消</el-button>
        <el-button type="primary" @click="editSave">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data: function() {
    return {
      rightList: [],
      addDialogeVisible: false,
      addForm: {
        name: '',
        path: '',
        parent: '',
        sort: ''
      },
      addFormRules: {
        name: [
          { required: true, message: '请输入权限名称', trigger: 'blur' },
          { min: 2, max: 10, message: '长度在2至10个字符', trigger: 'blur' }
        ],
        path: [
          { required: true, message: '请输入权限路径', trigger: 'blur' },
          { min: 1, max: 20, message: '长度在1至20个字符', trigger: 'blur' }
        ]
      },
      editDialogeVisible: false,
      editForm: {},
      editFormRules: {
        name: [
          { required: true, message: '请输入权限名称', trigger: 'blur' },
          { min: 2, max: 10, message: '长度在2至10个字符', trigger: 'blur' }
        ],
        path: [
          { required: true, message: '请输入权限路径', trigger: 'blur' },
          { min: 1, max: 20, message: '长度在1至20个字符', trigger: 'blur' }
        ]
      },
      parentList: [],
      cascaderProps: {
        value: 'sequence_code',
        label: 'name',
        children: 'children',
        expandTrigger: 'hover',
        emitPath: false,
        checkStrictly: true
      }
    }
  },
  created() {
    this.getRightList()
  },
  methods: {
    async getRightList() {
      const { data: res } = await this.$http.get('/Menu/List?type=list')
      if (res.status !== 1) {
        return this.$message.error('获取权限列表失败')
      }
      console.log(res.data)
      this.rightList = res.data
    },
    stateChanged: async function(row) {
      console.log(row)
      const { data: res } = await this.$http.put(`/Menu/State?id=${row.id}&is_use=${row.is_use}`)
      if (res.status !== 1) {
        row.is_use = !row.is_use
        return this.$message.error('更新状态失败')
      }
      this.$message.success('更新状态成功')
    },
    showAddDialoge: function() {
      this.getParentList()
      this.addDialogeVisible = true
    },
    addDialogeClosed: function() {
      this.$refs.addFormRef.resetFields()
    },
    addRow: function() {
      this.$refs.addFormRef.validate(async valid => {
        if (!valid) return
        const { data: res } = await this.$http.post('/Menu/Add', this.addForm)
        if (res.status !== 1) return this.$message.error('添加失败')
        this.$message.success('添加成功')
        this.addDialogeVisible = false
        this.getRightList()
      })
    },
    showEditDialoge: async function(id) {
      const { data: res } = await this.$http.get(`/Menu/One?id=${id}`)
      if (res.status !== 1) return this.$message.error('获取权限信息失败')
      this.editForm = res.data
      this.editDialogeVisible = true
    },
    editDialogeClosed: function() {
      this.$refs.editFormRef.resetFields()
      this.editDialogeVisible = false
    },
    editSave: function() {
      this.$refs.editFormRef.validate(async valid => {
        if (!valid) return
        const { data: res } = await this.$http.put('/Menu/Edit?id=' + this.editForm.id, { name: this.editForm.name, path: this.editForm.path, sort: this.editForm.sort })
        if (res.status !== 1) {
          return this.$message.error('更新失败')
        }
        this.editDialogeVisible = false
        this.getRightList()
        this.$message.success('更新成功')
      })
    },
    deleteRow: async function(id) {
      const confirmResult = await this.$confirm('此操作将永久删除该权限, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(err => err)
      if (confirmResult !== 'confirm') {
        return this.$message('已取消删除')
      }
      const { data: res } = await this.$http.delete('/Menu/Del?id=' + id)
      if (res.status !== 1) {
        return this.$message.error('删除失败')
      }
      this.getRightList()
      this.$message.success('删除成功')
    },
    getParentList: async function() {
      const { data: res } = await this.$http.get('/Menu/Tree')
      if (res.status !== 1) {
        return this.$message.error('获取父级权限失败')
      }
      console.log('获取父级权限成功')
      this.parentList = res.data
      console.log(this.parentList)
    }
  },
  filters: {
    setLevel(val) {
      switch (val) {
        case 1:
          return '一级'
        case 2:
          return '二级'
        case 3:
          return '三级'
        default:
          return '未知'
      }
    },
    setLevelStyle(val) {
      switch (val) {
        case '一级':
          return 'primary'
        case '二级':
          return 'success'
        case '三级':
          return 'warning'
      }
    }
  }
}
</script>
<style lang="less" scoped>
.el-cascader {
  width: 100%;
}
</style>
