import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './plugins/element.js'
import './plugins/iview.js'
import './assets/css/global.css'
import './assets/fonts/iconfont.css'
import axios from 'axios'

import './assets/css/iconfont.css'
import './assets/theme/index.css'
import 'iview/dist/styles/iview.css'

axios.defaults.baseURL = process.env.VUE_APP_BASE_API
axios.interceptors.request.use(config => {
  config.headers.Authorization = window.sessionStorage.getItem('token')
  return config
})
Vue.prototype.$http = axios
Vue.prototype.pageOption = [50, 100, 200, 1000, 5000]

Vue.config.productionTip = false

Vue.filter('dateformat', function(val) {
  const dt = new Date(val)
  const y = dt.getFullYear().toString()
  const m = (dt.getMonth() + 1).toString().padStart(2, '0')
  const d = dt
    .getDate()
    .toString()
    .padStart(2, '0')
  const hh = dt
    .getHours()
    .toString()
    .padStart(2, '0')
  const mm = dt
    .getMinutes()
    .toString()
    .padStart(2, '0')
  const ss = dt
    .getSeconds()
    .toString()
    .padStart(2, '0')
  return `${y}-${m}-${d} ${hh}:${mm}:${ss}`
})

Vue.directive('has', {
  inserted: function(el, bindings, vnode) {
    console.log(JSON.parse(window.localStorage.getItem('permissions')))
    const btnPermissionValue = bindings.value + ''
    const boolean = JSON.parse(window.localStorage.getItem('permissions'))[btnPermissionValue]
    console.log(boolean)
    !boolean && el.parentNode.removeChild(el)
  }
})

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
