<template>
  <div>
    <el-breadcrumb>
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>运营数据</el-breadcrumb-item>
      <el-breadcrumb-item>充值记录</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <el-row :gutter="20">
        <el-col :span="8">
          <el-input placeholder="请输入用户进行搜索" v-model="queryParam.queryJson" clearable v-on:clear="getChargeList" size="mini">
            <el-button slot="append" icon="el-icon-search" v-on:click="getChargeList"></el-button>
          </el-input>
        </el-col>
      </el-row>
      <el-table :data="chargeList" border stripe>
        <el-table-column type="index" label="#"></el-table-column>
        <el-table-column prop="name" label="用户"> </el-table-column>
        <el-table-column prop="money" label="付款金额"> </el-table-column>
        <el-table-column prop="gift_money" label="赠送金额"> </el-table-column>
        <el-table-column prop="total_money" label="充值金额"> </el-table-column>
        <el-table-column prop="last_balance" label="充值前余额"> </el-table-column>
        <el-table-column prop="balance" label="充值后余额"> </el-table-column>
        <el-table-column label="充值时间" width="180px">
          <template slot-scope="scope">
            {{ scope.row.add_time | dateformat }}
          </template>
        </el-table-column>
        <el-table-column prop="order_status" label="充值结果">
          <template slot-scope="scope">
            <el-tag size="mini" v-if="scope.row.order_status === 0">待付款</el-tag>
            <el-tag size="mini" v-else-if="scope.row.order_status === 1">已付款</el-tag>
            <el-tag size="mini" v-else-if="scope.row.order_status === 3">已完成</el-tag>
            <el-tag size="mini" v-else-if="scope.row.order_status === 4">申请退款</el-tag>
            <el-tag size="mini" v-else-if="scope.row.order_status === 5">已退款</el-tag>
            <el-tag size="mini" v-else-if="scope.row.order_status === 6">已取消</el-tag>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination :hide-on-single-page="true" @size-change="handleSizeChange" @current-change="handleCurrentChange" v-bind:current-page="queryParam.pagenum" v-bind:page-sizes="this.pageOption" v-bind:page-size="queryParam.pagesize" layout="total, sizes, prev, pager, next, jumper" v-bind:total="total"> </el-pagination>
    </el-card>
  </div>
</template>
<script>
export default {
  data() {
    return {
      queryParam: {
        queryJson: '',
        pagenum: 1,
        pagesize: this.pageOption[0]
      },
      total: 0,
      chargeList: []
    }
  },
  created() {
    this.getChargeList()
  },
  methods: {
    getChargeList: async function() {
      const { data: res } = await this.$http.get('/Charge/List', { params: this.queryParam })
      if (res.status !== 1) {
        return this.$message.error('获取充值列表失败')
      }
      console.log(res.data)
      this.chargeList = res.data.list
      this.total = res.data.total
    },
    handleSizeChange: function(newSize) {
      this.queryParam.pagesize = newSize
      this.getChargeList()
    },
    handleCurrentChange: function(newPage) {
      this.queryParam.pagenum = newPage
      this.getChargeList()
    }
  }
}
</script>
