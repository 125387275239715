<template>
  <div>
    <el-breadcrumb>
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>系统设置</el-breadcrumb-item>
      <el-breadcrumb-item>分账管理</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <el-row>
        <el-col>
          <el-button type="primary" v-on:click="addDialogeVisible = true" v-has="'sharesetting:add'">添加</el-button>
        </el-col>
      </el-row>
      <el-table :data="transferStrategyList" border stripe>
        <el-table-column type="index" label="#"></el-table-column>
        <!-- <el-table-column prop="company_name" label="所属企业"> </el-table-column> -->
        <el-table-column prop="station_name" label="网点"> </el-table-column>
        <el-table-column prop="period_hour" label="统计周期（小时）"> </el-table-column>
        <el-table-column prop="begin_time" label="开始转账时间"> </el-table-column>
        <el-table-column label="策略类型">
          <template slot-scope="scope">
            <el-tag size="mini" v-if="scope.row.type === 1">默认</el-tag>
            <el-tag size="mini" v-else type="success">特殊</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="180">
          <template slot-scope="scope">
            <el-button type="primary" icon="el-icon-edit" size="mini" v-on:click="showEditDialoge(scope.row.id)" v-has="'sharesetting:add'"></el-button>
            <el-button type="danger" icon="el-icon-delete" size="mini" v-on:click="deleteRow(scope.row.id)" v-has="'sharesetting:remove'"></el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <!-- 添加对话框 -->
    <el-dialog title="添加转账策略" :visible.sync="addDialogeVisible" width="50%" v-on:closed="addDialogeClosed">
      <el-form :model="addForm" :rules="formRules" ref="addFormRef" label-width="120px">
        <el-form-item label="网点" prop="station_id">
          <el-select v-model="addForm.station_id" placeholder="请选择网点">
            <el-option v-for="item in stationList" :key="item.id" :label="item.name" :value="item.id"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="统计周期(小时)" prop="period_hour">
          <el-input v-model="addForm.period_hour"></el-input>
        </el-form-item>
        <el-form-item label="开始转账时间" prop="begin_time">
          <el-input v-model="addForm.begin_time"></el-input>
        </el-form-item>
        <el-form-item label="策略类型" prop="type">
          <el-radio-group v-model="addForm.type">
            <el-radio label="1">默认</el-radio>
            <el-radio label="2">特殊</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogeVisible = false">取 消</el-button>
        <el-button type="primary" @click="addRow">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 修改对话框 -->
    <el-dialog title="修改转账策略" :visible.sync="editDialogeVisible" width="50%" v-on:closed="editDialogeClosed">
      <el-form :model="editForm" :rules="formRules" ref="editFormRef" label-width="120px">
        <el-form-item label="网点" prop="station_id">
          <el-select v-model="editForm.station_id" placeholder="请选择网点">
            <el-option v-for="item in stationList" :key="item.id" :label="item.name" :value="item.id"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="统计周期(小时)" prop="period_hour">
          <el-input v-model="editForm.period_hour" disabled></el-input>
        </el-form-item>
        <el-form-item label="开始转账时间" prop="begin_time">
          <el-input v-model="editForm.begin_time"></el-input>
        </el-form-item>
        <el-form-item label="策略类型" prop="type">
          <el-radio-group v-model="editForm.type">
            <el-radio label="1">默认</el-radio>
            <el-radio label="2">特殊</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogeClosed">取 消</el-button>
        <el-button type="primary" @click="editSave">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      transferStrategyList: [],
      addDialogeVisible: false,
      addForm: {
        period_hour: '',
        begin_time: '',
        type: ''
      },
      formRules: {
        station_id: [{ required: true, message: '请选择网点', trigger: 'blur' }],
        period_hour: [{ required: true, message: '请输入统计周期', trigger: 'blur' }],
        begin_time: [{ required: true, message: '请输入转账开始时间', trigger: 'blur' }],
        type: [{ required: true, message: '请选择策略类型', trigger: 'blur' }]
      },
      editDialogeVisible: false,
      editForm: {},
      stationList: []
    }
  },
  created() {
    this.getTransferStrategyList()
    this.getStationList()
  },
  methods: {
    getTransferStrategyList: async function() {
      const { data: res } = await this.$http.get('/TransferStrategy/List')
      if (res.status !== 1) {
        return this.$message.error('获取列表失败')
      }
      console.log(res.data)
      this.transferStrategyList = res.data
    },
    addDialogeClosed: function() {
      this.$refs.addFormRef.resetFields()
    },
    addRow: function() {
      this.$refs.addFormRef.validate(async valid => {
        if (!valid) return
        const { data: res } = await this.$http.post('/TransferStrategy/Add', this.addForm)
        if (res.status !== 1) return this.$message.error('添加失败，' + res.msg)
        this.$message.success('添加成功')
        this.addDialogeVisible = false
        this.getTransferStrategyList()
      })
    },
    showEditDialoge: async function(id) {
      const { data: res } = await this.$http.get(`/TransferStrategy/One?id=${id}`)
      if (res.status !== 1) return this.$message.error('获取策略信息失败')
      this.editForm = res.data
      this.editDialogeVisible = true
    },
    editDialogeClosed: function() {
      this.$refs.editFormRef.resetFields()
      this.editDialogeVisible = false
    },
    editSave: function() {
      this.$refs.editFormRef.validate(async valid => {
        if (!valid) return
        const { data: res } = await this.$http.put('/TransferStrategy/Edit?id=' + this.editForm.id, { period_hour: this.editForm.period_hour, begin_time: this.editForm.begin_time, type: this.editForm.type, station_id: this.editForm.station_id })
        if (res.status !== 1) {
          return this.$message.error('更新失败')
        }
        this.editDialogeVisible = false
        this.getTransferStrategyList()
        this.$message.success('更新成功')
      })
    },
    deleteRow: async function(id) {
      const confirmResult = await this.$confirm('此操作将永久删除该策略, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(err => err)
      if (confirmResult !== 'confirm') {
        return this.$message('已取消删除')
      }
      const { data: res } = await this.$http.delete('/TransferStrategy/Del?id=' + id)
      if (res.status !== 1) {
        return this.$message.error('删除失败')
      }
      this.getTransferStrategyList()
      this.$message.success('删除成功')
    },
    getStationList: async function() {
      const { data: res } = await this.$http.get('/Partner/Station')
      if (res.status !== 1) return this.$message.error('获取网点信息失败')
      this.stationList = res.data
    }
  }
}
</script>
