export default [
  {
    label: '中国建设银行',
    value: '105100000017',
    children: [
      {
        label: '中国建设银行股份有限公司青岛山东路第二支行',
        value: '105452002056|3702|山东省|青岛市'
      },
      {
        label: '中国建设银行股份有限公司青岛升平路支行',
        value: '105452003090|3702|山东省|青岛市'
      },
      {
        label: '中国建设银行股份有限公司青岛南崂路支行',
        value: '105452003073|3702|山东省|青岛市'
      },
      {
        label: '中国建设银行股份有限公司青岛汉口路支行',
        value: '105452001125|3702|山东省|青岛市'
      }
    ]
  },
  {
    label: '中国工商银行',
    value: '102100099996',
    children: [
      {
        label: '中国工商银行股份有限公司哈密光明北路支行',
        value: '102884000196|6522|新疆维吾尔自治区|哈密地区'
      },
      {
        label: '中国工商银行股份有限公司哈密广东路支行',
        value: '102884000057|6522|新疆维吾尔自治区|哈密地区'
      },
      {
        label: '中国工商银行股份有限公司广州大塘支行',
        value: '102581004050|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州美林支行',
        value: '102581005358|4401|广东省|广州市'
      },
      {
        label: '中国工商银行宣化钟楼支行',
        value: '102138111905|1307|河北省|张家口市'
      },
      {
        label: '中国工商银行股份有限公司长治英华支行',
        value: '102164024129|1402|山西省|大同市'
      },
      {
        label: '中国工商银行包头白云鄂博区支行',
        value: '102204501258|1509|内蒙古自治区|乌兰察布市'
      },
      {
        label: '中国工商银行股份有限公司乾安丹青支行',
        value: '102247924015|2208|吉林省|白城市'
      },
      {
        label: '中国工商银行股份有限公司南通平潮支行',
        value: '102308442545|3206|江苏省|南通市'
      },
      {
        label: '中国工商银行股份有限公司南通城中支行',
        value: '102308442607|3206|江苏省|南通市'
      },
      {
        label: '中国工商银行宿迁市幸福路支行',
        value: '102309003009|3213|江苏省|宿迁市'
      },
      {
        label: '中国工商银行股份有限公司扬州大桥支行',
        value: '102312582058|3212|江苏省|泰州市'
      },
      {
        label: '中国工商银行股份有限公司利辛支行',
        value: '102373200282|3416|安徽省|亳州市'
      },
      {
        label: '中国工商银行武夷山市支行',
        value: '102402278002|3507|福建省|南平市'
      },
      {
        label: '中国工商银行股份有限公司兴化昭阳支行',
        value: '102313151110|3210|江苏省|扬州市'
      },
      {
        label: '中国工商银行莱芜潘西支行',
        value: '102463430404|3712|山东省|莱芜市'
      },
      {
        label: '中国工商银行股份有限公司襄城支行',
        value: '102495502617|4110|河南省|许昌市'
      },
      {
        label: '中国工商银行股份有限公司宜都支行',
        value: '102526107200|4205|湖北省|宜昌市'
      },
      {
        label: '中国工商银行股份有限公司扶绥县支行',
        value: '102612712200|4514|广西壮族自治区|崇左市'
      },
      {
        label: '中国工商银行股份有限公司天等县支行',
        value: '102613111809|4514|广西壮族自治区|崇左市'
      },
      {
        label: '中国工商银行股份有限公司林芝支行',
        value: '102783000015|5426|西藏自治区|林芝地区'
      },
      {
        label: '中国工商银行股份有限公司成都龙工南路支行',
        value: '102651000446|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司营山三星支行',
        value: '102673654233|5113|四川省|南充市'
      },
      {
        label: '中国工商银行股份有限公司阆中张飞大道支行',
        value: '102674354631|5113|四川省|南充市'
      },
      {
        label: '中国工商银行股份有限公司吉林市通潭大路支行',
        value: '102242000221|2202|吉林省|吉林市'
      },
      {
        label: '中国工商银行股份有限公司惠州金田苑支行',
        value: '102595003041|4413|广东省|惠州市'
      },
      {
        label: '中国工商银行股份有限公司惠州南新支行',
        value: '102595003092|4413|广东省|惠州市'
      },
      {
        label: '中国工商银行股份有限公司梅州大新城支行',
        value: '102596003081|4414|广东省|梅州市'
      },
      {
        label: '中国工商银行股份有限公司广州西关支行',
        value: '102581002011|4401|广东省|广州市'
      },
      {
        label: '中国工商银行连云港龙河支行',
        value: '102307002000|3207|江苏省|连云港市'
      },
      {
        label: '中国工商银行股份有限公司武汉经开永旺支行',
        value: '102521004115|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行股份有限公司广东自贸试验区横琴长隆支行',
        value: '102585000037|4404|广东省|珠海市'
      },
      {
        label: '中国工商银行股份有限公司珠海关口支行',
        value: '102585000045|4404|广东省|珠海市'
      },
      {
        label: '中国工商银行股份有限公司潮安新城支行',
        value: '102587202361|4452|广东省|揭阳市'
      },
      {
        label: '中国工商银行股份有限公司佛山南海金都支行',
        value: '102588007583|4406|广东省|佛山市'
      },
      {
        label: '中国工商银行股份有限公司深圳桥头支行',
        value: '102584011572|4403|广东省|深圳市'
      },
      {
        label: '工行陕西省西安雁南四路支行',
        value: '102791011878|6101|陕西省|西安市'
      },
      {
        label: '中国工商银行股份有限公司洛阳王城支行',
        value: '102493002029|4103|河南省|洛阳市'
      },
      {
        label: '中国工商银行股份有限公司洛阳上海市场支行',
        value: '102493022089|4103|河南省|洛阳市'
      },
      {
        label: '中国工商银行股份有限公司太原双塔北路支行',
        value: '102161000806|1401|山西省|太原市'
      },
      {
        label: '中国工商银行股份有限公司安阳东区支行',
        value: '102496032073|4105|河南省|安阳市'
      },
      {
        label: '中国工商银行股份有限公司成都罗家碾支行',
        value: '102651029903|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司内江开发区支行',
        value: '102663000025|5110|四川省|内江市'
      },
      {
        label: '中国工商银行股份有限公司遵义红旗支行',
        value: '102703005067|5203|贵州省|遵义市'
      },
      {
        label: '中国工商银行贵州省安顺分行',
        value: '102711005008|5204|贵州省|安顺市'
      },
      {
        label: '中国工商银行昆明经济信息支行',
        value: '102731010533|5301|云南省|昆明市'
      },
      {
        label: '中国工商银行昆明金桥支行',
        value: '102731011840|5301|云南省|昆明市'
      },
      {
        label: '中国工商银行昆明新亚洲体育城支行',
        value: '102731001166|5301|云南省|昆明市'
      },
      {
        label: '中国工商银行股份有限公司杭州上东城支行',
        value: '102331020494|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行银行股份有限公司杭州天城路支行',
        value: '102331000645|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行股份有限公司杭州东苑支行',
        value: '102331002518|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行宁波大榭支行',
        value: '102332021001|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行股份有限公司温州苍南支行龙跃分理处',
        value: '102333604478|3303|浙江省|温州市'
      },
      {
        label: '中国工商银行股份有限公司温州城西支行中洲分理处',
        value: '102333000333|3303|浙江省|温州市'
      },
      {
        label: '中国工商银行股份有限公司东营广饶支行开发区分理处',
        value: '102455302033|3705|山东省|东营市'
      },
      {
        label: '中国工商银行银行股份有限公司寿光支行城北分理处',
        value: '102458200111|3707|山东省|潍坊市'
      },
      {
        label: '中国工商银行济宁市太白支行',
        value: '102461000684|3708|山东省|济宁市'
      },
      {
        label: '中国工商银行股份有限公司滨州渤海支行黄河三路分理处',
        value: '102466000255|3716|山东省|滨州市'
      },
      {
        label: '中国工商银行股份有限公司忻州城建支行',
        value: '102171004058|1409|山西省|忻州市'
      },
      {
        label: '中国工商银行介休西关支行',
        value: '102176127017|1407|山西省|晋中市'
      },
      {
        label: '中国工商银行股份有限公司晋中分行',
        value: '102175001178|1407|山西省|晋中市'
      },
      {
        label: '中国工商银行股份有限公司福清西门支行',
        value: '102391053436|3501|福建省|福州市'
      },
      {
        label: '中国工商银行股份有限公司南安长安支行',
        value: '102397325162|3505|福建省|泉州市'
      },
      {
        label: '中国工商银行股份有限公司泉州百源支行',
        value: '102397022013|3505|福建省|泉州市'
      },
      {
        label: '中国工商银行股份有限公司漳州芗城支行新华西储蓄所',
        value: '102399032036|3506|福建省|漳州市'
      },
      {
        label: '中国工商银行霞浦县支行',
        value: '102403384007|3509|福建省|宁德市'
      },
      {
        label: '中国工商银行股份有限公司南昌洪都大道支行',
        value: '102421000139|3601|江西省|南昌市'
      },
      {
        label: '中国工商银行股份有限公司萍乡城北支行',
        value: '102423004107|3603|江西省|萍乡市'
      },
      {
        label: '中国工商银行铅山县支行',
        value: '102433512143|3611|江西省|上饶市'
      },
      {
        label: '中国工商银行股份有限公司济南山泉支行',
        value: '102451002153|3701|山东省|济南市'
      },
      {
        label: '中国工商银行股份有限公司青岛南京路贵都支行',
        value: '102452000279|3702|山东省|青岛市'
      },
      {
        label: '中国工商银行股份有限公司枣庄台儿庄支行',
        value: '102454000400|3704|山东省|枣庄市'
      },
      {
        label: '中国工商银行股份有限公司渭南城南支行',
        value: '102797050301|6105|陕西省|渭南市'
      },
      {
        label: '中国工商银行汉中分行业务处理中心',
        value: '102799065005|6107|陕西省|汉中市'
      },
      {
        label: '中国工商银行股份有限公司长沙芙蓉支行',
        value: '102551000123|4301|湖南省|长沙市'
      },
      {
        label: '中国工商银行股份有限公司郑州黄河支行',
        value: '102491002271|4101|河南省|郑州市'
      },
      {
        label: '中国工商银行荥阳市支行',
        value: '102491002888|4101|河南省|郑州市'
      },
      {
        label: '中国工商银行延安市七里铺支行南关分理处',
        value: '102804095029|6106|陕西省|延安市'
      },
      {
        label: '工行陕西省榆林长虹路支行',
        value: '102806015050|6108|陕西省|榆林市'
      },
      {
        label: '中国工商银行股份有限公司兰州汇通支行',
        value: '102821000361|6201|甘肃省|兰州市'
      },
      {
        label: '中国工商银行股份有限公司兰州七里河支行',
        value: '102821000021|6201|甘肃省|兰州市'
      },
      {
        label: '中国工商银行股份有限公司梧州市金湖支行',
        value: '102621034000|4504|广西壮族自治区|梧州市'
      },
      {
        label: '中国工商银行玉林分行',
        value: '102624075005|4509|广西壮族自治区|玉林市'
      },
      {
        label: '中国工商银行股份有限公司巴马县支行',
        value: '102628988005|4512|广西壮族自治区|河池市'
      },
      {
        label: '中国工商银行股份有限公司昌江河南支行',
        value: '102641000434|4601|海南省|海口市'
      },
      {
        label: '中国工商银行沈阳航空支行',
        value: '102221004631|2101|辽宁省|沈阳市'
      },
      {
        label: '中国工商银行股份有限公司白银银光路支行',
        value: '102824000125|6204|甘肃省|白银市'
      },
      {
        label: '中国工商银行股份有限公司天水天水郡支行',
        value: '102825000088|6205|甘肃省|天水市'
      },
      {
        label: '中国工商银行股份有限公司酒泉新城支行',
        value: '102826100267|6209|甘肃省|酒泉市'
      },
      {
        label: '中国工商银行邯郸和平支行',
        value: '102127006131|2103|辽宁省|鞍山市'
      },
      {
        label: '中国工商银行股份有限公司邢台百泉支行',
        value: '102131009169|1305|河北省|邢台市'
      },
      {
        label: '中国工商银行股份有限公司保定西苑支行',
        value: '102134010009|1306|河北省|保定市'
      },
      {
        label: '中国工商银行保定富源支行',
        value: '102134010252|1306|河北省|保定市'
      },
      {
        label: '中国工商银行承德双塔山支行',
        value: '102141013312|1308|河北省|承德市'
      },
      {
        label: '中国工商银行沧州通翔支行',
        value: '102143014419|1309|河北省|沧州市'
      },
      {
        label: '中国工商银行固安支行',
        value: '102146215590|1310|河北省|廊坊市'
      },
      {
        label: '中国工商银行大厂支行',
        value: '102146816326|1310|河北省|廊坊市'
      },
      {
        label: '中国工商银行廊坊解放支行',
        value: '102146015527|1310|河北省|廊坊市'
      },
      {
        label: '中国工商银行股份有限公司廊坊峰尚支行',
        value: '102146016218|1310|河北省|廊坊市'
      },
      {
        label: '中国工商银行股份有限公司蒙阴支行',
        value: '102473900497|3713|山东省|临沂市'
      },
      {
        label: '中国工商银行股份有限公司东莞凤岗雁田支行',
        value: '102602002923|4419|广东省|东莞市'
      },
      {
        label: '中国工商银行股份有限公司丽水商城支行',
        value: '102343020131|3311|浙江省|丽水市'
      },
      {
        label: '中国工商银行铜陵城汇支行',
        value: '102367002415|3407|安徽省|铜陵市'
      },
      {
        label: '中国工商银行南京市钟山支行',
        value: '102301000085|3201|江苏省|南京市'
      },
      {
        label: '中国工商银行股份有限公司常州中山门支行',
        value: '102304003835|3204|江苏省|常州市'
      },
      {
        label: '中国工商银行股份有限公司常州清凉支行',
        value: '102304004813|3204|江苏省|常州市'
      },
      {
        label: '中国工商银行股份有限公司邯郸春风支行',
        value: '102127006088|2103|辽宁省|鞍山市'
      },
      {
        label: '中国工商银行股份有限公司黑河龙源支行',
        value: '102278000016|2311|黑龙江省|黑河市'
      },
      {
        label: '中国工商银行股份有限公司黑河邮政路支行',
        value: '102278000065|2311|黑龙江省|黑河市'
      },
      {
        label: '中国工商银行股份有限公司甘肃矿区分行营业室',
        value: '102822003992|6202|甘肃省|嘉峪关市'
      },
      {
        label: '中国工商银行桦甸市支行',
        value: '102242500015|2202|吉林省|吉林市'
      },
      {
        label: '中国工商银行股份有限公司吉林市松江支行',
        value: '102242000213|2202|吉林省|吉林市'
      },
      {
        label: '中国工商银行吉林市分行驻吉化公司支行',
        value: '102242000424|2202|吉林省|吉林市'
      },
      {
        label: '中国工商银行股份有限公司吉林市分行营业部',
        value: '102242000658|2202|吉林省|吉林市'
      },
      {
        label: '中国工商银行武汉华农支行',
        value: '102521002611|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行股份有限公司武汉武青四干道支行',
        value: '102521003989|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行盘锦平安支行',
        value: '102232000162|2111|辽宁省|盘锦市'
      },
      {
        label: '中国工商银行盘锦市曙光支行',
        value: '102232000322|2111|辽宁省|盘锦市'
      },
      {
        label: '中国工商银行股份有限公司凌源南街支行',
        value: '102234301491|2113|辽宁省|朝阳市'
      },
      {
        label: '中国工商银行朝阳市分行业务处理中心',
        value: '102234000019|2113|辽宁省|朝阳市'
      },
      {
        label: '中国工商银行股份有限公司朝阳凌河支行',
        value: '102234000109|2113|辽宁省|朝阳市'
      },
      {
        label: '中国工商银行长春开发区支行',
        value: '102241000148|2201|吉林省|长春市'
      },
      {
        label: '中国工商银行股份有限公司长春双阳支行',
        value: '102241000210|2201|吉林省|长春市'
      },
      {
        label: '中国工商银行股份有限公司长春长通支行',
        value: '102241000316|2201|吉林省|长春市'
      },
      {
        label: '中国工商银行股份有限公司长春远达大街支行',
        value: '102241000357|2201|吉林省|长春市'
      },
      {
        label: '中国工商银行大连周水子支行',
        value: '102222020123|2102|辽宁省|大连市'
      },
      {
        label: '中国工商银行本溪人民路支行',
        value: '102225000228|2105|辽宁省|本溪市'
      },
      {
        label: '中国工商银行股份有限公司锦州三八支行',
        value: '102227000359|2107|辽宁省|锦州市'
      },
      {
        label: '中国工商银行股份有限公司东海幸福北路支行',
        value: '102307203037|3207|江苏省|连云港市'
      },
      {
        label: '中国工商银行股份有限公司穆棱长征路支行',
        value: '102275300048|2310|黑龙江省|牡丹江市'
      },
      {
        label: '中国工商银行股份有限公司鹤岗二马路支行',
        value: '102267000192|2304|黑龙江省|鹤岗市'
      },
      {
        label: '中国工商银行股份有限公司武汉南湖大道支行',
        value: '102521003044|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行股份有限公司武汉常青花园中路支行',
        value: '102521004131|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行股份有限公司武汉佛祖岭支行',
        value: '102521004140|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行股份有限公司武汉瀚城支行',
        value: '102521004158|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行股份有限公司武汉北华街支行',
        value: '102521004166|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行股份有限公司阳新广场支行',
        value: '102536700013|4212|湖北省|咸宁市'
      },
      {
        label: '中国工商银行股份有限公司三亚崖城支行',
        value: '102642000350|4602|海南省|三亚市'
      },
      {
        label: '中国工商银行股份有限公司苏州学府支行',
        value: '102305018164|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行股份有限公司银兴支行',
        value: '102428010251|3607|江西省|赣州市'
      },
      {
        label: '中国工商银行股份有限公司龙南金水支行',
        value: '102428910558|3607|江西省|赣州市'
      },
      {
        label: '中国工商银行股份有限公司东宁新华支行',
        value: '102275404347|2310|黑龙江省|牡丹江市'
      },
      {
        label: '中国工商银行股份有限公司杭州西溪紫霞街支行',
        value: '102331018862|3301|浙江省|杭州市'
      },
      {
        label: '工商银行宁波江北支行宁波大学储蓄所',
        value: '102332013157|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行股份有限公司慈溪胜山支行',
        value: '102332530243|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行股份有限公司慈溪宗汉支行',
        value: '102332530251|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行股份有限公司深圳生态园支行',
        value: '102584013496|4403|广东省|深圳市'
      },
      {
        label: '工商银行克州分行（不对外办理业务）',
        value: '102893000090|6530|新疆维吾尔自治区|克孜勒苏柯尔克孜自治州'
      },
      {
        label: '中国工商银行股份有限公司泰州南门支行',
        value: '102312802046|3212|江苏省|泰州市'
      },
      {
        label: '中国工商银行泰州城西支行',
        value: '102312802054|3212|江苏省|泰州市'
      },
      {
        label: '中国工商银行股份有限公司泰州新区支行',
        value: '102312802095|3212|江苏省|泰州市'
      },
      {
        label: '中国工商银行泰州市分行会计业务处理中心',
        value: '102312802118|3212|江苏省|泰州市'
      },
      {
        label: '中国工商银行股份有限公司泰州高港支行',
        value: '102312802409|3212|江苏省|泰州市'
      },
      {
        label: '中国工商银行股份有限公司泰州口岸支行',
        value: '102312802417|3212|江苏省|泰州市'
      },
      {
        label: '中国工商银行股份有限公司泰州高港园区支行',
        value: '102312802433|3212|江苏省|泰州市'
      },
      {
        label: '中国工商银行股份有限公司泰州扬子江支行',
        value: '102312802601|3212|江苏省|泰州市'
      },
      {
        label: '中国工商银行股份有限公司泰州凤凰支行',
        value: '102312802861|3212|江苏省|泰州市'
      },
      {
        label: '中国工商银行股份有限公司泰州锦绣支行',
        value: '102312802888|3212|江苏省|泰州市'
      },
      {
        label: '中国工商银行泰州市海陵支行',
        value: '102312806029|3212|江苏省|泰州市'
      },
      {
        label: '中国工商银行股份有限公司杭州科创支行',
        value: '102331022094|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行股份有限公司杭州崇贤支行',
        value: '102331022385|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行股份有限公司杭州转塘支行',
        value: '102331022432|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行股份有限公司杭州白马湖支行',
        value: '102331022481|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行股份有限公司临安青山湖科技城支行',
        value: '102331022596|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行股份有限公司杭州闻堰支行',
        value: '102331022631|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行股份有限公司杭州留下支行',
        value: '102331022658|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行股份有限公司阜新银鹰支行',
        value: '102229009909|2109|辽宁省|阜新市'
      },
      {
        label: '中国工商银行阜新蒙古族自治县支行',
        value: '102229100076|2109|辽宁省|阜新市'
      },
      {
        label: '中国工商银行彰武县支行',
        value: '102229200807|2109|辽宁省|阜新市'
      },
      {
        label: '中国工商银行辽阳市分行业务处理中心',
        value: '102231000013|2110|辽宁省|辽阳市'
      },
      {
        label: '中国工商银行股份有限公司辽阳融兴支行',
        value: '102231000021|2110|辽宁省|辽阳市'
      },
      {
        label: '中国工商银行股份有限公司辽阳站前支行',
        value: '102231000030|2110|辽宁省|辽阳市'
      },
      {
        label: '中国工商银行股份有限公司辽阳辽化支行',
        value: '102231000048|2110|辽宁省|辽阳市'
      },
      {
        label: '中国工商银行股份有限公司辽阳东京陵支行',
        value: '102231000056|2110|辽宁省|辽阳市'
      },
      {
        label: '中国工商银行股份有限公司辽阳弓长岭支行',
        value: '102231000064|2110|辽宁省|辽阳市'
      },
      {
        label: '中国工商银行股份有限公司辽阳新运支行',
        value: '102231000089|2110|辽宁省|辽阳市'
      },
      {
        label: '中国工商银行股份有限公司辽阳武圣支行',
        value: '102231000097|2110|辽宁省|辽阳市'
      },
      {
        label: '中国工商银行股份有限公司辽阳二道街支行',
        value: '102231000101|2110|辽宁省|辽阳市'
      },
      {
        label: '中国工商银行股份有限公司辽阳文圣路支行',
        value: '102231000110|2110|辽宁省|辽阳市'
      },
      {
        label: '中国工商银行股份有限公司辽阳泰兴支行',
        value: '102231000144|2110|辽宁省|辽阳市'
      },
      {
        label: '中国工商银行股份有限公司辽阳中心路支行',
        value: '102231000169|2110|辽宁省|辽阳市'
      },
      {
        label: '中国工商银行股份有限公司辽阳辽通支行',
        value: '102231000185|2110|辽宁省|辽阳市'
      },
      {
        label: '中国工商银行股份有限公司辽阳青年大街支行',
        value: '102231000257|2110|辽宁省|辽阳市'
      },
      {
        label: '中国工商银行股份有限公司辽阳民主路支行',
        value: '102231000265|2110|辽宁省|辽阳市'
      },
      {
        label: '中国工商银行股份有限公司辽阳卫国路支行',
        value: '102231000337|2110|辽宁省|辽阳市'
      },
      {
        label: '中国工商银行股份有限公司辽阳广场支行',
        value: '102231000370|2110|辽宁省|辽阳市'
      },
      {
        label: '中国工商银行股份有限公司辽阳黎明支行',
        value: '102231000415|2110|辽宁省|辽阳市'
      },
      {
        label: '中国工商银行股份有限公司辽阳工农街支行',
        value: '102231000423|2110|辽宁省|辽阳市'
      },
      {
        label: '中国工商银行股份有限公司辽阳纤城支行',
        value: '102231000431|2110|辽宁省|辽阳市'
      },
      {
        label: '中国工商银行股份有限公司辽阳安南支行',
        value: '102231000482|2110|辽宁省|辽阳市'
      },
      {
        label: '中国工商银行股份有限公司辽阳苏家支行',
        value: '102231000511|2110|辽宁省|辽阳市'
      },
      {
        label: '中国工商银行股份有限公司辽阳县支行',
        value: '102231100581|2110|辽宁省|辽阳市'
      },
      {
        label: '中国工商银行股份有限公司辽阳首山支行',
        value: '102231100590|2110|辽宁省|辽阳市'
      },
      {
        label: '中国工商银行股份有限公司灯塔支行',
        value: '102231200074|2110|辽宁省|辽阳市'
      },
      {
        label: '中国工商银行股份有限公司大同牡丹支行',
        value: '102162004688|1402|山西省|大同市'
      },
      {
        label: '中国工商银行股份有限公司大同向阳街支行',
        value: '102162004696|1402|山西省|大同市'
      },
      {
        label: '中国工商银行股份有限公司大同云中路支行',
        value: '102162004967|1402|山西省|大同市'
      },
      {
        label: '工行阳泉天桥支行',
        value: '102163000422|1402|山西省|大同市'
      },
      {
        label: '工行阳泉南大街支行',
        value: '102163000447|1402|山西省|大同市'
      },
      {
        label: '中国工商银行股份有限公司阳泉分行',
        value: '102163000463|1402|山西省|大同市'
      },
      {
        label: '工行阳泉新市街支行',
        value: '102163000480|1402|山西省|大同市'
      },
      {
        label: '工行阳泉北大街支行',
        value: '102163000498|1402|山西省|大同市'
      },
      {
        label: '工行阳泉德胜街支行',
        value: '102163000527|1402|山西省|大同市'
      },
      {
        label: '工行阳泉石卜嘴支行',
        value: '102163000535|1402|山西省|大同市'
      },
      {
        label: '工行阳泉南山路支行',
        value: '102163000543|1402|山西省|大同市'
      },
      {
        label: '工行阳泉郊区支行',
        value: '102163000551|1402|山西省|大同市'
      },
      {
        label: '工行赛鱼分理处',
        value: '102163000586|1402|山西省|大同市'
      },
      {
        label: '中国工商银行阳泉桃河支行',
        value: '102163000885|1402|山西省|大同市'
      },
      {
        label: '工行阳泉矿务局支行',
        value: '102163023119|1402|山西省|大同市'
      },
      {
        label: '中国工商银行股份有限公司阳高支行',
        value: '102163104566|1402|山西省|大同市'
      },
      {
        label: '中国工商银行股份有限公司广灵支行',
        value: '102163304709|1402|山西省|大同市'
      },
      {
        label: '中国工商银行股份有限公司灵丘支行',
        value: '102163404876|1402|山西省|大同市'
      },
      {
        label: '中国工商银行股份有限公司浑源支行',
        value: '102163504729|1402|山西省|大同市'
      },
      {
        label: '中国工商银行朔州应县支行',
        value: '102163604853|1402|山西省|大同市'
      },
      {
        label: '中国工商银行定兴支行',
        value: '102135609718|1306|河北省|保定市'
      },
      {
        label: '中国工商银行股份有限公司定兴东城支行',
        value: '102135610873|1306|河北省|保定市'
      },
      {
        label: '中国工商银行股份有限公司敖汉旗南街支行',
        value: '102194900049|1504|内蒙古自治区|赤峰市'
      },
      {
        label: '中国工商银行股份有限公司齐齐哈尔东市场储蓄所',
        value: '102264000224|2302|黑龙江省|齐齐哈尔市'
      },
      {
        label: '中国工商银行股份有限公司齐齐哈尔北大街储蓄所',
        value: '102264000249|2302|黑龙江省|齐齐哈尔市'
      },
      {
        label: '中国工商银行股份有限公司齐齐哈尔铁北所',
        value: '102264000538|2302|黑龙江省|齐齐哈尔市'
      },
      {
        label: '中国工商银行股份有限公司齐齐哈尔中华路分理处',
        value: '102264000546|2302|黑龙江省|齐齐哈尔市'
      },
      {
        label: '中国工商银行股份有限公司齐齐哈尔民乐储蓄所',
        value: '102264000554|2302|黑龙江省|齐齐哈尔市'
      },
      {
        label: '中国工商银行股份有限公司忻州九原支行',
        value: '102171004584|1409|山西省|忻州市'
      },
      {
        label: '中国工商银行股份有限公司忻州和平街支行',
        value: '102171026268|1409|山西省|忻州市'
      },
      {
        label: '中国工商银行股份有限公司忻州忻府支行',
        value: '102171026276|1409|山西省|忻州市'
      },
      {
        label: '中国工商银行股份有限公司定襄支行',
        value: '102171204165|1409|山西省|忻州市'
      },
      {
        label: '中国工商银行股份有限公司五台山支行',
        value: '102171302367|1409|山西省|忻州市'
      },
      {
        label: '中国工商银行股份有限公司五台支行',
        value: '102171304034|1409|山西省|忻州市'
      },
      {
        label: '中国工商银行股份有限公司五台东冶支行',
        value: '102171304042|1409|山西省|忻州市'
      },
      {
        label: '中国工商银行股份有限公司原平支行',
        value: '102171404425|1409|山西省|忻州市'
      },
      {
        label: '中国工商银行股份有限公司原平轩岗支行',
        value: '102171404433|1409|山西省|忻州市'
      },
      {
        label: '中国工商银行股份有限公司原平平安街支行',
        value: '102171404468|1409|山西省|忻州市'
      },
      {
        label: '中国工商银行股份有限公司代县支行',
        value: '102171504060|1409|山西省|忻州市'
      },
      {
        label: '中国工商银行股份有限公司代县峨口支行',
        value: '102171504078|1409|山西省|忻州市'
      },
      {
        label: '中国工商银行股份有限公司繁峙支行',
        value: '102171626042|1409|山西省|忻州市'
      },
      {
        label: '中国工商银行股份有限公司宁武支行',
        value: '102171704199|1409|山西省|忻州市'
      },
      {
        label: '中国工商银行股份有限公司五寨支行',
        value: '102172104260|1409|山西省|忻州市'
      },
      {
        label: '中国工商银行股份有限公司岢岚支行',
        value: '102172204343|1409|山西省|忻州市'
      },
      {
        label: '中国工商银行股份有限公司岢岚神堂坪支行',
        value: '102172204351|1409|山西省|忻州市'
      },
      {
        label: '中国工商银行股份有限公司河曲支行',
        value: '102172304289|1409|山西省|忻州市'
      },
      {
        label: '中国工商银行股份有限公司保德支行',
        value: '102172404313|1409|山西省|忻州市'
      },
      {
        label: '中国工商银行股份有限公司吕梁滨西支行',
        value: '102173000091|1411|山西省|吕梁市'
      },
      {
        label: '中国工商银行股份有限公司吕梁分行',
        value: '102173001682|1411|山西省|吕梁市'
      },
      {
        label: '中国工商银行股份有限公司吕梁石州支行',
        value: '102173002101|1411|山西省|吕梁市'
      },
      {
        label: '中国工商银行股份有限公司明水支行',
        value: '102277200016|2312|黑龙江省|绥化市'
      },
      {
        label: '中国工商银行股份有限公司绥棱支行',
        value: '102277300017|2312|黑龙江省|绥化市'
      },
      {
        label: '中国工商银行股份有限公司绥棱林区支行',
        value: '102277300025|2312|黑龙江省|绥化市'
      },
      {
        label: '中国工商银行黑河市站前支行',
        value: '102278002039|2311|黑龙江省|黑河市'
      },
      {
        label: '中国工商银行黑河市兴安支行',
        value: '102278002055|2311|黑龙江省|黑河市'
      },
      {
        label: '中国工商银行黑河市中央街支行',
        value: '102278002372|2311|黑龙江省|黑河市'
      },
      {
        label: '中国工商银行股份有限公司黑河兴林街分理处',
        value: '102278005756|2311|黑龙江省|黑河市'
      },
      {
        label: '中国工商银行黑河分行业务处理中心',
        value: '102278009005|2311|黑龙江省|黑河市'
      },
      {
        label: '中国工商银行股份有限公司北安市支行',
        value: '102278200018|2311|黑龙江省|黑河市'
      },
      {
        label: '中国工商银行股份有限公司北安市北岗分理处',
        value: '102278200026|2311|黑龙江省|黑河市'
      },
      {
        label: '中国工商银行股份有限公司通北分理处',
        value: '102278200034|2311|黑龙江省|黑河市'
      },
      {
        label: '中国工商银行股份有限公司五大连池市支行',
        value: '102278300010|2311|黑龙江省|黑河市'
      },
      {
        label: '中国工商银行股份有限公司五大连池镇分理处',
        value: '102278300052|2311|黑龙江省|黑河市'
      },
      {
        label: '中国工商银行股份有限公司五大连池沾河分理处',
        value: '102278300069|2311|黑龙江省|黑河市'
      },
      {
        label: '中国工商银行股份有限公司龙镇分理处',
        value: '102278300077|2311|黑龙江省|黑河市'
      },
      {
        label: '中国工商银行股份有限公司嫩江县支行',
        value: '102278400019|2311|黑龙江省|黑河市'
      },
      {
        label: '中国工商银行股份有限公司嫩江九三支行',
        value: '102278400078|2311|黑龙江省|黑河市'
      },
      {
        label: '中国工商银行股份有限公司逊克县支行',
        value: '102278600012|2311|黑龙江省|黑河市'
      },
      {
        label: '中国工商银行股份有限公司孙吴县支行',
        value: '102278700013|2311|黑龙江省|黑河市'
      },
      {
        label: '中国工商银行大兴安岭清算中心',
        value: '102279000018|2327|黑龙江省|大兴安岭地区'
      },
      {
        label: '中国工商银行大兴安岭加格达奇支行',
        value: '102279000026|2327|黑龙江省|大兴安岭地区'
      },
      {
        label: '中国工商银行大兴安岭松岭支行',
        value: '102279000034|2327|黑龙江省|大兴安岭地区'
      },
      {
        label: '中国工商银行大兴安岭新林支行',
        value: '102279000042|2327|黑龙江省|大兴安岭地区'
      },
      {
        label: '中国工商银行大兴安岭呼中支行',
        value: '102279000059|2327|黑龙江省|大兴安岭地区'
      },
      {
        label: '中国工商银行股份有限公司加格达奇加铁分理处',
        value: '102279000122|2327|黑龙江省|大兴安岭地区'
      },
      {
        label: '中国工商银行大兴安岭光辉分理处',
        value: '102279000139|2327|黑龙江省|大兴安岭地区'
      },
      {
        label: '中国工商银行股份有限公司加格达奇人民路分理处',
        value: '102279000147|2327|黑龙江省|大兴安岭地区'
      },
      {
        label: '中国工商银行股份有限公司杭州新农都支行',
        value: '102331025222|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行股份有限公司杭州乔司支行',
        value: '102331025360|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行股份有限公司通化泓源支行',
        value: '102245024286|2205|吉林省|通化市'
      },
      {
        label: '中国工商银行股份有限公司嫩江墨尔根支行',
        value: '102278400027|2311|黑龙江省|黑河市'
      },
      {
        label: '中国工商银行股份有限公司嫩江嫩兴路支行',
        value: '102278400060|2311|黑龙江省|黑河市'
      },
      {
        label: '中国工商银行股份有限公司杭州良渚物流中心支行',
        value: '102331020419|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行靖宇支行',
        value: '102246200184|2206|吉林省|白山市'
      },
      {
        label: '中国工商银行长白朝鲜族自治县支行',
        value: '102246300239|2206|吉林省|白山市'
      },
      {
        label: '中国工商银行股份有限公司塔甸路支行',
        value: '102246300247|2206|吉林省|白山市'
      },
      {
        label: '中国工商银行江源支行',
        value: '102246400383|2206|吉林省|白山市'
      },
      {
        label: '中国工商银行江源孙家堡子支行',
        value: '102246400447|2206|吉林省|白山市'
      },
      {
        label: '中国工商银行江源湾沟支行',
        value: '102246400463|2206|吉林省|白山市'
      },
      {
        label: '中国工商银行白城市分行',
        value: '102247000010|2208|吉林省|白城市'
      },
      {
        label: '中国工商银行股份有限公司徐州泰山支行',
        value: '102303003186|3203|江苏省|徐州市'
      },
      {
        label: '中国工商银行徐州西安北路支行',
        value: '102303003217|3203|江苏省|徐州市'
      },
      {
        label: '中国工商银行徐州市祥和支行',
        value: '102303003241|3203|江苏省|徐州市'
      },
      {
        label: '中国工商银行徐州白云山支行',
        value: '102303003276|3203|江苏省|徐州市'
      },
      {
        label: '中国工商银行徐州铜山路支行',
        value: '102303003284|3203|江苏省|徐州市'
      },
      {
        label: '中国工商银行股份有限公司徐州环城路支行',
        value: '102303003292|3203|江苏省|徐州市'
      },
      {
        label: '中国工商银行股份有限公司徐州黄山新村支行',
        value: '102303003330|3203|江苏省|徐州市'
      },
      {
        label: '中国工商银行股份有限公司徐州东甸子支行',
        value: '102303003356|3203|江苏省|徐州市'
      },
      {
        label: '中国工商银行股份有限公司徐州复兴北路支行',
        value: '102303003364|3203|江苏省|徐州市'
      },
      {
        label: '中国工商银行徐州绿地世纪城支行',
        value: '102303003389|3203|江苏省|徐州市'
      },
      {
        label: '中国工商银行股份有限公司徐州金庭支行',
        value: '102303003397|3203|江苏省|徐州市'
      },
      {
        label: '中国工商银行徐州滨湖花园支行',
        value: '102303003410|3203|江苏省|徐州市'
      },
      {
        label: '中国工商银行兴化安丰支行',
        value: '102313151056|3210|江苏省|扬州市'
      },
      {
        label: '中国工商银行股份有限公司兴化大桥支行',
        value: '102313151064|3210|江苏省|扬州市'
      },
      {
        label: '中国工商银行股份有限公司兴化戴南支行',
        value: '102313151089|3210|江苏省|扬州市'
      },
      {
        label: '中国工商银行股份有限公司兴化新区支行',
        value: '102313151128|3210|江苏省|扬州市'
      },
      {
        label: '中国工商银行股份有限公司兴化陈堡支行',
        value: '102313151136|3210|江苏省|扬州市'
      },
      {
        label: '中国工商银行股份有限公司姜堰支行',
        value: '102313272013|3212|江苏省|泰州市'
      },
      {
        label: '中国工商银行股份有限公司杭州体东支行',
        value: '102331000766|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行股份有限公司杭州杭大路支行',
        value: '102331000803|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行股份有限公司杭州红会支行',
        value: '102331000846|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行股份有限公司杭州万家星城支行',
        value: '102331000854|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行股份有限公司杭州北景园支行',
        value: '102331000862|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行股份有限公司睢宁八里支行',
        value: '102303408062|3203|江苏省|徐州市'
      },
      {
        label: '中国工商银行股份有限公司如皋磨头支行',
        value: '102306200070|3206|江苏省|南通市'
      },
      {
        label: '中国工商银行股份有限公司如皋皋东支行',
        value: '102306200088|3206|江苏省|南通市'
      },
      {
        label: '中国工商银行股份有限公司如皋经济开发区支行',
        value: '102306200096|3206|江苏省|南通市'
      },
      {
        label: '中国工商银行如皋市城中支行',
        value: '102306200123|3206|江苏省|南通市'
      },
      {
        label: '中国工商银行如皋北大街分理处',
        value: '102306200131|3206|江苏省|南通市'
      },
      {
        label: '中国工商银行股份有限公司如皋长江支行',
        value: '102306200158|3206|江苏省|南通市'
      },
      {
        label: '中国工商银行股份有限公司如皋九华支行',
        value: '102306200166|3206|江苏省|南通市'
      },
      {
        label: '中国工商银行如东县支行',
        value: '102306300013|3206|江苏省|南通市'
      },
      {
        label: '中国工商银行如东人民路支行',
        value: '102306300021|3206|江苏省|南通市'
      },
      {
        label: '中国工商银行如东马塘支行',
        value: '102306300030|3206|江苏省|南通市'
      },
      {
        label: '中国工商银行如东岔河支行',
        value: '102306300048|3206|江苏省|南通市'
      },
      {
        label: '中国工商银行股份有限公司如东栟茶支行',
        value: '102306300064|3206|江苏省|南通市'
      },
      {
        label: '中国工商银行如东丰利支行',
        value: '102306300072|3206|江苏省|南通市'
      },
      {
        label: '中国工商银行股份有限公司如东日晖支行',
        value: '102306300089|3206|江苏省|南通市'
      },
      {
        label: '中国工商银行如东县掘港镇滨山分理处',
        value: '102306300097|3206|江苏省|南通市'
      },
      {
        label: '中国工商银行股份有限公司如东华荣支行',
        value: '102306300110|3206|江苏省|南通市'
      },
      {
        label: '中国工商银行股份有限公司如东双甸支行',
        value: '102306301129|3206|江苏省|南通市'
      },
      {
        label: '中国工商银行股份有限公司如东洋口支行',
        value: '102306312116|3206|江苏省|南通市'
      },
      {
        label: '中国工商银行海门市包场分理处',
        value: '102306552026|3206|江苏省|南通市'
      },
      {
        label: '中国工商银行股份有限公司海门三和支行',
        value: '102306552075|3206|江苏省|南通市'
      },
      {
        label: '中国工商银行股份有限公司杭州二桥支行',
        value: '102331002526|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行股份有限公司杭州和平支行',
        value: '102331002534|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行股份有限公司杭州金城路支行',
        value: '102331002542|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行股份有限公司杭州洄澜支行',
        value: '102331002559|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行股份有限公司杭州文新路支行',
        value: '102331002583|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行杭州市莫干山路支行',
        value: '102331002614|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行股份有限公司杭州高新支行',
        value: '102331002622|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行股份有限公司杭州南阳支行',
        value: '102331002680|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行股份有限公司行杭州育才路支行',
        value: '102331002698|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行股份有限公司杭州金浦支行',
        value: '102331002702|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行股份有限公司杭州大学路支行',
        value: '102331002735|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行杭州九莲支行',
        value: '102331002743|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行杭州市城站支行',
        value: '102331002778|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行股份有限公司杭州南肖埠支行',
        value: '102331002786|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行股份有限公司杭州华星路支行',
        value: '102331002794|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行建德市支行',
        value: '102331002817|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行建德市支行梅城分理处',
        value: '102331002825|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行建德市支行寿昌分理处',
        value: '102331002833|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行淳安县支行',
        value: '102331002913|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行股份有限公司桐庐开发区支行',
        value: '102331004816|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行桐庐县支行迎南分理处',
        value: '102331004832|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行股份有限公司杭州通惠支行',
        value: '102331004849|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行股份有限公司杭州空港城支行',
        value: '102331005026|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行股份有限公司永吉吉桦路支行',
        value: '102242100020|2202|吉林省|吉林市'
      },
      {
        label: '中国工商银行临江支行',
        value: '102242200166|2202|吉林省|吉林市'
      },
      {
        label: '中国工商银行磐石市支行',
        value: '102242300013|2202|吉林省|吉林市'
      },
      {
        label: '中国工商银行股份有限公司磐石阜康大路支行',
        value: '102242300030|2202|吉林省|吉林市'
      },
      {
        label: '中国工商银行股份有限公司磐石红旗支行',
        value: '102242300048|2202|吉林省|吉林市'
      },
      {
        label: '中国工商银行磐石市支行河南分理处',
        value: '102242300064|2202|吉林省|吉林市'
      },
      {
        label: '中国工商银行股份有限公司蛟河支行',
        value: '102242400014|2202|吉林省|吉林市'
      },
      {
        label: '中国工商银行股份有限公司蛟河白石山分理处',
        value: '102242400022|2202|吉林省|吉林市'
      },
      {
        label: '中国工商银行股份有限公司蛟河新华支行',
        value: '102242400047|2202|吉林省|吉林市'
      },
      {
        label: '中国工商银行股份有限公司蛟河新区支行',
        value: '102242400071|2202|吉林省|吉林市'
      },
      {
        label: '中国工商银行桦甸市支行白山电厂分理处',
        value: '102242500023|2202|吉林省|吉林市'
      },
      {
        label: '中国工商银行桦甸人民路支行',
        value: '102242500031|2202|吉林省|吉林市'
      },
      {
        label: '中国工商银行舒兰市支行',
        value: '102242600016|2202|吉林省|吉林市'
      },
      {
        label: '中国工商银行股份有限公司舒兰吉舒支行',
        value: '102242600065|2202|吉林省|吉林市'
      },
      {
        label: '中国工商银行股份有限公司四平分行',
        value: '102243022011|2203|吉林省|四平市'
      },
      {
        label: '中国工商银行股份有限公司四平中央东路支行',
        value: '102243022020|2203|吉林省|四平市'
      },
      {
        label: '中国工商银行股份有限公司四平站前支行',
        value: '102243022038|2203|吉林省|四平市'
      },
      {
        label: '中国工商银行股份有限公司四平平东支行',
        value: '102243022046|2203|吉林省|四平市'
      },
      {
        label: '中国工商银行股份有限公司四平中央西路支行',
        value: '102243022054|2203|吉林省|四平市'
      },
      {
        label: '中国工商银行股份有限公司四平鼎晟支行',
        value: '102243022087|2203|吉林省|四平市'
      },
      {
        label: '中国工商银行辽源矿电支行',
        value: '102244022043|2204|吉林省|辽源市'
      },
      {
        label: '中国工商银行辽源世纪广场支行',
        value: '102244022051|2204|吉林省|辽源市'
      },
      {
        label: '中国工商银行沈阳市南站支行',
        value: '102221000204|2101|辽宁省|沈阳市'
      },
      {
        label: '中国工商银行沈阳青年大街支行',
        value: '102221000212|2101|辽宁省|沈阳市'
      },
      {
        label: '中国工商银行沈阳铁路支行',
        value: '102221000229|2101|辽宁省|沈阳市'
      },
      {
        label: '中国工商银行沈阳总站路支行',
        value: '102221000237|2101|辽宁省|沈阳市'
      },
      {
        label: '中国工商银行沈阳浑南支行',
        value: '102221000253|2101|辽宁省|沈阳市'
      },
      {
        label: '中国工商银行沈阳市南京街支行',
        value: '102221000288|2101|辽宁省|沈阳市'
      },
      {
        label: '中国工商银行沈阳南市支行',
        value: '102221000307|2101|辽宁省|沈阳市'
      },
      {
        label: '中国工商银行沈阳市南五马路支行',
        value: '102221000315|2101|辽宁省|沈阳市'
      },
      {
        label: '中国工商银行沈阳市中华路分理处',
        value: '102221000323|2101|辽宁省|沈阳市'
      },
      {
        label: '中国工商银行沈阳市北市支行',
        value: '102221000331|2101|辽宁省|沈阳市'
      },
      {
        label: '中国工商银行沈阳市中山广场支行',
        value: '102221000340|2101|辽宁省|沈阳市'
      },
      {
        label: '中国工商银行沈阳文化路支行',
        value: '102221000358|2101|辽宁省|沈阳市'
      },
      {
        label: '中国工商银行沈阳新华支行',
        value: '102221000366|2101|辽宁省|沈阳市'
      },
      {
        label: '中国工商银行沈阳市和平支行',
        value: '102221000374|2101|辽宁省|沈阳市'
      },
      {
        label: '中国工商银行沈阳市和平支行东电分理处',
        value: '102221000382|2101|辽宁省|沈阳市'
      },
      {
        label: '中国工商银行沈阳八一支行',
        value: '102221000399|2101|辽宁省|沈阳市'
      },
      {
        label: '中国工商银行沈阳市融信支行',
        value: '102221000403|2101|辽宁省|沈阳市'
      },
      {
        label: '中国工商银行沈阳市铁西支行',
        value: '102221000500|2101|辽宁省|沈阳市'
      },
      {
        label: '中国工商银行沈阳市兴顺支行',
        value: '102221000526|2101|辽宁省|沈阳市'
      },
      {
        label: '中国工商银行沈阳九路支行',
        value: '102221000534|2101|辽宁省|沈阳市'
      },
      {
        label: '中国工商银行沈阳建设大路支行',
        value: '102221000542|2101|辽宁省|沈阳市'
      },
      {
        label: '中国工商银行沈阳市沈阳经济技术开发区支行',
        value: '102221000567|2101|辽宁省|沈阳市'
      },
      {
        label: '中国工商银行沈阳三好支行',
        value: '102221000614|2101|辽宁省|沈阳市'
      },
      {
        label: '中国工商银行康平支行',
        value: '102221000655|2101|辽宁省|沈阳市'
      },
      {
        label: '中国工商银行法库支行',
        value: '102221000663|2101|辽宁省|沈阳市'
      },
      {
        label: '中国工商银行沈阳市新民市支行兴隆堡分理处',
        value: '102221000671|2101|辽宁省|沈阳市'
      },
      {
        label: '中国工商银行沈阳市辽中县支行茨榆坨分理处',
        value: '102221000680|2101|辽宁省|沈阳市'
      },
      {
        label: '中国工商银行沈阳市沈河支行',
        value: '102221000702|2101|辽宁省|沈阳市'
      },
      {
        label: '中国工商银行沈阳大西支行',
        value: '102221000719|2101|辽宁省|沈阳市'
      },
      {
        label: '中国工商银行沈阳小西支行',
        value: '102221000727|2101|辽宁省|沈阳市'
      },
      {
        label: '中国工商银行沈阳大南支行',
        value: '102221000735|2101|辽宁省|沈阳市'
      },
      {
        label: '中国工商银行沈阳南塔支行',
        value: '102221000743|2101|辽宁省|沈阳市'
      },
      {
        label: '中国工商银行沈阳南顺城支行',
        value: '102221000751|2101|辽宁省|沈阳市'
      },
      {
        label: '中国工商银行股份有限公司鄂尔多斯白云路支行',
        value: '102205300045|1506|内蒙古自治区|鄂尔多斯市'
      },
      {
        label: '中国工商银行股份有限公司鄂尔多斯河滨路支行',
        value: '102205300053|1506|内蒙古自治区|鄂尔多斯市'
      },
      {
        label: '中国工商银行股份有限公司鄂尔多斯蓝天路支行',
        value: '102205300061|1506|内蒙古自治区|鄂尔多斯市'
      },
      {
        label: '中国工商银行股份有限公司准格尔三马路支行',
        value: '102205300088|1506|内蒙古自治区|鄂尔多斯市'
      },
      {
        label: '中国工商银行准格尔煤田矿区支行',
        value: '102205305303|1506|内蒙古自治区|鄂尔多斯市'
      },
      {
        label: '中国工商银行准格尔旗支行',
        value: '102205308033|1506|内蒙古自治区|鄂尔多斯市'
      },
      {
        label: '中国工商银行股份有限公司鄂托克乌仁都西支行',
        value: '102205500022|1506|内蒙古自治区|鄂尔多斯市'
      },
      {
        label: '中国工商银行鄂托克旗支行棋盘井分理处',
        value: '102205500063|1506|内蒙古自治区|鄂尔多斯市'
      },
      {
        label: '中国工商银行股份有限公司鄂托克乌珠尔支行',
        value: '102205500071|1506|内蒙古自治区|鄂尔多斯市'
      },
      {
        label: '中国工商银行鄂托克旗支行',
        value: '102205508073|1506|内蒙古自治区|鄂尔多斯市'
      },
      {
        label: '中国工商银行股份有限公司杭锦锦元支行',
        value: '102205600023|1506|内蒙古自治区|鄂尔多斯市'
      },
      {
        label: '中国工商银行杭锦旗支行',
        value: '102205608052|1506|内蒙古自治区|鄂尔多斯市'
      },
      {
        label: '中国工商银行股份有限公司乌审萨拉乌素支行',
        value: '102205700049|1506|内蒙古自治区|鄂尔多斯市'
      },
      {
        label: '中国工商银行乌审旗支行',
        value: '102205708094|1506|内蒙古自治区|鄂尔多斯市'
      },
      {
        label: '中国工商银行股份有限公司伊金霍洛鑫荣支行',
        value: '102205800025|1506|内蒙古自治区|鄂尔多斯市'
      },
      {
        label: '中国工商银行股份有限公司乌海矿业小区支行',
        value: '102193000402|1503|内蒙古自治区|乌海市'
      },
      {
        label: '中国工商银行乌海分行海南支行',
        value: '102193003193|1503|内蒙古自治区|乌海市'
      },
      {
        label: '中国工商银行乌海分行乌达支行',
        value: '102193004354|1503|内蒙古自治区|乌海市'
      },
      {
        label: '中国工商银行乌海市海勃湾支行',
        value: '102193004400|1503|内蒙古自治区|乌海市'
      },
      {
        label: '中国工商银行乌海车站支行',
        value: '102193005425|1503|内蒙古自治区|乌海市'
      },
      {
        label: '中国工商银行乌海分行会计业务处理中心',
        value: '102193008500|1503|内蒙古自治区|乌海市'
      },
      {
        label: '中国工商银行乌海千里山支行',
        value: '102193008559|1503|内蒙古自治区|乌海市'
      },
      {
        label: '中国工商银行股份有限公司乌海汽车城支行',
        value: '102193008671|1503|内蒙古自治区|乌海市'
      },
      {
        label: '中国工商银行乌海市人民路支行',
        value: '102193008698|1503|内蒙古自治区|乌海市'
      },
      {
        label: '中国工商银行乌海黄河街支行',
        value: '102193015068|1503|内蒙古自治区|乌海市'
      },
      {
        label: '中国工商银行股份有限公司赤峰新华支行',
        value: '102194000041|1504|内蒙古自治区|赤峰市'
      },
      {
        label: '中国工商银行股份有限公司赤峰火花支行',
        value: '102194000050|1504|内蒙古自治区|赤峰市'
      },
      {
        label: '中国工商银行股份有限公司赤峰王府支行',
        value: '102194000076|1504|内蒙古自治区|赤峰市'
      },
      {
        label: '中国工商银行石家庄平安支行',
        value: '102121000447|1301|河北省|石家庄市'
      },
      {
        label: '中国工商银行股份有限公司石家庄新东支行',
        value: '102121000455|1301|河北省|石家庄市'
      },
      {
        label: '中国工商银行石家庄桥西支行世纪分理处',
        value: '102121000463|1301|河北省|石家庄市'
      },
      {
        label: '中国工商银行包头市林荫路支行',
        value: '102192001400|1502|内蒙古自治区|包头市'
      },
      {
        label: '中国工商银行包头市青山区支行',
        value: '102192002206|1502|内蒙古自治区|包头市'
      },
      {
        label: '中国工商银行包头富强路支行',
        value: '102192002214|1502|内蒙古自治区|包头市'
      },
      {
        label: '中国工商银行包头一机支行',
        value: '102192002222|1502|内蒙古自治区|包头市'
      },
      {
        label: '中国工商银行包头北重支行',
        value: '102192002239|1502|内蒙古自治区|包头市'
      },
      {
        label: '中国工商银行包头市建华支行',
        value: '102192002302|1502|内蒙古自治区|包头市'
      },
      {
        label: '中国工商银行股份有限公司通辽胜利支行',
        value: '102199000083|1505|内蒙古自治区|通辽市'
      },
      {
        label: '中国工商银行股份有限公司无锡南湖家园支行',
        value: '102302005769|3202|江苏省|无锡市'
      },
      {
        label: '中国工商银行股份有限公司江阴临港新城支行',
        value: '102302201805|3202|江苏省|无锡市'
      },
      {
        label: '中国工商银行股份有限公司江阴人民路支行',
        value: '102302202749|3202|江苏省|无锡市'
      },
      {
        label: '中国工商银行股份有限公司江阴市支行',
        value: '102302202757|3202|江苏省|无锡市'
      },
      {
        label: '中国工商银行股份有限公司江阴城西支行',
        value: '102302202765|3202|江苏省|无锡市'
      },
      {
        label: '中国工商银行股份有限公司江阴青阳支行',
        value: '102302202773|3202|江苏省|无锡市'
      },
      {
        label: '中国工商银行股份有限公司江阴华士支行',
        value: '102302202781|3202|江苏省|无锡市'
      },
      {
        label: '中国工商银行沈阳景星支行',
        value: '102221002937|2101|辽宁省|沈阳市'
      },
      {
        label: '中国工商银行沈阳大东支行小东门分理处',
        value: '102221003034|2101|辽宁省|沈阳市'
      },
      {
        label: '中国工商银行沈阳东站支行',
        value: '102221003139|2101|辽宁省|沈阳市'
      },
      {
        label: '中国工商银行邳州市支行',
        value: '102303502600|3203|江苏省|徐州市'
      },
      {
        label: '中国工商银行邳州明德支行',
        value: '102303502714|3203|江苏省|徐州市'
      },
      {
        label: '中国工商银行股份有限公司邳州通成支行',
        value: '102303505047|3203|江苏省|徐州市'
      },
      {
        label: '中国工商银行股份有限公司邳州解放路支行',
        value: '102303505080|3203|江苏省|徐州市'
      },
      {
        label: '中国工商银行新沂市支行',
        value: '102303602613|3203|江苏省|徐州市'
      },
      {
        label: '中国工商银行新沂市支行南京路分理处',
        value: '102303602736|3203|江苏省|徐州市'
      },
      {
        label: '中国工商银行股份有限公司新沂建邺东路支行',
        value: '102303609324|3203|江苏省|徐州市'
      },
      {
        label: '中国工商银行股份有限公司常州经济开发区支行',
        value: '102304002027|3204|江苏省|常州市'
      },
      {
        label: '中国工商银行股份有限公司常州天宁支行',
        value: '102304002035|3204|江苏省|常州市'
      },
      {
        label: '中国工商银行常州市钟楼支行',
        value: '102304002051|3204|江苏省|常州市'
      },
      {
        label: '中国工商银行常州市广化支行',
        value: '102304002094|3204|江苏省|常州市'
      },
      {
        label: '中国工商银行常州市武进支行',
        value: '102304002109|3204|江苏省|常州市'
      },
      {
        label: '中国工商银行常州博爱路支行',
        value: '102304002117|3204|江苏省|常州市'
      },
      {
        label: '中国工商银行常州湖塘支行',
        value: '102304002125|3204|江苏省|常州市'
      },
      {
        label: '中国工商银行股份有限公司常州夏城路支行',
        value: '102304002133|3204|江苏省|常州市'
      },
      {
        label: '中国工商银行常州横林支行',
        value: '102304002141|3204|江苏省|常州市'
      },
      {
        label: '中国工商银行常州奔牛支行',
        value: '102304002150|3204|江苏省|常州市'
      },
      {
        label: '中国工商银行常州市新区支行',
        value: '102304002168|3204|江苏省|常州市'
      },
      {
        label: '中国工商银行常州分行会计业务处理中心',
        value: '102304002176|3204|江苏省|常州市'
      },
      {
        label: '中国工商银行常州市小营前支行',
        value: '102304002184|3204|江苏省|常州市'
      },
      {
        label: '中国工商银行股份有限公司常州府琛花园支行',
        value: '102304002192|3204|江苏省|常州市'
      },
      {
        label: '中国工商银行股份有限公司常州武进高新区支行',
        value: '102304002301|3204|江苏省|常州市'
      },
      {
        label: '中国工商银行股份有限公司常州花园街支行',
        value: '102304002328|3204|江苏省|常州市'
      },
      {
        label: '中国工商银行股份有限公司常州礼嘉支行',
        value: '102304002336|3204|江苏省|常州市'
      },
      {
        label: '中国工商银行股份有限公司丹阳和平桥支行',
        value: '102314102201|3211|江苏省|镇江市'
      },
      {
        label: '中国工商银行股份有限公司丹阳小东门桥支行',
        value: '102314102210|3211|江苏省|镇江市'
      },
      {
        label: '中国工商银行昆山东城支行',
        value: '102305223263|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行昆山淀山湖支行',
        value: '102305223271|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行昆山巴城支行',
        value: '102305223280|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行昆山周市支行',
        value: '102305223298|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行股份有限公司吴江分行',
        value: '102305402201|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行吴江盛泽支行',
        value: '102305402210|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行吴江市震泽分理处',
        value: '102305402228|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行吴江市同里分理处',
        value: '102305402236|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行股份有限公司吴江黎里支行',
        value: '102305402244|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行吴江平望支行',
        value: '102305402252|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行股份有限公司吴江汾湖支行',
        value: '102305402269|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行吴江市梅堰分理处',
        value: '102305402277|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行吴江七都支行',
        value: '102305402285|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行吴江横扇支行',
        value: '102305402293|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行股份有限公司吴江经济开发区支行',
        value: '102305422026|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行吴江嘉鸿广场支行',
        value: '102305422034|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行吴江鲈乡支行',
        value: '102305422042|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行吴江水乡支行',
        value: '102305422059|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行吴江盛泽中心广场分理处',
        value: '102305422067|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行股份有限公司吴江盛泽盛东支行',
        value: '102305422083|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行股份有限公司吴江东盛支行',
        value: '102305422106|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行吴江盛泽城南支行',
        value: '102305422155|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行吴江铜罗支行',
        value: '102305422163|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行吴江盛泽舜湖支行',
        value: '102305422180|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行常熟市支行',
        value: '102305502489|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行常熟老县场支行',
        value: '102305502497|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行常熟南门支行',
        value: '102305502501|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行常熟颜港支行',
        value: '102305502510|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行股份有限公司鄂尔多斯市东胜煤田矿区支行',
        value: '102205805481|1506|内蒙古自治区|鄂尔多斯市'
      },
      {
        label: '中国工商银行伊金霍洛旗支行',
        value: '102205808206|1506|内蒙古自治区|鄂尔多斯市'
      },
      {
        label: '中国工商银行股份有限公司巴彦淖尔新区支行',
        value: '102207000097|1508|内蒙古自治区|巴彦淖尔市'
      },
      {
        label: '中国工商银行股份有限公司巴彦淖尔新华东街支行',
        value: '102207000110|1508|内蒙古自治区|巴彦淖尔市'
      },
      {
        label: '中国工商银行巴彦淖尔市胜利路支行',
        value: '102207000216|1508|内蒙古自治区|巴彦淖尔市'
      },
      {
        label: '中国工商银行巴彦淖尔分行核算中心',
        value: '102207001231|1508|内蒙古自治区|巴彦淖尔市'
      },
      {
        label: '中国工商银行巴彦淖尔市临河区支行',
        value: '102207002079|1508|内蒙古自治区|巴彦淖尔市'
      },
      {
        label: '中国工商银行股份有限公司常州湟里支行',
        value: '102304002416|3204|江苏省|常州市'
      },
      {
        label: '中国工商银行辽源龙山支行',
        value: '102244025167|2204|吉林省|辽源市'
      },
      {
        label: '中国工商银行辽源工农支行',
        value: '102244025239|2204|吉林省|辽源市'
      },
      {
        label: '中国工商银行辽源开发区支行',
        value: '102244026022|2204|吉林省|辽源市'
      },
      {
        label: '中国工商银行辽源红城支行',
        value: '102244026098|2204|吉林省|辽源市'
      },
      {
        label: '中国工商银行辽源东丰支行',
        value: '102244124204|2204|吉林省|辽源市'
      },
      {
        label: '中国工商银行股份有限公司通化分行',
        value: '102245002006|2205|吉林省|通化市'
      },
      {
        label: '中国工商银行股份有限公司通化滨江支行',
        value: '102245002014|2205|吉林省|通化市'
      },
      {
        label: '中国工商银行股份有限公司通化新华支行',
        value: '102245002022|2205|吉林省|通化市'
      },
      {
        label: '中国工商银行股份有限公司通化江东支行',
        value: '102245002039|2205|吉林省|通化市'
      },
      {
        label: '中国工商银行股份有限公司通化二道江支行',
        value: '102245002047|2205|吉林省|通化市'
      },
      {
        label: '中国工商银行股份有限公司通化县支行',
        value: '102245002055|2205|吉林省|通化市'
      },
      {
        label: '中国工商银行股份有限公司通化民主支行',
        value: '102245002063|2205|吉林省|通化市'
      },
      {
        label: '中国工商银行股份有限公司通化新站支行',
        value: '102245002071|2205|吉林省|通化市'
      },
      {
        label: '中国工商银行股份有限公司呼和浩特市呼哈路支行',
        value: '102191000351|1501|内蒙古自治区|呼和浩特市'
      },
      {
        label: '中国工商银行股份有限公司呼和浩特市昭乌达路支行',
        value: '102191000378|1501|内蒙古自治区|呼和浩特市'
      },
      {
        label: '中国工商银行股份有限公司呼和浩特赛罕支行',
        value: '102191000409|1501|内蒙古自治区|呼和浩特市'
      },
      {
        label: '中国工商银行股份有限公司扬中长江支行',
        value: '102314304871|3211|江苏省|镇江市'
      },
      {
        label: '中国工商银行股份有限公司扬中车站支行',
        value: '102314304898|3211|江苏省|镇江市'
      },
      {
        label: '中国工商银行股份有限公司扬中新城支行',
        value: '102314304902|3211|江苏省|镇江市'
      },
      {
        label: '中国工商银行股份有限公司扬中八桥支行',
        value: '102314308083|3211|江苏省|镇江市'
      },
      {
        label: '中国工商银行股份有限公司浙江省分行(不对外办理业务)',
        value: '102331000014|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行股份有限公司杭州白马支行',
        value: '102331000217|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行股份有限公司杭州卖鱼桥支行',
        value: '102331000241|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行股份有限公司杭州中河支行',
        value: '102331000250|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行股份有限公司杭州康华支行',
        value: '102331000268|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行杭州潮王路支行',
        value: '102331000321|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行股份有限公司杭州新市街支行',
        value: '102331000330|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行股份有限公司杭州新城支行',
        value: '102331000356|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行股份有限公司杭州南星桥支行',
        value: '102331000364|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行股份有限公司杭州望江东路支行',
        value: '102331000389|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行股份有限公司杭州银马支行',
        value: '102331000452|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行股份有限公司杭州浦沿支行',
        value: '102331000469|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行股份有限公司杭州九堡支行',
        value: '102331000477|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行股份有限公司杭州上塘北路支行',
        value: '102331000485|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行股份有限公司杭州新华路支行',
        value: '102331000508|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行杭州古荡支行',
        value: '102331000590|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行营口市站前支行',
        value: '102228000039|2108|辽宁省|营口市'
      },
      {
        label: '中国工商银行营口市新华支行',
        value: '102228000055|2108|辽宁省|营口市'
      },
      {
        label: '中国工商银行营口市西市支行',
        value: '102228000071|2108|辽宁省|营口市'
      },
      {
        label: '中国工商银行股份有限公司营口永红支行',
        value: '102228000080|2108|辽宁省|营口市'
      },
      {
        label: '中国工商银行股份有限公司营口老边支行',
        value: '102228000098|2108|辽宁省|营口市'
      },
      {
        label: '中国工商银行股份有限公司营口富强支行',
        value: '102228000102|2108|辽宁省|营口市'
      },
      {
        label: '中国工商银行营口经济技术开发区支行',
        value: '102228000186|2108|辽宁省|营口市'
      },
      {
        label: '中国工商银行股份有限公司营口劳动支行',
        value: '102228000250|2108|辽宁省|营口市'
      },
      {
        label: '中国工商银行股份有限公司营口黎明支行',
        value: '102228000305|2108|辽宁省|营口市'
      },
      {
        label: '中国工商银行股份有限公司营口钢铁支行',
        value: '102228000410|2108|辽宁省|营口市'
      },
      {
        label: '中国工商银行营口市兴汇支行',
        value: '102228000493|2108|辽宁省|营口市'
      },
      {
        label: '中国工商银行营口市金牛山支行',
        value: '102228000508|2108|辽宁省|营口市'
      },
      {
        label: '中国工商银行股份有限公司营口营东支行',
        value: '102228000516|2108|辽宁省|营口市'
      },
      {
        label: '中国工商银行股份有限公司营口熊岳支行',
        value: '102228000604|2108|辽宁省|营口市'
      },
      {
        label: '中国工商银行股份有限公司营口渤海支行',
        value: '102228000637|2108|辽宁省|营口市'
      },
      {
        label: '中国工商银行股份有限公司营口白云支行',
        value: '102228000688|2108|辽宁省|营口市'
      },
      {
        label: '中国工商银行股份有限公司营口望儿山支行',
        value: '102228000811|2108|辽宁省|营口市'
      },
      {
        label: '中国工商银行股份有限公司营口红旺支行',
        value: '102228000934|2108|辽宁省|营口市'
      },
      {
        label: '中国工商银行股份有限公司营口华联支行',
        value: '102228000942|2108|辽宁省|营口市'
      },
      {
        label: '中国工商银行股份有限公司营口迎春支行',
        value: '102228000967|2108|辽宁省|营口市'
      },
      {
        label: '中国工商银行营口市业务处理中心',
        value: '102228006002|2108|辽宁省|营口市'
      },
      {
        label: '中国工商银行股份有限公司营口盖州永安支行',
        value: '102228200024|2108|辽宁省|营口市'
      },
      {
        label: '中国工商银行股份有限公司营口盖州大堆子支行',
        value: '102228200717|2108|辽宁省|营口市'
      },
      {
        label: '中国工商银行盖州市支行',
        value: '102228200805|2108|辽宁省|营口市'
      },
      {
        label: '中国工商银行阜新海州支行',
        value: '102229000016|2109|辽宁省|阜新市'
      },
      {
        label: '中国工商银行泰州市分行营业部',
        value: '102312802011|3212|江苏省|泰州市'
      },
      {
        label: '中国工商银行泰州扬桥支行',
        value: '102312802020|3212|江苏省|泰州市'
      },
      {
        label: '中国工商银行股份有限公司枝江江汉支行',
        value: '102526401425|4205|湖北省|宜昌市'
      },
      {
        label: '中国工商银行沈阳大东支行小北分理处',
        value: '102221003251|2101|辽宁省|沈阳市'
      },
      {
        label: '中国工商银行沈阳珠林支行',
        value: '102221003325|2101|辽宁省|沈阳市'
      },
      {
        label: '中国工商银行沈阳机场支行',
        value: '102221003333|2101|辽宁省|沈阳市'
      },
      {
        label: '中国工商银行沈阳和睦路支行',
        value: '102221003341|2101|辽宁省|沈阳市'
      },
      {
        label: '中国工商银行沈阳北行支行',
        value: '102221004019|2101|辽宁省|沈阳市'
      },
      {
        label: '中国工商银行沈阳亚明支行',
        value: '102221004027|2101|辽宁省|沈阳市'
      },
      {
        label: '中国工商银行沈阳崇山路支行',
        value: '102221004035|2101|辽宁省|沈阳市'
      },
      {
        label: '中国工商银行沈阳香炉山路支行',
        value: '102221004043|2101|辽宁省|沈阳市'
      },
      {
        label: '中国工商银行沈阳岐山路支行',
        value: '102221004060|2101|辽宁省|沈阳市'
      },
      {
        label: '中国工商银行沈阳陵北支行',
        value: '102221004078|2101|辽宁省|沈阳市'
      },
      {
        label: '中国工商银行沈阳碧塘支行',
        value: '102221004086|2101|辽宁省|沈阳市'
      },
      {
        label: '中国工商银行股份有限公司长治分行',
        value: '102164000918|1402|山西省|大同市'
      },
      {
        label: '中国工商银行股份有限公司长治汇通支行',
        value: '102164000926|1402|山西省|大同市'
      },
      {
        label: '中国工商银行股份有限公司长治南街支行',
        value: '102164000934|1402|山西省|大同市'
      },
      {
        label: '中国工商银行股份有限公司长治西关支行',
        value: '102164000959|1402|山西省|大同市'
      },
      {
        label: '中国工商银行股份有限公司长治火车站支行',
        value: '102164000967|1402|山西省|大同市'
      },
      {
        label: '中国工商银行股份有限公司长治五马支行',
        value: '102164000975|1402|山西省|大同市'
      },
      {
        label: '中国工商银行股份有限公司长治北董支行',
        value: '102164000983|1402|山西省|大同市'
      },
      {
        label: '中国工商银行股份有限公司长治故县支行',
        value: '102164001009|1402|山西省|大同市'
      },
      {
        label: '中国工商银行股份有限公司长治石圪节支行',
        value: '102164001017|1402|山西省|大同市'
      },
      {
        label: '中国工商银行股份有限公司长治漳村支行',
        value: '102164001025|1402|山西省|大同市'
      },
      {
        label: '中国工商银行股份有限公司长治滨河支行',
        value: '102164001033|1402|山西省|大同市'
      },
      {
        label: '中国工商银行股份有限公司长治郊区支行',
        value: '102164001041|1402|山西省|大同市'
      },
      {
        label: '中国工商银行股份有限公司长治漳电支行',
        value: '102164001050|1402|山西省|大同市'
      },
      {
        label: '中国工商银行股份有限公司长治八一路支行',
        value: '102164001076|1402|山西省|大同市'
      },
      {
        label: '中国工商银行股份有限公司长治太西支行',
        value: '102164001084|1402|山西省|大同市'
      },
      {
        label: '中国工商银行股份有限公司长治城区支行',
        value: '102164001293|1402|山西省|大同市'
      },
      {
        label: '中国工商银行股份有限公司长治东街支行',
        value: '102164001308|1402|山西省|大同市'
      },
      {
        label: '中国工商银行股份有限公司长治电力支行',
        value: '102164002204|1402|山西省|大同市'
      },
      {
        label: '中国工商银行股份有限公司长治开发区支行',
        value: '102164002296|1402|山西省|大同市'
      },
      {
        label: '中国工商银行股份有限公司长治王庄支行',
        value: '102164024024|1402|山西省|大同市'
      },
      {
        label: '中国工商银行股份有限公司长治清华支行',
        value: '102164024049|1402|山西省|大同市'
      },
      {
        label: '中国工商银行股份有限公司长治五一路支行',
        value: '102164024065|1402|山西省|大同市'
      },
      {
        label: '中国工商银行股份有限公司长治府后支行',
        value: '102164024090|1402|山西省|大同市'
      },
      {
        label: '中国工商银行股份有限公司长治白求恩支行',
        value: '102164024153|1402|山西省|大同市'
      },
      {
        label: '中国工商银行股份有限公司长治兴苑支行',
        value: '102164024170|1402|山西省|大同市'
      },
      {
        label: '中国工商银行股份有限公司长治长兴支行',
        value: '102164024188|1402|山西省|大同市'
      },
      {
        label: '中国工商银行股份有限公司长治延安中路支行',
        value: '102164024215|1402|山西省|大同市'
      },
      {
        label: '中国工商银行秦皇岛秦山支行',
        value: '102126004852|1303|河北省|秦皇岛市'
      },
      {
        label: '中国工商银行秦皇岛文化支行',
        value: '102126004885|1303|河北省|秦皇岛市'
      },
      {
        label: '中国工商银行秦皇岛人民支行',
        value: '102126004908|1303|河北省|秦皇岛市'
      },
      {
        label: '中国工商银行秦皇岛山海关支行',
        value: '102126004916|1303|河北省|秦皇岛市'
      },
      {
        label: '中国工商银行股份有限公司秦皇岛龙海支行',
        value: '102126004932|1303|河北省|秦皇岛市'
      },
      {
        label: '中国工商银行股份有限公司秦皇岛城西支行',
        value: '102126004965|1303|河北省|秦皇岛市'
      },
      {
        label: '中国工商银行秦皇岛北戴河支行',
        value: '102126004981|1303|河北省|秦皇岛市'
      },
      {
        label: '中国工商银行秦皇岛海宁支行',
        value: '102126004990|1303|河北省|秦皇岛市'
      },
      {
        label: '中国工商银行秦皇岛西山支行',
        value: '102126005015|1303|河北省|秦皇岛市'
      },
      {
        label: '中国工商银行阜新太平支行',
        value: '102229000024|2109|辽宁省|阜新市'
      },
      {
        label: '中国工商银行阜新新邱支行',
        value: '102229000032|2109|辽宁省|阜新市'
      },
      {
        label: '中国工商银行阜新平安支行',
        value: '102229000049|2109|辽宁省|阜新市'
      },
      {
        label: '中国工商银行阜新清河门支行',
        value: '102229000057|2109|辽宁省|阜新市'
      },
      {
        label: '中国工商银行阜新阜矿支行',
        value: '102229000065|2109|辽宁省|阜新市'
      },
      {
        label: '中国工商银行昆山市经济技术开发区支行',
        value: '102305202355|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行昆山长江北路支行',
        value: '102305202363|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行昆山千灯支行',
        value: '102305202371|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行昆山锦溪支行',
        value: '102305202380|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行昆山金浦路支行',
        value: '102305210146|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行昆山出口加工园支行',
        value: '102305223020|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行昆山正阳支行',
        value: '102305223038|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行昆山柏芦支行',
        value: '102305223054|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行昆山市亭林支行',
        value: '102305223079|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行昆山紫竹路分理处',
        value: '102305223100|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行股份有限公司昆山花望路支行',
        value: '102305223175|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行股份有限公司肥东北街支行',
        value: '102361002561|3401|安徽省|合肥市'
      },
      {
        label: '中国工商银行股份有限公司肥西新华街支行',
        value: '102361002570|3401|安徽省|合肥市'
      },
      {
        label: '中国工商银行股份有限公司常州邹区支行',
        value: '102304002424|3204|江苏省|常州市'
      },
      {
        label: '中国工商银行股份有限公司常州遥观支行',
        value: '102304002432|3204|江苏省|常州市'
      },
      {
        label: '中国工商银行股份有限公司常州三河口支行',
        value: '102304002457|3204|江苏省|常州市'
      },
      {
        label: '中国工商银行股份有限公司常州金色新城支行',
        value: '102304003021|3204|江苏省|常州市'
      },
      {
        label: '中国工商银行股份有限公司常州机场路支行',
        value: '102304003101|3204|江苏省|常州市'
      },
      {
        label: '中国工商银行股份有限公司常州常新路分理处',
        value: '102304003128|3204|江苏省|常州市'
      },
      {
        label: '中国工商银行股份有限公司常州西新桥支行',
        value: '102304003152|3204|江苏省|常州市'
      },
      {
        label: '中国工商银行股份有限公司常州花园新村支行',
        value: '102304003169|3204|江苏省|常州市'
      },
      {
        label: '中国工商银行股份有限公司常州豪庭花园支行',
        value: '102304003208|3204|江苏省|常州市'
      },
      {
        label: '中国工商银行股份有限公司常州金谷花园支行',
        value: '102304003265|3204|江苏省|常州市'
      },
      {
        label: '中国工商银行股份有限公司常州水木年华支行',
        value: '102304003290|3204|江苏省|常州市'
      },
      {
        label: '中国工商银行股份有限公司常州早科坊支行',
        value: '102304003312|3204|江苏省|常州市'
      },
      {
        label: '中国工商银行股份有限公司常州金百支行',
        value: '102304003329|3204|江苏省|常州市'
      },
      {
        label: '中国工商银行股份有限公司常州莱茵花苑支行',
        value: '102304003337|3204|江苏省|常州市'
      },
      {
        label: '中国工商银行股份有限公司常州浦南支行',
        value: '102304003520|3204|江苏省|常州市'
      },
      {
        label: '中国工商银行股份有限公司常州朝阳支行',
        value: '102304003763|3204|江苏省|常州市'
      },
      {
        label: '中国工商银行股份有限公司常州翠园世家支行',
        value: '102304003771|3204|江苏省|常州市'
      },
      {
        label: '中国工商银行股份有限公司常州关河中路支行',
        value: '102304003780|3204|江苏省|常州市'
      },
      {
        label: '中国工商银行股份有限公司常州翠竹支行',
        value: '102304003802|3204|江苏省|常州市'
      },
      {
        label: '中国工商银行股份有限公司常州红梅支行',
        value: '102304003827|3204|江苏省|常州市'
      },
      {
        label: '中国工商银行弋阳县支行',
        value: '102433712177|3611|江西省|上饶市'
      },
      {
        label: '中国工商银行余干县支行',
        value: '102433812181|3611|江西省|上饶市'
      },
      {
        label: '中国工商银行鄱阳县支行',
        value: '102433912195|3611|江西省|上饶市'
      },
      {
        label: '中国工商银行万年县支行',
        value: '102434112206|3611|江西省|上饶市'
      },
      {
        label: '中国工商银行股份有限公司沂南支行',
        value: '102474300670|3713|山东省|临沂市'
      },
      {
        label: '中国工商银行股份有限公司临沭支行',
        value: '102474400460|3713|山东省|临沂市'
      },
      {
        label: '中国工商银行菏泽市分行',
        value: '102475000101|3717|山东省|菏泽市'
      },
      {
        label: '中国工商银行菏泽牡丹支行',
        value: '102475000208|3717|山东省|菏泽市'
      },
      {
        label: '中国工商银行菏泽市分行市中办事处',
        value: '102475000304|3717|山东省|菏泽市'
      },
      {
        label: '中国工商银行菏泽东关支行',
        value: '102475000407|3717|山东省|菏泽市'
      },
      {
        label: '中国工商银行菏泽开发支行',
        value: '102475000503|3717|山东省|菏泽市'
      },
      {
        label: '中国工商银行股份有限公司菏泽分行丹阳分理处',
        value: '102475000626|3717|山东省|菏泽市'
      },
      {
        label: '中国工商银行菏泽市分行东郊分理处',
        value: '102475000683|3717|山东省|菏泽市'
      },
      {
        label: '中国工商银行股份有限公司菏泽高新支行',
        value: '102475000691|3717|山东省|菏泽市'
      },
      {
        label: '中国工商银行曹县支行',
        value: '102475200605|3717|山东省|菏泽市'
      },
      {
        label: '中国工商银行菏泽市分行定陶分理处',
        value: '102475300709|3717|山东省|菏泽市'
      },
      {
        label: '中国工商银行成武县支行',
        value: '102475401108|3717|山东省|菏泽市'
      },
      {
        label: '中国工商银行单县支行',
        value: '102475500807|3717|山东省|菏泽市'
      },
      {
        label: '中国工商银行巨野县支行',
        value: '102475601203|3717|山东省|菏泽市'
      },
      {
        label: '中国工商银行梁山县支行',
        value: '102475702137|3708|山东省|济宁市'
      },
      {
        label: '中国工商银行郓城县支行',
        value: '102475801006|3717|山东省|菏泽市'
      },
      {
        label: '中国工商银行鄄城县支行',
        value: '102475900908|3717|山东省|菏泽市'
      },
      {
        label: '中国工商银行东明县支行',
        value: '102476101302|3717|山东省|菏泽市'
      },
      {
        label: '中国工商银行股份有限公司郑州航空港区支行',
        value: '102491000064|4101|河南省|郑州市'
      },
      {
        label: '中国工商银行郑州市解放路支行',
        value: '102491002011|4101|河南省|郑州市'
      },
      {
        label: '中国工商银行郑州市二里岗支行',
        value: '102491002020|4101|河南省|郑州市'
      },
      {
        label: '中国工商银行郑州市西大街支行',
        value: '102491002038|4101|河南省|郑州市'
      },
      {
        label: '中国工商银行郑州市建设路支行',
        value: '102491002054|4101|河南省|郑州市'
      },
      {
        label: '中国工商银行郑州市花园路支行',
        value: '102491002062|4101|河南省|郑州市'
      },
      {
        label: '中国工商银行股份有限公司郑州金水支行',
        value: '102491002079|4101|河南省|郑州市'
      },
      {
        label: '中国工商银行股份有限公司郑州商都路支行',
        value: '102491002087|4101|河南省|郑州市'
      },
      {
        label: '中国工商银行郑州市铁路支行',
        value: '102491002095|4101|河南省|郑州市'
      },
      {
        label: '中国工商银行股份有限公司郑州兴华南街支行',
        value: '102491002100|4101|河南省|郑州市'
      },
      {
        label: '中国工商银行郑州市桐柏路支行',
        value: '102491002134|4101|河南省|郑州市'
      },
      {
        label: '中国工商银行郑州市农业路支行',
        value: '102491002142|4101|河南省|郑州市'
      },
      {
        label: '中国工商银行股份有限公司郑州陇海路支行',
        value: '102491002159|4101|河南省|郑州市'
      },
      {
        label: '中国工商银行股份有限公司郑州东区支行',
        value: '102491002167|4101|河南省|郑州市'
      },
      {
        label: '中国工商银行郑州市南阳路支行',
        value: '102491002175|4101|河南省|郑州市'
      },
      {
        label: '中国工商银行股份有限公司郑州康复前街支行',
        value: '102491002183|4101|河南省|郑州市'
      },
      {
        label: '中国工商银行郑州市郑花路支行',
        value: '102491002206|4101|河南省|郑州市'
      },
      {
        label: '中国工商银行郑州市上街区支行',
        value: '102491002214|4101|河南省|郑州市'
      },
      {
        label: '中国工商银行郑州市中原路支行',
        value: '102491002280|4101|河南省|郑州市'
      },
      {
        label: '中国工商银行股份有限公司新乡南桥支行',
        value: '102498062063|4107|河南省|新乡市'
      },
      {
        label: '中国工商银行新乡分行获嘉县支行',
        value: '102498202223|4107|河南省|新乡市'
      },
      {
        label: '中国工商银行新乡分行原阳县支行',
        value: '102498302269|4107|河南省|新乡市'
      },
      {
        label: '中国工商银行股份有限公司启东电力大厦支行',
        value: '102306600137|3206|江苏省|南通市'
      },
      {
        label: '中国工商银行顺平支行',
        value: '102135709729|1306|河北省|保定市'
      },
      {
        label: '中国工商银行望都支行',
        value: '102135909735|1306|河北省|保定市'
      },
      {
        label: '中国工商银行高阳支行',
        value: '102136209740|1306|河北省|保定市'
      },
      {
        label: '中国工商银行安新支行',
        value: '102136309752|1306|河北省|保定市'
      },
      {
        label: '中国工商银行雄县支行',
        value: '102136409767|1306|河北省|保定市'
      },
      {
        label: '中国工商银行张家港杨舍路支行',
        value: '102305602870|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行张家港市财税大厦分理处',
        value: '102305602888|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行张家港南丰支行',
        value: '102305602896|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行张家港凤凰支行',
        value: '102305602907|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行股份有限公司张家港城中支行',
        value: '102305602915|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行张家港德积支行',
        value: '102305602923|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行股份有限公司张家港塘市支行',
        value: '102305610233|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行张家港城南分理处',
        value: '102305627038|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行张家港暨阳支行',
        value: '102305627054|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行股份有限公司张家港海港支行',
        value: '102305627095|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行张家港花园支行',
        value: '102305627100|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行张家港万红支行',
        value: '102305627126|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行沈阳道义支行',
        value: '102221000093|2101|辽宁省|沈阳市'
      },
      {
        label: '中国工商银行沈阳贸易中心支行',
        value: '102221000116|2101|辽宁省|沈阳市'
      },
      {
        label: '中国工商银行沈阳河畔新城支行',
        value: '102221000124|2101|辽宁省|沈阳市'
      },
      {
        label: '中国工商银行股份有限公司寿阳朝阳支行',
        value: '102175600105|1407|山西省|晋中市'
      },
      {
        label: '中国工商银行寿阳县支行',
        value: '102175601288|1407|山西省|晋中市'
      },
      {
        label: '中国工商银行太谷县支行',
        value: '102175701570|1407|山西省|晋中市'
      },
      {
        label: '中国工商银行股份有限公司太谷农大支行',
        value: '102175727056|1407|山西省|晋中市'
      },
      {
        label: '中国工商银行祁县支行',
        value: '102175801254|1407|山西省|晋中市'
      },
      {
        label: '中国工商银行股份有限公司祁县丹枫路支行',
        value: '102175827422|1407|山西省|晋中市'
      },
      {
        label: '中国工商银行平遥县支行',
        value: '102175901440|1407|山西省|晋中市'
      },
      {
        label: '中国工商银行介休支行',
        value: '102176101316|1407|山西省|晋中市'
      },
      {
        label: '中国工商银行介休市车站支行',
        value: '102176101324|1407|山西省|晋中市'
      },
      {
        label: '中国工商银行灵石县支行',
        value: '102176201501|1407|山西省|晋中市'
      },
      {
        label: '中国工商银行股份有限公司临汾南城支行',
        value: '102177001317|1410|山西省|临汾市'
      },
      {
        label: '中国工商银行临汾分行城建支行',
        value: '102177002826|1410|山西省|临汾市'
      },
      {
        label: '中国工商银行股份有限公司临汾平阳支行',
        value: '102177002834|1410|山西省|临汾市'
      },
      {
        label: '中国工商银行临汾市车站支行',
        value: '102177002859|1410|山西省|临汾市'
      },
      {
        label: '中国工商银行股份有限公司临汾分行',
        value: '102177003281|1410|山西省|临汾市'
      },
      {
        label: '工行银苑支行',
        value: '102177003600|1410|山西省|临汾市'
      },
      {
        label: '工行尧都支行',
        value: '102177004813|1410|山西省|临汾市'
      },
      {
        label: '工行临汾开发区支行',
        value: '102177004821|1410|山西省|临汾市'
      },
      {
        label: '中国工商银行股份有限公司临汾西城支行',
        value: '102177028272|1410|山西省|临汾市'
      },
      {
        label: '侯马市支行',
        value: '102177202893|1410|山西省|临汾市'
      },
      {
        label: '中国工商银行股份有限公司曲沃支行',
        value: '102177328395|1410|山西省|临汾市'
      },
      {
        label: '中国工商银行翼城县支行',
        value: '102177403260|1410|山西省|临汾市'
      },
      {
        label: '中国工商银行襄汾县支行',
        value: '102177502928|1410|山西省|临汾市'
      },
      {
        label: '中国工商银行洪洞县支行',
        value: '102177603001|1410|山西省|临汾市'
      },
      {
        label: '中国工商银行霍州市支行',
        value: '102177703086|1410|山西省|临汾市'
      },
      {
        label: '中国工商银行霍州市辛置支行',
        value: '102177703094|1410|山西省|临汾市'
      },
      {
        label: '中国工商银行股份有限公司安泽支行',
        value: '102177928288|1410|山西省|临汾市'
      },
      {
        label: '中国工商银行乡宁县支行',
        value: '102178303222|1410|山西省|临汾市'
      },
      {
        label: '工行山西省临汾分行蒲县支行',
        value: '102178403181|1410|山西省|临汾市'
      },
      {
        label: '中国工商银行股份有限公司运城电力支行',
        value: '102181000122|1408|山西省|运城市'
      },
      {
        label: '中国工商银行股份有限公司运城河东支行',
        value: '102181003426|1408|山西省|运城市'
      },
      {
        label: '中国工商银行股份有限公司运城解州支行',
        value: '102181003434|1408|山西省|运城市'
      },
      {
        label: '中国工商银行股份有限公司运城解放路支行',
        value: '102181003442|1408|山西省|运城市'
      },
      {
        label: '中国工商银行股份有限公司运城禹都支行',
        value: '102181003459|1408|山西省|运城市'
      },
      {
        label: '中国工商银行股份有限公司运城红旗西街支行',
        value: '102181003467|1408|山西省|运城市'
      },
      {
        label: '中国工商银行股份有限公司运城南风广场支行',
        value: '102181003475|1408|山西省|运城市'
      },
      {
        label: '中国工商银行股份有限公司运城城建支行',
        value: '102181003539|1408|山西省|运城市'
      },
      {
        label: '中国工商银行股份有限公司运城牡丹支行',
        value: '102181003555|1408|山西省|运城市'
      },
      {
        label: '中国工商银行股份有限公司运城分行',
        value: '102181003784|1408|山西省|运城市'
      },
      {
        label: '中国工商银行股份有限公司朝阳鑫融支行',
        value: '102234000125|2113|辽宁省|朝阳市'
      },
      {
        label: '中国工商银行股份有限公司朝阳珠江支行',
        value: '102234000168|2113|辽宁省|朝阳市'
      },
      {
        label: '中国工商银行股份有限公司朝阳朝凤支行',
        value: '102234000297|2113|辽宁省|朝阳市'
      },
      {
        label: '中国工商银行股份有限公司朝阳铁西支行',
        value: '102234000607|2113|辽宁省|朝阳市'
      },
      {
        label: '中国工商银行股份有限公司龙海支行商业城储蓄所',
        value: '102399133177|3506|福建省|漳州市'
      },
      {
        label: '中国工商银行云霄县支行',
        value: '102399237502|3506|福建省|漳州市'
      },
      {
        label: '中国工商银行股份有限公司石家庄良村支行',
        value: '102121002219|1301|河北省|石家庄市'
      },
      {
        label: '中国工商银行股份有限公司石家庄石化支行',
        value: '102121002227|1301|河北省|石家庄市'
      },
      {
        label: '中国工商银行股份有限公司新乐广场支行',
        value: '102121022206|1301|河北省|石家庄市'
      },
      {
        label: '中国工商银行唐山分行核算中心',
        value: '102124002511|1302|河北省|唐山市'
      },
      {
        label: '中国工商银行唐山凤凰支行',
        value: '102124002520|1302|河北省|唐山市'
      },
      {
        label: '中国工商银行唐山新华支行',
        value: '102124002538|1302|河北省|唐山市'
      },
      {
        label: '中国工商银行股份有限公司唐山尚座支行',
        value: '102124002554|1302|河北省|唐山市'
      },
      {
        label: '中国工商银行股份有限公司呼和浩特明泽支行',
        value: '102191011702|1501|内蒙古自治区|呼和浩特市'
      },
      {
        label: '中国工商银行股份有限公司土默特左旗敕勒川支行',
        value: '102191012004|1501|内蒙古自治区|呼和浩特市'
      },
      {
        label: '中国工商银行呼和浩特托电工业园区支行',
        value: '102191012123|1501|内蒙古自治区|呼和浩特市'
      },
      {
        label: '中国工商银行股份有限公司包头喜桂图支行',
        value: '102192000177|1502|内蒙古自治区|包头市'
      },
      {
        label: '中国工商银行股份有限公司包头站北路支行',
        value: '102192000185|1502|内蒙古自治区|包头市'
      },
      {
        label: '中国工商银行股份有限公司包头和平路支行',
        value: '102192000257|1502|内蒙古自治区|包头市'
      },
      {
        label: '中国工商银行股份有限公司包头文化路第一支行',
        value: '102192000407|1502|内蒙古自治区|包头市'
      },
      {
        label: '中国工商银行股份有限公司包头阳光支行',
        value: '102192000415|1502|内蒙古自治区|包头市'
      },
      {
        label: '中国工商银行股份有限公司包头保利花园支行',
        value: '102192000423|1502|内蒙古自治区|包头市'
      },
      {
        label: '中国工商银行股份有限公司包头迎宾小区支行',
        value: '102192000440|1502|内蒙古自治区|包头市'
      },
      {
        label: '中国工商银行股份有限公司包头民主路支行',
        value: '102192000458|1502|内蒙古自治区|包头市'
      },
      {
        label: '中国工商银行股份有限公司包头信得惠支行',
        value: '102192000466|1502|内蒙古自治区|包头市'
      },
      {
        label: '中国工商银行股份有限公司包头现代支行',
        value: '102192000503|1502|内蒙古自治区|包头市'
      },
      {
        label: '中国工商银行股份有限公司包头意城晶华支行',
        value: '102192000520|1502|内蒙古自治区|包头市'
      },
      {
        label: '中国工商银行股份有限公司包头大连新型支行',
        value: '102192000546|1502|内蒙古自治区|包头市'
      },
      {
        label: '中国工商银行股份有限公司包头团结大街中段支行',
        value: '102192000626|1502|内蒙古自治区|包头市'
      },
      {
        label: '中国工商银行股份有限公司包头民族西路支行',
        value: '102192000634|1502|内蒙古自治区|包头市'
      },
      {
        label: '中国工商银行股份有限公司包头科海支行',
        value: '102192000714|1502|内蒙古自治区|包头市'
      },
      {
        label: '中国工商银行股份有限公司包头团结大街支行',
        value: '102192000722|1502|内蒙古自治区|包头市'
      },
      {
        label: '中国工商银行股份有限公司包头广鑫支行',
        value: '102192000747|1502|内蒙古自治区|包头市'
      },
      {
        label: '中国工商银行股份有限公司包头白云综合市场支行',
        value: '102192000843|1502|内蒙古自治区|包头市'
      },
      {
        label: '中国工商银行股份有限公司包头乐园支行',
        value: '102192000851|1502|内蒙古自治区|包头市'
      },
      {
        label: '中国工商银行股份有限公司兰溪支行城西分理处',
        value: '102338605052|3307|浙江省|金华市'
      },
      {
        label: '中国工商银行股份有限公司义乌分行',
        value: '102338702001|3307|浙江省|金华市'
      },
      {
        label: '中国工商银行股份有限公司肥西三河支行',
        value: '102361002588|3401|安徽省|合肥市'
      },
      {
        label: '中国工商银行股份有限公司合肥蒙城北路支行',
        value: '102361004362|3401|安徽省|合肥市'
      },
      {
        label: '中国工商银行安徽省分行营业部核算中心',
        value: '102361005003|3401|安徽省|合肥市'
      },
      {
        label: '中国工商银行股份有限公司合肥逍遥津支行',
        value: '102361005046|3401|安徽省|合肥市'
      },
      {
        label: '中国工商银行股份有限公司长丰北城分理处',
        value: '102361005222|3401|安徽省|合肥市'
      },
      {
        label: '中国工商银行股份有限公司长丰双凤支行',
        value: '102361005239|3401|安徽省|合肥市'
      },
      {
        label: '中国工商银行丽水市分行莲城支行',
        value: '102343020502|3311|浙江省|丽水市'
      },
      {
        label: '中国工商银行股份有限公司丽水天宁寺支行',
        value: '102343020543|3311|浙江省|丽水市'
      },
      {
        label: '中国工商银行股份有限公司丽水经济开发区支行',
        value: '102343020609|3311|浙江省|丽水市'
      },
      {
        label: '中国工商银行股份有限公司青田支行',
        value: '102343222306|3311|浙江省|丽水市'
      },
      {
        label: '中国工商银行股份有限公司青田温溪支行',
        value: '102343222314|3311|浙江省|丽水市'
      },
      {
        label: '中国工商银行股份有限公司青田上店街支行',
        value: '102343222322|3311|浙江省|丽水市'
      },
      {
        label: '中国工商银行股份有限公司青田龙津路支行',
        value: '102343222339|3311|浙江省|丽水市'
      },
      {
        label: '中国工商银行股份有限公司青田塔山支行',
        value: '102343222347|3311|浙江省|丽水市'
      },
      {
        label: '中国工商银行股份有限公司青田江南支行',
        value: '102343222355|3311|浙江省|丽水市'
      },
      {
        label: '中国工商银行股份有限公司青田侨乡支行',
        value: '102343222363|3311|浙江省|丽水市'
      },
      {
        label: '中国工商银行股份有限公司青田龙东支行',
        value: '102343222371|3311|浙江省|丽水市'
      },
      {
        label: '中国工商银行云和县支行',
        value: '102343323101|3311|浙江省|丽水市'
      },
      {
        label: '中国工商银行股份有限公司庆元支行',
        value: '102343424108|3311|浙江省|丽水市'
      },
      {
        label: '中国工商银行缙云县支行',
        value: '102343528100|3311|浙江省|丽水市'
      },
      {
        label: '中国工商银行缙云壶镇支行',
        value: '102343528118|3311|浙江省|丽水市'
      },
      {
        label: '中国工商银行股份有限公司缙云仙都支行',
        value: '102343528159|3311|浙江省|丽水市'
      },
      {
        label: '中国工商银行遂昌县支行',
        value: '102343626105|3311|浙江省|丽水市'
      },
      {
        label: '中国工商银行松阳支行',
        value: '102343727101|3311|浙江省|丽水市'
      },
      {
        label: '中国工商银行景宁支行',
        value: '102343829106|3311|浙江省|丽水市'
      },
      {
        label: '中国工商银行龙泉市支行',
        value: '102343925103|3311|浙江省|丽水市'
      },
      {
        label: '中国工商银行股份有限公司龙泉西街头支行',
        value: '102343925111|3311|浙江省|丽水市'
      },
      {
        label: '中国工商银行厦门银行卡营业中心',
        value: '102393000868|3502|福建省|厦门市'
      },
      {
        label: '中国工商银行股份有限公司厦门观音山支行',
        value: '102393000876|3502|福建省|厦门市'
      },
      {
        label: '中国工商银行股份有限公司厦门软件园支行',
        value: '102393000884|3502|福建省|厦门市'
      },
      {
        label: '中国工商银行股份有限公司厦门工业集中区支行',
        value: '102393000892|3502|福建省|厦门市'
      },
      {
        label: '中国工商银行股份有限公司厦门园山支行',
        value: '102393000905|3502|福建省|厦门市'
      },
      {
        label: '中国工商银行股份有限公司厦门钟宅支行',
        value: '102393000913|3502|福建省|厦门市'
      },
      {
        label: '中国工商银行股份有限公司厦门西柯支行',
        value: '102393000921|3502|福建省|厦门市'
      },
      {
        label: '中国工商银行股份有限公司厦门新店支行',
        value: '102393000930|3502|福建省|厦门市'
      },
      {
        label: '中国工商银行股份有限公司厦门七星路支行',
        value: '102393000948|3502|福建省|厦门市'
      },
      {
        label: '中国工商银行股份有限公司厦门凤山支行',
        value: '102393000956|3502|福建省|厦门市'
      },
      {
        label: '中国工商银行股份有限公司厦门金钟支行',
        value: '102393000964|3502|福建省|厦门市'
      },
      {
        label: '中国工商银行股份有限公司厦门湖滨中路支行',
        value: '102393000972|3502|福建省|厦门市'
      },
      {
        label: '中国工商银行莆田市分行',
        value: '102394010009|3503|福建省|莆田市'
      },
      {
        label: '中国工商银行莆田市城厢支行',
        value: '102394011001|3503|福建省|莆田市'
      },
      {
        label: '中国工商银行莆田乐园支行',
        value: '102394011010|3503|福建省|莆田市'
      },
      {
        label: '中国工商银行莆田公园支行',
        value: '102394011028|3503|福建省|莆田市'
      },
      {
        label: '中国工商银行莆田糖厂支行',
        value: '102394011036|3503|福建省|莆田市'
      },
      {
        label: '中国工商银行莆田南门支行',
        value: '102394011052|3503|福建省|莆田市'
      },
      {
        label: '中国工商银行莆田市荔城支行',
        value: '102394012004|3503|福建省|莆田市'
      },
      {
        label: '中国工商银行莆田后卓支行',
        value: '102394012012|3503|福建省|莆田市'
      },
      {
        label: '中国工商银行股份有限公司宁德万达支行',
        value: '102403081063|3509|福建省|宁德市'
      },
      {
        label: '中国工商银行股份有限公司宁德三都澳支行',
        value: '102403081071|3509|福建省|宁德市'
      },
      {
        label: '中国工商银行宁德分行银行卡业务部',
        value: '102403081508|3509|福建省|宁德市'
      },
      {
        label: '中国工商银行宁德分行营业部',
        value: '102403081516|3509|福建省|宁德市'
      },
      {
        label: '中国工商银行福鼎市支行',
        value: '102403282005|3509|福建省|宁德市'
      },
      {
        label: '中国工商银行股份有限公司福鼎玉龙支行',
        value: '102403282013|3509|福建省|宁德市'
      },
      {
        label: '中国工商银行股份有限公司福鼎桐山支行',
        value: '102403282021|3509|福建省|宁德市'
      },
      {
        label: '中国工商银行股份有限公司福鼎城南支行',
        value: '102403282030|3509|福建省|宁德市'
      },
      {
        label: '中国工商银行股份有限公司福鼎太姥山支行',
        value: '102403282048|3509|福建省|宁德市'
      },
      {
        label: '中国工商银行股份有限公司霞浦松港支行',
        value: '102403384040|3509|福建省|宁德市'
      },
      {
        label: '中国工商银行股份有限公司新乡新区支行',
        value: '102498002021|4107|河南省|新乡市'
      },
      {
        label: '中国工商银行股份有限公司新乡牧野支行',
        value: '102498002030|4107|河南省|新乡市'
      },
      {
        label: '中国工商银行股份有限公司新乡凤泉支行',
        value: '102498002048|4107|河南省|新乡市'
      },
      {
        label: '中国工商银行新乡分行平原路支行',
        value: '102498002056|4107|河南省|新乡市'
      },
      {
        label: '中国工商银行新乡分行解放路支行',
        value: '102498002064|4107|河南省|新乡市'
      },
      {
        label: '中国工商银行新乡分行黄岗支行',
        value: '102498002072|4107|河南省|新乡市'
      },
      {
        label: '中国工商银行新乡分行北干道支行',
        value: '102498002089|4107|河南省|新乡市'
      },
      {
        label: '中国工商银行股份有限公司常州水晶城支行',
        value: '102304005530|3204|江苏省|常州市'
      },
      {
        label: '中国工商银行股份有限公司常州崔桥支行',
        value: '102304005564|3204|江苏省|常州市'
      },
      {
        label: '中国工商银行股份有限公司启东滨海新城支行',
        value: '102306600153|3206|江苏省|南通市'
      },
      {
        label: '中国工商银行股份有限公司启东和合支行',
        value: '102306601548|3206|江苏省|南通市'
      },
      {
        label: '中国工商银行连云港分行',
        value: '102307001007|3207|江苏省|连云港市'
      },
      {
        label: '中国工商银行连云港云台支行',
        value: '102307001023|3207|江苏省|连云港市'
      },
      {
        label: '中国工商银行连云港海昌路支行',
        value: '102307001058|3207|江苏省|连云港市'
      },
      {
        label: '中国工商银行连云港大庆路支行',
        value: '102307001074|3207|江苏省|连云港市'
      },
      {
        label: '中国工商银行连云港新浦支行',
        value: '102307001509|3207|江苏省|连云港市'
      },
      {
        label: '中国工商银行连云港幸福路支行',
        value: '102307001517|3207|江苏省|连云港市'
      },
      {
        label: '中国工商银行连云港海昌南路支行',
        value: '102307001525|3207|江苏省|连云港市'
      },
      {
        label: '中国工商银行连云港解放东路支行',
        value: '102307001550|3207|江苏省|连云港市'
      },
      {
        label: '中国工商银行连云港万润花园支行',
        value: '102307002018|3207|江苏省|连云港市'
      },
      {
        label: '中国工商银行连云港苍梧支行',
        value: '102307002042|3207|江苏省|连云港市'
      },
      {
        label: '中国工商银行连云港学院路支行',
        value: '102307002083|3207|江苏省|连云港市'
      },
      {
        label: '中国工商银行连云港连云支行',
        value: '102307002501|3207|江苏省|连云港市'
      },
      {
        label: '中国工商银行无锡分行业务处理中心',
        value: '102302002453|3202|江苏省|无锡市'
      },
      {
        label: '中国工商银行股份有限公司无锡军保支行',
        value: '102302002470|3202|江苏省|无锡市'
      },
      {
        label: '中国工商银行股份有限公司无锡银电支行',
        value: '102302002496|3202|江苏省|无锡市'
      },
      {
        label: '中国工商银行股份有限公司无锡锡山支行',
        value: '102302002507|3202|江苏省|无锡市'
      },
      {
        label: '中国工商银行股份有限公司无锡金城湾支行',
        value: '102302002515|3202|江苏省|无锡市'
      },
      {
        label: '中国工商银行股份有限公司无锡钱桥支行',
        value: '102302002531|3202|江苏省|无锡市'
      },
      {
        label: '中国工商银行无锡东亭支行',
        value: '102302002540|3202|江苏省|无锡市'
      },
      {
        label: '中国工商银行股份有限公司无锡洛社支行',
        value: '102302002558|3202|江苏省|无锡市'
      },
      {
        label: '中国工商银行股份有限公司无锡华庄支行',
        value: '102302002566|3202|江苏省|无锡市'
      },
      {
        label: '中国工商银行无锡安镇支行',
        value: '102302002574|3202|江苏省|无锡市'
      },
      {
        label: '中国工商银行股份有限公司无锡锡北支行',
        value: '102302002582|3202|江苏省|无锡市'
      },
      {
        label: '中国工商银行股份有限公司无锡前洲支行',
        value: '102302002599|3202|江苏省|无锡市'
      },
      {
        label: '中国工商银行股份有限公司无锡野花园支行',
        value: '102302002603|3202|江苏省|无锡市'
      },
      {
        label: '中国工商银行无锡鹅湖支行',
        value: '102302002611|3202|江苏省|无锡市'
      },
      {
        label: '中国工商银行股份有限公司无锡东港支行',
        value: '102302002638|3202|江苏省|无锡市'
      },
      {
        label: '中国工商银行无锡查桥支行',
        value: '102302002646|3202|江苏省|无锡市'
      },
      {
        label: '中国工商银行股份有限公司无锡雪浪支行',
        value: '102302002654|3202|江苏省|无锡市'
      },
      {
        label: '中国工商银行股份有限公司福州星光支行',
        value: '102391052038|3501|福建省|福州市'
      },
      {
        label: '中国工商银行福州象园路支行',
        value: '102391052046|3501|福建省|福州市'
      },
      {
        label: '中国工商银行福州高桥支行',
        value: '102391052054|3501|福建省|福州市'
      },
      {
        label: '中国工商银行股份有限公司福州元一支行',
        value: '102391052062|3501|福建省|福州市'
      },
      {
        label: '中国工商银行股份有限公司福州海润滨江支行',
        value: '102391052079|3501|福建省|福州市'
      },
      {
        label: '中国工商银行股份有限公司福州融侨东支行',
        value: '102391052087|3501|福建省|福州市'
      },
      {
        label: '中国工商银行股份有限公司福州黄山支行',
        value: '102391052095|3501|福建省|福州市'
      },
      {
        label: '中国工商银行股份有限公司福州金牛山支行',
        value: '102391052100|3501|福建省|福州市'
      },
      {
        label: '中国工商银行股份有限公司福州下杭支行',
        value: '102391052126|3501|福建省|福州市'
      },
      {
        label: '中国工商银行福州交通路支行',
        value: '102391052159|3501|福建省|福州市'
      },
      {
        label: '中国工商银行福州工业路支行',
        value: '102391052167|3501|福建省|福州市'
      },
      {
        label: '中国工商银行股份有限公司福清江阴支行',
        value: '102391052175|3501|福建省|福州市'
      },
      {
        label: '中国工商银行股份有限公司福州商场支行',
        value: '102391052183|3501|福建省|福州市'
      },
      {
        label: '中国工商银行股份有限公司福州金融街支行',
        value: '102391052206|3501|福建省|福州市'
      },
      {
        label: '中国工商银行股份有限公司福州心家泊支行',
        value: '102391052222|3501|福建省|福州市'
      },
      {
        label: '中国工商银行股份有限公司福州河下街支行',
        value: '102391052239|3501|福建省|福州市'
      },
      {
        label: '中国工商银行股份有限公司福州世贸外滩支行',
        value: '102391052263|3501|福建省|福州市'
      },
      {
        label: '中国工商银行股份有限公司福州宁化路支行',
        value: '102391052271|3501|福建省|福州市'
      },
      {
        label: '中国工商银行股份有限公司福州王庄支行',
        value: '102391052280|3501|福建省|福州市'
      },
      {
        label: '中国工商银行股份有限公司福州金榕支行',
        value: '102391052302|3501|福建省|福州市'
      },
      {
        label: '中国工商银行股份有限公司福州长汀支行',
        value: '102391052319|3501|福建省|福州市'
      },
      {
        label: '中国工商银行股份有限公司福州小桥支行',
        value: '102391052335|3501|福建省|福州市'
      },
      {
        label: '中国工商银行福州古街支行',
        value: '102391052343|3501|福建省|福州市'
      },
      {
        label: '中国工商银行福州锦秀支行',
        value: '102391052351|3501|福建省|福州市'
      },
      {
        label: '中国工商银行福州大学城支行',
        value: '102391052360|3501|福建省|福州市'
      },
      {
        label: '中国工商银行福州鼓楼支行方圆分理处',
        value: '102391052378|3501|福建省|福州市'
      },
      {
        label: '中国工商银行股份有限公司福州连江路支行',
        value: '102391052386|3501|福建省|福州市'
      },
      {
        label: '中国工商银行福州屏山支行',
        value: '102391052394|3501|福建省|福州市'
      },
      {
        label: '中国工商银行福州铜盘支行',
        value: '102391052409|3501|福建省|福州市'
      },
      {
        label: '中国工商银行股份有限公司福州闽潇支行',
        value: '102391052425|3501|福建省|福州市'
      },
      {
        label: '中国工商银行股份有限公司福州主题公园支行',
        value: '102391052433|3501|福建省|福州市'
      },
      {
        label: '中国工商银行股份有限公司福州大儒世家支行',
        value: '102391052441|3501|福建省|福州市'
      },
      {
        label: '中国工商银行福州钱塘支行',
        value: '102391052450|3501|福建省|福州市'
      },
      {
        label: '中国工商银行股份有限公司福州杨桥支行',
        value: '102391052468|3501|福建省|福州市'
      },
      {
        label: '中国工商银行股份有限公司云霄支行富云储蓄所',
        value: '102399237527|3506|福建省|漳州市'
      },
      {
        label: '中国工商银行漳浦县支行',
        value: '102399338005|3506|福建省|漳州市'
      },
      {
        label: '中国工商银行股份有限公司漳浦支行城关储蓄所',
        value: '102399338013|3506|福建省|漳州市'
      },
      {
        label: '中国工商银行股份有限公司漳州古雷新港城支行',
        value: '102399338021|3506|福建省|漳州市'
      },
      {
        label: '中国工商银行诏安县支行',
        value: '102399438507|3506|福建省|漳州市'
      },
      {
        label: '中国工商银行股份有限公司诏安支行城关储蓄所',
        value: '102399438531|3506|福建省|漳州市'
      },
      {
        label: '中国工商银行长泰县支行',
        value: '102399536502|3506|福建省|漳州市'
      },
      {
        label: '中国工商银行东山县支行',
        value: '102399639009|3506|福建省|漳州市'
      },
      {
        label: '中国工商银行东山县支行城关分理处',
        value: '102399639017|3506|福建省|漳州市'
      },
      {
        label: '中国工商银行南靖县支行',
        value: '102399736007|3506|福建省|漳州市'
      },
      {
        label: '中国工商银行股份有限公司平和支行',
        value: '102399804039|3506|福建省|漳州市'
      },
      {
        label: '中国工商银行华安县支行',
        value: '102399937001|3506|福建省|漳州市'
      },
      {
        label: '中国工商银行南平市分行',
        value: '102401070002|3507|福建省|南平市'
      },
      {
        label: '中国工商银行南平市分行营业部',
        value: '102401070019|3507|福建省|南平市'
      },
      {
        label: '中国工商银行南平市城银支行',
        value: '102401070027|3507|福建省|南平市'
      },
      {
        label: '中国工商银行南平市延平支行',
        value: '102401071005|3507|福建省|南平市'
      },
      {
        label: '中国工商银行股份有限公司南平成功支行',
        value: '102401071013|3507|福建省|南平市'
      },
      {
        label: '中国工商银行南平中山支行',
        value: '102401071021|3507|福建省|南平市'
      },
      {
        label: '中国工商银行南平火车站支行',
        value: '102401071030|3507|福建省|南平市'
      },
      {
        label: '中国工商银行南平水东支行',
        value: '102401071064|3507|福建省|南平市'
      },
      {
        label: '中国工商银行南平长富支行',
        value: '102401071097|3507|福建省|南平市'
      },
      {
        label: '中国工商银行南平南纺支行',
        value: '102401071101|3507|福建省|南平市'
      },
      {
        label: '中国工商银行南平市剑州支行',
        value: '102401072008|3507|福建省|南平市'
      },
      {
        label: '中国工商银行南平人民支行',
        value: '102401072016|3507|福建省|南平市'
      },
      {
        label: '中国工商银行股份有限公司南平黄金山支行',
        value: '102401072081|3507|福建省|南平市'
      },
      {
        label: '中国工商银行邵武市支行',
        value: '102401273008|3507|福建省|南平市'
      },
      {
        label: '中国工商银行股份有限公司东营新区支行',
        value: '102455060129|3705|山东省|东营市'
      },
      {
        label: '中国工商银行垦利县支行',
        value: '102455103018|3705|山东省|东营市'
      },
      {
        label: '中国工商银行股份有限公司东营垦利支行水电厂分理处',
        value: '102455103026|3705|山东省|东营市'
      },
      {
        label: '中国工商银行股份有限公司东营垦利支行胜坨分理处',
        value: '102455103034|3705|山东省|东营市'
      },
      {
        label: '中国工商银行股份有限公司东营垦利支行井下分理处',
        value: '102455103042|3705|山东省|东营市'
      },
      {
        label: '中国工商银行股份有限公司东营垦利支行东安分理处',
        value: '102455103059|3705|山东省|东营市'
      },
      {
        label: '中国工商银行股份有限公司利津支行',
        value: '102455204799|3705|山东省|东营市'
      },
      {
        label: '中国工商银行广饶县支行',
        value: '102455302017|3705|山东省|东营市'
      },
      {
        label: '中国工商银行股份有限公司东营大王支行',
        value: '102455302025|3705|山东省|东营市'
      },
      {
        label: '中国工商银行股份有限公司广饶稻庄支行',
        value: '102455306472|3705|山东省|东营市'
      },
      {
        label: '中国工商银行股份有限公司烟台分行',
        value: '102456000108|3706|山东省|烟台市'
      },
      {
        label: '中国工商银行股份有限公司烟台分行运行业务处理中心',
        value: '102456000165|3706|山东省|烟台市'
      },
      {
        label: '中国工商银行股份有限公司盐城东亭支行',
        value: '102311066510|3209|江苏省|盐城市'
      },
      {
        label: '中国工商银行盐城市分行',
        value: '102311066903|3209|江苏省|盐城市'
      },
      {
        label: '工商银行响水支行',
        value: '102311168011|3209|江苏省|盐城市'
      },
      {
        label: '中国工商银行响水陈家港支行',
        value: '102311168020|3209|江苏省|盐城市'
      },
      {
        label: '工商银行滨海县支行',
        value: '102311265014|3209|江苏省|盐城市'
      },
      {
        label: '中国工商银行股份有限公司合肥明发广场支行',
        value: '102361002027|3401|安徽省|合肥市'
      },
      {
        label: '中国工商银行股份有限公司合肥清溪路支行',
        value: '102361002035|3401|安徽省|合肥市'
      },
      {
        label: '中国工商银行股份有限公司合肥宁国路支行',
        value: '102361002060|3401|安徽省|合肥市'
      },
      {
        label: '中国工商银行股份有限公司合肥望江东路支行',
        value: '102361002078|3401|安徽省|合肥市'
      },
      {
        label: '中国工商银行股份有限公司合肥海汇支行',
        value: '102361002086|3401|安徽省|合肥市'
      },
      {
        label: '中国工商银行股份有限公司合肥全椒路支行',
        value: '102361002109|3401|安徽省|合肥市'
      },
      {
        label: '中国工商银行股份有限公司合肥三里街支行',
        value: '102361002125|3401|安徽省|合肥市'
      },
      {
        label: '中国工商银行股份有限公司合肥天鹅湖支行',
        value: '102361002133|3401|安徽省|合肥市'
      },
      {
        label: '中国工商银行股份有限公司合肥安庆路支行',
        value: '102361002141|3401|安徽省|合肥市'
      },
      {
        label: '中国工商银行股份有限公司合肥绩溪路支行',
        value: '102361002150|3401|安徽省|合肥市'
      },
      {
        label: '中国工商银行股份有限公司合肥西环广场支行',
        value: '102361002168|3401|安徽省|合肥市'
      },
      {
        label: '中国工商银行股份有限公司合肥黄山路支行',
        value: '102361002176|3401|安徽省|合肥市'
      },
      {
        label: '中国工商银行股份有限公司合肥稻香楼支行',
        value: '102361002184|3401|安徽省|合肥市'
      },
      {
        label: '中国工商银行股份有限公司合肥阜阳北路支行',
        value: '102361002192|3401|安徽省|合肥市'
      },
      {
        label: '中国工商银行股份有限公司合肥三里庵支行',
        value: '102361002205|3401|安徽省|合肥市'
      },
      {
        label: '中国工商银行股份有限公司合肥长江中路支行',
        value: '102361002221|3401|安徽省|合肥市'
      },
      {
        label: '中国工商银行股份有限公司合肥潜山路支行',
        value: '102361002230|3401|安徽省|合肥市'
      },
      {
        label: '中国工商银行股份有限公司合肥屯溪路支行',
        value: '102361002248|3401|安徽省|合肥市'
      },
      {
        label: '中国工商银行股份有限公司合肥三牌楼支行',
        value: '102361002256|3401|安徽省|合肥市'
      },
      {
        label: '中国工商银行股份有限公司合肥滨湖支行',
        value: '102361002264|3401|安徽省|合肥市'
      },
      {
        label: '中国工商银行股份有限公司四平师大支行',
        value: '102243024017|2203|吉林省|四平市'
      },
      {
        label: '中国工商银行股份有限公司四平英城支行',
        value: '102243024130|2203|吉林省|四平市'
      },
      {
        label: '中国工商银行股份有限公司义乌嘉和支行',
        value: '102338702028|3307|浙江省|金华市'
      },
      {
        label: '中国工商银行股份有限公司义乌稠江支行',
        value: '102338702044|3307|浙江省|金华市'
      },
      {
        label: '中国工商银行股份有限公司义乌梅园支行',
        value: '102338702052|3307|浙江省|金华市'
      },
      {
        label: '中国工商银行股份有限公司义乌市民广场支行',
        value: '102338702069|3307|浙江省|金华市'
      },
      {
        label: '中国工商银行股份有限公司义乌商城支行',
        value: '102338702077|3307|浙江省|金华市'
      },
      {
        label: '中国工商银行股份有限公司义乌富商支行',
        value: '102338702085|3307|浙江省|金华市'
      },
      {
        label: '中国工商银行股份有限公司义乌港城支行',
        value: '102338702093|3307|浙江省|金华市'
      },
      {
        label: '中国工商银行股份有限公司义乌词林支行',
        value: '102338702124|3307|浙江省|金华市'
      },
      {
        label: '中国工商银行股份有限公司义乌商博支行',
        value: '102338702149|3307|浙江省|金华市'
      },
      {
        label: '中国工商银行股份有限公司义乌佛堂支行',
        value: '102338702157|3307|浙江省|金华市'
      },
      {
        label: '中国工商银行股份有限公司义乌福田支行',
        value: '102338702165|3307|浙江省|金华市'
      },
      {
        label: '中国工商银行股份有限公司义乌江东支行',
        value: '102338702173|3307|浙江省|金华市'
      },
      {
        label: '中国工商银行股份有限公司义乌丹溪北路支行',
        value: '102338702181|3307|浙江省|金华市'
      },
      {
        label: '中国工商银行股份有限公司义乌商苑支行',
        value: '102338702190|3307|浙江省|金华市'
      },
      {
        label: '中国工商银行股份有限公司义乌行政服务中心分理处',
        value: '102338702204|3307|浙江省|金华市'
      },
      {
        label: '中国工商银行股份有限公司义乌金苑支行',
        value: '102338702212|3307|浙江省|金华市'
      },
      {
        label: '中国工商银行股份有限公司义乌江南支行',
        value: '102338702229|3307|浙江省|金华市'
      },
      {
        label: '中国工商银行股份有限公司义乌苏溪支行',
        value: '102338702237|3307|浙江省|金华市'
      },
      {
        label: '中国工商银行股份有限公司义乌南门分理处',
        value: '102338702261|3307|浙江省|金华市'
      },
      {
        label: '中国工商银行股份有限公司义乌廿三里支行',
        value: '102338702270|3307|浙江省|金华市'
      },
      {
        label: '中国工商银行股份有限公司义乌商城四区支行',
        value: '102338702288|3307|浙江省|金华市'
      },
      {
        label: '中国工商银行股份有限公司义乌后宅支行',
        value: '102338702296|3307|浙江省|金华市'
      },
      {
        label: '中国工商银行股份有限公司义乌贝村路支行',
        value: '102338702307|3307|浙江省|金华市'
      },
      {
        label: '中国工商银行股份有限公司东阳支行',
        value: '102338804008|3307|浙江省|金华市'
      },
      {
        label: '中国工商银行股份有限公司东阳南街支行',
        value: '102338804016|3307|浙江省|金华市'
      },
      {
        label: '中国工商银行股份有限公司东阳商城支行',
        value: '102338804024|3307|浙江省|金华市'
      },
      {
        label: '中国工商银行股份有限公司东阳振兴支行',
        value: '102338804032|3307|浙江省|金华市'
      },
      {
        label: '中国工商银行股份有限公司东阳通江支行',
        value: '102338804057|3307|浙江省|金华市'
      },
      {
        label: '中国工商银行股份有限公司东阳木雕城支行',
        value: '102338804065|3307|浙江省|金华市'
      },
      {
        label: '中国工商银行股份有限公司东阳东站支行',
        value: '102338804090|3307|浙江省|金华市'
      },
      {
        label: '中国工商银行股份有限公司东阳双岘支行',
        value: '102338804104|3307|浙江省|金华市'
      },
      {
        label: '中国工商银行股份有限公司东阳白云分理处',
        value: '102338804129|3307|浙江省|金华市'
      },
      {
        label: '中国工商银行股份有限公司东阳横店支行',
        value: '102338804137|3307|浙江省|金华市'
      },
      {
        label: '中国工商银行股份有限公司东阳江北支行',
        value: '102338804153|3307|浙江省|金华市'
      },
      {
        label: '中国工商银行股份有限公司东阳南马支行',
        value: '102338804161|3307|浙江省|金华市'
      },
      {
        label: '中国工商银行衢州市分行业务处理中心',
        value: '102341020006|3308|浙江省|衢州市'
      },
      {
        label: '中国工商银行股份有限公司衢州分行营业部',
        value: '102341021009|3308|浙江省|衢州市'
      },
      {
        label: '中国工商银行股份有限公司衢州柯城支行',
        value: '102341026002|3308|浙江省|衢州市'
      },
      {
        label: '中国工商银行股份有限公司闽侯三福支行',
        value: '102391053372|3501|福建省|福州市'
      },
      {
        label: '中国工商银行股份有限公司福清福塘支行',
        value: '102391053401|3501|福建省|福州市'
      },
      {
        label: '中国工商银行股份有限公司福清南门支行',
        value: '102391053410|3501|福建省|福州市'
      },
      {
        label: '中国工商银行股份有限公司福清北门支行',
        value: '102391053428|3501|福建省|福州市'
      },
      {
        label: '中国工商银行股份有限公司玉环城中支行',
        value: '102345828127|3310|浙江省|台州市'
      },
      {
        label: '中国工商银行股份有限公司玉环龙溪支行',
        value: '102345828160|3310|浙江省|台州市'
      },
      {
        label: '中国工商银行股份有限公司无锡惠山支行',
        value: '102302002662|3202|江苏省|无锡市'
      },
      {
        label: '中国工商银行股份有限公司无锡东北塘支行',
        value: '102302002679|3202|江苏省|无锡市'
      },
      {
        label: '中国工商银行股份有限公司无锡太湖支行',
        value: '102302002687|3202|江苏省|无锡市'
      },
      {
        label: '中国工商银行股份有限公司无锡玉祁支行',
        value: '102302002695|3202|江苏省|无锡市'
      },
      {
        label: '中国工商银行股份有限公司无锡泰伯支行',
        value: '102302003036|3202|江苏省|无锡市'
      },
      {
        label: '中国工商银行股份有限公司无锡招商城分理处',
        value: '102302003052|3202|江苏省|无锡市'
      },
      {
        label: '中国工商银行无锡春江支行',
        value: '102302003069|3202|江苏省|无锡市'
      },
      {
        label: '中国工商银行股份有限公司无锡江南大学分理处',
        value: '102302003077|3202|江苏省|无锡市'
      },
      {
        label: '中国工商银行股份有限公司无锡新安支行',
        value: '102302003229|3202|江苏省|无锡市'
      },
      {
        label: '中国工商银行股份有限公司扬州文汇支行',
        value: '102312002150|3210|江苏省|扬州市'
      },
      {
        label: '中国工商银行股份有限公司扬州国庆北路支行',
        value: '102312002168|3210|江苏省|扬州市'
      },
      {
        label: '中国工商银行股份有限公司扬州瘦西湖支行',
        value: '102312002184|3210|江苏省|扬州市'
      },
      {
        label: '中国工商银行股份有限公司扬州荷花池支行',
        value: '102312002205|3210|江苏省|扬州市'
      },
      {
        label: '中国工商银行股份有限公司扬州东城支行',
        value: '102312002221|3210|江苏省|扬州市'
      },
      {
        label: '中国工商银行股份有限公司扬州五亭龙支行',
        value: '102312002385|3210|江苏省|扬州市'
      },
      {
        label: '中国工商银行股份有限公司扬州翠岗支行',
        value: '102312002481|3210|江苏省|扬州市'
      },
      {
        label: '中国工商银行股份有限公司江阴周庄支行',
        value: '102302202790|3202|江苏省|无锡市'
      },
      {
        label: '中国工商银行股份有限公司江阴长泾支行',
        value: '102302202812|3202|江苏省|无锡市'
      },
      {
        label: '中国工商银行股份有限公司常州人民路支行',
        value: '102304005915|3204|江苏省|常州市'
      },
      {
        label: '中国工商银行股份有限公司常州环府路支行',
        value: '102304005931|3204|江苏省|常州市'
      },
      {
        label: '中国工商银行常州分行',
        value: '102304099994|3204|江苏省|常州市'
      },
      {
        label: '中国工商银行金坛市支行',
        value: '102304202713|3204|江苏省|常州市'
      },
      {
        label: '中国工商银行股份有限公司金坛思古街支行',
        value: '102304206005|3204|江苏省|常州市'
      },
      {
        label: '中国工商银行股份有限公司金坛经济开发区支行',
        value: '102304206048|3204|江苏省|常州市'
      },
      {
        label: '中国工商银行股份有限公司金坛峨嵋中路支行',
        value: '102304206064|3204|江苏省|常州市'
      },
      {
        label: '中国工商银行股份有限公司金坛金城支行',
        value: '102304206185|3204|江苏省|常州市'
      },
      {
        label: '中国工商银行股份有限公司金坛尧塘支行',
        value: '102304206265|3204|江苏省|常州市'
      },
      {
        label: '中国工商银行股份有限公司金坛直溪支行',
        value: '102304206281|3204|江苏省|常州市'
      },
      {
        label: '中国工商银行股份有限公司溧阳县前支行',
        value: '102304302618|3204|江苏省|常州市'
      },
      {
        label: '中国工商银行溧阳市支行',
        value: '102304302626|3204|江苏省|常州市'
      },
      {
        label: '中国工商银行股份有限公司溧阳西门支行',
        value: '102304302634|3204|江苏省|常州市'
      },
      {
        label: '中国工商银行股份有限公司溧阳东方支行',
        value: '102304306603|3204|江苏省|常州市'
      },
      {
        label: '中国工商银行股份有限公司加格达奇中兴支行',
        value: '102279000163|2327|黑龙江省|大兴安岭地区'
      },
      {
        label: '中国工商银行股份有限公司呼玛支行',
        value: '102279100069|2327|黑龙江省|大兴安岭地区'
      },
      {
        label: '中国工商银行股份有限公司呼玛韩家园分理处',
        value: '102279100157|2327|黑龙江省|大兴安岭地区'
      },
      {
        label: '中国工商银行股份有限公司徐州民乐支行',
        value: '102303003436|3203|江苏省|徐州市'
      },
      {
        label: '工行江苏省徐州世纪路支行',
        value: '102303003444|3203|江苏省|徐州市'
      },
      {
        label: '中国工商银行股份有限公司徐州湖滨新村支行',
        value: '102303003469|3203|江苏省|徐州市'
      },
      {
        label: '中国工商银行徐州梅苑支行',
        value: '102303003493|3203|江苏省|徐州市'
      },
      {
        label: '中国工商银行徐州纺织西路支行',
        value: '102303003508|3203|江苏省|徐州市'
      },
      {
        label: '中国工商银行徐州西苑支行',
        value: '102303003516|3203|江苏省|徐州市'
      },
      {
        label: '中国工商银行股份有限公司徐州矿大支行',
        value: '102303003549|3203|江苏省|徐州市'
      },
      {
        label: '中国工商银行股份有限公司徐州管道支行',
        value: '102303003557|3203|江苏省|徐州市'
      },
      {
        label: '中国工商银行股份有限公司徐州奎园支行',
        value: '102303003565|3203|江苏省|徐州市'
      },
      {
        label: '中国工商银行徐州师大支行',
        value: '102303003573|3203|江苏省|徐州市'
      },
      {
        label: '中国工商银行徐州上院支行',
        value: '102303003612|3203|江苏省|徐州市'
      },
      {
        label: '中国工商银行股份有限公司丰县中阳大道支行',
        value: '102303100047|3203|江苏省|徐州市'
      },
      {
        label: '中国工商银行丰县支行',
        value: '102303102647|3203|江苏省|徐州市'
      },
      {
        label: '中国工商银行股份有限公司丰县凤城支行',
        value: '102303107064|3203|江苏省|徐州市'
      },
      {
        label: '中国工商银行股份有限公司丰县人民路支行',
        value: '102303107136|3203|江苏省|徐州市'
      },
      {
        label: '中国工商银行沛县支行',
        value: '102303200015|3203|江苏省|徐州市'
      },
      {
        label: '中国工商银行徐州大屯支行',
        value: '102303200023|3203|江苏省|徐州市'
      },
      {
        label: '中国工商银行沛县正阳路支行',
        value: '102303200031|3203|江苏省|徐州市'
      },
      {
        label: '中国工商银行沛县姚桥支行',
        value: '102303200066|3203|江苏省|徐州市'
      },
      {
        label: '中国工商银行股份有限公司沛县五一支行',
        value: '102303200099|3203|江苏省|徐州市'
      },
      {
        label: '中国工商银行睢宁县支行',
        value: '102303402633|3203|江苏省|徐州市'
      },
      {
        label: '中国工商银行苏州天翔支行',
        value: '102305013035|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行苏州独墅湖支行',
        value: '102305013043|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行苏州东湖大郡分理处',
        value: '102305013051|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行苏州跨塘支行',
        value: '102305013060|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行苏州临顿路支行',
        value: '102305014056|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行苏州白塔路支行',
        value: '102305014097|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行股份有限公司苏州里河支行',
        value: '102305014110|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行股份有限公司苏州东港分理处',
        value: '102305014128|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行苏州庄先湾支行',
        value: '102305014136|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行苏州万达广场支行',
        value: '102305014169|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行苏州三元支行',
        value: '102305015057|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行苏州新庄分理处',
        value: '102305015090|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行苏州金门路支行',
        value: '102305015153|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行苏州阳山支行',
        value: '102305015161|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行股份有限公司苏州城东分理处',
        value: '102305017047|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行股份有限公司苏州沧浪新城支行',
        value: '102305017055|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行苏州劳动路支行',
        value: '102305017063|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行苏州何山路分理处',
        value: '102305017071|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行分行苏州虎丘分理处',
        value: '102305017926|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行股份有限公司苏州枫桥支行',
        value: '102305018025|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行苏州横塘支行',
        value: '102305018033|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行股份有限公司杭州萧然支行',
        value: '102331002411|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行杭州湖滨支行',
        value: '102331002446|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行杭州曙光路支行',
        value: '102331002454|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行杭州浙大支行',
        value: '102331002462|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行股份有限公司杭州工人路支行',
        value: '102331002479|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行股份有限公司杭州古墩路支行',
        value: '102331002495|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行股份有限公司杭州滨江支行',
        value: '102331000887|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行股份有限公司杭州采荷支行',
        value: '102331000918|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行股份有限公司杭州大关支行',
        value: '102331000926|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行股份有限公司杭州景芳支行',
        value: '102331000975|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行杭州市半山支行',
        value: '102331002001|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行杭州市羊坝头支行',
        value: '102331002010|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行杭州市庆春路支行',
        value: '102331002028|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行杭州市江城支行',
        value: '102331002036|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行杭州市西湖支行',
        value: '102331002044|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行股份有限公司杭州涌金支行',
        value: '102331002052|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行杭州市湖墅支行',
        value: '102331002069|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行杭州市解放路支行',
        value: '102331002077|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行股份有限公司杭州拱宸支行',
        value: '102331002085|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行杭州体育场路支行',
        value: '102331002108|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行股份有限公司杭州分行营业部',
        value: '102331002116|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行杭州市武林支行',
        value: '102331002124|3301|浙江省|杭州市'
      },
      {
        label: '工商银行杭州市分行钱江支行',
        value: '102331002149|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行股份有限公司杭州开元支行',
        value: '102331002157|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行股份有限公司杭州众安支行',
        value: '102331002173|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行股份有限公司东新支行',
        value: '102331002181|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行股份有限公司杭州浣纱支行',
        value: '102331002204|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行杭州市朝晖支行',
        value: '102331002212|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行杭州建国北路支行',
        value: '102331002229|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行杭州市艮山支行',
        value: '102331002237|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行杭州凤起支行',
        value: '102331002253|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行股份有限公司杭州分行业务处理中心',
        value: '102331002261|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行股份有限公司石家庄栾城支行',
        value: '102121000107|1301|河北省|石家庄市'
      },
      {
        label: '中国工商银行股份有限公司石家庄鹿泉支行',
        value: '102121000115|1301|河北省|石家庄市'
      },
      {
        label: '中国工商银行正定支行',
        value: '102121000123|1301|河北省|石家庄市'
      },
      {
        label: '中国工商银行股份有限公司石家庄天翼支行',
        value: '102121000131|1301|河北省|石家庄市'
      },
      {
        label: '中国工商银行石家庄裕华支行',
        value: '102121000158|1301|河北省|石家庄市'
      },
      {
        label: '中国工商银行石家庄高新支行',
        value: '102121000166|1301|河北省|石家庄市'
      },
      {
        label: '中国工商银行股份有限公司石家庄藁城支行',
        value: '102121000174|1301|河北省|石家庄市'
      },
      {
        label: '中国工商银行新乐支行',
        value: '102121000182|1301|河北省|石家庄市'
      },
      {
        label: '中国工商银行元氏支行',
        value: '102121000199|1301|河北省|石家庄市'
      },
      {
        label: '中国工商银行石家庄开发区支行',
        value: '102121000203|1301|河北省|石家庄市'
      },
      {
        label: '中国工商银行井陉支行',
        value: '102121000211|1301|河北省|石家庄市'
      },
      {
        label: '中国工商银行石家庄西苑支行',
        value: '102121000220|1301|河北省|石家庄市'
      },
      {
        label: '中国工商银行石家庄矿区支行',
        value: '102121000238|1301|河北省|石家庄市'
      },
      {
        label: '中国工商银行石家庄建华支行',
        value: '102121000246|1301|河北省|石家庄市'
      },
      {
        label: '中国工商银行石家庄石正支行',
        value: '102121000262|1301|河北省|石家庄市'
      },
      {
        label: '中国工商银行股份有限公司石家庄东胜科技支行',
        value: '102121000279|1301|河北省|石家庄市'
      },
      {
        label: '中国工商银行石家庄建南支行',
        value: '102121000287|1301|河北省|石家庄市'
      },
      {
        label: '中国工商银行赞皇支行',
        value: '102121000295|1301|河北省|石家庄市'
      },
      {
        label: '中国工商银行赵县支行',
        value: '102121000300|1301|河北省|石家庄市'
      },
      {
        label: '中国工商银行辛集支行',
        value: '102121000318|1301|河北省|石家庄市'
      },
      {
        label: '中国工商银行股份有限公司高邑支行',
        value: '102121000326|1301|河北省|石家庄市'
      },
      {
        label: '中国工商银行石家庄光明支行',
        value: '102121000334|1301|河北省|石家庄市'
      },
      {
        label: '中国工商银行股份有限公司行唐支行',
        value: '102121000342|1301|河北省|石家庄市'
      },
      {
        label: '中国工商银行深泽支行',
        value: '102121000359|1301|河北省|石家庄市'
      },
      {
        label: '中国工商银行无极支行',
        value: '102121000367|1301|河北省|石家庄市'
      },
      {
        label: '中国工商银行平山支行',
        value: '102121000375|1301|河北省|石家庄市'
      },
      {
        label: '中国工商银行灵寿支行',
        value: '102121000383|1301|河北省|石家庄市'
      },
      {
        label: '中国工商银行晋州支行',
        value: '102121000391|1301|河北省|石家庄市'
      },
      {
        label: '中国工商银行股份有限公司石家庄胜利支行',
        value: '102121000406|1301|河北省|石家庄市'
      },
      {
        label: '中国工商银行河北省分行计划财务部',
        value: '102121000422|1301|河北省|石家庄市'
      },
      {
        label: '中国工商银行德惠市支行',
        value: '102241000228|2201|吉林省|长春市'
      },
      {
        label: '中国工商银行股份有限公司榆树支行',
        value: '102241000236|2201|吉林省|长春市'
      },
      {
        label: '中国工商银行农安县支行',
        value: '102241000244|2201|吉林省|长春市'
      },
      {
        label: '中国工商银行九台支行',
        value: '102241000252|2201|吉林省|长春市'
      },
      {
        label: '中国工商银行股份有限公司长春文化广场支行',
        value: '102241000269|2201|吉林省|长春市'
      },
      {
        label: '中国工商银行股份有限公司长春丙九路支行',
        value: '102241000277|2201|吉林省|长春市'
      },
      {
        label: '中国工商银行股份有限公司长春四平路支行',
        value: '102241000285|2201|吉林省|长春市'
      },
      {
        label: '中国工商银行股份有限公司长春兴业支行',
        value: '102241000293|2201|吉林省|长春市'
      },
      {
        label: '中国工商银行股份有限公司长春凯旋路支行',
        value: '102241000308|2201|吉林省|长春市'
      },
      {
        label: '中国工商银行股份有限公司长春亚泰大街支行',
        value: '102241000324|2201|吉林省|长春市'
      },
      {
        label: '中国工商银行股份有限公司巴彦淖尔新华街支行',
        value: '102207002087|1508|内蒙古自治区|巴彦淖尔市'
      },
      {
        label: '中国工商银行股份有限公司四平南河支行',
        value: '102243024574|2203|吉林省|四平市'
      },
      {
        label: '中国工商银行股份有限公司四平兴平支行',
        value: '102243024582|2203|吉林省|四平市'
      },
      {
        label: '中国工商银行股份有限公司四平七马路支行',
        value: '102243024898|2203|吉林省|四平市'
      },
      {
        label: '中国工商银行股份有限公司梨树支行',
        value: '102243122314|2203|吉林省|四平市'
      },
      {
        label: '中国工商银行股份有限公司满洲里合作区支行',
        value: '102196208258|1507|内蒙古自治区|呼伦贝尔市'
      },
      {
        label: '中国工商银行股份有限公司满洲里榕欣广场支行',
        value: '102196213796|1507|内蒙古自治区|呼伦贝尔市'
      },
      {
        label: '中国工商银行股份有限公司满洲里中央街支行',
        value: '102196213815|1507|内蒙古自治区|呼伦贝尔市'
      },
      {
        label: '中国工商银行股份有限公司满洲里现代支行',
        value: '102196213938|1507|内蒙古自治区|呼伦贝尔市'
      },
      {
        label: '中国工商银行股份有限公司满洲里南区支行',
        value: '102196213962|1507|内蒙古自治区|呼伦贝尔市'
      },
      {
        label: '中国工商银行扎兰屯市支行吊桥分理处',
        value: '102196303101|1507|内蒙古自治区|呼伦贝尔市'
      },
      {
        label: '中国工商银行扎兰屯市支行',
        value: '102196303546|1507|内蒙古自治区|呼伦贝尔市'
      },
      {
        label: '中国工商银行股份有限公司扎兰屯兰云大厦支行',
        value: '102196328051|1507|内蒙古自治区|呼伦贝尔市'
      },
      {
        label: '中国工商银行内蒙古分行牙克石市支行兴安西街分理处',
        value: '102196403121|1507|内蒙古自治区|呼伦贝尔市'
      },
      {
        label: '中国工商银行内蒙古分行牙克石市支行',
        value: '102196403558|1507|内蒙古自治区|呼伦贝尔市'
      },
      {
        label: '中国工商银行股份有限公司牙克石中央街支行',
        value: '102196428548|1507|内蒙古自治区|呼伦贝尔市'
      },
      {
        label: '中国工商银行股份有限公司牙克石青松路支行',
        value: '102196428597|1507|内蒙古自治区|呼伦贝尔市'
      },
      {
        label: '中国工商银行股份有限公司牙克石林城支行',
        value: '102196428601|1507|内蒙古自治区|呼伦贝尔市'
      },
      {
        label: '中国工商银行股份有限公司阿荣旗支行',
        value: '102196503584|1507|内蒙古自治区|呼伦贝尔市'
      },
      {
        label: '中国工商银行莫力达瓦达斡尔族自治旗支行',
        value: '102196603598|1507|内蒙古自治区|呼伦贝尔市'
      },
      {
        label: '中国工商银行额尔古纳市支行',
        value: '102196703616|1507|内蒙古自治区|呼伦贝尔市'
      },
      {
        label: '中国工商银行根河市支行',
        value: '102196803958|1507|内蒙古自治区|呼伦贝尔市'
      },
      {
        label: '中国工商银行鄂伦春自治旗支行',
        value: '102196903719|1507|内蒙古自治区|呼伦贝尔市'
      },
      {
        label: '中国工商银行鄂温克旗支行',
        value: '102197103706|1507|内蒙古自治区|呼伦贝尔市'
      },
      {
        label: '中国工商银行呼伦贝尔市分行大雁分理处',
        value: '102197103933|1507|内蒙古自治区|呼伦贝尔市'
      },
      {
        label: '中国工商银行鄂温克旗支行伊敏河矿区分理处',
        value: '102197105263|1507|内蒙古自治区|呼伦贝尔市'
      },
      {
        label: '中国工商银行呼伦贝尔市分行营业部巴彦库仁分理处',
        value: '102197403694|1507|内蒙古自治区|呼伦贝尔市'
      },
      {
        label: '中国工商银行股份有限公司乌兰浩特乌兰大街支行',
        value: '102198000081|1522|内蒙古自治区|兴安盟'
      },
      {
        label: '中国工商银行股份公司乌兰浩特和平街支行',
        value: '102198000170|1522|内蒙古自治区|兴安盟'
      },
      {
        label: '中国工商银行乌兰浩特支行会计业务处理中心',
        value: '102198004001|1522|内蒙古自治区|兴安盟'
      },
      {
        label: '中国工商银行股份有限公司乌兰浩特支行',
        value: '102198004044|1522|内蒙古自治区|兴安盟'
      },
      {
        label: '中国工商银行股份有限公司乌兰浩特兴安南路支行',
        value: '102198004124|1522|内蒙古自治区|兴安盟'
      },
      {
        label: '中国工商银行股份有限公司乌兰浩特普惠支行',
        value: '102198004132|1522|内蒙古自治区|兴安盟'
      },
      {
        label: '中国工商银行股份有限公司兴安盟分行',
        value: '102198004778|1522|内蒙古自治区|兴安盟'
      },
      {
        label: '中国工商银行股份有限公司乌兰浩特新桥中街支行',
        value: '102198004884|1522|内蒙古自治区|兴安盟'
      },
      {
        label: '中国工商银行股份有限公司科右中旗支行',
        value: '102198304019|1522|内蒙古自治区|兴安盟'
      },
      {
        label: '中国工商银行股份有限公司扎赉特旗支行',
        value: '102198403652|1522|内蒙古自治区|兴安盟'
      },
      {
        label: '中国工商银行股份有限公司突泉支行',
        value: '102198504990|1522|内蒙古自治区|兴安盟'
      },
      {
        label: '中国工商银行股份有限公司阿尔山温泉街支行',
        value: '102198604152|1522|内蒙古自治区|兴安盟'
      },
      {
        label: '中国工商银行股份有限公司阿尔山支行',
        value: '102198604249|1522|内蒙古自治区|兴安盟'
      },
      {
        label: '中国工商银行股份有限公司通辽永清大街支行',
        value: '102199000026|1505|内蒙古自治区|通辽市'
      },
      {
        label: '中国工商银行股份有限公司通辽交通路支行',
        value: '102199000059|1505|内蒙古自治区|通辽市'
      },
      {
        label: '中国工商银行股份有限公司通辽明仁支行',
        value: '102199000067|1505|内蒙古自治区|通辽市'
      },
      {
        label: '中国工商银行股份有限公司通辽中心支行',
        value: '102199000075|1505|内蒙古自治区|通辽市'
      },
      {
        label: '中国工商银行唐山天元支行',
        value: '102124003635|1302|河北省|唐山市'
      },
      {
        label: '中国工商银行股份有限公司唐山凤之梦支行',
        value: '102124004134|1302|河北省|唐山市'
      },
      {
        label: '中国工商银行滦县支行',
        value: '102124303778|1302|河北省|唐山市'
      },
      {
        label: '中国工商银行滦南支行',
        value: '102124403834|1302|河北省|唐山市'
      },
      {
        label: '中国工商银行唐山海港支行',
        value: '102124503803|1302|河北省|唐山市'
      },
      {
        label: '中国工商银行乐亭支行',
        value: '102124503895|1302|河北省|唐山市'
      },
      {
        label: '中国工商银行迁安支行',
        value: '102124603641|1302|河北省|唐山市'
      },
      {
        label: '中国工商银行股份有限公司迁安上东水郡支行',
        value: '102124603836|1302|河北省|唐山市'
      },
      {
        label: '中国工商银行股份有限公司迁安惠宁支行',
        value: '102124603844|1302|河北省|唐山市'
      },
      {
        label: '中国工商银行股份有限公司迁安明珠支行',
        value: '102124603877|1302|河北省|唐山市'
      },
      {
        label: '中国工商银行伊春市分行带岭支行',
        value: '102271002373|2307|黑龙江省|伊春市'
      },
      {
        label: '中国工商银行股份有限公司左云支行',
        value: '102163704834|1402|山西省|大同市'
      },
      {
        label: '中国工商银行股份有限公司右玉支行',
        value: '102163801925|1402|山西省|大同市'
      },
      {
        label: '中国工商银行股份有限公司常州春江支行',
        value: '102304004057|3204|江苏省|常州市'
      },
      {
        label: '中国工商银行股份有限公司常州太湖明珠苑支行',
        value: '102304004081|3204|江苏省|常州市'
      },
      {
        label: '中国工商银行股份有限公司常州薛家支行',
        value: '102304004090|3204|江苏省|常州市'
      },
      {
        label: '中国工商银行股份有限公司常州孟河支行',
        value: '102304004104|3204|江苏省|常州市'
      },
      {
        label: '中国工商银行股份有限公司常州西夏墅支行',
        value: '102304004112|3204|江苏省|常州市'
      },
      {
        label: '中国工商银行股份有限公司常州龙虎塘支行',
        value: '102304004129|3204|江苏省|常州市'
      },
      {
        label: '中国工商银行股份有限公司常州劳动中路支行',
        value: '102304004274|3204|江苏省|常州市'
      },
      {
        label: '中国工商银行股份有限公司常州雕庄支行',
        value: '102304004547|3204|江苏省|常州市'
      },
      {
        label: '中国工商银行常州市南大街支行',
        value: '102304004792|3204|江苏省|常州市'
      },
      {
        label: '中国工商银行股份有限公司杭州益乐支行',
        value: '102331000612|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行杭州新宫桥支行',
        value: '102331005294|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行杭州茅廊巷支行',
        value: '102331005309|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行股份有限公司杭州分行票据中心',
        value: '102331005317|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行股份有限公司杭州时代广场支行',
        value: '102331005325|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行股份有限公司临安钱王支行',
        value: '102331005376|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行股份有限公司杭州东湖支行',
        value: '102331005384|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行股份有限公司杭州良渚支行',
        value: '102331005421|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行股份有限公司杭州彩虹城支行',
        value: '102331005448|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行股份有限公司杭州临北支行',
        value: '102331005456|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行股份有限公司临安於潜支行',
        value: '102331005464|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行股份有限公司杭州江南新区支行',
        value: '102331005544|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行股份有限公司富阳春江支行',
        value: '102331005569|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行股份有限公司富阳育才支行',
        value: '102331005585|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行股份有限公司淳安排岭支行',
        value: '102331005761|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行杭州市余杭支行',
        value: '102331008311|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行股份有限公司杭州塘栖支行',
        value: '102331008320|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行股份有限公司杭州瓶窑支行',
        value: '102331008338|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行股份有限公司杭州西园支行',
        value: '102331008346|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行股份有限公司杭州闲林支行',
        value: '102331008354|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行临安市支行',
        value: '102331008514|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行股份有限公司临安昌化支行',
        value: '102331008539|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行富阳市支行',
        value: '102331008717|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行股份有限公司富阳文教路支行',
        value: '102331008821|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行富阳市支行新登分理处',
        value: '102331008872|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行桐庐县支行',
        value: '102331008910|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行桐庐县支行三合分理处',
        value: '102331008936|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行桐庐县支行富春江分理处',
        value: '102331008944|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行股份有限公司萧山分行',
        value: '102331009011|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行杭州市萧山支行临浦分理处',
        value: '102331009020|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行股份有限公司太原胜利桥支行',
        value: '102161000365|1401|山西省|太原市'
      },
      {
        label: '中国工商银行股份有限公司太原城建支行',
        value: '102161000373|1401|山西省|太原市'
      },
      {
        label: '中国工商银行股份有限公司太原平阳路支行',
        value: '102161000381|1401|山西省|太原市'
      },
      {
        label: '中国工商银行股份有限公司太原兴华街支行',
        value: '102161000390|1401|山西省|太原市'
      },
      {
        label: '中国工商银行股份有限公司太原湖滨支行',
        value: '102161000404|1401|山西省|太原市'
      },
      {
        label: '中国工商银行股份有限公司太原并州支行',
        value: '102161000412|1401|山西省|太原市'
      },
      {
        label: '中国工商银行股份有限公司太原府东街支行',
        value: '102161000429|1401|山西省|太原市'
      },
      {
        label: '中国工商银行股份有限公司太原新东方支行',
        value: '102161000445|1401|山西省|太原市'
      },
      {
        label: '中国工商银行股份有限公司太原并州南路支行',
        value: '102161000496|1401|山西省|太原市'
      },
      {
        label: '中国工商银行股份有限公司太原坞城路支行',
        value: '102161000507|1401|山西省|太原市'
      },
      {
        label: '中国工商银行股份有限公司太原建设南路支行',
        value: '102161000515|1401|山西省|太原市'
      },
      {
        label: '中国工商银行股份有限公司太原并州北路支行',
        value: '102161000759|1401|山西省|太原市'
      },
      {
        label: '中国工商银行股份有限公司太原丽华西路支行',
        value: '102161000847|1401|山西省|太原市'
      },
      {
        label: '中国工商银行股份有限公司太原茂盛支行',
        value: '102161000919|1401|山西省|太原市'
      },
      {
        label: '中国工商银行股份有限公司太原千峰北路支行',
        value: '102161000927|1401|山西省|太原市'
      },
      {
        label: '中国工商银行股份有限公司太原千峰南路支行',
        value: '102161000935|1401|山西省|太原市'
      },
      {
        label: '中国工商银行股份有限公司绥芬河绥远支行',
        value: '102275732907|2310|黑龙江省|牡丹江市'
      },
      {
        label: '中国工商银行鸡西分行密山市支行',
        value: '102275802802|2310|黑龙江省|牡丹江市'
      },
      {
        label: '中国工商银行股份有限公司密山兴凯湖支行',
        value: '102275802819|2310|黑龙江省|牡丹江市'
      },
      {
        label: '中国工商银行股份有限公司绥化中兴支行',
        value: '102276000029|2312|黑龙江省|绥化市'
      },
      {
        label: '中国工商银行巷头分理处',
        value: '102302303004|3202|江苏省|无锡市'
      },
      {
        label: '中国工商银行股份有限公司宜兴周铁支行',
        value: '102302303471|3202|江苏省|无锡市'
      },
      {
        label: '中国工商银行秦皇岛东区支行',
        value: '102126005023|1303|河北省|秦皇岛市'
      },
      {
        label: '中国工商银行秦皇岛金新支行',
        value: '102126005040|1303|河北省|秦皇岛市'
      },
      {
        label: '中国工商银行秦皇岛燕山支行',
        value: '102126005058|1303|河北省|秦皇岛市'
      },
      {
        label: '中国工商银行巨鹿支行',
        value: '102133108162|1305|河北省|邢台市'
      },
      {
        label: '中国工商银行新河支行',
        value: '102133208249|1305|河北省|邢台市'
      },
      {
        label: '中国工商银行广宗支行',
        value: '102133308092|1305|河北省|邢台市'
      },
      {
        label: '中国工商银行平乡支行',
        value: '102133408075|1305|河北省|邢台市'
      },
      {
        label: '中国工商银行股份有限公司通辽东城支行',
        value: '102199000091|1505|内蒙古自治区|通辽市'
      },
      {
        label: '中国工商银行股份有限公司通辽霍林河大街支行',
        value: '102199000139|1505|内蒙古自治区|通辽市'
      },
      {
        label: '中国工商银行股份有限公司通辽铁南支行',
        value: '102199000155|1505|内蒙古自治区|通辽市'
      },
      {
        label: '中国工商银行股份有限公司通辽钱隆支行',
        value: '102199000171|1505|内蒙古自治区|通辽市'
      },
      {
        label: '中国工商银行股份有限公司通辽民主支行',
        value: '102199000198|1505|内蒙古自治区|通辽市'
      },
      {
        label: '中国工商银行股份有限公司通辽西拉木伦支行',
        value: '102199000219|1505|内蒙古自治区|通辽市'
      },
      {
        label: '中国工商银行股份有限公司通辽建国支行',
        value: '102199000227|1505|内蒙古自治区|通辽市'
      },
      {
        label: '中国工商银行股份有限公司通辽新华支行',
        value: '102199000309|1505|内蒙古自治区|通辽市'
      },
      {
        label: '中国工商银行股份有限公司通辽经济技术开发区支行',
        value: '102199000384|1505|内蒙古自治区|通辽市'
      },
      {
        label: '中国工商银行通辽分行业务处理中心',
        value: '102199005006|1505|内蒙古自治区|通辽市'
      },
      {
        label: '中国工商银行股份有限公司霍林郭勒支行',
        value: '102199200408|1505|内蒙古自治区|通辽市'
      },
      {
        label: '中国工商银行股份有限公司科左后旗支行',
        value: '102199400633|1505|内蒙古自治区|通辽市'
      },
      {
        label: '中国工商银行股份有限公司开鲁支行',
        value: '102199500520|1505|内蒙古自治区|通辽市'
      },
      {
        label: '中国工商银行股份有限公司奈曼旗支行',
        value: '102199700584|1505|内蒙古自治区|通辽市'
      },
      {
        label: '中国工商银行股份有限公司扎鲁特旗支行',
        value: '102199800483|1505|内蒙古自治区|通辽市'
      },
      {
        label: '中国工商银行股份有限公司多伦东凉亭路支行',
        value: '102201000096|1525|内蒙古自治区|锡林郭勒盟'
      },
      {
        label: '中国工商银行股份有限公司锡林浩特二中支行',
        value: '102201000107|1525|内蒙古自治区|锡林郭勒盟'
      },
      {
        label: '中国工商银行股份有限公司锡林浩特宝石根街支行',
        value: '102201000115|1525|内蒙古自治区|锡林郭勒盟'
      },
      {
        label: '中国工商银行股份有限公司锡林浩特振兴支行',
        value: '102201000140|1525|内蒙古自治区|锡林郭勒盟'
      },
      {
        label: '中国工商银行股份有限公司锡林浩特那达慕西街支行',
        value: '102201000158|1525|内蒙古自治区|锡林郭勒盟'
      },
      {
        label: '中国工商银行股份有限公司锡林浩特支行',
        value: '102201003481|1525|内蒙古自治区|锡林郭勒盟'
      },
      {
        label: '中国工商银行股份有限公司锡林郭勒盟分行',
        value: '102201003529|1525|内蒙古自治区|锡林郭勒盟'
      },
      {
        label: '中国工商银行股份有限公司锡林浩特锡林东街支行',
        value: '102201005299|1525|内蒙古自治区|锡林郭勒盟'
      },
      {
        label: '中国工商银行股份有限公司内蒙古自治区分行',
        value: '102191000046|1501|内蒙古自治区|呼和浩特市'
      },
      {
        label: '中国工商银行股份有限公司呼和浩特市光明支行',
        value: '102191000079|1501|内蒙古自治区|呼和浩特市'
      },
      {
        label: '中国工商银行呼和浩特市托克托县支行',
        value: '102191000087|1501|内蒙古自治区|呼和浩特市'
      },
      {
        label: '中国工商银行呼和浩特市土默特左旗支行',
        value: '102191000095|1501|内蒙古自治区|呼和浩特市'
      },
      {
        label: '中国工商银行呼和浩特市大北街支行',
        value: '102191000100|1501|内蒙古自治区|呼和浩特市'
      },
      {
        label: '中国工商银行股份有限公司呼和浩特市民族商场支行',
        value: '102191000126|1501|内蒙古自治区|呼和浩特市'
      },
      {
        label: '中国工商银行股份有限公司呼和浩特市大南街支行',
        value: '102191000134|1501|内蒙古自治区|呼和浩特市'
      },
      {
        label: '中国工商银行股份有限公司呼和浩特会展中心支行',
        value: '102191000159|1501|内蒙古自治区|呼和浩特市'
      },
      {
        label: '中国工商银行呼和浩特市车站支行',
        value: '102191000206|1501|内蒙古自治区|呼和浩特市'
      },
      {
        label: '中国工商银行呼和浩特市武川县支行',
        value: '102191000214|1501|内蒙古自治区|呼和浩特市'
      },
      {
        label: '中国工商银行股份有限公司呼和浩特市海西路支行',
        value: '102191000222|1501|内蒙古自治区|呼和浩特市'
      },
      {
        label: '中国工商银行股份有限公司呼和浩特金色华府支行',
        value: '102191000239|1501|内蒙古自治区|呼和浩特市'
      },
      {
        label: '中国工商银行呼和浩特市新城东街支行',
        value: '102191000255|1501|内蒙古自治区|呼和浩特市'
      },
      {
        label: '中国工商银行股份有限公司呼和浩特市钢铁路支行',
        value: '102191000263|1501|内蒙古自治区|呼和浩特市'
      },
      {
        label: '中国工商银行呼和浩特市新城支行',
        value: '102191000302|1501|内蒙古自治区|呼和浩特市'
      },
      {
        label: '中国工商银行股份有限公司呼和浩特市东门外支行',
        value: '102191000327|1501|内蒙古自治区|呼和浩特市'
      },
      {
        label: '中国工商银行股份有限公司呼和浩特市呼和佳地支行',
        value: '102191000335|1501|内蒙古自治区|呼和浩特市'
      },
      {
        label: '中国工商银行股份有限公司呼和浩特嘉林小区支行',
        value: '102191000343|1501|内蒙古自治区|呼和浩特市'
      },
      {
        label: '中国工商银行昆山市樾河支行',
        value: '102305223183|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行股份有限公司辽阳佟二堡支行',
        value: '102231200568|2110|辽宁省|辽阳市'
      },
      {
        label: '中国工商银行股份有限公司巴彦淖尔团结南路支行',
        value: '102207002095|1508|内蒙古自治区|巴彦淖尔市'
      },
      {
        label: '中国工商银行股份有限公司巴彦淖尔胜利南路支行',
        value: '102207002271|1508|内蒙古自治区|巴彦淖尔市'
      },
      {
        label: '中国工商银行股份有限公司巴彦淖尔车站支行',
        value: '102207005270|1508|内蒙古自治区|巴彦淖尔市'
      },
      {
        label: '中国工商银行股份有限公司巴彦淖尔新华西街支行',
        value: '102207005606|1508|内蒙古自治区|巴彦淖尔市'
      },
      {
        label: '中国工商银行股份有限公司五原西街支行',
        value: '102207200363|1508|内蒙古自治区|巴彦淖尔市'
      },
      {
        label: '中国工商银行股份有限公司五原支行',
        value: '102207205100|1508|内蒙古自治区|巴彦淖尔市'
      },
      {
        label: '中国工商银行股份有限公司磴口东风支行',
        value: '102207300231|1508|内蒙古自治区|巴彦淖尔市'
      },
      {
        label: '中国工商银行股份有限公司磴口支行',
        value: '102207303244|1508|内蒙古自治区|巴彦淖尔市'
      },
      {
        label: '中国工商银行股份有限公司乌拉特前旗支行',
        value: '102207406265|1508|内蒙古自治区|巴彦淖尔市'
      },
      {
        label: '中国工商银行股份有限公司常州晋陵支行',
        value: '102304004805|3204|江苏省|常州市'
      },
      {
        label: '中国工商银行江苏省南通分行营业部',
        value: '102306000019|3206|江苏省|南通市'
      },
      {
        label: '中国工商银行股份有限公司南通龙王桥支行',
        value: '102306080245|3206|江苏省|南通市'
      },
      {
        label: '中国工商银行股份有限公司兴城温泉支行',
        value: '102227700036|2107|辽宁省|锦州市'
      },
      {
        label: '中国工商银行股份有限公司兴城首阳支行',
        value: '102227700069|2107|辽宁省|锦州市'
      },
      {
        label: '中国工商银行股份有限公司营口通惠支行',
        value: '102228000022|2108|辽宁省|营口市'
      },
      {
        label: '中国工商银行股份有限公司绥化西直路支行',
        value: '102276000037|2312|黑龙江省|绥化市'
      },
      {
        label: '中国工商银行股份有限公司绥化黄河路支行',
        value: '102276000070|2312|黑龙江省|绥化市'
      },
      {
        label: '中国工商银行股份有限公司原阳和平街支行',
        value: '102498312262|4107|河南省|新乡市'
      },
      {
        label: '中国工商银行股份有限公司大同支行',
        value: '102163904957|1402|山西省|大同市'
      },
      {
        label: '中国工商银行股份有限公司姜堰下坝支行',
        value: '102313272021|3212|江苏省|泰州市'
      },
      {
        label: '中国工商银行股份有限公司姜堰城南支行',
        value: '102313272030|3212|江苏省|泰州市'
      },
      {
        label: '中国工商银行姜堰西大街支行',
        value: '102313272048|3212|江苏省|泰州市'
      },
      {
        label: '中国工商银行股份有限公司姜堰溱潼支行',
        value: '102313272056|3212|江苏省|泰州市'
      },
      {
        label: '中国工商银行股份有限公司姜堰华东五金城支行',
        value: '102313272064|3212|江苏省|泰州市'
      },
      {
        label: '中国工商银行股份有限公司姜堰阳光支行',
        value: '102313278061|3212|江苏省|泰州市'
      },
      {
        label: '中国工商银行股份有限公司姜堰支行友谊分理处',
        value: '102313278096|3212|江苏省|泰州市'
      },
      {
        label: '中国工商银行股份有限公司镇江南大街支行',
        value: '102314001021|3211|江苏省|镇江市'
      },
      {
        label: '中国工商银行镇江市分行营业部',
        value: '102314001030|3211|江苏省|镇江市'
      },
      {
        label: '中国工商银行股份有限公司镇江永安路支行',
        value: '102314001089|3211|江苏省|镇江市'
      },
      {
        label: '中国工商银行镇江市分行业务处理中心',
        value: '102314001101|3211|江苏省|镇江市'
      },
      {
        label: '中国工商银行股份有限公司镇江润州支行',
        value: '102314005009|3211|江苏省|镇江市'
      },
      {
        label: '中国工商银行股份有限公司镇江黄山南路支行',
        value: '102314005025|3211|江苏省|镇江市'
      },
      {
        label: '中国工商银行股份有限公司镇江长江路支行',
        value: '102314005033|3211|江苏省|镇江市'
      },
      {
        label: '中国工商银行股份制有限公司镇江镇句支行',
        value: '102314005574|3211|江苏省|镇江市'
      },
      {
        label: '中国工商银行股份有限公司镇江新区支行',
        value: '102314006001|3211|江苏省|镇江市'
      },
      {
        label: '中国工商银行股份有限公司镇江大港支行',
        value: '102314006069|3211|江苏省|镇江市'
      },
      {
        label: '中国工商银行股份有限公司镇江科技新城支行',
        value: '102314006500|3211|江苏省|镇江市'
      },
      {
        label: '中国工商银行股份有限公司镇江学府支行',
        value: '102314006534|3211|江苏省|镇江市'
      },
      {
        label: '中国工商银行镇江市谏壁支行',
        value: '102314007004|3211|江苏省|镇江市'
      },
      {
        label: '中国工商银行镇江市中山支行',
        value: '102314008007|3211|江苏省|镇江市'
      },
      {
        label: '中国工商银行股份有限公司镇江丹徒支行',
        value: '102314008023|3211|江苏省|镇江市'
      },
      {
        label: '中国工商银行股份有限公司镇江南徐支行',
        value: '102314008031|3211|江苏省|镇江市'
      },
      {
        label: '中国工商银行股份有限公司镇江江苏大学支行',
        value: '102314008058|3211|江苏省|镇江市'
      },
      {
        label: '中国工商银行股份有限公司镇江高资支行',
        value: '102314008517|3211|江苏省|镇江市'
      },
      {
        label: '中国工商银行股份制有限公司镇江东门支行',
        value: '102314008816|3211|江苏省|镇江市'
      },
      {
        label: '中国工商银行股份有限公司镇江五条街支行',
        value: '102314008849|3211|江苏省|镇江市'
      },
      {
        label: '中国工商银行股份有限公司镇江京口支行',
        value: '102314008857|3211|江苏省|镇江市'
      },
      {
        label: '中国工商银行股份有限公司鄂尔多斯东兴支行',
        value: '102205000165|1506|内蒙古自治区|鄂尔多斯市'
      },
      {
        label: '中国工商银行股份有限公司鄂尔多斯巨融支行',
        value: '102205000190|1506|内蒙古自治区|鄂尔多斯市'
      },
      {
        label: '中国工商银行股份有限公司鄂尔多斯万融支行',
        value: '102205000237|1506|内蒙古自治区|鄂尔多斯市'
      },
      {
        label: '中国工商银行股份有限公司鄂尔多斯吉雅支行',
        value: '102205000270|1506|内蒙古自治区|鄂尔多斯市'
      },
      {
        label: '中国工商银行股份有限公司鄂尔多斯宝东支行',
        value: '102205000288|1506|内蒙古自治区|鄂尔多斯市'
      },
      {
        label: '中国工商银行股份有限公司鄂尔多斯西街支行',
        value: '102205000323|1506|内蒙古自治区|鄂尔多斯市'
      },
      {
        label: '中国工商银行东胜支行准格尔南路分理处',
        value: '102205000526|1506|内蒙古自治区|鄂尔多斯市'
      },
      {
        label: '中国工商银行股份有限公司鄂尔多斯东绒支行',
        value: '102205005466|1506|内蒙古自治区|鄂尔多斯市'
      },
      {
        label: '中国工商银行鄂尔多斯分行会计业务处理中心',
        value: '102205008006|1506|内蒙古自治区|鄂尔多斯市'
      },
      {
        label: '中国工商银行鄂尔多斯分行东胜支行',
        value: '102205008014|1506|内蒙古自治区|鄂尔多斯市'
      },
      {
        label: '中国工商银行股份有限公司鄂尔多斯东颐支行',
        value: '102205008160|1506|内蒙古自治区|鄂尔多斯市'
      },
      {
        label: '中国工商银行鄂尔多斯分行伊金霍洛西街支行',
        value: '102205008194|1506|内蒙古自治区|鄂尔多斯市'
      },
      {
        label: '中国工商银行股份有限公司鄂尔多斯猛格支行',
        value: '102205008217|1506|内蒙古自治区|鄂尔多斯市'
      },
      {
        label: '中国工商银行股份有限公司鄂尔多斯锦绣苑支行',
        value: '102205008268|1506|内蒙古自治区|鄂尔多斯市'
      },
      {
        label: '中国工商银行股份有限公司达拉特响沙支行',
        value: '102205200020|1506|内蒙古自治区|鄂尔多斯市'
      },
      {
        label: '中国工商银行股份有限公司达拉特迎宾支行',
        value: '102205200054|1506|内蒙古自治区|鄂尔多斯市'
      },
      {
        label: '中国工商银行股份有限公司达拉特融丰支行',
        value: '102205200062|1506|内蒙古自治区|鄂尔多斯市'
      },
      {
        label: '中国工商银行达拉特旗支行',
        value: '102205208128|1506|内蒙古自治区|鄂尔多斯市'
      },
      {
        label: '中国工商银行股份有限公司鄂尔多斯商业街支行',
        value: '102205300029|1506|内蒙古自治区|鄂尔多斯市'
      },
      {
        label: '中国工商银行股份有限公司鄂尔多斯薛家湾支行',
        value: '102205300037|1506|内蒙古自治区|鄂尔多斯市'
      },
      {
        label: '中国工商银行股份有限公司呼和浩特诺和木勒支行',
        value: '102191011069|1501|内蒙古自治区|呼和浩特市'
      },
      {
        label: '中国工商银行股份有限公司呼和浩特大学西路支行',
        value: '102191011253|1501|内蒙古自治区|呼和浩特市'
      },
      {
        label: '中国工商银行呼和浩特市海拉尔东路支行',
        value: '102191011307|1501|内蒙古自治区|呼和浩特市'
      },
      {
        label: '中国工商银行股份有限公司呼和浩特锡林路南口支行',
        value: '102191011567|1501|内蒙古自治区|呼和浩特市'
      },
      {
        label: '中国工商银行股份有限公司呼和浩特新建东街支行',
        value: '102191011591|1501|内蒙古自治区|呼和浩特市'
      },
      {
        label: '中国工商银行阜新银通支行',
        value: '102229000104|2109|辽宁省|阜新市'
      },
      {
        label: '中国工商银行股份有限公司阜新迎宾大街支行',
        value: '102229000161|2109|辽宁省|阜新市'
      },
      {
        label: '中国工商银行股份有限公司阜新滨河支行',
        value: '102229000188|2109|辽宁省|阜新市'
      },
      {
        label: '中国工商银行阜新建新支行',
        value: '102229000196|2109|辽宁省|阜新市'
      },
      {
        label: '中国工商银行阜新中兴支行',
        value: '102229000936|2109|辽宁省|阜新市'
      },
      {
        label: '中国工商银行股份有限公司阜新东门外支行',
        value: '102229001020|2109|辽宁省|阜新市'
      },
      {
        label: '中国工商银行股份有限公司阜新经纬路支行',
        value: '102229001038|2109|辽宁省|阜新市'
      },
      {
        label: '中国工商银行股份有限公司阜新电厂支行',
        value: '102229002102|2109|辽宁省|阜新市'
      },
      {
        label: '中国工商银行股份有限公司阜新高德支行',
        value: '102229002119|2109|辽宁省|阜新市'
      },
      {
        label: '中国工商银行股份有限公司阜新煤海支行',
        value: '102229002127|2109|辽宁省|阜新市'
      },
      {
        label: '中国工商银行股份有限公司阜新城南支行',
        value: '102229002135|2109|辽宁省|阜新市'
      },
      {
        label: '中国工商银行股份有限公司阜新街基支行',
        value: '102229003404|2109|辽宁省|阜新市'
      },
      {
        label: '中国工商银行股份有限公司阜新站前支行',
        value: '102229004307|2109|辽宁省|阜新市'
      },
      {
        label: '中国工商银行股份有限公司阜新西部支行',
        value: '102229004323|2109|辽宁省|阜新市'
      },
      {
        label: '中国工商银行股份有限公司阜新西铁支行',
        value: '102229004331|2109|辽宁省|阜新市'
      },
      {
        label: '中国工商银行股份有限公司阜新中部支行',
        value: '102229004340|2109|辽宁省|阜新市'
      },
      {
        label: '中国工商银行阜新分行业务处理中心',
        value: '102229005004|2109|辽宁省|阜新市'
      },
      {
        label: '中国工商银行股份有限公司阜新菜市街支行',
        value: '102229006200|2109|辽宁省|阜新市'
      },
      {
        label: '中国工商银行股份有限公司塔河支行',
        value: '102279200078|2327|黑龙江省|大兴安岭地区'
      },
      {
        label: '中国工商银行股份有限公司十八站支行',
        value: '102279200086|2327|黑龙江省|大兴安岭地区'
      },
      {
        label: '中国工商银行股份有限公司漠河支行',
        value: '102279300095|2327|黑龙江省|大兴安岭地区'
      },
      {
        label: '中国工商银行股份有限公司图强支行',
        value: '102279300100|2327|黑龙江省|大兴安岭地区'
      },
      {
        label: '中国工商银行股份有限公司阿木尔支行',
        value: '102279300118|2327|黑龙江省|大兴安岭地区'
      },
      {
        label: '中国工商银行股份有限公司漠河北极支行',
        value: '102279301811|2327|黑龙江省|大兴安岭地区'
      },
      {
        label: '中国工商银行股份有限公司包头南排道支行',
        value: '102192000878|1502|内蒙古自治区|包头市'
      },
      {
        label: '中国工商银行佳木斯分行中心支行',
        value: '102269002116|2306|黑龙江省|大庆市'
      },
      {
        label: '中国工商银行佳木斯分行长安支行',
        value: '102269002124|2306|黑龙江省|大庆市'
      },
      {
        label: '中国工商银行盘锦市分行业务处理中心',
        value: '102232000015|2111|辽宁省|盘锦市'
      },
      {
        label: '中国工商银行盘锦市渤海支行',
        value: '102232000023|2111|辽宁省|盘锦市'
      },
      {
        label: '中国工商银行盘锦市新工支行',
        value: '102232000058|2111|辽宁省|盘锦市'
      },
      {
        label: '中国工商银行盘锦市城建支行',
        value: '102232000099|2111|辽宁省|盘锦市'
      },
      {
        label: '中国工商银行盘锦茨采支行',
        value: '102232000111|2111|辽宁省|盘锦市'
      },
      {
        label: '中国工商银行盘锦高升支行',
        value: '102232000120|2111|辽宁省|盘锦市'
      },
      {
        label: '中国工商银行盘锦市欢喜岭支行',
        value: '102232000146|2111|辽宁省|盘锦市'
      },
      {
        label: '中国工商银行盘锦市辽河油田支行',
        value: '102232000179|2111|辽宁省|盘锦市'
      },
      {
        label: '中国工商银行股份有限公司盘锦兴顺支行',
        value: '102232000187|2111|辽宁省|盘锦市'
      },
      {
        label: '中国工商银行股份有限公司盘锦世纪支行',
        value: '102232000195|2111|辽宁省|盘锦市'
      },
      {
        label: '中国工商银行股份有限公司盘锦新广厦支行',
        value: '102232000200|2111|辽宁省|盘锦市'
      },
      {
        label: '中国工商银行股份有限公司盘锦兴油支行',
        value: '102232000234|2111|辽宁省|盘锦市'
      },
      {
        label: '中国工商银行常熟招商支行',
        value: '102305502528|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行常熟五星支行',
        value: '102305502536|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行常熟东南支行',
        value: '102305502552|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行常熟梅李支行',
        value: '102305502569|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行常熟支塘支行',
        value: '102305502585|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行常熟新颜分理处',
        value: '102305525077|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行常熟枫林支行',
        value: '102305525085|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行常熟新世纪支行',
        value: '102305525173|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行股份有限公司常熟商城中路支行',
        value: '102305525212|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行股份有限公司常熟滨江支行',
        value: '102305525237|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行常熟海虞支行',
        value: '102305525261|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行常熟虞山支行',
        value: '102305525270|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行常熟招商南路支行',
        value: '102305525288|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行常熟辛庄支行',
        value: '102305525296|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行常熟沙家浜支行',
        value: '102305525307|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行常熟古里支行',
        value: '102305525315|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行股份有限公司常熟碧溪支行',
        value: '102305525331|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行股份有限公司常熟尚湖支行',
        value: '102305525340|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行股份有限公司常熟董浜支行',
        value: '102305525358|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行张家港中兴支行',
        value: '102305601733|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行股份有限公司张家港分行',
        value: '102305602708|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行张家港后塍支行',
        value: '102305602716|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行张家港港区支行',
        value: '102305602724|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行张家港乐余支行',
        value: '102305602732|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行张家港锦丰支行',
        value: '102305602757|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行张家港塘桥支行',
        value: '102305602765|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行股份有限公司张家港东城支行',
        value: '102305602773|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行张家港园林路分理处',
        value: '102305602804|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行张家港经济开发区支行',
        value: '102305602812|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行张家港市大新分理处',
        value: '102305602845|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行张家港市保税区支行',
        value: '102305602853|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行张家港城北支行',
        value: '102305602861|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行股份有限公司镇江花山湾支行',
        value: '102314008873|3211|江苏省|镇江市'
      },
      {
        label: '中国工商银行股份有限公司镇江象山支行',
        value: '102314008881|3211|江苏省|镇江市'
      },
      {
        label: '中国工商银行股份有限公司镇江北门支行',
        value: '102314008890|3211|江苏省|镇江市'
      },
      {
        label: '中国工商银行股份有限公司镇江朱方支行',
        value: '102314009000|3211|江苏省|镇江市'
      },
      {
        label: '中国工商银行股份有限公司镇江丁卯桥支行',
        value: '102314009026|3211|江苏省|镇江市'
      },
      {
        label: '中国工商银行股份有限公司镇江桃花坞支行',
        value: '102314009059|3211|江苏省|镇江市'
      },
      {
        label: '中国工商银行股份有限公司丹阳支行',
        value: '102314102105|3211|江苏省|镇江市'
      },
      {
        label: '中国工商银行股份有限公司锦州经济技术开发区支行',
        value: '102227000406|2107|辽宁省|锦州市'
      },
      {
        label: '中国工商银行股份有限公司锦州汇通支行',
        value: '102227000414|2107|辽宁省|锦州市'
      },
      {
        label: '中国工商银行锦州市建宇支行',
        value: '102227000422|2107|辽宁省|锦州市'
      },
      {
        label: '中国工商银行股份有限公司锦州洛阳路支行',
        value: '102227000439|2107|辽宁省|锦州市'
      },
      {
        label: '中国工商银行股份有限公司锦州松山支行',
        value: '102227000447|2107|辽宁省|锦州市'
      },
      {
        label: '中国工商银行锦州市桥西支行',
        value: '102227000455|2107|辽宁省|锦州市'
      },
      {
        label: '中国工商银行股份有限公司新乡新中大道支行',
        value: '102498002097|4107|河南省|新乡市'
      },
      {
        label: '中国工商银行股份有限公司新乡分行',
        value: '102498002144|4107|河南省|新乡市'
      },
      {
        label: '中国工商银行新乡分行延津县支行',
        value: '102498402243|4107|河南省|新乡市'
      },
      {
        label: '中国工商银行新乡分行封丘县支行',
        value: '102498502255|4107|河南省|新乡市'
      },
      {
        label: '中国工商银行新乡分行长垣县支行',
        value: '102498602270|4107|河南省|新乡市'
      },
      {
        label: '中国工商银行新乡分行卫辉市支行',
        value: '102498702230|4107|河南省|新乡市'
      },
      {
        label: '中国工商银行新乡分行辉县市支行',
        value: '102498802217|4107|河南省|新乡市'
      },
      {
        label: '中国工商银行股份有限公司辉县文昌支行',
        value: '102498812210|4107|河南省|新乡市'
      },
      {
        label: '中国工商银行股份有限公司焦作分行',
        value: '102501002036|4108|河南省|焦作市'
      },
      {
        label: '中国工商银行股份有限公司绍兴杨汛桥支行',
        value: '102337104212|3306|浙江省|绍兴市'
      },
      {
        label: '中国工商银行股份有限公司浙江省绍兴国贸支行',
        value: '102337104229|3306|浙江省|绍兴市'
      },
      {
        label: '中国工商银行股份有限公司绍兴柯北支行',
        value: '102337104237|3306|浙江省|绍兴市'
      },
      {
        label: '中国工商银行股份有限公司绍兴东升支行',
        value: '102337104245|3306|浙江省|绍兴市'
      },
      {
        label: '中国工商银行股份有限公司绍兴笛扬支行',
        value: '102337104253|3306|浙江省|绍兴市'
      },
      {
        label: '中国工商银行上虞市支行',
        value: '102337202203|3306|浙江省|绍兴市'
      },
      {
        label: '中国工商银行上虞松厦支行',
        value: '102337202220|3306|浙江省|绍兴市'
      },
      {
        label: '中国工商银行股份有限公司上虞城北支行',
        value: '102337202246|3306|浙江省|绍兴市'
      },
      {
        label: '中国工商银行股份有限公司上虞新建路支行',
        value: '102337202262|3306|浙江省|绍兴市'
      },
      {
        label: '中国工商银行股份有限公司上虞槐花桥支行',
        value: '102337202287|3306|浙江省|绍兴市'
      },
      {
        label: '中国工商银行上虞经济开发区支行',
        value: '102337202326|3306|浙江省|绍兴市'
      },
      {
        label: '中国工商银行股份有限公司浙江省上虞道墟支行',
        value: '102337202334|3306|浙江省|绍兴市'
      },
      {
        label: '中国工商银行股份有限公司上虞江扬路支行',
        value: '102337202359|3306|浙江省|绍兴市'
      },
      {
        label: '中国工商银行股份有限公司上虞凤鸣路支行',
        value: '102337202367|3306|浙江省|绍兴市'
      },
      {
        label: '中国工商银行股份有限公司浙江上虞城南支行',
        value: '102337202391|3306|浙江省|绍兴市'
      },
      {
        label: '中国工商银行股份有限公司上虞杭州湾支行',
        value: '102337204501|3306|浙江省|绍兴市'
      },
      {
        label: '中国工商银行嵊州市支行',
        value: '102337302600|3306|浙江省|绍兴市'
      },
      {
        label: '中国工商银行嵊州市东城支行',
        value: '102337302618|3306|浙江省|绍兴市'
      },
      {
        label: '中国工商银行股份有限公司嵊州南站支行',
        value: '102337302659|3306|浙江省|绍兴市'
      },
      {
        label: '中国工商银行股份有限公司嵊州城西支行',
        value: '102337302683|3306|浙江省|绍兴市'
      },
      {
        label: '中国工商银行股份有限公司嵊州支行广场分理处',
        value: '102337302691|3306|浙江省|绍兴市'
      },
      {
        label: '中国工商银行股份有限公司嵊州城中支行',
        value: '102337302714|3306|浙江省|绍兴市'
      },
      {
        label: '中国工商银行股份有限公司嵊州天乐支行',
        value: '102337302722|3306|浙江省|绍兴市'
      },
      {
        label: '中国工商银行股份有限公司嵊州长乐支行',
        value: '102337302755|3306|浙江省|绍兴市'
      },
      {
        label: '中国工商银行新昌县支行',
        value: '102337402808|3306|浙江省|绍兴市'
      },
      {
        label: '中国工商银行股份有限公司新昌太平洋支行',
        value: '102337402816|3306|浙江省|绍兴市'
      },
      {
        label: '中国工商银行股份有限公司新昌城东支行',
        value: '102337402824|3306|浙江省|绍兴市'
      },
      {
        label: '中国工商银行股份有限公司新昌支行横街分理处',
        value: '102337402832|3306|浙江省|绍兴市'
      },
      {
        label: '中国工商银行股份有限公司新昌支行城西分理处',
        value: '102337402849|3306|浙江省|绍兴市'
      },
      {
        label: '中国工商银行股份有限公司新昌支行礼泉分理处',
        value: '102337402865|3306|浙江省|绍兴市'
      },
      {
        label: '中国工商银行股份有限公司新昌大西洋支行',
        value: '102337402881|3306|浙江省|绍兴市'
      },
      {
        label: '中国工商银行股份有限公司新昌南岩支行',
        value: '102337404699|3306|浙江省|绍兴市'
      },
      {
        label: '中国工商银行诸暨市支行',
        value: '102337502400|3306|浙江省|绍兴市'
      },
      {
        label: '中国工商银行诸暨枫桥支行',
        value: '102337502418|3306|浙江省|绍兴市'
      },
      {
        label: '中国工商银行诸暨牌头支行',
        value: '102337502426|3306|浙江省|绍兴市'
      },
      {
        label: '中国工商银行诸暨城东支行',
        value: '102337502442|3306|浙江省|绍兴市'
      },
      {
        label: '中国工商银行诸暨城西支行',
        value: '102337502459|3306|浙江省|绍兴市'
      },
      {
        label: '中国工商银行诸暨城中支行',
        value: '102337502467|3306|浙江省|绍兴市'
      },
      {
        label: '中国工商银行股份有限公司诸暨新世纪支行',
        value: '102337502483|3306|浙江省|绍兴市'
      },
      {
        label: '工行浙江省舟山岱山支行竹屿分理处',
        value: '102342101021|3309|浙江省|舟山市'
      },
      {
        label: '中国工商银行舟山市嵊泗县支行',
        value: '102342201321|3309|浙江省|舟山市'
      },
      {
        label: '中国工商银行丽水市分行核算中心',
        value: '102343019900|3311|浙江省|丽水市'
      },
      {
        label: '中国工商银行丽水市分行营业部',
        value: '102343019926|3311|浙江省|丽水市'
      },
      {
        label: '中国工商银行丽水市处州支行',
        value: '102343020004|3311|浙江省|丽水市'
      },
      {
        label: '中国工商银行股份有限公司丽水城西支行',
        value: '102343020012|3311|浙江省|丽水市'
      },
      {
        label: '中国工商银行股份有限公司丽水丽青支行',
        value: '102343020029|3311|浙江省|丽水市'
      },
      {
        label: '中国工商银行丽水市莲都支行',
        value: '102343020107|3311|浙江省|丽水市'
      },
      {
        label: '中国工商银行股份有限公司丽水白云支行',
        value: '102343020158|3311|浙江省|丽水市'
      },
      {
        label: '中国工商银行股份有限公司丽水人民路支行',
        value: '102343020182|3311|浙江省|丽水市'
      },
      {
        label: '中国工商银行股份有限公司郑州卡萨大厦支行',
        value: '102491042207|4101|河南省|郑州市'
      },
      {
        label: '中国工商银行股份有限公司济源愚公路支行',
        value: '102491042555|4101|河南省|郑州市'
      },
      {
        label: '中国工商银行股份有限公司郑州普罗旺世支行',
        value: '102491042813|4101|河南省|郑州市'
      },
      {
        label: '中国工商银行股份有限公司郑州航海华中支行',
        value: '102491052014|4101|河南省|郑州市'
      },
      {
        label: '中国工商银行股份有限公司郑州嵩山路支行',
        value: '102491052055|4101|河南省|郑州市'
      },
      {
        label: '中国工商银行股份有限公司郑州新华支行',
        value: '102491052063|4101|河南省|郑州市'
      },
      {
        label: '中国工商银行股份有限公司郑州大学路支行',
        value: '102491052080|4101|河南省|郑州市'
      },
      {
        label: '中国工商银行股份有限公司郑州长江路支行',
        value: '102491052098|4101|河南省|郑州市'
      },
      {
        label: '中国工商银行南通市城南支行',
        value: '102306082218|3206|江苏省|南通市'
      },
      {
        label: '中国工商银行股份有限公司南通南川园支行',
        value: '102306082234|3206|江苏省|南通市'
      },
      {
        label: '中国工商银行股份有限公司南通中南世纪城支行',
        value: '102306082242|3206|江苏省|南通市'
      },
      {
        label: '中国工商银行股份有限公司南通易家桥支行',
        value: '102306082259|3206|江苏省|南通市'
      },
      {
        label: '中国工商银行南通长桥支行',
        value: '102306082275|3206|江苏省|南通市'
      },
      {
        label: '中国工商银行南通港闸支行',
        value: '102306082314|3206|江苏省|南通市'
      },
      {
        label: '中国工商银行南通天生港支行',
        value: '102306082322|3206|江苏省|南通市'
      },
      {
        label: '中国工商银行南通高店支行',
        value: '102306082347|3206|江苏省|南通市'
      },
      {
        label: '中国工商银行南通唐闸支行',
        value: '102306082371|3206|江苏省|南通市'
      },
      {
        label: '中国工商银行股份有限公司南通幸福支行',
        value: '102306082380|3206|江苏省|南通市'
      },
      {
        label: '中国工商银行南通经济技术开发区支行',
        value: '102306082419|3206|江苏省|南通市'
      },
      {
        label: '中国工商银行股份有限公司南通外滩支行',
        value: '102306082435|3206|江苏省|南通市'
      },
      {
        label: '中国工商银行南通城北支行',
        value: '102306082451|3206|江苏省|南通市'
      },
      {
        label: '中国工商银行股份有限公司南通瑞兴支行',
        value: '102306082460|3206|江苏省|南通市'
      },
      {
        label: '中国工商银行股份有限公司南通星湖支行',
        value: '102306082486|3206|江苏省|南通市'
      },
      {
        label: '中国工商银行股份有限公司南通濠东路支行',
        value: '102306082664|3206|江苏省|南通市'
      },
      {
        label: '中国工商银行股份有限公司南通华侨支行',
        value: '102306082824|3206|江苏省|南通市'
      },
      {
        label: '中国工商银行南通秦灶支行',
        value: '102306082832|3206|江苏省|南通市'
      },
      {
        label: '中国工商银行股份有限公司南通春晖支行',
        value: '102306082988|3206|江苏省|南通市'
      },
      {
        label: '中国工商银行海安县支行',
        value: '102306112012|3206|江苏省|南通市'
      },
      {
        label: '中国工商银行海安县曲塘分理处',
        value: '102306112029|3206|江苏省|南通市'
      },
      {
        label: '中国工商银行股份有限公司海安城南支行',
        value: '102306112045|3206|江苏省|南通市'
      },
      {
        label: '中国工商银行海安县开发区分理处',
        value: '102306112107|3206|江苏省|南通市'
      },
      {
        label: '中国工商银行沈阳东顺城支行',
        value: '102221001045|2101|辽宁省|沈阳市'
      },
      {
        label: '中国工商银行沈阳天光支行',
        value: '102221001238|2101|辽宁省|沈阳市'
      },
      {
        label: '中国工商银行沈阳奥体中心支行',
        value: '102221001295|2101|辽宁省|沈阳市'
      },
      {
        label: '中国工商银行沈阳蓝天支行',
        value: '102221001342|2101|辽宁省|沈阳市'
      },
      {
        label: '中国工商银行沈阳总院支行',
        value: '102221001375|2101|辽宁省|沈阳市'
      },
      {
        label: '中国工商银行沈阳东陵路支行',
        value: '102221001439|2101|辽宁省|沈阳市'
      },
      {
        label: '中国工商银行沈阳长青支行',
        value: '102221001498|2101|辽宁省|沈阳市'
      },
      {
        label: '中国工商银行沈阳兴华支行',
        value: '102221002015|2101|辽宁省|沈阳市'
      },
      {
        label: '中国工商银行沈阳八路支行',
        value: '102221002023|2101|辽宁省|沈阳市'
      },
      {
        label: '中国工商银行沈阳艳粉支行',
        value: '102221002066|2101|辽宁省|沈阳市'
      },
      {
        label: '中国工商银行股份有限公司通化贸源支行',
        value: '102245002080|2205|吉林省|通化市'
      },
      {
        label: '中国工商银行股份有限公司通化江南支行',
        value: '102245024171|2205|吉林省|通化市'
      },
      {
        label: '中国工商银行股份有限公司通化向阳支行',
        value: '102245024235|2205|吉林省|通化市'
      },
      {
        label: '中国工商银行股份有限公司通化县快大支行',
        value: '102245102107|2205|吉林省|通化市'
      },
      {
        label: '中国工商银行股份有限公司辉南支行',
        value: '102245220014|2205|吉林省|通化市'
      },
      {
        label: '中国工商银行股份有限公司柳河支行',
        value: '102245322016|2205|吉林省|通化市'
      },
      {
        label: '中国工商银行股份有限公司梅河口支行',
        value: '102245422105|2205|吉林省|通化市'
      },
      {
        label: '中国工商银行股份有限公司梅河口山城支行',
        value: '102245422121|2205|吉林省|通化市'
      },
      {
        label: '中国工商银行股份有限公司梅河口站前支行',
        value: '102245422148|2205|吉林省|通化市'
      },
      {
        label: '中国工商银行股份有限公司梅河口春天支行',
        value: '102245426026|2205|吉林省|通化市'
      },
      {
        label: '中国工商银行股份有限公司梅河口铁北支行',
        value: '102245426059|2205|吉林省|通化市'
      },
      {
        label: '中国工商银行股份有限公司集安支行',
        value: '102245522202|2205|吉林省|通化市'
      },
      {
        label: '中国工商银行股份有限公司集安电厂支行',
        value: '102245522219|2205|吉林省|通化市'
      },
      {
        label: '中国工商银行白山分行',
        value: '102246000018|2206|吉林省|白山市'
      },
      {
        label: '中国工商银行股份有限公司白山分行营业部',
        value: '102246000026|2206|吉林省|白山市'
      },
      {
        label: '中国工商银行白山八道江支行',
        value: '102246000034|2206|吉林省|白山市'
      },
      {
        label: '中国工商银行白山浑江支行',
        value: '102246000042|2206|吉林省|白山市'
      },
      {
        label: '中国工商银行股份有限公司白山新建支行',
        value: '102246000067|2206|吉林省|白山市'
      },
      {
        label: '中国工商银行股份有限公司白山民中支行',
        value: '102246000083|2206|吉林省|白山市'
      },
      {
        label: '中国工商银行白山新民支行',
        value: '102246000155|2206|吉林省|白山市'
      },
      {
        label: '中国工商银行抚松支行',
        value: '102246100270|2206|吉林省|白山市'
      },
      {
        label: '中国工商银行股份有限公司抚松南大街支行',
        value: '102246100296|2206|吉林省|白山市'
      },
      {
        label: '中国工商银行股份有限公司抚松泉阳支行',
        value: '102246100323|2206|吉林省|白山市'
      },
      {
        label: '中国工商银行股份有限公司抚松露水河支行',
        value: '102246100340|2206|吉林省|白山市'
      },
      {
        label: '中国工商银行股份有限公司合肥九华山路支行',
        value: '102361002289|3401|安徽省|合肥市'
      },
      {
        label: '中国工商银行股份有限公司合肥政务新区支行',
        value: '102361002301|3401|安徽省|合肥市'
      },
      {
        label: '中国工商银行佳木斯市分行通江支行',
        value: '102269002132|2306|黑龙江省|大庆市'
      },
      {
        label: '中国工商银行佳木斯市分行站前支行',
        value: '102269002157|2306|黑龙江省|大庆市'
      },
      {
        label: '中国工商银行佳木斯市分行光复支行',
        value: '102269002165|2306|黑龙江省|大庆市'
      },
      {
        label: '中国工商银行佳木斯市分行佳东支行',
        value: '102269002173|2306|黑龙江省|大庆市'
      },
      {
        label: '中国工商银行佳木斯市分行西林支行',
        value: '102269002190|2306|黑龙江省|大庆市'
      },
      {
        label: '中国工商银行佳木斯市分行永和支行',
        value: '102269002204|2306|黑龙江省|大庆市'
      },
      {
        label: '中国工商银行佳木斯市分行佳信支行',
        value: '102269002237|2306|黑龙江省|大庆市'
      },
      {
        label: '中国工商银行佳木斯市分行建欣支行',
        value: '102269002323|2306|黑龙江省|大庆市'
      },
      {
        label: '中国工商银行佳木斯市分行核算中心',
        value: '102269009003|2306|黑龙江省|大庆市'
      },
      {
        label: '中国工商银行伊春市分行南岔支行',
        value: '102271002009|2307|黑龙江省|伊春市'
      },
      {
        label: '中国工商银行伊春汇通支行',
        value: '102271002017|2307|黑龙江省|伊春市'
      },
      {
        label: '中国工商银行伊春市分行上甘岭支行',
        value: '102271002025|2307|黑龙江省|伊春市'
      },
      {
        label: '中国工商银行伊春市分行林海支行',
        value: '102271002033|2307|黑龙江省|伊春市'
      },
      {
        label: '中国工商银行伊春市分行新兴路支行',
        value: '102271002041|2307|黑龙江省|伊春市'
      },
      {
        label: '中国工商银行伊春市分行乌马河支行',
        value: '102271002050|2307|黑龙江省|伊春市'
      },
      {
        label: '中国工商银行伊春市分行五营支行',
        value: '102271002068|2307|黑龙江省|伊春市'
      },
      {
        label: '中国工商银行衡水河西支行',
        value: '102148016643|1311|河北省|衡水市'
      },
      {
        label: '中国工商银行衡水怡水支行',
        value: '102148016660|1311|河北省|衡水市'
      },
      {
        label: '中国工商银行股份有限公司衡水华阳支行',
        value: '102148016709|1311|河北省|衡水市'
      },
      {
        label: '中国工商银行衡水河东支行',
        value: '102148016750|1311|河北省|衡水市'
      },
      {
        label: '中国工商银行股份有限公司衡水东兴支行',
        value: '102148016768|1311|河北省|衡水市'
      },
      {
        label: '中国工商银行衡水东明支行',
        value: '102148016776|1311|河北省|衡水市'
      },
      {
        label: '中国工商银行股份有限公司衡水滏阳支行',
        value: '102148016784|1311|河北省|衡水市'
      },
      {
        label: '中国工商银行衡水路北支行',
        value: '102148016830|1311|河北省|衡水市'
      },
      {
        label: '中国工商银行衡水大庆支行',
        value: '102148016864|1311|河北省|衡水市'
      },
      {
        label: '中国工商银行衡水光明支行',
        value: '102148016872|1311|河北省|衡水市'
      },
      {
        label: '中国工商银行衡水朝阳支行',
        value: '102148016928|1311|河北省|衡水市'
      },
      {
        label: '中国工商银行衡水胜利支行',
        value: '102148016952|1311|河北省|衡水市'
      },
      {
        label: '中国工商银行冀州支行',
        value: '102148216987|1311|河北省|衡水市'
      },
      {
        label: '中国工商银行股份有限公司冀州冀新支行',
        value: '102148217019|1311|河北省|衡水市'
      },
      {
        label: '中国工商银行枣强支行',
        value: '102148317055|1311|河北省|衡水市'
      },
      {
        label: '中国工商银行股份有限公司枣强大营支行',
        value: '102148317063|1311|河北省|衡水市'
      },
      {
        label: '中国工商银行武邑支行',
        value: '102148417099|1311|河北省|衡水市'
      },
      {
        label: '中国工商银行深州支行',
        value: '102148517121|1311|河北省|衡水市'
      },
      {
        label: '中国工商银行深州前么头支行',
        value: '102148517130|1311|河北省|衡水市'
      },
      {
        label: '中国工商银行饶阳支行',
        value: '102148717231|1311|河北省|衡水市'
      },
      {
        label: '中国工商银行安平支行',
        value: '102148817173|1311|河北省|衡水市'
      },
      {
        label: '中国工商银行股份有限公司锦州铁北支行',
        value: '102227000279|2107|辽宁省|锦州市'
      },
      {
        label: '中国工商银行股份有限公司锦州上海路支行',
        value: '102227000295|2107|辽宁省|锦州市'
      },
      {
        label: '中国工商银行股份有限公司锦州北宁路支行',
        value: '102227000334|2107|辽宁省|锦州市'
      },
      {
        label: '中国工商银行锦州市女儿河支行',
        value: '102227000367|2107|辽宁省|锦州市'
      },
      {
        label: '中国工商银行锦州市桥南支行',
        value: '102227000391|2107|辽宁省|锦州市'
      },
      {
        label: '中国工商银行牡丹江市分行阳明街支行',
        value: '102272002046|2308|黑龙江省|佳木斯市'
      },
      {
        label: '中国工商银行牡丹江市分行站前支行',
        value: '102272002062|2308|黑龙江省|佳木斯市'
      },
      {
        label: '中国工商银行股份有限公司牡丹江牡达支行',
        value: '102272002087|2308|黑龙江省|佳木斯市'
      },
      {
        label: '中国工商银行股份有限公司牡丹江温春支行',
        value: '102272002095|2308|黑龙江省|佳木斯市'
      },
      {
        label: '中国工商银行牡丹江市分行太平路支行',
        value: '102272002134|2308|黑龙江省|佳木斯市'
      },
      {
        label: '中国工商银行牡丹江市分行安居支行',
        value: '102272002167|2308|黑龙江省|佳木斯市'
      },
      {
        label: '中国工商银行股份有限公司牡丹江锦江支行',
        value: '102272002175|2308|黑龙江省|佳木斯市'
      },
      {
        label: '中国工商银行牡丹江市分行融汇支行',
        value: '102272002183|2308|黑龙江省|佳木斯市'
      },
      {
        label: '中国工商银行牡丹江市分行核算中心',
        value: '102272009035|2308|黑龙江省|佳木斯市'
      },
      {
        label: '中国工商银行股份有限公司桦南县支行',
        value: '102272102001|2308|黑龙江省|佳木斯市'
      },
      {
        label: '中国工商银行股份有限公司宝清支行',
        value: '102272400017|2305|黑龙江省|双鸭山市'
      },
      {
        label: '中国工商银行股份有限公司汤原县支行',
        value: '102272502013|2308|黑龙江省|佳木斯市'
      },
      {
        label: '中国工商银行股份有限公司双鸭山市分行饶河支行',
        value: '102272600010|2305|黑龙江省|双鸭山市'
      },
      {
        label: '中国工商银行股份有限公司抚远县支行',
        value: '102272702303|2308|黑龙江省|佳木斯市'
      },
      {
        label: '中国工商银行股份有限公司同江市支行',
        value: '102272902286|2308|黑龙江省|佳木斯市'
      },
      {
        label: '中国工商银行股份有限公司富锦市支行',
        value: '102273102225|2308|黑龙江省|佳木斯市'
      },
      {
        label: '中国工商银行股份有限公司七台河财政支行',
        value: '102274000164|2309|黑龙江省|七台河市'
      },
      {
        label: '中国工商银行股份有限公司七台河银达支行',
        value: '102274002029|2309|黑龙江省|七台河市'
      },
      {
        label: '中国工商银行七台河新兴支行',
        value: '102274002045|2309|黑龙江省|七台河市'
      },
      {
        label: '中国工商银行七台河茄子河支行',
        value: '102274002053|2309|黑龙江省|七台河市'
      },
      {
        label: '中国工商银行七台河桃南支行',
        value: '102274002070|2309|黑龙江省|七台河市'
      },
      {
        label: '中国工商银行七台河大同支行',
        value: '102274002115|2309|黑龙江省|七台河市'
      },
      {
        label: '中国工商银行七台河市分行清算中心',
        value: '102274009006|2309|黑龙江省|七台河市'
      },
      {
        label: '中国工商银行青岛市四方区支行',
        value: '102452002018|3702|山东省|青岛市'
      },
      {
        label: '中国工商银行股份有限公司青岛威海路支行',
        value: '102452002026|3702|山东省|青岛市'
      },
      {
        label: '中国工商银行新乡分行会计业务处理中心',
        value: '102498002667|4107|河南省|新乡市'
      },
      {
        label: '中国工商银行新乡分行开发区支行',
        value: '102498012020|4107|河南省|新乡市'
      },
      {
        label: '中国工商银行股份有限公司新乡古龙支行',
        value: '102498012038|4107|河南省|新乡市'
      },
      {
        label: '中国工商银行台州市温岭泽国支行',
        value: '102345404213|3310|浙江省|台州市'
      },
      {
        label: '中国工商银行股份有限公司温岭新河支行',
        value: '102345404310|3310|浙江省|台州市'
      },
      {
        label: '中国工商银行台州市温岭市支行箬横分理处',
        value: '102345404416|3310|浙江省|台州市'
      },
      {
        label: '中国工商银行台州市温岭市支行松门分理处',
        value: '102345404512|3310|浙江省|台州市'
      },
      {
        label: '中国工商银行台州市温岭市支行温西分理处',
        value: '102345404615|3310|浙江省|台州市'
      },
      {
        label: '中国工商银行台州市温岭市支行大溪分理处',
        value: '102345404711|3310|浙江省|台州市'
      },
      {
        label: '中国工商银行台州市温岭市支行城区分理处',
        value: '102345404818|3310|浙江省|台州市'
      },
      {
        label: '中国工商银行股份有限公司温岭横峰支行',
        value: '102345424119|3310|浙江省|台州市'
      },
      {
        label: '中国工商银行股份有限公司温岭九龙支行',
        value: '102345424135|3310|浙江省|台州市'
      },
      {
        label: '中国工商银行股份有限公司温岭支行万昌分理处',
        value: '102345424151|3310|浙江省|台州市'
      },
      {
        label: '中国工商银行股份有限公司温岭支行万城分理处',
        value: '102345424178|3310|浙江省|台州市'
      },
      {
        label: '中国工商银行温岭支行牧屿分理处',
        value: '102345424209|3310|浙江省|台州市'
      },
      {
        label: '中国工商银行股份有限公司温岭城北支行',
        value: '102345424233|3310|浙江省|台州市'
      },
      {
        label: '中国工商银行股份有限公司温岭支行泽国前程分理处',
        value: '102345424241|3310|浙江省|台州市'
      },
      {
        label: '中国工商银行台州市仙居县支行',
        value: '102345505113|3310|浙江省|台州市'
      },
      {
        label: '中国工商银行股份有限公司仙居城南支行',
        value: '102345505210|3310|浙江省|台州市'
      },
      {
        label: '中国工商银行股份有限公司仙居复兴支行',
        value: '102345525111|3310|浙江省|台州市'
      },
      {
        label: '中国工商银行股份有限公司仙居永安支行',
        value: '102345525120|3310|浙江省|台州市'
      },
      {
        label: '中国工商银行股份有限公司仙居商城支行',
        value: '102345525146|3310|浙江省|台州市'
      },
      {
        label: '中国工商银行台州市天台县支行',
        value: '102345606114|3310|浙江省|台州市'
      },
      {
        label: '中国工商银行股份有限公司天台平桥支行',
        value: '102345606219|3310|浙江省|台州市'
      },
      {
        label: '中国工商银行股份有限公司天台新城支行',
        value: '102345626126|3310|浙江省|台州市'
      },
      {
        label: '中国工商银行股份有限公司天台城东支行',
        value: '102345626134|3310|浙江省|台州市'
      },
      {
        label: '中国工商银行股份有限公司天台始丰支行',
        value: '102345626159|3310|浙江省|台州市'
      },
      {
        label: '中国工商银行台州市三门支行',
        value: '102345707119|3310|浙江省|台州市'
      },
      {
        label: '中国工商银行股份有限公司三门南山支行',
        value: '102345727138|3310|浙江省|台州市'
      },
      {
        label: '中国工商银行股份有限公司三门支行上洋路分理处',
        value: '102345727154|3310|浙江省|台州市'
      },
      {
        label: '中国工商银行股份有限公司三门核电支行',
        value: '102345727162|3310|浙江省|台州市'
      },
      {
        label: '中国工商银行股份有限公司玉环支行',
        value: '102345808114|3310|浙江省|台州市'
      },
      {
        label: '中国工商银行股份有限公司玉环坎门支行',
        value: '102345808219|3310|浙江省|台州市'
      },
      {
        label: '中国工商银行股份有限公司玉环楚门支行',
        value: '102345808317|3310|浙江省|台州市'
      },
      {
        label: '中国工商银行股份有限公司玉环陈屿支行',
        value: '102345808413|3310|浙江省|台州市'
      },
      {
        label: '中国工商银行股份有限公司漳州胜利支行',
        value: '102399035075|3506|福建省|漳州市'
      },
      {
        label: '中国工商银行漳州市龙文支行',
        value: '102399035106|3506|福建省|漳州市'
      },
      {
        label: '中国工商银行股份有限公司龙海芦州支行',
        value: '102399104130|3506|福建省|漳州市'
      },
      {
        label: '中国工商银行股份有限公司漳州开发区支行',
        value: '102399104201|3506|福建省|漳州市'
      },
      {
        label: '中国工商银行股份有限公司洛阳自贸试验区支行',
        value: '102493002158|4103|河南省|洛阳市'
      },
      {
        label: '中国工商银行洛阳分行西工支行',
        value: '102493002182|4103|河南省|洛阳市'
      },
      {
        label: '中国工商银行股份有限公司洛阳青年宫支行',
        value: '102493002203|4103|河南省|洛阳市'
      },
      {
        label: '中国工商银行洛阳分行老城支行',
        value: '102493002246|4103|河南省|洛阳市'
      },
      {
        label: '中国工商银行洛阳分行廛河支行',
        value: '102493002262|4103|河南省|洛阳市'
      },
      {
        label: '中国工商银行洛阳分行洛南支行',
        value: '102493002295|4103|河南省|洛阳市'
      },
      {
        label: '中国工商银行洛阳分行吉利支行',
        value: '102493002367|4103|河南省|洛阳市'
      },
      {
        label: '中国工商银行洛阳分行银行卡业务部',
        value: '102493002391|4103|河南省|洛阳市'
      },
      {
        label: '中国工商银行洛阳分行九都支行',
        value: '102493002406|4103|河南省|洛阳市'
      },
      {
        label: '中国工商银行洛阳分行会计业务处理中心',
        value: '102493002684|4103|河南省|洛阳市'
      },
      {
        label: '中国工商银行股份有限公司洛阳西苑支行',
        value: '102493010045|4103|河南省|洛阳市'
      },
      {
        label: '中国工商银行股份有限公司洛阳小街支行',
        value: '102493012018|4103|河南省|洛阳市'
      },
      {
        label: '中国工商银行股份有限公司洛阳武汉路支行',
        value: '102493012042|4103|河南省|洛阳市'
      },
      {
        label: '中国工商银行股份有限公司洛阳广州市场支行',
        value: '102493012083|4103|河南省|洛阳市'
      },
      {
        label: '中国工商银行股份有限公司洛阳丽春支行',
        value: '102493012155|4103|河南省|洛阳市'
      },
      {
        label: '中国工商银行股份有限公司洛阳安乐支行',
        value: '102493012243|4103|河南省|洛阳市'
      },
      {
        label: '中国工商银行股份有限公司洛阳友谊支行',
        value: '102493013043|4103|河南省|洛阳市'
      },
      {
        label: '中国工商银行股份有限公司洛阳南华支行',
        value: '102493014089|4103|河南省|洛阳市'
      },
      {
        label: '中国工商银行股份有限公司洛阳谷水支行',
        value: '102493015049|4103|河南省|洛阳市'
      },
      {
        label: '中国工商银行股份有限公司洛阳武汉路第二支行',
        value: '102493016046|4103|河南省|洛阳市'
      },
      {
        label: '中国工商银行股份有限公司洛阳外语学院支行',
        value: '102493022048|4103|河南省|洛阳市'
      },
      {
        label: '中国工商银行股份有限公司衢州下街支行',
        value: '102341026019|3308|浙江省|衢州市'
      },
      {
        label: '中国工商银行股份有限公司杭州天水支行',
        value: '102331002500|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行围场支行',
        value: '102142713143|1308|河北省|承德市'
      },
      {
        label: '中国工商银行沧州分行业务处理中心',
        value: '102143014015|1309|河北省|沧州市'
      },
      {
        label: '中国工商银行股份有限公司合肥徽州大道支行',
        value: '102361002328|3401|安徽省|合肥市'
      },
      {
        label: '中国工商银行股份有限公司合肥香樟雅苑支行',
        value: '102361002336|3401|安徽省|合肥市'
      },
      {
        label: '中国工商银行股份有限公司合肥南七支行',
        value: '102361002344|3401|安徽省|合肥市'
      },
      {
        label: '中国工商银行股份有限公司合肥铁四局支行',
        value: '102361002352|3401|安徽省|合肥市'
      },
      {
        label: '中国工商银行股份有限公司合肥江淮汽车厂支行',
        value: '102361002369|3401|安徽省|合肥市'
      },
      {
        label: '中国工商银行股份有限公司合肥亳州路支行',
        value: '102361002393|3401|安徽省|合肥市'
      },
      {
        label: '中国工商银行股份有限公司合肥颖上路支行',
        value: '102361002408|3401|安徽省|合肥市'
      },
      {
        label: '中国工商银行涟水高沟支行',
        value: '102308506049|3208|江苏省|淮安市'
      },
      {
        label: '中国工商银行涟水城北支行',
        value: '102308506065|3208|江苏省|淮安市'
      },
      {
        label: '中国工商银行股份有限公司鞍山深沟四区支行',
        value: '102223000353|2103|辽宁省|鞍山市'
      },
      {
        label: '中国工商银行股份有限公司鞍山湖滨支行',
        value: '102223000396|2103|辽宁省|鞍山市'
      },
      {
        label: '中国工商银行股份有限公司鞍山电笛支行',
        value: '102223000423|2103|辽宁省|鞍山市'
      },
      {
        label: '中国工商银行股份有限公司鞍山平安支行',
        value: '102223000440|2103|辽宁省|鞍山市'
      },
      {
        label: '中国工商银行股份有限公司鞍山大石街支行',
        value: '102223000466|2103|辽宁省|鞍山市'
      },
      {
        label: '中国工商银行股份有限公司鞍山长兴支行',
        value: '102223000474|2103|辽宁省|鞍山市'
      },
      {
        label: '中国工商银行股份有限公司鞍山明达支行',
        value: '102223000499|2103|辽宁省|鞍山市'
      },
      {
        label: '中国工商银行股份有限公司鞍山太平支行',
        value: '102223000562|2103|辽宁省|鞍山市'
      },
      {
        label: '中国工商银行股份有限公司鞍山大西街支行',
        value: '102223000600|2103|辽宁省|鞍山市'
      },
      {
        label: '中国工商银行股份有限公司鞍山太阳升支行',
        value: '102223000626|2103|辽宁省|鞍山市'
      },
      {
        label: '中国工商银行股份有限公司鞍山公园支行',
        value: '102223000659|2103|辽宁省|鞍山市'
      },
      {
        label: '中国工商银行股份有限公司鞍山九九支行',
        value: '102223000992|2103|辽宁省|鞍山市'
      },
      {
        label: '中国工商银行股份有限公司海城腾鳌支行',
        value: '102223001032|2103|辽宁省|鞍山市'
      },
      {
        label: '中国工商银行股份有限公司鞍山振兴支行',
        value: '102223001274|2103|辽宁省|鞍山市'
      },
      {
        label: '中国工商银行股份有限公司鞍山雷锋支行',
        value: '102223001514|2103|辽宁省|鞍山市'
      },
      {
        label: '中国工商银行辽宁省鞍山市分行业务处理中心',
        value: '102223002003|2103|辽宁省|鞍山市'
      },
      {
        label: '中国工商银行辽宁省鞍山汇发支行',
        value: '102223002011|2103|辽宁省|鞍山市'
      },
      {
        label: '中国工商银行辽宁省鞍山市铁东支行',
        value: '102223002100|2103|辽宁省|鞍山市'
      },
      {
        label: '中国工商银行辽宁省鞍山市道西支行',
        value: '102223002206|2103|辽宁省|鞍山市'
      },
      {
        label: '中国工商银行辽宁省鞍山市立山支行',
        value: '102223002302|2103|辽宁省|鞍山市'
      },
      {
        label: '中国工商银行股份有限公司鞍山正阳支行',
        value: '102223002327|2103|辽宁省|鞍山市'
      },
      {
        label: '中国工商银行股份有限公司鞍山高新支行',
        value: '102223002335|2103|辽宁省|鞍山市'
      },
      {
        label: '中国工商银行辽宁省鞍山市鞍钢支行',
        value: '102223002409|2103|辽宁省|鞍山市'
      },
      {
        label: '中国工商银行辽宁省鞍山市鞍钢支行新钢分理处',
        value: '102223002433|2103|辽宁省|鞍山市'
      },
      {
        label: '中国工商银行辽宁省鞍山市千山支行',
        value: '102223002505|2103|辽宁省|鞍山市'
      },
      {
        label: '中国工商银行辽宁省鞍山市千山支行齐大山分理处',
        value: '102223002513|2103|辽宁省|鞍山市'
      },
      {
        label: '中国工商银行鞍山市大孤山分理处',
        value: '102223002530|2103|辽宁省|鞍山市'
      },
      {
        label: '中国工商银行辽宁省鞍山市千山支行千山分理处',
        value: '102223002548|2103|辽宁省|鞍山市'
      },
      {
        label: '中国工商银行辽宁省鞍山市千山支行汤岗子分理处',
        value: '102223002556|2103|辽宁省|鞍山市'
      },
      {
        label: '中国工商银行股份有限公司鞍山灵南支行',
        value: '102223002601|2103|辽宁省|鞍山市'
      },
      {
        label: '中国工商银行辽宁省鞍山市长甸支行',
        value: '102223002708|2103|辽宁省|鞍山市'
      },
      {
        label: '中国工商银行股份有限公司鞍山广场支行',
        value: '102223002804|2103|辽宁省|鞍山市'
      },
      {
        label: '中国工商银行辽宁省鞍山市分行建鞍支行',
        value: '102223020307|2103|辽宁省|鞍山市'
      },
      {
        label: '中国工商银行台安县支行',
        value: '102223100217|2103|辽宁省|鞍山市'
      },
      {
        label: '中国工商银行股份有限公司海城站前支行',
        value: '102223200808|2103|辽宁省|鞍山市'
      },
      {
        label: '中国工商银行伊春市分行西林支行',
        value: '102271002700|2307|黑龙江省|伊春市'
      },
      {
        label: '中国工商银行股份有限公司伊春汇西支行',
        value: '102271002806|2307|黑龙江省|伊春市'
      },
      {
        label: '中国工商银行股份有限公司青岛香港东路支行',
        value: '102452002034|3702|山东省|青岛市'
      },
      {
        label: '中国工商银行股份有限公司青岛水清沟支行',
        value: '102452002042|3702|山东省|青岛市'
      },
      {
        label: '中国工商银行股份有限公司青岛重庆南路支行',
        value: '102452002059|3702|山东省|青岛市'
      },
      {
        label: '中国工商银行股份有限公司青岛四方第二支行',
        value: '102452002122|3702|山东省|青岛市'
      },
      {
        label: '中国工商银行股份有限公司淮南金鑫支行',
        value: '102364022335|3404|安徽省|淮南市'
      },
      {
        label: '中国工商银行淮南大通分理处',
        value: '102364022343|3404|安徽省|淮南市'
      },
      {
        label: '中国工商银行股份有限公司淮南居仁村支行',
        value: '102364022351|3404|安徽省|淮南市'
      },
      {
        label: '中国工商银行股份有限公司淮南三号井支行',
        value: '102364023217|3404|安徽省|淮南市'
      },
      {
        label: '中国工商银行凤台县支行',
        value: '102364100216|3404|安徽省|淮南市'
      },
      {
        label: '中国工商银行股份有限公司凤台城关支行',
        value: '102364124230|3404|安徽省|淮南市'
      },
      {
        label: '中国工商银行马鞍山分行业务处理中心',
        value: '102365002010|3405|安徽省|马鞍山市'
      },
      {
        label: '中国工商银行马鞍山金家庄支行',
        value: '102365002028|3405|安徽省|马鞍山市'
      },
      {
        label: '中国工商银行股份有限公司马鞍山向山支行',
        value: '102365002036|3405|安徽省|马鞍山市'
      },
      {
        label: '中国工商银行马鞍山马钢支行',
        value: '102365002044|3405|安徽省|马鞍山市'
      },
      {
        label: '中国工商银行股份有限公司台州西城支行',
        value: '102345023130|3310|浙江省|台州市'
      },
      {
        label: '中国工商银行股份有限公司台州新前支行',
        value: '102345023148|3310|浙江省|台州市'
      },
      {
        label: '中国工商银行台州市黄岩区支行东城分理处',
        value: '102345023156|3310|浙江省|台州市'
      },
      {
        label: '中国工商银行台州市黄岩区支行横街分理处',
        value: '102345023164|3310|浙江省|台州市'
      },
      {
        label: '中国工商银行股份有限公司台州永宁支行',
        value: '102345023172|3310|浙江省|台州市'
      },
      {
        label: '中国工商银行股份有限公司台州南城支行',
        value: '102345023189|3310|浙江省|台州市'
      },
      {
        label: '中国工商银行股份有限公司台州天元支行',
        value: '102345023197|3310|浙江省|台州市'
      },
      {
        label: '中国工商银行台州市临海市支行',
        value: '102345202115|3310|浙江省|台州市'
      },
      {
        label: '中国工商银行股份有限公司临海靖江支行',
        value: '102345202211|3310|浙江省|台州市'
      },
      {
        label: '中国工商银行台州市临海市支行杜桥分理处',
        value: '102345202318|3310|浙江省|台州市'
      },
      {
        label: '中国工商银行台州市临海市支行大洋分理处',
        value: '102345222110|3310|浙江省|台州市'
      },
      {
        label: '中国工商银行股份有限公司临海支行古城分理处',
        value: '102345222128|3310|浙江省|台州市'
      },
      {
        label: '中国工商银行股份有限公司临海赤城支行',
        value: '102345222152|3310|浙江省|台州市'
      },
      {
        label: '中国工商银行股份有限公司临海东城支行',
        value: '102345222169|3310|浙江省|台州市'
      },
      {
        label: '中国工商银行股份有限公司临海杜桥府前街支行',
        value: '102345222193|3310|浙江省|台州市'
      },
      {
        label: '中国工商银行股份有限公司临海东塍支行',
        value: '102345222208|3310|浙江省|台州市'
      },
      {
        label: '中国工商银行台州市温岭市支行',
        value: '102345404117|3310|浙江省|台州市'
      },
      {
        label: '中国工商银行三明人民路支行',
        value: '102395060079|3504|福建省|三明市'
      },
      {
        label: '中国工商银行三明江滨支行',
        value: '102395060087|3504|福建省|三明市'
      },
      {
        label: '中国工商银行三明光明支行',
        value: '102395060118|3504|福建省|三明市'
      },
      {
        label: '中国工商银行三明和仁支行',
        value: '102395060134|3504|福建省|三明市'
      },
      {
        label: '中国工商银行三明市三元支行',
        value: '102395060183|3504|福建省|三明市'
      },
      {
        label: '中国工商银行三明元城支行',
        value: '102395060191|3504|福建省|三明市'
      },
      {
        label: '中国工商银行三明下洋支行',
        value: '102395060239|3504|福建省|三明市'
      },
      {
        label: '中国工商银行三明市分行银行卡部',
        value: '102395060271|3504|福建省|三明市'
      },
      {
        label: '中国工商银行三明市明溪县支行',
        value: '102395160523|3504|福建省|三明市'
      },
      {
        label: '中国工商银行三明市清流县支行',
        value: '102395260552|3504|福建省|三明市'
      },
      {
        label: '中国工商银行三明市宁化县支行',
        value: '102395360565|3504|福建省|三明市'
      },
      {
        label: '中国工商银行三明市大田县支行',
        value: '102395460486|3504|福建省|三明市'
      },
      {
        label: '中国工商银行三明市尤溪县支行',
        value: '102395560459|3504|福建省|三明市'
      },
      {
        label: '中国工商银行股份有限公司尤溪沈城支行',
        value: '102395560660|3504|福建省|三明市'
      },
      {
        label: '中国工商银行三明市沙县支行',
        value: '102395660391|3504|福建省|三明市'
      },
      {
        label: '中国工商银行三明市沙县青州支行',
        value: '102395660406|3504|福建省|三明市'
      },
      {
        label: '中国工商银行沙县金沙支行',
        value: '102395660422|3504|福建省|三明市'
      },
      {
        label: '中国工商银行沙县解放支行',
        value: '102395660439|3504|福建省|三明市'
      },
      {
        label: '中国工商银行三明市将乐县支行',
        value: '102395760595|3504|福建省|三明市'
      },
      {
        label: '中国工商银行股份有限公司将乐三华支行',
        value: '102395760675|3504|福建省|三明市'
      },
      {
        label: '中国工商银行三明市泰宁县支行',
        value: '102395860622|3504|福建省|三明市'
      },
      {
        label: '中国工商银行股份有限公司建宁支行',
        value: '102395960658|3504|福建省|三明市'
      },
      {
        label: '中国工商银行三明市永安支行',
        value: '102396060286|3504|福建省|三明市'
      },
      {
        label: '中国工商银行永安燕江支行',
        value: '102396060309|3504|福建省|三明市'
      },
      {
        label: '中国工商银行永安东风支行',
        value: '102396060317|3504|福建省|三明市'
      },
      {
        label: '中国工商银行永安南门支行',
        value: '102396060384|3504|福建省|三明市'
      },
      {
        label: '中国工商银行泉州市分行',
        value: '102397020000|3505|福建省|泉州市'
      },
      {
        label: '中国工商银行泉州市鲤城支行',
        value: '102397021019|3505|福建省|泉州市'
      },
      {
        label: '中国工商银行泉州市分行营业部',
        value: '102397021027|3505|福建省|泉州市'
      },
      {
        label: '中国工商银行泉州开元支行',
        value: '102397021035|3505|福建省|泉州市'
      },
      {
        label: '中国工商银行股份有限公司南昌市胜利支行',
        value: '102421000122|3601|江西省|南昌市'
      },
      {
        label: '中国工商银行股份有限公司南昌洪航支行',
        value: '102421000147|3601|江西省|南昌市'
      },
      {
        label: '中国工商银行股份有限公司南昌青山湖支行',
        value: '102421000155|3601|江西省|南昌市'
      },
      {
        label: '中国工商银行股份有限公司南昌南钢支行',
        value: '102421000163|3601|江西省|南昌市'
      },
      {
        label: '中国工商银行股份有限公司宜兴高塍支行',
        value: '102302303578|3202|江苏省|无锡市'
      },
      {
        label: '中国工商银行沧州福宾支行',
        value: '102143014023|1309|河北省|沧州市'
      },
      {
        label: '中国工商银行沧州南门里支行',
        value: '102143014031|1309|河北省|沧州市'
      },
      {
        label: '中国工商银行沧州车站支行',
        value: '102143014040|1309|河北省|沧州市'
      },
      {
        label: '中国工商银行沧州河西支行',
        value: '102143014058|1309|河北省|沧州市'
      },
      {
        label: '中国工商银行沧州南环支行',
        value: '102143014066|1309|河北省|沧州市'
      },
      {
        label: '中国工商银行沧州北环支行',
        value: '102143014074|1309|河北省|沧州市'
      },
      {
        label: '中国工商银行沧州铁东支行',
        value: '102143014082|1309|河北省|沧州市'
      },
      {
        label: '中国工商银行沧州新华支行',
        value: '102143014200|1309|河北省|沧州市'
      },
      {
        label: '中国工商银行沧州北维支行',
        value: '102143014226|1309|河北省|沧州市'
      },
      {
        label: '中国工商银行沧州华西支行',
        value: '102143014242|1309|河北省|沧州市'
      },
      {
        label: '中国工商银行沧州站前支行',
        value: '102143014314|1309|河北省|沧州市'
      },
      {
        label: '中国工商银行股份有限公司沧州御河支行',
        value: '102143014347|1309|河北省|沧州市'
      },
      {
        label: '中国工商银行沧州道东支行',
        value: '102143014355|1309|河北省|沧州市'
      },
      {
        label: '中国工商银行沧州建北支行',
        value: '102143014371|1309|河北省|沧州市'
      },
      {
        label: '中国工商银行沧州西环支行',
        value: '102143014460|1309|河北省|沧州市'
      },
      {
        label: '中国工商银行沧州沧化支行',
        value: '102143014486|1309|河北省|沧州市'
      },
      {
        label: '中国工商银行股份有限公司沧州文园支行',
        value: '102143014517|1309|河北省|沧州市'
      },
      {
        label: '中国工商银行沧州东环支行',
        value: '102143014541|1309|河北省|沧州市'
      },
      {
        label: '中国工商银行股份有限公司沧州宏宇支行',
        value: '102143015278|1309|河北省|沧州市'
      },
      {
        label: '中国工商银行股份有限公司沧州开发区支行',
        value: '102143114393|1309|河北省|沧州市'
      },
      {
        label: '中国工商银行青县支行',
        value: '102143214098|1309|河北省|沧州市'
      },
      {
        label: '中国工商银行青县石油支行',
        value: '102143214573|1309|河北省|沧州市'
      },
      {
        label: '中国工商银行泊头支行',
        value: '102144114107|1309|河北省|沧州市'
      },
      {
        label: '中国工商银行泊头河东支行',
        value: '102144114705|1309|河北省|沧州市'
      },
      {
        label: '中国工商银行泊头交河支行',
        value: '102144114713|1309|河北省|沧州市'
      },
      {
        label: '中国工商银行华北石油支行',
        value: '102144214110|1309|河北省|沧州市'
      },
      {
        label: '中国工商银行任丘支行',
        value: '102144214739|1309|河北省|沧州市'
      },
      {
        label: '中国工商银行股份有限公司华北石油站前支行',
        value: '102144214798|1309|河北省|沧州市'
      },
      {
        label: '中国工商银行股份有限公司华北石油东风支行',
        value: '102144214835|1309|河北省|沧州市'
      },
      {
        label: '中国工商银行华北石油北环支行',
        value: '102144214886|1309|河北省|沧州市'
      },
      {
        label: '中国工商银行华北石油裕东支行',
        value: '102144214917|1309|河北省|沧州市'
      },
      {
        label: '中国工商银行河间支行',
        value: '102144314121|1309|河北省|沧州市'
      },
      {
        label: '中国工商银行河间城苑支行',
        value: '102144314935|1309|河北省|沧州市'
      },
      {
        label: '中国工商银行河间融兴支行',
        value: '102144314951|1309|河北省|沧州市'
      },
      {
        label: '中国工商银行河间石油支行',
        value: '102144314978|1309|河北省|沧州市'
      },
      {
        label: '中国工商银行河间曙光支行',
        value: '102144314986|1309|河北省|沧州市'
      },
      {
        label: '中国工商银行河间曙东支行',
        value: '102144314994|1309|河北省|沧州市'
      },
      {
        label: '中国工商银行股份有限公司河间米各庄支行',
        value: '102144315288|1309|河北省|沧州市'
      },
      {
        label: '中国工商银行肃宁支行',
        value: '102144414137|1309|河北省|沧州市'
      },
      {
        label: '中国工商银行献县支行',
        value: '102144514149|1309|河北省|沧州市'
      },
      {
        label: '中国工商银行吴桥支行',
        value: '102144614163|1309|河北省|沧州市'
      },
      {
        label: '中国工商银行东光支行',
        value: '102144714159|1309|河北省|沧州市'
      },
      {
        label: '中国工商银行盐山支行',
        value: '102144914184|1309|河北省|沧州市'
      },
      {
        label: '中国工商银行股份有限公司盐山南门支行',
        value: '102144915216|1309|河北省|沧州市'
      },
      {
        label: '中国工商银行黄骅支行',
        value: '102145114172|1309|河北省|沧州市'
      },
      {
        label: '中国工商银行沧州渤海新区支行',
        value: '102145114197|1309|河北省|沧州市'
      },
      {
        label: '中国工商银行黄骅府前支行',
        value: '102145115173|1309|河北省|沧州市'
      },
      {
        label: '中国工商银行黄骅华兴支行',
        value: '102145115181|1309|河北省|沧州市'
      },
      {
        label: '孟村支行',
        value: '102145215222|1309|河北省|沧州市'
      },
      {
        label: '中国工商银行股份有限公司海兴支行',
        value: '102145315262|1309|河北省|沧州市'
      },
      {
        label: '中国工商银行廊坊业务处理中心',
        value: '102146015519|1310|河北省|廊坊市'
      },
      {
        label: '中国工商银行廊坊东安支行',
        value: '102146015535|1310|河北省|廊坊市'
      },
      {
        label: '中国工商银行廊坊朝阳支行',
        value: '102146015924|1310|河北省|廊坊市'
      },
      {
        label: '中国工商银行廊坊开发区支行',
        value: '102146015949|1310|河北省|廊坊市'
      },
      {
        label: '中国工商银行廊坊光明支行',
        value: '102146015981|1310|河北省|廊坊市'
      },
      {
        label: '中国工商银行廊坊广阳支行',
        value: '102146016023|1310|河北省|廊坊市'
      },
      {
        label: '中国工商银行吉林市分行南京路支行',
        value: '102242000078|2202|吉林省|吉林市'
      },
      {
        label: '中国工商银行股份有限公司吉林市大东门支行',
        value: '102242000086|2202|吉林省|吉林市'
      },
      {
        label: '中国工商银行股份有限公司吉林市解放西路支行',
        value: '102242000094|2202|吉林省|吉林市'
      },
      {
        label: '中国工商银行股份有限公司吉林市物华商城支行',
        value: '102242000168|2202|吉林省|吉林市'
      },
      {
        label: '中国工商银行吉林市分行北大街支行',
        value: '102242000184|2202|吉林省|吉林市'
      },
      {
        label: '中国工商银行吉林市分行哈达支行',
        value: '102242000192|2202|吉林省|吉林市'
      },
      {
        label: '中国工商银行股份有限公司吉林市四川路支行',
        value: '102242000230|2202|吉林省|吉林市'
      },
      {
        label: '中国工商银行股份有限公司唐山勤源里支行',
        value: '102124002562|1302|河北省|唐山市'
      },
      {
        label: '中国工商银行股份有限公司唐山雅园支行',
        value: '102124002587|1302|河北省|唐山市'
      },
      {
        label: '中国工商银行唐山开滦支行',
        value: '102124002595|1302|河北省|唐山市'
      },
      {
        label: '中国工商银行唐山西山支行',
        value: '102124002618|1302|河北省|唐山市'
      },
      {
        label: '中国工商银行唐山农贸支行',
        value: '102124002634|1302|河北省|唐山市'
      },
      {
        label: '中国工商银行股份有限公司晋中经纬顺业支行',
        value: '102175027209|1407|山西省|晋中市'
      },
      {
        label: '中国工商银行晋中市分行迎宾支行萧河分理处',
        value: '102175027217|1407|山西省|晋中市'
      },
      {
        label: '中国工商银行股份有限公司晋中粮店街支行',
        value: '102175027225|1407|山西省|晋中市'
      },
      {
        label: '中国工商银行廊坊新华支行',
        value: '102146016058|1310|河北省|廊坊市'
      },
      {
        label: '中国工商银行廊坊和平支行',
        value: '102146016066|1310|河北省|廊坊市'
      },
      {
        label: '中国工商银行廊坊曙光支行',
        value: '102146016099|1310|河北省|廊坊市'
      },
      {
        label: '中国工商银行廊坊万庄支行',
        value: '102146016179|1310|河北省|廊坊市'
      },
      {
        label: '中国工商银行廊坊金光支行',
        value: '102146016226|1310|河北省|廊坊市'
      },
      {
        label: '中国工商银行股份有限公司廊坊燕顺支行',
        value: '102146104287|1310|河北省|廊坊市'
      },
      {
        label: '中国工商银行廊坊燕郊支行',
        value: '102146115826|1310|河北省|廊坊市'
      },
      {
        label: '中国工商银行廊坊迎宾支行',
        value: '102146115859|1310|河北省|廊坊市'
      },
      {
        label: '中国工商银行廊坊行宫支行',
        value: '102146115867|1310|河北省|廊坊市'
      },
      {
        label: '中国工商银行三河支行',
        value: '102146116273|1310|河北省|廊坊市'
      },
      {
        label: '中国工商银行股份有限公司永清支行',
        value: '102146316424|1310|河北省|廊坊市'
      },
      {
        label: '中国工商银行广平支行',
        value: '102128707102|2103|辽宁省|鞍山市'
      },
      {
        label: '中国工商银行成安支行',
        value: '102128807234|2103|辽宁省|鞍山市'
      },
      {
        label: '中国工商银行临漳支行',
        value: '102128907257|2103|辽宁省|鞍山市'
      },
      {
        label: '中国工商银行磁县支行',
        value: '102129107270|2103|辽宁省|鞍山市'
      },
      {
        label: '中国工商银行邯郸天津铁厂支行',
        value: '102129206805|2103|辽宁省|鞍山市'
      },
      {
        label: '中国工商银行涉县支行',
        value: '102129207302|2103|辽宁省|鞍山市'
      },
      {
        label: '中国工商银行永年支行',
        value: '102129307045|2103|辽宁省|鞍山市'
      },
      {
        label: '中国工商银行股份有限公司永年融兴支行',
        value: '102129307053|2103|辽宁省|鞍山市'
      },
      {
        label: '中国工商银行永年河北铺支行',
        value: '102129307061|2103|辽宁省|鞍山市'
      },
      {
        label: '中国工商银行馆陶支行',
        value: '102129407125|2103|辽宁省|鞍山市'
      },
      {
        label: '中国工商银行武安支行',
        value: '102129506888|2103|辽宁省|鞍山市'
      },
      {
        label: '中国工商银行邯郸新兴支行',
        value: '102129507573|2103|辽宁省|鞍山市'
      },
      {
        label: '中国工商银行邢台业务处理中心',
        value: '102131008019|1305|河北省|邢台市'
      },
      {
        label: '中国工商银行邢台开元支行',
        value: '102131008561|1305|河北省|邢台市'
      },
      {
        label: '中国工商银行邢台清风楼支行',
        value: '102131008588|1305|河北省|邢台市'
      },
      {
        label: '中国工商银行邢台豫新支行',
        value: '102131008596|1305|河北省|邢台市'
      },
      {
        label: '中国工商银行邢台新华支行',
        value: '102131008631|1305|河北省|邢台市'
      },
      {
        label: '中国工商银行邢台花园支行',
        value: '102131008738|1305|河北省|邢台市'
      },
      {
        label: '中国工商银行邢台桥东支行',
        value: '102131008754|1305|河北省|邢台市'
      },
      {
        label: '中国工商银行邢台青园支行',
        value: '102131008779|1305|河北省|邢台市'
      },
      {
        label: '中国工商银行邢台金华支行',
        value: '102131008826|1305|河北省|邢台市'
      },
      {
        label: '中国工商银行邢台冶金支行',
        value: '102131008842|1305|河北省|邢台市'
      },
      {
        label: '中国工商银行邢台团结支行',
        value: '102131008859|1305|河北省|邢台市'
      },
      {
        label: '中国工商银行邢台新光支行',
        value: '102131008939|1305|河北省|邢台市'
      },
      {
        label: '中国工商银行邢台鸿溪支行',
        value: '102131008947|1305|河北省|邢台市'
      },
      {
        label: '中国工商银行邢台邢州支行',
        value: '102131008963|1305|河北省|邢台市'
      },
      {
        label: '中国工商银行邢台顺德支行',
        value: '102131009030|1305|河北省|邢台市'
      },
      {
        label: '中国工商银行邢台泉北支行',
        value: '102131009056|1305|河北省|邢台市'
      },
      {
        label: '中国工商银行邢台豫让桥支行',
        value: '102131009064|1305|河北省|邢台市'
      },
      {
        label: '中国工商银行邢台桥西支行',
        value: '102131009072|1305|河北省|邢台市'
      },
      {
        label: '中国工商银行邢台钢北支行',
        value: '102131009110|1305|河北省|邢台市'
      },
      {
        label: '中国工商银行邢台万城支行',
        value: '102131009144|1305|河北省|邢台市'
      },
      {
        label: '中国工商银行邢台运河支行',
        value: '102131009152|1305|河北省|邢台市'
      },
      {
        label: '中国工商银行邢台太行支行',
        value: '102131009193|1305|河北省|邢台市'
      },
      {
        label: '中国工商银行邢台中兴支行',
        value: '102131009208|1305|河北省|邢台市'
      },
      {
        label: '中国工商银行邢台守敬支行',
        value: '102131009216|1305|河北省|邢台市'
      },
      {
        label: '中国工商银行南宫支行',
        value: '102132108127|1305|河北省|邢台市'
      },
      {
        label: '中国工商银行邢台沙河支行',
        value: '102132208402|1305|河北省|邢台市'
      },
      {
        label: '中国工商银行临城支行',
        value: '102132308312|1305|河北省|邢台市'
      },
      {
        label: '中国工商银行股份有限公司内丘支行',
        value: '102132408365|1305|河北省|邢台市'
      },
      {
        label: '中国工商银行隆尧支行',
        value: '102132608266|1305|河北省|邢台市'
      },
      {
        label: '中国工商银行股份有限公司任县支行',
        value: '102132709045|1305|河北省|邢台市'
      },
      {
        label: '中国工商银行股份有限公司南和支行',
        value: '102132809232|1305|河北省|邢台市'
      },
      {
        label: '中国工商银行宁晋支行',
        value: '102132908191|1305|河北省|邢台市'
      },
      {
        label: '中国工商银行股份有限公司绥化北林支行',
        value: '102276000096|2312|黑龙江省|绥化市'
      },
      {
        label: '中国工商银行股份有限公司伊春分行业务处理中心',
        value: '102271009008|2307|黑龙江省|伊春市'
      },
      {
        label: '中国工商银行股份有限公司伊春红升分理处',
        value: '102271020029|2307|黑龙江省|伊春市'
      },
      {
        label: '中国工商银行股份有限公司伊春中远支行',
        value: '102271025081|2307|黑龙江省|伊春市'
      },
      {
        label: '中国工商银行股份有限公司伊春市嘉荫县支行',
        value: '102271102260|2307|黑龙江省|伊春市'
      },
      {
        label: '中国工商银行股份有限公司嘉荫百万支行',
        value: '102271128083|2307|黑龙江省|伊春市'
      },
      {
        label: '中国工商银行股份有限公司铁力市支行',
        value: '102271202530|2307|黑龙江省|伊春市'
      },
      {
        label: '中国工商银行股份有限公司铁力农场支行',
        value: '102271202781|2307|黑龙江省|伊春市'
      },
      {
        label: '中国工商银行股份有限公司铁力市朗乡分理处',
        value: '102271204068|2307|黑龙江省|伊春市'
      },
      {
        label: '中国工商银行股份有限公司铁力市双丰分理处',
        value: '102271204076|2307|黑龙江省|伊春市'
      },
      {
        label: '中国工商银行股份有限公司铁力市桃山分理处',
        value: '102271204130|2307|黑龙江省|伊春市'
      },
      {
        label: '中国工商银行股份有限公司牡丹江旺角支行',
        value: '102272000021|2308|黑龙江省|佳木斯市'
      },
      {
        label: '中国工商银行股份有限公司牡丹江东一支行',
        value: '102272000030|2308|黑龙江省|佳木斯市'
      },
      {
        label: '中国工商银行股份有限公司牡丹江兴力支行',
        value: '102272000056|2308|黑龙江省|佳木斯市'
      },
      {
        label: '中国工商银行股份有限公司牡丹江长兴支行',
        value: '102272000101|2308|黑龙江省|佳木斯市'
      },
      {
        label: '中国工商银行股份有限公司牡丹江大庆路支行',
        value: '102272000193|2308|黑龙江省|佳木斯市'
      },
      {
        label: '中国工商银行股份有限公司牡丹江平安支行',
        value: '102272000265|2308|黑龙江省|佳木斯市'
      },
      {
        label: '中国工商银行股份有限公司牡丹江信通支行',
        value: '102272002011|2308|黑龙江省|佳木斯市'
      },
      {
        label: '中国工商银行牡丹江市分行长安街支行',
        value: '102272002020|2308|黑龙江省|佳木斯市'
      },
      {
        label: '中国工商银行牡丹江市分行新华路支行',
        value: '102272002038|2308|黑龙江省|佳木斯市'
      },
      {
        label: '中国工商银行股份有限公司无锡阳光花园支行',
        value: '102302003526|3202|江苏省|无锡市'
      },
      {
        label: '中国工商银行股份有限公司无锡阳山支行',
        value: '102302004633|3202|江苏省|无锡市'
      },
      {
        label: '中国工商银行股份有限公司无锡鸿山支行',
        value: '102302004668|3202|江苏省|无锡市'
      },
      {
        label: '中国工商银行股份有限公司无锡百联支行',
        value: '102302004676|3202|江苏省|无锡市'
      },
      {
        label: '中国工商银行股份有限公司无锡羊尖支行',
        value: '102302004692|3202|江苏省|无锡市'
      },
      {
        label: '中国工商银行股份有限公司无锡观山路支行',
        value: '102302005040|3202|江苏省|无锡市'
      },
      {
        label: '中国工商银行股份有限公司无锡江海路支行',
        value: '102302005234|3202|江苏省|无锡市'
      },
      {
        label: '中国工商银行股份有限公司无锡宁海支行',
        value: '102302005259|3202|江苏省|无锡市'
      },
      {
        label: '中国工商银行股份有限公司无锡黄巷支行',
        value: '102302005435|3202|江苏省|无锡市'
      },
      {
        label: '中国工商银行股份有限公司无锡人民东路支行',
        value: '102302005451|3202|江苏省|无锡市'
      },
      {
        label: '中国工商银行股份有限公司无锡杨市支行',
        value: '102302005460|3202|江苏省|无锡市'
      },
      {
        label: '中国工商银行股份有限公司无锡荣巷支行',
        value: '102302005533|3202|江苏省|无锡市'
      },
      {
        label: '中国工商银行股份有限公司无锡中桥支行',
        value: '102302005613|3202|江苏省|无锡市'
      },
      {
        label: '中国工商银行股份有限公司无锡太湖国际社区支行',
        value: '102302005728|3202|江苏省|无锡市'
      },
      {
        label: '中国工商银行股份有限公司无锡长安支行',
        value: '102302005736|3202|江苏省|无锡市'
      },
      {
        label: '中国工商银行股份有限公司无锡工博园支行',
        value: '102302005744|3202|江苏省|无锡市'
      },
      {
        label: '中国工商银行股份有限公司海门临江支行',
        value: '102306552083|3206|江苏省|南通市'
      },
      {
        label: '中国工商银行股份有限公司海门开发区分理处',
        value: '102306552219|3206|江苏省|南通市'
      },
      {
        label: '中国工商银行海门市支行',
        value: '102306552712|3206|江苏省|南通市'
      },
      {
        label: '中国工商银行海门解放路支行',
        value: '102306552729|3206|江苏省|南通市'
      },
      {
        label: '中国工商银行股份有限公司海门三厂支行',
        value: '102306552737|3206|江苏省|南通市'
      },
      {
        label: '中国工商银行股份有限公司海门东洲支行',
        value: '102306552745|3206|江苏省|南通市'
      },
      {
        label: '中国工商银行股份有限公司海门城南支行',
        value: '102306552761|3206|江苏省|南通市'
      },
      {
        label: '中国工商银行股份有限公司海门正余支行',
        value: '102306552770|3206|江苏省|南通市'
      },
      {
        label: '中国工商银行股份有限公司南通国际家纺城支行',
        value: '102306552788|3206|江苏省|南通市'
      },
      {
        label: '中国工商银行股份有限公司海门七一桥支行',
        value: '102306552796|3206|江苏省|南通市'
      },
      {
        label: '中国工商银行启东市支行',
        value: '102306600016|3206|江苏省|南通市'
      },
      {
        label: '中国工商银行启东城中支行',
        value: '102306600024|3206|江苏省|南通市'
      },
      {
        label: '中国工商银行启东吕四支行',
        value: '102306600049|3206|江苏省|南通市'
      },
      {
        label: '中国工商银行股份有限公司启东城北支行',
        value: '102306600065|3206|江苏省|南通市'
      },
      {
        label: '中国工商银行股份有限公司启东花园路支行',
        value: '102306600073|3206|江苏省|南通市'
      },
      {
        label: '中国工商银行启东市城东分理处',
        value: '102306600081|3206|江苏省|南通市'
      },
      {
        label: '中国工商银行启东市人民东路分理处',
        value: '102306600090|3206|江苏省|南通市'
      },
      {
        label: '中国工商银行股份有限公司启东城南支行',
        value: '102306600104|3206|江苏省|南通市'
      },
      {
        label: '中国工商银行沈阳松山支行',
        value: '102221004408|2101|辽宁省|沈阳市'
      },
      {
        label: '中国工商银行故城支行',
        value: '102148917273|1311|河北省|衡水市'
      },
      {
        label: '中国工商银行景县支行',
        value: '102149117321|1311|河北省|衡水市'
      },
      {
        label: '中国工商银行景县龙华支行',
        value: '102149117364|1311|河北省|衡水市'
      },
      {
        label: '中国工商银行阜城支行',
        value: '102149217402|1311|河北省|衡水市'
      },
      {
        label: '中国工商银行股份有限公司山西省分行',
        value: '102161000017|1401|山西省|太原市'
      },
      {
        label: '中国工商银行股份有限公司山西省分行营业部',
        value: '102161000105|1401|山西省|太原市'
      },
      {
        label: '中国工商银行股份有限公司太原迎泽支行',
        value: '102161000113|1401|山西省|太原市'
      },
      {
        label: '中国工商银行股份有限公司太原五一路支行',
        value: '102161000121|1401|山西省|太原市'
      },
      {
        label: '中国工商银行抚松松江河支行',
        value: '102246100366|2206|吉林省|白山市'
      },
      {
        label: '中国工商银行股份有限公司锡林浩特那达慕东街支行',
        value: '102201006271|1525|内蒙古自治区|锡林郭勒盟'
      },
      {
        label: '中国工商银行股份有限公司宜兴万石支行',
        value: '102302304701|3202|江苏省|无锡市'
      },
      {
        label: '中国工商银行股份有限公司宜兴徐舍支行',
        value: '102302305384|3202|江苏省|无锡市'
      },
      {
        label: '中国工商银行股份有限公司宜兴屺亭支行',
        value: '102302305753|3202|江苏省|无锡市'
      },
      {
        label: '中国工商银行徐州市分行核算中心',
        value: '102303002003|3203|江苏省|徐州市'
      },
      {
        label: '中国工商银行徐州市分行营业部',
        value: '102303002011|3203|江苏省|徐州市'
      },
      {
        label: '中国工商银行徐州市云龙支行',
        value: '102303002020|3203|江苏省|徐州市'
      },
      {
        label: '中国工商银行徐州市鼓楼支行',
        value: '102303002038|3203|江苏省|徐州市'
      },
      {
        label: '中国工商银行徐州市淮海东路支行',
        value: '102303002046|3203|江苏省|徐州市'
      },
      {
        label: '中国工商银行徐州市淮海西路支行',
        value: '102303002062|3203|江苏省|徐州市'
      },
      {
        label: '中国工商银行股份有限公司徐州铜山支行',
        value: '102303002100|3203|江苏省|徐州市'
      },
      {
        label: '中国工商银行徐州市泉山支行',
        value: '102303002118|3203|江苏省|徐州市'
      },
      {
        label: '中国工商银行徐州市贾汪支行',
        value: '102303002126|3203|江苏省|徐州市'
      },
      {
        label: '中国工商银行徐州金马支行',
        value: '102303002134|3203|江苏省|徐州市'
      },
      {
        label: '中国工商银行徐州市经济开发区支行',
        value: '102303002142|3203|江苏省|徐州市'
      },
      {
        label: '中国工商银行徐州东苑支行',
        value: '102303002167|3203|江苏省|徐州市'
      },
      {
        label: '中国工商银行徐州市权台分理处',
        value: '102303002175|3203|江苏省|徐州市'
      },
      {
        label: '中国工商银行徐州万里支行',
        value: '102303002191|3203|江苏省|徐州市'
      },
      {
        label: '中国工商银行股份有限公司徐州建东支行',
        value: '102303002214|3203|江苏省|徐州市'
      },
      {
        label: '中国工商银行徐州矿山路支行',
        value: '102303002222|3203|江苏省|徐州市'
      },
      {
        label: '中国工商银行徐州市大黄山矿支行',
        value: '102303002239|3203|江苏省|徐州市'
      },
      {
        label: '中国工商银行徐州利国支行',
        value: '102303002247|3203|江苏省|徐州市'
      },
      {
        label: '中国工商银行徐州庞庄支行',
        value: '102303002271|3203|江苏省|徐州市'
      },
      {
        label: '中国工商银行徐州府苑支行',
        value: '102303002298|3203|江苏省|徐州市'
      },
      {
        label: '中国工商银行徐州坝子街支行',
        value: '102303002319|3203|江苏省|徐州市'
      },
      {
        label: '中国工商银行徐州化机支行',
        value: '102303002327|3203|江苏省|徐州市'
      },
      {
        label: '中国工商银行股份有限公司徐州帝都支行',
        value: '102303003119|3203|江苏省|徐州市'
      },
      {
        label: '中国工商银行徐州市中山支行',
        value: '102303003151|3203|江苏省|徐州市'
      },
      {
        label: '中国工商银行股份有限公司丹阳眼镜城支行',
        value: '102314102404|3211|江苏省|镇江市'
      },
      {
        label: '中国工商银行股份有限公司丹阳新城支行',
        value: '102314102412|3211|江苏省|镇江市'
      },
      {
        label: '中国工商银行股份有限公司丹阳后巷支行',
        value: '102314102507|3211|江苏省|镇江市'
      },
      {
        label: '中国工商银行股份有限公司丹阳城东支行',
        value: '102314102558|3211|江苏省|镇江市'
      },
      {
        label: '中国工商银行股份有限公司丹阳开发区支行',
        value: '102314102574|3211|江苏省|镇江市'
      },
      {
        label: '中国工商银行股份有限公司丹阳新桥支行',
        value: '102314102582|3211|江苏省|镇江市'
      },
      {
        label: '中国工商银行股份有限公司丹阳界牌支行',
        value: '102314102599|3211|江苏省|镇江市'
      },
      {
        label: '中国工商银行股份有限公司丹阳西门支行',
        value: '102314102603|3211|江苏省|镇江市'
      },
      {
        label: '中国工商银行股份有限公司丹阳皇塘支行',
        value: '102314102611|3211|江苏省|镇江市'
      },
      {
        label: '中国工商银行股份有限公司丹阳华南支行',
        value: '102314102654|3211|江苏省|镇江市'
      },
      {
        label: '工商银行句容城中支行',
        value: '102314203002|3211|江苏省|镇江市'
      },
      {
        label: '中国工商银行股份有限公司句容支行',
        value: '102314203035|3211|江苏省|镇江市'
      },
      {
        label: '中国工商银行股份有限公司句容北门分理处',
        value: '102314203043|3211|江苏省|镇江市'
      },
      {
        label: '中国工商银行股份有限公司句容车站支行',
        value: '102314203060|3211|江苏省|镇江市'
      },
      {
        label: '中国工商银行股份有限公司句容河滨支行',
        value: '102314203094|3211|江苏省|镇江市'
      },
      {
        label: '中国工商银行股份有限公司句容下蜀支行',
        value: '102314203109|3211|江苏省|镇江市'
      },
      {
        label: '中国工商银行股份有限公司句容崇明桥支行',
        value: '102314203168|3211|江苏省|镇江市'
      },
      {
        label: '中国工商银行股份有限公司句容宝华支行',
        value: '102314203176|3211|江苏省|镇江市'
      },
      {
        label: '中国工商银行股份有限公司句容南门支行',
        value: '102314203184|3211|江苏省|镇江市'
      },
      {
        label: '中国工商银行股份有限公司扬中支行',
        value: '102314304437|3211|江苏省|镇江市'
      },
      {
        label: '中国工商银行股份有限公司扬中江洲支行',
        value: '102314304806|3211|江苏省|镇江市'
      },
      {
        label: '中国工商银行股份有限公司扬中新坝支行',
        value: '102314304814|3211|江苏省|镇江市'
      },
      {
        label: '中国工商银行股份有限公司扬中扬子东路支行',
        value: '102314304863|3211|江苏省|镇江市'
      },
      {
        label: '中国工商银行杭州经济技术开发区支行',
        value: '102331005059|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行杭州文三路支行',
        value: '102331005067|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行杭州庆春东路支行',
        value: '102331005083|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行股份有限公司杭州孝丰路支行',
        value: '102331005114|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行股份有限公司杭州广电支行',
        value: '102331005139|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行股份有限公司杭州半道红支行',
        value: '102331005147|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行杭州城站广场支行',
        value: '102331005163|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行杭州观音桥支行',
        value: '102331005171|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行股份有限公司杭州运河支行',
        value: '102331005180|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行股份有限公司杭州五洲支行',
        value: '102331005198|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行股份有限公司杭州国际花园支行',
        value: '102331005235|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行富阳市支行龙山路分理处',
        value: '102331005243|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行建德市支行新安江分理处',
        value: '102331005251|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行股份有限公司杭州金晖支行',
        value: '102331005260|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行股份有限公司杭州城北支行',
        value: '102331005278|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行猫儿岭支行',
        value: '102175027233|1407|山西省|晋中市'
      },
      {
        label: '中国工商银行股份有限公司晋中大学城支行',
        value: '102175027292|1407|山西省|晋中市'
      },
      {
        label: '中国工商银行晋中安宁支行',
        value: '102175027305|1407|山西省|晋中市'
      },
      {
        label: '中国工商银行榆社县支行',
        value: '102175201475|1407|山西省|晋中市'
      },
      {
        label: '中国工商银行股份有限公司左权城区支行',
        value: '102175302868|1407|山西省|晋中市'
      },
      {
        label: '中国工商银行股份有限公司和顺云龙支行',
        value: '102175402883|1407|山西省|晋中市'
      },
      {
        label: '中国工商银行昔阳县支行',
        value: '102175501417|1407|山西省|晋中市'
      },
      {
        label: '中国工商银行昔阳新建路支行',
        value: '102175527170|1407|山西省|晋中市'
      },
      {
        label: '中国工商银行股份有限公司江阴北国支行',
        value: '102302202829|3202|江苏省|无锡市'
      },
      {
        label: '中国工商银行股份有限公司江阴新桥支行',
        value: '102302202837|3202|江苏省|无锡市'
      },
      {
        label: '中国工商银行股份有限公司江阴丽都支行',
        value: '102302202853|3202|江苏省|无锡市'
      },
      {
        label: '中国工商银行股份有限公司江阴璜土支行',
        value: '102302203217|3202|江苏省|无锡市'
      },
      {
        label: '中国工商银行股份有限公司江阴霞客支行',
        value: '102302203532|3202|江苏省|无锡市'
      },
      {
        label: '中国工商银行股份有限公司江阴申港支行',
        value: '102302204620|3202|江苏省|无锡市'
      },
      {
        label: '中国工商银行股份有限公司江阴云亭支行',
        value: '102302204646|3202|江苏省|无锡市'
      },
      {
        label: '中国工商银行股份有限公司江阴南闸支行',
        value: '102302204687|3202|江苏省|无锡市'
      },
      {
        label: '中国工商银行股份有限公司江阴城东支行',
        value: '102302205403|3202|江苏省|无锡市'
      },
      {
        label: '中国工商银行股份有限公司江阴天鹤路支行',
        value: '102302206326|3202|江苏省|无锡市'
      },
      {
        label: '中国工商银行股份有限公司江阴大桥支行',
        value: '102302206406|3202|江苏省|无锡市'
      },
      {
        label: '中国工商银行股份有限公司江阴中山支行',
        value: '102302206480|3202|江苏省|无锡市'
      },
      {
        label: '中国工商银行股份有限公司宜兴环科园支行',
        value: '102302302868|3202|江苏省|无锡市'
      },
      {
        label: '工商银行宜兴城中支行',
        value: '102302302876|3202|江苏省|无锡市'
      },
      {
        label: '工商银行宜兴市支行',
        value: '102302302884|3202|江苏省|无锡市'
      },
      {
        label: '工商银行宜兴东山支行',
        value: '102302302892|3202|江苏省|无锡市'
      },
      {
        label: '工商银行宜兴市宜城锦绣分理处',
        value: '102302302905|3202|江苏省|无锡市'
      },
      {
        label: '中国工商银行股份有限公司宜兴丁蜀支行',
        value: '102302302913|3202|江苏省|无锡市'
      },
      {
        label: '工商银行宜兴张渚支行',
        value: '102302302948|3202|江苏省|无锡市'
      },
      {
        label: '工商银行宜兴市和桥支行',
        value: '102302302956|3202|江苏省|无锡市'
      },
      {
        label: '工商银行宜兴官林支行',
        value: '102302302964|3202|江苏省|无锡市'
      },
      {
        label: '中国工商银行股份有限公司泰兴大庆支行',
        value: '102312492647|3212|江苏省|泰州市'
      },
      {
        label: '中国工商银行股份有限公司泰兴广源支行',
        value: '102312492671|3212|江苏省|泰州市'
      },
      {
        label: '中国工商银行股份有限公司泰兴济川支行',
        value: '102312492680|3212|江苏省|泰州市'
      },
      {
        label: '中国工商银行股份有限公司泰兴南二环路支行',
        value: '102312492727|3212|江苏省|泰州市'
      },
      {
        label: '中国工商银行股份有限公司泰兴滨江支行',
        value: '102312492735|3212|江苏省|泰州市'
      },
      {
        label: '中国工商银行股份有限公司扬州东方红路支行',
        value: '102312505017|3212|江苏省|泰州市'
      },
      {
        label: '中国工商银行股份有限公司扬州城北支行',
        value: '102312505050|3212|江苏省|泰州市'
      },
      {
        label: '中国工商银行股份有限公司扬州龙川支行',
        value: '102312505113|3212|江苏省|泰州市'
      },
      {
        label: '中国工商银行股份有限公司扬州江都支行',
        value: '102312581004|3212|江苏省|泰州市'
      },
      {
        label: '中国工商银行股份有限公司扬州兴业支行',
        value: '102312581012|3212|江苏省|泰州市'
      },
      {
        label: '中国工商银行股份有限公司扬州新城支行',
        value: '102312581029|3212|江苏省|泰州市'
      },
      {
        label: '中国工商银行股份有限公司扬州城中支行',
        value: '102312581037|3212|江苏省|泰州市'
      },
      {
        label: '中国工商银行股份有限公司扬州邵伯支行',
        value: '102312581045|3212|江苏省|泰州市'
      },
      {
        label: '中国工商银行股份有限公司扬州小纪支行',
        value: '102312581061|3212|江苏省|泰州市'
      },
      {
        label: '中国工商银行股份有限公司扬州滨江支行',
        value: '102312581070|3212|江苏省|泰州市'
      },
      {
        label: '中国工商银行沈阳市五爱支行',
        value: '102221000760|2101|辽宁省|沈阳市'
      },
      {
        label: '中国工商银行沈阳市大东支行',
        value: '102221000809|2101|辽宁省|沈阳市'
      },
      {
        label: '中国工商银行沈阳吉祥支行',
        value: '102221000817|2101|辽宁省|沈阳市'
      },
      {
        label: '中国工商银行沈阳大北街支行',
        value: '102221000825|2101|辽宁省|沈阳市'
      },
      {
        label: '中国工商银行沈阳文官支行',
        value: '102221000833|2101|辽宁省|沈阳市'
      },
      {
        label: '中国工商银行沈阳东塔支行',
        value: '102221000841|2101|辽宁省|沈阳市'
      },
      {
        label: '中国工商银行沈阳市皇姑支行',
        value: '102221000905|2101|辽宁省|沈阳市'
      },
      {
        label: '中国工商银行沈阳龙江支行',
        value: '102221000913|2101|辽宁省|沈阳市'
      },
      {
        label: '中国工商银行沈阳于洪支行',
        value: '102221000921|2101|辽宁省|沈阳市'
      },
      {
        label: '中国工商银行沈阳昆山西路支行',
        value: '102221000930|2101|辽宁省|沈阳市'
      },
      {
        label: '中国工商银行沈阳崇山东路支行',
        value: '102221000948|2101|辽宁省|沈阳市'
      },
      {
        label: '中国工商银行股份有限公司吉林市东昌支行',
        value: '102242000553|2202|吉林省|吉林市'
      },
      {
        label: '中国工商银行股份有限公司吉林市解放北路支行',
        value: '102242000561|2202|吉林省|吉林市'
      },
      {
        label: '中国工商银行股份有限公司吉林市中兴街支行',
        value: '102242000570|2202|吉林省|吉林市'
      },
      {
        label: '中国工商银行吉林市分行高新技术产业开发区支行',
        value: '102242000607|2202|吉林省|吉林市'
      },
      {
        label: '中国工商银行股份有限公司吉林市新世纪支行',
        value: '102242000615|2202|吉林省|吉林市'
      },
      {
        label: '中国工商银行股份有限公司吉林市恒山西路支行',
        value: '102242000631|2202|吉林省|吉林市'
      },
      {
        label: '中国工商银行股份有限公司吉林市河南街支行',
        value: '102242000640|2202|吉林省|吉林市'
      },
      {
        label: '中国工商银行股份有限公司吉林市口前支行',
        value: '102242100011|2202|吉林省|吉林市'
      },
      {
        label: '中国工商银行股份有限公司锦州电厂支行',
        value: '102227000463|2107|辽宁省|锦州市'
      },
      {
        label: '中国工商银行绥中县支行',
        value: '102227100013|2107|辽宁省|锦州市'
      },
      {
        label: '中国工商银行凌海市支行',
        value: '102227200014|2107|辽宁省|锦州市'
      },
      {
        label: '中国工商银行股份有限公司绥化站前支行',
        value: '102276000107|2312|黑龙江省|绥化市'
      },
      {
        label: '中国工商银行股份有限公司绥化祥和路支行',
        value: '102276000123|2312|黑龙江省|绥化市'
      },
      {
        label: '中国工商银行股份有限公司绥化中兴大街支行',
        value: '102276000140|2312|黑龙江省|绥化市'
      },
      {
        label: '中国工商银行股份有限公司绥化农垦支行',
        value: '102276000166|2312|黑龙江省|绥化市'
      },
      {
        label: '中国工商银行股份有限公司绥化正阳支行',
        value: '102276000182|2312|黑龙江省|绥化市'
      },
      {
        label: '中国工商银行绥化分行核算中心',
        value: '102276001007|2312|黑龙江省|绥化市'
      },
      {
        label: '中国工商银行股份有限公司绥化开发区支行',
        value: '102276002198|2312|黑龙江省|绥化市'
      },
      {
        label: '中国工商银行股份有限公司安达支行',
        value: '102276200014|2312|黑龙江省|绥化市'
      },
      {
        label: '中国工商银行股份有限公司安达南三支行',
        value: '102276200022|2312|黑龙江省|绥化市'
      },
      {
        label: '中国工商银行股份有限公司肇东支行',
        value: '102276300015|2312|黑龙江省|绥化市'
      },
      {
        label: '中国工商银行股份有限公司海伦支行',
        value: '102276400016|2312|黑龙江省|绥化市'
      },
      {
        label: '中国工商银行股份有限公司青冈支行',
        value: '102276700077|2312|黑龙江省|绥化市'
      },
      {
        label: '中国工商银行股份有限公司肇州支行',
        value: '102276906091|2306|黑龙江省|大庆市'
      },
      {
        label: '中国工商银行股份有限公司庆安支行',
        value: '102277100015|2312|黑龙江省|绥化市'
      },
      {
        label: '中国工商银行股份有限公司乌兰察布恩和路支行',
        value: '102203018193|1509|内蒙古自治区|乌兰察布市'
      },
      {
        label: '中国工商银行股份有限公司乌兰察布文化路支行',
        value: '102203018208|1509|内蒙古自治区|乌兰察布市'
      },
      {
        label: '中国工商银行股份有限公司乌兰察布水语山城支行',
        value: '102203018232|1509|内蒙古自治区|乌兰察布市'
      },
      {
        label: '中国工商银行卓资支行',
        value: '102203507176|1509|内蒙古自治区|乌兰察布市'
      },
      {
        label: '中国工商银行股份有限公司卓资人民路支行',
        value: '102203518441|1509|内蒙古自治区|乌兰察布市'
      },
      {
        label: '中国工商银行股份有限公司商都支行',
        value: '102203718112|1509|内蒙古自治区|乌兰察布市'
      },
      {
        label: '中国工商银行乌兰察布兴和支行',
        value: '102203818661|1509|内蒙古自治区|乌兰察布市'
      },
      {
        label: '中国工商银行丰镇支行',
        value: '102203907092|1509|内蒙古自治区|乌兰察布市'
      },
      {
        label: '中国工商银行股份有限公司丰镇城隍庙支行',
        value: '102203918322|1509|内蒙古自治区|乌兰察布市'
      },
      {
        label: '中国工商银行股份有限公司丰镇化肥路支行',
        value: '102203918371|1509|内蒙古自治区|乌兰察布市'
      },
      {
        label: '中国工商银行凉城岱海电厂支行',
        value: '102204103139|1509|内蒙古自治区|乌兰察布市'
      },
      {
        label: '中国工商银行察右前旗支行',
        value: '102204203183|1509|内蒙古自治区|乌兰察布市'
      },
      {
        label: '中国工商银行乌兰察布集宁区支行科布尔分理处',
        value: '102204307168|1509|内蒙古自治区|乌兰察布市'
      },
      {
        label: '中国工商银行察右后旗支行',
        value: '102204407119|1509|内蒙古自治区|乌兰察布市'
      },
      {
        label: '中国工商银行股份有限公司察哈尔右翼后旗广场支行',
        value: '102204418527|1509|内蒙古自治区|乌兰察布市'
      },
      {
        label: '中国工商银行股份有限公司包头百灵庙支行',
        value: '102204521158|1509|内蒙古自治区|乌兰察布市'
      },
      {
        label: '中国工商银行四子王支行',
        value: '102204603359|1509|内蒙古自治区|乌兰察布市'
      },
      {
        label: '中国工商银行股份有限公司四子王旗西街支行',
        value: '102204618604|1509|内蒙古自治区|乌兰察布市'
      },
      {
        label: '中国工商银行股份有限公司鄂尔多斯伊煤路支行',
        value: '102205000036|1506|内蒙古自治区|鄂尔多斯市'
      },
      {
        label: '中国工商银行股份有限公司鄂尔多斯塞北支行',
        value: '102205000044|1506|内蒙古自治区|鄂尔多斯市'
      },
      {
        label: '中国工商银行股份有限公司鄂尔多斯林荫路支行',
        value: '102205000069|1506|内蒙古自治区|鄂尔多斯市'
      },
      {
        label: '中国工商银行股份有限公司鄂尔多斯阿拉腾支行',
        value: '102205000077|1506|内蒙古自治区|鄂尔多斯市'
      },
      {
        label: '中国工商银行股份有限公司郑州陇海中路支行',
        value: '102491052151|4101|河南省|郑州市'
      },
      {
        label: '中国工商银行股份有限公司二连浩特恐龙大街支行',
        value: '102201100048|1525|内蒙古自治区|锡林郭勒盟'
      },
      {
        label: '中国工商银行股份有限公司二连浩特市支行',
        value: '102201104068|1525|内蒙古自治区|锡林郭勒盟'
      },
      {
        label: '中国工商银行股份有限公司阿巴嘎旗支行',
        value: '102201303503|1525|内蒙古自治区|锡林郭勒盟'
      },
      {
        label: '中国工商银行股份有限公司苏尼特右旗朱日和支行',
        value: '102201500017|1525|内蒙古自治区|锡林郭勒盟'
      },
      {
        label: '中国工商银行股份有限公司苏尼特右旗支行',
        value: '102201503517|1525|内蒙古自治区|锡林郭勒盟'
      },
      {
        label: '中国工商银行股份有限公司乌拉盖管理区支行',
        value: '102201600026|1525|内蒙古自治区|锡林郭勒盟'
      },
      {
        label: '中国工商银行股份有限公司东乌珠穆沁旗支行',
        value: '102201604191|1525|内蒙古自治区|锡林郭勒盟'
      },
      {
        label: '中国工商银行股份有限公司西乌珠穆沁旗白音华支行',
        value: '102201700028|1525|内蒙古自治区|锡林郭勒盟'
      },
      {
        label: '中国工商银行股份有限公司西乌珠穆沁旗支行',
        value: '102201703497|1525|内蒙古自治区|锡林郭勒盟'
      },
      {
        label: '中国工商银行股份有限公司太仆寺旗支行',
        value: '102201806245|1525|内蒙古自治区|锡林郭勒盟'
      },
      {
        label: '中国工商银行股份有限公司镶黄旗支行',
        value: '102201903534|1525|内蒙古自治区|锡林郭勒盟'
      },
      {
        label: '中国工商银行股份有限公司正蓝旗支行',
        value: '102202206234|1525|内蒙古自治区|锡林郭勒盟'
      },
      {
        label: '中国工商银行股份有限公司多伦支行',
        value: '102202303736|1525|内蒙古自治区|锡林郭勒盟'
      },
      {
        label: '中国工商银行乌兰察布分行会计业务处理中心',
        value: '102203007000|1509|内蒙古自治区|乌兰察布市'
      },
      {
        label: '中国工商银行乌兰察布市集宁区支行',
        value: '102203007018|1509|内蒙古自治区|乌兰察布市'
      },
      {
        label: '中国工商银行乌兰察布光明街支行',
        value: '102203007026|1509|内蒙古自治区|乌兰察布市'
      },
      {
        label: '中国工商银行乌兰察布市集宁区支行民建路分理处',
        value: '102203007042|1509|内蒙古自治区|乌兰察布市'
      },
      {
        label: '中国工商银行乌兰察布市集宁区支行桥西分理处',
        value: '102203007059|1509|内蒙古自治区|乌兰察布市'
      },
      {
        label: '中国工商银行股份有限公司乌兰察布工业区支行',
        value: '102203007067|1509|内蒙古自治区|乌兰察布市'
      },
      {
        label: '中国工商银行股份有限公司乌兰察布建设街支行',
        value: '102203018005|1509|内蒙古自治区|乌兰察布市'
      },
      {
        label: '中国工商银行股份有限公司乌兰察布广场支行',
        value: '102203018030|1509|内蒙古自治区|乌兰察布市'
      },
      {
        label: '中国工商银行容城支行',
        value: '102136509770|1306|河北省|保定市'
      },
      {
        label: '中国工商银行股份有限公司容城古城支行',
        value: '102136511062|1306|河北省|保定市'
      },
      {
        label: '中国工商银行高碑店支行',
        value: '102136609784|1306|河北省|保定市'
      },
      {
        label: '中国工商银行高碑店白沟支行',
        value: '102136611116|1306|河北省|保定市'
      },
      {
        label: '中国工商银行曲阳支行',
        value: '102136709794|1306|河北省|保定市'
      },
      {
        label: '中国工商银行股份有限公司安国支行',
        value: '102136909804|1306|河北省|保定市'
      },
      {
        label: '中国工商银行股份有限公司呼和浩特军区支行',
        value: '102191000417|1501|内蒙古自治区|呼和浩特市'
      },
      {
        label: '中国工商银行呼和浩特市石羊桥东路支行',
        value: '102191000505|1501|内蒙古自治区|呼和浩特市'
      },
      {
        label: '中国工商银行股份有限公司呼和浩特新华东街支行',
        value: '102191000513|1501|内蒙古自治区|呼和浩特市'
      },
      {
        label: '中国工商银行呼和浩特市锡林南路支行',
        value: '102191000521|1501|内蒙古自治区|呼和浩特市'
      },
      {
        label: '中国工商银行呼和浩特市如意支行',
        value: '102191000530|1501|内蒙古自治区|呼和浩特市'
      },
      {
        label: '中国工商银行股份有限公司呼和浩特敕勒川大街支行',
        value: '102191000572|1501|内蒙古自治区|呼和浩特市'
      },
      {
        label: '中国工商银行股份有限公司呼和浩特市南马路支行',
        value: '102191000677|1501|内蒙古自治区|呼和浩特市'
      },
      {
        label: '中国工商银行股份有限公司呼和浩特市伟业大厦支行',
        value: '102191000708|1501|内蒙古自治区|呼和浩特市'
      },
      {
        label: '中国工商银行股份有限公司呼和浩特大学东路支行',
        value: '102191000732|1501|内蒙古自治区|呼和浩特市'
      },
      {
        label: '中国工商银行股份有限公司呼和浩特市四纬东路支行',
        value: '102191000757|1501|内蒙古自治区|呼和浩特市'
      },
      {
        label: '中国工商银行股份有限公司呼和浩特内蒙古大学创业支行',
        value: '102191000781|1501|内蒙古自治区|呼和浩特市'
      },
      {
        label: '中国工商银行股份有限公司呼和浩特市巨海城第二支行',
        value: '102191000790|1501|内蒙古自治区|呼和浩特市'
      },
      {
        label: '中国工商银行内蒙古自治区分行营业部会计核算中心（不对外办理业',
        value: '102191000804|1501|内蒙古自治区|呼和浩特市'
      },
      {
        label: '中国工商银行呼和浩特市明珠支行',
        value: '102191000812|1501|内蒙古自治区|呼和浩特市'
      },
      {
        label: '中国工商银行股份有限公司呼和浩特市盛乐园区支行',
        value: '102191000829|1501|内蒙古自治区|呼和浩特市'
      },
      {
        label: '中国工商银行股份有限公司呼和浩特市五塔寺东街支行',
        value: '102191000837|1501|内蒙古自治区|呼和浩特市'
      },
      {
        label: '中国工商银行股份有限公司呼和浩特市人和小区支行',
        value: '102191000845|1501|内蒙古自治区|呼和浩特市'
      },
      {
        label: '中国工商银行股份有限公司呼和浩特如意河支行',
        value: '102191000853|1501|内蒙古自治区|呼和浩特市'
      },
      {
        label: '中国工商银行股份有限公司呼和浩特如意西街支行',
        value: '102191000896|1501|内蒙古自治区|呼和浩特市'
      },
      {
        label: '中国工商银行股份有限公司呼和浩特市桥华世纪支行',
        value: '102191002025|1501|内蒙古自治区|呼和浩特市'
      },
      {
        label: '中国工商银行呼和浩特市锡林北路支行',
        value: '102191002113|1501|内蒙古自治区|呼和浩特市'
      },
      {
        label: '中国工商银行股份有限公司呼和浩特市铁路支行',
        value: '102191002156|1501|内蒙古自治区|呼和浩特市'
      },
      {
        label: '中国工商银行股份有限公司呼和浩特市金桥支行',
        value: '102191002700|1501|内蒙古自治区|呼和浩特市'
      },
      {
        label: '中国工商银行股份有限公司呼和浩特富贵小区支行',
        value: '102191005007|1501|内蒙古自治区|呼和浩特市'
      },
      {
        label: '中国工商银行股份有限公司呼和浩特上东支行',
        value: '102191010197|1501|内蒙古自治区|呼和浩特市'
      },
      {
        label: '中国工商银行股份有限公司呼和浩特北环支行',
        value: '102191010210|1501|内蒙古自治区|呼和浩特市'
      },
      {
        label: '中国工商银行股份有限公司呼和浩特光明西路支行',
        value: '102191010228|1501|内蒙古自治区|呼和浩特市'
      },
      {
        label: '中国工商银行股份有限公司呼和浩特市海拉尔东路第二支行',
        value: '102191010252|1501|内蒙古自治区|呼和浩特市'
      },
      {
        label: '中国工商银行股份有限公司呼和浩特市体育场支行',
        value: '102191010269|1501|内蒙古自治区|呼和浩特市'
      },
      {
        label: '中国工商银行股份有限公司呼和浩特金丰路支行',
        value: '102191010515|1501|内蒙古自治区|呼和浩特市'
      },
      {
        label: '中国工商银行股份有限公司呼和浩特县府街支行',
        value: '102191010523|1501|内蒙古自治区|呼和浩特市'
      },
      {
        label: '中国工商银行股份有限公司赤城支行',
        value: '102140212397|1307|河北省|张家口市'
      },
      {
        label: '中国工商银行股份有限公司张家口崇礼支行',
        value: '102140312088|1307|河北省|张家口市'
      },
      {
        label: '中国工商银行股份有限公司东港聚财支行',
        value: '102226303233|2106|辽宁省|丹东市'
      },
      {
        label: '中国工商银行丹东宽甸支行',
        value: '102226400102|2106|辽宁省|丹东市'
      },
      {
        label: '中国工商银行股份有限公司宽甸太平哨发电厂支行',
        value: '102226400653|2106|辽宁省|丹东市'
      },
      {
        label: '中国工商银行股份有限公司岫岩支行',
        value: '102226500015|2106|辽宁省|丹东市'
      },
      {
        label: '中国工商银行锦州市分行业务处理中心',
        value: '102227000012|2107|辽宁省|锦州市'
      },
      {
        label: '中国工商银行锦州市古塔支行',
        value: '102227000029|2107|辽宁省|锦州市'
      },
      {
        label: '中国工商银行锦州中央大街支行',
        value: '102227000037|2107|辽宁省|锦州市'
      },
      {
        label: '中国工商银行股份有限公司锦州康宁支行',
        value: '102227000053|2107|辽宁省|锦州市'
      },
      {
        label: '中国工商银行锦州市凌河支行',
        value: '102227000131|2107|辽宁省|锦州市'
      },
      {
        label: '中国工商银行锦州铁路支行',
        value: '102227000140|2107|辽宁省|锦州市'
      },
      {
        label: '中国工商银行股份有限公司锦州菊元支行',
        value: '102227000158|2107|辽宁省|锦州市'
      },
      {
        label: '中国工商银行股份有限公司锦州市府路支行',
        value: '102227000174|2107|辽宁省|锦州市'
      },
      {
        label: '中国工商银行股份有限公司锦州锦纺支行',
        value: '102227000220|2107|辽宁省|锦州市'
      },
      {
        label: '中国工商银行股份有限公司锦州云飞路支行',
        value: '102227000246|2107|辽宁省|锦州市'
      },
      {
        label: '中国工商银行锦州市城内支行',
        value: '102227000254|2107|辽宁省|锦州市'
      },
      {
        label: '中国工商银行锦州重庆路支行',
        value: '102227000262|2107|辽宁省|锦州市'
      },
      {
        label: '中国工商银行朔州怀仁县支行',
        value: '102164104784|1402|山西省|大同市'
      },
      {
        label: '中国工商银行股份有限公司怀仁支行开发区分理处',
        value: '102164123620|1402|山西省|大同市'
      },
      {
        label: '工行平定县支行',
        value: '102165100599|1403|山西省|阳泉市'
      },
      {
        label: '中国工商银行股份有限公司阳泉分行贵石沟分理处',
        value: '102165100695|1403|山西省|阳泉市'
      },
      {
        label: '工行盂县支行',
        value: '102165200569|1403|山西省|阳泉市'
      },
      {
        label: '工行盂县新建路支行',
        value: '102165223211|1403|山西省|阳泉市'
      },
      {
        label: '中国工商银行股份有限公司长治支行',
        value: '102166101096|1404|山西省|长治市'
      },
      {
        label: '中国工商银行股份有限公司潞城支行',
        value: '102166201136|1404|山西省|长治市'
      },
      {
        label: '中国工商银行股份有限公司长治山化支行',
        value: '102166201169|1404|山西省|长治市'
      },
      {
        label: '中国工商银行股份有限公司勃利新建储蓄所',
        value: '102274100219|2309|黑龙江省|七台河市'
      },
      {
        label: '中国工商银行股份有限公司凌海金城支行',
        value: '102227200022|2107|辽宁省|锦州市'
      },
      {
        label: '中国工商银行股份有限公司凌海城建楼支行',
        value: '102227200063|2107|辽宁省|锦州市'
      },
      {
        label: '中国工商银行股份有限公司凌海九华山支行',
        value: '102227200098|2107|辽宁省|锦州市'
      },
      {
        label: '中国工商银行股份有限公司北镇支行',
        value: '102227300015|2107|辽宁省|锦州市'
      },
      {
        label: '中国工商银行盘锦市天河支行',
        value: '102232000380|2111|辽宁省|盘锦市'
      },
      {
        label: '中国工商银行盘锦湖滨支行',
        value: '102232000402|2111|辽宁省|盘锦市'
      },
      {
        label: '中国工商银行盘锦市兴隆台支行',
        value: '102232000419|2111|辽宁省|盘锦市'
      },
      {
        label: '中国工商银行盘锦润达支行',
        value: '102232000435|2111|辽宁省|盘锦市'
      },
      {
        label: '中国工商银行股份有限公司盘锦公园街支行',
        value: '102232000451|2111|辽宁省|盘锦市'
      },
      {
        label: '中国工商银行盘锦市于楼支行',
        value: '102232000486|2111|辽宁省|盘锦市'
      },
      {
        label: '中国工商银行股份有限公司盘山新城支行',
        value: '102232002465|2111|辽宁省|盘锦市'
      },
      {
        label: '中国工商银行大洼县支行',
        value: '102232100016|2111|辽宁省|盘锦市'
      },
      {
        label: '中国工商银行盘锦红村支行',
        value: '102232100032|2111|辽宁省|盘锦市'
      },
      {
        label: '中国工商银行股份有银公司盘锦汇美支行',
        value: '102232100161|2111|辽宁省|盘锦市'
      },
      {
        label: '中国工商银行铁岭新兴支行',
        value: '102233000121|2112|辽宁省|铁岭市'
      },
      {
        label: '中国工商银行铁岭市银州支行',
        value: '102233000201|2112|辽宁省|铁岭市'
      },
      {
        label: '中国工商银行铁岭前进支行',
        value: '102233000228|2112|辽宁省|铁岭市'
      },
      {
        label: '中国工商银行铁岭龙兴支行',
        value: '102233000244|2112|辽宁省|铁岭市'
      },
      {
        label: '中国工商银行铁岭市分行业务处理中心',
        value: '102233000252|2112|辽宁省|铁岭市'
      },
      {
        label: '中国工商银行铁岭新区支行',
        value: '102233000269|2112|辽宁省|铁岭市'
      },
      {
        label: '中国工商银行铁岭市分行站前支行',
        value: '102233000277|2112|辽宁省|铁岭市'
      },
      {
        label: '中国工商银行铁岭开发区支行',
        value: '102233000285|2112|辽宁省|铁岭市'
      },
      {
        label: '中国工商银行铁岭市清河支行',
        value: '102233002203|2112|辽宁省|铁岭市'
      },
      {
        label: '中国工商银行铁岭市清河支行西丰分理处',
        value: '102233205208|2112|辽宁省|铁岭市'
      },
      {
        label: '中国工商银行铁岭市昌图支行',
        value: '102233304209|2112|辽宁省|铁岭市'
      },
      {
        label: '中国工商银行铁岭市调兵山支行',
        value: '102233601207|2112|辽宁省|铁岭市'
      },
      {
        label: '中国工商银行铁岭市调兵山支行大青分理处',
        value: '102233601215|2112|辽宁省|铁岭市'
      },
      {
        label: '中国工商银行昌图政府路支行',
        value: '102233601240|2112|辽宁省|铁岭市'
      },
      {
        label: '中国工商银行调兵山新区支行',
        value: '102233601776|2112|辽宁省|铁岭市'
      },
      {
        label: '中国工商银行铁岭市开原支行',
        value: '102233703203|2112|辽宁省|铁岭市'
      },
      {
        label: '中国工商银行朝阳燕都支行',
        value: '102234000027|2113|辽宁省|朝阳市'
      },
      {
        label: '中国工商银行朝阳龙城支行',
        value: '102234000035|2113|辽宁省|朝阳市'
      },
      {
        label: '中国工商银行朝阳春风支行',
        value: '102234000043|2113|辽宁省|朝阳市'
      },
      {
        label: '中国工商银行朝阳新华支行',
        value: '102234000051|2113|辽宁省|朝阳市'
      },
      {
        label: '中国工商银行朝阳双塔支行',
        value: '102234000060|2113|辽宁省|朝阳市'
      },
      {
        label: '中国工商银行朝阳通卡支行',
        value: '102234000078|2113|辽宁省|朝阳市'
      },
      {
        label: '中国工商银行朝阳电厂分理处',
        value: '102234000086|2113|辽宁省|朝阳市'
      },
      {
        label: '中国工商银行股份有限公司衢州航埠支行',
        value: '102341026035|3308|浙江省|衢州市'
      },
      {
        label: '中国工商银行股份有限公司衢州衢江支行',
        value: '102341026060|3308|浙江省|衢州市'
      },
      {
        label: '中国工商银行股份有限公司衢州新桥支行',
        value: '102341026078|3308|浙江省|衢州市'
      },
      {
        label: '中国工商银行股份有限公司郑州上街许昌路支行',
        value: '102491052217|4101|河南省|郑州市'
      },
      {
        label: '中国工商银行股份有限公司郑州烟厂街支行',
        value: '102491062025|4101|河南省|郑州市'
      },
      {
        label: '中国工商银行股份有限公司郑州黄委会支行',
        value: '102491062068|4101|河南省|郑州市'
      },
      {
        label: '中国工商银行股份有限公司郑州庆丰街支行',
        value: '102491062092|4101|河南省|郑州市'
      },
      {
        label: '中国工商银行股份有限公司郑州金海支行',
        value: '102491062156|4101|河南省|郑州市'
      },
      {
        label: '中国工商银行股份有限公司郑州信息学院路支行',
        value: '102491062172|4101|河南省|郑州市'
      },
      {
        label: '中国工商银行股份有限公司郑州京广南路支行',
        value: '102491072012|4101|河南省|郑州市'
      },
      {
        label: '中国工商银行股份有限公司郑州青年路支行',
        value: '102491072029|4101|河南省|郑州市'
      },
      {
        label: '中国工商银行股份有限公司郑州互助路支行',
        value: '102491072088|4101|河南省|郑州市'
      },
      {
        label: '中国工商银行股份有限公司郑州嵩山南路支行',
        value: '102491072096|4101|河南省|郑州市'
      },
      {
        label: '中国工商银行股份有限公司郑州洛河支行',
        value: '102491072158|4101|河南省|郑州市'
      },
      {
        label: '中国工商银行济源天坛路支行',
        value: '102491072551|4101|河南省|郑州市'
      },
      {
        label: '中国工商银行股份有限公司郑州东韩支行',
        value: '102491082067|4101|河南省|郑州市'
      },
      {
        label: '中国工商银行股份有限公司郑州棉纺东路支行',
        value: '102491082083|4101|河南省|郑州市'
      },
      {
        label: '中国工商银行股份有限公司郑州桃源洋房支行',
        value: '102491082091|4101|河南省|郑州市'
      },
      {
        label: '中国工商银行股份有限公司郑州紫东支行',
        value: '102491092089|4101|河南省|郑州市'
      },
      {
        label: '中国工商银行股份有限公司郑州祥和支行',
        value: '102491092152|4101|河南省|郑州市'
      },
      {
        label: '中国工商银行开封分行南关支行',
        value: '102492002019|4102|河南省|开封市'
      },
      {
        label: '中国工商银行股份有限公司开封城东支行',
        value: '102492002027|4102|河南省|开封市'
      },
      {
        label: '中国工商银行开封分行鼓楼街支行',
        value: '102492002035|4102|河南省|开封市'
      },
      {
        label: '中国工商银行股份有限公司开封金明池支行',
        value: '102492002043|4102|河南省|开封市'
      },
      {
        label: '中国工商银行开封分行东大街支行',
        value: '102492002051|4102|河南省|开封市'
      },
      {
        label: '中国工商银行股份有限公司开封分行',
        value: '102492002060|4102|河南省|开封市'
      },
      {
        label: '中国工商银行股份有限公司开封自贸试验区支行',
        value: '102492002086|4102|河南省|开封市'
      },
      {
        label: '中国工商银行股份有限公司开封豪德支行',
        value: '102492002094|4102|河南省|开封市'
      },
      {
        label: '中国工商银行开封分行会计业务处理中心',
        value: '102492002682|4102|河南省|开封市'
      },
      {
        label: '中国工商银行股份有限公司开封前锋支行',
        value: '102492012016|4102|河南省|开封市'
      },
      {
        label: '中国工商银行股份有限公司开封汴京路支行',
        value: '102492012024|4102|河南省|开封市'
      },
      {
        label: '中国工商银行股份有限公司开封香榭里支行',
        value: '102492012032|4102|河南省|开封市'
      },
      {
        label: '中国工商银行股份有限公司开封万宝支行',
        value: '102492012049|4102|河南省|开封市'
      },
      {
        label: '中国工商银行磐安县支行',
        value: '102338508009|3307|浙江省|金华市'
      },
      {
        label: '中国工商银行股份有限公司磐安尖山支行',
        value: '102338508017|3307|浙江省|金华市'
      },
      {
        label: '中国工商银行兰溪支行',
        value: '102338605001|3307|浙江省|金华市'
      },
      {
        label: '中国工商银行股份有限公司兰溪市官桥分理处',
        value: '102338605010|3307|浙江省|金华市'
      },
      {
        label: '中国工商银行股份有限公司鹤壁衡山路支行',
        value: '102497072099|4106|河南省|鹤壁市'
      },
      {
        label: '中国工商银行鹤壁分行浚县支行',
        value: '102497102070|4106|河南省|鹤壁市'
      },
      {
        label: '中国工商银行鹤壁分行淇县支行',
        value: '102497202089|4106|河南省|鹤壁市'
      },
      {
        label: '中国工商银行股份有限公司新乡分行卫滨支行',
        value: '102498002013|4107|河南省|新乡市'
      },
      {
        label: '中国工商银行股份有限公司杭州钱江开发区支行',
        value: '102331025394|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行承德业务处理中心',
        value: '102141013013|1308|河北省|承德市'
      },
      {
        label: '中国工商银行承德热河支行',
        value: '102141013021|1308|河北省|承德市'
      },
      {
        label: '中国工商银行承德太平桥支行',
        value: '102141013030|1308|河北省|承德市'
      },
      {
        label: '中国工商银行承德山庄支行',
        value: '102141013048|1308|河北省|承德市'
      },
      {
        label: '中国工商银行承德双滦支行',
        value: '102141013056|1308|河北省|承德市'
      },
      {
        label: '中国工商银行石家庄新苑支行',
        value: '102121000498|1301|河北省|石家庄市'
      },
      {
        label: '中国工商银行石家庄红旗支行',
        value: '102121000519|1301|河北省|石家庄市'
      },
      {
        label: '中国工商银行石家庄天河支行',
        value: '102121000527|1301|河北省|石家庄市'
      },
      {
        label: '中国工商银行石家庄泰华支行',
        value: '102121000535|1301|河北省|石家庄市'
      },
      {
        label: '中国工商银行石家庄建大支行',
        value: '102121000828|1301|河北省|石家庄市'
      },
      {
        label: '中国工商银行石家庄西郊支行',
        value: '102121001040|1301|河北省|石家庄市'
      },
      {
        label: '中国工商银行股份有限公司南通朝晖支行',
        value: '102306080331|3206|江苏省|南通市'
      },
      {
        label: '中国工商银行股份有限公司南通任港路支行',
        value: '102306080366|3206|江苏省|南通市'
      },
      {
        label: '中国工商银行南通学田支行',
        value: '102306080403|3206|江苏省|南通市'
      },
      {
        label: '中国工商银行股份有限公司南通文华支行',
        value: '102306080411|3206|江苏省|南通市'
      },
      {
        label: '中国工商银行股份有限公司南通方天支行',
        value: '102306080719|3206|江苏省|南通市'
      },
      {
        label: '中国工商银行南通分行业务处理中心',
        value: '102306082007|3206|江苏省|南通市'
      },
      {
        label: '中国工商银行南通市青年路支行',
        value: '102306082015|3206|江苏省|南通市'
      },
      {
        label: '中国工商银行南通通明支行',
        value: '102306082031|3206|江苏省|南通市'
      },
      {
        label: '中国工商银行南通解放路支行',
        value: '102306082074|3206|江苏省|南通市'
      },
      {
        label: '中国工商银行南通行政中心分理处',
        value: '102306082082|3206|江苏省|南通市'
      },
      {
        label: '中国工商银行股份有限公司南通新城支行',
        value: '102306082099|3206|江苏省|南通市'
      },
      {
        label: '中国工商银行股份有限公司南通节制闸支行',
        value: '102306082111|3206|江苏省|南通市'
      },
      {
        label: '中国工商银行南通人民路支行',
        value: '102306082120|3206|江苏省|南通市'
      },
      {
        label: '中国工商银行南通少先路支行',
        value: '102306082138|3206|江苏省|南通市'
      },
      {
        label: '中国工商银行南通友谊桥支行',
        value: '102306082146|3206|江苏省|南通市'
      },
      {
        label: '中国工商银行南通新桥支行',
        value: '102306082162|3206|江苏省|南通市'
      },
      {
        label: '中国工商银行股份有限公司南通城山路支行',
        value: '102306082179|3206|江苏省|南通市'
      },
      {
        label: '中国工商银行南通人民东路分理处',
        value: '102306082187|3206|江苏省|南通市'
      },
      {
        label: '中国工商银行股份有限公司芮城风陵渡支行',
        value: '102181303833|1408|山西省|运城市'
      },
      {
        label: '中国工商银行股份有限公司临猗支行',
        value: '102181403859|1408|山西省|运城市'
      },
      {
        label: '中国工商银行股份有限公司万荣支行',
        value: '102181503918|1408|山西省|运城市'
      },
      {
        label: '中国工商银行股份有限公司新绛支行',
        value: '102181603519|1408|山西省|运城市'
      },
      {
        label: '中国工商银行股份有限公司稷山支行',
        value: '102181703763|1408|山西省|运城市'
      },
      {
        label: '中国工商银行股份有限公司河津支行',
        value: '102181803951|1408|山西省|运城市'
      },
      {
        label: '中国工商银行股份有限公司河津禹门支行',
        value: '102181803960|1408|山西省|运城市'
      },
      {
        label: '中国工商银行股份有限公司河津龙门大道支行',
        value: '102181803978|1408|山西省|运城市'
      },
      {
        label: '中国工商银行股份有限公司河津万春支行',
        value: '102181829268|1408|山西省|运城市'
      },
      {
        label: '中国工商银行股份有限公司闻喜支行',
        value: '102181903541|1408|山西省|运城市'
      },
      {
        label: '中国工商银行股份有限公司闻喜东镇支行',
        value: '102181903584|1408|山西省|运城市'
      },
      {
        label: '中国工商银行股份有限公司夏县支行',
        value: '102182103790|1408|山西省|运城市'
      },
      {
        label: '中国工商银行股份有限公司绛县支行',
        value: '102182203622|1408|山西省|运城市'
      },
      {
        label: '中国工商银行股份有限公司绛县里册支行',
        value: '102182203680|1408|山西省|运城市'
      },
      {
        label: '中国工商银行股份有限公司平陆支行',
        value: '102182329019|1408|山西省|运城市'
      },
      {
        label: '中国工商银行股份有限公司垣曲支行',
        value: '102182403723|1408|山西省|运城市'
      },
      {
        label: '中国工商银行股份有限公司垣曲中条支行',
        value: '102182403731|1408|山西省|运城市'
      },
      {
        label: '中国工商银行股份有限公司垣曲新城支行',
        value: '102182429381|1408|山西省|运城市'
      },
      {
        label: '中国工商银行股份有限公司呼和浩特市满都拉支行',
        value: '102191000038|1501|内蒙古自治区|呼和浩特市'
      },
      {
        label: '中国工商银行香河支行',
        value: '102146416362|1310|河北省|廊坊市'
      },
      {
        label: '中国工商银行香河支行香宁分理处',
        value: '102146416395|1310|河北省|廊坊市'
      },
      {
        label: '中国工商银行香河支行向阳分理处',
        value: '102146416418|1310|河北省|廊坊市'
      },
      {
        label: '中国工商银行大城支行',
        value: '102146515775|1310|河北省|廊坊市'
      },
      {
        label: '中国工商银行文安支行',
        value: '102146615710|1310|河北省|廊坊市'
      },
      {
        label: '中国工商银行霸州支行',
        value: '102146715643|1310|河北省|廊坊市'
      },
      {
        label: '中国工商银行廊坊胜芳支行',
        value: '102146715864|1310|河北省|廊坊市'
      },
      {
        label: '中国工商银行股份有限公司廊坊胜芳前进支行',
        value: '102146715910|1310|河北省|廊坊市'
      },
      {
        label: '中国工商银行股份有限公司大厂夏垫支行',
        value: '102146816359|1310|河北省|廊坊市'
      },
      {
        label: '中国工商银行衡水业务处理中心',
        value: '102148016514|1311|河北省|衡水市'
      },
      {
        label: '中国工商银行衡水新华支行',
        value: '102148016539|1311|河北省|衡水市'
      },
      {
        label: '中国工商银行股份有限公司衡水永兴支行',
        value: '102148016563|1311|河北省|衡水市'
      },
      {
        label: '中国工商银行股份有限公司衡水友谊支行',
        value: '102148016571|1311|河北省|衡水市'
      },
      {
        label: '中国工商银行衡水百货大楼支行',
        value: '102148016602|1311|河北省|衡水市'
      },
      {
        label: '中国工商银行苏州市新区支行',
        value: '102305002115|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行苏州星湖支行',
        value: '102305002199|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行苏州市吴中支行',
        value: '102305002625|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行辽宁省海城市支行',
        value: '102223209203|2103|辽宁省|鞍山市'
      },
      {
        label: '中国工商银行股份有限公司诸暨江东支行',
        value: '102337502491|3306|浙江省|绍兴市'
      },
      {
        label: '中国工商银行股份有限公司勃利支行',
        value: '102274102101|2309|黑龙江省|七台河市'
      },
      {
        label: '中国工商银行股份有限公司宁安繁华支行',
        value: '102275100484|2310|黑龙江省|牡丹江市'
      },
      {
        label: '中国工商银行股份有限公司晋城分行',
        value: '102168002496|1405|山西省|晋城市'
      },
      {
        label: '工行晋城市牡丹支行',
        value: '102168002540|1405|山西省|晋城市'
      },
      {
        label: '工行晋城市凤翔支行',
        value: '102168002566|1405|山西省|晋城市'
      },
      {
        label: '工行晋城市巴公支行',
        value: '102168002582|1405|山西省|晋城市'
      },
      {
        label: '中国工商银行股份有限公司晋城景西路支行',
        value: '102168002599|1405|山西省|晋城市'
      },
      {
        label: '工行晋城市矿区支行',
        value: '102168002603|1405|山西省|晋城市'
      },
      {
        label: '工行晋城凤西分理处',
        value: '102168002662|1405|山西省|晋城市'
      },
      {
        label: '工行晋城市泽州支行',
        value: '102168002943|1405|山西省|晋城市'
      },
      {
        label: '工行晋城市开发区支行',
        value: '102168025223|1405|山西省|晋城市'
      },
      {
        label: '工行沁水县支行加丰支行',
        value: '102168102251|1405|山西省|晋城市'
      },
      {
        label: '工行沁水县支行',
        value: '102168102737|1405|山西省|晋城市'
      },
      {
        label: '工行阳城县支行',
        value: '102168202628|1405|山西省|晋城市'
      },
      {
        label: '工行高平市支行',
        value: '102168302500|1405|山西省|晋城市'
      },
      {
        label: '中国工商银行股份有限公司陵川支行',
        value: '102168403554|1405|山西省|晋城市'
      },
      {
        label: '中国工商银行朔州市振华支行',
        value: '102169004603|1406|山西省|朔州市'
      },
      {
        label: '中国工商银行朔州市城建支行',
        value: '102169004611|1406|山西省|朔州市'
      },
      {
        label: '中国工商银行朔州市平鲁区支行',
        value: '102169004620|1406|山西省|朔州市'
      },
      {
        label: '中国工商银行朔州市新城支行',
        value: '102169004638|1406|山西省|朔州市'
      },
      {
        label: '中国工商银行股份有限公司朔州分行',
        value: '102169004646|1406|山西省|朔州市'
      },
      {
        label: '中国工商银行朔州市朔城区支行',
        value: '102169004759|1406|山西省|朔州市'
      },
      {
        label: '中国工商银行朔州市神头电厂支行',
        value: '102169004767|1406|山西省|朔州市'
      },
      {
        label: '中国工商银行朔州市牡丹支行',
        value: '102169004978|1406|山西省|朔州市'
      },
      {
        label: '中国工商银行股份有限公司朔州铁路支行',
        value: '102169023552|1406|山西省|朔州市'
      },
      {
        label: '中国工商银行股份有限公司朔州平朔支行',
        value: '102169023577|1406|山西省|朔州市'
      },
      {
        label: '中国工商银行朔州山阴县支行',
        value: '102169104592|1406|山西省|朔州市'
      },
      {
        label: '中国工商银行股份有限公司忻州分行',
        value: '102171004120|1409|山西省|忻州市'
      },
      {
        label: '中国工商银行股份有限公司忻州牡丹支行',
        value: '102171004371|1409|山西省|忻州市'
      },
      {
        label: '中国工商银行股份有限公司忻州汾源支行',
        value: '102171004380|1409|山西省|忻州市'
      },
      {
        label: '中国工商银行股份有限公司忻州开发区支行',
        value: '102171004398|1409|山西省|忻州市'
      },
      {
        label: '中国工商银行股份有限公司忻州长征街支行',
        value: '102171004402|1409|山西省|忻州市'
      },
      {
        label: '中国工商银行股份有限公司扬州新能源支行',
        value: '102312002490|3210|江苏省|扬州市'
      },
      {
        label: '中国工商银行股份有限公司衢州浮石分理处',
        value: '102341026086|3308|浙江省|衢州市'
      },
      {
        label: '中国工商银行股份有限公司衢州南区支行',
        value: '102341027005|3308|浙江省|衢州市'
      },
      {
        label: '中国工商银行股份有限公司衢州学院支行',
        value: '102341027013|3308|浙江省|衢州市'
      },
      {
        label: '中国工商银行股份有限公司衢州松园支行',
        value: '102341027048|3308|浙江省|衢州市'
      },
      {
        label: '中国工商银行股份有限公司宁波云龙支行',
        value: '102332015283|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行宁波市镇海区支行',
        value: '102332016000|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行宁波胜利桥支行',
        value: '102332016018|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行宁波镇海西门支行',
        value: '102332016026|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行宁波镇海电厂支行',
        value: '102332016034|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行宁波镇海骆驼支行',
        value: '102332016042|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行合肥市东陈岗分理处',
        value: '102361000111|3401|安徽省|合肥市'
      },
      {
        label: '中国工商银行合肥市汇通支行',
        value: '102361000120|3401|安徽省|合肥市'
      },
      {
        label: '中国工商银行合肥市宿州路支行',
        value: '102361000138|3401|安徽省|合肥市'
      },
      {
        label: '中国工商银行股份有限公司合肥新汇支行',
        value: '102361000146|3401|安徽省|合肥市'
      },
      {
        label: '中国工商银行合肥市科大分理处',
        value: '102361000154|3401|安徽省|合肥市'
      },
      {
        label: '中国工商银行合肥市合钢分理处',
        value: '102361000162|3401|安徽省|合肥市'
      },
      {
        label: '中国工商银行长丰县支行',
        value: '102361000179|3401|安徽省|合肥市'
      },
      {
        label: '中国工商银行股份有限公司合肥包河支行',
        value: '102361000200|3401|安徽省|合肥市'
      },
      {
        label: '中国工商银行合肥市城建支行',
        value: '102361000218|3401|安徽省|合肥市'
      },
      {
        label: '中国工商银行合肥市牡丹支行',
        value: '102361000226|3401|安徽省|合肥市'
      },
      {
        label: '中国工商银行合肥市银河支行',
        value: '102361000234|3401|安徽省|合肥市'
      },
      {
        label: '中国工商银行合肥明珠支行',
        value: '102361000242|3401|安徽省|合肥市'
      },
      {
        label: '中国工商银行合肥市胜利路分理处',
        value: '102361001294|3401|安徽省|合肥市'
      },
      {
        label: '中国工商银行肥东支行',
        value: '102361001874|3401|安徽省|合肥市'
      },
      {
        label: '中国工商银行肥西支行',
        value: '102361001954|3401|安徽省|合肥市'
      },
      {
        label: '中国工商银行福安市支行',
        value: '102403483005|3509|福建省|宁德市'
      },
      {
        label: '中国工商银行福安赛岐支行',
        value: '102403483013|3509|福建省|宁德市'
      },
      {
        label: '中国工商银行股份有限公司福安阳头支行',
        value: '102403483030|3509|福建省|宁德市'
      },
      {
        label: '中国工商银行股份有限公司福安东凤支行',
        value: '102403483056|3509|福建省|宁德市'
      },
      {
        label: '中国工商银行股份有限公司福安甘棠支行',
        value: '102403483089|3509|福建省|宁德市'
      },
      {
        label: '中国工商银行古田县支行',
        value: '102403585004|3509|福建省|宁德市'
      },
      {
        label: '中国工商银行古田城东支行',
        value: '102403585045|3509|福建省|宁德市'
      },
      {
        label: '中国工商银行屏南县支行',
        value: '102403686005|3509|福建省|宁德市'
      },
      {
        label: '中国工商银行宁德寿宁支行',
        value: '102403787007|3509|福建省|宁德市'
      },
      {
        label: '中国工商银行周宁县支行',
        value: '102403888006|3509|福建省|宁德市'
      },
      {
        label: '中国工商银行柘荣县支行',
        value: '102403989000|3509|福建省|宁德市'
      },
      {
        label: '中国工商银行龙岩分行',
        value: '102405040000|3508|福建省|龙岩市'
      },
      {
        label: '中国工商银行龙岩分行信用卡部',
        value: '102405040018|3508|福建省|龙岩市'
      },
      {
        label: '中国工商银行龙岩岩城支行',
        value: '102405041002|3508|福建省|龙岩市'
      },
      {
        label: '中国工商银行龙岩东方支行',
        value: '102405041035|3508|福建省|龙岩市'
      },
      {
        label: '中国工商银行潍坊市东关支行',
        value: '102458000021|3707|山东省|潍坊市'
      },
      {
        label: '中国工商银行股份有限公司潍坊分行新华路第二分理处',
        value: '102458000056|3707|山东省|潍坊市'
      },
      {
        label: '中国工商银行股份有限公司潍坊分行文化路第二分理处',
        value: '102458000064|3707|山东省|潍坊市'
      },
      {
        label: '中国工商银行潍坊潍柴支行',
        value: '102458000072|3707|山东省|潍坊市'
      },
      {
        label: '中国工商银行潍坊市南关支行',
        value: '102458000128|3707|山东省|潍坊市'
      },
      {
        label: '中国工商银行潍坊和平路支行',
        value: '102458000136|3707|山东省|潍坊市'
      },
      {
        label: '中国工商银行股份有限公司潍坊分行北宫西街分理处',
        value: '102458000152|3707|山东省|潍坊市'
      },
      {
        label: '中国工商银行股份有限公司潍坊阳光大厦支行',
        value: '102458000177|3707|山东省|潍坊市'
      },
      {
        label: '中国工商银行股份有限公司潍坊分行大十字口分理处',
        value: '102458000193|3707|山东省|潍坊市'
      },
      {
        label: '中国工商银行潍坊市潍城区支行',
        value: '102458000224|3707|山东省|潍坊市'
      },
      {
        label: '中国工商银行股份有限公司潍坊豪德广场支行',
        value: '102458000249|3707|山东省|潍坊市'
      },
      {
        label: '中国工商银行潍坊北宫支行',
        value: '102458000257|3707|山东省|潍坊市'
      },
      {
        label: '中国工商银行股份有限公司潍坊分行东风影院分理处',
        value: '102458000265|3707|山东省|潍坊市'
      },
      {
        label: '中国工商银行股份有限公司潍坊分行上东国际分理处',
        value: '102458000273|3707|山东省|潍坊市'
      },
      {
        label: '中国工商银行股份有限公司潍坊福寿西街支行',
        value: '102458000281|3707|山东省|潍坊市'
      },
      {
        label: '中国工商银行潍坊市奎文区支行',
        value: '102458000312|3707|山东省|潍坊市'
      },
      {
        label: '中国工商银行潍坊新华路第一支行',
        value: '102458000329|3707|山东省|潍坊市'
      },
      {
        label: '中国工商银行潍坊河东支行',
        value: '102458000337|3707|山东省|潍坊市'
      },
      {
        label: '中国工商银行股份有限公司潍坊潍州路支行',
        value: '102458000345|3707|山东省|潍坊市'
      },
      {
        label: '中国工商银行股份有限公司潍坊北海花园支行',
        value: '102458000353|3707|山东省|潍坊市'
      },
      {
        label: '中国工商银行股份有限公司潍坊分行东郊分理处',
        value: '102458000361|3707|山东省|潍坊市'
      },
      {
        label: '中国工商银行股份有限公司潍坊金马国际支行',
        value: '102458000388|3707|山东省|潍坊市'
      },
      {
        label: '中国工商银行股份有限公司潍坊文化路支行',
        value: '102458000415|3707|山东省|潍坊市'
      },
      {
        label: '中国工商银行潍坊市开发区支行',
        value: '102458000423|3707|山东省|潍坊市'
      },
      {
        label: '中国工商银行股份有限公司唐山北新支行',
        value: '102124002642|1302|河北省|唐山市'
      },
      {
        label: '中国工商银行股份有限公司唐山兴盛支行',
        value: '102124002659|1302|河北省|唐山市'
      },
      {
        label: '中国工商银行股份有限公司唐山阳光支行',
        value: '102124002667|1302|河北省|唐山市'
      },
      {
        label: '中国工商银行股份有限公司富阳高桥支行',
        value: '102331025513|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行宁波市分行业务处理中心',
        value: '102332001003|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行宁波市分行营业部',
        value: '102332002006|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行宁波市第二营业部',
        value: '102332003009|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行宁波市东门支行',
        value: '102332010003|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行宁波开明支行',
        value: '102332010011|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行宁波亚城支行',
        value: '102332010020|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行宁波朝阳支行',
        value: '102332010038|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行股份有限公司宁波天水支行',
        value: '102332010054|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行股份有限公司宁波横街支行',
        value: '102332010079|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行宁波世纪苑支行',
        value: '102332010087|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行宁波南雅支行',
        value: '102332010095|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行宁波白云支行',
        value: '102332010100|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行股份有限公司宁波东柳支行',
        value: '102332010118|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行宁波镇明支行',
        value: '102332010126|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行股份有限公司宁波南苑支行',
        value: '102332010134|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行股份有限公司宁波西城支行',
        value: '102332010159|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行宁波市鼓楼支行',
        value: '102332011006|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行股份有限公司宁波东湖支行',
        value: '102332011014|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行宁波西河支行',
        value: '102332011047|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行股份有限公司宁波青林湾支行',
        value: '102332011063|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行宁波万安支行',
        value: '102332011071|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行股份有限公司宁波马园支行',
        value: '102332011080|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行宁波新芝支行',
        value: '102332011102|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行股份有限公司宁波城西支行',
        value: '102332011119|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行宁波南大支行',
        value: '102332011135|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行股份有限公司宁波启运支行',
        value: '102332011160|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行股份有限公司宁波高桥支行',
        value: '102332011178|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行宁波市江东支行',
        value: '102332012009|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行股份有限公司宁波新城支行',
        value: '102332012017|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行宁波彩虹支行',
        value: '102332012025|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行宁波凤凰支行',
        value: '102332012033|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行宁波兴宁支行',
        value: '102332012041|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行股份有限公司宁波天园支行',
        value: '102332012050|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行股份有限公司宁波华光城支行',
        value: '102332012084|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行股份有限公司宁波樱花支行',
        value: '102332012105|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行股份有限公司宁波新河支行',
        value: '102332012113|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行宁波常青藤支行',
        value: '102332012121|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行宁波明楼支行',
        value: '102332012130|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行股份有限公司宁波东海支行',
        value: '102332012148|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行股份有限公司长春洋浦大街支行',
        value: '102241000332|2201|吉林省|长春市'
      },
      {
        label: '中国工商银行长春南岭支行',
        value: '102241000349|2201|吉林省|长春市'
      },
      {
        label: '中国工商银行股份有限公司长春环城支行',
        value: '102241000365|2201|吉林省|长春市'
      },
      {
        label: '中国工商银行股份有限公司长春宽平大路支行',
        value: '102241000373|2201|吉林省|长春市'
      },
      {
        label: '中国工商银行股份有限公司长春景阳大路支行',
        value: '102241000381|2201|吉林省|长春市'
      },
      {
        label: '中国工商银行股份有限公司长春平治街支行',
        value: '102241000390|2201|吉林省|长春市'
      },
      {
        label: '中国工商银行股份有限公司长春新区支行',
        value: '102241000404|2201|吉林省|长春市'
      },
      {
        label: '中国工商银行股份有限公司长春中吉支行',
        value: '102241000412|2201|吉林省|长春市'
      },
      {
        label: '中国工商银行股份有限公司常州丽华新村支行',
        value: '102304003851|3204|江苏省|常州市'
      },
      {
        label: '中国工商银行股份有限公司常州兆丰支行',
        value: '102304003909|3204|江苏省|常州市'
      },
      {
        label: '中国工商银行股份有限公司常州青山湾分理处',
        value: '102304003917|3204|江苏省|常州市'
      },
      {
        label: '中国工商银行股份有限公司常州科教城科技支行',
        value: '102304003984|3204|江苏省|常州市'
      },
      {
        label: '中国工商银行股份有限公司常州东横街支行',
        value: '102304004016|3204|江苏省|常州市'
      },
      {
        label: '中国工商银行股份有限公司常州长贸中心支行',
        value: '102304004024|3204|江苏省|常州市'
      },
      {
        label: '中国工商银行股份有限公司常州河海支行',
        value: '102304004032|3204|江苏省|常州市'
      },
      {
        label: '中国工商银行内蒙古自治区阿拉善盟分行会计业务处理中心',
        value: '102208009502|1529|内蒙古自治区|阿拉善盟'
      },
      {
        label: '中国工商银行阿拉善盟分行城西支行',
        value: '102208009543|1529|内蒙古自治区|阿拉善盟'
      },
      {
        label: '中国工商银行阿拉善盟分行巴彦浩特新城支行',
        value: '102208009551|1529|内蒙古自治区|阿拉善盟'
      },
      {
        label: '中国工商银行股份有限公司巴彦浩特新浩特支行',
        value: '102208009560|1529|内蒙古自治区|阿拉善盟'
      },
      {
        label: '中国工商银行阿拉善盟分行乌斯太开发区支行',
        value: '102208009578|1529|内蒙古自治区|阿拉善盟'
      },
      {
        label: '中国工商银行股份有限公司巴彦浩特东城财富广场支行',
        value: '102208013020|1529|内蒙古自治区|阿拉善盟'
      },
      {
        label: '工商银行阿拉善盟分行阿拉善右旗支行',
        value: '102208204796|1529|内蒙古自治区|阿拉善盟'
      },
      {
        label: '中国工商银行阿拉善盟分行额济纳支行',
        value: '102208304095|1529|内蒙古自治区|阿拉善盟'
      },
      {
        label: '中国工商银行沈阳市市府大路支行',
        value: '102221000010|2101|辽宁省|沈阳市'
      },
      {
        label: '中国工商银行诸暨南门支行',
        value: '102337502506|3306|浙江省|绍兴市'
      },
      {
        label: '中国工商银行股份有限公司诸暨城北支行',
        value: '102337502514|3306|浙江省|绍兴市'
      },
      {
        label: '中国工商银行诸暨东湖支行',
        value: '102337502522|3306|浙江省|绍兴市'
      },
      {
        label: '中国工商银行诸暨店口支行',
        value: '102337502539|3306|浙江省|绍兴市'
      },
      {
        label: '中国工商银行股份有限公司诸暨大唐支行',
        value: '102337502547|3306|浙江省|绍兴市'
      },
      {
        label: '中国工商银行诸暨山下湖支行',
        value: '102337502555|3306|浙江省|绍兴市'
      },
      {
        label: '中国工商银行股份有限公司诸暨璜山支行',
        value: '102337502563|3306|浙江省|绍兴市'
      },
      {
        label: '中国工商银行闽侯青口支行',
        value: '102391051338|3501|福建省|福州市'
      },
      {
        label: '中国工商银行罗源支行东门储蓄所',
        value: '102391052003|3501|福建省|福州市'
      },
      {
        label: '中国工商银行股份有限公司福州阿波罗支行',
        value: '102391052011|3501|福建省|福州市'
      },
      {
        label: '中国工商银行泗洪县支行营业部',
        value: '102308605009|3206|江苏省|南通市'
      },
      {
        label: '中国工商银行泗洪县猿洲支行',
        value: '102308605025|3206|江苏省|南通市'
      },
      {
        label: '中国工商银行股份有限公司泗洪双沟支行',
        value: '102308605041|3206|江苏省|南通市'
      },
      {
        label: '中国工商银行泗洪开发区支行',
        value: '102308605050|3206|江苏省|南通市'
      },
      {
        label: '中国工商银行洪泽县支行',
        value: '102308708013|3208|江苏省|淮安市'
      },
      {
        label: '中国工商银行洪泽人民路支行',
        value: '102308708030|3208|江苏省|淮安市'
      },
      {
        label: '中国工商银行洪泽县北京路分理处',
        value: '102308708056|3208|江苏省|淮安市'
      },
      {
        label: '中国工商银行盱眙县支行',
        value: '102308809010|3208|江苏省|淮安市'
      },
      {
        label: '中国工商银行盱眙县新华分理处',
        value: '102308809036|3208|江苏省|淮安市'
      },
      {
        label: '中国工商银行盱眙县城中分理处',
        value: '102308809526|3208|江苏省|淮安市'
      },
      {
        label: '中国工商银行金湖县支行',
        value: '102308900013|3208|江苏省|淮安市'
      },
      {
        label: '中国工商银行金湖闸东支行',
        value: '102308900021|3208|江苏省|淮安市'
      },
      {
        label: '中国工商银行金湖县石油二处分理处',
        value: '102308900056|3208|江苏省|淮安市'
      },
      {
        label: '中国工商银行宿迁市分行业务处理中心',
        value: '102309001003|3213|江苏省|宿迁市'
      },
      {
        label: '中国工商银行宿迁市营业部',
        value: '102309002006|3213|江苏省|宿迁市'
      },
      {
        label: '中国工商银行宿迁市八一路支行',
        value: '102309002014|3213|江苏省|宿迁市'
      },
      {
        label: '中国工商银行宿迁府苑支行',
        value: '102309002022|3213|江苏省|宿迁市'
      },
      {
        label: '中国工商银行股份有限公司宿迁万源支行',
        value: '102309002047|3213|江苏省|宿迁市'
      },
      {
        label: '中国工商银行股份有限公司宿迁城中支行',
        value: '102309003017|3213|江苏省|宿迁市'
      },
      {
        label: '中国工商银行股份有限公司宿迁新区支行',
        value: '102309003025|3213|江苏省|宿迁市'
      },
      {
        label: '中国工商银行股份有限公司宿迁开发区支行',
        value: '102309003033|3213|江苏省|宿迁市'
      },
      {
        label: '中国工商银行宿迁市宿豫支行',
        value: '102309003041|3213|江苏省|宿迁市'
      },
      {
        label: '中国工商银行宿迁市徐淮路支行',
        value: '102309003050|3213|江苏省|宿迁市'
      },
      {
        label: '中国工商银行股份有限公司宿迁宿城支行',
        value: '102309003105|3213|江苏省|宿迁市'
      },
      {
        label: '中国工商银行宿迁市洋河支行',
        value: '102309004028|3213|江苏省|宿迁市'
      },
      {
        label: '中国工商银行泗阳县支行营业部',
        value: '102309304002|3213|江苏省|宿迁市'
      },
      {
        label: '中国工商银行泗阳县城中支行',
        value: '102309304019|3213|江苏省|宿迁市'
      },
      {
        label: '中国工商银行股份有限公司泗阳人民路支行',
        value: '102309304035|3213|江苏省|宿迁市'
      },
      {
        label: '中国工商银行股份有限公司泗阳北京路支行',
        value: '102309304051|3213|江苏省|宿迁市'
      },
      {
        label: '中国工商银行盐城市分行营业部',
        value: '102311066018|3209|江苏省|盐城市'
      },
      {
        label: '中国工商银行盐城海悦支行',
        value: '102311066026|3209|江苏省|盐城市'
      },
      {
        label: '中国工商银行盐城盐渎支行',
        value: '102311066034|3209|江苏省|盐城市'
      },
      {
        label: '中国工商银行盐城儒学街支行',
        value: '102311066042|3209|江苏省|盐城市'
      },
      {
        label: '中国工商银行盐城南环支行',
        value: '102311066083|3209|江苏省|盐城市'
      },
      {
        label: '中国工商银行盐城创业园支行',
        value: '102311066122|3209|江苏省|盐城市'
      },
      {
        label: '中国工商银行盐城通榆中路支行',
        value: '102311066155|3209|江苏省|盐城市'
      },
      {
        label: '中国工商银行盐城盐马路支行',
        value: '102311066171|3209|江苏省|盐城市'
      },
      {
        label: '中国工商银行盐城盐都支行',
        value: '102311066180|3209|江苏省|盐城市'
      },
      {
        label: '中国工商银行盐城亭湖支行',
        value: '102311066219|3209|江苏省|盐城市'
      },
      {
        label: '中国工商银行股份有限公司郑州北环支行',
        value: '102491032133|4101|河南省|郑州市'
      },
      {
        label: '中国工商银行股份有限公司郑州富田支行',
        value: '102491032176|4101|河南省|郑州市'
      },
      {
        label: '中国工商银行股份有限公司诸暨次坞支行',
        value: '102337502571|3306|浙江省|绍兴市'
      },
      {
        label: '中国工商银行金华市分行业务处理中心',
        value: '102338001001|3307|浙江省|金华市'
      },
      {
        label: '中国工商银行金华市分行营业部',
        value: '102338001108|3307|浙江省|金华市'
      },
      {
        label: '中国工商银行股份有限公司金华婺州街支行',
        value: '102338001204|3307|浙江省|金华市'
      },
      {
        label: '中国工商银行股份有限公司金华铁岭头支行',
        value: '102338001307|3307|浙江省|金华市'
      },
      {
        label: '工商银行滨海坎中支行',
        value: '102311265039|3209|江苏省|盐城市'
      },
      {
        label: '中国工商银行滨海新城支行',
        value: '102311265047|3209|江苏省|盐城市'
      },
      {
        label: '工商银行阜宁县支行',
        value: '102311367013|3209|江苏省|盐城市'
      },
      {
        label: '工商银行阜宁阜西支行',
        value: '102311367021|3209|江苏省|盐城市'
      },
      {
        label: '中国工商银行股份有限公司太原大南门支行',
        value: '102161000130|1401|山西省|太原市'
      },
      {
        label: '中国工商银行股份有限公司太原建设路支行',
        value: '102161000148|1401|山西省|太原市'
      },
      {
        label: '中国工商银行股份有限公司扬州东花园支行',
        value: '102312002545|3210|江苏省|扬州市'
      },
      {
        label: '中国工商银行股份有限公司扬州扬子江支行',
        value: '102312002826|3210|江苏省|扬州市'
      },
      {
        label: '中国工商银行股份有限公司扬州曲江支行',
        value: '102312006006|3210|江苏省|扬州市'
      },
      {
        label: '中国工商银行股份有限公司扬州分行业务处理中心',
        value: '102312008001|3210|江苏省|扬州市'
      },
      {
        label: '中国工商银行股份有限公司扬州文昌支行',
        value: '102312020153|3210|江苏省|扬州市'
      },
      {
        label: '中国工商银行股份有限公司扬州栖月苑支行',
        value: '102312026023|3210|江苏省|扬州市'
      },
      {
        label: '中国工商银行股份有限公司扬州邗江支行',
        value: '102312026031|3210|江苏省|扬州市'
      },
      {
        label: '中国工商银行股份有限公司扬州邗江工业园支行',
        value: '102312026040|3210|江苏省|扬州市'
      },
      {
        label: '中国工商银行股份有限公司高邮牡丹支行',
        value: '102312106082|3210|江苏省|扬州市'
      },
      {
        label: '中国工商银行股份有限公司高邮开发支行',
        value: '102312106162|3210|江苏省|扬州市'
      },
      {
        label: '中国工商银行股份有限公司高邮海潮支行',
        value: '102312129119|3210|江苏省|扬州市'
      },
      {
        label: '中国工商银行股份有限公司高邮城南支行',
        value: '102312139116|3210|江苏省|扬州市'
      },
      {
        label: '中国工商银行股份有限公司高邮支行',
        value: '102312190117|3210|江苏省|扬州市'
      },
      {
        label: '中国工商银行股份有限公司高邮城中支行',
        value: '102312190125|3210|江苏省|扬州市'
      },
      {
        label: '中国工商银行股份有限公司衢州坊门街支行',
        value: '102341027056|3308|浙江省|衢州市'
      },
      {
        label: '中国工商银行股份有限公司衢州集聚区支行',
        value: '102341027064|3308|浙江省|衢州市'
      },
      {
        label: '中国工商银行股份有限公司衢州衢化支行',
        value: '102341028008|3308|浙江省|衢州市'
      },
      {
        label: '中国工商银行股份有限公司衢州滨江分理处',
        value: '102341028032|3308|浙江省|衢州市'
      },
      {
        label: '中国工商银行股份有限公司衢州生活区支行',
        value: '102341028049|3308|浙江省|衢州市'
      },
      {
        label: '中国工商银行股份有限公司衢州经济开发区支行',
        value: '102341028057|3308|浙江省|衢州市'
      },
      {
        label: '中国工商银行股份有限公司衢州廿里支行',
        value: '102341028065|3308|浙江省|衢州市'
      },
      {
        label: '中国工商银行股份有限公司衢州常山支行',
        value: '102341224004|3308|浙江省|衢州市'
      },
      {
        label: '中国工商银行股份有限公司常山县支行小东门分理处',
        value: '102341224029|3308|浙江省|衢州市'
      },
      {
        label: '中国工商银行股份有限公司衢州常山万家富支行',
        value: '102341224088|3308|浙江省|衢州市'
      },
      {
        label: '中国工商银行股份有限公司衢州开化支行城中分理处',
        value: '102341329001|3308|浙江省|衢州市'
      },
      {
        label: '中国工商银行股份有限公司衢州开化支行',
        value: '102341329010|3308|浙江省|衢州市'
      },
      {
        label: '中国工商银行股份有限公司衢州开化华埠支行',
        value: '102341329028|3308|浙江省|衢州市'
      },
      {
        label: '中国工商银行股份有限公司衢州龙游支行',
        value: '102341422003|3308|浙江省|衢州市'
      },
      {
        label: '中国工商银行股份有限公司龙游县支行交易城分理处',
        value: '102341422020|3308|浙江省|衢州市'
      },
      {
        label: '中国工商银行莆田涵江支行后街分理处',
        value: '102394013040|3503|福建省|莆田市'
      },
      {
        label: '中国工商银行莆田商业城支行',
        value: '102394013058|3503|福建省|莆田市'
      },
      {
        label: '中国工商银行莆田保尾支行',
        value: '102394013082|3503|福建省|莆田市'
      },
      {
        label: '中国工商银行莆田市秀屿支行',
        value: '102394014000|3503|福建省|莆田市'
      },
      {
        label: '中国工商银行股份有限公司福建莆田望山支行',
        value: '102394014026|3503|福建省|莆田市'
      },
      {
        label: '中国工商银行莆田市分行国际业务部',
        value: '102394016005|3503|福建省|莆田市'
      },
      {
        label: '中国工商银行莆田市仙游县支行',
        value: '102394215000|3503|福建省|莆田市'
      },
      {
        label: '中国工商银行莆田田岑底支行',
        value: '102394215026|3503|福建省|莆田市'
      },
      {
        label: '中国工商银行股份有限公司福建莆田仙游城内储蓄所',
        value: '102394215034|3503|福建省|莆田市'
      },
      {
        label: '中国工商银行莆田枫亭支行',
        value: '102394215042|3503|福建省|莆田市'
      },
      {
        label: '中国工商银行莆田榜头支行',
        value: '102394215059|3503|福建省|莆田市'
      },
      {
        label: '中国工商银行莆田燕顶支行',
        value: '102394215075|3503|福建省|莆田市'
      },
      {
        label: '中国工商银行三明市分行',
        value: '102395060003|3504|福建省|三明市'
      },
      {
        label: '中国工商银行三明市列东支行',
        value: '102395060011|3504|福建省|三明市'
      },
      {
        label: '中国工商银行三明市列东三钢支行',
        value: '102395060020|3504|福建省|三明市'
      },
      {
        label: '中国工商银行三明市列东白沙支行',
        value: '102395060038|3504|福建省|三明市'
      },
      {
        label: '中国工商银行三明徐碧支行',
        value: '102395060046|3504|福建省|三明市'
      },
      {
        label: '中国工商银行三明钢花支行',
        value: '102395060054|3504|福建省|三明市'
      },
      {
        label: '中国工商银行股份有限公司青岛市北第一支行',
        value: '102452001015|3702|山东省|青岛市'
      },
      {
        label: '中国工商银行股份有限公司青岛辽阳路支行',
        value: '102452001023|3702|山东省|青岛市'
      },
      {
        label: '中国工商银行股份有限公司青岛台东支行台东三路分理处',
        value: '102452001031|3702|山东省|青岛市'
      },
      {
        label: '中国工商银行股份有限公司青岛山东路支行镇江北路分理处',
        value: '102452001040|3702|山东省|青岛市'
      },
      {
        label: '中国工商银行股份有限公司青岛台东支行',
        value: '102452001058|3702|山东省|青岛市'
      },
      {
        label: '中国工商银行股份有限公司青岛市北第二支行',
        value: '102452001066|3702|山东省|青岛市'
      },
      {
        label: '中国工商银行股份有限公司青岛辽宁路支行',
        value: '102452001074|3702|山东省|青岛市'
      },
      {
        label: '中国工商银行股份有限公司青岛市北第二支行市场三路分理处',
        value: '102452001099|3702|山东省|青岛市'
      },
      {
        label: '中国工商银行青岛市市北区第二支行聊城路分理处',
        value: '102452001103|3702|山东省|青岛市'
      },
      {
        label: '中国工商银行青岛香港中路阳光支行',
        value: '102452001111|3702|山东省|青岛市'
      },
      {
        label: '中国工商银行股份有限公司青岛延安路支行',
        value: '102452001162|3702|山东省|青岛市'
      },
      {
        label: '中国工商银行股份有限公司青岛芝泉路支行',
        value: '102452001179|3702|山东省|青岛市'
      },
      {
        label: '中国工商银行股份有限公司青岛敦化路支行',
        value: '102452001195|3702|山东省|青岛市'
      },
      {
        label: '中国工商银行股份有限公司青岛延安三路支行',
        value: '102452001200|3702|山东省|青岛市'
      },
      {
        label: '中国工商银行股份有限公司青岛市北第三支行',
        value: '102452001226|3702|山东省|青岛市'
      },
      {
        label: '中国工商银行股份有限公司朝阳大庆路支行',
        value: '102234090040|2113|辽宁省|朝阳市'
      },
      {
        label: '中国工商银行股份有限公司唐山丽景支行',
        value: '102124002706|1302|河北省|唐山市'
      },
      {
        label: '中国工商银行唐山建北支行',
        value: '102124002714|1302|河北省|唐山市'
      },
      {
        label: '中国工商银行股份有限公司唐山机场支行',
        value: '102124002747|1302|河北省|唐山市'
      },
      {
        label: '中国工商银行股份有限公司唐山部西里支行',
        value: '102124002755|1302|河北省|唐山市'
      },
      {
        label: '中国工商银行股份有限公司唐山钓鱼台支行',
        value: '102124002771|1302|河北省|唐山市'
      },
      {
        label: '中国工商银行唐山胜利支行',
        value: '102124002802|1302|河北省|唐山市'
      },
      {
        label: '中国工商银行股份有限公司唐山鹭港支行',
        value: '102124002827|1302|河北省|唐山市'
      },
      {
        label: '中国工商银行唐山福乐园支行',
        value: '102124002894|1302|河北省|唐山市'
      },
      {
        label: '中国工商银行唐山西缸窑支行',
        value: '102124002909|1302|河北省|唐山市'
      },
      {
        label: '中国工商银行股份有限公司唐山会展支行',
        value: '102124002925|1302|河北省|唐山市'
      },
      {
        label: '中国工商银行股份有限公司唐山荣华支行',
        value: '102124002933|1302|河北省|唐山市'
      },
      {
        label: '中国工商银行股份有限公司唐山永庆支行',
        value: '102124002950|1302|河北省|唐山市'
      },
      {
        label: '中国工商银行股份有限公司唐山凤祥园支行',
        value: '102124002976|1302|河北省|唐山市'
      },
      {
        label: '中国工商银行股份有限公司唐山长宁支行',
        value: '102124002984|1302|河北省|唐山市'
      },
      {
        label: '中国工商银行股份有限公司唐山金港国际支行',
        value: '102124002992|1302|河北省|唐山市'
      },
      {
        label: '中国工商银行唐山开平支行',
        value: '102124003008|1302|河北省|唐山市'
      },
      {
        label: '中国工商银行唐山荆各庄支行',
        value: '102124003016|1302|河北省|唐山市'
      },
      {
        label: '中国工商银行唐山古冶支行',
        value: '102124003081|1302|河北省|唐山市'
      },
      {
        label: '中国工商银行唐山赵各庄支行',
        value: '102124003090|1302|河北省|唐山市'
      },
      {
        label: '中国工商银行唐山古榛支行',
        value: '102124003112|1302|河北省|唐山市'
      },
      {
        label: '中国工商银行唐山唐家庄支行',
        value: '102124003129|1302|河北省|唐山市'
      },
      {
        label: '中国工商银行唐山南范各庄支行',
        value: '102124003137|1302|河北省|唐山市'
      },
      {
        label: '中国工商银行唐山吕家坨支行',
        value: '102124003145|1302|河北省|唐山市'
      },
      {
        label: '中国工商银行唐山唐钢支行',
        value: '102124003231|1302|河北省|唐山市'
      },
      {
        label: '中国工商银行唐山车站支行',
        value: '102124003282|1302|河北省|唐山市'
      },
      {
        label: '中国工商银行股份有限公司唐山帝景支行',
        value: '102124003299|1302|河北省|唐山市'
      },
      {
        label: '中国工商银行唐山赵庄支行',
        value: '102124003311|1302|河北省|唐山市'
      },
      {
        label: '中国工商银行股份有限公司唐山南新嘉园支行',
        value: '102124003320|1302|河北省|唐山市'
      },
      {
        label: '中国工商银行股份有限公司唐山站前支行',
        value: '102124003338|1302|河北省|唐山市'
      },
      {
        label: '中国工商银行唐山南堡支行',
        value: '102124003362|1302|河北省|唐山市'
      },
      {
        label: '中国工商银行唐山丰润支行',
        value: '102124003426|1302|河北省|唐山市'
      },
      {
        label: '中国工商银行唐山红星支行',
        value: '102124003434|1302|河北省|唐山市'
      },
      {
        label: '中国工商银行唐山金利支行',
        value: '102124003442|1302|河北省|唐山市'
      },
      {
        label: '中国工商银行唐山新城支行',
        value: '102124003459|1302|河北省|唐山市'
      },
      {
        label: '中国工商银行股份有限公司唐山朝阳支行',
        value: '102124003475|1302|河北省|唐山市'
      },
      {
        label: '中国工商银行唐山丰南支行',
        value: '102124003571|1302|河北省|唐山市'
      },
      {
        label: '中国工商银行股份有限公司溧阳南环支行',
        value: '102304306611|3204|江苏省|常州市'
      },
      {
        label: '中国工商银行股份有限公司溧阳昆仑支行',
        value: '102304306620|3204|江苏省|常州市'
      },
      {
        label: '中国工商银行股份有限公司溧阳体育路支行',
        value: '102304306687|3204|江苏省|常州市'
      },
      {
        label: '中国工商银行股份有限公司溧阳阳光支行',
        value: '102304306695|3204|江苏省|常州市'
      },
      {
        label: '中国工商银行股份有限公司溧阳罗湾路支行',
        value: '102304306700|3204|江苏省|常州市'
      },
      {
        label: '中国工商银行苏州分行',
        value: '102305000017|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行苏州市观前支行',
        value: '102305002012|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行苏州市道前支行',
        value: '102305002029|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行苏州市工业园区支行',
        value: '102305002037|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行苏州市平江支行',
        value: '102305002045|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行苏州市阊门支行',
        value: '102305002053|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行股份有限公司苏州分行业务处理中心',
        value: '102305002096|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行苏州市留园支行',
        value: '102305002107|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行股份有限公司杭州柳莺支行',
        value: '102331000653|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行股份有限公司杭州运河码头支行',
        value: '102331000715|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行股份有限公司杭州祥符支行',
        value: '102331000723|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行股份有限公司杭州新河小微企业专营支行',
        value: '102331000731|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行股份有限公司海安中楹桥支行',
        value: '102306112115|3206|江苏省|南通市'
      },
      {
        label: '中国工商银行股份有限公司海安李堡支行',
        value: '102306112123|3206|江苏省|南通市'
      },
      {
        label: '中国工商银行股份有限公司海安西楹桥支行',
        value: '102306118038|3206|江苏省|南通市'
      },
      {
        label: '中国工商银行股份有限公司海安文明支行',
        value: '102306118159|3206|江苏省|南通市'
      },
      {
        label: '中国工商银行股份有限公司海安奥星支行',
        value: '102306118183|3206|江苏省|南通市'
      },
      {
        label: '中国工商银行如皋市支行',
        value: '102306200012|3206|江苏省|南通市'
      },
      {
        label: '中国工商银行股份有限公司如皋环南支行',
        value: '102306200029|3206|江苏省|南通市'
      },
      {
        label: '中国工商银行如皋白蒲支行',
        value: '102306200045|3206|江苏省|南通市'
      },
      {
        label: '中国工商银行股份有限公司太原和平北路支行',
        value: '102161000960|1401|山西省|太原市'
      },
      {
        label: '中国工商银行股份有限公司太原迎泽西大街支行',
        value: '102161000986|1401|山西省|太原市'
      },
      {
        label: '中国工商银行股份有限公司太原西岸支行',
        value: '102161001001|1401|山西省|太原市'
      },
      {
        label: '中国工商银行股份有限公司迁安阜安支行',
        value: '102124641386|1302|河北省|唐山市'
      },
      {
        label: '中国工商银行迁西支行',
        value: '102124703714|1302|河北省|唐山市'
      },
      {
        label: '中国工商银行迁西洒河桥支行',
        value: '102124703894|1302|河北省|唐山市'
      },
      {
        label: '中国工商银行股份有限公司唐山遵化城关支行',
        value: '102124803943|1302|河北省|唐山市'
      },
      {
        label: '中国工商银行遵化支行',
        value: '102124803951|1302|河北省|唐山市'
      },
      {
        label: '中国工商银行股份有限公司唐山遵化建设支行',
        value: '102124803960|1302|河北省|唐山市'
      },
      {
        label: '中国工商银行股份有限公司唐山遵化河东支行',
        value: '102124803978|1302|河北省|唐山市'
      },
      {
        label: '中国工商银行股份有限公司唐山遵化北关支行',
        value: '102124803994|1302|河北省|唐山市'
      },
      {
        label: '中国工商银行玉田支行',
        value: '102124904034|1302|河北省|唐山市'
      },
      {
        label: '中国工商银行玉田昌盛支行',
        value: '102124904075|1302|河北省|唐山市'
      },
      {
        label: '中国工商银行股份有限公司唐山曹妃甸支行',
        value: '102125103394|1302|河北省|唐山市'
      },
      {
        label: '中国工商银行股份有限公司唐山曹妃甸港支行',
        value: '102125104151|1302|河北省|唐山市'
      },
      {
        label: '中国工商银行秦皇岛滨海支行',
        value: '102126004519|1303|河北省|秦皇岛市'
      },
      {
        label: '中国工商银行秦皇岛上秦支行',
        value: '102126004527|1303|河北省|秦皇岛市'
      },
      {
        label: '中国工商银行秦皇岛港兴支行',
        value: '102126004560|1303|河北省|秦皇岛市'
      },
      {
        label: '中国工商银行秦皇岛海港支行',
        value: '102126004594|1303|河北省|秦皇岛市'
      },
      {
        label: '中国工商银行秦皇岛光明支行',
        value: '102126004633|1303|河北省|秦皇岛市'
      },
      {
        label: '中国工商银行秦皇岛迎宾支行',
        value: '102126004650|1303|河北省|秦皇岛市'
      },
      {
        label: '中国工商银行秦皇岛港城支行',
        value: '102126004668|1303|河北省|秦皇岛市'
      },
      {
        label: '中国工商银行秦皇岛河东支行',
        value: '102126004692|1303|河北省|秦皇岛市'
      },
      {
        label: '中国工商银行股份有限公司秦皇岛热电支行',
        value: '102126004705|1303|河北省|秦皇岛市'
      },
      {
        label: '中国工商银行股份有限公司秦皇岛祁连山支行',
        value: '102126004730|1303|河北省|秦皇岛市'
      },
      {
        label: '中国工商银行秦皇岛西港支行',
        value: '102126004748|1303|河北省|秦皇岛市'
      },
      {
        label: '中国工商银行股份有限公司秦皇岛森林逸城支行',
        value: '102126004756|1303|河北省|秦皇岛市'
      },
      {
        label: '中国工商银行秦皇岛北站支行',
        value: '102126004772|1303|河北省|秦皇岛市'
      },
      {
        label: '中国工商银行秦皇岛开发区支行',
        value: '102126004801|1303|河北省|秦皇岛市'
      },
      {
        label: '中国工商银行秦皇岛桥东支行',
        value: '102126004810|1303|河北省|秦皇岛市'
      },
      {
        label: '中国工商银行股份有限公司秦皇岛峨嵋支行',
        value: '102126004836|1303|河北省|秦皇岛市'
      },
      {
        label: '中国工商银行股份有限公司孝义支行',
        value: '102173401736|1411|山西省|吕梁市'
      },
      {
        label: '中国工商银行股份有限公司孝义兑镇支行',
        value: '102173401744|1411|山西省|吕梁市'
      },
      {
        label: '中国工商银行股份有限公司兴县支行',
        value: '102173501977|1411|山西省|吕梁市'
      },
      {
        label: '中国工商银行股份有限公司临县支行',
        value: '102173601814|1411|山西省|吕梁市'
      },
      {
        label: '中国工商银行股份有限公司柳林支行',
        value: '102173701858|1411|山西省|吕梁市'
      },
      {
        label: '中国工商银行股份有限公司中阳支行',
        value: '102174301881|1411|山西省|吕梁市'
      },
      {
        label: '中国工商银行股份有限公司交口支行',
        value: '102174402046|1411|山西省|吕梁市'
      },
      {
        label: '中国工商银行股份有限公司晋中锦纶路支行',
        value: '102175000175|1407|山西省|晋中市'
      },
      {
        label: '中国工商银行晋中市分行牡丹支行',
        value: '102175000837|1407|山西省|晋中市'
      },
      {
        label: '中国工商银行晋中市分行中都支行',
        value: '102175001194|1407|山西省|晋中市'
      },
      {
        label: '中国工商银行晋中市分行道北支行',
        value: '102175001209|1407|山西省|晋中市'
      },
      {
        label: '中国工商银行晋中市分行迎宾支行',
        value: '102175001225|1407|山西省|晋中市'
      },
      {
        label: '中国工商银行晋中市分行经纬支行',
        value: '102175001241|1407|山西省|晋中市'
      },
      {
        label: '中国工商银行股份有限公司杭州保俶支行',
        value: '102331002270|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行杭州德胜支行',
        value: '102331002288|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行杭州市之江支行',
        value: '102331002296|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行股份有限公司杭州延中支行',
        value: '102331002307|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行股份有限公司杭州西兴支行',
        value: '102331002315|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行股份有限公司杭州高新支行学院路分理处',
        value: '102331002323|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行股份有限公司杭州三墩支行',
        value: '102331002331|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行股份有限公司杭州景江苑支行',
        value: '102331002340|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行股份有限公司杭州清泰支行',
        value: '102331002358|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行股份有限公司杭州东门支行',
        value: '102331002366|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行股份有限公司杭州金汇支行',
        value: '102331002403|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行股份有限公司盘锦盘隆支行',
        value: '102232000267|2111|辽宁省|盘锦市'
      },
      {
        label: '中国工商银行盘锦市盘山支行',
        value: '102232000275|2111|辽宁省|盘锦市'
      },
      {
        label: '中国工商银行股份有限公司盘锦渤海路支行',
        value: '102232000283|2111|辽宁省|盘锦市'
      },
      {
        label: '中国工商银行盘锦辽河油田支行康桥分理处',
        value: '102232000314|2111|辽宁省|盘锦市'
      },
      {
        label: '中国工商银行股份有限公司盘锦采油支行',
        value: '102232000339|2111|辽宁省|盘锦市'
      },
      {
        label: '中国工商银行盘锦友谊支行',
        value: '102232000355|2111|辽宁省|盘锦市'
      },
      {
        label: '中国工商银行股份有限公司鄂尔多斯金珠支行',
        value: '102205000085|1506|内蒙古自治区|鄂尔多斯市'
      },
      {
        label: '中国工商银行股份有限公司太原世纪广场支行',
        value: '102161000156|1401|山西省|太原市'
      },
      {
        label: '中国工商银行股份有限公司太原解放路支行',
        value: '102161000164|1401|山西省|太原市'
      },
      {
        label: '中国工商银行股份有限公司太原太钢支行',
        value: '102161000172|1401|山西省|太原市'
      },
      {
        label: '中国工商银行股份有限公司太原万柏林支行',
        value: '102161000189|1401|山西省|太原市'
      },
      {
        label: '中国工商银行股份有限公司太原晋源支行',
        value: '102161000197|1401|山西省|太原市'
      },
      {
        label: '中国工商银行股份有限公司太原西山支行',
        value: '102161000201|1401|山西省|太原市'
      },
      {
        label: '中国工商银行股份有限公司太原大营盘支行',
        value: '102161000210|1401|山西省|太原市'
      },
      {
        label: '中国工商银行股份有限公司太原三营盘支行',
        value: '102161000228|1401|山西省|太原市'
      },
      {
        label: '中国工商银行股份有限公司太原迎宾路支行',
        value: '102161000236|1401|山西省|太原市'
      },
      {
        label: '中国工商银行股份有限公司太原迎新街支行',
        value: '102161000244|1401|山西省|太原市'
      },
      {
        label: '中国工商银行股份有限公司太原小店支行',
        value: '102161000252|1401|山西省|太原市'
      },
      {
        label: '中国工商银行股份有限公司太原尖草坪支行',
        value: '102161000277|1401|山西省|太原市'
      },
      {
        label: '中国工商银行股份有限公司清徐支行',
        value: '102161000285|1401|山西省|太原市'
      },
      {
        label: '中国工商银行股份有限公司古交支行',
        value: '102161000293|1401|山西省|太原市'
      },
      {
        label: '中国工商银行股份有限公司太原府西街支行',
        value: '102161000308|1401|山西省|太原市'
      },
      {
        label: '中国工商银行股份有限公司太原鼓楼街支行',
        value: '102161000316|1401|山西省|太原市'
      },
      {
        label: '中国工商银行股份有限公司太原漪汾街支行',
        value: '102161000324|1401|山西省|太原市'
      },
      {
        label: '中国工商银行股份有限公司太原高新技术开发区支行',
        value: '102161000332|1401|山西省|太原市'
      },
      {
        label: '中国工商银行股份有限公司太原国贸支行',
        value: '102161000349|1401|山西省|太原市'
      },
      {
        label: '中国工商银行股份有限公司太原下元支行',
        value: '102161000357|1401|山西省|太原市'
      },
      {
        label: '中国工商银行股份有限公司伊通满族自治县支行',
        value: '102243222168|2203|吉林省|四平市'
      },
      {
        label: '中国工商银行股份有限公司双辽支行',
        value: '102243322209|2203|吉林省|四平市'
      },
      {
        label: '中国工商银行股份有限公司公主岭支行',
        value: '102243422115|2203|吉林省|四平市'
      },
      {
        label: '中国工商银行股份有限公司公主岭范家屯支行',
        value: '102243422158|2203|吉林省|四平市'
      },
      {
        label: '中国工商银行股份有限公司辽源向阳支行',
        value: '102244000014|2204|吉林省|辽源市'
      },
      {
        label: '中国工商银行辽源中兴支行',
        value: '102244020015|2204|吉林省|辽源市'
      },
      {
        label: '中国工商银行辽源西宁支行',
        value: '102244020082|2204|吉林省|辽源市'
      },
      {
        label: '中国工商银行辽源分行',
        value: '102244022002|2204|吉林省|辽源市'
      },
      {
        label: '中国工商银行辽源东风支行',
        value: '102244022019|2204|吉林省|辽源市'
      },
      {
        label: '中国工商银行辽源东方红支行',
        value: '102244022027|2204|吉林省|辽源市'
      },
      {
        label: '中国工商银行股份有限公司吕梁永宁支行',
        value: '102173002554|1411|山西省|吕梁市'
      },
      {
        label: '中国工商银行股份有限公司吕梁广场支行',
        value: '102173025673|1411|山西省|吕梁市'
      },
      {
        label: '中国工商银行股份有限公司汾阳支行',
        value: '102173101625|1411|山西省|吕梁市'
      },
      {
        label: '中国工商银行股份有限公司汾阳杏花支行',
        value: '102173101633|1411|山西省|吕梁市'
      },
      {
        label: '中国工商银行股份有限公司文水支行',
        value: '102173201667|1411|山西省|吕梁市'
      },
      {
        label: '中国工商银行股份有限公司交城支行',
        value: '102173301707|1411|山西省|吕梁市'
      },
      {
        label: '中国工商银行股份有限公司包头汇源支行',
        value: '102192000886|1502|内蒙古自治区|包头市'
      },
      {
        label: '中国工商银行股份有限公司包头华丽家族支行',
        value: '102192000894|1502|内蒙古自治区|包头市'
      },
      {
        label: '中国工商银行股份有限公司包头保利百合支行',
        value: '102192000909|1502|内蒙古自治区|包头市'
      },
      {
        label: '中国工商银行股份有限公司包头龙园支行',
        value: '102192000950|1502|内蒙古自治区|包头市'
      },
      {
        label: '中国工商银行包头分行会计业务处理中心',
        value: '102192001008|1502|内蒙古自治区|包头市'
      },
      {
        label: '中国工商银行包头工业路支行',
        value: '102192001073|1502|内蒙古自治区|包头市'
      },
      {
        label: '中国工商银行股份有限公司包头民营经济发展中心大厦支行',
        value: '102192001112|1502|内蒙古自治区|包头市'
      },
      {
        label: '中国工商银行包头银河广场支行',
        value: '102192001188|1502|内蒙古自治区|包头市'
      },
      {
        label: '中国工商银行包头市昆都仑区支行',
        value: '102192001207|1502|内蒙古自治区|包头市'
      },
      {
        label: '中国工商银行包头阿尔丁大街支行',
        value: '102192001223|1502|内蒙古自治区|包头市'
      },
      {
        label: '中国工商银行包头沼潭车站支行',
        value: '102192001266|1502|内蒙古自治区|包头市'
      },
      {
        label: '中国工商银行股份有限公司包头江南文枢苑支行',
        value: '102192001299|1502|内蒙古自治区|包头市'
      },
      {
        label: '中国工商银行股份有限公司呼和浩特哲里木路第二支行',
        value: '102191010700|1501|内蒙古自治区|呼和浩特市'
      },
      {
        label: '中国工商银行股份有限公司朝阳广场支行',
        value: '102234090074|2113|辽宁省|朝阳市'
      },
      {
        label: '中国工商银行股份有限公司朝阳站前支行',
        value: '102234090082|2113|辽宁省|朝阳市'
      },
      {
        label: '中国工商银行朝阳建平支行',
        value: '102234200123|2113|辽宁省|朝阳市'
      },
      {
        label: '中国工商银行股份有限公司建平五四支行',
        value: '102234201259|2113|辽宁省|朝阳市'
      },
      {
        label: '中国工商银行股份有限公司建平长征支行',
        value: '102234201283|2113|辽宁省|朝阳市'
      },
      {
        label: '中国工商银行股份有限公司建平二一零支行',
        value: '102234201291|2113|辽宁省|朝阳市'
      },
      {
        label: '中国工商银行朝阳凌源支行',
        value: '102234300097|2113|辽宁省|朝阳市'
      },
      {
        label: '中国工商银行朝阳凌源凌北分理处',
        value: '102234300101|2113|辽宁省|朝阳市'
      },
      {
        label: '中国工商银行股份有限公司凌源大什字支行',
        value: '102234301475|2113|辽宁省|朝阳市'
      },
      {
        label: '中国工商银行股份有限公司长春临河街支行',
        value: '102241000429|2201|吉林省|长春市'
      },
      {
        label: '中国工商银行股份有限公司长春迎宾路支行',
        value: '102241000437|2201|吉林省|长春市'
      },
      {
        label: '中国工商银行股份有限公司长春红旗街支行',
        value: '102241000445|2201|吉林省|长春市'
      },
      {
        label: '中国工商银行长春东朝阳路支行',
        value: '102241000453|2201|吉林省|长春市'
      },
      {
        label: '中国工商银行长春珠海路支行',
        value: '102241000461|2201|吉林省|长春市'
      },
      {
        label: '中国工商银行股份有限公司长春仙台大街支行',
        value: '102241000523|2201|吉林省|长春市'
      },
      {
        label: '中国工商银行股份有限公司长春朝阳桥支行',
        value: '102241000540|2201|吉林省|长春市'
      },
      {
        label: '中国工商银行长春建设街支行',
        value: '102241000566|2201|吉林省|长春市'
      },
      {
        label: '中国工商银行股份有限公司长春吉大一院支行',
        value: '102241000582|2201|吉林省|长春市'
      },
      {
        label: '中国工商银行股份有限公司长春公平路支行',
        value: '102241000603|2201|吉林省|长春市'
      },
      {
        label: '中国工商银行股份有限公司长春客车厂支行',
        value: '102241000638|2201|吉林省|长春市'
      },
      {
        label: '中国工商银行股份有限公司长春站前支行',
        value: '102241000646|2201|吉林省|长春市'
      },
      {
        label: '中国工商银行股份有限公司长春杭州路支行',
        value: '102241000654|2201|吉林省|长春市'
      },
      {
        label: '中国工商银行股份有限公司长春电台街支行',
        value: '102241000662|2201|吉林省|长春市'
      },
      {
        label: '中国工商银行长春人民大街支行',
        value: '102241000687|2201|吉林省|长春市'
      },
      {
        label: '中国工商银行股份有限公司长春平阳街支行',
        value: '102241000718|2201|吉林省|长春市'
      },
      {
        label: '中国工商银行股份有限公司长春全安广场支行',
        value: '102241000734|2201|吉林省|长春市'
      },
      {
        label: '中国工商银行长春三道街支行',
        value: '102241000742|2201|吉林省|长春市'
      },
      {
        label: '中国工商银行股份有限公司长春岳阳街支行',
        value: '102241000759|2201|吉林省|长春市'
      },
      {
        label: '中国工商银行股份有限公司长春天富路支行',
        value: '102241000783|2201|吉林省|长春市'
      },
      {
        label: '中国工商银行股份有限公司长春卫星路支行',
        value: '102241000791|2201|吉林省|长春市'
      },
      {
        label: '中国工商银行股份有限公司长春胡兰路支行',
        value: '102241000806|2201|吉林省|长春市'
      },
      {
        label: '中国工商银行股份有限公司长春永昌路支行',
        value: '102241000814|2201|吉林省|长春市'
      },
      {
        label: '中国工商银行股份有限公司长春万达广场支行',
        value: '102241000822|2201|吉林省|长春市'
      },
      {
        label: '中国工商银行股份有限公司长春繁荣支行',
        value: '102241000839|2201|吉林省|长春市'
      },
      {
        label: '中国工商银行股份有限公司长春惠民路支行',
        value: '102241000847|2201|吉林省|长春市'
      },
      {
        label: '中国工商银行股份有限公司长春东盛路支行',
        value: '102241000880|2201|吉林省|长春市'
      },
      {
        label: '中国工商银行股份有限公司长春荣光路支行',
        value: '102241000902|2201|吉林省|长春市'
      },
      {
        label: '中国工商银行股份有限公司长春八里堡支行',
        value: '102241000919|2201|吉林省|长春市'
      },
      {
        label: '中国工商银行股份有限公司长春岭东路支行',
        value: '102241000927|2201|吉林省|长春市'
      },
      {
        label: '中国工商银行股份有限公司长春北八道街支行',
        value: '102241000935|2201|吉林省|长春市'
      },
      {
        label: '中国工商银行股份有限公司长春宋家支行',
        value: '102241000994|2201|吉林省|长春市'
      },
      {
        label: '中国工商银行股份有限公司长春西安桥外支行',
        value: '102241001001|2201|吉林省|长春市'
      },
      {
        label: '中国工商银行股份有限公司长春青年路支行',
        value: '102241001010|2201|吉林省|长春市'
      },
      {
        label: '中国工商银行股份有限公司长春花园支行',
        value: '102241001028|2201|吉林省|长春市'
      },
      {
        label: '中国工商银行股份有限公司长春万福街支行',
        value: '102241001036|2201|吉林省|长春市'
      },
      {
        label: '中国工商银行股份有限公司长春二0八医院支行',
        value: '102241001052|2201|吉林省|长春市'
      },
      {
        label: '中国工商银行股份有限公司杭州萧东支行',
        value: '102331009038|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行股份有限公司杭州江东支行',
        value: '102331009046|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行股份有限公司杭州南苑支行',
        value: '102331009054|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行杭州江南支行',
        value: '102331009206|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行股份有限公司杭州彭埠支行',
        value: '102331020226|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行股份有限公司杭州大学城支行',
        value: '102331020291|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行股份有限公司襄垣上寺楼支行',
        value: '102166300038|1404|山西省|长治市'
      },
      {
        label: '中国工商银行股份有限公司太原长风街支行',
        value: '102161001069|1401|山西省|太原市'
      },
      {
        label: '中国工商银行股份有限公司太原焦煤支行',
        value: '102161001085|1401|山西省|太原市'
      },
      {
        label: '中国工商银行股份有限公司太原二营盘支行',
        value: '102161001149|1401|山西省|太原市'
      },
      {
        label: '中国工商银行股份有限公司太原双塔西街支行',
        value: '102161001181|1401|山西省|太原市'
      },
      {
        label: '中国工商银行股份有限公司太原坞城南路支行',
        value: '102161001261|1401|山西省|太原市'
      },
      {
        label: '中国工商银行股份有限公司太原晋源北街支行',
        value: '102161001340|1401|山西省|太原市'
      },
      {
        label: '中国工商银行股份有限公司太原许坦西街支行',
        value: '102161001438|1401|山西省|太原市'
      },
      {
        label: '中国工商银行股份有限公司太原长治路支行',
        value: '102161001446|1401|山西省|太原市'
      },
      {
        label: '中国工商银行股份有限公司大同城建支行',
        value: '102162000256|1402|山西省|大同市'
      },
      {
        label: '中国工商银行股份有限公司大同广场支行',
        value: '102162000264|1402|山西省|大同市'
      },
      {
        label: '中国工商银行股份有限公司大同御河南路支行',
        value: '102162000272|1402|山西省|大同市'
      },
      {
        label: '中国工商银行股份有限公司大同南郊支行',
        value: '102162000289|1402|山西省|大同市'
      },
      {
        label: '中国工商银行股份有限公司大同城区支行',
        value: '102162000297|1402|山西省|大同市'
      },
      {
        label: '中国工商银行股份有限公司大同分行',
        value: '102162000674|1402|山西省|大同市'
      },
      {
        label: '中国工商银行股份有限公司大同御锦源支行',
        value: '102162000682|1402|山西省|大同市'
      },
      {
        label: '中国工商银行股份有限公司大同矿务局支行',
        value: '102162000746|1402|山西省|大同市'
      },
      {
        label: '中国工商银行股份有限公司大同车站支行',
        value: '102162000826|1402|山西省|大同市'
      },
      {
        label: '中国工商银行股份有限公司大同新建路支行',
        value: '102162000842|1402|山西省|大同市'
      },
      {
        label: '中国工商银行股份有限公司大同西花园支行',
        value: '102162000859|1402|山西省|大同市'
      },
      {
        label: '中国工商银行股份有限公司大同大庆路支行',
        value: '102162000867|1402|山西省|大同市'
      },
      {
        label: '中国工商银行股份有限公司大同同泉路支行',
        value: '102162000875|1402|山西省|大同市'
      },
      {
        label: '中国工商银行股份有限公司大同御河北路支行',
        value: '102162004532|1402|山西省|大同市'
      },
      {
        label: '中国工商银行股份有限公司大同振华支行',
        value: '102162004557|1402|山西省|大同市'
      },
      {
        label: '中国工商银行股份有限公司大同迎泽街支行',
        value: '102162004573|1402|山西省|大同市'
      },
      {
        label: '中国工商银行股份有限公司大同魏都支行',
        value: '102162004670|1402|山西省|大同市'
      },
      {
        label: '中国工商银行清原满族自治县支行',
        value: '102234408215|2113|辽宁省|朝阳市'
      },
      {
        label: '中国工商银行抚顺清原红透山分理处',
        value: '102234408223|2113|辽宁省|朝阳市'
      },
      {
        label: '中国工商银行建昌县支行',
        value: '102234500015|2113|辽宁省|朝阳市'
      },
      {
        label: '中国工商银行股份有限公司建昌朝阳路支行',
        value: '102234500058|2113|辽宁省|朝阳市'
      },
      {
        label: '中国工商银行朝阳北票支行',
        value: '102234600112|2113|辽宁省|朝阳市'
      },
      {
        label: '中国工商银行吉林省清算中心(不对外办理结算业务）',
        value: '102241000017|2201|吉林省|长春市'
      },
      {
        label: '中国工商银行吉林省分行营业部核算中心',
        value: '102241000025|2201|吉林省|长春市'
      },
      {
        label: '中国工商银行长春人民广场支行',
        value: '102241000033|2201|吉林省|长春市'
      },
      {
        label: '中国工商银行长春净月旅游经济开发区支行',
        value: '102241000041|2201|吉林省|长春市'
      },
      {
        label: '中国工商银行长春南广场支行',
        value: '102241000050|2201|吉林省|长春市'
      },
      {
        label: '中国工商银行长春南大街支行',
        value: '102241000068|2201|吉林省|长春市'
      },
      {
        label: '中国工商银行长春自由大路支行',
        value: '102241000076|2201|吉林省|长春市'
      },
      {
        label: '中国工商银行长春二道支行',
        value: '102241000084|2201|吉林省|长春市'
      },
      {
        label: '中国工商银行长春铁道北支行',
        value: '102241000092|2201|吉林省|长春市'
      },
      {
        label: '中国工商银行长春经济技术开发区支行',
        value: '102241000105|2201|吉林省|长春市'
      },
      {
        label: '中国工商银行长春迎春路支行',
        value: '102241000113|2201|吉林省|长春市'
      },
      {
        label: '中国工商银行股份有限公司长春驻第一汽车集团公司支行',
        value: '102241000121|2201|吉林省|长春市'
      },
      {
        label: '中国工商银行长春大经路支行',
        value: '102241000130|2201|吉林省|长春市'
      },
      {
        label: '中国工商银行股份有限公司长春绿园支行',
        value: '102241000156|2201|吉林省|长春市'
      },
      {
        label: '中国工商银行长春金程支行',
        value: '102241000164|2201|吉林省|长春市'
      },
      {
        label: '中国工商银行长春兴城支行',
        value: '102241000172|2201|吉林省|长春市'
      },
      {
        label: '中国工商银行长春光明路支行',
        value: '102241000189|2201|吉林省|长春市'
      },
      {
        label: '中国工商银行长春康平街支行',
        value: '102241000197|2201|吉林省|长春市'
      },
      {
        label: '中国工商银行长春同志街支行',
        value: '102241000201|2201|吉林省|长春市'
      },
      {
        label: '中国工商银行沈阳辽河街支行',
        value: '102221004117|2101|辽宁省|沈阳市'
      },
      {
        label: '中国工商银行沈阳五彩阳光支行',
        value: '102221004125|2101|辽宁省|沈阳市'
      },
      {
        label: '中国工商银行沈阳老瓜堡支行',
        value: '102221004133|2101|辽宁省|沈阳市'
      },
      {
        label: '中国工商银行沈阳小白楼支行',
        value: '102221004213|2101|辽宁省|沈阳市'
      },
      {
        label: '中国工商银行沈阳明廉街支行',
        value: '102221004221|2101|辽宁省|沈阳市'
      },
      {
        label: '中国工商银行沈阳塔湾支行',
        value: '102221004230|2101|辽宁省|沈阳市'
      },
      {
        label: '中国工商银行沈阳老道口支行',
        value: '102221004248|2101|辽宁省|沈阳市'
      },
      {
        label: '中国工商银行沈阳后塔街支行',
        value: '102221004256|2101|辽宁省|沈阳市'
      },
      {
        label: '中国工商银行沈阳向工街支行',
        value: '102221004264|2101|辽宁省|沈阳市'
      },
      {
        label: '中国工商银行沈阳春天支行',
        value: '102221004272|2101|辽宁省|沈阳市'
      },
      {
        label: '中国工商银行沈阳东平湖支行',
        value: '102221004336|2101|辽宁省|沈阳市'
      },
      {
        label: '中国工商银行沈阳紫庭园支行',
        value: '102221004377|2101|辽宁省|沈阳市'
      },
      {
        label: '中国工商银行沈阳沈飞支行',
        value: '102221004385|2101|辽宁省|沈阳市'
      },
      {
        label: '中国工商银行辽源东山支行',
        value: '102244022035|2204|吉林省|辽源市'
      },
      {
        label: '中国工商银行股份有限公司鄂尔多斯赛汗支行',
        value: '102205000149|1506|内蒙古自治区|鄂尔多斯市'
      },
      {
        label: '中国工商银行股份有限公司河北省分行业务处理中心-不办转汇业务',
        value: '102121000012|1301|河北省|石家庄市'
      },
      {
        label: '中国工商银行河北省分行营业部业务处理中心',
        value: '102121000029|1301|河北省|石家庄市'
      },
      {
        label: '中国工商银行石家庄东风支行',
        value: '102121000037|1301|河北省|石家庄市'
      },
      {
        label: '中国工商银行石家庄桥西支行',
        value: '102121000045|1301|河北省|石家庄市'
      },
      {
        label: '中国工商银行股份有限公司石家庄裕东支行',
        value: '102121000053|1301|河北省|石家庄市'
      },
      {
        label: '中国工商银行石家庄和平支行',
        value: '102121000061|1301|河北省|石家庄市'
      },
      {
        label: '中国工商银行石家庄长安支行',
        value: '102121000070|1301|河北省|石家庄市'
      },
      {
        label: '中国工商银行石家庄中华支行',
        value: '102121000088|1301|河北省|石家庄市'
      },
      {
        label: '中国工商银行石家庄车站支行',
        value: '102121000096|1301|河北省|石家庄市'
      },
      {
        label: '中国工商银行股份有限公司乌拉特前旗乌拉山支行',
        value: '102207409135|1508|内蒙古自治区|巴彦淖尔市'
      },
      {
        label: '中国工商银行股份有限公司乌拉特中旗支行',
        value: '102207500405|1508|内蒙古自治区|巴彦淖尔市'
      },
      {
        label: '中国工商银行股份有限公司乌拉特后旗支行',
        value: '102207600288|1508|内蒙古自治区|巴彦淖尔市'
      },
      {
        label: '中国工商银行股份有限公司杭锦后旗南马路支行',
        value: '102207700297|1508|内蒙古自治区|巴彦淖尔市'
      },
      {
        label: '中国工商银行股份有限公司杭锦后旗东北大桥支行',
        value: '102207700310|1508|内蒙古自治区|巴彦淖尔市'
      },
      {
        label: '中国工商银行股份有限公司杭锦后旗支行',
        value: '102207704135|1508|内蒙古自治区|巴彦淖尔市'
      },
      {
        label: '中国工商银行博野支行',
        value: '102137109816|1306|河北省|保定市'
      },
      {
        label: '中国工商银行蠡县支行',
        value: '102137209822|1306|河北省|保定市'
      },
      {
        label: '中国工商银行蠡县留史支行',
        value: '102137211368|1306|河北省|保定市'
      },
      {
        label: '中国工商银行股份有限公司蠡县永盛支行',
        value: '102137211376|1306|河北省|保定市'
      },
      {
        label: '中国工商银行张家口分行业务处理中心',
        value: '102138011513|1307|河北省|张家口市'
      },
      {
        label: '中国工商银行张家口桥西支行',
        value: '102138011521|1307|河北省|张家口市'
      },
      {
        label: '中国工商银行股份有限公司张家口学府支行',
        value: '102138011572|1307|河北省|张家口市'
      },
      {
        label: '中国工商银行股份有限公司张家口金鼎支行',
        value: '102138011628|1307|河北省|张家口市'
      },
      {
        label: '中国工商银行张家口桥东支行',
        value: '102138011636|1307|河北省|张家口市'
      },
      {
        label: '中国工商银行股份有限公司张家口福安支行',
        value: '102138011669|1307|河北省|张家口市'
      },
      {
        label: '中国工商银行股份有限公司张家口金桥支行',
        value: '102138011708|1307|河北省|张家口市'
      },
      {
        label: '中国工商银行张家口红旗楼支行',
        value: '102138011749|1307|河北省|张家口市'
      },
      {
        label: '中国工商银行股份有限公司张家口高新支行',
        value: '102138011773|1307|河北省|张家口市'
      },
      {
        label: '中国工商银行股份有限公司张家口迎宾支行',
        value: '102138011781|1307|河北省|张家口市'
      },
      {
        label: '中国工商银行股份有限公司张家口容辰支行',
        value: '102138011812|1307|河北省|张家口市'
      },
      {
        label: '中国工商银行张家口汇通支行',
        value: '102138011845|1307|河北省|张家口市'
      },
      {
        label: '中国工商银行张家口明德南支行',
        value: '102138011853|1307|河北省|张家口市'
      },
      {
        label: '中国工商银行张家口宣化支行',
        value: '102138111868|1307|河北省|张家口市'
      },
      {
        label: '中国工商银行宣化牌楼支行',
        value: '102138111876|1307|河北省|张家口市'
      },
      {
        label: '中国工商银行宣化南关支行',
        value: '102138111884|1307|河北省|张家口市'
      },
      {
        label: '中国工商银行宣化建国支行',
        value: '102138111913|1307|河北省|张家口市'
      },
      {
        label: '中国工商银行宣化钢城支行',
        value: '102138111921|1307|河北省|张家口市'
      },
      {
        label: '中国工商银行股份有限公司宣化新世纪支行',
        value: '102138111989|1307|河北省|张家口市'
      },
      {
        label: '中国工商银行股份有限公司宣化新开支行',
        value: '102138112037|1307|河北省|张家口市'
      },
      {
        label: '中国工商银行张家口下花园支行',
        value: '102138112053|1307|河北省|张家口市'
      },
      {
        label: '中国工商银行张北支行',
        value: '102139112315|1307|河北省|张家口市'
      },
      {
        label: '中国工商银行蔚县支行',
        value: '102139512227|1307|河北省|张家口市'
      },
      {
        label: '中国工商银行阳原支行',
        value: '102139612298|1307|河北省|张家口市'
      },
      {
        label: '中国工商银行怀安支行',
        value: '102139712352|1307|河北省|张家口市'
      },
      {
        label: '中国工商银行股份有限公司张家口万全支行',
        value: '102139812194|1307|河北省|张家口市'
      },
      {
        label: '中国工商银行怀来支行',
        value: '102139912140|1307|河北省|张家口市'
      },
      {
        label: '中国工商银行股份有限公司怀来沙城支行',
        value: '102139912158|1307|河北省|张家口市'
      },
      {
        label: '中国工商银行股份有限公司怀来京张支行',
        value: '102139912182|1307|河北省|张家口市'
      },
      {
        label: '中国工商银行涿鹿支行',
        value: '102140112109|1307|河北省|张家口市'
      },
      {
        label: '中国工商银行股份有限公司呼和浩特乌兰察布路支行',
        value: '102191010726|1501|内蒙古自治区|呼和浩特市'
      },
      {
        label: '中国工商银行股份有限公司呼和浩特惠民街支行',
        value: '102191010822|1501|内蒙古自治区|呼和浩特市'
      },
      {
        label: '中国工商银行股份有限公司呼和浩特哲里木路支行',
        value: '102191010855|1501|内蒙古自治区|呼和浩特市'
      },
      {
        label: '中国工商银行股份有限公司呼和浩特兴安北路支行',
        value: '102191010871|1501|内蒙古自治区|呼和浩特市'
      },
      {
        label: '中国工商银行股份有限公司呼和浩特南茶坊支行',
        value: '102191011036|1501|内蒙古自治区|呼和浩特市'
      },
      {
        label: '中国工商银行股份有限公司运城车站支行',
        value: '102181003928|1408|山西省|运城市'
      },
      {
        label: '中国工商银行股份有限公司运城盐湖支行',
        value: '102181029020|1408|山西省|运城市'
      },
      {
        label: '中国工商银行股份有限公司运城学苑支行',
        value: '102181029038|1408|山西省|运城市'
      },
      {
        label: '中国工商银行股份有限公司运城槐东支行',
        value: '102181029054|1408|山西省|运城市'
      },
      {
        label: '中国工商银行股份有限公司运城禹香苑支行',
        value: '102181029441|1408|山西省|运城市'
      },
      {
        label: '中国工商银行股份有限公司运城圣惠支行',
        value: '102181029450|1408|山西省|运城市'
      },
      {
        label: '中国工商银行股份有限公司运城城北支行',
        value: '102181029468|1408|山西省|运城市'
      },
      {
        label: '中国工商银行股份有限公司运城南城支行',
        value: '102181029484|1408|山西省|运城市'
      },
      {
        label: '中国工商银行股份有限公司运城东城支行',
        value: '102181029513|1408|山西省|运城市'
      },
      {
        label: '中国工商银行股份有限公司永济支行',
        value: '102181203982|1408|山西省|运城市'
      },
      {
        label: '中国工商银行股份有限公司永济电机支行',
        value: '102181204151|1408|山西省|运城市'
      },
      {
        label: '中国工商银行股份有限公司永济城关支行',
        value: '102181229073|1408|山西省|运城市'
      },
      {
        label: '中国工商银行股份有限公司永济舜都大道支行',
        value: '102181229129|1408|山西省|运城市'
      },
      {
        label: '中国工商银行股份有限公司芮城支行',
        value: '102181303825|1408|山西省|运城市'
      },
      {
        label: '中国工商银行泰州升仙支行',
        value: '102312806037|3212|江苏省|泰州市'
      },
      {
        label: '中国工商银行股份有限公司泰州南通路支行',
        value: '102312806088|3212|江苏省|泰州市'
      },
      {
        label: '中国工商银行股份有限公司泰州育才路支行',
        value: '102312806166|3212|江苏省|泰州市'
      },
      {
        label: '中国工商银行股份有限公司泰州东郊支行',
        value: '102312806203|3212|江苏省|泰州市'
      },
      {
        label: '中国工商银行股份有限公司泰州林海支行',
        value: '102312806254|3212|江苏省|泰州市'
      },
      {
        label: '中国工商银行股份有限公司泰州沁莲支行',
        value: '102312806318|3212|江苏省|泰州市'
      },
      {
        label: '中国工商银行股份有限公司仪征胥浦浦东支行',
        value: '102312900022|3210|江苏省|扬州市'
      },
      {
        label: '中国工商银行股份有限公司仪征胥浦纤城支行',
        value: '102312900039|3210|江苏省|扬州市'
      },
      {
        label: '中国工商银行股份有限公司仪征白沙支行',
        value: '102312900991|3210|江苏省|扬州市'
      },
      {
        label: '中国工商银行股份有限公司仪征支行',
        value: '102312901017|3210|江苏省|扬州市'
      },
      {
        label: '中国工商银行股份有限公司仪征城南支行',
        value: '102312920120|3210|江苏省|扬州市'
      },
      {
        label: '中国工商银行股份有限公司仪征城北支行',
        value: '102312920138|3210|江苏省|扬州市'
      },
      {
        label: '中国工商银行股份有限公司仪征城中支行',
        value: '102312920162|3210|江苏省|扬州市'
      },
      {
        label: '中国工商银行股份有限公司仪征新城支行',
        value: '102312920179|3210|江苏省|扬州市'
      },
      {
        label: '中国工商银行兴化市支行',
        value: '102313151013|3210|江苏省|扬州市'
      },
      {
        label: '中国工商银行股份有限公司兴化城中支行',
        value: '102313151030|3210|江苏省|扬州市'
      },
      {
        label: '中国工商银行股份有限公司丹东六合街支行',
        value: '102226000393|2106|辽宁省|丹东市'
      },
      {
        label: '中国工商银行股份有限公司丹东站前支行',
        value: '102226000424|2106|辽宁省|丹东市'
      },
      {
        label: '中国工商银行股份有限公司丹东振八支行',
        value: '102226000449|2106|辽宁省|丹东市'
      },
      {
        label: '中国工商银行股份有限公司丹东锦绣支行',
        value: '102226000465|2106|辽宁省|丹东市'
      },
      {
        label: '中国工商银行股份有限公司丹东青年大街支行',
        value: '102226000473|2106|辽宁省|丹东市'
      },
      {
        label: '中国工商银行丹东分行业务处理中心',
        value: '102226000703|2106|辽宁省|丹东市'
      },
      {
        label: '中国工商银行丹东凤城支行',
        value: '102226100095|2106|辽宁省|丹东市'
      },
      {
        label: '中国工商银行股份有限公司凤城花园支行',
        value: '102226100628|2106|辽宁省|丹东市'
      },
      {
        label: '中国工商银行股份有限公司凤城凤凰支行',
        value: '102226100644|2106|辽宁省|丹东市'
      },
      {
        label: '中国工商银行股份有限公司凤城龙源城支行',
        value: '102226100652|2106|辽宁省|丹东市'
      },
      {
        label: '中国工商银行大石桥市支行',
        value: '102226200199|2106|辽宁省|丹东市'
      },
      {
        label: '中国工商银行大石桥市南楼分理处',
        value: '102226200391|2106|辽宁省|丹东市'
      },
      {
        label: '中国工商银行股份有限公司大石桥宏兴支行',
        value: '102226200527|2106|辽宁省|丹东市'
      },
      {
        label: '中国工商银行股份有限公司大石桥长征支行',
        value: '102226200543|2106|辽宁省|丹东市'
      },
      {
        label: '中国工商银行股份有限公司大石桥五一支行',
        value: '102226200551|2106|辽宁省|丹东市'
      },
      {
        label: '中国工商银行大石桥市中街分理处',
        value: '102226200594|2106|辽宁省|丹东市'
      },
      {
        label: '中国工商银行丹东东港支行',
        value: '102226300089|2106|辽宁省|丹东市'
      },
      {
        label: '中国工商银行股份有限公司东港前阳支行',
        value: '102226301188|2106|辽宁省|丹东市'
      },
      {
        label: '中国工商银行沈阳兴工街支行',
        value: '102221002074|2101|辽宁省|沈阳市'
      },
      {
        label: '中国工商银行沈阳水产支行',
        value: '102221002111|2101|辽宁省|沈阳市'
      },
      {
        label: '中国工商银行沈阳凌空支行',
        value: '102221002162|2101|辽宁省|沈阳市'
      },
      {
        label: '中国工商银行沈阳工人村支行',
        value: '102221002218|2101|辽宁省|沈阳市'
      },
      {
        label: '中国工商银行沈阳肇工支行',
        value: '102221002267|2101|辽宁省|沈阳市'
      },
      {
        label: '中国工商银行沈阳沈新路支行',
        value: '102221002291|2101|辽宁省|沈阳市'
      },
      {
        label: '中国工商银行沈阳虹桥路支行',
        value: '102221002525|2101|辽宁省|沈阳市'
      },
      {
        label: '中国工商银行沈阳熊家岗路支行',
        value: '102221002592|2101|辽宁省|沈阳市'
      },
      {
        label: '中国工商银行沈阳十马路支行',
        value: '102221002816|2101|辽宁省|沈阳市'
      },
      {
        label: '中国工商银行沈阳兴建支行',
        value: '102221002929|2101|辽宁省|沈阳市'
      },
      {
        label: '中国工商银行苏州市木渎支行',
        value: '102305002633|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行苏州浒墅关支行',
        value: '102305002641|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行股份有限公司长治常村支行',
        value: '102166300095|1404|山西省|长治市'
      },
      {
        label: '中国工商银行股份有限公司襄垣支行',
        value: '102166302324|1404|山西省|长治市'
      },
      {
        label: '中国工商银行股份有限公司长治潞矿支行',
        value: '102166302349|1404|山西省|长治市'
      },
      {
        label: '中国工商银行股份有限公司襄垣五阳矿支行',
        value: '102166324304|1404|山西省|长治市'
      },
      {
        label: '中国工商银行股份有限公司屯留支行',
        value: '102166401929|1404|山西省|长治市'
      },
      {
        label: '中国工商银行股份有限公司黎城支行',
        value: '102166602420|1404|山西省|长治市'
      },
      {
        label: '中国工商银行股份有限公司壶关支行',
        value: '102166702385|1404|山西省|长治市'
      },
      {
        label: '中国工商银行股份有限公司长子支行',
        value: '102166802276|1404|山西省|长治市'
      },
      {
        label: '中国工商银行股份有限公司武乡支行',
        value: '102166903656|1404|山西省|长治市'
      },
      {
        label: '中国工商银行股份有限公司沁源支行',
        value: '102167202228|1404|山西省|长治市'
      },
      {
        label: '工行晋城市广场支行',
        value: '102168001233|1405|山西省|晋城市'
      },
      {
        label: '中国工商银行股份有限公司北镇沟帮子支行',
        value: '102227300023|2107|辽宁省|锦州市'
      },
      {
        label: '中国工商银行股份有限公司北镇什字街支行',
        value: '102227300031|2107|辽宁省|锦州市'
      },
      {
        label: '中国工商银行股份有限公司黑山支行',
        value: '102227400016|2107|辽宁省|锦州市'
      },
      {
        label: '中国工商银行股份有限公司黑山大虎山支行',
        value: '102227400024|2107|辽宁省|锦州市'
      },
      {
        label: '中国工商银行股份有限公司黑山南广场支行',
        value: '102227400032|2107|辽宁省|锦州市'
      },
      {
        label: '中国工商银行股份有限公司黑山新立屯支行',
        value: '102227400073|2107|辽宁省|锦州市'
      },
      {
        label: '中国工商银行股份有限公司黑山八道壕支行',
        value: '102227400081|2107|辽宁省|锦州市'
      },
      {
        label: '中国工商银行股份有限公司义县支行',
        value: '102227500017|2107|辽宁省|锦州市'
      },
      {
        label: '中国工商银行葫芦岛市分行业务处理中心',
        value: '102227600018|2107|辽宁省|锦州市'
      },
      {
        label: '中国工商银行葫芦岛市连山支行',
        value: '102227600026|2107|辽宁省|锦州市'
      },
      {
        label: '中国工商银行葫芦岛经济开发区滨海支行',
        value: '102227600034|2107|辽宁省|锦州市'
      },
      {
        label: '中国工商银行葫芦岛市龙港支行',
        value: '102227600042|2107|辽宁省|锦州市'
      },
      {
        label: '中国工商银行葫芦岛市南票支行',
        value: '102227600059|2107|辽宁省|锦州市'
      },
      {
        label: '中国工商银行葫芦岛龙湾大街支行',
        value: '102227600067|2107|辽宁省|锦州市'
      },
      {
        label: '中国工商银行葫芦岛市分行新华支行',
        value: '102227600075|2107|辽宁省|锦州市'
      },
      {
        label: '中国工商银行葫芦岛市分行兴工支行',
        value: '102227600083|2107|辽宁省|锦州市'
      },
      {
        label: '中国工商银行葫芦岛新华大街支行',
        value: '102227600091|2107|辽宁省|锦州市'
      },
      {
        label: '中国工商银行葫芦岛化工支行',
        value: '102227600114|2107|辽宁省|锦州市'
      },
      {
        label: '中国工商银行葫芦岛东门支行',
        value: '102227600122|2107|辽宁省|锦州市'
      },
      {
        label: '中国工商银行葫芦岛西山支行',
        value: '102227600139|2107|辽宁省|锦州市'
      },
      {
        label: '中国工商银行葫芦岛望海寺支行',
        value: '102227600147|2107|辽宁省|锦州市'
      },
      {
        label: '中国工商银行葫芦岛滨城支行',
        value: '102227600155|2107|辽宁省|锦州市'
      },
      {
        label: '中国工商银行葫芦岛道口支行',
        value: '102227600219|2107|辽宁省|锦州市'
      },
      {
        label: '中国工商银行葫芦岛分行毛祁屯支行',
        value: '102227600235|2107|辽宁省|锦州市'
      },
      {
        label: '中国工商银行股份有限公司葫芦岛龙程路支行',
        value: '102227600243|2107|辽宁省|锦州市'
      },
      {
        label: '中国工商银行股份有限公司葫芦岛石油支行',
        value: '102227600286|2107|辽宁省|锦州市'
      },
      {
        label: '中国工商银行葫芦岛马仗房支行',
        value: '102227600294|2107|辽宁省|锦州市'
      },
      {
        label: '中国工商银行股份有限公司葫芦岛永昌路支行',
        value: '102227600350|2107|辽宁省|锦州市'
      },
      {
        label: '中国工商银行股份有限公司葫芦岛东城支行',
        value: '102227600368|2107|辽宁省|锦州市'
      },
      {
        label: '中国工商银行兴城市支行',
        value: '102227700010|2107|辽宁省|锦州市'
      },
      {
        label: '中国工商银行包头市稀土高新技术开发区支行',
        value: '102192002589|1502|内蒙古自治区|包头市'
      },
      {
        label: '中国工商银行包头市东河区支行',
        value: '102192003209|1502|内蒙古自治区|包头市'
      },
      {
        label: '中国工商银行包头超越支行',
        value: '102192003225|1502|内蒙古自治区|包头市'
      },
      {
        label: '中国工商银行包头铝厂支行',
        value: '102192003233|1502|内蒙古自治区|包头市'
      },
      {
        label: '中国工商银行包头糖厂支行',
        value: '102192003241|1502|内蒙古自治区|包头市'
      },
      {
        label: '中国工商银行包头市九原区支行',
        value: '102192004201|1502|内蒙古自治区|包头市'
      },
      {
        label: '中国工商银行股份有限公司包头富丽佳园支行',
        value: '102192004236|1502|内蒙古自治区|包头市'
      },
      {
        label: '中国工商银行股份有限公司包头滨河支行',
        value: '102192006203|1502|内蒙古自治区|包头市'
      },
      {
        label: '中国工商银行包头呼得木林支行',
        value: '102192020358|1502|内蒙古自治区|包头市'
      },
      {
        label: '中国工商银行包头稀土大厦支行',
        value: '102192020559|1502|内蒙古自治区|包头市'
      },
      {
        label: '中国工商银行包头包百大楼支行',
        value: '102192020622|1502|内蒙古自治区|包头市'
      },
      {
        label: '中国工商银行股份有限公司包头东亚世纪城支行',
        value: '102192020647|1502|内蒙古自治区|包头市'
      },
      {
        label: '中国工商银行股份有限公司包头土默特右旗府东支行',
        value: '102192100022|1502|内蒙古自治区|包头市'
      },
      {
        label: '中国工商银行股份有限公司土默特右旗东门支行',
        value: '102192100047|1502|内蒙古自治区|包头市'
      },
      {
        label: '中国工商银行包头土默特右旗支行',
        value: '102192105202|1502|内蒙古自治区|包头市'
      },
      {
        label: '中国工商银行固阳支行',
        value: '102192207201|1502|内蒙古自治区|包头市'
      },
      {
        label: '中国工商银行固阳南街支行',
        value: '102192221601|1502|内蒙古自治区|包头市'
      },
      {
        label: '中国工商银行股份有限公司乌海和平东街支行',
        value: '102193000259|1503|内蒙古自治区|乌海市'
      },
      {
        label: '中国工商银行股份有限公司乌海海拉路支行',
        value: '102193000283|1503|内蒙古自治区|乌海市'
      },
      {
        label: '中国工商银行股份有限公司乌海食品中心支行',
        value: '102193000314|1503|内蒙古自治区|乌海市'
      },
      {
        label: '中国工商银行股份有限公司乌海滨河支行',
        value: '102193000355|1503|内蒙古自治区|乌海市'
      },
      {
        label: '中国工商银行股份有限公司锡林浩特团结大街支行',
        value: '102201005522|1525|内蒙古自治区|锡林郭勒盟'
      },
      {
        label: '中国工商银行股份有限公司锡林浩特会计业务处理中心',
        value: '102201006003|1525|内蒙古自治区|锡林郭勒盟'
      },
      {
        label: '中国工商银行苏州竹园路分理处',
        value: '102305018050|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行苏州滨河路支行',
        value: '102305018068|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行股份有限公司苏州新升支行',
        value: '102305018084|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行股份有限公司苏州科技城支行',
        value: '102305018105|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行苏州华山路支行',
        value: '102305018113|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行苏州华夏五金城支行',
        value: '102305018121|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行苏州唯亭东区支行',
        value: '102305021922|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行苏州方洲路支行',
        value: '102305021939|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行股份有限公司苏州东吴北路支行',
        value: '102305026050|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行苏州市黄桥分理处',
        value: '102305026068|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行苏州宝带路支行',
        value: '102305026076|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行苏州市官渎里分理处',
        value: '102305026092|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行昆山陆家支行',
        value: '102305223191|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行昆山北门支行',
        value: '102305223206|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行昆山张浦支行',
        value: '102305223214|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行昆山城西支行',
        value: '102305223222|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行昆山花桥支行',
        value: '102305223239|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行昆山周巷支行',
        value: '102305223247|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行威县支行',
        value: '102133508024|1305|河北省|邢台市'
      },
      {
        label: '中国工商银行清河支行',
        value: '102133608479|1305|河北省|邢台市'
      },
      {
        label: '中国工商银行股份有限公司邢台清河车站支行',
        value: '102133608487|1305|河北省|邢台市'
      },
      {
        label: '中国工商银行临西支行',
        value: '102133708537|1305|河北省|邢台市'
      },
      {
        label: '中国工商银行保定业务处理中心',
        value: '102134009514|1306|河北省|保定市'
      },
      {
        label: '中国工商银行保定古城支行',
        value: '102134009522|1306|河北省|保定市'
      },
      {
        label: '中国工商银行保定七一支行',
        value: '102134009539|1306|河北省|保定市'
      },
      {
        label: '中国工商银行保定广济支行',
        value: '102134009547|1306|河北省|保定市'
      },
      {
        label: '中国工商银行保定红星支行',
        value: '102134009555|1306|河北省|保定市'
      },
      {
        label: '中国工商银行保定新华支行',
        value: '102134009563|1306|河北省|保定市'
      },
      {
        label: '中国工商银行保定开发区支行',
        value: '102134009571|1306|河北省|保定市'
      },
      {
        label: '中国工商银行保定朝阳支行',
        value: '102134009580|1306|河北省|保定市'
      },
      {
        label: '中国工商银行保定东风支行',
        value: '102134009598|1306|河北省|保定市'
      },
      {
        label: '中国工商银行保定三丰支行',
        value: '102134009602|1306|河北省|保定市'
      },
      {
        label: '中国工商银行保定永华支行',
        value: '102134009619|1306|河北省|保定市'
      },
      {
        label: '中国工商银行保定长城支行',
        value: '102134009627|1306|河北省|保定市'
      },
      {
        label: '中国工商银行保定莲池支行',
        value: '102134009635|1306|河北省|保定市'
      },
      {
        label: '中国工商银行股份有限公司保定五四支行',
        value: '102134009830|1306|河北省|保定市'
      },
      {
        label: '中国工商银行股份有限公司保定阳光支行',
        value: '102134009944|1306|河北省|保定市'
      },
      {
        label: '中国工商银行股份有限公司保定融盛支行',
        value: '102134010017|1306|河北省|保定市'
      },
      {
        label: '中国工商银行股份有限公司保定风帆支行',
        value: '102134010025|1306|河北省|保定市'
      },
      {
        label: '中国工商银行股份有限公司保定天威支行',
        value: '102134010033|1306|河北省|保定市'
      },
      {
        label: '中国工商银行股份有限公司保定融兴支行',
        value: '102134010105|1306|河北省|保定市'
      },
      {
        label: '中国工商银行股份有限公司保定河北大学支行',
        value: '102134010121|1306|河北省|保定市'
      },
      {
        label: '工行河北省保定清苑支行',
        value: '102134010130|1306|河北省|保定市'
      },
      {
        label: '中国工商银行股份有限公司保定融达支行',
        value: '102134010148|1306|河北省|保定市'
      },
      {
        label: '中国工商银行股份有限公司保定天鹅支行',
        value: '102134010236|1306|河北省|保定市'
      },
      {
        label: '中国工商银行股份有限公司保定新市场支行',
        value: '102134010277|1306|河北省|保定市'
      },
      {
        label: '中国工商银行股份有限公司保定先锋支行',
        value: '102134010381|1306|河北省|保定市'
      },
      {
        label: '中国工商银行满城支行',
        value: '102134109647|1306|河北省|保定市'
      },
      {
        label: '中国工商银行定州支行',
        value: '102135109669|1306|河北省|保定市'
      },
      {
        label: '中国工商银行定州宝塔支行',
        value: '102135110534|1306|河北省|保定市'
      },
      {
        label: '中国工商银行股份有限公司保定定州清风支行',
        value: '102135110542|1306|河北省|保定市'
      },
      {
        label: '中国工商银行股份有限公司定州长安支行',
        value: '102135111391|1306|河北省|保定市'
      },
      {
        label: '中国工商银行涿州支行',
        value: '102135209676|1306|河北省|保定市'
      },
      {
        label: '中国工商银行易县支行',
        value: '102135309683|1306|河北省|保定市'
      },
      {
        label: '中国工商银行徐水支行',
        value: '102135409696|1306|河北省|保定市'
      },
      {
        label: '中国工商银行股份有限公司保定徐水城关支行',
        value: '102135410756|1306|河北省|保定市'
      },
      {
        label: '中国工商银行股份有限公司保定徐水物探支行',
        value: '102135410764|1306|河北省|保定市'
      },
      {
        label: '中国工商银行股份有限公司保定徐水振兴支行',
        value: '102135410789|1306|河北省|保定市'
      },
      {
        label: '中国工商银行股份有限公司徐水东兴支行',
        value: '102135410797|1306|河北省|保定市'
      },
      {
        label: '中国工商银行涞源支行',
        value: '102135509701|1306|河北省|保定市'
      },
      {
        label: '中国工商银行股份有限公司潍坊分行科技街分理处',
        value: '102458000440|3707|山东省|潍坊市'
      },
      {
        label: '中国工商银行股份有限公司南通张芝山支行',
        value: '102308442561|3206|江苏省|南通市'
      },
      {
        label: '中国工商银行股份有限公司开封汴东支行',
        value: '102492052026|4102|河南省|开封市'
      },
      {
        label: '中国工商银行股份有限公司开封大庆路支行',
        value: '102492052042|4102|河南省|开封市'
      },
      {
        label: '中国工商银行股份有限公司开封繁塔支行',
        value: '102492062013|4102|河南省|开封市'
      },
      {
        label: '中国工商银行股份有限公司开封劳动路支行',
        value: '102492062021|4102|河南省|开封市'
      },
      {
        label: '中国工商银行股份有限公司开封汉兴路支行',
        value: '102492062048|4102|河南省|开封市'
      },
      {
        label: '中国工商银行股份有限公司开封集英街支行',
        value: '102492072011|4102|河南省|开封市'
      },
      {
        label: '中国工商银行开封分行杞县支行',
        value: '102492102209|4102|河南省|开封市'
      },
      {
        label: '中国工商银行开封分行通许县支行',
        value: '102492202300|4102|河南省|开封市'
      },
      {
        label: '中国工商银行开封分行尉氏县支行',
        value: '102492302407|4102|河南省|开封市'
      },
      {
        label: '中国工商银行股份有限公司尉氏支行东大街分理处',
        value: '102492312409|4102|河南省|开封市'
      },
      {
        label: '中国工商银行股份有限公司尉氏滨河路支行',
        value: '102492322403|4102|河南省|开封市'
      },
      {
        label: '中国工商银行股份有限公司开封祥符支行',
        value: '102492402605|4102|河南省|开封市'
      },
      {
        label: '中国工商银行开封分行兰考县支行',
        value: '102492502104|4102|河南省|开封市'
      },
      {
        label: '中国工商银行股份有限公司洛阳关林支行',
        value: '102493000076|4103|河南省|洛阳市'
      },
      {
        label: '中国工商银行洛阳分行',
        value: '102493002012|4103|河南省|洛阳市'
      },
      {
        label: '中国工商银行股份有限公司洛阳华山支行',
        value: '102493002045|4103|河南省|洛阳市'
      },
      {
        label: '中国工商银行洛阳分行涧西支行',
        value: '102493002088|4103|河南省|洛阳市'
      },
      {
        label: '中国工商银行股份有限公司洛阳长春支行',
        value: '102493002123|4103|河南省|洛阳市'
      },
      {
        label: '中国工商银行股份有限公司焦作新区支行',
        value: '102501002069|4108|河南省|焦作市'
      },
      {
        label: '中国工商银行焦作分行解放路支行',
        value: '102501002108|4108|河南省|焦作市'
      },
      {
        label: '中国工商银行焦作分行民主路支行',
        value: '102501002204|4108|河南省|焦作市'
      },
      {
        label: '中国工商银行股份有限公司焦作民主南路支行',
        value: '102501002307|4108|河南省|焦作市'
      },
      {
        label: '中国工商银行股份有限公司焦作大学支行',
        value: '102501002403|4108|河南省|焦作市'
      },
      {
        label: '中国工商银行股份有限公司焦作马村支行',
        value: '102501002500|4108|河南省|焦作市'
      },
      {
        label: '中国工商银行焦作分行会计业务处理中心',
        value: '102501002680|4108|河南省|焦作市'
      },
      {
        label: '中国工商银行焦作分行中铝支行',
        value: '102501002809|4108|河南省|焦作市'
      },
      {
        label: '中国工商银行股份有限公司焦作环城东路支行',
        value: '102501002905|4108|河南省|焦作市'
      },
      {
        label: '中国工商银行股份有限公司焦作工业路支行',
        value: '102501012306|4108|河南省|焦作市'
      },
      {
        label: '中国工商银行股份有限公司焦作建设西路支行',
        value: '102501012605|4108|河南省|焦作市'
      },
      {
        label: '中国工商银行股份有限公司沁阳西向支行',
        value: '102501012701|4108|河南省|焦作市'
      },
      {
        label: '中国工商银行股份有限公司焦作人民路支行',
        value: '102501012904|4108|河南省|焦作市'
      },
      {
        label: '中国工商银行股份有限公司焦作广场支行',
        value: '102501022109|4108|河南省|焦作市'
      },
      {
        label: '中国工商银行股份有限公司焦作东二环路支行',
        value: '102501022906|4108|河南省|焦作市'
      },
      {
        label: '中国工商银行股份有限公司焦作人民广场支行',
        value: '102501042305|4108|河南省|焦作市'
      },
      {
        label: '中国工商银行股份有限公司焦作朝阳路支行',
        value: '102501042900|4108|河南省|焦作市'
      },
      {
        label: '中国工商银行股份有限公司焦作新华街支行',
        value: '102501062203|4108|河南省|焦作市'
      },
      {
        label: '中国工商银行股份有限公司焦作太行西路支行',
        value: '102501072202|4108|河南省|焦作市'
      },
      {
        label: '中国工商银行股份有限公司焦作山阳支行',
        value: '102501072309|4108|河南省|焦作市'
      },
      {
        label: '中国工商银行股份有限公焦作轮胎支行',
        value: '102501082101|4108|河南省|焦作市'
      },
      {
        label: '中国工商银行股份有限公司焦作中站支行',
        value: '102501082208|4108|河南省|焦作市'
      },
      {
        label: '中国工商银行股份有限公司修武支行',
        value: '102501102289|4108|河南省|焦作市'
      },
      {
        label: '中国工商银行舟山六横支行',
        value: '102342002218|3309|浙江省|舟山市'
      },
      {
        label: '中国工商银行股份有限公司舟山城北支行',
        value: '102342002234|3309|浙江省|舟山市'
      },
      {
        label: '中国工商银行舟山新城支行',
        value: '102342002242|3309|浙江省|舟山市'
      },
      {
        label: '中国工商银行舟山城西支行',
        value: '102342002267|3309|浙江省|舟山市'
      },
      {
        label: '工行浙江省舟山分行新城时代分理处',
        value: '102342002275|3309|浙江省|舟山市'
      },
      {
        label: '中国工商银行股份有限公司舟山竹山门支行',
        value: '102342002283|3309|浙江省|舟山市'
      },
      {
        label: '中国工商银行股份有限公司舟山分行香园分理处',
        value: '102342002291|3309|浙江省|舟山市'
      },
      {
        label: '中国工商银行股份有限公司舟山分行檀枫分理处',
        value: '102342002306|3309|浙江省|舟山市'
      },
      {
        label: '中国工商银行舟山分行金塘分理处',
        value: '102342002314|3309|浙江省|舟山市'
      },
      {
        label: '中国工商银行股份有限公司舟山分行港城分理处',
        value: '102342002322|3309|浙江省|舟山市'
      },
      {
        label: '中国工商银行股份有限公司舟山环城南路支行',
        value: '102342002339|3309|浙江省|舟山市'
      },
      {
        label: '中国工商银行舟山朱家尖支行',
        value: '102342002363|3309|浙江省|舟山市'
      },
      {
        label: '中国工商银行舟山白泉支行',
        value: '102342002371|3309|浙江省|舟山市'
      },
      {
        label: '中国工商银行舟山分行岱山支行衢山分理处',
        value: '102342100310|3309|浙江省|舟山市'
      },
      {
        label: '中国工商银行舟山市岱山县支行',
        value: '102342101013|3309|浙江省|舟山市'
      },
      {
        label: '中国工商银行肥东撮镇分理处',
        value: '102361002545|3401|安徽省|合肥市'
      },
      {
        label: '中国工商银行股份有限公司肥东龙岗支行',
        value: '102361002553|3401|安徽省|合肥市'
      },
      {
        label: '中国工商银行新民市支行',
        value: '102221000028|2101|辽宁省|沈阳市'
      },
      {
        label: '中国工商银行沈阳市辽中县支行',
        value: '102221000036|2101|辽宁省|沈阳市'
      },
      {
        label: '中国工商银行沈阳市苏家屯支行',
        value: '102221000044|2101|辽宁省|沈阳市'
      },
      {
        label: '中国工商银行苏州市相城支行',
        value: '102305002650|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行苏州东山支行',
        value: '102305002668|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行苏州光福支行',
        value: '102305002676|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行苏州望亭支行',
        value: '102305002684|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行苏州角直支行',
        value: '102305002692|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行股份有限公司苏州玲珑湾支行',
        value: '102305009516|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行股份有限公司苏州通安支行',
        value: '102305009557|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行苏州景德路分理处',
        value: '102305011040|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行苏州供电支行',
        value: '102305011066|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行苏州苏站支行',
        value: '102305011074|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行苏州竹辉路分理处',
        value: '102305012026|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行苏州友谊分理处',
        value: '102305012034|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行苏州通园分理处',
        value: '102305012059|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行股份有限公司苏州翠园分理处',
        value: '102305012075|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行股份有限公司苏州盘门分理处',
        value: '102305012091|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行股份有限公司苏州胥江路分理处',
        value: '102305012139|3205|江苏省|苏州市'
      },
      {
        label: '工商银行苏州馨都广场分理处',
        value: '102305013019|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行股份有限公司苏州东方花园支行',
        value: '102305013027|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行股份有限公司吉林市双吉支行',
        value: '102242000272|2202|吉林省|吉林市'
      },
      {
        label: '中国工商银行股份有限公司吉林市锦绣支行',
        value: '102242000297|2202|吉林省|吉林市'
      },
      {
        label: '中国工商银行吉林市吉林经济技术开发区支行',
        value: '102242000310|2202|吉林省|吉林市'
      },
      {
        label: '中国工商银行吉林市分行临江门支行',
        value: '102242000328|2202|吉林省|吉林市'
      },
      {
        label: '中国工商银行股份有限公司吉林市长春路支行',
        value: '102242000336|2202|吉林省|吉林市'
      },
      {
        label: '中国工商银行股份有限公司吉林市丰满支行',
        value: '102242000344|2202|吉林省|吉林市'
      },
      {
        label: '中国工商银行吉林市分行炼油厂分理处',
        value: '102242000449|2202|吉林省|吉林市'
      },
      {
        label: '中国工商银行股份有限公司吉林市湘潭支行',
        value: '102242000457|2202|吉林省|吉林市'
      },
      {
        label: '中国工商银行股份有限公司吉林市铁东支行',
        value: '102242000465|2202|吉林省|吉林市'
      },
      {
        label: '中国工商银行股份有限公司吉林市吉林大街支行',
        value: '102242000537|2202|吉林省|吉林市'
      },
      {
        label: '中国工商银行股份有限公司吉林市驻吉铁支行',
        value: '102242000545|2202|吉林省|吉林市'
      },
      {
        label: '中国工商银行福州东街口支行',
        value: '102391052492|3501|福建省|福州市'
      },
      {
        label: '中国工商银行股份有限公司福州公园道支行',
        value: '102391052505|3501|福建省|福州市'
      },
      {
        label: '中国工商银行伊春市分行翠峦支行',
        value: '102271002076|2307|黑龙江省|伊春市'
      },
      {
        label: '中国工商银行伊春市分行红星支行',
        value: '102271002084|2307|黑龙江省|伊春市'
      },
      {
        label: '中国工商银行伊春市分行友好支行',
        value: '102271002092|2307|黑龙江省|伊春市'
      },
      {
        label: '中国工商银行伊春市分行新青支行',
        value: '102271002105|2307|黑龙江省|伊春市'
      },
      {
        label: '中国工商银行伊春市分行美溪支行',
        value: '102271002113|2307|黑龙江省|伊春市'
      },
      {
        label: '中国工商银行伊春市分行汤旺河支行',
        value: '102271002197|2307|黑龙江省|伊春市'
      },
      {
        label: '中国工商银行伊春市分行金山屯支行',
        value: '102271002201|2307|黑龙江省|伊春市'
      },
      {
        label: '中国工商银行伊春市分行汇鑫支行',
        value: '102271002252|2307|黑龙江省|伊春市'
      },
      {
        label: '中国工商银行股份有限公司常州雪堰支行',
        value: '102304002352|3204|江苏省|常州市'
      },
      {
        label: '中国工商银行股份有限公司常州前黄支行',
        value: '102304002369|3204|江苏省|常州市'
      },
      {
        label: '中国工商银行股份有限公司常州牛塘支行',
        value: '102304002377|3204|江苏省|常州市'
      },
      {
        label: '中国工商银行股份有限公司常州横山支行',
        value: '102304002385|3204|江苏省|常州市'
      },
      {
        label: '中国工商银行常州洛阳支行',
        value: '102304002393|3204|江苏省|常州市'
      },
      {
        label: '中国工商银行股份有限公司常州鸣凰支行',
        value: '102304002408|3204|江苏省|常州市'
      },
      {
        label: '中国工商银行阜宁城东支行',
        value: '102311367030|3209|江苏省|盐城市'
      },
      {
        label: '工商银行阜宁县城中支行',
        value: '102311367048|3209|江苏省|盐城市'
      },
      {
        label: '中国工商银行南通通州支行',
        value: '102308442570|3206|江苏省|南通市'
      },
      {
        label: '中国工商银行股份有限公司南通兴仁支行',
        value: '102308442588|3206|江苏省|南通市'
      },
      {
        label: '中国工商银行股份有限公司南通刘桥支行',
        value: '102308442615|3206|江苏省|南通市'
      },
      {
        label: '中国工商银行股份有限公司南通通州人民路支行',
        value: '102308445020|3206|江苏省|南通市'
      },
      {
        label: '中国工商银行股份有限公司南通虹桥支行',
        value: '102308445046|3206|江苏省|南通市'
      },
      {
        label: '中国工商银行股份有限公司南通世纪大道支行',
        value: '102308445062|3206|江苏省|南通市'
      },
      {
        label: '中国工商银行股份有限公司南通通州开发区支行',
        value: '102308445079|3206|江苏省|南通市'
      },
      {
        label: '中国工商银行涟水县支行',
        value: '102308506016|3208|江苏省|淮安市'
      },
      {
        label: '中国工商银行涟水安东支行',
        value: '102308506024|3208|江苏省|淮安市'
      },
      {
        label: '中国工商银行安丘市支行',
        value: '102458100014|3707|山东省|潍坊市'
      },
      {
        label: '中国工商银行安丘市支行北关分理处',
        value: '102458100022|3707|山东省|潍坊市'
      },
      {
        label: '中国工商银行股份有限公司安丘支行汶北分理处',
        value: '102458100039|3707|山东省|潍坊市'
      },
      {
        label: '中国工商银行寿光市支行',
        value: '102458200015|3707|山东省|潍坊市'
      },
      {
        label: '中国工商银行寿光海化支行',
        value: '102458200023|3707|山东省|潍坊市'
      },
      {
        label: '中国工商银行寿光支行羊口分理处',
        value: '102458200031|3707|山东省|潍坊市'
      },
      {
        label: '中国工商银行寿光支行城南分理处',
        value: '102458200040|3707|山东省|潍坊市'
      },
      {
        label: '中国工商银行寿光支行城里分理处',
        value: '102458200058|3707|山东省|潍坊市'
      },
      {
        label: '中国工商银行寿光支行东环分理处',
        value: '102458200066|3707|山东省|潍坊市'
      },
      {
        label: '中国工商银行银行股份有限公司寿光支行城头分理处',
        value: '102458200074|3707|山东省|潍坊市'
      },
      {
        label: '中国工商银行股份有限公司寿光支行农产品物流园分理处',
        value: '102458200082|3707|山东省|潍坊市'
      },
      {
        label: '中国工商银行股份公司寿光支行九巷菜市场分理处',
        value: '102458200103|3707|山东省|潍坊市'
      },
      {
        label: '中国工商银行温州市城东支行东都分理处',
        value: '102333000448|3303|浙江省|温州市'
      },
      {
        label: '中国工商银行股份有限公司温州鹿城支行车站大道分理处',
        value: '102333000497|3303|浙江省|温州市'
      },
      {
        label: '中国工商银行股份有限公司温州城东支行五马街分理处',
        value: '102333000501|3303|浙江省|温州市'
      },
      {
        label: '中国工商银行股份有限公司温州万盛支行',
        value: '102333000552|3303|浙江省|温州市'
      },
      {
        label: '中国工商银行温州经济技术开发区支行教育新村分理处',
        value: '102333000681|3303|浙江省|温州市'
      },
      {
        label: '中国工商银行股份有限公司温州永兴支行',
        value: '102333000729|3303|浙江省|温州市'
      },
      {
        label: '中国工商银行温州鹿城支行康迪锦园分理处',
        value: '102333000753|3303|浙江省|温州市'
      },
      {
        label: '中国工商银行股份有限公司温州鹿城支行王子分理处',
        value: '102333000796|3303|浙江省|温州市'
      },
      {
        label: '中国工商银行股份有限公司温州城西支行黄龙分理处',
        value: '102333000807|3303|浙江省|温州市'
      },
      {
        label: '中国工商银行股份有限公司温州鹿城支行天雄分理处',
        value: '102333000815|3303|浙江省|温州市'
      },
      {
        label: '中国工商银行温州市开发区支行新城分理处',
        value: '102333000823|3303|浙江省|温州市'
      },
      {
        label: '中国工商银行股份有限公司温州三溪支行',
        value: '102333000840|3303|浙江省|温州市'
      },
      {
        label: '中国工商银行股份有限公司温州丽岙支行',
        value: '102333000874|3303|浙江省|温州市'
      },
      {
        label: '中国工商银行股份有限公司温州娄桥支行',
        value: '102333000882|3303|浙江省|温州市'
      },
      {
        label: '中国工商银行温州市分行营业部',
        value: '102333020209|3303|浙江省|温州市'
      },
      {
        label: '中国工商银行股份有限公司温州市中支行',
        value: '102333020305|3303|浙江省|温州市'
      },
      {
        label: '中国工商银行股份有限公司温州鹿城支行三板桥分理处',
        value: '102333020313|3303|浙江省|温州市'
      },
      {
        label: '中国工商银行温州经济技术开发区支行',
        value: '102333020401|3303|浙江省|温州市'
      },
      {
        label: '中国工商银行股份有限公司温州滨海支行',
        value: '102333020410|3303|浙江省|温州市'
      },
      {
        label: '中国工商银行温州市马鞍池支行',
        value: '102333020604|3303|浙江省|温州市'
      },
      {
        label: '中国工商银行温州市开发区支行鸿福分理处',
        value: '102333020612|3303|浙江省|温州市'
      },
      {
        label: '中国工商银行股份有限公司温州鹿城支行',
        value: '102333020707|3303|浙江省|温州市'
      },
      {
        label: '中国工商银行股份有限公司温州鹿城支行二营分理处',
        value: '102333020731|3303|浙江省|温州市'
      },
      {
        label: '中国工商银行温州经济技术开发区支行汤家桥分理处',
        value: '102333020740|3303|浙江省|温州市'
      },
      {
        label: '中国工商银行股份有限公司温州双屿支行',
        value: '102333020811|3303|浙江省|温州市'
      },
      {
        label: '中国工商银行股份有限公司温州城西支行西站分理处',
        value: '102333020820|3303|浙江省|温州市'
      },
      {
        label: '中国工商银行龙海市支行',
        value: '102399133005|3506|福建省|漳州市'
      },
      {
        label: '中国工商银行股份有限公司龙海支行解东储蓄所',
        value: '102399133013|3506|福建省|漳州市'
      },
      {
        label: '中国工商银行漳州九湖支行',
        value: '102399133048|3506|福建省|漳州市'
      },
      {
        label: '中国工商银行龙海支行城东分理处',
        value: '102399133072|3506|福建省|漳州市'
      },
      {
        label: '中国工商银行漳州角美支行',
        value: '102399133097|3506|福建省|漳州市'
      },
      {
        label: '中国工商银行漳州龙池开发区支行',
        value: '102399133101|3506|福建省|漳州市'
      },
      {
        label: '中国工商银行龙海支行海澄分理处',
        value: '102399133136|3506|福建省|漳州市'
      },
      {
        label: '中国工商银行股份有限公司如皋丁埝支行',
        value: '102306200053|3206|江苏省|南通市'
      },
      {
        label: '中国工商银行股份有限公司如皋石庄支行',
        value: '102306200061|3206|江苏省|南通市'
      },
      {
        label: '中国工商银行沈阳市中兴分理处',
        value: '102221000069|2101|辽宁省|沈阳市'
      },
      {
        label: '中国工商银行沈阳沈北支行',
        value: '102221000077|2101|辽宁省|沈阳市'
      },
      {
        label: '中国工商银行沈阳虎石台支行',
        value: '102221000085|2101|辽宁省|沈阳市'
      },
      {
        label: '中国工商银行股份有限公司长春五站支行',
        value: '102241001077|2201|吉林省|长春市'
      },
      {
        label: '中国工商银行股份有限公司长春二区支行',
        value: '102241001093|2201|吉林省|长春市'
      },
      {
        label: '中国工商银行股份有限公司长春新华路支行',
        value: '102241001116|2201|吉林省|长春市'
      },
      {
        label: '中国工商银行股份有限公司长春一汽二十五街区支行',
        value: '102241001132|2201|吉林省|长春市'
      },
      {
        label: '中国工商银行股份有限公司长春中日联谊医院支行',
        value: '102241001149|2201|吉林省|长春市'
      },
      {
        label: '中国工商银行股份有限公司长春西环城路支行',
        value: '102241001157|2201|吉林省|长春市'
      },
      {
        label: '中国工商银行股份有限公司长春东环城路支行',
        value: '102241001165|2201|吉林省|长春市'
      },
      {
        label: '中国工商银行股份有限公司长春吉安路支行',
        value: '102241001173|2201|吉林省|长春市'
      },
      {
        label: '中国工商银行股份有限公司长春前进大街支行',
        value: '102241001204|2201|吉林省|长春市'
      },
      {
        label: '中国工商银行股份有限公司长春春城大街支行',
        value: '102241001229|2201|吉林省|长春市'
      },
      {
        label: '中国工商银行股份有限公司长春长沈路支行',
        value: '102241001237|2201|吉林省|长春市'
      },
      {
        label: '中国工商银行股份有限公司长春孟家支行',
        value: '102241001253|2201|吉林省|长春市'
      },
      {
        label: '中国工商银行股份有限公司长春三八支行',
        value: '102241001261|2201|吉林省|长春市'
      },
      {
        label: '中国工商银行长春新发广场支行',
        value: '102241001296|2201|吉林省|长春市'
      },
      {
        label: '中国工商银行股份有限公司长春中海支行',
        value: '102241001315|2201|吉林省|长春市'
      },
      {
        label: '中国工商银行股份有限公司长春机场支行',
        value: '102241001323|2201|吉林省|长春市'
      },
      {
        label: '中国工商银行股份有限公司长春东岭南街支行',
        value: '102241001331|2201|吉林省|长春市'
      },
      {
        label: '中国工商银行股份有限公司长春南湖大路支行',
        value: '102241001366|2201|吉林省|长春市'
      },
      {
        label: '中国工商银行股份有限公司德惠隆诚支行',
        value: '102241001526|2201|吉林省|长春市'
      },
      {
        label: '中国工商银行长春南部都市经济开发区支行',
        value: '102241001606|2201|吉林省|长春市'
      },
      {
        label: '中国工商银行股份有限公司长春宽府路支行',
        value: '102241001622|2201|吉林省|长春市'
      },
      {
        label: '中国工商银行股份有限公司榆树工农大街支行',
        value: '102241001639|2201|吉林省|长春市'
      },
      {
        label: '中国工商银行吉林市分行',
        value: '102242000019|2202|吉林省|吉林市'
      },
      {
        label: '中国工商银行股份有限公司吉林市昌邑支行',
        value: '102242000027|2202|吉林省|吉林市'
      },
      {
        label: '中国工商银行吉林市桃园支行',
        value: '102242000043|2202|吉林省|吉林市'
      },
      {
        label: '中国工商银行股份有限公司牡丹江宁安支行',
        value: '102275102201|2310|黑龙江省|牡丹江市'
      },
      {
        label: '中国工商银行股份有限公司宁安东京城支行',
        value: '102275102210|2310|黑龙江省|牡丹江市'
      },
      {
        label: '中国工商银行股份有限公司海林子荣街支行',
        value: '102275200439|2310|黑龙江省|牡丹江市'
      },
      {
        label: '中国工商银行股份有限公司海林林业局支行',
        value: '102275200447|2310|黑龙江省|牡丹江市'
      },
      {
        label: '中国工商银行股份有限公司牡丹江海林支行',
        value: '102275202309|2310|黑龙江省|牡丹江市'
      },
      {
        label: '中国工商银行股份有限公司海林长汀支行',
        value: '102275202911|2310|黑龙江省|牡丹江市'
      },
      {
        label: '中国工商银行股份有限公司牡丹江穆棱支行',
        value: '102275300013|2310|黑龙江省|牡丹江市'
      },
      {
        label: '中国工商银行股份有限公司穆棱森融支行',
        value: '102275300064|2310|黑龙江省|牡丹江市'
      },
      {
        label: '中国工商银行股份有限公司牡丹江东宁支行',
        value: '102275403797|2310|黑龙江省|牡丹江市'
      },
      {
        label: '中国工商银行股份有限公司东宁远东支行',
        value: '102275404355|2310|黑龙江省|牡丹江市'
      },
      {
        label: '中国工商银行股份有限公司牡丹江林口支行',
        value: '102275500015|2310|黑龙江省|牡丹江市'
      },
      {
        label: '中国工商银行股份有限公司林口古城支行',
        value: '102275500509|2310|黑龙江省|牡丹江市'
      },
      {
        label: '中国工商银行鸡西分行虎林市支行',
        value: '102275602707|2303|黑龙江省|鸡西市'
      },
      {
        label: '中国工商银行鸡西分行虎林市支行东方红办事处',
        value: '102275602715|2303|黑龙江省|鸡西市'
      },
      {
        label: '中国工商银行鸡西分行虎林市支行迎春办事处',
        value: '102275602723|2303|黑龙江省|鸡西市'
      },
      {
        label: '中国工商银行股份有限公司绥芬河山城路支行',
        value: '102275700687|2310|黑龙江省|牡丹江市'
      },
      {
        label: '中国工商银行股份有限公司牡丹江绥芬河支行',
        value: '102275702908|2310|黑龙江省|牡丹江市'
      },
      {
        label: '中国工商银行股份有限公司常州清潭新村支行',
        value: '102304004848|3204|江苏省|常州市'
      },
      {
        label: '中国工商银行股份有限公司常州紫荆东苑支行',
        value: '102304004872|3204|江苏省|常州市'
      },
      {
        label: '中国工商银行股份有限公司常州工人新村支行',
        value: '102304004889|3204|江苏省|常州市'
      },
      {
        label: '中国工商银行股份有限公司常州玉隆支行',
        value: '102304004897|3204|江苏省|常州市'
      },
      {
        label: '中国工商银行股份有限公司常州新闸支行',
        value: '102304004910|3204|江苏省|常州市'
      },
      {
        label: '中国工商银行股份有限公司常州天安支行',
        value: '102304004928|3204|江苏省|常州市'
      },
      {
        label: '中国工商银行股份有限公司常州香梅支行',
        value: '102304004985|3204|江苏省|常州市'
      },
      {
        label: '中国工商银行股份有限公司常州戚机厂支行',
        value: '102304005020|3204|江苏省|常州市'
      },
      {
        label: '中国工商银行股份有限公司常州河苑路支行',
        value: '102304005079|3204|江苏省|常州市'
      },
      {
        label: '中国工商银行股份有限公司常州潞城支行',
        value: '102304005095|3204|江苏省|常州市'
      },
      {
        label: '中国工商银行股份有限公司常州五益新村支行',
        value: '102304005126|3204|江苏省|常州市'
      },
      {
        label: '中国工商银行股份有限公司常州勤德支行',
        value: '102304005263|3204|江苏省|常州市'
      },
      {
        label: '中国工商银行股份有限公司常州会馆浜支行',
        value: '102304005513|3204|江苏省|常州市'
      },
      {
        label: '中国工商银行股份有限公司常州嘉盛苑支行',
        value: '102304005521|3204|江苏省|常州市'
      },
      {
        label: '中国工商银行股份有限公司盘锦惠宾街支行',
        value: '102232000363|2111|辽宁省|盘锦市'
      },
      {
        label: '中国工商银行沈阳市常德支行',
        value: '102221000149|2101|辽宁省|沈阳市'
      },
      {
        label: '中国工商银行沈阳市新北支行',
        value: '102221000157|2101|辽宁省|沈阳市'
      },
      {
        label: '中国工商银行股份有限公司福州湖前支行',
        value: '102391052521|3501|福建省|福州市'
      },
      {
        label: '中国工商银行福州湖光支行',
        value: '102391052530|3501|福建省|福州市'
      },
      {
        label: '中国工商银行福州西城支行',
        value: '102391052548|3501|福建省|福州市'
      },
      {
        label: '中国工商银行股份有限公司福州湖东支行',
        value: '102391052556|3501|福建省|福州市'
      },
      {
        label: '中国工商银行股份有限公司莆田笏石支行',
        value: '102394012029|3503|福建省|莆田市'
      },
      {
        label: '中国工商银行莆田黄石支行',
        value: '102394012037|3503|福建省|莆田市'
      },
      {
        label: '中国工商银行莆田观桥支行',
        value: '102394012070|3503|福建省|莆田市'
      },
      {
        label: '中国工商银行莆田庙前支行',
        value: '102394012088|3503|福建省|莆田市'
      },
      {
        label: '中国工商银行莆田东岩山支行',
        value: '102394012096|3503|福建省|莆田市'
      },
      {
        label: '中国工商银行股份有限公司莆田新度支行',
        value: '102394012115|3503|福建省|莆田市'
      },
      {
        label: '中国工商银行股份有限公司莆田延寿支行',
        value: '102394012123|3503|福建省|莆田市'
      },
      {
        label: '中国工商银行莆田市涵江支行',
        value: '102394013007|3503|福建省|莆田市'
      },
      {
        label: '中国工商银行莆田鉴前支行',
        value: '102394013015|3503|福建省|莆田市'
      },
      {
        label: '中国工商银行莆田江口支行',
        value: '102394013023|3503|福建省|莆田市'
      },
      {
        label: '中国工商银行莆田石庭支行',
        value: '102394013031|3503|福建省|莆田市'
      },
      {
        label: '中国工商银行金华浙师大支行',
        value: '102338001315|3307|浙江省|金华市'
      },
      {
        label: '中国工商银行股份有限公司金华东关支行',
        value: '102338001323|3307|浙江省|金华市'
      },
      {
        label: '中国工商银行股份有限公司金华城东支行',
        value: '102338001331|3307|浙江省|金华市'
      },
      {
        label: '中国工商银行股份有限公司金华新南站支行',
        value: '102338001366|3307|浙江省|金华市'
      },
      {
        label: '中国工商银行股份有限公司金华李渔路支行',
        value: '102338001374|3307|浙江省|金华市'
      },
      {
        label: '中国工商银行股份有限公司金华北苑支行',
        value: '102338001382|3307|浙江省|金华市'
      },
      {
        label: '中国工商银行金华金三角支行',
        value: '102338001399|3307|浙江省|金华市'
      },
      {
        label: '中国工商银行金华婺城支行',
        value: '102338001500|3307|浙江省|金华市'
      },
      {
        label: '中国工商银行股份有限公司金华电力支行',
        value: '102338001518|3307|浙江省|金华市'
      },
      {
        label: '中国工商银行股份有限公司金华新农贸支行',
        value: '102338001526|3307|浙江省|金华市'
      },
      {
        label: '中国工商银行股份有限公司金华经济开发区支行',
        value: '102338001702|3307|浙江省|金华市'
      },
      {
        label: '中国工商银行股份有限公司金华商城支行',
        value: '102338001719|3307|浙江省|金华市'
      },
      {
        label: '中国工商银行股份有限公司金华市府支行',
        value: '102338001735|3307|浙江省|金华市'
      },
      {
        label: '中国工商银行股份有限公司金华新都会支行',
        value: '102338001751|3307|浙江省|金华市'
      },
      {
        label: '中国工商银行股份有限公司金华宾虹路支行',
        value: '102338001778|3307|浙江省|金华市'
      },
      {
        label: '中国工商银行股份有限公司金华金东支行',
        value: '102338001809|3307|浙江省|金华市'
      },
      {
        label: '中国工商银行金华金西支行',
        value: '102338001913|3307|浙江省|金华市'
      },
      {
        label: '中国工商银行股份有限公司金华白龙桥支行',
        value: '102338001921|3307|浙江省|金华市'
      },
      {
        label: '中国工商银行股份有限公司永康支行',
        value: '102338203000|3307|浙江省|金华市'
      },
      {
        label: '中国工商银行股份有限公司永康五金城支行',
        value: '102338203018|3307|浙江省|金华市'
      },
      {
        label: '中国工商银行股份有限公司永康芝英支行',
        value: '102338203026|3307|浙江省|金华市'
      },
      {
        label: '中国工商银行股份有限公司永康阳光支行',
        value: '102338203034|3307|浙江省|金华市'
      },
      {
        label: '中国工商银行股份有限公司永康江滨支行',
        value: '102338203042|3307|浙江省|金华市'
      },
      {
        label: '中国工商银行股份有限公司永康支行广电路分理处',
        value: '102338203059|3307|浙江省|金华市'
      },
      {
        label: '中国工商银行股份有限公司永康城西支行',
        value: '102338203067|3307|浙江省|金华市'
      },
      {
        label: '中国工商银行股份有限公司永康双铃支行',
        value: '102338203083|3307|浙江省|金华市'
      },
      {
        label: '中国工商银行股份有限公司永康望春支行',
        value: '102338203091|3307|浙江省|金华市'
      },
      {
        label: '中国工商银行股份有限公司永康明珠支行',
        value: '102338203106|3307|浙江省|金华市'
      },
      {
        label: '中国工商银行股份有限公司永康城南支行',
        value: '102338203114|3307|浙江省|金华市'
      },
      {
        label: '中国工商银行股份有限公司永康科技园支行',
        value: '102338203122|3307|浙江省|金华市'
      },
      {
        label: '中国工商银行股份有限公司永康古山支行',
        value: '102338203139|3307|浙江省|金华市'
      },
      {
        label: '中国工商银行股份有限公司永康花城支行',
        value: '102338203155|3307|浙江省|金华市'
      },
      {
        label: '中国工商银行武义县支行',
        value: '102338306001|3307|浙江省|金华市'
      },
      {
        label: '中国工商银行股份有限公司武义支行北岭分理处',
        value: '102338306010|3307|浙江省|金华市'
      },
      {
        label: '中国工商银行股份有限公司武义莹东支行',
        value: '102338306028|3307|浙江省|金华市'
      },
      {
        label: '中国工商银行股份有限公司武义支行城中分理处',
        value: '102338306036|3307|浙江省|金华市'
      },
      {
        label: '中国工商银行股份有限公司武义支行桐琴分理处',
        value: '102338306044|3307|浙江省|金华市'
      },
      {
        label: '中国工商银行股份有限公司浦江支行',
        value: '102338407002|3307|浙江省|金华市'
      },
      {
        label: '中国工商银行股份有限公司浦江江南支行',
        value: '102338407027|3307|浙江省|金华市'
      },
      {
        label: '中国工商银行股份有限公司浦江学塘角支行',
        value: '102338407035|3307|浙江省|金华市'
      },
      {
        label: '中国工商银行股份有限公司浦江广场支行',
        value: '102338407051|3307|浙江省|金华市'
      },
      {
        label: '中国工商银行股份有限公司台州镇中路支行',
        value: '102345021564|3310|浙江省|台州市'
      },
      {
        label: '中国工商银行股份有限公司台州中心菜场支行',
        value: '102345021589|3310|浙江省|台州市'
      },
      {
        label: '中国工商银行股份有限公司台州金清支行',
        value: '102345021610|3310|浙江省|台州市'
      },
      {
        label: '中国工商银行银行股份有限公司台州蓬街支行',
        value: '102345021628|3310|浙江省|台州市'
      },
      {
        label: '中国工商银行股份有限公司台州商业街支行',
        value: '102345021812|3310|浙江省|台州市'
      },
      {
        label: '中国工商银行台州市黄岩区支行北门分理处',
        value: '102345023121|3310|浙江省|台州市'
      },
      {
        label: '中国工商银行包头市包钢支行',
        value: '102192001303|1502|内蒙古自治区|包头市'
      },
      {
        label: '中国工商银行包头居然新城支行',
        value: '102192001320|1502|内蒙古自治区|包头市'
      },
      {
        label: '中国工商银行包头市钢铁大街支行',
        value: '102192001395|1502|内蒙古自治区|包头市'
      },
      {
        label: '工商银行射阳县支行',
        value: '102311463013|3209|江苏省|盐城市'
      },
      {
        label: '工商银行射阳城中支行',
        value: '102311463021|3209|江苏省|盐城市'
      },
      {
        label: '工商银行射阳县朝阳分理处',
        value: '102311463030|3209|江苏省|盐城市'
      },
      {
        label: '工商银行射阳县黄海桥支行',
        value: '102311463056|3209|江苏省|盐城市'
      },
      {
        label: '中国工商银行射阳幸福大道支行',
        value: '102311463064|3209|江苏省|盐城市'
      },
      {
        label: '工商银行建湖县支行',
        value: '102311561003|3209|江苏省|盐城市'
      },
      {
        label: '工商银行建湖建南支行',
        value: '102311561011|3209|江苏省|盐城市'
      },
      {
        label: '工商银行建湖县秀夫支行',
        value: '102311561020|3209|江苏省|盐城市'
      },
      {
        label: '工商银行建湖县建宝路支行',
        value: '102311561046|3209|江苏省|盐城市'
      },
      {
        label: '工商银行建湖上冈支行',
        value: '102311561054|3209|江苏省|盐城市'
      },
      {
        label: '工商银行建湖县建中桥支行',
        value: '102311561062|3209|江苏省|盐城市'
      },
      {
        label: '中国工商银行建湖建西支行',
        value: '102311561159|3209|江苏省|盐城市'
      },
      {
        label: '工商银行大丰市支行',
        value: '102311669010|3209|江苏省|盐城市'
      },
      {
        label: '工商银行大丰市城中支行',
        value: '102311669028|3209|江苏省|盐城市'
      },
      {
        label: '工商银行大丰健康路支行',
        value: '102311669036|3209|江苏省|盐城市'
      },
      {
        label: '中国工商银行大丰城西支行',
        value: '102311669069|3209|江苏省|盐城市'
      },
      {
        label: '中国工商银行大丰市城南分理处',
        value: '102311669077|3209|江苏省|盐城市'
      },
      {
        label: '中国工商银行大丰丰东支行',
        value: '102311669085|3209|江苏省|盐城市'
      },
      {
        label: '工商银行东台市新坝支行',
        value: '102311762011|3209|江苏省|盐城市'
      },
      {
        label: '工商银行东台市金东支行',
        value: '102311762020|3209|江苏省|盐城市'
      },
      {
        label: '中国工商银行东台金都支行',
        value: '102311762054|3209|江苏省|盐城市'
      },
      {
        label: '工商银行东台市金海支行',
        value: '102311762062|3209|江苏省|盐城市'
      },
      {
        label: '工商银行东台市金园支行',
        value: '102311762079|3209|江苏省|盐城市'
      },
      {
        label: '工商银行东台市支行',
        value: '102311762100|3209|江苏省|盐城市'
      },
      {
        label: '中国工商银行东台金辉支行',
        value: '102311762167|3209|江苏省|盐城市'
      },
      {
        label: '中国工商银行股份有限公司扬州分行营业部',
        value: '102312002002|3210|江苏省|扬州市'
      },
      {
        label: '中国工商银行股份有限公司扬州广陵支行',
        value: '102312002019|3210|江苏省|扬州市'
      },
      {
        label: '中国工商银行股份有限公司扬州琼花支行',
        value: '102312002027|3210|江苏省|扬州市'
      },
      {
        label: '中国工商银行股份有限公司扬州市汇金支行',
        value: '102312002035|3210|江苏省|扬州市'
      },
      {
        label: '中国工商银行股份有限公司扬州文津园支行',
        value: '102312002043|3210|江苏省|扬州市'
      },
      {
        label: '中国工商银行股份有限公司扬州东关支行',
        value: '102312002051|3210|江苏省|扬州市'
      },
      {
        label: '中国工商银行股份有限公司扬州雍华府支行',
        value: '102312002086|3210|江苏省|扬州市'
      },
      {
        label: '中国工商银行股份有限公司扬州汶河支行',
        value: '102312002094|3210|江苏省|扬州市'
      },
      {
        label: '中国工商银行股份有限公司扬州维扬支行',
        value: '102312002109|3210|江苏省|扬州市'
      },
      {
        label: '中国工商银行股份有限公司扬州沿江支行',
        value: '102312002125|3210|江苏省|扬州市'
      },
      {
        label: '中国工商银行股份有限公司扬州开发支行',
        value: '102312002133|3210|江苏省|扬州市'
      },
      {
        label: '中国工商银行股份有限公司本溪光明支行',
        value: '102225000050|2105|辽宁省|本溪市'
      },
      {
        label: '中国工商银行股份有限公司本溪兴北支行',
        value: '102225000076|2105|辽宁省|本溪市'
      },
      {
        label: '中国工商银行本溪财丰分理处',
        value: '102225000092|2105|辽宁省|本溪市'
      },
      {
        label: '中国工商银行本溪市分行平山支行北台分理处',
        value: '102225000113|2105|辽宁省|本溪市'
      },
      {
        label: '中国工商银行本溪解放路支行',
        value: '102225000130|2105|辽宁省|本溪市'
      },
      {
        label: '中国工商银行本溪南地支行',
        value: '102225000189|2105|辽宁省|本溪市'
      },
      {
        label: '中国工商银行本溪市分行本钢支行',
        value: '102225000201|2105|辽宁省|本溪市'
      },
      {
        label: '中国工商银行本溪前进支行',
        value: '102225000210|2105|辽宁省|本溪市'
      },
      {
        label: '中国工商银行股份有限公司本溪建工支行',
        value: '102225000236|2105|辽宁省|本溪市'
      },
      {
        label: '中国工商银行本溪英勇支行',
        value: '102225000244|2105|辽宁省|本溪市'
      },
      {
        label: '中国工商银行本溪永安支行',
        value: '102225000285|2105|辽宁省|本溪市'
      },
      {
        label: '中国工商银行兰溪站前支行',
        value: '102338605028|3307|浙江省|金华市'
      },
      {
        label: '中国工商银行股份有限公司兰溪支行云山分理处',
        value: '102338605036|3307|浙江省|金华市'
      },
      {
        label: '中国工商银行启东市城西分理处',
        value: '102306600129|3206|江苏省|南通市'
      },
      {
        label: '中国工商银行股份有限公司杭州清泰小微企业专营支行',
        value: '102331020371|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行股份有限公司池州殷汇支行',
        value: '102379000066|3417|安徽省|池州市'
      },
      {
        label: '中国工商银行股份有限公司池州站前支行',
        value: '102379000120|3417|安徽省|池州市'
      },
      {
        label: '中国工商银行股份有限公司池州浦西支行',
        value: '102379000146|3417|安徽省|池州市'
      },
      {
        label: '中国工商银行股份有限公司九华山景区支行',
        value: '102379000154|3417|安徽省|池州市'
      },
      {
        label: '中国工商银行股份有限公司池州梅龙支行',
        value: '102379000162|3417|安徽省|池州市'
      },
      {
        label: '中国工商银行股份有限公司池州百荷支行',
        value: '102379000179|3417|安徽省|池州市'
      },
      {
        label: '中国工商银行股份有限公司池州池阳支行',
        value: '102379000187|3417|安徽省|池州市'
      },
      {
        label: '中国工商银行股份有限公司东至支行',
        value: '102379200017|3417|安徽省|池州市'
      },
      {
        label: '中国工商银行股份有限公司东至东流支行',
        value: '102379200025|3417|安徽省|池州市'
      },
      {
        label: '中国工商银行股份有限公司池州大渡口支行',
        value: '102379200033|3417|安徽省|池州市'
      },
      {
        label: '中国工商银行股份有限公司石台支行',
        value: '102379300018|3417|安徽省|池州市'
      },
      {
        label: '中国工商银行股份有限公司青阳支行',
        value: '102379400010|3417|安徽省|池州市'
      },
      {
        label: '中国工商银行股份有限公司青阳蓉城分理处',
        value: '102379400028|3417|安徽省|池州市'
      },
      {
        label: '中国工商银行股份有限公司福建省分行(不对外办理业务）',
        value: '102391050013|3501|福建省|福州市'
      },
      {
        label: '中国工商银行福建省福州市五一支行',
        value: '102391051016|3501|福建省|福州市'
      },
      {
        label: '中国工商银行福建省福州市闽都支行',
        value: '102391051024|3501|福建省|福州市'
      },
      {
        label: '中国工商银行福建省福州市台江支行',
        value: '102391051032|3501|福建省|福州市'
      },
      {
        label: '中国工商银行福建省福州市鼓楼支行',
        value: '102391051057|3501|福建省|福州市'
      },
      {
        label: '中国工商银行福州吉祥支行',
        value: '102391051065|3501|福建省|福州市'
      },
      {
        label: '中国工商银行福州华林支行',
        value: '102391051073|3501|福建省|福州市'
      },
      {
        label: '中国工商银行福建省福州市南门支行',
        value: '102391051081|3501|福建省|福州市'
      },
      {
        label: '中国工商银行股份有限公司福州仓山支行',
        value: '102391051090|3501|福建省|福州市'
      },
      {
        label: '中国工商银行福建省福州市晋安支行',
        value: '102391051104|3501|福建省|福州市'
      },
      {
        label: '中国工商银行福州东大支行',
        value: '102391051112|3501|福建省|福州市'
      },
      {
        label: '中国工商银行股份有限公司厦门金融中心支行',
        value: '102393000253|3502|福建省|厦门市'
      },
      {
        label: '中国工商银行股份有限公司厦门三家村支行',
        value: '102393000270|3502|福建省|厦门市'
      },
      {
        label: '中国工商银行厦门中山支行',
        value: '102393000315|3502|福建省|厦门市'
      },
      {
        label: '中国工商银行厦门美仁宫支行',
        value: '102393000331|3502|福建省|厦门市'
      },
      {
        label: '中国工商银行股份有限公司厦门华侨支行',
        value: '102393000340|3502|福建省|厦门市'
      },
      {
        label: '中国工商银行股份有限公司厦门梧村支行',
        value: '102393000358|3502|福建省|厦门市'
      },
      {
        label: '中国工商银行厦门湖滨北支行',
        value: '102393000366|3502|福建省|厦门市'
      },
      {
        label: '中国工商银行股份有限公司厦门美禾支行',
        value: '102393000374|3502|福建省|厦门市'
      },
      {
        label: '中国工商银行股份有限公司厦门湖滨南支行',
        value: '102393000382|3502|福建省|厦门市'
      },
      {
        label: '中国工商银行股份有限公司厦门禾祥西支行',
        value: '102393000403|3502|福建省|厦门市'
      },
      {
        label: '中国工商银行股份有限公司厦门报业支行',
        value: '102393000411|3502|福建省|厦门市'
      },
      {
        label: '中国工商银行股份有限公司厦门电子城支行',
        value: '102393000487|3502|福建省|厦门市'
      },
      {
        label: '中国工商银行股份有限公司厦门文灶支行',
        value: '102393000518|3502|福建省|厦门市'
      },
      {
        label: '中国工商银行厦门金榜支行',
        value: '102393000526|3502|福建省|厦门市'
      },
      {
        label: '中国工商银行厦门湖里支行',
        value: '102393000534|3502|福建省|厦门市'
      },
      {
        label: '中国工商银行厦门东渡支行',
        value: '102393000542|3502|福建省|厦门市'
      },
      {
        label: '中国工商银行股份有限公司青岛李沧第二支行永清路分理处',
        value: '102452003125|3702|山东省|青岛市'
      },
      {
        label: '中国工商银行股份有限公司青岛李沧第二支行板桥坊分理处',
        value: '102452003133|3702|山东省|青岛市'
      },
      {
        label: '中国工商银行股份有限公司青岛李沧第二支行南渠分理处',
        value: '102452003150|3702|山东省|青岛市'
      },
      {
        label: '中国工商银行股份有限公司青岛高科园支行',
        value: '102452004013|3702|山东省|青岛市'
      },
      {
        label: '中国工商银行股份有限公司青岛高科园支行合肥路分理处',
        value: '102452004021|3702|山东省|青岛市'
      },
      {
        label: '中国工商银行股份有限公司青岛崂山路支行',
        value: '102452004030|3702|山东省|青岛市'
      },
      {
        label: '中国工商银行股份有限公司青岛宁夏路支行',
        value: '102452004048|3702|山东省|青岛市'
      },
      {
        label: '中国工商银行股份有限公司青岛开发区支行',
        value: '102452007014|3702|山东省|青岛市'
      },
      {
        label: '中国工商银行股份有限公司青岛开发区第二支行',
        value: '102452007022|3702|山东省|青岛市'
      },
      {
        label: '中国工商银行股份有限公司青岛开发区支行长江东路分理处',
        value: '102452007039|3702|山东省|青岛市'
      },
      {
        label: '中国工商银行股份有限公司青岛长江路支行',
        value: '102452007055|3702|山东省|青岛市'
      },
      {
        label: '中国工商银行股份有限公司青岛市城阳区支行',
        value: '102452105010|3702|山东省|青岛市'
      },
      {
        label: '中国工商银行青岛市城阳区支行青岛民航站分理处',
        value: '102452105028|3702|山东省|青岛市'
      },
      {
        label: '中国工商银行股份有限公司即墨支行',
        value: '102452106012|3702|山东省|青岛市'
      },
      {
        label: '中国工商银行股份有限公司即墨烟青路支行',
        value: '102452106037|3702|山东省|青岛市'
      },
      {
        label: '中国工商银行股份有限公司青岛黄岛支行',
        value: '102452208016|3702|山东省|青岛市'
      },
      {
        label: '中国工商银行股份有限公司青岛黄岛支行文化路分理处',
        value: '102452208024|3702|山东省|青岛市'
      },
      {
        label: '中国工商银行股份有限公司洛阳徐家营支行',
        value: '102493022152|4103|河南省|洛阳市'
      },
      {
        label: '中国工商银行股份有限公司洛阳铁路支行',
        value: '102493022265|4103|河南省|洛阳市'
      },
      {
        label: '中国工商银行股份有限公司洛阳龙门支行',
        value: '102493032298|4103|河南省|洛阳市'
      },
      {
        label: '中国工商银行股份有限公司洛阳春都路支行',
        value: '102493032409|4103|河南省|洛阳市'
      },
      {
        label: '中国工商银行股份有限公司洛阳华南支行',
        value: '102493042044|4103|河南省|洛阳市'
      },
      {
        label: '中国工商银行股份有限公司洛阳白马支行',
        value: '102493042403|4103|河南省|洛阳市'
      },
      {
        label: '中国工商银行股份有限公司南昌沿江支行',
        value: '102421000034|3601|江西省|南昌市'
      },
      {
        label: '中国工商银行股份有限公司南昌赣江支行',
        value: '102421000042|3601|江西省|南昌市'
      },
      {
        label: '中国工商银行股份有限公司南昌中山路支行',
        value: '102421000059|3601|江西省|南昌市'
      },
      {
        label: '中国工商银行股份有限公司南昌都司前支行',
        value: '102421000067|3601|江西省|南昌市'
      },
      {
        label: '中国工商银行股份有限公司南昌阳明路支行',
        value: '102421000075|3601|江西省|南昌市'
      },
      {
        label: '中国工商银行股份有限公司南昌站前路支行',
        value: '102421000083|3601|江西省|南昌市'
      },
      {
        label: '中国工商银行股份有限公司南昌北京西路支行',
        value: '102421000091|3601|江西省|南昌市'
      },
      {
        label: '中国工商银行股份有限公司南昌八一支行',
        value: '102421000106|3601|江西省|南昌市'
      },
      {
        label: '中国工商银行股份有限公司南昌省府大院支行',
        value: '102421000114|3601|江西省|南昌市'
      },
      {
        label: '中国工商银行股份有限公司鞍山海城镁矿支行',
        value: '102223209211|2103|辽宁省|鞍山市'
      },
      {
        label: '中国工商银行股份有限公司海城铁西支行',
        value: '102223209220|2103|辽宁省|鞍山市'
      },
      {
        label: '中国工商银行股份有限公司海城西柳支行',
        value: '102223209246|2103|辽宁省|鞍山市'
      },
      {
        label: '中国工商银行股份有限公司抚顺万新支行',
        value: '102224000031|2104|辽宁省|抚顺市'
      },
      {
        label: '中国工商银行股份有限公司抚顺平山支行',
        value: '102224000040|2104|辽宁省|抚顺市'
      },
      {
        label: '中国工商银行股份有限公司抚顺南阳支行',
        value: '102224000082|2104|辽宁省|抚顺市'
      },
      {
        label: '中国工商银行股份有限公司抚顺十一道街支行',
        value: '102224000103|2104|辽宁省|抚顺市'
      },
      {
        label: '中国工商银行股份有限公司抚顺千金支行',
        value: '102224000138|2104|辽宁省|抚顺市'
      },
      {
        label: '中国工商银行股份有限公司南昌苏圃支行',
        value: '102421000180|3601|江西省|南昌市'
      },
      {
        label: '中国工商银行股份有限公司南昌支行',
        value: '102421000198|3601|江西省|南昌市'
      },
      {
        label: '中国工商银行股份有限公司南昌向塘支行',
        value: '102421000202|3601|江西省|南昌市'
      },
      {
        label: '中国工商银行股份有限公司新建支行',
        value: '102421000219|3601|江西省|南昌市'
      },
      {
        label: '中国工商银行股份有限公司进贤支行',
        value: '102421000227|3601|江西省|南昌市'
      },
      {
        label: '中国工商银行股份有限公司安义支行',
        value: '102421000235|3601|江西省|南昌市'
      },
      {
        label: '中国工商银行股份有限公司寿光候镇支行',
        value: '102458200146|3707|山东省|潍坊市'
      },
      {
        label: '中国工商银行临朐县支行',
        value: '102458300016|3707|山东省|潍坊市'
      },
      {
        label: '中国工商银行股份有限公司临朐支行东城分理处',
        value: '102458300049|3707|山东省|潍坊市'
      },
      {
        label: '中国工商银行股份有限公司临朐文苑支行',
        value: '102458300065|3707|山东省|潍坊市'
      },
      {
        label: '中国工商银行昌乐县支行',
        value: '102458400017|3707|山东省|潍坊市'
      },
      {
        label: '中国工商银行股份有限公司昌乐支行孤山路分理处',
        value: '102458400025|3707|山东省|潍坊市'
      },
      {
        label: '中国工商银行股份有限公司昌乐支行文化路储蓄所',
        value: '102458400041|3707|山东省|潍坊市'
      },
      {
        label: '中国工商银行股份有限公司昌乐昌盛支行',
        value: '102458400068|3707|山东省|潍坊市'
      },
      {
        label: '中国工商银行昌邑市支行',
        value: '102458500018|3707|山东省|潍坊市'
      },
      {
        label: '中国工商银行股份有限公司昌邑柳疃支行',
        value: '102458500083|3707|山东省|潍坊市'
      },
      {
        label: '中国工商银行高密市支行',
        value: '102458600010|3707|山东省|潍坊市'
      },
      {
        label: '中国工商银行高密支行人民大街分理处',
        value: '102458600028|3707|山东省|潍坊市'
      },
      {
        label: '中国工商银行高密支行孚日家纺分理处',
        value: '102458600085|3707|山东省|潍坊市'
      },
      {
        label: '中国工商银行股份有限公司高密康成大街支行',
        value: '102458600093|3707|山东省|潍坊市'
      },
      {
        label: '中国工商银行股份有限公司高密盛世家华支行',
        value: '102458600108|3707|山东省|潍坊市'
      },
      {
        label: '中国工商银行五莲县支行工业分理处',
        value: '102458701064|3711|山东省|日照市'
      },
      {
        label: '中国工商银行日照市五莲县支行',
        value: '102458702049|3711|山东省|日照市'
      },
      {
        label: '中国工商银行青州市支行',
        value: '102458800011|3707|山东省|潍坊市'
      },
      {
        label: '中国工商银行青州支行西门分理处',
        value: '102458800038|3707|山东省|潍坊市'
      },
      {
        label: '中国工商银行股份有限公司青州支行城里分理处',
        value: '102458800054|3707|山东省|潍坊市'
      },
      {
        label: '中国工商银行股份有限公司青州支行东门分理处',
        value: '102458800062|3707|山东省|潍坊市'
      },
      {
        label: '中国工商银行股份有限公司青州支行尧王路分理处',
        value: '102458800079|3707|山东省|潍坊市'
      },
      {
        label: '中国工商银行股份有限公司青州支行范公亭东路分理处',
        value: '102458800087|3707|山东省|潍坊市'
      },
      {
        label: '中国工商银行青州支行火车站分理处',
        value: '102458800095|3707|山东省|潍坊市'
      },
      {
        label: '中国工商银行股份有限公司青州支行云门山路分理处',
        value: '102458800118|3707|山东省|潍坊市'
      },
      {
        label: '中国工商银行诸城市支行',
        value: '102458900012|3707|山东省|潍坊市'
      },
      {
        label: '中国工商银行股份有限公司诸城支行城北分理处',
        value: '102458900029|3707|山东省|潍坊市'
      },
      {
        label: '中国工商银行诸城支行兴华路分理处',
        value: '102458900037|3707|山东省|潍坊市'
      },
      {
        label: '中国工商银行股份有限公司诸城支行兴华路东分理处',
        value: '102458900045|3707|山东省|潍坊市'
      },
      {
        label: '中国工商银行股份有限公司诸城支行河西分理处',
        value: '102458900053|3707|山东省|潍坊市'
      },
      {
        label: '中国工商银行股份有限公司诸城龙都支行',
        value: '102458900096|3707|山东省|潍坊市'
      },
      {
        label: '中国工商银行济宁市城区支行',
        value: '102461000012|3708|山东省|济宁市'
      },
      {
        label: '中国工商银行济宁市任城支行',
        value: '102461000053|3708|山东省|济宁市'
      },
      {
        label: '中国工商银行济宁市分行',
        value: '102461000096|3708|山东省|济宁市'
      },
      {
        label: '中国工商银行济宁市分行营业部',
        value: '102461000140|3708|山东省|济宁市'
      },
      {
        label: '中国工商银行济宁市开发区支行',
        value: '102461000334|3708|山东省|济宁市'
      },
      {
        label: '中国工商银行盐城青年路支行',
        value: '102311066227|3209|江苏省|盐城市'
      },
      {
        label: '中国工商银行盐城南元支行',
        value: '102311066235|3209|江苏省|盐城市'
      },
      {
        label: '中国工商银行盐城城北支行',
        value: '102311066243|3209|江苏省|盐城市'
      },
      {
        label: '中国工商银行盐城市前进支行',
        value: '102311066260|3209|江苏省|盐城市'
      },
      {
        label: '中国工商银行盐城新洋支行',
        value: '102311066278|3209|江苏省|盐城市'
      },
      {
        label: '中国工商银行股份有限公司广州第一支行',
        value: '102581000013|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州第二支行',
        value: '102581000021|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州第三支行',
        value: '102581000030|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州花城支行',
        value: '102581000048|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州天平架支行',
        value: '102581000056|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州庙前直街支行',
        value: '102581000064|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州白云路支行',
        value: '102581000072|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州北京路支行',
        value: '102581000089|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州大德路支行',
        value: '102581000097|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州德政中路支行',
        value: '102581000101|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州流花支行',
        value: '102581000110|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州工业大道支行',
        value: '102581000128|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州同福中路支行',
        value: '102581000136|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州云山支行',
        value: '102581000144|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州荔湾支行',
        value: '102581000152|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州西华路支行',
        value: '102581000169|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州经济技术开发区支行',
        value: '102581000177|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州黄埔支行',
        value: '102581000185|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州广州大道支行',
        value: '102581000193|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州天河支行',
        value: '102581000208|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州高新技术开发区支行',
        value: '102581000216|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州环城支行',
        value: '102581000224|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州芳村支行',
        value: '102581000232|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司武冈东门支行',
        value: '102555601608|4305|湖南省|邵阳市'
      },
      {
        label: '中国工商银行股份有限公司武冈支行',
        value: '102555602035|4305|湖南省|邵阳市'
      },
      {
        label: '中国工商银行股份有限公司襄阳清河支行',
        value: '102528000889|4206|湖北省|襄阳市'
      },
      {
        label: '中国工商银行股份有限公司襄阳朗曼支行',
        value: '102528000897|4206|湖北省|襄阳市'
      },
      {
        label: '中国工商银行股份有限公司襄阳民发天地支行',
        value: '102528002731|4206|湖北省|襄阳市'
      },
      {
        label: '中国工商银行股份有限公司襄阳春园东路支行',
        value: '102528002854|4206|湖北省|襄阳市'
      },
      {
        label: '中国工商银行股份有限公司襄阳春园西路支行',
        value: '102528002862|4206|湖北省|襄阳市'
      },
      {
        label: '中国工商银行股份有限公司宜城支行',
        value: '102528200673|4206|湖北省|襄阳市'
      },
      {
        label: '中国工商银行股份有限公司宜城襄沙路支行',
        value: '102528200690|4206|湖北省|襄阳市'
      },
      {
        label: '中国工商银行股份有限公司宜城十字街支行',
        value: '102528200704|4206|湖北省|襄阳市'
      },
      {
        label: '中国工商银行股份有限公司宜城中华大道支行',
        value: '102528206734|4206|湖北省|襄阳市'
      },
      {
        label: '中国工商银行股份有限公司南漳支行',
        value: '102528300614|4206|湖北省|襄阳市'
      },
      {
        label: '中国工商银行股份有限公司南漳便河支行',
        value: '102528300622|4206|湖北省|襄阳市'
      },
      {
        label: '中国工商银行股份有限公司谷城支行',
        value: '102528400631|4206|湖北省|襄阳市'
      },
      {
        label: '中国工商银行股份有限公司谷城银城支行',
        value: '102528400640|4206|湖北省|襄阳市'
      },
      {
        label: '中国工商银行股份有限公司谷城光明支行',
        value: '102528400658|4206|湖北省|襄阳市'
      },
      {
        label: '中国工商银行股份有限公司谷城石花支行',
        value: '102528467259|4206|湖北省|襄阳市'
      },
      {
        label: '中国工商银行股份有限公司保康支行',
        value: '102528502767|4206|湖北省|襄阳市'
      },
      {
        label: '中国工商银行股份有限公司随州分行',
        value: '102528600012|4213|湖北省|随州市'
      },
      {
        label: '中国工商银行股份有限公司益阳分行桃花江分理处',
        value: '102561510277|4309|湖南省|益阳市'
      },
      {
        label: '中国工商银行股份有限公司安化支行',
        value: '102561603408|4309|湖南省|益阳市'
      },
      {
        label: '中国工商银行股份有限公司娄底兴城支行',
        value: '102562001019|4313|湖南省|娄底市'
      },
      {
        label: '中国工商银行股份有限公司娄底分行',
        value: '102562001060|4313|湖南省|娄底市'
      },
      {
        label: '中国工商银行股份有限公司娄底涟钢支行',
        value: '102562001078|4313|湖南省|娄底市'
      },
      {
        label: '中国工商银行股份有限公司娄底城建支行',
        value: '102562001086|4313|湖南省|娄底市'
      },
      {
        label: '中国工商银行股份有限公司娄底分行（不对外）',
        value: '102562001109|4313|湖南省|娄底市'
      },
      {
        label: '中国工商银行股份有限公司娄底城北支行',
        value: '102562010021|4313|湖南省|娄底市'
      },
      {
        label: '中国工商银行股份有限公司娄底和乐坪支行',
        value: '102562010030|4313|湖南省|娄底市'
      },
      {
        label: '中国工商银行股份有限公司娄底城南支行',
        value: '102562010048|4313|湖南省|娄底市'
      },
      {
        label: '中国工商银行股份有限公司娄底耕塘支行',
        value: '102562010056|4313|湖南省|娄底市'
      },
      {
        label: '中国工商银行股份有限公司娄底商业街支行',
        value: '102562010064|4313|湖南省|娄底市'
      },
      {
        label: '中国工商银行股份有限公司娄底开发区支行',
        value: '102562010177|4313|湖南省|娄底市'
      },
      {
        label: '中国工商银行股份有限公司娄底王家支行',
        value: '102562010185|4313|湖南省|娄底市'
      },
      {
        label: '中国工商银行股份有限公司娄底月塘支行',
        value: '102562010193|4313|湖南省|娄底市'
      },
      {
        label: '中国工商银行股份有限公司娄底新星支行',
        value: '102562010265|4313|湖南省|娄底市'
      },
      {
        label: '中国工商银行股份有限公司冷水江支行',
        value: '102562201021|4313|湖南省|娄底市'
      },
      {
        label: '中国工商银行股份有限公司冷水江支行江南分理处',
        value: '102562210073|4313|湖南省|娄底市'
      },
      {
        label: '中国工商银行股份有限公司冷水江支行轧钢分理处',
        value: '102562210081|4313|湖南省|娄底市'
      },
      {
        label: '中国工商银行股份有限公司莱州支行',
        value: '102456902208|3706|山东省|烟台市'
      },
      {
        label: '中国工商银行股份有限公司丰城南门支行',
        value: '102431210102|3609|江西省|宜春市'
      },
      {
        label: '中国工商银行股份有限公司丰城大井头支行',
        value: '102431210119|3609|江西省|宜春市'
      },
      {
        label: '中国工商银行股份有限公司樟树支行',
        value: '102431308081|3609|江西省|宜春市'
      },
      {
        label: '中国工商银行股份有限公司樟树临江支行',
        value: '102431308090|3609|江西省|宜春市'
      },
      {
        label: '中国工商银行股份有限公司樟树欣兴支行',
        value: '102431308104|3609|江西省|宜春市'
      },
      {
        label: '中国工商银行股份有限公司樟树城南支行',
        value: '102431310036|3609|江西省|宜春市'
      },
      {
        label: '中国工商银行股份有限公司樟树金都支行',
        value: '102431310044|3609|江西省|宜春市'
      },
      {
        label: '中国工商银行股份有限公司高安支行',
        value: '102431408116|3609|江西省|宜春市'
      },
      {
        label: '中国工商银行股份有限公司高安英岗岭支行',
        value: '102431408124|3609|江西省|宜春市'
      },
      {
        label: '中国工商银行股份有限公司高安八景支行',
        value: '102431408132|3609|江西省|宜春市'
      },
      {
        label: '中国工商银行股份有限公司高安瑞州路支行',
        value: '102431408149|3609|江西省|宜春市'
      },
      {
        label: '中国工商银行股份有限公司高安瑞阳支行',
        value: '102431410060|3609|江西省|宜春市'
      },
      {
        label: '中国工商银行股份有限公司高安朝阳门支行',
        value: '102431410086|3609|江西省|宜春市'
      },
      {
        label: '中国工商银行股份有限公司奉新支行',
        value: '102431508150|3609|江西省|宜春市'
      },
      {
        label: '中国工商银行股份有限公司万载支行',
        value: '102431608167|3609|江西省|宜春市'
      },
      {
        label: '中国工商银行股份有限公司万载锦江支行',
        value: '102431610037|3609|江西省|宜春市'
      },
      {
        label: '中国工商银行股份有限公司上高支行',
        value: '102431708174|3609|江西省|宜春市'
      },
      {
        label: '中国工商银行股份有限公司上高建设路支行',
        value: '102431710021|3609|江西省|宜春市'
      },
      {
        label: '中国工商银行股份有限公司宜丰支行',
        value: '102431808186|3609|江西省|宜春市'
      },
      {
        label: '中国工商银行股份有限公司靖安支行',
        value: '102431908023|3609|江西省|宜春市'
      },
      {
        label: '中国工商银行股份有限公司铜鼓支行',
        value: '102432108196|3609|江西省|宜春市'
      },
      {
        label: '中国工商银行上饶市分行营业部',
        value: '102433012008|3611|江西省|上饶市'
      },
      {
        label: '中国工商银行上饶市分行营业部胜利路分理处',
        value: '102433012016|3611|江西省|上饶市'
      },
      {
        label: '中国工商银行上饶市分行赣东北大道分理处',
        value: '102433012024|3611|江西省|上饶市'
      },
      {
        label: '中国工商银行上饶市分行汇通支行',
        value: '102433012032|3611|江西省|上饶市'
      },
      {
        label: '中国工商银行上饶市分行三清支行',
        value: '102433012049|3611|江西省|上饶市'
      },
      {
        label: '中国工商银行上饶市分行',
        value: '102433012057|3611|江西省|上饶市'
      },
      {
        label: '中国工商银行上饶市分行信州支行',
        value: '102433012065|3611|江西省|上饶市'
      },
      {
        label: '中国工商银行上饶市分行信州支行水南分理处',
        value: '102433012073|3611|江西省|上饶市'
      },
      {
        label: '中国工商银行股份有限公司济南山大南路支行',
        value: '102451015037|3701|山东省|济南市'
      },
      {
        label: '中国工商银行股份有限公司济南山大北路支行',
        value: '102451015115|3701|山东省|济南市'
      },
      {
        label: '中国工商银行股份有限公司济南洪西支行',
        value: '102451015140|3701|山东省|济南市'
      },
      {
        label: '中国工商银行股份有限公司济南长清支行东关分理处',
        value: '102451015519|3701|山东省|济南市'
      },
      {
        label: '中国工商银行股份有限公司济南五峰路支行',
        value: '102451015535|3701|山东省|济南市'
      },
      {
        label: '中国工商银行股份有限公司济南灵岩路支行',
        value: '102451015543|3701|山东省|济南市'
      },
      {
        label: '中国工商银行股份有限公司济南长清支行第一分理处',
        value: '102451015578|3701|山东省|济南市'
      },
      {
        label: '中国工商银行股份有限公司济南长清支行大学城分理处',
        value: '102451015617|3701|山东省|济南市'
      },
      {
        label: '中国工商银行股份有限公司济南开发区支行',
        value: '102451016023|3701|山东省|济南市'
      },
      {
        label: '中国工商银行股份有限公司章丘明泉支行',
        value: '102451017018|3701|山东省|济南市'
      },
      {
        label: '中国工商银行股份有限公司章丘百脉泉支行',
        value: '102451017042|3701|山东省|济南市'
      },
      {
        label: '中国工商银行平顶山分行开源中路支行',
        value: '102495002286|4104|河南省|平顶山市'
      },
      {
        label: '中国工商银行股份有限公司平顶山分行会计业务处理中心',
        value: '102495002987|4104|河南省|平顶山市'
      },
      {
        label: '中国工商银行平顶山分行姚孟支行',
        value: '102495022038|4104|河南省|平顶山市'
      },
      {
        label: '中国工商银行股份有限公司平顶山新卫东支行',
        value: '102495022054|4104|河南省|平顶山市'
      },
      {
        label: '中国工商银行股份有限公司平顶山先锋路支行',
        value: '102495052051|4104|河南省|平顶山市'
      },
      {
        label: '中国工商银行股份有限公司平顶山诚朴路支行',
        value: '102495062016|4104|河南省|平顶山市'
      },
      {
        label: '中国工商银行股份有限公司平顶山贸易广场支行',
        value: '102495072055|4104|河南省|平顶山市'
      },
      {
        label: '中国工商银行平顶山分行宝丰县支行',
        value: '102495102417|4104|河南省|平顶山市'
      },
      {
        label: '中国工商银行股份有限公司叶县支行',
        value: '102495202534|4104|河南省|平顶山市'
      },
      {
        label: '中国工商银行平顶山分行鲁山县支行',
        value: '102495302449|4104|河南省|平顶山市'
      },
      {
        label: '中国工商银行股份有限公司郏县支行',
        value: '102495402590|4104|河南省|平顶山市'
      },
      {
        label: '中国工商银行平顶山分行汝州市支行',
        value: '102495602562|4104|河南省|平顶山市'
      },
      {
        label: '中国工商银行股份有限公司汝州丹阳路支行',
        value: '102495632568|4104|河南省|平顶山市'
      },
      {
        label: '中国工商银行安阳分行行政区支行',
        value: '102496002010|4105|河南省|安阳市'
      },
      {
        label: '中国工商银行股份有限公司安阳永明支行',
        value: '102496002028|4105|河南省|安阳市'
      },
      {
        label: '中国工商银行安阳分行车站支行',
        value: '102496002036|4105|河南省|安阳市'
      },
      {
        label: '中国工商银行安阳分行南大街支行',
        value: '102496002044|4105|河南省|安阳市'
      },
      {
        label: '中国工商银行安阳分行梅园庄支行',
        value: '102496002052|4105|河南省|安阳市'
      },
      {
        label: '中国工商银行台州市椒江区支行',
        value: '102345001114|3310|浙江省|台州市'
      },
      {
        label: '中国工商银行股份有限公司台州经济开发区支行',
        value: '102345001122|3310|浙江省|台州市'
      },
      {
        label: '中国工商银行台州市分行牡丹卡中心',
        value: '102345001139|3310|浙江省|台州市'
      },
      {
        label: '中国工商银行台州市椒江区支行椒北支行',
        value: '102345001219|3310|浙江省|台州市'
      },
      {
        label: '中国工商银行股份有限公司台州三甲支行',
        value: '102345001317|3310|浙江省|台州市'
      },
      {
        label: '中国工商银行股份有限公司芜湖劳动路支行',
        value: '102362022216|3402|安徽省|芜湖市'
      },
      {
        label: '中国工商银行股份有限公司芜湖高新技术开发区支行',
        value: '102362022232|3402|安徽省|芜湖市'
      },
      {
        label: '中国工商银行股份有限公司芜湖弋矶山支行',
        value: '102362022257|3402|安徽省|芜湖市'
      },
      {
        label: '中国工商银行股份有限公司芜湖狮子山支行',
        value: '102362022281|3402|安徽省|芜湖市'
      },
      {
        label: '中国工商银行股份有限公司芜湖天门山支行',
        value: '102362022992|3402|安徽省|芜湖市'
      },
      {
        label: '中国工商银行股份有限公司芜湖利民路支行',
        value: '102362023274|3402|安徽省|芜湖市'
      },
      {
        label: '中国工商银行股份有限公司芜湖烟厂支行',
        value: '102362023282|3402|安徽省|芜湖市'
      },
      {
        label: '中国工商银行股份有限公司芜湖张家山支行',
        value: '102362023299|3402|安徽省|芜湖市'
      },
      {
        label: '中国工商银行股份有限公司芜湖南瑞支行',
        value: '102362023993|3402|安徽省|芜湖市'
      },
      {
        label: '中国工商银行漳州新浦路支行',
        value: '102399032124|3506|福建省|漳州市'
      },
      {
        label: '中国工商银行股份有限公司漳州元光北支行',
        value: '102399034007|3506|福建省|漳州市'
      },
      {
        label: '中国工商银行股份有限公司漳州漳华支行',
        value: '102399034058|3506|福建省|漳州市'
      },
      {
        label: '中国工商银行漳州分行营业部',
        value: '102399035000|3506|福建省|漳州市'
      },
      {
        label: '中国工商银行股份制有限公司漳州中山支行',
        value: '102399035018|3506|福建省|漳州市'
      },
      {
        label: '中国工商银行漳州东门支行',
        value: '102399035026|3506|福建省|漳州市'
      },
      {
        label: '中国工商银行股份有限公司漳州商城支行',
        value: '102399035067|3506|福建省|漳州市'
      },
      {
        label: '中国工商银行股份有限公司温州胜利支行',
        value: '102333021523|3303|浙江省|温州市'
      },
      {
        label: '中国工商银行股份有限公司温州鹿城支行站前分理处',
        value: '102333021540|3303|浙江省|温州市'
      },
      {
        label: '中国工商银行温州市城南支行',
        value: '102333021902|3303|浙江省|温州市'
      },
      {
        label: '中国工商银行股份有限公司温州城东支行东方花苑分理处',
        value: '102333021927|3303|浙江省|温州市'
      },
      {
        label: '中国工商银行股份有限公司温州瓯海支行',
        value: '102333022702|3303|浙江省|温州市'
      },
      {
        label: '中国工商银行股份有限公司温州西山支行',
        value: '102333022719|3303|浙江省|温州市'
      },
      {
        label: '中国工商银行股份有限公司温州龙湾支行',
        value: '102333022735|3303|浙江省|温州市'
      },
      {
        label: '中国工商银行股份有限公司温州永强支行',
        value: '102333022743|3303|浙江省|温州市'
      },
      {
        label: '中国工商银行股份有限公司温州梧田支行',
        value: '102333022751|3303|浙江省|温州市'
      },
      {
        label: '中国工商银行股份有限公司温州城西支行商贸城分理处',
        value: '102333022760|3303|浙江省|温州市'
      },
      {
        label: '中国工商银行股份有限公司温州新桥支行',
        value: '102333022778|3303|浙江省|温州市'
      },
      {
        label: '中国工商银行股份有限公司温州瓯海支行火车站分理处',
        value: '102333022786|3303|浙江省|温州市'
      },
      {
        label: '中国工商银行温州茶山高教园区支行',
        value: '102333022794|3303|浙江省|温州市'
      },
      {
        label: '中国工商银行淮南蔡家岗支行',
        value: '102364000238|3404|安徽省|淮南市'
      },
      {
        label: '中国工商银行股份有限公司淮南淮河支行',
        value: '102364000254|3404|安徽省|淮南市'
      },
      {
        label: '中国工商银行淮南潘集支行',
        value: '102364000262|3404|安徽省|淮南市'
      },
      {
        label: '中国工商银行淮南洞山支行',
        value: '102364000279|3404|安徽省|淮南市'
      },
      {
        label: '中国工商银行淮南龙湖支行',
        value: '102364000287|3404|安徽省|淮南市'
      },
      {
        label: '中国工商银行淮南汇通支行',
        value: '102364000295|3404|安徽省|淮南市'
      },
      {
        label: '中国工商银行淮南城建支行',
        value: '102364000300|3404|安徽省|淮南市'
      },
      {
        label: '中国工商银行股份有限公司淮南人民路支行',
        value: '102364020216|3404|安徽省|淮南市'
      },
      {
        label: '中国工商银行股份有限公司淮南前锋支行',
        value: '102364020224|3404|安徽省|淮南市'
      },
      {
        label: '中国工商银行股份有限公司淮南罗马广场支行',
        value: '102364020249|3404|安徽省|淮南市'
      },
      {
        label: '中国工商银行股份有限公司淮南关路支行',
        value: '102364020257|3404|安徽省|淮南市'
      },
      {
        label: '中国工商银行股份有限公司淮南香港街支行',
        value: '102364020265|3404|安徽省|淮南市'
      },
      {
        label: '中国工商银行股份有限公司淮南洛河支行',
        value: '102364020273|3404|安徽省|淮南市'
      },
      {
        label: '中国工商银行股份有限公司淮南工厂支行',
        value: '102364020312|3404|安徽省|淮南市'
      },
      {
        label: '中国工商银行股份有限公司淮南金源支行',
        value: '102364020345|3404|安徽省|淮南市'
      },
      {
        label: '中国工商银行股份有限公司淮南西城支行',
        value: '102364021207|3404|安徽省|淮南市'
      },
      {
        label: '中国工商银行股份有限公司淮南谢三村支行',
        value: '102364021223|3404|安徽省|淮南市'
      },
      {
        label: '中国工商银行淮南望锋岗分理处',
        value: '102364021231|3404|安徽省|淮南市'
      },
      {
        label: '中国工商银行股份有限公司淮南李郢孜支行',
        value: '102364021240|3404|安徽省|淮南市'
      },
      {
        label: '中国工商银行股份有限公司淮南土坝孜支行',
        value: '102364021311|3404|安徽省|淮南市'
      },
      {
        label: '中国工商银行股份有限公司淮南毕家岗支行',
        value: '102364021320|3404|安徽省|淮南市'
      },
      {
        label: '中国工商银行股份有限公司淮南孔集支行',
        value: '102364021346|3404|安徽省|淮南市'
      },
      {
        label: '中国工商银行淮南市商场分理处',
        value: '102364021362|3404|安徽省|淮南市'
      },
      {
        label: '中国工商银行股份有限公司淮南黎明支行',
        value: '102364022214|3404|安徽省|淮南市'
      },
      {
        label: '中国工商银行股份有限公司淮南朝阳支行',
        value: '102364022222|3404|安徽省|淮南市'
      },
      {
        label: '中国工商银行股份有限公司金溪支行',
        value: '102437811150|3610|江西省|抚州市'
      },
      {
        label: '中国工商银行股份有限公司资溪支行',
        value: '102437911164|3610|江西省|抚州市'
      },
      {
        label: '中国工商银行股份有限公司东乡恒安支行',
        value: '102438110406|3610|江西省|抚州市'
      },
      {
        label: '中国工商银行股份有限公司东乡铜矿支行',
        value: '102438110422|3610|江西省|抚州市'
      },
      {
        label: '中国工商银行股份有限公司东乡支行',
        value: '102438111179|3610|江西省|抚州市'
      },
      {
        label: '中国工商银行沈阳分行账务管理中心',
        value: '102221005005|2101|辽宁省|沈阳市'
      },
      {
        label: '中国工商银行沈阳民主支行',
        value: '102221005021|2101|辽宁省|沈阳市'
      },
      {
        label: '中国工商银行沈阳临湖支行',
        value: '102221005056|2101|辽宁省|沈阳市'
      },
      {
        label: '中国工商银行沈阳桂花支行',
        value: '102221005144|2101|辽宁省|沈阳市'
      },
      {
        label: '中国工商银行沈阳苏白路支行',
        value: '102221005312|2101|辽宁省|沈阳市'
      },
      {
        label: '中国工商银行沈阳湖西支行',
        value: '102221005345|2101|辽宁省|沈阳市'
      },
      {
        label: '中国工商银行沈阳红阳支行',
        value: '102221005353|2101|辽宁省|沈阳市'
      },
      {
        label: '中国工商银行沈阳蒲河支行',
        value: '102221006024|2101|辽宁省|沈阳市'
      },
      {
        label: '中国工商银行沈阳建设路支行',
        value: '102221006129|2101|辽宁省|沈阳市'
      },
      {
        label: '中国工商银行沈阳十一纬路支行',
        value: '102221007319|2101|辽宁省|沈阳市'
      },
      {
        label: '中国工商银行沈阳方型广场支行',
        value: '102221007327|2101|辽宁省|沈阳市'
      },
      {
        label: '中国工商银行沈阳红霞支行',
        value: '102221007351|2101|辽宁省|沈阳市'
      },
      {
        label: '中国工商银行沈阳黄河支行',
        value: '102221007530|2101|辽宁省|沈阳市'
      },
      {
        label: '中国工商银行沈阳北市支行西塔分理处',
        value: '102221007548|2101|辽宁省|沈阳市'
      },
      {
        label: '中国工商银行沈阳民富支行',
        value: '102221007564|2101|辽宁省|沈阳市'
      },
      {
        label: '中国工商银行沈阳和平广场支行',
        value: '102221007652|2101|辽宁省|沈阳市'
      },
      {
        label: '中国工商银行沈阳辽展支行',
        value: '102221007716|2101|辽宁省|沈阳市'
      },
      {
        label: '中国工商银行沈阳东北大学支行',
        value: '102221007724|2101|辽宁省|沈阳市'
      },
      {
        label: '中国工商银行沈阳胜利大街支行',
        value: '102221009210|2101|辽宁省|沈阳市'
      },
      {
        label: '中国工商银行沈阳砂山街第二支行',
        value: '102221009252|2101|辽宁省|沈阳市'
      },
      {
        label: '中国工商银行股份有限公司辽宁省分行业务集中处理中心',
        value: '102221009994|2101|辽宁省|沈阳市'
      },
      {
        label: '中国工商银行股份有限公司票据营业部沈阳分部',
        value: '102221010082|2101|辽宁省|沈阳市'
      },
      {
        label: '中国工商银行股份有限公司大连贤林园支行',
        value: '102222000378|2102|辽宁省|大连市'
      },
      {
        label: '中国工商银行股份有限公司大连小龙街支行',
        value: '102222000386|2102|辽宁省|大连市'
      },
      {
        label: '中国工商银行股份有限公司大连新天地支行',
        value: '102222000505|2102|辽宁省|大连市'
      },
      {
        label: '中国工商银行股份有限公司大连星海湾支行',
        value: '102222002148|2102|辽宁省|大连市'
      },
      {
        label: '中国工商银行股份有限公司大连高新园区支行',
        value: '102222003751|2102|辽宁省|大连市'
      },
      {
        label: '中国工商银行股份有限公司大连中山路支行',
        value: '102222005363|2102|辽宁省|大连市'
      },
      {
        label: '中国工商银行股份有限公司大连人民路支行',
        value: '102222007221|2102|辽宁省|大连市'
      },
      {
        label: '中国工商银行股份有限公司大连黄河街支行',
        value: '102222007297|2102|辽宁省|大连市'
      },
      {
        label: '中国工商银行股份有限公司大连营城子支行',
        value: '102222007393|2102|辽宁省|大连市'
      },
      {
        label: '中国工商银行大连中山广场支行营业部',
        value: '102222020012|2102|辽宁省|大连市'
      },
      {
        label: '中国工商银行大连二七广场支行营业部',
        value: '102222020029|2102|辽宁省|大连市'
      },
      {
        label: '中国工商银行大连青泥洼桥支行营业部',
        value: '102222020037|2102|辽宁省|大连市'
      },
      {
        label: '中国工商银行股份有限公司大连桃源支行',
        value: '102222020045|2102|辽宁省|大连市'
      },
      {
        label: '中国工商银行大连西岗支行营业部',
        value: '102222020053|2102|辽宁省|大连市'
      },
      {
        label: '中国工商银行大连沙河口支行营业部',
        value: '102222020070|2102|辽宁省|大连市'
      },
      {
        label: '中国工商银行股份有限公司大连春柳支行',
        value: '102222020088|2102|辽宁省|大连市'
      },
      {
        label: '中国工商银行大连栾金分理处',
        value: '102222020107|2102|辽宁省|大连市'
      },
      {
        label: '中国工商银行大连甘井子支行营业部',
        value: '102222020115|2102|辽宁省|大连市'
      },
      {
        label: '中国工商银行股份有限公司大连金三角支行',
        value: '102222020131|2102|辽宁省|大连市'
      },
      {
        label: '中国工商银行大连经济技术开发区分行营业部',
        value: '102222020140|2102|辽宁省|大连市'
      },
      {
        label: '中国工商银行大连旅顺口支行营业部',
        value: '102222020158|2102|辽宁省|大连市'
      },
      {
        label: '中国工商银行白城海明支行',
        value: '102247000028|2208|吉林省|白城市'
      },
      {
        label: '中国工商银行白城联营分理处',
        value: '102247000044|2208|吉林省|白城市'
      },
      {
        label: '中国工商银行股份有限公司白城欧亚支行',
        value: '102247000052|2208|吉林省|白城市'
      },
      {
        label: '中国工商银行白城长庆支行',
        value: '102247000085|2208|吉林省|白城市'
      },
      {
        label: '中国工商银行白城洮北支行',
        value: '102247000132|2208|吉林省|白城市'
      },
      {
        label: '中国工商银行股份有限公司白城文化广场支行',
        value: '102247000165|2208|吉林省|白城市'
      },
      {
        label: '中国工商银行白城经济开发区支行',
        value: '102247000204|2208|吉林省|白城市'
      },
      {
        label: '中国工商银行股份有限公司鸡西市恒山支行',
        value: '102266002096|2303|黑龙江省|鸡西市'
      },
      {
        label: '中国工商银行股份有限公司鞍山园林支行',
        value: '102223000089|2103|辽宁省|鞍山市'
      },
      {
        label: '中国工商银行股份有限公司鞍山健身支行',
        value: '102223000097|2103|辽宁省|鞍山市'
      },
      {
        label: '中国工商银行股份有限公司鞍山虹桥支行',
        value: '102223000101|2103|辽宁省|鞍山市'
      },
      {
        label: '中国工商银行股份有限公司鞍山九街口支行',
        value: '102223000110|2103|辽宁省|鞍山市'
      },
      {
        label: '中国工商银行股份有限公司鞍山人民路支行',
        value: '102223000136|2103|辽宁省|鞍山市'
      },
      {
        label: '中国工商银行股份有限公司鞍山共和支行',
        value: '102223000152|2103|辽宁省|鞍山市'
      },
      {
        label: '中国工商银行股份有限公司鞍山陶官支行',
        value: '102223000193|2103|辽宁省|鞍山市'
      },
      {
        label: '中国工商银行股份有限公司鞍山曙光支行',
        value: '102223000232|2103|辽宁省|鞍山市'
      },
      {
        label: '中国工商银行股份有限公司鞍山科大支行',
        value: '102223000265|2103|辽宁省|鞍山市'
      },
      {
        label: '中国工商银行潍坊市分行营业部',
        value: '102458000013|3707|山东省|潍坊市'
      },
      {
        label: '中国工商银行股份有限公司余姚姚北支行',
        value: '102332431116|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行股份有限公司余姚南园支行',
        value: '102332431149|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行股份有限公司余姚大江南支行',
        value: '102332431173|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行股份有限公司浙江省宁波余姚泗门支行',
        value: '102332431181|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行股份有限公司余姚城东支行',
        value: '102332431190|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行股份有限公司余姚丈亭支行',
        value: '102332431204|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行股份有限公司余姚临山支行',
        value: '102332431212|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行股份有限公司余姚朗霞支行',
        value: '102332431229|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行股份有限公司余姚兰江支行',
        value: '102332431237|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行股份有限公司慈溪分行',
        value: '102332530000|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行慈溪观城支行',
        value: '102332530018|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行慈溪周巷支行',
        value: '102332530026|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行慈溪掌起支行',
        value: '102332530034|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行股份有限公司齐齐哈尔龙华路支行',
        value: '102264003102|2302|黑龙江省|齐齐哈尔市'
      },
      {
        label: '中国工商银行股份有限公司齐齐哈尔站前支行',
        value: '102264003119|2302|黑龙江省|齐齐哈尔市'
      },
      {
        label: '中国工商银行股份有限公司齐齐哈尔昂昂溪支行',
        value: '102264003135|2302|黑龙江省|齐齐哈尔市'
      },
      {
        label: '中国工商银行股份有限公司齐齐哈尔富拉尔基支行',
        value: '102264003803|2302|黑龙江省|齐齐哈尔市'
      },
      {
        label: '中国工商银行股份有限公司齐齐哈尔碾子山支行',
        value: '102264003975|2302|黑龙江省|齐齐哈尔市'
      },
      {
        label: '中国工商银行股份有限公司齐齐哈尔建设路支行',
        value: '102264004017|2302|黑龙江省|齐齐哈尔市'
      },
      {
        label: '中国工商银行股份有限公司齐齐哈尔向阳分理处',
        value: '102264004025|2302|黑龙江省|齐齐哈尔市'
      },
      {
        label: '中国工商银行股份有限公司慈溪浒山支行',
        value: '102332530114|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行股份有限公司慈溪虞波支行',
        value: '102332530122|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行股份有限公司慈溪城东支行',
        value: '102332530139|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行股份有限公司宁波杭州湾新区支行',
        value: '102332530171|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行股份有限公司慈溪新浦支行',
        value: '102332530180|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行股份有限公司慈溪横河支行',
        value: '102332530198|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行股份有限公司慈溪龙山支行',
        value: '102332530202|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行股份有限公司慈溪长河支行',
        value: '102332530219|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行股份有限公司慈溪逍林支行',
        value: '102332530227|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行股份有限公司慈溪庵东支行',
        value: '102332530235|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行股份有限公司宁波奉化支行',
        value: '102332632001|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行奉化溪口支行',
        value: '102332632010|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行奉化中山支行',
        value: '102332632028|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行股份有限公司奉化新丰支行',
        value: '102332632052|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行股份有限公司奉化大桥支行',
        value: '102332632069|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行股份有限公司奉化大成支行',
        value: '102332632093|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行股份有限公司奉化茗山支行',
        value: '102332632108|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行股份有限公司奉化西坞支行',
        value: '102332632132|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行股份有限公司温州仙岩支行',
        value: '102333000018|3303|浙江省|温州市'
      },
      {
        label: '中国工商银行股份有限公司温州城东支行广场路分理处',
        value: '102333000026|3303|浙江省|温州市'
      },
      {
        label: '中国工商银行股份有限公司温州城西支行新世界分理处',
        value: '102333000042|3303|浙江省|温州市'
      },
      {
        label: '中国工商银行股份有限公司温州城东支行曙光大厦分理处',
        value: '102333000139|3303|浙江省|温州市'
      },
      {
        label: '中国工商银行股份有限公司温州城西支行百里东路分理处',
        value: '102333000147|3303|浙江省|温州市'
      },
      {
        label: '中国工商银行股份有限公司温州城西支行西城路分理处',
        value: '102333000413|3303|浙江省|温州市'
      },
      {
        label: '中国工商银行股份有限公司温州南浦支行',
        value: '102333000421|3303|浙江省|温州市'
      },
      {
        label: '中国工商银行股份有限公司芜湖延安路支行',
        value: '102362024222|3402|安徽省|芜湖市'
      },
      {
        label: '中国工商银行股份有限公司芜湖赤铸山路支行',
        value: '102362025241|3402|安徽省|芜湖市'
      },
      {
        label: '中国工商银行股份有限公司芜湖清水支行',
        value: '102362026236|3402|安徽省|芜湖市'
      },
      {
        label: '中国工商银行股份有限公司芜湖三山支行',
        value: '102362027245|3402|安徽省|芜湖市'
      },
      {
        label: '中国工商银行芜湖县支行',
        value: '102362102503|3402|安徽省|芜湖市'
      },
      {
        label: '中国工商银行股份有限公司芜湖县城北分理处',
        value: '102362126221|3402|安徽省|芜湖市'
      },
      {
        label: '中国工商银行芜湖市繁昌县支行',
        value: '102362202701|3402|安徽省|芜湖市'
      },
      {
        label: '中国工商银行股份有限公司繁昌南门支行',
        value: '102362227238|3402|安徽省|芜湖市'
      },
      {
        label: '中国工商银行股份有限公司南陵支行',
        value: '102362302602|3402|安徽省|芜湖市'
      },
      {
        label: '中国工商银行股份有限公司蚌埠朝阳路支行',
        value: '102363000424|3403|安徽省|蚌埠市'
      },
      {
        label: '中国工商银行蚌埠分行会计业务处理中心',
        value: '102363000703|3403|安徽省|蚌埠市'
      },
      {
        label: '中国工商银行股份有限公司蚌埠中山支行',
        value: '102363000711|3403|安徽省|蚌埠市'
      },
      {
        label: '中国工商银行蚌埠淮河支行',
        value: '102363000720|3403|安徽省|蚌埠市'
      },
      {
        label: '中国工商银行蚌埠车站支行',
        value: '102363000738|3403|安徽省|蚌埠市'
      },
      {
        label: '中国工商银行蚌埠烟墩孜支行',
        value: '102363000746|3403|安徽省|蚌埠市'
      },
      {
        label: '中国工商银行蚌埠华夏支行',
        value: '102363000754|3403|安徽省|蚌埠市'
      },
      {
        label: '中国工商银行股份有限公司无锡梁溪支行',
        value: '102302002017|3202|江苏省|无锡市'
      },
      {
        label: '中国工商银行股份有限公司成都蜀辉路支行',
        value: '102651021021|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都金履四路支行',
        value: '102651021030|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都通锦桥支行',
        value: '102651021056|5101|四川省|成都市'
      },
      {
        label: '中国工商银行成都市沙河支行营业室',
        value: '102651021101|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司惠州下角支行',
        value: '102595002022|4413|广东省|惠州市'
      },
      {
        label: '中国工商银行股份有限公司惠州金山湖支行',
        value: '102595002039|4413|广东省|惠州市'
      },
      {
        label: '中国工商银行惠州市陈江支行',
        value: '102595002047|4413|广东省|惠州市'
      },
      {
        label: '中国工商银行股份有限公司惠州白鹭湖支行',
        value: '102595002055|4413|广东省|惠州市'
      },
      {
        label: '中国工商银行股份有限公司惠州富力国际中心支行',
        value: '102595002063|4413|广东省|惠州市'
      },
      {
        label: '中国工商银行股份有限公司四川省成都石油路支行',
        value: '102651022602|5101|四川省|成都市'
      },
      {
        label: '中国工商银行成都市邛崃市支行营业室',
        value: '102651022709|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都邛崃长安大道支行',
        value: '102651022717|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司蒲江朝阳大道支行',
        value: '102651022725|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司邛崃蜚虹路支行',
        value: '102651022733|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司四川省成都二仙桥支行',
        value: '102651022805|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都市银行卡业务部',
        value: '102651023003|5101|四川省|成都市'
      },
      {
        label: '中国工商银行成都市青白江区支行营业室',
        value: '102651023100|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都新河路支行',
        value: '102651023118|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都指挥街支行',
        value: '102651023302|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司四川省成都龙泉支行',
        value: '102651023409|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都驿泉路支行',
        value: '102651023417|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都总部经济港支行',
        value: '102651023433|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都市城南支行营业室',
        value: '102651023505|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都中和支行',
        value: '102651023513|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都花牌坊支行',
        value: '102651023708|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都东电集团支行',
        value: '102651023804|5101|四川省|成都市'
      },
      {
        label: '中国工商银行成都市高新技术产业开发区支行营业室',
        value: '102651023907|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都姐儿堰支行',
        value: '102651023931|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都天仁路支行',
        value: '102651023940|5101|四川省|成都市'
      },
      {
        label: '中国工商银行成都市大邑县支行营业室',
        value: '102651024006|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司四川省成都市龙舟路支行',
        value: '102651024102|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都芷泉支行经天路分理处',
        value: '102651024135|5101|四川省|成都市'
      },
      {
        label: '中国工商银行成都市马道街分理处',
        value: '102651024209|5101|四川省|成都市'
      },
      {
        label: '中国工商银行成都市金牛区支行营业室',
        value: '102651024305|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都国际商贸城支行',
        value: '102651024330|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都金府路支行',
        value: '102651024356|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都国际商贸城聚兴路支行',
        value: '102651024364|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都王贾路支行',
        value: '102651024372|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都大石西路支行',
        value: '102651001088|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都紫荆西路支行',
        value: '102651001131|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都金牛支行蓉北商贸大道分理处',
        value: '102651001158|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都盐市口支行清溪分理处',
        value: '102651001965|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司四川省成都市西马棚支行',
        value: '102651001973|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都盐市口支行游乐园分理处',
        value: '102651002040|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都倪家桥支行',
        value: '102651002074|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都提督街支行',
        value: '102651002082|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都双楠小区支行',
        value: '102651002162|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都科华南路支行',
        value: '102651002200|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都棕北支行',
        value: '102651002218|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都成龙大道支行',
        value: '102651002234|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都龙江路支行',
        value: '102651002306|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都新华公园支行',
        value: '102651002314|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都陕西街支行',
        value: '102651002517|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都青白江支行大弯南路分理处',
        value: '102651003106|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都怡湖西路支行',
        value: '102651003180|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司沁阳怀府路支行',
        value: '102501422583|4108|河南省|焦作市'
      },
      {
        label: '中国工商银行焦作分行温县支行',
        value: '102501502187|4108|河南省|焦作市'
      },
      {
        label: '中国工商银行股份有限公司温县振兴路支行',
        value: '102501512184|4108|河南省|焦作市'
      },
      {
        label: '中国工商银行焦作分行孟州市支行',
        value: '102501602784|4108|河南省|焦作市'
      },
      {
        label: '中国工商银行股份有限公司孟州韩愈大街支行',
        value: '102501612782|4108|河南省|焦作市'
      },
      {
        label: '中国工商银行股份有限公司无锡分行',
        value: '102302002025|3202|江苏省|无锡市'
      },
      {
        label: '中国工商银行股份有限公司无锡崇安支行',
        value: '102302002050|3202|江苏省|无锡市'
      },
      {
        label: '中国工商银行股份有限公司无锡北塘支行',
        value: '102302002068|3202|江苏省|无锡市'
      },
      {
        label: '中国工商银行盐城盐东支行',
        value: '102311066448|3209|江苏省|盐城市'
      },
      {
        label: '中国工商银行盐城阳光分理处',
        value: '102311066489|3209|江苏省|盐城市'
      },
      {
        label: '中国工商银行盐城黄海支行',
        value: '102311066497|3209|江苏省|盐城市'
      },
      {
        label: '中国工商银行股份有限公司盐城五星支行',
        value: '102311066501|3209|江苏省|盐城市'
      },
      {
        label: '中国工商银行股份有限公司郑州新郑路支行',
        value: '102491042016|4101|河南省|郑州市'
      },
      {
        label: '中国工商银行股份有限公司郑州航海路支行',
        value: '102491042024|4101|河南省|郑州市'
      },
      {
        label: '中国工商银行股份有限公司郑州郑东新区支行',
        value: '102491042065|4101|河南省|郑州市'
      },
      {
        label: '中国工商银行股份有限公司郑州万福支行',
        value: '102491042153|4101|河南省|郑州市'
      },
      {
        label: '中国工商银行股份有限公司阿鲁科尔沁旗支行',
        value: '102194124322|1504|内蒙古自治区|赤峰市'
      },
      {
        label: '中国工商银行股份有限公司巴林左旗支行',
        value: '102194203067|1504|内蒙古自治区|赤峰市'
      },
      {
        label: '中国工商银行内蒙古赤峰市分行右旗支行',
        value: '102194300019|1504|内蒙古自治区|赤峰市'
      },
      {
        label: '中国工商银行内蒙古赤峰市分行林西县支行',
        value: '102194400011|1504|内蒙古自治区|赤峰市'
      },
      {
        label: '中国工商银行内蒙古赤峰市分行克什克腾旗支行',
        value: '102194500012|1504|内蒙古自治区|赤峰市'
      },
      {
        label: '中国工商银行内蒙古赤峰市分行翁牛特旗支行',
        value: '102194600013|1504|内蒙古自治区|赤峰市'
      },
      {
        label: '中国工商银行股份有限公司翁牛特旗中街支行',
        value: '102194600030|1504|内蒙古自治区|赤峰市'
      },
      {
        label: '中国工商银行内蒙古赤峰市分行喀喇沁旗支行',
        value: '102194700014|1504|内蒙古自治区|赤峰市'
      },
      {
        label: '中国工商银行内蒙古赤峰市分行宁城县支行',
        value: '102194800015|1504|内蒙古自治区|赤峰市'
      },
      {
        label: '中国工商银行内蒙古赤峰市分行敖汉旗支行',
        value: '102194900016|1504|内蒙古自治区|赤峰市'
      },
      {
        label: '中国工商银行呼伦贝尔市分行核算中心',
        value: '102196003002|1507|内蒙古自治区|呼伦贝尔市'
      },
      {
        label: '中国工商银行呼伦贝尔市分行河东支行',
        value: '102196003019|1507|内蒙古自治区|呼伦贝尔市'
      },
      {
        label: '中国工商银行呼伦贝尔分行海拉尔区河西支行',
        value: '102196003027|1507|内蒙古自治区|呼伦贝尔市'
      },
      {
        label: '中国工商银行呼伦贝尔分行海拉尔区铁道支行',
        value: '102196003043|1507|内蒙古自治区|呼伦贝尔市'
      },
      {
        label: '中国工商银行股份有限公司呼伦贝尔安居支行',
        value: '102196003051|1507|内蒙古自治区|呼伦贝尔市'
      },
      {
        label: '中国工商银行呼伦贝尔分行海拉尔区中央街支行',
        value: '102196003094|1507|内蒙古自治区|呼伦贝尔市'
      },
      {
        label: '中国工商银行股份有限公司呼伦贝尔分行营业室',
        value: '102196004110|1507|内蒙古自治区|呼伦贝尔市'
      },
      {
        label: '中国工商银行股份有限公司呼伦贝尔海晨支行',
        value: '102196028227|1507|内蒙古自治区|呼伦贝尔市'
      },
      {
        label: '中国工商银行股份有限公司呼伦贝尔学府路支行',
        value: '102196028350|1507|内蒙古自治区|呼伦贝尔市'
      },
      {
        label: '中国工商银行股份有限公司呼伦贝尔西大街支行',
        value: '102196028866|1507|内蒙古自治区|呼伦贝尔市'
      },
      {
        label: '中国工商银行股份有限公司呼伦贝尔城南支行',
        value: '102196028946|1507|内蒙古自治区|呼伦贝尔市'
      },
      {
        label: '中国工商银行股份有限公司呼伦贝尔中央桥支行',
        value: '102196028987|1507|内蒙古自治区|呼伦贝尔市'
      },
      {
        label: '中国工商银行股份有限公司呼伦贝尔广场支行',
        value: '102196030006|1507|内蒙古自治区|呼伦贝尔市'
      },
      {
        label: '中国工商银行满洲里营业部',
        value: '102196203535|1507|内蒙古自治区|呼伦贝尔市'
      },
      {
        label: '中国工商银行满洲里扎赉诺尔支行',
        value: '102196203869|1507|内蒙古自治区|呼伦贝尔市'
      },
      {
        label: '中国工商银行满洲里业务处理中心',
        value: '102196204509|1507|内蒙古自治区|呼伦贝尔市'
      },
      {
        label: '中国工商银行股份有限公司满洲里兴华支行',
        value: '102196208223|1507|内蒙古自治区|呼伦贝尔市'
      },
      {
        label: '中国工商银行股份有限公司满洲里尚都支行',
        value: '102196208231|1507|内蒙古自治区|呼伦贝尔市'
      },
      {
        label: '中国工商银行哈尔滨市和平支行',
        value: '102261001264|2301|黑龙江省|哈尔滨市'
      },
      {
        label: '中国工商银行股份有限公司哈尔滨和顺支行',
        value: '102261001272|2301|黑龙江省|哈尔滨市'
      },
      {
        label: '中国工商银行股份有限公司哈尔滨农垦支行',
        value: '102261001289|2301|黑龙江省|哈尔滨市'
      },
      {
        label: '中国工商银行哈尔滨市红旗支行',
        value: '102261001297|2301|黑龙江省|哈尔滨市'
      },
      {
        label: '中国工商银行哈尔滨市东直支行',
        value: '102261001301|2301|黑龙江省|哈尔滨市'
      },
      {
        label: '中国工商银行股份有限公司嘉兴科技城支行',
        value: '102335006690|3304|浙江省|嘉兴市'
      },
      {
        label: '中国工商银行股份有限公司乌鲁木齐鲤鱼山路支行',
        value: '102881001538|6501|新疆维吾尔自治区|乌鲁木齐市'
      },
      {
        label: '中国工商银行股份有限公司乌鲁木齐温泉路支行',
        value: '102881001618|6501|新疆维吾尔自治区|乌鲁木齐市'
      },
      {
        label: '中国工商银行股份有限公司乌鲁木齐南湖广场支行',
        value: '102881001634|6501|新疆维吾尔自治区|乌鲁木齐市'
      },
      {
        label: '中国工商银行股份有限公司乌鲁木齐红山路支行',
        value: '102881001659|6501|新疆维吾尔自治区|乌鲁木齐市'
      },
      {
        label: '中国工商银行股份有限公司乌鲁木齐西虹东路支行',
        value: '102881001675|6501|新疆维吾尔自治区|乌鲁木齐市'
      },
      {
        label: '中国工商银行股份有限公司乌鲁木齐亚中支行',
        value: '102881001683|6501|新疆维吾尔自治区|乌鲁木齐市'
      },
      {
        label: '中国工商银行股份有限公司乌鲁木齐人民路支行',
        value: '102881001691|6501|新疆维吾尔自治区|乌鲁木齐市'
      },
      {
        label: '中国工商银行股份有限公司乌鲁木齐八一路支行',
        value: '102881001714|6501|新疆维吾尔自治区|乌鲁木齐市'
      },
      {
        label: '中国工商银行股份有限公司乌鲁木齐火车西站支行',
        value: '102881001722|6501|新疆维吾尔自治区|乌鲁木齐市'
      },
      {
        label: '中国工商银行股份有限公司乌鲁木齐杭州路支行',
        value: '102881001819|6501|新疆维吾尔自治区|乌鲁木齐市'
      },
      {
        label: '中国工商银行股份有限公司乌鲁木齐东山支行',
        value: '102881001827|6501|新疆维吾尔自治区|乌鲁木齐市'
      },
      {
        label: '中国工商银行股份有限公司乌鲁木齐卡子湾支行',
        value: '102881001835|6501|新疆维吾尔自治区|乌鲁木齐市'
      },
      {
        label: '中国工商银行股份有限公司乌鲁木齐铁道支行',
        value: '102881001917|6501|新疆维吾尔自治区|乌鲁木齐市'
      },
      {
        label: '中国工商银行股份有限公司乌鲁木齐石油新村支行',
        value: '102881001925|6501|新疆维吾尔自治区|乌鲁木齐市'
      },
      {
        label: '中国工商银行股份有限公司乌鲁木齐北京路支行',
        value: '102881001933|6501|新疆维吾尔自治区|乌鲁木齐市'
      },
      {
        label: '中国工商银行股份有限公司乌鲁木齐北京南路支行',
        value: '102881001950|6501|新疆维吾尔自治区|乌鲁木齐市'
      },
      {
        label: '中国工商银行股份有限公司乌鲁木齐迎宾路支行',
        value: '102881001968|6501|新疆维吾尔自治区|乌鲁木齐市'
      },
      {
        label: '中国工商银行股份有限公司乌鲁木齐天津路支行',
        value: '102881001976|6501|新疆维吾尔自治区|乌鲁木齐市'
      },
      {
        label: '中国工商银行股份有限公司乌鲁木齐科技城支行',
        value: '102881001984|6501|新疆维吾尔自治区|乌鲁木齐市'
      },
      {
        label: '中国工商银行股份有限公司乌鲁木齐东风路支行',
        value: '102881002016|6501|新疆维吾尔自治区|乌鲁木齐市'
      },
      {
        label: '中国工商银行股份有限公司乌鲁木齐天池路支行',
        value: '102881002145|6501|新疆维吾尔自治区|乌鲁木齐市'
      },
      {
        label: '中国工商银行股份有限公司乌鲁木齐延安路支行',
        value: '102881002153|6501|新疆维吾尔自治区|乌鲁木齐市'
      },
      {
        label: '中国工商银行股份有限公司乌鲁木齐幸福南路支行',
        value: '102881002161|6501|新疆维吾尔自治区|乌鲁木齐市'
      },
      {
        label: '中国工商银行股份有限公司乌鲁木齐河南东路支行',
        value: '102881002188|6501|新疆维吾尔自治区|乌鲁木齐市'
      },
      {
        label: '中国工商银行股份有限公司乌鲁木齐青年东路支行',
        value: '102881002196|6501|新疆维吾尔自治区|乌鲁木齐市'
      },
      {
        label: '中国工商银行兴义红旗支行',
        value: '102707000046|5223|贵州省|黔西南布依族苗族自治州'
      },
      {
        label: '中国工商银行股份有限公司兴义体育城支行',
        value: '102707000054|5223|贵州省|黔西南布依族苗族自治州'
      },
      {
        label: '中国工商银行兴义坪东支行',
        value: '102707000062|5223|贵州省|黔西南布依族苗族自治州'
      },
      {
        label: '中国工商银行兴义场坝支行',
        value: '102707001014|5223|贵州省|黔西南布依族苗族自治州'
      },
      {
        label: '中国工商银行兴义桔山支行',
        value: '102707001063|5223|贵州省|黔西南布依族苗族自治州'
      },
      {
        label: '中国工商银行兴义富民路支行',
        value: '102707001135|5223|贵州省|黔西南布依族苗族自治州'
      },
      {
        label: '中国工商银行兴义万峰支行',
        value: '102707001151|5223|贵州省|黔西南布依族苗族自治州'
      },
      {
        label: '中国工商银行股份有限公司兴义兴泰支行',
        value: '102707001389|5223|贵州省|黔西南布依族苗族自治州'
      },
      {
        label: '中国工商银行贵州兴义分行',
        value: '102707005007|5223|贵州省|黔西南布依族苗族自治州'
      },
      {
        label: '中国工商银行兴义瑞金路支行',
        value: '102707005015|5223|贵州省|黔西南布依族苗族自治州'
      },
      {
        label: '中国工商银行兴义神奇支行',
        value: '102707005023|5223|贵州省|黔西南布依族苗族自治州'
      },
      {
        label: '中国工商银行兴仁支行',
        value: '102707200101|5223|贵州省|黔西南布依族苗族自治州'
      },
      {
        label: '中国工商银行普安支行',
        value: '102707300119|5223|贵州省|黔西南布依族苗族自治州'
      },
      {
        label: '中国工商银行晴隆支行',
        value: '102707400162|5223|贵州省|黔西南布依族苗族自治州'
      },
      {
        label: '中国工商银行贞丰支行',
        value: '102707500153|5223|贵州省|黔西南布依族苗族自治州'
      },
      {
        label: '中国工商银行望谟支行',
        value: '102707600148|5223|贵州省|黔西南布依族苗族自治州'
      },
      {
        label: '中国工商银行册亨支行',
        value: '102707700075|5223|贵州省|黔西南布依族苗族自治州'
      },
      {
        label: '中国工商银行安龙支行',
        value: '102707800085|5223|贵州省|黔西南布依族苗族自治州'
      },
      {
        label: '中国工商银行贵州省毕节分行营业部',
        value: '102709000015|5205|贵州省|毕节市'
      },
      {
        label: '中国工商银行股份有限公司乌鲁木齐民航机场支行',
        value: '102881002977|6501|新疆维吾尔自治区|乌鲁木齐市'
      },
      {
        label: '中国工商银行股份有限公司乌鲁木齐长春路支行',
        value: '102881003011|6501|新疆维吾尔自治区|乌鲁木齐市'
      },
      {
        label: '中国工商银行股份有限公司乌鲁木齐常州街支行',
        value: '102881003020|6501|新疆维吾尔自治区|乌鲁木齐市'
      },
      {
        label: '中国工商银行股份有限公司乌鲁木齐卫星路支行',
        value: '102881003038|6501|新疆维吾尔自治区|乌鲁木齐市'
      },
      {
        label: '中国工商银行股份有限公司乌鲁木齐文艺路支行',
        value: '102881003046|6501|新疆维吾尔自治区|乌鲁木齐市'
      },
      {
        label: '中国工商银行股份有限公司乌鲁木齐河北东路支行',
        value: '102881003054|6501|新疆维吾尔自治区|乌鲁木齐市'
      },
      {
        label: '中国工商银行股份有限公司乌鲁木齐安居支行',
        value: '102881008785|6501|新疆维吾尔自治区|乌鲁木齐市'
      },
      {
        label: '中国工商银行股份有限公司乌鲁木齐蓝天支行',
        value: '102881008793|6501|新疆维吾尔自治区|乌鲁木齐市'
      },
      {
        label: '中国工商银行股份有限公司乌鲁木齐振兴路支行',
        value: '102881081238|6501|新疆维吾尔自治区|乌鲁木齐市'
      },
      {
        label: '中国工商银行股份有限公司克拉玛依石油分行业务处理中心',
        value: '102882000012|6502|新疆维吾尔自治区|克拉玛依市'
      },
      {
        label: '中国工商银行股份有限公司克拉玛依大十字支行',
        value: '102882000029|6502|新疆维吾尔自治区|克拉玛依市'
      },
      {
        label: '中国工商银行股份有限公司克拉玛依石油分行',
        value: '102882000037|6502|新疆维吾尔自治区|克拉玛依市'
      },
      {
        label: '中国工商银行股份有限公司克拉玛依东风支行',
        value: '102882000045|6502|新疆维吾尔自治区|克拉玛依市'
      },
      {
        label: '中国工商银行股份有限公司克拉玛依三八支行',
        value: '102882000053|6502|新疆维吾尔自治区|克拉玛依市'
      },
      {
        label: '中国工商银行股份有限公司克拉玛依阳光支行',
        value: '102882000061|6502|新疆维吾尔自治区|克拉玛依市'
      },
      {
        label: '中国工商银行股份有限公司克拉玛依友谊路支行',
        value: '102882000088|6502|新疆维吾尔自治区|克拉玛依市'
      },
      {
        label: '中国工商银行股份有限公司克拉玛依大学城支行',
        value: '102882000107|6502|新疆维吾尔自治区|克拉玛依市'
      },
      {
        label: '中国工商银行股份有限公司克拉玛依迎宾支行',
        value: '102882000115|6502|新疆维吾尔自治区|克拉玛依市'
      },
      {
        label: '中国工商银行盐城开发区支行',
        value: '102311066317|3209|江苏省|盐城市'
      },
      {
        label: '中国工商银行盐城新区支行',
        value: '102311066413|3209|江苏省|盐城市'
      },
      {
        label: '中国工商银行股份有限公司鸡西市东风路支行',
        value: '102266002203|2303|黑龙江省|鸡西市'
      },
      {
        label: '中国工商银行鸡西分行鸡东县支行',
        value: '102266102101|2303|黑龙江省|鸡西市'
      },
      {
        label: '中国工商银行股份有限公司鹤岗向秀丽支行',
        value: '102267000027|2304|黑龙江省|鹤岗市'
      },
      {
        label: '中国工商银行股份有限公司鹤岗分行',
        value: '102267000035|2304|黑龙江省|鹤岗市'
      },
      {
        label: '中国工商银行股份有限公司鹤岗大陆支行',
        value: '102267000043|2304|黑龙江省|鹤岗市'
      },
      {
        label: '中国工商银行股份有限公司鹤岗新南支行',
        value: '102267000051|2304|黑龙江省|鹤岗市'
      },
      {
        label: '中国工商银行股份有限公司鹤岗南山支行',
        value: '102267000060|2304|黑龙江省|鹤岗市'
      },
      {
        label: '中国工商银行股份有限公司鹤岗兴安台支行',
        value: '102267000078|2304|黑龙江省|鹤岗市'
      },
      {
        label: '中国工商银行股份有限公司鹤岗新一支行',
        value: '102267000086|2304|黑龙江省|鹤岗市'
      },
      {
        label: '中国工商银行股份有限公司鹤岗新世纪广场支行',
        value: '102267000094|2304|黑龙江省|鹤岗市'
      },
      {
        label: '中国工商银行鹤岗兴山路分理处',
        value: '102267000125|2304|黑龙江省|鹤岗市'
      },
      {
        label: '中国工商银行股份有限公司鹤岗振兴广场支行',
        value: '102267000248|2304|黑龙江省|鹤岗市'
      },
      {
        label: '中国工商银行鹤岗市分行核算中心',
        value: '102267001005|2304|黑龙江省|鹤岗市'
      },
      {
        label: '中国工商银行股份有限公司萝北支行',
        value: '102267100011|2304|黑龙江省|鹤岗市'
      },
      {
        label: '中国工商银行股份有限公司鹤岗宝泉岭支行',
        value: '102267100020|2304|黑龙江省|鹤岗市'
      },
      {
        label: '中国工商银行股份有限公司萝北鹤北分理处',
        value: '102267100038|2304|黑龙江省|鹤岗市'
      },
      {
        label: '中国工商银行股份有限公司萝北石墨工业园区分理处',
        value: '102267100046|2304|黑龙江省|鹤岗市'
      },
      {
        label: '中国工商银行股份有限公司绥滨支行',
        value: '102267200012|2304|黑龙江省|鹤岗市'
      },
      {
        label: '中国工商银行股份有限公司双鸭山分行',
        value: '102268000012|2305|黑龙江省|双鸭山市'
      },
      {
        label: '中国工商银行股份有限公司双鸭山鑫兴支行',
        value: '102268000029|2305|黑龙江省|双鸭山市'
      },
      {
        label: '中国工商银行股份有限公司双鸭山尖山支行',
        value: '102268000037|2305|黑龙江省|双鸭山市'
      },
      {
        label: '中国工商银行股份有限公司双鸭山市分行牡丹支行',
        value: '102268000045|2305|黑龙江省|双鸭山市'
      },
      {
        label: '中国工商银行股份有限公司双鸭山市分行电厂支行',
        value: '102268000061|2305|黑龙江省|双鸭山市'
      },
      {
        label: '中国工商银行股份有限公司双鸭山红兴隆支行',
        value: '102268000287|2305|黑龙江省|双鸭山市'
      },
      {
        label: '中国工商银行股份有限公司双鸭山市分行集贤支行',
        value: '102268100013|2305|黑龙江省|双鸭山市'
      },
      {
        label: '中国工商银行股份有限公司佳木斯中山分理处',
        value: '102269000022|2306|黑龙江省|大庆市'
      },
      {
        label: '中国工商银行股份有限公司佳木斯西菜市分理处',
        value: '102269000039|2306|黑龙江省|大庆市'
      },
      {
        label: '中国工商银行股份有限公司佳木斯佳西分理处',
        value: '102269000186|2306|黑龙江省|大庆市'
      },
      {
        label: '中国工商银行股份有限公司佳木斯兴财分理处',
        value: '102269000217|2306|黑龙江省|大庆市'
      },
      {
        label: '中国工商银行股份有限公司佳木斯永和分理处',
        value: '102269000321|2306|黑龙江省|大庆市'
      },
      {
        label: '中国工商银行股份有限公司无锡新吴支行',
        value: '102302002084|3202|江苏省|无锡市'
      },
      {
        label: '中国工商银行股份有限公司无锡南长支行',
        value: '102302002092|3202|江苏省|无锡市'
      },
      {
        label: '中国工商银行股份有限公司无锡吴桥支行',
        value: '102302002105|3202|江苏省|无锡市'
      },
      {
        label: '中国工商银行股份有限公司无锡滨湖支行',
        value: '102302002113|3202|江苏省|无锡市'
      },
      {
        label: '中国工商银行股份有限公司无锡太湖国家旅游度假区支行',
        value: '102302002121|3202|江苏省|无锡市'
      },
      {
        label: '中国工商银行股份有限公司无锡瑞景道支行',
        value: '102302002130|3202|江苏省|无锡市'
      },
      {
        label: '中国工商银行股份有限公司无锡长街支行',
        value: '102302002148|3202|江苏省|无锡市'
      },
      {
        label: '中国工商银行股份有限公司无锡会龙桥支行',
        value: '102302002156|3202|江苏省|无锡市'
      },
      {
        label: '中国工商银行股份有限公司无锡胡埭支行',
        value: '102302002172|3202|江苏省|无锡市'
      },
      {
        label: '中国工商银行股份有限公司无锡梁青支行',
        value: '102302002201|3202|江苏省|无锡市'
      },
      {
        label: '中国工商银行股份有限公司无锡清扬支行',
        value: '102302002252|3202|江苏省|无锡市'
      },
      {
        label: '中国工商银行股份有限公司无锡惠钱路分理处',
        value: '102302002324|3202|江苏省|无锡市'
      },
      {
        label: '中国工商银行股份有限公司无锡蠡园开发区支行',
        value: '102302002332|3202|江苏省|无锡市'
      },
      {
        label: '中国工商银行股份有限公司无锡硕放支行',
        value: '102302002349|3202|江苏省|无锡市'
      },
      {
        label: '中国工商银行股份有限公司无锡城南支行',
        value: '102302002357|3202|江苏省|无锡市'
      },
      {
        label: '中国工商银行股份有限公司济南泺源支行',
        value: '102451002338|3701|山东省|济南市'
      },
      {
        label: '中国工商银行股份有限公司南安美林支行',
        value: '102397326171|3505|福建省|泉州市'
      },
      {
        label: '中国工商银行股份有限公司南安水头滨海支行',
        value: '102397326198|3505|福建省|泉州市'
      },
      {
        label: '中国工商银行股份有限公司南安霞美支行',
        value: '102397326823|3505|福建省|泉州市'
      },
      {
        label: '中国工商银行安溪县支行',
        value: '102397421613|3505|福建省|泉州市'
      },
      {
        label: '中国工商银行股份有限公司安溪美法支行',
        value: '102397423221|3505|福建省|泉州市'
      },
      {
        label: '中国工商银行股份有限公司安溪大同支行',
        value: '102397423297|3505|福建省|泉州市'
      },
      {
        label: '中国工商银行股份有限公司安溪官桥支行',
        value: '102397423344|3505|福建省|泉州市'
      },
      {
        label: '中国工商银行股份有限公司安溪民主路支行',
        value: '102397425170|3505|福建省|泉州市'
      },
      {
        label: '中国工商银行永春县支行',
        value: '102397521717|3505|福建省|泉州市'
      },
      {
        label: '中国工商银行永春五里街支行',
        value: '102397521725|3505|福建省|泉州市'
      },
      {
        label: '中国工商银行股份有限公司永春达埔支行',
        value: '102397523838|3505|福建省|泉州市'
      },
      {
        label: '中国工商银行股份有限公司永春桃城支行',
        value: '102397523887|3505|福建省|泉州市'
      },
      {
        label: '中国工商银行股份有限公司永春城关支行',
        value: '102397523895|3505|福建省|泉州市'
      },
      {
        label: '中国工商银行德化县支行',
        value: '102397621801|3505|福建省|泉州市'
      },
      {
        label: '中国工商银行股份有限公司鞍山爱民支行',
        value: '102223000273|2103|辽宁省|鞍山市'
      },
      {
        label: '中国工商银行股份有限公司清远上廓支行',
        value: '102601002050|4418|广东省|清远市'
      },
      {
        label: '中国工商银行股份有限公司清远新城支行',
        value: '102601002068|4418|广东省|清远市'
      },
      {
        label: '中国工商银行股份有限公司清远琶江支行',
        value: '102601002076|4418|广东省|清远市'
      },
      {
        label: '中国工商银行股份有限公司清远北江支行',
        value: '102601002092|4418|广东省|清远市'
      },
      {
        label: '中国工商银行股份有限公司清远石角支行',
        value: '102601002113|4418|广东省|清远市'
      },
      {
        label: '中国工商银行股份有限公司清远银盏支行',
        value: '102601002373|4418|广东省|清远市'
      },
      {
        label: '中国工商银行股份有限公司清远清城支行',
        value: '102601002646|4418|广东省|清远市'
      },
      {
        label: '中国工商银行股份有限公司清远东江支行',
        value: '102601002726|4418|广东省|清远市'
      },
      {
        label: '中国工商银行股份有限公司佛冈支行',
        value: '102601102219|4418|广东省|清远市'
      },
      {
        label: '中国工商银行股份有限公司永州滨江支行',
        value: '102565000511|4311|湖南省|永州市'
      },
      {
        label: '中国工商银行股份有限公司永州电力支行',
        value: '102565000520|4311|湖南省|永州市'
      },
      {
        label: '中国工商银行股份有限公司永州湘跃支行',
        value: '102565000634|4311|湖南省|永州市'
      },
      {
        label: '中国工商银行股份有限公司永州徐家井支行',
        value: '102565002167|4311|湖南省|永州市'
      },
      {
        label: '中国工商银行股份有限公司永州零陵中路支行',
        value: '102565002175|4311|湖南省|永州市'
      },
      {
        label: '中国工商银行股份有限公司永州张家铺支行',
        value: '102565002183|4311|湖南省|永州市'
      },
      {
        label: '中国工商银行股份有限公司永州分行',
        value: '102565005001|4311|湖南省|永州市'
      },
      {
        label: '中国工商银行股份有限公司广州中荔支行',
        value: '102581001266|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州中山六路支行',
        value: '102581001274|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州华南理工大学支行',
        value: '102581001282|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州府前路支行',
        value: '102581001311|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州石牌支行',
        value: '102581001346|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州泰康路支行',
        value: '102581001354|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州水荫路支行',
        value: '102581001362|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州科技园支行',
        value: '102581001379|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州华景新城支行',
        value: '102581001387|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州天河工业园支行',
        value: '102581001395|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州汇侨新城支行',
        value: '102581001426|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州东环支行',
        value: '102581001434|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州永平支行',
        value: '102581001442|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州太阳广场支行',
        value: '102581001459|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州花都新华支行',
        value: '102581001467|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州花都雅居乐支行',
        value: '102581001483|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州花都狮岭支行',
        value: '102581001491|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州中信支行',
        value: '102581001522|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州海印支行',
        value: '102581001563|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州西城支行',
        value: '102581001571|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州以太广场支行',
        value: '102581001602|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州花都城区支行',
        value: '102581001619|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州利通支行',
        value: '102581001660|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州执信南路支行',
        value: '102581001694|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州东华东路支行',
        value: '102581001717|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州东湖支行',
        value: '102581001725|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州三角市支行',
        value: '102581001768|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州番禺新城支行',
        value: '102581001784|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州番禺兴泰支行',
        value: '102581001792|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司东莞大岭山支行',
        value: '102602002732|4419|广东省|东莞市'
      },
      {
        label: '中国工商银行股份有限公司东莞中堂支行',
        value: '102602002757|4419|广东省|东莞市'
      },
      {
        label: '中国工商银行股份有限公司东莞麻涌支行',
        value: '102602002790|4419|广东省|东莞市'
      },
      {
        label: '中国工商银行股份有限公司东莞望牛墩支行',
        value: '102602002812|4419|广东省|东莞市'
      },
      {
        label: '中国工商银行股份有限公司东莞道窖支行',
        value: '102602002837|4419|广东省|东莞市'
      },
      {
        label: '中国工商银行股份有限公司东莞洪梅支行',
        value: '102602002853|4419|广东省|东莞市'
      },
      {
        label: '中国工商银行股份有限公司东莞塘厦支行',
        value: '102602002870|4419|广东省|东莞市'
      },
      {
        label: '中国工商银行股份有限公司东莞清溪支行',
        value: '102602002896|4419|广东省|东莞市'
      },
      {
        label: '中国工商银行股份有限公司东莞谢岗支行',
        value: '102602002915|4419|广东省|东莞市'
      },
      {
        label: '中国工商银行股份有限公司张家界武陵源支行',
        value: '102559001069|4308|湖南省|张家界市'
      },
      {
        label: '中国工商银行股份有限公司张家界官黎坪支行',
        value: '102559001411|4308|湖南省|张家界市'
      },
      {
        label: '中国工商银行湖南省张家界市分行',
        value: '102559005002|4308|湖南省|张家界市'
      },
      {
        label: '中国工商银行湖南省张家界市银行卡部',
        value: '102559005019|4308|湖南省|张家界市'
      },
      {
        label: '中国工商银行股份有限公司慈利城北支行',
        value: '102559100294|4308|湖南省|张家界市'
      },
      {
        label: '中国工商银行湖南省张家界市慈利县支行',
        value: '102559101035|4308|湖南省|张家界市'
      },
      {
        label: '中国工商银行湖南张家界桑植支行',
        value: '102559201107|4308|湖南省|张家界市'
      },
      {
        label: '中国工商银行股份有限公司益阳城中支行',
        value: '102561000320|4309|湖南省|益阳市'
      },
      {
        label: '中国工商银行股份有限公司嘉兴秀洲支行',
        value: '102335006807|3304|浙江省|嘉兴市'
      },
      {
        label: '中国工商银行股份有限公司嘉兴中山支行',
        value: '102335006815|3304|浙江省|嘉兴市'
      },
      {
        label: '中国工商银行股份有限公司嘉兴经济开发区支行',
        value: '102335006823|3304|浙江省|嘉兴市'
      },
      {
        label: '中国工商银行股份有限公司嘉兴真如支行',
        value: '102335006840|3304|浙江省|嘉兴市'
      },
      {
        label: '中国工商银行股份有限公司嘉兴王江泾支行',
        value: '102335006858|3304|浙江省|嘉兴市'
      },
      {
        label: '中国工商银行股份有限公司嘉兴运河新区支行',
        value: '102335006866|3304|浙江省|嘉兴市'
      },
      {
        label: '中国工商银行安庆分行业务处理中心',
        value: '102368000010|3408|安徽省|安庆市'
      },
      {
        label: '中国工商银行安庆分行人民路支行',
        value: '102368000411|3408|安徽省|安庆市'
      },
      {
        label: '中国工商银行安庆分行开发区支行',
        value: '102368000438|3408|安徽省|安庆市'
      },
      {
        label: '中国工商银行股份有限公司十堰柳林支行',
        value: '102523000541|4203|湖北省|十堰市'
      },
      {
        label: '中国工商银行股份有限公司十堰五四路支行',
        value: '102523000550|4203|湖北省|十堰市'
      },
      {
        label: '中国工商银行股份有限公司十堰车城路支行',
        value: '102523000576|4203|湖北省|十堰市'
      },
      {
        label: '中国工商银行十堰车桥厂支行',
        value: '102523000605|4203|湖北省|十堰市'
      },
      {
        label: '中国工商银行股份有限公司十堰锻造厂支行',
        value: '102523000621|4203|湖北省|十堰市'
      },
      {
        label: '中国工商银行十堰铸造二厂支行',
        value: '102523000630|4203|湖北省|十堰市'
      },
      {
        label: '中国工商银行股份有限公司十堰中岳支行',
        value: '102523000752|4203|湖北省|十堰市'
      },
      {
        label: '中国工商银行股份有限公司成都东大支行金福路分理处',
        value: '102651008653|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都西二巷支行',
        value: '102651008740|5101|四川省|成都市'
      },
      {
        label: '中国工商银行成都市三洞桥分理处',
        value: '102651008774|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都西南航空港支行',
        value: '102651008799|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都西南交通大学支行',
        value: '102651008854|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司都江堰幸福家园支行',
        value: '102651008879|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司都江堰支行江安河东路分理处',
        value: '102651008895|5101|四川省|成都市'
      },
      {
        label: '中国工商银行成都市草市支行营业室',
        value: '102651020205|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都建设南路支行',
        value: '102651020289|5101|四川省|成都市'
      },
      {
        label: '中国工商银行成都市红牌楼分理处',
        value: '102651020301|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都天府支行',
        value: '102651020328|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都武青北路支行',
        value: '102651020393|5101|四川省|成都市'
      },
      {
        label: '中国工商银行随州曾都支行',
        value: '102528600029|4213|湖北省|随州市'
      },
      {
        label: '中国工商银行股份有限公司随州东城支行',
        value: '102528600037|4213|湖北省|随州市'
      },
      {
        label: '中国工商银行股份有限公司随州南关口支行',
        value: '102528600045|4213|湖北省|随州市'
      },
      {
        label: '中国工商银行股份有限公司随州神农支行',
        value: '102528600053|4213|湖北省|随州市'
      },
      {
        label: '中国工商银行股份有限公司随州牡丹支行',
        value: '102528600061|4213|湖北省|随州市'
      },
      {
        label: '中国工商银行股份有限公司随州南郊支行',
        value: '102528600070|4213|湖北省|随州市'
      },
      {
        label: '中国工商银行股份有限公司随州淅河支行',
        value: '102528600088|4213|湖北省|随州市'
      },
      {
        label: '中国工商银行股份有限公司随州解放路支行',
        value: '102528600107|4213|湖北省|随州市'
      },
      {
        label: '中国工商银行股份有限公司随州花溪桥支行',
        value: '102528600115|4213|湖北省|随州市'
      },
      {
        label: '中国工商银行随州车站路支行',
        value: '102528600140|4213|湖北省|随州市'
      },
      {
        label: '中国工商银行股份有限公司随州清河路支行',
        value: '102528600174|4213|湖北省|随州市'
      },
      {
        label: '中国工商银行股份有限公司随州开发区支行',
        value: '102528600199|4213|湖北省|随州市'
      },
      {
        label: '中国工商银行股份有限公司随县支行',
        value: '102528600203|4206|湖北省|襄阳市'
      },
      {
        label: '中国工商银行股份有限公司随州分行业务处理中心',
        value: '102528605008|4213|湖北省|随州市'
      },
      {
        label: '中国工商银行老河口市支行',
        value: '102528700802|4206|湖北省|襄阳市'
      },
      {
        label: '中国工商银行股份有限公司老河口中山路支行',
        value: '102528700819|4206|湖北省|襄阳市'
      },
      {
        label: '中国工商银行股份有限公司老河口市府路支行',
        value: '102528700827|4206|湖北省|襄阳市'
      },
      {
        label: '中国工商银行枣阳市支行',
        value: '102528800715|4206|湖北省|襄阳市'
      },
      {
        label: '中国工商银行股份有限公司枣阳北城支行',
        value: '102528800723|4206|湖北省|襄阳市'
      },
      {
        label: '中国工商银行股份有限公司枣阳南城支行',
        value: '102528800731|4206|湖北省|襄阳市'
      },
      {
        label: '中国工商银行股份有限公司枣阳工业区支行',
        value: '102528800740|4206|湖北省|襄阳市'
      },
      {
        label: '中国工商银行股份有限公司枣阳火车站支行',
        value: '102528800766|4206|湖北省|襄阳市'
      },
      {
        label: '中国工商银行股份有限公司枣阳人民路支行',
        value: '102528800774|4206|湖北省|襄阳市'
      },
      {
        label: '中国工商银行股份有限公司枣阳汽车站支行',
        value: '102528800782|4206|湖北省|襄阳市'
      },
      {
        label: '中国工商银行股份有限公司枣阳人民南路支行',
        value: '102528800799|4206|湖北省|襄阳市'
      },
      {
        label: '中国工商银行鄂州吴都支行',
        value: '102531000015|4207|湖北省|鄂州市'
      },
      {
        label: '中国工商银行鄂州西山支行',
        value: '102531000023|4207|湖北省|鄂州市'
      },
      {
        label: '中国工商银行股份有限公司鄂州交通支行',
        value: '102531000040|4207|湖北省|鄂州市'
      },
      {
        label: '中国工商银行绍兴市城东支行中兴南路分理处',
        value: '102337001823|3306|浙江省|绍兴市'
      },
      {
        label: '中国工商银行股份有限公司绍兴江滨支行',
        value: '102337002377|3306|浙江省|绍兴市'
      },
      {
        label: '中国工商银行股份有限公司绍兴袍江支行',
        value: '102337003208|3306|浙江省|绍兴市'
      },
      {
        label: '中国工商银行股份有限公司绍兴皋埠支行',
        value: '102337004459|3306|浙江省|绍兴市'
      },
      {
        label: '中国工商银行股份有限公司绍兴北复线支行',
        value: '102337004467|3306|浙江省|绍兴市'
      },
      {
        label: '中国工商银行绍兴县支行',
        value: '102337102006|3306|浙江省|绍兴市'
      },
      {
        label: '中国工商银行股份有限公司冷水江支行金竹路分理处',
        value: '102562210104|4313|湖南省|娄底市'
      },
      {
        label: '中国工商银行股份有限公司上蔡支行',
        value: '102511602622|4117|河南省|驻马店市'
      },
      {
        label: '中国工商银行股份有限公司上蔡蔡都支行',
        value: '102511612621|4117|河南省|驻马店市'
      },
      {
        label: '中国工商银行驻马店分行汝南县支行',
        value: '102511702184|4117|河南省|驻马店市'
      },
      {
        label: '中国工商银行驻马店分行平舆县支行',
        value: '102511802838|4117|河南省|驻马店市'
      },
      {
        label: '中国工商银行股份有限公司平舆工业路支行',
        value: '102511812839|4117|河南省|驻马店市'
      },
      {
        label: '中国工商银行驻马店分行新蔡县支行',
        value: '102511902730|4117|河南省|驻马店市'
      },
      {
        label: '中国工商银行股份有限公司新蔡驻新路支行',
        value: '102511912737|4117|河南省|驻马店市'
      },
      {
        label: '中国工商银行股份有限公司正阳支行',
        value: '102512102299|4117|河南省|驻马店市'
      },
      {
        label: '中国工商银行股份有限公司南阳行政支行',
        value: '102513002050|4113|河南省|南阳市'
      },
      {
        label: '中国工商银行南阳分行卧龙区支行',
        value: '102513002105|4113|河南省|南阳市'
      },
      {
        label: '中国工商银行南阳分行宛城区支行',
        value: '102513002201|4113|河南省|南阳市'
      },
      {
        label: '中国工商银行股份有限公司南阳分行会计业务处理中心',
        value: '102513002687|4113|河南省|南阳市'
      },
      {
        label: '中国工商银行南阳分行河南油田支行',
        value: '102513002855|4113|河南省|南阳市'
      },
      {
        label: '中国工商银行股份有限公司南阳分行',
        value: '102513002902|4113|河南省|南阳市'
      },
      {
        label: '中国工商银行股份有限公司南阳工业北路支行',
        value: '102513012107|4113|河南省|南阳市'
      },
      {
        label: '中国工商银行股份有限公司河南油田江河支行',
        value: '102513012852|4113|河南省|南阳市'
      },
      {
        label: '中国工商银行股份有限公司南阳新华支行',
        value: '102513012908|4113|河南省|南阳市'
      },
      {
        label: '中国工商银行股份有限公司南阳中州支行',
        value: '102513013904|4113|河南省|南阳市'
      },
      {
        label: '中国工商银行股份有限公司南阳工业南路支行',
        value: '102513022106|4113|河南省|南阳市'
      },
      {
        label: '中国工商银行股份有限公司南阳建设路支行',
        value: '102513022202|4113|河南省|南阳市'
      },
      {
        label: '中国工商银行股份有限公司河南油田大庆路支行',
        value: '102513032851|4113|河南省|南阳市'
      },
      {
        label: '中国工商银行股份有限公司南阳棉纺支行',
        value: '102513042206|4113|河南省|南阳市'
      },
      {
        label: '中国工商银行股份有限公司南阳车站路支行',
        value: '102513052905|4113|河南省|南阳市'
      },
      {
        label: '中国工商银行股份有限公司潜江油田处支行',
        value: '102537511349|4210|湖北省|荆州市'
      },
      {
        label: '中国工商银行股份有限公司平湖开发区支行',
        value: '102335208034|3304|浙江省|嘉兴市'
      },
      {
        label: '中国工商银行股份有限公司平湖百步支行',
        value: '102335208042|3304|浙江省|嘉兴市'
      },
      {
        label: '中国工商银行股份有限公司海口海甸二东路支行',
        value: '102641000547|4601|海南省|海口市'
      },
      {
        label: '中国工商银行股份有限公司海口南大桥支行',
        value: '102641000555|4601|海南省|海口市'
      },
      {
        label: '中国工商银行股份有限公司海口机场东路支行',
        value: '102641000571|4601|海南省|海口市'
      },
      {
        label: '中国工商银行股份有限公司昌江铁矿支行',
        value: '102641000619|4601|海南省|海口市'
      },
      {
        label: '中国工商银行股份有限公司海口亿圣和支行',
        value: '102641000741|4601|海南省|海口市'
      },
      {
        label: '中国工商银行股份有限公司海口解放路支行',
        value: '102641000750|4601|海南省|海口市'
      },
      {
        label: '中国工商银行股份有限公司海口椰润支行',
        value: '102641000821|4601|海南省|海口市'
      },
      {
        label: '中国工商银行股份有限公司海口银都支行',
        value: '102641000848|4601|海南省|海口市'
      },
      {
        label: '中国工商银行股份有限公司海口国度支行',
        value: '102641000910|4601|海南省|海口市'
      },
      {
        label: '中国工商银行股份有限公司海口龙华下路支行',
        value: '102641000936|4601|海南省|海口市'
      },
      {
        label: '中国工商银行股份有限公司海口中洋支行',
        value: '102641000944|4601|海南省|海口市'
      },
      {
        label: '中国工商银行股份有限公司海口秀华路支行',
        value: '102641000952|4601|海南省|海口市'
      },
      {
        label: '中国工商银行股份有限公司海口海垦路支行',
        value: '102641000969|4601|海南省|海口市'
      },
      {
        label: '中国工商银行股份有限公司海口海港支行',
        value: '102641000985|4601|海南省|海口市'
      },
      {
        label: '中国工商银行股份有限公司海口南海大道支行',
        value: '102641001062|4601|海南省|海口市'
      },
      {
        label: '中国工商银行股份有限公司海口红城湖路支行',
        value: '102641001087|4601|海南省|海口市'
      },
      {
        label: '中国工商银行股份有限公司海口海师支行',
        value: '102641001095|4601|海南省|海口市'
      },
      {
        label: '中国工商银行股份有限公司海口迎宾支行',
        value: '102641001126|4601|海南省|海口市'
      },
      {
        label: '中国工商银行股份有限公司文昌文中坡支行',
        value: '102641001142|4601|海南省|海口市'
      },
      {
        label: '中国工商银行股份有限公司文昌沿江支行',
        value: '102641001159|4601|海南省|海口市'
      },
      {
        label: '中国工商银行股份有限公司琼海银海支行',
        value: '102641001183|4601|海南省|海口市'
      },
      {
        label: '中国工商银行股份有限公司琼海人民路支行',
        value: '102641001191|4601|海南省|海口市'
      },
      {
        label: '中国工商银行股份有限公司海口金龙支行',
        value: '102641001298|4601|海南省|海口市'
      },
      {
        label: '中国工商银行股份有限公司文昌文城支行',
        value: '102641001378|4601|海南省|海口市'
      },
      {
        label: '中国工商银行股份有限公司文昌清澜支行',
        value: '102641001386|4601|海南省|海口市'
      },
      {
        label: '中国工商银行股份有限公司海口凤翔东路支行',
        value: '102641001409|4601|海南省|海口市'
      },
      {
        label: '中国工商银行股份有限公司琼海加积支行',
        value: '102641001417|4601|海南省|海口市'
      },
      {
        label: '中国工商银行股份有限公司琼海加祥支行',
        value: '102641001425|4601|海南省|海口市'
      },
      {
        label: '中国工商银行股份有限公司武汉兴业路支行',
        value: '102521003997|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行股份有限公司武汉移动支行',
        value: '102521004004|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行股份有限公司武汉七雄路支行',
        value: '102521004012|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行股份有限公司武汉藏龙岛支行',
        value: '102521004037|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行股份有限公司武汉海关支行',
        value: '102521004045|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行股份有限公司武汉巨龙支行',
        value: '102521004053|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行股份有限公司鞍山青年支行',
        value: '102223000281|2103|辽宁省|鞍山市'
      },
      {
        label: '中国工商银行股份有限公司珲春支行',
        value: '102249409014|2224|吉林省|延边朝鲜族自治州'
      },
      {
        label: '中国工商银行股份有限公司珲春龙源支行',
        value: '102249409022|2224|吉林省|延边朝鲜族自治州'
      },
      {
        label: '中国工商银行股份有限公司龙井支行',
        value: '102249508012|2224|吉林省|延边朝鲜族自治州'
      },
      {
        label: '中国工商银行股份有限公司和龙支行',
        value: '102249610015|2224|吉林省|延边朝鲜族自治州'
      },
      {
        label: '中国工商银行股份有限公司汪清支行',
        value: '102249706010|2224|吉林省|延边朝鲜族自治州'
      },
      {
        label: '中国工商银行股份有限公司延边长白山支行',
        value: '102249811010|2224|吉林省|延边朝鲜族自治州'
      },
      {
        label: '中国工商银行股份有限公司安图支行',
        value: '102249813015|2224|吉林省|延边朝鲜族自治州'
      },
      {
        label: '中国工商银行松原分行业务处理中心',
        value: '102252012017|2207|吉林省|松原市'
      },
      {
        label: '中国工商银行松原新城支行',
        value: '102252012105|2207|吉林省|松原市'
      },
      {
        label: '中国工商银行松原宁江支行',
        value: '102252012113|2207|吉林省|松原市'
      },
      {
        label: '中国工商银行松原吉林油田支行',
        value: '102252012148|2207|吉林省|松原市'
      },
      {
        label: '中国工商银行松原江北支行',
        value: '102252012156|2207|吉林省|松原市'
      },
      {
        label: '中国工商银行松原经济技术开发区支行',
        value: '102252012164|2207|吉林省|松原市'
      },
      {
        label: '中国工商银行股份有限公司松原镜湖支行',
        value: '102252012172|2207|吉林省|松原市'
      },
      {
        label: '中国工商银行股份有限公司松原分行营业部',
        value: '102252012197|2207|吉林省|松原市'
      },
      {
        label: '中国工商银行松原集成支行',
        value: '102252026021|2207|吉林省|松原市'
      },
      {
        label: '中国工商银行黑龙江省分行清算中心（不对外办理业务）',
        value: '102261000018|2301|黑龙江省|哈尔滨市'
      },
      {
        label: '中国工商银行哈尔滨分行账务管理中心',
        value: '102261001002|2301|黑龙江省|哈尔滨市'
      },
      {
        label: '中国工商银行哈尔滨分行电子银行中心',
        value: '102261001019|2301|黑龙江省|哈尔滨市'
      },
      {
        label: '中国工商银行哈尔滨市河图支行',
        value: '102261001027|2301|黑龙江省|哈尔滨市'
      },
      {
        label: '中国工商银行股份有限公司哈尔滨前进支行',
        value: '102261001035|2301|黑龙江省|哈尔滨市'
      },
      {
        label: '中国工商银行哈尔滨市田地支行',
        value: '102261001051|2301|黑龙江省|哈尔滨市'
      },
      {
        label: '中国工商银行哈尔滨市顾乡支行',
        value: '102261001086|2301|黑龙江省|哈尔滨市'
      },
      {
        label: '中国工商银行哈尔滨市新阳支行',
        value: '102261001094|2301|黑龙江省|哈尔滨市'
      },
      {
        label: '中国工商银行哈尔滨市中央大街支行',
        value: '102261001109|2301|黑龙江省|哈尔滨市'
      },
      {
        label: '中国工商银行股份有限公司福州火车站支行',
        value: '102391051129|3501|福建省|福州市'
      },
      {
        label: '中国工商银行福州三角池支行',
        value: '102391051137|3501|福建省|福州市'
      },
      {
        label: '中国工商银行福建省福州市五四支行',
        value: '102391051145|3501|福建省|福州市'
      },
      {
        label: '中国工商银行福建省分行营业部牡丹卡中心',
        value: '102391051153|3501|福建省|福州市'
      },
      {
        label: '中国工商银行福建省福州市洪山支行',
        value: '102391051161|3501|福建省|福州市'
      },
      {
        label: '中国工商银行股份有限公司福建自贸试验区福州片区分行',
        value: '102391051170|3501|福建省|福州市'
      },
      {
        label: '中国工商银行股份有限公司闽侯支行',
        value: '102391051188|3501|福建省|福州市'
      },
      {
        label: '中国工商银行股份有限公司闽侯白沙支行',
        value: '102391051196|3501|福建省|福州市'
      },
      {
        label: '中国工商银行福建省福清市支行',
        value: '102391051207|3501|福建省|福州市'
      },
      {
        label: '中国工商银行福建省闽清县支行',
        value: '102391051215|3501|福建省|福州市'
      },
      {
        label: '中国工商银行福建省连江县支行',
        value: '102391051223|3501|福建省|福州市'
      },
      {
        label: '中国工商银行福建省长乐市支行',
        value: '102391051231|3501|福建省|福州市'
      },
      {
        label: '中国工商银行福建省永泰县支行',
        value: '102391051240|3501|福建省|福州市'
      },
      {
        label: '中国工商银行福建省罗源县支行',
        value: '102391051258|3501|福建省|福州市'
      },
      {
        label: '中国工商银行股份有限公司票据营业部郑州分部',
        value: '102491010071|4101|河南省|郑州市'
      },
      {
        label: '中国工商银行股份有限公司郑州城市山水支行',
        value: '102491011084|4101|河南省|郑州市'
      },
      {
        label: '中国工商银行股份有限公司郑州花都港湾支行',
        value: '102491012010|4101|河南省|郑州市'
      },
      {
        label: '中国工商银行股份有限公司郑州城东路支行',
        value: '102491012028|4101|河南省|郑州市'
      },
      {
        label: '中国工商银行股份有限公司郑州军区支行',
        value: '102491012069|4101|河南省|郑州市'
      },
      {
        label: '中国工商银行股份有限公司郑州福华支行',
        value: '102491012093|4101|河南省|郑州市'
      },
      {
        label: '中国工商银行股份有限公司郑州科学大道支行',
        value: '102491012132|4101|河南省|郑州市'
      },
      {
        label: '中国工商银行股份有限公司郑州伏牛路支行',
        value: '102491012157|4101|河南省|郑州市'
      },
      {
        label: '中国工商银行股份有限公司郑州文博支行',
        value: '102491012173|4101|河南省|郑州市'
      },
      {
        label: '中国工商银行股份有限公司郑州天赋路支行',
        value: '102491012204|4101|河南省|郑州市'
      },
      {
        label: '中国工商银行股份有限公司济源克井支行',
        value: '102491012559|4101|河南省|郑州市'
      },
      {
        label: '中国工商银行股份有限公司河南省分行营业部营业中心',
        value: '102491012680|4101|河南省|郑州市'
      },
      {
        label: '中国工商银行股份有限公司郑州花园丽都支行',
        value: '102491012817|4101|河南省|郑州市'
      },
      {
        label: '中国工商银行股份有限公司荥阳万山路支行',
        value: '102491012884|4101|河南省|郑州市'
      },
      {
        label: '中国工商银行股份有限公司郑州福塔支行',
        value: '102491012913|4101|河南省|郑州市'
      },
      {
        label: '中国工商银行股份有限公司郑州西三马路支行',
        value: '102491013012|4101|河南省|郑州市'
      },
      {
        label: '中国工商银行股份有限公司郑州顺河路支行',
        value: '102491013088|4101|河南省|郑州市'
      },
      {
        label: '中国工商银行股份有限公司郑州商鼎路支行',
        value: '102491014015|4101|河南省|郑州市'
      },
      {
        label: '中国工商银行股份有限公司郑州国基路支行',
        value: '102491014023|4101|河南省|郑州市'
      },
      {
        label: '中国工商银行股份有限公司郑州中苑名都支行',
        value: '102491015083|4101|河南省|郑州市'
      },
      {
        label: '中国工商银行股份有限公司郑州阳光花苑支行',
        value: '102491015155|4101|河南省|郑州市'
      },
      {
        label: '中国工商银行股份有限公司郑州中州支行',
        value: '102491016027|4101|河南省|郑州市'
      },
      {
        label: '中国工商银行股份有限公司郑州卧龙花园支行',
        value: '102491016150|4101|河南省|郑州市'
      },
      {
        label: '中国工商银行股份有限公司临沂西郊支行',
        value: '102473000270|3713|山东省|临沂市'
      },
      {
        label: '中国工商银行大连金州支行营业部',
        value: '102222020166|2102|辽宁省|大连市'
      },
      {
        label: '中国工商银行大连金州站前分理处',
        value: '102222020174|2102|辽宁省|大连市'
      },
      {
        label: '中国工商银行大连星海支行营业部',
        value: '102222020238|2102|辽宁省|大连市'
      },
      {
        label: '中国工商银行大连海港分理处',
        value: '102222020287|2102|辽宁省|大连市'
      },
      {
        label: '中国工商银行大连市分行营业部',
        value: '102222020318|2102|辽宁省|大连市'
      },
      {
        label: '中国工商银行大连市分行会计业务处理中心',
        value: '102222020334|2102|辽宁省|大连市'
      },
      {
        label: '中国工商银行大连友好广场支行营业部',
        value: '102222020342|2102|辽宁省|大连市'
      },
      {
        label: '中国工商银行股份有限公司大连商品交易所支行',
        value: '102222020359|2102|辽宁省|大连市'
      },
      {
        label: '中国工商银行大连大窑湾支行营业部',
        value: '102222020375|2102|辽宁省|大连市'
      },
      {
        label: '中国工商银行股份有限公司大连鲁迅路支行',
        value: '102222020383|2102|辽宁省|大连市'
      },
      {
        label: '中国工商银行股份有限公司大连自由贸易试验区支行',
        value: '102222021099|2102|辽宁省|大连市'
      },
      {
        label: '中国工商银行大连机场前分理处',
        value: '102222021259|2102|辽宁省|大连市'
      },
      {
        label: '中国工商银行股份有限公司大连普兰店丰荣支行',
        value: '102222201354|2102|辽宁省|大连市'
      },
      {
        label: '中国工商银行股份有限公司大连普兰店商业大街支行',
        value: '102222201362|2102|辽宁省|大连市'
      },
      {
        label: '中国工商银行股份有限公司大连普兰店同仁支行',
        value: '102222201379|2102|辽宁省|大连市'
      },
      {
        label: '中国工商银行股份有限公司大连普兰店孛兰支行',
        value: '102222201387|2102|辽宁省|大连市'
      },
      {
        label: '中国工商银行股份有限公司大连普兰店太山支行',
        value: '102222201459|2102|辽宁省|大连市'
      },
      {
        label: '中国工商银行股份有限公司大连普兰店皮口支行',
        value: '102222202837|2102|辽宁省|大连市'
      },
      {
        label: '中国工商银行股份有限公司大连普兰店春城分理处',
        value: '102222202853|2102|辽宁省|大连市'
      },
      {
        label: '中国工商银行股份有限公司大连炮台支行',
        value: '102222207697|2102|辽宁省|大连市'
      },
      {
        label: '中国工商银行股份有限公司大连石河支行',
        value: '102222207736|2102|辽宁省|大连市'
      },
      {
        label: '中国工商银行股份有限公司宝应安宜支行',
        value: '102312207073|3210|江苏省|扬州市'
      },
      {
        label: '中国工商银行股份有限公司宝应桃园支行',
        value: '102312207090|3210|江苏省|扬州市'
      },
      {
        label: '中国工商银行股份有限公司宝应支行',
        value: '102312220018|3210|江苏省|扬州市'
      },
      {
        label: '中国工商银行股份有限公司宝应苏中路支行',
        value: '102312220026|3210|江苏省|扬州市'
      },
      {
        label: '中国工商银行股份有限公司宝应宝胜桥支行',
        value: '102312220042|3210|江苏省|扬州市'
      },
      {
        label: '中国工商银行股份有限公司宝应白田支行',
        value: '102312220067|3210|江苏省|扬州市'
      },
      {
        label: '中国工商银行股份有限公司宝应城郊支行',
        value: '102312220075|3210|江苏省|扬州市'
      },
      {
        label: '中国工商银行靖江市支行',
        value: '102312312011|3210|江苏省|扬州市'
      },
      {
        label: '中国工商银行靖江城南支行',
        value: '102312312020|3210|江苏省|扬州市'
      },
      {
        label: '中国工商银行股份有限公司靖江城北支行',
        value: '102312312038|3210|江苏省|扬州市'
      },
      {
        label: '中国工商银行靖江南京路支行',
        value: '102312312046|3210|江苏省|扬州市'
      },
      {
        label: '中国工商银行靖江季市支行',
        value: '102312312054|3210|江苏省|扬州市'
      },
      {
        label: '中国工商银行靖江新港支行',
        value: '102312312062|3210|江苏省|扬州市'
      },
      {
        label: '中国工商银行靖江人民南路支行',
        value: '102312312095|3210|江苏省|扬州市'
      },
      {
        label: '中国工商银行靖江骥江西路支行',
        value: '102312312100|3210|江苏省|扬州市'
      },
      {
        label: '中国工商银行股份有限公司靖江新桥支行',
        value: '102312312118|3210|江苏省|扬州市'
      },
      {
        label: '中国工商银行股份有限公司靖江城西支行',
        value: '102312312126|3210|江苏省|扬州市'
      },
      {
        label: '中国工商银行股份有限公司吉安分行古南镇分理处',
        value: '102435009026|3608|江西省|吉安市'
      },
      {
        label: '中国工商银行股份有限公司吉安吉州支行',
        value: '102435009034|3608|江西省|吉安市'
      },
      {
        label: '中国工商银行股份有限公司吉安分行营业部',
        value: '102435009042|3608|江西省|吉安市'
      },
      {
        label: '中国工商银行股份有限公司吉安青原支行',
        value: '102435009059|3608|江西省|吉安市'
      },
      {
        label: '中国工商银行股份有限公司吉安城建支行',
        value: '102435009075|3608|江西省|吉安市'
      },
      {
        label: '中国工商银行股份有限公司吉安贸易广场支行',
        value: '102435009309|3608|江西省|吉安市'
      },
      {
        label: '中国工商银行股份有限公司吉安分行韶山路分理处',
        value: '102435010104|3608|江西省|吉安市'
      },
      {
        label: '中国工商银行股份有限公司吉安广场支行',
        value: '102435010153|3608|江西省|吉安市'
      },
      {
        label: '中国工商银行股份有限公司吉安分行鹭洲分理处',
        value: '102435010188|3608|江西省|吉安市'
      },
      {
        label: '中国工商银行股份有限公司井冈山支行龙市分理处',
        value: '102435209085|3608|江西省|吉安市'
      },
      {
        label: '中国工商银行股份有限公司井冈山茨坪支行',
        value: '102435209093|3608|江西省|吉安市'
      },
      {
        label: '中国工商银行股份有限公司井冈山支行',
        value: '102435210616|3608|江西省|吉安市'
      },
      {
        label: '中国工商银行股份有限公司吉安井开区支行',
        value: '102435302749|3608|江西省|吉安市'
      },
      {
        label: '中国工商银行股份有限公司吉安支行',
        value: '102435309109|3608|江西省|吉安市'
      },
      {
        label: '中国工商银行股份有限公司吉水支行',
        value: '102435409118|3608|江西省|吉安市'
      },
      {
        label: '中国工商银行股份有限公司峡江支行',
        value: '102435509123|3608|江西省|吉安市'
      },
      {
        label: '中国工商银行股份有限公司新干支行',
        value: '102435609144|3608|江西省|吉安市'
      },
      {
        label: '中国工商银行股份有限公司永丰支行',
        value: '102435709151|3608|江西省|吉安市'
      },
      {
        label: '中国工商银行股份有限公司泰和支行',
        value: '102435809162|3608|江西省|吉安市'
      },
      {
        label: '中国工商银行股份有限公司遂川支行',
        value: '102435909172|3608|江西省|吉安市'
      },
      {
        label: '中国工商银行股份有限公司万安支行',
        value: '102436109184|3608|江西省|吉安市'
      },
      {
        label: '中国工商银行股份有限公司安福支行',
        value: '102436209191|3608|江西省|吉安市'
      },
      {
        label: '中国工商银行股份有限公司永新支行',
        value: '102436309203|3608|江西省|吉安市'
      },
      {
        label: '中国工商银行股份有限公司莲花支行',
        value: '102436404149|3608|江西省|吉安市'
      },
      {
        label: '中国工商银行股份有限公司抚州伍塘路支行',
        value: '102437010010|3610|江西省|抚州市'
      },
      {
        label: '中国工商银行股份有限公司冷水江支行冷锡路分理处',
        value: '102562210112|4313|湖南省|娄底市'
      },
      {
        label: '中国工商银行股份有限公司涟源支行',
        value: '102562301039|4313|湖南省|娄底市'
      },
      {
        label: '中国工商银行股份有限公司涟源支行城建分理处',
        value: '102562310121|4313|湖南省|娄底市'
      },
      {
        label: '中国工商银行股份有限公司涟源支行芙蓉分理处',
        value: '102562310277|4313|湖南省|娄底市'
      },
      {
        label: '中国工商银行股份有限公司双峰支行',
        value: '102562401058|4313|湖南省|娄底市'
      },
      {
        label: '中国工商银行股份有限公司双峰支行永丰分理处',
        value: '102562410163|4313|湖南省|娄底市'
      },
      {
        label: '中国工商银行股份有限公司新化支行',
        value: '102562501049|4313|湖南省|娄底市'
      },
      {
        label: '中国工商银行股份有限公司常德分行',
        value: '102558000040|4307|湖南省|常德市'
      },
      {
        label: '中国工商银行股份有限公司常德武陵支行',
        value: '102558000066|4307|湖南省|常德市'
      },
      {
        label: '中国工商银行股份有限公司常德德山支行',
        value: '102558000074|4307|湖南省|常德市'
      },
      {
        label: '中国工商银行股份有限公司常德鼎城支行',
        value: '102558000082|4307|湖南省|常德市'
      },
      {
        label: '中国工商银行股份有限公司常德人民路支行',
        value: '102558000138|4307|湖南省|常德市'
      },
      {
        label: '中国工商银行股份有限公司常德临江支行',
        value: '102558000146|4307|湖南省|常德市'
      },
      {
        label: '中国工商银行股份有限公司常德分行银行卡业务部',
        value: '102558000306|4307|湖南省|常德市'
      },
      {
        label: '中国工商银行股份有限公司鄂州航空港支行',
        value: '102531000179|4207|湖北省|鄂州市'
      },
      {
        label: '中国工商银行股份有限公司鄂州长城支行',
        value: '102531000218|4207|湖北省|鄂州市'
      },
      {
        label: '中国工商银行股份有限公司鄂州洋澜湖支行',
        value: '102531000226|4207|湖北省|鄂州市'
      },
      {
        label: '中国工商银行股份有限公司鄂州花湖支行',
        value: '102531000234|4207|湖北省|鄂州市'
      },
      {
        label: '中国工商银行股份有限公司鄂州开发区支行',
        value: '102531000242|4207|湖北省|鄂州市'
      },
      {
        label: '中国工商银行股份有限公司鄂州分行营业部',
        value: '102531000259|4207|湖北省|鄂州市'
      },
      {
        label: '中国工商银行股份有限公司鄂州华容支行',
        value: '102531000267|4207|湖北省|鄂州市'
      },
      {
        label: '中国工商银行鄂州支行会计业务处理中心',
        value: '102531001000|4207|湖北省|鄂州市'
      },
      {
        label: '中国工商银行神农架林区支行',
        value: '102531100129|4207|湖北省|鄂州市'
      },
      {
        label: '中国工商银行股份有限公司荆门花坛支行',
        value: '102532000025|4208|湖北省|荆门市'
      },
      {
        label: '中国工商银行荆门东宝支行',
        value: '102532000041|4208|湖北省|荆门市'
      },
      {
        label: '中国工商银行股份有限公司郴州解放路支行',
        value: '102563021101|4310|湖南省|郴州市'
      },
      {
        label: '中国工商银行股份有限公司郴州财兴支行',
        value: '102563021208|4310|湖南省|郴州市'
      },
      {
        label: '中国工商银行股份有限公司郴州苏仙支行',
        value: '102563023003|4310|湖南省|郴州市'
      },
      {
        label: '中国工商银行股份有限公司郴州鑫兴支行',
        value: '102563023011|4310|湖南省|郴州市'
      },
      {
        label: '中国工商银行股份有限公司郴州分行',
        value: '102563033603|4310|湖南省|郴州市'
      },
      {
        label: '中国工商银行股份有限公司郴州郴江支行',
        value: '102563038906|4310|湖南省|郴州市'
      },
      {
        label: '中国工商银行股份有限公司资兴支行',
        value: '102563224005|4310|湖南省|郴州市'
      },
      {
        label: '中国工商银行股份有限公司资兴三都支行',
        value: '102563224101|4310|湖南省|郴州市'
      },
      {
        label: '中国工商银行股份有限公司资兴鲤鱼江支行',
        value: '102563224208|4310|湖南省|郴州市'
      },
      {
        label: '中国工商银行股份有限公司郴州分行桂阳城关分理处',
        value: '102563400739|4310|湖南省|郴州市'
      },
      {
        label: '中国工商银行股份有限公司桂阳支行',
        value: '102563426008|4310|湖南省|郴州市'
      },
      {
        label: '中国工商银行股份有限公司桂阳黄沙坪支行',
        value: '102563426104|4310|湖南省|郴州市'
      },
      {
        label: '中国工商银行股份有限公司郴州分行永兴广场分理处',
        value: '102563501113|4310|湖南省|郴州市'
      },
      {
        label: '中国工商银行股份有限公司永兴支行',
        value: '102563525003|4310|湖南省|郴州市'
      },
      {
        label: '中国工商银行股份有限公司宜章城中支行',
        value: '102563601026|4310|湖南省|郴州市'
      },
      {
        label: '中国工商银行股份有限公司宜章支行',
        value: '102563627000|4310|湖南省|郴州市'
      },
      {
        label: '中国工商银行股份有限公司嘉禾支行',
        value: '102563729006|4310|湖南省|郴州市'
      },
      {
        label: '中国工商银行股份有限公司临武城东支行',
        value: '102563800927|4310|湖南省|郴州市'
      },
      {
        label: '中国工商银行股份有限公司临武支行',
        value: '102563831008|4310|湖南省|郴州市'
      },
      {
        label: '中国工商银行股份有限公司郴州分行安仁五一南路分理处',
        value: '102564201311|4310|湖南省|郴州市'
      },
      {
        label: '中国工商银行股份有限公司安仁支行',
        value: '102564228006|4310|湖南省|郴州市'
      },
      {
        label: '中国工商银行股份有限公司永州零陵支行',
        value: '102565000013|4311|湖南省|永州市'
      },
      {
        label: '中国工商银行股份有限公司永州冷水滩支行',
        value: '102565000021|4311|湖南省|永州市'
      },
      {
        label: '中国工商银行股份有限公司永州银丰支行',
        value: '102565000128|4311|湖南省|永州市'
      },
      {
        label: '中国工商银行股份有限公司永州城建支行',
        value: '102565000136|4311|湖南省|永州市'
      },
      {
        label: '中国工商银行股份有限公司永州荷叶塘支行',
        value: '102565000353|4311|湖南省|永州市'
      },
      {
        label: '中国工商银行股份有限公司永州烟厂支行',
        value: '102565000388|4311|湖南省|永州市'
      },
      {
        label: '中国工商银行股份有限公司永州河东支行',
        value: '102565000499|4311|湖南省|永州市'
      },
      {
        label: '中国工商银行股份有限公司永州城中支行',
        value: '102565000503|4311|湖南省|永州市'
      },
      {
        label: '中国工商银行股份有限公司巢湖巢州分理处',
        value: '102378106818|3401|安徽省|合肥市'
      },
      {
        label: '中国工商银行股份有限公司巢湖城北分理处',
        value: '102378106826|3401|安徽省|合肥市'
      },
      {
        label: '中国工商银行股份有限公司巢湖滨湖支行',
        value: '102378106859|3401|安徽省|合肥市'
      },
      {
        label: '中国工商银行股份有限公司巢湖居巢支行',
        value: '102378106883|3401|安徽省|合肥市'
      },
      {
        label: '中国工商银行股份有限公司安徽省江北集中区支行',
        value: '102378107407|3401|安徽省|合肥市'
      },
      {
        label: '中国工商银行股份有限公司巢湖东风路支行',
        value: '102378122205|3401|安徽省|合肥市'
      },
      {
        label: '中国工商银行股份有限公司巢湖金巢分理处',
        value: '102378122272|3401|安徽省|合肥市'
      },
      {
        label: '中国工商银行股份有限公司齐齐哈尔铁东支行',
        value: '102264005575|2302|黑龙江省|齐齐哈尔市'
      },
      {
        label: '中国工商银行股份有限公司云梦黄香大道支行',
        value: '102535000055|4209|湖北省|孝感市'
      },
      {
        label: '中国工商银行股份有限公司孝感黄陂路支行',
        value: '102535000063|4209|湖北省|孝感市'
      },
      {
        label: '中国工商银行股份有限公司孝感拥军支行',
        value: '102535000071|4209|湖北省|孝感市'
      },
      {
        label: '中国工商银行股份有限公司孝感学院支行',
        value: '102535000080|4209|湖北省|孝感市'
      },
      {
        label: '中国工商银行股份有限公司孝感解放街支行',
        value: '102535000119|4209|湖北省|孝感市'
      },
      {
        label: '中国工商银行股份有限公司孝感铜锣湾支行',
        value: '102535000135|4209|湖北省|孝感市'
      },
      {
        label: '中国工商银行股份有限公司孝感南大支行',
        value: '102535002108|4209|湖北省|孝感市'
      },
      {
        label: '中国工商银行孝天支行',
        value: '102535010010|4209|湖北省|孝感市'
      },
      {
        label: '中国工商银行孝南区支行',
        value: '102535010069|4209|湖北省|孝感市'
      },
      {
        label: '中国工商银行孝感市长征支行',
        value: '102535010085|4209|湖北省|孝感市'
      },
      {
        label: '中国工商银行孝感市城东支行',
        value: '102535010108|4209|湖北省|孝感市'
      },
      {
        label: '中国工商银行孝感市北街口支行',
        value: '102535010116|4209|湖北省|孝感市'
      },
      {
        label: '中国工商银行股份有限公司孝昌支行',
        value: '102535102883|4209|湖北省|孝感市'
      },
      {
        label: '中国工商银行应城长江支行',
        value: '102535200016|4209|湖北省|孝感市'
      },
      {
        label: '中国工商银行应城化工支行',
        value: '102535200024|4209|湖北省|孝感市'
      },
      {
        label: '中国工商银行股份有限公司应城解放街支行',
        value: '102535200032|4209|湖北省|孝感市'
      },
      {
        label: '中国工商银行股份有限公司应城月圆支行',
        value: '102535200049|4209|湖北省|孝感市'
      },
      {
        label: '中国工商银行股份有限公司应城开发支行',
        value: '102535200065|4209|湖北省|孝感市'
      },
      {
        label: '中国工商银行应城市支行',
        value: '102535210038|4209|湖北省|孝感市'
      },
      {
        label: '中国工商银行股份有限公司安陆德安支行',
        value: '102535300025|4209|湖北省|孝感市'
      },
      {
        label: '中国工商银行股份有限公司安陆东城支行',
        value: '102535300041|4209|湖北省|孝感市'
      },
      {
        label: '中国工商银行股份有限公司安陆开发支行',
        value: '102535300269|4209|湖北省|孝感市'
      },
      {
        label: '中国工商银行安陆市支行',
        value: '102535310053|4209|湖北省|孝感市'
      },
      {
        label: '中国工商银行广水市支行营业室',
        value: '102535400075|4209|湖北省|孝感市'
      },
      {
        label: '中国工商银行股份有限公司广水中山支行',
        value: '102535400083|4209|湖北省|孝感市'
      },
      {
        label: '中国工商银行股份有限公司广水解放支行',
        value: '102535400227|4209|湖北省|孝感市'
      },
      {
        label: '中国工商银行股份有限公司亳州康美中药城支行',
        value: '102372200160|3412|安徽省|阜阳市'
      },
      {
        label: '中国工商银行股份有限公司临泉支行',
        value: '102372400442|3412|安徽省|阜阳市'
      },
      {
        label: '中国工商银行股份有限公司黄石永安里支行',
        value: '102522011050|4202|湖北省|黄石市'
      },
      {
        label: '中国工商银行黄石市大冶钢厂支行',
        value: '102522012001|4202|湖北省|黄石市'
      },
      {
        label: '中国工商银行股份有限公司黄石黄思湾支行',
        value: '102522012010|4202|湖北省|黄石市'
      },
      {
        label: '中国工商银行股份有限公司黄石西塞山支行',
        value: '102522013004|4202|湖北省|黄石市'
      },
      {
        label: '中国工商银行股份有限公司黄石磁湖支行',
        value: '102522013012|4202|湖北省|黄石市'
      },
      {
        label: '中国工商银行黄石市黄石港支行',
        value: '102522014007|4202|湖北省|黄石市'
      },
      {
        label: '中国工商银行股份有限公司黄石沈家营支行',
        value: '102522014015|4202|湖北省|黄石市'
      },
      {
        label: '中国工商银行黄石市石料山支行',
        value: '102522015000|4202|湖北省|黄石市'
      },
      {
        label: '中国工商银行黄石市下陆支行东方分理处',
        value: '102522016002|4202|湖北省|黄石市'
      },
      {
        label: '中国工商银行黄石市下陆支行',
        value: '102522016043|4202|湖北省|黄石市'
      },
      {
        label: '中国工商银行股份有限公司黄石铜花支行',
        value: '102522016051|4202|湖北省|黄石市'
      },
      {
        label: '中国工商银行黄石市铁山支行',
        value: '102522017005|4202|湖北省|黄石市'
      },
      {
        label: '中国工商银行股份有限公司黄石经济开发区支行',
        value: '102522021908|4202|湖北省|黄石市'
      },
      {
        label: '中国工商银行股份有限公司大冶支行',
        value: '102522102181|4202|湖北省|黄石市'
      },
      {
        label: '工商银行大冶开发区支行',
        value: '102522120015|4202|湖北省|黄石市'
      },
      {
        label: '中国工商银行股份有限公司大冶铜都支行',
        value: '102522120023|4202|湖北省|黄石市'
      },
      {
        label: '中国工商银行股份有限公司大冶东岳路支行',
        value: '102522120040|4202|湖北省|黄石市'
      },
      {
        label: '中国工商银行股份有限公司大冶新冶支行',
        value: '102522120066|4202|湖北省|黄石市'
      },
      {
        label: '中国工商银行股份有限公司大冶灵乡支行',
        value: '102522120082|4202|湖北省|黄石市'
      },
      {
        label: '中国工商银行股份有限公司大冶城区支行',
        value: '102522120099|4202|湖北省|黄石市'
      },
      {
        label: '中国工商银行股份有限公司大冶金山店支行',
        value: '102522120103|4202|湖北省|黄石市'
      },
      {
        label: '中国工商银行股份有限公司大冶城中支行',
        value: '102522120120|4202|湖北省|黄石市'
      },
      {
        label: '中国工商银行十堰市分行会计业务处理中心',
        value: '102523000015|4203|湖北省|十堰市'
      },
      {
        label: '中国工商银行股份有限公司十堰分行营业部',
        value: '102523000023|4203|湖北省|十堰市'
      },
      {
        label: '中国工商银行十堰市东汽支行',
        value: '102523000031|4203|湖北省|十堰市'
      },
      {
        label: '中国工商银行十堰市人民路支行',
        value: '102523000040|4203|湖北省|十堰市'
      },
      {
        label: '中国工商银行十堰市城西支行',
        value: '102523000066|4203|湖北省|十堰市'
      },
      {
        label: '中国工商银行股份有限公司邵阳分行',
        value: '102555002904|4305|湖南省|邵阳市'
      },
      {
        label: '中国工商银行股份有限公司邵东衡邵路支行',
        value: '102555100947|4305|湖南省|邵阳市'
      },
      {
        label: '中国工商银行股份有限公司邵东城北支行',
        value: '102555100955|4305|湖南省|邵阳市'
      },
      {
        label: '中国工商银行股份有限公司邵东城南支行',
        value: '102555100963|4305|湖南省|邵阳市'
      },
      {
        label: '中国工商银行股份有限公司邵东农林城支行',
        value: '102555101021|4305|湖南省|邵阳市'
      },
      {
        label: '中国工商银行股份有限公司邵东支行',
        value: '102555102016|4305|湖南省|邵阳市'
      },
      {
        label: '中国工商银行股份有限公司邵东牛马司支行',
        value: '102555104209|4305|湖南省|邵阳市'
      },
      {
        label: '中国工商银行股份有限公司邵东两市塘支行',
        value: '102555120142|4305|湖南省|邵阳市'
      },
      {
        label: '中国工商银行股份有限公司新邵大坪支行',
        value: '102555201143|4305|湖南省|邵阳市'
      },
      {
        label: '中国工商银行股份有限公司新邵支行',
        value: '102555202507|4305|湖南省|邵阳市'
      },
      {
        label: '中国工商银行日照市分行营业部',
        value: '102473200011|3713|山东省|临沂市'
      },
      {
        label: '中国工商银行股份有限公司日照东港支行',
        value: '102473200020|3713|山东省|临沂市'
      },
      {
        label: '中国工商银行股份有限公司日照岚山支行',
        value: '102473200038|3713|山东省|临沂市'
      },
      {
        label: '中国工商银行股份有限公司日照市中支行',
        value: '102473200046|3713|山东省|临沂市'
      },
      {
        label: '中国工商银行股份有限公司佛山南海中海万锦支行',
        value: '102588001682|4406|广东省|佛山市'
      },
      {
        label: '中国工商银行股份有限公司佛山南海桂信支行',
        value: '102588001699|4406|广东省|佛山市'
      },
      {
        label: '中国工商银行股份有限公司佛山三水文锋东支行',
        value: '102588001754|4406|广东省|佛山市'
      },
      {
        label: '中国工商银行股份有限公司佛山三水奥园支行',
        value: '102588001787|4406|广东省|佛山市'
      },
      {
        label: '中国工商银行股份有限公司佛山西樵支行',
        value: '102588001818|4406|广东省|佛山市'
      },
      {
        label: '中国工商银行股份有限公司佛山南海平地支行',
        value: '102588001867|4406|广东省|佛山市'
      },
      {
        label: '中国工商银行股份有限公司佛山南海平东支行',
        value: '102588001891|4406|广东省|佛山市'
      },
      {
        label: '中国工商银行股份有限公司佛山南海乐信支行',
        value: '102588001906|4406|广东省|佛山市'
      },
      {
        label: '中国工商银行股份有限公司佛山南海夏教支行',
        value: '102588001922|4406|广东省|佛山市'
      },
      {
        label: '中国工商银行股份有限公司佛山东元支行',
        value: '102588001947|4406|广东省|佛山市'
      },
      {
        label: '中国工商银行股份有限公司佛山南海东华支行',
        value: '102588001963|4406|广东省|佛山市'
      },
      {
        label: '中国工商银行股份有限公司佛山南海城区支行',
        value: '102588001998|4406|广东省|佛山市'
      },
      {
        label: '中国工商银行股份有限公司佛山南海支行',
        value: '102588002005|4406|广东省|佛山市'
      },
      {
        label: '中国工商银行股份有限公司佛山祖庙支行',
        value: '102588002013|4406|广东省|佛山市'
      },
      {
        label: '中国工商银行股份有限公司佛山升平支行',
        value: '102588002021|4406|广东省|佛山市'
      },
      {
        label: '中国工商银行股份有限公司佛山朝东支行',
        value: '102588002030|4406|广东省|佛山市'
      },
      {
        label: '中国工商银行股份有限公司佛山上沙支行',
        value: '102588002048|4406|广东省|佛山市'
      },
      {
        label: '中国工商银行股份有限公司佛山汇银支行',
        value: '102588002072|4406|广东省|佛山市'
      },
      {
        label: '中国工商银行股份有限公司佛山城西支行',
        value: '102588002097|4406|广东省|佛山市'
      },
      {
        label: '中国工商银行股份有限公司成都滨江支行实业街分理处',
        value: '102651000518|5101|四川省|成都市'
      },
      {
        label: '中国工商银行成都市滨江支行营业室',
        value: '102651020408|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都东坡大道支行',
        value: '102651020424|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都西部鞋都支行',
        value: '102651020432|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都小南街支行',
        value: '102651020449|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都滨江支行栖霞路分理处',
        value: '102651020457|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都晋康街支行',
        value: '102651020465|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都浆洗街支行',
        value: '102651020473|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都芷泉支行武青分理处',
        value: '102651020490|5101|四川省|成都市'
      },
      {
        label: '中国工商银行成都市芷泉支行营业室',
        value: '102651020504|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都高车支行',
        value: '102651020512|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都东方电气支行',
        value: '102651020529|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都东大街支行',
        value: '102651020607|5101|四川省|成都市'
      },
      {
        label: '中国工商银行成都市东城根街支行',
        value: '102651020703|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都春熙路步行街支行',
        value: '102651020800|5101|四川省|成都市'
      },
      {
        label: '中国工商银行四川省成都市春熙支行营业室',
        value: '102651020906|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司珠海凤凰支行',
        value: '102585002026|4404|广东省|珠海市'
      },
      {
        label: '中国工商银行股份有限公司珠海拱北支行',
        value: '102585002034|4404|广东省|珠海市'
      },
      {
        label: '中国工商银行股份有限公司珠海香洲支行',
        value: '102585002042|4404|广东省|珠海市'
      },
      {
        label: '中国工商银行股份有限公司珠海南山支行',
        value: '102585002059|4404|广东省|珠海市'
      },
      {
        label: '中国工商银行股份有限公司珠海吉大支行',
        value: '102585002067|4404|广东省|珠海市'
      },
      {
        label: '中国工商银行股份有限公司珠海分行',
        value: '102585002075|4404|广东省|珠海市'
      },
      {
        label: '中国工商银行股份有限公司珠海景山支行',
        value: '102585002083|4404|广东省|珠海市'
      },
      {
        label: '中国工商银行股份有限公司珠海前山支行',
        value: '102585002091|4404|广东省|珠海市'
      },
      {
        label: '中国工商银行股份有限公司珠海南香支行',
        value: '102585002106|4404|广东省|珠海市'
      },
      {
        label: '中国工商银行股份有限公司珠海湾仔支行',
        value: '102585002114|4404|广东省|珠海市'
      },
      {
        label: '中国工商银行股份有限公司珠海斗门支行',
        value: '102585002122|4404|广东省|珠海市'
      },
      {
        label: '中国工商银行股份有限公司珠海金湾支行',
        value: '102585002139|4404|广东省|珠海市'
      },
      {
        label: '中国工商银行股份有限公司珠海平沙支行',
        value: '102585002147|4404|广东省|珠海市'
      },
      {
        label: '中国工商银行股份有限公司珠海翠香支行',
        value: '102585002163|4404|广东省|珠海市'
      },
      {
        label: '中国工商银行股份有限公司珠海唐家支行',
        value: '102585002171|4404|广东省|珠海市'
      },
      {
        label: '中国工商银行股份有限公司珠海紫荆支行',
        value: '102585002180|4404|广东省|珠海市'
      },
      {
        label: '中国工商银行股份有限公司珠海兰埔支行',
        value: '102585002198|4404|广东省|珠海市'
      },
      {
        label: '中国工商银行股份有限公司珠海南苑支行',
        value: '102585002202|4404|广东省|珠海市'
      },
      {
        label: '中国工商银行股份有限公司珠海九洲支行',
        value: '102585002219|4404|广东省|珠海市'
      },
      {
        label: '中国工商银行股份有限公司佛山汾江支行',
        value: '102588002878|4406|广东省|佛山市'
      },
      {
        label: '中国工商银行股份有限公司佛山季华支行',
        value: '102588002925|4406|广东省|佛山市'
      },
      {
        label: '中国工商银行股份有限公司佛山南海松岗支行',
        value: '102588005284|4406|广东省|佛山市'
      },
      {
        label: '中国工商银行景德镇市新厂支行',
        value: '102422003042|3602|江西省|景德镇市'
      },
      {
        label: '中国工商银行股份有限公司台州中山支行',
        value: '102345001413|3310|浙江省|台州市'
      },
      {
        label: '中国工商银行台州市路桥区支行',
        value: '102345001510|3310|浙江省|台州市'
      },
      {
        label: '中国工商银行银行股份有限公司台州商城支行',
        value: '102345001528|3310|浙江省|台州市'
      },
      {
        label: '中国工商银行股份有限公司台州分行业务处理中心',
        value: '102345001903|3310|浙江省|台州市'
      },
      {
        label: '中国工商银行股份有限公司台州分行',
        value: '102345002125|3310|浙江省|台州市'
      },
      {
        label: '中国工商银行股份有限公司台州市府大楼分理处',
        value: '102345002221|3310|浙江省|台州市'
      },
      {
        label: '中国工商银行台州市黄岩区支行',
        value: '102345003110|3310|浙江省|台州市'
      },
      {
        label: '中国工商银行股份有限公司台州青年路支行',
        value: '102345021142|3310|浙江省|台州市'
      },
      {
        label: '中国工商银行股份有限公司台州蓝天支行',
        value: '102345021167|3310|浙江省|台州市'
      },
      {
        label: '中国工商银行股份有限公司台州经济开发区支行机场路分理处',
        value: '102345021175|3310|浙江省|台州市'
      },
      {
        label: '中国工商银行股份有限公司台州葭芷支行',
        value: '102345021206|3310|浙江省|台州市'
      },
      {
        label: '中国工商银行股份有限公司台州椒江支行东海大道分理处',
        value: '102345021214|3310|浙江省|台州市'
      },
      {
        label: '中国工商银行股份有限公司台州椒南支行',
        value: '102345021222|3310|浙江省|台州市'
      },
      {
        label: '中国工商银行股份有限公司台州卖芝桥支行',
        value: '102345021530|3310|浙江省|台州市'
      },
      {
        label: '中国工商银行股份有限公司台州五金城支行',
        value: '102345021548|3310|浙江省|台州市'
      },
      {
        label: '中国工商银行股份有限公司台州横街支行',
        value: '102345021556|3310|浙江省|台州市'
      },
      {
        label: '中国工商银行股份有限公司潍坊分行圣荣广场分理处',
        value: '102458000458|3707|山东省|潍坊市'
      },
      {
        label: '中国工商银行潍坊坊子支行',
        value: '102458000474|3707|山东省|潍坊市'
      },
      {
        label: '中国工商银行潍坊市寒亭区支行',
        value: '102458000503|3707|山东省|潍坊市'
      },
      {
        label: '中国工商银行股份有限公司潍坊分行寒亭民主西街分理处',
        value: '102458000511|3707|山东省|潍坊市'
      },
      {
        label: '中国工商银行股份有限公司潍坊分行寒亭民主街分理处',
        value: '102458000538|3707|山东省|潍坊市'
      },
      {
        label: '中国工商银行股份有限公司潍坊滨海开发区支行',
        value: '102458000562|3707|山东省|潍坊市'
      },
      {
        label: '中国工商银行潍坊市分行会计业务处理中心',
        value: '102458040570|3707|山东省|潍坊市'
      },
      {
        label: '中国工商银行淄博市淄川区支行淄城路分理处',
        value: '102453003061|3703|山东省|淄博市'
      },
      {
        label: '中国工商银行淄博市淄川区支行矿务局分理处',
        value: '102453003070|3703|山东省|淄博市'
      },
      {
        label: '中国工商银行股份有限公司淄博淄川洪山支行',
        value: '102453003088|3703|山东省|淄博市'
      },
      {
        label: '中国工商银行淄博市淄川区支行龙泉分理处',
        value: '102453003096|3703|山东省|淄博市'
      },
      {
        label: '中国工商银行股份有限公司淄博淄川支行昆仑分理处',
        value: '102453003131|3703|山东省|淄博市'
      },
      {
        label: '中国工商银行股份有限公司淄博淄川岭子支行',
        value: '102453003140|3703|山东省|淄博市'
      },
      {
        label: '中国工商银行淄博市淄川区支行服装城分理处',
        value: '102453003203|3703|山东省|淄博市'
      },
      {
        label: '中国工商银行股份有限公司淄博周村东门支行',
        value: '102453004015|3703|山东省|淄博市'
      },
      {
        label: '中国工商银行股份有限公司淄博周村市南路分理处',
        value: '102453004023|3703|山东省|淄博市'
      },
      {
        label: '中国工商银行股份有限公司淄博周村王村支行',
        value: '102453004074|3703|山东省|淄博市'
      },
      {
        label: '中国工商银行股份有限公司淄博周村支行正阳路分理处',
        value: '102453004146|3703|山东省|淄博市'
      },
      {
        label: '中国工商银行安阳分行豆腐营支行',
        value: '102496002069|4105|河南省|安阳市'
      },
      {
        label: '中国工商银行安阳分行铁西支行',
        value: '102496002077|4105|河南省|安阳市'
      },
      {
        label: '中国工商银行股份有限公司安阳黄河大道支行',
        value: '102496002085|4105|河南省|安阳市'
      },
      {
        label: '中国工商银行股份有限公司安阳分行解放大道分理处',
        value: '102496002108|4105|河南省|安阳市'
      },
      {
        label: '中国工商银行股份有限公司安阳分行',
        value: '102496002165|4105|河南省|安阳市'
      },
      {
        label: '中国工商银行股份有限公司安阳迎宾支行',
        value: '102496002181|4105|河南省|安阳市'
      },
      {
        label: '中国工商银行安阳分行会计业务处理中心',
        value: '102496002680|4105|河南省|安阳市'
      },
      {
        label: '中国工商银行股份有限公司安阳三八支行',
        value: '102496012023|4105|河南省|安阳市'
      },
      {
        label: '中国工商银行股份有限公司安阳电厂支行',
        value: '102496012058|4105|河南省|安阳市'
      },
      {
        label: '中国工商银行股份有限公司安阳安钢一生活区支行',
        value: '102496022051|4105|河南省|安阳市'
      },
      {
        label: '中国工商银行股份有限公司安阳人民大道支行',
        value: '102496032065|4105|河南省|安阳市'
      },
      {
        label: '中国工商银行股份有限公司安阳海河大道支行',
        value: '102496062045|4105|河南省|安阳市'
      },
      {
        label: '中国工商银行安阳分行林州市支行',
        value: '102496102205|4105|河南省|安阳市'
      },
      {
        label: '中国工商银行股份有限公司林州城里支行',
        value: '102496112207|4105|河南省|安阳市'
      },
      {
        label: '中国工商银行安阳分行水冶支行',
        value: '102496202119|4105|河南省|安阳市'
      },
      {
        label: '中国工商银行股份有限公司安阳安化支行',
        value: '102496212113|4105|河南省|安阳市'
      },
      {
        label: '中国工商银行安阳分行汤阴县支行',
        value: '102496302300|4105|河南省|安阳市'
      },
      {
        label: '中国工商银行安阳分行滑县支行',
        value: '102496402407|4105|河南省|安阳市'
      },
      {
        label: '中国工商银行安阳分行内黄县支行',
        value: '102496502605|4105|河南省|安阳市'
      },
      {
        label: '中国工商银行鹤壁分行长风路支行',
        value: '102497002022|4106|河南省|鹤壁市'
      },
      {
        label: '中国工商银行股份有限公司鹤壁黄河路支行',
        value: '102497002047|4106|河南省|鹤壁市'
      },
      {
        label: '中国工商银行鹤壁分行淇滨支行',
        value: '102497002098|4106|河南省|鹤壁市'
      },
      {
        label: '中国工商银行鹤壁分行会计业务处理中心',
        value: '102497002688|4106|河南省|鹤壁市'
      },
      {
        label: '中国工商银行股份有限公司鹤壁中山支行',
        value: '102497012025|4106|河南省|鹤壁市'
      },
      {
        label: '中国工商银行股份有限公司鹤壁汤河街支行',
        value: '102497022029|4106|河南省|鹤壁市'
      },
      {
        label: '中国工商银行股份有限公司鹤壁广场支行',
        value: '102497032026|4106|河南省|鹤壁市'
      },
      {
        label: '中国工商银行股份有限公司鹤壁福田支行',
        value: '102497032091|4106|河南省|鹤壁市'
      },
      {
        label: '中国工商银行股份有限公司齐齐哈尔卜奎支行',
        value: '102264005614|2302|黑龙江省|齐齐哈尔市'
      },
      {
        label: '中国工商银行股份有限公司龙江支行',
        value: '102264103721|2302|黑龙江省|齐齐哈尔市'
      },
      {
        label: '中国工商银行股份有限公司讷河支行',
        value: '102264203675|2302|黑龙江省|齐齐哈尔市'
      },
      {
        label: '中国工商银行股份有限公司依安支行',
        value: '102264303695|2302|黑龙江省|齐齐哈尔市'
      },
      {
        label: '中国工商银行股份有限公司泰来支行',
        value: '102264403701|2302|黑龙江省|齐齐哈尔市'
      },
      {
        label: '中国工商银行本溪市分行溪湖支行',
        value: '102225000332|2105|辽宁省|本溪市'
      },
      {
        label: '中国工商银行股份有限公司本溪民主路支行',
        value: '102225000349|2105|辽宁省|本溪市'
      },
      {
        label: '中国工商银行本溪市分行彩屯支行',
        value: '102225000373|2105|辽宁省|本溪市'
      },
      {
        label: '中国工商银行本溪市分行明山支行',
        value: '102225000390|2105|辽宁省|本溪市'
      },
      {
        label: '中国工商银行本溪市分行平山支行',
        value: '102225000404|2105|辽宁省|本溪市'
      },
      {
        label: '中国工商银行本溪市分行融信支行',
        value: '102225000412|2105|辽宁省|本溪市'
      },
      {
        label: '中国工商银行本溪市分行东明支行',
        value: '102225000429|2105|辽宁省|本溪市'
      },
      {
        label: '中国工商银行本溪分行开发区支行',
        value: '102225000531|2105|辽宁省|本溪市'
      },
      {
        label: '中国工商银行本溪鑫丰支行',
        value: '102225000558|2105|辽宁省|本溪市'
      },
      {
        label: '中国工商银行本溪市分行本溪满族自治县支行',
        value: '102225100430|2105|辽宁省|本溪市'
      },
      {
        label: '中国工商银行股份有限公司本溪田师府支行',
        value: '102225100456|2105|辽宁省|本溪市'
      },
      {
        label: '中国工商银行股份有限公司本溪一马路支行',
        value: '102225100464|2105|辽宁省|本溪市'
      },
      {
        label: '中国工商银行本溪市分行桓仁满族自治县支行',
        value: '102225200487|2105|辽宁省|本溪市'
      },
      {
        label: '中国工商银行股份有限公司本溪西关支行',
        value: '102225200500|2105|辽宁省|本溪市'
      },
      {
        label: '中国工商银行股份有限公司本溪城镇支行',
        value: '102225200526|2105|辽宁省|本溪市'
      },
      {
        label: '中国工商银行丹东元宝支行',
        value: '102226000019|2106|辽宁省|丹东市'
      },
      {
        label: '中国工商银行丹东振兴支行',
        value: '102226000027|2106|辽宁省|丹东市'
      },
      {
        label: '中国工商银行丹东人民广场支行',
        value: '102226000035|2106|辽宁省|丹东市'
      },
      {
        label: '中国工商银行丹东永昌支行',
        value: '102226000043|2106|辽宁省|丹东市'
      },
      {
        label: '中国工商银行丹东沿江开发区支行',
        value: '102226000051|2106|辽宁省|丹东市'
      },
      {
        label: '中国工商银行丹东聚宝支行',
        value: '102226000060|2106|辽宁省|丹东市'
      },
      {
        label: '中国工商银行丹东锦山支行',
        value: '102226000078|2106|辽宁省|丹东市'
      },
      {
        label: '中国工商银行股份有限公司丹东七道支行',
        value: '102226000117|2106|辽宁省|丹东市'
      },
      {
        label: '中国工商银行股份有限公司丹东八道支行',
        value: '102226000133|2106|辽宁省|丹东市'
      },
      {
        label: '中国工商银行股份有限公司丹东珍珠路支行',
        value: '102226000168|2106|辽宁省|丹东市'
      },
      {
        label: '中国工商银行股份有限公司丹东兴隆支行',
        value: '102226000205|2106|辽宁省|丹东市'
      },
      {
        label: '中国工商银行股份有限公司丹东解放支行',
        value: '102226000221|2106|辽宁省|丹东市'
      },
      {
        label: '中国工商银行股份有限公司丹东三马路支行',
        value: '102226000256|2106|辽宁省|丹东市'
      },
      {
        label: '中国工商银行股份有限公司丹东山下街支行',
        value: '102226000289|2106|辽宁省|丹东市'
      },
      {
        label: '中国工商银行股份有限公司丹东福春支行',
        value: '102226000301|2106|辽宁省|丹东市'
      },
      {
        label: '中国工商银行股份有限公司丹东同春路支行',
        value: '102226000310|2106|辽宁省|丹东市'
      },
      {
        label: '中国工商银行股份有限公司丹东四道沟支行',
        value: '102226000336|2106|辽宁省|丹东市'
      },
      {
        label: '中国工商银行股份有限公司大庆呈祥支行',
        value: '102265010027|2306|黑龙江省|大庆市'
      },
      {
        label: '中国工商银行股份有限公司大庆分行东湖支行',
        value: '102265013054|2306|黑龙江省|大庆市'
      },
      {
        label: '中国工商银行股份有限公司大庆第二支行',
        value: '102265014022|2306|黑龙江省|大庆市'
      },
      {
        label: '中国工商银行股份有限公司大庆龙飞支行',
        value: '102265018023|2306|黑龙江省|大庆市'
      },
      {
        label: '中国工商银行股份有限公司克东支行',
        value: '102265103496|2302|黑龙江省|齐齐哈尔市'
      },
      {
        label: '中国工商银行股份有限公司拜泉支行',
        value: '102265203451|2302|黑龙江省|齐齐哈尔市'
      },
      {
        label: '中国工商银行鸡西分行核算中心',
        value: '102266000017|2303|黑龙江省|鸡西市'
      },
      {
        label: '中国工商银行股份有限公司鸡西红旗支行',
        value: '102266000041|2303|黑龙江省|鸡西市'
      },
      {
        label: '中国工商银行股份有限公司鸡西红军路支行',
        value: '102266000148|2303|黑龙江省|鸡西市'
      },
      {
        label: '中国工商银行股份有限公司鸡西金洲支行',
        value: '102266000293|2303|黑龙江省|鸡西市'
      },
      {
        label: '中国工商银行股份有限公司鸡西和平支行',
        value: '102266002012|2303|黑龙江省|鸡西市'
      },
      {
        label: '中国工商银行股份有限公司鸡西市分行永昌支行',
        value: '102266002029|2303|黑龙江省|鸡西市'
      },
      {
        label: '中国工商银行股份有限公司鸡西市中心大街支行',
        value: '102266002037|2303|黑龙江省|鸡西市'
      },
      {
        label: '中国工商银行股份有限公司鸡西市鸡铁支行',
        value: '102266002045|2303|黑龙江省|鸡西市'
      },
      {
        label: '中国工商银行股份有限公司鸡西市城子河支行',
        value: '102266002053|2303|黑龙江省|鸡西市'
      },
      {
        label: '中国工商银行股份有限公司鸡西市滴道支行',
        value: '102266002088|2303|黑龙江省|鸡西市'
      },
      {
        label: '中国工商银行股份有限公司福清后埔街支行',
        value: '102391053444|3501|福建省|福州市'
      },
      {
        label: '中国工商银行股份有限公司枣庄南关支行',
        value: '102454100381|3704|山东省|枣庄市'
      },
      {
        label: '中国工商银行股份有限公司枣庄龙山支行',
        value: '102454100390|3704|山东省|枣庄市'
      },
      {
        label: '中国工商银行东营市分行会计核算中心',
        value: '102455000018|3705|山东省|东营市'
      },
      {
        label: '中国工商银行东营市西城支行',
        value: '102455000026|3705|山东省|东营市'
      },
      {
        label: '中国工商银行股份有限公司东营西城支行济南路分理处',
        value: '102455000034|3705|山东省|东营市'
      },
      {
        label: '中国工商银行东营市东城支行',
        value: '102455000042|3705|山东省|东营市'
      },
      {
        label: '中国工商银行股份有限公司东营胜利支行',
        value: '102455000067|3705|山东省|东营市'
      },
      {
        label: '中国工商银行东营市河口区支行',
        value: '102455001019|3705|山东省|东营市'
      },
      {
        label: '中国工商银行股份有限公司东营河口支行百货大楼分理处',
        value: '102455001027|3705|山东省|东营市'
      },
      {
        label: '中国工商银行哈尔滨市太平桥支行',
        value: '102261001310|2301|黑龙江省|哈尔滨市'
      },
      {
        label: '中国工商银行股份有限公司洛阳创展贵都财富支行',
        value: '102493052082|4103|河南省|洛阳市'
      },
      {
        label: '中国工商银行股份有限公司洛阳文峰支行',
        value: '102493072240|4103|河南省|洛阳市'
      },
      {
        label: '中国工商银行股份有限公司洛阳玻璃厂南路支行',
        value: '102493072299|4103|河南省|洛阳市'
      },
      {
        label: '中国工商银行洛阳分行偃师市支行',
        value: '102493102706|4103|河南省|洛阳市'
      },
      {
        label: '中国工商银行洛阳分行孟津县支行',
        value: '102493202259|4103|河南省|洛阳市'
      },
      {
        label: '中国工商银行洛阳分行新安县支行',
        value: '102493302768|4103|河南省|洛阳市'
      },
      {
        label: '中国工商银行股份有限公司新安魏郡支行',
        value: '102493322767|4103|河南省|洛阳市'
      },
      {
        label: '中国工商银行洛阳分行栾川县支行',
        value: '102493402324|4103|河南省|洛阳市'
      },
      {
        label: '中国工商银行洛阳分行嵩县支行',
        value: '102493502317|4103|河南省|洛阳市'
      },
      {
        label: '中国工商银行股份有限公司嵩县人民路支行',
        value: '102493512312|4103|河南省|洛阳市'
      },
      {
        label: '中国工商银行股份有限公司汝阳支行',
        value: '102493602302|4103|河南省|洛阳市'
      },
      {
        label: '中国工商银行洛阳分行宜阳县支行',
        value: '102493702270|4103|河南省|洛阳市'
      },
      {
        label: '中国工商银行洛阳分行洛宁县支行',
        value: '102493802859|4103|河南省|洛阳市'
      },
      {
        label: '中国工商银行洛阳分行伊川县支行',
        value: '102493902284|4103|河南省|洛阳市'
      },
      {
        label: '中国工商银行股份有限公司烟台南大街支行',
        value: '102456000204|3706|山东省|烟台市'
      },
      {
        label: '中国工商银行股份有限公司烟台毓璜顶西路支行',
        value: '102456000270|3706|山东省|烟台市'
      },
      {
        label: '中国工商银行股份有限公司烟台西大街支行',
        value: '102456000307|3706|山东省|烟台市'
      },
      {
        label: '中国工商银行股份有限公司烟台上夼西路支行',
        value: '102456000358|3706|山东省|烟台市'
      },
      {
        label: '中国工商银行股份有限公司烟台芝罘支行',
        value: '102456000403|3706|山东省|烟台市'
      },
      {
        label: '中国工商银行股份有限公司烟台西郊支行',
        value: '102456000438|3706|山东省|烟台市'
      },
      {
        label: '中国工商银行股份有限公司烟台福安小区支行',
        value: '102456000446|3706|山东省|烟台市'
      },
      {
        label: '中国工商银行股份有限公司烟台幸福支行',
        value: '102456000500|3706|山东省|烟台市'
      },
      {
        label: '中国工商银行股份有限公司泉州丰泽支行千亿商城分理处',
        value: '102397022048|3505|福建省|泉州市'
      },
      {
        label: '中国工商银行股份有限公司泉州南俊巷支行',
        value: '102397022097|3505|福建省|泉州市'
      },
      {
        label: '中国工商银行泉州幸福支行',
        value: '102397022128|3505|福建省|泉州市'
      },
      {
        label: '中国工商银行股份有限公司泉州东美支行',
        value: '102397022144|3505|福建省|泉州市'
      },
      {
        label: '中国工商银行股份有限公司泉州滨城支行',
        value: '102397022152|3505|福建省|泉州市'
      },
      {
        label: '中国工商银行股份有限公司泉州新门街支行',
        value: '102397022169|3505|福建省|泉州市'
      },
      {
        label: '中国工商银行泉州江南支行',
        value: '102397022185|3505|福建省|泉州市'
      },
      {
        label: '中国工商银行股份有限公司泉州培元支行',
        value: '102397022193|3505|福建省|泉州市'
      },
      {
        label: '中国工商银行股份有限公司泉州宝洲路支行',
        value: '102397022747|3505|福建省|泉州市'
      },
      {
        label: '中国工商银行股份有限公司泉州北门支行',
        value: '102397022819|3505|福建省|泉州市'
      },
      {
        label: '中国工商银行股份有限公司青岛黄岛支行古镇口分理处',
        value: '102452208049|3702|山东省|青岛市'
      },
      {
        label: '中国工商银行股份有限公司莱西支行',
        value: '102452310019|3702|山东省|青岛市'
      },
      {
        label: '中国工商银行股份有限公司莱西上海路支行',
        value: '102452310051|3702|山东省|青岛市'
      },
      {
        label: '中国工商银行平度市支行',
        value: '102452411014|3702|山东省|青岛市'
      },
      {
        label: '中国工商银行股份有限公司平度第二支行',
        value: '102452411039|3702|山东省|青岛市'
      },
      {
        label: '中国工商银行股份有限公司胶州支行',
        value: '102452509014|3702|山东省|青岛市'
      },
      {
        label: '中国工商银行股份有限公司胶州北京路支行',
        value: '102452509022|3702|山东省|青岛市'
      },
      {
        label: '中国工商银行股份有限公司淄博高新支行',
        value: '102453000119|3703|山东省|淄博市'
      },
      {
        label: '中国工商银行淄博市分行营业部',
        value: '102453000160|3703|山东省|淄博市'
      },
      {
        label: '中国工商银行淄博市张店区支行',
        value: '102453000217|3703|山东省|淄博市'
      },
      {
        label: '中国工商银行淄博市南定支行',
        value: '102453000250|3703|山东省|淄博市'
      },
      {
        label: '中国工商银行淄博市博山区支行',
        value: '102453000313|3703|山东省|淄博市'
      },
      {
        label: '中国工商银行淄博市淄川区支行',
        value: '102453000410|3703|山东省|淄博市'
      },
      {
        label: '中国工商银行淄博市周村区支行',
        value: '102453000516|3703|山东省|淄博市'
      },
      {
        label: '中国工商银行淄博市临淄区支行',
        value: '102453000612|3703|山东省|淄博市'
      },
      {
        label: '中国工商银行股份有限公司淄博张店车站支行',
        value: '102453001017|3703|山东省|淄博市'
      },
      {
        label: '中国工商银行股份有限公司淄博张店西城支行',
        value: '102453001025|3703|山东省|淄博市'
      },
      {
        label: '中国工商银行股份有限公司淄博张店支行中心路分理处',
        value: '102453001041|3703|山东省|淄博市'
      },
      {
        label: '中国工商银行股份有限公司淄博张店支行洪沟东路分理处',
        value: '102453001068|3703|山东省|淄博市'
      },
      {
        label: '中国工商银行股份有限公司淄博张店共青团西路支行',
        value: '102453001076|3703|山东省|淄博市'
      },
      {
        label: '中国工商银行股份有限公司淄博张店支行新村西路分理处',
        value: '102453001084|3703|山东省|淄博市'
      },
      {
        label: '中国工商银行股份有限公司淄博张店丽景苑支行',
        value: '102453001105|3703|山东省|淄博市'
      },
      {
        label: '中国工商银行股份有限公司淄博张店人民西路支行',
        value: '102453001172|3703|山东省|淄博市'
      },
      {
        label: '中国工商银行股份有限公司淄博张店体坛支行',
        value: '102453001201|3703|山东省|淄博市'
      },
      {
        label: '中国工商银行股份有限公司淄博张店南京路支行',
        value: '102453001252|3703|山东省|淄博市'
      },
      {
        label: '中国工商银行股份有限公司淄博张店杏园路支行',
        value: '102453001269|3703|山东省|淄博市'
      },
      {
        label: '中国工商银行股份有限公司淄博张店理工大学支行',
        value: '102453001277|3703|山东省|淄博市'
      },
      {
        label: '中国工商银行股份有限公司淄博博山人民路支行',
        value: '102453002010|3703|山东省|淄博市'
      },
      {
        label: '中国工商银行股份有限公司淄博博山新建二路支行',
        value: '102453002028|3703|山东省|淄博市'
      },
      {
        label: '中国工商银行淄博市博山区支行夏庄分理处',
        value: '102453002077|3703|山东省|淄博市'
      },
      {
        label: '中国工商银行股份有限公司衡阳金山支行',
        value: '102554002886|4304|湖南省|衡阳市'
      },
      {
        label: '中国工商银行股份有限公司衡阳呆鹰岭支行',
        value: '102554003514|4304|湖南省|衡阳市'
      },
      {
        label: '中国工商银行股份有限公司衡阳湖南路支行',
        value: '102554003856|4304|湖南省|衡阳市'
      },
      {
        label: '中国工商银行股份有限公司衡阳分行',
        value: '102554003901|4304|湖南省|衡阳市'
      },
      {
        label: '中国工商银行股份有限公司衡阳县支行',
        value: '102554103501|4304|湖南省|衡阳市'
      },
      {
        label: '中国工商银行股份有限公司衡阳县文明支行',
        value: '102554103528|4304|湖南省|衡阳市'
      },
      {
        label: '中国工商银行股份有限公司衡南支行',
        value: '102554203724|4304|湖南省|衡阳市'
      },
      {
        label: '中国工商银行股份有限公司衡阳南岳支行',
        value: '102554303204|4304|湖南省|衡阳市'
      },
      {
        label: '中国工商银行股份有限公司衡山支行',
        value: '102554303307|4304|湖南省|衡阳市'
      },
      {
        label: '中国工商银行股份有限公司衡东支行',
        value: '102554403408|4304|湖南省|衡阳市'
      },
      {
        label: '中国工商银行股份有限公司常宁支行',
        value: '102554503604|4304|湖南省|衡阳市'
      },
      {
        label: '中国工商银行股份有限公司衡阳水口山支行',
        value: '102554503707|4304|湖南省|衡阳市'
      },
      {
        label: '中国工商银行股份有限公司祁东支行',
        value: '102554603107|4304|湖南省|衡阳市'
      },
      {
        label: '中国工商银行股份有限公司祁东中心市场支行',
        value: '102554603115|4304|湖南省|衡阳市'
      },
      {
        label: '中国工商银行股份有限公司耒阳支行',
        value: '102554703802|4304|湖南省|衡阳市'
      },
      {
        label: '中国工商银行股份有限公司耒阳灶市支行',
        value: '102554703819|4304|湖南省|衡阳市'
      },
      {
        label: '中国工商银行股份有限公司耒阳牌楼支行',
        value: '102554703878|4304|湖南省|衡阳市'
      },
      {
        label: '中国工商银行股份有限公司耒阳开发区支行',
        value: '102554703917|4304|湖南省|衡阳市'
      },
      {
        label: '中国工商银行股份有限公司耒阳花石支行',
        value: '102554703925|4304|湖南省|衡阳市'
      },
      {
        label: '中国工商银行股份有限公司耒阳广场支行',
        value: '102554703933|4304|湖南省|衡阳市'
      },
      {
        label: '中国工商银行股份有限公司邵阳三八亭支行',
        value: '102555000374|4305|湖南省|邵阳市'
      },
      {
        label: '中国工商银行股份有限公司邵阳宝西支行',
        value: '102555000382|4305|湖南省|邵阳市'
      },
      {
        label: '中国工商银行股份有限公司邵阳红卫支行',
        value: '102555000403|4305|湖南省|邵阳市'
      },
      {
        label: '中国工商银行股份有限公司邵阳江北支行',
        value: '102555000526|4305|湖南省|邵阳市'
      },
      {
        label: '中国工商银行股份有限公司广州五山支行',
        value: '102581000546|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州红棉支行',
        value: '102581000554|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司平湖当湖支行',
        value: '102335208059|3304|浙江省|嘉兴市'
      },
      {
        label: '中国工商银行股份有限公司平湖金平支行',
        value: '102335208067|3304|浙江省|嘉兴市'
      },
      {
        label: '中国工商银行股份有限公司平湖独山港支行',
        value: '102335208083|3304|浙江省|嘉兴市'
      },
      {
        label: '中国工商银行股份有限公司平湖新仓支行',
        value: '102335208091|3304|浙江省|嘉兴市'
      },
      {
        label: '中国工商银行股份有限公司海盐支行',
        value: '102335309003|3304|浙江省|嘉兴市'
      },
      {
        label: '中国工商银行股份有限公司海盐朝阳支行',
        value: '102335309011|3304|浙江省|嘉兴市'
      },
      {
        label: '中国工商银行股份有限公司海盐城北支行',
        value: '102335309020|3304|浙江省|嘉兴市'
      },
      {
        label: '中国工商银行股份有限公司海盐开发区支行',
        value: '102335309038|3304|浙江省|嘉兴市'
      },
      {
        label: '中国工商银行股份有限公司海盐海滨支行',
        value: '102335309054|3304|浙江省|嘉兴市'
      },
      {
        label: '中国工商银行股份有限公司海盐华园支行',
        value: '102335309062|3304|浙江省|嘉兴市'
      },
      {
        label: '中国工商银行股份有限公司海盐电力支行',
        value: '102335309079|3304|浙江省|嘉兴市'
      },
      {
        label: '中国工商银行股份有限公司桐乡支行',
        value: '102335407507|3304|浙江省|嘉兴市'
      },
      {
        label: '中国工商银行股份有限公司桐乡乌镇支行',
        value: '102335407515|3304|浙江省|嘉兴市'
      },
      {
        label: '中国工商银行股份有限公司桐乡濮院支行',
        value: '102335407523|3304|浙江省|嘉兴市'
      },
      {
        label: '中国工商银行股份有限公司桐乡崇福支行',
        value: '102335407531|3304|浙江省|嘉兴市'
      },
      {
        label: '中国工商银行股份有限公司桐乡梧桐支行',
        value: '102335407540|3304|浙江省|嘉兴市'
      },
      {
        label: '中国工商银行股份有限公司桐乡凤鸣支行',
        value: '102335407574|3304|浙江省|嘉兴市'
      },
      {
        label: '中国工商银行股份有限公司桐乡洲泉支行',
        value: '102335407599|3304|浙江省|嘉兴市'
      },
      {
        label: '中国工商银行股份有限公司桐乡世纪花苑支行',
        value: '102335407603|3304|浙江省|嘉兴市'
      },
      {
        label: '中国工商银行股份有限公司桐乡振兴支行',
        value: '102335407620|3304|浙江省|嘉兴市'
      },
      {
        label: '中国工商银行股份有限公司桐乡新濮支行',
        value: '102335407638|3304|浙江省|嘉兴市'
      },
      {
        label: '中国工商银行股份有限公司海宁支行',
        value: '102335508504|3304|浙江省|嘉兴市'
      },
      {
        label: '中国工商银行股份有限公司海宁长安支行',
        value: '102335508512|3304|浙江省|嘉兴市'
      },
      {
        label: '中国工商银行股份有限公司海宁工人路支行',
        value: '102335508529|3304|浙江省|嘉兴市'
      },
      {
        label: '中国工商银行股份有限公司海宁梅园支行',
        value: '102335508545|3304|浙江省|嘉兴市'
      },
      {
        label: '中国工商银行股份有限公司海宁支行海宁开发区分理处',
        value: '102335508553|3304|浙江省|嘉兴市'
      },
      {
        label: '中国工商银行股份有限公司海宁皮革城支行',
        value: '102335508561|3304|浙江省|嘉兴市'
      },
      {
        label: '中国工商银行股份有限公司海宁袁花支行',
        value: '102335508588|3304|浙江省|嘉兴市'
      },
      {
        label: '中国工商银行股份有限公司海宁文苑桥支行',
        value: '102335508596|3304|浙江省|嘉兴市'
      },
      {
        label: '中国工商银行股份有限公司海宁洛隆路支行',
        value: '102335508607|3304|浙江省|嘉兴市'
      },
      {
        label: '中国工商银行股份有限公司海宁白漾支行',
        value: '102335508615|3304|浙江省|嘉兴市'
      },
      {
        label: '中国工商银行股份有限公司阜阳瑶海支行',
        value: '102372000238|3412|安徽省|阜阳市'
      },
      {
        label: '中国工商银行股份有限公司阜阳颍东支行',
        value: '102372000326|3412|安徽省|阜阳市'
      },
      {
        label: '中国工商银行股份有限公司阜阳颍河支行',
        value: '102372000342|3412|安徽省|阜阳市'
      },
      {
        label: '中国工商银行股份有限公司阜阳文峰支行',
        value: '102372000414|3412|安徽省|阜阳市'
      },
      {
        label: '中国工商银行阜阳分行业务处理中心',
        value: '102372006003|3412|安徽省|阜阳市'
      },
      {
        label: '中国工商银行德化瓷都支行',
        value: '102397623737|3505|福建省|泉州市'
      },
      {
        label: '中国工商银行股份有限公司濮阳中原西路支行',
        value: '102502000395|4109|河南省|濮阳市'
      },
      {
        label: '中国工商银行濮阳分行会计业务处理中心',
        value: '102502002014|4109|河南省|濮阳市'
      },
      {
        label: '中国工商银行股份有限公司濮阳分行',
        value: '102502002022|4109|河南省|濮阳市'
      },
      {
        label: '中国工商银行濮阳分行中原油田支行',
        value: '102502002047|4109|河南省|濮阳市'
      },
      {
        label: '中国工商银行濮阳分行开发区支行',
        value: '102502002055|4109|河南省|濮阳市'
      },
      {
        label: '中国工商银行濮阳分行华龙区支行',
        value: '102502002063|4109|河南省|濮阳市'
      },
      {
        label: '中国工商银行股份有限公司濮阳人民路支行',
        value: '102502002071|4109|河南省|濮阳市'
      },
      {
        label: '中国工商银行股份有限公司濮阳胜利路支行',
        value: '102502002080|4109|河南省|濮阳市'
      },
      {
        label: '中国工商银行股份有限公司濮阳天运支行',
        value: '102502010062|4109|河南省|濮阳市'
      },
      {
        label: '中国工商银行股份有限公司濮阳中原路支行',
        value: '102502013044|4109|河南省|濮阳市'
      },
      {
        label: '中国工商银行股份有限公司濮阳玉门路支行',
        value: '102502013069|4109|河南省|濮阳市'
      },
      {
        label: '中国工商银行股份有限公司濮阳濮京支行',
        value: '102502022029|4109|河南省|濮阳市'
      },
      {
        label: '中国工商银行股份有限公司濮阳大庆路支行',
        value: '102502052062|4109|河南省|濮阳市'
      },
      {
        label: '中国工商银行股份有限公司濮阳兴工支行',
        value: '102502072023|4109|河南省|濮阳市'
      },
      {
        label: '中国工商银行濮阳分行清丰县支行',
        value: '102502102203|4109|河南省|濮阳市'
      },
      {
        label: '中国工商银行濮阳分行南乐支行',
        value: '102502202304|4109|河南省|濮阳市'
      },
      {
        label: '中国工商银行股份有限公司濮阳采油二厂支行',
        value: '102502302103|4109|河南省|濮阳市'
      },
      {
        label: '中国工商银行濮阳分行范县支行',
        value: '102502302402|4109|河南省|濮阳市'
      },
      {
        label: '中国工商银行濮阳分行台前县支行',
        value: '102502402506|4109|河南省|濮阳市'
      },
      {
        label: '中国工商银行濮阳县支行',
        value: '102502502033|4109|河南省|濮阳市'
      },
      {
        label: '中国工商银行股份有限公司濮阳濮东支行',
        value: '102502502092|4109|河南省|濮阳市'
      },
      {
        label: '中国工商银行股份有限公司许昌分行营业部',
        value: '102503002030|4110|河南省|许昌市'
      },
      {
        label: '中国工商银行股份有限公司许昌天平街支行',
        value: '102503002101|4110|河南省|许昌市'
      },
      {
        label: '中国工商银行股份有限公司许昌南关支行',
        value: '102503002208|4110|河南省|许昌市'
      },
      {
        label: '中国工商银行赤壁市支行营业部',
        value: '102536200083|4212|湖北省|咸宁市'
      },
      {
        label: '中国工商银行股份有限公司赤壁新街口支行',
        value: '102536200106|4212|湖北省|咸宁市'
      },
      {
        label: '中国工商银行股份有限公司威海经开支行青岛路分理处',
        value: '102465000331|3710|山东省|威海市'
      },
      {
        label: '中国工商银行股份有限公司威海渔港支行',
        value: '102465000438|3710|山东省|威海市'
      },
      {
        label: '中国工商银行股份有限公司威海经开支行海滨路分理处',
        value: '102465000534|3710|山东省|威海市'
      },
      {
        label: '中国工商银行股份有限公司威海工业园支行',
        value: '102465000542|3710|山东省|威海市'
      },
      {
        label: '中国工商银行股份有限公司威海文化东路支行',
        value: '102465000639|3710|山东省|威海市'
      },
      {
        label: '中国工商银行股份有限公司威海高开支行文化西路储蓄所',
        value: '102465000647|3710|山东省|威海市'
      },
      {
        label: '中国工商银行股份有限公司威海蒿泊支行',
        value: '102465000735|3710|山东省|威海市'
      },
      {
        label: '中国工商银行威海市环翠区支行东码头分理处',
        value: '102465000833|3710|山东省|威海市'
      },
      {
        label: '中国工商银行股份有限公司威海长征路支行',
        value: '102465000930|3710|山东省|威海市'
      },
      {
        label: '中国工商银行股份有限公司威海市中支行西门外分理处',
        value: '102465001037|3710|山东省|威海市'
      },
      {
        label: '中国工商银行股份有限公司威海张村支行',
        value: '102465001131|3710|山东省|威海市'
      },
      {
        label: '中国工商银行股份有限公司威海城里支行',
        value: '102465001238|3710|山东省|威海市'
      },
      {
        label: '中国工商银行股份有限公司威海文化中路支行',
        value: '102465001334|3710|山东省|威海市'
      },
      {
        label: '中国工商银行股份有限公司威海九龙支行',
        value: '102465001439|3710|山东省|威海市'
      },
      {
        label: '中国工商银行威海市分行',
        value: '102465010007|3710|山东省|威海市'
      },
      {
        label: '中国工商银行乳山市支行',
        value: '102465130017|3710|山东省|威海市'
      },
      {
        label: '中国工商银行股份有限公司乳山商业街支行',
        value: '102465130041|3710|山东省|威海市'
      },
      {
        label: '中国工商银行股份有限公司乳山城西支行',
        value: '102465130148|3710|山东省|威海市'
      },
      {
        label: '中国工商银行乳山市支行小区储蓄所',
        value: '102465130349|3710|山东省|威海市'
      },
      {
        label: '中国工商银行股份有限公司乳山城东支行',
        value: '102465130847|3710|山东省|威海市'
      },
      {
        label: '中国工商银行文登市支行',
        value: '102465220012|3710|山东省|威海市'
      },
      {
        label: '中国工商银行股份有限公司文登支行文山路储蓄所',
        value: '102465220045|3710|山东省|威海市'
      },
      {
        label: '中国工商银行股份有限公司文登文山东路支行',
        value: '102465220088|3710|山东省|威海市'
      },
      {
        label: '中国工商银行文登市支行昆嵛路储蓄所',
        value: '102465220246|3710|山东省|威海市'
      },
      {
        label: '中国工商银行文登市支行峰山路储蓄所',
        value: '102465220342|3710|山东省|威海市'
      },
      {
        label: '中国工商银行文登市支行环城路储蓄所',
        value: '102465220447|3710|山东省|威海市'
      },
      {
        label: '中国工商银行荣成市支行',
        value: '102465310018|3710|山东省|威海市'
      },
      {
        label: '中国工商银行荣成石岛支行',
        value: '102465310026|3710|山东省|威海市'
      },
      {
        label: '中国工商银行荣成市支行蜊江分理处',
        value: '102465310034|3710|山东省|威海市'
      },
      {
        label: '中国工商银行股份有限公司荣成成山支行',
        value: '102465310106|3710|山东省|威海市'
      },
      {
        label: '中国工商银行荣成市支行市府分理处',
        value: '102465310139|3710|山东省|威海市'
      },
      {
        label: '中国工商银行股份有限公司嘉兴越秀分理处',
        value: '102335006874|3304|浙江省|嘉兴市'
      },
      {
        label: '中国工商银行股份有限公司嘉兴秀洲新城支行',
        value: '102335006882|3304|浙江省|嘉兴市'
      },
      {
        label: '中国工商银行股份有限公司嘉兴大华支行',
        value: '102335006899|3304|浙江省|嘉兴市'
      },
      {
        label: '中国工商银行股份有限公司嘉善支行',
        value: '102335107006|3304|浙江省|嘉兴市'
      },
      {
        label: '中国工商银行股份有限公司嘉善商城支行',
        value: '102335107014|3304|浙江省|嘉兴市'
      },
      {
        label: '中国工商银行股份有限公司嘉善城南支行',
        value: '102335107022|3304|浙江省|嘉兴市'
      },
      {
        label: '中国工商银行哈尔滨市先锋支行',
        value: '102261001328|2301|黑龙江省|哈尔滨市'
      },
      {
        label: '中国工商银行哈尔滨市南四支行',
        value: '102261001336|2301|黑龙江省|哈尔滨市'
      },
      {
        label: '中国工商银行哈尔滨市水晶支行',
        value: '102261001344|2301|黑龙江省|哈尔滨市'
      },
      {
        label: '中国工商银行哈尔滨市靖宇支行',
        value: '102261001369|2301|黑龙江省|哈尔滨市'
      },
      {
        label: '中国工商银行哈尔滨市平房支行',
        value: '102261001377|2301|黑龙江省|哈尔滨市'
      },
      {
        label: '中国工商银行上饶市分行信州支行铁路医院分理处',
        value: '102433012081|3611|江西省|上饶市'
      },
      {
        label: '中国工商银行上饶市分行信州支行中融分理处',
        value: '102433012090|3611|江西省|上饶市'
      },
      {
        label: '中国工商银行股份有限公司上饶商贸城支行',
        value: '102433012112|3611|江西省|上饶市'
      },
      {
        label: '中国工商银行股份有限公司上饶县南灵支行',
        value: '102433210029|3611|江西省|上饶市'
      },
      {
        label: '中国工商银行上饶县支行',
        value: '102433212114|3611|江西省|上饶市'
      },
      {
        label: '中国工商银行广丰县支行',
        value: '102433312126|3611|江西省|上饶市'
      },
      {
        label: '中国工商银行玉山县支行',
        value: '102433412133|3611|江西省|上饶市'
      },
      {
        label: '中国工商银行股份有限公司铅山支行河口分理处',
        value: '102433510016|3611|江西省|上饶市'
      },
      {
        label: '中国工商银行股份有限公司上饶铅山永平支行',
        value: '102433510065|3611|江西省|上饶市'
      },
      {
        label: '中国工商银行铅山县支行永平铜矿分理处',
        value: '102433512151|3611|江西省|上饶市'
      },
      {
        label: '中国工商银行横峰县支行',
        value: '102433612166|3611|江西省|上饶市'
      },
      {
        label: '中国工商银行股份有限公司潜江东风路支行',
        value: '102537511316|4210|湖北省|荆州市'
      },
      {
        label: '中国工商银行潜江市支行城南分理处',
        value: '102537511332|4210|湖北省|荆州市'
      },
      {
        label: '中国工商银行股份有限公司广州番禺石楼支行',
        value: '102581001057|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州南沙大岗支行',
        value: '102581001065|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州华南支行',
        value: '102581001073|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州番禺平康支行',
        value: '102581001081|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州新窖支行',
        value: '102581001104|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州黄埔军校路支行',
        value: '102581001129|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州红山支行',
        value: '102581001137|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州经济技术开发区东区支行',
        value: '102581001145|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州钟升支行',
        value: '102581001161|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州海珠中路支行',
        value: '102581001170|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州车陂支行',
        value: '102581001196|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州麒麟岗支行',
        value: '102581001207|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州东山铁路支行',
        value: '102581001215|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州陵园西路支行',
        value: '102581001223|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州国防大厦支行',
        value: '102581001231|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司衡阳冶金支行',
        value: '102554002415|4304|湖南省|衡阳市'
      },
      {
        label: '中国工商银行股份有限公司衡阳苗圃支行',
        value: '102554002423|4304|湖南省|衡阳市'
      },
      {
        label: '中国工商银行股份有限公司深圳龙翔支行',
        value: '102584005611|4403|广东省|深圳市'
      },
      {
        label: '中国工商银行绍兴钱清支行',
        value: '102337102014|3306|浙江省|绍兴市'
      },
      {
        label: '中国工商银行淮南泉山分理处',
        value: '102364022239|3404|安徽省|淮南市'
      },
      {
        label: '中国工商银行股份有限公司淮南东淮支行',
        value: '102364022247|3404|安徽省|淮南市'
      },
      {
        label: '中国工商银行股份有限公司淮南龙泉支行',
        value: '102364022263|3404|安徽省|淮南市'
      },
      {
        label: '中国工商银行股份有限公司淮南安理支行',
        value: '102364022271|3404|安徽省|淮南市'
      },
      {
        label: '中国工商银行股份有限公司淮南广场支行',
        value: '102364022302|3404|安徽省|淮南市'
      },
      {
        label: '中国工商银行股份有限公司淮南南岭支行',
        value: '102364022327|3404|安徽省|淮南市'
      },
      {
        label: '中国工商银行邯郸钢城支行',
        value: '102127006498|2103|辽宁省|鞍山市'
      },
      {
        label: '中国工商银行股份有限公司邯郸罗城头支行',
        value: '102127006502|2103|辽宁省|鞍山市'
      },
      {
        label: '中国工商银行邯郸人民支行',
        value: '102127006551|2103|辽宁省|鞍山市'
      },
      {
        label: '中国工商银行邯郸向阳支行',
        value: '102127006617|2103|辽宁省|鞍山市'
      },
      {
        label: '中国工商银行邯郸马头支行',
        value: '102127006676|2103|辽宁省|鞍山市'
      },
      {
        label: '中国工商银行邯郸峰峰支行',
        value: '102127006713|2103|辽宁省|鞍山市'
      },
      {
        label: '中国工商银行股份有限公司邯郸宾悦支行',
        value: '102127007355|2103|辽宁省|鞍山市'
      },
      {
        label: '中国工商银行邯郸彭城支行',
        value: '102127007443|2103|辽宁省|鞍山市'
      },
      {
        label: '中国工商银行邯郸丛台支行',
        value: '102127007533|2103|辽宁省|鞍山市'
      },
      {
        label: '中国工商银行邯郸新国际支行',
        value: '102127007568|2103|辽宁省|鞍山市'
      },
      {
        label: '中国工商银行邯郸高开支行',
        value: '102127080053|2103|辽宁省|鞍山市'
      },
      {
        label: '中国工商银行邯郸城东支行',
        value: '102127106963|1304|河北省|邯郸市'
      },
      {
        label: '中国工商银行股份有限公司大名县支行',
        value: '102128107163|2103|辽宁省|鞍山市'
      },
      {
        label: '中国工商银行魏县支行',
        value: '102128207196|2103|辽宁省|鞍山市'
      },
      {
        label: '中国工商银行曲周支行',
        value: '102128307077|2103|辽宁省|鞍山市'
      },
      {
        label: '中国工商银行股份有限公司邯郸肥乡支行',
        value: '102128604140|2103|辽宁省|鞍山市'
      },
      {
        label: '中国工商银行承德开发区支行',
        value: '102141013064|1308|河北省|承德市'
      },
      {
        label: '中国工商银行承德汇通支行',
        value: '102141013072|1308|河北省|承德市'
      },
      {
        label: '中国工商银行承德鹰手营子支行',
        value: '102141013089|1308|河北省|承德市'
      },
      {
        label: '中国工商银行承德翠桥支行',
        value: '102141013169|1308|河北省|承德市'
      },
      {
        label: '中国工商银行承德二仙居支行',
        value: '102141013177|1308|河北省|承德市'
      },
      {
        label: '中国工商银行承德南营子支行',
        value: '102141013185|1308|河北省|承德市'
      },
      {
        label: '中国工商银行承德普宁支行',
        value: '102141013208|1308|河北省|承德市'
      },
      {
        label: '中国工商银行承德电力支行',
        value: '102141013216|1308|河北省|承德市'
      },
      {
        label: '中国工商银行承德裕华支行',
        value: '102141013249|1308|河北省|承德市'
      },
      {
        label: '中国工商银行承德头道牌楼支行',
        value: '102141013257|1308|河北省|承德市'
      },
      {
        label: '中国工商银行承德站前支行',
        value: '102141013337|1308|河北省|承德市'
      },
      {
        label: '中国工商银行承德富华支行',
        value: '102141013407|1308|河北省|承德市'
      },
      {
        label: '中国工商银行承德县支行',
        value: '102141113113|1308|河北省|承德市'
      },
      {
        label: '中国工商银行承德宽城支行',
        value: '102142113161|1308|河北省|承德市'
      },
      {
        label: '中国工商银行兴隆支行',
        value: '102142213096|1308|河北省|承德市'
      },
      {
        label: '中国工商银行平泉支行',
        value: '102142313155|1308|河北省|承德市'
      },
      {
        label: '中国工商银行滦平支行',
        value: '102142413126|1308|河北省|承德市'
      },
      {
        label: '中国工商银行丰宁支行',
        value: '102142513132|1308|河北省|承德市'
      },
      {
        label: '中国工商银行丰宁支行大阁分理处',
        value: '102142513500|1308|河北省|承德市'
      },
      {
        label: '中国工商银行隆化支行',
        value: '102142613109|1308|河北省|承德市'
      },
      {
        label: '中国工商银行股份有限公司赤峰天峰支行',
        value: '102194000092|1504|内蒙古自治区|赤峰市'
      },
      {
        label: '中国工商银行股份有限公司赤峰分行园林路支行',
        value: '102194000156|1504|内蒙古自治区|赤峰市'
      },
      {
        label: '中国工商银行股份有限公司赤峰振兴支行',
        value: '102194000189|1504|内蒙古自治区|赤峰市'
      },
      {
        label: '中国工商银行股份有限公司赤峰钟楼支行',
        value: '102194000236|1504|内蒙古自治区|赤峰市'
      },
      {
        label: '中国工商银行股份有限公司赤峰天义路分理处',
        value: '102194000269|1504|内蒙古自治区|赤峰市'
      },
      {
        label: '中国工商银行股份有限公司喀喇沁旗和美支行',
        value: '102194000277|1504|内蒙古自治区|赤峰市'
      },
      {
        label: '中国工商银行股份有限公司赤峰银鑫支行',
        value: '102194000324|1504|内蒙古自治区|赤峰市'
      },
      {
        label: '中国工商银行股份有限公司赤峰桥北支行',
        value: '102194000332|1504|内蒙古自治区|赤峰市'
      },
      {
        label: '中国工商银行内蒙古赤峰分行会计核算中心',
        value: '102194002004|1504|内蒙古自治区|赤峰市'
      },
      {
        label: '中国工商银行内蒙古赤峰分行红山支行',
        value: '102194002012|1504|内蒙古自治区|赤峰市'
      },
      {
        label: '中国工商银行股份有限公司赤峰站前支行',
        value: '102194002037|1504|内蒙古自治区|赤峰市'
      },
      {
        label: '中国工商银行内蒙古赤峰分行昭乌达支行',
        value: '102194002045|1504|内蒙古自治区|赤峰市'
      },
      {
        label: '中国工商银行内蒙古赤峰分行松山支行',
        value: '102194002053|1504|内蒙古自治区|赤峰市'
      },
      {
        label: '中国工商银行股份有限公司赤峰新城支行',
        value: '102194002061|1504|内蒙古自治区|赤峰市'
      },
      {
        label: '中国工商银行内蒙古赤峰分行元宝山支行',
        value: '102194002391|1504|内蒙古自治区|赤峰市'
      },
      {
        label: '中国工商银行内蒙古赤峰分行平庄支行',
        value: '102194002439|1504|内蒙古自治区|赤峰市'
      },
      {
        label: '中国工商银行内蒙古赤峰分行广场支行',
        value: '102194002471|1504|内蒙古自治区|赤峰市'
      },
      {
        label: '中国工商银行股份有限公司赤峰东城支行',
        value: '102194002560|1504|内蒙古自治区|赤峰市'
      },
      {
        label: '中国工商银行股份有限公司宁波坝头支行',
        value: '102332018064|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行股份有限公司宁波华山支行',
        value: '102332018089|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行股份有限公司宁波港区支行',
        value: '102332018110|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行股份有限公司宁波梅山保税港区支行',
        value: '102332018128|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行股份有限公司德化环城路支行',
        value: '102397623920|3505|福建省|泉州市'
      },
      {
        label: '中国工商银行石狮人民路支行',
        value: '102397821915|3505|福建省|泉州市'
      },
      {
        label: '中国工商银行股份有限公司石狮子芳路支行',
        value: '102397821923|3505|福建省|泉州市'
      },
      {
        label: '中国工商银行石狮支行蚶江分理处',
        value: '102397821931|3505|福建省|泉州市'
      },
      {
        label: '中国工商银行石狮支行祥芝分理处',
        value: '102397821958|3505|福建省|泉州市'
      },
      {
        label: '中国工商银行股份有限公司石狮德辉支行',
        value: '102397821966|3505|福建省|泉州市'
      },
      {
        label: '中国工商银行石狮市支行',
        value: '102397821982|3505|福建省|泉州市'
      },
      {
        label: '中国工商银行股份有限公司石狮香江路支行',
        value: '102397825246|3505|福建省|泉州市'
      },
      {
        label: '中国工商银行石狮支行南环路分理处',
        value: '102397825262|3505|福建省|泉州市'
      },
      {
        label: '中国工商银行股份有限公司石狮宝岛支行',
        value: '102397825713|3505|福建省|泉州市'
      },
      {
        label: '中国工商银行石狮灵秀支行',
        value: '102397825797|3505|福建省|泉州市'
      },
      {
        label: '中国工商银行股份有限公司石狮服装城支行',
        value: '102397825885|3505|福建省|泉州市'
      },
      {
        label: '中国工商银行石狮八七路支行',
        value: '102397825893|3505|福建省|泉州市'
      },
      {
        label: '中国工商银行漳州市分行',
        value: '102399030006|3506|福建省|漳州市'
      },
      {
        label: '中国工商银行漳州龙江支行',
        value: '102399031009|3506|福建省|漳州市'
      },
      {
        label: '中国工商银行漳州市芗城支行',
        value: '102399032001|3506|福建省|漳州市'
      },
      {
        label: '中国工商银行股份有限公司漳州瑞京路支行',
        value: '102399032044|3506|福建省|漳州市'
      },
      {
        label: '中国工商银行股份有限公司漳州城西支行',
        value: '102399032085|3506|福建省|漳州市'
      },
      {
        label: '中国工商银行连云港港口支行',
        value: '102307002528|3207|江苏省|连云港市'
      },
      {
        label: '中国工商银行股份有限公司连云港西园支行',
        value: '102307002585|3207|江苏省|连云港市'
      },
      {
        label: '中国工商银行连云港朝阳路支行',
        value: '102307003002|3207|江苏省|连云港市'
      },
      {
        label: '中国工商银行连云港朝阳东路支行',
        value: '102307003060|3207|江苏省|连云港市'
      },
      {
        label: '中国工商银行连云港海州支行',
        value: '102307003504|3207|江苏省|连云港市'
      },
      {
        label: '中国工商银行连云港光明支行',
        value: '102307003529|3207|江苏省|连云港市'
      },
      {
        label: '中国工商银行股份有限公司连云港连云新城支行',
        value: '102307003553|3207|江苏省|连云港市'
      },
      {
        label: '中国工商银行股份有限公司连云港海宁西路支行',
        value: '102307003588|3207|江苏省|连云港市'
      },
      {
        label: '中国工商银行连云港经济技术开发区支行',
        value: '102307004005|3207|江苏省|连云港市'
      },
      {
        label: '中国工商银行连云港分行会计业务处理中心',
        value: '102307005506|3207|江苏省|连云港市'
      },
      {
        label: '中国工商银行赣榆支行',
        value: '102307108000|3207|江苏省|连云港市'
      },
      {
        label: '中国工商银行赣榆城北支行',
        value: '102307108018|3207|江苏省|连云港市'
      },
      {
        label: '中国工商银行赣榆中心支行',
        value: '102307108083|3207|江苏省|连云港市'
      },
      {
        label: '中国工商银行东海支行',
        value: '102307208506|3207|江苏省|连云港市'
      },
      {
        label: '中国工商银行股份有限公司东海晶城支行',
        value: '102307208547|3207|江苏省|连云港市'
      },
      {
        label: '中国工商银行股份有限公司东海海陵东路支行',
        value: '102307208555|3207|江苏省|连云港市'
      },
      {
        label: '中国工商银行灌云支行',
        value: '102307309004|3207|江苏省|连云港市'
      },
      {
        label: '中国工商银行灌云中大街支行',
        value: '102307309012|3207|江苏省|连云港市'
      },
      {
        label: '中国工商银行股份有限公司抚顺南台支行',
        value: '102224000162|2104|辽宁省|抚顺市'
      },
      {
        label: '中国工商银行股份有限公司抚顺阿金沟支行',
        value: '102224000200|2104|辽宁省|抚顺市'
      },
      {
        label: '中国工商银行股份有限公司抚顺东洲支行',
        value: '102224000226|2104|辽宁省|抚顺市'
      },
      {
        label: '中国工商银行股份有限公司哈尔滨新疆大街支行',
        value: '102261001385|2301|黑龙江省|哈尔滨市'
      },
      {
        label: '中国工商银行股份有限公司哈尔滨保国支行',
        value: '102261001393|2301|黑龙江省|哈尔滨市'
      },
      {
        label: '中国工商银行阿城市支行',
        value: '102261001408|2301|黑龙江省|哈尔滨市'
      },
      {
        label: '中国工商银行双城市支行',
        value: '102261001416|2301|黑龙江省|哈尔滨市'
      },
      {
        label: '中国工商银行五常市支行',
        value: '102261001424|2301|黑龙江省|哈尔滨市'
      },
      {
        label: '中国工商银行股份有限公司五常山河镇支行',
        value: '102261001432|2301|黑龙江省|哈尔滨市'
      },
      {
        label: '中国工商银行尚志市支行',
        value: '102261001449|2301|黑龙江省|哈尔滨市'
      },
      {
        label: '中国工商银行股份有限公司尚志苇河镇林业局支行',
        value: '102261001457|2301|黑龙江省|哈尔滨市'
      },
      {
        label: '中国工商银行股份有限公司尚志亚布力镇林业局支行',
        value: '102261001473|2301|黑龙江省|哈尔滨市'
      },
      {
        label: '中国工商银行股份有限公司延寿支行',
        value: '102261001481|2301|黑龙江省|哈尔滨市'
      },
      {
        label: '中国工商银行方正县支行',
        value: '102261001490|2301|黑龙江省|哈尔滨市'
      },
      {
        label: '中国工商银行方正县支行高楞分理处',
        value: '102261001504|2301|黑龙江省|哈尔滨市'
      },
      {
        label: '中国工商银行依兰县支行',
        value: '102261001512|2301|黑龙江省|哈尔滨市'
      },
      {
        label: '中国工商银行股份有限公司依兰龙化支行',
        value: '102261001529|2301|黑龙江省|哈尔滨市'
      },
      {
        label: '中国工商银行宾县支行',
        value: '102261001537|2301|黑龙江省|哈尔滨市'
      },
      {
        label: '中国工商银行呼兰县支行',
        value: '102261001545|2301|黑龙江省|哈尔滨市'
      },
      {
        label: '中国工商银行股份有限公司哈尔滨三电厂支行',
        value: '102261001553|2301|黑龙江省|哈尔滨市'
      },
      {
        label: '中国工商银行股份有限公司哈尔滨利民支行',
        value: '102261001561|2301|黑龙江省|哈尔滨市'
      },
      {
        label: '中国工商银行巴彦县支行',
        value: '102261001570|2301|黑龙江省|哈尔滨市'
      },
      {
        label: '中国工商银行股份有限公司巴彦兴隆镇支行',
        value: '102261001588|2301|黑龙江省|哈尔滨市'
      },
      {
        label: '中国工商银行通河县支行',
        value: '102261001596|2301|黑龙江省|哈尔滨市'
      },
      {
        label: '中国工商银行股份有限公司通河清河镇支行',
        value: '102261001607|2301|黑龙江省|哈尔滨市'
      },
      {
        label: '中国工商银行哈尔滨市田地支行驻市政府分理处',
        value: '102261001615|2301|黑龙江省|哈尔滨市'
      },
      {
        label: '中国工商银行股份有限公司哈尔滨房信支行',
        value: '102261006814|2301|黑龙江省|哈尔滨市'
      },
      {
        label: '中国工商银行股份有限公司哈尔滨汉水路支行',
        value: '102261006839|2301|黑龙江省|哈尔滨市'
      },
      {
        label: '中国工商银行哈尔滨西大桥分理处',
        value: '102261030487|2301|黑龙江省|哈尔滨市'
      },
      {
        label: '中国工商银行股份有限公司哈尔滨哈平支行',
        value: '102261030735|2301|黑龙江省|哈尔滨市'
      },
      {
        label: '中国工商银行股份有限公司郑州金苑支行',
        value: '102491017021|4101|河南省|郑州市'
      },
      {
        label: '中国工商银行股份有限公司中山东升支行',
        value: '102603002703|4420|广东省|中山市'
      },
      {
        label: '中国工商银行股份有限公司中山板芙支行',
        value: '102603002711|4420|广东省|中山市'
      },
      {
        label: '中国工商银行股份有限公司中山小榄永宁支行',
        value: '102603002746|4420|广东省|中山市'
      },
      {
        label: '中国工商银行股份有限公司中山高新技术开发区科技支行',
        value: '102603002762|4420|广东省|中山市'
      },
      {
        label: '中国工商银行股份有限公司中山黄圃支行',
        value: '102603002787|4420|广东省|中山市'
      },
      {
        label: '中国工商银行股份有限公司中山张家边濠头支行',
        value: '102603002795|4420|广东省|中山市'
      },
      {
        label: '中国工商银行股份有限公司中山孙文支行',
        value: '102603002800|4420|广东省|中山市'
      },
      {
        label: '中国工商银行股份有限公司中山东升坦背支行',
        value: '102603002818|4420|广东省|中山市'
      },
      {
        label: '中国工商银行股份有限公司中山阜沙支行',
        value: '102603002826|4420|广东省|中山市'
      },
      {
        label: '中国工商银行股份有限公司中山古镇曹步支行',
        value: '102603002834|4420|广东省|中山市'
      },
      {
        label: '中国工商银行股份有限公司中山三乡华丰支行',
        value: '102603002842|4420|广东省|中山市'
      },
      {
        label: '中国工商银行股份有限公司中山银苑支行',
        value: '102603002891|4420|广东省|中山市'
      },
      {
        label: '中国工商银行股份有限公司中山雍逸廷支行',
        value: '102603002963|4420|广东省|中山市'
      },
      {
        label: '中国工商银行股份有限公司中山远洋城支行',
        value: '102603003056|4420|广东省|中山市'
      },
      {
        label: '中国工商银行股份有限公司中山港口裕港支行',
        value: '102603003097|4420|广东省|中山市'
      },
      {
        label: '中国工商银行股份有限公司中山凯茵支行',
        value: '102603005476|4420|广东省|中山市'
      },
      {
        label: '中国工商银行股份有限公司中山横栏四沙支行',
        value: '102603005484|4420|广东省|中山市'
      },
      {
        label: '中国工商银行股份有限公司南阳长江路支行',
        value: '102513072205|4113|河南省|南阳市'
      },
      {
        label: '中国工商银行股份有限公司南阳七一路支行',
        value: '102513082104|4113|河南省|南阳市'
      },
      {
        label: '中国工商银行南阳分行邓州市支行',
        value: '102513202302|4113|河南省|南阳市'
      },
      {
        label: '中国工商银行南阳分行南召县支行',
        value: '102513302702|4113|河南省|南阳市'
      },
      {
        label: '中国工商银行南阳分行方城县支行',
        value: '102513402504|4113|河南省|南阳市'
      },
      {
        label: '中国工商银行南阳分行西峡县支行',
        value: '102513502653|4113|河南省|南阳市'
      },
      {
        label: '中国工商银行南阳分行镇平县支行',
        value: '102513702407|4113|河南省|南阳市'
      },
      {
        label: '中国工商银行股份有限公司英德支行',
        value: '102601202319|4418|广东省|清远市'
      },
      {
        label: '中国工商银行股份有限公司英德富强支行',
        value: '102601202343|4418|广东省|清远市'
      },
      {
        label: '中国工商银行股份有限公司太和支行',
        value: '102372500531|3412|安徽省|阜阳市'
      },
      {
        label: '中国工商银行涡阳支行',
        value: '102372600171|3416|安徽省|亳州市'
      },
      {
        label: '中国工商银行蒙城支行',
        value: '102372700229|3416|安徽省|亳州市'
      },
      {
        label: '中国工商银行股份有限公司阜南支行',
        value: '102372800481|3412|安徽省|阜阳市'
      },
      {
        label: '中国工商银行股份有限公司颍上支行',
        value: '102372900597|3412|安徽省|阜阳市'
      },
      {
        label: '中国工商银行股份有限公司界首支行',
        value: '102373100656|3412|安徽省|阜阳市'
      },
      {
        label: '中国工商银行股份有限公司宿州分行',
        value: '102374000016|3413|安徽省|宿州市'
      },
      {
        label: '中国工商银行股份有限公司宿州汴河支行',
        value: '102374000024|3413|安徽省|宿州市'
      },
      {
        label: '中国工商银行股份有限公司宿州汇通支行',
        value: '102374000032|3413|安徽省|宿州市'
      },
      {
        label: '中国工商银行股份有限公司宿州牡丹支行',
        value: '102374000049|3413|安徽省|宿州市'
      },
      {
        label: '中国工商银行股份有限公司宿州中山支行',
        value: '102374000057|3413|安徽省|宿州市'
      },
      {
        label: '中国工商银行股份有限公司宿州墉桥支行',
        value: '102374000104|3413|安徽省|宿州市'
      },
      {
        label: '中国工商银行股份有限公司宿州城建支行',
        value: '102374000112|3413|安徽省|宿州市'
      },
      {
        label: '中国工商银行股份有限公司宿州符离支行',
        value: '102374000137|3413|安徽省|宿州市'
      },
      {
        label: '中国工商银行股份有限公司宿州淮海分理处',
        value: '102374000145|3413|安徽省|宿州市'
      },
      {
        label: '中国工商银行股份有限公司宿州皖北矿区支行',
        value: '102374000188|3413|安徽省|宿州市'
      },
      {
        label: '中国工商银行股份有限公司宿州芦岭支行',
        value: '102374000196|3413|安徽省|宿州市'
      },
      {
        label: '中国工商银行股份有限公司宿州桃园支行',
        value: '102374000207|3413|安徽省|宿州市'
      },
      {
        label: '中国工商银行股份有限公司宿州朱仙庄支行',
        value: '102374000215|3413|安徽省|宿州市'
      },
      {
        label: '中国工商银行股份有限公司宿州宿东支行',
        value: '102374000223|3413|安徽省|宿州市'
      },
      {
        label: '中国工商银行股份有限公司宿州万达广场支行',
        value: '102374000395|3413|安徽省|宿州市'
      },
      {
        label: '中国工商银行股份有限公司宿州政务区支行',
        value: '102374002022|3413|安徽省|宿州市'
      },
      {
        label: '中国工商银行股份有限公司宿州开发区支行',
        value: '102374002063|3413|安徽省|宿州市'
      },
      {
        label: '中国工商银行股份有限公司砀山支行',
        value: '102374200294|3413|安徽省|宿州市'
      },
      {
        label: '中国工商银行股份有限公司萧县支行',
        value: '102374300237|3413|安徽省|宿州市'
      },
      {
        label: '中国工商银行股份有限公司灵璧支行',
        value: '102374500335|3413|安徽省|宿州市'
      },
      {
        label: '中国工商银行股份有限公司灵璧凤仪支行',
        value: '102374500343|3413|安徽省|宿州市'
      },
      {
        label: '中国工商银行股份有限公司泗县支行',
        value: '102374600375|3413|安徽省|宿州市'
      },
      {
        label: '中国工商银行股份有限公司泗县虹都支行',
        value: '102374602075|3413|安徽省|宿州市'
      },
      {
        label: '中国工商银行股份有限公司滁州琅琊支行',
        value: '102375000114|3411|安徽省|滁州市'
      },
      {
        label: '中国工商银行股份有限公司九江八里湖支行',
        value: '102424010014|3604|江西省|九江市'
      },
      {
        label: '中国工商银行股份有限公司九江长虹支行',
        value: '102424010022|3604|江西省|九江市'
      },
      {
        label: '中国工商银行股份有限公司九江分行营业部',
        value: '102424010039|3604|江西省|九江市'
      },
      {
        label: '中国工商银行股份有限公司九江九瑞路支行',
        value: '102424010047|3604|江西省|九江市'
      },
      {
        label: '中国工商银行股份有限公司九江天鹅泉支行',
        value: '102424010119|3604|江西省|九江市'
      },
      {
        label: '中国工商银行股份有限公司九江金鸡坡支行',
        value: '102424010217|3604|江西省|九江市'
      },
      {
        label: '中国工商银行股份有限公司九江八角石支行',
        value: '102424010225|3604|江西省|九江市'
      },
      {
        label: '中国工商银行绍兴马山支行',
        value: '102337102022|3306|浙江省|绍兴市'
      },
      {
        label: '中国工商银行股份有限公司绍兴锦江支行',
        value: '102337102039|3306|浙江省|绍兴市'
      },
      {
        label: '中国工商银行股份有限公司绍兴阳光支行',
        value: '102337102047|3306|浙江省|绍兴市'
      },
      {
        label: '中国工商银行绍兴县支行齐贤分理处',
        value: '102337102055|3306|浙江省|绍兴市'
      },
      {
        label: '中国工商银行绍兴县支行安昌分理处',
        value: '102337102063|3306|浙江省|绍兴市'
      },
      {
        label: '中国工商银行股份有限公司绍兴马鞍支行',
        value: '102337102071|3306|浙江省|绍兴市'
      },
      {
        label: '中国工商银行股份有限公司成都龙潭工业园区支行',
        value: '102651021128|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都昭觉横街支行',
        value: '102651021136|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都双荆路支行',
        value: '102651021144|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司四川省成都八里庄支行',
        value: '102651021208|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司四川省成都市石灰街支行',
        value: '102651021304|5101|四川省|成都市'
      },
      {
        label: '中国工商银行成都市外北支行',
        value: '102651021407|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司四川省成都市双桥子支行',
        value: '102651021600|5101|四川省|成都市'
      },
      {
        label: '中国工商银行成都市芷泉支行九眼桥分理处',
        value: '102651021706|5101|四川省|成都市'
      },
      {
        label: '中国工商银行成都市青羊宫支行',
        value: '102651021802|5101|四川省|成都市'
      },
      {
        label: '中国工商银行成都市黄田坝支行',
        value: '102651021909|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都簇桥支行',
        value: '102651022008|5101|四川省|成都市'
      },
      {
        label: '中国工商银行成都市茶店子支行',
        value: '102651022104|5101|四川省|成都市'
      },
      {
        label: '中国工商银行成都市彭州市支行营业室',
        value: '102651022303|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司彭州泰安北路支行',
        value: '102651022311|5101|四川省|成都市'
      },
      {
        label: '中国工商银行成都市都江堰市支行营业室',
        value: '102651022400|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司都江堰迎宾大道支行',
        value: '102651022426|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司都江堰安轻路支行',
        value: '102651022442|5101|四川省|成都市'
      },
      {
        label: '中国工商银行成都市崇州市支行营业室',
        value: '102651022506|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司崇州永康西路支行',
        value: '102651022514|5101|四川省|成都市'
      },
      {
        label: '中国工商银行三峡分行伍家岗支行',
        value: '102526005008|4205|湖北省|宜昌市'
      },
      {
        label: '中国工商银行三峡肖亭支行',
        value: '102526005104|4205|湖北省|宜昌市'
      },
      {
        label: '中国工商银行三峡宝塔河支行',
        value: '102526006003|4205|湖北省|宜昌市'
      },
      {
        label: '中国工商银行三峡分行清算中心',
        value: '102526007006|4205|湖北省|宜昌市'
      },
      {
        label: '中国工商银行三峡分行营业部',
        value: '102526007014|4205|湖北省|宜昌市'
      },
      {
        label: '中国工商银行三峡分行临江支行',
        value: '102526007022|4205|湖北省|宜昌市'
      },
      {
        label: '中国工商银行股份有限公司三峡东湖支行',
        value: '102526007047|4205|湖北省|宜昌市'
      },
      {
        label: '中国工商银行股份有限公司三峡亚广支行',
        value: '102526007102|4205|湖北省|宜昌市'
      },
      {
        label: '中国工商银行三峡分行夷陵支行',
        value: '102526007401|4205|湖北省|宜昌市'
      },
      {
        label: '中国工商银行股份有限公司三峡宜昌自贸区支行',
        value: '102526008009|4205|湖北省|宜昌市'
      },
      {
        label: '中国工商银行三峡分行城中支行',
        value: '102526009001|4205|湖北省|宜昌市'
      },
      {
        label: '中国工商银行股份有限公司宜都清江支行',
        value: '102526101514|4205|湖北省|宜昌市'
      },
      {
        label: '中国工商银行股份有限公司宜都长江支行',
        value: '102526101571|4205|湖北省|宜昌市'
      },
      {
        label: '中国工商银行股份有限公司宜都陆城支行',
        value: '102526101602|4205|湖北省|宜昌市'
      },
      {
        label: '中国工商银行股份有限公司宜都枝城支行',
        value: '102526101619|4205|湖北省|宜昌市'
      },
      {
        label: '中国工商银行股份有限公司当阳长坂坡支行',
        value: '102526201687|4205|湖北省|宜昌市'
      },
      {
        label: '中国工商银行股份有限公司武汉领秀支行',
        value: '102521003850|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行股份有限公司武汉汉南支行',
        value: '102521003868|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行股份有限公司武汉岳家嘴支行',
        value: '102521003876|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行股份有限公司武汉古田四路支行',
        value: '102521003884|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行股份有限公司武汉汉街支行',
        value: '102521003892|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行股份有限公司武汉大学园路支行',
        value: '102521003905|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行股份有限公司武汉沿江大道支行',
        value: '102521003913|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行股份有限公司武汉地铁支行',
        value: '102521003921|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行股份有限公司武汉汉口江滩支行',
        value: '102521003930|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行股份有限公司武汉武汉天兴洲大桥支行',
        value: '102521003948|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行股份有限公司武汉品牌广场支行',
        value: '102521003964|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行股份有限公司武汉沌口万达支行',
        value: '102521003972|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行股份有限公司衡阳四一五支行',
        value: '102554002458|4304|湖南省|衡阳市'
      },
      {
        label: '中国工商银行股份有限公司衡阳广场支行',
        value: '102554002503|4304|湖南省|衡阳市'
      },
      {
        label: '中国工商银行股份有限公司衡阳白沙洲支行',
        value: '102554002600|4304|湖南省|衡阳市'
      },
      {
        label: '中国工商银行股份有限公司衡阳福龙湾支行',
        value: '102554002618|4304|湖南省|衡阳市'
      },
      {
        label: '中国工商银行股份有限公司衡阳牌楼支行',
        value: '102554002626|4304|湖南省|衡阳市'
      },
      {
        label: '中国工商银行股份有限公司衡阳师院支行',
        value: '102554002634|4304|湖南省|衡阳市'
      },
      {
        label: '中国工商银行股份有限公司衡阳分行会计业务处理中心',
        value: '102554002780|4304|湖南省|衡阳市'
      },
      {
        label: '中国工商银行股份有限公司衡阳华新支行',
        value: '102554002802|4304|湖南省|衡阳市'
      },
      {
        label: '中国工商银行股份有限公司衡阳三塘支行',
        value: '102554002819|4304|湖南省|衡阳市'
      },
      {
        label: '中国工商银行股份有限公司巢湖汇豪支行',
        value: '102378137211|3401|安徽省|合肥市'
      },
      {
        label: '中国工商银行股份有限公司武汉奓山支行',
        value: '102521004061|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行股份有限公司武汉锦绣龙城支行',
        value: '102521004070|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行湖北省分行营业部会计业务处理中心',
        value: '102521004990|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行湖北省分行清算中心(不办理对外业务)',
        value: '102521009993|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行股份有限公司黄石分行营业部',
        value: '102522006684|4202|湖北省|黄石市'
      },
      {
        label: '中国工商银行黄石市分行会计业务处理中心',
        value: '102522010006|4202|湖北省|黄石市'
      },
      {
        label: '中国工商银行股份有限公司黄石华新路支行',
        value: '102522010014|4202|湖北省|黄石市'
      },
      {
        label: '中国工商银行黄石杭州路支行',
        value: '102522010039|4202|湖北省|黄石市'
      },
      {
        label: '中国工商银行股份有限公司湖州碧浪湖支行',
        value: '102336023059|3305|浙江省|湖州市'
      },
      {
        label: '中国工商银行股份有限公司湖州二里桥支行',
        value: '102336023075|3305|浙江省|湖州市'
      },
      {
        label: '中国工商银行南浔区支行',
        value: '102336024002|3305|浙江省|湖州市'
      },
      {
        label: '中国工商银行湖州双林支行',
        value: '102336024019|3305|浙江省|湖州市'
      },
      {
        label: '中国工商银行湖州练市支行',
        value: '102336024027|3305|浙江省|湖州市'
      },
      {
        label: '中国工商银行股份有限公司湖州南浔建材城支行',
        value: '102336024051|3305|浙江省|湖州市'
      },
      {
        label: '中国工商银行股份有限公司湖州银河支行',
        value: '102336024060|3305|浙江省|湖州市'
      },
      {
        label: '中国工商银行股份有限公司三亚榆林支行',
        value: '102642001289|4602|海南省|三亚市'
      },
      {
        label: '中国工商银行股份有限公司三亚河东支行',
        value: '102642001301|4602|海南省|三亚市'
      },
      {
        label: '中国工商银行股份有限公司三亚河西支行',
        value: '102642001310|4602|海南省|三亚市'
      },
      {
        label: '中国工商银行股份有限公司三亚安游支行',
        value: '102642001328|4602|海南省|三亚市'
      },
      {
        label: '中国工商银行股份有限公司三亚半山半岛支行',
        value: '102642001336|4602|海南省|三亚市'
      },
      {
        label: '中国工商银行股份有限公司三亚解放支行',
        value: '102642001344|4602|海南省|三亚市'
      },
      {
        label: '中国工商银行股份有限公司成都航空路支行',
        value: '102651000044|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都同善桥南街支行',
        value: '102651000052|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都太升南路支行',
        value: '102651000069|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都民丰大道支行',
        value: '102651000085|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都蜀光路支行',
        value: '102651000093|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都金阳路支行',
        value: '102651000116|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司四川省成都小关庙支行',
        value: '102651000124|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都铁路支行',
        value: '102651000181|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都簸箕街支行',
        value: '102651000212|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都人民北路支行',
        value: '102651000261|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司郫县支行正义路分理处',
        value: '102651000296|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都华润路支行',
        value: '102651000323|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都昭觉南路支行',
        value: '102651000340|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都星辉中路支行',
        value: '102651000366|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都白马寺支行',
        value: '102651000374|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都龙腾西路支行',
        value: '102651000438|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都滨江支行大学路分理处',
        value: '102651000454|5101|四川省|成都市'
      },
      {
        label: '中国工商银行十堰市茅箭支行',
        value: '102523000074|4203|湖北省|十堰市'
      },
      {
        label: '中国工商银行十堰市白浪开发区支行',
        value: '102523000082|4203|湖北省|十堰市'
      },
      {
        label: '中国工商银行股份有限公司十堰汽配城支行',
        value: '102523000146|4203|湖北省|十堰市'
      },
      {
        label: '中国工商银行十堰市三堰支行',
        value: '102523000154|4203|湖北省|十堰市'
      },
      {
        label: '中国工商银行十堰北京路支行',
        value: '102523000234|4203|湖北省|十堰市'
      },
      {
        label: '中国工商银行十堰城中支行',
        value: '102523000242|4203|湖北省|十堰市'
      },
      {
        label: '中国工商银行股份有限公司十堰东风支行',
        value: '102523000275|4203|湖北省|十堰市'
      },
      {
        label: '中国工商银行十堰体育路支行',
        value: '102523000283|4203|湖北省|十堰市'
      },
      {
        label: '中国工商银行股份有限公司十堰总装厂支行',
        value: '102523000314|4203|湖北省|十堰市'
      },
      {
        label: '中国工商银行股份有限公司十堰阳光新城支行',
        value: '102523000322|4203|湖北省|十堰市'
      },
      {
        label: '中国工商银行十堰大岭路支行',
        value: '102523000339|4203|湖北省|十堰市'
      },
      {
        label: '中国工商银行股份有限公司十堰浙江路支行',
        value: '102523000347|4203|湖北省|十堰市'
      },
      {
        label: '中国工商银行股份有限公司十堰车架厂支行',
        value: '102523000398|4203|湖北省|十堰市'
      },
      {
        label: '中国工商银行十堰变速箱厂支行',
        value: '102523000419|4203|湖北省|十堰市'
      },
      {
        label: '中国工商银行股份有限公司十堰轴瓦厂支行',
        value: '102523000427|4203|湖北省|十堰市'
      },
      {
        label: '中国工商银行股份有限公司十堰通用铸锻厂支行',
        value: '102523000478|4203|湖北省|十堰市'
      },
      {
        label: '中国工商银行股份有限公司十堰燕林支行',
        value: '102523000525|4203|湖北省|十堰市'
      },
      {
        label: '中国工商银行股份有限公司广州上步支行',
        value: '102581004197|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州轻纺交易园支行',
        value: '102581004244|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州暨南大学支行',
        value: '102581004252|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州正佳支行',
        value: '102581004316|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州岑村支行',
        value: '102581004324|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州中石化大厦支行',
        value: '102581005446|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州嘉怡支行',
        value: '102581005462|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司郑州陇西支行',
        value: '102491017152|4101|河南省|郑州市'
      },
      {
        label: '中国工商银行股份有限公司郑州高新技术开发区支行',
        value: '102491018153|4101|河南省|郑州市'
      },
      {
        label: '中国工商银行股份有限公司郑州康桥花园支行',
        value: '102491022023|4101|河南省|郑州市'
      },
      {
        label: '中国工商银行股份有限公司郑州中原东路支行',
        value: '102491022099|4101|河南省|郑州市'
      },
      {
        label: '中国工商银行股份有限公司郑州中原万达支行',
        value: '102491022154|4101|河南省|郑州市'
      },
      {
        label: '中国工商银行股份有限公司郑州东风路支行',
        value: '102491022179|4101|河南省|郑州市'
      },
      {
        label: '中国工商银行股份有限公司郑州二十一世纪支行',
        value: '102491022200|4101|河南省|郑州市'
      },
      {
        label: '中国工商银行股份有限公司新郑车站支行',
        value: '102491022419|4101|河南省|郑州市'
      },
      {
        label: '中国工商银行股份有限公司郑州投资大厦支行',
        value: '102491022912|4101|河南省|郑州市'
      },
      {
        label: '中国工商银行股份有限公司郑州郑汴路支行',
        value: '102491032027|4101|河南省|郑州市'
      },
      {
        label: '中国工商银行股份有限公司郑州工人路支行',
        value: '102491032051|4101|河南省|郑州市'
      },
      {
        label: '中国工商银行股份有限公司郑州建文支行',
        value: '102491032078|4101|河南省|郑州市'
      },
      {
        label: '中国工商银行股份有限公司郑州五里堡支行',
        value: '102491032086|4101|河南省|郑州市'
      },
      {
        label: '中国工商银行股份有限公司郑州马寨支行',
        value: '102491032094|4101|河南省|郑州市'
      },
      {
        label: '中国工商银行股份有限公司伊川人民路支行',
        value: '102493912285|4103|河南省|洛阳市'
      },
      {
        label: '中国工商银行股份有限公司平顶山新城区支行',
        value: '102495001710|4104|河南省|平顶山市'
      },
      {
        label: '中国工商银行平顶山分行',
        value: '102495002018|4104|河南省|平顶山市'
      },
      {
        label: '中国工商银行平顶山分行火车站支行',
        value: '102495002034|4104|河南省|平顶山市'
      },
      {
        label: '中国工商银行平顶山分行建设东路支行',
        value: '102495002059|4104|河南省|平顶山市'
      },
      {
        label: '中国工商银行平顶山分行建设西路支行',
        value: '102495002083|4104|河南省|平顶山市'
      },
      {
        label: '中国工商银行平顶山分行华鹰支行',
        value: '102495002106|4104|河南省|平顶山市'
      },
      {
        label: '中国工商银行平顶山分行矿区支行',
        value: '102495002251|4104|河南省|平顶山市'
      },
      {
        label: '中国工商银行股份有限公司广昌支行',
        value: '102438211188|3610|江西省|抚州市'
      },
      {
        label: '中国工商银行股份有限公司济南经二路支行',
        value: '102451000109|3701|山东省|济南市'
      },
      {
        label: '中国工商银行股份有限公司济南银河支行',
        value: '102451000117|3701|山东省|济南市'
      },
      {
        label: '中国工商银行股份有限公司济南经十一路支行',
        value: '102451000125|3701|山东省|济南市'
      },
      {
        label: '中国工商银行股份有限公司济南六里山支行',
        value: '102451000133|3701|山东省|济南市'
      },
      {
        label: '中国工商银行股份有限公司济南普利支行',
        value: '102451000141|3701|山东省|济南市'
      },
      {
        label: '中国工商银行股份有限公司济南文东支行',
        value: '102451000150|3701|山东省|济南市'
      },
      {
        label: '中国工商银行股份有限公司济南市中支行',
        value: '102451000168|3701|山东省|济南市'
      },
      {
        label: '中国工商银行股份有限公司济南燕山大厦支行',
        value: '102451000176|3701|山东省|济南市'
      },
      {
        label: '中国工商银行山东省分行营业部营业厅',
        value: '102451000192|3701|山东省|济南市'
      },
      {
        label: '中国工商银行股份有限公司济南市中支行十六里河分理处',
        value: '102451000221|3701|山东省|济南市'
      },
      {
        label: '中国工商银行股份有限公司济南经五路支行',
        value: '102451000230|3701|山东省|济南市'
      },
      {
        label: '中国工商银行股份有限公司济南天桥支行师范路分理处',
        value: '102451000248|3701|山东省|济南市'
      },
      {
        label: '中国工商银行股份有限公司济南天桥支行无影潭分理处',
        value: '102451000264|3701|山东省|济南市'
      },
      {
        label: '中国工商银行股份有限公司济南历下支行',
        value: '102451000301|3701|山东省|济南市'
      },
      {
        label: '中国工商银行股份有限公司济南山大路支行',
        value: '102451000310|3701|山东省|济南市'
      },
      {
        label: '中国工商银行股份有限公司济南明湖支行',
        value: '102451000328|3701|山东省|济南市'
      },
      {
        label: '中国工商银行股份有限公司济南历山支行',
        value: '102451000336|3701|山东省|济南市'
      },
      {
        label: '中国工商银行股份有限公司济南东郊支行',
        value: '102451000344|3701|山东省|济南市'
      },
      {
        label: '中国工商银行章丘市支行',
        value: '102451000416|3701|山东省|济南市'
      },
      {
        label: '中国工商银行股份有限公司济南泺源支行齐鲁医院分理处',
        value: '102451000432|3701|山东省|济南市'
      },
      {
        label: '中国工商银行股份有限公司济南历城支行',
        value: '102451000457|3701|山东省|济南市'
      },
      {
        label: '中国工商银行股份有限公司济南天桥支行',
        value: '102451000504|3701|山东省|济南市'
      },
      {
        label: '中国工商银行股份有限公司济南明湖支行济洛路分理处',
        value: '102451000529|3701|山东省|济南市'
      },
      {
        label: '中国工商银行股份有限公司济南堤口路支行',
        value: '102451000537|3701|山东省|济南市'
      },
      {
        label: '中国工商银行股份有限公司济南纬六路支行',
        value: '102451000607|3701|山东省|济南市'
      },
      {
        label: '中国工商银行股份有限公司济南趵突泉支行舜玉路分理处',
        value: '102451000615|3701|山东省|济南市'
      },
      {
        label: '中国工商银行股份有限公司济南解放东路支行',
        value: '102451000623|3701|山东省|济南市'
      },
      {
        label: '中国工商银行股份有限公司济南槐荫支行',
        value: '102451000631|3701|山东省|济南市'
      },
      {
        label: '中国工商银行股份有限公司济南和平支行',
        value: '102451000640|3701|山东省|济南市'
      },
      {
        label: '中国工商银行股份有限公司济南南辛支行',
        value: '102451000666|3701|山东省|济南市'
      },
      {
        label: '中国工商银行股份有限公司济南洪楼支行',
        value: '102451000703|3701|山东省|济南市'
      },
      {
        label: '中国工商银行股份有限公司济南经十西路支行',
        value: '102451000711|3701|山东省|济南市'
      },
      {
        label: '中国工商银行股份有限公司济南明湖支行洛口分理处',
        value: '102451000720|3701|山东省|济南市'
      },
      {
        label: '中国工商银行股份有限公司济南石化新区支行',
        value: '102451000738|3701|山东省|济南市'
      },
      {
        label: '中国工商银行股份有限公司济南工业南路支行',
        value: '102451000779|3701|山东省|济南市'
      },
      {
        label: '中国工商银行股份有限公司济南历下支行解放阁分理处',
        value: '102451000787|3701|山东省|济南市'
      },
      {
        label: '中国工商银行股份有限公司济南长清支行',
        value: '102451000980|3701|山东省|济南市'
      },
      {
        label: '中国工商银行股份有限公司佛山南海桂海支行',
        value: '102588009031|4406|广东省|佛山市'
      },
      {
        label: '中国工商银行股份有限公司抚州龙津支行',
        value: '102437010085|3610|江西省|抚州市'
      },
      {
        label: '中国工商银行股份有限公司抚州分行',
        value: '102437011004|3610|江西省|抚州市'
      },
      {
        label: '中国工商银行股份有限公司抚州赣东支行',
        value: '102437011012|3610|江西省|抚州市'
      },
      {
        label: '中国工商银行股份有限公司抚州南关支行',
        value: '102437011029|3610|江西省|抚州市'
      },
      {
        label: '中国工商银行股份有限公司抚州文昌支行',
        value: '102437011037|3610|江西省|抚州市'
      },
      {
        label: '中国工商银行股份有限公司抚州城南支行',
        value: '102437011053|3610|江西省|抚州市'
      },
      {
        label: '中国工商银行股份有限公司抚州临川支行',
        value: '102437011061|3610|江西省|抚州市'
      },
      {
        label: '中国工商银行股份有限公司抚州分行营业部',
        value: '102437011070|3610|江西省|抚州市'
      },
      {
        label: '中国工商银行股份有限公司南城盱江支行',
        value: '102437210191|3610|江西省|抚州市'
      },
      {
        label: '中国工商银行股份有限公司南城支行',
        value: '102437211071|3610|江西省|抚州市'
      },
      {
        label: '中国工商银行股份有限公司黎川支行',
        value: '102437311099|3610|江西省|抚州市'
      },
      {
        label: '中国工商银行股份有限公司南丰支行城关分理处',
        value: '102437410232|3610|江西省|抚州市'
      },
      {
        label: '中国工商银行股份有限公司南丰支行',
        value: '102437411104|3610|江西省|抚州市'
      },
      {
        label: '中国工商银行股份有限公司青岛崇阳路支行',
        value: '102452002219|3702|山东省|青岛市'
      },
      {
        label: '中国工商银行股份有限公司青岛麦岛支行',
        value: '102452002235|3702|山东省|青岛市'
      },
      {
        label: '中国工商银行股份有限公司青岛李沧第一支行',
        value: '102452003019|3702|山东省|青岛市'
      },
      {
        label: '中国工商银行股份有限公司青岛李沧第二支行',
        value: '102452003027|3702|山东省|青岛市'
      },
      {
        label: '中国工商银行股份有限公司青岛李沧第二支行娄山后分理处',
        value: '102452003035|3702|山东省|青岛市'
      },
      {
        label: '中国工商银行股份有限公司青岛德仁路支行',
        value: '102452003043|3702|山东省|青岛市'
      },
      {
        label: '中国工商银行股份有限公司青岛金水路支行',
        value: '102452003094|3702|山东省|青岛市'
      },
      {
        label: '中国工商银行股份有限公司青岛香港中路信息大厦支行',
        value: '102452003117|3702|山东省|青岛市'
      },
      {
        label: '中国工商银行股份有限公司福州迎宾路支行',
        value: '102391052716|3501|福建省|福州市'
      },
      {
        label: '中国工商银行福州金山支行',
        value: '102391052765|3501|福建省|福州市'
      },
      {
        label: '中国工商银行福州南街支行',
        value: '102391052773|3501|福建省|福州市'
      },
      {
        label: '中国工商银行福州于山支行',
        value: '102391052781|3501|福建省|福州市'
      },
      {
        label: '中国工商银行股份有限公司福州观凤亭支行',
        value: '102391052804|3501|福建省|福州市'
      },
      {
        label: '中国工商银行福州南门支行津门分理处',
        value: '102391052829|3501|福建省|福州市'
      },
      {
        label: '中国工商银行福州南门支行广达分理处',
        value: '102391052845|3501|福建省|福州市'
      },
      {
        label: '中国工商银行福州锦江支行',
        value: '102391052853|3501|福建省|福州市'
      },
      {
        label: '中国工商银行股份有限公司长乐长山湖支行',
        value: '102391052861|3501|福建省|福州市'
      },
      {
        label: '中国工商银行股份有限公司福州双抛桥支行',
        value: '102391052870|3501|福建省|福州市'
      },
      {
        label: '中国工商银行股份有限公司福州加洋路支行',
        value: '102391052888|3501|福建省|福州市'
      },
      {
        label: '中国工商银行股份有限公司福州黎明支行',
        value: '102391052915|3501|福建省|福州市'
      },
      {
        label: '中国工商银行股份有限公司福州福新路支行',
        value: '102391052923|3501|福建省|福州市'
      },
      {
        label: '中国工商银行福州南门支行灵响分理处',
        value: '102391052931|3501|福建省|福州市'
      },
      {
        label: '中国工商银行股份有限公司福州行政中心支行',
        value: '102391052958|3501|福建省|福州市'
      },
      {
        label: '中国工商银行福州亭江支行',
        value: '102391052966|3501|福建省|福州市'
      },
      {
        label: '中国工商银行福州后街支行',
        value: '102391052974|3501|福建省|福州市'
      },
      {
        label: '中国工商银行福州马尾支行快安分理处',
        value: '102391053014|3501|福建省|福州市'
      },
      {
        label: '中国工商银行股份有限公司福州前屿支行',
        value: '102391053039|3501|福建省|福州市'
      },
      {
        label: '中国工商银行福州大名城支行',
        value: '102391053047|3501|福建省|福州市'
      },
      {
        label: '中国工商银行福州王庄支行',
        value: '102391053055|3501|福建省|福州市'
      },
      {
        label: '中国工商银行股份有限公司福州凤坂支行',
        value: '102391053071|3501|福建省|福州市'
      },
      {
        label: '中国工商银行福州紫阳支行',
        value: '102391053098|3501|福建省|福州市'
      },
      {
        label: '中国工商银行福州金泉支行',
        value: '102391053102|3501|福建省|福州市'
      },
      {
        label: '中国工商银行股份有限公司福州塔头支行',
        value: '102391053119|3501|福建省|福州市'
      },
      {
        label: '中国工商银行股份有限公司福州北尚支行',
        value: '102391053127|3501|福建省|福州市'
      },
      {
        label: '中国工商银行福州洋下支行',
        value: '102391053135|3501|福建省|福州市'
      },
      {
        label: '中国工商银行福州金兴支行',
        value: '102391053151|3501|福建省|福州市'
      },
      {
        label: '中国工商银行股份有限公司福州沁园路支行',
        value: '102391053160|3501|福建省|福州市'
      },
      {
        label: '中国工商银行福州温泉公园支行',
        value: '102391053178|3501|福建省|福州市'
      },
      {
        label: '中国工商银行福州东江滨支行',
        value: '102391053209|3501|福建省|福州市'
      },
      {
        label: '中国工商银行股份有限公司福州福飞北路支行',
        value: '102391053217|3501|福建省|福州市'
      },
      {
        label: '中国工商银行股份有限公司福州南江滨支行',
        value: '102391053233|3501|福建省|福州市'
      },
      {
        label: '中国工商银行股份有限公司福州二手房金融服务中心支行',
        value: '102391053241|3501|福建省|福州市'
      },
      {
        label: '中国工商银行福州凤凰支行',
        value: '102391053250|3501|福建省|福州市'
      },
      {
        label: '中国工商银行股份有限公司福州空司支行',
        value: '102391053268|3501|福建省|福州市'
      },
      {
        label: '中国工商银行福州荣侨支行',
        value: '102391053276|3501|福建省|福州市'
      },
      {
        label: '中国工商银行股份有限公司福州福湾支行',
        value: '102391053292|3501|福建省|福州市'
      },
      {
        label: '中国工商银行股份有限公司福州西环中路支行',
        value: '102391053305|3501|福建省|福州市'
      },
      {
        label: '中国工商银行股份有限公司福州福大支行',
        value: '102391053313|3501|福建省|福州市'
      },
      {
        label: '中国工商银行股份有限公司闽侯上街支行',
        value: '102391053330|3501|福建省|福州市'
      },
      {
        label: '中国工商银行股份有限公司闽侯尚干支行',
        value: '102391053348|3501|福建省|福州市'
      },
      {
        label: '中国工商银行闽侯南屿支行',
        value: '102391053356|3501|福建省|福州市'
      },
      {
        label: '中国工商银行股份有限公司庐江四牌楼分理处',
        value: '102378200023|3401|安徽省|合肥市'
      },
      {
        label: '中国工商银行巢湖市庐江县支行',
        value: '102378207105|3401|安徽省|合肥市'
      },
      {
        label: '中国工商银行股份有限公司无为襄安分理处',
        value: '102378300024|3401|安徽省|合肥市'
      },
      {
        label: '中国工商银行股份有限公司无为凤河路分理处',
        value: '102378300049|3401|安徽省|合肥市'
      },
      {
        label: '中国工商银行股份有限公司无为高沟支行',
        value: '102378300081|3401|安徽省|合肥市'
      },
      {
        label: '中国工商银行巢湖市无为县支行',
        value: '102378307000|3401|安徽省|合肥市'
      },
      {
        label: '中国工商银行兴山支行',
        value: '102526607116|4205|湖北省|宜昌市'
      },
      {
        label: '中国工商银行股份有限公司秭归支行',
        value: '102526707062|4205|湖北省|宜昌市'
      },
      {
        label: '中国工商银行股份有限公司秭归屈原支行',
        value: '102526707214|4205|湖北省|宜昌市'
      },
      {
        label: '中国工商银行股份有限公司长阳龙舟支行',
        value: '102526804612|4205|湖北省|宜昌市'
      },
      {
        label: '中国工商银行股份有限公司长阳支行',
        value: '102526807123|4205|湖北省|宜昌市'
      },
      {
        label: '中国工商银行股份有限公司五峰渔洋关支行',
        value: '102526904641|4205|湖北省|宜昌市'
      },
      {
        label: '中国工商银行五峰支行',
        value: '102526907094|4205|湖北省|宜昌市'
      },
      {
        label: '中国工商银行股份有限公司襄阳分行业务处理中心',
        value: '102528000016|4206|湖北省|襄阳市'
      },
      {
        label: '中国工商银行股份有限公司襄阳五洲支行',
        value: '102528000024|4206|湖北省|襄阳市'
      },
      {
        label: '中国工商银行股份有限公司襄阳牡丹支行',
        value: '102528000032|4206|湖北省|襄阳市'
      },
      {
        label: '中国工商银行股份有限公司襄阳兴业支行',
        value: '102528000049|4206|湖北省|襄阳市'
      },
      {
        label: '中国工商银行股份有限公司襄阳长征路支行',
        value: '102528000057|4206|湖北省|襄阳市'
      },
      {
        label: '中国工商银行股份有限公司襄阳长征中路支行',
        value: '102528000065|4206|湖北省|襄阳市'
      },
      {
        label: '中国工商银行股份有限公司襄阳航宇支行',
        value: '102528000081|4206|湖北省|襄阳市'
      },
      {
        label: '中国工商银行股份有限公司襄阳铁路大院支行',
        value: '102528000104|4206|湖北省|襄阳市'
      },
      {
        label: '中国工商银行股份有限公司襄阳襄城支行',
        value: '102528000129|4206|湖北省|襄阳市'
      },
      {
        label: '中国工商银行股份有限公司襄阳檀溪支行',
        value: '102528000137|4206|湖北省|襄阳市'
      },
      {
        label: '中国工商银行股份有限公司襄阳东街支行',
        value: '102528000145|4206|湖北省|襄阳市'
      },
      {
        label: '中国工商银行股份有限公司临澧支行',
        value: '102558407417|4307|湖南省|常德市'
      },
      {
        label: '中国工商银行股份有限公司桃源支行',
        value: '102558500125|4307|湖南省|常德市'
      },
      {
        label: '中国工商银行股份有限公司桃源陬市支行',
        value: '102558501257|4307|湖南省|常德市'
      },
      {
        label: '中国工商银行股份有限公司桃源支行漳江北路分理处',
        value: '102558501265|4307|湖南省|常德市'
      },
      {
        label: '中国工商银行股份有限公司石门支行',
        value: '102558600118|4307|湖南省|常德市'
      },
      {
        label: '中国工商银行股份有限公司石门支行新街口分理处',
        value: '102558601143|4307|湖南省|常德市'
      },
      {
        label: '中国工商银行股份有限公司石门支行武陵街分理处',
        value: '102558601151|4307|湖南省|常德市'
      },
      {
        label: '中国工商银行股份有限公司津市支行',
        value: '102558700096|4307|湖南省|常德市'
      },
      {
        label: '中国工商银行股份有限公司津市支行万寿路分理处',
        value: '102558700852|4307|湖南省|常德市'
      },
      {
        label: '中国工商银行股份有限公司津市支行湖桥分理处',
        value: '102558700877|4307|湖南省|常德市'
      },
      {
        label: '中国工商银行股份有限公司张家界南庄坪支行',
        value: '102559000461|4308|湖南省|张家界市'
      },
      {
        label: '中国工商银行股份有限公司张家界大庸桥支行',
        value: '102559000496|4308|湖南省|张家界市'
      },
      {
        label: '中国工商银行股份有限公司张家界后溶街支行',
        value: '102559000515|4308|湖南省|张家界市'
      },
      {
        label: '中国工商银行湖南省张家界市永定支行',
        value: '102559001028|4308|湖南省|张家界市'
      },
      {
        label: '中国工商银行股份公司张家界西溪坪支行',
        value: '102559001052|4308|湖南省|张家界市'
      },
      {
        label: '中国工商银行股份有限公司邵阳新华支行',
        value: '102555000567|4305|湖南省|邵阳市'
      },
      {
        label: '中国工商银行股份有限公司邵阳工业街支行',
        value: '102555000680|4305|湖南省|邵阳市'
      },
      {
        label: '中国工商银行股份有限公司邵阳火车北站支行',
        value: '102555000698|4305|湖南省|邵阳市'
      },
      {
        label: '中国工商银行股份有限公司邵阳兴隆支行',
        value: '102555000719|4305|湖南省|邵阳市'
      },
      {
        label: '中国工商银行股份有限公司邵阳城西支行',
        value: '102555000825|4305|湖南省|邵阳市'
      },
      {
        label: '中国工商银行股份有限公司邵阳百春园支行',
        value: '102555000841|4305|湖南省|邵阳市'
      },
      {
        label: '中国工商银行股份有限公司邵阳南门口支行',
        value: '102555000850|4305|湖南省|邵阳市'
      },
      {
        label: '中国工商银行股份有限公司邵阳红旗路支行',
        value: '102555002103|4305|湖南省|邵阳市'
      },
      {
        label: '中国工商银行股份有限公司邵阳分行业务处理中心',
        value: '102555002187|4305|湖南省|邵阳市'
      },
      {
        label: '中国工商银行股份有限公司邵阳广场支行',
        value: '102555002200|4305|湖南省|邵阳市'
      },
      {
        label: '中国工商银行股份有限公司邵阳塔北支行',
        value: '102555002306|4305|湖南省|邵阳市'
      },
      {
        label: '中国工商银行股份有限公司邵阳敏州路支行',
        value: '102555002701|4305|湖南省|邵阳市'
      },
      {
        label: '中国工商银行股份有限公司邵阳西湖支行',
        value: '102555002808|4305|湖南省|邵阳市'
      },
      {
        label: '中国工商银行股份有限公司珠海柠溪支行',
        value: '102585002243|4404|广东省|珠海市'
      },
      {
        label: '中国工商银行股份有限公司珠海北岭支行',
        value: '102585002251|4404|广东省|珠海市'
      },
      {
        label: '中国工商银行股份有限公司珠海丽景支行',
        value: '102585002278|4404|广东省|珠海市'
      },
      {
        label: '中国工商银行股份有限公司珠海南屏支行',
        value: '102585002286|4404|广东省|珠海市'
      },
      {
        label: '中国工商银行股份有限公司珠海东岸支行',
        value: '102585002294|4404|广东省|珠海市'
      },
      {
        label: '中国工商银行股份有限公司珠海城北支行',
        value: '102585002309|4404|广东省|珠海市'
      },
      {
        label: '中国工商银行股份有限公司珠海中心南支行',
        value: '102585002317|4404|广东省|珠海市'
      },
      {
        label: '中国工商银行股份有限公司珠海五洲支行',
        value: '102585002448|4404|广东省|珠海市'
      },
      {
        label: '中国工商银行股份有限公司珠海明珠支行',
        value: '102585002464|4404|广东省|珠海市'
      },
      {
        label: '中国工商银行股份有限公司东营河口支行繁华分理处',
        value: '102455001035|3705|山东省|东营市'
      },
      {
        label: '中国工商银行股份有限公司邵阳县桥头支行',
        value: '102555301120|4305|湖南省|邵阳市'
      },
      {
        label: '中国工商银行股份有限公司邵阳县支行',
        value: '102555302608|4305|湖南省|邵阳市'
      },
      {
        label: '中国工商银行股份有限公司隆回桃洪路支行',
        value: '102555401266|4305|湖南省|邵阳市'
      },
      {
        label: '中国工商银行股份有限公司隆回城中支行',
        value: '102555401274|4305|湖南省|邵阳市'
      },
      {
        label: '中国工商银行股份有限公司隆回文体路支行',
        value: '102555401311|4305|湖南省|邵阳市'
      },
      {
        label: '中国工商银行股份有限公司隆回支行',
        value: '102555402020|4305|湖南省|邵阳市'
      },
      {
        label: '中国工商银行股份有限公司洞口支行',
        value: '102555502069|4305|湖南省|邵阳市'
      },
      {
        label: '中国工商银行股份有限公司洞口黎园路支行',
        value: '102555503004|4305|湖南省|邵阳市'
      },
      {
        label: '中国工商银行股份有限公司洞口高沙支行',
        value: '102555504701|4305|湖南省|邵阳市'
      },
      {
        label: '中国工商银行股份有限公司定远新大街支行',
        value: '102375523243|3411|安徽省|滁州市'
      },
      {
        label: '中国工商银行凤阳县支行',
        value: '102375607216|3411|安徽省|滁州市'
      },
      {
        label: '中国工商银行股份有限公司凤阳城关支行',
        value: '102375624207|3411|安徽省|滁州市'
      },
      {
        label: '中国工商银行股份有限公司凤阳北门支行',
        value: '102375624223|3411|安徽省|滁州市'
      },
      {
        label: '中国工商银行股份有限公司明光支行',
        value: '102375708212|3411|安徽省|滁州市'
      },
      {
        label: '中国工商银行股份有限公司明光明珠支行',
        value: '102375708229|3411|安徽省|滁州市'
      },
      {
        label: '中国工商银行六安分行业务处理中心',
        value: '102376000010|3415|安徽省|六安市'
      },
      {
        label: '中国工商银行股份有限公司三峡万达广场支行',
        value: '102526000037|4205|湖北省|宜昌市'
      },
      {
        label: '中国工商银行股份有限公司三峡华祥支行',
        value: '102526000070|4205|湖北省|宜昌市'
      },
      {
        label: '中国工商银行股份有限公司三峡大学支行',
        value: '102526000342|4205|湖北省|宜昌市'
      },
      {
        label: '中国工商银行三峡城东支行',
        value: '102526000447|4205|湖北省|宜昌市'
      },
      {
        label: '中国工商银行股份有限公司三峡新外滩支行',
        value: '102526000471|4205|湖北省|宜昌市'
      },
      {
        label: '中国工商银行股份有限公司三峡国贸支行',
        value: '102526000535|4205|湖北省|宜昌市'
      },
      {
        label: '中国工商银行股份有限公司三峡东站支行',
        value: '102526000586|4205|湖北省|宜昌市'
      },
      {
        label: '中国工商银行股份有限公司三峡桃花岭支行',
        value: '102526000617|4205|湖北省|宜昌市'
      },
      {
        label: '中国工商银行三峡电力支行',
        value: '102526000668|4205|湖北省|宜昌市'
      },
      {
        label: '中国工商银行股份有限公司三峡伍临支行',
        value: '102526000721|4205|湖北省|宜昌市'
      },
      {
        label: '中国工商银行股份有限公司三峡西陵支行',
        value: '102526000885|4205|湖北省|宜昌市'
      },
      {
        label: '中国工商银行三峡分行葛洲坝支行',
        value: '102526001007|4205|湖北省|宜昌市'
      },
      {
        label: '中国工商银行三峡西坝支行',
        value: '102526001111|4205|湖北省|宜昌市'
      },
      {
        label: '中国工商银行股份有限公司三峡中南路支行',
        value: '102526001162|4205|湖北省|宜昌市'
      },
      {
        label: '中国工商银行股份有限公司三峡小溪塔支行',
        value: '102526001187|4205|湖北省|宜昌市'
      },
      {
        label: '中国工商银行股份有限公司三峡冯家湾支行',
        value: '102526001259|4205|湖北省|宜昌市'
      },
      {
        label: '中国工商银行股份有限公司三峡丁家坝支行',
        value: '102526001267|4205|湖北省|宜昌市'
      },
      {
        label: '中国工商银行股份有限公司三峡港窑支行',
        value: '102526001656|4205|湖北省|宜昌市'
      },
      {
        label: '中国工商银行三峡分行铁路坝支行',
        value: '102526002227|4205|湖北省|宜昌市'
      },
      {
        label: '中国工商银行股份有限公司三峡夜明珠支行',
        value: '102526002807|4205|湖北省|宜昌市'
      },
      {
        label: '中国工商银行三峡分行云集支行',
        value: '102526003109|4205|湖北省|宜昌市'
      },
      {
        label: '中国工商银行股份有限公司三峡发展大道支行',
        value: '102526004626|4205|湖北省|宜昌市'
      },
      {
        label: '中国工商银行股份有限公司三峡龙泉支行',
        value: '102526004843|4205|湖北省|宜昌市'
      },
      {
        label: '中国工商银行股份有限公司岳阳巴中支行',
        value: '102557060521|4306|湖南省|岳阳市'
      },
      {
        label: '中国工商银行股份有限公司岳阳王家河支行',
        value: '102557060530|4306|湖南省|岳阳市'
      },
      {
        label: '中国工商银行股份有限公司岳阳云溪支行',
        value: '102557060601|4306|湖南省|岳阳市'
      },
      {
        label: '中国工商银行股份有限公司岳阳云溪支行汪家岭分理处',
        value: '102557060628|4306|湖南省|岳阳市'
      },
      {
        label: '中国工商银行股份有限公司岳阳长岭支行',
        value: '102557060708|4306|湖南省|岳阳市'
      },
      {
        label: '中国工商银行股份有限公司岳阳分行',
        value: '102557061401|4306|湖南省|岳阳市'
      },
      {
        label: '中国工商银行股份有限公司岳阳八字门支行',
        value: '102557066106|4306|湖南省|岳阳市'
      },
      {
        label: '中国工商银行股份有限公司岳阳县支行',
        value: '102557161204|4306|湖南省|岳阳市'
      },
      {
        label: '中国工商银行股份有限公司临湘支行',
        value: '102557260903|4306|湖南省|岳阳市'
      },
      {
        label: '中国工商银行股份有限公司临湘支行城关分理处',
        value: '102557260911|4306|湖南省|岳阳市'
      },
      {
        label: '中国工商银行股份有限公司华容支行',
        value: '102557361009|4306|湖南省|岳阳市'
      },
      {
        label: '中国工商银行股份有限公司华容支行银河分理处',
        value: '102557361025|4306|湖南省|岳阳市'
      },
      {
        label: '中国工商银行股份有限公司湘阴支行',
        value: '102557461309|4306|湖南省|岳阳市'
      },
      {
        label: '中国工商银行股份有限公司湘阴支行旭东分理处',
        value: '102557461325|4306|湖南省|岳阳市'
      },
      {
        label: '中国工商银行股份有限公司平江支行',
        value: '102557561100|4306|湖南省|岳阳市'
      },
      {
        label: '中国工商银行股份有限公司平江支行城关分理处',
        value: '102557561118|4306|湖南省|岳阳市'
      },
      {
        label: '中国工商银行股份有限公司汨罗支行',
        value: '102557660801|4306|湖南省|岳阳市'
      },
      {
        label: '中国工商银行股份有限公司岳阳屈原支行',
        value: '102557660810|4306|湖南省|岳阳市'
      },
      {
        label: '中国工商银行驻马店分行会计业务处理中心',
        value: '102511002683|4117|河南省|驻马店市'
      },
      {
        label: '中国工商银行驻马店分行雪松路支行',
        value: '102511002802|4117|河南省|驻马店市'
      },
      {
        label: '中国工商银行股份有限公司驻马店通达支行',
        value: '102511002950|4117|河南省|驻马店市'
      },
      {
        label: '中国工商银行股份有限公司驻马店一五九支行',
        value: '102511010503|4117|河南省|驻马店市'
      },
      {
        label: '中国工商银行股份有限公司驻马店文化路支行',
        value: '102511012505|4117|河南省|驻马店市'
      },
      {
        label: '中国工商银行股份有限公司驻马店东风支行',
        value: '102511012597|4117|河南省|驻马店市'
      },
      {
        label: '中国工商银行股份有限公司佛山南海乐丰支行',
        value: '102588009234|4406|广东省|佛山市'
      },
      {
        label: '中国工商银行股份有限公司佛山南海万科支行',
        value: '102588009242|4406|广东省|佛山市'
      },
      {
        label: '中国工商银行股份有限公司佛山南海广隆支行',
        value: '102588009267|4406|广东省|佛山市'
      },
      {
        label: '中国工商银行股份有限公司佛山南海桂华支行',
        value: '102588009275|4406|广东省|佛山市'
      },
      {
        label: '中国工商银行股份有限公司佛山华夏支行',
        value: '102588009283|4406|广东省|佛山市'
      },
      {
        label: '中国工商银行股份有限公司佛山狮山支行',
        value: '102588009306|4406|广东省|佛山市'
      },
      {
        label: '中国工商银行股份有限公司佛山高新支行',
        value: '102588009689|4406|广东省|佛山市'
      },
      {
        label: '中国工商银行股份有限公司佛山高明沧江支行',
        value: '102588080453|4406|广东省|佛山市'
      },
      {
        label: '中国工商银行股份有限公司佛山金辉支行',
        value: '102588080638|4406|广东省|佛山市'
      },
      {
        label: '中国工商银行股份有限公司成都滨江支行华西医院分理处',
        value: '102651000567|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都万年场支行',
        value: '102651000698|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司四川省成都市四川师范大学支行',
        value: '102651000751|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都万象城支行',
        value: '102651000809|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都鹭岛支行',
        value: '102651000850|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都建设路支行',
        value: '102651000868|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都沙河支行机车车辆厂分理处',
        value: '102651000905|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都双林北支路支行',
        value: '102651000913|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都理工大学支行',
        value: '102651000930|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都天顺路支行',
        value: '102651000956|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都双建路支行',
        value: '102651000964|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都天府大道支行',
        value: '102651000997|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都贝森南路支行',
        value: '102651001037|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司苍南支行江湾分理处',
        value: '102333628462|3303|浙江省|温州市'
      },
      {
        label: '中国工商银行股份有限公司苍南金乡支行',
        value: '102333628479|3303|浙江省|温州市'
      },
      {
        label: '中国工商银行股份有限公司禹州滨河大道支行',
        value: '102503472508|4110|河南省|许昌市'
      },
      {
        label: '中国工商银行漯河分行人民路支行',
        value: '102504002015|4111|河南省|漯河市'
      },
      {
        label: '中国工商银行股份有限公司漯河人民东路支行',
        value: '102504002023|4111|河南省|漯河市'
      },
      {
        label: '中国工商银行漯河分行铁东支行',
        value: '102504002082|4111|河南省|漯河市'
      },
      {
        label: '中国工商银行漯河分行黄河路支行',
        value: '102504002099|4111|河南省|漯河市'
      },
      {
        label: '中国工商银行股份有限公司漯河行行政区支行',
        value: '102504002195|4111|河南省|漯河市'
      },
      {
        label: '中国工商银行漯河分行会计业务处理中心',
        value: '102504002689|4111|河南省|漯河市'
      },
      {
        label: '中国工商银行股份有限公司漯河分行',
        value: '102504002697|4111|河南省|漯河市'
      },
      {
        label: '中国工商银行股份有限公司漯河开源支行',
        value: '102504010218|4111|河南省|漯河市'
      },
      {
        label: '中国工商银行股份有限公司漯河友爱街支行',
        value: '102504012019|4111|河南省|漯河市'
      },
      {
        label: '中国工商银行股份有限公司漯河柳江路支行',
        value: '102504012094|4111|河南省|漯河市'
      },
      {
        label: '中国工商银行股份有限公司漯河滨河路支行',
        value: '102504032014|4111|河南省|漯河市'
      },
      {
        label: '中国工商银行股份有限公司漯河海河路支行',
        value: '102504032098|4111|河南省|漯河市'
      },
      {
        label: '中国工商银行股份有限公司漯河长江路支行',
        value: '102504042092|4111|河南省|漯河市'
      },
      {
        label: '中国工商银行股份有限公司漯河交通南路支行',
        value: '102504052012|4111|河南省|漯河市'
      },
      {
        label: '中国工商银行股份有限公司漯河淞江新区支行',
        value: '102504062018|4111|河南省|漯河市'
      },
      {
        label: '中国工商银行股份有限公司漯河解放中路支行',
        value: '102504062083|4111|河南省|漯河市'
      },
      {
        label: '中国工商银行股份有限公司漯河召陵支行',
        value: '102504072089|4111|河南省|漯河市'
      },
      {
        label: '中国工商银行漯河分行舞阳县支行',
        value: '102504102776|4111|河南省|漯河市'
      },
      {
        label: '中国工商银行股份有限公司舞阳人民中路支行',
        value: '102504132772|4111|河南省|漯河市'
      },
      {
        label: '中国工商银行漯河分行临颍县支行',
        value: '102504202863|4111|河南省|漯河市'
      },
      {
        label: '中国工商银行股份有限公司漯河滨河支行',
        value: '102504302059|4111|河南省|漯河市'
      },
      {
        label: '中国工商银行股份有限公司漯河辽河路支行',
        value: '102504322054|4111|河南省|漯河市'
      },
      {
        label: '中国工商银行平顶山分行舞钢市支行',
        value: '102504402492|4111|河南省|漯河市'
      },
      {
        label: '中国工商银行股份有限公司舞钢寺坡支行',
        value: '102504412495|4111|河南省|漯河市'
      },
      {
        label: '中国工商银行股份有限公司舞钢支行新市场分理处',
        value: '102504422494|4111|河南省|漯河市'
      },
      {
        label: '中国工商银行三门峡分行湖滨支行',
        value: '102505002017|4112|河南省|三门峡市'
      },
      {
        label: '中国工商银行股份有限公司三门峡车站支行',
        value: '102505002025|4112|河南省|三门峡市'
      },
      {
        label: '中国工商银行三门峡分行涧南支行',
        value: '102505002033|4112|河南省|三门峡市'
      },
      {
        label: '中国工商银行股份有限公司三门峡商汇支行',
        value: '102505002084|4112|河南省|三门峡市'
      },
      {
        label: '中国工商银行三门峡分行会计业务处理中心',
        value: '102505002228|4112|河南省|三门峡市'
      },
      {
        label: '中国工商银行股份有限公司三门峡分行',
        value: '102505002293|4112|河南省|三门峡市'
      },
      {
        label: '中国工商银行股份有限公司三门峡黄河路支行',
        value: '102505002412|4112|河南省|三门峡市'
      },
      {
        label: '中国工商银行股份有限公司三门峡万达广场支行',
        value: '102505022294|4112|河南省|三门峡市'
      },
      {
        label: '中国工商银行三门峡分行渑池县支行',
        value: '102505102163|4112|河南省|三门峡市'
      },
      {
        label: '中国工商银行股份有限公司渑池仰韶支行',
        value: '102505112169|4112|河南省|三门峡市'
      },
      {
        label: '中国工商银行股份有限公司亳州分行',
        value: '102372200014|3412|安徽省|阜阳市'
      },
      {
        label: '中国工商银行股份有限公司鹤壁工业园区支行',
        value: '102497042110|4106|河南省|鹤壁市'
      },
      {
        label: '中国工商银行股份有限公司鹤壁兴鹤支行',
        value: '102497052095|4106|河南省|鹤壁市'
      },
      {
        label: '中国工商银行股份有限公司鹤壁九州路支行',
        value: '102497062028|4106|河南省|鹤壁市'
      },
      {
        label: '中国工商银行温州市城东支行',
        value: '102333021105|3303|浙江省|温州市'
      },
      {
        label: '中国工商银行股份有限公司温州城西支行',
        value: '102333021308|3303|浙江省|温州市'
      },
      {
        label: '中国工商银行股份有限公司温州江滨支行',
        value: '102333021316|3303|浙江省|温州市'
      },
      {
        label: '中国工商银行股份有限公司温州五马支行',
        value: '102333021507|3303|浙江省|温州市'
      },
      {
        label: '中国工商银行股份有限公司温州城东支行五洲分理处',
        value: '102333021515|3303|浙江省|温州市'
      },
      {
        label: '中国工商银行苏州东吴南路支行',
        value: '102305026105|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行苏州龙西路支行',
        value: '102305026121|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行股份有限公司苏州澄湖西路支行',
        value: '102305026148|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行苏州桐泾路支行',
        value: '102305026156|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行苏州吴中经济开发区支行',
        value: '102305026172|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行苏州郭巷支行',
        value: '102305026189|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行苏州胥口支行',
        value: '102305026197|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行苏州元和支行',
        value: '102305026515|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行股份有限公司苏州渭塘支行',
        value: '102305026523|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行苏州陆慕分理处',
        value: '102305026531|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行苏州浒关工业园支行',
        value: '102305026540|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行股份有限公司苏州黄埭支行',
        value: '102305026558|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行苏州阳澄湖支行',
        value: '102305026566|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行苏州太平支行',
        value: '102305026574|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行太仓市支行',
        value: '102305102407|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行太仓城西支行',
        value: '102305109443|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行太仓市市区支行',
        value: '102305124023|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行太仓（上合下升）山支行',
        value: '102305124058|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行太仓沙溪支行',
        value: '102305124082|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行太仓新区支行',
        value: '102305124111|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行太仓浮桥分理处',
        value: '102305124120|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行太仓港区支行',
        value: '102305124138|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行太仓浏河支行',
        value: '102305124146|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行股份有限公司太仓陆渡支行',
        value: '102305124187|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行太仓城北支行',
        value: '102305124200|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行太仓璜泾支行',
        value: '102305124218|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行太仓双凤支行',
        value: '102305124226|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行太仓常胜南路支行',
        value: '102305124234|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行昆山市支行',
        value: '102305202306|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行昆山玉峰支行',
        value: '102305202314|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行股份有限公司昆山正仪支行',
        value: '102305202347|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行股份有限公司杭州西溪支行',
        value: '102331025079|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行股份有限公司杭州星桥支行',
        value: '102331025159|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行秦皇岛太阳城支行',
        value: '102126005066|1303|河北省|秦皇岛市'
      },
      {
        label: '中国工商银行秦皇岛燕大支行',
        value: '102126005082|1303|河北省|秦皇岛市'
      },
      {
        label: '中国工商银行秦皇岛西岭支行',
        value: '102126005099|1303|河北省|秦皇岛市'
      },
      {
        label: '中国工商银行秦皇岛东港支行',
        value: '102126005103|1303|河北省|秦皇岛市'
      },
      {
        label: '中国工商银行秦皇岛华运支行',
        value: '102126005111|1303|河北省|秦皇岛市'
      },
      {
        label: '中国工商银行秦皇岛市黄河支行',
        value: '102126005120|1303|河北省|秦皇岛市'
      },
      {
        label: '中国工商银行股份有限公司秦皇岛北戴河新区支行',
        value: '102126005200|1303|河北省|秦皇岛市'
      },
      {
        label: '中国工商银行秦皇岛业务处理中心',
        value: '102126006000|1303|河北省|秦皇岛市'
      },
      {
        label: '中国工商银行青龙支行',
        value: '102126105129|1303|河北省|秦皇岛市'
      },
      {
        label: '中国工商银行昌黎支行',
        value: '102126205149|1303|河北省|秦皇岛市'
      },
      {
        label: '中国工商银行股份有限公司昌黎碣阳支行',
        value: '102126205157|1303|河北省|秦皇岛市'
      },
      {
        label: '中国工商银行股份有限公司昌黎民生支行',
        value: '102126205165|1303|河北省|秦皇岛市'
      },
      {
        label: '中国工商银行股份有限公司秦皇岛抚宁支行',
        value: '102126305180|1303|河北省|秦皇岛市'
      },
      {
        label: '中国工商银行股份有限公司秦皇岛抚宁迎宾支行',
        value: '102126305198|1303|河北省|秦皇岛市'
      },
      {
        label: '中国工商银行股份有限公司卢龙支行',
        value: '102126405214|1303|河北省|秦皇岛市'
      },
      {
        label: '中国工商银行邯郸业务处理中心',
        value: '102127006012|2103|辽宁省|鞍山市'
      },
      {
        label: '中国工商银行邯郸车站支行',
        value: '102127006029|2103|辽宁省|鞍山市'
      },
      {
        label: '中国工商银行股份有限公司河北省邯郸欣甸支行',
        value: '102127006199|2103|辽宁省|鞍山市'
      },
      {
        label: '中国工商银行邯郸中华支行',
        value: '102127006279|2103|辽宁省|鞍山市'
      },
      {
        label: '中国工商银行股份有限公司邯郸丛西支行',
        value: '102127006334|2103|辽宁省|鞍山市'
      },
      {
        label: '中国工商银行邯郸邯钢支行',
        value: '102127006406|2103|辽宁省|鞍山市'
      },
      {
        label: '中国工商银行股份有限公司章丘汇泉支行',
        value: '102451017091|3701|山东省|济南市'
      },
      {
        label: '中国工商银行股份有限公司章丘支行眼明泉分理处',
        value: '102451017114|3701|山东省|济南市'
      },
      {
        label: '中国工商银行股份有限公司章丘双山支行',
        value: '102451017147|3701|山东省|济南市'
      },
      {
        label: '中国工商银行山东省分行(不办理转汇)',
        value: '102451038003|3701|山东省|济南市'
      },
      {
        label: '中国工商银行山东分行营业部',
        value: '102451040480|3701|山东省|济南市'
      },
      {
        label: '中国工商银行青岛市分行',
        value: '102452000012|3702|山东省|青岛市'
      },
      {
        label: '中国工商银行股份有限公司东营河口支行孤北分理处',
        value: '102455001043|3705|山东省|东营市'
      },
      {
        label: '中国工商银行股份有限公司东营河口支行仙河分理处',
        value: '102455001060|3705|山东省|东营市'
      },
      {
        label: '中国工商银行股份有限公司东营西城支行石油大学分理处',
        value: '102455020014|3705|山东省|东营市'
      },
      {
        label: '中国工商银行股份有限公司东营西城支行胜华分理处',
        value: '102455020022|3705|山东省|东营市'
      },
      {
        label: '中国工商银行股份有限公司东营西城支行西三路分理处',
        value: '102455020039|3705|山东省|东营市'
      },
      {
        label: '中国工商银行股份有限公司东营东城支行八分厂分理处',
        value: '102455040023|3705|山东省|东营市'
      },
      {
        label: '中国工商银行股份有限公司东营东城支行府前大街分理处',
        value: '102455040031|3705|山东省|东营市'
      },
      {
        label: '中国工商银行股份有限公司东营东城支行海河小区分理处',
        value: '102455040040|3705|山东省|东营市'
      },
      {
        label: '中国工商银行股份有限公司东营东城胶州路支行',
        value: '102455040099|3705|山东省|东营市'
      },
      {
        label: '中国工商银行股份有限公司东营胜利支行钻井分理处',
        value: '102455060016|3705|山东省|东营市'
      },
      {
        label: '中国工商银行股份有限公司东营胜利支行机厂分理处',
        value: '102455060032|3705|山东省|东营市'
      },
      {
        label: '中国工商银行股份有限公司东营分行营业部',
        value: '102455060049|3705|山东省|东营市'
      },
      {
        label: '中国工商银行股份有限公司东营胜利支行采油分理处',
        value: '102455060057|3705|山东省|东营市'
      },
      {
        label: '中国工商银行股份有限公司东营胜利支行汽修分理处',
        value: '102455060065|3705|山东省|东营市'
      },
      {
        label: '中国工商银行股份有限公司东营胜利支行淄博路分理处',
        value: '102455060073|3705|山东省|东营市'
      },
      {
        label: '中国工商银行股份有限公司东营西城支行南里分理处',
        value: '102455060081|3705|山东省|东营市'
      },
      {
        label: '中国工商银行股份有限公司东营胜利支行青岛路分理处',
        value: '102455060090|3705|山东省|东营市'
      },
      {
        label: '中国工商银行股份有限公司东营胜利支行基东分理处',
        value: '102455060104|3705|山东省|东营市'
      },
      {
        label: '中国工商银行股份有限公司东营胜利支行供应处分理处',
        value: '102455060112|3705|山东省|东营市'
      },
      {
        label: '中国工商银行大连普兰店支行营业部',
        value: '102222220190|2102|辽宁省|大连市'
      },
      {
        label: '中国工商银行股份有限公司大连庄河新开路支行',
        value: '102222302518|2102|辽宁省|大连市'
      },
      {
        label: '中国工商银行股份有限公司大连庄河世纪大街支行',
        value: '102222307511|2102|辽宁省|大连市'
      },
      {
        label: '中国工商银行大连庄河支行营业部',
        value: '102222320242|2102|辽宁省|大连市'
      },
      {
        label: '中国工商银行大连瓦房店东方红分理处',
        value: '102222402205|2102|辽宁省|大连市'
      },
      {
        label: '中国工商银行股份有限公司大连瓦房店长兴岛支行',
        value: '102222405386|2102|辽宁省|大连市'
      },
      {
        label: '中国工商银行大连瓦房店支行营业部',
        value: '102222420215|2102|辽宁省|大连市'
      },
      {
        label: '中国工商银行大连瓦房店岭东分理处',
        value: '102222421183|2102|辽宁省|大连市'
      },
      {
        label: '中国工商银行大连瓦房店瓦轴分理处',
        value: '102222421191|2102|辽宁省|大连市'
      },
      {
        label: '中国工商银行大连瓦房店复州城分理处',
        value: '102222421206|2102|辽宁省|大连市'
      },
      {
        label: '中国工商银行股份有限公司鞍山胜利支行',
        value: '102223000021|2103|辽宁省|鞍山市'
      },
      {
        label: '中国工商银行股份有限公司鞍山山南支行',
        value: '102223000030|2103|辽宁省|鞍山市'
      },
      {
        label: '中国工商银行股份有限公司鞍山九华支行',
        value: '102223000056|2103|辽宁省|鞍山市'
      },
      {
        label: '中国工商银行股份有限公司鞍山中华支行',
        value: '102223000072|2103|辽宁省|鞍山市'
      },
      {
        label: '中国工商银行股份有限公司齐齐哈尔龙沙支行',
        value: '102264004381|2302|黑龙江省|齐齐哈尔市'
      },
      {
        label: '中国工商银行齐齐哈尔市分行核算中心',
        value: '102264005001|2302|黑龙江省|齐齐哈尔市'
      },
      {
        label: '中国工商银行股份有限公司齐齐哈尔安顺路支行',
        value: '102264005085|2302|黑龙江省|齐齐哈尔市'
      },
      {
        label: '中国工商银行股份有限公司齐齐哈尔分行龙花支行',
        value: '102264005173|2302|黑龙江省|齐齐哈尔市'
      },
      {
        label: '中国工商银行股份有限公司深圳富士康支行',
        value: '102584005646|4403|广东省|深圳市'
      },
      {
        label: '中国工商银行淄博市博山区支行山头分理处',
        value: '102453002116|3703|山东省|淄博市'
      },
      {
        label: '中国工商银行慈溪电力支行',
        value: '102332530042|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行慈溪城建支行',
        value: '102332530059|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行股份有限公司慈溪万金支行',
        value: '102332530067|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行股份有限公司慈溪附海支行',
        value: '102332530075|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行股份有限公司慈溪崇寿支行',
        value: '102332530083|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行股份有限公司慈溪大通支行',
        value: '102332530106|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行股份有限公司济南泉城路支行',
        value: '102451002346|3701|山东省|济南市'
      },
      {
        label: '中国工商银行股份有限公司济南朝山街支行',
        value: '102451002354|3701|山东省|济南市'
      },
      {
        label: '中国工商银行股份有限公司济南高新支行',
        value: '102451002362|3701|山东省|济南市'
      },
      {
        label: '中国工商银行股份有限公司济南趵突泉支行泉城公园分理处',
        value: '102451002379|3701|山东省|济南市'
      },
      {
        label: '中国工商银行股份有限公司济南趵突泉支行',
        value: '102451002395|3701|山东省|济南市'
      },
      {
        label: '中国工商银行股份有限公司济南燕山支行',
        value: '102451002418|3701|山东省|济南市'
      },
      {
        label: '中国工商银行股份有限公司济南济微路支行',
        value: '102451002506|3701|山东省|济南市'
      },
      {
        label: '中国工商银行股份有限公司济南明湖支行北园路分理处',
        value: '102451002514|3701|山东省|济南市'
      },
      {
        label: '中国工商银行股份有限公司济南馆驿街支行',
        value: '102451002522|3701|山东省|济南市'
      },
      {
        label: '中国工商银行股份有限公司济南城西支行',
        value: '102451002619|3701|山东省|济南市'
      },
      {
        label: '中国工商银行股份有限公司济南槐荫支行匡山分理处',
        value: '102451002627|3701|山东省|济南市'
      },
      {
        label: '中国工商银行股份有限公司济南青龙山支行',
        value: '102451002635|3701|山东省|济南市'
      },
      {
        label: '中国工商银行股份有限公司济南历城支行鲁艺分理处',
        value: '102451005092|3701|山东省|济南市'
      },
      {
        label: '中国工商银行股份有限公司济南闵子骞支行',
        value: '102451006052|3701|山东省|济南市'
      },
      {
        label: '中国工商银行股份有限公司济南开元支行',
        value: '102451006069|3701|山东省|济南市'
      },
      {
        label: '中国工商银行股份有限公司济南大观园支行',
        value: '102451011011|3701|山东省|济南市'
      },
      {
        label: '中国工商银行股份有限公司济南经八路支行',
        value: '102451011046|3701|山东省|济南市'
      },
      {
        label: '中国工商银行股份有限公司济南六一支行',
        value: '102451011118|3701|山东省|济南市'
      },
      {
        label: '中国工商银行股份有限公司济南经十一路支行浪潮路分理处',
        value: '102451011222|3701|山东省|济南市'
      },
      {
        label: '中国工商银行股份有限公司济南经十路支行',
        value: '102451011255|3701|山东省|济南市'
      },
      {
        label: '中国工商银行股份有限公司济南纬一路支行',
        value: '102451011280|3701|山东省|济南市'
      },
      {
        label: '中国工商银行股份有限公司济南七里山支行',
        value: '102451011343|3701|山东省|济南市'
      },
      {
        label: '中国工商银行股份有限公司济南岔路街支行',
        value: '102451011378|3701|山东省|济南市'
      },
      {
        label: '中国工商银行股份有限公司济南齐鲁软件园支行',
        value: '102451011513|3701|山东省|济南市'
      },
      {
        label: '中国工商银行股份有限公司济南经三路支行',
        value: '102451011530|3701|山东省|济南市'
      },
      {
        label: '中国工商银行股份有限公司济南阳光新路支行',
        value: '102451012047|3701|山东省|济南市'
      },
      {
        label: '中国工商银行股份有限公司济南槐西支行',
        value: '102451012055|3701|山东省|济南市'
      },
      {
        label: '中国工商银行股份有限公司济南营市街支行',
        value: '102451012063|3701|山东省|济南市'
      },
      {
        label: '中国工商银行股份有限公司济南辛庄西街支行',
        value: '102451012071|3701|山东省|济南市'
      },
      {
        label: '中国工商银行股份有限公司济南卧龙支行',
        value: '102451012135|3701|山东省|济南市'
      },
      {
        label: '中国工商银行股份有限公司济南无影山支行',
        value: '102451012151|3701|山东省|济南市'
      },
      {
        label: '中国工商银行股份有限公司济南幸福街支行',
        value: '102451012209|3701|山东省|济南市'
      },
      {
        label: '中国工商银行股份有限公司济南段店支行',
        value: '102451012524|3701|山东省|济南市'
      },
      {
        label: '中国工商银行股份有限公司济南槐苑支行',
        value: '102451012532|3701|山东省|济南市'
      },
      {
        label: '中国工商银行股份有限公司济南解放路支行',
        value: '102451013033|3701|山东省|济南市'
      },
      {
        label: '中国工商银行股份有限公司济南工业北路支行',
        value: '102451013084|3701|山东省|济南市'
      },
      {
        label: '中国工商银行股份有限公司济南山师东路支行',
        value: '102451013105|3701|山东省|济南市'
      },
      {
        label: '中国工商银行股份有限公司济南泺文支行',
        value: '102451013130|3701|山东省|济南市'
      },
      {
        label: '中国工商银行股份有限公司济南龙奥大厦支行',
        value: '102451013172|3701|山东省|济南市'
      },
      {
        label: '中国工商银行股份有限公司开封河南大学支行',
        value: '102492022055|4102|河南省|开封市'
      },
      {
        label: '中国工商银行股份有限公司开封五一路支行',
        value: '102492032017|4102|河南省|开封市'
      },
      {
        label: '中国工商银行股份有限公司开封黄河路支行',
        value: '102492032050|4102|河南省|开封市'
      },
      {
        label: '中国工商银行股份有限公司开封金明东街支行',
        value: '102492042053|4102|河南省|开封市'
      },
      {
        label: '中国工商银行股份有限公司开封郑汴路支行',
        value: '102492052018|4102|河南省|开封市'
      },
      {
        label: '中国工商银行股份有限公司厦门康乐支行',
        value: '102393000567|3502|福建省|厦门市'
      },
      {
        label: '中国工商银行厦门海天支行',
        value: '102393000583|3502|福建省|厦门市'
      },
      {
        label: '中国工商银行股份有限公司厦门芙蓉支行',
        value: '102393000591|3502|福建省|厦门市'
      },
      {
        label: '中国工商银行厦门江头支行',
        value: '102393000614|3502|福建省|厦门市'
      },
      {
        label: '中国工商银行厦门莲秀支行',
        value: '102393000622|3502|福建省|厦门市'
      },
      {
        label: '中国工商银行厦门禾山支行',
        value: '102393000639|3502|福建省|厦门市'
      },
      {
        label: '中国工商银行股份有限公司厦门自贸试验区机场支行',
        value: '102393000655|3502|福建省|厦门市'
      },
      {
        label: '中国工商银行股份有限公司厦门高科技园支行',
        value: '102393000663|3502|福建省|厦门市'
      },
      {
        label: '中国工商银行股份有限公司厦门松柏支行',
        value: '102393000671|3502|福建省|厦门市'
      },
      {
        label: '中国工商银行股份有限公司厦门自贸试验区分行',
        value: '102393000702|3502|福建省|厦门市'
      },
      {
        label: '中国工商银行宁波庐山支行',
        value: '102332018152|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行股份有限公司亳州分行业务处理中心',
        value: '102372200022|3412|安徽省|阜阳市'
      },
      {
        label: '中国工商银行股份有限公司亳州涡北支行',
        value: '102372200039|3412|安徽省|阜阳市'
      },
      {
        label: '中国工商银行股份有限公司亳州谯陵支行',
        value: '102372200055|3412|安徽省|阜阳市'
      },
      {
        label: '中国工商银行股份有限公司亳州火车站支行',
        value: '102372200063|3412|安徽省|阜阳市'
      },
      {
        label: '中国工商银行股份有限公司亳州城建支行',
        value: '102372200071|3412|安徽省|阜阳市'
      },
      {
        label: '中国工商银行股份有限公司亳州新华路支行',
        value: '102372200102|3412|安徽省|阜阳市'
      },
      {
        label: '中国工商银行股份有限公司亳州新区支行',
        value: '102372200135|3412|安徽省|阜阳市'
      },
      {
        label: '中国工商银行股份有限公司亳州魏武支行',
        value: '102372200143|3412|安徽省|阜阳市'
      },
      {
        label: '中国工商银行长沙市司门口支行',
        value: '102551000115|4301|湖南省|长沙市'
      },
      {
        label: '中国工商银行长沙市南门口支行',
        value: '102551000131|4301|湖南省|长沙市'
      },
      {
        label: '中国工商银行股份有限公司长沙新开铺支行',
        value: '102551000140|4301|湖南省|长沙市'
      },
      {
        label: '中国工商银行股份有限公司长沙华升支行',
        value: '102551000158|4301|湖南省|长沙市'
      },
      {
        label: '中国工商银行长沙市岳麓山支行',
        value: '102551000166|4301|湖南省|长沙市'
      },
      {
        label: '中国工商银行股份有限公司长沙左家垅支行',
        value: '102551000174|4301|湖南省|长沙市'
      },
      {
        label: '中国工商银行股份有限公司湖州人瑞路支行',
        value: '102336024078|3305|浙江省|湖州市'
      },
      {
        label: '中国工商银行湖州市分行',
        value: '102336025005|3305|浙江省|湖州市'
      },
      {
        label: '中国工商银行湖州菱湖支行',
        value: '102336026008|3305|浙江省|湖州市'
      },
      {
        label: '中国工商银行股份有限公司湖州织里支行',
        value: '102336026016|3305|浙江省|湖州市'
      },
      {
        label: '中国工商银行股份有限公司湖州富民路支行',
        value: '102336026049|3305|浙江省|湖州市'
      },
      {
        label: '中国工商银行股份有限公司湖州八里店支行',
        value: '102336026057|3305|浙江省|湖州市'
      },
      {
        label: '中国工商银行德清县支行',
        value: '102336128001|3305|浙江省|湖州市'
      },
      {
        label: '中国工商银行德清乾元支行',
        value: '102336128010|3305|浙江省|湖州市'
      },
      {
        label: '中国工商银行股份有限公司德清钟管支行',
        value: '102336128028|3305|浙江省|湖州市'
      },
      {
        label: '中国工商银行德清新市支行',
        value: '102336128036|3305|浙江省|湖州市'
      },
      {
        label: '中国工商银行股份有限公司德清舞阳支行',
        value: '102336128044|3305|浙江省|湖州市'
      },
      {
        label: '中国工商银行股份有限公司德清英溪支行',
        value: '102336128052|3305|浙江省|湖州市'
      },
      {
        label: '中国工商银行股份有限公司德清千秋支行',
        value: '102336128085|3305|浙江省|湖州市'
      },
      {
        label: '中国工商银行股份有限公司德清广场支行',
        value: '102336128093|3305|浙江省|湖州市'
      },
      {
        label: '中国工商银行股份有限公司德清雷甸支行',
        value: '102336128108|3305|浙江省|湖州市'
      },
      {
        label: '中国工商银行长兴县支行',
        value: '102336227000|3305|浙江省|湖州市'
      },
      {
        label: '中国工商银行长兴煤山支行',
        value: '102336227034|3305|浙江省|湖州市'
      },
      {
        label: '中国工商银行股份有限公司长兴龙山支行',
        value: '102336227042|3305|浙江省|湖州市'
      },
      {
        label: '中国工商银行长兴金陵支行',
        value: '102336227059|3305|浙江省|湖州市'
      },
      {
        label: '中国工商银行股份有限公司长兴明珠路支行',
        value: '102336227067|3305|浙江省|湖州市'
      },
      {
        label: '中国工商银行长兴长海路支行',
        value: '102336227075|3305|浙江省|湖州市'
      },
      {
        label: '中国工商银行股份有限公司长兴北城支行',
        value: '102336227083|3305|浙江省|湖州市'
      },
      {
        label: '中国工商银行滁州市东南支行',
        value: '102375000227|3411|安徽省|滁州市'
      },
      {
        label: '中国工商银行滁州车站路分理处',
        value: '102375000235|3411|安徽省|滁州市'
      },
      {
        label: '中国工商银行滁州市汇通支行',
        value: '102375000260|3411|安徽省|滁州市'
      },
      {
        label: '中国工商银行股份有限公司滁州中都支行',
        value: '102375002056|3411|安徽省|滁州市'
      },
      {
        label: '中国工商银行股份有限公司滁州丰乐支行',
        value: '102375002144|3411|安徽省|滁州市'
      },
      {
        label: '中国工商银行股份有限公司滁州会峰阁分理处',
        value: '102375002208|3411|安徽省|滁州市'
      },
      {
        label: '中国工商银行滁州分行业务处理中心',
        value: '102375005006|3411|安徽省|滁州市'
      },
      {
        label: '中国工商银行滁州城建支行',
        value: '102375006285|3411|安徽省|滁州市'
      },
      {
        label: '中国工商银行股份有限公司滁州曙光分理处',
        value: '102375027208|3411|安徽省|滁州市'
      },
      {
        label: '中国工商银行股份有限公司滁州南桥支行',
        value: '102375027216|3411|安徽省|滁州市'
      },
      {
        label: '中国工商银行股份有限公司滁州醉翁亭支行',
        value: '102375027232|3411|安徽省|滁州市'
      },
      {
        label: '中国工商银行股份有限公司滁州银花支行',
        value: '102375028207|3411|安徽省|滁州市'
      },
      {
        label: '中国工商银行股份有限公司滁州开发区分理处',
        value: '102375028223|3411|安徽省|滁州市'
      },
      {
        label: '中国工商银行天长市支行',
        value: '102375203214|3411|安徽省|滁州市'
      },
      {
        label: '中国工商银行天长三圣分理处',
        value: '102375220206|3411|安徽省|滁州市'
      },
      {
        label: '中国工商银行股份有限公司天长南湖支行',
        value: '102375220222|3411|安徽省|滁州市'
      },
      {
        label: '中国工商银行股份有限公司来安汊河支行',
        value: '102375302156|3411|安徽省|滁州市'
      },
      {
        label: '中国工商银行股份有限公司来安支行',
        value: '102375306000|3411|安徽省|滁州市'
      },
      {
        label: '中国工商银行股份有限公司来安中心街支行',
        value: '102375321200|3411|安徽省|滁州市'
      },
      {
        label: '中国工商银行全椒县支行',
        value: '102375404216|3411|安徽省|滁州市'
      },
      {
        label: '中国工商银行股份有限公司全椒南屏支行',
        value: '102375422206|3411|安徽省|滁州市'
      },
      {
        label: '中国工商银行定远县支行',
        value: '102375505212|3411|安徽省|滁州市'
      },
      {
        label: '中国工商银行定远炉桥分理处',
        value: '102375523235|3411|安徽省|滁州市'
      },
      {
        label: '中国工商银行麻城市支行营业部',
        value: '102533110068|4211|湖北省|黄冈市'
      },
      {
        label: '中国工商银行麻城支行金桥理处',
        value: '102533110084|4211|湖北省|黄冈市'
      },
      {
        label: '中国工商银行麻城市支行宋埠分理处',
        value: '102533110092|4211|湖北省|黄冈市'
      },
      {
        label: '中国工商银行股份有限公司麻城支行十字街分理处',
        value: '102533110357|4211|湖北省|黄冈市'
      },
      {
        label: '中国工商银行股份有限公司麻城支行将军路分理处',
        value: '102533110373|4211|湖北省|黄冈市'
      },
      {
        label: '中国工商银行股份有限公司麻城城北支行',
        value: '102533110381|4211|湖北省|黄冈市'
      },
      {
        label: '中国工商银行股份有限公司嘉善亭桥支行',
        value: '102335107039|3304|浙江省|嘉兴市'
      },
      {
        label: '中国工商银行股份有限公司嘉善施家路支行',
        value: '102335107047|3304|浙江省|嘉兴市'
      },
      {
        label: '中国工商银行股份有限公司嘉善支行卖鱼桥分理处',
        value: '102335107055|3304|浙江省|嘉兴市'
      },
      {
        label: '中国工商银行股份有限公司嘉善支行西塘分理处',
        value: '102335107063|3304|浙江省|嘉兴市'
      },
      {
        label: '中国工商银行股份有限公司嘉善姚庄支行',
        value: '102335107071|3304|浙江省|嘉兴市'
      },
      {
        label: '中国工商银行股份有限公司平湖支行',
        value: '102335208000|3304|浙江省|嘉兴市'
      },
      {
        label: '中国工商银行股份有限公司嘉兴乍浦支行',
        value: '102335208018|3304|浙江省|嘉兴市'
      },
      {
        label: '中国工商银行股份有限公司平湖城南支行',
        value: '102335208026|3304|浙江省|嘉兴市'
      },
      {
        label: '中国工商银行荆州市分行仙桃市支行沔阳分理处',
        value: '102537110793|4210|湖北省|荆州市'
      },
      {
        label: '中国工商银行荆州市分行仙桃市支行何李路分理处',
        value: '102537110808|4210|湖北省|荆州市'
      },
      {
        label: '中国工商银行股份有限公司仙桃支行德政园分理处',
        value: '102537110816|4210|湖北省|荆州市'
      },
      {
        label: '中国工商银行仙桃市支行仙桃大道分理处',
        value: '102537110824|4210|湖北省|荆州市'
      },
      {
        label: '中国工商银行股份有限公司石首支行',
        value: '102537210619|4210|湖北省|荆州市'
      },
      {
        label: '中国工商银行股份有限公司沾化支行富国分理处',
        value: '102466500741|3716|山东省|滨州市'
      },
      {
        label: '中国工商银行博兴县支行',
        value: '102466600806|3716|山东省|滨州市'
      },
      {
        label: '中国工商银行股份有限公司博兴纯梁支行',
        value: '102466600814|3716|山东省|滨州市'
      },
      {
        label: '中国工商银行博兴东风分理处',
        value: '102466600855|3716|山东省|滨州市'
      },
      {
        label: '中国工商银行股份有限公司博兴兴福支行',
        value: '102466602334|3716|山东省|滨州市'
      },
      {
        label: '中国工商银行邹平县支行',
        value: '102466700903|3716|山东省|滨州市'
      },
      {
        label: '中国工商银行股份有限公司邹平长山支行',
        value: '102466702345|3716|山东省|滨州市'
      },
      {
        label: '中国工商银行股份有限公司邹平韩店支行',
        value: '102466702361|3716|山东省|滨州市'
      },
      {
        label: '中国工商银行德州市分行',
        value: '102468000015|3714|山东省|德州市'
      },
      {
        label: '中国工商银行德州市解放北路支行',
        value: '102468000023|3714|山东省|德州市'
      },
      {
        label: '中国工商银行德州市三八路支行',
        value: '102468000031|3714|山东省|德州市'
      },
      {
        label: '中国工商银行德州市东风西路支行',
        value: '102468000040|3714|山东省|德州市'
      },
      {
        label: '中国工商银行德州市运河支行',
        value: '102468000058|3714|山东省|德州市'
      },
      {
        label: '中国工商银行德州市东方红路支行',
        value: '102468000066|3714|山东省|德州市'
      },
      {
        label: '中国工商银行股份有限公司德州东地支行',
        value: '102468000103|3714|山东省|德州市'
      },
      {
        label: '中国工商银行股份有限公司德州东风东路支行',
        value: '102468000111|3714|山东省|德州市'
      },
      {
        label: '中国工商银行德州市经济开发区支行',
        value: '102468000146|3714|山东省|德州市'
      },
      {
        label: '中国工商银行股份有限公司德州富荣支行',
        value: '102468000162|3714|山东省|德州市'
      },
      {
        label: '中国工商银行德州市铁道西支行',
        value: '102468000242|3714|山东省|德州市'
      },
      {
        label: '中国工商银行股份有限公司德州新华路支行',
        value: '102468000259|3714|山东省|德州市'
      },
      {
        label: '中国工商银行股份有限公司德州天衢路支行',
        value: '102468000306|3714|山东省|德州市'
      },
      {
        label: '中国工商银行德州市德城支行',
        value: '102468000427|3714|山东省|德州市'
      },
      {
        label: '中国工商银行德州市分行会计核算中心',
        value: '102468038304|3714|山东省|德州市'
      },
      {
        label: '中国工商银行乐陵市支行',
        value: '102468200332|3714|山东省|德州市'
      },
      {
        label: '中国工商银行陵县支行',
        value: '102468300350|3714|山东省|德州市'
      },
      {
        label: '中国工商银行平原县支行',
        value: '102468400374|3714|山东省|德州市'
      },
      {
        label: '中国工商银行夏津县支行',
        value: '102468500408|3714|山东省|德州市'
      },
      {
        label: '中国工商银行股份有限公司海宁连杭支行',
        value: '102335508623|3304|浙江省|嘉兴市'
      },
      {
        label: '中国工商银行股份有限公司海宁支行南苑分理处',
        value: '102335508631|3304|浙江省|嘉兴市'
      },
      {
        label: '中国工商银行股份有限公司海宁马桥支行',
        value: '102335508640|3304|浙江省|嘉兴市'
      },
      {
        label: '中国工商银行股份有限公司海宁许村支行',
        value: '102335508658|3304|浙江省|嘉兴市'
      },
      {
        label: '中国工商银行股份有限公司海宁斜桥支行',
        value: '102335508666|3304|浙江省|嘉兴市'
      },
      {
        label: '中国工商银行湖州市分行营业部',
        value: '102336021004|3305|浙江省|湖州市'
      },
      {
        label: '中国工商银行股份有限公司湖州仁皇山支行',
        value: '102336021029|3305|浙江省|湖州市'
      },
      {
        label: '中国工商银行股份有限公司湖州滨河支行',
        value: '102336021045|3305|浙江省|湖州市'
      },
      {
        label: '中国工商银行湖州经济开发区支行',
        value: '102336022007|3305|浙江省|湖州市'
      },
      {
        label: '中国工商银行湖州红丰支行',
        value: '102336022015|3305|浙江省|湖州市'
      },
      {
        label: '中国工商银行股份有限公司湖州新城支行',
        value: '102336022023|3305|浙江省|湖州市'
      },
      {
        label: '中国工商银行股份有限公司湖州北门支行',
        value: '102336022040|3305|浙江省|湖州市'
      },
      {
        label: '中国工商银行湖州市陌路支行',
        value: '102336022058|3305|浙江省|湖州市'
      },
      {
        label: '中国工商银行股份有限公司湖州阳光城支行',
        value: '102336022074|3305|浙江省|湖州市'
      },
      {
        label: '中国工商银行股份有限公司湖州埭溪支行',
        value: '102336023000|3305|浙江省|湖州市'
      },
      {
        label: '中国工商银行股份有限公司湖州东街支行',
        value: '102336023018|3305|浙江省|湖州市'
      },
      {
        label: '中国工商银行股份有限公司湖州吴兴支行',
        value: '102336023026|3305|浙江省|湖州市'
      },
      {
        label: '中国工商银行股份有限公司湖州潮音桥支行',
        value: '102336023042|3305|浙江省|湖州市'
      },
      {
        label: '中国工商银行股份有限公司成都富士康德源新城支行',
        value: '102651024389|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都蜀西路支行',
        value: '102651024397|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都二环路北一段支行',
        value: '102651024401|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都金牛支行西南交大新区分理处',
        value: '102651024508|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都光华大道支行',
        value: '102651024557|5101|四川省|成都市'
      },
      {
        label: '中国工商银行成都市跳伞塔支行',
        value: '102651024803|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司深圳光明支行',
        value: '102584009114|4403|广东省|深圳市'
      },
      {
        label: '中国工商银行股份有限公司深圳高新园支行',
        value: '102584009198|4403|广东省|深圳市'
      },
      {
        label: '中国工商银行股份有限公司前海分行',
        value: '102584009227|4403|广东省|深圳市'
      },
      {
        label: '中国工商银行股份有限公司深圳宝民支行',
        value: '102584009251|4403|广东省|深圳市'
      },
      {
        label: '中国工商银行股份有限公司深圳横岗支行',
        value: '102584009286|4403|广东省|深圳市'
      },
      {
        label: '中国工商银行股份有限公司深圳合水口支行',
        value: '102584009309|4403|广东省|深圳市'
      },
      {
        label: '中国工商银行股份有限公司深圳塘尾支行',
        value: '102584009317|4403|广东省|深圳市'
      },
      {
        label: '中国工商银行股份有限公司深圳侨城西街支行',
        value: '102584009325|4403|广东省|深圳市'
      },
      {
        label: '中国工商银行股份有限公司深圳高新园中区支行',
        value: '102584009333|4403|广东省|深圳市'
      },
      {
        label: '中国工商银行股份有限公司深圳高新园北区支行',
        value: '102584009341|4403|广东省|深圳市'
      },
      {
        label: '中国工商银行股份有限公司深圳民治支行',
        value: '102584010387|4403|广东省|深圳市'
      },
      {
        label: '中国工商银行股份有限公司深圳大浪支行',
        value: '102584010395|4403|广东省|深圳市'
      },
      {
        label: '中国工商银行股份有限公司深圳宝中支行',
        value: '102584010400|4403|广东省|深圳市'
      },
      {
        label: '中国工商银行股份有限公司深圳前进路支行',
        value: '102584010418|4403|广东省|深圳市'
      },
      {
        label: '中国工商银行股份有限公司深圳岗厦支行',
        value: '102584010426|4403|广东省|深圳市'
      },
      {
        label: '中国工商银行股份有限公司深圳楼岗支行',
        value: '102584010434|4403|广东省|深圳市'
      },
      {
        label: '中国工商银行股份有限公司深圳欢乐海岸支行',
        value: '102584010969|4403|广东省|深圳市'
      },
      {
        label: '中国工商银行股份有限公司深圳卓越时代广场支行',
        value: '102584010977|4403|广东省|深圳市'
      },
      {
        label: '中国工商银行股份有限公司深圳龙城支行',
        value: '102584010985|4403|广东省|深圳市'
      },
      {
        label: '中国工商银行股份有限公司深圳太古城支行',
        value: '102584011126|4403|广东省|深圳市'
      },
      {
        label: '中国工商银行股份有限公司深圳中心区支行',
        value: '102584011589|4403|广东省|深圳市'
      },
      {
        label: '中国工商银行股份有限公司深圳罗湖口岸支行',
        value: '102584011907|4403|广东省|深圳市'
      },
      {
        label: '中国工商银行股份有限公司广东自贸试验区横琴琴海支行',
        value: '102585000012|4404|广东省|珠海市'
      },
      {
        label: '中国工商银行股份有限公司珠海华发支行',
        value: '102585002018|4404|广东省|珠海市'
      },
      {
        label: '中国工商银行股份有限公司新建南齿支行',
        value: '102421001248|3601|江西省|南昌市'
      },
      {
        label: '中国工商银行股份有限公司南昌经纬支行',
        value: '102421001328|3601|江西省|南昌市'
      },
      {
        label: '中国工商银行股份有限公司邹城岗山支行',
        value: '102461205067|3708|山东省|济宁市'
      },
      {
        label: '中国工商银行股份有限公司邹城鲍店支行',
        value: '102461205075|3708|山东省|济宁市'
      },
      {
        label: '中国工商银行股份有限公司邹城东滩支行',
        value: '102461205083|3708|山东省|济宁市'
      },
      {
        label: '中国工商银行股份有限公司邹城铁西支行',
        value: '102461205114|3708|山东省|济宁市'
      },
      {
        label: '中国工商银行股份有限公司邹城电厂支行',
        value: '102461205122|3708|山东省|济宁市'
      },
      {
        label: '中国工商银行邹城市支行汇梅分理处',
        value: '102461205309|3708|山东省|济宁市'
      },
      {
        label: '中国工商银行微山县支行',
        value: '102461302060|3708|山东省|济宁市'
      },
      {
        label: '中国工商银行微山县支行欢城办事处',
        value: '102461306055|3708|山东省|济宁市'
      },
      {
        label: '中国工商银行鱼台县支行',
        value: '102461401530|3708|山东省|济宁市'
      },
      {
        label: '中国工商银行金乡县支行',
        value: '102461501639|3708|山东省|济宁市'
      },
      {
        label: '中国工商银行股份有限公司嘉祥支行',
        value: '102461601738|3708|山东省|济宁市'
      },
      {
        label: '中国工商银行汶上县支行',
        value: '102461701836|3708|山东省|济宁市'
      },
      {
        label: '中国工商银行泗水县支行',
        value: '102461802207|3708|山东省|济宁市'
      },
      {
        label: '中国工商银行曲阜市支行',
        value: '102461901186|3708|山东省|济宁市'
      },
      {
        label: '中国工商银行股份有限公司曲阜支行逵泉路分理处',
        value: '102461904158|3708|山东省|济宁市'
      },
      {
        label: '中国工商银行泰安市分行',
        value: '102463000016|3709|山东省|泰安市'
      },
      {
        label: '中国工商银行股份有限公司泰安高新支行',
        value: '102463000024|3709|山东省|泰安市'
      },
      {
        label: '中国工商银行泰安市分行核算中心',
        value: '102463000049|3709|山东省|泰安市'
      },
      {
        label: '中国工商银行股份有限公司泰安泰山支行',
        value: '102463001011|3709|山东省|泰安市'
      },
      {
        label: '中国工商银行股份有限公司泰安城西支行',
        value: '102463001038|3709|山东省|泰安市'
      },
      {
        label: '中国工商银行股份有限公司泰安火车站分理处',
        value: '102463001046|3709|山东省|泰安市'
      },
      {
        label: '中国工商银行股份有限公司泰安市泰山区支行东岳大街分理处',
        value: '102463001054|3709|山东省|泰安市'
      },
      {
        label: '中国工商银行股份有限公司泰安望山支行',
        value: '102463002014|3709|山东省|泰安市'
      },
      {
        label: '中国工商银行股份有限公司泰安岱岳支行',
        value: '102463002047|3709|山东省|泰安市'
      },
      {
        label: '中国工商银行宁阳县支行',
        value: '102463105019|3709|山东省|泰安市'
      },
      {
        label: '中国工商银行股份有限公司宁阳支行磁窑分理处',
        value: '102463105027|3709|山东省|泰安市'
      },
      {
        label: '中国工商银行肥城市支行',
        value: '102463204011|3709|山东省|泰安市'
      },
      {
        label: '中国工商银行股份有限公司肥城支行高余分理处',
        value: '102463204020|3709|山东省|泰安市'
      },
      {
        label: '中国工商银行股份有限公司肥城支行矿务局分理处',
        value: '102463204038|3709|山东省|泰安市'
      },
      {
        label: '中国工商银行股份有限公司肥城支行向阳分理处',
        value: '102463204054|3709|山东省|泰安市'
      },
      {
        label: '中国工商银行股份有限公司肥城支行曹庄矿分理处',
        value: '102463204118|3709|山东省|泰安市'
      },
      {
        label: '中国工商银行股份有限公司肥城支行新城路分理处',
        value: '102463204134|3709|山东省|泰安市'
      },
      {
        label: '中国工商银行股份有限公司肥城支行泰西分理处',
        value: '102463204167|3709|山东省|泰安市'
      },
      {
        label: '中国工商银行东平县支行',
        value: '102463306016|3709|山东省|泰安市'
      },
      {
        label: '中国工商银行莱芜市分行业务中心',
        value: '102463410104|3712|山东省|莱芜市'
      },
      {
        label: '中国工商银行股份有限公司济宁东城支行',
        value: '102461009229|3708|山东省|济宁市'
      },
      {
        label: '中国工商银行股份有限公司宁波新大支行',
        value: '102332018169|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行宁波经济技术开发区支行',
        value: '102332019008|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行宁波开发区红联支行',
        value: '102332019016|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行宁波保税区支行',
        value: '102332020009|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行象山县支行',
        value: '102332134009|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行象山石浦支行',
        value: '102332134017|3302|浙江省|宁波市'
      },
      {
        label: '工行浙江省宁波象山丹峰路支行',
        value: '102332134025|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行股份有限公司浙江省宁波象山蓬莱支行',
        value: '102332134033|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行宁海县支行',
        value: '102332233000|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行股份有限公司宁海金水支行',
        value: '102332233018|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行宁海县支行西店分理处',
        value: '102332233026|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行宁海跃龙支行',
        value: '102332233034|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行股份有限公司宁海梅林支行',
        value: '102332233042|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行股份有限公司宁海桃源支行',
        value: '102332233083|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行股份有限公司宁海六合支行',
        value: '102332233106|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行股份有限公司宁海龙山支行',
        value: '102332233114|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行股份有限公司宁海三星支行',
        value: '102332233147|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行股份有限公司宁海兴海支行',
        value: '102332233163|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行股份有限公司余姚分行',
        value: '102332431001|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行余姚江南支行',
        value: '102332431010|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行余姚城北支行',
        value: '102332431028|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行余姚塑料城支行',
        value: '102332431036|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行余姚城建支行',
        value: '102332431044|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行余姚马渚支行',
        value: '102332431052|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行余姚陆埠支行',
        value: '102332431069|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行股份有限公司余姚城西支行',
        value: '102332431077|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行股份有限公司余姚凤山支行',
        value: '102332431093|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行股份有限公司余姚广电支行',
        value: '102332431108|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行股份有限公司合肥幼儿师范支行',
        value: '102361002481|3401|安徽省|合肥市'
      },
      {
        label: '中国工商银行股份有限公司合肥董铺支行',
        value: '102361002504|3401|安徽省|合肥市'
      },
      {
        label: '中国工商银行股份有限公司合肥世纪家园支行',
        value: '102361002512|3401|安徽省|合肥市'
      },
      {
        label: '中国工商银行合肥马鞍山南路支行',
        value: '102361002529|3401|安徽省|合肥市'
      },
      {
        label: '中国工商银行股份有限公司合肥柏堰科技园支行',
        value: '102361002537|3401|安徽省|合肥市'
      },
      {
        label: '中国工商银行哈尔滨市友谊支行',
        value: '102261001125|2301|黑龙江省|哈尔滨市'
      },
      {
        label: '中国工商银行哈尔滨市融汇支行',
        value: '102261001133|2301|黑龙江省|哈尔滨市'
      },
      {
        label: '中国工商银行哈尔滨市大直支行',
        value: '102261001141|2301|黑龙江省|哈尔滨市'
      },
      {
        label: '中国工商银行哈尔滨市革新支行',
        value: '102261001150|2301|黑龙江省|哈尔滨市'
      },
      {
        label: '中国工商银行哈尔滨市驻哈尔滨铁路局支行',
        value: '102261001168|2301|黑龙江省|哈尔滨市'
      },
      {
        label: '中国工商银行哈尔滨市和兴支行',
        value: '102261001176|2301|黑龙江省|哈尔滨市'
      },
      {
        label: '中国工商银行股份有限公司哈尔滨昆仑支行',
        value: '102261001192|2301|黑龙江省|哈尔滨市'
      },
      {
        label: '中国工商银行哈尔滨市开发区支行',
        value: '102261001205|2301|黑龙江省|哈尔滨市'
      },
      {
        label: '中国工商银行股份有限公司哈尔滨长江支行',
        value: '102261001213|2301|黑龙江省|哈尔滨市'
      },
      {
        label: '中国工商银行股份有限公司哈尔滨黄河支行',
        value: '102261001221|2301|黑龙江省|哈尔滨市'
      },
      {
        label: '中国工商银行哈尔滨市森工支行',
        value: '102261001230|2301|黑龙江省|哈尔滨市'
      },
      {
        label: '中国工商银行哈尔滨市森融支行宣桥分理处',
        value: '102261001248|2301|黑龙江省|哈尔滨市'
      },
      {
        label: '中国工商银行淮安南门支行',
        value: '102308002044|3208|江苏省|淮安市'
      },
      {
        label: '中国工商银行淮安清江支行',
        value: '102308002069|3208|江苏省|淮安市'
      },
      {
        label: '中国工商银行股份有限公司淮安清浦支行',
        value: '102308002085|3208|江苏省|淮安市'
      },
      {
        label: '中国工商银行股份有限公司翔宇分理处',
        value: '102308002518|3208|江苏省|淮安市'
      },
      {
        label: '中国工商银行淮安浦东分理处',
        value: '102308002542|3208|江苏省|淮安市'
      },
      {
        label: '中国工商银行淮安市分行营业部',
        value: '102308003012|3208|江苏省|淮安市'
      },
      {
        label: '中国工商银行淮安市新区支行',
        value: '102308004015|3208|江苏省|淮安市'
      },
      {
        label: '中国工商银行淮安深圳路支行',
        value: '102308004023|3208|江苏省|淮安市'
      },
      {
        label: '中国工商银行淮安市富淮分理处',
        value: '102308004040|3208|江苏省|淮安市'
      },
      {
        label: '中国工商银行股份有限公司淮安富誉路支行',
        value: '102308004058|3208|江苏省|淮安市'
      },
      {
        label: '中国工商银行淮安市分行业务处理中心',
        value: '102308005000|3208|江苏省|淮安市'
      },
      {
        label: '中国工商银行淮安市城北支行',
        value: '102308005018|3208|江苏省|淮安市'
      },
      {
        label: '中国工商银行股份有限公司淮安淮北路支行',
        value: '102308005026|3208|江苏省|淮安市'
      },
      {
        label: '中国工商银行股份有限公司淮安王营支行',
        value: '102308005544|3208|江苏省|淮安市'
      },
      {
        label: '中国工商银行淮安市楚州支行',
        value: '102308007013|3208|江苏省|淮安市'
      },
      {
        label: '中国工商银行股份有限公司广州中山大道中支行',
        value: '102581005487|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司哈尔滨中山支行',
        value: '102261032074|2301|黑龙江省|哈尔滨市'
      },
      {
        label: '中国工商银行股份有限公司哈尔滨公滨路支行',
        value: '102261032082|2301|黑龙江省|哈尔滨市'
      },
      {
        label: '中国工商银行股份有限公司哈尔滨衡山路支行',
        value: '102261032200|2301|黑龙江省|哈尔滨市'
      },
      {
        label: '中国工商银行股份有限公司哈尔滨西十二支行',
        value: '102261037628|2301|黑龙江省|哈尔滨市'
      },
      {
        label: '中国工商银行股份有限公司双城新城区分理处',
        value: '102261047084|2301|黑龙江省|哈尔滨市'
      },
      {
        label: '中国工商银行股份有限公司哈尔滨松北支行',
        value: '102261051431|2301|黑龙江省|哈尔滨市'
      },
      {
        label: '中国工商银行股份有限公司齐齐哈尔湖滨支行',
        value: '102264000474|2302|黑龙江省|齐齐哈尔市'
      },
      {
        label: '中国工商银行股份有限公司齐齐哈尔建华支行',
        value: '102264002011|2302|黑龙江省|齐齐哈尔市'
      },
      {
        label: '中国工商银行股份有限公司齐齐哈尔建安支行',
        value: '102264002183|2302|黑龙江省|齐齐哈尔市'
      },
      {
        label: '中国工商银行福州三叉街支行',
        value: '102391052589|3501|福建省|福州市'
      },
      {
        label: '中国工商银行福州下渡支行',
        value: '102391052597|3501|福建省|福州市'
      },
      {
        label: '中国工商银行股份有限公司福州上江城支行',
        value: '102391052601|3501|福建省|福州市'
      },
      {
        label: '中国工商银行福州阳光支行',
        value: '102391052610|3501|福建省|福州市'
      },
      {
        label: '中国工商银行福州金洲支行',
        value: '102391052628|3501|福建省|福州市'
      },
      {
        label: '中国工商银行德兴市支行',
        value: '102434212221|3611|江西省|上饶市'
      },
      {
        label: '中国工商银行上饶市分行德兴铜矿支行',
        value: '102434212230|3611|江西省|上饶市'
      },
      {
        label: '中国工商银行股份有限公司婺源星江支行',
        value: '102434310057|3611|江西省|上饶市'
      },
      {
        label: '中国工商银行婺源县支行',
        value: '102434312241|3611|江西省|上饶市'
      },
      {
        label: '中国工商银行股份有限公司吉安分行',
        value: '102435009000|3608|江西省|吉安市'
      },
      {
        label: '中国工商银行股份有限公司吉安阳明路支行',
        value: '102435009018|3608|江西省|吉安市'
      },
      {
        label: '中国工商银行股份有限公司抚顺胜利支行',
        value: '102224000234|2104|辽宁省|抚顺市'
      },
      {
        label: '中国工商银行股份有限公司抚顺龙凤支行',
        value: '102224000267|2104|辽宁省|抚顺市'
      },
      {
        label: '中国工商银行股份有限公司抚顺雷锋支行',
        value: '102224000314|2104|辽宁省|抚顺市'
      },
      {
        label: '中国工商银行股份有限公司抚顺高湾支行',
        value: '102224000339|2104|辽宁省|抚顺市'
      },
      {
        label: '中国工商银行股份有限公司抚顺古田支行',
        value: '102224000355|2104|辽宁省|抚顺市'
      },
      {
        label: '中国工商银行股份有限公司抚顺和平路支行',
        value: '102224000380|2104|辽宁省|抚顺市'
      },
      {
        label: '中国工商银行股份有限公司抚顺铜城支行',
        value: '102224000419|2104|辽宁省|抚顺市'
      },
      {
        label: '中国工商银行股份有限公司抚顺戈布支行',
        value: '102224000427|2104|辽宁省|抚顺市'
      },
      {
        label: '中国工商银行股份有限公司抚顺西戈支行',
        value: '102224000435|2104|辽宁省|抚顺市'
      },
      {
        label: '中国工商银行股份有限公司抚顺临江路支行',
        value: '102224000509|2104|辽宁省|抚顺市'
      },
      {
        label: '中国工商银行股份有限公司抚顺新城支行',
        value: '102224000517|2104|辽宁省|抚顺市'
      },
      {
        label: '中国工商银行股份有限公司抚顺西将军支行',
        value: '102224000550|2104|辽宁省|抚顺市'
      },
      {
        label: '中国工商银行股份有限公司抚顺前进支行',
        value: '102224000605|2104|辽宁省|抚顺市'
      },
      {
        label: '中国工商银行股份有限公司抚顺台北支行',
        value: '102224000621|2104|辽宁省|抚顺市'
      },
      {
        label: '中国工商银行股份有限公司抚顺南站支行',
        value: '102224000689|2104|辽宁省|抚顺市'
      },
      {
        label: '中国工商银行股份有限公司抚顺东三路支行',
        value: '102224000697|2104|辽宁省|抚顺市'
      },
      {
        label: '中国工商银行股份有限公司抚顺迎宾路支行',
        value: '102224000752|2104|辽宁省|抚顺市'
      },
      {
        label: '中国工商银行抚顺市分行新抚支行',
        value: '102224002019|2104|辽宁省|抚顺市'
      },
      {
        label: '中国工商银行抚顺市分行新抚支行东风分理处',
        value: '102224002027|2104|辽宁省|抚顺市'
      },
      {
        label: '中国工商银行抚顺市分行河北支行城东分理处',
        value: '102224002035|2104|辽宁省|抚顺市'
      },
      {
        label: '中国工商银行抚顺市分行中央路支行',
        value: '102224002043|2104|辽宁省|抚顺市'
      },
      {
        label: '中国工商银行抚顺市分行望花支行',
        value: '102224002051|2104|辽宁省|抚顺市'
      },
      {
        label: '中国工商银行抚顺市分行望花支行工农分理处',
        value: '102224002060|2104|辽宁省|抚顺市'
      },
      {
        label: '中国工商银行抚顺市分行广场支行',
        value: '102224002094|2104|辽宁省|抚顺市'
      },
      {
        label: '中国工商银行抚顺市分行露天支行',
        value: '102224002109|2104|辽宁省|抚顺市'
      },
      {
        label: '中国工商银行抚顺市分行露天支行西露天分理处',
        value: '102224002117|2104|辽宁省|抚顺市'
      },
      {
        label: '中国工商银行抚顺市分行露天支行老虎台分理处',
        value: '102224002133|2104|辽宁省|抚顺市'
      },
      {
        label: '中国工商银行抚顺市分行望花支行李石经济开发区分理处',
        value: '102224002184|2104|辽宁省|抚顺市'
      },
      {
        label: '中国工商银行抚顺市分行河北支行',
        value: '102224002192|2104|辽宁省|抚顺市'
      },
      {
        label: '中国工商银行抚顺市分行河北支行将军分理处',
        value: '102224002205|2104|辽宁省|抚顺市'
      },
      {
        label: '中国工商银行股份有限公司抚顺银河湾支行',
        value: '102224002213|2104|辽宁省|抚顺市'
      },
      {
        label: '中国工商银行抚顺市分行业务处理中心',
        value: '102224002221|2104|辽宁省|抚顺市'
      },
      {
        label: '中国工商银行股份有限公司抚顺沈抚支行',
        value: '102224002230|2104|辽宁省|抚顺市'
      },
      {
        label: '中国工商银行抚顺北台支行',
        value: '102224002248|2104|辽宁省|抚顺市'
      },
      {
        label: '中国工商银行抚顺市分行章党支行',
        value: '102224002256|2104|辽宁省|抚顺市'
      },
      {
        label: '中国工商银行抚顺市分行章党支行6409分理处',
        value: '102224002272|2104|辽宁省|抚顺市'
      },
      {
        label: '中国工商银行朝阳喀左支行',
        value: '102224300139|2104|辽宁省|抚顺市'
      },
      {
        label: '中国工商银行股份有限公司朝阳友谊支行',
        value: '102224301176|2104|辽宁省|抚顺市'
      },
      {
        label: '中国工商银行本溪市分行',
        value: '102225000017|2105|辽宁省|本溪市'
      },
      {
        label: '中国工商银行本溪市分行北地支行',
        value: '102225000025|2105|辽宁省|本溪市'
      },
      {
        label: '中国工商银行股份有限公司本溪东胜支行',
        value: '102225000033|2105|辽宁省|本溪市'
      },
      {
        label: '中国工商银行股份有限公司齐齐哈尔铁西分理处',
        value: '102264005495|2302|黑龙江省|齐齐哈尔市'
      },
      {
        label: '中国工商银行股份有限公司浦江黄宅支行',
        value: '102338407060|3307|浙江省|金华市'
      },
      {
        label: '中国工商银行福州城门支行',
        value: '102391052636|3501|福建省|福州市'
      },
      {
        label: '中国工商银行股份有限公司益阳分行康富分理处',
        value: '102561000338|4309|湖南省|益阳市'
      },
      {
        label: '中国工商银行股份有限公司益阳分行大利分理处',
        value: '102561000571|4309|湖南省|益阳市'
      },
      {
        label: '中国工商银行股份有限公司益阳电厂支行',
        value: '102561000821|4309|湖南省|益阳市'
      },
      {
        label: '中国工商银行股份有限公司益阳桃花仑支行',
        value: '102561002102|4309|湖南省|益阳市'
      },
      {
        label: '中国工商银行股份有限公司益阳赫山支行',
        value: '102561002209|4309|湖南省|益阳市'
      },
      {
        label: '中国工商银行股份有限公司益阳资阳支行',
        value: '102561002305|4309|湖南省|益阳市'
      },
      {
        label: '中国工商银行股份有限公司黄冈罗田桥南支行',
        value: '102533510176|4211|湖北省|黄冈市'
      },
      {
        label: '中国工商银行股份有限公司黄冈英山支行',
        value: '102533610126|4211|湖北省|黄冈市'
      },
      {
        label: '中国工商银行股份有限公司黄冈英山温泉支行',
        value: '102533610134|4211|湖北省|黄冈市'
      },
      {
        label: '中国工商银行浠水县支行营业部',
        value: '102533710131|4211|湖北省|黄冈市'
      },
      {
        label: '中国工商银行蕲春县支行营业部',
        value: '102533810152|4211|湖北省|黄冈市'
      },
      {
        label: '中国工商银行股份有限公司蕲春濒湖支行',
        value: '102533810193|4211|湖北省|黄冈市'
      },
      {
        label: '中国工商银行股份有限公司江华支行',
        value: '102565700086|4311|湖南省|永州市'
      },
      {
        label: '中国工商银行股份有限公司蓝山支行',
        value: '102565800095|4311|湖南省|永州市'
      },
      {
        label: '中国工商银行股份有限公司新田支行',
        value: '102565900107|4311|湖南省|永州市'
      },
      {
        label: '中国工商银行股份有限公司双牌支行',
        value: '102566100400|4311|湖南省|永州市'
      },
      {
        label: '中国工商银行股份有限公司祁阳支行',
        value: '102566200033|4311|湖南省|永州市'
      },
      {
        label: '中国工商银行股份有限公司祁阳黎家坪支行',
        value: '102566200695|4311|湖南省|永州市'
      },
      {
        label: '中国工商银行股份有限公司祁阳支行浯溪分理处',
        value: '102566200718|4311|湖南省|永州市'
      },
      {
        label: '中国工商银行股份有限公司祁阳支行城关分理处',
        value: '102566200726|4311|湖南省|永州市'
      },
      {
        label: '中国工商银行股份有限公司中方支行',
        value: '102567000017|4312|湖南省|怀化市'
      },
      {
        label: '中国工商银行怀化市分行',
        value: '102567001010|4312|湖南省|怀化市'
      },
      {
        label: '中国工商银行怀化市迎丰支行',
        value: '102567001036|4312|湖南省|怀化市'
      },
      {
        label: '中国工商银行怀化金怀支行',
        value: '102567001044|4312|湖南省|怀化市'
      },
      {
        label: '中国工商银行怀化鹤城支行',
        value: '102567001052|4312|湖南省|怀化市'
      },
      {
        label: '中国工商银行怀化市怀兴支行',
        value: '102567001069|4312|湖南省|怀化市'
      },
      {
        label: '中国工商银行怀化市铁道支行',
        value: '102567001077|4312|湖南省|怀化市'
      },
      {
        label: '中国工商银行怀化城中支行',
        value: '102567001237|4312|湖南省|怀化市'
      },
      {
        label: '中国工商银行怀化城南支行',
        value: '102567001245|4312|湖南省|怀化市'
      },
      {
        label: '中国工商银行怀化庆丰支行',
        value: '102567010012|4312|湖南省|怀化市'
      },
      {
        label: '中国工商银行怀化学院支行',
        value: '102567010029|4312|湖南省|怀化市'
      },
      {
        label: '中国工商银行怀化城东支行',
        value: '102567010037|4312|湖南省|怀化市'
      },
      {
        label: '中国工商银行怀化一0一支行',
        value: '102567010414|4312|湖南省|怀化市'
      },
      {
        label: '中国工商银行怀化锦溪支行',
        value: '102567010447|4312|湖南省|怀化市'
      },
      {
        label: '中国工商银行怀化政通支行',
        value: '102567010463|4312|湖南省|怀化市'
      },
      {
        label: '中国工商银行怀化天星坪支行',
        value: '102567010535|4312|湖南省|怀化市'
      },
      {
        label: '中国工商银行怀化经济开发区支行',
        value: '102567010551|4312|湖南省|怀化市'
      },
      {
        label: '中国工商银行洪江市支行',
        value: '102567201088|4312|湖南省|怀化市'
      },
      {
        label: '中国工商银行洪江市安江支行',
        value: '102567201096|4312|湖南省|怀化市'
      },
      {
        label: '中国工商银行怀化市洪江区支行',
        value: '102567210824|4312|湖南省|怀化市'
      },
      {
        label: '中国工商银行沅陵县支行',
        value: '102567401102|4312|湖南省|怀化市'
      },
      {
        label: '中国工商银行沅陵辰州矿业支行',
        value: '102567401209|4312|湖南省|怀化市'
      },
      {
        label: '中国工商银行沅陵支行天宁分理处',
        value: '102567411010|4312|湖南省|怀化市'
      },
      {
        label: '中国工商银行股份有限公司广水应山支行',
        value: '102535400235|4209|湖北省|孝感市'
      },
      {
        label: '中国工商银行股份有限公司广水渡蚁桥支行',
        value: '102535401184|4209|湖北省|孝感市'
      },
      {
        label: '中国工商银行股份有限公司大悟府前支行',
        value: '102535500010|4209|湖北省|孝感市'
      },
      {
        label: '中国工商银行股份有限公司大悟支行',
        value: '102535510074|4209|湖北省|孝感市'
      },
      {
        label: '中国工商银行股份有限公司云梦梦泽支行',
        value: '102535600027|4209|湖北省|孝感市'
      },
      {
        label: '中国工商银行云梦县支行',
        value: '102535610049|4209|湖北省|孝感市'
      },
      {
        label: '中国工商银行股份有限公司汉川晨曦支行',
        value: '102535700011|4209|湖北省|孝感市'
      },
      {
        label: '中国工商银行股份有限公司汉川金马支行',
        value: '102535700020|4209|湖北省|孝感市'
      },
      {
        label: '中国工商银行股份有限公司汉川欢乐支行',
        value: '102535700046|4209|湖北省|孝感市'
      },
      {
        label: '中国工商银行股份有限公司汉川开发区支行',
        value: '102535700079|4209|湖北省|孝感市'
      },
      {
        label: '中国工商银行汉川支行',
        value: '102535710025|4209|湖北省|孝感市'
      },
      {
        label: '中国工商银行咸宁市分行泉山支行',
        value: '102536000016|4212|湖北省|咸宁市'
      },
      {
        label: '中国工商银行咸宁市分行温泉支行',
        value: '102536000032|4212|湖北省|咸宁市'
      },
      {
        label: '中国工商银行咸宁市咸安区支行',
        value: '102536000049|4212|湖北省|咸宁市'
      },
      {
        label: '中国工商银行股份有限公司咸宁南门支行',
        value: '102536000057|4212|湖北省|咸宁市'
      },
      {
        label: '中国工商银行股份有限公司咸宁永安支行',
        value: '102536000065|4212|湖北省|咸宁市'
      },
      {
        label: '中国工商银行股份有限公司吉首武陵路支行',
        value: '102569001162|4331|湖南省|湘西土家族苗族自治州'
      },
      {
        label: '中国工商银行股份有限公司泸溪支行',
        value: '102569201084|4331|湖南省|湘西土家族苗族自治州'
      },
      {
        label: '中国工商银行股份有限公司凤凰支行',
        value: '102569301114|4331|湖南省|湘西土家族苗族自治州'
      },
      {
        label: '中国工商银行股份有限公司花垣支行',
        value: '102569401100|4331|湖南省|湘西土家族苗族自治州'
      },
      {
        label: '中国工商银行股份有限公司保靖支行',
        value: '102569501124|4331|湖南省|湘西土家族苗族自治州'
      },
      {
        label: '中国工商银行股份有限公司永顺支行',
        value: '102569701096|4331|湖南省|湘西土家族苗族自治州'
      },
      {
        label: '中国工商银行股份有限公司龙山支行',
        value: '102569801144|4331|湖南省|湘西土家族苗族自治州'
      },
      {
        label: '中国工商银行股份有限公司中山城北支行',
        value: '102603002699|4420|广东省|中山市'
      },
      {
        label: '中国工商银行兖州市支行',
        value: '102461101032|3708|山东省|济宁市'
      },
      {
        label: '中国工商银行股份有限公司兖州支行龙桥路分理处',
        value: '102461103143|3708|山东省|济宁市'
      },
      {
        label: '中国工商银行股份有限公司兖州兴隆支行',
        value: '102461103160|3708|山东省|济宁市'
      },
      {
        label: '中国工商银行股份有限公司兖州支行杨村煤矿分理处',
        value: '102461103178|3708|山东省|济宁市'
      },
      {
        label: '中国工商银行邹城市支行',
        value: '102461201324|3708|山东省|济宁市'
      },
      {
        label: '中国工商银行邹城市支行铁山分理处',
        value: '102461205018|3708|山东省|济宁市'
      },
      {
        label: '中国工商银行股份有限公司邹城文博苑支行',
        value: '102461205026|3708|山东省|济宁市'
      },
      {
        label: '中国工商银行邹城市支行北宿分理处',
        value: '102461205042|3708|山东省|济宁市'
      },
      {
        label: '中国工商银行股份有限公司邹城南屯支行',
        value: '102461205059|3708|山东省|济宁市'
      },
      {
        label: '中国工商银行股份有限公司温州状元支行',
        value: '102333022817|3303|浙江省|温州市'
      },
      {
        label: '中国工商银行股份有限公司温州天河支行',
        value: '102333022825|3303|浙江省|温州市'
      },
      {
        label: '中国工商银行股份有限公司温州永中支行',
        value: '102333022833|3303|浙江省|温州市'
      },
      {
        label: '中国工商银行股份有限公司温州沙城支行',
        value: '102333022850|3303|浙江省|温州市'
      },
      {
        label: '中国工商银行温州市分行核算中心',
        value: '102333025807|3303|浙江省|温州市'
      },
      {
        label: '中国工商银行股份有限公司温州海城支行',
        value: '102333028032|3303|浙江省|温州市'
      },
      {
        label: '中国工商银行股份有限公司洞头支行',
        value: '102333228802|3303|浙江省|温州市'
      },
      {
        label: '中国工商银行股份有限公司乐清南虹广场支行',
        value: '102333302041|3303|浙江省|温州市'
      },
      {
        label: '中国工商银行股份有限公司乐清支行大兴分理处',
        value: '102333302121|3303|浙江省|温州市'
      },
      {
        label: '中国工商银行股份有限公司乐清清远支行',
        value: '102333302130|3303|浙江省|温州市'
      },
      {
        label: '中国工商银行乐清支行合兴分理处',
        value: '102333302156|3303|浙江省|温州市'
      },
      {
        label: '中国工商银行股份有限公司乐清支行电器城分理处',
        value: '102333302164|3303|浙江省|温州市'
      },
      {
        label: '中国工商银行股份有限公司乐清柳青支行',
        value: '102333305179|3303|浙江省|温州市'
      },
      {
        label: '中国工商银行股份有限公司乐清虹兴支行',
        value: '102333305961|3303|浙江省|温州市'
      },
      {
        label: '中国工商银行乐清市支行',
        value: '102333328201|3303|浙江省|温州市'
      },
      {
        label: '中国工商银行股份有限公司安庆龙啸支行',
        value: '102368000518|3408|安徽省|安庆市'
      },
      {
        label: '中国工商银行安庆分行华中路支行',
        value: '102368000526|3408|安徽省|安庆市'
      },
      {
        label: '中国工商银行湖北省咸宁分行赤壁西湖支行',
        value: '102536200114|4212|湖北省|咸宁市'
      },
      {
        label: '中国工商银行股份有限公司赤壁新城支行',
        value: '102536200235|4212|湖北省|咸宁市'
      },
      {
        label: '中国工商银行股份有限公司赤壁银厦支行',
        value: '102536200251|4212|湖北省|咸宁市'
      },
      {
        label: '中国工商银行咸宁市分行嘉鱼支行',
        value: '102536300077|4212|湖北省|咸宁市'
      },
      {
        label: '中国工商银行股份有限公司嘉鱼山湖支行',
        value: '102536300085|4212|湖北省|咸宁市'
      },
      {
        label: '中国工商银行咸宁市分行通城支行',
        value: '102536400133|4212|湖北省|咸宁市'
      },
      {
        label: '中国工商银行股份有限公司通城银城支行',
        value: '102536400289|4212|湖北省|咸宁市'
      },
      {
        label: '中国工商银行股份有限公司通城隽水支行',
        value: '102536400297|4212|湖北省|咸宁市'
      },
      {
        label: '中国工商银行崇阳县支行',
        value: '102536500142|4212|湖北省|咸宁市'
      },
      {
        label: '中国工商银行股份有限公司崇阳新建路支行',
        value: '102536500159|4212|湖北省|咸宁市'
      },
      {
        label: '中国工商银行股份有限公司崇阳白霓支行',
        value: '102536500298|4212|湖北省|咸宁市'
      },
      {
        label: '中国工商银行股份有限公司崇阳桃溪支行',
        value: '102536553650|4212|湖北省|咸宁市'
      },
      {
        label: '中国工商银行咸宁市分行通山支行',
        value: '102536600166|4212|湖北省|咸宁市'
      },
      {
        label: '中国工商银行股份有限公司通山新城支行',
        value: '102536601752|4212|湖北省|咸宁市'
      },
      {
        label: '中国工商银行阳新县支行',
        value: '102536730007|4212|湖北省|咸宁市'
      },
      {
        label: '中国工商银行股份有限公司阳新兴国支行',
        value: '102536730015|4212|湖北省|咸宁市'
      },
      {
        label: '中国工商银行股份有限公司阳新十字路支行',
        value: '102536730066|4212|湖北省|咸宁市'
      },
      {
        label: '中国工商银行荆州市分行营业部',
        value: '102537010005|4210|湖北省|荆州市'
      },
      {
        label: '中国工商银行股份有限公司荆州沙市支行大庆路分理处',
        value: '102537010013|4210|湖北省|荆州市'
      },
      {
        label: '中国工商银行股份有限公司荆州玉桥支行',
        value: '102537010048|4210|湖北省|荆州市'
      },
      {
        label: '中国工商银行荆州市分行金城分理处',
        value: '102537010056|4210|湖北省|荆州市'
      },
      {
        label: '中国工商银行荆州市分行汇通分理处',
        value: '102537010064|4210|湖北省|荆州市'
      },
      {
        label: '中国工商银行荆州市分行荆沙分理处',
        value: '102537010072|4210|湖北省|荆州市'
      },
      {
        label: '中国工商银行股份有限公司荆州经济技术开发区支行',
        value: '102537010097|4210|湖北省|荆州市'
      },
      {
        label: '中国工商银行荆州市分行中山路支行',
        value: '102537010136|4210|湖北省|荆州市'
      },
      {
        label: '中国工商银行股份有限公司监利新沟支行',
        value: '102537010177|4210|湖北省|荆州市'
      },
      {
        label: '中国工商银行荆州市分行北京路支行',
        value: '102537010185|4210|湖北省|荆州市'
      },
      {
        label: '中国工商银行荆州市分行北京路支行江汉路分理处',
        value: '102537010208|4210|湖北省|荆州市'
      },
      {
        label: '中国工商银行荆州市分行红门路支行',
        value: '102537010232|4210|湖北省|荆州市'
      },
      {
        label: '工商银行荆州沙市支行洪垸分理处',
        value: '102537010257|4210|湖北省|荆州市'
      },
      {
        label: '中国工商银行荆州市分行沙市支行',
        value: '102537010281|4210|湖北省|荆州市'
      },
      {
        label: '中国工商银行股份有限公司荆州沙市支行公园路分理处',
        value: '102537010304|4210|湖北省|荆州市'
      },
      {
        label: '中国工商银行股份有限公司荆州沙市支行恒生分理处',
        value: '102537010312|4210|湖北省|荆州市'
      },
      {
        label: '中国工商银行股份有限公司荆州燎原支行',
        value: '102537010329|4210|湖北省|荆州市'
      },
      {
        label: '中国工商银行荆州市分行会计业务处理中心',
        value: '102537010370|4210|湖北省|荆州市'
      },
      {
        label: '中国工商银行荆州市分行荆州支行',
        value: '102537010396|4210|湖北省|荆州市'
      },
      {
        label: '中国工商银行荆州市分行荆州支行东城分理处',
        value: '102537010415|4210|湖北省|荆州市'
      },
      {
        label: '中国工商银行舟山普陀山支行',
        value: '102342002099|3309|浙江省|舟山市'
      },
      {
        label: '中国工商银行股份有限公司广州永和开发区永顺支行',
        value: '102581005495|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州汇景新城支行',
        value: '102581005534|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州番禺锦绣生态园支行',
        value: '102581005559|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司日照石臼支行',
        value: '102473202017|3713|山东省|临沂市'
      },
      {
        label: '中国工商银行日照市分行业务中心',
        value: '102473202092|3713|山东省|临沂市'
      },
      {
        label: '中国工商银行股份有限公司日照高新支行',
        value: '102473203108|3713|山东省|临沂市'
      },
      {
        label: '中国工商银行日照开发区支行',
        value: '102473204053|3713|山东省|临沂市'
      },
      {
        label: '中国工商银行股份有限公司郯城支行',
        value: '102473300560|3713|山东省|临沂市'
      },
      {
        label: '中国工商银行股份有限公司兰陵支行',
        value: '102473400353|3713|山东省|临沂市'
      },
      {
        label: '中国工商银行股份有限公司莒南支行',
        value: '102473500436|3713|山东省|临沂市'
      },
      {
        label: '中国工商银行日照市莒县支行',
        value: '102473602035|3713|山东省|临沂市'
      },
      {
        label: '中国工商银行股份有限公司沂水支行',
        value: '102473700619|3713|山东省|临沂市'
      },
      {
        label: '中国工商银行股份有限公司平邑支行',
        value: '102474100527|3713|山东省|临沂市'
      },
      {
        label: '中国工商银行股份有限公司费县支行',
        value: '102474200396|3713|山东省|临沂市'
      },
      {
        label: '中国工商银行安吉县支行',
        value: '102336329006|3305|浙江省|湖州市'
      },
      {
        label: '中国工商银行安吉孝丰支行',
        value: '102336329014|3305|浙江省|湖州市'
      },
      {
        label: '中国工商银行安吉递铺支行',
        value: '102336329022|3305|浙江省|湖州市'
      },
      {
        label: '中国工商银行安吉城中支行',
        value: '102336329039|3305|浙江省|湖州市'
      },
      {
        label: '中国工商银行安吉昌硕支行',
        value: '102336329047|3305|浙江省|湖州市'
      },
      {
        label: '中国工商银行绍兴市分行',
        value: '102337001000|3306|浙江省|绍兴市'
      },
      {
        label: '中国工商银行绍兴市分行营业部',
        value: '102337001202|3306|浙江省|绍兴市'
      },
      {
        label: '中国工商银行股份有限公司绍兴迪荡支行',
        value: '102337001219|3306|浙江省|绍兴市'
      },
      {
        label: '中国工商银行绍兴市城北支行',
        value: '102337001405|3306|浙江省|绍兴市'
      },
      {
        label: '中国工商银行绍兴胜利路支行',
        value: '102337001421|3306|浙江省|绍兴市'
      },
      {
        label: '中国工商银行绍兴镜湖支行',
        value: '102337001472|3306|浙江省|绍兴市'
      },
      {
        label: '中国工商银行绍兴市城北支行快阁苑分理处',
        value: '102337001497|3306|浙江省|绍兴市'
      },
      {
        label: '中国工商银行股份有限公司绍兴江家溇支行',
        value: '102337001501|3306|浙江省|绍兴市'
      },
      {
        label: '中国工商银行绍兴东浦支行',
        value: '102337001528|3306|浙江省|绍兴市'
      },
      {
        label: '中国工商银行绍兴市越城支行',
        value: '102337001608|3306|浙江省|绍兴市'
      },
      {
        label: '中国工商银行绍兴剡溪路支行',
        value: '102337001616|3306|浙江省|绍兴市'
      },
      {
        label: '中国工商银行股份有限公司绍兴平水支行',
        value: '102337001624|3306|浙江省|绍兴市'
      },
      {
        label: '中国工商银行绍兴市越城支行牡丹分理处',
        value: '102337001632|3306|浙江省|绍兴市'
      },
      {
        label: '中国工商银行股份有限公司绍兴北海支行',
        value: '102337001649|3306|浙江省|绍兴市'
      },
      {
        label: '中国工商银行绍兴偏门支行',
        value: '102337001681|3306|浙江省|绍兴市'
      },
      {
        label: '中国工商银行绍兴市越城支行胜利西村分理处',
        value: '102337001690|3306|浙江省|绍兴市'
      },
      {
        label: '中国工商银行绍兴鲁迅支行',
        value: '102337001704|3306|浙江省|绍兴市'
      },
      {
        label: '中国工商银行股份有限公司绍兴城南支行',
        value: '102337001729|3306|浙江省|绍兴市'
      },
      {
        label: '中国工商银行绍兴市城东支行中兴大桥分理处',
        value: '102337001737|3306|浙江省|绍兴市'
      },
      {
        label: '中国工商银行股份有限公司绍兴斗门支行',
        value: '102337001745|3306|浙江省|绍兴市'
      },
      {
        label: '中国工商银行绍兴市越城支行洞桥分理处',
        value: '102337001753|3306|浙江省|绍兴市'
      },
      {
        label: '中国工商银行绍兴市城东支行',
        value: '102337001807|3306|浙江省|绍兴市'
      },
      {
        label: '中国工商银行绍兴城东新区支行',
        value: '102337001815|3306|浙江省|绍兴市'
      },
      {
        label: '中国工商银行潜江支行江汉路分理处',
        value: '102537511357|4210|湖北省|荆州市'
      },
      {
        label: '中国工商银行荆州市分行松滋市支行',
        value: '102537710545|4210|湖北省|荆州市'
      },
      {
        label: '中国工商银行荆州市分行松滋市支行刘家场分理处',
        value: '102537710553|4210|湖北省|荆州市'
      },
      {
        label: '中国工商银行湖北省荆州松滋支行西斋分理处',
        value: '102537710561|4210|湖北省|荆州市'
      },
      {
        label: '中国工商银行湖北省荆州松滋支行金松开发区分理处',
        value: '102537710588|4210|湖北省|荆州市'
      },
      {
        label: '中国工商银行湖北省荆州松滋车站支行',
        value: '102537710596|4210|湖北省|荆州市'
      },
      {
        label: '中国工商银行湖北省荆州松滋支行城关分理处',
        value: '102537710615|4210|湖北省|荆州市'
      },
      {
        label: '中国工商银行股份有限公司公安新区支行',
        value: '102537800506|4210|湖北省|荆州市'
      },
      {
        label: '中国工商银行荆州市分行公安县支行',
        value: '102537810896|4210|湖北省|荆州市'
      },
      {
        label: '中国工商银行股份有限公司公安斗湖堤支行',
        value: '102537810907|4210|湖北省|荆州市'
      },
      {
        label: '中国工商银行股份有限公司公安华通支行',
        value: '102537810923|4210|湖北省|荆州市'
      },
      {
        label: '中国工商银行荆州市分行公安县支行南平分理处',
        value: '102537810931|4210|湖北省|荆州市'
      },
      {
        label: '中国工商银行股份有限公司监利朱河支行',
        value: '102537900470|4210|湖北省|荆州市'
      },
      {
        label: '中国工商银行荆州市分行监利县支行',
        value: '102537910684|4210|湖北省|荆州市'
      },
      {
        label: '中国工商银行钟祥支行',
        value: '102538100301|4210|湖北省|荆州市'
      },
      {
        label: '中国工商银行股份有限公司钟祥郢都支行',
        value: '102538100310|4210|湖北省|荆州市'
      },
      {
        label: '中国工商银行股份有限公司钟祥东街支行',
        value: '102538100328|4210|湖北省|荆州市'
      },
      {
        label: '中国工商银行股份有限公司钟祥开发区支行',
        value: '102538100336|4210|湖北省|荆州市'
      },
      {
        label: '中国工商银行股份有限公司钟祥石城支行',
        value: '102538100344|4210|湖北省|荆州市'
      },
      {
        label: '中国工商银行钟祥石牌支行',
        value: '102538100377|4210|湖北省|荆州市'
      },
      {
        label: '中国工商银行股份有限公司钟祥胡集支行',
        value: '102538100385|4210|湖北省|荆州市'
      },
      {
        label: '中国工商银行股份有限公司珠海口岸支行',
        value: '102585002235|4404|广东省|珠海市'
      },
      {
        label: '中国工商银行股份有限公司驻马店文明路支行',
        value: '102511012804|4117|河南省|驻马店市'
      },
      {
        label: '中国工商银行股份有限公司平阴支行',
        value: '102451000998|3701|山东省|济南市'
      },
      {
        label: '中国工商银行股份有限公司郑州注协大厦支行',
        value: '102491032205|4101|河南省|郑州市'
      },
      {
        label: '中国工商银行淄博博山区支行石碳坞分理处',
        value: '102453002132|3703|山东省|淄博市'
      },
      {
        label: '中国工商银行股份有限公司淄博博山白塔支行',
        value: '102453002204|3703|山东省|淄博市'
      },
      {
        label: '中国工商银行淄博市淄川区淄城东路分理处',
        value: '102453003029|3703|山东省|淄博市'
      },
      {
        label: '中国工商银行淄博市淄川区支行火车站分理处',
        value: '102453003037|3703|山东省|淄博市'
      },
      {
        label: '中国工商银行股份有限公司淄博淄川双杨支行',
        value: '102453003053|3703|山东省|淄博市'
      },
      {
        label: '中国工商银行股份有限公司玉环清港支行',
        value: '102345828186|3310|浙江省|台州市'
      },
      {
        label: '中国工商银行股份有限公司玉环玉城支行',
        value: '102345828194|3310|浙江省|台州市'
      },
      {
        label: '中国工商银行合肥市四牌楼支行',
        value: '102361000015|3401|安徽省|合肥市'
      },
      {
        label: '中国工商银行合肥市长江东路支行',
        value: '102361000023|3401|安徽省|合肥市'
      },
      {
        label: '中国工商银行合肥市金寨路支行',
        value: '102361000031|3401|安徽省|合肥市'
      },
      {
        label: '中国工商银行合肥市淮河路支行',
        value: '102361000040|3401|安徽省|合肥市'
      },
      {
        label: '中国工商银行合肥市望江路支行',
        value: '102361000058|3401|安徽省|合肥市'
      },
      {
        label: '中国工商银行合肥市双岗支行',
        value: '102361000066|3401|安徽省|合肥市'
      },
      {
        label: '中国工商银行合肥市和平路支行',
        value: '102361000074|3401|安徽省|合肥市'
      },
      {
        label: '中国工商银行合肥市庐东支行',
        value: '102361000082|3401|安徽省|合肥市'
      },
      {
        label: '中国工商银行合肥市五里墩分理处',
        value: '102361000099|3401|安徽省|合肥市'
      },
      {
        label: '中国工商银行合肥市高新技术产业开发区支行',
        value: '102361000103|3401|安徽省|合肥市'
      },
      {
        label: '中国工商银行泉州津淮街支行',
        value: '102397022827|3505|福建省|泉州市'
      },
      {
        label: '中国工商银行泉州东街支行',
        value: '102397022851|3505|福建省|泉州市'
      },
      {
        label: '中国工商银行泉州东海支行',
        value: '102397022860|3505|福建省|泉州市'
      },
      {
        label: '中国工商银行股份有限公司泉州湖心街支行',
        value: '102397022878|3505|福建省|泉州市'
      },
      {
        label: '中国工商银行股份有限公司泉州义全支行',
        value: '102397022886|3505|福建省|泉州市'
      },
      {
        label: '中国工商银行股份有限公司泉州友谊支行',
        value: '102397022917|3505|福建省|泉州市'
      },
      {
        label: '中国工商银行股份有限公司泉州阳光支行',
        value: '102397024298|3505|福建省|泉州市'
      },
      {
        label: '中国工商银行股份有限公司泉州东海湾支行',
        value: '102397024327|3505|福建省|泉州市'
      },
      {
        label: '中国工商银行股份有限公司泉州双阳支行',
        value: '102397024378|3505|福建省|泉州市'
      },
      {
        label: '中国工商银行股份有限公司泉州安吉路支行',
        value: '102397025143|3505|福建省|泉州市'
      },
      {
        label: '中国工商银行股份有限公司泉州刺桐支行',
        value: '102397027028|3505|福建省|泉州市'
      },
      {
        label: '中国工商银行股份有限公司泉州华城支行',
        value: '102397027526|3505|福建省|泉州市'
      },
      {
        label: '中国工商银行惠安县支行',
        value: '102397121113|3505|福建省|泉州市'
      },
      {
        label: '工商银行泉州市泉港支行',
        value: '102397121121|3505|福建省|泉州市'
      },
      {
        label: '中国工商银行惠安崇武支行',
        value: '102397121164|3505|福建省|泉州市'
      },
      {
        label: '中国工商银行股份有限公司泉州惠南支行',
        value: '102397121189|3505|福建省|泉州市'
      },
      {
        label: '中国工商银行股份有限公司泉州台商投资区支行',
        value: '102397125180|3505|福建省|泉州市'
      },
      {
        label: '中国工商银行股份有限公司惠安世纪大道支行',
        value: '102397125421|3505|福建省|泉州市'
      },
      {
        label: '中国工商银行股份有限公司惠安南岭桥支行',
        value: '102397125448|3505|福建省|泉州市'
      },
      {
        label: '中国工商银行股份有限公司惠安支行建设路储蓄所',
        value: '102397125456|3505|福建省|泉州市'
      },
      {
        label: '中国工商银行股份有限公司泉州山腰支行',
        value: '102397125472|3505|福建省|泉州市'
      },
      {
        label: '中国工商银行股份有限公司惠安惠东支行',
        value: '102397125489|3505|福建省|泉州市'
      },
      {
        label: '中国工商银行股份有限公司惠安城南支行',
        value: '102397125501|3505|福建省|泉州市'
      },
      {
        label: '中国工商银行股份有限公司晋江分行',
        value: '102397221216|3505|福建省|泉州市'
      },
      {
        label: '中国工商银行股份有限公司晋江安海支行',
        value: '102397221224|3505|福建省|泉州市'
      },
      {
        label: '中国工商银行股份有限公司晋江磁灶支行',
        value: '102397221232|3505|福建省|泉州市'
      },
      {
        label: '中国工商银行晋江龙湖支行',
        value: '102397221249|3505|福建省|泉州市'
      },
      {
        label: '中国工商银行股份有限公司福建泉州晋江金井支行',
        value: '102397221257|3505|福建省|泉州市'
      },
      {
        label: '中国工商银行晋江陈埭支行',
        value: '102397221273|3505|福建省|泉州市'
      },
      {
        label: '中国工商银行股份有限公司晋江东石支行',
        value: '102397221290|3505|福建省|泉州市'
      },
      {
        label: '中国工商银行股份有限公司晋江英林支行',
        value: '102397221304|3505|福建省|泉州市'
      },
      {
        label: '中国工商银行股份有限公司晋江内坑支行',
        value: '102397221312|3505|福建省|泉州市'
      },
      {
        label: '中国工商银行股份有限公司晋江陈埭鹏青路支行',
        value: '102397222090|3505|福建省|泉州市'
      },
      {
        label: '中国工商银行股份有限公司晋江城关支行',
        value: '102397222258|3505|福建省|泉州市'
      },
      {
        label: '中国工商银行股份有限公司晋江福埔支行',
        value: '102397222299|3505|福建省|泉州市'
      },
      {
        label: '中国工商银行晋江新华街支行',
        value: '102397222320|3505|福建省|泉州市'
      },
      {
        label: '中国工商银行蚌埠涂山路支行',
        value: '102363000762|3403|安徽省|蚌埠市'
      },
      {
        label: '中国工商银行股份有限公司蚌埠汇通支行',
        value: '102363000779|3403|安徽省|蚌埠市'
      },
      {
        label: '中国工商银行股份有限公司福建自贸试验区平潭片区分行',
        value: '102391051266|3501|福建省|福州市'
      },
      {
        label: '中国工商银行股份有限公司福州分行',
        value: '102391051282|3501|福建省|福州市'
      },
      {
        label: '中国工商银行股份有限公司福州名店街支行',
        value: '102391051299|3501|福建省|福州市'
      },
      {
        label: '中国工商银行福州五四北路支行',
        value: '102391051303|3501|福建省|福州市'
      },
      {
        label: '中国工商银行股份有限公司福州城东支行',
        value: '102391051320|3501|福建省|福州市'
      },
      {
        label: '中国工商银行股份有限公司丰城坪湖支行',
        value: '102431210063|3609|江西省|宜春市'
      },
      {
        label: '中国工商银行股份有限公司丰城工业园支行',
        value: '102431210098|3609|江西省|宜春市'
      },
      {
        label: '中国工商银行股份有限公司乐安支行',
        value: '102437611134|3610|江西省|抚州市'
      },
      {
        label: '中国工商银行股份有限公司宜黄支行',
        value: '102437711149|3610|江西省|抚州市'
      },
      {
        label: '中国工商银行泉州泉秀支行',
        value: '102397021043|3505|福建省|泉州市'
      },
      {
        label: '中国工商银行泉州浮桥支行',
        value: '102397021051|3505|福建省|泉州市'
      },
      {
        label: '中国工商银行泉州市丰泽支行',
        value: '102397021060|3505|福建省|泉州市'
      },
      {
        label: '中国工商银行泉州市洛江支行',
        value: '102397021078|3505|福建省|泉州市'
      },
      {
        label: '中国工商银行泉州清蒙支行',
        value: '102397021094|3505|福建省|泉州市'
      },
      {
        label: '中国工商银行泉州指挥巷支行',
        value: '102397022021|3505|福建省|泉州市'
      },
      {
        label: '中国工商银行股份有限公司晋江灵源支行',
        value: '102397222354|3505|福建省|泉州市'
      },
      {
        label: '中国工商银行股份有限公司晋江安海恒安路支行',
        value: '102397222362|3505|福建省|泉州市'
      },
      {
        label: '中国工商银行股份有限公司晋江安海鸿信支行',
        value: '102397222400|3505|福建省|泉州市'
      },
      {
        label: '中国工商银行晋江罗祥支行',
        value: '102397222442|3505|福建省|泉州市'
      },
      {
        label: '中国工商银行股份有限公司晋江银利支行',
        value: '102397222686|3505|福建省|泉州市'
      },
      {
        label: '中国工商银行股份有限公司晋江深沪支行',
        value: '102397222694|3505|福建省|泉州市'
      },
      {
        label: '中国工商银行股份有限公司晋江池店支行',
        value: '102397222725|3505|福建省|泉州市'
      },
      {
        label: '中国工商银行股份有限公司晋江安海成功支行',
        value: '102397224313|3505|福建省|泉州市'
      },
      {
        label: '中国工商银行股份有限公司晋江万达支行',
        value: '102397224330|3505|福建省|泉州市'
      },
      {
        label: '中国工商银行股份有限公司晋江罗山支行',
        value: '102397224348|3505|福建省|泉州市'
      },
      {
        label: '中国工商银行南安市支行',
        value: '102397321401|3505|福建省|泉州市'
      },
      {
        label: '中国工商银行股份有限公司淄博周村支行祠堂街分理处',
        value: '102453004162|3703|山东省|淄博市'
      },
      {
        label: '中国工商银行股份有限公司淄博临淄胜利支行',
        value: '102453005018|3703|山东省|淄博市'
      },
      {
        label: '中国工商银行股份有限公司淄博临淄辛店支行',
        value: '102453005034|3703|山东省|淄博市'
      },
      {
        label: '中国工商银行股份有限公司淄博临淄化建支行',
        value: '102453005059|3703|山东省|淄博市'
      },
      {
        label: '中国工商银行股份有限公司淄博临淄稷下支行',
        value: '102453005067|3703|山东省|淄博市'
      },
      {
        label: '中国工商银行股份有限公司淄博临淄车站支行',
        value: '102453005075|3703|山东省|淄博市'
      },
      {
        label: '中国工商银行股份有限公司淄博临淄遄台路支行',
        value: '102453005106|3703|山东省|淄博市'
      },
      {
        label: '中国工商银行股份有限公司淄博临淄支行闻韶分理处',
        value: '102453005155|3703|山东省|淄博市'
      },
      {
        label: '中国工商银行股份有限公司淄博临淄化工城支行',
        value: '102453005198|3703|山东省|淄博市'
      },
      {
        label: '中国工商银行股份有限公司淄博南定工业路支行',
        value: '102453005510|3703|山东省|淄博市'
      },
      {
        label: '中国工商银行股份有限公司淄博南定支行电厂分理处',
        value: '102453005528|3703|山东省|淄博市'
      },
      {
        label: '中国工商银行股份有限公司淄博南定支行四八一分理处',
        value: '102453005544|3703|山东省|淄博市'
      },
      {
        label: '中国工商银行股份有限公司淄博高新支行湖田分理处',
        value: '102453006537|3703|山东省|淄博市'
      },
      {
        label: '中国工商银行股份有限公司淄博高新支行张桓路南分理处',
        value: '102453006545|3703|山东省|淄博市'
      },
      {
        label: '中国工商银行股份有限公司淄博高新柳泉路支行',
        value: '102453006553|3703|山东省|淄博市'
      },
      {
        label: '中国工商银行股份有限公司淄博高新铁山支行',
        value: '102453006588|3703|山东省|淄博市'
      },
      {
        label: '中国工商银行股份有限公司淄博高新支行柳泉路北分理处',
        value: '102453006596|3703|山东省|淄博市'
      },
      {
        label: '中国工商银行淄博市分行核算中心',
        value: '102453016032|3703|山东省|淄博市'
      },
      {
        label: '中国工商银行桓台县支行',
        value: '102453100710|3703|山东省|淄博市'
      },
      {
        label: '中国工商银行股份有限公司桓台马桥支行',
        value: '102453106063|3703|山东省|淄博市'
      },
      {
        label: '中国工商银行股份有限公司桓台支行惠仟佳分理处',
        value: '102453106071|3703|山东省|淄博市'
      },
      {
        label: '中国工商银行股份有限公司高青支行',
        value: '102453500913|3703|山东省|淄博市'
      },
      {
        label: '中国工商银行股份有限公司高青芦湖支行',
        value: '102453507513|3703|山东省|淄博市'
      },
      {
        label: '中国工商银行沂源县支行',
        value: '102453600818|3703|山东省|淄博市'
      },
      {
        label: '中国工商银行股份有限公司沂源支行振兴路分理处',
        value: '102453607012|3703|山东省|淄博市'
      },
      {
        label: '中国工商银行股份有限公司枣庄分行',
        value: '102454000016|3704|山东省|枣庄市'
      },
      {
        label: '中国工商银行股份有限公司枣庄山亭支行',
        value: '102454000057|3704|山东省|枣庄市'
      },
      {
        label: '中国工商银行股份有限公司枣庄解放北路支行',
        value: '102454000065|3704|山东省|枣庄市'
      },
      {
        label: '中国工商银行股份有限公司枣庄各塔埠支行',
        value: '102454000073|3704|山东省|枣庄市'
      },
      {
        label: '中国工商银行股份有限公司枣庄新城支行',
        value: '102454000081|3704|山东省|枣庄市'
      },
      {
        label: '中国工商银行股份有限公司枣庄市中支行',
        value: '102454000104|3704|山东省|枣庄市'
      },
      {
        label: '中国工商银行股份有限公司枣庄市中支行花园分理处',
        value: '102454000112|3704|山东省|枣庄市'
      },
      {
        label: '中国工商银行股份有限公司枣庄文化路支行',
        value: '102454000129|3704|山东省|枣庄市'
      },
      {
        label: '中国工商银行沈阳府东支行',
        value: '102221004537|2101|辽宁省|沈阳市'
      },
      {
        label: '中国工商银行股份有限公司靖江城中支行',
        value: '102312314017|3210|江苏省|扬州市'
      },
      {
        label: '中国工商银行股份有限公司靖江百富绅支行',
        value: '102312314105|3210|江苏省|扬州市'
      },
      {
        label: '中国工商银行股份有限公司泰兴鼓楼支行',
        value: '102312487015|3212|江苏省|泰州市'
      },
      {
        label: '中国工商银行股份有限公司泰兴新区支行',
        value: '102312487179|3212|江苏省|泰州市'
      },
      {
        label: '中国工商银行股份有限公司泰兴支行',
        value: '102312492606|3212|江苏省|泰州市'
      },
      {
        label: '中国工商银行股份有限公司泰兴黄桥支行',
        value: '102312492614|3212|江苏省|泰州市'
      },
      {
        label: '中国工商银行股份有限公司池州贵池支行',
        value: '102379000023|3417|安徽省|池州市'
      },
      {
        label: '中国工商银行股份有限公司池州城建支行',
        value: '102379000031|3417|安徽省|池州市'
      },
      {
        label: '中国工商银行股份有限公司九华山支行',
        value: '102379000040|3417|安徽省|池州市'
      },
      {
        label: '中国工商银行舟山城南支行',
        value: '102342002103|3309|浙江省|舟山市'
      },
      {
        label: '中国工商银行舟山市分行普陀区支行',
        value: '102342002111|3309|浙江省|舟山市'
      },
      {
        label: '中国工商银行舟山沈家门支行',
        value: '102342002120|3309|浙江省|舟山市'
      },
      {
        label: '中国工商银行股份有限公司舟山浦西支行',
        value: '102342002138|3309|浙江省|舟山市'
      },
      {
        label: '中国工商银行股份有限公司舟山东港支行',
        value: '102342002146|3309|浙江省|舟山市'
      },
      {
        label: '中国工商银行舟山市普陀区支行水产城分理处',
        value: '102342002154|3309|浙江省|舟山市'
      },
      {
        label: '中国工商银行股份有限公司舟山分行墩头分理处',
        value: '102342002162|3309|浙江省|舟山市'
      },
      {
        label: '中国工商银行股份有限公司厦门灌口支行',
        value: '102393000124|3502|福建省|厦门市'
      },
      {
        label: '中国工商银行股份有限公司厦门大社支行',
        value: '102393000157|3502|福建省|厦门市'
      },
      {
        label: '中国工商银行厦门分行营业部',
        value: '102393000165|3502|福建省|厦门市'
      },
      {
        label: '中国工商银行厦门杏林支行',
        value: '102393000173|3502|福建省|厦门市'
      },
      {
        label: '中国工商银行股份有限公司厦门杏林湾支行',
        value: '102393000181|3502|福建省|厦门市'
      },
      {
        label: '中国工商银行股份有限公司厦门杏西支行',
        value: '102393000190|3502|福建省|厦门市'
      },
      {
        label: '中国工商银行厦门思明支行',
        value: '102393000212|3502|福建省|厦门市'
      },
      {
        label: '中国工商银行厦门厦大支行',
        value: '102393000229|3502|福建省|厦门市'
      },
      {
        label: '中国工商银行股份有限公司厦门镇海支行',
        value: '102393000237|3502|福建省|厦门市'
      },
      {
        label: '中国工商银行股份有限公司厦门珍珠湾支行',
        value: '102393000245|3502|福建省|厦门市'
      },
      {
        label: '中国工商银行股份有限公司合肥银屏花园支行',
        value: '102361002416|3401|安徽省|合肥市'
      },
      {
        label: '中国工商银行股份有限公司合肥大兴集支行',
        value: '102361002424|3401|安徽省|合肥市'
      },
      {
        label: '中国工商银行股份有限公司合肥临泉路支行',
        value: '102361002432|3401|安徽省|合肥市'
      },
      {
        label: '中国工商银行股份有限公司合肥东七支行',
        value: '102361002465|3401|安徽省|合肥市'
      },
      {
        label: '中国工商银行南安洪濑支行',
        value: '102397321410|3505|福建省|泉州市'
      },
      {
        label: '中国工商银行股份有限公司南安官桥支行',
        value: '102397321428|3505|福建省|泉州市'
      },
      {
        label: '中国工商银行股份有限公司南安水头新街支行',
        value: '102397321436|3505|福建省|泉州市'
      },
      {
        label: '中国工商银行南安仑苍支行',
        value: '102397321444|3505|福建省|泉州市'
      },
      {
        label: '中国工商银行股份有限公司南安石井支行',
        value: '102397321452|3505|福建省|泉州市'
      },
      {
        label: '中国工商银行南安梅山支行',
        value: '102397321469|3505|福建省|泉州市'
      },
      {
        label: '中国工商银行股份有限公司南安诗山支行',
        value: '102397321477|3505|福建省|泉州市'
      },
      {
        label: '中国工商银行南安新华支行',
        value: '102397321485|3505|福建省|泉州市'
      },
      {
        label: '中国工商银行南安水头支行',
        value: '102397321508|3505|福建省|泉州市'
      },
      {
        label: '中国工商银行股份有限公司南安官桥太同支行',
        value: '102397324300|3505|福建省|泉州市'
      },
      {
        label: '中国工商银行南安支行洪濑同安街分理处',
        value: '102397326155|3505|福建省|泉州市'
      },
      {
        label: '中国工商银行南安支行柳城分理处',
        value: '102397326163|3505|福建省|泉州市'
      },
      {
        label: '中国工商银行股份有限公司马鞍山采石支行',
        value: '102365002052|3405|安徽省|马鞍山市'
      },
      {
        label: '中国工商银行股份有限公司马鞍山西湖花园支行',
        value: '102365002069|3405|安徽省|马鞍山市'
      },
      {
        label: '中国工商银行马鞍山花山支行',
        value: '102365002077|3405|安徽省|马鞍山市'
      },
      {
        label: '中国工商银行股份有限公司马鞍山团结广场支行',
        value: '102365002085|3405|安徽省|马鞍山市'
      },
      {
        label: '中国工商银行股份有限公司马鞍山康乐路支行',
        value: '102365002116|3405|安徽省|马鞍山市'
      },
      {
        label: '中国工商银行股份有限公司马鞍山花园路支行',
        value: '102365002124|3405|安徽省|马鞍山市'
      },
      {
        label: '中国工商银行龙岩溪南支行',
        value: '102405041043|3508|福建省|龙岩市'
      },
      {
        label: '中国工商银行龙岩莲花支行',
        value: '102405041051|3508|福建省|龙岩市'
      },
      {
        label: '中国工商银行股份有限公司龙岩莲东支行',
        value: '102405041060|3508|福建省|龙岩市'
      },
      {
        label: '中国工商银行龙岩分行新罗支行',
        value: '102405042005|3508|福建省|龙岩市'
      },
      {
        label: '中国工商银行龙岩登高路支行',
        value: '102405042030|3508|福建省|龙岩市'
      },
      {
        label: '中国工商银行股份有限公司龙岩交易城支行',
        value: '102405042056|3508|福建省|龙岩市'
      },
      {
        label: '中国工商银行股份有限公司龙岩龙雁支行',
        value: '102405042064|3508|福建省|龙岩市'
      },
      {
        label: '中国工商银行股份有限公司龙岩西陂支行',
        value: '102405042072|3508|福建省|龙岩市'
      },
      {
        label: '中国工商银行龙岩分行龙津支行',
        value: '102405043008|3508|福建省|龙岩市'
      },
      {
        label: '中国工商银行龙岩北门支行',
        value: '102405043016|3508|福建省|龙岩市'
      },
      {
        label: '中国工商银行龙岩西城支行',
        value: '102405043024|3508|福建省|龙岩市'
      },
      {
        label: '中国工商银行龙岩龙腾支行',
        value: '102405043049|3508|福建省|龙岩市'
      },
      {
        label: '中国工商银行龙岩凤凰隔支行',
        value: '102405043090|3508|福建省|龙岩市'
      },
      {
        label: '中国工商银行股份有限公司龙岩龙门支行',
        value: '102405043112|3508|福建省|龙岩市'
      },
      {
        label: '中国工商银行龙岩曹溪支行',
        value: '102405043137|3508|福建省|龙岩市'
      },
      {
        label: '中国工商银行长汀县支行',
        value: '102405244008|3508|福建省|龙岩市'
      },
      {
        label: '中国工商银行长汀新丰支行',
        value: '102405244016|3508|福建省|龙岩市'
      },
      {
        label: '中国工商银行永定县支行',
        value: '102405345007|3508|福建省|龙岩市'
      },
      {
        label: '中国工商银行永定坎市支行',
        value: '102405345015|3508|福建省|龙岩市'
      },
      {
        label: '中国工商银行股份有限公司龙岩古蛟支行',
        value: '102405403038|3508|福建省|龙岩市'
      },
      {
        label: '中国工商银行上杭县支行',
        value: '102405446003|3508|福建省|龙岩市'
      },
      {
        label: '中国工商银行股份有限公司上杭杭城支行',
        value: '102405446038|3508|福建省|龙岩市'
      },
      {
        label: '中国工商银行武平支行',
        value: '102405546012|3508|福建省|龙岩市'
      },
      {
        label: '中国工商银行漳平市支行',
        value: '102405647003|3508|福建省|龙岩市'
      },
      {
        label: '中国工商银行连城县支行',
        value: '102405748007|3508|福建省|龙岩市'
      },
      {
        label: '中国工商银行江西省分行清算中心（不对外）',
        value: '102421000018|3601|江西省|南昌市'
      },
      {
        label: '中国工商银行股份有限公司江西省分行营业部业务处理中心',
        value: '102421000026|3601|江西省|南昌市'
      },
      {
        label: '中国工商银行股份有限公司驻马店开发区支行',
        value: '102511032509|4117|河南省|驻马店市'
      },
      {
        label: '中国工商银行驻马店分行确山县支行',
        value: '102511202514|4117|河南省|驻马店市'
      },
      {
        label: '中国工商银行股份有限公司泌阳泌水支行',
        value: '102511301637|4117|河南省|驻马店市'
      },
      {
        label: '中国工商银行股份有限公司泌阳支行',
        value: '102511302847|4117|河南省|驻马店市'
      },
      {
        label: '中国工商银行驻马店分行遂平县支行',
        value: '102511402938|4117|河南省|驻马店市'
      },
      {
        label: '中国工商银行股份有限公司遂平灈阳支行',
        value: '102511412667|4117|河南省|驻马店市'
      },
      {
        label: '中国工商银行股份有限公司绍兴县支行官塘分理处',
        value: '102337102080|3306|浙江省|绍兴市'
      },
      {
        label: '中国工商银行股份有限公司绍兴华舍支行',
        value: '102337102098|3306|浙江省|绍兴市'
      },
      {
        label: '中国工商银行股份有限公司绍兴万国支行',
        value: '102337102119|3306|浙江省|绍兴市'
      },
      {
        label: '中国工商银行股份有限公司绍兴轻纺支行',
        value: '102337102151|3306|浙江省|绍兴市'
      },
      {
        label: '中国工商银行股份有限公司绍兴袍江支行孙端分理处',
        value: '102337102178|3306|浙江省|绍兴市'
      },
      {
        label: '中国工商银行股份有限公司绍兴镜海支行',
        value: '102337103210|3306|浙江省|绍兴市'
      },
      {
        label: '中国工商银行股份有限公司寿县新桥国际产业园支行',
        value: '102376300037|3404|安徽省|淮南市'
      },
      {
        label: '中国工商银行股份有限公司霍邱支行',
        value: '102376400013|3415|安徽省|六安市'
      },
      {
        label: '中国工商银行股份有限公司霍邱城内支行',
        value: '102376400021|3415|安徽省|六安市'
      },
      {
        label: '中国工商银行股份有限公司舒城支行',
        value: '102376500014|3415|安徽省|六安市'
      },
      {
        label: '中国工商银行股份有限公司舒城大桥支行',
        value: '102376500022|3415|安徽省|六安市'
      },
      {
        label: '中国工商银行股份有限公司金寨支行',
        value: '102376600015|3415|安徽省|六安市'
      },
      {
        label: '中国工商银行股份有限公司金寨江店支行',
        value: '102376600040|3415|安徽省|六安市'
      },
      {
        label: '中国工商银行股份有限公司霍山支行',
        value: '102376700016|3415|安徽省|六安市'
      },
      {
        label: '中国工商银行股份有限公司霍山城关支行',
        value: '102376700032|3415|安徽省|六安市'
      },
      {
        label: '中国工商银行股份有限公司宣城鳌峰支行',
        value: '102377100061|3418|安徽省|宣城市'
      },
      {
        label: '中国工商银行宣城分行业务处理中心',
        value: '102377101103|3418|安徽省|宣城市'
      },
      {
        label: '中国工商银行宣城分行敬亭山支行',
        value: '102377108603|3418|安徽省|宣城市'
      },
      {
        label: '中国工商银行股份有限公司宣城龙首支行',
        value: '102377108611|3418|安徽省|宣城市'
      },
      {
        label: '中国工商银行宣城市宣州支行',
        value: '102377108679|3418|安徽省|宣城市'
      },
      {
        label: '中国工商银行股份有限公司宣城梅溪支行',
        value: '102377108687|3418|安徽省|宣城市'
      },
      {
        label: '中国工商银行股份有限公司郎溪支行',
        value: '102377208807|3418|安徽省|宣城市'
      },
      {
        label: '中国工商银行广德县支行',
        value: '102377308709|3418|安徽省|宣城市'
      },
      {
        label: '中国工商银行股份有限公司广德中街支行',
        value: '102377308725|3418|安徽省|宣城市'
      },
      {
        label: '中国工商银行股份有限公司广德开发区支行',
        value: '102377308733|3418|安徽省|宣城市'
      },
      {
        label: '中国工商银行宁国市支行',
        value: '102377409004|3418|安徽省|宣城市'
      },
      {
        label: '中国工商银行股份有限公司宁国宁阳路支行',
        value: '102377409037|3418|安徽省|宣城市'
      },
      {
        label: '中国工商银行股份有限公司宁国大华支行',
        value: '102377409045|3418|安徽省|宣城市'
      },
      {
        label: '中国工商银行股份有限公司宁国城南支行',
        value: '102377423236|3418|安徽省|宣城市'
      },
      {
        label: '中国工商银行泾县支行',
        value: '102377508906|3418|安徽省|宣城市'
      },
      {
        label: '中国工商银行股份有限公司泾县东城支行',
        value: '102377508914|3418|安徽省|宣城市'
      },
      {
        label: '中国工商银行股份有限公司旌德支行',
        value: '102377609707|3418|安徽省|宣城市'
      },
      {
        label: '中国工商银行股份有限公司绩溪龙川支行',
        value: '102377701588|3418|安徽省|宣城市'
      },
      {
        label: '中国工商银行绩溪县支行',
        value: '102377709605|3418|安徽省|宣城市'
      },
      {
        label: '中国工商银行股份有限公司巢湖草城分理处',
        value: '102378106800|3401|安徽省|合肥市'
      },
      {
        label: '中国工商银行温州乐清市支行乐成分理处',
        value: '102333328210|3303|浙江省|温州市'
      },
      {
        label: '中国工商银行温州乐清柳市支行',
        value: '102333328228|3303|浙江省|温州市'
      },
      {
        label: '中国工商银行温州乐清市支行北白象分理处',
        value: '102333328236|3303|浙江省|温州市'
      },
      {
        label: '中国工商银行温州乐清虹桥支行',
        value: '102333328244|3303|浙江省|温州市'
      },
      {
        label: '中国工商银行温州乐清市支行柳川分理处',
        value: '102333328252|3303|浙江省|温州市'
      },
      {
        label: '中国工商银行股份有限公司乐清支行兴华分理处',
        value: '102333328269|3303|浙江省|温州市'
      },
      {
        label: '中国工商银行股份有限公司乐清大荆支行',
        value: '102333328277|3303|浙江省|温州市'
      },
      {
        label: '中国工商银行股份有限公司乐清翁垟支行',
        value: '102333328285|3303|浙江省|温州市'
      },
      {
        label: '中国工商银行股份有限公司乐清芙蓉支行',
        value: '102333328293|3303|浙江省|温州市'
      },
      {
        label: '中国工商银行股份有限公司永嘉阳光支行',
        value: '102333405215|3303|浙江省|温州市'
      },
      {
        label: '中国工商银行股份有限公司永嘉黄田支行',
        value: '102333407436|3303|浙江省|温州市'
      },
      {
        label: '中国工商银行股份有限公司永嘉上塘支行',
        value: '102333407469|3303|浙江省|温州市'
      },
      {
        label: '中国工商银行股份有限公司温州永嘉支行城中分理处',
        value: '102333407532|3303|浙江省|温州市'
      },
      {
        label: '中国工商银行股份有限公司永嘉支行',
        value: '102333428501|3303|浙江省|温州市'
      },
      {
        label: '中国工商银行股份有限公司永嘉瓯北支行',
        value: '102333428536|3303|浙江省|温州市'
      },
      {
        label: '中国工商银行股份有限公司永嘉乌牛支行',
        value: '102333428544|3303|浙江省|温州市'
      },
      {
        label: '中国工商银行股份有限公司永嘉桥头支行',
        value: '102333428569|3303|浙江省|温州市'
      },
      {
        label: '中国工商银行股份有限公司平阳横阳支行',
        value: '102333503055|3303|浙江省|温州市'
      },
      {
        label: '中国工商银行股份有限公司平阳凤林支行',
        value: '102333503071|3303|浙江省|温州市'
      },
      {
        label: '中国工商银行股份有限公司平阳支行',
        value: '102333528305|3303|浙江省|温州市'
      },
      {
        label: '中国工商银行股份有限公司平阳支行敖江分理处',
        value: '102333528313|3303|浙江省|温州市'
      },
      {
        label: '中国工商银行股份有限公司平阳昆阳支行',
        value: '102333528321|3303|浙江省|温州市'
      },
      {
        label: '中国工商银行股份有限公司厦门长虹支行',
        value: '102393000719|3502|福建省|厦门市'
      },
      {
        label: '中国工商银行股份有限公司渑池会盟路支行',
        value: '102505132165|4112|河南省|三门峡市'
      },
      {
        label: '中国工商银行股份有限公司三门峡陕州支行',
        value: '102505202079|4112|河南省|三门峡市'
      },
      {
        label: '中国工商银行股份有限公司咸宁岔路口支行',
        value: '102536000170|4212|湖北省|咸宁市'
      },
      {
        label: '中国工商银行股份有限公司咸宁分行',
        value: '102536000196|4212|湖北省|咸宁市'
      },
      {
        label: '中国工商银行股份有限公司咸宁泉塘支行',
        value: '102536000311|4212|湖北省|咸宁市'
      },
      {
        label: '中国工商银行股份有限公司咸宁花坛支行',
        value: '102536000320|4212|湖北省|咸宁市'
      },
      {
        label: '中国工商银行股份有限公司咸宁银泉支行',
        value: '102536001853|4212|湖北省|咸宁市'
      },
      {
        label: '中国工商银行股份有限公司咸宁淦河支行',
        value: '102536001861|4212|湖北省|咸宁市'
      },
      {
        label: '中国工商银行咸宁市分行会计业务处理中心',
        value: '102536005004|4212|湖北省|咸宁市'
      },
      {
        label: '中国工商银行股份有限公司成都蜀汉路支行',
        value: '102651008135|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司四川省成都彭州新城支行',
        value: '102651008233|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都高升桥东路支行',
        value: '102651008428|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都龙泉建设路支行',
        value: '102651008549|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都航天路支行',
        value: '102651008604|5101|四川省|成都市'
      },
      {
        label: '中国工商银行商丘分行睢阳支行',
        value: '102506002062|4114|河南省|商丘市'
      },
      {
        label: '中国工商银行股份有限公司商丘兰亭支行',
        value: '102506002183|4114|河南省|商丘市'
      },
      {
        label: '中国工商银行商丘分行会计业务处理中心',
        value: '102506002896|4114|河南省|商丘市'
      },
      {
        label: '中国工商银行商丘分行向阳路支行',
        value: '102506012028|4114|河南省|商丘市'
      },
      {
        label: '中国工商银行股份有限公司商丘中山街支行',
        value: '102506022066|4114|河南省|商丘市'
      },
      {
        label: '中国工商银行股份有限公司商丘长江路支行',
        value: '102506042049|4114|河南省|商丘市'
      },
      {
        label: '中国工商银行股份有限公司商丘南京路支行',
        value: '102506052022|4114|河南省|商丘市'
      },
      {
        label: '中国工商银行股份有限公司虞城支行',
        value: '102506202078|4114|河南省|商丘市'
      },
      {
        label: '中国工商银行商丘分行民权县支行',
        value: '102506402131|4114|河南省|商丘市'
      },
      {
        label: '中国工商银行商丘分行宁陵县支行',
        value: '102506502110|4114|河南省|商丘市'
      },
      {
        label: '中国工商银行商丘分行睢县支行',
        value: '102506602120|4114|河南省|商丘市'
      },
      {
        label: '中国工商银行商丘分行夏邑县支行',
        value: '102506702081|4114|河南省|商丘市'
      },
      {
        label: '中国工商银行商丘分行柘城县支行',
        value: '102506802105|4114|河南省|商丘市'
      },
      {
        label: '中国工商银行商丘分行永城市支行',
        value: '102506902091|4114|河南省|商丘市'
      },
      {
        label: '中国工商银行商丘分行永城市中山路支行',
        value: '102506912097|4114|河南省|商丘市'
      },
      {
        label: '中国工商银行股份有限公司周口分行会计业务处理中心',
        value: '102508000017|4116|河南省|周口市'
      },
      {
        label: '中国工商银行股份有限公司周口分行',
        value: '102508000025|4116|河南省|周口市'
      },
      {
        label: '中国工商银行股份有限公司周口川汇支行',
        value: '102508000033|4116|河南省|周口市'
      },
      {
        label: '中国工商银行股份有限公司周口川汇支行建设路分理处',
        value: '102508000041|4116|河南省|周口市'
      },
      {
        label: '中国工商银行股份有限公司周口川汇支行中州分理处',
        value: '102508000050|4116|河南省|周口市'
      },
      {
        label: '中国工商银行股份有限公司周口铁路支行',
        value: '102508000189|4116|河南省|周口市'
      },
      {
        label: '中国工商银行股份有限公司周口大庆路支行',
        value: '102508000252|4116|河南省|周口市'
      },
      {
        label: '中国工商银行股份有限公司周口东城支行',
        value: '102508000285|4116|河南省|周口市'
      },
      {
        label: '中国工商银行股份有限公司扶沟支行',
        value: '102508200010|4116|河南省|周口市'
      },
      {
        label: '中国工商银行股份有限公司西华支行',
        value: '102508300019|4116|河南省|周口市'
      },
      {
        label: '中国工商银行股份有限公司商水支行',
        value: '102508400011|4116|河南省|周口市'
      },
      {
        label: '中国工商银行股份有限公司太康支行',
        value: '102508500012|4116|河南省|周口市'
      },
      {
        label: '中国工商银行股份有限公司鹿邑支行',
        value: '102508600013|4116|河南省|周口市'
      },
      {
        label: '中国工商银行股份有限公司郸城支行',
        value: '102508700014|4116|河南省|周口市'
      },
      {
        label: '中国工商银行股份有限公司淮阳支行',
        value: '102508800015|4116|河南省|周口市'
      },
      {
        label: '中国工商银行股份有限公司沈丘支行',
        value: '102508900016|4116|河南省|周口市'
      },
      {
        label: '中国工商银行股份有限公司项城支行',
        value: '102509100011|4116|河南省|周口市'
      },
      {
        label: '中国工商银行荆州市分行荆州支行荆都分理处',
        value: '102537010431|4210|湖北省|荆州市'
      },
      {
        label: '中国工商银行股份有限公司荆州江津路支行',
        value: '102537010458|4210|湖北省|荆州市'
      },
      {
        label: '中国工商银行荆州市分行荆州支行四机分理处',
        value: '102537010466|4210|湖北省|荆州市'
      },
      {
        label: '中国工商银行股份有限公司荆州城南开发区支行',
        value: '102537010482|4210|湖北省|荆州市'
      },
      {
        label: '中国工商银行荆州市荆州支行市委分理处',
        value: '102537010511|4210|湖北省|荆州市'
      },
      {
        label: '中国工商银行股份有限公司江陵支行',
        value: '102537067309|4210|湖北省|荆州市'
      },
      {
        label: '中国工商银行股份有限公司仙桃江汉支行',
        value: '102537103114|4210|湖北省|荆州市'
      },
      {
        label: '中国工商银行股份有限公司仙桃新城支行',
        value: '102537103122|4210|湖北省|荆州市'
      },
      {
        label: '中国工商银行股份有限公司仙桃西桥支行',
        value: '102537103139|4210|湖北省|荆州市'
      },
      {
        label: '中国工商银行荆州市分行仙桃市支行',
        value: '102537110785|4210|湖北省|荆州市'
      },
      {
        label: '中国工商银行股份有限公司成都春熙支行槐树街分理处',
        value: '102651020922|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都万科路支行',
        value: '102651020939|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都净居寺南街支行',
        value: '102651020963|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都水杉街支行',
        value: '102651020971|5101|四川省|成都市'
      },
      {
        label: '中国工商银行成都市青龙支行营业室',
        value: '102651021005|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都春熙支行宏济新路分理处',
        value: '102651021013|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司青岛山东路支行',
        value: '102452000029|3702|山东省|青岛市'
      },
      {
        label: '中国工商银行股份有限公司青岛市南支行',
        value: '102452000037|3702|山东省|青岛市'
      },
      {
        label: '中国工商银行股份有限公司青岛中央商务区支行',
        value: '102452000045|3702|山东省|青岛市'
      },
      {
        label: '中国工商银行股份有限公司青岛福州路支行',
        value: '102452000053|3702|山东省|青岛市'
      },
      {
        label: '中国工商银行股份有限公司青岛中山路支行',
        value: '102452000061|3702|山东省|青岛市'
      },
      {
        label: '中国工商银行青岛市市南区第三支行',
        value: '102452000070|3702|山东省|青岛市'
      },
      {
        label: '中国工商银行股份有限公司青岛市南支行莱芜二路分理处',
        value: '102452000088|3702|山东省|青岛市'
      },
      {
        label: '中国工商银行股份有限公司青岛香港中路支行',
        value: '102452000096|3702|山东省|青岛市'
      },
      {
        label: '中国工商银行股份有限公司青岛昌乐路支行',
        value: '102452000107|3702|山东省|青岛市'
      },
      {
        label: '中国工商银行股份有限公司青岛龙口路支行',
        value: '102452000115|3702|山东省|青岛市'
      },
      {
        label: '中国工商银行股份有限公司青岛兴阳路支行',
        value: '102452000123|3702|山东省|青岛市'
      },
      {
        label: '中国工商银行青岛市市南区第二支行',
        value: '102452000131|3702|山东省|青岛市'
      },
      {
        label: '中国工商银行股份有限公司青岛市分行（卡业务专用）',
        value: '102452000140|3702|山东省|青岛市'
      },
      {
        label: '中国工商银行股份有限公司青岛市南第四支行',
        value: '102452000158|3702|山东省|青岛市'
      },
      {
        label: '中国工商银行股份有限公司青岛开发区支行太行山路分理处',
        value: '102452000166|3702|山东省|青岛市'
      },
      {
        label: '中国工商银行股份有限公司青岛南京路支行',
        value: '102452000174|3702|山东省|青岛市'
      },
      {
        label: '中国工商银行股份有限公司青岛贵州路支行',
        value: '102452000238|3702|山东省|青岛市'
      },
      {
        label: '中国工商银行股份有限公司青岛福州北路支行',
        value: '102452000262|3702|山东省|青岛市'
      },
      {
        label: '中国工商银行股份有限公司洛阳新区支行',
        value: '102493022128|4103|河南省|洛阳市'
      },
      {
        label: '中国工商银行股份有限公司新乡支行',
        value: '102498022028|4107|河南省|新乡市'
      },
      {
        label: '中国工商银行股份有限公司新乡区府路支行',
        value: '102498022044|4107|河南省|新乡市'
      },
      {
        label: '中国工商银行股份有限公司新乡新一街支行',
        value: '102498022052|4107|河南省|新乡市'
      },
      {
        label: '中国工商银行股份有限公司新乡道清路支行',
        value: '102498022069|4107|河南省|新乡市'
      },
      {
        label: '中国工商银行股份有限公司新乡金汇支行',
        value: '102498022077|4107|河南省|新乡市'
      },
      {
        label: '中国工商银行股份有限公司新乡三八支行',
        value: '102498032023|4107|河南省|新乡市'
      },
      {
        label: '中国工商银行股份有限公司新乡宝龙支行',
        value: '102498042035|4107|河南省|新乡市'
      },
      {
        label: '中国工商银行股份有限公司新乡五一支行',
        value: '102498052024|4107|河南省|新乡市'
      },
      {
        label: '中国工商银行股份有限公司新乡洪门支行',
        value: '102498062022|4107|河南省|新乡市'
      },
      {
        label: '中国工商银行股份有限公司宁波镇海开发区支行',
        value: '102332016075|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行股份有限公司宁波镇海新区支行',
        value: '102332016083|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行股份有限公司宁波万科城支行',
        value: '102332016106|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行股份有限公司宁波镇海海尚支行',
        value: '102332016122|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行宁波市镇海石化支行',
        value: '102332017002|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行股份有限公司宁波丰收支行',
        value: '102332017027|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行宁波庄市支行',
        value: '102332017035|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行宁波市北仑区支行',
        value: '102332018005|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行股份有限公司宁波大碶支行',
        value: '102332018013|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行股份有限公司宁波柴桥支行',
        value: '102332018021|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行宁波兴港支行',
        value: '102332018030|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行股份有限公司宁波新碶支行',
        value: '102332018056|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行安徽省分行清算中心（不对外办理业务）',
        value: '102361009990|3401|安徽省|合肥市'
      },
      {
        label: '中国工商银行股份有限公司芜湖政务新区支行',
        value: '102362000700|3402|安徽省|芜湖市'
      },
      {
        label: '中国工商银行芜湖市分行业务处理中心',
        value: '102362001001|3402|安徽省|芜湖市'
      },
      {
        label: '中国工商银行芜湖分行开发区支行',
        value: '102362001884|3402|安徽省|芜湖市'
      },
      {
        label: '中国工商银行股份有限公司芜湖赭山支行',
        value: '102362002300|3402|安徽省|芜湖市'
      },
      {
        label: '中国工商银行股份有限公司芜湖中山路支行',
        value: '102362002318|3402|安徽省|芜湖市'
      },
      {
        label: '中国工商银行芜湖环城路支行',
        value: '102362002326|3402|安徽省|芜湖市'
      },
      {
        label: '中国工商银行芜湖长江路支行',
        value: '102362002334|3402|安徽省|芜湖市'
      },
      {
        label: '中国工商银行股份有限公司芜湖四褐山支行',
        value: '102362002359|3402|安徽省|芜湖市'
      },
      {
        label: '中国工商银行股份有限公司芜湖裕溪口支行',
        value: '102362002367|3402|安徽省|芜湖市'
      },
      {
        label: '中国工商银行股份有限公司芜湖奇瑞支行',
        value: '102362002375|3402|安徽省|芜湖市'
      },
      {
        label: '中国工商银行股份有限公司芜湖湾里支行',
        value: '102362002383|3402|安徽省|芜湖市'
      },
      {
        label: '中国工商银行股份有限公司芜湖白马山支行',
        value: '102362002519|3402|安徽省|芜湖市'
      },
      {
        label: '中国工商银行股份有限公司芜湖镜湖支行',
        value: '102362003200|3402|安徽省|芜湖市'
      },
      {
        label: '中国工商银行芜湖城建支行',
        value: '102362003306|3402|安徽省|芜湖市'
      },
      {
        label: '中国工商银行股份有限公司芜湖九华中路支行',
        value: '102362020235|3402|安徽省|芜湖市'
      },
      {
        label: '中国工商银行股份有限公司芜湖北京路支行',
        value: '102362021221|3402|安徽省|芜湖市'
      },
      {
        label: '中国工商银行股份有限公司芜湖新芜路支行',
        value: '102362021230|3402|安徽省|芜湖市'
      },
      {
        label: '中国工商银行股份有限公司芜湖中央城支行',
        value: '102362021248|3402|安徽省|芜湖市'
      },
      {
        label: '中国工商银行股份有限公司芜湖滨江支行',
        value: '102362021256|3402|安徽省|芜湖市'
      },
      {
        label: '中国工商银行股份有限公司芜湖新家巷支行',
        value: '102362021264|3402|安徽省|芜湖市'
      },
      {
        label: '中国工商银行股份有限公司芜湖团结路支行',
        value: '102362022208|3402|安徽省|芜湖市'
      },
      {
        label: '中国工商银行股份有限公司崇仁支行',
        value: '102437511114|3610|江西省|抚州市'
      },
      {
        label: '中国工商银行股份有限公司乐安公溪支行',
        value: '102437611126|3610|江西省|抚州市'
      },
      {
        label: '中国工商银行郑州市行政区支行',
        value: '102491002915|4101|河南省|郑州市'
      },
      {
        label: '中国工商银行股份有限公司郑州自贸试验区支行',
        value: '102491002923|4101|河南省|郑州市'
      },
      {
        label: '中国工商银行股份有限公司郑州财富广场支行',
        value: '102491002931|4101|河南省|郑州市'
      },
      {
        label: '中国工商银行郑州市三八支行',
        value: '102491002940|4101|河南省|郑州市'
      },
      {
        label: '中国工商银行股份有限公司郑州经济技术开发区支行',
        value: '102491010022|4101|河南省|郑州市'
      },
      {
        label: '中国工商银行股份有限公司宝龙广场支行',
        value: '102491010063|4101|河南省|郑州市'
      },
      {
        label: '中国工商银行股份有限公司甘南支行',
        value: '102264503717|2302|黑龙江省|齐齐哈尔市'
      },
      {
        label: '中国工商银行股份有限公司甘南查哈阳支行',
        value: '102264504239|2302|黑龙江省|齐齐哈尔市'
      },
      {
        label: '中国工商银行股份有限公司富裕支行',
        value: '102264703765|2302|黑龙江省|齐齐哈尔市'
      },
      {
        label: '中国工商银行股份有限公司林甸支行',
        value: '102264806235|2306|黑龙江省|大庆市'
      },
      {
        label: '中国工商银行股份有限公司克山支行',
        value: '102264903432|2302|黑龙江省|齐齐哈尔市'
      },
      {
        label: '中国工商银行股份有限公司大庆永达支行',
        value: '102265000099|2306|黑龙江省|大庆市'
      },
      {
        label: '中国工商银行股份有限公司大庆分行银行卡中心',
        value: '102265000793|2306|黑龙江省|大庆市'
      },
      {
        label: '中国工商银行股份有限公司大庆会战支行',
        value: '102265001026|2306|黑龙江省|大庆市'
      },
      {
        label: '中国工商银行股份有限公司大庆炼化支行',
        value: '102265001067|2306|黑龙江省|大庆市'
      },
      {
        label: '中国工商银行股份有限公司大庆油田支行大楼支行',
        value: '102265001083|2306|黑龙江省|大庆市'
      },
      {
        label: '中国工商银行股份有限公司大庆龙北支行',
        value: '102265002043|2306|黑龙江省|大庆市'
      },
      {
        label: '中国工商银行股份有限公司大庆百湖支行',
        value: '102265004104|2306|黑龙江省|大庆市'
      },
      {
        label: '中国工商银行大庆市油田支行',
        value: '102265006014|2306|黑龙江省|大庆市'
      },
      {
        label: '中国工商银行大庆市工行龙凤支行',
        value: '102265006039|2306|黑龙江省|大庆市'
      },
      {
        label: '中国工商银行大庆市工行让胡路支行',
        value: '102265006047|2306|黑龙江省|大庆市'
      },
      {
        label: '中国工商银行大庆市工行拥军支行',
        value: '102265006055|2306|黑龙江省|大庆市'
      },
      {
        label: '中国工商银行大庆市工行庆新支行',
        value: '102265006063|2306|黑龙江省|大庆市'
      },
      {
        label: '中国工商银行大庆市工行红岗支行',
        value: '102265006071|2306|黑龙江省|大庆市'
      },
      {
        label: '中国工商银行大庆市工行七厂支行',
        value: '102265006080|2306|黑龙江省|大庆市'
      },
      {
        label: '中国工商银行大庆市工行友谊支行',
        value: '102265006102|2306|黑龙江省|大庆市'
      },
      {
        label: '中国工商银行大庆市工行新华支行',
        value: '102265006119|2306|黑龙江省|大庆市'
      },
      {
        label: '中国工商银行大庆市工行杏南支行',
        value: '102265006135|2306|黑龙江省|大庆市'
      },
      {
        label: '中国工商银行大庆市工行乘风支行',
        value: '102265006151|2306|黑龙江省|大庆市'
      },
      {
        label: '中国工商银行大庆市工行八百垧支行',
        value: '102265006160|2306|黑龙江省|大庆市'
      },
      {
        label: '中国工商银行股份有限公司大庆大同支行',
        value: '102265006178|2306|黑龙江省|大庆市'
      },
      {
        label: '中国工商银行股份有限公司大庆林源支行',
        value: '102265006186|2306|黑龙江省|大庆市'
      },
      {
        label: '中国工商银行股份有限公司大庆大化支行',
        value: '102265006194|2306|黑龙江省|大庆市'
      },
      {
        label: '中国工商银行大庆市卧里屯支行',
        value: '102265006209|2306|黑龙江省|大庆市'
      },
      {
        label: '中国工商银行大庆市龙南支行',
        value: '102265006217|2306|黑龙江省|大庆市'
      },
      {
        label: '中国工商银行大庆市铁人支行',
        value: '102265006225|2306|黑龙江省|大庆市'
      },
      {
        label: '中国工商银行股份有限公司大庆奔腾支行',
        value: '102265006268|2306|黑龙江省|大庆市'
      },
      {
        label: '中国工商银行大庆市东风支行',
        value: '102265006276|2306|黑龙江省|大庆市'
      },
      {
        label: '中国工商银行股份有限公司大庆新三支行',
        value: '102265006292|2306|黑龙江省|大庆市'
      },
      {
        label: '中国工商银行大庆市开发区支行',
        value: '102265006356|2306|黑龙江省|大庆市'
      },
      {
        label: '中国工商银行大庆市兴业支行',
        value: '102265006372|2306|黑龙江省|大庆市'
      },
      {
        label: '中国工商银行大庆市通达支行',
        value: '102265006389|2306|黑龙江省|大庆市'
      },
      {
        label: '中国工商银行股份有限公司大庆龙源支行',
        value: '102265006469|2306|黑龙江省|大庆市'
      },
      {
        label: '中国工商银行股份有限公司大庆龙兴支行',
        value: '102265006485|2306|黑龙江省|大庆市'
      },
      {
        label: '中国工商银行股份有限公司大庆翔天支行',
        value: '102265006581|2306|黑龙江省|大庆市'
      },
      {
        label: '中国工商银行股份有限公司大庆高新支行',
        value: '102265007033|2306|黑龙江省|大庆市'
      },
      {
        label: '中国工商银行大庆市分行核算中心',
        value: '102265009004|2306|黑龙江省|大庆市'
      },
      {
        label: '中国工商银行厦门东区支行',
        value: '102393000727|3502|福建省|厦门市'
      },
      {
        label: '中国工商银行股份有限公司厦门吕岭支行',
        value: '102393000735|3502|福建省|厦门市'
      },
      {
        label: '中国工商银行股份有限公司厦门福津支行',
        value: '102393000743|3502|福建省|厦门市'
      },
      {
        label: '中国工商银行股份有限公司厦门前埔支行',
        value: '102393000751|3502|福建省|厦门市'
      },
      {
        label: '中国工商银行厦门城建支行',
        value: '102393000760|3502|福建省|厦门市'
      },
      {
        label: '中国工商银行厦门同安支行',
        value: '102393000778|3502|福建省|厦门市'
      },
      {
        label: '中国工商银行厦门翔安支行',
        value: '102393000786|3502|福建省|厦门市'
      },
      {
        label: '中国工商银行股份有限公司厦门城南支行',
        value: '102393000809|3502|福建省|厦门市'
      },
      {
        label: '中国工商银行股份有限公司厦门环城支行',
        value: '102393000825|3502|福建省|厦门市'
      },
      {
        label: '中国工商银行厦门海沧支行',
        value: '102393000833|3502|福建省|厦门市'
      },
      {
        label: '中国工商银行股份有限公司厦门自贸试验区航运支行',
        value: '102393000841|3502|福建省|厦门市'
      },
      {
        label: '中国工商银行股份有限公司厦门新阳支行',
        value: '102393000850|3502|福建省|厦门市'
      },
      {
        label: '中国工商银行股份有限公司济南钢厂支行',
        value: '102451013210|3701|山东省|济南市'
      },
      {
        label: '中国工商银行股份有限公司济南二环东路支行',
        value: '102451013269|3701|山东省|济南市'
      },
      {
        label: '中国工商银行股份有限公司济南舜耕路支行',
        value: '102451013277|3701|山东省|济南市'
      },
      {
        label: '中国工商银行股份有限公司济南甸柳支行',
        value: '102451013324|3701|山东省|济南市'
      },
      {
        label: '中国工商银行股份有限公司济南北坦支行',
        value: '102451014052|3701|山东省|济南市'
      },
      {
        label: '中国工商银行股份有限公司济南洛口北支行',
        value: '102451014116|3701|山东省|济南市'
      },
      {
        label: '中国工商银行股份有限公司济南堤口西支行',
        value: '102451014149|3701|山东省|济南市'
      },
      {
        label: '中国工商银行股份有限公司济南明湖东路支行',
        value: '102451014165|3701|山东省|济南市'
      },
      {
        label: '中国工商银行股份有限公司济南明湖支行三孔桥分理处',
        value: '102451014173|3701|山东省|济南市'
      },
      {
        label: '中国工商银行股份有限公司济南英贤街支行',
        value: '102451014245|3701|山东省|济南市'
      },
      {
        label: '中国工商银行股份有限公司济南花园路支行',
        value: '102451015012|3701|山东省|济南市'
      },
      {
        label: '中国工商银行股份有限公司漳州芗城支行前锋储蓄所',
        value: '102399032108|3506|福建省|漳州市'
      },
      {
        label: '中国工商银行股份有限公司枣庄君山路支行',
        value: '102454000459|3704|山东省|枣庄市'
      },
      {
        label: '中国工商银行股份有限公司枣庄分行运行业务处理中心',
        value: '102454007761|3704|山东省|枣庄市'
      },
      {
        label: '中国工商银行股份有限公司滕州支行平行路分理处',
        value: '102454100285|3704|山东省|枣庄市'
      },
      {
        label: '中国工商银行股份有限公司枣庄鲁化支行',
        value: '102454100293|3704|山东省|枣庄市'
      },
      {
        label: '中国工商银行股份有限公司滕州支行',
        value: '102454100308|3704|山东省|枣庄市'
      },
      {
        label: '中国工商银行股份有限公司滕州支行城镇分理处',
        value: '102454100316|3704|山东省|枣庄市'
      },
      {
        label: '中国工商银行股份有限公司滕州支行新兴分理处',
        value: '102454100324|3704|山东省|枣庄市'
      },
      {
        label: '中国工商银行股份有限公司滕州支行铁路西分理处',
        value: '102454100332|3704|山东省|枣庄市'
      },
      {
        label: '中国工商银行股份有限公司枣庄三八支行',
        value: '102454100349|3704|山东省|枣庄市'
      },
      {
        label: '中国工商银行股份有限公司滕州支行荆东分理处',
        value: '102454100357|3704|山东省|枣庄市'
      },
      {
        label: '中国工商银行股份有限公司枣庄柴里支行',
        value: '102454100365|3704|山东省|枣庄市'
      },
      {
        label: '中国工商银行股份有限公司滕州支行广场分理处',
        value: '102454100373|3704|山东省|枣庄市'
      },
      {
        label: '中国工商银行股份有限公司烟台莱山支行',
        value: '102456000606|3706|山东省|烟台市'
      },
      {
        label: '中国工商银行股份有限公司烟台开发区支行',
        value: '102456000702|3706|山东省|烟台市'
      },
      {
        label: '中国工商银行股份有限公司烟台福山支行',
        value: '102456000809|3706|山东省|烟台市'
      },
      {
        label: '中国工商银行股份有限公司烟台牟平支行',
        value: '102456000905|3706|山东省|烟台市'
      },
      {
        label: '中国工商银行股份有限公司烟台牟平政府街支行',
        value: '102456010066|3706|山东省|烟台市'
      },
      {
        label: '中国工商银行股份有限公司蓬莱支行',
        value: '102456101005|3706|山东省|烟台市'
      },
      {
        label: '中国工商银行股份有限公司招远支行河东路分理处',
        value: '102456200940|3706|山东省|烟台市'
      },
      {
        label: '中国工商银行股份有限公司招远支行',
        value: '102456202009|3706|山东省|烟台市'
      },
      {
        label: '中国工商银行股份有限公司栖霞支行',
        value: '102456302704|3706|山东省|烟台市'
      },
      {
        label: '中国工商银行股份有限公司栖霞支行桃村分理处',
        value: '102456302807|3706|山东省|烟台市'
      },
      {
        label: '中国工商银行股份有限公司海阳支行',
        value: '102456403006|3706|山东省|烟台市'
      },
      {
        label: '中国工商银行股份有限公司海阳核电分理处',
        value: '102456410321|3706|山东省|烟台市'
      },
      {
        label: '中国工商银行股份有限公司长岛支行',
        value: '102456603101|3706|山东省|烟台市'
      },
      {
        label: '中国工商银行股份有限公司龙口支行',
        value: '102456701409|3706|山东省|烟台市'
      },
      {
        label: '中国工商银行股份有限公司龙口支行黄城西大街分理处',
        value: '102456701601|3706|山东省|烟台市'
      },
      {
        label: '中国工商银行股份有限公司龙口支行振兴路分理处',
        value: '102456701804|3706|山东省|烟台市'
      },
      {
        label: '中国工商银行股份有限公司龙口支行煤矿分理处',
        value: '102456701907|3706|山东省|烟台市'
      },
      {
        label: '中国工商银行股份有限公司龙口支行南山路分理处',
        value: '102456710011|3706|山东省|烟台市'
      },
      {
        label: '中国工商银行股份有限公司龙口黄城支行',
        value: '102456710038|3706|山东省|烟台市'
      },
      {
        label: '中国工商银行股份有限公司莱阳支行',
        value: '102456802300|3706|山东省|烟台市'
      },
      {
        label: '中国工商银行股份有限公司莱阳支行火车站分理处',
        value: '102456802406|3706|山东省|烟台市'
      },
      {
        label: '中国工商银行股份有限公司莱州支行焦家分理处',
        value: '102456900866|3706|山东省|烟台市'
      },
      {
        label: '中国工商银行股份有限公司莱州支行广场分理处',
        value: '102456900874|3706|山东省|烟台市'
      },
      {
        label: '中国工商银行股份有限公司莱州支行三山岛分理处',
        value: '102456900938|3706|山东省|烟台市'
      },
      {
        label: '中国工商银行淮安市楚南支行',
        value: '102308007021|3208|江苏省|淮安市'
      },
      {
        label: '中国工商银行股份有限公司珠海御景支行',
        value: '102585002489|4404|广东省|珠海市'
      },
      {
        label: '中国工商银行股份有限公司平阳支行腾蛟分理处',
        value: '102333528348|3303|浙江省|温州市'
      },
      {
        label: '中国工商银行股份有限公司平阳萧江支行',
        value: '102333528356|3303|浙江省|温州市'
      },
      {
        label: '中国工商银行股份有限公司平阳万全支行',
        value: '102333528364|3303|浙江省|温州市'
      },
      {
        label: '中国工商银行股份有限公司苍南支行德雅分理处',
        value: '102333600838|3303|浙江省|温州市'
      },
      {
        label: '中国工商银行股份有限公司苍南方城支行',
        value: '102333605198|3303|浙江省|温州市'
      },
      {
        label: '中国工商银行苍南县支行',
        value: '102333628403|3303|浙江省|温州市'
      },
      {
        label: '中国工商银行股份有限公司苍南支行新区分理处',
        value: '102333628411|3303|浙江省|温州市'
      },
      {
        label: '中国工商银行温州苍南龙港支行',
        value: '102333628420|3303|浙江省|温州市'
      },
      {
        label: '中国工商银行股份有限公司马鞍山四村支行',
        value: '102365002132|3405|安徽省|马鞍山市'
      },
      {
        label: '中国工商银行马鞍山汇通支行',
        value: '102365002288|3405|安徽省|马鞍山市'
      },
      {
        label: '中国工商银行股份有限公司马鞍山牡丹支行',
        value: '102365002382|3405|安徽省|马鞍山市'
      },
      {
        label: '中国工商银行马鞍山城建支行',
        value: '102365002487|3405|安徽省|马鞍山市'
      },
      {
        label: '中国工商银行马鞍山开发区支行',
        value: '102365002680|3405|安徽省|马鞍山市'
      },
      {
        label: '中国工商银行股份有限公司马鞍山菊园路分理处',
        value: '102365023265|3405|安徽省|马鞍山市'
      },
      {
        label: '中国工商银行股份有限公司当涂支行',
        value: '102365102094|3405|安徽省|马鞍山市'
      },
      {
        label: '中国工商银行淮北淮海路支行',
        value: '102366000024|3406|安徽省|淮北市'
      },
      {
        label: '中国工商银行淮北相山西区支行',
        value: '102366000032|3406|安徽省|淮北市'
      },
      {
        label: '中国工商银行淮北袁庄支行',
        value: '102366000049|3406|安徽省|淮北市'
      },
      {
        label: '中国工商银行淮北红旗支行',
        value: '102366000057|3406|安徽省|淮北市'
      },
      {
        label: '中国工商银行淮北矿山集支行',
        value: '102366000065|3406|安徽省|淮北市'
      },
      {
        label: '中国工商银行淮北杜集支行',
        value: '102366000073|3406|安徽省|淮北市'
      },
      {
        label: '中国工商银行淮北相南支行',
        value: '102366000081|3406|安徽省|淮北市'
      },
      {
        label: '中国工商银行淮北烈山支行',
        value: '102366000090|3406|安徽省|淮北市'
      },
      {
        label: '中国工商银行淮北刘桥支行',
        value: '102366000104|3406|安徽省|淮北市'
      },
      {
        label: '中国工商银行淮北临童支行',
        value: '102366000112|3406|安徽省|淮北市'
      },
      {
        label: '中国工商银行淮北汇通支行',
        value: '102366000129|3406|安徽省|淮北市'
      },
      {
        label: '中国工商银行股份有限公司新化支行梅苑分理处',
        value: '102562510151|4313|湖南省|娄底市'
      },
      {
        label: '中国工商银行股份有限公司新化支行南门分理处',
        value: '102562510225|4313|湖南省|娄底市'
      },
      {
        label: '中国工商银行股份有限公司广州石井支行',
        value: '102581000562|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州新市支行',
        value: '102581000579|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州解放南路支行',
        value: '102581000600|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州光明路支行',
        value: '102581000618|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州东圃支行',
        value: '102581000626|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州越秀桥支行',
        value: '102581000659|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州塞坝口支行',
        value: '102581000667|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州江南西路支行',
        value: '102581000675|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州金碧支行',
        value: '102581000706|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州站西路支行',
        value: '102581000714|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州中山八路支行',
        value: '102581000739|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州江湾支行',
        value: '102581000747|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州中华广场支行',
        value: '102581000755|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州宝岗大道支行',
        value: '102581000763|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州烟草大厦支行',
        value: '102581000780|4401|广东省|广州市'
      },
      {
        label: '中国工商银行广州黄埔大道西支行',
        value: '102581000798|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州昌岗中路支行',
        value: '102581000802|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州机场支行',
        value: '102581000819|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州黄华路支行',
        value: '102581000843|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州燕子岗支行',
        value: '102581000860|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州环市中路支行',
        value: '102581000878|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州黄石路支行',
        value: '102581000886|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州政通路支行',
        value: '102581000894|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州黄花岗科技支行',
        value: '102581000917|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州麓景路支行',
        value: '102581000925|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州东风西路支行',
        value: '102581000933|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州元岗支行',
        value: '102581000941|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州东晓支行',
        value: '102581000950|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州京溪支行',
        value: '102581000976|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州中山二路支行',
        value: '102581000992|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州松北支行',
        value: '102581001008|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州江村支行',
        value: '102581001016|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州广源西路支行',
        value: '102581001024|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州鹤洞支行',
        value: '102581001032|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州番禺市桥支行',
        value: '102581001049|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司四川省成都双流支行营业室',
        value: '102651024900|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司双流蛟龙港支行',
        value: '102651024918|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都三强东路支行',
        value: '102651024926|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都龙灯山路支行',
        value: '102651024934|5101|四川省|成都市'
      },
      {
        label: '中国工商银行长沙市岳麓山支行石佳冲分理处',
        value: '102551000182|4301|湖南省|长沙市'
      },
      {
        label: '中国工商银行股份有限公司长沙师大支行',
        value: '102551000203|4301|湖南省|长沙市'
      },
      {
        label: '中国工商银行股份有限公司长沙湖大支行',
        value: '102551000211|4301|湖南省|长沙市'
      },
      {
        label: '中国工商银行股份有限公司长沙溁湾镇支行',
        value: '102551000220|4301|湖南省|长沙市'
      },
      {
        label: '中国工商银行长沙市韶山路支行',
        value: '102551000238|4301|湖南省|长沙市'
      },
      {
        label: '中国工商银行股份有限公司长沙人民路支行',
        value: '102551000246|4301|湖南省|长沙市'
      },
      {
        label: '中国工商银行长沙市马王堆支行',
        value: '102551000262|4301|湖南省|长沙市'
      },
      {
        label: '中国工商银行长沙市东塘支行',
        value: '102551000287|4301|湖南省|长沙市'
      },
      {
        label: '中国工商银行股份有限公司长沙曙光南路支行',
        value: '102551000295|4301|湖南省|长沙市'
      },
      {
        label: '中国工商银行长沙市树木岭支行',
        value: '102551000300|4301|湖南省|长沙市'
      },
      {
        label: '中国工商银行长沙市井湾子支行',
        value: '102551000318|4301|湖南省|长沙市'
      },
      {
        label: '中国工商银行长沙市德雅路支行',
        value: '102551000342|4301|湖南省|长沙市'
      },
      {
        label: '中国工商银行股份有限公司长沙市银河支行',
        value: '102551000367|4301|湖南省|长沙市'
      },
      {
        label: '中国工商银行股份有限公司长沙砚瓦池支行',
        value: '102551000375|4301|湖南省|长沙市'
      },
      {
        label: '中国工商银行股份有限公司广州均禾支行',
        value: '102581003557|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州金钟支行',
        value: '102581003612|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州同德支行',
        value: '102581003637|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州东兴支行',
        value: '102581003696|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州兴华支行',
        value: '102581003707|4401|广东省|广州市'
      },
      {
        label: '中国工商银行广州明珠支行',
        value: '102581003715|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州高德广场支行',
        value: '102581003731|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州发展中心大厦支行',
        value: '102581003740|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州花都新机场支行',
        value: '102581003758|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州花都新都支行',
        value: '102581003782|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州花都公益大道支行',
        value: '102581003811|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州花都建设北路支行',
        value: '102581003854|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州钟村支行',
        value: '102581003862|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州大石新浦支行',
        value: '102581003879|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州南村支行',
        value: '102581003887|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州祈福支行',
        value: '102581003895|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州怡乐支行',
        value: '102581004092|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州中山大学支行',
        value: '102581004113|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州瑞宝支行',
        value: '102581004148|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州穗和支行',
        value: '102581004156|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州白云大道北支行',
        value: '102581004172|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州城西支行',
        value: '102581004189|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司许昌五一路支行',
        value: '102503002304|4110|河南省|许昌市'
      },
      {
        label: '中国工商银行股份有限公司许昌分行账务处理中心',
        value: '102503002683|4110|河南省|许昌市'
      },
      {
        label: '中国工商银行股份有限公司许昌学院路支行',
        value: '102503010304|4110|河南省|许昌市'
      },
      {
        label: '中国工商银行股份有限公司许昌前进路支行',
        value: '102503012206|4110|河南省|许昌市'
      },
      {
        label: '中国工商银行股份有限公司许昌开发区支行',
        value: '102503020305|4110|河南省|许昌市'
      },
      {
        label: '中国工商银行股份有限公司许昌魏武帝广场支行',
        value: '102503022108|4110|河南省|许昌市'
      },
      {
        label: '中国工商银行股份有限公司许昌白庙支行',
        value: '102503022204|4110|河南省|许昌市'
      },
      {
        label: '中国工商银行股份有限公司许昌帝豪广场支行',
        value: '102503022307|4110|河南省|许昌市'
      },
      {
        label: '中国工商银行股份有限公司许昌建设路支行',
        value: '102503032103|4110|河南省|许昌市'
      },
      {
        label: '中国工商银行股份有限公司许昌八一路支行',
        value: '102503042109|4110|河南省|许昌市'
      },
      {
        label: '中国工商银行股份有限公司许昌魏文路支行',
        value: '102503042205|4110|河南省|许昌市'
      },
      {
        label: '中国工商银行股份有限公司许昌许继大道支行',
        value: '102503042301|4110|河南省|许昌市'
      },
      {
        label: '中国工商银行股份有限公司许昌许由路支行',
        value: '102503052207|4110|河南省|许昌市'
      },
      {
        label: '中国工商银行股份有限公司许昌智慧大道支行',
        value: '102503052303|4110|河南省|许昌市'
      },
      {
        label: '中国工商银行股份有限公司蕲春蕲阳支行',
        value: '102533810208|4211|湖北省|黄冈市'
      },
      {
        label: '中国工商银行股份有限公司黄冈黄梅支行',
        value: '102533910187|4211|湖北省|黄冈市'
      },
      {
        label: '中国工商银行孝感市分行业务处理中心',
        value: '102535000014|4209|湖北省|孝感市'
      },
      {
        label: '中国工商银行股份有限公司孝感大东门支行',
        value: '102535000022|4209|湖北省|孝感市'
      },
      {
        label: '中国工商银行孝感北京路支行',
        value: '102535000039|4209|湖北省|孝感市'
      },
      {
        label: '中国工商银行驻马店分行西平县支行',
        value: '102511502079|4117|河南省|驻马店市'
      },
      {
        label: '中国工商银行股份有限公司西平支行解放路分理处',
        value: '102511522074|4117|河南省|驻马店市'
      },
      {
        label: '中国工商银行股份有限公司西平支行文化路分理处',
        value: '102511542073|4117|河南省|驻马店市'
      },
      {
        label: '中国工商银行莱芜市分行营业部',
        value: '102463410207|3712|山东省|莱芜市'
      },
      {
        label: '中国工商银行莱芜市莱城区支行',
        value: '102463410709|3712|山东省|莱芜市'
      },
      {
        label: '中国工商银行莱芜市钢城区支行',
        value: '102463411003|3712|山东省|莱芜市'
      },
      {
        label: '中国工商银行莱芜张家洼支行',
        value: '102463420100|3712|山东省|莱芜市'
      },
      {
        label: '中国工商银行莱芜南冶支行',
        value: '102463420206|3712|山东省|莱芜市'
      },
      {
        label: '中国工商银行莱芜城东支行',
        value: '102463420302|3712|山东省|莱芜市'
      },
      {
        label: '中国工商银行莱芜分行金莱广场支行',
        value: '102463420708|3712|山东省|莱芜市'
      },
      {
        label: '中国工商银行莱芜龙潭支行',
        value: '102463421305|3712|山东省|莱芜市'
      },
      {
        label: '中国工商银行莱芜花园支行',
        value: '102463421707|3712|山东省|莱芜市'
      },
      {
        label: '中国工商银行莱芜城西支行',
        value: '102463422201|3712|山东省|莱芜市'
      },
      {
        label: '中国工商银行莱芜城北支行',
        value: '102463422806|3712|山东省|莱芜市'
      },
      {
        label: '中国工商银行莱芜经济开发区支行',
        value: '102463423001|3712|山东省|莱芜市'
      },
      {
        label: '中国工商银行苍南县支行钱库分理处',
        value: '102333628438|3303|浙江省|温州市'
      },
      {
        label: '中国工商银行股份有限公司三沙支行',
        value: '102642000341|4602|海南省|三亚市'
      },
      {
        label: '中国工商银行股份有限公司三亚亚龙湾支行',
        value: '102642000497|4602|海南省|三亚市'
      },
      {
        label: '中国工商银行股份有限公司三亚东方支行',
        value: '102642000995|4602|海南省|三亚市'
      },
      {
        label: '中国工商银行股份有限公司三亚迎宾支行',
        value: '102642001019|4602|海南省|三亚市'
      },
      {
        label: '中国工商银行股份有限公司三亚瑞海支行',
        value: '102642001043|4602|海南省|三亚市'
      },
      {
        label: '中国工商银行股份有限公司吴川支行',
        value: '102591102136|4408|广东省|湛江市'
      },
      {
        label: '中国工商银行股份有限公司廉江支行',
        value: '102591202120|4408|广东省|湛江市'
      },
      {
        label: '中国工商银行股份有限公司遂溪支行',
        value: '102591302090|4408|广东省|湛江市'
      },
      {
        label: '中国工商银行股份有限公司雷州支行',
        value: '102591402113|4408|广东省|湛江市'
      },
      {
        label: '中国工商银行股份有限公司徐闻支行',
        value: '102591502106|4408|广东省|湛江市'
      },
      {
        label: '中国工商银行股份有限公司茂名分行营业部',
        value: '102592002112|4409|广东省|茂名市'
      },
      {
        label: '中国工商银行股份有限公司茂名官渡支行',
        value: '102592002129|4409|广东省|茂名市'
      },
      {
        label: '中国工商银行股份有限公司茂名迎宾支行',
        value: '102592002145|4409|广东省|茂名市'
      },
      {
        label: '中国工商银行股份有限公司茂名河西支行',
        value: '102592002215|4409|广东省|茂名市'
      },
      {
        label: '中国工商银行股份有限公司茂名文明南支行',
        value: '102592002240|4409|广东省|茂名市'
      },
      {
        label: '中国工商银行股份有限公司茂名银电支行',
        value: '102592002274|4409|广东省|茂名市'
      },
      {
        label: '中国工商银行股份有限公司茂名石化支行',
        value: '102592002311|4409|广东省|茂名市'
      },
      {
        label: '中国工商银行股份有限公司成都邛崃东星支行',
        value: '102651004818|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都邛崃支行东街分理处',
        value: '102651004867|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都彭州支行朝阳南路分理处',
        value: '102651005056|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司都江堰支行东门分理处',
        value: '102651005193|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都现代工业港支行',
        value: '102651005388|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都珠江路支行',
        value: '102651005423|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司郫县红光西华大学支行',
        value: '102651005466|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都盐市口支行长庆路分理处',
        value: '102651005554|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都交大路支行',
        value: '102651005562|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都金牛支行群星路分理处',
        value: '102651005595|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都温江支行德通桥分理处',
        value: '102651005634|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都滨江东路支行',
        value: '102651006104|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都成飞大道支行',
        value: '102651007020|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都培根路支行',
        value: '102651007038|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都东风路支行',
        value: '102651007046|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都春熙支行静康路分理处',
        value: '102651007079|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司四川省成都市四川大学支行',
        value: '102651007126|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都芷泉支行长寿路分理处',
        value: '102651007134|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都春熙支行晨晖路分理处',
        value: '102651007191|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都洪河大道支行',
        value: '102651007206|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都东大支行锦华路分理处',
        value: '102651007222|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都锦东路支行',
        value: '102651007239|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都光华村支行',
        value: '102651007425|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都建机厂支行',
        value: '102651007829|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都桐梓林北街支行',
        value: '102651008006|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都致顺路支行',
        value: '102651008063|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都李家沱支行',
        value: '102651008071|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司临沂城西支行',
        value: '102473000077|3713|山东省|临沂市'
      },
      {
        label: '中国工商银行股份有限公司临沂金雀山支行',
        value: '102473000085|3713|山东省|临沂市'
      },
      {
        label: '中国工商银行股份有限公司临沂城西支行商城分理处',
        value: '102473000093|3713|山东省|临沂市'
      },
      {
        label: '中国工商银行股份有限公司临沂罗庄支行',
        value: '102473000108|3713|山东省|临沂市'
      },
      {
        label: '中国工商银行股份有限公司临沂河东支行',
        value: '102473000116|3713|山东省|临沂市'
      },
      {
        label: '中国工商银行股份有限公司淮安楚州开发区支行',
        value: '102308007030|3208|江苏省|淮安市'
      },
      {
        label: '中国工商银行淮安市河下分理处',
        value: '102308007048|3208|江苏省|淮安市'
      },
      {
        label: '中国工商银行股份有限公司淮安楚东支行',
        value: '102308007520|3208|江苏省|淮安市'
      },
      {
        label: '中国工商银行股份有限公司淮安楚北分理处',
        value: '102308007546|3208|江苏省|淮安市'
      },
      {
        label: '中国工商银行灌南支行',
        value: '102308209503|3208|江苏省|淮安市'
      },
      {
        label: '中国工商银行灌南商厦支行',
        value: '102308209511|3208|江苏省|淮安市'
      },
      {
        label: '中国工商银行股份有限公司灌南海西支行',
        value: '102308209520|3208|江苏省|淮安市'
      },
      {
        label: '中国工商银行股份有限公司沭阳开发区支行',
        value: '102308300076|3208|江苏省|淮安市'
      },
      {
        label: '中国工商银行沭阳县支行营业部',
        value: '102308306005|3208|江苏省|淮安市'
      },
      {
        label: '中国工商银行沭阳县上海路支行',
        value: '102308306013|3208|江苏省|淮安市'
      },
      {
        label: '中国工商银行沭阳县人民路支行',
        value: '102308306021|3208|江苏省|淮安市'
      },
      {
        label: '中国工商银行股份有限公司沭阳北京路支行',
        value: '102308306048|3208|江苏省|淮安市'
      },
      {
        label: '中国工商银行股份有限公司沭阳南京路支行',
        value: '102308306056|3208|江苏省|淮安市'
      },
      {
        label: '中国工商银行股份有限公司南通志浩支行',
        value: '102308403901|3206|江苏省|南通市'
      },
      {
        label: '中国工商银行股份有限公司南通三余支行',
        value: '102308442512|3206|江苏省|南通市'
      },
      {
        label: '中国工商银行股份有限公司南通二甲支行',
        value: '102308442529|3206|江苏省|南通市'
      },
      {
        label: '中国工商银行股份有限公司南通石港支行',
        value: '102308442537|3206|江苏省|南通市'
      },
      {
        label: '中国工商银行股份有限公司南通姜灶支行',
        value: '102308442553|3206|江苏省|南通市'
      },
      {
        label: '中国工商银行郑州市未来支行',
        value: '102491002298|4101|河南省|郑州市'
      },
      {
        label: '中国工商银行巩义市支行',
        value: '102491002319|4101|河南省|郑州市'
      },
      {
        label: '中国工商银行新郑市支行',
        value: '102491002417|4101|河南省|郑州市'
      },
      {
        label: '中国工商银行新密市支行',
        value: '102491002513|4101|河南省|郑州市'
      },
      {
        label: '中国工商银行股份有限公司济源分行',
        value: '102491002556|4101|河南省|郑州市'
      },
      {
        label: '中国工商银行登封市支行',
        value: '102491002610|4101|河南省|郑州市'
      },
      {
        label: '中国工商银行股份有限公司河南省分行业务处理中心',
        value: '102491002685|4101|河南省|郑州市'
      },
      {
        label: '中国工商银行中牟县支行',
        value: '102491002708|4101|河南省|郑州市'
      },
      {
        label: '中国工商银行郑州市二七路支行',
        value: '102491002812|4101|河南省|郑州市'
      },
      {
        label: '中国工商银行河南省分行营业部',
        value: '102491002907|4101|河南省|郑州市'
      },
      {
        label: '中国工商银行股份有限公司绍兴福全支行',
        value: '102337104204|3306|浙江省|绍兴市'
      },
      {
        label: '中国工商银行股份有限公司无锡天一支行',
        value: '102302001820|3202|江苏省|无锡市'
      },
      {
        label: '中国工商银行股份有限公司钦州分行',
        value: '102631154500|4507|广西壮族自治区|钦州市'
      },
      {
        label: '中国工商银行股份有限公司福州龙津支行',
        value: '102391052644|3501|福建省|福州市'
      },
      {
        label: '中国工商银行股份有限公司福州对湖支行',
        value: '102391052669|3501|福建省|福州市'
      },
      {
        label: '中国工商银行福州上渡支行',
        value: '102391052677|3501|福建省|福州市'
      },
      {
        label: '中国工商银行股份有限公司仓山支行红星储蓄所',
        value: '102391052685|3501|福建省|福州市'
      },
      {
        label: '中国工商银行股份有限公司蚌埠光彩支行',
        value: '102363000800|3403|安徽省|蚌埠市'
      },
      {
        label: '中国工商银行股份有限公司蚌埠财大支行',
        value: '102363020222|3403|安徽省|蚌埠市'
      },
      {
        label: '中国工商银行股份有限公司蚌埠体育路支行',
        value: '102363020247|3403|安徽省|蚌埠市'
      },
      {
        label: '中国工商银行蚌埠中荣街支行',
        value: '102363020255|3403|安徽省|蚌埠市'
      },
      {
        label: '中国工商银行股份有限公司蚌埠烟厂支行',
        value: '102363020271|3403|安徽省|蚌埠市'
      },
      {
        label: '中国工商银行股份有限公司蚌埠新二马路支行',
        value: '102363020298|3403|安徽省|蚌埠市'
      },
      {
        label: '中国工商银行蚌埠治淮路支行',
        value: '102363021217|3403|安徽省|蚌埠市'
      },
      {
        label: '中国工商银行蚌埠胜利路支行',
        value: '102363021233|3403|安徽省|蚌埠市'
      },
      {
        label: '中国工商银行股份有限公司蚌埠延安路支行',
        value: '102363021268|3403|安徽省|蚌埠市'
      },
      {
        label: '中国工商银行股份有限公司蚌埠淮上支行',
        value: '102363022244|3403|安徽省|蚌埠市'
      },
      {
        label: '中国工商银行蚌埠大庆路支行',
        value: '102363023204|3403|安徽省|蚌埠市'
      },
      {
        label: '中国工商银行蚌埠张公山支行',
        value: '102363023212|3403|安徽省|蚌埠市'
      },
      {
        label: '中国工商银行蚌埠宏业村支行',
        value: '102363024220|3403|安徽省|蚌埠市'
      },
      {
        label: '中国工商银行股份有限公司蚌埠解放路支行',
        value: '102363024262|3403|安徽省|蚌埠市'
      },
      {
        label: '中国工商银行蚌埠龙湖香都支行',
        value: '102363024279|3403|安徽省|蚌埠市'
      },
      {
        label: '中国工商银行蚌埠工农路支行',
        value: '102363025200|3403|安徽省|蚌埠市'
      },
      {
        label: '中国工商银行股份有限公司蚌埠万达广场支行',
        value: '102363025218|3403|安徽省|蚌埠市'
      },
      {
        label: '中国工商银行股份有限公司蚌埠高新支行',
        value: '102363025226|3403|安徽省|蚌埠市'
      },
      {
        label: '中国工商银行股份有限公司蚌埠珠城支行',
        value: '102363025234|3403|安徽省|蚌埠市'
      },
      {
        label: '中国工商银行股份有限公司怀远支行',
        value: '102363100907|3403|安徽省|蚌埠市'
      },
      {
        label: '中国工商银行怀远大禹支行',
        value: '102363123230|3403|安徽省|蚌埠市'
      },
      {
        label: '中国工商银行怀远乳泉支行',
        value: '102363126206|3403|安徽省|蚌埠市'
      },
      {
        label: '中国工商银行股份有限公司五河支行',
        value: '102363201007|3403|安徽省|蚌埠市'
      },
      {
        label: '中国工商银行五河淮河路支行',
        value: '102363227226|3403|安徽省|蚌埠市'
      },
      {
        label: '中国工商银行股份有限公司固镇浍河路支行',
        value: '102363300013|3403|安徽省|蚌埠市'
      },
      {
        label: '中国工商银行股份有限公司固镇支行',
        value: '102363301104|3403|安徽省|蚌埠市'
      },
      {
        label: '中国工商银行淮南分行业务处理中心',
        value: '102364000203|3404|安徽省|淮南市'
      },
      {
        label: '中国工商银行股份有限公司淮南舜耕支行',
        value: '102364000220|3404|安徽省|淮南市'
      },
      {
        label: '中国工商银行邵武水北支行',
        value: '102401273024|3507|福建省|南平市'
      },
      {
        label: '中国工商银行邵武熙春支行',
        value: '102401273049|3507|福建省|南平市'
      },
      {
        label: '中国工商银行股份有限公司邵武李纲支行',
        value: '102401273090|3507|福建省|南平市'
      },
      {
        label: '中国工商银行顺昌县支行',
        value: '102401375000|3507|福建省|南平市'
      },
      {
        label: '中国工商银行股份有限公司顺昌城关支行',
        value: '102401375034|3507|福建省|南平市'
      },
      {
        label: '中国工商银行建阳市支行',
        value: '102401474008|3507|福建省|南平市'
      },
      {
        label: '中国工商银行建阳童游支行',
        value: '102401474024|3507|福建省|南平市'
      },
      {
        label: '中国工商银行股份有限公司建阳水南支行',
        value: '102401474049|3507|福建省|南平市'
      },
      {
        label: '中国工商银行建瓯市支行',
        value: '102401576007|3507|福建省|南平市'
      },
      {
        label: '中国工商银行建瓯芝山支行',
        value: '102401576015|3507|福建省|南平市'
      },
      {
        label: '中国工商银行股份有限公司建瓯公园口支行',
        value: '102401576074|3507|福建省|南平市'
      },
      {
        label: '中国工商银行浦城县支行',
        value: '102401677008|3507|福建省|南平市'
      },
      {
        label: '中国工商银行浦城新华支行',
        value: '102401677016|3507|福建省|南平市'
      },
      {
        label: '中国工商银行股份有限公司光泽支行',
        value: '102401868004|3507|福建省|南平市'
      },
      {
        label: '中国工商银行松溪县支行',
        value: '102401979009|3507|福建省|南平市'
      },
      {
        label: '中国工商银行股份有限公司政和支行',
        value: '102402103069|3507|福建省|南平市'
      },
      {
        label: '中国工商银行股份有限公司武夷山崇安支行',
        value: '102402203248|3507|福建省|南平市'
      },
      {
        label: '中国工商银行武夷山度假区支行',
        value: '102402278019|3507|福建省|南平市'
      },
      {
        label: '中国工商银行宁德分行业务处理中心',
        value: '102403080003|3509|福建省|宁德市'
      },
      {
        label: '中国工商银行蕉城支行',
        value: '102403081006|3509|福建省|宁德市'
      },
      {
        label: '中国工商银行宁德大众支行',
        value: '102403081039|3509|福建省|宁德市'
      },
      {
        label: '中国工商银行宁德东侨支行',
        value: '102403081047|3509|福建省|宁德市'
      },
      {
        label: '中国工商银行股份有限公司宁德南路支行',
        value: '102403081055|3509|福建省|宁德市'
      },
      {
        label: '中国工商银行本溪市分行本钢支行南芬分理处',
        value: '102225000324|2105|辽宁省|本溪市'
      },
      {
        label: '中国工商银行股份有限公司灌云伊山路支行',
        value: '102307309061|3207|江苏省|连云港市'
      },
      {
        label: '中国工商银行股份有限公司灌云南京路支行',
        value: '102307309070|3207|江苏省|连云港市'
      },
      {
        label: '中国工商银行淮安市城中支行',
        value: '102308001017|3208|江苏省|淮安市'
      },
      {
        label: '中国工商银行淮安淮海支行',
        value: '102308001025|3208|江苏省|淮安市'
      },
      {
        label: '中国工商银行股份有限公司淮安淮海西路支行',
        value: '102308001033|3208|江苏省|淮安市'
      },
      {
        label: '中国工商银行淮安市和平分理处',
        value: '102308001041|3208|江苏省|淮安市'
      },
      {
        label: '中国工商银行股份有限公司淮安健康路支行',
        value: '102308001050|3208|江苏省|淮安市'
      },
      {
        label: '中国工商银行股份有限公司淮安生态新城支行',
        value: '102308001076|3208|江苏省|淮安市'
      },
      {
        label: '中国工商银行淮安市城南支行',
        value: '102308002010|3208|江苏省|淮安市'
      },
      {
        label: '中国工商银行淮安解放支行',
        value: '102308002028|3208|江苏省|淮安市'
      },
      {
        label: '中国工商银行淮安城西支行',
        value: '102308002036|3208|江苏省|淮安市'
      },
      {
        label: '中国工商银行股份有限公司武穴正街支行',
        value: '102533210171|4211|湖北省|黄冈市'
      },
      {
        label: '中国工商银行武穴市支行营业部',
        value: '102533210251|4211|湖北省|黄冈市'
      },
      {
        label: '中国工商银行股份有限公司武穴融兴支行',
        value: '102533210501|4211|湖北省|黄冈市'
      },
      {
        label: '中国工商银行股份有限公司武穴梅川支行',
        value: '102533210510|4211|湖北省|黄冈市'
      },
      {
        label: '中国工商银行股份有限公司黄冈团风支行',
        value: '102533310047|4211|湖北省|黄冈市'
      },
      {
        label: '中国工商银行红安县支行营业部',
        value: '102533410052|4211|湖北省|黄冈市'
      },
      {
        label: '中国工商银行股份有限公司中山板芙深湾支行',
        value: '102603000200|4420|广东省|中山市'
      },
      {
        label: '中国工商银行股份有限公司中山香山支行',
        value: '102603000218|4420|广东省|中山市'
      },
      {
        label: '中国工商银行股份有限公司中山小榄支行',
        value: '102603000226|4420|广东省|中山市'
      },
      {
        label: '中国工商银行股份有限公司中山石岐支行',
        value: '102603000242|4420|广东省|中山市'
      },
      {
        label: '中国工商银行股份有限公司中山分行',
        value: '102603000259|4420|广东省|中山市'
      },
      {
        label: '中国工商银行股份有限公司中山南朗支行',
        value: '102603000291|4420|广东省|中山市'
      },
      {
        label: '中国工商银行股份有限公司中山南朗美景支行',
        value: '102603000306|4420|广东省|中山市'
      },
      {
        label: '中国工商银行股份有限公司中山银海支行',
        value: '102603000314|4420|广东省|中山市'
      },
      {
        label: '中国工商银行股份有限公司中山东升东华支行',
        value: '102603000339|4420|广东省|中山市'
      },
      {
        label: '中国工商银行股份有限公司中山张家边东镇支行',
        value: '102603000347|4420|广东省|中山市'
      },
      {
        label: '中国工商银行股份有限公司中山大涌银峰支行',
        value: '102603000371|4420|广东省|中山市'
      },
      {
        label: '中国工商银行股份有限公司中山沙朗支行',
        value: '102603000380|4420|广东省|中山市'
      },
      {
        label: '中国工商银行股份有限公司中山银通支行',
        value: '102603000398|4420|广东省|中山市'
      },
      {
        label: '中国工商银行股份有限公司中山小榄梅花洞支行',
        value: '102603000402|4420|广东省|中山市'
      },
      {
        label: '中国工商银行股份有限公司中山水云轩支行',
        value: '102603000427|4420|广东省|中山市'
      },
      {
        label: '中国工商银行股份有限公司中山三角支行',
        value: '102603000443|4420|广东省|中山市'
      },
      {
        label: '中国工商银行股份有限公司中山坦洲中澳支行',
        value: '102603000451|4420|广东省|中山市'
      },
      {
        label: '中国工商银行股份有限公司中山小榄泰丰支行',
        value: '102603000486|4420|广东省|中山市'
      },
      {
        label: '中国工商银行股份有限公司中山小榄九洲基支行',
        value: '102603000494|4420|广东省|中山市'
      },
      {
        label: '中国工商银行股份有限公司中山古镇东兴支行',
        value: '102603000592|4420|广东省|中山市'
      },
      {
        label: '中国工商银行股份有限公司中山东凤同安支行',
        value: '102603001114|4420|广东省|中山市'
      },
      {
        label: '中国工商银行股份有限公司中山沙溪宝珠支行',
        value: '102603001341|4420|广东省|中山市'
      },
      {
        label: '中国工商银行股份有限公司中山沙溪星宝支行',
        value: '102603001421|4420|广东省|中山市'
      },
      {
        label: '中国工商银行股份有限公司中山宏基路支行',
        value: '102603001456|4420|广东省|中山市'
      },
      {
        label: '中国工商银行股份有限公司中山松苑支行',
        value: '102603001464|4420|广东省|中山市'
      },
      {
        label: '中国工商银行股份有限公司中山东苑支行',
        value: '102603001729|4420|广东省|中山市'
      },
      {
        label: '中国工商银行股份有限公司中山东凤小沥支行',
        value: '102603001761|4420|广东省|中山市'
      },
      {
        label: '中国工商银行股份有限公司驻马店金山支行',
        value: '102511001623|4117|河南省|驻马店市'
      },
      {
        label: '中国工商银行驻马店分行乐山路支行',
        value: '102511002030|4117|河南省|驻马店市'
      },
      {
        label: '中国工商银行驻马店分行',
        value: '102511002503|4117|河南省|驻马店市'
      },
      {
        label: '中国工商银行驻马店分行解放路支行',
        value: '102511002595|4117|河南省|驻马店市'
      },
      {
        label: '中国工商银行股份有限公司驻马店学院支行',
        value: '102511002600|4117|河南省|驻马店市'
      },
      {
        label: '中国工商银行股份有限公司驻马店开源支行',
        value: '102511002618|4117|河南省|驻马店市'
      },
      {
        label: '中国工商银行股份有限公司驻马店广通支行',
        value: '102511002667|4117|河南省|驻马店市'
      },
      {
        label: '中国工商银行股份有限公司襄阳火车站支行',
        value: '102528000282|4206|湖北省|襄阳市'
      },
      {
        label: '中国工商银行股份有限公司襄阳樊西支行',
        value: '102528000303|4206|湖北省|襄阳市'
      },
      {
        label: '中国工商银行股份有限公司襄阳民发商业广场支行',
        value: '102528000311|4206|湖北省|襄阳市'
      },
      {
        label: '中国工商银行股份有限公司襄阳建华路支行',
        value: '102528000320|4206|湖北省|襄阳市'
      },
      {
        label: '中国工商银行股份有限公司襄阳长虹路支行',
        value: '102528000338|4206|湖北省|襄阳市'
      },
      {
        label: '中国工商银行股份有限公司襄阳泰跃朝阳支行',
        value: '102528000354|4206|湖北省|襄阳市'
      },
      {
        label: '中国工商银行股份有限公司襄阳解放桥支行',
        value: '102528000362|4206|湖北省|襄阳市'
      },
      {
        label: '中国工商银行股份有限公司襄阳朝阳路支行',
        value: '102528000379|4206|湖北省|襄阳市'
      },
      {
        label: '中国工商银行股份有限公司襄阳虹桥支行',
        value: '102528000400|4206|湖北省|襄阳市'
      },
      {
        label: '中国工商银行股份有限公司襄阳人民公园支行',
        value: '102528000418|4206|湖北省|襄阳市'
      },
      {
        label: '中国工商银行股份有限公司襄阳高新技术产业支行',
        value: '102528000426|4206|湖北省|襄阳市'
      },
      {
        label: '中国工商银行股份有限公司襄阳车城支行',
        value: '102528000434|4206|湖北省|襄阳市'
      },
      {
        label: '中国工商银行股份有限公司襄阳经济技术开发区支行',
        value: '102528000442|4206|湖北省|襄阳市'
      },
      {
        label: '中国工商银行股份有限公司襄阳科技开发支行',
        value: '102528000459|4206|湖北省|襄阳市'
      },
      {
        label: '中国工商银行股份有限公司襄阳万达广场支行',
        value: '102528000467|4206|湖北省|襄阳市'
      },
      {
        label: '中国工商银行股份有限公司襄阳军纺支行',
        value: '102528000475|4206|湖北省|襄阳市'
      },
      {
        label: '中国工商银行股份有限公司襄阳襄州支行',
        value: '102528000539|4206|湖北省|襄阳市'
      },
      {
        label: '中国工商银行股份有限公司襄阳化纤支行',
        value: '102528000547|4206|湖北省|襄阳市'
      },
      {
        label: '中国工商银行股份有限公司襄阳光彩支行',
        value: '102528000563|4206|湖北省|襄阳市'
      },
      {
        label: '中国工商银行股份有限公司襄阳鹿门支行',
        value: '102528000580|4206|湖北省|襄阳市'
      },
      {
        label: '中国工商银行股份有限公司襄阳分行',
        value: '102528000619|4206|湖北省|襄阳市'
      },
      {
        label: '中国工商银行股份有限公司孝感三江支行',
        value: '102535000047|4209|湖北省|孝感市'
      },
      {
        label: '中国工商银行荆州市荆州支行长江大学分理处',
        value: '102537010423|4210|湖北省|荆州市'
      },
      {
        label: '中国工商银行广州麓湖路支行',
        value: '102581000440|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州沙园支行',
        value: '102581000458|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州江南大道中支行',
        value: '102581000466|4401|广东省|广州市'
      },
      {
        label: '中国工商银行广州逢源路支行',
        value: '102581000474|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州宝华支行',
        value: '102581000482|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州金港支行',
        value: '102581000503|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州石化支行',
        value: '102581000511|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州员村支行',
        value: '102581000538|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司鄂州葛店支行',
        value: '102531000058|4207|湖北省|鄂州市'
      },
      {
        label: '中国工商银行股份有限公司鄂州葛店开发区支行',
        value: '102531000066|4207|湖北省|鄂州市'
      },
      {
        label: '中国工商银行鄂州文峰支行',
        value: '102531000074|4207|湖北省|鄂州市'
      },
      {
        label: '中国工商银行股份有限公司鄂州牡丹支行',
        value: '102531000111|4207|湖北省|鄂州市'
      },
      {
        label: '中国工商银行鄂州虹桥支行',
        value: '102531000154|4207|湖北省|鄂州市'
      },
      {
        label: '中国工商银行股份有限公司鄂州鄂东支行',
        value: '102531000162|4207|湖北省|鄂州市'
      },
      {
        label: '中国工商银行股份有限公司信阳南湖路支行',
        value: '102515042140|4115|河南省|信阳市'
      },
      {
        label: '中国工商银行股份有限公司信阳河南路支行',
        value: '102515072118|4115|河南省|信阳市'
      },
      {
        label: '中国工商银行信阳分行息县支行',
        value: '102515202220|4115|河南省|信阳市'
      },
      {
        label: '中国工商银行股份有限公司息县东城支行',
        value: '102515232222|4115|河南省|信阳市'
      },
      {
        label: '中国工商银行信阳分行淮滨县支行',
        value: '102515302265|4115|河南省|信阳市'
      },
      {
        label: '中国工商银行股份有限公司淮滨南大街支行',
        value: '102515312263|4115|河南省|信阳市'
      },
      {
        label: '中国工商银行信阳分行潢川县支行',
        value: '102515502240|4115|河南省|信阳市'
      },
      {
        label: '中国工商银行股份有限公司潢川航空路支行',
        value: '102515532246|4115|河南省|信阳市'
      },
      {
        label: '中国工商银行信阳分行光山县支行',
        value: '102515602236|4115|河南省|信阳市'
      },
      {
        label: '中国工商银行股份有限公司光山东城支行',
        value: '102515612238|4115|河南省|信阳市'
      },
      {
        label: '中国工商银行信阳分行固始县支行',
        value: '102515702294|4115|河南省|信阳市'
      },
      {
        label: '中国工商银行股份有限公司固始淮河路支行',
        value: '102515712290|4115|河南省|信阳市'
      },
      {
        label: '中国工商银行股份有限公司固始蓼城大道支行',
        value: '102515742291|4115|河南省|信阳市'
      },
      {
        label: '中国工商银行信阳分行商城县支行',
        value: '102515802280|4115|河南省|信阳市'
      },
      {
        label: '中国工商银行股份有限公司商城崇福支行',
        value: '102515832289|4115|河南省|信阳市'
      },
      {
        label: '中国工商银行股份有限公司罗山行政路支行',
        value: '102515901228|4115|河南省|信阳市'
      },
      {
        label: '中国工商银行股份有限公司恩施城区支行',
        value: '102541000027|4228|湖北省|恩施土家族苗族自治州'
      },
      {
        label: '中国工商银行股份有限公司恩施分行营业部',
        value: '102541000035|4228|湖北省|恩施土家族苗族自治州'
      },
      {
        label: '中国工商银行股份有限公司恩施解放路支行',
        value: '102541000043|4228|湖北省|恩施土家族苗族自治州'
      },
      {
        label: '中国工商银行恩施机场路支行',
        value: '102541000060|4228|湖北省|恩施土家族苗族自治州'
      },
      {
        label: '中国工商银行恩施临江支行',
        value: '102541000125|4228|湖北省|恩施土家族苗族自治州'
      },
      {
        label: '中国工商银行股份有限公司恩施学院路支行',
        value: '102541000192|4228|湖北省|恩施土家族苗族自治州'
      },
      {
        label: '中国工商银行恩施州分行业务处理中心',
        value: '102541001304|4228|湖北省|恩施土家族苗族自治州'
      },
      {
        label: '中国工商银行股份有限公司恩施硒都支行',
        value: '102541005033|4228|湖北省|恩施土家族苗族自治州'
      },
      {
        label: '中国工商银行股份有限公司利川支行',
        value: '102541200088|4228|湖北省|恩施土家族苗族自治州'
      },
      {
        label: '中国工商银行股份有限公司利川关东支行',
        value: '102541200238|4228|湖北省|恩施土家族苗族自治州'
      },
      {
        label: '中国工商银行股份有限公司利川利北路支行',
        value: '102541200246|4228|湖北省|恩施土家族苗族自治州'
      },
      {
        label: '中国工商银行股份有限公司利川解放路支行',
        value: '102541205057|4228|湖北省|恩施土家族苗族自治州'
      },
      {
        label: '中国工商银行股份有限公司建始支行',
        value: '102541300072|4228|湖北省|恩施土家族苗族自治州'
      },
      {
        label: '中国工商银行巴东支行',
        value: '102541407136|4228|湖北省|恩施土家族苗族自治州'
      },
      {
        label: '中国工商银行宣恩支行',
        value: '102541500200|4228|湖北省|恩施土家族苗族自治州'
      },
      {
        label: '中国工商银行咸丰支行',
        value: '102541600104|4228|湖北省|恩施土家族苗族自治州'
      },
      {
        label: '中国工商银行股份有限公司来凤支行',
        value: '102541700092|4228|湖北省|恩施土家族苗族自治州'
      },
      {
        label: '中国工商银行股份有限公司来凤凤翔支行',
        value: '102541705043|4228|湖北省|恩施土家族苗族自治州'
      },
      {
        label: '中国工商银行鹤峰支行',
        value: '102541800114|4228|湖北省|恩施土家族苗族自治州'
      },
      {
        label: '中国工商银行股份有限公司长沙分行',
        value: '102551000012|4301|湖南省|长沙市'
      },
      {
        label: '中国工商银行长沙市五一路支行',
        value: '102551000029|4301|湖南省|长沙市'
      },
      {
        label: '中国工商银行股份有限公司长沙黄兴南路支行',
        value: '102551000037|4301|湖南省|长沙市'
      },
      {
        label: '中国工商银行股份有限公司长沙芙蓉中路支行',
        value: '102551000053|4301|湖南省|长沙市'
      },
      {
        label: '中国工商银行长沙市中山路支行',
        value: '102551000070|4301|湖南省|长沙市'
      },
      {
        label: '中国工商银行股份有限公司长沙松桂园支行',
        value: '102551000088|4301|湖南省|长沙市'
      },
      {
        label: '中国工商银行股份有限公司长沙远大路支行',
        value: '102551000096|4301|湖南省|长沙市'
      },
      {
        label: '中国工商银行股份有限公司长沙展览馆路支行',
        value: '102551000107|4301|湖南省|长沙市'
      },
      {
        label: '中国工商银行股份有限公司当阳坝陵支行',
        value: '102526201700|4205|湖北省|宜昌市'
      },
      {
        label: '中国工商银行当阳支行玉阳分理处',
        value: '102526201726|4205|湖北省|宜昌市'
      },
      {
        label: '中国工商银行股份有限公司当阳玉泉支行',
        value: '102526201742|4205|湖北省|宜昌市'
      },
      {
        label: '中国工商银行股份有限公司当阳航空路支行',
        value: '102526201759|4205|湖北省|宜昌市'
      },
      {
        label: '中国工商银行三峡分行当阳市支行',
        value: '102526207072|4205|湖北省|宜昌市'
      },
      {
        label: '中国工商银行股份有限公司枝江礼化支行',
        value: '102526401327|4205|湖北省|宜昌市'
      },
      {
        label: '中国工商银行枝江支行城南分理处',
        value: '102526401394|4205|湖北省|宜昌市'
      },
      {
        label: '中国工商银行股份有限公司钦州市向阳支行',
        value: '102631159005|4507|广西壮族自治区|钦州市'
      },
      {
        label: '中国工商银行钦州港支行',
        value: '102631159013|4507|广西壮族自治区|钦州市'
      },
      {
        label: '中国工商银行上思县支行',
        value: '102631256501|4507|广西壮族自治区|钦州市'
      },
      {
        label: '中国工商银行灵山县支行',
        value: '102631458000|4507|广西壮族自治区|钦州市'
      },
      {
        label: '中国工商银行浦北县支行',
        value: '102631558508|4507|广西壮族自治区|钦州市'
      },
      {
        label: '中国工商银行股份有限公司防城港分行',
        value: '102633054009|4508|广西壮族自治区|贵港市'
      },
      {
        label: '中国工商银行股份有限公司防城港市港口支行',
        value: '102633057007|4508|广西壮族自治区|贵港市'
      },
      {
        label: '中国工商银行防城港分行教育路支行',
        value: '102633057015|4508|广西壮族自治区|贵港市'
      },
      {
        label: '中国工商银行防城港市金港支行',
        value: '102633057023|4508|广西壮族自治区|贵港市'
      },
      {
        label: '中国工商银行防城支行',
        value: '102633057509|4508|广西壮族自治区|贵港市'
      },
      {
        label: '中国工商银行东兴支行',
        value: '102633159507|4508|广西壮族自治区|贵港市'
      },
      {
        label: '中国工商银行股份有限公司海口龙昆北路支行',
        value: '102641000024|4601|海南省|海口市'
      },
      {
        label: '中国工商银行股份有限公司海南省分行营业部',
        value: '102641000032|4601|海南省|海口市'
      },
      {
        label: '中国工商银行股份有限公司海口文明东路支行',
        value: '102641000049|4601|海南省|海口市'
      },
      {
        label: '中国工商银行股份有限公司海口金盘支行',
        value: '102641000057|4601|海南省|海口市'
      },
      {
        label: '中国工商银行股份有限公司海口金贸支行',
        value: '102641000065|4601|海南省|海口市'
      },
      {
        label: '中国工商银行股份有限公司海口海秀支行',
        value: '102641000073|4601|海南省|海口市'
      },
      {
        label: '中国工商银行股份有限公司海口美舍支行',
        value: '102641000081|4601|海南省|海口市'
      },
      {
        label: '中国工商银行股份有限公司海口望海楼支行',
        value: '102641000090|4601|海南省|海口市'
      },
      {
        label: '中国工商银行股份有限公司海口秀英支行',
        value: '102641000104|4601|海南省|海口市'
      },
      {
        label: '中国工商银行股份有限公司海口新华支行',
        value: '102641000112|4601|海南省|海口市'
      },
      {
        label: '中国工商银行股份有限公司海口和平南支行',
        value: '102641000129|4601|海南省|海口市'
      },
      {
        label: '中国工商银行股份有限公司海口海甸支行',
        value: '102641000137|4601|海南省|海口市'
      },
      {
        label: '中国工商银行股份有限公司海口义龙支行',
        value: '102641000145|4601|海南省|海口市'
      },
      {
        label: '中国工商银行股份有限公司海口龙华路支行',
        value: '102641000153|4601|海南省|海口市'
      },
      {
        label: '中国工商银行股份有限公司海口建国路支行',
        value: '102641000170|4601|海南省|海口市'
      },
      {
        label: '中国工商银行股份有限公司黄石交通路支行',
        value: '102522011041|4202|湖北省|黄石市'
      },
      {
        label: '中国工商银行文成县支行',
        value: '102333728607|3303|浙江省|温州市'
      },
      {
        label: '中国工商银行股份有限公司文成玉壶支行',
        value: '102333728631|3303|浙江省|温州市'
      },
      {
        label: '中国工商银行股份有限公司泰顺支行',
        value: '102333828708|3303|浙江省|温州市'
      },
      {
        label: '中国工商银行瑞安支行南门分理处',
        value: '102333905035|3303|浙江省|温州市'
      },
      {
        label: '中国工商银行瑞安支行北门分理处',
        value: '102333905060|3303|浙江省|温州市'
      },
      {
        label: '中国工商银行瑞安市支行兴隆分理处',
        value: '102333905094|3303|浙江省|温州市'
      },
      {
        label: '中国工商银行瑞安市支行新街分理处',
        value: '102333905125|3303|浙江省|温州市'
      },
      {
        label: '中国工商银行股份有限公司瑞安鲍田支行',
        value: '102333905168|3303|浙江省|温州市'
      },
      {
        label: '中国工商银行股份有限公司瑞安汀田支行',
        value: '102333905184|3303|浙江省|温州市'
      },
      {
        label: '中国工商银行股份有限公司瑞安马屿支行',
        value: '102333905971|3303|浙江省|温州市'
      },
      {
        label: '中国工商银行股份有限公司瑞安仙降支行',
        value: '102333928012|3303|浙江省|温州市'
      },
      {
        label: '中国工商银行股份有限公司瑞安罗凤支行',
        value: '102333928029|3303|浙江省|温州市'
      },
      {
        label: '中国工商银行股份有限公司瑞安新区支行',
        value: '102333928045|3303|浙江省|温州市'
      },
      {
        label: '中国工商银行股份有限公司瑞安支行',
        value: '102333928107|3303|浙江省|温州市'
      },
      {
        label: '中国工商银行股份有限公司瑞安安阳支行',
        value: '102333928115|3303|浙江省|温州市'
      },
      {
        label: '中国工商银行温州瑞安塘下支行',
        value: '102333928123|3303|浙江省|温州市'
      },
      {
        label: '中国工商银行温州瑞安莘塍支行',
        value: '102333928140|3303|浙江省|温州市'
      },
      {
        label: '中国工商银行股份有限公司瑞安虹桥支行',
        value: '102333928158|3303|浙江省|温州市'
      },
      {
        label: '中国工商银行股份有限公司瑞安飞云支行',
        value: '102333928166|3303|浙江省|温州市'
      },
      {
        label: '中国工商银行股份有限公司瑞安长虹支行',
        value: '102333928174|3303|浙江省|温州市'
      },
      {
        label: '中国工商银行股份有限公司瑞安广场支行',
        value: '102333928199|3303|浙江省|温州市'
      },
      {
        label: '中国工商银行嘉兴市分行',
        value: '102335005007|3304|浙江省|嘉兴市'
      },
      {
        label: '中国工商银行嘉兴市分行营业部',
        value: '102335006000|3304|浙江省|嘉兴市'
      },
      {
        label: '中国工商银行股份有限公司嘉兴兴禾支行',
        value: '102335006202|3304|浙江省|嘉兴市'
      },
      {
        label: '中国工商银行股份有限公司嘉兴高新支行',
        value: '102335006405|3304|浙江省|嘉兴市'
      },
      {
        label: '中国工商银行股份有限公司嘉兴东升支行',
        value: '102335006421|3304|浙江省|嘉兴市'
      },
      {
        label: '中国工商银行股份有限公司嘉兴北京城支行',
        value: '102335006430|3304|浙江省|嘉兴市'
      },
      {
        label: '中国工商银行股份有限公司嘉兴西马桥支行',
        value: '102335006448|3304|浙江省|嘉兴市'
      },
      {
        label: '中国工商银行股份有限公司嘉兴余新支行',
        value: '102335006456|3304|浙江省|嘉兴市'
      },
      {
        label: '中国工商银行股份有限公司嘉兴秀城支行',
        value: '102335006501|3304|浙江省|嘉兴市'
      },
      {
        label: '中国工商银行股份有限公司嘉兴南湖支行',
        value: '102335006608|3304|浙江省|嘉兴市'
      },
      {
        label: '中国工商银行股份有限公司嘉兴东门支行',
        value: '102335006616|3304|浙江省|嘉兴市'
      },
      {
        label: '中国工商银行股份有限公司嘉兴南溪路支行',
        value: '102335006624|3304|浙江省|嘉兴市'
      },
      {
        label: '中国工商银行股份有限公司嘉兴陈省身支行',
        value: '102335006632|3304|浙江省|嘉兴市'
      },
      {
        label: '中国工商银行股份有限公司嘉兴禾兴中路支行',
        value: '102335006665|3304|浙江省|嘉兴市'
      },
      {
        label: '中国工商银行股份有限公司嘉兴洪合支行',
        value: '102335006673|3304|浙江省|嘉兴市'
      },
      {
        label: '中国工商银行股份有限公司嘉兴东塔支行',
        value: '102335006681|3304|浙江省|嘉兴市'
      },
      {
        label: '中国工商银行股份有限公司平阳水头支行',
        value: '102333528330|3303|浙江省|温州市'
      },
      {
        label: '中国工商银行惠东县黄埠支行',
        value: '102595302435|4413|广东省|惠州市'
      },
      {
        label: '中国工商银行股份有限公司惠东平海支行',
        value: '102595302460|4413|广东省|惠州市'
      },
      {
        label: '中国工商银行股份有限公司惠东吉隆支行',
        value: '102595303317|4413|广东省|惠州市'
      },
      {
        label: '中国工商银行龙门县支行',
        value: '102595402563|4413|广东省|惠州市'
      },
      {
        label: '中国工商银行惠州市滨海支行',
        value: '102595602278|4413|广东省|惠州市'
      },
      {
        label: '中国工商银行惠州大亚湾西区分理处',
        value: '102595602294|4413|广东省|惠州市'
      },
      {
        label: '中国工商银行惠州市大亚湾支行',
        value: '102595602317|4413|广东省|惠州市'
      },
      {
        label: '中国工商银行惠州市石化区支行',
        value: '102595602456|4413|广东省|惠州市'
      },
      {
        label: '中国工商银行股份有限公司梅县支行',
        value: '102596002013|4414|广东省|梅州市'
      },
      {
        label: '中国工商银行股份有限公司博爱中山路支行',
        value: '102501212381|4108|河南省|焦作市'
      },
      {
        label: '中国工商银行焦作分行武陟县支行',
        value: '102501302480|4108|河南省|焦作市'
      },
      {
        label: '中国工商银行股份有限公司武陟兴华路支行',
        value: '102501322482|4108|河南省|焦作市'
      },
      {
        label: '中国工商银行焦作分行沁阳市支行',
        value: '102501402587|4108|河南省|焦作市'
      },
      {
        label: '中国工商银行股份有限公司石首中山路支行',
        value: '102537210627|4210|湖北省|荆州市'
      },
      {
        label: '中国工商银行股份有限公司石首绣林支行',
        value: '102537210635|4210|湖北省|荆州市'
      },
      {
        label: '中国工商银行股份有限公司石首商业城支行',
        value: '102537210651|4210|湖北省|荆州市'
      },
      {
        label: '中国工商银行股份有限公司洪湖新区支行',
        value: '102537302960|4210|湖北省|荆州市'
      },
      {
        label: '中国工商银行股份有限公司洪湖峰口支行',
        value: '102537303149|4210|湖北省|荆州市'
      },
      {
        label: '中国工商银行荆州市分行洪湖市支行',
        value: '102537310734|4210|湖北省|荆州市'
      },
      {
        label: '中国工商银行股份有限公司洪湖三桥支行',
        value: '102537310775|4210|湖北省|荆州市'
      },
      {
        label: '中国工商银行股份有限公司天门支行陆羽分理处',
        value: '102537409036|4210|湖北省|荆州市'
      },
      {
        label: '中国工商银行股份有限公司天门支行南桥分理处',
        value: '102537409044|4210|湖北省|荆州市'
      },
      {
        label: '中国工商银行天门支行岳口分理处',
        value: '102537409052|4210|湖北省|荆州市'
      },
      {
        label: '中国工商银行股份有限公司天门东城支行',
        value: '102537409077|4210|湖北省|荆州市'
      },
      {
        label: '中国工商银行股份有限公司天门新城支行',
        value: '102537409093|4210|湖北省|荆州市'
      },
      {
        label: '中国工商银行股份有限公司天门支行东湖分理处',
        value: '102537409108|4210|湖北省|荆州市'
      },
      {
        label: '中国工商银行股份有限公司天门中心支行',
        value: '102537409116|4210|湖北省|荆州市'
      },
      {
        label: '中国工商银行股份有限公司天门竟陵支行',
        value: '102537409124|4210|湖北省|荆州市'
      },
      {
        label: '中国工商银行荆州市分行潜江市支行',
        value: '102537510959|4210|湖北省|荆州市'
      },
      {
        label: '中国工商银行荆州市分行潜江市支行广华支行',
        value: '102537510967|4210|湖北省|荆州市'
      },
      {
        label: '中国工商银行股份有限公司潜江广华小区支行',
        value: '102537510991|4210|湖北省|荆州市'
      },
      {
        label: '中国工商银行潜江支行测井分理处',
        value: '102537511009|4210|湖北省|荆州市'
      },
      {
        label: '中国工商银行股份有限公司潜江钻井支行',
        value: '102537511041|4210|湖北省|荆州市'
      },
      {
        label: '中国工商银行股份有限公司潜江局机关支行',
        value: '102537511076|4210|湖北省|荆州市'
      },
      {
        label: '中国工商银行股份有限公司潜江北门支行',
        value: '102537511092|4210|湖北省|荆州市'
      },
      {
        label: '中国工商银行股份有限公司潜江钻头厂支行',
        value: '102537511210|4210|湖北省|荆州市'
      },
      {
        label: '中国工商银行潜江市广华支行油田分理处',
        value: '102537511228|4210|湖北省|荆州市'
      },
      {
        label: '中国工商银行股份有限公司潜江周矶支行',
        value: '102537511236|4210|湖北省|荆州市'
      },
      {
        label: '中国工商银行潜江市广华支行五七分理处',
        value: '102537511252|4210|湖北省|荆州市'
      },
      {
        label: '中国工商银行潜江市广华支行向阳分理处',
        value: '102537511269|4210|湖北省|荆州市'
      },
      {
        label: '中国工商银行股份有限公司潜江广南支行',
        value: '102537511277|4210|湖北省|荆州市'
      },
      {
        label: '中国工商银行潜江市支行园林路分理处',
        value: '102537511285|4210|湖北省|荆州市'
      },
      {
        label: '中国工商银行股份有限公司白城平台支行',
        value: '102247000237|2208|吉林省|白城市'
      },
      {
        label: '中国工商银行股份有限公司白城海明西路支行',
        value: '102247000253|2208|吉林省|白城市'
      },
      {
        label: '中国工商银行白城洮南支行',
        value: '102247200011|2208|吉林省|白城市'
      },
      {
        label: '中国工商银行股份有限公司白城洮南光明支行',
        value: '102247200038|2208|吉林省|白城市'
      },
      {
        label: '中国工商银行扶余支行',
        value: '102247312021|2208|吉林省|白城市'
      },
      {
        label: '中国工商银行扶余三岔河支行',
        value: '102247312030|2208|吉林省|白城市'
      },
      {
        label: '中国工商银行白城大安支行',
        value: '102247400013|2208|吉林省|白城市'
      },
      {
        label: '中国工商银行股份有限公司白城大安嫩江支行',
        value: '102247400021|2208|吉林省|白城市'
      },
      {
        label: '中国工商银行长岭支行',
        value: '102247512120|2208|吉林省|白城市'
      },
      {
        label: '中国工商银行前郭尔罗斯蒙古族自治县支行',
        value: '102247612065|2208|吉林省|白城市'
      },
      {
        label: '中国工商银行前郭尔罗斯蒙古族自治县长山支行',
        value: '102247612073|2208|吉林省|白城市'
      },
      {
        label: '中国工商银行松原郭尔罗斯支行',
        value: '102247612081|2208|吉林省|白城市'
      },
      {
        label: '中国工商银行松原炼油厂支行',
        value: '102247612090|2208|吉林省|白城市'
      },
      {
        label: '中国工商银行松原江南支行',
        value: '102247612188|2208|吉林省|白城市'
      },
      {
        label: '中国工商银行白城镇赉支行',
        value: '102247700016|2208|吉林省|白城市'
      },
      {
        label: '中国工商银行股份有限公司白城镇赉广场支行',
        value: '102247700024|2208|吉林省|白城市'
      },
      {
        label: '中国工商银行白城镇赉友谊分理处',
        value: '102247700032|2208|吉林省|白城市'
      },
      {
        label: '中国工商银行白城通榆支行',
        value: '102247800017|2208|吉林省|白城市'
      },
      {
        label: '中国工商银行白城通榆开通镇支行',
        value: '102247800025|2208|吉林省|白城市'
      },
      {
        label: '中国工商银行白城通榆百货大楼支行',
        value: '102247800033|2208|吉林省|白城市'
      },
      {
        label: '中国工商银行乾安支行',
        value: '102247912054|2208|吉林省|白城市'
      },
      {
        label: '中国工商银行延边朝鲜族自治州分行核算中心',
        value: '102249001032|2224|吉林省|延边朝鲜族自治州'
      },
      {
        label: '中国工商银行股份有限公司成都新驿南街支行',
        value: '102651003243|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都龙泉广场支行',
        value: '102651003251|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都温江支行锦泉街分理处',
        value: '102651003392|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都金兴支行',
        value: '102651003413|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都西南石油大学支行',
        value: '102651003536|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都桂湖西路支行',
        value: '102651003544|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都金堂滨江路支行',
        value: '102651003831|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都川大西路支行',
        value: '102651003987|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司四川省成都双流支行川航分理处',
        value: '102651004051|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都大邑晋原支行',
        value: '102651004289|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都大邑大道支行',
        value: '102651004301|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司崇州西江桥支行',
        value: '102651004465|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司崇州支行蜀州中路分理处',
        value: '102651004473|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司崇州蜀州北路支行',
        value: '102651004529|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司新津支行武阳路分理处',
        value: '102651004631|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司襄阳万山支行',
        value: '102528000153|4206|湖北省|襄阳市'
      },
      {
        label: '中国工商银行股份有限公司襄阳荆州街支行',
        value: '102528000170|4206|湖北省|襄阳市'
      },
      {
        label: '中国工商银行股份有限公司襄阳胜利街支行',
        value: '102528000188|4206|湖北省|襄阳市'
      },
      {
        label: '中国工商银行股份有限公司襄阳闸口支行',
        value: '102528000223|4206|湖北省|襄阳市'
      },
      {
        label: '中国工商银行股份有限公司襄阳樊东支行',
        value: '102528000231|4206|湖北省|襄阳市'
      },
      {
        label: '中国工商银行股份有限公司襄阳铁路支行',
        value: '102528000240|4206|湖北省|襄阳市'
      },
      {
        label: '中国工商银行股份有限公司襄阳左岸春天支行',
        value: '102528000258|4206|湖北省|襄阳市'
      },
      {
        label: '中国工商银行股份有限公司襄阳丹江路支行',
        value: '102528000266|4206|湖北省|襄阳市'
      },
      {
        label: '中国工商银行股份有限公司揭阳东山支行',
        value: '102586500270|4405|广东省|汕头市'
      },
      {
        label: '中国工商银行股份有限公司揭阳分行业务处理中心',
        value: '102586500288|4405|广东省|汕头市'
      },
      {
        label: '中国工商银行股份有限公司新化支行梅城分理处',
        value: '102562510233|4313|湖南省|娄底市'
      },
      {
        label: '中国工商银行股份有限公司郴州下湄桥支行',
        value: '102563000420|4310|湖南省|郴州市'
      },
      {
        label: '中国工商银行股份有限公司郴州人民西路支行',
        value: '102563000438|4310|湖南省|郴州市'
      },
      {
        label: '中国工商银行股份有限公司郴州分行花园分理处',
        value: '102563000446|4310|湖南省|郴州市'
      },
      {
        label: '中国工商银行股份有限公司郴州五岭支行',
        value: '102563000454|4310|湖南省|郴州市'
      },
      {
        label: '中国工商银行股份有限公司郴州广场支行',
        value: '102563000462|4310|湖南省|郴州市'
      },
      {
        label: '中国工商银行股份有限公司郴州龙泉支行',
        value: '102563000583|4310|湖南省|郴州市'
      },
      {
        label: '中国工商银行股份有限公司郴州燕泉支行',
        value: '102563000591|4310|湖南省|郴州市'
      },
      {
        label: '中国工商银行股份有限公司郴州苏园支行',
        value: '102563000622|4310|湖南省|郴州市'
      },
      {
        label: '中国工商银行股份有限公司郴州南塔支行',
        value: '102563000655|4310|湖南省|郴州市'
      },
      {
        label: '中国工商银行股份有限公司郴州国庆南路支行',
        value: '102563001391|4310|湖南省|郴州市'
      },
      {
        label: '中国工商银行股份有限公司郴州分行国庆北路分理处',
        value: '102563001406|4310|湖南省|郴州市'
      },
      {
        label: '中国工商银行股份有限公司郴州南街支行',
        value: '102563002007|4310|湖南省|郴州市'
      },
      {
        label: '中国工商银行股份有限公司郴州五里堆支行',
        value: '102563010015|4310|湖南省|郴州市'
      },
      {
        label: '中国工商银行股份有限公司郴州高新支行',
        value: '102563010040|4310|湖南省|郴州市'
      },
      {
        label: '中国工商银行股份有限公司郴州北湖支行',
        value: '102563021005|4310|湖南省|郴州市'
      },
      {
        label: '中国工商银行股份有限公司佛山南海海北支行',
        value: '102588081719|4406|广东省|佛山市'
      },
      {
        label: '中国工商银行股份有限公司佛山三水耀华支行',
        value: '102588082203|4406|广东省|佛山市'
      },
      {
        label: '中国工商银行股份有限公司佛山南海太平支行',
        value: '102588083363|4406|广东省|佛山市'
      },
      {
        label: '中国工商银行股份有限公司佛山南海华丽支行',
        value: '102588084649|4406|广东省|佛山市'
      },
      {
        label: '中国工商银行股份有限公司江门港口支行',
        value: '102589000212|4407|广东省|江门市'
      },
      {
        label: '中国工商银行股份有限公司江门城西支行',
        value: '102589000229|4407|广东省|江门市'
      },
      {
        label: '中国工商银行股份有限公司江门江海支行',
        value: '102589000237|4407|广东省|江门市'
      },
      {
        label: '中国工商银行股份有限公司江门城区支行',
        value: '102589000261|4407|广东省|江门市'
      },
      {
        label: '中国工商银行股份有限公司江门分行',
        value: '102589000270|4407|广东省|江门市'
      },
      {
        label: '中国工商银行股份有限公司江门江翠支行',
        value: '102589000288|4407|广东省|江门市'
      },
      {
        label: '中国工商银行股份有限公司江门北新区支行',
        value: '102589000296|4407|广东省|江门市'
      },
      {
        label: '中国工商银行股份有限公司江门分行业务处理中心',
        value: '102589000331|4407|广东省|江门市'
      },
      {
        label: '中国工商银行股份有限公司江门江华支行',
        value: '102589000358|4407|广东省|江门市'
      },
      {
        label: '中国工商银行股份有限公司江门新会支行',
        value: '102589000382|4407|广东省|江门市'
      },
      {
        label: '中国工商银行股份有限公司江门新会第二支行',
        value: '102589000399|4407|广东省|江门市'
      },
      {
        label: '中国工商银行股份有限公司江门新会第三支行',
        value: '102589000526|4407|广东省|江门市'
      },
      {
        label: '中国工商银行股份有限公司台山支行',
        value: '102589200708|4407|广东省|江门市'
      },
      {
        label: '中国工商银行股份有限公司台山台电支行',
        value: '102589200765|4407|广东省|江门市'
      },
      {
        label: '中国工商银行股份有限公司恩平支行',
        value: '102589300908|4407|广东省|江门市'
      },
      {
        label: '中国工商银行股份有限公司开平支行',
        value: '102589400802|4407|广东省|江门市'
      },
      {
        label: '中国工商银行靖州苗族侗族自治县支行',
        value: '102568201179|4312|湖南省|怀化市'
      },
      {
        label: '中国工商银行靖州支行汽车站分理处',
        value: '102568211711|4312|湖南省|怀化市'
      },
      {
        label: '中国工商银行股份有限公司吉首砂子坳支行',
        value: '102569000279|4331|湖南省|湘西土家族苗族自治州'
      },
      {
        label: '中国工商银行股份有限公司吉首雅溪支行',
        value: '102569000756|4331|湖南省|湘西土家族苗族自治州'
      },
      {
        label: '中国工商银行股份有限公司湘西分行',
        value: '102569001015|4331|湖南省|湘西土家族苗族自治州'
      },
      {
        label: '中国工商银行股份有限公司吉首火车站支行',
        value: '102569001023|4331|湖南省|湘西土家族苗族自治州'
      },
      {
        label: '中国工商银行股份有限公司吉首乾州支行',
        value: '102569001040|4331|湖南省|湘西土家族苗族自治州'
      },
      {
        label: '中国工商银行股份有限公司吉首人民路支行',
        value: '102569001058|4331|湖南省|湘西土家族苗族自治州'
      },
      {
        label: '中国工商银行股份有限公司广州增城夏街支行',
        value: '102581002589|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州增城增江支行',
        value: '102581002597|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州增城锦绣支行',
        value: '102581002610|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州泰沙路支行',
        value: '102581002644|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州昌岗东路支行',
        value: '102581002685|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州信和广场支行',
        value: '102581002693|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州江湾路支行',
        value: '102581002708|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州基立道支行',
        value: '102581002724|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州晓港支行',
        value: '102581002749|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州远安支行',
        value: '102581002757|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州南洲支行',
        value: '102581002765|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州中山五路支行',
        value: '102581002888|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州东风中路支行',
        value: '102581002896|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州中山七路支行',
        value: '102581002923|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州乐嘉路支行',
        value: '102581003135|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州维多利广场支行',
        value: '102581003160|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州福今支行',
        value: '102581003284|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州天河北支行',
        value: '102581003305|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州景泰直街支行',
        value: '102581003549|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司中山三乡文昌支行',
        value: '102603001920|4420|广东省|中山市'
      },
      {
        label: '中国工商银行股份有限公司中山金汇支行',
        value: '102603001946|4420|广东省|中山市'
      },
      {
        label: '中国工商银行股份有限公司中山富丽支行',
        value: '102603001987|4420|广东省|中山市'
      },
      {
        label: '中国工商银行股份有限公司中山沙溪支行',
        value: '102603002109|4420|广东省|中山市'
      },
      {
        label: '中国工商银行股份有限公司中山分行业务处理中心',
        value: '102603002117|4420|广东省|中山市'
      },
      {
        label: '中国工商银行股份有限公司中山城南支行',
        value: '102603002133|4420|广东省|中山市'
      },
      {
        label: '中国工商银行股份有限公司中山南头支行',
        value: '102603002141|4420|广东省|中山市'
      },
      {
        label: '中国工商银行股份有限公司中山港口支行',
        value: '102603002150|4420|广东省|中山市'
      },
      {
        label: '中国工商银行股份有限公司中山民众支行',
        value: '102603002168|4420|广东省|中山市'
      },
      {
        label: '中国工商银行股份有限公司中山张家边支行',
        value: '102603002176|4420|广东省|中山市'
      },
      {
        label: '中国工商银行股份有限公司中山古镇支行',
        value: '102603002205|4420|广东省|中山市'
      },
      {
        label: '中国工商银行股份有限公司中山大涌支行',
        value: '102603002213|4420|广东省|中山市'
      },
      {
        label: '中国工商银行股份有限公司广州粤秀支行',
        value: '102581000249|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州东城支行',
        value: '102581000257|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州五羊支行',
        value: '102581000265|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州银山支行',
        value: '102581000273|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州番禺支行',
        value: '102581000281|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广东自由贸易试验区南沙分行',
        value: '102581000290|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州花都支行',
        value: '102581000304|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州从化支行',
        value: '102581000312|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州增城支行',
        value: '102581000329|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州南方支行',
        value: '102581000353|4401|广东省|广州市'
      },
      {
        label: '中国工商银行广州新塘支行',
        value: '102581000361|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州沙河支行',
        value: '102581000370|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州东风东路支行',
        value: '102581000396|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州建设六马路支行',
        value: '102581000407|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州吉祥支行',
        value: '102581000415|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州珠江城大厦支行',
        value: '102581000423|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州小北路支行',
        value: '102581000431|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司梅州梅江支行',
        value: '102596002021|4414|广东省|梅州市'
      },
      {
        label: '中国工商银行股份有限公司梅州分行营业部',
        value: '102596002048|4414|广东省|梅州市'
      },
      {
        label: '中国工商银行股份有限公司梅州分行业务处理中心',
        value: '102596005006|4414|广东省|梅州市'
      },
      {
        label: '中国工商银行股份有限公司大埔支行',
        value: '102596200117|4414|广东省|梅州市'
      },
      {
        label: '中国工商银行股份有限公司丰顺支行',
        value: '102596302210|4414|广东省|梅州市'
      },
      {
        label: '中国工商银行股份有限公司白银东山路支行',
        value: '102824000150|6204|甘肃省|白银市'
      },
      {
        label: '中国工商银行股份有限公司灵宝老城支行',
        value: '102505312103|4112|河南省|三门峡市'
      },
      {
        label: '中国工商银行股份有限公司灵宝北区支行',
        value: '102505322109|4112|河南省|三门峡市'
      },
      {
        label: '中国工商银行股份有限公司灵宝时代支行',
        value: '102505392100|4112|河南省|三门峡市'
      },
      {
        label: '中国工商银行三门峡分行卢氏县支行',
        value: '102505402205|4112|河南省|三门峡市'
      },
      {
        label: '中国工商银行三门峡分行义马市支行',
        value: '102505502135|4112|河南省|三门峡市'
      },
      {
        label: '中国工商银行股份有限公司义马千秋支行',
        value: '102505542136|4112|河南省|三门峡市'
      },
      {
        label: '中国工商银行股份有限公司义马银杏路支行',
        value: '102505572138|4112|河南省|三门峡市'
      },
      {
        label: '中国工商银行商丘分行新建路支行',
        value: '102506002011|4114|河南省|商丘市'
      },
      {
        label: '中国工商银行股份有限公司云浮硫铁矿支行',
        value: '102593700229|4412|广东省|肇庆市'
      },
      {
        label: '中国工商银行股份有限公司云浮城区支行',
        value: '102593700237|4412|广东省|肇庆市'
      },
      {
        label: '中国工商银行股份有限公司云浮新城支行',
        value: '102593700245|4412|广东省|肇庆市'
      },
      {
        label: '中国工商银行股份有限公司云浮云安支行',
        value: '102593700253|4412|广东省|肇庆市'
      },
      {
        label: '中国工商银行股份有限公司云浮分行业务处理中心',
        value: '102593700340|4412|广东省|肇庆市'
      },
      {
        label: '中国工商银行股份有限公司新兴支行',
        value: '102593800328|4412|广东省|肇庆市'
      },
      {
        label: '中国工商银行股份有限公司郁南支行',
        value: '102593900298|4453|广东省|云浮市'
      },
      {
        label: '中国工商银行股份有限公司罗定支行',
        value: '102594100267|4453|广东省|云浮市'
      },
      {
        label: '中国工商银行惠州市分行营业部',
        value: '102595002014|4413|广东省|惠州市'
      },
      {
        label: '中国工商银行京山支行',
        value: '102538200386|4210|湖北省|荆州市'
      },
      {
        label: '中国工商银行股份有限公司京山宋河支行',
        value: '102538200394|4210|湖北省|荆州市'
      },
      {
        label: '中国工商银行股份有限公司京山财政支行',
        value: '102538200409|4210|湖北省|荆州市'
      },
      {
        label: '中国工商银行股份有限公司京山城中路支行',
        value: '102538200425|4210|湖北省|荆州市'
      },
      {
        label: '中国工商银行股份有限公司京山新市支行',
        value: '102538200433|4210|湖北省|荆州市'
      },
      {
        label: '中国工商银行股份有限公司京山三角洲支行',
        value: '102538200441|4210|湖北省|荆州市'
      },
      {
        label: '中国工商银行股份有限公司京山惠亭支行',
        value: '102538200476|4210|湖北省|荆州市'
      },
      {
        label: '中国工商银行股份有限公司京山文笔峰支行',
        value: '102538200484|4210|湖北省|荆州市'
      },
      {
        label: '中国工商银行股份有限公司荆门屈家岭支行',
        value: '102538200492|4210|湖北省|荆州市'
      },
      {
        label: '中国工商银行沙洋支行',
        value: '102538300262|4208|湖北省|荆门市'
      },
      {
        label: '中国工商银行股份有限公司沙洋百川支行',
        value: '102538300287|4208|湖北省|荆门市'
      },
      {
        label: '中国工商银行股份有限公司沙洋牛奶山支行',
        value: '102538300295|4208|湖北省|荆门市'
      },
      {
        label: '中国工商银行丹江口市支行',
        value: '102539100096|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行武当山经济开发区支行',
        value: '102539100107|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行丹江口支行汉江分理处',
        value: '102539100684|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行丹江口支行跃进门分理处',
        value: '102539100692|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行股份有限公司丹江口均州支行',
        value: '102539100713|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行股份有限公司丹江口六里坪支行',
        value: '102539102712|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行郧县支行',
        value: '102539200110|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行股份有限公司郧县长岭支行',
        value: '102539227087|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行股份有限公司郧西支行',
        value: '102539300723|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行十堰市分行竹山县分理处',
        value: '102539400138|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行股份有限公司竹溪支行',
        value: '102539502696|4203|湖北省|十堰市'
      },
      {
        label: '中国工商银行十堰房县支行',
        value: '102539600189|4203|湖北省|十堰市'
      },
      {
        label: '中国工商银行股份有限公司恩施开发区支行',
        value: '102541000019|4228|湖北省|恩施土家族苗族自治州'
      },
      {
        label: '中国工商银行股份有限公司广州番禺沙湾支行',
        value: '102581001813|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州番禺石基支行',
        value: '102581001848|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州番禺喜盈支行',
        value: '102581001944|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州番禺西丽支行',
        value: '102581001952|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州番禺康乐支行',
        value: '102581001969|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州番禺清河东路支行',
        value: '102581001977|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州番禺德贤支行',
        value: '102581001985|4401|广东省|广州市'
      },
      {
        label: '中国工商银行广州新塘永和支行',
        value: '102581002126|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州新塘南安支行',
        value: '102581002142|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州新塘解放路支行',
        value: '102581002167|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州增城开发区支行',
        value: '102581002175|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州南湖支行',
        value: '102581002206|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州科学城支行',
        value: '102581002214|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州萝岗支行',
        value: '102581002247|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司佛山南海沙头支行',
        value: '102588002396|4406|广东省|佛山市'
      },
      {
        label: '中国工商银行股份有限公司佛山南海藤厂支行',
        value: '102588002407|4406|广东省|佛山市'
      },
      {
        label: '中国工商银行股份有限公司佛山南海小塘支行',
        value: '102588002415|4406|广东省|佛山市'
      },
      {
        label: '中国工商银行股份有限公司佛山罗村支行',
        value: '102588002423|4406|广东省|佛山市'
      },
      {
        label: '中国工商银行股份有限公司佛山南海丹灶支行',
        value: '102588002440|4406|广东省|佛山市'
      },
      {
        label: '中国工商银行股份有限公司佛山石湾支行',
        value: '102588002503|4406|广东省|佛山市'
      },
      {
        label: '中国工商银行股份有限公司佛山张槎支行',
        value: '102588002538|4406|广东省|佛山市'
      },
      {
        label: '中国工商银行股份有限公司白银胜利路支行',
        value: '102824000176|6204|甘肃省|白银市'
      },
      {
        label: '中国工商银行股份有限公司白银四龙路支行',
        value: '102824000393|6204|甘肃省|白银市'
      },
      {
        label: '中国工商银行股份有限公司靖远支行',
        value: '102824100062|6204|甘肃省|白银市'
      },
      {
        label: '中国工商银行股份有限公司靖远北城支行',
        value: '102824100298|6204|甘肃省|白银市'
      },
      {
        label: '中国工商银行股份有限公司会宁支行',
        value: '102824200560|6204|甘肃省|白银市'
      },
      {
        label: '中国工商银行股份有限公司景泰支行',
        value: '102824300072|6204|甘肃省|白银市'
      },
      {
        label: '中国工商银行股份有限公司景泰大楼支行',
        value: '102824300265|6204|甘肃省|白银市'
      },
      {
        label: '中国工商银行股份有限公司平川支行',
        value: '102824400055|6204|甘肃省|白银市'
      },
      {
        label: '中国工商银行股份有限公司平川盘旋路支行',
        value: '102824400452|6204|甘肃省|白银市'
      },
      {
        label: '中国工商银行股份有限公司平川电力支行',
        value: '102824400469|6204|甘肃省|白银市'
      },
      {
        label: '中国工商银行股份有限公司平川长征支行',
        value: '102824400485|6204|甘肃省|白银市'
      },
      {
        label: '中国工商银行股份有限公司天水分行业务处理中心',
        value: '102825000012|6205|甘肃省|天水市'
      },
      {
        label: '中国工商银行股份有限公司天水秦州支行',
        value: '102825000029|6205|甘肃省|天水市'
      },
      {
        label: '中国工商银行股份有限公司天水麦积支行',
        value: '102825000037|6205|甘肃省|天水市'
      },
      {
        label: '中国工商银行股份有限公司天水市东关支行',
        value: '102825000053|6205|甘肃省|天水市'
      },
      {
        label: '中国工商银行云南保山分行正南支行',
        value: '102753010091|5305|云南省|保山市'
      },
      {
        label: '中国工商银行云南保山分行永昌支行',
        value: '102753010106|5305|云南省|保山市'
      },
      {
        label: '中国工商银行云南保山分行龙泉支行',
        value: '102753010114|5305|云南省|保山市'
      },
      {
        label: '中国工商银行云南保山分行隆阳支行',
        value: '102753010122|5305|云南省|保山市'
      },
      {
        label: '中国工商银行云南保山分行和平支行',
        value: '102753010147|5305|云南省|保山市'
      },
      {
        label: '中国工商银行云南保山分行兰城支行',
        value: '102753010155|5305|云南省|保山市'
      },
      {
        label: '中国工商银行云南保山分行腾冲县支行',
        value: '102753310035|5305|云南省|保山市'
      },
      {
        label: '中国工商银行云南保山分行腾冲县文星楼支行',
        value: '102753310213|5305|云南省|保山市'
      },
      {
        label: '中国工商银行云南保山分行腾冲县三八支行',
        value: '102753310221|5305|云南省|保山市'
      },
      {
        label: '中国工商银行云南保山分行腾冲县来凤支行',
        value: '102753310230|5305|云南省|保山市'
      },
      {
        label: '中国工商银行云南保山分行腾冲县腾越支行',
        value: '102753310248|5305|云南省|保山市'
      },
      {
        label: '中国工商银行云南保山分行龙陵县支行',
        value: '102753410046|5305|云南省|保山市'
      },
      {
        label: '中国工商银行云南德宏分行芒市勐垅沙支行',
        value: '102754002340|5331|云南省|德宏傣族景颇族自治州'
      },
      {
        label: '中国工商银行股份有限公司汕头公园支行',
        value: '102586002052|4405|广东省|汕头市'
      },
      {
        label: '中国工商银行股份有限公司汕头汕樟支行',
        value: '102586002069|4405|广东省|汕头市'
      },
      {
        label: '中国工商银行股份有限公司汕头金樟支行',
        value: '102586002077|4405|广东省|汕头市'
      },
      {
        label: '中国工商银行股份有限公司汕头达濠支行',
        value: '102586002116|4405|广东省|汕头市'
      },
      {
        label: '中国工商银行股份有限公司汕头金湖支行',
        value: '102586002157|4405|广东省|汕头市'
      },
      {
        label: '中国工商银行股份有限公司汕头金凤支行',
        value: '102586002181|4405|广东省|汕头市'
      },
      {
        label: '中国工商银行股份有限公司汕头高新技术开发区支行',
        value: '102586002212|4405|广东省|汕头市'
      },
      {
        label: '中国工商银行股份有限公司汕头长江支行',
        value: '102586002229|4405|广东省|汕头市'
      },
      {
        label: '中国工商银行股份有限公司汕头韩江支行',
        value: '102586002382|4405|广东省|汕头市'
      },
      {
        label: '中国工商银行股份有限公司汕头长平支行',
        value: '102586002399|4405|广东省|汕头市'
      },
      {
        label: '中国工商银行股份有限公司陵水支行',
        value: '102641000346|4601|海南省|海口市'
      },
      {
        label: '中国工商银行股份有限公司保亭支行',
        value: '102641000354|4601|海南省|海口市'
      },
      {
        label: '中国工商银行股份有限公司乐东支行',
        value: '102641000362|4601|海南省|海口市'
      },
      {
        label: '中国工商银行股份有限公司乐东利国支行',
        value: '102641000379|4601|海南省|海口市'
      },
      {
        label: '中国工商银行股份有限公司海口海盛支行',
        value: '102641000387|4601|海南省|海口市'
      },
      {
        label: '中国工商银行股份有限公司乐东黄流支行',
        value: '102641000395|4601|海南省|海口市'
      },
      {
        label: '中国工商银行股份有限公司琼中支行',
        value: '102641000400|4601|海南省|海口市'
      },
      {
        label: '中国工商银行股份有限公司东方支行',
        value: '102641000418|4601|海南省|海口市'
      },
      {
        label: '中国工商银行股份有限公司昌江支行',
        value: '102641000426|4601|海南省|海口市'
      },
      {
        label: '中国工商银行股份有限公司白沙支行',
        value: '102641000442|4601|海南省|海口市'
      },
      {
        label: '中国工商银行股份有限公司洋浦分行',
        value: '102641000459|4601|海南省|海口市'
      },
      {
        label: '中国工商银行股份有限公司海口世贸支行',
        value: '102641000467|4601|海南省|海口市'
      },
      {
        label: '中国工商银行股份有限公司海口海大支行',
        value: '102641000514|4601|海南省|海口市'
      },
      {
        label: '中国工商银行股份有限公司海口凤翔支行',
        value: '102641000522|4601|海南省|海口市'
      },
      {
        label: '中国工商银行股份有限公司铜陵牡丹支行',
        value: '102367002917|3407|安徽省|铜陵市'
      },
      {
        label: '中国工商银行股份有限公司铜陵开发区支行',
        value: '102367002925|3407|安徽省|铜陵市'
      },
      {
        label: '中国工商银行铜陵县支行',
        value: '102367102808|3407|安徽省|铜陵市'
      },
      {
        label: '中国工商银行铜陵顺安支行',
        value: '102367102824|3407|安徽省|铜陵市'
      },
      {
        label: '中国工商银行股份有限公司南昌桃苑支行',
        value: '102421000251|3601|江西省|南昌市'
      },
      {
        label: '中国工商银行股份有限公司南昌厚福支行',
        value: '102421000260|3601|江西省|南昌市'
      },
      {
        label: '中国工商银行股份有限公司南昌洪城支行',
        value: '102421000286|3601|江西省|南昌市'
      },
      {
        label: '中国工商银行股份有限公司南昌绳金塔支行',
        value: '102421000294|3601|江西省|南昌市'
      },
      {
        label: '中国工商银行股份有限公司南昌湾里支行',
        value: '102421000341|3601|江西省|南昌市'
      },
      {
        label: '中国工商银行股份有限公司南昌永外支行',
        value: '102421000376|3601|江西省|南昌市'
      },
      {
        label: '中国工商银行股份有限公司昌吉特变支行',
        value: '102885080033|6523|新疆维吾尔自治区|昌吉回族自治州'
      },
      {
        label: '中国工商银行西安南院门支行',
        value: '102791000138|6101|陕西省|西安市'
      },
      {
        label: '中国工商银行西安东关支行',
        value: '102791000146|6101|陕西省|西安市'
      },
      {
        label: '中国工商银行西安和平路支行',
        value: '102791000154|6101|陕西省|西安市'
      },
      {
        label: '中国工商银行西安互助路支行',
        value: '102791000162|6101|陕西省|西安市'
      },
      {
        label: '中国工商银行西安市北大街支行',
        value: '102791000179|6101|陕西省|西安市'
      },
      {
        label: '工行陕西省西安莲湖路支行',
        value: '102791000187|6101|陕西省|西安市'
      },
      {
        label: '工行陕西省西安北关支行',
        value: '102791000195|6101|陕西省|西安市'
      },
      {
        label: '中国工商银行西安西大街支行',
        value: '102791000200|6101|陕西省|西安市'
      },
      {
        label: '中国工商银行西安星火路支行',
        value: '102791000218|6101|陕西省|西安市'
      },
      {
        label: '中国工商银行西安市南关支行',
        value: '102791000226|6101|陕西省|西安市'
      },
      {
        label: '中国工商银行西安小寨支行',
        value: '102791000234|6101|陕西省|西安市'
      },
      {
        label: '中国工商银行西安雁塔路支行',
        value: '102791000242|6101|陕西省|西安市'
      },
      {
        label: '中国工商银行西安含光路支行',
        value: '102791000259|6101|陕西省|西安市'
      },
      {
        label: '中国工商银行西安铁路局支行',
        value: '102791000267|6101|陕西省|西安市'
      },
      {
        label: '中国工商银行西安电子工业区支行',
        value: '102791000275|6101|陕西省|西安市'
      },
      {
        label: '中国工商银行股份有限公司乌鲁木齐克拉玛依西路支行',
        value: '102881001423|6501|新疆维吾尔自治区|乌鲁木齐市'
      },
      {
        label: '中国工商银行股份有限公司乌鲁木齐长江路支行',
        value: '102881001431|6501|新疆维吾尔自治区|乌鲁木齐市'
      },
      {
        label: '中国工商银行股份有限公司乌鲁木齐新民路支行',
        value: '102881001511|6501|新疆维吾尔自治区|乌鲁木齐市'
      },
      {
        label: '中国工商银行股份有限公司枝江迎宾支行',
        value: '102526401433|4205|湖北省|宜昌市'
      },
      {
        label: '中国工商银行股份有限公司枝江团结路支行',
        value: '102526404825|4205|湖北省|宜昌市'
      },
      {
        label: '中国工商银行三峡分行枝江市支行',
        value: '102526407309|4205|湖北省|宜昌市'
      },
      {
        label: '中国工商银行远安支行',
        value: '102526501841|4205|湖北省|宜昌市'
      },
      {
        label: '中国工商银行股份有限公司远安鸣凤支行',
        value: '102526507081|4205|湖北省|宜昌市'
      },
      {
        label: '中国工商银行股份有限公司成都仁和路支行',
        value: '102651024942|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都华府大道支行',
        value: '102651024959|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都双流保税园区支行',
        value: '102651024967|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都双流支行杨桥路分理处',
        value: '102651024975|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都东大支行武科西四路分理处',
        value: '102651025009|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都长城西一路支行',
        value: '102651025017|5101|四川省|成都市'
      },
      {
        label: '中国工商银行成都市金堂支行营业室',
        value: '102651025105|5101|四川省|成都市'
      },
      {
        label: '中国工商银行湖北省荆门长宁支行',
        value: '102532000068|4208|湖北省|荆门市'
      },
      {
        label: '中国工商银行股份有限公司荆门泉口支行',
        value: '102532000076|4208|湖北省|荆门市'
      },
      {
        label: '中国工商银行荆门文峰支行',
        value: '102532000084|4208|湖北省|荆门市'
      },
      {
        label: '中国工商银行股份有限公司荆门老城支行',
        value: '102532000105|4208|湖北省|荆门市'
      },
      {
        label: '中国工商银行股份有限公司荆门掇刀支行',
        value: '102532000113|4208|湖北省|荆门市'
      },
      {
        label: '中国工商银行股份有限公司荆门东风支行',
        value: '102532000130|4208|湖北省|荆门市'
      },
      {
        label: '中国工商银行荆门石化工业区支行',
        value: '102532000148|4208|湖北省|荆门市'
      },
      {
        label: '中国工商银行股份有限公司荆门热电厂支行',
        value: '102532000164|4208|湖北省|荆门市'
      },
      {
        label: '中国工商银行股份有限公司荆门月亮湾支行',
        value: '102532000189|4208|湖北省|荆门市'
      },
      {
        label: '中国工商银行股份有限公司荆门文化宫支行',
        value: '102532000197|4208|湖北省|荆门市'
      },
      {
        label: '中国工商银行股份有限公司荆门虎牙关支行',
        value: '102532000228|4208|湖北省|荆门市'
      },
      {
        label: '中国工商银行荆门龙泉支行',
        value: '102532000244|4208|湖北省|荆门市'
      },
      {
        label: '中国工商银行荆门南台支行',
        value: '102532000252|4208|湖北省|荆门市'
      },
      {
        label: '中国工商银行股份有限公司荆门漳河新区支行',
        value: '102532000269|4208|湖北省|荆门市'
      },
      {
        label: '中国工商银行股份有限公司荆门高新区支行',
        value: '102532000277|4208|湖北省|荆门市'
      },
      {
        label: '中国工商银行荆门分行会计业务处理中心',
        value: '102532000496|4208|湖北省|荆门市'
      },
      {
        label: '中国工商银行股份有限公司黄冈八卦井支行',
        value: '102533010024|4211|湖北省|黄冈市'
      },
      {
        label: '中国工商银行黄冈分行八一路支行',
        value: '102533010032|4211|湖北省|黄冈市'
      },
      {
        label: '中国工商银行股份有限公司黄冈长江支行',
        value: '102533010073|4211|湖北省|黄冈市'
      },
      {
        label: '中国工商银行股份有限公司黄冈城东支行',
        value: '102533010112|4211|湖北省|黄冈市'
      },
      {
        label: '中国工商银行股份有限公司黄冈黄州支行',
        value: '102533010215|4211|湖北省|黄冈市'
      },
      {
        label: '中国工商银行股份有限公司黄冈胜利街支行',
        value: '102533010223|4211|湖北省|黄冈市'
      },
      {
        label: '中国工商银行股份有限公司黄冈三台河支行',
        value: '102533010231|4211|湖北省|黄冈市'
      },
      {
        label: '中国工商银行股份有限公司黄冈大东门支行',
        value: '102533010240|4211|湖北省|黄冈市'
      },
      {
        label: '中国工商银行股份有限公司黄冈开发区支行',
        value: '102533010266|4211|湖北省|黄冈市'
      },
      {
        label: '中国工商银行股份有限公司黄冈赤壁支行',
        value: '102533010274|4211|湖北省|黄冈市'
      },
      {
        label: '中国工商银行黄冈市分行会计业务处理中心',
        value: '102533015004|4211|湖北省|黄冈市'
      },
      {
        label: '中国工商银行黄山分行徽州支行',
        value: '102371009413|3410|安徽省|黄山市'
      },
      {
        label: '中国工商银行黄山支行',
        value: '102371070014|3410|安徽省|黄山市'
      },
      {
        label: '中国工商银行股份有限公司黄山平湖支行',
        value: '102371070039|3410|安徽省|黄山市'
      },
      {
        label: '中国工商银行黄山景区支行',
        value: '102371070047|3410|安徽省|黄山市'
      },
      {
        label: '中国工商银行歙县支行',
        value: '102371100011|3410|安徽省|黄山市'
      },
      {
        label: '中国工商银行股份有限公司歙县八角牌楼支行',
        value: '102371100020|3410|安徽省|黄山市'
      },
      {
        label: '中国工商银行股份有限公司歙县开发区支行',
        value: '102371100046|3410|安徽省|黄山市'
      },
      {
        label: '中国工商银行股份有限公司休宁支行',
        value: '102371209307|3410|安徽省|黄山市'
      },
      {
        label: '中国工商银行股份有限公司休宁北街支行',
        value: '102371226203|3410|安徽省|黄山市'
      },
      {
        label: '中国工商银行股份有限公司黟县支行',
        value: '102371309504|3410|安徽省|黄山市'
      },
      {
        label: '中国工商银行股份有限公司祁门支行',
        value: '102371400014|3410|安徽省|黄山市'
      },
      {
        label: '中国工商银行股份有限公司阜阳汇通支行',
        value: '102372000029|3412|安徽省|阜阳市'
      },
      {
        label: '中国工商银行股份有限公司阜阳城建支行',
        value: '102372000045|3412|安徽省|阜阳市'
      },
      {
        label: '中国工商银行股份有限公司阜阳颍州支行',
        value: '102372000088|3412|安徽省|阜阳市'
      },
      {
        label: '中国工商银行股份有限公司阜阳颍州路支行',
        value: '102372000131|3412|安徽省|阜阳市'
      },
      {
        label: '中国工商银行股份有限公司阜阳颍泉支行',
        value: '102372000220|3412|安徽省|阜阳市'
      },
      {
        label: '中国工商银行股份有限公司南昌步行街支行',
        value: '102421001344|3601|江西省|南昌市'
      },
      {
        label: '中国工商银行股份有限公司南昌江铜千僖支行',
        value: '102421001377|3601|江西省|南昌市'
      },
      {
        label: '中国工商银行股份有限公司南昌顺外支行',
        value: '102421001408|3601|江西省|南昌市'
      },
      {
        label: '中国工商银行股份有限公司景德镇瓷都大道支行',
        value: '102422001560|3602|江西省|景德镇市'
      },
      {
        label: '中国工商银行景德镇市业务处理中心',
        value: '102422003000|3602|江西省|景德镇市'
      },
      {
        label: '中国工商银行景德镇市中山路支行',
        value: '102422003018|3602|江西省|景德镇市'
      },
      {
        label: '中国工商银行景德镇市斗富弄支行',
        value: '102422003026|3602|江西省|景德镇市'
      },
      {
        label: '中国工商银行景德镇市珠山路支行',
        value: '102422003034|3602|江西省|景德镇市'
      },
      {
        label: '中国工商银行盐城建军东路支行',
        value: '102311066286|3209|江苏省|盐城市'
      },
      {
        label: '中国工商银行股份有限公司遂宁经济开发区支行',
        value: '102662000154|5109|四川省|遂宁市'
      },
      {
        label: '中国工商银行股份有限公司遂宁分行犀牛广场分理处',
        value: '102662000162|5109|四川省|遂宁市'
      },
      {
        label: '中国工商银行股份有限公司遂宁金海支行',
        value: '102662000218|5109|四川省|遂宁市'
      },
      {
        label: '中国工商银行遂宁市分行业务处理中心',
        value: '102662046110|5109|四川省|遂宁市'
      },
      {
        label: '中国工商银行遂宁市分行',
        value: '102662046216|5109|四川省|遂宁市'
      },
      {
        label: '中国工商银行遂宁市裕丰支行',
        value: '102662046224|5109|四川省|遂宁市'
      },
      {
        label: '中国工商银行遂宁市南津支行',
        value: '102662046232|5109|四川省|遂宁市'
      },
      {
        label: '中国工商银行股份有限公司遂宁河东支行',
        value: '102662046257|5109|四川省|遂宁市'
      },
      {
        label: '中国工商银行股份有限公司四川省遂宁工业园区支行',
        value: '102662046273|5109|四川省|遂宁市'
      },
      {
        label: '中国工商银行股份有限公司四川省遂宁遂州支行',
        value: '102662046281|5109|四川省|遂宁市'
      },
      {
        label: '中国工商银行股份有限公司遂宁物流港支行',
        value: '102662046290|5109|四川省|遂宁市'
      },
      {
        label: '中国工商银行股份有限公司四川省遂宁安居支行',
        value: '102662046618|5109|四川省|遂宁市'
      },
      {
        label: '中国工商银行蓬溪县支行',
        value: '102662146316|5109|四川省|遂宁市'
      },
      {
        label: '中国工商银行股份有限公司蓬溪赤城支行',
        value: '102662146365|5109|四川省|遂宁市'
      },
      {
        label: '中国工商银行股份有限公司射洪支行太和大道分理处',
        value: '102662200726|5109|四川省|遂宁市'
      },
      {
        label: '中国工商银行东风场区分行营业室',
        value: '102826000590|6209|甘肃省|酒泉市'
      },
      {
        label: '中国工商银行股份有限公司东风场区河东里支行',
        value: '102826000612|6209|甘肃省|酒泉市'
      },
      {
        label: '中国工商银行股份有限公司东风场区红旗支行',
        value: '102826000688|6209|甘肃省|酒泉市'
      },
      {
        label: '中国工商银行股份有限公司酒泉南关支行',
        value: '102826000782|6209|甘肃省|酒泉市'
      },
      {
        label: '中国工商银行股份有限公司玉门支行',
        value: '102826100031|6209|甘肃省|酒泉市'
      },
      {
        label: '中国工商银行股份有限公司酒泉承瑞园支行',
        value: '102826100120|6209|甘肃省|酒泉市'
      },
      {
        label: '中国工商银行股份有限公司敦煌支行',
        value: '102826300041|6209|甘肃省|酒泉市'
      },
      {
        label: '中国工商银行股份有限公司敦煌七里镇支行',
        value: '102826300365|6209|甘肃省|酒泉市'
      },
      {
        label: '中国工商银行股份有限公司敦煌新区支行',
        value: '102826300373|6209|甘肃省|酒泉市'
      },
      {
        label: '中国工商银行股份有限公司敦煌西街支行',
        value: '102826300381|6209|甘肃省|酒泉市'
      },
      {
        label: '中国工商银行股份有限公司敦煌昆仑西路支行',
        value: '102826300470|6209|甘肃省|酒泉市'
      },
      {
        label: '中国工商银行股份有限公司敦煌金达路支行',
        value: '102826300488|6209|甘肃省|酒泉市'
      },
      {
        label: '中国工商银行股份有限公司金塔支行',
        value: '102826400067|6209|甘肃省|酒泉市'
      },
      {
        label: '中国工商银行股份有限公司肃北支行',
        value: '102826500518|6209|甘肃省|酒泉市'
      },
      {
        label: '中国工商银行股份有限公司阿克塞支行',
        value: '102826600504|6209|甘肃省|酒泉市'
      },
      {
        label: '中国工商银行股份有限公司瓜州支行',
        value: '102826700054|6209|甘肃省|酒泉市'
      },
      {
        label: '中国工商银行股份有限公司张掖分行业务处理中心',
        value: '102827000016|6207|甘肃省|张掖市'
      },
      {
        label: '中国工商银行股份有限公司张掖甘州支行',
        value: '102827000024|6207|甘肃省|张掖市'
      },
      {
        label: '中国工商银行股份有限公司张掖城关支行',
        value: '102827000032|6207|甘肃省|张掖市'
      },
      {
        label: '中国工商银行股份有限公司张掖县府街支行',
        value: '102827000049|6207|甘肃省|张掖市'
      },
      {
        label: '中国工商银行股份有限公司张掖西关支行',
        value: '102827000065|6207|甘肃省|张掖市'
      },
      {
        label: '中国工商银行股份有限公司张掖南关支行',
        value: '102827000073|6207|甘肃省|张掖市'
      },
      {
        label: '中国工商银行股份有限公司张掖火车站支行',
        value: '102827000153|6207|甘肃省|张掖市'
      },
      {
        label: '中国工商银行股份有限公司张掖市南街支行',
        value: '102827000188|6207|甘肃省|张掖市'
      },
      {
        label: '工行宁夏银川海宝支行',
        value: '102871000808|6401|宁夏回族自治区|银川市'
      },
      {
        label: '工行宁夏银川新华支行',
        value: '102871001018|6401|宁夏回族自治区|银川市'
      },
      {
        label: '工行宁夏银川振兴支行',
        value: '102871001042|6401|宁夏回族自治区|银川市'
      },
      {
        label: '工行宁夏银川永安支行',
        value: '102871001180|6401|宁夏回族自治区|银川市'
      },
      {
        label: '工行宁夏银川信义支行',
        value: '102871001294|6401|宁夏回族自治区|银川市'
      },
      {
        label: '工行宁夏银川西夏支行军区分理处',
        value: '102871001317|6401|宁夏回族自治区|银川市'
      },
      {
        label: '工行宁夏银川共享支行',
        value: '102871001448|6401|宁夏回族自治区|银川市'
      },
      {
        label: '工行宁夏银川西塔支行',
        value: '102871001544|6401|宁夏回族自治区|银川市'
      },
      {
        label: '工行宁夏银川富宁支行',
        value: '102871001690|6401|宁夏回族自治区|银川市'
      },
      {
        label: '工行宁夏银川牡丹园支行',
        value: '102871001761|6401|宁夏回族自治区|银川市'
      },
      {
        label: '工行宁夏灵武支行',
        value: '102871002117|6401|宁夏回族自治区|银川市'
      },
      {
        label: '工行宁夏宁东青松苑支行',
        value: '102871002176|6401|宁夏回族自治区|银川市'
      },
      {
        label: '工行宁夏银川同心南路支行',
        value: '102871002256|6401|宁夏回族自治区|银川市'
      },
      {
        label: '工行宁夏宁东支行',
        value: '102871002711|6401|宁夏回族自治区|银川市'
      },
      {
        label: '工行宁夏银川业务处理中心（不办理对客户业务）',
        value: '102871005003|6401|宁夏回族自治区|银川市'
      },
      {
        label: '工行宁夏银川城区支行',
        value: '102871005011|6401|宁夏回族自治区|银川市'
      },
      {
        label: '中国工商银行股份有限公司昌吉延安中路支行',
        value: '102885080041|6523|新疆维吾尔自治区|昌吉回族自治州'
      },
      {
        label: '中国工商银行股份有限公司昌吉建国路支行',
        value: '102885080092|6523|新疆维吾尔自治区|昌吉回族自治州'
      },
      {
        label: '中国工商银行股份有限公司昌吉宁边路支行',
        value: '102885080130|6523|新疆维吾尔自治区|昌吉回族自治州'
      },
      {
        label: '中国工商银行股份有限公司昌吉北京南路支行',
        value: '102885080148|6523|新疆维吾尔自治区|昌吉回族自治州'
      },
      {
        label: '中国工商银行股份有限公司昌吉长宁路支行',
        value: '102885080156|6523|新疆维吾尔自治区|昌吉回族自治州'
      },
      {
        label: '中国工商银行股份有限公司昌吉建国西路支行',
        value: '102885080201|6523|新疆维吾尔自治区|昌吉回族自治州'
      },
      {
        label: '中国工商银行股份有限公司昌吉回族自治州分行银行卡中心',
        value: '102885080228|6523|新疆维吾尔自治区|昌吉回族自治州'
      },
      {
        label: '工商银行乌鲁木齐米东支行营业室',
        value: '102881081990|6501|新疆维吾尔自治区|乌鲁木齐市'
      },
      {
        label: '中国工商银行股份有限公司呼图壁支行',
        value: '102885384991|6523|新疆维吾尔自治区|昌吉回族自治州'
      },
      {
        label: '中国工商银行股份有限公司四川省自贡分行汇新支行',
        value: '102655001069|5103|四川省|自贡市'
      },
      {
        label: '中国工商银行遵义分行赤水市支行',
        value: '102704300253|5203|贵州省|遵义市'
      },
      {
        label: '中国工商银行股份有限公司赤水丹霞支行',
        value: '102704300462|5203|贵州省|遵义市'
      },
      {
        label: '中国工商银行遵义分行习水县支行',
        value: '102704400297|5203|贵州省|遵义市'
      },
      {
        label: '中国工商银行股份有限公司习水红城支行',
        value: '102704400424|5203|贵州省|遵义市'
      },
      {
        label: '中国工商银行铜仁市九龙支行',
        value: '102705000018|5206|贵州省|铜仁市'
      },
      {
        label: '中国工商银行铜仁市梵净山支行',
        value: '102705000114|5206|贵州省|铜仁市'
      },
      {
        label: '中国工商银行股份有限公司铜仁铜江支行',
        value: '102705000163|5206|贵州省|铜仁市'
      },
      {
        label: '中国工商银行股份有限公司铜仁民主路支行',
        value: '102705000180|5206|贵州省|铜仁市'
      },
      {
        label: '中国工商银行股份有限公司铜仁延新路支行',
        value: '102705000202|5206|贵州省|铜仁市'
      },
      {
        label: '中国工商银行股份有限公司铜仁北关支行',
        value: '102705000219|5206|贵州省|铜仁市'
      },
      {
        label: '中国工商银行股份有限公司万山谢桥支行',
        value: '102705001133|5206|贵州省|铜仁市'
      },
      {
        label: '中国工商银行贵州省铜仁分行',
        value: '102705005006|5206|贵州省|铜仁市'
      },
      {
        label: '中国工商银行股份有限公司江口支行',
        value: '102705201144|5206|贵州省|铜仁市'
      },
      {
        label: '中国工商银行玉屏侗族自治县支行',
        value: '102705300095|5206|贵州省|铜仁市'
      },
      {
        label: '中国工商银行玉屏侗族自治县支行大龙分理处',
        value: '102705305058|5206|贵州省|铜仁市'
      },
      {
        label: '中国工商银行股份有限公司石阡支行',
        value: '102705400107|5206|贵州省|铜仁市'
      },
      {
        label: '中国工商银行思南县支行',
        value: '102705500128|5206|贵州省|铜仁市'
      },
      {
        label: '中国工商银行股份有限公司印江支行',
        value: '102705601156|5206|贵州省|铜仁市'
      },
      {
        label: '中国工商银行德江县支行',
        value: '102705700074|5206|贵州省|铜仁市'
      },
      {
        label: '中国工商银行沿河土家族自治县支行',
        value: '102705800065|5206|贵州省|铜仁市'
      },
      {
        label: '中国工商银行松桃苗族自治县支行',
        value: '102705900025|5206|贵州省|铜仁市'
      },
      {
        label: '中国工商银行万山特区支行',
        value: '102706100109|5206|贵州省|铜仁市'
      },
      {
        label: '中国工商银行兴义城区支行',
        value: '102707000011|5223|贵州省|黔西南布依族苗族自治州'
      },
      {
        label: '中国工商银行兴义顶效支行',
        value: '102707000020|5223|贵州省|黔西南布依族苗族自治州'
      },
      {
        label: '中国工商银行兴义西湖支行',
        value: '102707000038|5223|贵州省|黔西南布依族苗族自治州'
      },
      {
        label: '中国工商银行股份有限公司深圳东门支行',
        value: '102584002112|4403|广东省|深圳市'
      },
      {
        label: '中国工商银行股份有限公司深圳红围支行',
        value: '102584002129|4403|广东省|深圳市'
      },
      {
        label: '中国工商银行股份有限公司深圳百仕达支行',
        value: '102584002137|4403|广东省|深圳市'
      },
      {
        label: '中国工商银行股份有限公司深圳宝安支行',
        value: '102584002145|4403|广东省|深圳市'
      },
      {
        label: '中国工商银行股份有限公司深圳工业区支行',
        value: '102584002153|4403|广东省|深圳市'
      },
      {
        label: '中国工商银行股份有限公司深圳华联支行',
        value: '102584002161|4403|广东省|深圳市'
      },
      {
        label: '中国工商银行股份有限公司深圳振华支行',
        value: '102584002170|4403|广东省|深圳市'
      },
      {
        label: '中国工商银行股份有限公司深圳赤湾支行',
        value: '102584002196|4403|广东省|深圳市'
      },
      {
        label: '中国工商银行股份有限公司深圳坪山支行',
        value: '102584002207|4403|广东省|深圳市'
      },
      {
        label: '中国工商银行股份有限公司深圳华强支行',
        value: '102584002215|4403|广东省|深圳市'
      },
      {
        label: '中国工商银行股份有限公司深圳布吉支行',
        value: '102584002231|4403|广东省|深圳市'
      },
      {
        label: '中国工商银行股份有限公司深圳南油支行',
        value: '102584002240|4403|广东省|深圳市'
      },
      {
        label: '中国工商银行股份有限公司深圳沙井支行',
        value: '102584002258|4403|广东省|深圳市'
      },
      {
        label: '中国工商银行股份有限公司深圳松岗支行',
        value: '102584002266|4403|广东省|深圳市'
      },
      {
        label: '中国工商银行股份有限公司深圳福永支行',
        value: '102584002274|4403|广东省|深圳市'
      },
      {
        label: '中国工商银行股份有限公司深圳国贸支行',
        value: '102584002282|4403|广东省|深圳市'
      },
      {
        label: '中国工商银行深圳市分行营业部',
        value: '102584002303|4403|广东省|深圳市'
      },
      {
        label: '中国工商银行股份有限公司深圳麒麟支行',
        value: '102584002311|4403|广东省|深圳市'
      },
      {
        label: '中国工商银行股份有限公司深圳华侨城支行',
        value: '102584002320|4403|广东省|深圳市'
      },
      {
        label: '中国工商银行毕节杜鹃支行',
        value: '102709000023|5205|贵州省|毕节市'
      },
      {
        label: '中国工商银行股份有限公司毕节奢香支行',
        value: '102709000031|5205|贵州省|毕节市'
      },
      {
        label: '中国工商银行股份有限公司毕节威宁路支行',
        value: '102709000099|5205|贵州省|毕节市'
      },
      {
        label: '中国工商银行股份有限公司毕节碧阳支行',
        value: '102709000103|5205|贵州省|毕节市'
      },
      {
        label: '工行陕西省延安富县支行',
        value: '102804900019|6106|陕西省|延安市'
      },
      {
        label: '中国工商银行贵阳市白云支行艳山红分理处',
        value: '102701000738|5201|贵州省|贵阳市'
      },
      {
        label: '中国工商银行股份有限公司四川省自贡檀木林支行',
        value: '102655001229|5103|四川省|自贡市'
      },
      {
        label: '中国工商银行股份有限公司四川省自贡方冲支行',
        value: '102655001296|5103|四川省|自贡市'
      },
      {
        label: '中国工商银行股份有限公司自贡长征大道支行',
        value: '102655003023|5103|四川省|自贡市'
      },
      {
        label: '中国工商银行股份有限公司自贡分行业务处理中心',
        value: '102655030110|5103|四川省|自贡市'
      },
      {
        label: '中国工商银行股份有限公司自贡高新科技支行',
        value: '102655030232|5103|四川省|自贡市'
      },
      {
        label: '中国工商银行股份有限公司自贡分行',
        value: '102655030312|5103|四川省|自贡市'
      },
      {
        label: '中国工商银行自贡光大街支行',
        value: '102655030337|5103|四川省|自贡市'
      },
      {
        label: '中国工商银行自贡鸿鹤坝支行',
        value: '102655030353|5103|四川省|自贡市'
      },
      {
        label: '中国工商银行自贡丹桂支行',
        value: '102655030361|5103|四川省|自贡市'
      },
      {
        label: '中国工商银行股份有限公司自贡自流井支行',
        value: '102655030370|5103|四川省|自贡市'
      },
      {
        label: '中国工商银行股份有限公司自贡汇川支行',
        value: '102655030388|5103|四川省|自贡市'
      },
      {
        label: '中国工商银行自贡市大安区支行',
        value: '102655030415|5103|四川省|自贡市'
      },
      {
        label: '中国工商银行股份有限公司自贡分行南湖支行',
        value: '102655030431|5103|四川省|自贡市'
      },
      {
        label: '中国工商银行自贡广华山支行',
        value: '102655030440|5103|四川省|自贡市'
      },
      {
        label: '中国工商银行自贡市贡井区支行',
        value: '102655030511|5103|四川省|自贡市'
      },
      {
        label: '中国工商银行股份有限公司四川省自贡马吃水支行',
        value: '102655030538|5103|四川省|自贡市'
      },
      {
        label: '中国工商银行自贡市沿滩区支行',
        value: '102655030618|5103|四川省|自贡市'
      },
      {
        label: '中国工商银行自贡五星街支行',
        value: '102655062035|5103|四川省|自贡市'
      },
      {
        label: '中国工商银行股份有限公司荣县支行',
        value: '102655130817|5103|四川省|自贡市'
      },
      {
        label: '中国工商银行富顺县支行',
        value: '102655230717|5103|四川省|自贡市'
      },
      {
        label: '中国工商银行股份有限公司四川省自贡富顺晨光支行',
        value: '102655230725|5103|四川省|自贡市'
      },
      {
        label: '中国工商银行股份有限公司富顺北湖支行',
        value: '102655230733|5103|四川省|自贡市'
      },
      {
        label: '中国工商银行股份有限公司富顺东湖支行',
        value: '102655230741|5103|四川省|自贡市'
      },
      {
        label: '中国工商银行股份有限公司攀枝花学园路支行',
        value: '102656000043|5104|四川省|攀枝花市'
      },
      {
        label: '中国工商银行股份有限公司攀枝花分行人民街支行',
        value: '102656000192|5104|四川省|攀枝花市'
      },
      {
        label: '中国工商银行股份有限公司攀枝花分行业务处理中心',
        value: '102656032115|5104|四川省|攀枝花市'
      },
      {
        label: '中国工商银行股份有限公司攀枝花大渡口支行',
        value: '102656032318|5104|四川省|攀枝花市'
      },
      {
        label: '中国工商银行股份有限公司桂林市临桂支行',
        value: '102617221820|4503|广西壮族自治区|桂林市'
      },
      {
        label: '中国工商银行股份有限公司桂林市临桂金山支行',
        value: '102617228185|4503|广西壮族自治区|桂林市'
      },
      {
        label: '中国工商银行股份有限公司灵川县灵南路支行',
        value: '102618123913|4503|广西壮族自治区|桂林市'
      },
      {
        label: '中国工商银行灵川县支行',
        value: '102618123921|4503|广西壮族自治区|桂林市'
      },
      {
        label: '中国工商银行股份有限公司灵川县八里街支行',
        value: '102618123948|4503|广西壮族自治区|桂林市'
      },
      {
        label: '中国工商银行全州县支行',
        value: '102618223019|4503|广西壮族自治区|桂林市'
      },
      {
        label: '中国工商银行股份有限公司全州县桂黄路分理处',
        value: '102618223027|4503|广西壮族自治区|桂林市'
      },
      {
        label: '中国工商银行兴安县支行',
        value: '102618322118|4503|广西壮族自治区|桂林市'
      },
      {
        label: '中国工商银行永福县支行',
        value: '102618422419|4503|广西壮族自治区|桂林市'
      },
      {
        label: '中国工商银行平乐县支行',
        value: '102618823311|4503|广西壮族自治区|桂林市'
      },
      {
        label: '中国工商银行荔浦县支行',
        value: '102618923617|4503|广西壮族自治区|桂林市'
      },
      {
        label: '中国工商银行梧州分行',
        value: '102621030507|4504|广西壮族自治区|梧州市'
      },
      {
        label: '中国工商银行梧州分行营业部',
        value: '102621031001|4504|广西壮族自治区|梧州市'
      },
      {
        label: '中国工商银行梧州分行市区支行',
        value: '102621032004|4504|广西壮族自治区|梧州市'
      },
      {
        label: '中国工商银行梧州分行河西支行',
        value: '102621033007|4504|广西壮族自治区|梧州市'
      },
      {
        label: '中国工商银行股份有限公司梧州市金苑支行',
        value: '102621033099|4504|广西壮族自治区|梧州市'
      },
      {
        label: '中国工商银行股份有限公司梧州市新湖支行',
        value: '102621033103|4504|广西壮族自治区|梧州市'
      },
      {
        label: '中国工商银行梧州分行大东支行',
        value: '102621035002|4504|广西壮族自治区|梧州市'
      },
      {
        label: '中国工商银行苍梧县支行',
        value: '102621130202|4504|广西壮族自治区|梧州市'
      },
      {
        label: '中国工商银行股份有限公司苍梧县峡顶支行',
        value: '102621130227|4504|广西壮族自治区|梧州市'
      },
      {
        label: '中国工商银行股份有限公司攀枝花炳草岗支行',
        value: '102656032810|5104|四川省|攀枝花市'
      },
      {
        label: '中国工商银行股份有限公司毕节清毕路支行',
        value: '102709000111|5205|贵州省|毕节市'
      },
      {
        label: '中国工商银行股份有限公司毕节分行金海湖支行',
        value: '102709000120|5205|贵州省|毕节市'
      },
      {
        label: '中国工商银行贵州省毕节分行会计业务处理中心',
        value: '102709005003|5205|贵州省|毕节市'
      },
      {
        label: '中国工商银行大方县支行',
        value: '102709200041|5205|贵州省|毕节市'
      },
      {
        label: '中国工商银行股份有限公司大方西大街支行',
        value: '102709200130|5205|贵州省|毕节市'
      },
      {
        label: '中国工商银行黔西县支行',
        value: '102709300059|5205|贵州省|毕节市'
      },
      {
        label: '中国工商银行股份有限公司冕宁支行',
        value: '102685463338|5134|四川省|凉山彝族自治州'
      },
      {
        label: '中国工商银行株洲渌口支行',
        value: '102552100517|4302|湖南省|株洲市'
      },
      {
        label: '中国工商银行攸县支行',
        value: '102552202194|4302|湖南省|株洲市'
      },
      {
        label: '中国工商银行股份有限公司茶陵支行交通街分理处',
        value: '102552300025|4302|湖南省|株洲市'
      },
      {
        label: '中国工商银行股份有限公司茶陵支行',
        value: '102552302254|4302|湖南省|株洲市'
      },
      {
        label: '中国工商银行股份有限公司醴陵支行胜利路分理处',
        value: '102552500077|4302|湖南省|株洲市'
      },
      {
        label: '中国工商银行股份有限公司醴陵支行新街储蓄所',
        value: '102552500093|4302|湖南省|株洲市'
      },
      {
        label: '中国工商银行醴陵市支行',
        value: '102552502162|4302|湖南省|株洲市'
      },
      {
        label: '中国工商银行株洲市醴陵支行解放路分理处',
        value: '102552502517|4302|湖南省|株洲市'
      },
      {
        label: '中国工商银行株洲市醴陵支行阳三石分理处',
        value: '102552502525|4302|湖南省|株洲市'
      },
      {
        label: '中国工商银行股份有限公司炎陵支行',
        value: '102552603003|4302|湖南省|株洲市'
      },
      {
        label: '中国工商银行股份有限公司湘潭县支行',
        value: '102553002926|4303|湖南省|湘潭市'
      },
      {
        label: '中国工商银行股份有限公司湘潭市分行',
        value: '102553003025|4303|湖南省|湘潭市'
      },
      {
        label: '中国工商银行股份有限公司湘潭市湘江支行',
        value: '102553003033|4303|湖南省|湘潭市'
      },
      {
        label: '中国工商银行股份有限公司湘潭市雨湖支行',
        value: '102553003050|4303|湖南省|湘潭市'
      },
      {
        label: '中国工商银行股份有限公司湘潭白石支行',
        value: '102553003068|4303|湖南省|湘潭市'
      },
      {
        label: '中国工商银行股份有限公司湘潭建北支行',
        value: '102553003084|4303|湖南省|湘潭市'
      },
      {
        label: '中国工商银行股份有限公司湘潭板塘支行',
        value: '102553003092|4303|湖南省|湘潭市'
      },
      {
        label: '中国工商银行股份有限公司湘潭岳塘支行',
        value: '102553003113|4303|湖南省|湘潭市'
      },
      {
        label: '中国工商银行股份有限公司深圳新澜支行',
        value: '102584002686|4403|广东省|深圳市'
      },
      {
        label: '中国工商银行股份有限公司深圳石岩支行',
        value: '102584002717|4403|广东省|深圳市'
      },
      {
        label: '中国工商银行股份有限公司深圳高新园南区支行',
        value: '102584002725|4403|广东省|深圳市'
      },
      {
        label: '中国工商银行股份有限公司深圳西丽支行',
        value: '102584002741|4403|广东省|深圳市'
      },
      {
        label: '中国工商银行股份有限公司深圳福虹支行',
        value: '102584002750|4403|广东省|深圳市'
      },
      {
        label: '中国工商银行股份有限公司深圳坪地支行',
        value: '102584002768|4403|广东省|深圳市'
      },
      {
        label: '中国工商银行股份有限公司深圳深圳湾支行',
        value: '102584002776|4403|广东省|深圳市'
      },
      {
        label: '中国工商银行股份有限公司深圳公明支行',
        value: '102584002784|4403|广东省|深圳市'
      },
      {
        label: '中国工商银行股份有限公司深圳国财支行',
        value: '102584002792|4403|广东省|深圳市'
      },
      {
        label: '中国工商银行股份有限公司深圳福园支行',
        value: '102584002805|4403|广东省|深圳市'
      },
      {
        label: '中国工商银行深圳梅林一村支行',
        value: '102584002821|4403|广东省|深圳市'
      },
      {
        label: '中国工商银行云南大理分行牡丹支行',
        value: '102751015073|5329|云南省|大理白族自治州'
      },
      {
        label: '中国工商银行云南大理分行祥云县城区中心分理处',
        value: '102751310602|5329|云南省|大理白族自治州'
      },
      {
        label: '中国工商银行华亭县支行',
        value: '102833500050|6208|甘肃省|平凉市'
      },
      {
        label: '中国工商银行股份有限公司华亭新城支行',
        value: '102833500068|6208|甘肃省|平凉市'
      },
      {
        label: '中国工商银行华亭县支行安口分理处',
        value: '102833500164|6208|甘肃省|平凉市'
      },
      {
        label: '中国工商银行股份有限公司华亭西关支行',
        value: '102833500277|6208|甘肃省|平凉市'
      },
      {
        label: '中国工商银行股份有限公司庄浪支行',
        value: '102833600083|6208|甘肃省|平凉市'
      },
      {
        label: '中国工商银行静宁县支行',
        value: '102833700077|6208|甘肃省|平凉市'
      },
      {
        label: '中国工商银行股份有限公司庆阳分行业务处理中心',
        value: '102834000014|6210|甘肃省|庆阳市'
      },
      {
        label: '中国工商银行股份有限公司西峰中街支行',
        value: '102834000022|6210|甘肃省|庆阳市'
      },
      {
        label: '中国工商银行股份有限公司庆阳分行营业室',
        value: '102834000071|6210|甘肃省|庆阳市'
      },
      {
        label: '中国工商银行股份有限公司西峰北街支行',
        value: '102834000098|6210|甘肃省|庆阳市'
      },
      {
        label: '中国工商银行股份有限公司西峰东街支行',
        value: '102834000102|6210|甘肃省|庆阳市'
      },
      {
        label: '中国工商银行股份有限公司西峰南街支行',
        value: '102834000209|6210|甘肃省|庆阳市'
      },
      {
        label: '中国工商银行股份有限公司甘谷县支行',
        value: '102825300486|6205|甘肃省|天水市'
      },
      {
        label: '中国工商银行股份有限公司武山县支行',
        value: '102825400475|6205|甘肃省|天水市'
      },
      {
        label: '中国工商银行股份有限公司武山洛门支行',
        value: '102825400506|6205|甘肃省|天水市'
      },
      {
        label: '中国工商银行股份有限公司天水张家川支行',
        value: '102825500603|6205|甘肃省|天水市'
      },
      {
        label: '中国工商银行股份有限公司酒泉分行',
        value: '102826000014|6209|甘肃省|酒泉市'
      },
      {
        label: '中国工商银行股份有限公司酒泉肃州支行',
        value: '102826000022|6209|甘肃省|酒泉市'
      },
      {
        label: '中国工商银行股份有限公司酒泉西关支行',
        value: '102826000098|6209|甘肃省|酒泉市'
      },
      {
        label: '中国工商银行股份有限公司酒泉广场东路支行',
        value: '102826000119|6209|甘肃省|酒泉市'
      },
      {
        label: '中国工商银行股份有限公司酒泉鼓楼支行',
        value: '102826000127|6209|甘肃省|酒泉市'
      },
      {
        label: '中国工商银行股份有限公司酒泉东街支行',
        value: '102826000135|6209|甘肃省|酒泉市'
      },
      {
        label: '中国工商银行股份有限公司酒泉西街支行',
        value: '102826000151|6209|甘肃省|酒泉市'
      },
      {
        label: '中国工商银行股份有限公司酒泉解放路支行',
        value: '102826000178|6209|甘肃省|酒泉市'
      },
      {
        label: '中国工商银行股份有限公司武威分行西城支行',
        value: '102828000083|6206|甘肃省|武威市'
      },
      {
        label: '中国工商银行股份有限公司武威分行南关支行',
        value: '102828000091|6206|甘肃省|武威市'
      },
      {
        label: '中国工商银行股份有限公司武威天马路支行',
        value: '102828000171|6206|甘肃省|武威市'
      },
      {
        label: '中国工商银行股份有限公司武威分行阜康路支行',
        value: '102828000198|6206|甘肃省|武威市'
      },
      {
        label: '中国工商银行股份有限公司武威南关中路支行',
        value: '102828000202|6206|甘肃省|武威市'
      },
      {
        label: '中国工商银行股份有限公司眉山分行新区大市场分理处',
        value: '102665206703|5111|四川省|乐山市'
      },
      {
        label: '中国工商银行眉山市分行',
        value: '102665239911|5111|四川省|乐山市'
      },
      {
        label: '中国工商银行股份有限公司眉山东坡支行',
        value: '102665239920|5111|四川省|乐山市'
      },
      {
        label: '中国工商银行股份有限公司眉山西门支行',
        value: '102665239938|5111|四川省|乐山市'
      },
      {
        label: '中国工商银行股份有限公司眉山红星路支行',
        value: '102665239954|5111|四川省|乐山市'
      },
      {
        label: '中国工商银行股份有限公司四川省眉山分行崇仁支行',
        value: '102665239962|5111|四川省|乐山市'
      },
      {
        label: '中国工商银行眉山市分行业务处理中心',
        value: '102665240418|5111|四川省|乐山市'
      },
      {
        label: '中国工商银行股份有限公司犍为支行',
        value: '102665338819|5111|四川省|乐山市'
      },
      {
        label: '中国工商银行股份有限公司犍为新城支行',
        value: '102665338827|5111|四川省|乐山市'
      },
      {
        label: '中国工商银行股份有限公司井研支行',
        value: '102665438461|5111|四川省|乐山市'
      },
      {
        label: '中国工商银行股份有限公司夹江东大街支行',
        value: '102665502519|5111|四川省|乐山市'
      },
      {
        label: '中国工商银行股份有限公司夹江支行',
        value: '102665539019|5111|四川省|乐山市'
      },
      {
        label: '中国工商银行股份有限公司夹江广场支行',
        value: '102665539051|5111|四川省|乐山市'
      },
      {
        label: '中国工商银行洪雅县支行',
        value: '102665640111|5111|四川省|乐山市'
      },
      {
        label: '中国工商银行股份有限公司洪雅广场支行',
        value: '102665640120|5111|四川省|乐山市'
      },
      {
        label: '中国工商银行股份有限公司彭山青龙支行',
        value: '102665707530|5111|四川省|乐山市'
      },
      {
        label: '中国工商银行股份有限公司彭山支行北街分理处',
        value: '102665707548|5111|四川省|乐山市'
      },
      {
        label: '中国工商银行彭山县支行',
        value: '102665740011|5111|四川省|乐山市'
      },
      {
        label: '中国工商银行股份有限公司沐川支行',
        value: '102665838915|5111|四川省|乐山市'
      },
      {
        label: '中国工商银行股份有限公司四川省眉山分行青神支行',
        value: '102665940312|5114|四川省|眉山市'
      },
      {
        label: '中国工商银行股份有限公司峨边支行',
        value: '102666239212|5111|四川省|乐山市'
      },
      {
        label: '中国工商银行股份有限公司乐山金口河支行',
        value: '102666239237|5111|四川省|乐山市'
      },
      {
        label: '中国工商银行股份有限公司峨眉山景区支行',
        value: '102666402064|5111|四川省|乐山市'
      },
      {
        label: '中国工商银行股份有限公司峨眉山支行',
        value: '102666438715|5111|四川省|乐山市'
      },
      {
        label: '中国工商银行股份有限公司峨眉山燕岗支行',
        value: '102666438731|5111|四川省|乐山市'
      },
      {
        label: '中国工商银行股份有限公司峨眉山名山路支行',
        value: '102666438774|5111|四川省|乐山市'
      },
      {
        label: '中国工商银行股份有限公司峨眉山红华苑支行',
        value: '102666438782|5111|四川省|乐山市'
      },
      {
        label: '中国工商银行丹棱县支行',
        value: '102666540214|5114|四川省|眉山市'
      },
      {
        label: '中国工商银行股份有限公司重庆太白支行',
        value: '102667000805|5114|四川省|眉山市'
      },
      {
        label: '中国工商银行股份有限公司重庆龙宝支行',
        value: '102667000813|5114|四川省|眉山市'
      },
      {
        label: '中国工商银行股份有限公司重庆天城支行',
        value: '102667000821|5114|四川省|眉山市'
      },
      {
        label: '中国工商银行股份有限公司石棉支行',
        value: '102677561817|5118|四川省|雅安市'
      },
      {
        label: '中国工商银行股份有限公司天全支行',
        value: '102677661914|5118|四川省|雅安市'
      },
      {
        label: '中国工商银行股份有限公司芦山支行',
        value: '102677761470|5118|四川省|雅安市'
      },
      {
        label: '中国工商银行股份有限公司阿坝直属支行业务处理中心',
        value: '102679000013|5132|四川省|阿坝藏族羌族自治州'
      },
      {
        label: '中国工商银行股份有限公司阿坝直属支行',
        value: '102679010006|5132|四川省|阿坝藏族羌族自治州'
      },
      {
        label: '中国工商银行九寨沟分理处',
        value: '102679524517|5132|四川省|阿坝藏族羌族自治州'
      },
      {
        label: '中国工商银行股份有限公司甘孜直属支行业务处理中心',
        value: '102681000014|5133|四川省|甘孜藏族自治州'
      },
      {
        label: '中国工商银行股份有限公司甘孜直属支行',
        value: '102681010001|5133|四川省|甘孜藏族自治州'
      },
      {
        label: '中国工商银行股份有限公司四川省凉山商业街支行',
        value: '102684000149|5134|四川省|凉山彝族自治州'
      },
      {
        label: '中国工商银行股份有限公司凉山经久工业园区支行',
        value: '102684000868|5134|四川省|凉山彝族自治州'
      },
      {
        label: '中国工商银行股份有限公司凉山分行业务处理中心',
        value: '102684063117|5134|四川省|凉山彝族自治州'
      },
      {
        label: '中国工商银行股份有限公司凉山分行',
        value: '102684063213|5134|四川省|凉山彝族自治州'
      },
      {
        label: '中国工商银行股份有限公司凉山分行马道分理处',
        value: '102684063221|5134|四川省|凉山彝族自治州'
      },
      {
        label: '中国工商银行股份有限公司凉山三岔口支行',
        value: '102684063230|5134|四川省|凉山彝族自治州'
      },
      {
        label: '中国工商银行股份有限公司凉山城南支行',
        value: '102684063248|5134|四川省|凉山彝族自治州'
      },
      {
        label: '中国工商银行股份有限公司四川省凉山州分行航天城支行',
        value: '102684063264|5134|四川省|凉山彝族自治州'
      },
      {
        label: '工行陕西省汉中南环路支行',
        value: '102799060315|6107|陕西省|汉中市'
      },
      {
        label: '中国工商银行贵阳市白云支行广九分理处',
        value: '102701000746|5201|贵州省|贵阳市'
      },
      {
        label: '中国工商银行股份有限公司贵阳市威清路分理处',
        value: '102701000762|5201|贵州省|贵阳市'
      },
      {
        label: '中国工商银行股份有限公司贵阳市山林路分理处',
        value: '102701000779|5201|贵州省|贵阳市'
      },
      {
        label: '中国工商银行股份有限公司贵阳黄金路支行',
        value: '102701000787|5201|贵州省|贵阳市'
      },
      {
        label: '中国工商银行股份有限公司贵阳云岩支行宝山支行',
        value: '102701000800|5201|贵州省|贵阳市'
      },
      {
        label: '中国工商银行股份有限公司贵阳省政府支行',
        value: '102701000818|5201|贵州省|贵阳市'
      },
      {
        label: '中国工商银行股份有限公司贵阳高新区科技支行',
        value: '102701000826|5201|贵州省|贵阳市'
      },
      {
        label: '中国工商银行股份有限公司贵安支行',
        value: '102701000842|5201|贵州省|贵阳市'
      },
      {
        label: '中国工商银行股份有限公司贵阳交际处支行',
        value: '102701000859|5201|贵州省|贵阳市'
      },
      {
        label: '中国工商银行股份有限公司贵阳宅吉支行',
        value: '102701000867|5201|贵州省|贵阳市'
      },
      {
        label: '中国工商银行股份有限公司贵州省分行营业部银行卡中心',
        value: '102701000875|5201|贵州省|贵阳市'
      },
      {
        label: '中国工商银行股份有限公司贵阳文化路支行',
        value: '102701000906|5201|贵州省|贵阳市'
      },
      {
        label: '中国工商银行股份有限公司贵阳花果园支行',
        value: '102701000914|5201|贵州省|贵阳市'
      },
      {
        label: '中国工商银行股份有限公司贵阳贵溪支行解五路分理处',
        value: '102701000922|5201|贵州省|贵阳市'
      },
      {
        label: '中国工商银行股份有限公司贵安马场支行',
        value: '102701000939|5201|贵州省|贵阳市'
      },
      {
        label: '中国工商银行股份有限公司广州新塘凤凰城支行',
        value: '102581005575|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州开创大道支行',
        value: '102581005591|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州誉峰支行',
        value: '102581005606|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州龙洞支行',
        value: '102581005614|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州太和支行',
        value: '102581005622|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州番禺节能科技园支行',
        value: '102581005647|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州花都紫薇支行',
        value: '102581005663|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州琶洲支行',
        value: '102581005698|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司贵阳大营坡支行',
        value: '102701013461|5201|贵州省|贵阳市'
      },
      {
        label: '中国工商银行股份有限公司贵阳清溪路支行',
        value: '102701013470|5201|贵州省|贵阳市'
      },
      {
        label: '中国工商银行股份有限公司贵阳锦江路支行',
        value: '102701013488|5201|贵州省|贵阳市'
      },
      {
        label: '中国工商银行股份有限公司贵阳大理石路支行',
        value: '102701013496|5201|贵州省|贵阳市'
      },
      {
        label: '中国工商银行股份有限公司贵阳黄河路支行',
        value: '102701013507|5201|贵州省|贵阳市'
      },
      {
        label: '中国工商银行股份有限公司六盘水荷城支行',
        value: '102702000029|5202|贵州省|六盘水市'
      },
      {
        label: '中国工商银行股份有限公司六盘水龙井支行',
        value: '102702000037|5202|贵州省|六盘水市'
      },
      {
        label: '中国工商银行股份有限公司六盘水凉都宫支行',
        value: '102702000045|5202|贵州省|六盘水市'
      },
      {
        label: '中国工商银行股份有限公司六盘水水钢支行',
        value: '102702000053|5202|贵州省|六盘水市'
      },
      {
        label: '中国工商银行股份有限公司六盘水麒麟支行',
        value: '102702000061|5202|贵州省|六盘水市'
      },
      {
        label: '中国工商银行股份有限公司水城支行',
        value: '102702000182|5202|贵州省|六盘水市'
      },
      {
        label: '中国工商银行股份有限公司六盘水钟山支行',
        value: '102702000199|5202|贵州省|六盘水市'
      },
      {
        label: '中国工商银行股份有限公司六盘水明湖路支行',
        value: '102702000211|5202|贵州省|六盘水市'
      },
      {
        label: '中国工商银行六盘水分行',
        value: '102702005008|5202|贵州省|六盘水市'
      },
      {
        label: '中国工商银行股份有限公司六盘水白鹤支行',
        value: '102702005032|5202|贵州省|六盘水市'
      },
      {
        label: '中国工商银行股份有限公司六盘水钟山路建业支行',
        value: '102702005049|5202|贵州省|六盘水市'
      },
      {
        label: '中国工商银行股份有限公司六盘水广场支行',
        value: '102702005065|5202|贵州省|六盘水市'
      },
      {
        label: '中国工商银行股份有限公司六盘水钟山经济开发区支行',
        value: '102702005081|5202|贵州省|六盘水市'
      },
      {
        label: '中国工商银行股份有限公司六枝特区支行',
        value: '102702100072|5202|贵州省|六盘水市'
      },
      {
        label: '中国工商银行股份有限公司六枝特区平寨支行',
        value: '102702100089|5202|贵州省|六盘水市'
      },
      {
        label: '工行陕西省渭南蒲城县正街支行',
        value: '102797755104|6105|陕西省|渭南市'
      },
      {
        label: '中国工商银行渭南澄城县支行',
        value: '102797850185|6105|陕西省|渭南市'
      },
      {
        label: '工商银行澄城县七路支行',
        value: '102797850337|6105|陕西省|渭南市'
      },
      {
        label: '工行陕西省渭南澄城县古徵支行',
        value: '102797850353|6105|陕西省|渭南市'
      },
      {
        label: '中国工商银行陕西省渭南白水县支行',
        value: '102797950190|6105|陕西省|渭南市'
      },
      {
        label: '工行合阳县支行',
        value: '102798150235|6105|陕西省|渭南市'
      },
      {
        label: '中国工商银行渭南富平县支行',
        value: '102798250241|6105|陕西省|渭南市'
      },
      {
        label: '中国工商银行股份有限公司深圳罗湖支行',
        value: '102584002049|4403|广东省|深圳市'
      },
      {
        label: '中国工商银行股份有限公司深圳洪湖支行',
        value: '102584002057|4403|广东省|深圳市'
      },
      {
        label: '中国工商银行股份有限公司深圳九州支行',
        value: '102584002065|4403|广东省|深圳市'
      },
      {
        label: '中国工商银行股份有限公司崇左分行',
        value: '102612815507|4514|广西壮族自治区|崇左市'
      },
      {
        label: '中国工商银行股份有限公司大新县支行',
        value: '102612912009|4514|广西壮族自治区|崇左市'
      },
      {
        label: '中国工商银行股份有限公司宁明县支行',
        value: '102613212600|4514|广西壮族自治区|崇左市'
      },
      {
        label: '中国工商银行股份有限公司龙州县支行',
        value: '102613312500|4514|广西壮族自治区|崇左市'
      },
      {
        label: '中国工商银行柳州分行',
        value: '102614040001|4502|广西壮族自治区|柳州市'
      },
      {
        label: '中国工商银行柳州市城中支行',
        value: '102614040108|4502|广西壮族自治区|柳州市'
      },
      {
        label: '中国工商银行柳州分行鱼峰支行',
        value: '102614040204|4502|广西壮族自治区|柳州市'
      },
      {
        label: '中国工商银行柳州分行龙城支行',
        value: '102614040307|4502|广西壮族自治区|柳州市'
      },
      {
        label: '中国工商银行柳州分行铁路支行',
        value: '102614040403|4502|广西壮族自治区|柳州市'
      },
      {
        label: '中国工商银行股份有限公司五华支行',
        value: '102596402616|4414|广东省|梅州市'
      },
      {
        label: '中国工商银行股份有限公司德昌支行',
        value: '102685963321|5134|四川省|凉山彝族自治州'
      },
      {
        label: '中国工商银行股份有限公司重庆黔州支行',
        value: '102687000988|5119|四川省|巴中市'
      },
      {
        label: '中国工商银行股份有限公司重庆黔江分行',
        value: '102687001198|5119|四川省|巴中市'
      },
      {
        label: '中国工商银行股份有限公司黔江正阳支行',
        value: '102687002002|5119|四川省|巴中市'
      },
      {
        label: '中国工商银行股份有限公司重庆市石柱县支行',
        value: '102687101003|5119|四川省|巴中市'
      },
      {
        label: '中国工商银行股份有限公司秀山渝秀支行',
        value: '102687200041|5119|四川省|巴中市'
      },
      {
        label: '中国工商银行股份有限公司重庆市秀山县支行',
        value: '102687201028|5119|四川省|巴中市'
      },
      {
        label: '中国工商银行股份有限公司重庆市酉阳县支行',
        value: '102687401012|5119|四川省|巴中市'
      },
      {
        label: '中国工商银行股份有限公司酉阳支行桃花源分理处',
        value: '102687403840|5119|四川省|巴中市'
      },
      {
        label: '中国工商银行股份有限公司重庆市彭水县支行',
        value: '102687500998|5119|四川省|巴中市'
      },
      {
        label: '中国工商银行股份有限公司彭水迎宾大道支行',
        value: '102687513017|5119|四川省|巴中市'
      },
      {
        label: '中国工商银行贵阳市中西支行',
        value: '102701000019|5201|贵州省|贵阳市'
      },
      {
        label: '中国工商银行贵阳市东山支行',
        value: '102701000027|5201|贵州省|贵阳市'
      },
      {
        label: '中国工商银行贵阳市中华路支行',
        value: '102701000035|5201|贵州省|贵阳市'
      },
      {
        label: '中国工商银行股份有限公司贵阳中山支行',
        value: '102701000043|5201|贵州省|贵阳市'
      },
      {
        label: '中国工商银行贵阳市云岩支行',
        value: '102701000051|5201|贵州省|贵阳市'
      },
      {
        label: '中国工商银行股份有限公司贵阳马王庙支行',
        value: '102701000060|5201|贵州省|贵阳市'
      },
      {
        label: '中国工商银行股份有限公司贵阳三桥支行',
        value: '102701000078|5201|贵州省|贵阳市'
      },
      {
        label: '中国工商银行股份有限公司贵阳汽车站支行',
        value: '102701000086|5201|贵州省|贵阳市'
      },
      {
        label: '中国工商银行股份有限公司贵阳省新支行',
        value: '102701000094|5201|贵州省|贵阳市'
      },
      {
        label: '中国工商银行股份有限公司贵阳诚信路支行',
        value: '102701000109|5201|贵州省|贵阳市'
      },
      {
        label: '中国工商银行股份有限公司贵阳南明支行',
        value: '102701000117|5201|贵州省|贵阳市'
      },
      {
        label: '中国工商银行股份有限公司贵阳遵义路支行',
        value: '102701000125|5201|贵州省|贵阳市'
      },
      {
        label: '中国工商银行股份有限公司贵阳市油榨支行',
        value: '102701000133|5201|贵州省|贵阳市'
      },
      {
        label: '中国工商银行股份有限公司贵阳火车站支行',
        value: '102701000150|5201|贵州省|贵阳市'
      },
      {
        label: '中国工商银行贵阳市贵溪支行',
        value: '102701000168|5201|贵州省|贵阳市'
      },
      {
        label: '中国工商银行股份有限公司贵阳甘荫塘支行',
        value: '102701000176|5201|贵州省|贵阳市'
      },
      {
        label: '中国工商银行丹凤县支行',
        value: '102803380020|6110|陕西省|商洛市'
      },
      {
        label: '中国工商银行山阳县支行',
        value: '102803580042|6110|陕西省|商洛市'
      },
      {
        label: '中国工商银行镇安县支行',
        value: '102803680055|6110|陕西省|商洛市'
      },
      {
        label: '工行陕西省镇安县十字口支行',
        value: '102803680143|6110|陕西省|商洛市'
      },
      {
        label: '工行陕西省延安东盛支行',
        value: '102804000134|6106|陕西省|延安市'
      },
      {
        label: '工行陕西省延安高峁湾分理处',
        value: '102804000239|6106|陕西省|延安市'
      },
      {
        label: '工行陕西省延安丽景支行',
        value: '102804000247|6106|陕西省|延安市'
      },
      {
        label: '工行陕西省延安万花路支行',
        value: '102804000263|6106|陕西省|延安市'
      },
      {
        label: '中国工商银行延安市宝塔区支行',
        value: '102804090016|6106|陕西省|延安市'
      },
      {
        label: '中国工商银行延安市宝塔区支行姚店分理处',
        value: '102804090049|6106|陕西省|延安市'
      },
      {
        label: '工行陕西省延安双拥大道支行',
        value: '102804090161|6106|陕西省|延安市'
      },
      {
        label: '中国工商银行延安市七里铺支行',
        value: '102804090170|6106|陕西省|延安市'
      },
      {
        label: '工商银行延安市中心街支行苑中路分理处',
        value: '102804090188|6106|陕西省|延安市'
      },
      {
        label: '中国工商银行延安兰家坪支行',
        value: '102804090196|6106|陕西省|延安市'
      },
      {
        label: '中国工商银行延安分行业务处理中心',
        value: '102804095004|6106|陕西省|延安市'
      },
      {
        label: '中国工商银行股份有限公司塔城分行信用卡部',
        value: '102901000056|6542|新疆维吾尔自治区|塔城地区'
      },
      {
        label: '中国工商银行股份有限公司额敏支行',
        value: '102901200015|6542|新疆维吾尔自治区|塔城地区'
      },
      {
        label: '中国工商银行股份有限公司额敏农贸市场支行',
        value: '102901200023|6542|新疆维吾尔自治区|塔城地区'
      },
      {
        label: '中国工商银行股份有限公司乌苏支行',
        value: '102901300016|6542|新疆维吾尔自治区|塔城地区'
      },
      {
        label: '中国工商银行股份有限公司沙湾支行',
        value: '102901400017|6542|新疆维吾尔自治区|塔城地区'
      },
      {
        label: '中国工商银行股份有限公司沙湾大十字支行',
        value: '102901400025|6542|新疆维吾尔自治区|塔城地区'
      },
      {
        label: '中国工商银行股份有限公司阿勒泰分行业务处理中心',
        value: '102902000015|6543|新疆维吾尔自治区|阿勒泰地区'
      },
      {
        label: '中国工商银行股份有限公司阿勒泰解放路支行',
        value: '102902012016|6543|新疆维吾尔自治区|阿勒泰地区'
      },
      {
        label: '中国工商银行股份有限公司布尔津支行',
        value: '102902200017|6543|新疆维吾尔自治区|阿勒泰地区'
      },
      {
        label: '中国工商银行股份有限公司富蕴支行',
        value: '102902300018|6543|新疆维吾尔自治区|阿勒泰地区'
      },
      {
        label: '中国工商银行股份有限公司福海支行',
        value: '102902418010|6543|新疆维吾尔自治区|阿勒泰地区'
      },
      {
        label: '中国工商银行股份有限公司哈巴河支行',
        value: '102902500019|6543|新疆维吾尔自治区|阿勒泰地区'
      },
      {
        label: '中国工商银行股份有限公司石河子分行业务处理中心',
        value: '102902800013|6590|新疆维吾尔自治区|自治区直辖县级行政区划'
      },
      {
        label: '工行陕西省岐山县蔡家坡支行',
        value: '102793330234|6103|陕西省|宝鸡市'
      },
      {
        label: '中国工商银行股份有限公司乐至支行北街分理处',
        value: '102663208039|5110|四川省|内江市'
      },
      {
        label: '中国工商银行股份有限公司四川省资阳乐至县支行',
        value: '102663249311|5110|四川省|内江市'
      },
      {
        label: '中国工商银行股份有限公司乐至帅乡大道支行',
        value: '102663249320|5110|四川省|内江市'
      },
      {
        label: '中国工商银行股份有限公司安岳支行顺城街分理处',
        value: '102663302024|5120|四川省|资阳市'
      },
      {
        label: '中国工商银行云南普洱分行振兴路支行',
        value: '102747008094|5308|云南省|普洱市'
      },
      {
        label: '中国工商银行云南普洱分行思茅支行',
        value: '102747008109|5308|云南省|普洱市'
      },
      {
        label: '中国工商银行云南普洱分行花园支行',
        value: '102747008117|5308|云南省|普洱市'
      },
      {
        label: '中国工商银行云南普洱分行边城路支行',
        value: '102747008125|5308|云南省|普洱市'
      },
      {
        label: '中国工商银行云南普洱分行振兴南路支行',
        value: '102747008133|5308|云南省|普洱市'
      },
      {
        label: '中国工商银行云南普洱分行振兴北路支行',
        value: '102747008141|5308|云南省|普洱市'
      },
      {
        label: '中国工商银行云南普洱分行茶都支行',
        value: '102747008168|5308|云南省|普洱市'
      },
      {
        label: '中国工商银行云南普洱分行宁洱支行',
        value: '102747208029|5308|云南省|普洱市'
      },
      {
        label: '中国工商银行云南西双版纳分行会计业务处理中心',
        value: '102749009017|5328|云南省|西双版纳傣族自治州'
      },
      {
        label: '中国工商银行云南西双版纳分行景洪宣慰支行',
        value: '102749009041|5328|云南省|西双版纳傣族自治州'
      },
      {
        label: '中国工商银行云南西双版纳分行景洪开发区支行',
        value: '102749009050|5328|云南省|西双版纳傣族自治州'
      },
      {
        label: '中国工商银行云南西双版纳分行景洪东路支行',
        value: '102749009068|5328|云南省|西双版纳傣族自治州'
      },
      {
        label: '中国工商银行云南西双版纳分行景洪牡丹支行',
        value: '102749009076|5328|云南省|西双版纳傣族自治州'
      },
      {
        label: '中国工商银行云南西双版纳分行景洪南路支行',
        value: '102749009084|5328|云南省|西双版纳傣族自治州'
      },
      {
        label: '中国工商银行云南西双版纳分行景洪北路支行',
        value: '102749009092|5328|云南省|西双版纳傣族自治州'
      },
      {
        label: '中国工商银行云南西双版纳分行勐海支行',
        value: '102749209024|5328|云南省|西双版纳傣族自治州'
      },
      {
        label: '中国工商银行云南大理分行龙山分理处',
        value: '102751003040|5329|云南省|大理白族自治州'
      },
      {
        label: '中国工商银行云南大理分行源泉支行',
        value: '102751010029|5329|云南省|大理白族自治州'
      },
      {
        label: '中国工商银行云南大理分行苍山中路分理处',
        value: '102751010070|5329|云南省|大理白族自治州'
      },
      {
        label: '中国工商银行云南大理分行小花园支行',
        value: '102751010088|5329|云南省|大理白族自治州'
      },
      {
        label: '中国工商银行云南大理分行文化路分理处',
        value: '102751010096|5329|云南省|大理白族自治州'
      },
      {
        label: '中国工商银行云南大理分行海玉支行',
        value: '102751010107|5329|云南省|大理白族自治州'
      },
      {
        label: '中国工商银行云南大理分行人民路分理处',
        value: '102751010115|5329|云南省|大理白族自治州'
      },
      {
        label: '中国工商银行云南大理分行苍山西路支行',
        value: '102751010131|5329|云南省|大理白族自治州'
      },
      {
        label: '中国工商银行云南大理分行新桥支行',
        value: '102751010140|5329|云南省|大理白族自治州'
      },
      {
        label: '中国工商银行云南大理分行北门分理处',
        value: '102751010203|5329|云南省|大理白族自治州'
      },
      {
        label: '中国工商银行云南大理分行云岭支行',
        value: '102751010730|5329|云南省|大理白族自治州'
      },
      {
        label: '中国工商银行云南大理分行',
        value: '102751015016|5329|云南省|大理白族自治州'
      },
      {
        label: '中国工商银行云南大理分行振兴支行',
        value: '102751015024|5329|云南省|大理白族自治州'
      },
      {
        label: '中国工商银行云南大理分行建设支行',
        value: '102751015032|5329|云南省|大理白族自治州'
      },
      {
        label: '中国工商银行云南大理分行龙溪支行',
        value: '102751015049|5329|云南省|大理白族自治州'
      },
      {
        label: '中国工商银行云南大理分行古城支行',
        value: '102751015057|5329|云南省|大理白族自治州'
      },
      {
        label: '中国工商银行云南大理分行经济开发区支行',
        value: '102751015065|5329|云南省|大理白族自治州'
      },
      {
        label: '中国工商银行宝鸡陈仓区金桥分理处',
        value: '102793030488|6103|陕西省|宝鸡市'
      },
      {
        label: '工行陕西省宝鸡陈仓区北大街分理处',
        value: '102793030507|6103|陕西省|宝鸡市'
      },
      {
        label: '中国工商银行宝鸡清姜东五路支行',
        value: '102793030574|6103|陕西省|宝鸡市'
      },
      {
        label: '工行陕西省宝鸡创业园支行',
        value: '102793030582|6103|陕西省|宝鸡市'
      },
      {
        label: '工行陕西省宝鸡百翠园支行',
        value: '102793030599|6103|陕西省|宝鸡市'
      },
      {
        label: '工行陕西省宝鸡高新大道支行',
        value: '102793030611|6103|陕西省|宝鸡市'
      },
      {
        label: '中国工商银行宝鸡政府广场支行',
        value: '102793030620|6103|陕西省|宝鸡市'
      },
      {
        label: '中国工商银行宝鸡斗中路支行',
        value: '102793030646|6103|陕西省|宝鸡市'
      },
      {
        label: '工行陕西省宝鸡石油机械厂支行',
        value: '102793030654|6103|陕西省|宝鸡市'
      },
      {
        label: '工行陕西省宝鸡广元路支行',
        value: '102793030700|6103|陕西省|宝鸡市'
      },
      {
        label: '中国工商银行宝鸡跃进路支行',
        value: '102793030775|6103|陕西省|宝鸡市'
      },
      {
        label: '中国工商银行宝鸡分行业务处理中心',
        value: '102793035002|6103|陕西省|宝鸡市'
      },
      {
        label: '中国工商银行凤翔县支行',
        value: '102793230194|6103|陕西省|宝鸡市'
      },
      {
        label: '中国工商银行凤翔县东湖口分理处',
        value: '102793230452|6103|陕西省|宝鸡市'
      },
      {
        label: '中国工商银行股份有限公司乌鲁木齐南昌路支行',
        value: '102881001396|6501|新疆维吾尔自治区|乌鲁木齐市'
      },
      {
        label: '中国工商银行股份有限公司乌鲁木齐昆仑路支行',
        value: '102881001415|6501|新疆维吾尔自治区|乌鲁木齐市'
      },
      {
        label: '中国工商银行股份有限公司宜宾酒都支行',
        value: '102671050212|5115|四川省|宜宾市'
      },
      {
        label: '中国工商银行股份有限公司宜宾南岸支行',
        value: '102671050518|5115|四川省|宜宾市'
      },
      {
        label: '中国工商银行股份有限公司宜宾长江路支行',
        value: '102671050526|5115|四川省|宜宾市'
      },
      {
        label: '中国工商银行股份有限公司宜宾莱茵支行',
        value: '102671050583|5115|四川省|宜宾市'
      },
      {
        label: '中国工商银行股份有限公司宜宾江北支行',
        value: '102671050614|5115|四川省|宜宾市'
      },
      {
        label: '中国工商银行汉中分行',
        value: '102799060323|6107|陕西省|汉中市'
      },
      {
        label: '工行陕西省汉中兴汉路支行',
        value: '102799060340|6107|陕西省|汉中市'
      },
      {
        label: '工行陕西省汉中前进东路支行',
        value: '102799060358|6107|陕西省|汉中市'
      },
      {
        label: '工行陕西省汉中东大街支行',
        value: '102799060366|6107|陕西省|汉中市'
      },
      {
        label: '工商银行汉中北关分理处',
        value: '102799060374|6107|陕西省|汉中市'
      },
      {
        label: '工行陕西省汉中西大街支行',
        value: '102799060382|6107|陕西省|汉中市'
      },
      {
        label: '工行陕西省汉中康复路支行',
        value: '102799060399|6107|陕西省|汉中市'
      },
      {
        label: '工银行陕西省汉中劳动西路支行',
        value: '102799060403|6107|陕西省|汉中市'
      },
      {
        label: '工行陕西省汉中西环路支行',
        value: '102799065097|6107|陕西省|汉中市'
      },
      {
        label: '工行陕西省汉中广场支行',
        value: '102799065101|6107|陕西省|汉中市'
      },
      {
        label: '中国工商银行汉中市天台路支行',
        value: '102799065136|6107|陕西省|汉中市'
      },
      {
        label: '中国工商银行汉中南郑县支行',
        value: '102799260062|6107|陕西省|汉中市'
      },
      {
        label: '工行汉中经济开发区支行',
        value: '102799260079|6107|陕西省|汉中市'
      },
      {
        label: '中国工商银行汉中城固县支行',
        value: '102799360092|6107|陕西省|汉中市'
      },
      {
        label: '工行陕西省铜川红旗街支行',
        value: '102792020018|6102|陕西省|铜川市'
      },
      {
        label: '中国工商银行铜川分行印台支行',
        value: '102792020026|6102|陕西省|铜川市'
      },
      {
        label: '工行宁夏平罗星海支行',
        value: '102872110097|6402|宁夏回族自治区|石嘴山市'
      },
      {
        label: '工行宁夏吴忠支行',
        value: '102873100011|6403|宁夏回族自治区|吴忠市'
      },
      {
        label: '工行宁夏吴忠中华桥支行',
        value: '102873100020|6403|宁夏回族自治区|吴忠市'
      },
      {
        label: '工行宁夏吴忠裕民支行',
        value: '102873100247|6403|宁夏回族自治区|吴忠市'
      },
      {
        label: '工行宁夏吴忠业务处理中心（不办理对客户业务）',
        value: '102873105007|6403|宁夏回族自治区|吴忠市'
      },
      {
        label: '工行宁夏吴忠支行利宁分理处',
        value: '102873105099|6403|宁夏回族自治区|吴忠市'
      },
      {
        label: '工行宁夏吴忠回乡支行',
        value: '102873105291|6403|宁夏回族自治区|吴忠市'
      },
      {
        label: '工行宁夏青铜峡支行',
        value: '102873200053|6403|宁夏回族自治区|吴忠市'
      },
      {
        label: '工行宁夏青铜峡铝厂支行',
        value: '102873200166|6403|宁夏回族自治区|吴忠市'
      },
      {
        label: '工行宁夏青铜峡古峡支行',
        value: '102873202180|6403|宁夏回族自治区|吴忠市'
      },
      {
        label: '工行宁夏青铜峡大坝支行',
        value: '102873205346|6403|宁夏回族自治区|吴忠市'
      },
      {
        label: '工行宁夏中卫支行',
        value: '102873300110|6403|宁夏回族自治区|吴忠市'
      },
      {
        label: '工行宁夏中宁支行',
        value: '102873400098|6405|宁夏回族自治区|中卫市'
      },
      {
        label: '工行宁夏盐池支行',
        value: '102873600137|6403|宁夏回族自治区|吴忠市'
      },
      {
        label: '工行宁夏盐池西门支行',
        value: '102873602194|6403|宁夏回族自治区|吴忠市'
      },
      {
        label: '工行宁夏同心支行',
        value: '102873703007|6403|宁夏回族自治区|吴忠市'
      },
      {
        label: '工行宁夏固原支行',
        value: '102874100013|6404|宁夏回族自治区|固原市'
      },
      {
        label: '工行宁夏固原业务处理中心（不办理对客户业务）',
        value: '102874105001|6404|宁夏回族自治区|固原市'
      },
      {
        label: '中国工商银行股份有限公司新疆区分行业务处理中心不对外办理业务',
        value: '102881000019|6501|新疆维吾尔自治区|乌鲁木齐市'
      },
      {
        label: '中国工商银行新疆区分行营业部核算中心',
        value: '102881001005|6501|新疆维吾尔自治区|乌鲁木齐市'
      },
      {
        label: '中国工商银行股份有限公司乌鲁木齐明德路支行',
        value: '102881001013|6501|新疆维吾尔自治区|乌鲁木齐市'
      },
      {
        label: '中国工商银行股份有限公司乌鲁木齐中山路支行',
        value: '102881001021|6501|新疆维吾尔自治区|乌鲁木齐市'
      },
      {
        label: '中国工商银行股份有限公司乌鲁木齐经济技术开发区支行',
        value: '102881001030|6501|新疆维吾尔自治区|乌鲁木齐市'
      },
      {
        label: '中国工商银行股份有限公司乌鲁木齐阳光清城支行',
        value: '102881001064|6501|新疆维吾尔自治区|乌鲁木齐市'
      },
      {
        label: '中国工商银行新疆区分行营业部银行卡业务部',
        value: '102881001072|6501|新疆维吾尔自治区|乌鲁木齐市'
      },
      {
        label: '中国工商银行股份有限公司乌鲁木齐二道桥支行',
        value: '102881001128|6501|新疆维吾尔自治区|乌鲁木齐市'
      },
      {
        label: '中国工商银行股份有限公司乌鲁木齐民主路支行',
        value: '102881001136|6501|新疆维吾尔自治区|乌鲁木齐市'
      },
      {
        label: '中国工商银行股份有限公司乌鲁木齐新华北路支行',
        value: '102881001144|6501|新疆维吾尔自治区|乌鲁木齐市'
      },
      {
        label: '中国工商银行股份有限公司乌鲁木齐胜利路支行',
        value: '102881001152|6501|新疆维吾尔自治区|乌鲁木齐市'
      },
      {
        label: '中国工商银行股份有限公司新疆第七支行',
        value: '102888800450|6528|新疆维吾尔自治区|巴音郭楞蒙古自治州'
      },
      {
        label: '中国工商银行股份有限公司新疆第七支行核算中心',
        value: '102888800476|6528|新疆维吾尔自治区|巴音郭楞蒙古自治州'
      },
      {
        label: '中国工商银行股份有限公司阿克苏分行业务处理中心',
        value: '102891001007|6529|新疆维吾尔自治区|阿克苏地区'
      },
      {
        label: '中国工商银行股份有限公司阿克苏栏杆路支行',
        value: '102891002000|6529|新疆维吾尔自治区|阿克苏地区'
      },
      {
        label: '中国工商银行股份有限公司阿克苏分行营业部',
        value: '102891002018|6529|新疆维吾尔自治区|阿克苏地区'
      },
      {
        label: '中国工商银行股份有限公司阿克苏解放路支行',
        value: '102891002026|6529|新疆维吾尔自治区|阿克苏地区'
      },
      {
        label: '中国工商银行股份有限公司阿克苏西大街支行',
        value: '102891002042|6529|新疆维吾尔自治区|阿克苏地区'
      },
      {
        label: '中国工商银行股份有限公司阿克苏分行东大街支行',
        value: '102891002122|6529|新疆维吾尔自治区|阿克苏地区'
      },
      {
        label: '中国工商银行股份有限公司阿克苏南大街支行',
        value: '102891002155|6529|新疆维吾尔自治区|阿克苏地区'
      },
      {
        label: '中国工商银行股份有限公司阿克苏塔北北路支行',
        value: '102891002163|6529|新疆维吾尔自治区|阿克苏地区'
      },
      {
        label: '中国工商银行股份有限公司温宿支行',
        value: '102891216016|6529|新疆维吾尔自治区|阿克苏地区'
      },
      {
        label: '中国工商银行股份有限公司库车五一路支行',
        value: '102891312024|6529|新疆维吾尔自治区|阿克苏地区'
      },
      {
        label: '中国工商银行股份有限公司库车文化路支行',
        value: '102891312057|6529|新疆维吾尔自治区|阿克苏地区'
      },
      {
        label: '中国工商银行股份有限公司库车支行',
        value: '102891335584|6529|新疆维吾尔自治区|阿克苏地区'
      },
      {
        label: '中国工商银行股份有限公司沙雅支行',
        value: '102891400153|6529|新疆维吾尔自治区|阿克苏地区'
      },
      {
        label: '中国工商银行股份有限公司拜城支行',
        value: '102891613014|6529|新疆维吾尔自治区|阿克苏地区'
      },
      {
        label: '中国工商银行股份有限公司阿瓦提支行',
        value: '102891800019|6529|新疆维吾尔自治区|阿克苏地区'
      },
      {
        label: '中国工商银行股份有限公司兰州八一支行',
        value: '102821000110|6201|甘肃省|兰州市'
      },
      {
        label: '中国工商银行股份有限公司兰州安宁支行',
        value: '102821000128|6201|甘肃省|兰州市'
      },
      {
        label: '中国工商银行股份有限公司石河子分行',
        value: '102902800021|6590|新疆维吾尔自治区|自治区直辖县级行政区划'
      },
      {
        label: '中国工商银行股份有限公司石河子百大城支行',
        value: '102902800030|6590|新疆维吾尔自治区|自治区直辖县级行政区划'
      },
      {
        label: '中国工商银行股份有限公司佛山和平支行',
        value: '102588002554|4406|广东省|佛山市'
      },
      {
        label: '中国工商银行股份有限公司安庆政务区支行',
        value: '102368000614|3408|安徽省|安庆市'
      },
      {
        label: '中国工商银行股份有限公司安庆东湖支行',
        value: '102368000702|3408|安徽省|安庆市'
      },
      {
        label: '中国工商银行安庆分行汇通支行',
        value: '102368000809|3408|安徽省|安庆市'
      },
      {
        label: '中国工商银行安庆分行石集支行',
        value: '102368000817|3408|安徽省|安庆市'
      },
      {
        label: '中国工商银行安庆分行城建支行',
        value: '102368000997|3408|安徽省|安庆市'
      },
      {
        label: '中国工商银行股份有限公司安庆牡丹支行',
        value: '102368001004|3408|安徽省|安庆市'
      },
      {
        label: '中国工商银行安庆分行桐城市支行营业部',
        value: '102368107502|3408|安徽省|安庆市'
      },
      {
        label: '中国工商银行安庆分行怀宁县支行营业部',
        value: '102368207703|3408|安徽省|安庆市'
      },
      {
        label: '中国工商银行安庆分行枞阳县支行营业部',
        value: '102368308105|3407|安徽省|铜陵市'
      },
      {
        label: '中国工商银行安庆分行潜山县支行营业部',
        value: '102368407808|3408|安徽省|安庆市'
      },
      {
        label: '中国工商银行股份有限公司太湖支行',
        value: '102368507909|3408|安徽省|安庆市'
      },
      {
        label: '中国工商银行股份有限公司宿松支行',
        value: '102368608001|3408|安徽省|安庆市'
      },
      {
        label: '中国工商银行安庆分行望江县支行营业部',
        value: '102368707608|3408|安徽省|安庆市'
      },
      {
        label: '中国工商银行股份有限公司岳西支行',
        value: '102368808206|3408|安徽省|安庆市'
      },
      {
        label: '中国工商银行股份有限公司黄山阳湖支行',
        value: '102371000117|3410|安徽省|黄山市'
      },
      {
        label: '中国工商银行股份有限公司黄山稽灵山支行',
        value: '102371000125|3410|安徽省|黄山市'
      },
      {
        label: '中国工商银行股份有限公司黄山黄口支行',
        value: '102371000133|3410|安徽省|黄山市'
      },
      {
        label: '中国工商银行股份有限公司黄山屯光支行',
        value: '102371000141|3410|安徽省|黄山市'
      },
      {
        label: '中国工商银行股份有限公司黄山牡丹支行',
        value: '102371000150|3410|安徽省|黄山市'
      },
      {
        label: '中国工商银行黄山分行城建支行',
        value: '102371000168|3410|安徽省|黄山市'
      },
      {
        label: '中国工商银行股份有限公司黄山世纪广场支行',
        value: '102371000176|3410|安徽省|黄山市'
      },
      {
        label: '中国工商银行股份有限公司黄山黎阳支行',
        value: '102371000184|3410|安徽省|黄山市'
      },
      {
        label: '中国工商银行黄山市分行核算中心',
        value: '102371009106|3410|安徽省|黄山市'
      },
      {
        label: '中国工商银行屯溪支行',
        value: '102371009114|3410|安徽省|黄山市'
      },
      {
        label: '中国工商银行股份有限公司黄山荷花池支行',
        value: '102371009139|3410|安徽省|黄山市'
      },
      {
        label: '中国工商银行股份有限公司星子支行',
        value: '102424707070|3604|江西省|九江市'
      },
      {
        label: '中国工商银行股份有限公司都昌支行',
        value: '102424807086|3604|江西省|九江市'
      },
      {
        label: '中国工商银行股份有限公司绥宁支行',
        value: '102555702071|4305|湖南省|邵阳市'
      },
      {
        label: '中国工商银行股份有限公司新宁大兴路支行',
        value: '102555801680|4305|湖南省|邵阳市'
      },
      {
        label: '中国工商银行股份有限公司新宁支行',
        value: '102555802045|4305|湖南省|邵阳市'
      },
      {
        label: '中国工商银行股份有限公司城步支行',
        value: '102555902056|4305|湖南省|邵阳市'
      },
      {
        label: '中国工商银行股份有限公司岳阳分行（不对外）',
        value: '102557060100|4306|湖南省|岳阳市'
      },
      {
        label: '中国工商银行股份有限公司岳阳东茅岭支行',
        value: '102557060206|4306|湖南省|岳阳市'
      },
      {
        label: '中国工商银行股份有限公司岳阳南湖支行',
        value: '102557060214|4306|湖南省|岳阳市'
      },
      {
        label: '中国工商银行股份有限公司岳阳站前路支行',
        value: '102557060222|4306|湖南省|岳阳市'
      },
      {
        label: '中国工商银行股份有限公司岳阳建湘路支行',
        value: '102557060239|4306|湖南省|岳阳市'
      },
      {
        label: '中国工商银行股份有限公司岳阳步行街支行',
        value: '102557060255|4306|湖南省|岳阳市'
      },
      {
        label: '中国工商银行股份有限公司岳阳奇家岭支行',
        value: '102557060263|4306|湖南省|岳阳市'
      },
      {
        label: '中国工商银行股份有限公司岳阳金鹗路支行',
        value: '102557060271|4306|湖南省|岳阳市'
      },
      {
        label: '中国工商银行股份有限公司岳阳五里牌支行',
        value: '102557060280|4306|湖南省|岳阳市'
      },
      {
        label: '中国工商银行股份有限公司岳阳解放路支行',
        value: '102557060302|4306|湖南省|岳阳市'
      },
      {
        label: '中国工商银行股份有限公司岳阳巴东支行',
        value: '102557060319|4306|湖南省|岳阳市'
      },
      {
        label: '中国工商银行股份有限公司岳阳岳阳楼支行',
        value: '102557060335|4306|湖南省|岳阳市'
      },
      {
        label: '中国工商银行股份有限公司岳阳广场支行',
        value: '102557060343|4306|湖南省|岳阳市'
      },
      {
        label: '中国工商银行股份有限公司岳阳求索路支行',
        value: '102557060351|4306|湖南省|岳阳市'
      },
      {
        label: '中国工商银行股份有限公司岳阳琵琶王支行',
        value: '102557060360|4306|湖南省|岳阳市'
      },
      {
        label: '中国工商银行股份有限公司岳阳南环东路支行',
        value: '102557060386|4306|湖南省|岳阳市'
      },
      {
        label: '中国工商银行股份有限公司岳阳德胜路支行',
        value: '102557060394|4306|湖南省|岳阳市'
      },
      {
        label: '中国工商银行股份有限公司岳阳城陵矶支行',
        value: '102557060409|4306|湖南省|岳阳市'
      },
      {
        label: '中国工商银行股份有限公司岳阳通海路支行',
        value: '102557060417|4306|湖南省|岳阳市'
      },
      {
        label: '中国工商银行股份有限公司岳阳开发区支行',
        value: '102557060505|4306|湖南省|岳阳市'
      },
      {
        label: '中国工商银行股份有限公司岳阳太阳桥支行',
        value: '102557060513|4306|湖南省|岳阳市'
      },
      {
        label: '中国工商银行股份有限公司汕头嵩山支行',
        value: '102586002420|4405|广东省|汕头市'
      },
      {
        label: '中国工商银行股份有限公司汕头分行业务处理中心',
        value: '102586002487|4405|广东省|汕头市'
      },
      {
        label: '中国工商银行股份有限公司汕头澄海支行',
        value: '102586002606|4405|广东省|汕头市'
      },
      {
        label: '中国工商银行股份有限公司汕头潮阳支行',
        value: '102586002702|4405|广东省|汕头市'
      },
      {
        label: '中国工商银行股份有限公司汕头潮阳棉城支行',
        value: '102586002719|4405|广东省|汕头市'
      },
      {
        label: '中国工商银行股份有限公司汕头潮阳和平支行',
        value: '102586002735|4405|广东省|汕头市'
      },
      {
        label: '中国工商银行股份有限公司汕头潮南支行',
        value: '102586002743|4405|广东省|汕头市'
      },
      {
        label: '中国工商银行股份有限公司汕头潮南两英支行',
        value: '102586002751|4405|广东省|汕头市'
      },
      {
        label: '中国工商银行股份有限公司汕头潮南陈店支行',
        value: '102586002760|4405|广东省|汕头市'
      },
      {
        label: '中国工商银行股份有限公司汕头金园工业区支行',
        value: '102586003211|4405|广东省|汕头市'
      },
      {
        label: '中国工商银行股份有限公司南昌叠山路支行',
        value: '102421000384|3601|江西省|南昌市'
      },
      {
        label: '中国工商银行股份有限公司岐山县五丈原支行',
        value: '102793330533|6103|陕西省|宝鸡市'
      },
      {
        label: '中国工商银行岐山县车站分理处',
        value: '102793330550|6103|陕西省|宝鸡市'
      },
      {
        label: '中国工商银行股份有限公司岐山县蔡家坡开发区分理处',
        value: '102793330568|6103|陕西省|宝鸡市'
      },
      {
        label: '中国工商银行岐山县东街分理处',
        value: '102793330736|6103|陕西省|宝鸡市'
      },
      {
        label: '中国工商银行扶风县支行',
        value: '102793430289|6103|陕西省|宝鸡市'
      },
      {
        label: '工行陕西省扶风县绛帐支行',
        value: '102793430297|6103|陕西省|宝鸡市'
      },
      {
        label: '中国工商银行扶风县中街分理处',
        value: '102793430720|6103|陕西省|宝鸡市'
      },
      {
        label: '工行陕西省宝鸡眉县美阳街分理处',
        value: '102793504694|6103|陕西省|宝鸡市'
      },
      {
        label: '中国工商银行眉县支行',
        value: '102793530302|6103|陕西省|宝鸡市'
      },
      {
        label: '中国工商银行股份有限公司眉县平阳街支行',
        value: '102793530757|6103|陕西省|宝鸡市'
      },
      {
        label: '工行陕西省宝鸡凤县铅铜山分理处',
        value: '102793904720|6103|陕西省|宝鸡市'
      },
      {
        label: '中国工商银行凤县支行',
        value: '102793930320|6103|陕西省|宝鸡市'
      },
      {
        label: '中国工商银行凤县双石铺分理处',
        value: '102793930717|6103|陕西省|宝鸡市'
      },
      {
        label: '工行陕西省咸阳中华小区支行',
        value: '102795002028|6104|陕西省|咸阳市'
      },
      {
        label: '中国工商银行股份有限公司咸阳玉泉路支行',
        value: '102795002069|6104|陕西省|咸阳市'
      },
      {
        label: '工行陕西省咸阳陕西科技大学支行',
        value: '102795002124|6104|陕西省|咸阳市'
      },
      {
        label: '工行陕西省咸阳新兴南路支行',
        value: '102795003070|6104|陕西省|咸阳市'
      },
      {
        label: '工行陕西省咸阳抗战路支行',
        value: '102795003088|6104|陕西省|咸阳市'
      },
      {
        label: '工行咸阳团结路支行',
        value: '102795004040|6104|陕西省|咸阳市'
      },
      {
        label: '工行陕西省咸阳人民路支行',
        value: '102795004066|6104|陕西省|咸阳市'
      },
      {
        label: '工行陕西省咸阳联盟三路支行',
        value: '102795004074|6104|陕西省|咸阳市'
      },
      {
        label: '工行咸阳民生西路支行',
        value: '102795004099|6104|陕西省|咸阳市'
      },
      {
        label: '工行陕西省咸阳陈阳寨支行',
        value: '102795005034|6104|陕西省|咸阳市'
      },
      {
        label: '工行陕西省咸阳彩虹北区支行',
        value: '102795006035|6104|陕西省|咸阳市'
      },
      {
        label: '工行咸阳人民中路支行',
        value: '102795040016|6104|陕西省|咸阳市'
      },
      {
        label: '中国工商咸阳市人民东路支行',
        value: '102795040024|6104|陕西省|咸阳市'
      },
      {
        label: '中国工商银行咸阳东风路支行',
        value: '102795040032|6104|陕西省|咸阳市'
      },
      {
        label: '中国工商银行咸阳市人民西路支行',
        value: '102795040049|6104|陕西省|咸阳市'
      },
      {
        label: '工行陕西省咸阳长虹路支行',
        value: '102795040057|6104|陕西省|咸阳市'
      },
      {
        label: '工行陕西省咸阳世纪中路支行',
        value: '102795040065|6104|陕西省|咸阳市'
      },
      {
        label: '中国工商咸阳秦都支行',
        value: '102795040073|6104|陕西省|咸阳市'
      },
      {
        label: '中国工商银行咸阳市彩虹支行',
        value: '102795040081|6104|陕西省|咸阳市'
      },
      {
        label: '中国工商银行咸阳分行杨凌区支行',
        value: '102795040090|6104|陕西省|咸阳市'
      },
      {
        label: '中国工商银行咸阳金旭路支行',
        value: '102795040112|6104|陕西省|咸阳市'
      },
      {
        label: '工行陕西省咸阳安定路支行',
        value: '102795040266|6104|陕西省|咸阳市'
      },
      {
        label: '中国工商银行定边县支行',
        value: '102806610041|6108|陕西省|榆林市'
      },
      {
        label: '中国工商银行绥德县支行',
        value: '102806710067|6108|陕西省|榆林市'
      },
      {
        label: '工行陕西省榆林绥德县文化路支行',
        value: '102806710219|6108|陕西省|榆林市'
      },
      {
        label: '工行陕西省榆林米脂县支行',
        value: '102806809094|6108|陕西省|榆林市'
      },
      {
        label: '中国工商银行股份有限公司兰州城关支行',
        value: '102821000013|6201|甘肃省|兰州市'
      },
      {
        label: '中国工商银行股份有限公司兰州东岗东路支行',
        value: '102821000030|6201|甘肃省|兰州市'
      },
      {
        label: '中国工商银行股份有限公司兰州土门墩支行',
        value: '102821000048|6201|甘肃省|兰州市'
      },
      {
        label: '中国工商银行股份有限公司兰州东岗支行',
        value: '102821000056|6201|甘肃省|兰州市'
      },
      {
        label: '中国工商银行股份有限公司兰州金城支行',
        value: '102821000064|6201|甘肃省|兰州市'
      },
      {
        label: '中国工商银行股份有限公司兰州分行业务处理中心',
        value: '102821000072|6201|甘肃省|兰州市'
      },
      {
        label: '中国工商银行股份有限公司兰州张掖路支行',
        value: '102821000089|6201|甘肃省|兰州市'
      },
      {
        label: '中国工商银行股份有限公司兰州市开发区支行',
        value: '102821000097|6201|甘肃省|兰州市'
      },
      {
        label: '中国工商银行股份有限公司龙川新城支行',
        value: '102598202982|4416|广东省|河源市'
      },
      {
        label: '中国工商银行股份有限公司连平支行',
        value: '102598300268|4416|广东省|河源市'
      },
      {
        label: '中国工商银行股份有限公司连平忠信支行',
        value: '102598302639|4416|广东省|河源市'
      },
      {
        label: '中国工商银行股份有限公司和平支行',
        value: '102598400259|4416|广东省|河源市'
      },
      {
        label: '中国工商银行股份有限公司河源东源支行',
        value: '102598502305|4416|广东省|河源市'
      },
      {
        label: '中国工商银行股份有限公司阳江分行营业部',
        value: '102599000216|4417|广东省|阳江市'
      },
      {
        label: '中国工商银行股份有限公司阳江东区支行',
        value: '102599000257|4417|广东省|阳江市'
      },
      {
        label: '中国工商银行股份有限公司阳江江城支行',
        value: '102599000265|4417|广东省|阳江市'
      },
      {
        label: '中国工商银行股份有限公司阳江分行业务处理中心',
        value: '102599001215|4417|广东省|阳江市'
      },
      {
        label: '中国工商银行股份有限公司阳江豪贤支行',
        value: '102599002167|4417|广东省|阳江市'
      },
      {
        label: '中国工商银行股份有限公司阳西支行',
        value: '102599100276|4417|广东省|阳江市'
      },
      {
        label: '中国工商银行股份有限公司阳春支行',
        value: '102599200226|4417|广东省|阳江市'
      },
      {
        label: '中国工商银行股份有限公司阳东支行',
        value: '102599302321|4417|广东省|阳江市'
      },
      {
        label: '中国工商银行股份有限公司清远分行业务处理中心',
        value: '102601002009|4418|广东省|清远市'
      },
      {
        label: '中国工商银行股份有限公司清远分行',
        value: '102601002017|4418|广东省|清远市'
      },
      {
        label: '中国工商银行股份有限公司清远连江支行',
        value: '102601002025|4418|广东省|清远市'
      },
      {
        label: '中国工商银行股份有限公司许昌东城支行',
        value: '102503062102|4110|河南省|许昌市'
      },
      {
        label: '中国工商银行股份有限公司长葛支行',
        value: '102503102605|4110|河南省|许昌市'
      },
      {
        label: '中国工商银行股份有限公司长葛铁东支行',
        value: '102503152609|4110|河南省|许昌市'
      },
      {
        label: '中国工商银行延吉明珠支行',
        value: '102249002011|2224|吉林省|延边朝鲜族自治州'
      },
      {
        label: '中国工商银行延吉开发区支行',
        value: '102249002020|2224|吉林省|延边朝鲜族自治州'
      },
      {
        label: '中国工商银行股份有限公司开平水口支行',
        value: '102589400819|4407|广东省|江门市'
      },
      {
        label: '中国工商银行股份有限公司鹤山支行',
        value: '102589500604|4407|广东省|江门市'
      },
      {
        label: '中国工商银行股份有限公司湛江分行',
        value: '102591002012|4408|广东省|湛江市'
      },
      {
        label: '中国工商银行股份有限公司湛江第一支行',
        value: '102591002029|4408|广东省|湛江市'
      },
      {
        label: '中国工商银行股份有限公司湛江第二支行',
        value: '102591002037|4408|广东省|湛江市'
      },
      {
        label: '中国工商银行股份有限公司湛江赤坎支行',
        value: '102591002045|4408|广东省|湛江市'
      },
      {
        label: '中国工商银行股份有限公司湛江霞山支行',
        value: '102591002053|4408|广东省|湛江市'
      },
      {
        label: '中国工商银行股份有限公司湛江坡头支行',
        value: '102591002061|4408|广东省|湛江市'
      },
      {
        label: '中国工商银行股份有限公司佛山顺德容东支行',
        value: '102588001449|4406|广东省|佛山市'
      },
      {
        label: '中国工商银行股份有限公司佛山顺德泰安支行',
        value: '102588001457|4406|广东省|佛山市'
      },
      {
        label: '中国工商银行股份有限公司佛山顺德银晖支行',
        value: '102588001512|4406|广东省|佛山市'
      },
      {
        label: '中国工商银行股份有限公司佛山顺德龙峰支行',
        value: '102588001529|4406|广东省|佛山市'
      },
      {
        label: '中国工商银行股份有限公司佛山吉祥支行',
        value: '102588001607|4406|广东省|佛山市'
      },
      {
        label: '中国工商银行股份有限公司佛山杏市支行',
        value: '102588001631|4406|广东省|佛山市'
      },
      {
        label: '中国工商银行股份有限公司佛山南海海城支行',
        value: '102588001640|4406|广东省|佛山市'
      },
      {
        label: '中国工商银行股份有限公司佛山南海顺平支行',
        value: '102588001674|4406|广东省|佛山市'
      },
      {
        label: '中国工商银行湖南省永州市分行银行卡部',
        value: '102565005085|4311|湖南省|永州市'
      },
      {
        label: '中国工商银行股份有限公司东安支行',
        value: '102565300049|4311|湖南省|永州市'
      },
      {
        label: '中国工商银行股份有限公司东安支行中心分理处',
        value: '102565300830|4311|湖南省|永州市'
      },
      {
        label: '中国工商银行股份有限公司道县支行',
        value: '102565400050|4311|湖南省|永州市'
      },
      {
        label: '中国工商银行股份有限公司道县支行城中分理处',
        value: '102565400935|4311|湖南省|永州市'
      },
      {
        label: '中国工商银行股份有限公司宁远支行',
        value: '102565500067|4311|湖南省|永州市'
      },
      {
        label: '中国工商银行股份有限公司宁远支行九疑路分理处',
        value: '102565501019|4311|湖南省|永州市'
      },
      {
        label: '中国工商银行股份有限公司宁远支行新桥分理处',
        value: '102565501035|4311|湖南省|永州市'
      },
      {
        label: '中国工商银行股份有限公司江永支行',
        value: '102565600077|4311|湖南省|永州市'
      },
      {
        label: '中国工商银行股份有限公司茂名高凉路支行',
        value: '102592002346|4409|广东省|茂名市'
      },
      {
        label: '中国工商银行股份有限公司茂名油泉支行',
        value: '102592002379|4409|广东省|茂名市'
      },
      {
        label: '中国工商银行股份有限公司茂名新华街支行',
        value: '102592002418|4409|广东省|茂名市'
      },
      {
        label: '中国工商银行股份有限公司茂名银辉支行',
        value: '102592002426|4409|广东省|茂名市'
      },
      {
        label: '中国工商银行股份有限公司茂名银泉支行',
        value: '102592002467|4409|广东省|茂名市'
      },
      {
        label: '中国工商银行股份有限公司茂名河东支行',
        value: '102592002514|4409|广东省|茂名市'
      },
      {
        label: '中国工商银行股份有限公司茂名站前路支行',
        value: '102592002522|4409|广东省|茂名市'
      },
      {
        label: '中国工商银行股份有限公司茂名牡丹支行',
        value: '102592002547|4409|广东省|茂名市'
      },
      {
        label: '中国工商银行股份有限公司茂名分行业务处理中心',
        value: '102592002813|4409|广东省|茂名市'
      },
      {
        label: '中国工商银行股份有限公司茂名茂港支行',
        value: '102592008226|4409|广东省|茂名市'
      },
      {
        label: '中国工商银行股份有限公司信宜支行',
        value: '102592105218|4409|广东省|茂名市'
      },
      {
        label: '中国工商银行股份有限公司高州支行',
        value: '102592206214|4409|广东省|茂名市'
      },
      {
        label: '中国工商银行股份有限公司高州高城支行',
        value: '102592206247|4409|广东省|茂名市'
      },
      {
        label: '中国工商银行股份有限公司电白支行',
        value: '102592308219|4409|广东省|茂名市'
      },
      {
        label: '中国工商银行股份有限公司化州支行',
        value: '102592407216|4409|广东省|茂名市'
      },
      {
        label: '中国工商银行股份有限公司肇庆第一支行',
        value: '102593000211|4412|广东省|肇庆市'
      },
      {
        label: '中国工商银行股份有限公司肇庆端州支行',
        value: '102593000220|4412|广东省|肇庆市'
      },
      {
        label: '中国工商银行股份有限公司肇庆鼎湖支行',
        value: '102593000246|4412|广东省|肇庆市'
      },
      {
        label: '中国工商银行股份有限公司肇庆宋城路支行',
        value: '102593002122|4412|广东省|肇庆市'
      },
      {
        label: '中国工商银行股份有限公司肇庆分行业务处理中心',
        value: '102593002139|4412|广东省|肇庆市'
      },
      {
        label: '中国工商银行股份有限公司肇庆高新区支行',
        value: '102593002384|4412|广东省|肇庆市'
      },
      {
        label: '中国工商银行股份有限公司肇庆分行',
        value: '102593003213|4412|广东省|肇庆市'
      },
      {
        label: '中国工商银行股份有限公司高要支行',
        value: '102593102319|4412|广东省|肇庆市'
      },
      {
        label: '中国工商银行股份有限公司四会支行',
        value: '102593202028|4412|广东省|肇庆市'
      },
      {
        label: '中国工商银行股份有限公司广宁支行',
        value: '102593302060|4412|广东省|肇庆市'
      },
      {
        label: '中国工商银行股份有限公司怀集支行',
        value: '102593402102|4412|广东省|肇庆市'
      },
      {
        label: '中国工商银行股份有限公司怀集解放路支行',
        value: '102593402895|4412|广东省|肇庆市'
      },
      {
        label: '中国工商银行股份有限公司封开支行',
        value: '102593502096|4412|广东省|肇庆市'
      },
      {
        label: '中国工商银行股份有限公司德庆支行',
        value: '102593602056|4412|广东省|肇庆市'
      },
      {
        label: '中国工商银行股份有限公司云浮分行营业部',
        value: '102593700212|4412|广东省|肇庆市'
      },
      {
        label: '中国工商银行股份有限公司衡阳城中支行',
        value: '102554002101|4304|湖南省|衡阳市'
      },
      {
        label: '中国工商银行股份有限公司衡阳兴业支行',
        value: '102554002110|4304|湖南省|衡阳市'
      },
      {
        label: '中国工商银行股份有限公司衡阳雁西支行',
        value: '102554002152|4304|湖南省|衡阳市'
      },
      {
        label: '中国工商银行股份有限公司衡阳南华大学南院支行',
        value: '102554002169|4304|湖南省|衡阳市'
      },
      {
        label: '中国工商银行股份有限公司衡阳城南支行',
        value: '102554002208|4304|湖南省|衡阳市'
      },
      {
        label: '中国工商银行股份有限公司衡阳黄茶岭支行',
        value: '102554002224|4304|湖南省|衡阳市'
      },
      {
        label: '中国工商银行股份有限公司衡阳银雁支行',
        value: '102554002232|4304|湖南省|衡阳市'
      },
      {
        label: '中国工商银行股份有限公司佛山城区支行',
        value: '102588002667|4406|广东省|佛山市'
      },
      {
        label: '中国工商银行股份有限公司佛山三水文明支行',
        value: '102588002683|4406|广东省|佛山市'
      },
      {
        label: '中国工商银行股份有限公司佛山三水支行',
        value: '102588002714|4406|广东省|佛山市'
      },
      {
        label: '中国工商银行股份有限公司佛山三水人民支行',
        value: '102588002722|4406|广东省|佛山市'
      },
      {
        label: '中国工商银行股份有限公司佛山三水文锋支行',
        value: '102588002739|4406|广东省|佛山市'
      },
      {
        label: '中国工商银行股份有限公司佛山分行业务处理中心',
        value: '102588002802|4406|广东省|佛山市'
      },
      {
        label: '中国工商银行股份有限公司佛山高明支行',
        value: '102588002819|4406|广东省|佛山市'
      },
      {
        label: '中国工商银行股份有限公司佛山高明文华路支行',
        value: '102588002827|4406|广东省|佛山市'
      },
      {
        label: '中国工商银行股份有限公司惠州惠南支行',
        value: '102595002071|4413|广东省|惠州市'
      },
      {
        label: '中国工商银行惠州市惠城支行',
        value: '102595002080|4413|广东省|惠州市'
      },
      {
        label: '中国工商银行惠州市文明支行',
        value: '102595002098|4413|广东省|惠州市'
      },
      {
        label: '中国工商银行股份有限公司惠州惠台支行',
        value: '102595002127|4413|广东省|惠州市'
      },
      {
        label: '中国工商银行股份有限公司惠州万饰城支行',
        value: '102595002135|4413|广东省|惠州市'
      },
      {
        label: '中国工商银行惠州陈塘湖支行',
        value: '102595002143|4413|广东省|惠州市'
      },
      {
        label: '中国工商银行惠州市江南支行',
        value: '102595002160|4413|广东省|惠州市'
      },
      {
        label: '中国工商银行股份有限公司惠州东湖支行',
        value: '102595002178|4413|广东省|惠州市'
      },
      {
        label: '中国工商银行股份有限公司惠州仲恺高新区支行',
        value: '102595002209|4413|广东省|惠州市'
      },
      {
        label: '中国工商银行惠州市江北新城分理处',
        value: '102595002241|4413|广东省|惠州市'
      },
      {
        label: '中国工商银行股份有限公司惠州东江高新区支行',
        value: '102595002250|4413|广东省|惠州市'
      },
      {
        label: '中国工商银行惠州市小金分理处',
        value: '102595002268|4413|广东省|惠州市'
      },
      {
        label: '中国工商银行惠州市惠阳支行',
        value: '102595002330|4413|广东省|惠州市'
      },
      {
        label: '中国工商银行股份有限公司惠州淡水分理处',
        value: '102595002348|4413|广东省|惠州市'
      },
      {
        label: '中国工商银行惠州中山东支行',
        value: '102595002356|4413|广东省|惠州市'
      },
      {
        label: '中国工商银行股份有限公司惠州惠阳开城支行',
        value: '102595002364|4413|广东省|惠州市'
      },
      {
        label: '中国工商银行股份有限公司惠州仲恺开发区支行',
        value: '102595002372|4413|广东省|惠州市'
      },
      {
        label: '中国工商银行股份有限公司惠州惠阳秋长支行',
        value: '102595002389|4413|广东省|惠州市'
      },
      {
        label: '中国工商银行惠州市惠阳金惠支行',
        value: '102595002397|4413|广东省|惠州市'
      },
      {
        label: '中国工商银行股份有限公司惠州惠阳新圩支行',
        value: '102595002401|4413|广东省|惠州市'
      },
      {
        label: '中国工商银行惠州水口支行',
        value: '102595002410|4413|广东省|惠州市'
      },
      {
        label: '中国工商银行惠州市分行核算中心',
        value: '102595002604|4413|广东省|惠州市'
      },
      {
        label: '中国工商银行惠州市桥东支行',
        value: '102595002645|4413|广东省|惠州市'
      },
      {
        label: '中国工商银行股份有限公司惠州河南岸支行',
        value: '102595003017|4413|广东省|惠州市'
      },
      {
        label: '中国工商银行惠州市丽日支行',
        value: '102595003113|4413|广东省|惠州市'
      },
      {
        label: '中国工商银行股份有限公司惠州陈江御景支行',
        value: '102595003156|4413|广东省|惠州市'
      },
      {
        label: '中国工商银行股份有限公司惠州滨江支行',
        value: '102595003189|4413|广东省|惠州市'
      },
      {
        label: '中国工商银行股份有限公司惠州黄家塘支行',
        value: '102595003197|4413|广东省|惠州市'
      },
      {
        label: '中国工商银行股份有限公司惠州惠阳新街支行',
        value: '102595003244|4413|广东省|惠州市'
      },
      {
        label: '中国工商银行股份有限公司惠州惠阳中区支行',
        value: '102595003252|4413|广东省|惠州市'
      },
      {
        label: '中国工商银行惠州江北支行',
        value: '102595003277|4413|广东省|惠州市'
      },
      {
        label: '中国工商银行股份有限公司惠州惠阳伯恩支行',
        value: '102595003437|4413|广东省|惠州市'
      },
      {
        label: '中国工商银行股份有限公司枣庄振兴路支行',
        value: '102454000137|3704|山东省|枣庄市'
      },
      {
        label: '中国工商银行股份有限公司枣庄大观园支行',
        value: '102454000145|3704|山东省|枣庄市'
      },
      {
        label: '中国工商银行股份有限公司枣庄中心街支行',
        value: '102454000153|3704|山东省|枣庄市'
      },
      {
        label: '中国工商银行股份有限公司枣庄市中支行青檀路分理处',
        value: '102454000161|3704|山东省|枣庄市'
      },
      {
        label: '中国工商银行股份有限公司枣庄峄城支行',
        value: '102454000170|3704|山东省|枣庄市'
      },
      {
        label: '中国工商银行股份有限公司枣庄龙兴支行',
        value: '102454000188|3704|山东省|枣庄市'
      },
      {
        label: '中国工商银行股份有限公司枣庄薛城支行',
        value: '102454000207|3704|山东省|枣庄市'
      },
      {
        label: '中国工商银行股份有限公司枣庄泰山南路支行',
        value: '102454000215|3704|山东省|枣庄市'
      },
      {
        label: '中国工商银行股份有限公司枣庄陶庄支行',
        value: '102454000223|3704|山东省|枣庄市'
      },
      {
        label: '中国工商银行股份有限公司枣庄薛城支行中心分理处',
        value: '102454000231|3704|山东省|枣庄市'
      },
      {
        label: '中国工商银行股份有限公司枣庄高新支行',
        value: '102454000240|3704|山东省|枣庄市'
      },
      {
        label: '中国工商银行股份有限公司枣庄光明广场支行',
        value: '102454000434|3704|山东省|枣庄市'
      },
      {
        label: '中国工商银行股份有限公司伊金霍洛文明路支行',
        value: '102205800033|1506|内蒙古自治区|鄂尔多斯市'
      },
      {
        label: '中国工商银行股份有限公司浙江省宁波中园支行',
        value: '102332012156|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行宁波市江北支行',
        value: '102332013001|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行股份有限公司宁波日湖支行',
        value: '102332013010|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行宁波慈城支行',
        value: '102332013028|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行股份有限公司宁波江北支行槐树路分理处',
        value: '102332013052|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行股份有限公司宁波洪都支行',
        value: '102332013085|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行股份有限公司宁波育才支行',
        value: '102332013093|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行股份有限公司宁波江北支行新马路分理处',
        value: '102332013108|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行股份有限公司宁波甬江支行',
        value: '102332013116|3302|浙江省|宁波市'
      },
      {
        label: '工行宁夏宁东灵新支行',
        value: '102871006444|6401|宁夏回族自治区|银川市'
      },
      {
        label: '中国工商银行股份有限公司南昌青山路支行',
        value: '102421000392|3601|江西省|南昌市'
      },
      {
        label: '中国工商银行股份有限公司南昌塘山支行',
        value: '102421000405|3601|江西省|南昌市'
      },
      {
        label: '中国工商银行股份有限公司江西赣江新区支行',
        value: '102421000413|3601|江西省|南昌市'
      },
      {
        label: '中国工商银行股份有限公司南昌豫章支行',
        value: '102421000421|3601|江西省|南昌市'
      },
      {
        label: '中国工商银行股份有限公司南昌建设西路支行',
        value: '102421000430|3601|江西省|南昌市'
      },
      {
        label: '中国工商银行股份有限公司南昌广兰大道支行',
        value: '102421000448|3601|江西省|南昌市'
      },
      {
        label: '中国工商银行股份有限公司南昌福州路支行',
        value: '102421000501|3601|江西省|南昌市'
      },
      {
        label: '中国工商银行股份有限公司南昌师大支行',
        value: '102421000528|3601|江西省|南昌市'
      },
      {
        label: '中国工商银行股份有限公司南昌省府二大院支行',
        value: '102421000536|3601|江西省|南昌市'
      },
      {
        label: '中国工商银行股份有限公司南昌卧龙支行',
        value: '102421000569|3601|江西省|南昌市'
      },
      {
        label: '中国工商银行股份有限公司南昌新华文化广场支行',
        value: '102421000577|3601|江西省|南昌市'
      },
      {
        label: '中国工商银行股份有限公司南昌京东支行',
        value: '102421000608|3601|江西省|南昌市'
      },
      {
        label: '中国工商银行股份有限公司通渭支行',
        value: '102829200159|6211|甘肃省|定西市'
      },
      {
        label: '中国工商银行陇西县支行',
        value: '102829300037|6211|甘肃省|定西市'
      },
      {
        label: '中国工商银行股份有限公司陇西文峰支行',
        value: '102829300115|6211|甘肃省|定西市'
      },
      {
        label: '中国工商银行陇西县西郊支行',
        value: '102829300131|6211|甘肃省|定西市'
      },
      {
        label: '中国工商银行股份有限公司渭源支行',
        value: '102829407744|6211|甘肃省|定西市'
      },
      {
        label: '中国工商银行临洮县支行',
        value: '102829500047|6211|甘肃省|定西市'
      },
      {
        label: '中国工商银行股份有限公司临洮北门支行',
        value: '102829500292|6211|甘肃省|定西市'
      },
      {
        label: '中国工商银行股份有限公司漳县支行',
        value: '102829607734|6211|甘肃省|定西市'
      },
      {
        label: '中国工商银行岷县支行',
        value: '102829700057|6211|甘肃省|定西市'
      },
      {
        label: '中国工商银行股份有限公司岷县岷州中路支行',
        value: '102829700170|6211|甘肃省|定西市'
      },
      {
        label: '中国工商银行陇南分行会计业务处理中心',
        value: '102831000017|6212|甘肃省|陇南市'
      },
      {
        label: '中国工商银行陇南分行武都支行',
        value: '102831000025|6212|甘肃省|陇南市'
      },
      {
        label: '中国工商银行股份有限公司陇南武都大桥支行',
        value: '102831000084|6212|甘肃省|陇南市'
      },
      {
        label: '中国工商银行股份有限公司陇南新市街支行',
        value: '102831050036|6212|甘肃省|陇南市'
      },
      {
        label: '中国工商银行股份有限公司宕昌支行',
        value: '102831200253|6212|甘肃省|陇南市'
      },
      {
        label: '中国工商银行陇南分行成县支行',
        value: '102831300051|6212|甘肃省|陇南市'
      },
      {
        label: '中国工商银行股份有限公司成县东街支行',
        value: '102831300176|6212|甘肃省|陇南市'
      },
      {
        label: '中国工商银行股份有限公司康县支行',
        value: '102831401688|6212|甘肃省|陇南市'
      },
      {
        label: '中国工商银行陇南分行文县支行',
        value: '102831500045|6212|甘肃省|陇南市'
      },
      {
        label: '中国工商银行陇南分行文县碧口支行',
        value: '102831500203|6212|甘肃省|陇南市'
      },
      {
        label: '中国工商银行陇南分行西和县支行',
        value: '102831600072|6212|甘肃省|陇南市'
      },
      {
        label: '中国工商银行股份有限公司礼县支行',
        value: '102831702817|6212|甘肃省|陇南市'
      },
      {
        label: '中国工商银行股份有限公司两当支行',
        value: '102831810027|6212|甘肃省|陇南市'
      },
      {
        label: '中国工商银行陇南分行徽县支行',
        value: '102831900065|6212|甘肃省|陇南市'
      },
      {
        label: '中国工商银行平凉市分行会计业务处理中心',
        value: '102833000012|6208|甘肃省|平凉市'
      },
      {
        label: '中国工商银行股份有限公司平凉分行',
        value: '102833000029|6208|甘肃省|平凉市'
      },
      {
        label: '中国工商银行平凉市崆峒支行',
        value: '102833000037|6208|甘肃省|平凉市'
      },
      {
        label: '中国工商银行平凉市中山支行',
        value: '102833000045|6208|甘肃省|平凉市'
      },
      {
        label: '中国工商银行股份有限公司平凉世纪花园支行',
        value: '102833000096|6208|甘肃省|平凉市'
      },
      {
        label: '中国工商银行股份有限公司平凉西郊支行',
        value: '102833000123|6208|甘肃省|平凉市'
      },
      {
        label: '中国工商银行股份有限公司平凉广成路支行',
        value: '102833000158|6208|甘肃省|平凉市'
      },
      {
        label: '中国工商银行股份有限公司乌鲁木齐建国路支行',
        value: '102881001169|6501|新疆维吾尔自治区|乌鲁木齐市'
      },
      {
        label: '中国工商银行股份有限公司乌鲁木齐青年路支行',
        value: '102881001185|6501|新疆维吾尔自治区|乌鲁木齐市'
      },
      {
        label: '中国工商银行股份有限公司乌鲁木齐新华南路支行',
        value: '102881001193|6501|新疆维吾尔自治区|乌鲁木齐市'
      },
      {
        label: '中国工商银行股份有限公司乌鲁木齐北京中路支行',
        value: '102881001216|6501|新疆维吾尔自治区|乌鲁木齐市'
      },
      {
        label: '中国工商银行股份有限公司乌鲁木齐光明路支行',
        value: '102881001224|6501|新疆维吾尔自治区|乌鲁木齐市'
      },
      {
        label: '中国工商银行股份有限公司乌鲁木齐幸福路支行',
        value: '102881001232|6501|新疆维吾尔自治区|乌鲁木齐市'
      },
      {
        label: '中国工商银行股份有限公司乌鲁木齐大湾北路支行',
        value: '102881001265|6501|新疆维吾尔自治区|乌鲁木齐市'
      },
      {
        label: '中国工商银行股份有限公司乌鲁木齐新民西街支行',
        value: '102881001273|6501|新疆维吾尔自治区|乌鲁木齐市'
      },
      {
        label: '中国工商银行股份有限公司乌鲁木齐南湖北路支行',
        value: '102881001281|6501|新疆维吾尔自治区|乌鲁木齐市'
      },
      {
        label: '中国工商银行股份有限公司乌鲁木齐西大桥支行',
        value: '102881001312|6501|新疆维吾尔自治区|乌鲁木齐市'
      },
      {
        label: '中国工商银行股份有限公司乌鲁木齐黄河路支行',
        value: '102881001329|6501|新疆维吾尔自治区|乌鲁木齐市'
      },
      {
        label: '中国工商银行股份有限公司乌鲁木齐友好路支行',
        value: '102881001337|6501|新疆维吾尔自治区|乌鲁木齐市'
      },
      {
        label: '中国工商银行股份有限公司乌鲁木齐钱塘江路支行',
        value: '102881001345|6501|新疆维吾尔自治区|乌鲁木齐市'
      },
      {
        label: '中国工商银行股份有限公司乌鲁木齐阿勒泰路支行',
        value: '102881001353|6501|新疆维吾尔自治区|乌鲁木齐市'
      },
      {
        label: '中国工商银行股份有限公司乌鲁木齐仓房沟支行',
        value: '102881001361|6501|新疆维吾尔自治区|乌鲁木齐市'
      },
      {
        label: '中国工商银行股份有限公司乌鲁木齐友好南路支行',
        value: '102881001370|6501|新疆维吾尔自治区|乌鲁木齐市'
      },
      {
        label: '中国工商银行股份有限公司长沙蔡锷北路支行',
        value: '102551010026|4301|湖南省|长沙市'
      },
      {
        label: '中国工商银行股份有限公司长沙步行街支行',
        value: '102551010042|4301|湖南省|长沙市'
      },
      {
        label: '中国工商银行延安市洛川县支行',
        value: '102805190064|6106|陕西省|延安市'
      },
      {
        label: '中国工商银行延吉光明支行',
        value: '102249003014|2224|吉林省|延边朝鲜族自治州'
      },
      {
        label: '中国工商银行延吉朝阳支行',
        value: '102249003022|2224|吉林省|延边朝鲜族自治州'
      },
      {
        label: '中国工商银行延吉新兴支行',
        value: '102249003039|2224|吉林省|延边朝鲜族自治州'
      },
      {
        label: '中国工商银行延吉爱丹路支行',
        value: '102249003071|2224|吉林省|延边朝鲜族自治州'
      },
      {
        label: '中国工商银行延吉铁南支行',
        value: '102249003102|2224|吉林省|延边朝鲜族自治州'
      },
      {
        label: '中国工商银行延吉海兰江支行',
        value: '102249004017|2224|吉林省|延边朝鲜族自治州'
      },
      {
        label: '中国工商银行延边州分行营业部',
        value: '102249004025|2224|吉林省|延边朝鲜族自治州'
      },
      {
        label: '中国工商银行延吉延西支行',
        value: '102249004076|2224|吉林省|延边朝鲜族自治州'
      },
      {
        label: '中国工商银行股份有限公司延吉朝阳川支行',
        value: '102249004084|2224|吉林省|延边朝鲜族自治州'
      },
      {
        label: '中国工商银行延吉宏银支行',
        value: '102249005010|2224|吉林省|延边朝鲜族自治州'
      },
      {
        label: '中国工商银行股份有限公司图们支行',
        value: '102249207017|2224|吉林省|延边朝鲜族自治州'
      },
      {
        label: '中国工商银行股份有限公司敦化支行',
        value: '102249312017|2224|吉林省|延边朝鲜族自治州'
      },
      {
        label: '中国工商银行敦化江东支行',
        value: '102249312033|2224|吉林省|延边朝鲜族自治州'
      },
      {
        label: '中国工商银行股份有限公司敦化曙光支行',
        value: '102249312050|2224|吉林省|延边朝鲜族自治州'
      },
      {
        label: '中国工商银行股份有限公司敦化工农路支行',
        value: '102249312068|2224|吉林省|延边朝鲜族自治州'
      },
      {
        label: '中国工商银行股份有限公司滨州分行',
        value: '102466002453|3716|山东省|滨州市'
      },
      {
        label: '中国工商银行惠民县支行',
        value: '102466200503|3716|山东省|滨州市'
      },
      {
        label: '中国工商银行股份有限公司阳信支行',
        value: '102466302358|3716|山东省|滨州市'
      },
      {
        label: '中国工商银行无棣县支行',
        value: '102466400605|3716|山东省|滨州市'
      },
      {
        label: '中国工商银行沾化县支行',
        value: '102466500709|3716|山东省|滨州市'
      },
      {
        label: '中国工商银行股份有限公司揭阳新兴支行',
        value: '102586502314|4405|广东省|汕头市'
      },
      {
        label: '中国工商银行股份有限公司揭阳阳美支行',
        value: '102586502710|4405|广东省|汕头市'
      },
      {
        label: '中国工商银行股份有限公司揭阳城东支行',
        value: '102586523239|4405|广东省|汕头市'
      },
      {
        label: '中国工商银行股份有限公司揭西支行',
        value: '102586600256|4405|广东省|汕头市'
      },
      {
        label: '中国工商银行股份有限公司揭西棉湖支行',
        value: '102586602514|4405|广东省|汕头市'
      },
      {
        label: '中国工商银行股份有限公司普宁支行',
        value: '102586700222|4405|广东省|汕头市'
      },
      {
        label: '中国工商银行股份有限公司普宁占陇支行',
        value: '102586702210|4405|广东省|汕头市'
      },
      {
        label: '中国工商银行股份有限公司普宁洪阳支行',
        value: '102586702228|4405|广东省|汕头市'
      },
      {
        label: '中国工商银行股份有限公司惠来支行',
        value: '102586800262|4405|广东省|汕头市'
      },
      {
        label: '中国工商银行股份有限公司潮州南门支行',
        value: '102586901024|4405|广东省|汕头市'
      },
      {
        label: '中国工商银行股份有限公司潮州金山支行',
        value: '102586901057|4405|广东省|汕头市'
      },
      {
        label: '中国工商银行股份有限公司潮州宫前支行',
        value: '102586901081|4405|广东省|汕头市'
      },
      {
        label: '中国工商银行股份有限公司潮州分行业务处理中心',
        value: '102586902003|4405|广东省|汕头市'
      },
      {
        label: '中国工商银行股份有限公司潮州太平支行',
        value: '102586902020|4405|广东省|汕头市'
      },
      {
        label: '中国工商银行股份有限公司潮州西河支行',
        value: '102586902038|4405|广东省|汕头市'
      },
      {
        label: '中国工商银行股份有限公司潮州湘桥支行',
        value: '102586902100|4405|广东省|汕头市'
      },
      {
        label: '中国工商银行股份有限公司潮州西湖支行',
        value: '102586902206|4405|广东省|汕头市'
      },
      {
        label: '中国工商银行股份有限公司潮州城新支行',
        value: '102586902214|4405|广东省|汕头市'
      },
      {
        label: '中国工商银行股份有限公司潮州站前支行',
        value: '102586902222|4405|广东省|汕头市'
      },
      {
        label: '中国工商银行股份有限公司潮州枫溪支行',
        value: '102586902319|4405|广东省|汕头市'
      },
      {
        label: '中国工商银行股份有限公司潮州分行营业部',
        value: '102586902409|4405|广东省|汕头市'
      },
      {
        label: '中国工商银行股份有限公司潮州城建支行',
        value: '102586902425|4405|广东省|汕头市'
      },
      {
        label: '中国工商银行股份有限公司揭东支行',
        value: '102587100244|4452|广东省|揭阳市'
      },
      {
        label: '中国工商银行股份有限公司潮安古巷支行',
        value: '102587201035|4452|广东省|揭阳市'
      },
      {
        label: '中国工商银行股份有限公司潮安支行',
        value: '102587202304|4452|广东省|揭阳市'
      },
      {
        label: '中国工商银行股份有限公司潮安庵埠支行',
        value: '102587202329|4452|广东省|揭阳市'
      },
      {
        label: '中国工商银行股份有限公司东方解放支行',
        value: '102641001476|4601|海南省|海口市'
      },
      {
        label: '中国工商银行股份有限公司昌江河北支行',
        value: '102641001492|4601|海南省|海口市'
      },
      {
        label: '中国工商银行股份有限公司澄迈老城支行',
        value: '102641001548|4601|海南省|海口市'
      },
      {
        label: '中国工商银行股份有限公司洋浦石华支行',
        value: '102641001556|4601|海南省|海口市'
      },
      {
        label: '中国工商银行股份有限公司黔西莲城支行',
        value: '102709300147|5205|贵州省|毕节市'
      },
      {
        label: '中国工商银行金沙县支行',
        value: '102709400085|5205|贵州省|毕节市'
      },
      {
        label: '中国工商银行股份有限公司克拉玛依旺角支行',
        value: '102882000131|6502|新疆维吾尔自治区|克拉玛依市'
      },
      {
        label: '中国工商银行股份有限公司克拉玛依南泉支行',
        value: '102882000211|6502|新疆维吾尔自治区|克拉玛依市'
      },
      {
        label: '中国工商银行股份有限公司克拉玛依幸福路支行',
        value: '102882000220|6502|新疆维吾尔自治区|克拉玛依市'
      },
      {
        label: '中国工商银行股份有限公司克拉玛依白碱滩支行',
        value: '102882000238|6502|新疆维吾尔自治区|克拉玛依市'
      },
      {
        label: '中国工商银行股份有限公司克拉玛依跃进支行',
        value: '102882000246|6502|新疆维吾尔自治区|克拉玛依市'
      },
      {
        label: '中国工商银行股份有限公司克拉玛依独山子支行',
        value: '102882000295|6502|新疆维吾尔自治区|克拉玛依市'
      },
      {
        label: '中国工商银行股份有限公司克拉玛依四区支行',
        value: '102882000318|6502|新疆维吾尔自治区|克拉玛依市'
      },
      {
        label: '中国工商银行股份有限公司克拉玛依石化支行',
        value: '102882000367|6502|新疆维吾尔自治区|克拉玛依市'
      },
      {
        label: '中国工商银行股份有限公司克拉玛依白碱滩支行乌尔禾分理处',
        value: '102882000391|6502|新疆维吾尔自治区|克拉玛依市'
      },
      {
        label: '中国工商银行股份有限公司吐鲁番地区分行业务处理中心',
        value: '102883000014|6521|新疆维吾尔自治区|吐鲁番地区'
      },
      {
        label: '中国工商银行股份有限公司吐鲁番分行',
        value: '102883000022|6521|新疆维吾尔自治区|吐鲁番地区'
      },
      {
        label: '中国工商银行股份有限公司吐鲁番老城西路支行',
        value: '102883000063|6521|新疆维吾尔自治区|吐鲁番地区'
      },
      {
        label: '中国工商银行股份有限公司吐鲁番文化路支行',
        value: '102883000071|6521|新疆维吾尔自治区|吐鲁番地区'
      },
      {
        label: '中国工商银行股份有限公司吐鲁番分行个人金融业务部',
        value: '102883000143|6521|新疆维吾尔自治区|吐鲁番地区'
      },
      {
        label: '中国工商银行西安大雁塔支行',
        value: '102791000283|6101|陕西省|西安市'
      },
      {
        label: '中国工商银行西安市土门支行',
        value: '102791000291|6101|陕西省|西安市'
      },
      {
        label: '工行陕西省西安西关支行',
        value: '102791000306|6101|陕西省|西安市'
      },
      {
        label: '中国工商银行股份有限公司陕西自贸区西安沣东支行',
        value: '102791000314|6101|陕西省|西安市'
      },
      {
        label: '工行陕西省西安丰镐路西口支行',
        value: '102791000322|6101|陕西省|西安市'
      },
      {
        label: '工行陕西省西安大庆路西口支行',
        value: '102791000339|6101|陕西省|西安市'
      },
      {
        label: '中国工商银行西安市纺织城支行',
        value: '102791000347|6101|陕西省|西安市'
      },
      {
        label: '工行陕西省西安洪庆支行',
        value: '102791000355|6101|陕西省|西安市'
      },
      {
        label: '中国工商银行西安市韩森寨支行',
        value: '102791000371|6101|陕西省|西安市'
      },
      {
        label: '中国工商银行西安咸宁路支行',
        value: '102791000380|6101|陕西省|西安市'
      },
      {
        label: '工行陕西省西安万寿路支行',
        value: '102791000398|6101|陕西省|西安市'
      },
      {
        label: '中国工商银行西安市阎良区支行',
        value: '102791000419|6101|陕西省|西安市'
      },
      {
        label: '工行陕西省西安长安区支行',
        value: '102791000427|6101|陕西省|西安市'
      },
      {
        label: '工行宁夏银川北京西路支行',
        value: '102871000154|6401|宁夏回族自治区|银川市'
      },
      {
        label: '工行宁夏银川长城路支行',
        value: '102871000259|6401|宁夏回族自治区|银川市'
      },
      {
        label: '工行宁夏银川太阳街支行',
        value: '102871000283|6401|宁夏回族自治区|银川市'
      },
      {
        label: '工行宁夏银川火车站支行',
        value: '102871000322|6401|宁夏回族自治区|银川市'
      },
      {
        label: '工行宁夏银川临湖支行',
        value: '102871000355|6401|宁夏回族自治区|银川市'
      },
      {
        label: '工行宁夏银川纬四路支行',
        value: '102871000427|6401|宁夏回族自治区|银川市'
      },
      {
        label: '工行宁夏银川福星苑支行',
        value: '102871000584|6401|宁夏回族自治区|银川市'
      },
      {
        label: '工行宁夏银川湖畔嘉苑支行',
        value: '102871000592|6401|宁夏回族自治区|银川市'
      },
      {
        label: '工行宁夏银川胜利南街支行',
        value: '102871000697|6401|宁夏回族自治区|银川市'
      },
      {
        label: '工行宁夏银川丽景支行',
        value: '102871000701|6401|宁夏回族自治区|银川市'
      },
      {
        label: '工行宁夏银川光华支行',
        value: '102871000710|6401|宁夏回族自治区|银川市'
      },
      {
        label: '工行宁夏银川民生支行',
        value: '102871000728|6401|宁夏回族自治区|银川市'
      },
      {
        label: '工行宁夏银川景墨支行',
        value: '102871000736|6401|宁夏回族自治区|银川市'
      },
      {
        label: '工行宁夏银川燕鸽湖支行',
        value: '102871000752|6401|宁夏回族自治区|银川市'
      },
      {
        label: '工行宁夏银川紫荆花支行',
        value: '102871000777|6401|宁夏回族自治区|银川市'
      },
      {
        label: '工行宁夏贺兰太阳城支行',
        value: '102871000785|6401|宁夏回族自治区|银川市'
      },
      {
        label: '工行宁夏银川宝湖支行',
        value: '102871000793|6401|宁夏回族自治区|银川市'
      },
      {
        label: '中国工商银行股份有限公司哈密前进东路支行',
        value: '102884000073|6522|新疆维吾尔自治区|哈密地区'
      },
      {
        label: '中国工商银行股份有限公司哈密解放路支行',
        value: '102884000081|6522|新疆维吾尔自治区|哈密地区'
      },
      {
        label: '中国工商银行股份有限公司哈密百花路支行',
        value: '102884000090|6522|新疆维吾尔自治区|哈密地区'
      },
      {
        label: '中国工商银行股份有限公司哈密天山西路支行',
        value: '102884000112|6522|新疆维吾尔自治区|哈密地区'
      },
      {
        label: '中国工商银行股份有限公司哈密广场北路支行',
        value: '102884000129|6522|新疆维吾尔自治区|哈密地区'
      },
      {
        label: '中国工商银行股份有限公司哈密淖毛湖开发区支行',
        value: '102884000215|6522|新疆维吾尔自治区|哈密地区'
      },
      {
        label: '中国工商银行股份有限公司北屯支行',
        value: '102884400019|6543|新疆维吾尔自治区|阿勒泰地区'
      },
      {
        label: '中国工商银行股份有限公司昌吉回族自治州分行业务处理中心',
        value: '102885080009|6523|新疆维吾尔自治区|昌吉回族自治州'
      },
      {
        label: '中国工商银行股份有限公司昌吉回族自治州分行',
        value: '102885080017|6523|新疆维吾尔自治区|昌吉回族自治州'
      },
      {
        label: '中国工商银行股份有限公司昌吉延安南路支行',
        value: '102885080025|6523|新疆维吾尔自治区|昌吉回族自治州'
      },
      {
        label: '工行宁夏分行清算中心（不办理对客户业务）',
        value: '102871009990|6401|宁夏回族自治区|银川市'
      },
      {
        label: '工行宁夏石嘴山支行',
        value: '102872000017|6402|宁夏回族自治区|石嘴山市'
      },
      {
        label: '工行宁夏石嘴山贺兰山南路支行',
        value: '102872000025|6402|宁夏回族自治区|石嘴山市'
      },
      {
        label: '工行宁夏石嘴山青山路支行',
        value: '102872000033|6402|宁夏回族自治区|石嘴山市'
      },
      {
        label: '工行宁夏石嘴山惠农支行',
        value: '102872000041|6402|宁夏回族自治区|石嘴山市'
      },
      {
        label: '工行宁夏石嘴山游艺西街支行',
        value: '102872000076|6402|宁夏回族自治区|石嘴山市'
      },
      {
        label: '工行宁夏石嘴山支行锦林分理处',
        value: '102872000156|6402|宁夏回族自治区|石嘴山市'
      },
      {
        label: '工行宁夏石嘴山建设街支行',
        value: '102872004026|6402|宁夏回族自治区|石嘴山市'
      },
      {
        label: '工行宁夏石嘴山东风支行',
        value: '102872004034|6402|宁夏回族自治区|石嘴山市'
      },
      {
        label: '工行宁夏石嘴山永乐路支行',
        value: '102872004147|6402|宁夏回族自治区|石嘴山市'
      },
      {
        label: '中国工商银行巴中市平昌支行',
        value: '102675959614|5119|四川省|巴中市'
      },
      {
        label: '中国工商银行股份有限公司平昌信义大道支行',
        value: '102675959622|5119|四川省|巴中市'
      },
      {
        label: '中国工商银行股份有限公司大竹车坝支行',
        value: '102676100850|5117|四川省|达州市'
      },
      {
        label: '中国工商银行股份有限公司大竹竹海路支行',
        value: '102676100884|5117|四川省|达州市'
      },
      {
        label: '中国工商银行股份有限公司大竹金利多支行',
        value: '102676100921|5117|四川省|达州市'
      },
      {
        label: '中国工商银行股份有限公司达州大竹支行',
        value: '102676157813|5117|四川省|达州市'
      },
      {
        label: '中国工商银行股份有限公司渠县和平街支行',
        value: '102676200631|5117|四川省|达州市'
      },
      {
        label: '中国工商银行股份有限公司渠县南门支行',
        value: '102676200640|5117|四川省|达州市'
      },
      {
        label: '中国工商银行股份有限公司渠县东城支行',
        value: '102676200682|5117|四川省|达州市'
      },
      {
        label: '中国工商银行股份有限公司达州渠县支行',
        value: '102676257913|5117|四川省|达州市'
      },
      {
        label: '中国工商银行股份有限公司渠县华蓥山电厂支行',
        value: '102676257930|5117|四川省|达州市'
      },
      {
        label: '中国工商银行股份有限公司邻水支行恒源分理处',
        value: '102676300468|5116|四川省|广安市'
      },
      {
        label: '中国工商银行股份有限公司邻水支行古邻大道分理处',
        value: '102676300513|5116|四川省|广安市'
      },
      {
        label: '中国工商银行邻水县支行',
        value: '102676355616|5116|四川省|广安市'
      },
      {
        label: '中国工商银行股份有限公司雅安熊猫大道支行',
        value: '102677000149|5118|四川省|雅安市'
      },
      {
        label: '中国工商银行股份有限公司雅安上坝路支行',
        value: '102677000190|5118|四川省|雅安市'
      },
      {
        label: '中国工商银行股份有限公司雅安西门北路支行',
        value: '102677000253|5118|四川省|雅安市'
      },
      {
        label: '中国工商银行股份有限公司雅安分行会计业务处理中心',
        value: '102677061111|5118|四川省|雅安市'
      },
      {
        label: '中国工商银行股份有限公司雅安河北街支行',
        value: '102677061419|5118|四川省|雅安市'
      },
      {
        label: '中国工商银行股份有限公司雅安中大街支行',
        value: '102677061427|5118|四川省|雅安市'
      },
      {
        label: '中国工商银行股份有限公司雅安羌江南路支行',
        value: '102677061435|5118|四川省|雅安市'
      },
      {
        label: '中国工商银行股份有限公司雅安分行',
        value: '102677061443|5118|四川省|雅安市'
      },
      {
        label: '中国工商银行股份有限公司名山支行',
        value: '102677261463|5118|四川省|雅安市'
      },
      {
        label: '中国工商银行股份有限公司荥经支行',
        value: '102677361618|5118|四川省|雅安市'
      },
      {
        label: '中国工商银行股份有限公司汉源支行富林分理处',
        value: '102677400722|5118|四川省|雅安市'
      },
      {
        label: '中国工商银行股份有限公司汉源支行九襄分理处',
        value: '102677400739|5118|四川省|雅安市'
      },
      {
        label: '中国工商银行股份有限公司汉源支行',
        value: '102677461715|5118|四川省|雅安市'
      },
      {
        label: '中国工商银行股份有限公司石棉南桠河支行',
        value: '102677500645|5118|四川省|雅安市'
      },
      {
        label: '中国工商银行股份有限公司自贡汇西支行',
        value: '102655001085|5103|四川省|自贡市'
      },
      {
        label: '中国工商银行股份有限公司四川省自贡东锅支行',
        value: '102655001212|5103|四川省|自贡市'
      },
      {
        label: '中国工商银行股份有限公司乌鲁木齐天津北路支行',
        value: '102881002889|6501|新疆维吾尔自治区|乌鲁木齐市'
      },
      {
        label: '中国工商银行焦作分行博爱县支行',
        value: '102501202389|4108|河南省|焦作市'
      },
      {
        label: '中国工商银行股份有限公司成都金堂广场支行',
        value: '102651025113|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司金堂淮洲支行',
        value: '102651025121|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司四川省成都温江支行营业室',
        value: '102651025201|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都百锦支行',
        value: '102651025228|5101|四川省|成都市'
      },
      {
        label: '工行陕西省延安洛川交口河支行',
        value: '102805195044|6106|陕西省|延安市'
      },
      {
        label: '中国工商银行延安市宜川县支行',
        value: '102805290085|6106|陕西省|延安市'
      },
      {
        label: '工行陕西省延安黄陵店头支行',
        value: '102805490038|6106|陕西省|延安市'
      },
      {
        label: '中国工商银行延安市黄陵县支行',
        value: '102805490118|6106|陕西省|延安市'
      },
      {
        label: '中国工商银行榆林市榆阳区支行',
        value: '102806010019|6108|陕西省|榆林市'
      },
      {
        label: '中国工商银行榆林肤施路支行',
        value: '102806010150|6108|陕西省|榆林市'
      },
      {
        label: '中国工商银行榆林市西沙支行',
        value: '102806010168|6108|陕西省|榆林市'
      },
      {
        label: '工行榆林新建路南支行',
        value: '102806010176|6108|陕西省|榆林市'
      },
      {
        label: '工行陕西省榆林高新区支行',
        value: '102806010184|6108|陕西省|榆林市'
      },
      {
        label: '工行陕西省榆林兴榆路支行',
        value: '102806010192|6108|陕西省|榆林市'
      },
      {
        label: '中国工商银行榆林分行广济支行',
        value: '102806010230|6108|陕西省|榆林市'
      },
      {
        label: '中国工商银行榆林分行业务处理中心',
        value: '102806015009|6108|陕西省|榆林市'
      },
      {
        label: '中国工商银行榆林西人民路支行',
        value: '102806015025|6108|陕西省|榆林市'
      },
      {
        label: '中国工商银行榆阳区世纪广场支行',
        value: '102806015033|6108|陕西省|榆林市'
      },
      {
        label: '工行陕西省榆林阳光支行',
        value: '102806015076|6108|陕西省|榆林市'
      },
      {
        label: '工行陕西省榆林神木县麟州街支行',
        value: '102806200041|6108|陕西省|榆林市'
      },
      {
        label: '中国工商银行神木县支行',
        value: '102806210029|6108|陕西省|榆林市'
      },
      {
        label: '中国工商银行神木县大柳塔支行',
        value: '102806210131|6108|陕西省|榆林市'
      },
      {
        label: '工行陕西省榆林神木县北新街支行',
        value: '102806210203|6108|陕西省|榆林市'
      },
      {
        label: '工行陕西省榆林神木县驼峰街支行',
        value: '102806210220|6108|陕西省|榆林市'
      },
      {
        label: '工行陕西省榆林大柳塔神东矿区支行',
        value: '102806210287|6108|陕西省|榆林市'
      },
      {
        label: '中国工商银行神木县锦界支行',
        value: '102806215065|6108|陕西省|榆林市'
      },
      {
        label: '工行陕西省榆林府谷县大桥路支行',
        value: '102806310072|6108|陕西省|榆林市'
      },
      {
        label: '工行陕西省榆林府谷县河滨路支行',
        value: '102806310089|6108|陕西省|榆林市'
      },
      {
        label: '中国工商银行府谷县支行',
        value: '102806310097|6108|陕西省|榆林市'
      },
      {
        label: '工行陕西省榆林横山县支行',
        value: '102806400010|6108|陕西省|榆林市'
      },
      {
        label: '中国工商银行靖边县支行',
        value: '102806510057|6108|陕西省|榆林市'
      },
      {
        label: '工行陕西省榆林靖边县河东支行',
        value: '102806510240|6108|陕西省|榆林市'
      },
      {
        label: '工行陕西省榆林靖边县城西支行',
        value: '102806510274|6108|陕西省|榆林市'
      },
      {
        label: '工行陕西省榆林定边县新区支行',
        value: '102806600351|6108|陕西省|榆林市'
      },
      {
        label: '工行陕西省榆林定边县长城街支行',
        value: '102806600360|6108|陕西省|榆林市'
      },
      {
        label: '中国工商银行股份有限公司株洲南华支行',
        value: '102552000485|4302|湖南省|株洲市'
      },
      {
        label: '中国工商银行股份有限公司株洲分行建宁分理处',
        value: '102552000493|4302|湖南省|株洲市'
      },
      {
        label: '中国工商银行株洲长江路支行',
        value: '102552000532|4302|湖南省|株洲市'
      },
      {
        label: '中国工商银行股份有限公司株洲珠江路支行',
        value: '102552000549|4302|湖南省|株洲市'
      },
      {
        label: '中国工商银行股份有限公司株洲天园支行',
        value: '102552000557|4302|湖南省|株洲市'
      },
      {
        label: '中国工商银行株洲黄河路支行',
        value: '102552000565|4302|湖南省|株洲市'
      },
      {
        label: '中国工商银行股份有限公司株洲白石港支行',
        value: '102552000590|4302|湖南省|株洲市'
      },
      {
        label: '中国工商银行股份有限公司株洲数码城支行',
        value: '102552000604|4302|湖南省|株洲市'
      },
      {
        label: '中国工商银行株洲市奔龙支行',
        value: '102552002010|4302|湖南省|株洲市'
      },
      {
        label: '中国工商银行株洲市车站路支行',
        value: '102552002028|4302|湖南省|株洲市'
      },
      {
        label: '中国工商银行株洲市新华路支行',
        value: '102552002036|4302|湖南省|株洲市'
      },
      {
        label: '中国工商银行株洲市清水塘支行',
        value: '102552002044|4302|湖南省|株洲市'
      },
      {
        label: '中国工商银行株洲市田心支行',
        value: '102552002052|4302|湖南省|株洲市'
      },
      {
        label: '中国工商银行株洲市董家段支行',
        value: '102552002069|4302|湖南省|株洲市'
      },
      {
        label: '中国工商银行株洲市高新技术开发区支行',
        value: '102552002085|4302|湖南省|株洲市'
      },
      {
        label: '中国工商银行柳州分行雀儿山支行',
        value: '102614040500|4502|广西壮族自治区|柳州市'
      },
      {
        label: '中国工商银行股份有限公司金沙广场支行',
        value: '102709400157|5205|贵州省|毕节市'
      },
      {
        label: '中国工商银行股份有限公司金沙火电厂支行',
        value: '102709400165|5205|贵州省|毕节市'
      },
      {
        label: '中国工商银行织金县支行',
        value: '102709500078|5205|贵州省|毕节市'
      },
      {
        label: '中国工商银行股份有限公司织金平远支行',
        value: '102709501089|5205|贵州省|毕节市'
      },
      {
        label: '中国工商银行股份有限公司纳雍支行',
        value: '102709600095|5205|贵州省|毕节市'
      },
      {
        label: '中国工商银行威宁彝族回族苗族自治县支行',
        value: '102709700061|5205|贵州省|毕节市'
      },
      {
        label: '中国工商银行股份有限公司威宁草海支行',
        value: '102709700174|5205|贵州省|毕节市'
      },
      {
        label: '中国工商银行股份有限公司赫章支行',
        value: '102709801073|5205|贵州省|毕节市'
      },
      {
        label: '中国工商银行贵州省安顺市塔山支行',
        value: '102711000037|5204|贵州省|安顺市'
      },
      {
        label: '中国工商银行股份有限公司安顺建设路支行',
        value: '102711000045|5204|贵州省|安顺市'
      },
      {
        label: '中国工商银行安顺分行大十字支行',
        value: '102711000061|5204|贵州省|安顺市'
      },
      {
        label: '中国工商银行股份有限公司安顺春雷支行',
        value: '102711000070|5204|贵州省|安顺市'
      },
      {
        label: '中国工商银行股份有限公司安顺安大支行',
        value: '102711000096|5204|贵州省|安顺市'
      },
      {
        label: '中国工商银行股份有限公司安顺南华支行',
        value: '102711000107|5204|贵州省|安顺市'
      },
      {
        label: '中国工商银行股份有限公司安顺市府路支行',
        value: '102711000115|5204|贵州省|安顺市'
      },
      {
        label: '中国工商银行安顺市西航支行',
        value: '102711000123|5204|贵州省|安顺市'
      },
      {
        label: '中国工商银行贵州省安顺分行市东分理处',
        value: '102711000131|5204|贵州省|安顺市'
      },
      {
        label: '中国工商银行股份有限公司安顺体育场支行',
        value: '102711000140|5204|贵州省|安顺市'
      },
      {
        label: '中国工商银行股份有限公司安顺新天地支行',
        value: '102711000279|5204|贵州省|安顺市'
      },
      {
        label: '中国工商银行股份有限公司安顺南街支行',
        value: '102711000287|5204|贵州省|安顺市'
      },
      {
        label: '中国工商银行股份有限公司安顺若飞广场支行',
        value: '102711000295|5204|贵州省|安顺市'
      },
      {
        label: '中国工商银行股份有限公司贵州安顺格凸河支行',
        value: '102711000300|5204|贵州省|安顺市'
      },
      {
        label: '中国工商银行平坝县支行',
        value: '102711700190|5204|贵州省|安顺市'
      },
      {
        label: '中国工商银行安顺分行新寨支行',
        value: '102711700212|5204|贵州省|安顺市'
      },
      {
        label: '中国工商银行安顺分行夏云支行',
        value: '102711700229|5204|贵州省|安顺市'
      },
      {
        label: '中国工商银行安顺分行黎阳支行',
        value: '102711700237|5204|贵州省|安顺市'
      },
      {
        label: '中国工商银行股份有限公司深圳百花支行',
        value: '102584002848|4403|广东省|深圳市'
      },
      {
        label: '中国工商银行股份有限公司深圳龙岗支行',
        value: '102584002856|4403|广东省|深圳市'
      },
      {
        label: '中国工商银行股份有限公司深圳葵涌支行',
        value: '102584002864|4403|广东省|深圳市'
      },
      {
        label: '中国工商银行股份有限公司深圳鸿翔支行',
        value: '102584002889|4403|广东省|深圳市'
      },
      {
        label: '中国工商银行股份有限公司深圳深港支行',
        value: '102584002910|4403|广东省|深圳市'
      },
      {
        label: '中国工商银行股份有限公司深圳湖贝支行',
        value: '102584002928|4403|广东省|深圳市'
      },
      {
        label: '中国工商银行股份有限公司深圳海王支行',
        value: '102584002936|4403|广东省|深圳市'
      },
      {
        label: '中国工商银行股份有限公司深圳福强支行',
        value: '102584002969|4403|广东省|深圳市'
      },
      {
        label: '中国工商银行股份有限公司深圳景田支行',
        value: '102584002985|4403|广东省|深圳市'
      },
      {
        label: '中国工商银行股份有限公司深圳星海名城支行',
        value: '102584002993|4403|广东省|深圳市'
      },
      {
        label: '中国工商银行股份有限公司深圳彩田支行',
        value: '102584003038|4403|广东省|深圳市'
      },
      {
        label: '中国工商银行股份有限公司深圳海岸城支行',
        value: '102584003046|4403|广东省|深圳市'
      },
      {
        label: '中国工商银行股份有限公司深圳坂田支行',
        value: '102584003054|4403|广东省|深圳市'
      },
      {
        label: '中国工商银行股份有限公司成都永盛南路支行',
        value: '102651092727|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都均隆街支行',
        value: '102651092735|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都暑袜南街支行',
        value: '102651092809|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都猛追湾支行',
        value: '102651092905|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都天邑花园支行',
        value: '102651093012|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都紫藤支行',
        value: '102651093029|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都抚琴支行',
        value: '102651093107|5101|四川省|成都市'
      },
      {
        label: '中国工商银行成都市西较场支行',
        value: '102651093203|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都团结北路支行',
        value: '102651093406|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都大弯中路支行',
        value: '102651093502|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都十陵支行',
        value: '102651093609|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都静安路支行',
        value: '102651093713|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司四川省成都双流华阳支行',
        value: '102651093801|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司四川省成都双流机场支行',
        value: '102651093908|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都府河音乐花园支行',
        value: '102651093916|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都白河路支行',
        value: '102651093924|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都城东支行',
        value: '102651094007|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都犀浦支行',
        value: '102651094103|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司四川省成都新繁支行',
        value: '102651094200|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都马超西路支行',
        value: '102651094306|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都华金大道支行',
        value: '102651094435|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都来凤路支行',
        value: '102651094494|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司西宁城中支行',
        value: '102851000155|6301|青海省|西宁市'
      },
      {
        label: '中国工商银行股份有限公司西宁西川支行',
        value: '102851000198|6301|青海省|西宁市'
      },
      {
        label: '中国工商银行股份有限公司西宁大通人民路支行',
        value: '102851000325|6301|青海省|西宁市'
      },
      {
        label: '中国工商银行股份有限公司西宁西门口支行',
        value: '102851000350|6301|青海省|西宁市'
      },
      {
        label: '中国工商银行股份有限公司西宁中心广场支行',
        value: '102851000489|6301|青海省|西宁市'
      },
      {
        label: '中国工商银行股份有限公司西宁青铝支行',
        value: '102851000497|6301|青海省|西宁市'
      },
      {
        label: '中国工商银行股份有限公司西宁城北支行马坊分理处',
        value: '102851000624|6301|青海省|西宁市'
      },
      {
        label: '中国工商银行股份有限公司西宁虎台支行',
        value: '102851001537|6301|青海省|西宁市'
      },
      {
        label: '中国工商银行股份有限公司西宁经济技术开发区支行',
        value: '102851001834|6301|青海省|西宁市'
      },
      {
        label: '中国工商银行股份有限公司西宁中心广场支行宁新分理处',
        value: '102851002683|6301|青海省|西宁市'
      },
      {
        label: '中国工商银行宁乡县支行煤炭坝分理处',
        value: '102551000650|4301|湖南省|长沙市'
      },
      {
        label: '中国工商银行股份有限公司宁乡城西支行',
        value: '102551000692|4301|湖南省|长沙市'
      },
      {
        label: '中国工商银行股份有限公司长沙司门口支行解放西路储蓄所',
        value: '102551000797|4301|湖南省|长沙市'
      },
      {
        label: '中国工商银行股份有限公司望城景城苑支行',
        value: '102551000893|4301|湖南省|长沙市'
      },
      {
        label: '中国工商银行股份有限公司长沙大椿桥支行',
        value: '102551000924|4301|湖南省|长沙市'
      },
      {
        label: '中国工商银行股份有限公司长沙金盆岭支行',
        value: '102551000949|4301|湖南省|长沙市'
      },
      {
        label: '中国工商银行股份有限公司长沙南湖路支行',
        value: '102551000957|4301|湖南省|长沙市'
      },
      {
        label: '中国工商银行股份有限公司长沙湖南商学院支行',
        value: '102551000965|4301|湖南省|长沙市'
      },
      {
        label: '中国工商银行股份有限公司长沙月湖支行',
        value: '102551001031|4301|湖南省|长沙市'
      },
      {
        label: '中国工商银行股份有限公司长沙岳麓山支行麓山南路分理处',
        value: '102551001074|4301|湖南省|长沙市'
      },
      {
        label: '中国工商银行股份有限公司长沙万家丽路支行',
        value: '102551001082|4301|湖南省|长沙市'
      },
      {
        label: '中国工商银行股份有限公司长沙湘江世纪城支行',
        value: '102551001187|4301|湖南省|长沙市'
      },
      {
        label: '中国工商银行长沙市韶山路支行窑岭分理处',
        value: '102551001195|4301|湖南省|长沙市'
      },
      {
        label: '中国工商银行股份有限公司长沙韶山路支行朝阳分理处',
        value: '102551001218|4301|湖南省|长沙市'
      },
      {
        label: '中国工商银行股份有限公司长沙万家丽广场支行',
        value: '102551001226|4301|湖南省|长沙市'
      },
      {
        label: '中国工商银行股份有限公司长沙曙光大邸支行',
        value: '102551001234|4301|湖南省|长沙市'
      },
      {
        label: '中国工商银行股份有限公司长沙博林支行',
        value: '102551001242|4301|湖南省|长沙市'
      },
      {
        label: '中国工商银行股份有限公司长沙蓉园支行',
        value: '102551001259|4301|湖南省|长沙市'
      },
      {
        label: '中国工商银行股份有限公司长沙紫微路支行',
        value: '102551001267|4301|湖南省|长沙市'
      },
      {
        label: '中国工商银行股份有限公司长沙友谊路支行',
        value: '102551001355|4301|湖南省|长沙市'
      },
      {
        label: '中国工商银行股份有限公司长沙东塘支行雨花亭分理处',
        value: '102551001380|4301|湖南省|长沙市'
      },
      {
        label: '中国工商银行股份有限公司长沙奎塘支行',
        value: '102551001427|4301|湖南省|长沙市'
      },
      {
        label: '中国工商银行股份有限公司长沙中意路支行',
        value: '102551001435|4301|湖南省|长沙市'
      },
      {
        label: '中国工商银行云南曲靖分行沾益西平支行',
        value: '102737100030|5303|云南省|曲靖市'
      },
      {
        label: '中国工商银行云南曲靖分行沾益花山支行',
        value: '102737102940|5303|云南省|曲靖市'
      },
      {
        label: '中国工商银行云南曲靖分行沾益支行',
        value: '102737105044|5303|云南省|曲靖市'
      },
      {
        label: '中国工商银行云南楚雄分行西城支行',
        value: '102738002727|5323|云南省|楚雄彝族自治州'
      },
      {
        label: '中国工商银行云南楚雄分行气象路分理处',
        value: '102738002735|5323|云南省|楚雄彝族自治州'
      },
      {
        label: '中国工商银行云南楚雄分行东路支行',
        value: '102738002956|5323|云南省|楚雄彝族自治州'
      },
      {
        label: '中国工商银行云南楚雄分行鹿城支行',
        value: '102738003203|5323|云南省|楚雄彝族自治州'
      },
      {
        label: '中国工商银行云南楚雄分行北浦路支行',
        value: '102738003220|5323|云南省|楚雄彝族自治州'
      },
      {
        label: '中国工商银行云南楚雄分行牡丹支行',
        value: '102738003246|5323|云南省|楚雄彝族自治州'
      },
      {
        label: '中国工商银行云南楚雄分行经济技术开发区支行',
        value: '102738003262|5323|云南省|楚雄彝族自治州'
      },
      {
        label: '中国工商银行云南楚雄分行阳光大道支行',
        value: '102738010028|5323|云南省|楚雄彝族自治州'
      },
      {
        label: '中国工商银行云南楚雄分行北路支行',
        value: '102738010052|5323|云南省|楚雄彝族自治州'
      },
      {
        label: '中国工商银行股份有限公司重庆白岩支行',
        value: '102667001126|5114|四川省|眉山市'
      },
      {
        label: '中国工商银行股份有限公司重庆市开县支行',
        value: '102667300876|5114|四川省|眉山市'
      },
      {
        label: '中国工商银行股份有限公司忠县支行',
        value: '102667400867|5114|四川省|眉山市'
      },
      {
        label: '中国工商银行股份有限公司成都草市支行金沙分理处',
        value: '102651026534|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都武侯科技园支行',
        value: '102651026542|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都广福桥北街支行',
        value: '102651026614|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司四川省成都市抚琴西路支行',
        value: '102651027107|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都创业路支行',
        value: '102651027527|5101|四川省|成都市'
      },
      {
        label: '中国工商银行成都市蒲江县支行营业室',
        value: '102651027908|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都东大支行营业室',
        value: '102651029807|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都青羊工业园区支行',
        value: '102651029840|5101|四川省|成都市'
      },
      {
        label: '中国工商银行四川省分行清算中心(不对外办理业务)',
        value: '102651066669|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都分行业务处理中心',
        value: '102651086203|5101|四川省|成都市'
      },
      {
        label: '中国工商银行成都市盐市口支行营业室',
        value: '102651090209|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司内江公园街支行',
        value: '102663000324|5110|四川省|内江市'
      },
      {
        label: '中国工商银行股份有限公司内江汉安支行',
        value: '102663000515|5110|四川省|内江市'
      },
      {
        label: '中国工商银行股份有限公司内江蟠龙坝支行',
        value: '102663000531|5110|四川省|内江市'
      },
      {
        label: '中国工商银行股份有限公司内江分行江华街分理处',
        value: '102663000540|5110|四川省|内江市'
      },
      {
        label: '中国工商银行股份有限公司内江东兴支行',
        value: '102663001028|5110|四川省|内江市'
      },
      {
        label: '中国工商银行股份有限公司内江分行业务处理中心',
        value: '102663048112|5110|四川省|内江市'
      },
      {
        label: '中国工商银行股份有限公司内江壕子口支行',
        value: '102663048231|5110|四川省|内江市'
      },
      {
        label: '中国工商银行股份有限公司内江玉溪路支行',
        value: '102663048240|5110|四川省|内江市'
      },
      {
        label: '中国工商银行股份有限公司内江白马支行',
        value: '102663048258|5110|四川省|内江市'
      },
      {
        label: '中国工商银行股份有限公司内江中兴支行',
        value: '102663048311|5110|四川省|内江市'
      },
      {
        label: '中国工商银行股份有限公司内江西林新区支行',
        value: '102663048320|5110|四川省|内江市'
      },
      {
        label: '中国工商银行股份有限公司内江碑木支行',
        value: '102663048338|5110|四川省|内江市'
      },
      {
        label: '中国工商银行股份有限公司内江分行营业室',
        value: '102663048418|5110|四川省|内江市'
      },
      {
        label: '中国工商银行股份有限公司内江万达广场支行',
        value: '102663048514|5110|四川省|内江市'
      },
      {
        label: '中国工商银行云南曲靖分行开发区支行',
        value: '102736003126|5303|云南省|曲靖市'
      },
      {
        label: '中国工商银行云南曲靖分行牡丹支行',
        value: '102736003142|5303|云南省|曲靖市'
      },
      {
        label: '中国工商银行云南曲靖分行会计业务处理中心',
        value: '102736005017|5303|云南省|曲靖市'
      },
      {
        label: '中国工商银行云南曲靖分行珠源支行',
        value: '102736005025|5303|云南省|曲靖市'
      },
      {
        label: '中国工商银行云南曲靖分行麒麟支行',
        value: '102736005033|5303|云南省|曲靖市'
      },
      {
        label: '中国工商银行云南曲靖分行白石江支行',
        value: '102736005050|5303|云南省|曲靖市'
      },
      {
        label: '中国工商银行云南曲靖分行城关支行',
        value: '102736005068|5303|云南省|曲靖市'
      },
      {
        label: '中国工商银行云南曲靖分行宣威东风支行',
        value: '102736300290|5303|云南省|曲靖市'
      },
      {
        label: '中国工商银行股份有限公司威海分行营业部',
        value: '102465000149|3710|山东省|威海市'
      },
      {
        label: '中国工商银行柳州分行箭盘山支行',
        value: '102614040606|4502|广西壮族自治区|柳州市'
      },
      {
        label: '中国工商银行柳州分行高新开发区支行',
        value: '102614040702|4502|广西壮族自治区|柳州市'
      },
      {
        label: '中国工商银行柳州分行广场支行',
        value: '102614040809|4502|广西壮族自治区|柳州市'
      },
      {
        label: '中国工商银行柳州分行河西支行',
        value: '102614040905|4502|广西壮族自治区|柳州市'
      },
      {
        label: '中国工商银行股份有限公司柳州市和平支行',
        value: '102614045089|4502|广西壮族自治区|柳州市'
      },
      {
        label: '中国工商银行股份有限公司柳州市汽车厂支行',
        value: '102614045860|4502|广西壮族自治区|柳州市'
      },
      {
        label: '中国工商银行股份有限公司柳州市五菱支行',
        value: '102614045984|4502|广西壮族自治区|柳州市'
      },
      {
        label: '中国工商银行柳江县支行',
        value: '102614141005|4502|广西壮族自治区|柳州市'
      },
      {
        label: '中国工商银行柳城县支行',
        value: '102614241100|4502|广西壮族自治区|柳州市'
      },
      {
        label: '中国工商银行股份有限公司武汉余家头支行',
        value: '102521002783|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行股份有限公司武汉粮道街支行',
        value: '102521002806|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行股份有限公司武汉临江府支行',
        value: '102521002822|4201|湖北省|武汉市'
      },
      {
        label: '工行湖北省分行武汉十九街支行',
        value: '102521002839|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行股份有限公司武汉建设七路支行',
        value: '102521002847|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行股份有限公司武汉109街支行',
        value: '102521002863|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行股份有限公司武汉工业一路支行',
        value: '102521002871|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行股份有限公司武汉国信新城支行',
        value: '102521002880|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行股份有限公司武汉工业大道支行',
        value: '102521002927|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行武汉钢花支行',
        value: '102521002943|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行股份有限公司武汉虎泉支行',
        value: '102521003085|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行股份有限公司武汉黄金山支行',
        value: '102521003124|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行股份有限公司武汉地大支行',
        value: '102521003149|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行股份有限公司武汉汉口北支行',
        value: '102521003181|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行武汉利济路支行',
        value: '102521003245|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行武汉长飞支行',
        value: '102521003261|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行股份有限公司武汉二雅路支行',
        value: '102521003288|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行股份有限公司武汉熊廷弼路支行',
        value: '102521003307|4201|湖北省|武汉市'
      },
      {
        label: '工行湖北省分行武汉跃进桥支行',
        value: '102521003331|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行股份有限公司武汉建新支行',
        value: '102521003340|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行股份有限公司武汉邾城支行',
        value: '102521003366|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行武汉金色园支行',
        value: '102521003382|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行武汉钢洲支行',
        value: '102521003399|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行武汉沙湖支行',
        value: '102521003420|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行股份有限公司株洲分行红旗路分理处',
        value: '102552000225|4302|湖南省|株洲市'
      },
      {
        label: '中国工商银行股份有限公司株洲晏家湾支行',
        value: '102552000241|4302|湖南省|株洲市'
      },
      {
        label: '中国工商银行股份有限公司株洲湘天桥支行',
        value: '102552000305|4302|湖南省|株洲市'
      },
      {
        label: '中国工商银行株洲铁桥支行',
        value: '102552000330|4302|湖南省|株洲市'
      },
      {
        label: '中国工商银行股份有限公司株洲响石岭支行',
        value: '102552000356|4302|湖南省|株洲市'
      },
      {
        label: '中国工商银行株洲时代支行',
        value: '102552000397|4302|湖南省|株洲市'
      },
      {
        label: '中国工商银行股份有限公司株洲湖南工大支行',
        value: '102552000401|4302|湖南省|株洲市'
      },
      {
        label: '中国工商银行股份有限公司株洲新源支行',
        value: '102552000410|4302|湖南省|株洲市'
      },
      {
        label: '中国工商银行股份有限公司株洲幸福村支行',
        value: '102552000477|4302|湖南省|株洲市'
      },
      {
        label: '中国工商银行股份有限公司重庆五桥支行',
        value: '102667000830|5114|四川省|眉山市'
      },
      {
        label: '中国工商银行股份有限公司重庆新城支行',
        value: '102667001118|5114|四川省|眉山市'
      },
      {
        label: '中国工商银行云南文山分行会计业务处理中心',
        value: '102745006011|5326|云南省|文山壮族苗族自治州'
      },
      {
        label: '中国工商银行云南文山分行普阳支行',
        value: '102745006020|5326|云南省|文山壮族苗族自治州'
      },
      {
        label: '中国工商银行云南文山分行东风支行',
        value: '102745006062|5326|云南省|文山壮族苗族自治州'
      },
      {
        label: '中国工商银行云南文山分行南苑支行',
        value: '102745006079|5326|云南省|文山壮族苗族自治州'
      },
      {
        label: '中国工商银行云南文山分行开化北路支行',
        value: '102745006095|5326|云南省|文山壮族苗族自治州'
      },
      {
        label: '中国工商银行云南文山分行文运支行',
        value: '102745006100|5326|云南省|文山壮族苗族自治州'
      },
      {
        label: '中国工商银行股份有限公司文山南市区支行',
        value: '102745006126|5326|云南省|文山壮族苗族自治州'
      },
      {
        label: '中国工商银行云南文山分行七花北路分理处',
        value: '102745008068|5326|云南省|文山壮族苗族自治州'
      },
      {
        label: '中国工商银行云南文山分行砚山县支行',
        value: '102745206035|5326|云南省|文山壮族苗族自治州'
      },
      {
        label: '中国工商银行股份有限公司砚山平远支行',
        value: '102745206264|5326|云南省|文山壮族苗族自治州'
      },
      {
        label: '中国工商银行股份有限公司砚山兴砚支行',
        value: '102745206272|5326|云南省|文山壮族苗族自治州'
      },
      {
        label: '中国工商银行云南文山分行麻栗坡县支行',
        value: '102745406058|5326|云南省|文山壮族苗族自治州'
      },
      {
        label: '中国工商银行云南普洱分行会计业务处理中心',
        value: '102747008019|5308|云南省|普洱市'
      },
      {
        label: '中国工商银行股份有限公司平果县新兴支行',
        value: '102626460191|4510|广西壮族自治区|百色市'
      },
      {
        label: '中国工商银行平果县支行',
        value: '102626460409|4510|广西壮族自治区|百色市'
      },
      {
        label: '中国工商银行股份有限公司靖西市支行',
        value: '102626660456|4510|广西壮族自治区|百色市'
      },
      {
        label: '中国工商银行股份有限公司田林县支行',
        value: '102627160481|4510|广西壮族自治区|百色市'
      },
      {
        label: '中国工商银行隆林各族自治县支行',
        value: '102627260433|4510|广西壮族自治区|百色市'
      },
      {
        label: '中国工商银行股份有限公司德保县支行',
        value: '102627560135|4510|广西壮族自治区|百色市'
      },
      {
        label: '中国工商银行河池分行',
        value: '102628180000|4512|广西壮族自治区|河池市'
      },
      {
        label: '中国工商银行股份有限公司汕头东厦北路支行',
        value: '102586003220|4405|广东省|汕头市'
      },
      {
        label: '中国工商银行股份有限公司四川省南充蓬安花园支行',
        value: '102673854327|5113|四川省|南充市'
      },
      {
        label: '中国工商银行股份有限公司四川省南充仪陇支行',
        value: '102673900255|5113|四川省|南充市'
      },
      {
        label: '中国工商银行武胜县支行',
        value: '102674155418|5116|四川省|广安市'
      },
      {
        label: '中国工商银行股份有限公司武胜十字街支行',
        value: '102674155426|5116|四川省|广安市'
      },
      {
        label: '中国工商银行股份有限公司武胜人民南路支行',
        value: '102674155434|5116|四川省|广安市'
      },
      {
        label: '中国工商银行股份有限公司四川省西充支行',
        value: '102674253436|5113|四川省|南充市'
      },
      {
        label: '中国工商银行股份有限公司西充安汉支行',
        value: '102674253444|5113|四川省|南充市'
      },
      {
        label: '中国工商银行股份有限公司阆中北门支行',
        value: '102674303066|5113|四川省|南充市'
      },
      {
        label: '中国工商银行股份有限公司四川省阆中支行',
        value: '102674354615|5113|四川省|南充市'
      },
      {
        label: '中国工商银行股份有限公司阆中古城支行',
        value: '102674354623|5113|四川省|南充市'
      },
      {
        label: '中国工商银行股份有限公司达州朝阳路支行',
        value: '102675000049|5117|四川省|达州市'
      },
      {
        label: '中国工商银行股份有限公司达州凤凰支行',
        value: '102675000057|5117|四川省|达州市'
      },
      {
        label: '中国工商银行股份有限公司达州北街口支行',
        value: '102675000258|5117|四川省|达州市'
      },
      {
        label: '中国工商银行股份有限公司达州海棠湾支行',
        value: '102675000320|5117|四川省|达州市'
      },
      {
        label: '中国工商银行股份有限公司达州二马路支行',
        value: '102675000379|5117|四川省|达州市'
      },
      {
        label: '中国工商银行股份有限公司达州三里坪支行',
        value: '102675000395|5117|四川省|达州市'
      },
      {
        label: '中国工商银股份有限公司达州柴市街支行',
        value: '102675000400|5117|四川省|达州市'
      },
      {
        label: '中国工商银行股份有限公司达州金龙支行',
        value: '102675001685|5117|四川省|达州市'
      },
      {
        label: '中国工商银行股份有限公司达州分行业务处理中心',
        value: '102675057111|5117|四川省|达州市'
      },
      {
        label: '中国工商银行股份有限公司达州分行营业室',
        value: '102675057419|5117|四川省|达州市'
      },
      {
        label: '中国工商银行股份有限公司达州通川支行',
        value: '102675057613|5117|四川省|达州市'
      },
      {
        label: '中国工商银行股份有限公司达州分行火车站支行',
        value: '102675057621|5117|四川省|达州市'
      },
      {
        label: '中国工商银行股份有限公司贵阳延安东路支行',
        value: '102701001329|5201|贵州省|贵阳市'
      },
      {
        label: '中国工商银行股份有限公司贵阳南浦路支行',
        value: '102701001337|5201|贵州省|贵阳市'
      },
      {
        label: '中国工商银行股份有限公司贵阳筑新路支行',
        value: '102701004098|5201|贵州省|贵阳市'
      },
      {
        label: '中国工商银行股份有限公司贵州省分行营业部业务处理中心',
        value: '102701005009|5201|贵州省|贵阳市'
      },
      {
        label: '中国工商银行贵阳市息烽县支行重钙厂分理处',
        value: '102701005025|5201|贵州省|贵阳市'
      },
      {
        label: '中国工商银行股份有限公司贵阳鸿通城支行',
        value: '102701005285|5201|贵州省|贵阳市'
      },
      {
        label: '中国工商银行股份有限公司清镇百花大道支行',
        value: '102701005523|5201|贵州省|贵阳市'
      },
      {
        label: '中国工商银行股份有限公司贵阳世纪金源支行',
        value: '102701005540|5201|贵州省|贵阳市'
      },
      {
        label: '中国工商银行股份有限公司修文扎佐支行',
        value: '102701005558|5201|贵州省|贵阳市'
      },
      {
        label: '中国工商银行股份有限公司贵阳贵大新校区支行',
        value: '102701005566|5201|贵州省|贵阳市'
      },
      {
        label: '中国工商银行股份有限公司贵阳黑马分理处',
        value: '102701005574|5201|贵州省|贵阳市'
      },
      {
        label: '中国工商银行股份有限公司南昌昌北支行',
        value: '102421000171|3601|江西省|南昌市'
      },
      {
        label: '中国工商银行股份有限公司宜宾翠柏大道支行',
        value: '102671050622|5115|四川省|宜宾市'
      },
      {
        label: '中国工商银行股份有限公司宜宾五粮液支行',
        value: '102671050639|5115|四川省|宜宾市'
      },
      {
        label: '中国工商银行股份有限公司宜宾临港支行',
        value: '102671050647|5115|四川省|宜宾市'
      },
      {
        label: '中国工商银行股份有限公司宜宾财富广场支行',
        value: '102671050655|5115|四川省|宜宾市'
      },
      {
        label: '中国工商银行股份有限公司宜宾翠屏支行',
        value: '102671050719|5115|四川省|宜宾市'
      },
      {
        label: '中国工商银行股份有限公司宜宾西郊支行',
        value: '102671050735|5115|四川省|宜宾市'
      },
      {
        label: '中国工商银行股份有限公司宜宾南门支行',
        value: '102671050743|5115|四川省|宜宾市'
      },
      {
        label: '中国工商银行股份有限公司宜宾北大街支行',
        value: '102671050751|5115|四川省|宜宾市'
      },
      {
        label: '中国工商银行股份有限公司宜宾分行',
        value: '102671050913|5115|四川省|宜宾市'
      },
      {
        label: '中国工商银行股份有限公司宜宾县支行',
        value: '102671250814|5115|四川省|宜宾市'
      },
      {
        label: '中国工商银行股份有限公司宜宾三角路支行',
        value: '102671250822|5115|四川省|宜宾市'
      },
      {
        label: '中国工商银行股份有限公司宜宾城北支行',
        value: '102671250839|5115|四川省|宜宾市'
      },
      {
        label: '中国工商银行股份有限公司宜宾金江支行',
        value: '102671250855|5115|四川省|宜宾市'
      },
      {
        label: '中国工商银行股份有限公司宜宾三元街支行',
        value: '102671308040|5115|四川省|宜宾市'
      },
      {
        label: '中国工商银行南溪县支行',
        value: '102671351711|5115|四川省|宜宾市'
      },
      {
        label: '中国工商银行江安县支行',
        value: '102671451611|5115|四川省|宜宾市'
      },
      {
        label: '中国工商银行股份有限公司江安东正支行',
        value: '102671451638|5115|四川省|宜宾市'
      },
      {
        label: '中国工商银行股份有限公司长宁竹海支行',
        value: '102671507055|5115|四川省|宜宾市'
      },
      {
        label: '中国工商银行长宁县支行',
        value: '102671551317|5115|四川省|宜宾市'
      },
      {
        label: '中国工商银行股份有限公司高县支行',
        value: '102671602492|5115|四川省|宜宾市'
      },
      {
        label: '中国工商银行筠连县支行',
        value: '102671751119|5115|四川省|宜宾市'
      },
      {
        label: '中国工商银行股份有限公司珙县新桥街支行',
        value: '102671804016|5115|四川省|宜宾市'
      },
      {
        label: '中国工商银行珙县支行',
        value: '102671851218|5115|四川省|宜宾市'
      },
      {
        label: '中国工商银行云南大理分行祥云县支行',
        value: '102751315088|5329|云南省|大理白族自治州'
      },
      {
        label: '中国工商银行云南大理分行南涧县支行',
        value: '102751615114|5329|云南省|大理白族自治州'
      },
      {
        label: '中国工商银行云南大理分行鹤庆鹤阳支行',
        value: '102752310364|5329|云南省|大理白族自治州'
      },
      {
        label: '中国工商银行云南大理分行鹤庆县支行',
        value: '102752315171|5329|云南省|大理白族自治州'
      },
      {
        label: '中国工商银行云南保山分行',
        value: '102753010018|5305|云南省|保山市'
      },
      {
        label: '中国工商银行云南保山分行东路支行',
        value: '102753010026|5305|云南省|保山市'
      },
      {
        label: '中国工商银行云南保山分行牡丹支行',
        value: '102753010075|5305|云南省|保山市'
      },
      {
        label: '中国工商银行云南保山分行祥和支行',
        value: '102753010083|5305|云南省|保山市'
      },
      {
        label: '中国工商银行贵阳市小河支行',
        value: '102701000184|5201|贵州省|贵阳市'
      },
      {
        label: '中国工商银行贵阳市乌当区支行',
        value: '102701000192|5201|贵州省|贵阳市'
      },
      {
        label: '中国工商银行股份有限公司贵阳机场支行',
        value: '102701000205|5201|贵州省|贵阳市'
      },
      {
        label: '中国工商银行股份有限公司贵阳新添支行',
        value: '102701000213|5201|贵州省|贵阳市'
      },
      {
        label: '中国工商银行贵阳市花溪区支行',
        value: '102701000221|5201|贵州省|贵阳市'
      },
      {
        label: '中国工商银行贵阳市白云区支行',
        value: '102701000230|5201|贵州省|贵阳市'
      },
      {
        label: '中国工商银行股份有限公司贵阳铝城支行',
        value: '102701000248|5201|贵州省|贵阳市'
      },
      {
        label: '中国工商银行贵阳市金桥支行',
        value: '102701000256|5201|贵州省|贵阳市'
      },
      {
        label: '中国工商银行贵阳市中南支行',
        value: '102701000264|5201|贵州省|贵阳市'
      },
      {
        label: '中国工商银行贵阳市万东支行',
        value: '102701000272|5201|贵州省|贵阳市'
      },
      {
        label: '中国工商银行贵阳市甲秀支行',
        value: '102701000289|5201|贵州省|贵阳市'
      },
      {
        label: '中国工商银行股份有限公司贵阳中曹支行',
        value: '102701000301|5201|贵州省|贵阳市'
      },
      {
        label: '中国工商银行股份有限公司贵阳太慈桥支行',
        value: '102701000310|5201|贵州省|贵阳市'
      },
      {
        label: '中国工商银行股份有限公司贵州省贵阳二戈寨支行',
        value: '102701000328|5201|贵州省|贵阳市'
      },
      {
        label: '中国工商银行淮北牡丹支行',
        value: '102366000137|3406|安徽省|淮北市'
      },
      {
        label: '中国工商银行淮北城建支行',
        value: '102366000145|3406|安徽省|淮北市'
      },
      {
        label: '中国工商银行股份有限公司淮北东区分理处',
        value: '102366000161|3406|安徽省|淮北市'
      },
      {
        label: '中国工商银行股份有限公司淮北相北分理处',
        value: '102366000196|3406|安徽省|淮北市'
      },
      {
        label: '中国工商银行股份有限公司淮北黎苑分理处',
        value: '102366000207|3406|安徽省|淮北市'
      },
      {
        label: '中国工商银行股份有限公司淮北人民西路支行',
        value: '102366000223|3406|安徽省|淮北市'
      },
      {
        label: '中国工商银行股份有限公司淮北古城路分理处',
        value: '102366000231|3406|安徽省|淮北市'
      },
      {
        label: '中国工商银行股份有限公司淮北百川支行',
        value: '102366000258|3406|安徽省|淮北市'
      },
      {
        label: '中国工商银行股份有限公司淮北石台分理处',
        value: '102366000274|3406|安徽省|淮北市'
      },
      {
        label: '中国工商银行股份有限公司淮北海孜支行',
        value: '102366000379|3406|安徽省|淮北市'
      },
      {
        label: '中国工商银行股份有限公司淮北人民中路支行',
        value: '102366000426|3406|安徽省|淮北市'
      },
      {
        label: '中国工商银行淮北分行业务处理中心',
        value: '102366001601|3406|安徽省|淮北市'
      },
      {
        label: '中国工商银行濉溪支行',
        value: '102366100381|3406|安徽省|淮北市'
      },
      {
        label: '中国工商银行股份有限公司濉溪光源支行',
        value: '102366100390|3406|安徽省|淮北市'
      },
      {
        label: '中国工商银行股份有限公司濉溪百善分理处',
        value: '102366100404|3406|安徽省|淮北市'
      },
      {
        label: '中国工商银行铜陵石城路支行',
        value: '102367000243|3407|安徽省|铜陵市'
      },
      {
        label: '中国工商银行股份有限公司铜陵百大支行',
        value: '102367002005|3407|安徽省|铜陵市'
      },
      {
        label: '中国工商银行铜陵扫把沟支行',
        value: '102367002101|3407|安徽省|铜陵市'
      },
      {
        label: '中国工商银行铜陵横港支行',
        value: '102367002110|3407|安徽省|铜陵市'
      },
      {
        label: '中国工商银行铜陵铜官山支行',
        value: '102367002208|3407|安徽省|铜陵市'
      },
      {
        label: '中国工商银行铜陵向阳山支行',
        value: '102367002304|3407|安徽省|铜陵市'
      },
      {
        label: '中国工商银行威海经济技术开发区支行',
        value: '102465000212|3710|山东省|威海市'
      },
      {
        label: '中国工商银行股份有限公司威海高技术开发区支行哈尔滨工业大学分',
        value: '102465000237|3710|山东省|威海市'
      },
      {
        label: '中国工商银行威海市环翠区支行',
        value: '102465000315|3710|山东省|威海市'
      },
      {
        label: '中国工商银行股份有限公司珠海分行营业部',
        value: '102585002497|4404|广东省|珠海市'
      },
      {
        label: '中国工商银行股份有限公司珠海莲花支行',
        value: '102585002510|4404|广东省|珠海市'
      },
      {
        label: '中国工商银行股份有限公司珠海金华支行',
        value: '102585002536|4404|广东省|珠海市'
      },
      {
        label: '中国工商银行股份有限公司珠海东风支行',
        value: '102585002544|4404|广东省|珠海市'
      },
      {
        label: '中国工商银行股份有限公司珠海夏美支行',
        value: '102585002552|4404|广东省|珠海市'
      },
      {
        label: '中国工商银行股份有限公司珠海夏湾支行',
        value: '102585002569|4404|广东省|珠海市'
      },
      {
        label: '中国工商银行股份有限公司珠海兴华支行',
        value: '102585002608|4404|广东省|珠海市'
      },
      {
        label: '中国工商银行股份有限公司珠海屏东支行',
        value: '102585002649|4404|广东省|珠海市'
      },
      {
        label: '中国工商银行股份有限公司珠海临港支行',
        value: '102585002657|4404|广东省|珠海市'
      },
      {
        label: '中国工商银行股份有限公司珠海旺角支行',
        value: '102585002673|4404|广东省|珠海市'
      },
      {
        label: '中国工商银行股份有限公司珠海港湾支行',
        value: '102585002704|4404|广东省|珠海市'
      },
      {
        label: '中国工商银行股份有限公司珠海湖湾支行',
        value: '102585002712|4404|广东省|珠海市'
      },
      {
        label: '中国工商银行股份有限公司珠海迎宾支行',
        value: '102585002729|4404|广东省|珠海市'
      },
      {
        label: '中国工商银行股份有限公司珠海人民西支行',
        value: '102585002737|4404|广东省|珠海市'
      },
      {
        label: '中国工商银行股份有限公司广东自贸试验区横琴分行',
        value: '102585002753|4404|广东省|珠海市'
      },
      {
        label: '中国工商银行股份有限公司珠海公园道支行',
        value: '102585002770|4404|广东省|珠海市'
      },
      {
        label: '中国工商银行股份有限公司珠海映月支行',
        value: '102585002796|4404|广东省|珠海市'
      },
      {
        label: '中国工商银行股份有限公司珠海新青支行',
        value: '102585002815|4404|广东省|珠海市'
      },
      {
        label: '中国工商银行股份有限公司汕头分行',
        value: '102586002001|4405|广东省|汕头市'
      },
      {
        label: '中国工商银行股份有限公司汕头龙湖支行',
        value: '102586002010|4405|广东省|汕头市'
      },
      {
        label: '中国工商银行股份有限公司汕头外马支行',
        value: '102586002028|4405|广东省|汕头市'
      },
      {
        label: '中国工商银行股份有限公司汕头安平支行',
        value: '102586002036|4405|广东省|汕头市'
      },
      {
        label: '中国工商银行股份有限公司汕头同平支行',
        value: '102586002044|4405|广东省|汕头市'
      },
      {
        label: '工行陕西省城固县钟楼支行',
        value: '102799360105|6107|陕西省|汉中市'
      },
      {
        label: '中国工商银行汉中城固县彤辉办事处',
        value: '102799360113|6107|陕西省|汉中市'
      },
      {
        label: '工行城固县陕飞支行',
        value: '102799360277|6107|陕西省|汉中市'
      },
      {
        label: '工行陕西省城固县西环二路支行',
        value: '102799360308|6107|陕西省|汉中市'
      },
      {
        label: '中国工商银行汉中洋县支行',
        value: '102799460139|6107|陕西省|汉中市'
      },
      {
        label: '工行汉中洋县四O五厂支行',
        value: '102799460155|6107|陕西省|汉中市'
      },
      {
        label: '中国工商银行汉中西乡县支行',
        value: '102799560127|6107|陕西省|汉中市'
      },
      {
        label: '中国工商银行汉中勉县支行',
        value: '102799660179|6107|陕西省|汉中市'
      },
      {
        label: '工行陕西省勉县定军大道支行',
        value: '102799660339|6107|陕西省|汉中市'
      },
      {
        label: '工行陕西省勉县江滨路支行',
        value: '102799665046|6107|陕西省|汉中市'
      },
      {
        label: '中国工商银行汉中宁强县支行',
        value: '102799760207|6107|陕西省|汉中市'
      },
      {
        label: '中国工商银行汉中略阳县支行',
        value: '102799860220|6107|陕西省|汉中市'
      },
      {
        label: '工行陕西省略阳县象山路支行',
        value: '102799865073|6107|陕西省|汉中市'
      },
      {
        label: '工行陕西省略阳县电厂支行',
        value: '102799865112|6107|陕西省|汉中市'
      },
      {
        label: '工行陕西省安康西大街支行',
        value: '102801000065|6109|陕西省|安康市'
      },
      {
        label: '工行陕西省安康兴安路支行',
        value: '102801000073|6109|陕西省|安康市'
      },
      {
        label: '工行陕西省安康巴山西路支行',
        value: '102801000081|6109|陕西省|安康市'
      },
      {
        label: '工行陕西省安康金州路支行',
        value: '102801000104|6109|陕西省|安康市'
      },
      {
        label: '工行陕西省安康城西支行',
        value: '102801000129|6109|陕西省|安康市'
      },
      {
        label: '工行陕西省安康南环路支行',
        value: '102801000137|6109|陕西省|安康市'
      },
      {
        label: '中国工商银行股份有限公司库尔勒百乐支行',
        value: '102888000128|6528|新疆维吾尔自治区|巴音郭楞蒙古自治州'
      },
      {
        label: '中国工商银行股份有限公司库尔勒市人民西路分理处',
        value: '102888000136|6528|新疆维吾尔自治区|巴音郭楞蒙古自治州'
      },
      {
        label: '中国工商银行股份有限公司库尔勒巴音东路支行',
        value: '102888000177|6528|新疆维吾尔自治区|巴音郭楞蒙古自治州'
      },
      {
        label: '中国工商银行股份有限公司库尔勒建国北路支行',
        value: '102888000208|6528|新疆维吾尔自治区|巴音郭楞蒙古自治州'
      },
      {
        label: '中国工商银行股份有限公司库尔勒香梨大道支行',
        value: '102888000216|6528|新疆维吾尔自治区|巴音郭楞蒙古自治州'
      },
      {
        label: '中国工商银行股份有限公司轮台支行',
        value: '102888200371|6528|新疆维吾尔自治区|巴音郭楞蒙古自治州'
      },
      {
        label: '中国工商银行股份有限公司焉耆回族自治县支行',
        value: '102888600270|6528|新疆维吾尔自治区|巴音郭楞蒙古自治州'
      },
      {
        label: '中国工商银行股份有限公司和静支行',
        value: '102888700328|6528|新疆维吾尔自治区|巴音郭楞蒙古自治州'
      },
      {
        label: '中国工商银行股份有限公司和硕支行',
        value: '102888800351|6528|新疆维吾尔自治区|巴音郭楞蒙古自治州'
      },
      {
        label: '中国工商银行股份有限公司武汉新洲支行',
        value: '102521000564|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行武汉市阳逻开发区支行',
        value: '102521000572|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行武汉市江南支行',
        value: '102521000597|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行武汉市丁字桥支行',
        value: '102521000601|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行武汉市王家墩支行',
        value: '102521000610|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行武汉市江北支行',
        value: '102521000652|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行武汉市黄鹤楼支行',
        value: '102521000669|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行武汉市中北支行',
        value: '102521000677|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行武汉市武铁支行',
        value: '102521000685|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行武汉市天安支行',
        value: '102521000693|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行股份有限公司宁波洪塘支行',
        value: '102332013124|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行股份有限公司汕头阳光支行',
        value: '102586003527|4405|广东省|汕头市'
      },
      {
        label: '中国工商银行股份有限公司汕头世贸花园支行',
        value: '102586004023|4405|广东省|汕头市'
      },
      {
        label: '中国工商银行股份有限公司汕头谷饶支行',
        value: '102586004120|4405|广东省|汕头市'
      },
      {
        label: '中国工商银行股份有限公司饶平支行',
        value: '102586202516|4451|广东省|潮州市'
      },
      {
        label: '中国工商银行股份有限公司饶平中山支行',
        value: '102586202532|4451|广东省|潮州市'
      },
      {
        label: '中国工商银行股份有限公司汕头南澳支行',
        value: '102586302502|4405|广东省|汕头市'
      },
      {
        label: '中国工商银行股份有限公司揭阳分行营业部',
        value: '102586500212|4405|广东省|汕头市'
      },
      {
        label: '中国工商银行股份有限公司揭阳榕城支行',
        value: '102586500237|4405|广东省|汕头市'
      },
      {
        label: '中国工商银行赣州市分行定南支行',
        value: '102429110301|3607|江西省|赣州市'
      },
      {
        label: '中国工商银行股份有限公司全南支行',
        value: '102429210319|3607|江西省|赣州市'
      },
      {
        label: '中国工商银行宁都支行',
        value: '102429310326|3607|江西省|赣州市'
      },
      {
        label: '中国工商银行于都县支行',
        value: '102429410337|3607|江西省|赣州市'
      },
      {
        label: '中国工商银行兴国县支行',
        value: '102429510347|3607|江西省|赣州市'
      },
      {
        label: '中国工商银行瑞金市支行',
        value: '102429610354|3607|江西省|赣州市'
      },
      {
        label: '中国工商银行股份有限公司瑞金红都大道支行',
        value: '102429610776|3607|江西省|赣州市'
      },
      {
        label: '中国工商银行会昌县支行',
        value: '102429710365|3607|江西省|赣州市'
      },
      {
        label: '中国工商银行赣州市分行寻乌支行',
        value: '102429810376|3607|江西省|赣州市'
      },
      {
        label: '中国工商银行石城县支行',
        value: '102429910389|3607|江西省|赣州市'
      },
      {
        label: '中国工商银行股份有限公司常德白马湖支行',
        value: '102558000419|4307|湖南省|常德市'
      },
      {
        label: '中国工商银行股份有限公司常德芙蓉支行',
        value: '102558000460|4307|湖南省|常德市'
      },
      {
        label: '中国工商银行股份有限公司常德分行浦沅分理处',
        value: '102558000486|4307|湖南省|常德市'
      },
      {
        label: '中国工商银行股份有限公司常德青阳阁支行',
        value: '102558000494|4307|湖南省|常德市'
      },
      {
        label: '中国工商银行股份有限公司常德火车站支行',
        value: '102558000517|4307|湖南省|常德市'
      },
      {
        label: '中国工商银行股份有限公司常德阳明支行',
        value: '102558000736|4307|湖南省|常德市'
      },
      {
        label: '中国工商银行股份有限公司常德沅南支行',
        value: '102558000752|4307|湖南省|常德市'
      },
      {
        label: '中国工商银行股份有限公司常德武陵北路支行',
        value: '102558001376|4307|湖南省|常德市'
      },
      {
        label: '中国工商银行股份有限公司常德长庚路支行',
        value: '102558001384|4307|湖南省|常德市'
      },
      {
        label: '中国工商银行股份有限公司常德城东支行',
        value: '102558007033|4307|湖南省|常德市'
      },
      {
        label: '中国工商银行股份有限公司常德兴街口支行',
        value: '102558007041|4307|湖南省|常德市'
      },
      {
        label: '中国工商银行股份有限公司常德三岔路支行',
        value: '102558007076|4307|湖南省|常德市'
      },
      {
        label: '中国工商银行股份有限公司常德三闾桥支行',
        value: '102558007084|4307|湖南省|常德市'
      },
      {
        label: '中国工商银行股份有限公司常德下南门支行',
        value: '102558007293|4307|湖南省|常德市'
      },
      {
        label: '中国工商银行股份有限公司常德紫菱支行',
        value: '102558007488|4307|湖南省|常德市'
      },
      {
        label: '中国工商银行股份有限公司安乡支行潺陵分理处',
        value: '102558100969|4307|湖南省|常德市'
      },
      {
        label: '中国工商银行股份有限公司安乡支行',
        value: '102558107384|4307|湖南省|常德市'
      },
      {
        label: '中国工商银行股份有限公司汉寿支行',
        value: '102558202238|4307|湖南省|常德市'
      },
      {
        label: '中国工商银行股份有限公司澧县支行',
        value: '102558300106|4307|湖南省|常德市'
      },
      {
        label: '中国工商银行股份有限公司澧县支行兰江分理处',
        value: '102558301002|4307|湖南省|常德市'
      },
      {
        label: '中国工商银行股份有限公司澧县支行城关分理处',
        value: '102558310021|4307|湖南省|常德市'
      },
      {
        label: '中国工商银行股份有限公司临澧支行城关分理处',
        value: '102558401096|4307|湖南省|常德市'
      },
      {
        label: '中国工商银行股份有限公司潮安彩塘支行',
        value: '102587202337|4452|广东省|揭阳市'
      },
      {
        label: '中国工商银行股份有限公司潮安浮洋支行',
        value: '102587202345|4452|广东省|揭阳市'
      },
      {
        label: '中国工商银行股份有限公司潮安金石支行',
        value: '102587202353|4452|广东省|揭阳市'
      },
      {
        label: '中国工商银行股份有限公司佛山顺德红旗支行',
        value: '102588000018|4406|广东省|佛山市'
      },
      {
        label: '中国工商银行股份有限公司佛山顺德东银支行',
        value: '102588001256|4406|广东省|佛山市'
      },
      {
        label: '中国工商银行股份有限公司佛山顺德支行',
        value: '102588001289|4406|广东省|佛山市'
      },
      {
        label: '中国工商银行股份有限公司佛山容桂支行',
        value: '102588001297|4406|广东省|佛山市'
      },
      {
        label: '中国工商银行股份有限公司佛山顺德德胜支行',
        value: '102588001336|4406|广东省|佛山市'
      },
      {
        label: '中国工商银行股份有限公司佛山顺德伦教支行',
        value: '102588001352|4406|广东省|佛山市'
      },
      {
        label: '中国工商银行股份有限公司佛山顺德勒流支行',
        value: '102588001369|4406|广东省|佛山市'
      },
      {
        label: '中国工商银行股份有限公司佛山乐从支行',
        value: '102588001385|4406|广东省|佛山市'
      },
      {
        label: '中国工商银行股份有限公司佛山北滘支行',
        value: '102588001393|4406|广东省|佛山市'
      },
      {
        label: '中国工商银行股份有限公司佛山顺德锦湖支行',
        value: '102588001408|4406|广东省|佛山市'
      },
      {
        label: '中国工商银行股份有限公司佛山杏坛支行',
        value: '102588001416|4406|广东省|佛山市'
      },
      {
        label: '中国工商银行股份有限公司佛山顺德陈村支行',
        value: '102588001424|4406|广东省|佛山市'
      },
      {
        label: '中国工商银行股份有限公司佛山龙江支行',
        value: '102588001432|4406|广东省|佛山市'
      },
      {
        label: '中国工商银行股份有限公司中山横栏支行',
        value: '102603002221|4420|广东省|中山市'
      },
      {
        label: '中国工商银行股份有限公司中山古镇海洲支行',
        value: '102603002248|4420|广东省|中山市'
      },
      {
        label: '中国工商银行股份有限公司中山新区支行',
        value: '102603002256|4420|广东省|中山市'
      },
      {
        label: '中国工商银行股份有限公司中山东凤支行',
        value: '102603002264|4420|广东省|中山市'
      },
      {
        label: '中国工商银行股份有限公司中山财兴支行',
        value: '102603002272|4420|广东省|中山市'
      },
      {
        label: '中国工商银行股份有限公司中山高新技术开发区支行',
        value: '102603002297|4420|广东省|中山市'
      },
      {
        label: '中国工商银行股份有限公司长沙先导区支行',
        value: '102551010059|4301|湖南省|长沙市'
      },
      {
        label: '中国工商银行股份有限公司福清渔市街支行',
        value: '102391053452|3501|福建省|福州市'
      },
      {
        label: '中国工商银行股份有限公司福清滨江支行',
        value: '102391053469|3501|福建省|福州市'
      },
      {
        label: '中国工商银行福清宏路支行',
        value: '102391053477|3501|福建省|福州市'
      },
      {
        label: '中国工商银行股份有限公司福清龙田支行',
        value: '102391053485|3501|福建省|福州市'
      },
      {
        label: '中国工商银行福清渔溪支行',
        value: '102391053493|3501|福建省|福州市'
      },
      {
        label: '中国工商银行股份有限公司福清海口支行',
        value: '102391053508|3501|福建省|福州市'
      },
      {
        label: '中国工商银行福清高山支行',
        value: '102391053516|3501|福建省|福州市'
      },
      {
        label: '中国工商银行福清融城支行',
        value: '102391053532|3501|福建省|福州市'
      },
      {
        label: '中国工商银行连江琯头支行',
        value: '102391053573|3501|福建省|福州市'
      },
      {
        label: '中国工商银行连江支行城关分理处',
        value: '102391053581|3501|福建省|福州市'
      },
      {
        label: '中国工商银行股份有限公司连江丹凤路支行',
        value: '102391053629|3501|福建省|福州市'
      },
      {
        label: '中国工商银行股份有限公司长乐美域支行',
        value: '102391053637|3501|福建省|福州市'
      },
      {
        label: '中国工商银行长乐航城支行',
        value: '102391053645|3501|福建省|福州市'
      },
      {
        label: '中国工商银行长乐河下街支行',
        value: '102391053653|3501|福建省|福州市'
      },
      {
        label: '中国工商银行股份有限公司长乐漳港支行',
        value: '102391053670|3501|福建省|福州市'
      },
      {
        label: '中国工商银行股份有限公司长乐航港支行',
        value: '102391053696|3501|福建省|福州市'
      },
      {
        label: '中国工商银行股份有限公司永泰青云山支行',
        value: '102391053707|3501|福建省|福州市'
      },
      {
        label: '中国工商银行股份有限公司福建平潭西航支行',
        value: '102391053731|3501|福建省|福州市'
      },
      {
        label: '中国工商银行股份有限公司福州茶会支行',
        value: '102391053758|3501|福建省|福州市'
      },
      {
        label: '中国工商银行福州八一七支行',
        value: '102391053766|3501|福建省|福州市'
      },
      {
        label: '中国工商银行股份有限公司长乐胪峰支行',
        value: '102391053799|3501|福建省|福州市'
      },
      {
        label: '中国工商银行股份有限公司福州则徐大道支行',
        value: '102391053803|3501|福建省|福州市'
      },
      {
        label: '中国工商银行股份有限公司平潭万宝支行',
        value: '102391053838|3501|福建省|福州市'
      },
      {
        label: '中国工商银行股份有限公司福清江镜支行',
        value: '102391053846|3501|福建省|福州市'
      },
      {
        label: '中国工商银行股份有限公司闽侯南通支行',
        value: '102391053854|3501|福建省|福州市'
      },
      {
        label: '中国工商银行股份有限公司福清高山侨乡支行',
        value: '102391053862|3501|福建省|福州市'
      },
      {
        label: '中国工商银行股份有限公司长乐滨海支行',
        value: '102391053879|3501|福建省|福州市'
      },
      {
        label: '中国工商银行股份有限公司连江广场支行',
        value: '102391053887|3501|福建省|福州市'
      },
      {
        label: '中国工商银行股份有限公司罗源凤城支行',
        value: '102391053895|3501|福建省|福州市'
      },
      {
        label: '中国工商银行股份有限公司福州东方名城支行',
        value: '102391053900|3501|福建省|福州市'
      },
      {
        label: '中国工商银行股份有限公司福清龙田龙达支行',
        value: '102391053918|3501|福建省|福州市'
      },
      {
        label: '中国工商银行股份有限公司福州琅岐支行',
        value: '102391053926|3501|福建省|福州市'
      },
      {
        label: '中国工商银行股份有限公司福清三山支行',
        value: '102391053934|3501|福建省|福州市'
      },
      {
        label: '中国工商银行股份有限公司连江金安支行',
        value: '102391053942|3501|福建省|福州市'
      },
      {
        label: '中国工商银行厦门分行会计业务处理中心',
        value: '102393000010|3502|福建省|厦门市'
      },
      {
        label: '中国工商银行厦门鹭江支行',
        value: '102393000028|3502|福建省|厦门市'
      },
      {
        label: '中国工商银行股份有限公司厦门思北支行',
        value: '102393000044|3502|福建省|厦门市'
      },
      {
        label: '中国工商银行股份有限公司厦门嘉华里支行',
        value: '102393000052|3502|福建省|厦门市'
      },
      {
        label: '中国工商银行股份有限公司厦门嘉禾支行',
        value: '102393000077|3502|福建省|厦门市'
      },
      {
        label: '中国工商银行厦门鼓浪屿支行',
        value: '102393000093|3502|福建省|厦门市'
      },
      {
        label: '中国工商银行厦门集美支行',
        value: '102393000108|3502|福建省|厦门市'
      },
      {
        label: '中国工商银行厦门坂头支行',
        value: '102393000116|3502|福建省|厦门市'
      },
      {
        label: '中国工商银行股份有限公司济南纬三路支行',
        value: '102451001021|3701|山东省|济南市'
      },
      {
        label: '中国工商银行股份有限公司济南市中支行千佛山分理处',
        value: '102451001304|3701|山东省|济南市'
      },
      {
        label: '中国工商银行股份有限公司济南历下支行省府分理处',
        value: '102451002303|3701|山东省|济南市'
      },
      {
        label: '中国工商银行股份有限公司济南千佛山支行',
        value: '102451002311|3701|山东省|济南市'
      },
      {
        label: '中国工商银行股份有限公司济南千佛山支行经十东路分理处',
        value: '102451002320|3701|山东省|济南市'
      },
      {
        label: '中国工商银行股份有限公司福建泉州晋江泉安路支行',
        value: '102397222346|3505|福建省|泉州市'
      },
      {
        label: '中国工商银行股份有限公司衢州龙游光明路支行',
        value: '102341422046|3308|浙江省|衢州市'
      },
      {
        label: '中国工商银行股份有限公司龙游支行荣昌分理处',
        value: '102341422054|3308|浙江省|衢州市'
      },
      {
        label: '中国工商银行股份有限公司龙游城北支行',
        value: '102341422062|3308|浙江省|衢州市'
      },
      {
        label: '中国工商银行股份有限公司衢州江山支行',
        value: '102341523000|3308|浙江省|衢州市'
      },
      {
        label: '中国工商银行股份有限公司江山南市街支行',
        value: '102341523018|3308|浙江省|衢州市'
      },
      {
        label: '中国工商银行股份有限公司江山鹿溪中路支行',
        value: '102341523026|3308|浙江省|衢州市'
      },
      {
        label: '中国工商银行股份有限公司衢州江山解放路支行',
        value: '102341523034|3308|浙江省|衢州市'
      },
      {
        label: '中国工商银行股份有限公司江山南门支行',
        value: '102341523042|3308|浙江省|衢州市'
      },
      {
        label: '中国工商银行股份有限公司衢州江山贺村支行',
        value: '102341523067|3308|浙江省|衢州市'
      },
      {
        label: '中国工商银行舟山自贸试验区支行',
        value: '102342002007|3309|浙江省|舟山市'
      },
      {
        label: '中国工商银行舟山市分行营业部',
        value: '102342002015|3309|浙江省|舟山市'
      },
      {
        label: '中国工商银行舟山市定海区支行',
        value: '102342002023|3309|浙江省|舟山市'
      },
      {
        label: '中国工商银行舟山市解放路支行',
        value: '102342002031|3309|浙江省|舟山市'
      },
      {
        label: '中国工商银行舟山市分行核算中心',
        value: '102342002058|3309|浙江省|舟山市'
      },
      {
        label: '中国工商银行股份有限公司舟山城东支行',
        value: '102342002074|3309|浙江省|舟山市'
      },
      {
        label: '中国工商银行股份有限公司海口琼泰支行',
        value: '102641001564|4601|海南省|海口市'
      },
      {
        label: '中国工商银行股份有限公司衡阳雁峰支行',
        value: '102554002257|4304|湖南省|衡阳市'
      },
      {
        label: '中国工商银行股份有限公司衡阳城北支行',
        value: '102554002304|4304|湖南省|衡阳市'
      },
      {
        label: '中国工商银行股份有限公司衡阳合江套支行',
        value: '102554002312|4304|湖南省|衡阳市'
      },
      {
        label: '中国工商银行股份有限公司衡阳瓦子坪支行',
        value: '102554002329|4304|湖南省|衡阳市'
      },
      {
        label: '中国工商银行股份有限公司衡阳潇湘支行',
        value: '102554002337|4304|湖南省|衡阳市'
      },
      {
        label: '中国工商银行股份有限公司衡阳附一支行',
        value: '102554002353|4304|湖南省|衡阳市'
      },
      {
        label: '中国工商银行股份有限公司衡阳南华大学北院支行',
        value: '102554002361|4304|湖南省|衡阳市'
      },
      {
        label: '中国工商银行股份有限公司衡阳立新支行',
        value: '102554002388|4304|湖南省|衡阳市'
      },
      {
        label: '中国工商银行股份有限公司衡阳江东支行',
        value: '102554002407|4304|湖南省|衡阳市'
      },
      {
        label: '中国工商银行股份有限公司益阳分行',
        value: '102561003009|4309|湖南省|益阳市'
      },
      {
        label: '中国工商银行股份有限公司益阳朝阳开发区支行',
        value: '102561003105|4309|湖南省|益阳市'
      },
      {
        label: '中国工商银行股份有限公司益阳银城支行',
        value: '102561003201|4309|湖南省|益阳市'
      },
      {
        label: '中国工商银行益阳市银城支行银宾分理处',
        value: '102561003308|4309|湖南省|益阳市'
      },
      {
        label: '中国工商银行股份有限公司益阳东新支行',
        value: '102561010011|4309|湖南省|益阳市'
      },
      {
        label: '中国工商银行股份有限公司益阳桥南支行',
        value: '102561010062|4309|湖南省|益阳市'
      },
      {
        label: '中国工商银行股份有限公司益阳五一路支行',
        value: '102561010079|4309|湖南省|益阳市'
      },
      {
        label: '中国工商银行股份有限公司益阳高新支行',
        value: '102561010142|4309|湖南省|益阳市'
      },
      {
        label: '中国工商银行股份有限公司益阳城东支行',
        value: '102561010191|4309|湖南省|益阳市'
      },
      {
        label: '中国工商银行股份有限公司沅江支行',
        value: '102561202503|4309|湖南省|益阳市'
      },
      {
        label: '中国工商银行股份有限公司沅江庆云山支行',
        value: '102561210097|4309|湖南省|益阳市'
      },
      {
        label: '中国工商银行股份有限公司益阳分行南洲分理处',
        value: '102561400875|4309|湖南省|益阳市'
      },
      {
        label: '中国工商银行股份有限公司南县支行',
        value: '102561402709|4309|湖南省|益阳市'
      },
      {
        label: '中国工商银行股份有限公司益阳大通湖支行',
        value: '102561410311|4309|湖南省|益阳市'
      },
      {
        label: '中国工商银行股份有限公司桃江支行',
        value: '102561502603|4309|湖南省|益阳市'
      },
      {
        label: '中国工商银行股份有限公司桃江灰山港支行',
        value: '102561510269|4309|湖南省|益阳市'
      },
      {
        label: '中国工商银行股份有限公司佛山分行营业部',
        value: '102588002101|4406|广东省|佛山市'
      },
      {
        label: '中国工商银行股份有限公司佛山人民支行',
        value: '102588002177|4406|广东省|佛山市'
      },
      {
        label: '中国工商银行股份有限公司佛山中发支行',
        value: '102588002193|4406|广东省|佛山市'
      },
      {
        label: '中国工商银行股份有限公司佛山南海市东路支行',
        value: '102588002216|4406|广东省|佛山市'
      },
      {
        label: '中国工商银行股份有限公司佛山桂城支行',
        value: '102588002224|4406|广东省|佛山市'
      },
      {
        label: '中国工商银行股份有限公司佛山南海樵园支行',
        value: '102588002249|4406|广东省|佛山市'
      },
      {
        label: '中国工商银行股份有限公司佛山南海九江支行',
        value: '102588002257|4406|广东省|佛山市'
      },
      {
        label: '中国工商银行股份有限公司佛山大沥支行',
        value: '102588002265|4406|广东省|佛山市'
      },
      {
        label: '中国工商银行股份有限公司佛山南海黄岐支行',
        value: '102588002273|4406|广东省|佛山市'
      },
      {
        label: '中国工商银行股份有限公司佛山盐步支行',
        value: '102588002290|4406|广东省|佛山市'
      },
      {
        label: '中国工商银行股份有限公司佛山南海官窑支行',
        value: '102588002329|4406|广东省|佛山市'
      },
      {
        label: '中国工商银行股份有限公司佛山平洲支行',
        value: '102588002337|4406|广东省|佛山市'
      },
      {
        label: '中国工商银行股份有限公司佛山南海里水支行',
        value: '102588002345|4406|广东省|佛山市'
      },
      {
        label: '中国工商银行股份有限公司佛山南庄支行',
        value: '102588002361|4406|广东省|佛山市'
      },
      {
        label: '中国工商银行南阳分行内乡县支行',
        value: '102513802550|4113|河南省|南阳市'
      },
      {
        label: '中国工商银行南阳分行淅川县支行',
        value: '102513902602|4113|河南省|南阳市'
      },
      {
        label: '中国工商银行股份有限公司社旗支行',
        value: '102514102253|4113|河南省|南阳市'
      },
      {
        label: '中国工商银行南阳分行唐河县支行',
        value: '102514202352|4113|河南省|南阳市'
      },
      {
        label: '中国工商银行股份有限公司唐河滨河支行',
        value: '102514212354|4113|河南省|南阳市'
      },
      {
        label: '中国工商银行南阳分行新野县支行',
        value: '102514302452|4113|河南省|南阳市'
      },
      {
        label: '中国工商银行南阳分行桐柏县支行',
        value: '102514402756|4113|河南省|南阳市'
      },
      {
        label: '中国工商银行信阳分行营业部',
        value: '102515002109|4115|河南省|信阳市'
      },
      {
        label: '中国工商银行股份有限公司信阳申城支行',
        value: '102515002117|4115|河南省|信阳市'
      },
      {
        label: '中国工商银行股份有限公司信阳北京路支行',
        value: '102515002141|4115|河南省|信阳市'
      },
      {
        label: '中国工商银行信阳分行老城支行',
        value: '102515002176|4115|河南省|信阳市'
      },
      {
        label: '中国工商银行股份有限公司信阳平中大街支行',
        value: '102515002205|4115|河南省|信阳市'
      },
      {
        label: '中国工商银行信阳分行明港支行',
        value: '102515002256|4115|河南省|信阳市'
      },
      {
        label: '中国工商银行股份有限公司信阳体彩广场支行',
        value: '102515002301|4115|河南省|信阳市'
      },
      {
        label: '中国工商银行信阳分行会计业务处理中心',
        value: '102515002682|4115|河南省|信阳市'
      },
      {
        label: '中国工商银行股份有限公司信阳中山路支行',
        value: '102515012104|4115|河南省|信阳市'
      },
      {
        label: '中国工商银行股份有限公司信阳工区路支行',
        value: '102515012112|4115|河南省|信阳市'
      },
      {
        label: '中国工商银行股份有限公司信阳明河桥支行',
        value: '102515012258|4115|河南省|信阳市'
      },
      {
        label: '中国工商银行股份有限公司信阳羊山新区支行',
        value: '102515022102|4115|河南省|信阳市'
      },
      {
        label: '中国工商银行股份有限公司信阳湖东支行',
        value: '102515022143|4115|河南省|信阳市'
      },
      {
        label: '中国工商银行股份有限公司信阳航空路支行',
        value: '102515032113|4115|河南省|信阳市'
      },
      {
        label: '中国工商银行股份有限公司信阳浉河支行',
        value: '102515032172|4115|河南省|信阳市'
      },
      {
        label: '中国工商银行股份有限公司信阳平西支行',
        value: '102515032201|4115|河南省|信阳市'
      },
      {
        label: '中国工商银行股份有限公司武汉常码头支行',
        value: '102521003534|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行股份有限公司宁波国家高新区支行',
        value: '102332014004|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行股份有限公司浙江省宁波梅墟支行',
        value: '102332014012|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行股份有限公司宁波东部新城支行',
        value: '102332014029|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行股份有限公司宁波鄞州支行',
        value: '102332015007|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行宁波邱隘支行',
        value: '102332015015|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行宁波东钱湖支行',
        value: '102332015023|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行宁波姜山支行',
        value: '102332015031|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行股份有限公司宁波石碶支行',
        value: '102332015058|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行宁波下应支行',
        value: '102332015066|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行宁波甬港支行',
        value: '102332015074|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行股份有限公司新城支行第三分理处',
        value: '102332015082|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行股份有限公司宁波宋诏桥支行',
        value: '102332015099|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行股份有限公司宁波鄞江支行',
        value: '102332015120|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行宁波东城支行',
        value: '102332015146|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行股份有限公司浙江省宁波万达支行',
        value: '102332015154|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行股份有限公司宁波潘火支行',
        value: '102332015162|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行宁波东方支行',
        value: '102332015179|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行宁波鄞中支行',
        value: '102332015187|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行股份有限公司宁波五乡支行',
        value: '102332015195|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行股份有限公司宁波明州支行',
        value: '102332015200|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行股份有限公司宁波贸城支行',
        value: '102332015226|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行股份有限公司宁波首南支行',
        value: '102332015234|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行股份有限公司宁波古林支行',
        value: '102332015242|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行股份有限公司宁波横溪支行',
        value: '102332015259|3302|浙江省|宁波市'
      },
      {
        label: '中国工商银行商丘分行经济技术开发区支行',
        value: '102506002020|4114|河南省|商丘市'
      },
      {
        label: '中国工商银行股份有限公司商丘梁园支行',
        value: '102506002038|4114|河南省|商丘市'
      },
      {
        label: '中国工商银行股份有限公司商丘分行',
        value: '102506002046|4114|河南省|商丘市'
      },
      {
        label: '中国工商银行股份有限公司商丘京港支行',
        value: '102506002054|4114|河南省|商丘市'
      },
      {
        label: '中国工商银行股份有限公司湖口支行',
        value: '102424907098|3604|江西省|九江市'
      },
      {
        label: '中国工商银行股份有限公司彭泽支行',
        value: '102425107106|3604|江西省|九江市'
      },
      {
        label: '中国工商银行新余市分行',
        value: '102426005002|3605|江西省|新余市'
      },
      {
        label: '中国工商银行新余市分行城南支行',
        value: '102426005019|3605|江西省|新余市'
      },
      {
        label: '中国工商银行新余市分行新城支行',
        value: '102426005027|3605|江西省|新余市'
      },
      {
        label: '中国工商银行新余市分行新钢支行',
        value: '102426005035|3605|江西省|新余市'
      },
      {
        label: '中国工商银行新余市分行营业厅',
        value: '102426005043|3605|江西省|新余市'
      },
      {
        label: '中国工商银行新余市分行新亚新支行',
        value: '102426005051|3605|江西省|新余市'
      },
      {
        label: '中国工商银行股份有限公司新余高新支行',
        value: '102426005060|3605|江西省|新余市'
      },
      {
        label: '中国工商银行新余市分行良山支行',
        value: '102426005078|3605|江西省|新余市'
      },
      {
        label: '中国工商银股份有限公司新余城北支行',
        value: '102426010061|3605|江西省|新余市'
      },
      {
        label: '中国工商银行股份有限公司新余车站支行',
        value: '102426010088|3605|江西省|新余市'
      },
      {
        label: '中国工商银行分宜县支行',
        value: '102426105080|3605|江西省|新余市'
      },
      {
        label: '中国工商银行鹰潭分行业务处理中心',
        value: '102427006001|3606|江西省|鹰潭市'
      },
      {
        label: '中国工商银行股份有限公司鹰潭四海支行',
        value: '102427006010|3606|江西省|鹰潭市'
      },
      {
        label: '中国工商银行股份有限公司鹰潭月湖支行',
        value: '102427006028|3606|江西省|鹰潭市'
      },
      {
        label: '中国工商银行股份有限公司鹰潭东湖支行',
        value: '102427010049|3606|江西省|鹰潭市'
      },
      {
        label: '中国工商银行股份有限公司鹰潭站江支行',
        value: '102427010081|3606|江西省|鹰潭市'
      },
      {
        label: '中国工商银行股份有限公司鹰潭梅园支行',
        value: '102427010129|3606|江西省|鹰潭市'
      },
      {
        label: '中国工商银行股份有限公司鹰潭分行园区分理处',
        value: '102427010137|3606|江西省|鹰潭市'
      },
      {
        label: '中国工商银行股份有限公司贵溪支行',
        value: '102427106034|3606|江西省|鹰潭市'
      },
      {
        label: '中国工商银行股份有限公司鹰潭江铜支行',
        value: '102427106042|3606|江西省|鹰潭市'
      },
      {
        label: '中国工商银行股份有限公司余江支行',
        value: '102427206054|3606|江西省|鹰潭市'
      },
      {
        label: '中国工商银行股份有限公司余江锦江支行',
        value: '102427210286|3606|江西省|鹰潭市'
      },
      {
        label: '中国工商银行赣州市分行核算中心',
        value: '102428010000|3607|江西省|赣州市'
      },
      {
        label: '中国工商银行赣州市章江支行',
        value: '102428010018|3607|江西省|赣州市'
      },
      {
        label: '中国工商银行赣州市北京路支行',
        value: '102428010026|3607|江西省|赣州市'
      },
      {
        label: '中国工商银行赣州市南门支行',
        value: '102428010034|3607|江西省|赣州市'
      },
      {
        label: '中国工商银行赣州市文明大道支行',
        value: '102428010059|3607|江西省|赣州市'
      },
      {
        label: '中国工商银行荣成市支行崖头分理处',
        value: '102465310235|3710|山东省|威海市'
      },
      {
        label: '中国工商银行股份有限公司荣成城南支行',
        value: '102465310243|3710|山东省|威海市'
      },
      {
        label: '中国工商银行荣成石岛支行石岛储蓄所',
        value: '102465310544|3710|山东省|威海市'
      },
      {
        label: '中国工商银行滨州市分行',
        value: '102466000011|3716|山东省|滨州市'
      },
      {
        label: '中国工商银行滨州市滨城区支行',
        value: '102466000100|3716|山东省|滨州市'
      },
      {
        label: '中国工商银行股份有限公司滨州新城支行时代广场分理处',
        value: '102466000118|3716|山东省|滨州市'
      },
      {
        label: '中国工商银行滨州市滨城区支行滨北分理处',
        value: '102466000126|3716|山东省|滨州市'
      },
      {
        label: '中国工商银行股份有限公司成都海峡科技园支行',
        value: '102651025252|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司长沙书院路支行',
        value: '102551010075|4301|湖南省|长沙市'
      },
      {
        label: '中国工商银行股份有限公司长沙跃进路支行',
        value: '102551010139|4301|湖南省|长沙市'
      },
      {
        label: '中国工商银行股份有限公司长沙四方坪支行',
        value: '102551010163|4301|湖南省|长沙市'
      },
      {
        label: '中国工商银行股份有限公司长沙晓园支行',
        value: '102551010180|4301|湖南省|长沙市'
      },
      {
        label: '中国工商银行股份有限公司长沙西街花园支行',
        value: '102551010227|4301|湖南省|长沙市'
      },
      {
        label: '中国工商银行长沙市金鹏支行咸嘉新村分理处',
        value: '102551010235|4301|湖南省|长沙市'
      },
      {
        label: '中国工商银行股份有限公司长沙五一路支行荷花路分理处',
        value: '102551020136|4301|湖南省|长沙市'
      },
      {
        label: '中国工商银行股份有限公司长沙瑞丰支行',
        value: '102551020431|4301|湖南省|长沙市'
      },
      {
        label: '中国工商银行股份有限公司长沙五一路支行五一广场分理处',
        value: '102551020546|4301|湖南省|长沙市'
      },
      {
        label: '中国工商银行股份有限公司长沙树木岭支行新星分理处',
        value: '102551020618|4301|湖南省|长沙市'
      },
      {
        label: '中国工商银行股份有限公司长沙科大佳园支行',
        value: '102551021170|4301|湖南省|长沙市'
      },
      {
        label: '中国工商银行股份有限公司长沙芙蓉苑支行',
        value: '102551021188|4301|湖南省|长沙市'
      },
      {
        label: '中国工商银行湖南省分行(不对外办理业务)',
        value: '102551099983|4301|湖南省|长沙市'
      },
      {
        label: '中国工商银行股份有限公司株洲春光支行',
        value: '102552000063|4302|湖南省|株洲市'
      },
      {
        label: '中国工商银行股份有限公司株洲文化宫支行',
        value: '102552000071|4302|湖南省|株洲市'
      },
      {
        label: '中国工商银行股份有限公司株洲何家坳支行',
        value: '102552000080|4302|湖南省|株洲市'
      },
      {
        label: '中国工商银行株洲湘江支行',
        value: '102552000102|4302|湖南省|株洲市'
      },
      {
        label: '中国工商银行股份有限公司株洲桥东支行',
        value: '102552000143|4302|湖南省|株洲市'
      },
      {
        label: '中国工商银行股份有限公司株洲栗雨支行',
        value: '102552000151|4302|湖南省|株洲市'
      },
      {
        label: '中国工商银行股份有限公司株洲文化路支行',
        value: '102552000160|4302|湖南省|株洲市'
      },
      {
        label: '中国工商银行股份有限公司深圳华城支行',
        value: '102584002073|4403|广东省|深圳市'
      },
      {
        label: '中国工商银行股份有限公司深圳八卦岭支行',
        value: '102584002081|4403|广东省|深圳市'
      },
      {
        label: '中国工商银行股份有限公司深圳盐田支行',
        value: '102584002090|4403|广东省|深圳市'
      },
      {
        label: '中国工商银行股份有限公司深圳深东支行',
        value: '102584002104|4403|广东省|深圳市'
      },
      {
        label: '中国工商银行云南红河分行石屏县支行营业部',
        value: '102743607103|5325|云南省|红河哈尼族彝族自治州'
      },
      {
        label: '中国工商银行云南红河分行弥勒支行',
        value: '102743707110|5325|云南省|红河哈尼族彝族自治州'
      },
      {
        label: '中国工商银行云南红河分行弥勒吉山北路支行',
        value: '102743707128|5325|云南省|红河哈尼族彝族自治州'
      },
      {
        label: '中国工商银行云南红河分行弥勒庆来路支行',
        value: '102743707136|5325|云南省|红河哈尼族彝族自治州'
      },
      {
        label: '中国工商银行云南红河分行泸西城区支行',
        value: '102743800752|5325|云南省|红河哈尼族彝族自治州'
      },
      {
        label: '中国工商银行云南红河分行泸西九华路支行',
        value: '102743801243|5325|云南省|红河哈尼族彝族自治州'
      },
      {
        label: '中国工商银行云南红河分行泸西县支行',
        value: '102743807121|5325|云南省|红河哈尼族彝族自治州'
      },
      {
        label: '中国工商银行云南红河分行河口支行',
        value: '102744407337|5325|云南省|红河哈尼族彝族自治州'
      },
      {
        label: '中国工商银行股份有限公司武威西凉支行',
        value: '102828000227|6206|甘肃省|武威市'
      },
      {
        label: '中国工商银行股份有限公司武威富民路支行',
        value: '102828000278|6206|甘肃省|武威市'
      },
      {
        label: '中国工商银行股份有限公司民勤支行',
        value: '102828200035|6206|甘肃省|武威市'
      },
      {
        label: '中国工商银行股份有限公司民勤东关支行',
        value: '102828200336|6206|甘肃省|武威市'
      },
      {
        label: '中国工商银行股份有限公司古浪支行',
        value: '102828305267|6206|甘肃省|武威市'
      },
      {
        label: '中国工商银行股份有限公司天祝支行',
        value: '102828400045|6206|甘肃省|武威市'
      },
      {
        label: '中国工商银行股份有限公司定西市分行业务处理中心',
        value: '102829000010|6211|甘肃省|定西市'
      },
      {
        label: '中国工商银行定西市中华路支行',
        value: '102829000028|6211|甘肃省|定西市'
      },
      {
        label: '中国工商银行股份有限公司定西分行营业室',
        value: '102829000077|6211|甘肃省|定西市'
      },
      {
        label: '中国工商银行定西市火车站支行',
        value: '102829000085|6211|甘肃省|定西市'
      },
      {
        label: '中国工商银行股份有限公司定西大同巷支行',
        value: '102829000093|6211|甘肃省|定西市'
      },
      {
        label: '中国工商银行云南德宏分行芒市勐焕支行',
        value: '102754002366|5331|云南省|德宏傣族景颇族自治州'
      },
      {
        label: '中国工商银行云南德宏分行芒市牡丹支行',
        value: '102754002374|5331|云南省|德宏傣族景颇族自治州'
      },
      {
        label: '中国工商银行云南德宏分行会计业务处理中心',
        value: '102754011012|5331|云南省|德宏傣族景颇族自治州'
      },
      {
        label: '中国工商银行云南德宏分行芒市支行',
        value: '102754011029|5331|云南省|德宏傣族景颇族自治州'
      },
      {
        label: '中国工商银行云南德宏分行瑞丽勐卯支行',
        value: '102754600150|5331|云南省|德宏傣族景颇族自治州'
      },
      {
        label: '中国工商银行云南德宏分行瑞丽姐岗支行',
        value: '102754600176|5331|云南省|德宏傣族景颇族自治州'
      },
      {
        label: '中国工商银行云南德宏分行瑞丽姐告支行',
        value: '102754600192|5331|云南省|德宏傣族景颇族自治州'
      },
      {
        label: '中国工商银行云南德宏分行瑞丽市支行',
        value: '102754611046|5331|云南省|德宏傣族景颇族自治州'
      },
      {
        label: '中国工商银行云南丽江分行会计业务处理中心',
        value: '102755012017|5307|云南省|丽江市'
      },
      {
        label: '中国工商银行云南丽江分行丽江支行',
        value: '102755012025|5307|云南省|丽江市'
      },
      {
        label: '中国工商银行云南丽江分行南郊支行',
        value: '102755012033|5307|云南省|丽江市'
      },
      {
        label: '中国工商银行云南丽江分行雪山中路支行',
        value: '102755012041|5307|云南省|丽江市'
      },
      {
        label: '中国工商银行云南丽江分行香格里支行',
        value: '102755012050|5307|云南省|丽江市'
      },
      {
        label: '中国工商银行云南丽江分行牡丹支行',
        value: '102755012084|5307|云南省|丽江市'
      },
      {
        label: '中国工商银行云南丽江分行四方街支行',
        value: '102755012092|5307|云南省|丽江市'
      },
      {
        label: '中国工商银行云南丽江分行银泉支行',
        value: '102755012105|5307|云南省|丽江市'
      },
      {
        label: '中国工商银行云南丽江分行福慧支行',
        value: '102755012148|5307|云南省|丽江市'
      },
      {
        label: '中国工商银行云南丽江分行象山支行',
        value: '102755012156|5307|云南省|丽江市'
      },
      {
        label: '中国工商银行股份有限公司海口金贸西路支行',
        value: '102641001572|4601|海南省|海口市'
      },
      {
        label: '中国工商银行股份有限公司海口国贸支行',
        value: '102641002102|4601|海南省|海口市'
      },
      {
        label: '中国工商银行股份有限公司海口海口湾支行',
        value: '102641003017|4601|海南省|海口市'
      },
      {
        label: '中国工商银行股份有限公司海南省分行',
        value: '102641005004|4601|海南省|海口市'
      },
      {
        label: '中国工商银行股份有限公司文昌铺前支行',
        value: '102641010214|4601|海南省|海口市'
      },
      {
        label: '中国工商银行股份有限公司海口皇冠支行',
        value: '102641010572|4601|海南省|海口市'
      },
      {
        label: '中国工商银行股份有限公司三亚分行',
        value: '102642000317|4602|海南省|三亚市'
      },
      {
        label: '中国工商银行股份有限公司三亚金鸡岭支行',
        value: '102642000325|4602|海南省|三亚市'
      },
      {
        label: '中国工商银行股份有限公司三亚政务中心支行',
        value: '102642000333|4602|海南省|三亚市'
      },
      {
        label: '中国工商银行武汉武汉广场支行',
        value: '102521000853|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行武汉白玉山支行',
        value: '102521000907|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行武汉市武青三干道分理处',
        value: '102521000915|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行武汉汉纸支行',
        value: '102521000923|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行武汉石洞支行',
        value: '102521000958|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行武汉百秀支行',
        value: '102521000974|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行武汉新洲大道支行',
        value: '102521000999|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行武汉江岸开发区支行',
        value: '102521001022|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行股份有限公司武汉崇仁支行',
        value: '102521001039|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行股份有限公司武汉复兴路支行',
        value: '102521001063|4201|湖北省|武汉市'
      },
      {
        label: '工行湖北省分行武汉东一路支行',
        value: '102521001098|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行股份有限公司武汉冶金街支行',
        value: '102521001102|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行武汉钢都花园支行',
        value: '102521001119|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行股份有限公司武汉喻家山支行',
        value: '102521001127|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行武汉民族大道支行',
        value: '102521001143|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行股份有限公司武汉杨家湾支行',
        value: '102521001151|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行股份有限公司武汉理工大支行',
        value: '102521001160|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行武汉雄楚支行',
        value: '102521001178|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行武汉三支沟支行',
        value: '102521001209|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行武汉庙山开发区支行',
        value: '102521001217|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行武汉中兴支行',
        value: '102521001225|4201|湖北省|武汉市'
      },
      {
        label: '工商银行股份有限公司武汉珞南支行',
        value: '102521001241|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行股份有限公司武汉解放公园支行',
        value: '102521002076|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行股份有限公司武汉广源支行',
        value: '102521002084|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行股份有限公司武汉香港路支行',
        value: '102521002092|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行股份有限公司武汉融科天城支行',
        value: '102521002113|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行股份有限公司长沙伍家岭支行伍家岭分理处',
        value: '102551001728|4301|湖南省|长沙市'
      },
      {
        label: '中国工商银行股份有限公司长沙新河支行',
        value: '102551001736|4301|湖南省|长沙市'
      },
      {
        label: '中国工商银行长沙市伍家岭支行银通分理处',
        value: '102551001744|4301|湖南省|长沙市'
      },
      {
        label: '中国工商银行股份有限公司长沙银杉支行',
        value: '102551001849|4301|湖南省|长沙市'
      },
      {
        label: '中国工商银行股份有限公司隆昌南街支行',
        value: '102663806047|5110|四川省|内江市'
      },
      {
        label: '中国工商银行股份有限公司隆昌新华支行',
        value: '102663806063|5110|四川省|内江市'
      },
      {
        label: '中国工商银行股份有限公司隆昌支行',
        value: '102663849119|5110|四川省|内江市'
      },
      {
        label: '中国工商银行股份有限公司乐山分行柏杨路支行',
        value: '102665000543|5111|四川省|乐山市'
      },
      {
        label: '中国工商银行股份有限公司乐山分行凤凰路分理处',
        value: '102665000560|5111|四川省|乐山市'
      },
      {
        label: '中国工商银行股份有限公司乐山翡翠支行',
        value: '102665001023|5111|四川省|乐山市'
      },
      {
        label: '中国工商银行股份有限公司乐山天星路支行',
        value: '102665001058|5111|四川省|乐山市'
      },
      {
        label: '中国工商银行乐山市分行业务处理中心',
        value: '102665038112|5111|四川省|乐山市'
      },
      {
        label: '中国工商银行股份有限公司乐山春华路支行',
        value: '102665038215|5111|四川省|乐山市'
      },
      {
        label: '中国工商银行股份有限公司乐山土桥街支行',
        value: '102665038223|5111|四川省|乐山市'
      },
      {
        label: '中国工商银行股份有限公司乐山新村支行',
        value: '102665038231|5111|四川省|乐山市'
      },
      {
        label: '中国工商银行股份有限公司乐山嘉州支行',
        value: '102665038258|5111|四川省|乐山市'
      },
      {
        label: '中国工商银行股份有限公司乐山分行青江分理处',
        value: '102665038274|5111|四川省|乐山市'
      },
      {
        label: '中国工商银行云南玉溪分行玉兴支行',
        value: '102741003209|5304|云南省|玉溪市'
      },
      {
        label: '中国工商银行云南玉溪分行州城支行',
        value: '102741010014|5304|云南省|玉溪市'
      },
      {
        label: '中国工商银行云南玉溪分行世纪支行',
        value: '102741010047|5304|云南省|玉溪市'
      },
      {
        label: '中国工商银行云南玉溪分行北苑支行',
        value: '102741010080|5304|云南省|玉溪市'
      },
      {
        label: '中国工商银行云南玉溪分行会计业务处理中心',
        value: '102741017013|5304|云南省|玉溪市'
      },
      {
        label: '中国工商银行云南玉溪分行新兴支行',
        value: '102741017021|5304|云南省|玉溪市'
      },
      {
        label: '中国工商银行股份有限公司克孜勒苏柯尔克孜自治州分行',
        value: '102893000016|6530|新疆维吾尔自治区|克孜勒苏柯尔克孜自治州'
      },
      {
        label: '中国工商银行股份有限公司喀什分行业务处理中心',
        value: '102894000018|6531|新疆维吾尔自治区|喀什地区'
      },
      {
        label: '中国工商银行股份有限公司成都郫县支行唐昌四川电器分理处',
        value: '102651094517|5101|四川省|成都市'
      },
      {
        label: '中国工商银行云南楚雄分行南路支行',
        value: '102738010077|5323|云南省|楚雄彝族自治州'
      },
      {
        label: '中国工商银行云南楚雄分行会计业务处理中心',
        value: '102738016016|5323|云南省|楚雄彝族自治州'
      },
      {
        label: '中国工商银行云南楚雄分行府后街支行',
        value: '102738016024|5323|云南省|楚雄彝族自治州'
      },
      {
        label: '中国工商银行云南楚雄分行禄丰县支行西郊分理处',
        value: '102739100050|5323|云南省|楚雄彝族自治州'
      },
      {
        label: '中国工商银行云南楚雄分行禄丰县支行',
        value: '102739116095|5323|云南省|楚雄彝族自治州'
      },
      {
        label: '中国工商银行云南楚雄分行禄丰县广通支行',
        value: '102739116100|5323|云南省|楚雄彝族自治州'
      },
      {
        label: '中国工商银行云南玉溪分行玉江支行',
        value: '102741002628|5304|云南省|玉溪市'
      },
      {
        label: '中国工商银行云南玉溪分行东风支行',
        value: '102741002636|5304|云南省|玉溪市'
      },
      {
        label: '中国工商银行云南玉溪分行珊瑚分理处',
        value: '102741002669|5304|云南省|玉溪市'
      },
      {
        label: '中国工商银行云南玉溪分行南北路分理处',
        value: '102741002907|5304|云南省|玉溪市'
      },
      {
        label: '中国工商银行东风场区分行业务处理中心',
        value: '102826000581|6209|甘肃省|酒泉市'
      },
      {
        label: '中国工商银行股份有限公司西峰开发区支行',
        value: '102834000233|6210|甘肃省|庆阳市'
      },
      {
        label: '中国工商银行股份有限公司西峰新区支行',
        value: '102834000348|6210|甘肃省|庆阳市'
      },
      {
        label: '中国工商银行股份有限公司西峰庆化支行',
        value: '102834007136|6210|甘肃省|庆阳市'
      },
      {
        label: '中国工商银行股份有限公司庆城支行',
        value: '102834200032|6210|甘肃省|庆阳市'
      },
      {
        label: '中国工商银行股份有限公司庆城北区支行',
        value: '102834200129|6210|甘肃省|庆阳市'
      },
      {
        label: '中国工商银行股份有限公司成都温泉大道支行',
        value: '102651025269|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都两河路支行',
        value: '102651025277|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司四川省成都新都支行',
        value: '102651025308|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都大丰支行',
        value: '102651025316|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都物流大道支行',
        value: '102651025324|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都花都大道支行',
        value: '102651025332|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司新都工业大道支行',
        value: '102651025349|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司郫县支行',
        value: '102651025404|5101|四川省|成都市'
      },
      {
        label: '中国工商银行成都高新西部园区支行营业室',
        value: '102651025412|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都高新综合保税区支行',
        value: '102651025429|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都黄忠大道支行',
        value: '102651025445|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都清江中路支行',
        value: '102651025453|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都鹃城支行',
        value: '102651025470|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司郫县安平西路支行',
        value: '102651025488|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司郫县望东路支行',
        value: '102651025496|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司四川省成都新津支行',
        value: '102651025507|5101|四川省|成都市'
      },
      {
        label: '中国工商银行成都市武侯祠支行',
        value: '102651025603|5101|四川省|成都市'
      },
      {
        label: '中国工商银行成都市红星中路支行',
        value: '102651025902|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都玉双路支行',
        value: '102651026307|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都桐梓林南路支行',
        value: '102651026403|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都芷泉支行武侯大道分理处',
        value: '102651026411|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都春熙支行高升桥分理处',
        value: '102651026420|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都峨影支行',
        value: '102651026500|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都同辉支行',
        value: '102651026518|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都一环路南三段支行',
        value: '102651026526|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司攀枝花烂泥田支行',
        value: '102656032326|5104|四川省|攀枝花市'
      },
      {
        label: '中国工商银行股份有限公司攀枝花五十四支行',
        value: '102656032334|5104|四川省|攀枝花市'
      },
      {
        label: '中国工商银行股份有限公司攀枝花华山支行',
        value: '102656032342|5104|四川省|攀枝花市'
      },
      {
        label: '中国工商银行股份有限公司攀枝花仁和支行',
        value: '102656032414|5104|四川省|攀枝花市'
      },
      {
        label: '中国工商银行股份有限公司攀枝花五十一支行',
        value: '102656032422|5104|四川省|攀枝花市'
      },
      {
        label: '中国工商银行股份有限公司攀枝花市仁和广场支行',
        value: '102656032439|5104|四川省|攀枝花市'
      },
      {
        label: '中国工商银行股份有限公司攀枝花河门口支行',
        value: '102656032519|5104|四川省|攀枝花市'
      },
      {
        label: '中国工商银行股份有限公司攀枝花清香坪支行',
        value: '102656032527|5104|四川省|攀枝花市'
      },
      {
        label: '中国工商银行股份有限公司攀枝花大水井支行',
        value: '102656032535|5104|四川省|攀枝花市'
      },
      {
        label: '中国工商银行股份有限公司攀枝花瓜子坪支行',
        value: '102656032617|5104|四川省|攀枝花市'
      },
      {
        label: '中国工商银行股份有限公司攀枝花金江分理处',
        value: '102656032650|5104|四川省|攀枝花市'
      },
      {
        label: '中国工商银行股份有限公司攀枝花陶家渡支行',
        value: '102656032713|5104|四川省|攀枝花市'
      },
      {
        label: '中国工商银行股份有限公司广州金沙支行',
        value: '102581005719|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州人和支行',
        value: '102581005727|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州开发区西区支行',
        value: '102581005735|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州沙和路支行',
        value: '102581005778|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司中山小榄美加支行',
        value: '102603005505|4420|广东省|中山市'
      },
      {
        label: '中国工商银行股份有限公司中山沙溪隆都支行',
        value: '102603005907|4420|广东省|中山市'
      },
      {
        label: '中国工商银行股份有限公司广西壮族自治区分行（不对外办理业务）',
        value: '102611009990|4501|广西壮族自治区|南宁市'
      },
      {
        label: '中国工商银行南宁市共和支行',
        value: '102611010109|4501|广西壮族自治区|南宁市'
      },
      {
        label: '中国工商银行股份有限公司南宁市公园支行',
        value: '102611010117|4501|广西壮族自治区|南宁市'
      },
      {
        label: '中国工商银行股份有限公司南宁市新民中支行',
        value: '102611010141|4501|广西壮族自治区|南宁市'
      },
      {
        label: '中国工商银行南宁市兴宁支行',
        value: '102611010205|4501|广西壮族自治区|南宁市'
      },
      {
        label: '中国工商银行南宁市新城支行',
        value: '102611010301|4501|广西壮族自治区|南宁市'
      },
      {
        label: '中国工商银行股份有限公司南宁市星湖支行',
        value: '102611010310|4501|广西壮族自治区|南宁市'
      },
      {
        label: '中国工商银行南宁市永新支行',
        value: '102611010408|4501|广西壮族自治区|南宁市'
      },
      {
        label: '中国工商银行南宁市明秀支行',
        value: '102611010504|4501|广西壮族自治区|南宁市'
      },
      {
        label: '中国工商银行南宁市城北支行',
        value: '102611010512|4501|广西壮族自治区|南宁市'
      },
      {
        label: '中国工商银行南宁市江南支行',
        value: '102611010607|4501|广西壮族自治区|南宁市'
      },
      {
        label: '中国工商银行南宁市民主支行',
        value: '102611010703|4501|广西壮族自治区|南宁市'
      },
      {
        label: '中国工商银行股份有限公司南宁市建政支行',
        value: '102611010711|4501|广西壮族自治区|南宁市'
      },
      {
        label: '中国工商银行股份有限公司南宁市民主第二支行',
        value: '102611010720|4501|广西壮族自治区|南宁市'
      },
      {
        label: '中国工商银行南宁市桃源支行',
        value: '102611010800|4501|广西壮族自治区|南宁市'
      },
      {
        label: '中国工商银行股份有限公司南宁市开投支行',
        value: '102611010859|4501|广西壮族自治区|南宁市'
      },
      {
        label: '中国工商银行南宁市民族支行',
        value: '102611010906|4501|广西壮族自治区|南宁市'
      },
      {
        label: '中国工商银行南宁市城建支行',
        value: '102611010914|4501|广西壮族自治区|南宁市'
      },
      {
        label: '中国工商银行云南曲靖分行宣威振兴北路支行',
        value: '102736300304|5303|云南省|曲靖市'
      },
      {
        label: '中国工商银行股份有限公司六枝特区人民路支行',
        value: '102702100208|5202|贵州省|六盘水市'
      },
      {
        label: '中国工商银行股份有限公司盘县支行',
        value: '102702200098|5202|贵州省|六盘水市'
      },
      {
        label: '中国工商银行股份有限公司盘县山脚树支行',
        value: '102702200102|5202|贵州省|六盘水市'
      },
      {
        label: '中国工商银行股份有限公司盘县支行老屋基分理处',
        value: '102702200119|5202|贵州省|六盘水市'
      },
      {
        label: '中国工商银行股份有限公司盘县火铺支行',
        value: '102702200143|5202|贵州省|六盘水市'
      },
      {
        label: '中国工商银行股份有限公司盘县红果支行',
        value: '102702200151|5202|贵州省|六盘水市'
      },
      {
        label: '中国工商银行股份有限公司盘县土城支行',
        value: '102702200160|5202|贵州省|六盘水市'
      },
      {
        label: '中国工商银行股份有限公司盘县盘江支行',
        value: '102702200178|5202|贵州省|六盘水市'
      },
      {
        label: '中国工商银行股份有限公司盘县解放桥支行',
        value: '102702205018|5202|贵州省|六盘水市'
      },
      {
        label: '中国工商银行贵州省遵义市红花岗支行',
        value: '102703000014|5203|贵州省|遵义市'
      },
      {
        label: '中国工商银行遵义分行遵义路支行',
        value: '102703000039|5203|贵州省|遵义市'
      },
      {
        label: '中国工商银行股份有限公司天水七里墩支行',
        value: '102825000096|6205|甘肃省|天水市'
      },
      {
        label: '中国工商银行股份有限公司天水石马坪支行',
        value: '102825000107|6205|甘肃省|天水市'
      },
      {
        label: '中国工商银行股份有限公司天水桃园路支行',
        value: '102825000254|6205|甘肃省|天水市'
      },
      {
        label: '中国工商银行股份有限公司天水罗峪支行',
        value: '102825000279|6205|甘肃省|天水市'
      },
      {
        label: '中国工商银行股份有限公司天水麦积道北支行',
        value: '102825000295|6205|甘肃省|天水市'
      },
      {
        label: '中国工商银行股份有限公司天水麦积火车站支行',
        value: '102825000300|6205|甘肃省|天水市'
      },
      {
        label: '中国工商银行股份有限公司天水花牛路支行',
        value: '102825000334|6205|甘肃省|天水市'
      },
      {
        label: '中国工商银行股份有限公司天水大城支行',
        value: '102825000342|6205|甘肃省|天水市'
      },
      {
        label: '中国工商银行股份有限公司天水麦积开发区支行',
        value: '102825000439|6205|甘肃省|天水市'
      },
      {
        label: '中国工商银行股份有限公司天水清水支行',
        value: '102825100706|6205|甘肃省|天水市'
      },
      {
        label: '中国工商银行股份有限公司秦安县支行',
        value: '102825200493|6205|甘肃省|天水市'
      },
      {
        label: '中国工商银行股份有限公司玛纳斯支行',
        value: '102885421993|6523|新疆维吾尔自治区|昌吉回族自治州'
      },
      {
        label: '中国工商银行股份有限公司奇台东街支行',
        value: '102885586214|6523|新疆维吾尔自治区|昌吉回族自治州'
      },
      {
        label: '中国工商银行股份有限公司奇台支行',
        value: '102885586999|6523|新疆维吾尔自治区|昌吉回族自治州'
      },
      {
        label: '中国工商银行股份有限公司准东支行',
        value: '102885683231|6523|新疆维吾尔自治区|昌吉回族自治州'
      },
      {
        label: '中国工商银行股份有限公司阜康天池街支行',
        value: '102885683299|6523|新疆维吾尔自治区|昌吉回族自治州'
      },
      {
        label: '中国工商银行股份有限公司阜康支行',
        value: '102885683993|6523|新疆维吾尔自治区|昌吉回族自治州'
      },
      {
        label: '中国工商银行股份有限公司五家渠支行',
        value: '102885882998|6523|新疆维吾尔自治区|昌吉回族自治州'
      },
      {
        label: '中国工商银行股份有限公司博尔塔拉蒙古自治州分行业务处理中心',
        value: '102887000011|6527|新疆维吾尔自治区|博尔塔拉蒙古自治州'
      },
      {
        label: '中国工商银行股份有限公司阿拉山口支行',
        value: '102887000020|6527|新疆维吾尔自治区|博尔塔拉蒙古自治州'
      },
      {
        label: '中国工商银行股份有限公司博尔塔拉蒙古自治州分行',
        value: '102887000038|6527|新疆维吾尔自治区|博尔塔拉蒙古自治州'
      },
      {
        label: '中国工商银行股份有限公司博尔塔拉蒙古自治州锦绣支行',
        value: '102887000054|6527|新疆维吾尔自治区|博尔塔拉蒙古自治州'
      },
      {
        label: '中国工商银行股份有限公司博尔塔拉蒙古自治州顾里木图支行',
        value: '102887000079|6527|新疆维吾尔自治区|博尔塔拉蒙古自治州'
      },
      {
        label: '中国工商银行股份有限公司博尔塔拉蒙古自治州北京路支行',
        value: '102887000100|6527|新疆维吾尔自治区|博尔塔拉蒙古自治州'
      },
      {
        label: '中国工商银行股份有限公司精河支行',
        value: '102887200013|6527|新疆维吾尔自治区|博尔塔拉蒙古自治州'
      },
      {
        label: '中国工商银行股份有限公司巴音郭楞蒙古自治州分行',
        value: '102888000013|6528|新疆维吾尔自治区|巴音郭楞蒙古自治州'
      },
      {
        label: '中国工商银行股份有限公司库尔勒萨依巴格路支行',
        value: '102888000021|6528|新疆维吾尔自治区|巴音郭楞蒙古自治州'
      },
      {
        label: '中国工商银行股份有限公司库尔勒人民东路支行',
        value: '102888000030|6528|新疆维吾尔自治区|巴音郭楞蒙古自治州'
      },
      {
        label: '中国工商银行股份有限公司库尔勒火车东站支行',
        value: '102888000056|6528|新疆维吾尔自治区|巴音郭楞蒙古自治州'
      },
      {
        label: '中国工商银行股份有限公司库尔勒利民路支行',
        value: '102888000089|6528|新疆维吾尔自治区|巴音郭楞蒙古自治州'
      },
      {
        label: '中国工商银行股份有限公司库尔勒兰干路分理处',
        value: '102888000110|6528|新疆维吾尔自治区|巴音郭楞蒙古自治州'
      },
      {
        label: '中国工商银行股份有限公司武汉钢城支行',
        value: '102521000492|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行武汉市东西湖支行',
        value: '102521000505|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行武汉市经济技术开发区支行',
        value: '102521000513|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行武汉市东湖开发区支行',
        value: '102521000521|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行股份有限公司武汉江夏支行',
        value: '102521000530|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行武汉市黄陂区支行',
        value: '102521000548|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行长沙市德雅路支行体育馆路分理处',
        value: '102551000383|4301|湖南省|长沙市'
      },
      {
        label: '中国工商银行长沙市伍家岭支行',
        value: '102551000391|4301|湖南省|长沙市'
      },
      {
        label: '中国工商银行股份有限公司长沙澜北湾支行',
        value: '102551000406|4301|湖南省|长沙市'
      },
      {
        label: '中国工商银行股份有限公司长沙天健支行',
        value: '102551000414|4301|湖南省|长沙市'
      },
      {
        label: '中国工商银行长沙市北站路支行',
        value: '102551000439|4301|湖南省|长沙市'
      },
      {
        label: '中国工商银行股份有限公司长沙湘雅支行',
        value: '102551000447|4301|湖南省|长沙市'
      },
      {
        label: '中国工商银行长沙市金鹏支行',
        value: '102551000455|4301|湖南省|长沙市'
      },
      {
        label: '中国工商银行河池分行营业部',
        value: '102628181002|4512|广西壮族自治区|河池市'
      },
      {
        label: '中国工商银行股份有限公司霍尔果斯国际边境合作中心支行',
        value: '102898200017|6540|新疆维吾尔自治区|伊犁哈萨克自治州'
      },
      {
        label: '中国工商银行股份有限公司伊宁支行',
        value: '102899203000|6540|新疆维吾尔自治区|伊犁哈萨克自治州'
      },
      {
        label: '中国工商银行股份有限公司伊犁察布查尔支行',
        value: '102899302282|6540|新疆维吾尔自治区|伊犁哈萨克自治州'
      },
      {
        label: '中国工商银行股份有限公司霍城支行',
        value: '102899403108|6540|新疆维吾尔自治区|伊犁哈萨克自治州'
      },
      {
        label: '中国工商银行股份有限公司伊犁霍尔果斯支行',
        value: '102899403124|6540|新疆维吾尔自治区|伊犁哈萨克自治州'
      },
      {
        label: '中国工商银行股份有限公司巩留支行',
        value: '102899503408|6540|新疆维吾尔自治区|伊犁哈萨克自治州'
      },
      {
        label: '中国工商银行股份有限公司新源支行',
        value: '102899603305|6540|新疆维吾尔自治区|伊犁哈萨克自治州'
      },
      {
        label: '中国工商银行股份有限公司特克斯支行',
        value: '102899803503|6540|新疆维吾尔自治区|伊犁哈萨克自治州'
      },
      {
        label: '中国工商银行股份有限公司塔城分行业务处理中心',
        value: '102901000013|6542|新疆维吾尔自治区|塔城地区'
      },
      {
        label: '中国工商银行股份有限公司塔城分行',
        value: '102901000021|6542|新疆维吾尔自治区|塔城地区'
      },
      {
        label: '中国工商银行股份有限公司塔城文化街支行',
        value: '102901000030|6542|新疆维吾尔自治区|塔城地区'
      },
      {
        label: '中国工商银行股份有限公司贵阳金桥支行相宝山分理处',
        value: '102701001110|5201|贵州省|贵阳市'
      },
      {
        label: '中国工商银行股份有限公司贵阳未来方舟分理处',
        value: '102701001128|5201|贵州省|贵阳市'
      },
      {
        label: '中国工商银行股份有限公司长沙铁道支行',
        value: '102551001443|4301|湖南省|长沙市'
      },
      {
        label: '中国工商银行股份有限公司长沙井湾子支行井湾子储蓄所',
        value: '102551001451|4301|湖南省|长沙市'
      },
      {
        label: '中国工商银行股份有限公司长沙天河支行',
        value: '102551001541|4301|湖南省|长沙市'
      },
      {
        label: '中国工商银行股份有限公司长沙南航支行',
        value: '102551001576|4301|湖南省|长沙市'
      },
      {
        label: '中国工商银行股份有限公司长沙湘雅芙蓉路支行',
        value: '102551001656|4301|湖南省|长沙市'
      },
      {
        label: '中国工商银行股份有限公司广州增城石滩支行',
        value: '102581005794|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州十三行路支行',
        value: '102581005892|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州分行',
        value: '102581006004|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司票据营业部广州分部',
        value: '102581010055|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州大石支行',
        value: '102581015569|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州嘉禾支行',
        value: '102581051046|4401|广东省|广州市'
      },
      {
        label: '中国工商银行广东省分行清算中心（不办理对外业务）',
        value: '102581099996|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司贵阳市政府广场支行',
        value: '102701007053|5201|贵州省|贵阳市'
      },
      {
        label: '中国工商银行股份有限公司深圳福民支行',
        value: '102584003222|4403|广东省|深圳市'
      },
      {
        label: '中国工商银行股份有限公司深圳益田支行',
        value: '102584003239|4403|广东省|深圳市'
      },
      {
        label: '中国工商银行股份有限公司深圳喜年支行',
        value: '102584003247|4403|广东省|深圳市'
      },
      {
        label: '中国工商银行股份有限公司深圳新沙支行',
        value: '102584003255|4403|广东省|深圳市'
      },
      {
        label: '中国工商银行股份有限公司深圳市民中心支行',
        value: '102584003271|4403|广东省|深圳市'
      },
      {
        label: '中国工商银行股份有限公司深圳滨河支行',
        value: '102584004022|4403|广东省|深圳市'
      },
      {
        label: '中国工商银行股份有限公司深圳香梅支行',
        value: '102584004047|4403|广东省|深圳市'
      },
      {
        label: '中国工商银行股份有限公司深圳星河支行',
        value: '102584004055|4403|广东省|深圳市'
      },
      {
        label: '中国工商银行股份有限公司深圳水榭春天支行',
        value: '102584004276|4403|广东省|深圳市'
      },
      {
        label: '中国工商银行股份有限公司深圳华南城支行',
        value: '102584005092|4403|广东省|深圳市'
      },
      {
        label: '中国工商银行股份有限公司深圳侨香支行',
        value: '102584005130|4403|广东省|深圳市'
      },
      {
        label: '中国工商银行股份有限公司深圳东环支行',
        value: '102584005172|4403|广东省|深圳市'
      },
      {
        label: '中国工商银行股份有限公司深圳百合支行',
        value: '102584005308|4403|广东省|深圳市'
      },
      {
        label: '中国工商银行股份有限公司深圳免税大厦支行',
        value: '102584005365|4403|广东省|深圳市'
      },
      {
        label: '中国工商银行股份有限公司深圳尚都支行',
        value: '102584005515|4403|广东省|深圳市'
      },
      {
        label: '中国工商银行股份有限公司深圳观澜支行',
        value: '102584005566|4403|广东省|深圳市'
      },
      {
        label: '中国工商银行股份有限公司深圳华为支行',
        value: '102584005603|4403|广东省|深圳市'
      },
      {
        label: '中国工商银行玉林市江南支行',
        value: '102624070403|4509|广西壮族自治区|玉林市'
      },
      {
        label: '中国工商银行股份有限公司玉林市火车站支行',
        value: '102624070411|4509|广西壮族自治区|玉林市'
      },
      {
        label: '中国工商银行股份有限公司玉林市石子岭支行',
        value: '102624070420|4509|广西壮族自治区|玉林市'
      },
      {
        label: '中国工商银行股份有限公司玉林市大牛窝支行',
        value: '102624070438|4509|广西壮族自治区|玉林市'
      },
      {
        label: '中国工商银行股份有限公司玉林市中药材支行',
        value: '102624070446|4509|广西壮族自治区|玉林市'
      },
      {
        label: '中国工商银行股份有限公司玉林市城站分理处',
        value: '102624070454|4509|广西壮族自治区|玉林市'
      },
      {
        label: '中国工商银行股份有限公司贵港市贵城支行',
        value: '102624271006|4509|广西壮族自治区|玉林市'
      },
      {
        label: '中国工商银行股份有限公司贵港市覃塘支行',
        value: '102624271039|4509|广西壮族自治区|玉林市'
      },
      {
        label: '中国工商银行股份有限公司贵港分行',
        value: '102624271508|4509|广西壮族自治区|玉林市'
      },
      {
        label: '中国工商银行桂平市支行',
        value: '102624370903|4508|广西壮族自治区|贵港市'
      },
      {
        label: '中国工商银行云南曲靖分行宣威市支行',
        value: '102736305085|5303|云南省|曲靖市'
      },
      {
        label: '中国工商银行云南曲靖分行宣威下堡街支行',
        value: '102736306166|5303|云南省|曲靖市'
      },
      {
        label: '中国工商银行云南曲靖分行宣威钱屯支行',
        value: '102736306211|5303|云南省|曲靖市'
      },
      {
        label: '中国工商银行云南曲靖分行宣威榕城分理处',
        value: '102736306220|5303|云南省|曲靖市'
      },
      {
        label: '中国工商银行云南曲靖分行富源城关支行',
        value: '102736400670|5303|云南省|曲靖市'
      },
      {
        label: '中国工商银行云南曲靖分行富源县支行',
        value: '102736405091|5303|云南省|曲靖市'
      },
      {
        label: '中国工商银行云南曲靖分行富源县金城北路支行',
        value: '102736406570|5303|云南省|曲靖市'
      },
      {
        label: '中国工商银行云南曲靖分行罗平县支行',
        value: '102736505118|5303|云南省|曲靖市'
      },
      {
        label: '中国工商银行云南曲靖分行罗平县观音阁支行',
        value: '102736506493|5303|云南省|曲靖市'
      },
      {
        label: '中国工商银行云南曲靖分行陆良西城支行',
        value: '102736700025|5303|云南省|曲靖市'
      },
      {
        label: '中国工商银行云南曲靖分行陆良城区支行',
        value: '102736700033|5303|云南省|曲靖市'
      },
      {
        label: '中国工商银行云南曲靖分行陆良县支行',
        value: '102736705125|5303|云南省|曲靖市'
      },
      {
        label: '中国工商银行云南曲靖分行会泽者海支行',
        value: '102736900028|5303|云南省|曲靖市'
      },
      {
        label: '中国工商银行云南曲靖分行会泽城关支行',
        value: '102736900044|5303|云南省|曲靖市'
      },
      {
        label: '中国工商银行云南曲靖分行会泽钟屏支行',
        value: '102736900052|5303|云南省|曲靖市'
      },
      {
        label: '中国工商银行云南曲靖分行会泽县支行',
        value: '102736905136|5303|云南省|曲靖市'
      },
      {
        label: '中国工商银行股份有限公司四川省南充西河路支行',
        value: '102673053232|5113|四川省|南充市'
      },
      {
        label: '中国工商银行股份有限公司四川省南充火车站支行',
        value: '102673053249|5113|四川省|南充市'
      },
      {
        label: '中国工商银行股份有限公司四川省南充高坪支行',
        value: '102673053312|5113|四川省|南充市'
      },
      {
        label: '中国工商银行股份有限公司四川省南充分行河东分理处',
        value: '102673053329|5113|四川省|南充市'
      },
      {
        label: '中国工商银行股份有限公司南充分行江东大道分理处',
        value: '102673053353|5113|四川省|南充市'
      },
      {
        label: '中国工商银行股份有限公司四川省南充果城支行',
        value: '102673053618|5113|四川省|南充市'
      },
      {
        label: '中国工商银行股份有限公司四川省南充分行',
        value: '102673053714|5113|四川省|南充市'
      },
      {
        label: '中国工商银行股份有限公司石河子经济技术开发区支行',
        value: '102902800048|6590|新疆维吾尔自治区|自治区直辖县级行政区划'
      },
      {
        label: '中国工商银行股份有限公司河池市河北支行',
        value: '102628181019|4512|广西壮族自治区|河池市'
      },
      {
        label: '中国工商银行股份有限公司河池市南桥支行',
        value: '102628181027|4512|广西壮族自治区|河池市'
      },
      {
        label: '中国工商银行宜州市支行',
        value: '102628283004|4512|广西壮族自治区|河池市'
      },
      {
        label: '中国工商银行股份有限公司宜州市城北支行',
        value: '102628283037|4512|广西壮族自治区|河池市'
      },
      {
        label: '中国工商银行罗城仫佬族自治县支行',
        value: '102628384008|4512|广西壮族自治区|河池市'
      },
      {
        label: '中国工商银行环江毛南族自治县支行',
        value: '102628484500|4512|广西壮族自治区|河池市'
      },
      {
        label: '中国工商银行股份有限公司环江县桥东支行',
        value: '102628484518|4512|广西壮族自治区|河池市'
      },
      {
        label: '中国工商银行南丹县支行',
        value: '102628585002|4512|广西壮族自治区|河池市'
      },
      {
        label: '中国工商银行大厂办事处',
        value: '102628587008|4512|广西壮族自治区|河池市'
      },
      {
        label: '中国工商银行河池市龙滩支行',
        value: '102628687509|4512|广西壮族自治区|河池市'
      },
      {
        label: '中国工商银行东兰县支行',
        value: '102628885507|4512|广西壮族自治区|河池市'
      },
      {
        label: '中国工商银行都安瑶族自治县支行',
        value: '102629186006|4512|广西壮族自治区|河池市'
      },
      {
        label: '中国工商银行大化瑶族自治县支行',
        value: '102629286509|4512|广西壮族自治区|河池市'
      },
      {
        label: '中国工商银行昆明正义支行电力大楼分理处',
        value: '102731011874|5301|云南省|昆明市'
      },
      {
        label: '中国工商银行昆明茭菱路支行',
        value: '102731011882|5301|云南省|昆明市'
      },
      {
        label: '中国工商银行昆明行政中心支行',
        value: '102731011899|5301|云南省|昆明市'
      },
      {
        label: '中国工商银行昆明华都花园支行',
        value: '102731011903|5301|云南省|昆明市'
      },
      {
        label: '中国工商银行昆明海源中路支行',
        value: '102731011962|5301|云南省|昆明市'
      },
      {
        label: '中国工商银行昆明西华园支行',
        value: '102731012060|5301|云南省|昆明市'
      },
      {
        label: '中国工商银行昆明学府路支行',
        value: '102731012078|5301|云南省|昆明市'
      },
      {
        label: '中国工商银行昆明梁源支行',
        value: '102731012086|5301|云南省|昆明市'
      },
      {
        label: '中国工商银行昆明昆沙路支行',
        value: '102731012094|5301|云南省|昆明市'
      },
      {
        label: '中国工商银行昆明佳园支行',
        value: '102731012141|5301|云南省|昆明市'
      },
      {
        label: '中国工商银行昆明东川兴玉路支行',
        value: '102731012150|5301|云南省|昆明市'
      },
      {
        label: '中国工商银行昆明东川团结路支行',
        value: '102731012184|5301|云南省|昆明市'
      },
      {
        label: '中国工商银行昆明穿金路支行',
        value: '102731012272|5301|云南省|昆明市'
      },
      {
        label: '中国工商银行昆明建设路支行',
        value: '102731012289|5301|云南省|昆明市'
      },
      {
        label: '中国工商银行昆明白云路支行',
        value: '102731012310|5301|云南省|昆明市'
      },
      {
        label: '中国工商银行昆明正义支行气象路分理处',
        value: '102731012352|5301|云南省|昆明市'
      },
      {
        label: '中国工商银行昆明阳光支行',
        value: '102731012377|5301|云南省|昆明市'
      },
      {
        label: '中国工商银行昆明金沙支行',
        value: '102731012385|5301|云南省|昆明市'
      },
      {
        label: '中国工商银行股份有限公司梁平支行',
        value: '102667500896|5114|四川省|眉山市'
      },
      {
        label: '中国工商银行股份有限公司云阳支行',
        value: '102667600844|5114|四川省|眉山市'
      },
      {
        label: '中国工商银行股份有限公司重庆枳城支行',
        value: '102669000905|5116|四川省|广安市'
      },
      {
        label: '中国工商银行股份有限公司重庆南门山支行',
        value: '102669000913|5116|四川省|广安市'
      },
      {
        label: '中国工商银行股份有限公司重庆兴华支行',
        value: '102669001182|5116|四川省|广安市'
      },
      {
        label: '中国工商银行股份有限公司重庆市垫江支行',
        value: '102669200966|5116|四川省|广安市'
      },
      {
        label: '中国工商银行股份有限公司重庆市南川支行',
        value: '102669300949|5116|四川省|广安市'
      },
      {
        label: '中国工商银行股份有限公司重庆市丰都支行',
        value: '102669400933|5116|四川省|广安市'
      },
      {
        label: '中国工商银行股份有限公司重庆武隆支行',
        value: '102669501324|5116|四川省|广安市'
      },
      {
        label: '中国工商银行股份有限公司宜宾西区支行',
        value: '102671001181|5115|四川省|宜宾市'
      },
      {
        label: '中国工商银行宜宾市分行业务处理中心',
        value: '102671050116|5115|四川省|宜宾市'
      },
      {
        label: '中国工商银行股份有限公司西宁湟中支行',
        value: '102851003651|6301|青海省|西宁市'
      },
      {
        label: '中国工商银行股份有限公司海东分行',
        value: '102852003510|6321|青海省|海东地区'
      },
      {
        label: '中国工商银行股份有限公司民和支行',
        value: '102852203606|6321|青海省|海东地区'
      },
      {
        label: '中国工商银行股份有限公司乐都支行',
        value: '102852303553|6321|青海省|海东地区'
      },
      {
        label: '中国工商银行股份有限公司互助支行',
        value: '102852603755|6321|青海省|海东地区'
      },
      {
        label: '中国工商银行股份有限公司海西支行',
        value: '102859004040|6328|青海省|海西蒙古族藏族自治州'
      },
      {
        label: '中国工商银行股份有限公司大柴旦支行',
        value: '102859004200|6328|青海省|海西蒙古族藏族自治州'
      },
      {
        label: '中国工商银行股份有限公司格尔木昆仑路支行',
        value: '102859102027|6328|青海省|海西蒙古族藏族自治州'
      },
      {
        label: '中国工商银行股份有限公司格尔木博爱支行',
        value: '102859102043|6328|青海省|海西蒙古族藏族自治州'
      },
      {
        label: '中国工商银行股份有限公司格尔木站前支行',
        value: '102859102051|6328|青海省|海西蒙古族藏族自治州'
      },
      {
        label: '中国工商银行股份有限公司格尔木盐湖支行',
        value: '102859102060|6328|青海省|海西蒙古族藏族自治州'
      },
      {
        label: '中国工商银行股份有限公司格尔木盐桥路支行',
        value: '102859102141|6328|青海省|海西蒙古族藏族自治州'
      },
      {
        label: '中国工商银行股份有限公司格尔木支行',
        value: '102859104153|6328|青海省|海西蒙古族藏族自治州'
      },
      {
        label: '中国工商银行股份有限公司格尔木炼油厂支行',
        value: '102859104196|6328|青海省|海西蒙古族藏族自治州'
      },
      {
        label: '中国工商银行股份有限公司格尔木玲珑湾支行',
        value: '102859104725|6328|青海省|海西蒙古族藏族自治州'
      },
      {
        label: '工行宁夏分行营业部',
        value: '102871000015|6401|宁夏回族自治区|银川市'
      },
      {
        label: '工行宁夏银川东城支行',
        value: '102871000023|6401|宁夏回族自治区|银川市'
      },
      {
        label: '工行宁夏银川西城支行',
        value: '102871000031|6401|宁夏回族自治区|银川市'
      },
      {
        label: '工行宁夏银川西门支行',
        value: '102871000040|6401|宁夏回族自治区|银川市'
      },
      {
        label: '工行宁夏银川金凤支行',
        value: '102871000058|6401|宁夏回族自治区|银川市'
      },
      {
        label: '工行宁夏银川西夏支行',
        value: '102871000074|6401|宁夏回族自治区|银川市'
      },
      {
        label: '工行宁夏银川开发区支行',
        value: '102871000082|6401|宁夏回族自治区|银川市'
      },
      {
        label: '中国工商银行股份有限公司中山小榄红山支行',
        value: '102603002310|4420|广东省|中山市'
      },
      {
        label: '中国工商银行股份有限公司贵阳世纪城支行',
        value: '102701007061|5201|贵州省|贵阳市'
      },
      {
        label: '中国工商银行贵州省分行（不对外办理业务）',
        value: '102701009995|5201|贵州省|贵阳市'
      },
      {
        label: '中国工商银行股份有限公司贵阳贵州大学支行',
        value: '102701013404|5201|贵州省|贵阳市'
      },
      {
        label: '中国工商银行股份有限公司贵阳望哨坡支行',
        value: '102701013412|5201|贵州省|贵阳市'
      },
      {
        label: '中国工商银行股份有限公司贵阳纪念塔支行',
        value: '102701013429|5201|贵州省|贵阳市'
      },
      {
        label: '中国工商银行股份有限公司贵阳岐山路支行',
        value: '102701013437|5201|贵州省|贵阳市'
      },
      {
        label: '中国工商银行贵阳中天会展城支行',
        value: '102701013445|5201|贵州省|贵阳市'
      },
      {
        label: '中国工商银行股份有限公司贵阳省府路支行',
        value: '102701013453|5201|贵州省|贵阳市'
      },
      {
        label: '中国工商银行股份有限公司遵义十字街支行',
        value: '102703200459|5203|贵州省|遵义市'
      },
      {
        label: '中国工商银行股份有限公司遵义共青支行',
        value: '102703201601|5203|贵州省|遵义市'
      },
      {
        label: '中国工商银行股份有限公司遵义象山支行',
        value: '102703202410|5203|贵州省|遵义市'
      },
      {
        label: '中国工商银行遵义分行桐梓县支行',
        value: '102703300244|5203|贵州省|遵义市'
      },
      {
        label: '中国工商银行股份有限公司桐梓娄山支行',
        value: '102703300412|5203|贵州省|遵义市'
      },
      {
        label: '中国工商银行股份有限公司绥阳支行',
        value: '102703400472|5203|贵州省|遵义市'
      },
      {
        label: '中国工商银行遵义分行道真县支行',
        value: '102703600205|5203|贵州省|遵义市'
      },
      {
        label: '中国工商银行股份有限公司凤冈县支行',
        value: '102703801576|5203|贵州省|遵义市'
      },
      {
        label: '中国工商银行股份有限公司湄潭支行',
        value: '102703902907|5203|贵州省|遵义市'
      },
      {
        label: '中国工商银行遵义分行余庆县支行',
        value: '102704100197|5203|贵州省|遵义市'
      },
      {
        label: '中国工商银行遵义分行仁怀市支行',
        value: '102704200219|5203|贵州省|遵义市'
      },
      {
        label: '中国工商银行贵州省遵义分行仁怀市茅台支行',
        value: '102704200227|5203|贵州省|遵义市'
      },
      {
        label: '中国工商银行股份有限公司遵义仁怀国酒支行',
        value: '102704201529|5203|贵州省|遵义市'
      },
      {
        label: '中国工商银行股份有限公司遵义仁怀酒都支行',
        value: '102704201537|5203|贵州省|遵义市'
      },
      {
        label: '工行陕西省安康江北张岭分理处',
        value: '102801000161|6109|陕西省|安康市'
      },
      {
        label: '工行陕西省安康江北火车站支行',
        value: '102801000188|6109|陕西省|安康市'
      },
      {
        label: '工行陕西省安康巴山东路支行',
        value: '102801000207|6109|陕西省|安康市'
      },
      {
        label: '中国工商银行安康解放路支行',
        value: '102801070010|6109|陕西省|安康市'
      },
      {
        label: '中国工商银行安康汉滨支行',
        value: '102801070028|6109|陕西省|安康市'
      },
      {
        label: '中国工商银行安康江北支行',
        value: '102801070036|6109|陕西省|安康市'
      },
      {
        label: '中国工商银行安康分行业务处理中心',
        value: '102801075005|6109|陕西省|安康市'
      },
      {
        label: '中国工商银行安康分行',
        value: '102801076008|6109|陕西省|安康市'
      },
      {
        label: '中国工商银行安康汉阴县支行',
        value: '102801270118|6109|陕西省|安康市'
      },
      {
        label: '中国工商银行安康石泉县支行',
        value: '102801370106|6109|陕西省|安康市'
      },
      {
        label: '中国工商银行安康紫阳县支行',
        value: '102801570058|6109|陕西省|安康市'
      },
      {
        label: '中国工商银行安康岚皋县支行',
        value: '102801670049|6109|陕西省|安康市'
      },
      {
        label: '中国工商银行安康旬阳县支行',
        value: '102801970072|6109|陕西省|安康市'
      },
      {
        label: '中国工商银行股份有限公司商洛商州区江滨支行',
        value: '102803008039|6110|陕西省|商洛市'
      },
      {
        label: '中国工商银行商洛商州区迎宾支行',
        value: '102803080089|6110|陕西省|商洛市'
      },
      {
        label: '工行陕西省商洛行政广场支行',
        value: '102803080097|6110|陕西省|商洛市'
      },
      {
        label: '工行陕西省商洛商州区北新街支行',
        value: '102803080101|6110|陕西省|商洛市'
      },
      {
        label: '工行陕西省商洛商州支行',
        value: '102803080128|6110|陕西省|商洛市'
      },
      {
        label: '中国工商银行商洛分行业务处理中心',
        value: '102803085001|6110|陕西省|商洛市'
      },
      {
        label: '中国工商银行商洛支行',
        value: '102803086004|6110|陕西省|商洛市'
      },
      {
        label: '中国工商银行洛南县支行',
        value: '102803280035|6110|陕西省|商洛市'
      },
      {
        label: '工商银行洛南县支行西街分理处',
        value: '102803280133|6110|陕西省|商洛市'
      },
      {
        label: '中国工商银行股份有限公司武汉芳草路支行',
        value: '102521002293|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行股份有限公司武汉麒麟路支行',
        value: '102521002308|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行股份有限公司武汉西大街支行',
        value: '102521002349|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行股份有限公司武汉十里支行',
        value: '102521002357|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行股份有限公司武汉拦江路支行',
        value: '102521002381|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行股份有限公司武汉文化宫支行',
        value: '102521002507|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行股份有限公司武汉海工支行',
        value: '102521002515|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行股份有限公司武汉体育馆支行',
        value: '102521002558|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行武汉南湖璞园支行',
        value: '102521002574|4201|湖北省|武汉市'
      },
      {
        label: '工商银行武汉常青花园支行',
        value: '102521002582|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行武汉大东门支行',
        value: '102521002603|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行股份有限公司武汉三八支行',
        value: '102521002620|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行武汉列电新村支行',
        value: '102521002654|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行股份有限公司武汉东亭支行',
        value: '102521002687|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行股份有限公司武汉中山路支行',
        value: '102521002767|4201|湖北省|武汉市'
      },
      {
        label: '工行陕西省延安东关支行',
        value: '102804095053|6106|陕西省|延安市'
      },
      {
        label: '中国工商银行延安市中心街支行北关分理处',
        value: '102804095061|6106|陕西省|延安市'
      },
      {
        label: '工行延安迎宾路支行',
        value: '102804095070|6106|陕西省|延安市'
      },
      {
        label: '工行陕西省延安北大街支行',
        value: '102804095088|6106|陕西省|延安市'
      },
      {
        label: '工行陕西省延安延长县支行',
        value: '102804200013|6106|陕西省|延安市'
      },
      {
        label: '中国工商银行股份有限公司延川县支行',
        value: '102804390021|6106|陕西省|延安市'
      },
      {
        label: '中国工商银行延安市子长支行',
        value: '102804490149|6106|陕西省|延安市'
      },
      {
        label: '中国工商银行股份有限公司九江东区支行',
        value: '102424010292|3604|江西省|九江市'
      },
      {
        label: '中国工商银行铜陵铜都支行',
        value: '102367002407|3407|安徽省|铜陵市'
      },
      {
        label: '中国工商银行铜陵狮子山支行',
        value: '102367002503|3407|安徽省|铜陵市'
      },
      {
        label: '中国工商银行铜陵新桥支行',
        value: '102367002618|3407|安徽省|铜陵市'
      },
      {
        label: '中国工商银行铜陵分行会计业务处理中心',
        value: '102367002909|3407|安徽省|铜陵市'
      },
      {
        label: '中国工商银行股份有限公司兴宁支行',
        value: '102596500216|4414|广东省|梅州市'
      },
      {
        label: '中国工商银行股份有限公司平远支行',
        value: '102596602411|4414|广东省|梅州市'
      },
      {
        label: '中国工商银行股份有限公司蕉岭支行',
        value: '102596702512|4414|广东省|梅州市'
      },
      {
        label: '中国工商银行股份有限公司汕尾分行营业部',
        value: '102597000212|4415|广东省|汕尾市'
      },
      {
        label: '中国工商银行股份有限公司汕尾分行业务处理中心',
        value: '102597000307|4415|广东省|汕尾市'
      },
      {
        label: '中国工商银行股份有限公司海丰支行',
        value: '102597100205|4415|广东省|汕尾市'
      },
      {
        label: '中国工商银行股份有限公司陆丰支行',
        value: '102597200263|4415|广东省|汕尾市'
      },
      {
        label: '中国工商银行股份有限公司陆河支行',
        value: '102597300287|4415|广东省|汕尾市'
      },
      {
        label: '中国工商银行股份有限公司河源分行业务处理中心',
        value: '102598000206|4416|广东省|河源市'
      },
      {
        label: '中国工商银行股份有限公司河源源城支行',
        value: '102598000214|4416|广东省|河源市'
      },
      {
        label: '中国工商银行股份有限公司河源分行营业部',
        value: '102598000222|4416|广东省|河源市'
      },
      {
        label: '中国工商银行股份有限公司河源高新支行',
        value: '102598000280|4416|广东省|河源市'
      },
      {
        label: '中国工商银行股份有限公司河源建设支行',
        value: '102598001846|4416|广东省|河源市'
      },
      {
        label: '中国工商银行股份有限公司河源新丰江支行',
        value: '102598002113|4416|广东省|河源市'
      },
      {
        label: '中国工商银行股份有限公司河源开发区支行',
        value: '102598002236|4416|广东省|河源市'
      },
      {
        label: '中国工商银行股份有限公司河源中山支行',
        value: '102598002269|4416|广东省|河源市'
      },
      {
        label: '中国工商银行股份有限公司河源经纬支行',
        value: '102598002277|4416|广东省|河源市'
      },
      {
        label: '中国工商银行股份有限公司河源越王大道支行',
        value: '102598002285|4416|广东省|河源市'
      },
      {
        label: '中国工商银行股份有限公司河源兴源东支行',
        value: '102598002293|4416|广东省|河源市'
      },
      {
        label: '中国工商银行股份有限公司河源华达支行',
        value: '102598002316|4416|广东省|河源市'
      },
      {
        label: '中国工商银行股份有限公司紫金支行',
        value: '102598100279|4416|广东省|河源市'
      },
      {
        label: '中国工商银行股份有限公司龙川支行',
        value: '102598200290|4416|广东省|河源市'
      },
      {
        label: '中国工商银行股份有限公司龙川龙城支行',
        value: '102598202915|4416|广东省|河源市'
      },
      {
        label: '中国工商银行股份有限公司龙川新街支行',
        value: '102598202958|4416|广东省|河源市'
      },
      {
        label: '中国工商银行股份有限公司安岳柠都支行',
        value: '102663303505|5120|四川省|资阳市'
      },
      {
        label: '中国工商银行股份有限公司四川省资阳安岳县支行',
        value: '102663349212|5120|四川省|资阳市'
      },
      {
        label: '中国工商银行股份有限公司威远兰草街分理处',
        value: '102663407035|5110|四川省|内江市'
      },
      {
        label: '中国工商银行股份有限公司威远支行荷花街分理处',
        value: '102663407051|5110|四川省|内江市'
      },
      {
        label: '中国工商银行股份有限公司威远支行',
        value: '102663449019|5110|四川省|内江市'
      },
      {
        label: '中国工商银行股份有限公司威远川威支行',
        value: '102663449027|5110|四川省|内江市'
      },
      {
        label: '中国工商银行股份有限公司威远顺城支行',
        value: '102663449035|5110|四川省|内江市'
      },
      {
        label: '中国工商银行股份有限公司资中支行中街分理处',
        value: '102663505049|5110|四川省|内江市'
      },
      {
        label: '中国工商银行股份有限公司资中支行',
        value: '102663548715|5110|四川省|内江市'
      },
      {
        label: '中国工商银行股份有限公司资中笔架山支行',
        value: '102663548723|5110|四川省|内江市'
      },
      {
        label: '中国工商银行股份有限公司资中水南支行',
        value: '102663548731|5110|四川省|内江市'
      },
      {
        label: '中国工商银行股份有限公司资阳娇子大道支行',
        value: '102663603493|5120|四川省|资阳市'
      },
      {
        label: '中国工商银行股份有限公司资阳分行中城街分理处',
        value: '102663604050|5120|四川省|资阳市'
      },
      {
        label: '中国工商银行股份有限公司资阳狮山路支行',
        value: '102663604068|5120|四川省|资阳市'
      },
      {
        label: '中国工商银行股份有限公司资阳车城支行',
        value: '102663604076|5120|四川省|资阳市'
      },
      {
        label: '中国工商银行股份有限公司资阳分行仁德西路分理处',
        value: '102663604130|5120|四川省|资阳市'
      },
      {
        label: '中国工商银行股份有限公司资阳分行业务处理中心',
        value: '102663648111|5120|四川省|资阳市'
      },
      {
        label: '中国工商银行股份有限公司四川省资阳分行',
        value: '102663648816|5120|四川省|资阳市'
      },
      {
        label: '中国工商银行股份有限公司四川省资阳广场路支行',
        value: '102663648824|5120|四川省|资阳市'
      },
      {
        label: '中国工商银行股份有限公司四川省资阳和平路支行',
        value: '102663648832|5120|四川省|资阳市'
      },
      {
        label: '中国工商银行股份有限公司简阳政府街支行',
        value: '102663703045|5120|四川省|资阳市'
      },
      {
        label: '中国工商银行股份有限公司简阳支行空分分理处',
        value: '102663703088|5120|四川省|资阳市'
      },
      {
        label: '中国工商银行股份有限公司四川省资阳简阳市支行',
        value: '102663748914|5120|四川省|资阳市'
      },
      {
        label: '中国工商银行股份有限公司四川省简阳南街支行',
        value: '102663748922|5120|四川省|资阳市'
      },
      {
        label: '中国工商银行股份有限公司简阳天津路支行',
        value: '102663748939|5120|四川省|资阳市'
      },
      {
        label: '中国工商银行股份有限公司隆昌红旗支行',
        value: '102663806039|5110|四川省|内江市'
      },
      {
        label: '中国工商银行股份有限公司达州分行红旗路支行',
        value: '102675057630|5117|四川省|达州市'
      },
      {
        label: '中国工商银行股份有限公司达州西外新区支行',
        value: '102675057648|5117|四川省|达州市'
      },
      {
        label: '中国工商银行股份有限公司达州分行凤翎关分理处',
        value: '102675057664|5117|四川省|达州市'
      },
      {
        label: '中国工商银行股份有限公司四川省达州分行大西街支行',
        value: '102675057744|5117|四川省|达州市'
      },
      {
        label: '中国工商银行股份有限公司达州南北干道支行',
        value: '102675057769|5117|四川省|达州市'
      },
      {
        label: '中国工商银行股份有限公司达州达川支行',
        value: '102675258314|5117|四川省|达州市'
      },
      {
        label: '中国工商银行股份有限公司宣汉衙门口支行',
        value: '102675301238|5117|四川省|达州市'
      },
      {
        label: '中国工商银行股份有限公司石河子新城支行',
        value: '102902800056|6590|新疆维吾尔自治区|自治区直辖县级行政区划'
      },
      {
        label: '中国工商银行股份有限公司石河子西环路支行',
        value: '102902800089|6590|新疆维吾尔自治区|自治区直辖县级行政区划'
      },
      {
        label: '中国工商银行股份有限公司石河子老街支行',
        value: '102902800097|6590|新疆维吾尔自治区|自治区直辖县级行政区划'
      },
      {
        label: '中国工商银行股份有限公司石河子分行信用卡部',
        value: '102902800101|6590|新疆维吾尔自治区|自治区直辖县级行政区划'
      },
      {
        label: '中国工商银行股份有限公司哈密三道岭支行',
        value: '102902900014|6590|新疆维吾尔自治区|自治区直辖县级行政区划'
      },
      {
        label: '中国工商银行昆明金实支行',
        value: '102731012408|5301|云南省|昆明市'
      },
      {
        label: '中国工商银行昆明西市区支行花园分理处',
        value: '102731012416|5301|云南省|昆明市'
      },
      {
        label: '中国工商银行昆明金泉支行',
        value: '102731012424|5301|云南省|昆明市'
      },
      {
        label: '中国工商银行昆明呈贡斗南分理处',
        value: '102731020922|5301|云南省|昆明市'
      },
      {
        label: '中国工商银行昆明北教场支行',
        value: '102731021005|5301|云南省|昆明市'
      },
      {
        label: '中国工商银行昆明海口支行山冲分理处',
        value: '102731021281|5301|云南省|昆明市'
      },
      {
        label: '中国工商银行昆明石林支行石林分理处',
        value: '102731021474|5301|云南省|昆明市'
      },
      {
        label: '中国工商银行昆明宜良振兴街支行',
        value: '102731021503|5301|云南省|昆明市'
      },
      {
        label: '中国工商银行昆明宜良支行阳宗海分理处',
        value: '102731021554|5301|云南省|昆明市'
      },
      {
        label: '中国工商银行云南昭通分行团结路分理处',
        value: '102734000074|5306|云南省|昭通市'
      },
      {
        label: '中国工商银行云南昭通分行珠泉路分理处',
        value: '102734000103|5306|云南省|昭通市'
      },
      {
        label: '中国工商银行云南昭通分行西街支行',
        value: '102734002035|5306|云南省|昭通市'
      },
      {
        label: '中国工商银行云南昭通分行元宝支行',
        value: '102734002043|5306|云南省|昭通市'
      },
      {
        label: '中国工商银行云南昭通分行北门分理处',
        value: '102734002051|5306|云南省|昭通市'
      },
      {
        label: '中国工商银行股份有限公司萍乡跃进支行',
        value: '102423004115|3603|江西省|萍乡市'
      },
      {
        label: '中国工商银行股份有限公司萍乡文化路支行',
        value: '102423004158|3603|江西省|萍乡市'
      },
      {
        label: '中国工商银行股份有限公司萍乡分行营业部',
        value: '102423004166|3603|江西省|萍乡市'
      },
      {
        label: '中国工商银行股份有限公司上栗支行',
        value: '102423104128|3603|江西省|萍乡市'
      },
      {
        label: '中国工商银行股份有限公司芦溪支行',
        value: '102423204130|3603|江西省|萍乡市'
      },
      {
        label: '中国工商银行九江市分行',
        value: '102424007007|3604|江西省|九江市'
      },
      {
        label: '中国工商银行股份有限公司九江浔东支行',
        value: '102424007015|3604|江西省|九江市'
      },
      {
        label: '中国工商银行股份有限公司九江浔中支行',
        value: '102424007023|3604|江西省|九江市'
      },
      {
        label: '中国工商银行股份有限公司九江十里支行',
        value: '102424007031|3604|江西省|九江市'
      },
      {
        label: '中国工商银行股份有限公司庐山支行',
        value: '102424007040|3604|江西省|九江市'
      },
      {
        label: '中国工商银行九江城外支行',
        value: '102424010006|3604|江西省|九江市'
      },
      {
        label: '中国工商银行莱芜迎宾支行',
        value: '102463430105|3712|山东省|莱芜市'
      },
      {
        label: '中国工商银行莱芜张庄支行',
        value: '102463430201|3712|山东省|莱芜市'
      },
      {
        label: '中国工商银行莱芜艾山支行',
        value: '102463430603|3712|山东省|莱芜市'
      },
      {
        label: '中国工商银行莱芜钢都支行',
        value: '102463431204|3712|山东省|莱芜市'
      },
      {
        label: '中国工商银行新泰市支行',
        value: '102463503010|3709|山东省|泰安市'
      },
      {
        label: '中国工商银行股份有限公司泰安新汶支行',
        value: '102463503028|3709|山东省|泰安市'
      },
      {
        label: '中国工商银行威海市中支行',
        value: '102465000010|3710|山东省|威海市'
      },
      {
        label: '中国工商银行股份有限公司威海市中支行同心路储蓄所',
        value: '102465000044|3710|山东省|威海市'
      },
      {
        label: '中国工商银行威海高技术产业开发区支行',
        value: '102465000116|3710|山东省|威海市'
      },
      {
        label: '中国工商银行威海经济技术开发区支行三角分理处',
        value: '102465000132|3710|山东省|威海市'
      },
      {
        label: '中国工商银行股份有限公司滨州滨南支行',
        value: '102466000142|3716|山东省|滨州市'
      },
      {
        label: '中国工商银行股份有限公司中山黄圃永安支行',
        value: '102603002344|4420|广东省|中山市'
      },
      {
        label: '中国工商银行股份有限公司中山银兴支行',
        value: '102603002369|4420|广东省|中山市'
      },
      {
        label: '中国工商银行股份有限公司中山烟洲分理处',
        value: '102603002424|4420|广东省|中山市'
      },
      {
        label: '中国工商银行股份有限公司中山富豪支行',
        value: '102603002504|4420|广东省|中山市'
      },
      {
        label: '中国工商银行股份有限公司中山西区支行',
        value: '102603002512|4420|广东省|中山市'
      },
      {
        label: '中国工商银行股份有限公司中山湖滨支行',
        value: '102603002561|4420|广东省|中山市'
      },
      {
        label: '中国工商银行股份有限公司中山三乡支行',
        value: '102603002658|4420|广东省|中山市'
      },
      {
        label: '中国工商银行股份有限公司中山坦洲支行',
        value: '102603002674|4420|广东省|中山市'
      },
      {
        label: '中国工商银行巢湖市含山县支行',
        value: '102378407303|3401|安徽省|合肥市'
      },
      {
        label: '中国工商银行巢湖市和县支行',
        value: '102378507204|3401|安徽省|合肥市'
      },
      {
        label: '中国工商银行股份有限公司池州分行业务处理中心',
        value: '102379000015|3417|安徽省|池州市'
      },
      {
        label: '中国工商银行股份有限公司湘潭华隆支行',
        value: '102553003121|4303|湖南省|湘潭市'
      },
      {
        label: '中国工商银行股份有限公司湘潭易俗河支行',
        value: '102553003189|4303|湖南省|湘潭市'
      },
      {
        label: '中国工商银行股份有限公司湘潭高新支行',
        value: '102553003252|4303|湖南省|湘潭市'
      },
      {
        label: '中国工商银行股份有限公司湘潭分行韶山路分理处',
        value: '102553010020|4303|湖南省|湘潭市'
      },
      {
        label: '中国工商银行股份有限公司湘潭和平路支行',
        value: '102553010079|4303|湖南省|湘潭市'
      },
      {
        label: '中国工商银行股份有限公司湘潭江麓支行',
        value: '102553010087|4303|湖南省|湘潭市'
      },
      {
        label: '中国工商银行股份有限公司湘潭锰矿支行',
        value: '102553010095|4303|湖南省|湘潭市'
      },
      {
        label: '中国工商银行股份有限公司湘潭科大支行',
        value: '102553010100|4303|湖南省|湘潭市'
      },
      {
        label: '中国工商银行股份有限公司湘潭湘大支行',
        value: '102553010142|4303|湖南省|湘潭市'
      },
      {
        label: '中国工商银行股份有限公司湘潭楠竹山支行',
        value: '102553010159|4303|湖南省|湘潭市'
      },
      {
        label: '中国工商银行股份有限公司湘潭双拥路支行',
        value: '102553010191|4303|湖南省|湘潭市'
      },
      {
        label: '中国工商银行股份有限公司湘潭九华支行',
        value: '102553010222|4303|湖南省|湘潭市'
      },
      {
        label: '中国工商银行股份有限公司湘潭分行昭山支行',
        value: '102553010247|4303|湖南省|湘潭市'
      },
      {
        label: '中国工商银行股份有限公司湘潭电力支行',
        value: '102553010280|4303|湖南省|湘潭市'
      },
      {
        label: '中国工商银行股份有限公司湘潭东湖路支行',
        value: '102553010319|4303|湖南省|湘潭市'
      },
      {
        label: '中国工商银行股份有限公司湘潭下摄司支行',
        value: '102553010327|4303|湖南省|湘潭市'
      },
      {
        label: '中国工商银行股份有限公司湘潭晓塘支行',
        value: '102553010335|4303|湖南省|湘潭市'
      },
      {
        label: '中国工商银行股份有限公司湘潭芙蓉支行',
        value: '102553010343|4303|湖南省|湘潭市'
      },
      {
        label: '中国工商银行股份有限公司湘潭泗神庙支行',
        value: '102553010351|4303|湖南省|湘潭市'
      },
      {
        label: '中国工商银行股份有限公司湘潭谭家山支行',
        value: '102553010360|4303|湖南省|湘潭市'
      },
      {
        label: '中国工商银行股份有限公司湘乡支行',
        value: '102553203137|4303|湖南省|湘潭市'
      },
      {
        label: '中国工商银行股份有限公司湘乡银政支行',
        value: '102553210257|4303|湖南省|湘潭市'
      },
      {
        label: '中国工商银行股份有限公司湘乡棋梓支行',
        value: '102553210273|4303|湖南省|湘潭市'
      },
      {
        label: '中国工商银行股份有限公司湘乡工贸新区支行',
        value: '102553210370|4303|湖南省|湘潭市'
      },
      {
        label: '中国工商银行股份有限公司湘乡新湘路支行',
        value: '102553210388|4303|湖南省|湘潭市'
      },
      {
        label: '中国工商银行股份有限公司湘乡南门口支行',
        value: '102553210396|4303|湖南省|湘潭市'
      },
      {
        label: '中国工商银行股份有限公司韶山支行',
        value: '102553303227|4303|湖南省|湘潭市'
      },
      {
        label: '中国工商银行股份有限公司韶山银田寺支行',
        value: '102553310427|4303|湖南省|湘潭市'
      },
      {
        label: '中国工商银行股份有限公司海口琼山支行',
        value: '102641000188|4601|海南省|海口市'
      },
      {
        label: '中国工商银行股份有限公司海口城西路支行',
        value: '102641000196|4601|海南省|海口市'
      },
      {
        label: '中国工商银行股份有限公司文昌支行',
        value: '102641000207|4601|海南省|海口市'
      },
      {
        label: '中国工商银行股份有限公司琼海支行',
        value: '102641000215|4601|海南省|海口市'
      },
      {
        label: '中国工商银行股份有限公司万宁支行',
        value: '102641000223|4601|海南省|海口市'
      },
      {
        label: '中国工商银行股份有限公司定安支行',
        value: '102641000231|4601|海南省|海口市'
      },
      {
        label: '中国工商银行股份有限公司海口滨海大道支行',
        value: '102641000240|4601|海南省|海口市'
      },
      {
        label: '中国工商银行股份有限公司屯昌支行',
        value: '102641000258|4601|海南省|海口市'
      },
      {
        label: '中国工商银行股份有限公司澄迈支行',
        value: '102641000266|4601|海南省|海口市'
      },
      {
        label: '中国工商银行股份有限公司海口国兴大道支行',
        value: '102641000274|4601|海南省|海口市'
      },
      {
        label: '中国工商银行股份有限公司临高支行',
        value: '102641000282|4601|海南省|海口市'
      },
      {
        label: '中国工商银行股份有限公司五指山支行',
        value: '102641000303|4601|海南省|海口市'
      },
      {
        label: '中国工商银行股份有限公司海口海德路支行',
        value: '102641000338|4601|海南省|海口市'
      },
      {
        label: '中国工商银行武汉军院支行',
        value: '102521003438|4201|湖北省|武汉市'
      },
      {
        label: '工行武汉国通小企业专营支行',
        value: '102521003454|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行武汉将军路支行',
        value: '102521003462|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行武汉永宁支行',
        value: '102521003479|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行股份有限公司武汉辛家地支行',
        value: '102521003487|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行股份有限公司武汉盘龙支行',
        value: '102521003495|4201|湖北省|武汉市'
      },
      {
        label: '工行武汉东风支行',
        value: '102521003500|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行股份有限公司武汉财盛支行',
        value: '102521003518|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行股份有限公司武汉洪福家园支行',
        value: '102521003526|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行云南丽江分行华坪县支行',
        value: '102755312078|5307|云南省|丽江市'
      },
      {
        label: '中国工商银行股份有限公司九江支行',
        value: '102424107056|3604|江西省|九江市'
      },
      {
        label: '中国工商银行股份有限公司瑞昌支行',
        value: '102424207068|3604|江西省|九江市'
      },
      {
        label: '中国工商银行股份有限公司瑞昌码头支行',
        value: '102424210432|3604|江西省|九江市'
      },
      {
        label: '中国工商银行股份有限公司武宁支行',
        value: '102424307034|3604|江西省|九江市'
      },
      {
        label: '中国工商银行股份有限公司修水支行',
        value: '102424407049|3604|江西省|九江市'
      },
      {
        label: '中国工商银行永修县支行',
        value: '102424507052|3604|江西省|九江市'
      },
      {
        label: '中国工商银行股份有限公司德安支行',
        value: '102424607062|3604|江西省|九江市'
      },
      {
        label: '中国工商银行股份有限公司共青城支行',
        value: '102424607118|3604|江西省|九江市'
      },
      {
        label: '中国工商银行股份有限公司东莞凤岗支行',
        value: '102602002931|4419|广东省|东莞市'
      },
      {
        label: '中国工商银行股份有限公司东莞松山湖支行',
        value: '102602005044|4419|广东省|东莞市'
      },
      {
        label: '中国工商银行股份有限公司中山分行银行卡业务部',
        value: '102603000023|4420|广东省|中山市'
      },
      {
        label: '中国工商银行股份有限公司中山神湾支行',
        value: '102603000082|4420|广东省|中山市'
      },
      {
        label: '中国工商银行股份有限公司中山华力支行',
        value: '102603000099|4420|广东省|中山市'
      },
      {
        label: '中国工商银行股份有限公司中山小榄北秀支行',
        value: '102603000154|4420|广东省|中山市'
      },
      {
        label: '中国工商银行股份有限公司中山小榄东庙支行',
        value: '102603000162|4420|广东省|中山市'
      },
      {
        label: '中国工商银行股份有限公司六安人民路支行',
        value: '102376000028|3415|安徽省|六安市'
      },
      {
        label: '中国工商银行股份有限公司六安皖西路支行',
        value: '102376000036|3415|安徽省|六安市'
      },
      {
        label: '中国工商银行股份有限公司六安裕龙支行',
        value: '102376000044|3415|安徽省|六安市'
      },
      {
        label: '中国工商银行股份有限公司六安七里站支行',
        value: '102376000052|3415|安徽省|六安市'
      },
      {
        label: '中国工商银行股份有限公司六安开发区支行',
        value: '102376000069|3415|安徽省|六安市'
      },
      {
        label: '中国工商银行股份有限公司六安城南支行',
        value: '102376000077|3415|安徽省|六安市'
      },
      {
        label: '中国工商银行股份有限公司六安云路支行',
        value: '102376000085|3415|安徽省|六安市'
      },
      {
        label: '中国工商银行股份有限公司六安北门支行',
        value: '102376000093|3415|安徽省|六安市'
      },
      {
        label: '中国工商银行股份有限公司六安叶集支行',
        value: '102376000116|3415|安徽省|六安市'
      },
      {
        label: '中国工商银行股份有限公司六安中心广场支行',
        value: '102376000157|3415|安徽省|六安市'
      },
      {
        label: '中国工商银行股份有限公司六安佛子岭路支行',
        value: '102376000181|3415|安徽省|六安市'
      },
      {
        label: '中国工商银行股份有限公司六安三里桥支行',
        value: '102376000190|3415|安徽省|六安市'
      },
      {
        label: '中国工商银行股份有限公司六安皋城支行',
        value: '102376000204|3415|安徽省|六安市'
      },
      {
        label: '中国工商银行股份有限公司六安解放路支行',
        value: '102376000212|3415|安徽省|六安市'
      },
      {
        label: '中国工商银行股份有限公司六安南门支行',
        value: '102376000237|3415|安徽省|六安市'
      },
      {
        label: '中国工商银行股份有限公司六安示范园区支行',
        value: '102376001756|3415|安徽省|六安市'
      },
      {
        label: '中国工商银行股份有限公司寿县支行',
        value: '102376300012|3404|安徽省|淮南市'
      },
      {
        label: '中国工商银行股份有限公司寿县东街支行',
        value: '102376300029|3404|安徽省|淮南市'
      },
      {
        label: '中国工商银行股份有限公司赣州金桥支行',
        value: '102428010067|3607|江西省|赣州市'
      },
      {
        label: '中国工商银行股份有限公司赣州分行营业部',
        value: '102428010091|3607|江西省|赣州市'
      },
      {
        label: '中国工商银行股份有限公司赣州金房支行',
        value: '102428010106|3607|江西省|赣州市'
      },
      {
        label: '中国工商银行股份有限公司赣州赣江源支行',
        value: '102428010114|3607|江西省|赣州市'
      },
      {
        label: '中国工商银行赣州市虔城支行',
        value: '102428010147|3607|江西省|赣州市'
      },
      {
        label: '中国工商银行赣州市东阳支行',
        value: '102428010180|3607|江西省|赣州市'
      },
      {
        label: '中国工商银行赣州市创业支行',
        value: '102428010202|3607|江西省|赣州市'
      },
      {
        label: '中国工商银行赣州市贡江支行',
        value: '102428010219|3607|江西省|赣州市'
      },
      {
        label: '中国工商银行股份有限公司赣州分行广场支行',
        value: '102428011133|3607|江西省|赣州市'
      },
      {
        label: '中国工商银行股份有限公司赣州杨公路支行',
        value: '102428011150|3607|江西省|赣州市'
      },
      {
        label: '中国工商银行股份有限公司赣州开发区支行',
        value: '102428012013|3607|江西省|赣州市'
      },
      {
        label: '中国工商银行赣县支行',
        value: '102428210229|3607|江西省|赣州市'
      },
      {
        label: '中国工商银行南康市支行',
        value: '102428310230|3607|江西省|赣州市'
      },
      {
        label: '中国工商银行股份有限公司赣州分行南康唐江支行',
        value: '102428310369|3607|江西省|赣州市'
      },
      {
        label: '中国工商银行股份有限公司赣州分行南康潭口支行',
        value: '102428310377|3607|江西省|赣州市'
      },
      {
        label: '中国工商银行股份有限公司赣州分行南康市政广场支行',
        value: '102428310385|3607|江西省|赣州市'
      },
      {
        label: '中国工商银行股份有限公司赣州分行南康家具城支行',
        value: '102428310393|3607|江西省|赣州市'
      },
      {
        label: '中国工商银行信丰县支行',
        value: '102428410247|3607|江西省|赣州市'
      },
      {
        label: '中国工商银行大余县支行',
        value: '102428510254|3607|江西省|赣州市'
      },
      {
        label: '中国工商银行股份有限公司上犹支行',
        value: '102428610265|3607|江西省|赣州市'
      },
      {
        label: '中国工商银行崇义县支行',
        value: '102428710275|3607|江西省|赣州市'
      },
      {
        label: '中国工商银行赣州市分行安远支行',
        value: '102428810282|3607|江西省|赣州市'
      },
      {
        label: '中国工商银行股份有限公司龙南支行',
        value: '102428910293|3607|江西省|赣州市'
      },
      {
        label: '中国工商银行辰溪县支行',
        value: '102567501138|4312|湖南省|怀化市'
      },
      {
        label: '中国工商银行辰溪支行孝坪分理处',
        value: '102567511310|4312|湖南省|怀化市'
      },
      {
        label: '中国工商银行溆浦县支行',
        value: '102567601112|4312|湖南省|怀化市'
      },
      {
        label: '中国工商银行溆浦江口支行',
        value: '102567601129|4312|湖南省|怀化市'
      },
      {
        label: '中国工商银行溆浦支行浦兴分理处',
        value: '102567611113|4312|湖南省|怀化市'
      },
      {
        label: '中国工商银行麻阳支行',
        value: '102567702454|4312|湖南省|怀化市'
      },
      {
        label: '中国工商银行新晃侗族自治县支行',
        value: '102567801155|4312|湖南省|怀化市'
      },
      {
        label: '中国工商银行芷江侗族自治县支行',
        value: '102567901142|4312|湖南省|怀化市'
      },
      {
        label: '中国工商银行股份有限公司武汉南湖花园支行',
        value: '102521003542|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行武汉菱角湖支行',
        value: '102521003567|4201|湖北省|武汉市'
      },
      {
        label: '工行湖北省分行武汉梦湖水岸支行',
        value: '102521003575|4201|湖北省|武汉市'
      },
      {
        label: '工行湖北省分行武汉水岸星城支行',
        value: '102521003583|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行武汉友谊路支行',
        value: '102521003591|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行股份有限公司武汉融侨锦城支行',
        value: '102521003614|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行股份有限公司武汉升官渡支行',
        value: '102521003622|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行股份有限公司武汉金桥嘉园支行',
        value: '102521003639|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行武汉凤凰支行',
        value: '102521003655|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行武汉顶琇晶城支行',
        value: '102521003663|4201|湖北省|武汉市'
      },
      {
        label: '工行湖北省分行武汉华清园支行',
        value: '102521003671|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行股份有限公司武汉马沧湖支行',
        value: '102521003698|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行武汉姚家岭支行',
        value: '102521003702|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行武汉长航支行',
        value: '102521003727|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行股份有限公司武汉武湖支行',
        value: '102521003735|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行武汉美林公馆支行',
        value: '102521003751|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行股份有限公司武汉新生路支行',
        value: '102521003760|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行武汉王家湾支行',
        value: '102521003778|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行股份有限公司武汉软件园支行',
        value: '102521003786|4201|湖北省|武汉市'
      },
      {
        label: '工行湖北省分行武汉华侨城支行',
        value: '102521003794|4201|湖北省|武汉市'
      },
      {
        label: '工行湖北省分行武汉江山如画支行',
        value: '102521003809|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行股份有限公司武汉鲁巷支行',
        value: '102521003817|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行股份有限公司武汉静安上城支行',
        value: '102521003825|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行武汉金色港湾支行',
        value: '102521003833|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行股份有限公司武汉凌家山支行',
        value: '102521003841|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行股份有限公司湛江金地支行',
        value: '102591002070|4408|广东省|湛江市'
      },
      {
        label: '中国工商银行股份有限公司湛江开发区支行',
        value: '102591002088|4408|广东省|湛江市'
      },
      {
        label: '中国工商银行股份有限公司湛江分行业务处理中心',
        value: '102591002990|4408|广东省|湛江市'
      },
      {
        label: '中国工商银行股份有限公司英德长湖支行',
        value: '102601202351|4418|广东省|清远市'
      },
      {
        label: '中国工商银行股份有限公司阳山支行',
        value: '102601302518|4418|广东省|清远市'
      },
      {
        label: '中国工商银行股份有限公司连州支行',
        value: '102601402424|4418|广东省|清远市'
      },
      {
        label: '中国工商银行股份有限公司连南支行',
        value: '102601602617|4418|广东省|清远市'
      },
      {
        label: '中国工商银行股份有限公司清新支行',
        value: '102601702152|4418|广东省|清远市'
      },
      {
        label: '中国工商银行股份有限公司清远经济开发区支行',
        value: '102601702193|4418|广东省|清远市'
      },
      {
        label: '中国工商银行股份有限公司东莞鸿福支行',
        value: '102602001821|4419|广东省|东莞市'
      },
      {
        label: '中国工商银行股份有限公司东莞城区支行',
        value: '102602002011|4419|广东省|东莞市'
      },
      {
        label: '中国工商银行股份有限公司东莞八达路支行',
        value: '102602002038|4419|广东省|东莞市'
      },
      {
        label: '中国工商银行股份有限公司东莞步步高支行',
        value: '102602002046|4419|广东省|东莞市'
      },
      {
        label: '中国工商银行股份有限公司东莞万江支行',
        value: '102602002087|4419|广东省|东莞市'
      },
      {
        label: '中国工商银行股份有限公司东莞东城支行',
        value: '102602002095|4419|广东省|东莞市'
      },
      {
        label: '中国工商银行股份有限公司东莞南城支行',
        value: '102602002118|4419|广东省|东莞市'
      },
      {
        label: '中国工商银行股份有限公司东莞分行营业部',
        value: '102602002134|4419|广东省|东莞市'
      },
      {
        label: '中国工商银行股份有限公司东莞新河北支行',
        value: '102602002167|4419|广东省|东莞市'
      },
      {
        label: '中国工商银行股份有限公司东莞分行业务处理中心',
        value: '102602002206|4419|广东省|东莞市'
      },
      {
        label: '中国工商银行股份有限公司东莞虎门支行',
        value: '102602002214|4419|广东省|东莞市'
      },
      {
        label: '中国工商银行股份有限公司东莞石龙支行',
        value: '102602002319|4419|广东省|东莞市'
      },
      {
        label: '中国工商银行股份有限公司东莞茶山支行',
        value: '102602002417|4419|广东省|东莞市'
      },
      {
        label: '中国工商银行股份有限公司东莞石排支行',
        value: '102602002433|4419|广东省|东莞市'
      },
      {
        label: '中国工商银行股份有限公司东莞企石支行',
        value: '102602002450|4419|广东省|东莞市'
      },
      {
        label: '中国工商银行股份有限公司东莞石碣支行',
        value: '102602002476|4419|广东省|东莞市'
      },
      {
        label: '中国工商银行股份有限公司东莞高步支行',
        value: '102602002492|4419|广东省|东莞市'
      },
      {
        label: '中国工商银行股份有限公司东莞寮步支行',
        value: '102602002513|4419|广东省|东莞市'
      },
      {
        label: '中国工商银行股份有限公司东莞大朗支行',
        value: '102602002530|4419|广东省|东莞市'
      },
      {
        label: '中国工商银行股份有限公司东莞黄江支行',
        value: '102602002556|4419|广东省|东莞市'
      },
      {
        label: '中国工商银行股份有限公司东莞樟木头支行',
        value: '102602002572|4419|广东省|东莞市'
      },
      {
        label: '中国工商银行股份有限公司东莞常平支行',
        value: '102602002597|4419|广东省|东莞市'
      },
      {
        label: '中国工商银行股份有限公司东莞东坑支行',
        value: '102602002610|4419|广东省|东莞市'
      },
      {
        label: '中国工商银行股份有限公司东莞横沥支行',
        value: '102602002636|4419|广东省|东莞市'
      },
      {
        label: '中国工商银行股份有限公司东莞桥头支行',
        value: '102602002652|4419|广东省|东莞市'
      },
      {
        label: '中国工商银行股份有限公司东莞厚街支行',
        value: '102602002677|4419|广东省|东莞市'
      },
      {
        label: '中国工商银行股份有限公司东莞沙田支行',
        value: '102602002693|4419|广东省|东莞市'
      },
      {
        label: '中国工商银行股份有限公司东莞长安支行',
        value: '102602002716|4419|广东省|东莞市'
      },
      {
        label: '中国工商银行股份有限公司三门峡陕州高阳路支行',
        value: '102505212077|4112|河南省|三门峡市'
      },
      {
        label: '中国工商银行三门峡分行灵宝市支行',
        value: '102505302108|4112|河南省|三门峡市'
      },
      {
        label: '中国工商银行股份有限公司滨州滨城支行建翔分理处',
        value: '102466000167|3716|山东省|滨州市'
      },
      {
        label: '中国工商银行滨州市新城支行',
        value: '102466000191|3716|山东省|滨州市'
      },
      {
        label: '中国工商银行股份有限公司滨州渤海支行',
        value: '102466000206|3716|山东省|滨州市'
      },
      {
        label: '中国工商银行股份有限公司滨州滨印支行',
        value: '102466000222|3716|山东省|滨州市'
      },
      {
        label: '中国工商银行股份有限公司滨州渤海支行康家分理处',
        value: '102466000239|3716|山东省|滨州市'
      },
      {
        label: '中国工商银行股份有限公司滨州黄河二路支行',
        value: '102466000302|3716|山东省|滨州市'
      },
      {
        label: '中国工商银行滨州市滨化支行',
        value: '102466000409|3716|山东省|滨州市'
      },
      {
        label: '中国工商银行股份有限公司滨州阳光支行',
        value: '102466002324|3716|山东省|滨州市'
      },
      {
        label: '中国工商银行股份有限公司广州育蕾街支行',
        value: '102581002280|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州枫叶路支行',
        value: '102581002302|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州天晟明苑支行',
        value: '102581002327|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州从化凤仪支行',
        value: '102581002351|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州从化荔香支行',
        value: '102581002360|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州从化太平支行',
        value: '102581002378|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州南国支行',
        value: '102581002450|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州长堤路支行',
        value: '102581002484|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州荔城支行',
        value: '102581002548|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司宜春分行业务处理中心',
        value: '102431008005|3609|江西省|宜春市'
      },
      {
        label: '中国工商银行股份有限公司宜春东风支行',
        value: '102431008013|3609|江西省|宜春市'
      },
      {
        label: '中国工商银行股份有限公司宜春西门支行',
        value: '102431008030|3609|江西省|宜春市'
      },
      {
        label: '中国工商银行股份有限公司宜春分行',
        value: '102431008048|3609|江西省|宜春市'
      },
      {
        label: '中国工商银行股份有限公司宜春袁州支行',
        value: '102431008056|3609|江西省|宜春市'
      },
      {
        label: '中国工商银行股份有限公司宜春东门支行',
        value: '102431010017|3609|江西省|宜春市'
      },
      {
        label: '中国工商银行股份有限公司宜春平安支行',
        value: '102431010068|3609|江西省|宜春市'
      },
      {
        label: '中国工商银行股份有限公司宜春宜阳支行',
        value: '102431010092|3609|江西省|宜春市'
      },
      {
        label: '中国工商银行股份有限公司宜春明月支行',
        value: '102431010105|3609|江西省|宜春市'
      },
      {
        label: '中国工商银行股份有限公司宜春富民支行',
        value: '102431010113|3609|江西省|宜春市'
      },
      {
        label: '中国工商银行股份有限公司宜春分行高士路支行',
        value: '102431010148|3609|江西省|宜春市'
      },
      {
        label: '中国工商银行股份有限公司丰城支行',
        value: '102431208067|3609|江西省|宜春市'
      },
      {
        label: '中国工商银行股份有限公司丰城丰矿支行',
        value: '102431208075|3609|江西省|宜春市'
      },
      {
        label: '中国工商银行股份有限公司丰城人民路支行',
        value: '102431210022|3609|江西省|宜春市'
      },
      {
        label: '中国工商银行股份有限公司丰城新城支行',
        value: '102431210039|3609|江西省|宜春市'
      },
      {
        label: '中国工商银行武城县支行',
        value: '102468600394|3714|山东省|德州市'
      },
      {
        label: '中国工商银行齐河县支行',
        value: '102468700383|3714|山东省|德州市'
      },
      {
        label: '中国工商银行股份有限公司禹城支行站南分理处',
        value: '102468800021|3714|山东省|德州市'
      },
      {
        label: '中国工商银行禹城市支行',
        value: '102468800415|3714|山东省|德州市'
      },
      {
        label: '中国工商银行临邑县临盘支行',
        value: '102468900047|3714|山东省|德州市'
      },
      {
        label: '中国工商银行临邑县支行',
        value: '102468900348|3714|山东省|德州市'
      },
      {
        label: '中国工商银行宁津县支行',
        value: '102469300369|3714|山东省|德州市'
      },
      {
        label: '中国工商银行股份有限公司德州庆云支行',
        value: '102469400038|3714|山东省|德州市'
      },
      {
        label: '中国工商银行聊城市分行',
        value: '102471000016|3715|山东省|聊城市'
      },
      {
        label: '中国工商银行聊城市市中支行',
        value: '102471000024|3715|山东省|聊城市'
      },
      {
        label: '中国工商银行股份有限公司聊城龙山支行',
        value: '102471000090|3715|山东省|聊城市'
      },
      {
        label: '中国工商银行聊城市古楼支行',
        value: '102471000137|3715|山东省|聊城市'
      },
      {
        label: '中国工商银行股份有限公司聊城昌润路支行',
        value: '102471000161|3715|山东省|聊城市'
      },
      {
        label: '中国工商银行聊城市振兴路支行',
        value: '102471000215|3715|山东省|聊城市'
      },
      {
        label: '中国工商银行聊城开发区支行',
        value: '102471000299|3715|山东省|聊城市'
      },
      {
        label: '中国工商银行临清市支行',
        value: '102471200018|3715|山东省|聊城市'
      },
      {
        label: '中国工商银行阳谷县支行',
        value: '102471300010|3715|山东省|聊城市'
      },
      {
        label: '中国工商银行莘县支行',
        value: '102471400019|3715|山东省|聊城市'
      },
      {
        label: '中国工商银行茌平县支行',
        value: '102471500011|3715|山东省|聊城市'
      },
      {
        label: '中国工商银行东阿县支行',
        value: '102471600012|3715|山东省|聊城市'
      },
      {
        label: '中国工商银行冠县支行',
        value: '102471700013|3715|山东省|聊城市'
      },
      {
        label: '中国工商银行高唐县支行',
        value: '102471800014|3715|山东省|聊城市'
      },
      {
        label: '中国工商银行股份有限公司临沂分行',
        value: '102473000010|3713|山东省|临沂市'
      },
      {
        label: '中国工商银行股份有限公司临沂市中支行',
        value: '102473000028|3713|山东省|临沂市'
      },
      {
        label: '中国工商银行股份有限公司临沂兰山支行',
        value: '102473000044|3713|山东省|临沂市'
      },
      {
        label: '中国工商银行股份有限公司临沂城里支行',
        value: '102473000052|3713|山东省|临沂市'
      },
      {
        label: '中国工商银行股份有限公司临沂沂蒙支行',
        value: '102473000069|3713|山东省|临沂市'
      },
      {
        label: '中国工商银行博罗县支行',
        value: '102595202482|4413|广东省|惠州市'
      },
      {
        label: '中国工商银行博罗县杨村支行',
        value: '102595202499|4413|广东省|惠州市'
      },
      {
        label: '中国工商银行博罗县石湾支行',
        value: '102595202503|4413|广东省|惠州市'
      },
      {
        label: '中国工商银行博罗县长宁支行',
        value: '102595202511|4413|广东省|惠州市'
      },
      {
        label: '中国工商银行股份有限公司博罗园洲支行',
        value: '102595202554|4413|广东省|惠州市'
      },
      {
        label: '中国工商银行股份有限公司博罗龙溪支行',
        value: '102595203354|4413|广东省|惠州市'
      },
      {
        label: '中国工商银行股份有限公司南宁市永凯支行',
        value: '102611010980|4501|广西壮族自治区|南宁市'
      },
      {
        label: '中国工商银行云南怒江分行会计业务处理中心',
        value: '102756013014|5333|云南省|怒江傈僳族自治州'
      },
      {
        label: '中国工商银行云南怒江分行六库人民路支行',
        value: '102756013022|5333|云南省|怒江傈僳族自治州'
      },
      {
        label: '中国工商银行云南怒江分行六库支行',
        value: '102756013039|5333|云南省|怒江傈僳族自治州'
      },
      {
        label: '中国工商银行云南怒江分行排路坝支行',
        value: '102756013047|5333|云南省|怒江傈僳族自治州'
      },
      {
        label: '中国工商银行云南怒江分行兰坪县支行',
        value: '102756413024|5333|云南省|怒江傈僳族自治州'
      },
      {
        label: '中国工商银行云南迪庆分行业务处理中心',
        value: '102757002769|5334|云南省|迪庆藏族自治州'
      },
      {
        label: '中国工商银行云南迪庆分行香格里拉支行',
        value: '102757014016|5334|云南省|迪庆藏族自治州'
      },
      {
        label: '中国工商银行云南迪庆分行香格里拉长征路支行',
        value: '102757014024|5334|云南省|迪庆藏族自治州'
      },
      {
        label: '中国工商银行夏河县支行',
        value: '102838700034|6230|甘肃省|甘南藏族自治州'
      },
      {
        label: '中国工商银行青海省分行业务处理中心',
        value: '102851000026|6301|青海省|西宁市'
      },
      {
        label: '中国工商银行股份有限公司西宁城东支行',
        value: '102851000034|6301|青海省|西宁市'
      },
      {
        label: '中国工商银行股份有限公司西宁城北支行',
        value: '102851000059|6301|青海省|西宁市'
      },
      {
        label: '中国工商银行股份有限公司西宁西大街支行',
        value: '102851000067|6301|青海省|西宁市'
      },
      {
        label: '中国工商银行股份有限公司西宁胜利路支行',
        value: '102851000075|6301|青海省|西宁市'
      },
      {
        label: '中国工商银行股份有限公司西宁城西支行',
        value: '102851000114|6301|青海省|西宁市'
      },
      {
        label: '中国工商银行股份有限公司西宁五四大街支行',
        value: '102851000139|6301|青海省|西宁市'
      },
      {
        label: '中国工商银行股份有限公司西宁团结桥支行',
        value: '102851000147|6301|青海省|西宁市'
      },
      {
        label: '中国工商银行咸阳市世纪大道支行',
        value: '102795040274|6104|陕西省|咸阳市'
      },
      {
        label: '中国工商银行咸阳市渭电支行',
        value: '102795040282|6104|陕西省|咸阳市'
      },
      {
        label: '中国工商银行咸阳文林路支行',
        value: '102795040299|6104|陕西省|咸阳市'
      },
      {
        label: '中国工商银行咸阳渭阳西路支行',
        value: '102795040303|6104|陕西省|咸阳市'
      },
      {
        label: '中国工商银行咸阳渭阳中路支行',
        value: '102795040311|6104|陕西省|咸阳市'
      },
      {
        label: '工行陕西省咸阳市迎宾大道支行',
        value: '102795040320|6104|陕西省|咸阳市'
      },
      {
        label: '工行陕西省咸阳渭城桥支行',
        value: '102795040338|6104|陕西省|咸阳市'
      },
      {
        label: '中国工商银行咸阳市西华路支行',
        value: '102795040346|6104|陕西省|咸阳市'
      },
      {
        label: '中国工商银行咸阳市新兴路支行',
        value: '102795040354|6104|陕西省|咸阳市'
      },
      {
        label: '工商银行咸阳市支行',
        value: '102795040362|6104|陕西省|咸阳市'
      },
      {
        label: '中国工商银行股份有限公司乌鲁木齐高新技术产业开发区支行',
        value: '102881002910|6501|新疆维吾尔自治区|乌鲁木齐市'
      },
      {
        label: '中国工商银行股份有限公司乌鲁木齐三宫支行',
        value: '102881002936|6501|新疆维吾尔自治区|乌鲁木齐市'
      },
      {
        label: '中国工商银行股份有限公司南昌洗马池支行',
        value: '102421000616|3601|江西省|南昌市'
      },
      {
        label: '中国工商银行股份有限公司南昌金润支行',
        value: '102421000673|3601|江西省|南昌市'
      },
      {
        label: '中国工商银行股份有限公司南昌天佑支行',
        value: '102421000712|3601|江西省|南昌市'
      },
      {
        label: '中国工商银行股份有限公司南昌井冈山大道支行',
        value: '102421000761|3601|江西省|南昌市'
      },
      {
        label: '中国工商银行股份有限公司南昌解放路支行',
        value: '102421000770|3601|江西省|南昌市'
      },
      {
        label: '中国工商银行股份有限公司南昌江铃支行',
        value: '102421000796|3601|江西省|南昌市'
      },
      {
        label: '中国工商银行股份有限公司南昌青云谱支行',
        value: '102421000807|3601|江西省|南昌市'
      },
      {
        label: '中国工商银行股份有限公司南昌徐家坊支行',
        value: '102421000866|3601|江西省|南昌市'
      },
      {
        label: '中国工商银行股份有限公司南昌上海路支行',
        value: '102421000938|3601|江西省|南昌市'
      },
      {
        label: '中国工商银行股份有限公司南昌北京东路支行',
        value: '102421000954|3601|江西省|南昌市'
      },
      {
        label: '中国工商银行股份有限公司南昌南京东路支行',
        value: '102421000962|3601|江西省|南昌市'
      },
      {
        label: '中国工商银行股份有限公司南昌高新支行',
        value: '102421000979|3601|江西省|南昌市'
      },
      {
        label: '中国工商银行股份有限公司南昌江氨支行',
        value: '102421000987|3601|江西省|南昌市'
      },
      {
        label: '中国工商银行股份有限公司南昌罗家支行',
        value: '102421000995|3601|江西省|南昌市'
      },
      {
        label: '中国工商银行股份有限公司南昌红角洲支行',
        value: '102421001002|3601|江西省|南昌市'
      },
      {
        label: '中国工商银行股份有限公司南昌昌东支行',
        value: '102421001019|3601|江西省|南昌市'
      },
      {
        label: '中国工商银行股份有限公司南昌火炬大道支行',
        value: '102421001043|3601|江西省|南昌市'
      },
      {
        label: '中国工商银行股份有限公司南昌财大支行',
        value: '102421001051|3601|江西省|南昌市'
      },
      {
        label: '中国工商银行股份有限公司南昌红谷滩第一街区支行',
        value: '102421001060|3601|江西省|南昌市'
      },
      {
        label: '中国工商银行股份有限公司南昌广汇支行',
        value: '102421001176|3601|江西省|南昌市'
      },
      {
        label: '中国工商银行股份有限公司南昌昌北支行经开区分理处',
        value: '102421001184|3601|江西省|南昌市'
      },
      {
        label: '中国工商银行股份有限公司新建农大支行',
        value: '102421001213|3601|江西省|南昌市'
      },
      {
        label: '中国工商银行股份有限公司新建大道支行',
        value: '102421001221|3601|江西省|南昌市'
      },
      {
        label: '中国工商银行股份有限责任公司西藏自治区分行(不对外办理业务)',
        value: '102770006664|5401|西藏自治区|拉萨市'
      },
      {
        label: '中国工商银行股份有限公司西安城南科技支行',
        value: '102791000023|6101|陕西省|西安市'
      },
      {
        label: '中国工商银行西安经济技术开发区支行',
        value: '102791000031|6101|陕西省|西安市'
      },
      {
        label: '中国工商银行西安市东新街支行',
        value: '102791000040|6101|陕西省|西安市'
      },
      {
        label: '中国工商银行西安南大街支行',
        value: '102791000058|6101|陕西省|西安市'
      },
      {
        label: '中国工商银行西安市解放路支行',
        value: '102791000066|6101|陕西省|西安市'
      },
      {
        label: '中国工商银行西安市太华路支行',
        value: '102791000074|6101|陕西省|西安市'
      },
      {
        label: '工行陕西省西安长缨路支行',
        value: '102791000099|6101|陕西省|西安市'
      },
      {
        label: '中国工商银行西安民乐园支行',
        value: '102791000103|6101|陕西省|西安市'
      },
      {
        label: '工行陕西省西安安仁街支行',
        value: '102791000111|6101|陕西省|西安市'
      },
      {
        label: '中国工商银行西安市东大街支行',
        value: '102791000120|6101|陕西省|西安市'
      },
      {
        label: '中国工商银行长沙市全通支行',
        value: '102551000471|4301|湖南省|长沙市'
      },
      {
        label: '中国工商银行股份有限公司喀什分行多来提巴格路分理处',
        value: '102894000026|6531|新疆维吾尔自治区|喀什地区'
      },
      {
        label: '中国工商银行股份有限公司喀什机场路支行',
        value: '102894000034|6531|新疆维吾尔自治区|喀什地区'
      },
      {
        label: '中国工商银行股份有限公司喀什托克扎克路支行',
        value: '102894000059|6531|新疆维吾尔自治区|喀什地区'
      },
      {
        label: '中国工商银行股份有限公司喀什解放南路支行',
        value: '102894000067|6531|新疆维吾尔自治区|喀什地区'
      },
      {
        label: '中国工商银行股份有限公司喀什分行',
        value: '102894000309|6531|新疆维吾尔自治区|喀什地区'
      },
      {
        label: '中国工商银行股份有限公司疏附人民北路支行',
        value: '102894200019|6531|新疆维吾尔自治区|喀什地区'
      },
      {
        label: '中国工商银行股份有限公司疏勒支行',
        value: '102894300011|6531|新疆维吾尔自治区|喀什地区'
      },
      {
        label: '中国工商银行股份有限公司英吉沙支行',
        value: '102894400012|6531|新疆维吾尔自治区|喀什地区'
      },
      {
        label: '中国工商银行股份有限公司泽普支行',
        value: '102894500013|6531|新疆维吾尔自治区|喀什地区'
      },
      {
        label: '中国工商银行股份有限公司莎车支行',
        value: '102894600014|6531|新疆维吾尔自治区|喀什地区'
      },
      {
        label: '中国工商银行股份有限公司叶城支行',
        value: '102894700015|6531|新疆维吾尔自治区|喀什地区'
      },
      {
        label: '中国工商银行股份有限公司麦盖提支行',
        value: '102894800016|6531|新疆维吾尔自治区|喀什地区'
      },
      {
        label: '中国工商银行股份有限公司巴楚支行',
        value: '102895200011|6531|新疆维吾尔自治区|喀什地区'
      },
      {
        label: '中国工商银行股份有限公司和田分行业务处理中心',
        value: '102896038004|6532|新疆维吾尔自治区|和田地区'
      },
      {
        label: '中国工商银行股份有限公司长沙桐梓坡支行',
        value: '102551001857|4301|湖南省|长沙市'
      },
      {
        label: '中国工商银行股份有限公司长沙洋湖支行',
        value: '102551001865|4301|湖南省|长沙市'
      },
      {
        label: '中国工商银行股份有限公司长沙咸嘉湖支行',
        value: '102551001873|4301|湖南省|长沙市'
      },
      {
        label: '中国工商银行长沙市星沙支行天华北路分理处',
        value: '102551001953|4301|湖南省|长沙市'
      },
      {
        label: '中国工商银行长沙市星沙支行汽车东站分理处',
        value: '102551001961|4301|湖南省|长沙市'
      },
      {
        label: '中国工商银行股份有限公司长沙枫林支行府后路分理处',
        value: '102551002042|4301|湖南省|长沙市'
      },
      {
        label: '中国工商银行股份有限公司长沙枫林支行汽车西站分理处',
        value: '102551002059|4301|湖南省|长沙市'
      },
      {
        label: '中国工商银行股份有限公司望城支行城关储蓄所',
        value: '102551002075|4301|湖南省|长沙市'
      },
      {
        label: '中国工商银行股份有限公司宁乡水晶城支行',
        value: '102551002171|4301|湖南省|长沙市'
      },
      {
        label: '中国工商银行股份有限公司宁乡玉潭支行',
        value: '102551002180|4301|湖南省|长沙市'
      },
      {
        label: '中国工商银行浏阳市支行工业园分理处',
        value: '102551002278|4301|湖南省|长沙市'
      },
      {
        label: '中国工商银行股份有限公司浏阳天马路支行',
        value: '102551002294|4301|湖南省|长沙市'
      },
      {
        label: '中国工商银行股份有限公司长沙开元路支行',
        value: '102551002392|4301|湖南省|长沙市'
      },
      {
        label: '中国工商银行股份有限公司长沙科技城支行',
        value: '102551003213|4301|湖南省|长沙市'
      },
      {
        label: '中国工商银行股份有限公司长沙湘府东路支行',
        value: '102551004909|4301|湖南省|长沙市'
      },
      {
        label: '中国工商银行股份有限公司长沙博展路支行',
        value: '102551004917|4301|湖南省|长沙市'
      },
      {
        label: '中国工商银行股份有限公司长沙体育新城支行',
        value: '102551005362|4301|湖南省|长沙市'
      },
      {
        label: '中国工商银行股份有限公司长沙暮云支行',
        value: '102551005635|4301|湖南省|长沙市'
      },
      {
        label: '中国工商银行云南昭通分行凤霞支行',
        value: '102734002060|5306|云南省|昭通市'
      },
      {
        label: '中国工商银行云南昭通分行牡丹支行',
        value: '102734002086|5306|云南省|昭通市'
      },
      {
        label: '中国工商银行云南昭通分行安居支行',
        value: '102734002674|5306|云南省|昭通市'
      },
      {
        label: '中国工商银行云南昭通分行会计业务处理中心',
        value: '102734004016|5306|云南省|昭通市'
      },
      {
        label: '中国工商银行云南昭通分行昭阳支行',
        value: '102734004024|5306|云南省|昭通市'
      },
      {
        label: '中国工商银行云南昭通分行永善县支行',
        value: '102734604045|5306|云南省|昭通市'
      },
      {
        label: '中国工商银行云南昭通分行水富云天化支行',
        value: '102735200181|5306|云南省|昭通市'
      },
      {
        label: '中国工商银行云南昭通分行水富向家坝分理处',
        value: '102735200190|5306|云南省|昭通市'
      },
      {
        label: '中国工商银行云南昭通分行水富县支行',
        value: '102735204086|5306|云南省|昭通市'
      },
      {
        label: '中国工商银行云南曲靖分行东星分理处',
        value: '102736000043|5303|云南省|曲靖市'
      },
      {
        label: '中国工商银行云南曲靖分行龙泽园支行',
        value: '102736000060|5303|云南省|曲靖市'
      },
      {
        label: '中国工商银行云南曲靖分行麒麟西路分理处',
        value: '102736000078|5303|云南省|曲靖市'
      },
      {
        label: '中国工商银行云南曲靖分行南市支行',
        value: '102736000109|5303|云南省|曲靖市'
      },
      {
        label: '中国工商银行云南曲靖分行龙华支行',
        value: '102736000133|5303|云南省|曲靖市'
      },
      {
        label: '中国工商银行云南曲靖分行城北支行',
        value: '102736000150|5303|云南省|曲靖市'
      },
      {
        label: '中国工商银行云南曲靖分行廖廓北路分理处',
        value: '102736002936|5303|云南省|曲靖市'
      },
      {
        label: '中国工商银行云南曲靖分行阿诗玛支行',
        value: '102736003118|5303|云南省|曲靖市'
      },
      {
        label: '中国工商银行股份有限公司白银十字街支行',
        value: '102824000141|6204|甘肃省|白银市'
      },
      {
        label: '中国工商银行股份有限公司庆城北关支行',
        value: '102834200170|6210|甘肃省|庆阳市'
      },
      {
        label: '中国工商银行股份有限公司庆阳环县支行',
        value: '102834300228|6210|甘肃省|庆阳市'
      },
      {
        label: '中国工商银行股份有限公司庆阳华池支行',
        value: '102834400235|6210|甘肃省|庆阳市'
      },
      {
        label: '中国工商银行股份有限公司庆阳合水支行',
        value: '102834500245|6210|甘肃省|庆阳市'
      },
      {
        label: '中国工商银行股份有限公司正宁支行',
        value: '102834600060|6210|甘肃省|庆阳市'
      },
      {
        label: '中国工商银行股份有限公司宁县支行',
        value: '102834700054|6210|甘肃省|庆阳市'
      },
      {
        label: '中国工商银行股份有限公司镇原支行',
        value: '102834800045|6210|甘肃省|庆阳市'
      },
      {
        label: '中国工商银行临夏州分行会计业务处理中心',
        value: '102836000018|6229|甘肃省|临夏回族自治州'
      },
      {
        label: '中国工商银行股份有限公司临夏大什字支行',
        value: '102836000034|6229|甘肃省|临夏回族自治州'
      },
      {
        label: '中国工商银行股份有限公司临夏分行营业室',
        value: '102836000067|6229|甘肃省|临夏回族自治州'
      },
      {
        label: '中国工商银行临夏州分行解放路分理处',
        value: '102836000083|6229|甘肃省|临夏回族自治州'
      },
      {
        label: '中国工商银行股份有限公司临夏开发区支行',
        value: '102836200019|6229|甘肃省|临夏回族自治州'
      },
      {
        label: '中国工商银行股份有限公司康乐支行',
        value: '102836300011|6229|甘肃省|临夏回族自治州'
      },
      {
        label: '中国工商银行股份有限公司永靖支行',
        value: '102836400045|6229|甘肃省|临夏回族自治州'
      },
      {
        label: '中国工商银行股份有限公司永靖炳灵支行',
        value: '102836400096|6229|甘肃省|临夏回族自治州'
      },
      {
        label: '中国工商银行股份有限公司广河支行',
        value: '102836500013|6229|甘肃省|临夏回族自治州'
      },
      {
        label: '中国工商银行股份有限公司和政支行',
        value: '102836600014|6229|甘肃省|临夏回族自治州'
      },
      {
        label: '中国工商银行股份有限公司临夏东乡支行',
        value: '102836700015|6229|甘肃省|临夏回族自治州'
      },
      {
        label: '中国工商银行股份有限公司积石山支行',
        value: '102836800016|6229|甘肃省|临夏回族自治州'
      },
      {
        label: '中国工商银行合作市支行会计业务处理中心',
        value: '102838000011|6230|甘肃省|甘南藏族自治州'
      },
      {
        label: '中国工商银行合作市支行营业室',
        value: '102838000020|6230|甘肃省|甘南藏族自治州'
      },
      {
        label: '中国工商银行股份有限公司甘南分行羚城支行',
        value: '102838000095|6230|甘肃省|甘南藏族自治州'
      },
      {
        label: '中国工商银行股份有限公司甘南分行广场支行',
        value: '102838000100|6230|甘肃省|甘南藏族自治州'
      },
      {
        label: '中国工商银行股份有限公司临潭支行',
        value: '102838100254|6230|甘肃省|甘南藏族自治州'
      },
      {
        label: '中国工商银行股份有限公司卓尼支行',
        value: '102838200136|6230|甘肃省|甘南藏族自治州'
      },
      {
        label: '中国工商银行股份有限公司舟曲支行',
        value: '102838300127|6230|甘肃省|甘南藏族自治州'
      },
      {
        label: '中国工商银行迭部县支行',
        value: '102838400040|6230|甘肃省|甘南藏族自治州'
      },
      {
        label: '中国工商银行玛曲县支行',
        value: '102838500057|6230|甘肃省|甘南藏族自治州'
      },
      {
        label: '中国工商银行股份有限公司碌曲支行',
        value: '102838600148|6230|甘肃省|甘南藏族自治州'
      },
      {
        label: '中国工商银行股份有限公司乌鲁木齐克拉玛依东路支行',
        value: '102881002311|6501|新疆维吾尔自治区|乌鲁木齐市'
      },
      {
        label: '中国工商银行股份有限公司乌鲁木齐经二路支行三桥分理处',
        value: '102881002320|6501|新疆维吾尔自治区|乌鲁木齐市'
      },
      {
        label: '中国工商银行股份有限公司乌鲁木齐扬子江路支行',
        value: '102881002338|6501|新疆维吾尔自治区|乌鲁木齐市'
      },
      {
        label: '中国工商银行股份有限公司乌鲁木齐南昌路西支行',
        value: '102881002362|6501|新疆维吾尔自治区|乌鲁木齐市'
      },
      {
        label: '中国工商银行股份有限公司乌鲁木齐经二路支行',
        value: '102881002387|6501|新疆维吾尔自治区|乌鲁木齐市'
      },
      {
        label: '中国工商银行股份有限公司乌鲁木齐南湖路支行',
        value: '102881002627|6501|新疆维吾尔自治区|乌鲁木齐市'
      },
      {
        label: '中国工商银行股份有限公司乌鲁木齐南湖东路支行',
        value: '102881002635|6501|新疆维吾尔自治区|乌鲁木齐市'
      },
      {
        label: '中国工商银行股份有限公司乌鲁木齐钢城支行',
        value: '102881002717|6501|新疆维吾尔自治区|乌鲁木齐市'
      },
      {
        label: '中国工商银行股份有限公司乌鲁木齐中兴街支行',
        value: '102881002813|6501|新疆维吾尔自治区|乌鲁木齐市'
      },
      {
        label: '中国工商银行股份有限公司乌鲁木齐喀什东路支行',
        value: '102881002830|6501|新疆维吾尔自治区|乌鲁木齐市'
      },
      {
        label: '中国工商银行股份有限公司乌鲁木齐北京路东支行',
        value: '102881002864|6501|新疆维吾尔自治区|乌鲁木齐市'
      },
      {
        label: '中国工商银行股份有限公司宝轮支行',
        value: '102661044811|5108|四川省|广元市'
      },
      {
        label: '中国工商银行股份有限公司广元雪峰支行',
        value: '102661044820|5108|四川省|广元市'
      },
      {
        label: '中国工商银行股份有限公司旺苍支行',
        value: '102661144719|5108|四川省|广元市'
      },
      {
        label: '中国工商银行股份有限公司旺苍红星支行',
        value: '102661144743|5108|四川省|广元市'
      },
      {
        label: '中国工商银行股份有限公司广元青川支行',
        value: '102661200015|5108|四川省|广元市'
      },
      {
        label: '中国工商银行股份有限公司四川省广元剑阁普安支行',
        value: '102661345014|5108|四川省|广元市'
      },
      {
        label: '中国工商银行股份有限公司剑阁支行',
        value: '102661345022|5108|四川省|广元市'
      },
      {
        label: '中国工商银行股份有限公司四川省广元苍溪解放路支行',
        value: '102661444242|5108|四川省|广元市'
      },
      {
        label: '中国工商银行股份有限公司苍溪支行',
        value: '102661444672|5108|四川省|广元市'
      },
      {
        label: '中国工商银行股份有限公司遂宁凯旋支行',
        value: '102662000074|5109|四川省|遂宁市'
      },
      {
        label: '中国工商银行南宁市南湖支行',
        value: '102611011005|4501|广西壮族自治区|南宁市'
      },
      {
        label: '中国工商银行股份有限公司南宁市大沙田支行',
        value: '102611011013|4501|广西壮族自治区|南宁市'
      },
      {
        label: '中国工商银行南宁市高新技术产业开发区支行',
        value: '102611011101|4501|广西壮族自治区|南宁市'
      },
      {
        label: '中国工商银行股份有限公司南宁市西乡塘支行',
        value: '102611011136|4501|广西壮族自治区|南宁市'
      },
      {
        label: '中国工商银行股份有限公司南宁市科园分理处',
        value: '102611011169|4501|广西壮族自治区|南宁市'
      },
      {
        label: '中国工商银行南宁市琅东支行',
        value: '102611011208|4501|广西壮族自治区|南宁市'
      },
      {
        label: '中国工商银行股份有限公司南宁市市府支行',
        value: '102611011224|4501|广西壮族自治区|南宁市'
      },
      {
        label: '中国工商银行股份有限公司南宁市金禄支行',
        value: '102611011232|4501|广西壮族自治区|南宁市'
      },
      {
        label: '中国工商银行南宁市朝阳支行',
        value: '102611011304|4501|广西壮族自治区|南宁市'
      },
      {
        label: '中国工商银行南宁市衡阳支行',
        value: '102611011312|4501|广西壮族自治区|南宁市'
      },
      {
        label: '中国工商银行股份有限公司南宁市南棉支行',
        value: '102611011361|4501|广西壮族自治区|南宁市'
      },
      {
        label: '中国工商银行邕宁县支行',
        value: '102611011600|4501|广西壮族自治区|南宁市'
      },
      {
        label: '中国工商银行武鸣支行',
        value: '102611011706|4501|广西壮族自治区|南宁市'
      },
      {
        label: '中国工商银行股份有限公司武鸣县东盟经济园区支行',
        value: '102611011780|4501|广西壮族自治区|南宁市'
      },
      {
        label: '中国工商银行横县支行',
        value: '102611011909|4501|广西壮族自治区|南宁市'
      },
      {
        label: '中国工商银行宾阳支行',
        value: '102611012104|4501|广西壮族自治区|南宁市'
      },
      {
        label: '中国工商银行股份有限公司黎塘支行',
        value: '102611012400|4501|广西壮族自治区|南宁市'
      },
      {
        label: '中国工商银行股份有限公司射洪武安河支行',
        value: '102662200806|5109|四川省|遂宁市'
      },
      {
        label: '中国工商银行射洪县支行',
        value: '102662246416|5109|四川省|遂宁市'
      },
      {
        label: '中国工商银行射洪县支行金和分理处',
        value: '102662246449|5109|四川省|遂宁市'
      },
      {
        label: '中国工商银行股份有限公司射洪子昂广场支行',
        value: '102662246465|5109|四川省|遂宁市'
      },
      {
        label: '中国工商银行遂宁市大英支行',
        value: '102662346516|5109|四川省|遂宁市'
      },
      {
        label: '中国工商银行股份有限公司四川省遂宁梨园桥支行',
        value: '102662346532|5109|四川省|遂宁市'
      },
      {
        label: '中国工商银行股份有限公司内江高桥支行',
        value: '102663000068|5110|四川省|内江市'
      },
      {
        label: '工行宁夏石嘴山惠农南街支行',
        value: '102872004317|6402|宁夏回族自治区|石嘴山市'
      },
      {
        label: '工行宁夏石嘴山惠农惠安支行',
        value: '102872004325|6402|宁夏回族自治区|石嘴山市'
      },
      {
        label: '工行宁夏石嘴山业务处理中心（不办理对客户业务）',
        value: '102872005002|6402|宁夏回族自治区|石嘴山市'
      },
      {
        label: '工行宁夏石嘴山平罗支行',
        value: '102872100083|6402|宁夏回族自治区|石嘴山市'
      },
      {
        label: '中国工商银行长沙市汇通支行',
        value: '102551000480|4301|湖南省|长沙市'
      },
      {
        label: '中国工商银行长沙市广厦支行',
        value: '102551000498|4301|湖南省|长沙市'
      },
      {
        label: '中国工商银行长沙市银迅支行',
        value: '102551000502|4301|湖南省|长沙市'
      },
      {
        label: '中国工商银行长沙市星沙支行',
        value: '102551000519|4301|湖南省|长沙市'
      },
      {
        label: '中国工商银行长沙市星沙支行榔梨镇分理处',
        value: '102551000535|4301|湖南省|长沙市'
      },
      {
        label: '中国工商银行望城县支行',
        value: '102551000578|4301|湖南省|长沙市'
      },
      {
        label: '中国工商银行股份有限公司长沙枫林支行',
        value: '102551000586|4301|湖南省|长沙市'
      },
      {
        label: '中国工商银行浏阳市支行',
        value: '102551000594|4301|湖南省|长沙市'
      },
      {
        label: '中国工商银行浏阳市支行城北分理处',
        value: '102551000609|4301|湖南省|长沙市'
      },
      {
        label: '中国工商银行浏阳市支行银星分理处',
        value: '102551000625|4301|湖南省|长沙市'
      },
      {
        label: '中国工商银行股份有限公司宁乡支行',
        value: '102551000641|4301|湖南省|长沙市'
      },
      {
        label: '中国工商银行股份有限公司深圳福田支行',
        value: '102584002338|4403|广东省|深圳市'
      },
      {
        label: '中国工商银行股份有限公司深圳西乡支行',
        value: '102584002346|4403|广东省|深圳市'
      },
      {
        label: '中国工商银行深圳市爱华支行',
        value: '102584002354|4403|广东省|深圳市'
      },
      {
        label: '中国工商银行股份有限公司深圳宝湖支行',
        value: '102584002362|4403|广东省|深圳市'
      },
      {
        label: '中国工商银行股份有限公司深圳黄贝支行',
        value: '102584002379|4403|广东省|深圳市'
      },
      {
        label: '中国工商银行股份有限公司深圳水贝支行',
        value: '102584002387|4403|广东省|深圳市'
      },
      {
        label: '中国工商银行股份有限公司深圳西海岸支行',
        value: '102584002395|4403|广东省|深圳市'
      },
      {
        label: '中国工商银行股份有限公司深圳文锦支行',
        value: '102584002400|4403|广东省|深圳市'
      },
      {
        label: '中国工商银行股份有限公司深圳南新支行',
        value: '102584002418|4403|广东省|深圳市'
      },
      {
        label: '中国工商银行股份有限公司深圳市盐田港区支行',
        value: '102584002426|4403|广东省|深圳市'
      },
      {
        label: '中国工商银行股份有限公司深圳平湖支行',
        value: '102584002434|4403|广东省|深圳市'
      },
      {
        label: '中国工商银行股份有限公司深圳桂园支行',
        value: '102584002459|4403|广东省|深圳市'
      },
      {
        label: '中国工商银行股份有限公司深圳园岭支行',
        value: '102584002475|4403|广东省|深圳市'
      },
      {
        label: '中国工商银行股份有限公司深圳红宝支行',
        value: '102584002483|4403|广东省|深圳市'
      },
      {
        label: '中国工商银行股份有限公司深圳台湾花园支行',
        value: '102584002491|4403|广东省|深圳市'
      },
      {
        label: '中国工商银行股份有限公司深圳弘雅支行',
        value: '102584002506|4403|广东省|深圳市'
      },
      {
        label: '中国工商银行深圳新世界广场支行',
        value: '102584002514|4403|广东省|深圳市'
      },
      {
        label: '中国工商银行股份有限公司深圳黄木岗支行',
        value: '102584002522|4403|广东省|深圳市'
      },
      {
        label: '中国工商银行股份有限公司深圳车公庙支行',
        value: '102584002539|4403|广东省|深圳市'
      },
      {
        label: '中国工商银行股份有限公司深圳保税区支行',
        value: '102584002555|4403|广东省|深圳市'
      },
      {
        label: '中国工商银行股份有限公司深圳和平支行',
        value: '102584002563|4403|广东省|深圳市'
      },
      {
        label: '中国工商银行股份有限公司深圳友谊支行',
        value: '102584002580|4403|广东省|深圳市'
      },
      {
        label: '中国工商银行股份有限公司深圳梅林支行',
        value: '102584002627|4403|广东省|深圳市'
      },
      {
        label: '中国工商银行股份有限公司深圳皇岗支行',
        value: '102584002635|4403|广东省|深圳市'
      },
      {
        label: '中国工商银行股份有限公司深圳莲塘支行',
        value: '102584002643|4403|广东省|深圳市'
      },
      {
        label: '中国工商银行股份有限公司深圳宝华支行',
        value: '102584002651|4403|广东省|深圳市'
      },
      {
        label: '中国工商银行股份有限公司深圳龙华支行',
        value: '102584002660|4403|广东省|深圳市'
      },
      {
        label: '中国工商银行股份有限公司桂林市三里店支行',
        value: '102617026787|4503|广西壮族自治区|桂林市'
      },
      {
        label: '中国工商银行阳朔县支行',
        value: '102617122717|4503|广西壮族自治区|桂林市'
      },
      {
        label: '中国工商银行股份有限公司平凉南门什字支行',
        value: '102833000238|6208|甘肃省|平凉市'
      },
      {
        label: '中国工商银行股份有限公司平凉城东支行',
        value: '102833000254|6208|甘肃省|平凉市'
      },
      {
        label: '中国工商银行泾川县支行',
        value: '102833200063|6208|甘肃省|平凉市'
      },
      {
        label: '中国工商银行股份有限公司灵台支行',
        value: '102833306653|6208|甘肃省|平凉市'
      },
      {
        label: '中国工商银行股份有限公司崇信支行',
        value: '102833406648|6208|甘肃省|平凉市'
      },
      {
        label: '中国工商银行股份有限公司株洲茨菇塘支行',
        value: '102552002093|4302|湖南省|株洲市'
      },
      {
        label: '中国工商银行股份有限公司株洲宋家桥支行',
        value: '102552002108|4302|湖南省|株洲市'
      },
      {
        label: '中国工商银行股份有限公司株洲杉木塘支行',
        value: '102552002124|4302|湖南省|株洲市'
      },
      {
        label: '中国工商银行股份有限公司株洲分行（不对外）',
        value: '102552002270|4302|湖南省|株洲市'
      },
      {
        label: '中国工商银行株洲市汇源支行',
        value: '102552002296|4302|湖南省|株洲市'
      },
      {
        label: '工行宁夏银川永宁支行',
        value: '102871000099|6401|宁夏回族自治区|银川市'
      },
      {
        label: '中国工商银行股份有限公司韶关分行业务处理中心',
        value: '102582002184|4402|广东省|韶关市'
      },
      {
        label: '中国工商银行股份有限公司韶关南门支行',
        value: '102582002192|4402|广东省|韶关市'
      },
      {
        label: '中国工商银行股份有限公司韶关西河支行',
        value: '102582002213|4402|广东省|韶关市'
      },
      {
        label: '中国工商银行股份有限公司韶关风采支行',
        value: '102582002221|4402|广东省|韶关市'
      },
      {
        label: '中国工商银行股份有限公司韶关东堤支行',
        value: '102582002230|4402|广东省|韶关市'
      },
      {
        label: '中国工商银行股份有限公司韶关北郊支行',
        value: '102582002248|4402|广东省|韶关市'
      },
      {
        label: '中国工商银行股份有限公司韶关松山支行',
        value: '102582002264|4402|广东省|韶关市'
      },
      {
        label: '中国工商银行股份有限公司韶关分行营业部',
        value: '102582002289|4402|广东省|韶关市'
      },
      {
        label: '中国工商银行岑溪市支行',
        value: '102622137009|4504|广西壮族自治区|梧州市'
      },
      {
        label: '中国工商银行股份有限公司藤县支行',
        value: '102622230800|4504|广西壮族自治区|梧州市'
      },
      {
        label: '中国工商银行昭平县支行',
        value: '102622336000|4511|广西壮族自治区|贺州市'
      },
      {
        label: '中国工商银行股份有限公司蒙山县支行',
        value: '102622430705|4504|广西壮族自治区|梧州市'
      },
      {
        label: '中国工商银行股份有限公司贺州市建设路支行',
        value: '102622538000|4511|广西壮族自治区|贺州市'
      },
      {
        label: '中国工商银行股份有限公司贺州市西约支行',
        value: '102622538059|4511|广西壮族自治区|贺州市'
      },
      {
        label: '中国工商银行股份有限公司贺州分行',
        value: '102622538501|4511|广西壮族自治区|贺州市'
      },
      {
        label: '中国工商银行贺州市西湾支行',
        value: '102622539019|4511|广西壮族自治区|贺州市'
      },
      {
        label: '中国工商银行钟山县支行',
        value: '102622639009|4511|广西壮族自治区|贺州市'
      },
      {
        label: '中国工商银行富川瑶族自治县支行',
        value: '102622730104|4511|广西壮族自治区|贺州市'
      },
      {
        label: '中国工商银行北海分行营业部',
        value: '102623050003|4505|广西壮族自治区|北海市'
      },
      {
        label: '中国工商银行北海分行长青路支行',
        value: '102623050505|4505|广西壮族自治区|北海市'
      },
      {
        label: '中国工商银行北海分行南珠支行',
        value: '102623051006|4505|广西壮族自治区|北海市'
      },
      {
        label: '中国工商银行北海分行北部湾支行',
        value: '102623052009|4505|广西壮族自治区|北海市'
      },
      {
        label: '中国工商银行北海分行云南路支行',
        value: '102623053001|4505|广西壮族自治区|北海市'
      },
      {
        label: '中国工商银行股份有限公司北海市北部湾西路支行',
        value: '102623053036|4505|广西壮族自治区|北海市'
      },
      {
        label: '中国工商银行股份有限公司北海市四川南路分理处',
        value: '102623053069|4505|广西壮族自治区|北海市'
      },
      {
        label: '中国工商银行北海分行',
        value: '102623055007|4505|广西壮族自治区|北海市'
      },
      {
        label: '中国工商银行北海市牡丹支行',
        value: '102623059993|4505|广西壮族自治区|北海市'
      },
      {
        label: '中国工商银行合浦县支行',
        value: '102623156003|4505|广西壮族自治区|北海市'
      },
      {
        label: '中国工商银行玉林分行营业部',
        value: '102624070108|4509|广西壮族自治区|玉林市'
      },
      {
        label: '中国工商银行股份有限公司玉林市福绵支行',
        value: '102624070116|4509|广西壮族自治区|玉林市'
      },
      {
        label: '中国工商银行玉林市东门支行',
        value: '102624070204|4509|广西壮族自治区|玉林市'
      },
      {
        label: '中国工商银行股份有限公司玉林市解放路支行',
        value: '102624070212|4509|广西壮族自治区|玉林市'
      },
      {
        label: '中国工商银行股份有限公司玉林市县西支行',
        value: '102624070229|4509|广西壮族自治区|玉林市'
      },
      {
        label: '中国工商银行股份有限公司玉林市城西分理处',
        value: '102624070307|4509|广西壮族自治区|玉林市'
      },
      {
        label: '工商银行武汉市二七路支行',
        value: '102521002156|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行武汉百步亭支行',
        value: '102521002201|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行股份有限公司武汉常青路支行',
        value: '102521002252|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行股份有限公司武汉新华饭店支行',
        value: '102521002269|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行股份有限公司武汉新华家园支行',
        value: '102521002277|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行股份有限公司武汉光谷支行',
        value: '102521002285|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行股份有限公司乐山五通桥支行',
        value: '102665038418|5111|四川省|乐山市'
      },
      {
        label: '中国工商银行股份有限公司乐山五通桥支行双江路分理处',
        value: '102665038426|5111|四川省|乐山市'
      },
      {
        label: '中国工商银行股份有限公司乐山沙湾支行',
        value: '102665038514|5111|四川省|乐山市'
      },
      {
        label: '中国工商银行股份有限公司乐山沙湾支行文豪路分理处',
        value: '102665038539|5111|四川省|乐山市'
      },
      {
        label: '中国工商银行股份有限公司乐山滨江支行',
        value: '102665039417|5111|四川省|乐山市'
      },
      {
        label: '中国工商银行股份有限公司乐山分行营业室',
        value: '102665039513|5111|四川省|乐山市'
      },
      {
        label: '中国工商银行股份有限公司仁寿大道支行',
        value: '102665100072|5111|四川省|乐山市'
      },
      {
        label: '中国工商银行股份有限公司仁寿支行城中分理处',
        value: '102665108616|5111|四川省|乐山市'
      },
      {
        label: '中国工商银行股份有限公司仁寿文林支行',
        value: '102665108632|5111|四川省|乐山市'
      },
      {
        label: '中国工商银行股份有限公司仁寿富加支行',
        value: '102665110022|5111|四川省|乐山市'
      },
      {
        label: '中国工商银行仁寿县支行',
        value: '102665139810|5111|四川省|乐山市'
      },
      {
        label: '中国工商银行股份有限公司眉山下大南街支行',
        value: '102665206570|5111|四川省|乐山市'
      },
      {
        label: '中国工商银行股份有限公司深圳翠竹北支行',
        value: '102584003126|4403|广东省|深圳市'
      },
      {
        label: '中国工商银行股份有限公司深圳碧湖支行',
        value: '102584003134|4403|广东省|深圳市'
      },
      {
        label: '中国工商银行股份有限公司深圳臣田支行',
        value: '102584003142|4403|广东省|深圳市'
      },
      {
        label: '中国工商银行股份有限公司深圳丽景支行',
        value: '102584003159|4403|广东省|深圳市'
      },
      {
        label: '中国工商银行股份有限公司深圳上南支行',
        value: '102584003167|4403|广东省|深圳市'
      },
      {
        label: '中国工商银行深圳南岭支行',
        value: '102584003183|4403|广东省|深圳市'
      },
      {
        label: '中国工商银行股份有限公司深圳李朗支行',
        value: '102584003191|4403|广东省|深圳市'
      },
      {
        label: '中国工商银行股份有限公司兴文支行',
        value: '102671951813|5115|四川省|宜宾市'
      },
      {
        label: '中国工商银行股份有限公司屏山支行',
        value: '102672151412|5115|四川省|宜宾市'
      },
      {
        label: '中国工商银行股份有限公司南充西区支行',
        value: '102673000012|5113|四川省|南充市'
      },
      {
        label: '中国工商银行股份有限公司南充嘉南支行',
        value: '102673000037|5113|四川省|南充市'
      },
      {
        label: '中国工商银行股份有限公司四川省南充大西门支行',
        value: '102673000061|5113|四川省|南充市'
      },
      {
        label: '中国工商银行股份有限公司四川省南充人民广场支行',
        value: '102673000115|5113|四川省|南充市'
      },
      {
        label: '中国工商银行股份有限公司南充分行城南分理处',
        value: '102673000211|5113|四川省|南充市'
      },
      {
        label: '中国工商银行股份有限公司南充分行业务处理中心',
        value: '102673053110|5113|四川省|南充市'
      },
      {
        label: '中国工商银行股份有限公司四川省南充茧市街支行',
        value: '102673053216|5113|四川省|南充市'
      },
      {
        label: '中国工商银行股份有限公司四川省南充解放街支行',
        value: '102673053224|5113|四川省|南充市'
      },
      {
        label: '中国工商银行股份有限公司韶关大宝山支行',
        value: '102582002297|4402|广东省|韶关市'
      },
      {
        label: '中国工商银行股份有限公司韶关韶大支行',
        value: '102582002301|4402|广东省|韶关市'
      },
      {
        label: '中国工商银行股份有限公司韶关碧桂园支行',
        value: '102582002310|4402|广东省|韶关市'
      },
      {
        label: '中国工商银行股份有限公司韶关财中支行',
        value: '102582002385|4402|广东省|韶关市'
      },
      {
        label: '中国工商银行股份有限公司韶关金沙支行',
        value: '102582002393|4402|广东省|韶关市'
      },
      {
        label: '中国工商银行股份有限公司韶关曲江支行',
        value: '102582006217|4402|广东省|韶关市'
      },
      {
        label: '中国工商银行股份有限公司韶关马坝支行',
        value: '102582006225|4402|广东省|韶关市'
      },
      {
        label: '中国工商银行股份有限公司韶关中山支行',
        value: '102582020112|4402|广东省|韶关市'
      },
      {
        label: '中国工商银行股份有限公司韶关西苑支行',
        value: '102582020129|4402|广东省|韶关市'
      },
      {
        label: '中国工商银行股份有限公司韶关新华支行',
        value: '102582020188|4402|广东省|韶关市'
      },
      {
        label: '中国工商银行股份有限公司韶关向阳支行',
        value: '102582020223|4402|广东省|韶关市'
      },
      {
        label: '中国工商银行股份有限公司韶关鞍山支行',
        value: '102582060753|4402|广东省|韶关市'
      },
      {
        label: '中国工商银行股份有限公司始兴支行',
        value: '102582204219|4402|广东省|韶关市'
      },
      {
        label: '中国工商银行股份有限公司南雄支行',
        value: '102582303215|4402|广东省|韶关市'
      },
      {
        label: '中国工商银行股份有限公司仁化支行',
        value: '102582401211|4402|广东省|韶关市'
      },
      {
        label: '中国工商银行股份有限公司仁化凡口支行',
        value: '102582401220|4402|广东省|韶关市'
      },
      {
        label: '中国工商银行股份有限公司乐昌支行',
        value: '102582508218|4402|广东省|韶关市'
      },
      {
        label: '中国工商银行股份有限公司乐昌坪石支行',
        value: '102582508226|4402|广东省|韶关市'
      },
      {
        label: '中国工商银行股份有限公司翁源支行',
        value: '102582605218|4402|广东省|韶关市'
      },
      {
        label: '中国工商银行股份有限公司乳源支行',
        value: '102582709212|4402|广东省|韶关市'
      },
      {
        label: '中国工商银行股份有限公司新丰支行',
        value: '102582807218|4402|广东省|韶关市'
      },
      {
        label: '中国工商银行深圳市分行',
        value: '102584000002|4403|广东省|深圳市'
      },
      {
        label: '中国工商银行股份有限公司深圳莲花北支行',
        value: '102584001013|4403|广东省|深圳市'
      },
      {
        label: '中国工商银行股份有限公司深圳竹子林支行',
        value: '102584001030|4403|广东省|深圳市'
      },
      {
        label: '中国工商银行股份有限公司深圳上步支行',
        value: '102584002016|4403|广东省|深圳市'
      },
      {
        label: '中国工商银行股份有限公司深圳蛇口支行',
        value: '102584002024|4403|广东省|深圳市'
      },
      {
        label: '中国工商银行股份有限公司深圳南山支行',
        value: '102584002032|4403|广东省|深圳市'
      },
      {
        label: '中国工商银行股份有限公司张掖西区支行',
        value: '102827031314|6207|甘肃省|张掖市'
      },
      {
        label: '中国工商银行股份有限公司张掖市东街支行',
        value: '102827031347|6207|甘肃省|张掖市'
      },
      {
        label: '中国工商银行股份有限公司张掖肃南支行',
        value: '102827204255|6207|甘肃省|张掖市'
      },
      {
        label: '中国工商银行股份有限公司民乐支行',
        value: '102827300093|6207|甘肃省|张掖市'
      },
      {
        label: '中国工商银行股份有限公司临泽支行',
        value: '102827400109|6207|甘肃省|张掖市'
      },
      {
        label: '中国工商银行股份有限公司高台支行',
        value: '102827500118|6207|甘肃省|张掖市'
      },
      {
        label: '中国工商银行股份有限公司山丹支行',
        value: '102827600088|6207|甘肃省|张掖市'
      },
      {
        label: '中国工商银行武威市分行业务处理中心',
        value: '102828000018|6206|甘肃省|武威市'
      },
      {
        label: '中国工商银行股份有限公司武威分行武南支行',
        value: '102828000026|6206|甘肃省|武威市'
      },
      {
        label: '中国工商银行股份有限公司武威分行',
        value: '102828000059|6206|甘肃省|武威市'
      },
      {
        label: '中国工商银行股份有限公司武威分行凉州支行',
        value: '102828000067|6206|甘肃省|武威市'
      },
      {
        label: '中国工商银行股份有限公司武威分行东关支行',
        value: '102828000075|6206|甘肃省|武威市'
      },
      {
        label: '中国工商银行股份有限公司凉山胜利南路支行',
        value: '102684063272|5134|四川省|凉山彝族自治州'
      },
      {
        label: '中国工商银行铜川分行黄堡支行',
        value: '102792020034|6102|陕西省|铜川市'
      },
      {
        label: '中国工商银行铜川分行王益支行',
        value: '102792020042|6102|陕西省|铜川市'
      },
      {
        label: '中国工商银行铜川分行业务处理中心',
        value: '102792025006|6102|陕西省|铜川市'
      },
      {
        label: '工行铜川长虹路支行',
        value: '102792025022|6102|陕西省|铜川市'
      },
      {
        label: '工行铜川分行新区支行',
        value: '102792025047|6102|陕西省|铜川市'
      },
      {
        label: '工行铜川分行川口支行',
        value: '102792025055|6102|陕西省|铜川市'
      },
      {
        label: '中国工商银行铜川分行五一路分理处',
        value: '102792025071|6102|陕西省|铜川市'
      },
      {
        label: '工行铜川崔家沟支行',
        value: '102792025098|6102|陕西省|铜川市'
      },
      {
        label: '工行陕西省铜川朝阳路支行',
        value: '102792025102|6102|陕西省|铜川市'
      },
      {
        label: '中国工商银行铜川支行',
        value: '102792026009|6102|陕西省|铜川市'
      },
      {
        label: '中国工商银行宝鸡渭滨支行',
        value: '102793030017|6103|陕西省|宝鸡市'
      },
      {
        label: '工行宝鸡经二路支行',
        value: '102793030025|6103|陕西省|宝鸡市'
      },
      {
        label: '中国工商银行宝鸡宝钛支行',
        value: '102793030033|6103|陕西省|宝鸡市'
      },
      {
        label: '中国工商银行宝鸡福临堡支行',
        value: '102793030041|6103|陕西省|宝鸡市'
      },
      {
        label: '中国工商银行宝鸡金台支行',
        value: '102793030050|6103|陕西省|宝鸡市'
      },
      {
        label: '中国工商银行宝鸡马营支行',
        value: '102793030068|6103|陕西省|宝鸡市'
      },
      {
        label: '中国工商银行宝鸡中山支行',
        value: '102793030076|6103|陕西省|宝鸡市'
      },
      {
        label: '中国工商银行宝鸡清姜支行',
        value: '102793030084|6103|陕西省|宝鸡市'
      },
      {
        label: '中国工商银行宝鸡姜谭支行',
        value: '102793030092|6103|陕西省|宝鸡市'
      },
      {
        label: '中国工商银行宝鸡姜城支行',
        value: '102793030105|6103|陕西省|宝鸡市'
      },
      {
        label: '中国工商银行宝鸡斗鸡支行',
        value: '102793030113|6103|陕西省|宝鸡市'
      },
      {
        label: '中国工商银行宝鸡陈仓区支行',
        value: '102793030130|6103|陕西省|宝鸡市'
      },
      {
        label: '中国工商银行股份有限公司宝鸡陈仓区千渭支行',
        value: '102793030156|6103|陕西省|宝鸡市'
      },
      {
        label: '工行陕西省宝鸡陈仓区虢镇支行',
        value: '102793030164|6103|陕西省|宝鸡市'
      },
      {
        label: '中国工商银行宝鸡大庆路支行',
        value: '102793030201|6103|陕西省|宝鸡市'
      },
      {
        label: '中国工商银行宝鸡高新技术产业开发区支行',
        value: '102793030349|6103|陕西省|宝鸡市'
      },
      {
        label: '工行宝鸡金渭支行',
        value: '102793030357|6103|陕西省|宝鸡市'
      },
      {
        label: '中国工商银行宝鸡中山西路支行',
        value: '102793030365|6103|陕西省|宝鸡市'
      },
      {
        label: '工行陕西省宝鸡宝平路支行',
        value: '102793030373|6103|陕西省|宝鸡市'
      },
      {
        label: '中国工商银行宝鸡宝光路支行',
        value: '102793030381|6103|陕西省|宝鸡市'
      },
      {
        label: '工行陕西省宝鸡新建路支行',
        value: '102793030390|6103|陕西省|宝鸡市'
      },
      {
        label: '中国工商银行宝鸡红旗路支行',
        value: '102793030404|6103|陕西省|宝鸡市'
      },
      {
        label: '中国工商银行宝鸡迎宾支行',
        value: '102793030412|6103|陕西省|宝鸡市'
      },
      {
        label: '工行陕西省宝鸡宝烟路支行',
        value: '102793030429|6103|陕西省|宝鸡市'
      },
      {
        label: '中国工商银行宝鸡宝桥支行',
        value: '102793030437|6103|陕西省|宝鸡市'
      },
      {
        label: '中国工商银行宝鸡石油钢管厂支行',
        value: '102793030445|6103|陕西省|宝鸡市'
      },
      {
        label: '工行陕西省宝鸡金台大道支行',
        value: '102793030470|6103|陕西省|宝鸡市'
      },
      {
        label: '中国工商银行贵州省遵义市老城支行',
        value: '102703000047|5203|贵州省|遵义市'
      },
      {
        label: '中国工商银行股份有限公司遵义汇川支行',
        value: '102703000055|5203|贵州省|遵义市'
      },
      {
        label: '中国工商银行遵义分行京华支行',
        value: '102703000080|5203|贵州省|遵义市'
      },
      {
        label: '中国工商银行遵义分行凯山支行',
        value: '102703000098|5203|贵州省|遵义市'
      },
      {
        label: '中国工商银行贵州省遵义分行碧云支行',
        value: '102703000119|5203|贵州省|遵义市'
      },
      {
        label: '中国工商银行股份有限公司遵义沙坝支行',
        value: '102703000330|5203|贵州省|遵义市'
      },
      {
        label: '中国工商银行股份有限公司遵义湘江支行',
        value: '102703000389|5203|贵州省|遵义市'
      },
      {
        label: '中国工商银行股份有限公司遵义南京路支行',
        value: '102703000397|5203|贵州省|遵义市'
      },
      {
        label: '中国工商银行股份有限公司遵义高桥支行',
        value: '102703000401|5203|贵州省|遵义市'
      },
      {
        label: '中国工商银行股份有限公司遵义医学院支行',
        value: '102703002237|5203|贵州省|遵义市'
      },
      {
        label: '中国工商银行股份有限公司遵义珠海支行',
        value: '102703002245|5203|贵州省|遵义市'
      },
      {
        label: '中国工商银行贵州省遵义分行会计业务处理中心',
        value: '102703005000|5203|贵州省|遵义市'
      },
      {
        label: '中国工商银行股份有限公司遵义南新支行',
        value: '102703005042|5203|贵州省|遵义市'
      },
      {
        label: '中国工商银行贵州省遵义市八七支行',
        value: '102703005059|5203|贵州省|遵义市'
      },
      {
        label: '中国工商银行股份有限公司遵义播州支行',
        value: '102703200137|5203|贵州省|遵义市'
      },
      {
        label: '中国工商银行成都市锦江支行营业室',
        value: '102651090508|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都荷花池支行',
        value: '102651092001|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都高新区支行新义路分理处',
        value: '102651092108|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司凉山长安西路支行',
        value: '102684063289|5134|四川省|凉山彝族自治州'
      },
      {
        label: '中国工商银行股份有限公司四川省会理支行',
        value: '102684563306|5134|四川省|凉山彝族自治州'
      },
      {
        label: '中国工商银行股份有限公司会东支行',
        value: '102684663346|5134|四川省|凉山彝族自治州'
      },
      {
        label: '中国工商银行股份有限公司宣汉城西支行',
        value: '102675301367|5117|四川省|达州市'
      },
      {
        label: '工行宁夏银川贺兰支行',
        value: '102871000103|6401|宁夏回族自治区|银川市'
      },
      {
        label: '工行宁夏银川石油城支行',
        value: '102871000111|6401|宁夏回族自治区|银川市'
      },
      {
        label: '工行宁夏银川南郊支行',
        value: '102871000138|6401|宁夏回族自治区|银川市'
      },
      {
        label: '工行宁夏银川中山北街支行',
        value: '102871000146|6401|宁夏回族自治区|银川市'
      },
      {
        label: '中国工商银行股份有限公司鄯善支行',
        value: '102883200016|6521|新疆维吾尔自治区|吐鲁番地区'
      },
      {
        label: '中国工商银行股份有限公司鄯善东大街支行',
        value: '102883200024|6521|新疆维吾尔自治区|吐鲁番地区'
      },
      {
        label: '中国工商银行股份有限公司托克逊支行',
        value: '102883300017|6521|新疆维吾尔自治区|吐鲁番地区'
      },
      {
        label: '中国工商银行股份有限公司哈密分行业务处理中心',
        value: '102884000016|6522|新疆维吾尔自治区|哈密地区'
      },
      {
        label: '中国工商银行股份有限公司哈密石油支行',
        value: '102884000032|6522|新疆维吾尔自治区|哈密地区'
      },
      {
        label: '中国工商银行股份有限公司哈密分行',
        value: '102884000049|6522|新疆维吾尔自治区|哈密地区'
      },
      {
        label: '中国工商银行股份有限公司哈密火车站支行',
        value: '102884000065|6522|新疆维吾尔自治区|哈密地区'
      },
      {
        label: '中国工商银行股份有限公司成都永丰路支行',
        value: '102651092204|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都华达支行',
        value: '102651092403|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司成都金牛支行一品天下分理处',
        value: '102651092702|5101|四川省|成都市'
      },
      {
        label: '中国工商银行合山市支行',
        value: '102615141702|4513|广西壮族自治区|来宾市'
      },
      {
        label: '中国工商银行鹿寨县支行',
        value: '102615241906|4502|广西壮族自治区|柳州市'
      },
      {
        label: '中国工商银行股份有限公司象州县支行',
        value: '102615347667|4513|广西壮族自治区|来宾市'
      },
      {
        label: '中国工商银行股份有限公司武宣支行',
        value: '102615442002|4513|广西壮族自治区|来宾市'
      },
      {
        label: '中国工商银行股份有限公司来宾市兴宾支行',
        value: '102615541508|4513|广西壮族自治区|来宾市'
      },
      {
        label: '中国工商银行股份有限公司凤凰工业园区支行',
        value: '102615541604|4513|广西壮族自治区|来宾市'
      },
      {
        label: '中国工商银行股份有限公司来宾分行',
        value: '102615548503|4513|广西壮族自治区|来宾市'
      },
      {
        label: '中国工商银行融安县支行',
        value: '102615641808|4502|广西壮族自治区|柳州市'
      },
      {
        label: '中国工商银行股份有限公司三江县支行',
        value: '102615703671|4502|广西壮族自治区|柳州市'
      },
      {
        label: '中国工商银行股份有限公司融水县支行',
        value: '102615803658|4502|广西壮族自治区|柳州市'
      },
      {
        label: '中国工商银行股份有限公司忻城县支行',
        value: '102616141201|4513|广西壮族自治区|来宾市'
      },
      {
        label: '中国工商银行桂林市解放东路支行',
        value: '102617020014|4503|广西壮族自治区|桂林市'
      },
      {
        label: '中国工商银行桂林分行解西分理处',
        value: '102617020039|4503|广西壮族自治区|桂林市'
      },
      {
        label: '中国工商银行桂林市城北支行',
        value: '102617020313|4503|广西壮族自治区|桂林市'
      },
      {
        label: '中国工商银行桂林市群众分理处',
        value: '102617020321|4503|广西壮族自治区|桂林市'
      },
      {
        label: '中国工商银行桂林市阳桥支行',
        value: '102617020612|4503|广西壮族自治区|桂林市'
      },
      {
        label: '中国工商银行桂林市信义分理处',
        value: '102617020629|4503|广西壮族自治区|桂林市'
      },
      {
        label: '中国工商银行桂林市西城支行',
        value: '102617020918|4503|广西壮族自治区|桂林市'
      },
      {
        label: '中国工商银行桂林市翠竹路分理处',
        value: '102617020926|4503|广西壮族自治区|桂林市'
      },
      {
        label: '中国工商银行桂林市平山支行',
        value: '102617021210|4503|广西壮族自治区|桂林市'
      },
      {
        label: '中国工商银行桂林市高新技术开发区支行',
        value: '102617021515|4503|广西壮族自治区|桂林市'
      },
      {
        label: '中国工商银行桂林市漓江分理处',
        value: '102617021531|4503|广西壮族自治区|桂林市'
      },
      {
        label: '中国工商银行桂林市屏风分理处',
        value: '102617021566|4503|广西壮族自治区|桂林市'
      },
      {
        label: '中国工商银行桂林分行',
        value: '102617025000|4503|广西壮族自治区|桂林市'
      },
      {
        label: '中国工商银行股份有限公司桂林市桂湖支行',
        value: '102617026027|4503|广西壮族自治区|桂林市'
      },
      {
        label: '中国工商银行股份有限公司桂林市民族路分理处',
        value: '102617026570|4503|广西壮族自治区|桂林市'
      },
      {
        label: '中国工商银行股份有限公司桂林市西凤支行',
        value: '102617026588|4503|广西壮族自治区|桂林市'
      },
      {
        label: '中国工商银行股份有限公司桂林市东江支行',
        value: '102617026762|4503|广西壮族自治区|桂林市'
      },
      {
        label: '中国工商银行股份有限公司桂林市桂大路支行',
        value: '102617026779|4503|广西壮族自治区|桂林市'
      },
      {
        label: '中国工商银行武汉市蔡甸区支行',
        value: '102521000556|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行延安市安塞县支行',
        value: '102804590150|6106|陕西省|延安市'
      },
      {
        label: '中国工商银行延安市志丹县支行',
        value: '102804690126|6106|陕西省|延安市'
      },
      {
        label: '工行陕西省延安吴起开发区支行',
        value: '102804700018|6106|陕西省|延安市'
      },
      {
        label: '工行陕西省延安吴起支行',
        value: '102804790131|6106|陕西省|延安市'
      },
      {
        label: '中国工商银行延安市甘泉分理处',
        value: '102804890055|6106|陕西省|延安市'
      },
      {
        label: '中国工商银行股份有限公司和田分行营业部',
        value: '102896038107|6532|新疆维吾尔自治区|和田地区'
      },
      {
        label: '中国工商银行股份有限公司和田北京西路支行',
        value: '102896038115|6532|新疆维吾尔自治区|和田地区'
      },
      {
        label: '中国工商银行股份有限公司和田市东方红支行',
        value: '102896038123|6532|新疆维吾尔自治区|和田地区'
      },
      {
        label: '中国工商银行股份有限公司和田迎宾路支行',
        value: '102896038131|6532|新疆维吾尔自治区|和田地区'
      },
      {
        label: '中国工商银行股份有限公司墨玉支行',
        value: '102896300014|6532|新疆维吾尔自治区|和田地区'
      },
      {
        label: '中国工商银行股份有限公司洛浦支行',
        value: '102896500016|6532|新疆维吾尔自治区|和田地区'
      },
      {
        label: '中国工商银行股份有限公司伊犁哈萨克自治州分行业务处理中心',
        value: '102898002109|6540|新疆维吾尔自治区|伊犁哈萨克自治州'
      },
      {
        label: '中国工商银行股份有限公司伊犁哈萨克自治州分行',
        value: '102898002205|6540|新疆维吾尔自治区|伊犁哈萨克自治州'
      },
      {
        label: '中国工商银行股份有限公司伊宁解放路支行',
        value: '102898002213|6540|新疆维吾尔自治区|伊犁哈萨克自治州'
      },
      {
        label: '中国工商银行股份有限公司伊宁伊犁河路支行',
        value: '102898002230|6540|新疆维吾尔自治区|伊犁哈萨克自治州'
      },
      {
        label: '中国工商银行股份有限公司伊宁新华中路支行',
        value: '102898002272|6540|新疆维吾尔自治区|伊犁哈萨克自治州'
      },
      {
        label: '中国工商银行股份有限公司伊宁城建支行',
        value: '102898002297|6540|新疆维吾尔自治区|伊犁哈萨克自治州'
      },
      {
        label: '中国工商银行股份有限公司伊宁西桥支行',
        value: '102898002301|6540|新疆维吾尔自治区|伊犁哈萨克自治州'
      },
      {
        label: '中国工商银行股份有限公司伊宁阿合买提江街支行',
        value: '102898002310|6540|新疆维吾尔自治区|伊犁哈萨克自治州'
      },
      {
        label: '中国工商银行股份有限公司伊宁北京路支行',
        value: '102898002328|6540|新疆维吾尔自治区|伊犁哈萨克自治州'
      },
      {
        label: '中国工商银行股份有限公司伊宁新市区分理处',
        value: '102898002385|6540|新疆维吾尔自治区|伊犁哈萨克自治州'
      },
      {
        label: '中国工商银行股份有限公司伊宁合作区支行',
        value: '102898002393|6540|新疆维吾尔自治区|伊犁哈萨克自治州'
      },
      {
        label: '中国工商银行股份有限公司伊宁斯大林西街支行',
        value: '102898002424|6540|新疆维吾尔自治区|伊犁哈萨克自治州'
      },
      {
        label: '中国工商银行股份有限公司奎屯支行',
        value: '102898100016|6540|新疆维吾尔自治区|伊犁哈萨克自治州'
      },
      {
        label: '中国工商银行股份有限公司奎屯团结南路支行',
        value: '102898100065|6540|新疆维吾尔自治区|伊犁哈萨克自治州'
      },
      {
        label: '中国工商银行股份有限公司奎屯红旗支行',
        value: '102898100073|6540|新疆维吾尔自治区|伊犁哈萨克自治州'
      },
      {
        label: '中国工商银行股份有限公司奎屯乌鲁木齐东路支行',
        value: '102898100104|6540|新疆维吾尔自治区|伊犁哈萨克自治州'
      },
      {
        label: '中国工商银行股份有限公司平南县支行',
        value: '102624471201|4508|广西壮族自治区|贵港市'
      },
      {
        label: '中国工商银行容县支行',
        value: '102624570509|4509|广西壮族自治区|玉林市'
      },
      {
        label: '中国工商银行北流市支行',
        value: '102624670602|4509|广西壮族自治区|玉林市'
      },
      {
        label: '中国工商银行陆川县支行',
        value: '102624770700|4509|广西壮族自治区|玉林市'
      },
      {
        label: '中国工商银行博白县支行',
        value: '102624870801|4509|广西壮族自治区|玉林市'
      },
      {
        label: '中国工商银行百色市右江支行',
        value: '102626160013|4510|广西壮族自治区|百色市'
      },
      {
        label: '中国工商银行股份有限公司百色市东笋支行',
        value: '102626160030|4510|广西壮族自治区|百色市'
      },
      {
        label: '中国工商银行百色分行营业厅',
        value: '102626160056|4510|广西壮族自治区|百色市'
      },
      {
        label: '中国工商银行百色分行会计业务处理中心',
        value: '102626160089|4510|广西壮族自治区|百色市'
      },
      {
        label: '中国工商银行股份有限公司百色市向阳支行',
        value: '102626161024|4510|广西壮族自治区|百色市'
      },
      {
        label: '中国工商银行田阳县支行',
        value: '102626260374|4510|广西壮族自治区|百色市'
      },
      {
        label: '中国工商银行股份有限公司田东县庆平支行',
        value: '102626360150|4510|广西壮族自治区|百色市'
      },
      {
        label: '中国工商银行股份有限公司田东县新州支行',
        value: '102626360168|4510|广西壮族自治区|百色市'
      },
      {
        label: '中国工商银行股份有限公司田东县油路支行',
        value: '102626360184|4510|广西壮族自治区|百色市'
      },
      {
        label: '中国工商银行田东县支行',
        value: '102626360369|4510|广西壮族自治区|百色市'
      },
      {
        label: '中国工商银行股份有限公司贵阳枣山支行',
        value: '102701000344|5201|贵州省|贵阳市'
      },
      {
        label: '中国工商银行股份有限公司贵阳贵开路支行',
        value: '102701000352|5201|贵州省|贵阳市'
      },
      {
        label: '中国工商银行贵阳市修文县支行',
        value: '102701000377|5201|贵州省|贵阳市'
      },
      {
        label: '中国工商银行清镇市支行',
        value: '102701000393|5201|贵州省|贵阳市'
      },
      {
        label: '中国工商银行股份有限公司贵州省贵阳开阳支行',
        value: '102701000449|5201|贵州省|贵阳市'
      },
      {
        label: '中国工商银行股份有限公司贵州省贵阳开阳支行开磷分理处',
        value: '102701000457|5201|贵州省|贵阳市'
      },
      {
        label: '中国工商银行贵阳市息烽县支行',
        value: '102701000465|5201|贵州省|贵阳市'
      },
      {
        label: '中国工商银行贵阳市富水支行',
        value: '102701000473|5201|贵州省|贵阳市'
      },
      {
        label: '中国工商银行股份有限公司清镇云岭支行',
        value: '102701000537|5201|贵州省|贵阳市'
      },
      {
        label: '中国工商银行股份有限公司贵阳朝阳洞路支行',
        value: '102701000561|5201|贵州省|贵阳市'
      },
      {
        label: '中国工商银行股份有限公司贵阳水口寺支行',
        value: '102701000588|5201|贵州省|贵阳市'
      },
      {
        label: '中国工商银行股份有限公司贵阳建筑支行',
        value: '102701000596|5201|贵州省|贵阳市'
      },
      {
        label: '中国工商银行股份有限公司贵阳南横街支行',
        value: '102701000607|5201|贵州省|贵阳市'
      },
      {
        label: '中国工商银行股份有限公司贵州省贵阳中山东路支行',
        value: '102701000615|5201|贵州省|贵阳市'
      },
      {
        label: '中国工商银行股份有限公司贵阳指月支行',
        value: '102701000640|5201|贵州省|贵阳市'
      },
      {
        label: '中国工商银行股份有限公司贵阳大十字支行',
        value: '102701000666|5201|贵州省|贵阳市'
      },
      {
        label: '中国工商银行股份有限公司贵阳金阳支行',
        value: '102701000674|5201|贵州省|贵阳市'
      },
      {
        label: '中国工商银行股份有限公司贵阳双龙航空港分理处',
        value: '102701000699|5201|贵州省|贵阳市'
      },
      {
        label: '中国工商银行股份有限公司贵阳毓秀路支行',
        value: '102701000711|5201|贵州省|贵阳市'
      },
      {
        label: '中国工商银行股份有限公司贵阳军区支行',
        value: '102701000720|5201|贵州省|贵阳市'
      },
      {
        label: '中国工商银行会同县支行',
        value: '102568101160|4312|湖南省|怀化市'
      },
      {
        label: '中国工商银行股份有限公司长葛颖川大道支行',
        value: '102503172600|4110|河南省|许昌市'
      },
      {
        label: '中国工商银行股份有限公司鄢陵支行',
        value: '102503302700|4110|河南省|许昌市'
      },
      {
        label: '中国工商银行股份有限公司禹州支行',
        value: '102503402501|4110|河南省|许昌市'
      },
      {
        label: '中国工商银行股份有限公司禹州钧州大街支行',
        value: '102503412503|4110|河南省|许昌市'
      },
      {
        label: '中国工商银行股份有限公司禹州禹王大道支行',
        value: '102503425010|4110|河南省|许昌市'
      },
      {
        label: '中国工商银行景德镇市昌河支行',
        value: '102422003059|3602|江西省|景德镇市'
      },
      {
        label: '中国工商银行景德镇市西市区支行',
        value: '102422003067|3602|江西省|景德镇市'
      },
      {
        label: '中国工商银行股份有限公司戴家弄支行',
        value: '102422010074|3602|江西省|景德镇市'
      },
      {
        label: '中国工商银行景德镇市分行广场分理处',
        value: '102422010099|3602|江西省|景德镇市'
      },
      {
        label: '中国工商银行景德镇市602所支行',
        value: '102422010162|3602|江西省|景德镇市'
      },
      {
        label: '中国工商银行景德镇市分行为民分理处',
        value: '102422010195|3602|江西省|景德镇市'
      },
      {
        label: '中国工商银行景德镇市分行浮梁支行',
        value: '102422010200|3602|江西省|景德镇市'
      },
      {
        label: '中国工商银行股份有限公司乐平支行新北大道分理处',
        value: '102422100051|3602|江西省|景德镇市'
      },
      {
        label: '中国工商银行股份有限公司乐平支行登高山分理处',
        value: '102422100060|3602|江西省|景德镇市'
      },
      {
        label: '中国工商银行乐平支行',
        value: '102422103079|3602|江西省|景德镇市'
      },
      {
        label: '中国工商银行股份有限公司萍乡八一支行',
        value: '102423000011|3603|江西省|萍乡市'
      },
      {
        label: '中国工商银行股份有限公司萍乡萍矿支行',
        value: '102423000038|3603|江西省|萍乡市'
      },
      {
        label: '中国工商银行股份有限公司萍乡商城支行',
        value: '102423000183|3603|江西省|萍乡市'
      },
      {
        label: '中国工商银行股份有限公司萍乡开发区支行',
        value: '102423000335|3603|江西省|萍乡市'
      },
      {
        label: '中国工商银行股份有限公司萍乡楚萍支行',
        value: '102423001401|3603|江西省|萍乡市'
      },
      {
        label: '中国工商银行股份有限公司萍乡分行',
        value: '102423004004|3603|江西省|萍乡市'
      },
      {
        label: '中国工商银行股份有限公司萍乡安源支行',
        value: '102423004012|3603|江西省|萍乡市'
      },
      {
        label: '中国工商银行股份有限公司萍乡正大支行',
        value: '102423004045|3603|江西省|萍乡市'
      },
      {
        label: '中国工商银行股份有限公司萍乡安源新区支行',
        value: '102423004053|3603|江西省|萍乡市'
      },
      {
        label: '中国工商银行股份有限公司萍乡分行高坑支行',
        value: '102423004061|3603|江西省|萍乡市'
      },
      {
        label: '中国工商银行股份有限公司萍乡湘东支行',
        value: '102423004070|3603|江西省|萍乡市'
      },
      {
        label: '中国工商银行股份有限公司萍乡峡山口支行',
        value: '102423004088|3603|江西省|萍乡市'
      },
      {
        label: '中国工商银行股份有限公司萍乡政和支行',
        value: '102423004096|3603|江西省|萍乡市'
      },
      {
        label: '中国工商银行股份有限公司巴中巴州支行',
        value: '102675859215|5119|四川省|巴中市'
      },
      {
        label: '中国工商银行股份有限公司巴中九洲支行',
        value: '102675859311|5119|四川省|巴中市'
      },
      {
        label: '中国工商银行股份有限公司四川省巴中分行',
        value: '102675859717|5119|四川省|巴中市'
      },
      {
        label: '中国工商银行股份有限公司巴中回风支行',
        value: '102675859813|5119|四川省|巴中市'
      },
      {
        label: '中国工商银行股份有限公司西安长安区神舟三路支行',
        value: '102791000435|6101|陕西省|西安市'
      },
      {
        label: '中国工商银行西安市周至县支行',
        value: '102791000443|6101|陕西省|西安市'
      },
      {
        label: '中国工商银行西安市户县支行',
        value: '102791000451|6101|陕西省|西安市'
      },
      {
        label: '中国工商银行西安市临潼区支行',
        value: '102791000478|6101|陕西省|西安市'
      },
      {
        label: '工行陕西省西安蓝田县支行',
        value: '102791000486|6101|陕西省|西安市'
      },
      {
        label: '中国工商银行西安市高新技术开发区支行',
        value: '102791000494|6101|陕西省|西安市'
      },
      {
        label: '中国工商银行西安市未央支行',
        value: '102791000525|6101|陕西省|西安市'
      },
      {
        label: '中国工商银行西安徐家湾支行',
        value: '102791000533|6101|陕西省|西安市'
      },
      {
        label: '中国工商银行西安公园路支行',
        value: '102791000550|6101|陕西省|西安市'
      },
      {
        label: '中国工商银行股份有限公司西安曲江新区新开门支行',
        value: '102791000568|6101|陕西省|西安市'
      },
      {
        label: '中国工商银行西安兴庆路支行',
        value: '102791000576|6101|陕西省|西安市'
      },
      {
        label: '中国工商银行股份有限公司西安咸宁西路支行',
        value: '102791000584|6101|陕西省|西安市'
      },
      {
        label: '中国工商银行股份有限公司西安兴庆路南段支行',
        value: '102791000592|6101|陕西省|西安市'
      },
      {
        label: '中国工商银行股份有限公司西安凤城二路支行',
        value: '102791000605|6101|陕西省|西安市'
      },
      {
        label: '中国工商银行股份有限公司西安长庆支行',
        value: '102791000613|6101|陕西省|西安市'
      },
      {
        label: '工行陕西省西安白桦林居支行',
        value: '102791000621|6101|陕西省|西安市'
      },
      {
        label: '中国工商银行西安市高陵县支行泾河工业园区分理处',
        value: '102791000630|6101|陕西省|西安市'
      },
      {
        label: '工行陕西省西安曲江支行',
        value: '102791000689|6101|陕西省|西安市'
      },
      {
        label: '中国工商银行股份有限公司西安芙蓉西路支行',
        value: '102791002588|6101|陕西省|西安市'
      },
      {
        label: '中国工商银行西安高新路支行',
        value: '102791005038|6101|陕西省|西安市'
      },
      {
        label: '中国工商银行西安市朱雀大街支行',
        value: '102791005134|6101|陕西省|西安市'
      },
      {
        label: '中国工商银行陕西分行营业部',
        value: '102791005183|6101|陕西省|西安市'
      },
      {
        label: '中国工商银行陕西分行营业部核算中心',
        value: '102791009965|6101|陕西省|西安市'
      },
      {
        label: '中国工商银行陕西省分行清算中心',
        value: '102791009990|6101|陕西省|西安市'
      },
      {
        label: '中国工商银行股份有限公司票据营业部西安分部',
        value: '102791010060|6101|陕西省|西安市'
      },
      {
        label: '工行陕西省西安锦业路支行',
        value: '102791011634|6101|陕西省|西安市'
      },
      {
        label: '中国工商银行渭南富平县庄里支行',
        value: '102798250250|6105|陕西省|渭南市'
      },
      {
        label: '工行陕西省谓南富平县城西支行',
        value: '102798250372|6105|陕西省|渭南市'
      },
      {
        label: '中国工商银行汉中分行中山街支行',
        value: '102799060010|6107|陕西省|汉中市'
      },
      {
        label: '工行汉中北团结街支行',
        value: '102799060028|6107|陕西省|汉中市'
      },
      {
        label: '工行陕西省汉中高新支行',
        value: '102799060044|6107|陕西省|汉中市'
      },
      {
        label: '工行汉中人民路支行',
        value: '102799060253|6107|陕西省|汉中市'
      },
      {
        label: '中国工商银行汉中市汉台区汉江路分理处',
        value: '102799060288|6107|陕西省|汉中市'
      },
      {
        label: '中国工商银行股份有限公司达州宣汉支行',
        value: '102675358011|5117|四川省|达州市'
      },
      {
        label: '中国工商银行股份有限公司四川省达州开江支行',
        value: '102675458519|5117|四川省|达州市'
      },
      {
        label: '中国工商银行股份有限公司万源北门支行',
        value: '102675501428|5117|四川省|达州市'
      },
      {
        label: '中国工商银行股份有限公司达州分行万源支行',
        value: '102675558215|5117|四川省|达州市'
      },
      {
        label: '中国工商银行通江县支行',
        value: '102675659414|5119|四川省|巴中市'
      },
      {
        label: '中国工商银行股份有限公司通江翰林院支行',
        value: '102675659422|5119|四川省|巴中市'
      },
      {
        label: '中国工商银行股份有限公司南江县光雾山大道支行',
        value: '102675703014|5119|四川省|巴中市'
      },
      {
        label: '中国工商银行南江县支行',
        value: '102675759511|5119|四川省|巴中市'
      },
      {
        label: '中国工商银行股份有限公司巴中恩阳支行',
        value: '102675800014|5119|四川省|巴中市'
      },
      {
        label: '中国工商银行股份有限公司巴中金桥支行',
        value: '102675800080|5119|四川省|巴中市'
      },
      {
        label: '中国工商银行巴中市分行业务处理中心',
        value: '102675859112|5119|四川省|巴中市'
      },
      {
        label: '中国工商银行云南玉溪分行红塔山支行',
        value: '102741017030|5304|云南省|玉溪市'
      },
      {
        label: '中国工商银行云南玉溪分行牡丹支行',
        value: '102741017048|5304|云南省|玉溪市'
      },
      {
        label: '中国工商银行云南玉溪分行北市区分理处',
        value: '102741017056|5304|云南省|玉溪市'
      },
      {
        label: '中国工商银行云南玉溪分行七星支行',
        value: '102741017064|5304|云南省|玉溪市'
      },
      {
        label: '中国工商银行云南玉溪分行江川县支行',
        value: '102741217070|5304|云南省|玉溪市'
      },
      {
        label: '中国工商银行云南玉溪分行澄江县支行',
        value: '102741317086|5304|云南省|玉溪市'
      },
      {
        label: '中国工商银行云南玉溪分行通海县支行',
        value: '102741417098|5304|云南省|玉溪市'
      },
      {
        label: '中国工商银行云南玉溪分行通海支行滨湖分理处',
        value: '102741470019|5304|云南省|玉溪市'
      },
      {
        label: '中国工商银行红河分行个旧市人民路支行',
        value: '102743000041|5325|云南省|红河哈尼族彝族自治州'
      },
      {
        label: '中国工商银行云南红河分行个旧市金湖西路支行',
        value: '102743000076|5325|云南省|红河哈尼族彝族自治州'
      },
      {
        label: '中国工商银行红河分行个旧市五一路支行',
        value: '102743002772|5325|云南省|红河哈尼族彝族自治州'
      },
      {
        label: '中国工商银行云南红河个旧支行',
        value: '102743007024|5325|云南省|红河哈尼族彝族自治州'
      },
      {
        label: '中国工商银行云南红河分行个旧市红旗支行',
        value: '102743007032|5325|云南省|红河哈尼族彝族自治州'
      },
      {
        label: '中国工商银行云南红河分行个旧鸡街支行',
        value: '102743007049|5325|云南省|红河哈尼族彝族自治州'
      },
      {
        label: '中国工商银行云南红河分行个旧市大屯支行',
        value: '102743007057|5325|云南省|红河哈尼族彝族自治州'
      },
      {
        label: '中国工商银行云南红河分行开远市东桥支行',
        value: '102743207038|5325|云南省|红河哈尼族彝族自治州'
      },
      {
        label: '中国工商银行云南红河分行开远智源路支行',
        value: '102743207054|5325|云南省|红河哈尼族彝族自治州'
      },
      {
        label: '中国工商银行云南红河分行开远市支行',
        value: '102743207062|5325|云南省|红河哈尼族彝族自治州'
      },
      {
        label: '中国工商银行云南红河分行开远市景山路中段支行',
        value: '102743207079|5325|云南省|红河哈尼族彝族自治州'
      },
      {
        label: '中国工商银行云南红河分行蒙自县红河广场支行',
        value: '102743300553|5325|云南省|红河哈尼族彝族自治州'
      },
      {
        label: '中国工商银行云南红河分行蒙自县凤凰支行',
        value: '102743300615|5325|云南省|红河哈尼族彝族自治州'
      },
      {
        label: '中国工商银行云南红河分行蒙自县南湖支行',
        value: '102743300623|5325|云南省|红河哈尼族彝族自治州'
      },
      {
        label: '中国工商银行云南红河分行蒙自县支行',
        value: '102743307088|5325|云南省|红河哈尼族彝族自治州'
      },
      {
        label: '中国工商银行云南红河分行建水县朝阳支行',
        value: '102743500439|5325|云南省|红河哈尼族彝族自治州'
      },
      {
        label: '中国工商银行云南红河分行建水县临安路支行',
        value: '102743504407|5325|云南省|红河哈尼族彝族自治州'
      },
      {
        label: '中国工商银行云南红河分行建水县清远路支行',
        value: '102743504431|5325|云南省|红河哈尼族彝族自治州'
      },
      {
        label: '中国工商银行云南红河分行建水县支行',
        value: '102743507098|5325|云南省|红河哈尼族彝族自治州'
      },
      {
        label: '中国工商银行云南红河分行石屏西门支行',
        value: '102743600520|5325|云南省|红河哈尼族彝族自治州'
      },
      {
        label: '工行陕西省西安西工大支行',
        value: '102791011739|6101|陕西省|西安市'
      },
      {
        label: '工行陕西省西安枫林绿洲支行',
        value: '102791011886|6101|陕西省|西安市'
      },
      {
        label: '中国工商银行股份有限公司西安凤城八路支行',
        value: '102791012282|6101|陕西省|西安市'
      },
      {
        label: '工行陕西省西安咸宁中路支行',
        value: '102791013020|6101|陕西省|西安市'
      },
      {
        label: '中国工商银行股份有限公司西安万寿南路支行',
        value: '102791013134|6101|陕西省|西安市'
      },
      {
        label: '工行陕西省西安浐灞商务中心支行',
        value: '102791014071|6101|陕西省|西安市'
      },
      {
        label: '工行陕西省西安团结南路支行',
        value: '102791015050|6101|陕西省|西安市'
      },
      {
        label: '中国工商银行股份有限公司西安吉祥路支行',
        value: '102791015121|6101|陕西省|西安市'
      },
      {
        label: '中国工商银行股份有限公司陕西自贸区西安国际港务区支行',
        value: '102791015277|6101|陕西省|西安市'
      },
      {
        label: '工行陕西省西安长乐东路支行',
        value: '102791016116|6101|陕西省|西安市'
      },
      {
        label: '工行陕西省西安太元路支行',
        value: '102791016382|6101|陕西省|西安市'
      },
      {
        label: '中国工商银行股份有限公司西安东元路支行',
        value: '102791016399|6101|陕西省|西安市'
      },
      {
        label: '中国工商银行股份有限公司西安长安区宇航路支行',
        value: '102791017123|6101|陕西省|西安市'
      },
      {
        label: '中国工商银行股份有限公司西安紫薇田园都市支行',
        value: '102791017246|6101|陕西省|西安市'
      },
      {
        label: '工行陕西省铜川正阳路支行',
        value: '102792000068|6102|陕西省|铜川市'
      },
      {
        label: '工行陕西省铜川金谟路支行',
        value: '102792000092|6102|陕西省|铜川市'
      },
      {
        label: '工行铜川鸭口分理处',
        value: '102792000105|6102|陕西省|铜川市'
      },
      {
        label: '工商银行铜川耀州区支行',
        value: '102792000201|6102|陕西省|铜川市'
      },
      {
        label: '工行陕西省铜川药王山支行',
        value: '102792000210|6102|陕西省|铜川市'
      },
      {
        label: '工行铜川耀州区南大街分理处',
        value: '102792000236|6102|陕西省|铜川市'
      },
      {
        label: '中国工商银行股份有限公司贵阳西湖路支行',
        value: '102701000336|5201|贵州省|贵阳市'
      },
      {
        label: '工行陕西省凤翔县南环路支行',
        value: '102793230516|6103|陕西省|宝鸡市'
      },
      {
        label: '中国工商银行股份有限公司凤翔县秦凤路支行',
        value: '102793230740|6103|陕西省|宝鸡市'
      },
      {
        label: '中国工商银行岐山县支行',
        value: '102793330226|6103|陕西省|宝鸡市'
      },
      {
        label: '中国工商银行惠东县支行',
        value: '102595302427|4413|广东省|惠州市'
      },
      {
        label: '中国工商银行昆明北京路支行',
        value: '102731002066|5301|云南省|昆明市'
      },
      {
        label: '中国工商银行昆明关上支行',
        value: '102731002074|5301|云南省|昆明市'
      },
      {
        label: '中国工商银行昆明西市区支行',
        value: '102731002082|5301|云南省|昆明市'
      },
      {
        label: '中国工商银行昆明东风支行',
        value: '102731002099|5301|云南省|昆明市'
      },
      {
        label: '中国工商银行昆明大观支行',
        value: '102731002103|5301|云南省|昆明市'
      },
      {
        label: '中国工商银行昆明南市区支行',
        value: '102731002111|5301|云南省|昆明市'
      },
      {
        label: '中国工商银行昆明汇通支行',
        value: '102731002120|5301|云南省|昆明市'
      },
      {
        label: '中国工商银行昆明城市开发支行',
        value: '102731002138|5301|云南省|昆明市'
      },
      {
        label: '中国工商银行昆明银通支行',
        value: '102731002146|5301|云南省|昆明市'
      },
      {
        label: '中国工商银行昆明圆通支行',
        value: '102731002154|5301|云南省|昆明市'
      },
      {
        label: '中国工商银行昆明北市区支行',
        value: '102731002162|5301|云南省|昆明市'
      },
      {
        label: '中国工商银行昆明宜良县支行',
        value: '102731002187|5301|云南省|昆明市'
      },
      {
        label: '中国工商银行昆明石林县支行',
        value: '102731002195|5301|云南省|昆明市'
      },
      {
        label: '中国工商银行昆明安宁市支行',
        value: '102731002218|5301|云南省|昆明市'
      },
      {
        label: '中国工商银行昆明晋宁县支行',
        value: '102731002226|5301|云南省|昆明市'
      },
      {
        label: '中国工商银行昆明昆钢支行',
        value: '102731002234|5301|云南省|昆明市'
      },
      {
        label: '中国工商银行昆明呈贡县支行',
        value: '102731002242|5301|云南省|昆明市'
      },
      {
        label: '中国工商银行昆明高新技术产业区支行',
        value: '102731002267|5301|云南省|昆明市'
      },
      {
        label: '中国工商银行昆明海口支行',
        value: '102731002275|5301|云南省|昆明市'
      },
      {
        label: '中国工商银行昆明牡丹支行',
        value: '102731002283|5301|云南省|昆明市'
      },
      {
        label: '中国工商银行昆明东川支行',
        value: '102731002291|5301|云南省|昆明市'
      },
      {
        label: '中国工商银行昆明船舶工业区支行',
        value: '102731002363|5301|云南省|昆明市'
      },
      {
        label: '中国工商银行昆明大观支行西站分理处',
        value: '102731002419|5301|云南省|昆明市'
      },
      {
        label: '中国工商银行昆明高新支行科医路分理处',
        value: '102731002460|5301|云南省|昆明市'
      },
      {
        label: '中国工商银行昆明高新支行白马分理处',
        value: '102731002478|5301|云南省|昆明市'
      },
      {
        label: '中国工商银行昆明昆明机场支行',
        value: '102731003815|5301|云南省|昆明市'
      },
      {
        label: '中国工商银行昆明南市区支行十里长街分理处',
        value: '102731003987|5301|云南省|昆明市'
      },
      {
        label: '中国工商银行昆明塘子巷支行',
        value: '102731003995|5301|云南省|昆明市'
      },
      {
        label: '中国工商银行股份有限公司兰州安宁长新支行',
        value: '102821036321|6201|甘肃省|兰州市'
      },
      {
        label: '中国工商银行股份有限公司兰州费家营第二支行',
        value: '102821036364|6201|甘肃省|兰州市'
      },
      {
        label: '中国工商银行股份有限公司兰州段家滩支行',
        value: '102821037437|6201|甘肃省|兰州市'
      },
      {
        label: '中国工商银行股份有限公司兰州新港城支行',
        value: '102821037523|6201|甘肃省|兰州市'
      },
      {
        label: '中国工商银行股份有限公司兰州奇正支行',
        value: '102821037603|6201|甘肃省|兰州市'
      },
      {
        label: '中国工商银行股份有限公司兰州能源支行',
        value: '102821037620|6201|甘肃省|兰州市'
      },
      {
        label: '中国工商银行股份有限公司兰州红古海石湾支行',
        value: '102821088806|6201|甘肃省|兰州市'
      },
      {
        label: '中国工商银行股份有限公司兰州雁滩中路支行',
        value: '102821088822|6201|甘肃省|兰州市'
      },
      {
        label: '中国工商银行股份有限公司兰州雁滩第一支行',
        value: '102821088855|6201|甘肃省|兰州市'
      },
      {
        label: '中国工商银行股份有限公司兰州嘉峪关路第二支行',
        value: '102821088863|6201|甘肃省|兰州市'
      },
      {
        label: '中国工商银行股份有限公司嘉峪关市分行会计业务处理中心',
        value: '102822000015|6202|甘肃省|嘉峪关市'
      },
      {
        label: '中国工商银行股份有限公司嘉峪关市分行营业室',
        value: '102822000023|6202|甘肃省|嘉峪关市'
      },
      {
        label: '中国工商银行股份有限公司嘉峪关新华南路支行',
        value: '102822000040|6202|甘肃省|嘉峪关市'
      },
      {
        label: '中国工商银行昆明樱花语支行',
        value: '102731010777|5301|云南省|昆明市'
      },
      {
        label: '中国工商银行昆明翠湖支行',
        value: '102731010808|5301|云南省|昆明市'
      },
      {
        label: '中国工商银行昆明西坝支行',
        value: '102731010849|5301|云南省|昆明市'
      },
      {
        label: '中国工商银行昆明凤凰新村支行',
        value: '102731010857|5301|云南省|昆明市'
      },
      {
        label: '中国工商银行昆明安宁珍泉路支行',
        value: '102731010865|5301|云南省|昆明市'
      },
      {
        label: '中国工商银行昆明交三桥支行',
        value: '102731010873|5301|云南省|昆明市'
      },
      {
        label: '中国工商银行昆明呈贡春融支行',
        value: '102731010890|5301|云南省|昆明市'
      },
      {
        label: '中国工商银行昆明呈贡米兰园支行',
        value: '102731010904|5301|云南省|昆明市'
      },
      {
        label: '中国工商银行昆明安宁柳树花园支行',
        value: '102731010945|5301|云南省|昆明市'
      },
      {
        label: '中国工商银行昆明广福支行',
        value: '102731011026|5301|云南省|昆明市'
      },
      {
        label: '中国工商银行昆明高新支行丽苑路分理处',
        value: '102731011042|5301|云南省|昆明市'
      },
      {
        label: '中国工商银行昆明呈贡龙城支行',
        value: '102731011075|5301|云南省|昆明市'
      },
      {
        label: '中国工商银行昆明安宁昆钢南区支行',
        value: '102731011091|5301|云南省|昆明市'
      },
      {
        label: '中国工商银行昆明安宁昆钢朝阳路支行',
        value: '102731011114|5301|云南省|昆明市'
      },
      {
        label: '中国工商银行昆明银河大道支行',
        value: '102731011202|5301|云南省|昆明市'
      },
      {
        label: '中国工商银行昆明海口支行中滩分理处',
        value: '102731011278|5301|云南省|昆明市'
      },
      {
        label: '中国工商银行昆明晋宁支行城区分理处',
        value: '102731011368|5301|云南省|昆明市'
      },
      {
        label: '中国工商银行金昌市分行会计业务处理中心',
        value: '102823000017|6203|甘肃省|金昌市'
      },
      {
        label: '中国工商银行金昌金川支行',
        value: '102823000025|6203|甘肃省|金昌市'
      },
      {
        label: '中国工商银行金昌镍都支行',
        value: '102823000033|6203|甘肃省|金昌市'
      },
      {
        label: '中国工商银行金昌牡丹支行',
        value: '102823000068|6203|甘肃省|金昌市'
      },
      {
        label: '中国工商银行股份有限公司金昌金川西路支行',
        value: '102823000076|6203|甘肃省|金昌市'
      },
      {
        label: '中国工商银行股份有限公司金昌金川路支行',
        value: '102823000084|6203|甘肃省|金昌市'
      },
      {
        label: '中国工商银行股份有限公司金昌金谷里支行',
        value: '102823000113|6203|甘肃省|金昌市'
      },
      {
        label: '中国工商银行股份有限公司金昌龙门里支行',
        value: '102823000121|6203|甘肃省|金昌市'
      },
      {
        label: '中国工商银行股份有限公司金昌龙津里支行',
        value: '102823000189|6203|甘肃省|金昌市'
      },
      {
        label: '中国工商银行股份有限公司金昌广州路支行',
        value: '102823000197|6203|甘肃省|金昌市'
      },
      {
        label: '中国工商银行股份有限公司金昌延安路支行',
        value: '102823000201|6203|甘肃省|金昌市'
      },
      {
        label: '中国工商银行股份有限公司金昌上海路支行',
        value: '102823000285|6203|甘肃省|金昌市'
      },
      {
        label: '中国工商银行股份有限公司金昌分行营业室',
        value: '102823000308|6203|甘肃省|金昌市'
      },
      {
        label: '中国工商银行股份有限公司金昌天津路支行',
        value: '102823000332|6203|甘肃省|金昌市'
      },
      {
        label: '中国工商银行永昌县支行',
        value: '102823100059|6203|甘肃省|金昌市'
      },
      {
        label: '中国工商银行金昌河西堡支行',
        value: '102823150019|6203|甘肃省|金昌市'
      },
      {
        label: '中国工商银行白银市分行会计业务处理中心',
        value: '102824000019|6204|甘肃省|白银市'
      },
      {
        label: '中国工商银行股份有限公司白银铜城支行',
        value: '102824000027|6204|甘肃省|白银市'
      },
      {
        label: '中国工商银行股份有限公司白银西区支行',
        value: '102824000043|6204|甘肃省|白银市'
      },
      {
        label: '中国工商银行股份有限公司白银分行营业室',
        value: '102824000086|6204|甘肃省|白银市'
      },
      {
        label: '中国工商银行股份有限公司白银北京路支行',
        value: '102824000109|6204|甘肃省|白银市'
      },
      {
        label: '中国工商银行股份有限公司白银稀土支行',
        value: '102824000117|6204|甘肃省|白银市'
      },
      {
        label: '中国工商银行股份有限公司白银城建支行',
        value: '102824000133|6204|甘肃省|白银市'
      },
      {
        label: '中国工商银行股份有限公司兰州西津西路支行',
        value: '102821000388|6201|甘肃省|兰州市'
      },
      {
        label: '中国工商银行股份有限公司兰州渭源路第一支行',
        value: '102821000396|6201|甘肃省|兰州市'
      },
      {
        label: '中国工商银行股份有限公司兰州金轮支行',
        value: '102821000992|6201|甘肃省|兰州市'
      },
      {
        label: '中国工商银行股份有限公司兰州张苏滩支行',
        value: '102821001910|6201|甘肃省|兰州市'
      },
      {
        label: '中国工商银行股份有限公司兰州西固福利路支行',
        value: '102821001936|6201|甘肃省|兰州市'
      },
      {
        label: '中国工商银行股份有限公司兰州新区纬一路支行',
        value: '102821001944|6201|甘肃省|兰州市'
      },
      {
        label: '中国工商银行股份有限公司兰州东口支行',
        value: '102821001969|6201|甘肃省|兰州市'
      },
      {
        label: '中国工商银行股份有限公司兰州皋兰路支行',
        value: '102821001977|6201|甘肃省|兰州市'
      },
      {
        label: '中国工商银行股份有限公司兰州中央广场第二支行',
        value: '102821001985|6201|甘肃省|兰州市'
      },
      {
        label: '中国工商银行股份有限公司兰州财政厅支行',
        value: '102821001993|6201|甘肃省|兰州市'
      },
      {
        label: '中国工商银行股份有限公司兰州广场支行',
        value: '102821002003|6201|甘肃省|兰州市'
      },
      {
        label: '中国工商银行股份有限公司兰州榆中支行',
        value: '102821002628|6201|甘肃省|兰州市'
      },
      {
        label: '中国工商银行股份有限公司兰州红古支行',
        value: '102821002644|6201|甘肃省|兰州市'
      },
      {
        label: '中国工商银行股份有限公司兰州红古矿区支行',
        value: '102821002652|6201|甘肃省|兰州市'
      },
      {
        label: '中国工商银行三原县支行',
        value: '102795240180|6104|陕西省|咸阳市'
      },
      {
        label: '中国工商银行股份有限公司三原县红原支行',
        value: '102795240198|6104|陕西省|咸阳市'
      },
      {
        label: '中国工商银行股份有限公司三原县盐店街支行',
        value: '102795240202|6104|陕西省|咸阳市'
      },
      {
        label: '中国工商银行股份有限公司凯里天一支行',
        value: '102713000627|5226|贵州省|黔东南苗族侗族自治州'
      },
      {
        label: '中国工商银行股份有限公司营盘支行',
        value: '102713000651|5226|贵州省|黔东南苗族侗族自治州'
      },
      {
        label: '中国工商银行贵州省凯里分行',
        value: '102713005004|5226|贵州省|黔东南苗族侗族自治州'
      },
      {
        label: '中国工商银行股份有限公司三原县新庄支行',
        value: '102795245119|6104|陕西省|咸阳市'
      },
      {
        label: '中国工商银行泾阳县支行',
        value: '102795340225|6104|陕西省|咸阳市'
      },
      {
        label: '中国工商银行乾县支行',
        value: '102795440138|6104|陕西省|咸阳市'
      },
      {
        label: '中国工商银行礼泉县支行',
        value: '102795540159|6104|陕西省|咸阳市'
      },
      {
        label: '工行陕西省咸阳彬县豳风街支行',
        value: '102795705832|6104|陕西省|咸阳市'
      },
      {
        label: '中国工商银行彬县支行',
        value: '102795740101|6104|陕西省|咸阳市'
      },
      {
        label: '工行陕西省咸阳长武县支行',
        value: '102795840526|6104|陕西省|咸阳市'
      },
      {
        label: '中国工商银行武功县支行',
        value: '102796240234|6104|陕西省|咸阳市'
      },
      {
        label: '工行陕西省武功县5702厂区支行',
        value: '102796240509|6104|陕西省|咸阳市'
      },
      {
        label: '中国工商银行渭南前进路支行',
        value: '102797050019|6105|陕西省|渭南市'
      },
      {
        label: '中国工商银行渭南市东风街支行',
        value: '102797050027|6105|陕西省|渭南市'
      },
      {
        label: '中国工商银行渭南市解放路支行',
        value: '102797050035|6105|陕西省|渭南市'
      },
      {
        label: '中国工商银行渭南临渭区支行',
        value: '102797050043|6105|陕西省|渭南市'
      },
      {
        label: '工行陕西省渭南站北路支行',
        value: '102797050051|6105|陕西省|渭南市'
      },
      {
        label: '工行陕西省渭南乐天花园支行',
        value: '102797050086|6105|陕西省|渭南市'
      },
      {
        label: '工行陕西省渭南高新区支行',
        value: '102797050272|6105|陕西省|渭南市'
      },
      {
        label: '中国工商银行渭南市国贸大厦分理处',
        value: '102797050297|6105|陕西省|渭南市'
      },
      {
        label: '工行陕西省渭南市韩马分理处',
        value: '102797050344|6105|陕西省|渭南市'
      },
      {
        label: '工行陕西省渭南林业大厦支行',
        value: '102797050385|6105|陕西省|渭南市'
      },
      {
        label: '工行陕西省渭南杜桥支行',
        value: '102797050393|6105|陕西省|渭南市'
      },
      {
        label: '工行陕西省渭南仓程路支行',
        value: '102797050416|6105|陕西省|渭南市'
      },
      {
        label: '中国工商银行渭南分行业务处理中心',
        value: '102797055009|6105|陕西省|渭南市'
      },
      {
        label: '中国工商银行渭南韩城市支行',
        value: '102797250202|6105|陕西省|渭南市'
      },
      {
        label: '工行韩城市支龙门支行',
        value: '102797250219|6105|陕西省|渭南市'
      },
      {
        label: '工行陕西省渭南韩城市桑树坪支行',
        value: '102797250227|6105|陕西省|渭南市'
      },
      {
        label: '工商银行韩城市金塔支行',
        value: '102797250317|6105|陕西省|渭南市'
      },
      {
        label: '工行陕西省渭南韩城市禹甸圆支行',
        value: '102797250325|6105|陕西省|渭南市'
      },
      {
        label: '工行陕西省渭南韩城市盘河支行',
        value: '102797250368|6105|陕西省|渭南市'
      },
      {
        label: '工行陕西省渭南韩城市金城支行',
        value: '102797255080|6105|陕西省|渭南市'
      },
      {
        label: '中国工商银行股份有限公司渭南华州区支行',
        value: '102797350063|6105|陕西省|渭南市'
      },
      {
        label: '中国工商银行股份有限公司渭南华州区金堆支行',
        value: '102797350071|6105|陕西省|渭南市'
      },
      {
        label: '中国工商银行股份有限公司渭南华州区城南支行',
        value: '102797355091|6105|陕西省|渭南市'
      },
      {
        label: '中国工商银行渭南华阴市支行',
        value: '102797450099|6105|陕西省|渭南市'
      },
      {
        label: '工行陕西省渭南华阴市华岳支行',
        value: '102797450111|6105|陕西省|渭南市'
      },
      {
        label: '中国工商银行渭南华阴市罗夫支行',
        value: '102797450120|6105|陕西省|渭南市'
      },
      {
        label: '工行陕西省渭南华阴市华山支行',
        value: '102797455038|6105|陕西省|渭南市'
      },
      {
        label: '中国工商银行渭南潼关县支行',
        value: '102797550137|6105|陕西省|渭南市'
      },
      {
        label: '中国工商银行渭南大荔县支行',
        value: '102797650142|6105|陕西省|渭南市'
      },
      {
        label: '工行陕西省渭南大荔县花城路支行',
        value: '102797650409|6105|陕西省|渭南市'
      },
      {
        label: '中国工商银行渭南蒲城县支行',
        value: '102797750158|6105|陕西省|渭南市'
      },
      {
        label: '中国工商银行渭南蒲城县罕井支行',
        value: '102797750166|6105|陕西省|渭南市'
      },
      {
        label: '中国工商银行股份有限公司南宁市佛子岭支行',
        value: '102611013319|4501|广西壮族自治区|南宁市'
      },
      {
        label: '中国工商银行股份有限公司南宁市保税区支行',
        value: '102611013610|4501|广西壮族自治区|南宁市'
      },
      {
        label: '中国工商银行股份有限公司广西壮族自治区分行营业部',
        value: '102611015009|4501|广西壮族自治区|南宁市'
      },
      {
        label: '中国工商银行股份有限公司南宁市滨湖中支行',
        value: '102611017463|4501|广西壮族自治区|南宁市'
      },
      {
        label: '中国工商银行股份有限公司南宁市碧湖支行',
        value: '102611017498|4501|广西壮族自治区|南宁市'
      },
      {
        label: '中国工商银行股份有限公司隆安县支行',
        value: '102611017519|4501|广西壮族自治区|南宁市'
      },
      {
        label: '中国工商银行股份有限公司凭祥分行',
        value: '102612111506|4514|广西壮族自治区|崇左市'
      },
      {
        label: '中国工商银行股份有限公司绵阳安州支行',
        value: '102659342033|5107|四川省|绵阳市'
      },
      {
        label: '中国工商银行股份有限公司绵阳安州界牌支行',
        value: '102659342041|5107|四川省|绵阳市'
      },
      {
        label: '中国工商银行股份有限公司梓潼支行',
        value: '102659442317|5107|四川省|绵阳市'
      },
      {
        label: '中国工商银行股份有限公司北川支行',
        value: '102659542126|5107|四川省|绵阳市'
      },
      {
        label: '中国工商银行股份有限公司平武支行',
        value: '102659642419|5107|四川省|绵阳市'
      },
      {
        label: '中国工商银行股份有限公司四川省绵阳江油华丰彩虹路支行',
        value: '102659701024|5107|四川省|绵阳市'
      },
      {
        label: '中国工商银行股份有限公司四川省绵阳江油纪念碑支行',
        value: '102659701161|5107|四川省|绵阳市'
      },
      {
        label: '中国工商银行股份有限公司四川省江油太白支行',
        value: '102659701170|5107|四川省|绵阳市'
      },
      {
        label: '中国工商银行股份有限公司江油明月新城支行',
        value: '102659701223|5107|四川省|绵阳市'
      },
      {
        label: '中国工商银行股份有限公司江油涪江北路支行',
        value: '102659701266|5107|四川省|绵阳市'
      },
      {
        label: '中国工商银行股份有限公司江油支行',
        value: '102659742215|5107|四川省|绵阳市'
      },
      {
        label: '中国工商银行股份有限公司四川省绵阳江油解放街支行',
        value: '102659742223|5107|四川省|绵阳市'
      },
      {
        label: '中国工商银行股份有限公司四川省绵阳江油火车站支行',
        value: '102659742258|5107|四川省|绵阳市'
      },
      {
        label: '中国工商银行股份有限公司四川省绵阳江油三合支行',
        value: '102659742266|5107|四川省|绵阳市'
      },
      {
        label: '中国工商银行股份有限公司嘉峪关胜利南路支行',
        value: '102822000058|6202|甘肃省|嘉峪关市'
      },
      {
        label: '中国工商银行股份有限公司嘉峪关建设路支行',
        value: '102822000066|6202|甘肃省|嘉峪关市'
      },
      {
        label: '中国工商银行股份有限公司嘉峪关核城支行',
        value: '102822000103|6202|甘肃省|嘉峪关市'
      },
      {
        label: '中国工商银行股份有限公司嘉峪关迎宾东路支行',
        value: '102822000120|6202|甘肃省|嘉峪关市'
      },
      {
        label: '中国工商银行股份有限公司嘉峪关振兴支行',
        value: '102822000179|6202|甘肃省|嘉峪关市'
      },
      {
        label: '中国工商银行昆明云秀支行',
        value: '102731001713|5301|云南省|昆明市'
      },
      {
        label: '中国工商银行昆明牛街庄支行',
        value: '102731001721|5301|云南省|昆明市'
      },
      {
        label: '中国工商银行昆明穿金中路支行',
        value: '102731001730|5301|云南省|昆明市'
      },
      {
        label: '中国工商银行昆明大石坝支行',
        value: '102731001748|5301|云南省|昆明市'
      },
      {
        label: '中国工商银行昆明向阳支行',
        value: '102731001756|5301|云南省|昆明市'
      },
      {
        label: '中国工商银行昆明新迎小区支行',
        value: '102731001764|5301|云南省|昆明市'
      },
      {
        label: '中国工商银行昆明海伦国际支行',
        value: '102731001772|5301|云南省|昆明市'
      },
      {
        label: '中国工商银行股份有限公司昆明分行',
        value: '102731002015|5301|云南省|昆明市'
      },
      {
        label: '中国工商银行昆明石林县支行城区分理处',
        value: '102731011456|5301|云南省|昆明市'
      },
      {
        label: '中国工商银行股份有限公司绵阳剑南支行',
        value: '102659041230|5107|四川省|绵阳市'
      },
      {
        label: '中国工商银行股份有限公司绵阳临园支行',
        value: '102659041248|5107|四川省|绵阳市'
      },
      {
        label: '中国工商银行股份有限公司绵阳金柱园支行',
        value: '102659041256|5107|四川省|绵阳市'
      },
      {
        label: '中国工商银行股份有限公司绵阳御营支行',
        value: '102659041272|5107|四川省|绵阳市'
      },
      {
        label: '中国工商银行股份有限公司四川省绵阳游仙支行',
        value: '102659041310|5107|四川省|绵阳市'
      },
      {
        label: '中国工商银行股份有限公司四川省绵阳南河支行',
        value: '102659041328|5107|四川省|绵阳市'
      },
      {
        label: '中国工商银行股份有限公司绵阳高新技术产业开发支行',
        value: '102659041416|5107|四川省|绵阳市'
      },
      {
        label: '中国工商银行股份有限公司绵阳科学城支行',
        value: '102659041512|5107|四川省|绵阳市'
      },
      {
        label: '中国工商银行股份有限公司绵阳科学城支行第一分理处',
        value: '102659041529|5107|四川省|绵阳市'
      },
      {
        label: '中国工商银行股份有限公司绵阳科学城支行第八分理处',
        value: '102659041553|5107|四川省|绵阳市'
      },
      {
        label: '中国工商银行股份有限公司绵阳新华支行',
        value: '102659041711|5107|四川省|绵阳市'
      },
      {
        label: '中国工商银行股份有限公司绵阳分行营业室',
        value: '102659062085|5107|四川省|绵阳市'
      },
      {
        label: '中国工商银行股份有限公司四川省绵阳三台梓州支行',
        value: '102659101454|5107|四川省|绵阳市'
      },
      {
        label: '中国工商银行股份有限公司三台支行',
        value: '102659142518|5107|四川省|绵阳市'
      },
      {
        label: '中国工商银行股份有限公司三台东河路支行',
        value: '102659142534|5107|四川省|绵阳市'
      },
      {
        label: '中国工商银行股份有限公司盐亭支行',
        value: '102659242615|5107|四川省|绵阳市'
      },
      {
        label: '中国工商银行股份有限公司绵阳安州文星阁支行',
        value: '102659303899|5107|四川省|绵阳市'
      },
      {
        label: '中国工商银行股份有限公司北川安昌支行',
        value: '102659342017|5107|四川省|绵阳市'
      },
      {
        label: '中国工商银行甘肃省分行清算中心',
        value: '102821018008|6201|甘肃省|兰州市'
      },
      {
        label: '中国工商银行昆明希望路支行',
        value: '102731011497|5301|云南省|昆明市'
      },
      {
        label: '中国工商银行昆明新螺蛳湾第二支行',
        value: '102731011632|5301|云南省|昆明市'
      },
      {
        label: '中国工商银行昆明江岸支行',
        value: '102731011681|5301|云南省|昆明市'
      },
      {
        label: '中国工商银行昆明润城支行',
        value: '102731011796|5301|云南省|昆明市'
      },
      {
        label: '中国工商银行昆明大观支行西昌路分理处',
        value: '102731011866|5301|云南省|昆明市'
      },
      {
        label: '中国工商银行股份有限公司江油华丰支行',
        value: '102659742717|5107|四川省|绵阳市'
      },
      {
        label: '中国工商银行股份有限公司四川省绵阳江油华丰双鹤支行',
        value: '102659742725|5107|四川省|绵阳市'
      },
      {
        label: '中国工商银行股份有限公司广元上海路支行',
        value: '102661000802|5108|四川省|广元市'
      },
      {
        label: '中国工商银行股份有限公司四川省南充嘉陵支行',
        value: '102673053819|5113|四川省|南充市'
      },
      {
        label: '中国工商银行股份有限公司凯里新城西路支行',
        value: '102713005045|5226|贵州省|黔东南苗族侗族自治州'
      },
      {
        label: '中国工商银行施秉县支行',
        value: '102713300237|5226|贵州省|黔东南苗族侗族自治州'
      },
      {
        label: '中国工商银行镇远县支行',
        value: '102713500175|5226|贵州省|黔东南苗族侗族自治州'
      },
      {
        label: '中国工商银行股份有限公司岑巩支行',
        value: '102713600246|5226|贵州省|黔东南苗族侗族自治州'
      },
      {
        label: '中国工商银行天柱县支行',
        value: '102713700136|5226|贵州省|黔东南苗族侗族自治州'
      },
      {
        label: '中国工商银行锦屏县支行',
        value: '102713800194|5226|贵州省|黔东南苗族侗族自治州'
      },
      {
        label: '中国工商银行革东支行',
        value: '102713900200|5226|贵州省|黔东南苗族侗族自治州'
      },
      {
        label: '中国工商银行黎平县支行',
        value: '102714200168|5226|贵州省|黔东南苗族侗族自治州'
      },
      {
        label: '中国工商银行麻江县支行',
        value: '102714600014|5226|贵州省|黔东南苗族侗族自治州'
      },
      {
        label: '中国工商银行股份有限公司丹寨支行',
        value: '102714700058|5226|贵州省|黔东南苗族侗族自治州'
      },
      {
        label: '中国工商银行都匀分行桥城支行',
        value: '102715000010|5227|贵州省|黔南布依族苗族自治州'
      },
      {
        label: '中国工商银行都匀分行南工区分理处',
        value: '102715000028|5227|贵州省|黔南布依族苗族自治州'
      },
      {
        label: '中国工商银行都匀分行城北分理处',
        value: '102715000036|5227|贵州省|黔南布依族苗族自治州'
      },
      {
        label: '中国工商银行都匀分行平桥支行',
        value: '102715000044|5227|贵州省|黔南布依族苗族自治州'
      },
      {
        label: '中国工商银行都匀分行匀城支行',
        value: '102715000052|5227|贵州省|黔南布依族苗族自治州'
      },
      {
        label: '中国工商银行都匀分行开发区办事处',
        value: '102715000069|5227|贵州省|黔南布依族苗族自治州'
      },
      {
        label: '中国工商银行股份有限公司贵州都匀新市街支行',
        value: '102715000237|5227|贵州省|黔南布依族苗族自治州'
      },
      {
        label: '中国工商银行股份有限公司贵州都匀文化路分理处',
        value: '102715000245|5227|贵州省|黔南布依族苗族自治州'
      },
      {
        label: '中国工商银行都匀分行',
        value: '102715005005|5227|贵州省|黔南布依族苗族自治州'
      },
      {
        label: '中国工商银行都匀分行剑江分理处',
        value: '102715005021|5227|贵州省|黔南布依族苗族自治州'
      },
      {
        label: '中国工商银行都匀分行荔波县支行',
        value: '102715200206|5227|贵州省|黔南布依族苗族自治州'
      },
      {
        label: '中国工商银行都匀分行贵定县支行',
        value: '102715300096|5227|贵州省|黔南布依族苗族自治州'
      },
      {
        label: '中国工商银行都匀分行福泉市支行',
        value: '102715400185|5227|贵州省|黔南布依族苗族自治州'
      },
      {
        label: '中国工商银行都匀分行马场坪分理处',
        value: '102715400193|5227|贵州省|黔南布依族苗族自治州'
      },
      {
        label: '中国工商银行都匀分行瓮安县支行',
        value: '102715500119|5227|贵州省|黔南布依族苗族自治州'
      },
      {
        label: '中国工商银行都匀分行独山县支行',
        value: '102715600146|5227|贵州省|黔南布依族苗族自治州'
      },
      {
        label: '中国工商银行都匀分行平塘支行',
        value: '102715700161|5227|贵州省|黔南布依族苗族自治州'
      },
      {
        label: '中国工商银行股份有限公司罗甸支行',
        value: '102715800084|5227|贵州省|黔南布依族苗族自治州'
      },
      {
        label: '中国工商银行都匀分行龙里县支行',
        value: '102716100174|5227|贵州省|黔南布依族苗族自治州'
      },
      {
        label: '中国工商银行都匀分行惠水县支行',
        value: '102716200072|5227|贵州省|黔南布依族苗族自治州'
      },
      {
        label: '中国工商银行股份有限公司云南省分行（不办对外业务）',
        value: '102731000010|5301|云南省|昆明市'
      },
      {
        label: '中国工商银行昆明宝善支行',
        value: '102731000028|5301|云南省|昆明市'
      },
      {
        label: '中国工商银行昆明南市区支行银海分理处',
        value: '102731001045|5301|云南省|昆明市'
      },
      {
        label: '中国工商银行昆明威远街支行',
        value: '102731001061|5301|云南省|昆明市'
      },
      {
        label: '中国工商银行昆明东寺街支行',
        value: '102731001115|5301|云南省|昆明市'
      },
      {
        label: '中国工商银行昆明南屏支行螺蛳湾分理处',
        value: '102731001131|5301|云南省|昆明市'
      },
      {
        label: '中国工商银行昆明大观支行严家地分理处',
        value: '102731001140|5301|云南省|昆明市'
      },
      {
        label: '中国工商银行昆明公园1903支行',
        value: '102731001158|5301|云南省|昆明市'
      },
      {
        label: '中国工商银行昆明正义支行武成路分理处',
        value: '102731001211|5301|云南省|昆明市'
      },
      {
        label: '中国工商银行昆明拓东路支行',
        value: '102731001318|5301|云南省|昆明市'
      },
      {
        label: '中国工商银行昆明吴井支行',
        value: '102731001326|5301|云南省|昆明市'
      },
      {
        label: '中国工商银行昆明经开区支行',
        value: '102731001367|5301|云南省|昆明市'
      },
      {
        label: '中国工商银行股份有限公司广元分行核算中心',
        value: '102661044119|5108|四川省|广元市'
      },
      {
        label: '中国工商银行股份有限公司广元分行营业部',
        value: '102661044217|5108|四川省|广元市'
      },
      {
        label: '中国工商银行股份有限公司四川省广元开发区支行',
        value: '102661044233|5108|四川省|广元市'
      },
      {
        label: '中国工商银行股份有限公司广元鼓楼支行',
        value: '102661044612|5108|四川省|广元市'
      },
      {
        label: '中国工商银行股份有限公司广元万源支行',
        value: '102661044629|5108|四川省|广元市'
      },
      {
        label: '中国工商银行股份有限公司四川省广元蜀南支行',
        value: '102661044645|5108|四川省|广元市'
      },
      {
        label: '中国工商银行股份有限公司四川省广元苴国路支行',
        value: '102661044688|5108|四川省|广元市'
      },
      {
        label: '中国工商银行股份有限公司广元分行朝天支行',
        value: '102661044696|5108|四川省|广元市'
      },
      {
        label: '中国工商银行昆明金碧支行',
        value: '102731004027|5301|云南省|昆明市'
      },
      {
        label: '中国工商银行昆明金康支行',
        value: '102731004035|5301|云南省|昆明市'
      },
      {
        label: '中国工商银行昆明玫瑰湾支行',
        value: '102731004043|5301|云南省|昆明市'
      },
      {
        label: '中国工商银行昆明长水机场支行',
        value: '102731006580|5301|云南省|昆明市'
      },
      {
        label: '中国工商银行昆明大树营支行',
        value: '102731010023|5301|云南省|昆明市'
      },
      {
        label: '中国工商银行昆明龙翔街支行',
        value: '102731010040|5301|云南省|昆明市'
      },
      {
        label: '中国工商银行昆明世纪城支行',
        value: '102731010058|5301|云南省|昆明市'
      },
      {
        label: '中国工商银行昆明火车站支行',
        value: '102731010099|5301|云南省|昆明市'
      },
      {
        label: '中国工商银行昆明三八支行',
        value: '102731010103|5301|云南省|昆明市'
      },
      {
        label: '中国工商银行昆明人民东路支行',
        value: '102731010138|5301|云南省|昆明市'
      },
      {
        label: '中国工商银行昆明国际银座支行',
        value: '102731010200|5301|云南省|昆明市'
      },
      {
        label: '中国工商银行昆明前卫支行',
        value: '102731010314|5301|云南省|昆明市'
      },
      {
        label: '中国工商银行昆明新闻路支行',
        value: '102731010347|5301|云南省|昆明市'
      },
      {
        label: '中国工商银行昆明昆州路支行',
        value: '102731010355|5301|云南省|昆明市'
      },
      {
        label: '中国工商银行昆明虹山新村支行',
        value: '102731010363|5301|云南省|昆明市'
      },
      {
        label: '中国工商银行昆明前兴路支行',
        value: '102731010402|5301|云南省|昆明市'
      },
      {
        label: '中国工商银行昆明金源支行',
        value: '102731010419|5301|云南省|昆明市'
      },
      {
        label: '中国工商银行昆明黎明支行',
        value: '102731010435|5301|云南省|昆明市'
      },
      {
        label: '中国工商银行昆明金江路支行',
        value: '102731010451|5301|云南省|昆明市'
      },
      {
        label: '中国工商银行昆明珥季路支行',
        value: '102731010460|5301|云南省|昆明市'
      },
      {
        label: '中国工商银行昆明青年路支行',
        value: '102731010509|5301|云南省|昆明市'
      },
      {
        label: '中国工商银行昆明国贸中心支行',
        value: '102731010541|5301|云南省|昆明市'
      },
      {
        label: '中国工商银行昆明滇池度假区支行',
        value: '102731010621|5301|云南省|昆明市'
      },
      {
        label: '中国工商银行股份有限公司四川省南充延安路支行',
        value: '102673053917|5113|四川省|南充市'
      },
      {
        label: '中国工商银行股份有限公司四川省南充石油南路支行',
        value: '102673053925|5113|四川省|南充市'
      },
      {
        label: '中国工商银行股份有限公司四川省南充顺庆支行',
        value: '102673054016|5113|四川省|南充市'
      },
      {
        label: '中国工商银行股份有限公司南充金鱼岭支行',
        value: '102673054024|5113|四川省|南充市'
      },
      {
        label: '中国工商银行股份有限公司华蓥钢铁桥支行',
        value: '102673200653|5113|四川省|南充市'
      },
      {
        label: '中国工商银行华蓥市支行',
        value: '102673255517|5113|四川省|南充市'
      },
      {
        label: '中国工商银行股份有限公司华蓥明光路支行',
        value: '102673255568|5113|四川省|南充市'
      },
      {
        label: '中国工商银行股份有限公司四川省南充南部车站支行',
        value: '102673404028|5113|四川省|南充市'
      },
      {
        label: '中国工商银行股份有限公司四川省南充南部支行东风路分理处',
        value: '102673404044|5113|四川省|南充市'
      },
      {
        label: '中国工商银行股份有限公司南部支行',
        value: '102673454113|5113|四川省|南充市'
      },
      {
        label: '中国工商银行股份有限公司南部正街支行',
        value: '102673454130|5113|四川省|南充市'
      },
      {
        label: '中国工商银行股份有限公司岳池九龙大街支行',
        value: '102673501085|5116|四川省|广安市'
      },
      {
        label: '中国工商银行岳池县支行',
        value: '102673555312|5116|四川省|广安市'
      },
      {
        label: '中国工商银行股份有限公司岳池南门支行',
        value: '102673555329|5116|四川省|广安市'
      },
      {
        label: '中国工商银行股份有限公司岳池丝绸路支行',
        value: '102673555337|5116|四川省|广安市'
      },
      {
        label: '中国工商银行股份有限公司四川省南充营山支行',
        value: '102673654217|5113|四川省|南充市'
      },
      {
        label: '中国工商银行股份有限公司四川省南充营山正东街支行',
        value: '102673654225|5113|四川省|南充市'
      },
      {
        label: '中国工商银行股份有限公司广安分行建安南路分理处',
        value: '102673700010|5116|四川省|广安市'
      },
      {
        label: '中国工商银行股份有限公司广安人民路支行',
        value: '102673700028|5116|四川省|广安市'
      },
      {
        label: '中国工商银行股份有限公司广安分行西溪分理处',
        value: '102673700165|5116|四川省|广安市'
      },
      {
        label: '中国工商银行广安市分行会计核算中心',
        value: '102673755117|5116|四川省|广安市'
      },
      {
        label: '中国工商银行股份有限公司广安分行营业部',
        value: '102673755213|5116|四川省|广安市'
      },
      {
        label: '中国工商银行股份有限公司四川省广安市城南支行',
        value: '102673755230|5116|四川省|广安市'
      },
      {
        label: '中国工商银行股份有限公司广安前锋支行',
        value: '102673755248|5116|四川省|广安市'
      },
      {
        label: '中国工商银行股份有限公司广安洪州大道支行',
        value: '102673755264|5116|四川省|广安市'
      },
      {
        label: '中国工商银行股份有限公司广安分行建安中路分理处',
        value: '102673762163|5116|四川省|广安市'
      },
      {
        label: '中国工商银行股份有限公司四川省南充蓬安支行',
        value: '102673854319|5113|四川省|南充市'
      },
      {
        label: '中国工商银行云南迪庆分行香格里拉长征北路支行',
        value: '102757014032|5334|云南省|迪庆藏族自治州'
      },
      {
        label: '中国工商银行云南临沧分行',
        value: '102758018011|5309|云南省|临沧市'
      },
      {
        label: '中国工商银行云南临沧分行临翔支行',
        value: '102758018020|5309|云南省|临沧市'
      },
      {
        label: '中国工商银行云南临沧分行亚洲微电影支行',
        value: '102758018095|5309|云南省|临沧市'
      },
      {
        label: '中国工商银行云南临沧分行南塘支行',
        value: '102758018100|5309|云南省|临沧市'
      },
      {
        label: '中国工商银行云南临沧分行滨河支行',
        value: '102758018118|5309|云南省|临沧市'
      },
      {
        label: '中国工商银行云南临沧分行凤翔支行',
        value: '102758018126|5309|云南省|临沧市'
      },
      {
        label: '中国工商银行云南临沧分行旗山支行',
        value: '102758018134|5309|云南省|临沧市'
      },
      {
        label: '中国工商银行西藏自治区分行营业部',
        value: '102770000016|5401|西藏自治区|拉萨市'
      },
      {
        label: '中国工商银行股份有限公司拉萨色拉路支行',
        value: '102770000024|5401|西藏自治区|拉萨市'
      },
      {
        label: '中国工商银行股份有限公司贵阳八鸽岩支行',
        value: '102701000955|5201|贵州省|贵阳市'
      },
      {
        label: '中国工商银行股份有限公司贵阳飞山街支行',
        value: '102701000963|5201|贵州省|贵阳市'
      },
      {
        label: '中国工商银行股份有限公司清镇东门桥支行',
        value: '102701000971|5201|贵州省|贵阳市'
      },
      {
        label: '中国工商银行股份有限公司贵阳金仓路支行',
        value: '102701000998|5201|贵州省|贵阳市'
      },
      {
        label: '中国工商银行咸阳分行业务处理中心',
        value: '102795045004|6104|陕西省|咸阳市'
      },
      {
        label: '中国工商银行兴平市支行',
        value: '102795140164|6104|陕西省|咸阳市'
      },
      {
        label: '工行陕西省兴平市西城支行',
        value: '102795140172|6104|陕西省|咸阳市'
      },
      {
        label: '工行陕西省兴平市板桥支行',
        value: '102795145094|6104|陕西省|咸阳市'
      },
      {
        label: '中国工商银行股份有限公司兰州静宁路支行',
        value: '102821031235|6201|甘肃省|兰州市'
      },
      {
        label: '中国工商银行股份有限公司兰州永昌北路支行',
        value: '102821031260|6201|甘肃省|兰州市'
      },
      {
        label: '中国工商银行股份有限公司兰州乔家营支行',
        value: '102821032289|6201|甘肃省|兰州市'
      },
      {
        label: '中国工商银行股份有限公司泸州红星支行',
        value: '102657034849|5105|四川省|泸州市'
      },
      {
        label: '中国工商银行股份有限公司泸县玉蟾支行',
        value: '102657102417|5105|四川省|泸州市'
      },
      {
        label: '中国工商银行股份有限公司泸州高坝支行',
        value: '102657134821|5105|四川省|泸州市'
      },
      {
        label: '中国工商银行股份有限公司泸县支行',
        value: '102657134864|5105|四川省|泸州市'
      },
      {
        label: '中国工商银行股份有限公司泸县龙城支行',
        value: '102657134889|5105|四川省|泸州市'
      },
      {
        label: '中国工商银行股份有限公司合江招商城支行',
        value: '102657202930|5105|四川省|泸州市'
      },
      {
        label: '中国工商银行股份有限公司合江支行',
        value: '102657234918|5105|四川省|泸州市'
      },
      {
        label: '中国工商银行股份有限公司合江少岷路支行',
        value: '102657234959|5105|四川省|泸州市'
      },
      {
        label: '中国工商银行股份有限公司泸州纳溪支行',
        value: '102657334414|5105|四川省|泸州市'
      },
      {
        label: '中国工商银行股份有限公司泸州安富支行',
        value: '102657334422|5105|四川省|泸州市'
      },
      {
        label: '中国工商银行股份有限公司叙永支行',
        value: '102657435016|5105|四川省|泸州市'
      },
      {
        label: '中国工商银行股份有限公司叙永扬武坊支行',
        value: '102657435024|5105|四川省|泸州市'
      },
      {
        label: '中国工商银行股份有限公司古蔺支行',
        value: '102657535116|5105|四川省|泸州市'
      },
      {
        label: '中国工商银行股份有限公司古蔺东城支行',
        value: '102657535132|5105|四川省|泸州市'
      },
      {
        label: '中国工商银行股份有限公司德阳开发区支行',
        value: '102658001041|5106|四川省|德阳市'
      },
      {
        label: '中国工商银行股份有限公司德阳分行工农村分理处',
        value: '102658003029|5106|四川省|德阳市'
      },
      {
        label: '中国工商银行股份有限公司兰州秦安路支行',
        value: '102821032310|6201|甘肃省|兰州市'
      },
      {
        label: '中国工商银行股份有限公司兰州武都路支行',
        value: '102821032377|6201|甘肃省|兰州市'
      },
      {
        label: '中国工商银行股份有限公司兰州大砂坪支行',
        value: '102821032432|6201|甘肃省|兰州市'
      },
      {
        label: '中国工商银行股份有限公司兰州桥北支行',
        value: '102821032490|6201|甘肃省|兰州市'
      },
      {
        label: '中国工商银行股份有限公司兰州亚欧支行',
        value: '102821032537|6201|甘肃省|兰州市'
      },
      {
        label: '中国工商银行股份有限公司兰州东岗东路第二支行',
        value: '102821033290|6201|甘肃省|兰州市'
      },
      {
        label: '中国工商银行股份有限公司兰州东岗镇支行',
        value: '102821033345|6201|甘肃省|兰州市'
      },
      {
        label: '中国工商银行股份有限公司兰州定西路支行',
        value: '102821033361|6201|甘肃省|兰州市'
      },
      {
        label: '中国工商银行股份有限公司兰州瑞德摩尔支行',
        value: '102821033388|6201|甘肃省|兰州市'
      },
      {
        label: '中国工商银行股份有限公司兰州嘉峪关北路支行',
        value: '102821033458|6201|甘肃省|兰州市'
      },
      {
        label: '中国工商银行股份有限公司兰州牟家庄支行',
        value: '102821033466|6201|甘肃省|兰州市'
      },
      {
        label: '中国工商银行股份有限公司兰州榆中广场支行',
        value: '102821033860|6201|甘肃省|兰州市'
      },
      {
        label: '中国工商银行股份有限公司兰州敦煌路第二支行',
        value: '102821034266|6201|甘肃省|兰州市'
      },
      {
        label: '中国工商银行股份有限公司兰州杨家桥第二支行',
        value: '102821034303|6201|甘肃省|兰州市'
      },
      {
        label: '中国工商银行股份有限公司兰州龚家坪支行',
        value: '102821034426|6201|甘肃省|兰州市'
      },
      {
        label: '中国工商银行股份有限公司兰州兰工坪支行',
        value: '102821034539|6201|甘肃省|兰州市'
      },
      {
        label: '中国工商银行股份有限公司兰州金港城支行',
        value: '102821034571|6201|甘肃省|兰州市'
      },
      {
        label: '中国工商银行股份有限公司兰州西津东路第三支行',
        value: '102821034602|6201|甘肃省|兰州市'
      },
      {
        label: '中国工商银行股份有限公司兰州西固福利路第二支行',
        value: '102821035255|6201|甘肃省|兰州市'
      },
      {
        label: '中国工商银行股份有限公司兰州庄浪路第二支行',
        value: '102821035263|6201|甘肃省|兰州市'
      },
      {
        label: '中国工商银行股份有限公司兰州山丹街第一支行',
        value: '102821035302|6201|甘肃省|兰州市'
      },
      {
        label: '中国工商银行股份有限公司兰州山丹街第二支行',
        value: '102821035319|6201|甘肃省|兰州市'
      },
      {
        label: '中国工商银行股份有限公司兰州西固东路支行',
        value: '102821035343|6201|甘肃省|兰州市'
      },
      {
        label: '中国工商银行股份有限公司兰州临洮街支行',
        value: '102821035378|6201|甘肃省|兰州市'
      },
      {
        label: '中国工商银行股份有限公司兰州西固福利路第三支行',
        value: '102821035417|6201|甘肃省|兰州市'
      },
      {
        label: '中国工商银行股份有限公司兰州十里店支行',
        value: '102821036217|6201|甘肃省|兰州市'
      },
      {
        label: '中国工商银行股份有限公司兰州科教城支行',
        value: '102821036233|6201|甘肃省|兰州市'
      },
      {
        label: '中国工商银行股份有限公司兰州银安路支行',
        value: '102821036241|6201|甘肃省|兰州市'
      },
      {
        label: '中国工商银行武汉市汉口支行',
        value: '102521000011|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行武汉市牡丹支行',
        value: '102521000020|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行武汉市江岸区支行',
        value: '102521000038|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行武汉市黄浦支行',
        value: '102521000046|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行武汉市四唯路支行',
        value: '102521000054|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行股份有限公司德阳分行岷江西路分理处',
        value: '102658003053|5106|四川省|德阳市'
      },
      {
        label: '中国工商银行股份有限公司嘉峪关酒钢支行',
        value: '102822000195|6202|甘肃省|嘉峪关市'
      },
      {
        label: '中国工商银行股份有限公司昆明分行营业室',
        value: '102731002023|5301|云南省|昆明市'
      },
      {
        label: '中国工商银行昆明南屏支行',
        value: '102731002031|5301|云南省|昆明市'
      },
      {
        label: '中国工商银行昆明正义支行',
        value: '102731002040|5301|云南省|昆明市'
      },
      {
        label: '中国工商银行昆明护国支行',
        value: '102731002058|5301|云南省|昆明市'
      },
      {
        label: '中国工商银行武汉市车站路支行',
        value: '102521000079|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行武汉市球场路支行',
        value: '102521000087|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行武汉市长江支行',
        value: '102521000095|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行武汉市江汉区支行',
        value: '102521000118|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行武汉市民族路支行',
        value: '102521000126|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行武汉市汉北支行',
        value: '102521000134|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行武汉市西北湖支行',
        value: '102521000142|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行武汉市中山大道支行',
        value: '102521000159|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行股份有限公司武汉王家巷支行',
        value: '102521000175|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行武汉市航空路支行',
        value: '102521000183|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行武汉市桥口区支行',
        value: '102521000206|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行武汉市机场河支行',
        value: '102521000214|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行武汉市古田支行',
        value: '102521000239|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行股份有限公司武汉宗关支行',
        value: '102521000255|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行武汉市发展大道分理处',
        value: '102521000263|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行股份有限公司武汉武昌支行',
        value: '102521000271|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行武汉市徐家棚支行',
        value: '102521000280|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行武汉市水果湖支行',
        value: '102521000298|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行武汉市紫阳路支行',
        value: '102521000302|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行武汉市积玉桥支行',
        value: '102521000319|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行武汉市武珞路支行',
        value: '102521000335|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行武汉市徐东路支行',
        value: '102521000343|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行武汉市民航新村支行',
        value: '102521000351|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行武汉市洪山区支行',
        value: '102521000360|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行武汉市科技支行',
        value: '102521000378|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行武汉市关山支行',
        value: '102521000386|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行武汉市关东工业园支行',
        value: '102521000394|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行武汉市汉阳区支行',
        value: '102521000409|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行武汉市鹦鹉洲支行',
        value: '102521000417|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行武汉市江汉二桥支行',
        value: '102521000425|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行武汉市五里支行',
        value: '102521000433|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行股份有限公司武汉黄金口支行',
        value: '102521000441|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行武汉市青山区支行',
        value: '102521000450|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行武汉市武东支行',
        value: '102521000468|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行武汉市长青路支行',
        value: '102521000476|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行武汉市武钢支行',
        value: '102521000484|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行股份有限公司德阳旌南支行',
        value: '102658003088|5106|四川省|德阳市'
      },
      {
        label: '中国工商银行股份有限公司德阳玉泉支行',
        value: '102658005026|5106|四川省|德阳市'
      },
      {
        label: '中国工商银行股份有限公司德阳分行银杏花园分理处',
        value: '102658005091|5106|四川省|德阳市'
      },
      {
        label: '中国工商银行德阳市分行会计业务处理中心',
        value: '102658036116|5106|四川省|德阳市'
      },
      {
        label: '中国工商银行德阳钟鼓楼支行',
        value: '102658036212|5106|四川省|德阳市'
      },
      {
        label: '中国工商银行股份有限公司德阳西干道支行',
        value: '102658036229|5106|四川省|德阳市'
      },
      {
        label: '中国工商银行股份有限公司凯里新城东路支行',
        value: '102713000522|5226|贵州省|黔东南苗族侗族自治州'
      },
      {
        label: '中国工商银行昆明碧鸡支行',
        value: '102731010689|5301|云南省|昆明市'
      },
      {
        label: '中国工商银行昆明虹山支行',
        value: '102731010728|5301|云南省|昆明市'
      },
      {
        label: '中国工商银行德阳市城北支行',
        value: '102658036270|5106|四川省|德阳市'
      },
      {
        label: '中国工商银行德阳东大街支行',
        value: '102658036315|5106|四川省|德阳市'
      },
      {
        label: '中国工商银行德阳市旌东支行',
        value: '102658036331|5106|四川省|德阳市'
      },
      {
        label: '中国工商银行德阳市城南支行',
        value: '102658036340|5106|四川省|德阳市'
      },
      {
        label: '中国工商银行股份有限公司德阳庐山支行',
        value: '102658036366|5106|四川省|德阳市'
      },
      {
        label: '中国工商银行股份有限公司德阳分行黄河分理处',
        value: '102658036374|5106|四川省|德阳市'
      },
      {
        label: '中国工商银行股份有限公司德阳旌阳支行',
        value: '102658062053|5106|四川省|德阳市'
      },
      {
        label: '中国工商银行股份有限公司绵竹玉妃路支行',
        value: '102658100066|5106|四川省|德阳市'
      },
      {
        label: '中国工商银行股份有限公司兰州兰化支行',
        value: '102821005001|6201|甘肃省|兰州市'
      },
      {
        label: '中国工商银行股份有限公司兰州中山支行',
        value: '102821005010|6201|甘肃省|兰州市'
      },
      {
        label: '中国工商银行股份有限公司兰州建兰路支行',
        value: '102821009891|6201|甘肃省|兰州市'
      },
      {
        label: '中国工商银行股份有限公司兰州文化宫支行',
        value: '102821010022|6201|甘肃省|兰州市'
      },
      {
        label: '中国工商银行股份有限公司兰州民航局支行',
        value: '102821010039|6201|甘肃省|兰州市'
      },
      {
        label: '中国工商银行股份有限公司兰州牡丹支行',
        value: '102821010047|6201|甘肃省|兰州市'
      },
      {
        label: '中国工商银行股份有限公司平坝中山南路支行',
        value: '102711700323|5204|贵州省|安顺市'
      },
      {
        label: '中国工商银行普定县支行',
        value: '102711800184|5204|贵州省|安顺市'
      },
      {
        label: '中国工商银行贵州省关岭县支行',
        value: '102711900263|5204|贵州省|安顺市'
      },
      {
        label: '中国工商银行镇宁县支行',
        value: '102712100179|5204|贵州省|安顺市'
      },
      {
        label: '中国工商银行股份有限公司贵州省安顺紫云支行',
        value: '102712200240|5204|贵州省|安顺市'
      },
      {
        label: '中国工商银行贵州省凯里市北京路支行',
        value: '102713000065|5226|贵州省|黔东南苗族侗族自治州'
      },
      {
        label: '中国工商银行贵州省凯里市博南支行',
        value: '102713000073|5226|贵州省|黔东南苗族侗族自治州'
      },
      {
        label: '中国工商银行股份有限公司凯里开发区支行',
        value: '102713000081|5226|贵州省|黔东南苗族侗族自治州'
      },
      {
        label: '中国工商银行股份有限公司凯里体育馆支行',
        value: '102713000090|5226|贵州省|黔东南苗族侗族自治州'
      },
      {
        label: '中国工商银行股份有限公司凯里东城支行',
        value: '102713000104|5226|贵州省|黔东南苗族侗族自治州'
      },
      {
        label: '中国工商银行贵州省凯里分行银行卡部',
        value: '102713000129|5226|贵州省|黔东南苗族侗族自治州'
      },
      {
        label: '中国工商银行股份有限公司兰州西固支行',
        value: '102821000136|6201|甘肃省|兰州市'
      },
      {
        label: '中国工商银行股份有限公司兰州拱星墩支行',
        value: '102821000152|6201|甘肃省|兰州市'
      },
      {
        label: '中国工商银行股份有限公司兰州庆阳路支行',
        value: '102821000169|6201|甘肃省|兰州市'
      },
      {
        label: '中国工商银行股份有限公司兰州西津东路支行',
        value: '102821000177|6201|甘肃省|兰州市'
      },
      {
        label: '中国工商银行股份有限公司兰州费家营支行',
        value: '102821000185|6201|甘肃省|兰州市'
      },
      {
        label: '中国工商银行股份有限公司兰州住房城建支行',
        value: '102821000193|6201|甘肃省|兰州市'
      },
      {
        label: '中国工商银行股份有限公司兰州广武门支行',
        value: '102821000216|6201|甘肃省|兰州市'
      },
      {
        label: '中国工商银行股份有限公司兰州五泉支行',
        value: '102821000224|6201|甘肃省|兰州市'
      },
      {
        label: '中国工商银行股份有限公司兰州民主西路支行',
        value: '102821000232|6201|甘肃省|兰州市'
      },
      {
        label: '中国工商银行股份有限公司兰州天水路支行',
        value: '102821000249|6201|甘肃省|兰州市'
      },
      {
        label: '中国工商银行股份有限公司兰州新区支行',
        value: '102821000257|6201|甘肃省|兰州市'
      },
      {
        label: '中国工商银行股份有限公司兰州临夏路支行',
        value: '102821000265|6201|甘肃省|兰州市'
      },
      {
        label: '中国工商银行股份有限公司兰州永昌路支行',
        value: '102821000273|6201|甘肃省|兰州市'
      },
      {
        label: '中国工商银行股份有限公司兰州嘉峪关路支行',
        value: '102821000281|6201|甘肃省|兰州市'
      },
      {
        label: '中国工商银行股份有限公司兰州培黎广场支行',
        value: '102821000290|6201|甘肃省|兰州市'
      },
      {
        label: '中国工商银行股份有限公司兰州河口支行',
        value: '102821000312|6201|甘肃省|兰州市'
      },
      {
        label: '中国工商银行股份有限公司兰州西固西路支行',
        value: '102821000337|6201|甘肃省|兰州市'
      },
      {
        label: '中国工商银行股份有限公司兰州西固庄浪路支行',
        value: '102821000345|6201|甘肃省|兰州市'
      },
      {
        label: '中国工商银行股份有限公司兰州西固玉门街支行',
        value: '102821000353|6201|甘肃省|兰州市'
      },
      {
        label: '中国工商银行股份有限公司甘肃省分行营业部银行卡中心',
        value: '102821000370|6201|甘肃省|兰州市'
      },
      {
        label: '中国工商银行股份有限公司攀枝花建设街支行',
        value: '102656032828|5104|四川省|攀枝花市'
      },
      {
        label: '中国工商银行股份有限公司攀枝花望江支行',
        value: '102656033015|5104|四川省|攀枝花市'
      },
      {
        label: '中国工商银行股份有限公司攀枝花分行',
        value: '102656033218|5104|四川省|攀枝花市'
      },
      {
        label: '中国工商银行股份有限公司攀枝花米易支行',
        value: '102656132917|5104|四川省|攀枝花市'
      },
      {
        label: '中国工商银行股份有限公司米易城北支行',
        value: '102656132925|5104|四川省|攀枝花市'
      },
      {
        label: '中国工商银行股份有限公司盐边支行',
        value: '102656200279|5104|四川省|攀枝花市'
      },
      {
        label: '中国工商银行股份有限公司泸州柏香林支行',
        value: '102657000012|5105|四川省|泸州市'
      },
      {
        label: '中国工商银行股份有限公司泸州忠山支行',
        value: '102657001267|5105|四川省|泸州市'
      },
      {
        label: '中国工商银行股份有限公司泸州小市支行',
        value: '102657002026|5105|四川省|泸州市'
      },
      {
        label: '中国工商银行股份有限公司泸州龙南支行',
        value: '102657002464|5105|四川省|泸州市'
      },
      {
        label: '中国工商银行股份有限公司泸州分行业务处理中心',
        value: '102657034111|5105|四川省|泸州市'
      },
      {
        label: '中国工商银行股份有限公司泸州江阳支行',
        value: '102657034314|5105|四川省|泸州市'
      },
      {
        label: '中国工商银行股份有限公司泸州百子图支行',
        value: '102657034322|5105|四川省|泸州市'
      },
      {
        label: '中国工商银行股份有限公司泸州世纪城支行',
        value: '102657034339|5105|四川省|泸州市'
      },
      {
        label: '中国工商银行股份有限公司泸州江阳南路支行',
        value: '102657034347|5105|四川省|泸州市'
      },
      {
        label: '中国工商银行股份有限公司泸州大山坪支行',
        value: '102657034355|5105|四川省|泸州市'
      },
      {
        label: '中国工商银行股份有限公司泸州兰田支行',
        value: '102657034363|5105|四川省|泸州市'
      },
      {
        label: '中国工商银行股份有限公司泸州高新支行',
        value: '102657034371|5105|四川省|泸州市'
      },
      {
        label: '中国工商银行股份有限公司泸州龙透关支行',
        value: '102657034398|5105|四川省|泸州市'
      },
      {
        label: '中国工商银行股份有限公司泸州自贸区支行',
        value: '102657034435|5105|四川省|泸州市'
      },
      {
        label: '中国工商银行股份有限公司泸州城西支行',
        value: '102657034613|5105|四川省|泸州市'
      },
      {
        label: '中国工商银行股份有限公司泸州分行',
        value: '102657034710|5105|四川省|泸州市'
      },
      {
        label: '中国工商银行股份有限公司泸州龙马潭支行',
        value: '102657034816|5105|四川省|泸州市'
      },
      {
        label: '中国工商银行股份有限公司绵阳分行城东支行',
        value: '102659000555|5107|四川省|绵阳市'
      },
      {
        label: '中国工商银行股份有限公司绵阳科学城支行九区分理处',
        value: '102659000651|5107|四川省|绵阳市'
      },
      {
        label: '中国工商银行股份有限公司绵阳科学城支行七区分理处',
        value: '102659000709|5107|四川省|绵阳市'
      },
      {
        label: '中国工商银行股份有限公司绵阳火炬支行',
        value: '102659000813|5107|四川省|绵阳市'
      },
      {
        label: '中国工商银行股份有限公司四川省绵阳安昌支行',
        value: '102659000928|5107|四川省|绵阳市'
      },
      {
        label: '中国工商银行股份有限公司绵阳分行业务处理中心',
        value: '102659041117|5107|四川省|绵阳市'
      },
      {
        label: '中国工商银行股份有限公司绵阳涪城支行',
        value: '102659041213|5107|四川省|绵阳市'
      },
      {
        label: '中国工商银行昆明江东支行',
        value: '102731001375|5301|云南省|昆明市'
      },
      {
        label: '中国工商银行昆明小坝支行',
        value: '102731001414|5301|云南省|昆明市'
      },
      {
        label: '中国工商银行昆明红云支行',
        value: '102731001422|5301|云南省|昆明市'
      },
      {
        label: '中国工商银行股份有限公司昆明茨坝支行',
        value: '102731001439|5301|云南省|昆明市'
      },
      {
        label: '中国工商银行股份有限公司昆明环南支行',
        value: '102731001455|5301|云南省|昆明市'
      },
      {
        label: '中国工商银行昆明东华支行',
        value: '102731001463|5301|云南省|昆明市'
      },
      {
        label: '中国工商银行昆明西市区支行黑林铺分理处',
        value: '102731001617|5301|云南省|昆明市'
      },
      {
        label: '中国工商银行昆明马街支行',
        value: '102731001625|5301|云南省|昆明市'
      },
      {
        label: '中国工商银行昆明王家桥支行',
        value: '102731001633|5301|云南省|昆明市'
      },
      {
        label: '中国工商银行兴平市支行兴玻分理处',
        value: '102795145109|6104|陕西省|咸阳市'
      },
      {
        label: '中国工商银行昆明兴苑路支行',
        value: '102731001641|5301|云南省|昆明市'
      },
      {
        label: '中国工商银行股份有限公司兰州红古十一支行',
        value: '102821002677|6201|甘肃省|兰州市'
      },
      {
        label: '中国工商银行股份有限公司兰州永登支行',
        value: '102821002685|6201|甘肃省|兰州市'
      },
      {
        label: '中国工商银行股份有限公司兰州中堡支行',
        value: '102821002693|6201|甘肃省|兰州市'
      },
      {
        label: '中国工商银行股份有限公司兰州永登建设坪支行',
        value: '102821002708|6201|甘肃省|兰州市'
      },
      {
        label: '中国工商银行股份有限公司兰州皋兰支行',
        value: '102821002716|6201|甘肃省|兰州市'
      },
      {
        label: '中国工商银行股份有限公司兰州农民巷支行',
        value: '102821002812|6201|甘肃省|兰州市'
      },
      {
        label: '中国工商银行股份有限公司兰州莫高路支行',
        value: '102821002829|6201|甘肃省|兰州市'
      },
      {
        label: '中国工商银行股份有限公司永登纬五路支行',
        value: '102821002837|6201|甘肃省|兰州市'
      },
      {
        label: '中国工商银行股份有限公司兰州火星街支行',
        value: '102821002845|6201|甘肃省|兰州市'
      },
      {
        label: '中国工商银行股份有限公司兰州北滨河路支行',
        value: '102821002853|6201|甘肃省|兰州市'
      },
      {
        label: '中国工商银行股份有限公司兰州盐场堡支行',
        value: '102821002861|6201|甘肃省|兰州市'
      },
      {
        label: '中国工商银行武汉市红卫路支行',
        value: '102521000708|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行武汉市永清支行',
        value: '102521000716|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行武汉市汉正街支行',
        value: '102521000724|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行武汉市丹水池支行',
        value: '102521000732|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行武汉市泰合支行',
        value: '102521000757|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行武汉市杨园支行',
        value: '102521000765|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行武汉凯旋门支行',
        value: '102521000773|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行武汉彩电中心支行',
        value: '102521000781|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行武汉华府支行',
        value: '102521000790|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行武汉市长委支行',
        value: '102521000804|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行股份有限公司武汉邮科院支行',
        value: '102521000829|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行股份有限公司武汉台北路支行',
        value: '102521000837|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行武汉市三眼桥支行',
        value: '102521000845|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行股份有限公司崇左市江州支行',
        value: '102612812303|4514|广西壮族自治区|崇左市'
      },
      {
        label: '中国工商银行信阳分行罗山县支行',
        value: '102515902212|4115|河南省|信阳市'
      },
      {
        label: '中国工商银行信阳分行新县支行',
        value: '102516102273|4115|河南省|信阳市'
      },
      {
        label: '中国工商银行股份有限公司新县解放路支行',
        value: '102516112271|4115|河南省|信阳市'
      },
      {
        label: '中国工商银行股份有限公司绵竹广场支行',
        value: '102658106014|5106|四川省|德阳市'
      },
      {
        label: '中国工商银行股份有限公司绵竹南桥支行',
        value: '102658106022|5106|四川省|德阳市'
      },
      {
        label: '中国工商银行股份有限公司绵竹支行东门支行',
        value: '102658106039|5106|四川省|德阳市'
      },
      {
        label: '中国工商银行绵竹市支行',
        value: '102658137019|5106|四川省|德阳市'
      },
      {
        label: '中国工商银行德阳东汽支行',
        value: '102658137310|5106|四川省|德阳市'
      },
      {
        label: '中国工商银行股份有限公司中江凯丰支行',
        value: '102658201958|5106|四川省|德阳市'
      },
      {
        label: '中国工商银行股份有限公司中江中市街支行',
        value: '102658207026|5106|四川省|德阳市'
      },
      {
        label: '中国工商银行中江县支行',
        value: '102658237217|5106|四川省|德阳市'
      },
      {
        label: '中国工商银行股份有限公司什邡小花园街支行',
        value: '102658301921|5106|四川省|德阳市'
      },
      {
        label: '中国工商银行股份有限公司什邡金河南路支行',
        value: '102658308100|5106|四川省|德阳市'
      },
      {
        label: '中国工商银行股份有限公司什邡竹园路支行',
        value: '102658308118|5106|四川省|德阳市'
      },
      {
        label: '中国工商银行什邡市支行',
        value: '102658337111|5106|四川省|德阳市'
      },
      {
        label: '中国工商银行广汉飞行学院支行',
        value: '102658400106|5106|四川省|德阳市'
      },
      {
        label: '中国工商银行股份有限公司广汉韶山路支行',
        value: '102658402048|5106|四川省|德阳市'
      },
      {
        label: '中国工商银行广汉市支行',
        value: '102658436910|5106|四川省|德阳市'
      },
      {
        label: '中国工商银行股份有限公司广汉支行中山大道分理处',
        value: '102658436928|5106|四川省|德阳市'
      },
      {
        label: '中国工商银行股份有限公司广汉武昌路支行',
        value: '102658436952|5106|四川省|德阳市'
      },
      {
        label: '中国工商银行股份有限公司广汉佛山路支行',
        value: '102658436985|5106|四川省|德阳市'
      },
      {
        label: '中国工商银行股份有限公司罗江金山支行',
        value: '102658501965|5106|四川省|德阳市'
      },
      {
        label: '中国工商银行罗江县支行',
        value: '102658536816|5106|四川省|德阳市'
      },
      {
        label: '中国工商银行股份有限公司绵阳金三角支行',
        value: '102659000057|5107|四川省|绵阳市'
      },
      {
        label: '中国工商银行股份有限公司四川省绵阳解放街支行',
        value: '102659000137|5107|四川省|绵阳市'
      },
      {
        label: '中国工商银行股份有限公司四川省绵阳南桥支行',
        value: '102659000161|5107|四川省|绵阳市'
      },
      {
        label: '中国工商银行股份有限公司四川省绵阳涪城路支行',
        value: '102659000188|5107|四川省|绵阳市'
      },
      {
        label: '中国工商银行股份有限公司绵阳城北支行',
        value: '102659000223|5107|四川省|绵阳市'
      },
      {
        label: '中国工商银行股份有限公司四川省绵阳跃进路支行',
        value: '102659000231|5107|四川省|绵阳市'
      },
      {
        label: '中国工商银行股份有限公司绵阳西山支行',
        value: '102659000258|5107|四川省|绵阳市'
      },
      {
        label: '中国工商银行股份有限公司四川省绵阳世纪城支行',
        value: '102659000274|5107|四川省|绵阳市'
      },
      {
        label: '中国工商银行股份有限公司绵阳荷花支行',
        value: '102659000418|5107|四川省|绵阳市'
      },
      {
        label: '中国工商银行股份有限公司绵阳花园支行',
        value: '102659000475|5107|四川省|绵阳市'
      },
      {
        label: '中国工商银行股份有限公司四川省绵阳剑门支行',
        value: '102659000491|5107|四川省|绵阳市'
      },
      {
        label: '中国工商银行股份有限公司绵阳开元支行',
        value: '102659000514|5107|四川省|绵阳市'
      },
      {
        label: '中国工商银行南京迈皋桥支行',
        value: '102301000374|3201|江苏省|南京市'
      },
      {
        label: '中国工商银行南京白下支行',
        value: '102301000173|3201|江苏省|南京市'
      },
      {
        label: '中国工商银行股份有限公司南京麒麟科创园分理处',
        value: '102301000181|3201|江苏省|南京市'
      },
      {
        label: '中国工商银行南京成贤街支行',
        value: '102301000190|3201|江苏省|南京市'
      },
      {
        label: '中国工商银行南京热河路支行',
        value: '102301000204|3201|江苏省|南京市'
      },
      {
        label: '中国工商银行股份有限公司南京石鼓路支行',
        value: '102301000212|3201|江苏省|南京市'
      },
      {
        label: '中国工商银行股份有限公司南京苏源大道支行',
        value: '102301000229|3201|江苏省|南京市'
      },
      {
        label: '中国工商银行南京中山桥支行',
        value: '102301000237|3201|江苏省|南京市'
      },
      {
        label: '中国工商银行股份有限公司南京郁金香路支行',
        value: '102301000245|3201|江苏省|南京市'
      },
      {
        label: '中国工商银行南京孝陵卫支行',
        value: '102301000253|3201|江苏省|南京市'
      },
      {
        label: '中国工商银行南京市新街口支行',
        value: '102301000261|3201|江苏省|南京市'
      },
      {
        label: '中国工商银行股份有限公司南京紫东路支行',
        value: '102301000270|3201|江苏省|南京市'
      },
      {
        label: '中国工商银行南京湖南路支行',
        value: '102301000288|3201|江苏省|南京市'
      },
      {
        label: '中国工商银行南京察哈尔路支行',
        value: '102301000296|3201|江苏省|南京市'
      },
      {
        label: '中国工商银行股份有限公司南京新马路支行',
        value: '102301000093|3201|江苏省|南京市'
      },
      {
        label: '中国工商银行南京市大厂支行',
        value: '102301000108|3201|江苏省|南京市'
      },
      {
        label: '中国工商银行江苏省分行营业部',
        value: '102301000116|3201|江苏省|南京市'
      },
      {
        label: '中国工商银行南京市高新技术开发区支行',
        value: '102301000124|3201|江苏省|南京市'
      },
      {
        label: '中国工商银行南京军管支行',
        value: '102301000132|3201|江苏省|南京市'
      },
      {
        label: '中国工商银行南京汉中门支行',
        value: '102301000149|3201|江苏省|南京市'
      },
      {
        label: '中国工商银行南京大方巷支行',
        value: '102301000157|3201|江苏省|南京市'
      },
      {
        label: '中国工商银行南京汉口路支行',
        value: '102301000165|3201|江苏省|南京市'
      },
      {
        label: '中国工商银行溧水县支行',
        value: '102301000399|3201|江苏省|南京市'
      },
      {
        label: '中国工商银行股份有限公司南京燕江路支行',
        value: '102301000403|3201|江苏省|南京市'
      },
      {
        label: '中国工商银行高淳县支行',
        value: '102301000420|3201|江苏省|南京市'
      },
      {
        label: '中国工商银行南京板仓支行',
        value: '102301000438|3201|江苏省|南京市'
      },
      {
        label: '中国工商银行南京燕子矶支行',
        value: '102301000446|3201|江苏省|南京市'
      },
      {
        label: '中国工商银行南京市浦阳分理处',
        value: '102301000454|3201|江苏省|南京市'
      },
      {
        label: '中国工商银行股份有限公司南京浦珠路支行',
        value: '102301000462|3201|江苏省|南京市'
      },
      {
        label: '中国工商银行南京南湖支行',
        value: '102301000487|3201|江苏省|南京市'
      },
      {
        label: '中国工商银行南京小营支行',
        value: '102301000495|3201|江苏省|南京市'
      },
      {
        label: '中国工商银行南京市江宁支行',
        value: '102301000526|3201|江苏省|南京市'
      },
      {
        label: '中国工商银行南京江浦支行',
        value: '102301000534|3201|江苏省|南京市'
      },
      {
        label: '中国工商银行南京六合支行',
        value: '102301000542|3201|江苏省|南京市'
      },
      {
        label: '中国工商银行南京市城西支行',
        value: '102301000559|3201|江苏省|南京市'
      },
      {
        label: '中国工商银行南京宁海路支行',
        value: '102301000307|3201|江苏省|南京市'
      },
      {
        label: '中国工商银行南京雨花西路支行',
        value: '102301000315|3201|江苏省|南京市'
      },
      {
        label: '中国工商银行南京光华门支行',
        value: '102301000323|3201|江苏省|南京市'
      },
      {
        label: '中国工商银行南京市鼓楼支行',
        value: '102301000331|3201|江苏省|南京市'
      },
      {
        label: '中国工商银行南京梅山支行',
        value: '102301000340|3201|江苏省|南京市'
      },
      {
        label: '中国工商银行南京栖霞支行',
        value: '102301000358|3201|江苏省|南京市'
      },
      {
        label: '中国工商银行南京尧化门支行',
        value: '102301000366|3201|江苏省|南京市'
      },
      {
        label: '中国工商银行南京市汉府支行',
        value: '102301000567|3201|江苏省|南京市'
      },
      {
        label: '中国工商银行股份有限公司南京长江路支行',
        value: '102301000583|3201|江苏省|南京市'
      },
      {
        label: '中国工商银行股份有限公司南京奥体支行',
        value: '102301000591|3201|江苏省|南京市'
      },
      {
        label: '中国工商银行南京市御道街分理处',
        value: '102301000614|3201|江苏省|南京市'
      },
      {
        label: '中国工商银行南京草场门支行',
        value: '102301000622|3201|江苏省|南京市'
      },
      {
        label: '中国工商银行南京虹桥支行',
        value: '102301000639|3201|江苏省|南京市'
      },
      {
        label: '中国工商银行股份有限公司南京雨润大街支行',
        value: '102301000647|3201|江苏省|南京市'
      },
      {
        label: '中国工商银行股份有限公司南京城河村支行',
        value: '102301000655|3201|江苏省|南京市'
      },
      {
        label: '中国工商银行股份有限公司南京海福巷支行',
        value: '102301000671|3201|江苏省|南京市'
      },
      {
        label: '中国工商银行南京市新港支行',
        value: '102301000680|3201|江苏省|南京市'
      },
      {
        label: '中国工商银行股份有限公司南京解放路支行',
        value: '102301000698|3201|江苏省|南京市'
      },
      {
        label: '中国工商银行股份有限公司南京南京仙林湖支行',
        value: '102301000702|3201|江苏省|南京市'
      },
      {
        label: '中国工商银行股份有限公司南京高楼门支行',
        value: '102301000743|3201|江苏省|南京市'
      },
      {
        label: '中国工商银行股份有限公司南京新安江街支行',
        value: '102301000751|3201|江苏省|南京市'
      },
      {
        label: '中国工商银行南京西善桥支行',
        value: '102301000760|3201|江苏省|南京市'
      },
      {
        label: '中国工商银行南京市江东门分理处',
        value: '102301000778|3201|江苏省|南京市'
      },
      {
        label: '中国工商银行南京和平支行',
        value: '102301000786|3201|江苏省|南京市'
      },
      {
        label: '中国工商银行南京市山畔分理处',
        value: '102301000794|3201|江苏省|南京市'
      },
      {
        label: '中国工商银行南京后宰门支行',
        value: '102301000809|3201|江苏省|南京市'
      },
      {
        label: '中国工商银行股份有限公司南京汉中路支行',
        value: '102301000817|3201|江苏省|南京市'
      },
      {
        label: '中国工商银行股份有限公司溧水柘塘支行',
        value: '102301002031|3201|江苏省|南京市'
      },
      {
        label: '中国工商银行股份有限公司南京万达广场支行',
        value: '102301002040|3201|江苏省|南京市'
      },
      {
        label: '中国工商银行股份有限公司南京徐庄软件园支行',
        value: '102301002058|3201|江苏省|南京市'
      },
      {
        label: '中国工商银行股份有限公司南京滨江新城支行',
        value: '102301002066|3201|江苏省|南京市'
      },
      {
        label: '中国工商银行股份有限公司高淳北岭路支行',
        value: '102301002074|3201|江苏省|南京市'
      },
      {
        label: '中国工商银行股份有限公司南京江东中路支行',
        value: '102301002099|3201|江苏省|南京市'
      },
      {
        label: '中国工商银行股份有限公司南京油坊桥支行',
        value: '102301002103|3201|江苏省|南京市'
      },
      {
        label: '中国工商银行股份有限公司南京天元支行',
        value: '102301002111|3201|江苏省|南京市'
      },
      {
        label: '中国工商银行江苏分行营业部',
        value: '102301043010|3201|江苏省|南京市'
      },
      {
        label: '中国工商银行股份有限公司南京万寿支行',
        value: '102301001828|3201|江苏省|南京市'
      },
      {
        label: '中国工商银行股份有限公司南京大行宫支行',
        value: '102301001836|3201|江苏省|南京市'
      },
      {
        label: '中国工商银行南京电力大厦支行',
        value: '102301001844|3201|江苏省|南京市'
      },
      {
        label: '中国工商银行股份有限公司南京后标营支行',
        value: '102301001852|3201|江苏省|南京市'
      },
      {
        label: '中国工商银行股份有限公司南京铁心桥支行',
        value: '102301001869|3201|江苏省|南京市'
      },
      {
        label: '中国工商银行股份有限公司南京建邺路支行',
        value: '102301001877|3201|江苏省|南京市'
      },
      {
        label: '中国工商银行股份有限公司南京南京汉中门大街支行',
        value: '102301001885|3201|江苏省|南京市'
      },
      {
        label: '中国工商银行股份有限公司南京北京西路支行',
        value: '102301001893|3201|江苏省|南京市'
      },
      {
        label: '中国工商银行股份有限公司南京嘉陵江东街支行',
        value: '102301001908|3201|江苏省|南京市'
      },
      {
        label: '中国工商银行股份有限公司南京油运大厦支行',
        value: '102301001916|3201|江苏省|南京市'
      },
      {
        label: '中国工商银行股份有限公司南京燕山路支行',
        value: '102301001924|3201|江苏省|南京市'
      },
      {
        label: '中国工商银行股份有限公司南京红太阳商城分理处',
        value: '102301001965|3201|江苏省|南京市'
      },
      {
        label: '中国工商银行股份有限公司南京新城科技支行',
        value: '102301001973|3201|江苏省|南京市'
      },
      {
        label: '中国工商银行股份有限公司南京桥北路支行',
        value: '102301000921|3201|江苏省|南京市'
      },
      {
        label: '中国工商银行南京市岗山分理处',
        value: '102301000930|3201|江苏省|南京市'
      },
      {
        label: '中国工商银行南京市江宁经济开发区支行',
        value: '102301000948|3201|江苏省|南京市'
      },
      {
        label: '中国工商银行股份有限公司南京宏运大道支行',
        value: '102301000956|3201|江苏省|南京市'
      },
      {
        label: '中国工商银行股份有限公司南京天井支行',
        value: '102301000964|3201|江苏省|南京市'
      },
      {
        label: '中国工商银行南京延安北路分理处',
        value: '102301000989|3201|江苏省|南京市'
      },
      {
        label: '中国工商银行股份有限公司南京泰山路支行',
        value: '102301000997|3201|江苏省|南京市'
      },
      {
        label: '中国工商银行南京朝阳分理处',
        value: '102301001004|3201|江苏省|南京市'
      },
      {
        label: '中国工商银行股份有限公司溧水中大街支行',
        value: '102301001012|3201|江苏省|南京市'
      },
      {
        label: '中国工商银行高淳县淳西分理处',
        value: '102301001045|3201|江苏省|南京市'
      },
      {
        label: '中国工商银行股份有限公司南京柳州东路支行',
        value: '102301001053|3201|江苏省|南京市'
      },
      {
        label: '中国工商银行股份有限公司南京仙林支行',
        value: '102301001061|3201|江苏省|南京市'
      },
      {
        label: '中国工商银行股份有限公司南京竹山路支行',
        value: '102301001070|3201|江苏省|南京市'
      },
      {
        label: '中国工商银行股份有限公司南京扬子三村支行',
        value: '102301001088|3201|江苏省|南京市'
      },
      {
        label: '中国工商银行股份有限公司南京晓山路支行',
        value: '102301001096|3201|江苏省|南京市'
      },
      {
        label: '中国工商银行股份有限公司南京南苑支行',
        value: '102301001131|3201|江苏省|南京市'
      },
      {
        label: '中国工商银行股份有限公司南京胜太路支行',
        value: '102301001140|3201|江苏省|南京市'
      },
      {
        label: '中国工商银行股份有限公司南京龙江支行',
        value: '102301001166|3201|江苏省|南京市'
      },
      {
        label: '中国工商银行股份有限公司南京西康路分理处',
        value: '102301001174|3201|江苏省|南京市'
      },
      {
        label: '中国工商银行股份有限公司南京朝天宫支行',
        value: '102301001254|3201|江苏省|南京市'
      },
      {
        label: '中国工商银行股份有限公司南京四牌楼支行',
        value: '102301001295|3201|江苏省|南京市'
      },
      {
        label: '中国工商银行股份有限公司南京定淮门大街支行',
        value: '102301001300|3201|江苏省|南京市'
      },
      {
        label: '中国工商银行南京宁南支行',
        value: '102301001318|3201|江苏省|南京市'
      },
      {
        label: '中国工商银行股份有限公司南京上元支行',
        value: '102301001326|3201|江苏省|南京市'
      },
      {
        label: '中国工商银行南京市板桥分理处',
        value: '102301001334|3201|江苏省|南京市'
      },
      {
        label: '中国工商银行南京山西路支行',
        value: '102301001342|3201|江苏省|南京市'
      },
      {
        label: '中国工商银行股份有限公司南京马群支行',
        value: '102301001359|3201|江苏省|南京市'
      },
      {
        label: '中国工商银行股份有限公司南京石杨路支行',
        value: '102301001455|3201|江苏省|南京市'
      },
      {
        label: '中国工商银行江苏省分行（不对外办理业务）',
        value: '102301000010|3201|江苏省|南京市'
      },
      {
        label: '中国工商银行股份有限公司南京城北支行',
        value: '102301000028|3201|江苏省|南京市'
      },
      {
        label: '中国工商银行南京市下关支行',
        value: '102301000036|3201|江苏省|南京市'
      },
      {
        label: '中国工商银行南京市城南支行',
        value: '102301000044|3201|江苏省|南京市'
      },
      {
        label: '中国工商银行南京市城东支行',
        value: '102301000052|3201|江苏省|南京市'
      },
      {
        label: '中国工商银行南京市雨花支行',
        value: '102301000069|3201|江苏省|南京市'
      },
      {
        label: '中国工商银行南京市玄武支行',
        value: '102301000077|3201|江苏省|南京市'
      },
      {
        label: '中国工商银行股份有限公司南京九龙湖分理处',
        value: '102301001981|3201|江苏省|南京市'
      },
      {
        label: '中国工商银行股份有限公司南京溧水经济开发区支行',
        value: '102301001990|3201|江苏省|南京市'
      },
      {
        label: '中国工商银行股份有限公司南京龙池支行',
        value: '102301002007|3201|江苏省|南京市'
      },
      {
        label: '中国工商银行股份有限公司南京兴隆大街支行',
        value: '102301002015|3201|江苏省|南京市'
      },
      {
        label: '中国工商银行股份有限公司南京禄口支行',
        value: '102301002023|3201|江苏省|南京市'
      },
      {
        label: '中国工商银行股份有限公司南京秦虹支行',
        value: '102301000825|3201|江苏省|南京市'
      },
      {
        label: '中国工商银行南京龙园西路支行',
        value: '102301000833|3201|江苏省|南京市'
      },
      {
        label: '中国工商银行股份有限公司南京广州路支行',
        value: '102301000841|3201|江苏省|南京市'
      },
      {
        label: '中国工商银行股份有限公司南京五塘支行',
        value: '102301000876|3201|江苏省|南京市'
      },
      {
        label: '中国工商银行股份有限公司南京大明路支行',
        value: '102301000884|3201|江苏省|南京市'
      },
      {
        label: '中国工商银行南京市花旗分理处',
        value: '102301000905|3201|江苏省|南京市'
      },
      {
        label: '中国工商银行股份有限公司南京饮虹园支行',
        value: '102301001463|3201|江苏省|南京市'
      },
      {
        label: '中国工商银行股份有限公司南京美食街支行',
        value: '102301001498|3201|江苏省|南京市'
      },
      {
        label: '中国工商银行股份有限公司南京应天大街支行',
        value: '102301001535|3201|江苏省|南京市'
      },
      {
        label: '中国工商银行股份有限公司南京珠江支行',
        value: '102301001560|3201|江苏省|南京市'
      },
      {
        label: '中国工商银行股份有限公司南京火车南站支行',
        value: '102301001633|3201|江苏省|南京市'
      },
      {
        label: '中国工商银行南京市新百分理处',
        value: '102301001676|3201|江苏省|南京市'
      },
      {
        label: '中国工商银行股份有限公司高淳宝塔路支行',
        value: '102301001721|3201|江苏省|南京市'
      },
      {
        label: '中国工商银行股份有限公司南京狮子桥支行',
        value: '102301001772|3201|江苏省|南京市'
      },
      {
        label: '中国工商银行股份有限公司南京新门口支行',
        value: '102301001789|3201|江苏省|南京市'
      },
      {
        label: '中国工商银行股份有限公司南京三牌楼支行',
        value: '102301001797|3201|江苏省|南京市'
      },
      {
        label: '中国工商银行股份有限公司南京月牙湖支行',
        value: '102301001801|3201|江苏省|南京市'
      },
      {
        label: '中国工商银行股份有限公司石家庄向阳支行',
        value: '102121001120|1301|河北省|石家庄市'
      },
      {
        label: '中国工商银行股份有限公司石家庄铜冶支行',
        value: '102121001162|1301|河北省|石家庄市'
      },
      {
        label: '中国工商银行股份有限公司石家庄上庄支行',
        value: '102121002243|1301|河北省|石家庄市'
      },
      {
        label: '中国工商银行股份有限公司南京高新园支行',
        value: '102301002179|3201|江苏省|南京市'
      },
      {
        label: '中国工商银行股份有限公司南昌省行政中心支行',
        value: '102421000309|3601|江西省|南昌市'
      },
      {
        label: '中国工商银行股份有限公司南京清凉门大街分理处',
        value: '102301001367|3201|江苏省|南京市'
      },
      {
        label: '中国工商银行股份有限公司南京南空支行',
        value: '102301001641|3201|江苏省|南京市'
      },
      {
        label: '中国工商银行股份有限公司南京仙林大道支行',
        value: '102301002154|3201|江苏省|南京市'
      },
      {
        label: '中国工商银行股份有限公司南京高淳经济开发区支行',
        value: '102301002162|3201|江苏省|南京市'
      },
      {
        label: '中国工商银行股份有限公司南京凤台南路支行',
        value: '102301002146|3201|江苏省|南京市'
      },
      {
        label: '中国工商银行股份有限公司南京东麒路分理处',
        value: '102301002120|3201|江苏省|南京市'
      },
      {
        label: '中国工商银行股份有限公司南京银城东苑支行',
        value: '102301002138|3201|江苏省|南京市'
      },
      {
        label: '浙江平湖工银村镇银行股份有限公司',
        value: '320335200014|3304|浙江省|嘉兴市'
      },
      {
        label: '浙江平湖工银村镇银行股份有限公司滨海支行',
        value: '320335200022|3304|浙江省|嘉兴市'
      },
      {
        label: '中国工商银行股份有限公司唐山兴源支行',
        value: '102124005688|1302|河北省|唐山市'
      },
      {
        label: '中国工商银行股份有限公司北安南京路支行',
        value: '102278200042|2311|黑龙江省|黑河市'
      },
      {
        label: '中国工商银行股份有限公司广州花都天贵支行',
        value: '102581003766|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州花都汽车城支行',
        value: '102581005930|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司深圳熙龙湾支行',
        value: '102584012838|4403|广东省|深圳市'
      },
      {
        label: '铜仁丰源村镇银行有限责任公司',
        value: '320705000019|5206|贵州省|铜仁市'
      },
      {
        label: '中国工商银行股份有限公司台州集聚区支行',
        value: '102345002256|3310|浙江省|台州市'
      },
      {
        label: '中国工商银行云南昭通分行凤凰支行',
        value: '102734000242|5306|云南省|昭通市'
      },
      {
        label: '中国工商银行云南红河分行会计业务处理中心',
        value: '102743302784|5325|云南省|红河哈尼族彝族自治州'
      },
      {
        label: '中国工商银行云南红河分行牡丹支行',
        value: '102743304276|5325|云南省|红河哈尼族彝族自治州'
      },
      {
        label: '中国工商银行云南大理分行绿玉支行',
        value: '102751001751|5329|云南省|大理白族自治州'
      },
      {
        label: '中国工商银行云南怒江分行营业室',
        value: '102756013063|5333|云南省|怒江傈僳族自治州'
      },
      {
        label: '中国工商银行股份有限公司阿克陶支行',
        value: '102893200018|6530|新疆维吾尔自治区|克孜勒苏柯尔克孜自治州'
      },
      {
        label: '中国工商银行股份有限公司佛山顺德均安支行',
        value: '102588007229|4406|广东省|佛山市'
      },
      {
        label: '中国工商银行股份有限公司贵阳轨道支行',
        value: '102701010032|5201|贵州省|贵阳市'
      },
      {
        label: '都匀融通村镇银行有限责任公司',
        value: '320715000014|5227|贵州省|黔南布依族苗族自治州'
      },
      {
        label: '龙里国丰村镇银行有限责任公司',
        value: '320716100025|5227|贵州省|黔南布依族苗族自治州'
      },
      {
        label: '中国工商银行股份有限公司武汉凯乐花园支行',
        value: '102521003606|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行股份有限公司武汉八一路支行',
        value: '102521003680|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行股份有限公司深圳龙东支行',
        value: '102584012900|4403|广东省|深圳市'
      },
      {
        label: '中国工商银行股份有限公司贵阳花果园金融街支行',
        value: '102701010090|5201|贵州省|贵阳市'
      },
      {
        label: '中国工商银行股份有限公司齐齐哈尔恒腾支行',
        value: '102264000232|2302|黑龙江省|齐齐哈尔市'
      },
      {
        label: '中国工商银行股份有限公司双鸭山新兴大街支行',
        value: '102268000123|2305|黑龙江省|双鸭山市'
      },
      {
        label: '中国工商银行股份有限公司双鸭山八马路支行',
        value: '102268000166|2305|黑龙江省|双鸭山市'
      },
      {
        label: '中国工商银行股份有限公司双鸭山三马路支行',
        value: '102268000199|2305|黑龙江省|双鸭山市'
      },
      {
        label: '中国工商银行股份有限公司广州南大路支行',
        value: '102581003967|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州旺城支行',
        value: '102581005841|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司韶关沐溪支行',
        value: '102582002570|4402|广东省|韶关市'
      },
      {
        label: '中国工商银行股份有限公司大连马栏广场支行',
        value: '102222002121|2102|辽宁省|大连市'
      },
      {
        label: '中国工商银行股份有限公司烟台西山支行',
        value: '102456000680|3706|山东省|烟台市'
      },
      {
        label: '中国工商银行股份有限公司烟台高新技术产业开发区支行',
        value: '102456010363|3706|山东省|烟台市'
      },
      {
        label: '中国工商银行股份有限公司武汉高家湾支行',
        value: '102521001080|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行股份有限公司武汉武莲路支行',
        value: '102521002726|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行股份有限公司武汉百瑞景支行',
        value: '102521002734|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行股份有限公司大庆肇源支行',
        value: '102276806116|2306|黑龙江省|大庆市'
      },
      {
        label: '中国工商银行股份有限公司大庆杜蒙支行',
        value: '102264606266|2306|黑龙江省|大庆市'
      },
      {
        label: '中国工商银行股份有限公司呼和浩特麻花板支行',
        value: '102191000907|1501|内蒙古自治区|呼和浩特市'
      },
      {
        label: '中国工商银行股份有限公司合肥创新大道支行',
        value: '102361004985|3401|安徽省|合肥市'
      },
      {
        label: '中国工商银行股份有限公司随州滨湖支行',
        value: '102528612208|4213|湖北省|随州市'
      },
      {
        label: '中国工商银行股份有限公司郑州银河支行',
        value: '102491052071|4101|河南省|郑州市'
      },
      {
        label: '中国工商银行股份有限公司巩义建民支行',
        value: '102491062316|4101|河南省|郑州市'
      },
      {
        label: '中国工商银行股份有限公司莱州石坊支行',
        value: '102456904724|3706|山东省|烟台市'
      },
      {
        label: '中国工商银行股份有限公司加格达奇新世纪支行',
        value: '102279000202|2327|黑龙江省|大兴安岭地区'
      },
      {
        label: '中国工商银行股份有限公司郑州康平路支行',
        value: '102491000021|4101|河南省|郑州市'
      },
      {
        label: '中国工商银行股份有限公司郑州红专路支行',
        value: '102491013029|4101|河南省|郑州市'
      },
      {
        label: '中国工商银行股份有限公司郑州丰产路支行',
        value: '102491032060|4101|河南省|郑州市'
      },
      {
        label: '中国工商银行股份有限公司郑州信息大学支行',
        value: '102491000097|4101|河南省|郑州市'
      },
      {
        label: '中国工商银行股份有限公司惠州雍逸园支行',
        value: '102595003025|4413|广东省|惠州市'
      },
      {
        label: '中国工商银行股份有限公司拉萨经济技术开发区支行',
        value: '102770000032|5401|西藏自治区|拉萨市'
      },
      {
        label: '中国工商银行股份有限公司长春福祉大路支行',
        value: '102241001760|2201|吉林省|长春市'
      },
      {
        label: '中国工商银行股份有限公司鸡西桥东支行',
        value: '102266000201|2303|黑龙江省|鸡西市'
      },
      {
        label: '中国工商银行股份有限公司鸡西二道河子支行',
        value: '102266000599|2303|黑龙江省|鸡西市'
      },
      {
        label: '中国工商银行股份有限公司东丰向阳路支行',
        value: '102244121433|2204|吉林省|辽源市'
      },
      {
        label: '中国工商银行股份有限公司广州雅居乐支行',
        value: '102581003926|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州大石朝阳支行',
        value: '102581003934|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州保利大都汇支行',
        value: '102581003942|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州大学城支行',
        value: '102581004285|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州大石丽江支行',
        value: '102581004293|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州大学城中环支行',
        value: '102581005479|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州碧华坊支行',
        value: '102581005526|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州沙溪支行',
        value: '102581005671|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州洛溪支行',
        value: '102581005913|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司汕头澄海莲下支行',
        value: '102586004154|4405|广东省|汕头市'
      },
      {
        label: '中国工商银行股份有限公司无锡解放东路支行',
        value: '102302005242|3202|江苏省|无锡市'
      },
      {
        label: '中国工商银行股份有限公司宜兴丁蜀解放西路分理处',
        value: '102302306125|3202|江苏省|无锡市'
      },
      {
        label: '中国工商银行股份有限公司无锡湖滨路支行',
        value: '102302005146|3202|江苏省|无锡市'
      },
      {
        label: '中国工商银行股份有限公司无锡长江北路支行',
        value: '102302002293|3202|江苏省|无锡市'
      },
      {
        label: '中国工商银行股份有限公司无锡太湖花园支行',
        value: '102302005710|3202|江苏省|无锡市'
      },
      {
        label: '中国工商银行股份有限公司无锡五星家园支行',
        value: '102302004617|3202|江苏省|无锡市'
      },
      {
        label: '中国工商银行股份有限公司无锡奥林花园支行',
        value: '102302004650|3202|江苏省|无锡市'
      },
      {
        label: '中国工商银行股份有限公司无锡水秀支行',
        value: '102302005621|3202|江苏省|无锡市'
      },
      {
        label: '中国工商银行股份有限公司无锡靖塔支行',
        value: '102302005218|3202|江苏省|无锡市'
      },
      {
        label: '中国工商银行股份有限公司宜兴东方明珠支行',
        value: '102302303012|3202|江苏省|无锡市'
      },
      {
        label: '中国工商银行股份有限公司无锡县前西街支行',
        value: '102302005154|3202|江苏省|无锡市'
      },
      {
        label: '中国工商银行股份有限公司无锡青山湾支行',
        value: '102302005525|3202|江苏省|无锡市'
      },
      {
        label: '中国工商银行股份有限公司无锡五爱支行',
        value: '102302005111|3202|江苏省|无锡市'
      },
      {
        label: '中国工商银行股份有限公司无锡东方红支行',
        value: '102302005015|3202|江苏省|无锡市'
      },
      {
        label: '中国工商银行股份有限公司昌都支行',
        value: '102772000052|5421|西藏自治区|昌都地区'
      },
      {
        label: '中国工商银行股份有限公司晋中榆太路支行',
        value: '102175027348|1407|山西省|晋中市'
      },
      {
        label: '中国工商银行股份有限公司苏州三多分理处',
        value: '102305012147|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行股份有限公司苏州东沙湖支行',
        value: '102305013086|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行股份有限公司苏州富邻广场支行',
        value: '102305016038|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行昆明新螺蛳湾第一支行',
        value: '102731006065|5301|云南省|昆明市'
      },
      {
        label: '中国工商银行股份有限公司双鸭山宝山支行',
        value: '102268000238|2305|黑龙江省|双鸭山市'
      },
      {
        label: '中国工商银行股份有限公司双鸭山三利支行',
        value: '102268000140|2305|黑龙江省|双鸭山市'
      },
      {
        label: '中国工商银行股份有限公司双鸭山七星支行',
        value: '102268000096|2305|黑龙江省|双鸭山市'
      },
      {
        label: '中国工商银行股份有限公司济南未来城支行',
        value: '102451009325|3701|山东省|济南市'
      },
      {
        label: '中国工商银行股份有限公司厦门巷南支行',
        value: '102393000997|3502|福建省|厦门市'
      },
      {
        label: '中国工商银行股份有限公司深圳龙平支行',
        value: '102584012846|4403|广东省|深圳市'
      },
      {
        label: '中国工商银行股份有限公司济南万达广场支行',
        value: '102451015713|3701|山东省|济南市'
      },
      {
        label: '中国工商银行股份有限公司郑州玫瑰城支行',
        value: '102491000013|4101|河南省|郑州市'
      },
      {
        label: '中国工商银行股份有限公司郑州翠竹街支行',
        value: '102491000048|4101|河南省|郑州市'
      },
      {
        label: '中国工商银行股份有限公司郑州地润路支行',
        value: '102491000072|4101|河南省|郑州市'
      },
      {
        label: '中国工商银行股份有限公司郑州新干线支行',
        value: '102491007533|4101|河南省|郑州市'
      },
      {
        label: '中国工商银行股份有限公司郑州橄榄城支行',
        value: '102491010055|4101|河南省|郑州市'
      },
      {
        label: '中国工商银行股份有限公司济源三八支行',
        value: '102491011558|4101|河南省|郑州市'
      },
      {
        label: '中国工商银行股份有限公司郑州华淮支行',
        value: '102491014154|4101|河南省|郑州市'
      },
      {
        label: '中国工商银行股份有限公司郑州茜城支行',
        value: '102491022058|4101|河南省|郑州市'
      },
      {
        label: '中国工商银行股份有限公司济源沁园路支行',
        value: '102491032553|4101|河南省|郑州市'
      },
      {
        label: '中国工商银行股份有限公司济源北海路支行',
        value: '102491062558|4101|河南省|郑州市'
      },
      {
        label: '中国工商银行股份有限公司新郑双湖大道支行',
        value: '102491072414|4101|河南省|郑州市'
      },
      {
        label: '中国工商银行股份有限公司济源济水大街支行',
        value: '102491082552|4101|河南省|郑州市'
      },
      {
        label: '中国工商银行股份有限公司济源黄河路支行',
        value: '102491092554|4101|河南省|郑州市'
      },
      {
        label: '中国工商银行股份有限公司枣庄东湖支行',
        value: '102454002055|3704|山东省|枣庄市'
      },
      {
        label: '中国工商银行股份有限公司双鸭山向阳支行',
        value: '102268000174|2305|黑龙江省|双鸭山市'
      },
      {
        label: '中国工商银行股份有限公司通化团结支行',
        value: '102245024227|2205|吉林省|通化市'
      },
      {
        label: '中国工商银行股份有限公司仁寿黑龙滩支行',
        value: '102665100097|5111|四川省|乐山市'
      },
      {
        label: '中国工商银行股份有限公司哈尔滨尚志开发区支行',
        value: '102261001703|2301|黑龙江省|哈尔滨市'
      },
      {
        label: '中国工商银行股份有限公司汾阳阳光支行',
        value: '102173100173|1411|山西省|吕梁市'
      },
      {
        label: '中国工商银行股份有限公司杭州滨盛支行',
        value: '102331018039|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行股份有限公司安庆光彩大市场支行',
        value: '102368002769|3408|安徽省|安庆市'
      },
      {
        label: '中国工商银行股份有限公司娄底城东支行',
        value: '102562000018|4313|湖南省|娄底市'
      },
      {
        label: '中国工商银行股份有限公司玉林市玉东支行',
        value: '102624070190|4509|广西壮族自治区|玉林市'
      },
      {
        label: '中国工商银行股份有限公司长春长德支行',
        value: '102241001825|2201|吉林省|长春市'
      },
      {
        label: '中国工商银行股份有限公司广州荷塘月色支行',
        value: '102581003098|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州茶亭支行',
        value: '102581003102|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州站前路支行',
        value: '102581003119|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州白云新城支行',
        value: '102581003127|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司武汉武大支行',
        value: '102521003001|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行股份有限公司衡阳星月支行',
        value: '102554000018|4304|湖南省|衡阳市'
      },
      {
        label: '中国工商银行股份有限公司南宁市青秀路支行',
        value: '102611010213|4501|广西壮族自治区|南宁市'
      },
      {
        label: '中国工商银行股份有限公司南宁市奥园支行',
        value: '102611010328|4501|广西壮族自治区|南宁市'
      },
      {
        label: '中国工商银行股份有限公司南宁市新民支行',
        value: '102611010336|4501|广西壮族自治区|南宁市'
      },
      {
        label: '中国工商银行股份有限公司南宁市天桃支行',
        value: '102611010344|4501|广西壮族自治区|南宁市'
      },
      {
        label: '中国工商银行股份有限公司成都天府国际机场支行',
        value: '102651000462|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司大连体育场支行',
        value: '102222001811|2102|辽宁省|大连市'
      },
      {
        label: '中国工商银行股份有限公司成都尚锦路支行',
        value: '102651000487|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司新津支行五津西路分理处',
        value: '102651025515|5101|四川省|成都市'
      },
      {
        label: '中国工商银行股份有限公司农安兴盛支行',
        value: '102241001462|2201|吉林省|长春市'
      },
      {
        label: '中国工商银行股份有限公司长春苏瓦延街支行',
        value: '102241001559|2201|吉林省|长春市'
      },
      {
        label: '中国工商银行股份有限公司青岛高新区支行',
        value: '102452000334|3702|山东省|青岛市'
      },
      {
        label: '中国工商银行股份有限公司佛山绿景支行',
        value: '102588002835|4406|广东省|佛山市'
      },
      {
        label: '中国工商银行股份有限公司济南山钢支行',
        value: '102451015684|3701|山东省|济南市'
      },
      {
        label: '中国工商银行股份有限公司长春梧桐支行',
        value: '102241001778|2201|吉林省|长春市'
      },
      {
        label: '中国工商银行股份有限公司哈尔滨抚顺分理处',
        value: '102261032023|2301|黑龙江省|哈尔滨市'
      },
      {
        label: '中国工商银行股份有限公司徐州响山路支行',
        value: '102303002206|3203|江苏省|徐州市'
      },
      {
        label: '中国工商银行股份有限公司德州科技支行',
        value: '102468000339|3714|山东省|德州市'
      },
      {
        label: '中国工商银行股份有限公司郑州富田太阳城支行',
        value: '102491000030|4101|河南省|郑州市'
      },
      {
        label: '中国工商银行股份有限公司郑州三全路支行',
        value: '102491062050|4101|河南省|郑州市'
      },
      {
        label: '中国工商银行股份有限公司鹤岗内环路支行',
        value: '102267000264|2304|黑龙江省|鹤岗市'
      },
      {
        label: '中国工商银行股份有限公司庆安中央大街支行',
        value: '102277100023|2312|黑龙江省|绥化市'
      },
      {
        label: '中国工商银行股份有限公司绥棱城关支行',
        value: '102277300033|2312|黑龙江省|绥化市'
      },
      {
        label: '中国工商银行股份有限公司松岭爱民路支行',
        value: '102279000235|2327|黑龙江省|大兴安岭地区'
      },
      {
        label: '中国工商银行股份有限公司新林汇鑫支行',
        value: '102279000251|2327|黑龙江省|大兴安岭地区'
      },
      {
        label: '中国工商银行股份有限公司呼玛新华路支行',
        value: '102279100315|2327|黑龙江省|大兴安岭地区'
      },
      {
        label: '中国工商银行股份有限公司塔河繁荣支行',
        value: '102279200336|2327|黑龙江省|大兴安岭地区'
      },
      {
        label: '中国工商银行股份有限公司塔河塔铁支行',
        value: '102279200344|2327|黑龙江省|大兴安岭地区'
      },
      {
        label: '中国工商银行股份有限公司漠河中华路支行',
        value: '102279300360|2327|黑龙江省|大兴安岭地区'
      },
      {
        label: '中国工商银行股份有限公司襄城支行中心路分理处',
        value: '102495512616|4110|河南省|许昌市'
      },
      {
        label: '中国工商银行股份有限公司长葛建设路支行',
        value: '102503122602|4110|河南省|许昌市'
      },
      {
        label: '中国工商银行股份有限公司长葛支行金桥路分理处',
        value: '102503132604|4110|河南省|许昌市'
      },
      {
        label: '中国工商银行股份有限公司长葛八七路支行',
        value: '102503142603|4110|河南省|许昌市'
      },
      {
        label: '中国工商银行股份有限公司禹州南关支行',
        value: '102503442509|4110|河南省|许昌市'
      },
      {
        label: '中国工商银行股份有限公司禹州西大街支行',
        value: '102503452504|4110|河南省|许昌市'
      },
      {
        label: '中国工商银行股份有限公司禹州西城支行',
        value: '102503482507|4110|河南省|许昌市'
      },
      {
        label: '中国工商银行股份有限公司广州智慧城支行',
        value: '102581005411|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州颐和支行',
        value: '102581005399|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司阳江碧桂支行',
        value: '102599302305|4417|广东省|阳江市'
      },
      {
        label: '中国工商银行股份有限公司肇东美满支行',
        value: '102276300040|2312|黑龙江省|绥化市'
      },
      {
        label: '中国工商银行股份有限公司延安东方红大道支行',
        value: '102804090282|6106|陕西省|延安市'
      },
      {
        label: '中国工商银行股份有限公司西安车辆厂支行',
        value: '102791011257|6101|陕西省|西安市'
      },
      {
        label: '中国工商银行股份有限公司西安临潼区代王支行',
        value: '102791002980|6101|陕西省|西安市'
      },
      {
        label: '中国工商银行股份有限公司牡丹江红光支行',
        value: '102272000110|2308|黑龙江省|佳木斯市'
      },
      {
        label: '中国工商银行股份有限公司牡丹江东三条路支行',
        value: '102272000312|2308|黑龙江省|佳木斯市'
      },
      {
        label: '中国工商银行股份有限公司牡丹江江滨支行',
        value: '102272000232|2308|黑龙江省|佳木斯市'
      },
      {
        label: '中国工商银行股份有限公司海口政务中心支行',
        value: '102641010450|4601|海南省|海口市'
      },
      {
        label: '中国工商银行股份有限公司双鸭山四方台分理处',
        value: '102268000107|2305|黑龙江省|双鸭山市'
      },
      {
        label: '中国工商银行股份有限公司吉水城南支行',
        value: '102435410296|3608|江西省|吉安市'
      },
      {
        label: '华商银行广州分行',
        value: '785581000003|4401|广东省|广州市'
      },
      {
        label: '华商银行',
        value: '785584000009|4403|广东省|深圳市'
      },
      {
        label: '华商银行深圳分行',
        value: '785584000017|4403|广东省|深圳市'
      },
      {
        label: '华商银行总行营业部',
        value: '785584000025|4403|广东省|深圳市'
      },
      {
        label: '华商银行深圳科技园支行',
        value: '785584000033|4403|广东省|深圳市'
      },
      {
        label: '中国工商银行股份有限公司会昌南街支行',
        value: '102429710847|3607|江西省|赣州市'
      },
      {
        label: '中国工商银行股份有限公司安达新兴支行',
        value: '102276200047|2312|黑龙江省|绥化市'
      },
      {
        label: '中国工商银行股份有限公司绥化世纪方舟支行',
        value: '102276000045|2312|黑龙江省|绥化市'
      },
      {
        label: '中国工商银行股份有限公司信阳百花园支行',
        value: '102515042174|4115|河南省|信阳市'
      },
      {
        label: '中国工商银行股份有限公司深圳观澜湖支行',
        value: '102584012820|4403|广东省|深圳市'
      },
      {
        label: '中国工商银行股份有限公司齐齐哈尔梅里斯支行',
        value: '102264000739|2302|黑龙江省|齐齐哈尔市'
      },
      {
        label: '中国工商银行股份有限公司牡丹江天安路支行',
        value: '102272000177|2308|黑龙江省|佳木斯市'
      },
      {
        label: '中国工商银行股份有限公司林口政府路支行',
        value: '102275500031|2310|黑龙江省|牡丹江市'
      },
      {
        label: '中国工商银行股份有限公司五大连池德都支行',
        value: '102278300036|2311|黑龙江省|黑河市'
      },
      {
        label: '中国工商银行股份有限公司广元昭化支行',
        value: '102661000021|5108|四川省|广元市'
      },
      {
        label: '中国工商银行股份有限公司西安桃园南路支行',
        value: '102791011388|6101|陕西省|西安市'
      },
      {
        label: '中国工商银行股份有限公司厦门滨湖支行',
        value: '102393000989|3502|福建省|厦门市'
      },
      {
        label: '中国工商银行股份有限公司甘肃矿区分行业务处理中心',
        value: '102822003482|6202|甘肃省|嘉峪关市'
      },
      {
        label: '中国工商银行股份有限公司杭州东宝路支行',
        value: '102331000811|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行股份有限公司湘潭奔驰路支行',
        value: '102553000016|4303|湖南省|湘潭市'
      },
      {
        label: '中国工商银行股份有限公司武汉左岭支行',
        value: '102521004199|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行股份有限公司武汉木兰支行',
        value: '102521004203|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行沈阳中央大街支行',
        value: '102221002322|2101|辽宁省|沈阳市'
      },
      {
        label: '中国工商银行股份有限公司宁化翠江支行',
        value: '102395302508|3504|福建省|三明市'
      },
      {
        label: '中国工商银行股份有限公司杭州华悦支行',
        value: '102331018209|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行股份有限公司黄冈罗田支行',
        value: '102533510109|4211|湖北省|黄冈市'
      },
      {
        label: '中国工商银行股份有限公司蕲春蕲州支行',
        value: '102533810169|4211|湖北省|黄冈市'
      },
      {
        label: '中国工商银行股份有限公司黄冈东坡支行',
        value: '102533010555|4211|湖北省|黄冈市'
      },
      {
        label: '中国工商银行孝感市牡丹支行',
        value: '102535010093|4209|湖北省|孝感市'
      },
      {
        label: '中国工商银行股份有限公司肇东幸福路支行',
        value: '102276300031|2312|黑龙江省|绥化市'
      },
      {
        label: '中国工商银行股份有限公司加格达奇曙光支行',
        value: '102279000171|2327|黑龙江省|大兴安岭地区'
      },
      {
        label: '中国工商银行股份有限公司无锡梨花支行',
        value: '102302003261|3202|江苏省|无锡市'
      },
      {
        label: '中国工商银行股份有限公司泗洪泗州支行',
        value: '102308601771|3206|江苏省|南通市'
      },
      {
        label: '中国工商银行股份有限公司瑞昌城东支行',
        value: '102424210449|3604|江西省|九江市'
      },
      {
        label: '中国工商银行股份有限公司丹东新安支行',
        value: '102226000213|2106|辽宁省|丹东市'
      },
      {
        label: '中国工商银行股份有限公司广州棠德支行',
        value: '102581000827|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州大沙支行',
        value: '102581001112|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州逸景支行',
        value: '102581004277|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司潮州东平支行',
        value: '102586901049|4405|广东省|汕头市'
      },
      {
        label: '中国工商银行股份有限公司安达朝阳支行',
        value: '102276200055|2312|黑龙江省|绥化市'
      },
      {
        label: '中国工商银行股份有限公司松滋白云边支行',
        value: '102537710631|4210|湖北省|荆州市'
      },
      {
        label: '中国工商银行股份有限公司荆州区委分理处',
        value: '102537010520|4210|湖北省|荆州市'
      },
      {
        label: '中国工商银行股份有限公司乌鲁木齐团结路支行',
        value: '102881002215|6501|新疆维吾尔自治区|乌鲁木齐市'
      },
      {
        label: '中国工商银行股份有限公司乌鲁木齐米东南路支行',
        value: '102881002848|6501|新疆维吾尔自治区|乌鲁木齐市'
      },
      {
        label: '中国工商银行股份有限公司乌鲁木齐新疆医科大学支行',
        value: '102881002944|6501|新疆维吾尔自治区|乌鲁木齐市'
      },
      {
        label: '中国工商银行股份有限公司长春革新路支行',
        value: '102241000695|2201|吉林省|长春市'
      },
      {
        label: '中国工商银行股份有限公司长春吉大南岭校区支行',
        value: '102241000863|2201|吉林省|长春市'
      },
      {
        label: '中国工商银行股份有限公司长春皓月大路支行',
        value: '102241000960|2201|吉林省|长春市'
      },
      {
        label: '中国工商银行股份有限公司长春西四路支行',
        value: '102241001085|2201|吉林省|长春市'
      },
      {
        label: '中国工商银行股份有限公司和龙誉文街支行',
        value: '102249610040|2224|吉林省|延边朝鲜族自治州'
      },
      {
        label: '中国工商银行股份有限公司张家界回龙路支行',
        value: '102559000025|4308|湖南省|张家界市'
      },
      {
        label: '工行陕西省渭南韩城市桢州支行',
        value: '102797205718|6105|陕西省|渭南市'
      },
      {
        label: '工行陕西省渭南白水县仓颉路分理处',
        value: '102797950809|6105|陕西省|渭南市'
      },
      {
        label: '中国工商银行股份有限公司武汉龙腾支行',
        value: '102521004123|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行股份有限公司襄阳高新孵化园支行',
        value: '102528000901|4206|湖北省|襄阳市'
      },
      {
        label: '中国工商银行股份有限公司安岳支行正北街分理处',
        value: '102663304119|5120|四川省|资阳市'
      },
      {
        label: '中国工商银行股份有限公司牡丹江南市支行',
        value: '102272000144|2308|黑龙江省|佳木斯市'
      },
      {
        label: '中国工商银行股份有限公司深圳软件园支行',
        value: '102584012811|4403|广东省|深圳市'
      },
      {
        label: '中国工商银行股份有限公司齐齐哈尔万利支行',
        value: '102264000415|2302|黑龙江省|齐齐哈尔市'
      },
      {
        label: '中国工商银行股份有限公司齐齐哈尔光荣支行',
        value: '102264000431|2302|黑龙江省|齐齐哈尔市'
      },
      {
        label: '中国工商银行股份有限公司牡丹江祥伦支行',
        value: '102272000185|2308|黑龙江省|佳木斯市'
      },
      {
        label: '中国工商银行股份有限公司大连虹韵路支行',
        value: '102222001209|2102|辽宁省|大连市'
      },
      {
        label: '中国工商银行股份有限公司尉犁支行',
        value: '102888300266|6528|新疆维吾尔自治区|巴音郭楞蒙古自治州'
      },
      {
        label: '中国工商银行巴音郭楞蒙古自治州分行业务处理中心',
        value: '102888000273|6528|新疆维吾尔自治区|巴音郭楞蒙古自治州'
      },
      {
        label: '中国工商银行股份有限公司阿克苏分行塔北路支行',
        value: '102891002091|6529|新疆维吾尔自治区|阿克苏地区'
      },
      {
        label: '中国工商银行股份有限公司长春普阳街支行',
        value: '102241001044|2201|吉林省|长春市'
      },
      {
        label: '中国工商银行股份有限公司七台河欧洲新城支行',
        value: '102274000121|2309|黑龙江省|七台河市'
      },
      {
        label: '中国工商银行股份有限公司七台河兴煤支行',
        value: '102274000148|2309|黑龙江省|七台河市'
      },
      {
        label: '中国工商银行股份有限公司七台河七彩城支行',
        value: '102274000156|2309|黑龙江省|七台河市'
      },
      {
        label: '中国工商银行股份有限公司七台河矿区支行',
        value: '102274000172|2309|黑龙江省|七台河市'
      },
      {
        label: '中国工商银行股份有限公司七台河七星花园支行',
        value: '102274000252|2309|黑龙江省|七台河市'
      },
      {
        label: '中国工商银行股份有限公司七台河富东支行',
        value: '102274000332|2309|黑龙江省|七台河市'
      },
      {
        label: '中国工商银行股份有限公司勃利城西储蓄所',
        value: '102274100528|2309|黑龙江省|七台河市'
      },
      {
        label: '中国工商银行股份有限公司株洲分行',
        value: '102552000014|4302|湖南省|株洲市'
      },
      {
        label: '中国工商银行股份有限公司岳阳巴陵支行',
        value: '102557060298|4306|湖南省|岳阳市'
      },
      {
        label: '中国工商银行股份有限公司鹤岗育才路分理处',
        value: '102267000256|2304|黑龙江省|鹤岗市'
      },
      {
        label: '中国工商银行黄石天津路支行',
        value: '102522011009|4202|湖北省|黄石市'
      },
      {
        label: '中国工商银行十堰市五堰支行',
        value: '102523000058|4203|湖北省|十堰市'
      },
      {
        label: '中国工商银行十堰老虎沟支行',
        value: '102523000517|4203|湖北省|十堰市'
      },
      {
        label: '中国工商银行股份有限公司襄阳中原路支行',
        value: '102528000274|4206|湖北省|襄阳市'
      },
      {
        label: '中国工商银行鄂州鄂城支行',
        value: '102531000031|4207|湖北省|鄂州市'
      },
      {
        label: '中国工商银行股份有限公司鄂州明堂市场支行',
        value: '102531000120|4207|湖北省|鄂州市'
      },
      {
        label: '中国工商银行股份有限公司清河太行支行',
        value: '102133600040|1305|河北省|邢台市'
      },
      {
        label: '中国工商银行股份有限公司大同局前街支行',
        value: '102162022017|1402|山西省|大同市'
      },
      {
        label: '中国工商银行股份有限公司大同新华街支行',
        value: '102162022033|1402|山西省|大同市'
      },
      {
        label: '中国工商银行股份有限公司大同东风里支行',
        value: '102162022050|1402|山西省|大同市'
      },
      {
        label: '中国工商银行股份有限公司大同北都街支行',
        value: '102162022084|1402|山西省|大同市'
      },
      {
        label: '中国工商银行股份有限公司大同迎宾支行',
        value: '102162022092|1402|山西省|大同市'
      },
      {
        label: '中国工商银行股份有限公司大同南环路支行',
        value: '102162022121|1402|山西省|大同市'
      },
      {
        label: '中国工商银行股份有限公司大同永泰支行',
        value: '102162022164|1402|山西省|大同市'
      },
      {
        label: '中国工商银行股份有限公司大同新世纪支行',
        value: '102162022373|1402|山西省|大同市'
      },
      {
        label: '中国工商银行股份有限公司苏州盘蠡支行',
        value: '102305026164|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行股份有限公司庐江黄山路支行',
        value: '102378200031|3401|安徽省|合肥市'
      },
      {
        label: '中国工商银行股份有限公司临沂经济开发区支行',
        value: '102473000149|3713|山东省|临沂市'
      },
      {
        label: '中国工商银行股份有限公司吉首万溶江支行',
        value: '102569000012|4331|湖南省|湘西土家族苗族自治州'
      },
      {
        label: '中国工商银行股份有限公司苏州浒关开发区支行',
        value: '102305018148|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行股份有限公司昆山鹿城路支行',
        value: '102305223319|3205|江苏省|苏州市'
      },
      {
        label: '中国工商银行股份有限公司长沙谷丰支行',
        value: '102551001816|4301|湖南省|长沙市'
      },
      {
        label: '中国工商银行股份有限公司杭州城西支行',
        value: '102331020584|3301|浙江省|杭州市'
      },
      {
        label: '中国工商银行股份有限公司黑河中心支行',
        value: '102278000188|2311|黑龙江省|黑河市'
      },
      {
        label: '中国工商银行股份有限公司哈尔滨哈东支行',
        value: '102261001640|2301|黑龙江省|哈尔滨市'
      },
      {
        label: '中国工商银行股份有限公司自贡时代大道支行',
        value: '102655030396|5103|四川省|自贡市'
      },
      {
        label: '中国工商银行股份有限公司鹰潭新区支行',
        value: '102427000607|3606|江西省|鹰潭市'
      },
      {
        label: '中国工商银行股份有限公司鹰潭五洲路支行',
        value: '102427010104|3606|江西省|鹰潭市'
      },
      {
        label: '中国工商银行股份有限公司鹰潭双水坑支行',
        value: '102427010112|3606|江西省|鹰潭市'
      },
      {
        label: '中国工商银行股份有限公司贵溪滨江支行',
        value: '102427110172|3606|江西省|鹰潭市'
      },
      {
        label: '中国工商银行股份有限公司贵溪火车站支行',
        value: '102427110201|3606|江西省|鹰潭市'
      },
      {
        label: '中国工商银行股份有限公司贵溪雄石支行',
        value: '102427110228|3606|江西省|鹰潭市'
      },
      {
        label: '中国工商银行股份有限公司贵溪支行交通路分理处',
        value: '102427110244|3606|江西省|鹰潭市'
      },
      {
        label: '中国工商银行股份有限公司赣州滨江支行',
        value: '102428010260|3607|江西省|赣州市'
      },
      {
        label: '中国工商银行荆门分行',
        value: '102532000017|4208|湖北省|荆门市'
      },
      {
        label: '中国工商银行股份有限公司青州开发区支行',
        value: '102458800142|3707|山东省|潍坊市'
      },
      {
        label: '中国工商银行股份有限公司澄迈软件园支行',
        value: '102641010564|4601|海南省|海口市'
      },
      {
        label: '中国工商银行股份有限公司三亚海棠湾支行',
        value: '102642000376|4602|海南省|三亚市'
      },
      {
        label: '中国工商银行股份有限公司三亚新风支行',
        value: '102642000368|4602|海南省|三亚市'
      },
      {
        label: '中国工商银行股份有限公司潜江杨市支行',
        value: '102537500265|4210|湖北省|荆州市'
      },
      {
        label: '中国工商银行股份有限公司鸡西南山支行',
        value: '102266000025|2303|黑龙江省|鸡西市'
      },
      {
        label: '中国工商银行股份有限公司西安铁炉庙支行',
        value: '102791011851|6101|陕西省|西安市'
      },
      {
        label: '中国工商银行股份有限公司临汾东大街支行',
        value: '102177000099|1410|山西省|临汾市'
      },
      {
        label: '中国工商银行股份有限公司北安龙江路支行',
        value: '102278200083|2311|黑龙江省|黑河市'
      },
      {
        label: '中国工商银行股份有限公司东港碧海支行',
        value: '102226311025|2106|辽宁省|丹东市'
      },
      {
        label: '中国工商银行股份有限公司广州国际轻纺城支行',
        value: '102581004084|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司韶关碧水花城支行',
        value: '102582002352|4402|广东省|韶关市'
      },
      {
        label: '中国工商银行股份有限公司茂名财富支行',
        value: '102592002320|4409|广东省|茂名市'
      },
      {
        label: '中国工商银行股份有限公司鸡西新风支行',
        value: '102266000404|2303|黑龙江省|鸡西市'
      },
      {
        label: '中国工商银行股份有限公司潜江西城支行',
        value: '102537500257|4210|湖北省|荆州市'
      },
      {
        label: '中国工商银行股份有限公司松滋阳光支行',
        value: '102537710623|4210|湖北省|荆州市'
      },
      {
        label: '中国工商银行股份有限公司南昌百花洲支行',
        value: '102421000649|3601|江西省|南昌市'
      },
      {
        label: '中国工商银行股份有限公司唐山丰南双湖锦苑支行',
        value: '102124005670|1302|河北省|唐山市'
      },
      {
        label: '中国工商银行股份有限公司土默特左旗新区支行',
        value: '102191006632|1501|内蒙古自治区|呼和浩特市'
      },
      {
        label: '中国工商银行股份有限公司贵阳商贸支行',
        value: '102701010016|5201|贵州省|贵阳市'
      },
      {
        label: '中国工商银行股份有限公司贵阳黔灵山路支行',
        value: '102701056009|5201|贵州省|贵阳市'
      },
      {
        label: '中国工商银行股份有限公司南昌东湖支行',
        value: '102421000489|3601|江西省|南昌市'
      },
      {
        label: '中国工商银行股份有限公司唐山丰南湖畔郦舍支行',
        value: '102124005661|1302|河北省|唐山市'
      },
      {
        label: '中国工商银行股份有限公司漠河古莲支行',
        value: '102279300247|2327|黑龙江省|大兴安岭地区'
      },
      {
        label: '中国工商银行股份有限公司新余北湖西路支行',
        value: '102426010107|3605|江西省|新余市'
      },
      {
        label: '中国工商银行股份有限公司新余团结路支行',
        value: '102426010115|3605|江西省|新余市'
      },
      {
        label: '中国工商银行股份有限公司分宜支行钤府分理处',
        value: '102426010140|3605|江西省|新余市'
      },
      {
        label: '中国工商银行股份有限公司新余公园北村支行',
        value: '102426010174|3605|江西省|新余市'
      },
      {
        label: '中国工商银行股份有限公司新余渝水支行',
        value: '102426010295|3605|江西省|新余市'
      },
      {
        label: '中国工商银行股份有限公司新余赣西大道支行',
        value: '102426010300|3605|江西省|新余市'
      },
      {
        label: '中国工商银行百色市新兴支行',
        value: '102626160021|4510|广西壮族自治区|百色市'
      },
      {
        label: '中国工商银行百色市城东支行',
        value: '102626161073|4510|广西壮族自治区|百色市'
      },
      {
        label: '中国工商银行百色市大西门支行',
        value: '102626161112|4510|广西壮族自治区|百色市'
      },
      {
        label: '中国工商银行股份有限公司宣汉南坝支行',
        value: '102675357651|5117|四川省|达州市'
      },
      {
        label: '中国工商银行股份有限公司睢宁睢城镇支行',
        value: '102303408046|3203|江苏省|徐州市'
      },
      {
        label: '中国工商银行股份有限公司睢宁天虹大道支行',
        value: '102303408054|3203|江苏省|徐州市'
      },
      {
        label: '中国工商银行股份有限公司伊春通山路支行',
        value: '102271020037|2307|黑龙江省|伊春市'
      },
      {
        label: '中国工商银行股份有限公司张家口鱼儿山支行',
        value: '102138011716|1307|河北省|张家口市'
      },
      {
        label: '中国工商银行股份有限公司武汉南湖新区支行',
        value: '102521003647|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行股份有限公司金坛东环二路支行',
        value: '102304205117|3204|江苏省|常州市'
      },
      {
        label: '中国工商银行股份有限公司茂名建丰支行',
        value: '102592002266|4409|广东省|茂名市'
      },
      {
        label: '中国工商银行股份有限公司惠州丰湖支行',
        value: '102595003164|4413|广东省|惠州市'
      },
      {
        label: '中国工商银行股份有限公司宽城兴源支行',
        value: '102142100029|1308|河北省|承德市'
      },
      {
        label: '中国工商银行股份有限公司合肥瑶海工业园支行',
        value: '102361004387|3401|安徽省|合肥市'
      },
      {
        label: '中国工商银行股份有限公司合肥新站区支行',
        value: '102361004571|3401|安徽省|合肥市'
      },
      {
        label: '中国工商银行股份有限公司芜湖绿地支行',
        value: '102362023215|3402|安徽省|芜湖市'
      },
      {
        label: '中国工商银行股份有限公司芜湖瑞丰支行',
        value: '102362023231|3402|安徽省|芜湖市'
      },
      {
        label: '中国工商银行股份有限公司马鞍山博望支行',
        value: '102365002181|3405|安徽省|马鞍山市'
      },
      {
        label: '中国工商银行股份有限公司马鞍山红旗中路支行',
        value: '102365021219|3405|安徽省|马鞍山市'
      },
      {
        label: '中国工商银行股份有限公司马鞍山长青路支行',
        value: '102365021227|3405|安徽省|马鞍山市'
      },
      {
        label: '中国工商银行股份有限公司马鞍山湖西中路支行',
        value: '102365021243|3405|安徽省|马鞍山市'
      },
      {
        label: '中国工商银行股份有限公司马鞍山雨山路支行',
        value: '102365021251|3405|安徽省|马鞍山市'
      },
      {
        label: '中国工商银行股份有限公司马鞍山梅园支行',
        value: '102365022221|3405|安徽省|马鞍山市'
      },
      {
        label: '中国工商银行股份有限公司马鞍山东苑支行',
        value: '102365022248|3405|安徽省|马鞍山市'
      },
      {
        label: '中国工商银行股份有限公司马鞍山太白支行',
        value: '102365023224|3405|安徽省|马鞍山市'
      },
      {
        label: '中国工商银行股份有限公司马鞍山佳山路支行',
        value: '102365023232|3405|安徽省|马鞍山市'
      },
      {
        label: '中国工商银行股份有限公司马鞍山珍珠园支行',
        value: '102365023249|3405|安徽省|马鞍山市'
      },
      {
        label: '中国工商银行股份有限公司马鞍山江东支行',
        value: '102365023273|3405|安徽省|马鞍山市'
      },
      {
        label: '中国工商银行股份有限公司马鞍山春晖支行',
        value: '102365028010|3405|安徽省|马鞍山市'
      },
      {
        label: '中国工商银行股份有限公司当涂姑孰支行',
        value: '102365101995|3405|安徽省|马鞍山市'
      },
      {
        label: '中国工商银行股份有限公司抚顺高山路支行',
        value: '102224000988|2104|辽宁省|抚顺市'
      },
      {
        label: '中国工商银行股份有限公司宣城国购广场分理处',
        value: '102377120257|3418|安徽省|宣城市'
      },
      {
        label: '中国工商银行股份有限公司南靖高新区支行',
        value: '102399702111|3506|福建省|漳州市'
      },
      {
        label: '中国工商银行股份有限公司贵阳碧海花园支行',
        value: '102701005611|5201|贵州省|贵阳市'
      },
      {
        label: '工行宁夏银川保税区支行',
        value: '102871002221|6401|宁夏回族自治区|银川市'
      },
      {
        label: '中国工商银行股份有限公司三台城西支行',
        value: '102659101149|5107|四川省|绵阳市'
      },
      {
        label: '中国工商银行股份有限公司清徐东湖支行',
        value: '102161001358|1401|山西省|太原市'
      },
      {
        label: '中国工商银行股份有限公司朔州迎宾支行',
        value: '102169023593|1406|山西省|朔州市'
      },
      {
        label: '中国工商银行股份有限公司长春湖西路支行',
        value: '102241001647|2201|吉林省|长春市'
      },
      {
        label: '中国工商银行股份有限公司长春南三环路支行',
        value: '102241001655|2201|吉林省|长春市'
      },
      {
        label: '中国工商银行股份有限公司吉林市宜山路支行',
        value: '102242000682|2202|吉林省|吉林市'
      },
      {
        label: '中国工商银行股份有限公司四平三马路支行',
        value: '102243024269|2203|吉林省|四平市'
      },
      {
        label: '中国工商银行股份有限公司公主岭大马路支行',
        value: '102243425310|2203|吉林省|四平市'
      },
      {
        label: '中国工商银行股份有限公司公主岭解放路支行',
        value: '102243425336|2203|吉林省|四平市'
      },
      {
        label: '中国工商银行股份有限公司松原商贸小区支行',
        value: '102247620016|2208|吉林省|白城市'
      },
      {
        label: '中国工商银行股份有限公司武汉后湖支行',
        value: '102521004096|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行股份有限公司武汉兴新街支行',
        value: '102521004107|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行股份有限公司呼和浩特九久街支行',
        value: '102191006624|1501|内蒙古自治区|呼和浩特市'
      },
      {
        label: '中国工商银行股份有限公司长春东繁荣路支行',
        value: '102241001288|2201|吉林省|长春市'
      },
      {
        label: '中国工商银行股份有限公司榆树华昌路支行',
        value: '102241001399|2201|吉林省|长春市'
      },
      {
        label: '中国工商银行股份有限公司九台大街支行',
        value: '102241001438|2201|吉林省|长春市'
      },
      {
        label: '中国工商银行股份有限公司吉林市西城支行',
        value: '102242000385|2202|吉林省|吉林市'
      },
      {
        label: '中国工商银行股份有限公司吉林市热电支行',
        value: '102242000481|2202|吉林省|吉林市'
      },
      {
        label: '中国工商银行股份有限公司吉林市华山路支行',
        value: '102242000623|2202|吉林省|吉林市'
      },
      {
        label: '中国工商银行股份有限公司梨树奉化大街支行',
        value: '102243125216|2203|吉林省|四平市'
      },
      {
        label: '中国工商银行股份有限公司双辽民主支行',
        value: '102243325037|2203|吉林省|四平市'
      },
      {
        label: '中国工商银行股份有限公司铁力松涛支行',
        value: '102271229068|2307|黑龙江省|伊春市'
      },
      {
        label: '中国工商银行股份有限公司铁力鹿鸣支行',
        value: '102271229084|2307|黑龙江省|伊春市'
      },
      {
        label: '中国工商银行股份有限公司广州财富天地广场支行',
        value: '102581004236|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司揭阳乔南支行',
        value: '102586501692|4405|广东省|汕头市'
      },
      {
        label: '中国工商银行股份有限公司揭阳进贤支行',
        value: '102586523100|4405|广东省|汕头市'
      },
      {
        label: '中国工商银行股份有限公司普宁城北支行',
        value: '102586702269|4405|广东省|汕头市'
      },
      {
        label: '中国工商银行股份有限公司普宁城东支行',
        value: '102586722368|4405|广东省|汕头市'
      },
      {
        label: '中国工商银行股份有限公司湛江东简支行',
        value: '102591004000|4408|广东省|湛江市'
      },
      {
        label: '中国工商银行股份有限公司深圳福田口岸支行',
        value: '102584013210|4403|广东省|深圳市'
      },
      {
        label: '中国工商银行股份有限公司西安锦业一路支行',
        value: '102791016093|6101|陕西省|西安市'
      },
      {
        label: '中国工商银行股份有限公司鄂尔多斯康巴什支行',
        value: '102205003413|1506|内蒙古自治区|鄂尔多斯市'
      },
      {
        label: '中国工商银行股份有限公司垦利胜兴支行',
        value: '102455104682|3705|山东省|东营市'
      },
      {
        label: '中国工商银行股份有限公司安庆孝肃路支行',
        value: '102368000913|3408|安徽省|安庆市'
      },
      {
        label: '中国工商银行股份有限公司合肥当涂路支行',
        value: '102361002473|3401|安徽省|合肥市'
      },
      {
        label: '中国工商银行股份有限公司武汉协和医院支行',
        value: '102521004088|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行股份有限公司长沙香樟路支行',
        value: '102551004933|4301|湖南省|长沙市'
      },
      {
        label: '中国工商银行股份有限公司黄骅中捷支行',
        value: '102145100874|1309|河北省|沧州市'
      },
      {
        label: '中国工商银行股份有限公司长治颐龙湾支行',
        value: '102164024320|1402|山西省|大同市'
      },
      {
        label: '中国工商银行股份有限公司鸡西梨树支行',
        value: '102266000902|2303|黑龙江省|鸡西市'
      },
      {
        label: '中国工商银行股份有限公司牡丹江江南支行',
        value: '102272000249|2308|黑龙江省|佳木斯市'
      },
      {
        label: '中国工商银行股份有限公司牡丹江金源支行',
        value: '102272002126|2308|黑龙江省|佳木斯市'
      },
      {
        label: '中国工商银行股份有限公司淮南毛集支行',
        value: '102364122283|3404|安徽省|淮南市'
      },
      {
        label: '中国工商银行股份有限公司马鞍山绿洲花园支行',
        value: '102365022213|3405|安徽省|马鞍山市'
      },
      {
        label: '中国工商银行股份有限公司当涂东街支行',
        value: '102365124204|3405|安徽省|马鞍山市'
      },
      {
        label: '中国工商银行股份有限公司淮北人民东路支行',
        value: '102366000434|3406|安徽省|淮北市'
      },
      {
        label: '中国工商银行股份有限公司淮北南黎路支行',
        value: '102366000442|3406|安徽省|淮北市'
      },
      {
        label: '中国工商银行股份有限公司牡丹江通达支行',
        value: '102272000224|2308|黑龙江省|佳木斯市'
      },
      {
        label: '中国工商银行股份有限公司武汉鼎盛华城支行',
        value: '102521004182|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行股份有限公司张掖新区支行',
        value: '102827006584|6207|甘肃省|张掖市'
      },
      {
        label: '中国工商银行股份有限公司西安华南城支行',
        value: '102791016010|6101|陕西省|西安市'
      },
      {
        label: '中国工商银行股份有限公司长春中华路支行',
        value: '102241001358|2201|吉林省|长春市'
      },
      {
        label: '中国工商银行股份有限公司长春虹馆支行',
        value: '102241001751|2201|吉林省|长春市'
      },
      {
        label: '中国工商银行股份有限公司长春西繁荣路支行',
        value: '102241001786|2201|吉林省|长春市'
      },
      {
        label: '中国工商银行股份有限公司九台双福支行',
        value: '102241001794|2201|吉林省|长春市'
      },
      {
        label: '中国工商银行股份有限公司长春净月大街支行',
        value: '102241001809|2201|吉林省|长春市'
      },
      {
        label: '中国工商银行股份有限公司松原欧亚支行',
        value: '102252028046|2207|吉林省|松原市'
      },
      {
        label: '中国工商银行股份有限公司武汉大江园支行',
        value: '102521002130|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行股份有限公司武汉竹叶山支行',
        value: '102521002148|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行股份有限公司武汉堤角支行',
        value: '102521002197|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行股份有限公司武汉武梁路支行',
        value: '102521003028|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行股份有限公司武汉新阳支行',
        value: '102521003403|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行股份有限公司武汉同安家园支行',
        value: '102521003719|4201|湖北省|武汉市'
      },
      {
        label: '中国工商银行股份有限公司海口南滨支行',
        value: '102641010589|4601|海南省|海口市'
      },
      {
        label: '中国工商银行股份有限公司凉山碧府路支行',
        value: '102684063310|5134|四川省|凉山彝族自治州'
      },
      {
        label: '中国工商银行股份有限公司枣庄薛国支行',
        value: '102454002151|3704|山东省|枣庄市'
      },
      {
        label: '中国工商银行股份有限公司玉林市金桂支行',
        value: '102624070124|4509|广西壮族自治区|玉林市'
      },
      {
        label: '中国工商银行股份有限公司贵阳盘江集团支行',
        value: '102701010024|5201|贵州省|贵阳市'
      },
      {
        label: '中国工商银行股份有限公司阿拉尔支行',
        value: '102903100018|6590|新疆维吾尔自治区|自治区直辖县级行政区划'
      },
      {
        label: '中国工商银行股份有限公司长春三站支行',
        value: '102241001069|2201|吉林省|长春市'
      },
      {
        label: '中国工商银行股份有限公司长春新城大街支行',
        value: '102241001817|2201|吉林省|长春市'
      },
      {
        label: '中国工商银行股份有限公司梅河口工贸支行',
        value: '102245426075|2205|吉林省|通化市'
      },
      {
        label: '中国工商银行股份有限公司温岭支行人民西路分理处',
        value: '102345424127|3310|浙江省|台州市'
      },
      {
        label: '中国工商银行股份有限公司济南开发区会展中心支行',
        value: '102451015676|3701|山东省|济南市'
      },
      {
        label: '中国工商银行股份有限公司古丈支行',
        value: '102569601192|4331|湖南省|湘西土家族苗族自治州'
      },
      {
        label: '中国工商银行股份有限公司闽侯金桥支行',
        value: '102391053959|3501|福建省|福州市'
      },
      {
        label: '中国工商银行股份有限公司长春吉林大路支行',
        value: '102241000898|2201|吉林省|长春市'
      },
      {
        label: '中国工商银行股份有限公司阳泉开发区支行',
        value: '102163000439|1402|山西省|大同市'
      },
      {
        label: '中国工商银行股份有限公司广州星汇园支行',
        value: '102581005583|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司广州增城中新支行',
        value: '102581005833|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司惠州宏益支行',
        value: '102595002233|4413|广东省|惠州市'
      },
      {
        label: '中国工商银行股份有限公司桦川支行',
        value: '102272300016|2308|黑龙江省|佳木斯市'
      },
      {
        label: '中国工商银行股份有限公司广州院士庭支行',
        value: '102581002982|4401|广东省|广州市'
      },
      {
        label: '中国工商银行股份有限公司惠州龙湖支行',
        value: '102595002670|4413|广东省|惠州市'
      }
    ]
  }
]
