<template>
  <div>
    <el-breadcrumb>
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>运营设置</el-breadcrumb-item>
      <el-breadcrumb-item>调价策略</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <el-row :gutter="20">
        <el-col :span="8">
          <el-input placeholder="请输入内容" v-model="queryParam.queryJson" clearable v-on:clear="getStrategyList">
            <el-button slot="append" icon="el-icon-search" v-on:click="getStrategyList"></el-button>
          </el-input>
        </el-col>
        <el-col :span="6">
          <el-button type="primary" v-on:click="addDialogeVisible = true">添加</el-button>
        </el-col>
      </el-row>
      <el-table :data="strategyList" border stripe>
        <el-table-column type="index" label="#"></el-table-column>
        <el-table-column prop="company_name" label="企业"> </el-table-column>
        <el-table-column prop="station_name" label="网点"> </el-table-column>
        <el-table-column prop="oil_name" label="品类"> </el-table-column>
        <el-table-column prop="price" label="单价"> </el-table-column>
        <el-table-column prop="begin_time" label="开始时间"> </el-table-column>
        <el-table-column prop="excute_result" label="执行结果"> </el-table-column>
        <el-table-column label="操作" width="180">
          <template slot-scope="scope">
            <el-button type="primary" icon="el-icon-edit" size="mini" v-on:click="showEditDialoge(scope.row.id)"></el-button>
            <el-button type="danger" icon="el-icon-delete" size="mini" v-on:click="deleteRow(scope.row.id)"></el-button>
            <el-tooltip effect="dark" content="停止" placement="top" v-bind:enterable="false">
              <el-button type="warning" icon="el-icon-setting" size="mini" v-on:click="stop(scope.row)"></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" v-bind:current-page="queryParam.pagenum" v-bind:page-sizes="this.pageOption" v-bind:page-size="queryParam.pagesize" layout="total, sizes, prev, pager, next, jumper" v-bind:total="total"> </el-pagination>
    </el-card>
    <!-- 添加对话框 -->
    <el-dialog title="添加变价策略" :visible.sync="addDialogeVisible" width="50%" v-on:closed="addDialogeClosed">
      <el-form :model="addForm" :rules="addFormRules" ref="addFormRef" label-width="70px">
        <el-form-item label="名称" prop="name">
          <el-input v-model="addForm.name"></el-input>
        </el-form-item>
        <el-form-item label="负责人" prop="master">
          <el-input v-model="addForm.master"></el-input>
        </el-form-item>
        <el-form-item label="手机号" prop="mobile">
          <el-input v-model="addForm.mobile"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogeVisible = false">取 消</el-button>
        <el-button type="primary" @click="addRow">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 修改对话框 -->
    <el-dialog title="修改变价策略" :visible.sync="editDialogeVisible" width="50%" v-on:closed="editDialogeClosed">
      <el-form :model="editForm" :rules="editFormRules" ref="editFormRef" label-width="70px">
        <el-form-item label="名称" prop="name">
          <el-input v-model="editForm.name"></el-input>
        </el-form-item>
        <el-form-item label="电话" prop="mobile">
          <el-input v-model="editForm.mobile"></el-input>
        </el-form-item>
        <el-form-item label="联系人" prop="master">
          <el-input v-model="editForm.master"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogeClosed">取 消</el-button>
        <el-button type="primary" @click="editSave">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      queryParam: {
        queryJson: '',
        pagenum: 1,
        pagesize: this.pageOption[0]
      },
      strategyList: [],
      total: 0,
      addDialogeVisible: false,
      addForm: {},
      addFormRules: {},
      editDialogeVisible: false,
      editForm: {},
      editFormRules: {}
    }
  },
  created() {
    this.getStrategyList()
  },
  methods: {
    getStrategyList() {},
    handleSizeChange() {},
    handleCurrentChange() {},
    addRow() {},
    addDialogeClosed() {},
    showEditDialoge() {},
    editSave() {},
    editDialogeClosed() {},
    deleteRow() {},
    stop() {}
  }
}
</script>
