<template>
  <div>
    <el-breadcrumb>
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>系统设置</el-breadcrumb-item>
      <el-breadcrumb-item>分账人管理</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <el-tabs v-model="activeName">
        <el-tab-pane label="个人" name="person">
          <partner-person />
        </el-tab-pane>
        <el-tab-pane label="企业" name="corp">
          <partner-corp />
        </el-tab-pane>
      </el-tabs>
    </el-card>
  </div>
</template>
<script>
import PartnerPerson from './PartnerPerson.vue'
import PartnerCorp from './PartnerCorp.vue'
export default {
  data() {
    return {
      activeName: 'person'
    }
  },
  components: {
    PartnerPerson,
    PartnerCorp
  },
  methods: {
    // onUserTypeAddChange: async function(current) {
    //   if (current === '0') {
    //     this.userNoAddDisable = false
    //     this.addForm.user_no = ''
    //   } else {
    //     const { data: res } = await this.$http.get('/OperationAccount/List')
    //     if (res.status !== 1) {
    //       return this.$message.error('获取资金账户列表失败')
    //     }
    //     this.userNoAddDisable = true
    //     this.addForm.user_no = res.data[0].mcht_no
    //   }
    // },
    // onUserTypeEditChange: async function(current) {
    //   if (current === 0) {
    //     this.userNoEditDisable = false
    //     this.editForm.user_no = ''
    //   } else {
    //     const { data: res } = await this.$http.get('/OperationAccount/List')
    //     if (res.status !== 1) {
    //       return this.$message.error('获取资金账户列表失败')
    //     }
    //     this.userNoEditDisable = true
    //     this.editForm.user_no = res.data[0].mcht_no
    //   }
    // },
  }
  // watch: {
  //   addFormRef: {
  //     // deep，默认值是 false，代表是否深度监听
  //     deep: true,
  //     handler: function(newValue, oldValue) {
  //       console.log(newValue, oldValue)
  //     },
  //     // immediate:true 代表在wacth里声明后，立即去执行handler方法
  //     immediate: true
  //   }
  // }
}
</script>
