var echarts = require('echarts')
const chartLeft1 = {
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'shadow'
    }
  },
  grid: {
    left: '0%',
    top: '10px',
    right: '0%',
    bottom: '4%',
    containLabel: true
  },
  xAxis: [
    {
      type: 'category',
      data: ['92#汽油', '95#汽油', '0#柴油', '-10#柴油', '尿素', '电', 'CNG'],
      axisLine: {
        show: true,
        lineStyle: {
          color: 'rgba(255,255,255,.1)',
          width: 1,
          type: 'solid'
        }
      },

      axisTick: {
        show: false
      },
      axisLabel: {
        interval: 0,
        show: true,
        splitNumber: 15,
        textStyle: {
          color: 'rgba(255,255,255,.6)',
          fontSize: '12'
        }
      }
    }
  ],
  yAxis: [
    {
      type: 'value',
      axisLabel: {
        show: true,
        textStyle: {
          color: 'rgba(255,255,255,.6)',
          fontSize: '12'
        }
      },
      axisTick: {
        show: false
      },
      axisLine: {
        show: true,
        lineStyle: {
          color: 'rgba(255,255,255,.1)',
          width: 1,
          type: 'solid'
        }
      },
      splitLine: {
        lineStyle: {
          color: 'rgba(255,255,255,.1)'
        }
      }
    }
  ],
  series: [
    {
      type: 'bar',
      data: [200, 600, 300, 900, 1500, 1200, 600],
      barWidth: '35%',
      itemStyle: {
        normal: {
          color: '#2f89cf',
          opacity: 1,
          barBorderRadius: 5
        }
      }
    }
  ]
}

const chartLeft2 = {
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'shadow'
    }
  },
  grid: {
    left: '0%',
    top: '10px',
    right: '0%',
    bottom: '4%',
    containLabel: true
  },
  xAxis: [
    {
      type: 'category',
      data: ['09.01', '09.02', '09.03', '09.04', '09.05', '09.06'],
      axisLine: {
        show: true,
        lineStyle: {
          color: 'rgba(255,255,255,.1)',
          width: 1,
          type: 'solid'
        }
      },
      axisTick: {
        show: false
      },
      axisLabel: {
        interval: 0,
        // rotate:50,
        show: true,
        splitNumber: 15,
        textStyle: {
          color: 'rgba(255,255,255,.6)',
          fontSize: '12'
        }
      }
    }
  ],
  yAxis: [
    {
      type: 'value',
      axisLabel: {
        show: true,
        textStyle: {
          color: 'rgba(255,255,255,.6)',
          fontSize: '12'
        }
      },
      axisTick: {
        show: false
      },
      axisLine: {
        show: true,
        lineStyle: {
          color: 'rgba(255,255,255,.1)',
          width: 1,
          type: 'solid'
        }
      },
      splitLine: {
        lineStyle: {
          color: 'rgba(255,255,255,.1)'
        }
      }
    },
    {
      type: 'value',
      axisLabel: {
        show: true,
        textStyle: {
          color: 'rgba(255,255,255,.6)',
          fontSize: '12'
        }
      },
      axisTick: {
        show: false
      },
      axisLine: {
        show: true,
        lineStyle: {
          color: 'rgba(255,255,255,.1)',
          width: 1,
          type: 'solid'
        }
      },
      splitLine: {
        lineStyle: {
          color: 'rgba(255,255,255,.1)'
        }
      }
    }
  ],
  series: [
    {
      type: 'bar',
      name: '销售额',
      data: [1200, 800, 300, 500, 560, 220],
      barWidth: '25%',
      itemStyle: {
        normal: {
          color: '#2f89cf',
          opacity: 1,
          barBorderRadius: 5
        }
      }
    },
    {
      type: 'bar',
      name: '销售量',
      data: [1000, 750, 380, 450, 450, 120],
      barWidth: '25%',
      itemStyle: {
        normal: {
          color: '#46d000',
          opacity: 1,
          barBorderRadius: 5
        }
      }
    }
  ]
}

const chartLeft3 = {
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      // 坐标轴指示器，坐标轴触发有效
      type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
    }
  },
  grid: {
    left: '0%',
    top: '-5px',
    right: '3%',
    bottom: '4%',
    containLabel: true
  },
  xAxis: {
    type: 'value',
    axisLabel: {
      show: true,
      textStyle: {
        color: 'rgba(255,255,255,.6)',
        fontSize: '12'
      }
    },
    axisTick: {
      show: false
    },
    axisLine: {
      show: true,
      lineStyle: {
        color: 'rgba(255,255,255,.1)',
        width: 1,
        type: 'solid'
      }
    },
    splitLine: {
      lineStyle: {
        color: 'rgba(255,255,255,.1)'
      }
    }
  },
  yAxis: {
    type: 'category',
    axisLabel: {
      show: true,
      textStyle: {
        color: 'rgba(255,255,255,.6)',
        fontSize: '12'
      }
    },
    axisTick: {
      show: false
    },
    axisLine: {
      show: true,
      lineStyle: {
        color: 'rgba(255,255,255,.1)',
        width: 1,
        type: 'solid'
      }
    },
    splitLine: {
      lineStyle: {
        color: 'rgba(255,255,255,.1)'
      }
    },
    data: ['周一', '周二', '周三', '周四', '周五']
  },
  series: [
    {
      name: '直接访问',
      type: 'bar',
      stack: '总量',
      label: {
        show: true,
        position: 'insideRight'
      },
      barWidth: '55%',
      itemStyle: {
        normal: {
          color: '#2f89cf',
          opacity: 1,
          barBorderRadius: 5
        }
      },
      data: [120, 302, 400, 200, 700]
    }
  ]
}

const chartRight1 = {
  title: {},
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'cross',
      label: {
        backgroundColor: '#6a7985'
      }
    }
  },

  color: ['#ffab6f', '#09b916', '#83cddc'], //  图例颜色
  legend: {
    top: '0%',
    icon: 'roundRect',
    data: ['订单数', '销售量', '销售额'],
    textStyle: {
      color: 'rgba(255,255,255,.5)',
      fontSize: '12'
    }
  },
  toolbox: {
    feature: {}
  },
  grid: {
    left: '10',
    top: '20',
    right: '10',
    bottom: '10',
    containLabel: true
  },
  xAxis: [
    {
      type: 'category',
      boundaryGap: false,
      axisLabel: {
        textStyle: {
          color: 'rgba(255,255,255,.6)',
          fontSize: 12
        }
      },
      axisLine: {
        lineStyle: {
          color: 'rgba(255,255,255,.2)'
        }
      },
      data: ['2020.08.23', '2020.08.24', '2020.08.25', '2020.08.26', '2020.08.27', '2020.08.28', '2020.08.29', '2020.08.30']
    }
  ],
  yAxis: [
    {
      type: 'value',
      axisTick: { show: false },
      minInterval: 60,
      axisLine: {
        lineStyle: {
          color: 'rgba(255,255,255,.1)'
        }
      },
      axisLabel: {
        textStyle: {
          color: 'rgba(255,255,255,.6)',
          fontSize: 12
        }
      },

      splitLine: {
        lineStyle: {
          color: 'rgba(255,255,255,.1)'
        }
      }
    }
  ],
  series: [
    {
      name: '订单数',
      type: 'line',
      smooth: true,
      lineStyle: {
        color: '#45d4ba',
        width: 1
      }, // 线条的样式
      areaStyle: {
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          {
            offset: 0,
            color: '#83cddc'
          },
          {
            offset: 1,
            color: '#bfdffbb5'
          }
        ])
      },
      data: [5, 22, 150, 54, 1, 230, 4, 1]
    },
    {
      name: '销售量',
      type: 'line',

      smooth: true,
      lineStyle: {
        color: '#04a710',
        width: 1
      }, //
      areaStyle: {
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          {
            offset: 0,
            color: '#0cbf22'
          },
          {
            offset: 1,
            color: '#b8f7d1b5'
          }
        ])
      },
      data: [10, 150, 1, 250, 20, 100, 10, 150]
    },
    {
      name: '销售额',
      type: 'line',

      lineStyle: {
        color: '#0864c3',
        width: 1
      }, // 线条的样式
      smooth: true,
      areaStyle: {
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          {
            offset: 0,
            color: '#29d7ff'
          },
          {
            offset: 1,
            color: '#34ccef85'
          }
        ])
      },
      data: [100, 2, 260, 1, 200, 30, 101, 40]
    }
  ]
}

export { chartLeft1, chartLeft2, chartLeft3, chartRight1 }
