<template>
  <div>
    <el-breadcrumb>
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>用户管理</el-breadcrumb-item>
      <el-breadcrumb-item>用户列表</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <el-row :gutter="20">
        <el-col :span="8">
          <el-input placeholder="请输入内容" v-model="queryParam.query" clearable v-on:clear="getUserList" size="small">
            <el-button slot="append" icon="el-icon-search" v-on:click="getUserList" size="small"></el-button>
          </el-input>
        </el-col>
        <el-col :span="6">
          <el-button type="primary" v-on:click="showAddDialoge" v-has="'users:add'" size="small">添加用户</el-button>
        </el-col>
      </el-row>
      <el-table :data="userList" style="width: 100%" stripe border>
        <el-table-column type="index" width="30"></el-table-column>
        <el-table-column prop="name" label="姓名"></el-table-column>
        <el-table-column prop="mobile" label="电话" width="180"></el-table-column>
        <!-- <el-table-column prop="email" label="邮箱"></el-table-column> -->
        <!-- <el-table-column prop="company_name" label="企业"></el-table-column> -->
        <el-table-column label="用户类型">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.type === 1">平台用户</el-tag>
            <el-tag v-else-if="scope.row.type === 2" type="primary" size="small">中心用户</el-tag>
            <el-tag v-else-if="scope.row.type === 3" type="warning" size="small">网点用户</el-tag>
            <el-tag v-else type="primary" size="small">管理员</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="role_name" label="角色"></el-table-column>
        <el-table-column label="状态">
          <template slot-scope="scope" v-if="scope.row.type !== 5">
            <!-- {{ scope.row }} -->
            <el-switch v-model="scope.row.locked" v-on:change="changeState(scope.row)" v-has="'users:state'"> </el-switch>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="230">
          <template slot-scope="scope" v-if="scope.row.type !== 5">
            <el-button type="primary" icon="el-icon-edit" size="mini" v-on:click="showEditDialoge(scope.row.id)" v-has="'users:edit'"></el-button>
            <el-button type="danger" icon="el-icon-delete" size="mini" v-on:click="deleteUser(scope.row.id)" v-has="'users:remove'"></el-button>
            <el-tooltip effect="dark" content="设置角色" placement="top" v-bind:enterable="false">
              <el-button type="warning" icon="el-icon-setting" size="mini" v-on:click="showSetRoleDialoge(scope.row)" v-has="'users:allot'"></el-button>
            </el-tooltip>
            <el-tooltip effect="dark" content="修改管辖网点" placement="top" v-bind:enterable="false" v-if="scope.row.type === 3">
              <el-button type="warning" icon="el-icon-s-home" size="mini" v-on:click="showSetStationDialoge(scope.row)" v-has="'users:station'"></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <el-dialog title="添加用户" :visible.sync="addDialogeVisible" width="50%" v-on:closed="addDialogeClosed">
        <el-form :model="addUserForm" :rules="addUserFormRules" ref="addUserFormRef" label-width="90px">
          <el-form-item label="用户名" prop="name">
            <el-input v-model="addUserForm.name"></el-input>
          </el-form-item>
          <el-form-item label="密码" prop="password">
            <el-input v-model="addUserForm.password"></el-input>
          </el-form-item>
          <el-form-item label="电话" prop="mobile">
            <el-input v-model="addUserForm.mobile"></el-input>
          </el-form-item>
          <el-form-item label="邮箱" prop="email">
            <el-input v-model="addUserForm.email"></el-input>
          </el-form-item>
          <el-form-item label="用户类型" prop="type">
            <el-select v-model="addUserForm.type" placeholder="请选择用户类型">
              <el-option v-for="item in userTypeList" :key="item.id" :label="item.name" :value="item.id"> </el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="addDialogeVisible = false">取 消</el-button>
          <el-button type="primary" @click="addUser">确 定</el-button>
        </span>
      </el-dialog>
      <!-- 修改用户对话框 -->
      <el-dialog title="修改用户" :visible.sync="editDialogeVisible" width="50%" v-on:closed="editDialogeClosed">
        <el-form :model="editUserForm" :rules="editUserFormRules" ref="editUserFormRef" label-width="90px">
          <el-form-item label="用户名">
            <el-input v-model="editUserForm.name"></el-input>
          </el-form-item>
          <el-form-item label="密码" prop="password">
            <el-input v-model="editUserForm.password"></el-input>
          </el-form-item>
          <el-form-item label="电话" prop="mobile">
            <el-input v-model="editUserForm.mobile"></el-input>
          </el-form-item>
          <el-form-item label="邮箱" prop="email">
            <el-input v-model="editUserForm.email"></el-input>
          </el-form-item>
          <el-form-item label="用户类型" prop="type">
            <el-select v-model="editUserForm.type">
              <el-option v-for="item in userTypeList" :key="item.id" :label="item.name" :value="item.id"> </el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="editDialogeClosed">取 消</el-button>
          <el-button type="primary" @click="editUser">确 定</el-button>
        </span>
      </el-dialog>
      <!-- 分配角色对话框 -->
      <el-dialog title="分配角色" :visible.sync="setRoleDialogeVisible" width="50%" v-on:close="onSetRoleDialogeClosed">
        <el-form ref="roleForm" :model="currentUser" label-width="80px">
          <el-form-item label="用户名">
            <el-input v-model="currentUser.name" disabled></el-input>
          </el-form-item>
          <el-form-item label="当前角色">
            <el-input v-model="currentUser.role_name" disabled></el-input>
          </el-form-item>
          <el-form-item label="角色">
            <el-select v-model="selectedRoleId" placeholder="请选择角色">
              <el-option v-for="item in roleList" :key="item.id" :label="item.name" :value="item.id"> </el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="setRoleDialogeVisible = false">取 消</el-button>
          <el-button type="primary" @click="saveRole">确 定</el-button>
        </span>
      </el-dialog>
      <!-- 修改用户管辖网点对话框 -->
      <el-dialog title="修改用户管辖网点" :visible.sync="setStationDialogeVisible" width="50%" v-on:closed="onSetStationDialogeClosed">
        <el-form :model="currentUser" label-width="80px">
          <el-form-item label="用户名">
            <el-input v-model="currentUser.name" disabled></el-input>
          </el-form-item>
          <el-form-item label="管辖网点">
            <el-select v-model="selectedStationList" multiple clearable style="width:100%">
              <el-option v-for="item in stationList" :key="item.id" :label="item.name" :value="item.id"> </el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="setStationDialogeVisible = false">取 消</el-button>
          <el-button type="primary" @click="saveStation">确 定</el-button>
        </span>
      </el-dialog>
      <el-pagination :hide-on-single-page="true" @size-change="handleSizeChange" @current-change="handleCurrentChange" v-bind:current-page="queryParam.pagenum" v-bind:page-sizes="this.pageOption" v-bind:page-size="queryParam.pagesize" layout="total, sizes, prev, pager, next, jumper" v-bind:total="total"> </el-pagination>
    </el-card>
  </div>
</template>
<script>
export default {
  created: function() {
    this.getUserList()
  },
  data: function() {
    var checkEmail = (rule, value, callback) => {
      const regEmail = /^[_a-z0-9-]+(\.[_a-z0-9-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,})$/
      if (regEmail.test(value)) {
        return callback()
      }
      callback(new Error('请输入正确的邮箱'))
    }
    var checkMobile = (rule, value, callback) => {
      const regMobile = /^1([358][0-9]|4[579]|66|7[0135678]|9[89])[0-9]{8}$/
      if (regMobile.test(value)) {
        return callback()
      }
      callback(new Error('请输入正确的手机号'))
    }
    return {
      queryParam: {
        queryJson: '',
        pagenum: 1,
        pagesize: this.pageOption[0]
      },
      userList: [],
      total: 0,
      addDialogeVisible: false,
      addUserForm: {
        name: '',
        password: '',
        mobile: '',
        email: '',
        type: ''
      },
      addUserFormRules: {
        name: [
          { required: true, message: '请输入用户名', trigger: 'blur' },
          { min: 3, max: 15, message: '长度在3至10个字符', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { min: 6, max: 15, message: '长度在6至15个字符', trigger: 'blur' }
        ],
        mobile: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { validator: checkMobile, trigger: 'blur' }
        ],
        email: [
          { required: true, message: '请输入邮箱', trigger: 'blur' },
          { validator: checkEmail, trigger: 'blur' }
        ],
        type: [{ required: true, message: '请选择用户类型', trigger: 'blur' }]
      },
      // 控制修改用户对话框的显示与隐藏
      editDialogeVisible: false,
      editUserForm: {},
      editUserFormRules: {
        name: [
          { required: true, message: '请输入用户名', trigger: 'blur' },
          { min: 3, max: 15, message: '长度在3至10个字符', trigger: 'blur' }
        ],
        mobile: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { validator: checkMobile, trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { min: 6, max: 15, message: '长度在6至15个字符', trigger: 'blur' }
        ],
        email: [
          { required: true, message: '请输入邮箱', trigger: 'blur' },
          { validator: checkEmail, trigger: 'blur' }
        ],
        type: [{ required: true, message: '请选择用户类型', trigger: 'blur' }]
      },
      // 当前需要分配角色的用户
      currentUser: {},
      setRoleDialogeVisible: false,
      selectedRoleId: '',
      roleList: [],
      companyList: [],
      userTypeList: [],
      stationList: [],
      selectedStationList: [],
      setStationDialogeVisible: false
    }
  },
  methods: {
    getUserList: async function() {
      const { data: res } = await this.$http.get('/Account/Employees', {
        params: this.queryParam
      })
      console.log(res)
      if (res.status !== 1) return this.$message.error(res.msg)
      this.userList = res.data.users
      this.total = res.data.total
      this.pagenum = res.data.pagenum
    },
    handleSizeChange: function(val) {
      this.queryParam.pagesize = val
      this.getUserList()
    },
    handleCurrentChange: function(val) {
      this.queryParam.pagenum = val
      this.getUserList()
    },
    changeState: async function(row) {
      console.log(row)
      const { data: res } = await this.$http.put(`/Account/State?id=${row.id}&locked=${row.locked}`)
      if (res.status !== 1) {
        row.locked = !row.locked
        return this.$message.error('更新用户状态失败:' + res.msg)
      }
      this.$message.success('更新用户状态成功')
    },
    showAddDialoge: function() {
      this.getUserTypeList()
      this.addDialogeVisible = true
    },
    addDialogeClosed: function() {
      this.$refs.addUserFormRef.resetFields()
    },
    addUser: function() {
      this.$refs.addUserFormRef.validate(async valid => {
        console.log(valid)
        if (!valid) return
        const { data: res } = await this.$http.post('/Account/Employee', this.addUserForm)
        if (res.status !== 1) return this.$message.error('添加用户失败')
        this.$message.success('添加用户成功')
        this.addDialogeVisible = false
        this.getUserList()
      })
    },
    showEditDialoge: async function(id) {
      console.log(id)
      const { data: res } = await this.$http.get(`/Account/Employee?id=${id}`)
      if (res.status !== 1) return this.$message.error('获取用户信息失败')
      this.editUserForm = res.data
      this.editDialogeVisible = true
      this.getUserTypeList()
    },
    editUser: function() {
      this.$refs.editUserFormRef.validate(async valid => {
        if (!valid) return
        const { data: res } = await this.$http.put('/Account/Employee?id=' + this.editUserForm.id, { name: this.editUserForm.name, email: this.editUserForm.email, mobile: this.editUserForm.mobile, password: this.editUserForm.password, type: this.editUserForm.type })
        if (res.status !== 1) {
          return this.$message.error('更新用户信息失败')
        }
        // 关闭对话框
        this.editDialogeVisible = false
        this.getUserList()
        // 提示用户
        this.$message.success('更新用户信息成功')
      })
    },
    editDialogeClosed: function() {
      this.$refs.editUserFormRef.resetFields()
      this.editDialogeVisible = false
    },
    deleteUser: async function(id) {
      const confirmResult = await this.$confirm('此操作将永久删除该用户, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(err => err)
      console.log(confirmResult)
      // 返回promise 可通过async 和await简化操作
      // 点击确定时 res得到字符串confirm
      // 点击取消时 会报错，通过catch接收，res得到的错误信息是字符串cancel
      if (confirmResult !== 'confirm') {
        return this.$message('已取消删除')
      }
      const { data: res } = await this.$http.delete('/Account/EmployeeDel?id=' + id)
      if (res.status !== 1) {
        return this.$message.error('删除用户失败:' + res.msg)
      }
      this.getUserList()
      this.$message.success('删除用户成功')
    },
    showSetRoleDialoge: async function(userInfo) {
      this.currentUser = userInfo
      const { data: res } = await this.$http.get('/Account/Roles')
      if (res.status !== 1) {
        return this.$message.error('获取角色列表失败')
      }
      console.log(res.data)
      this.roleList = res.data
      this.setRoleDialogeVisible = true
    },
    saveRole: async function() {
      if (!this.selectedRoleId) {
        return this.$message.error('请选择角色')
      }
      const { data: res } = await this.$http.put('/Account/EmployeeRole?id=' + this.currentUser.id + '&role_id=' + this.selectedRoleId)
      if (res.status !== 1) {
        return this.$message.error('分配角色失败:' + res.msg)
      }
      this.$message.success('分配角色成功')
      this.getUserList()
      this.setRoleDialogeVisible = false
    },
    onSetRoleDialogeClosed: function() {
      this.selectedRoleId = ''
      this.currentUser = {}
    },
    getUserTypeList: async function() {
      const { data: res } = await this.$http.get('/Account/UserType')
      console.log(res)
      if (res.status !== 1) return this.$message.error('获取用户类型失败')
      this.userTypeList = res.data
    },
    showSetStationDialoge: async function(userInfo) {
      this.currentUser = userInfo
      const { data: res } = await this.$http.get('/PartnerPerson/Station')
      if (res.status !== 1) {
        return this.$message.error('获取网点列表失败')
      }
      console.log(res.data)
      this.stationList = res.data

      const { data: res1 } = await this.$http.get('/Account/EmployeeStations?id=' + userInfo.id)
      if (res1.status !== 1) {
        return this.$message.error('获取员工网点列表失败')
      }
      console.log(res1.data)
      this.selectedStationList = res1.data
      this.setStationDialogeVisible = true
    },
    onSetStationDialogeClosed: function() {
      this.selectedStationList = []
      this.currentUser = {}
    },
    saveStation: async function() {
      if (this.selectedStationList === [] || this.selectedStationList.length === 0) {
        return this.$message.error('请选择角色')
      }
      const { data: res } = await this.$http.put('/Account/EmployeeStations?id=' + this.currentUser.id + '&station_id=' + this.selectedStationList.join(','))
      if (res.status !== 1) {
        return this.$message.error('设置网点失败:' + res.msg)
      }
      this.$message.success('设置网点成功')
      this.setStationDialogeVisible = false
    }
  }
}
</script>
