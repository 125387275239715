<template>
  <div>
    <el-row>
      <el-col>
        <el-button type="primary" v-on:click="addCorpDialogeVisible = true" v-has="'partners:add'" size="small">添加</el-button>
      </el-col>
    </el-row>
    <el-table :data="corpPartnerList" border stripe>
      <el-table-column type="index" label="#"></el-table-column>
      <!-- <el-table-column prop="company_name" label="所属企业"> </el-table-column> -->
      <el-table-column prop="station_name" label="合伙网点"> </el-table-column>
      <el-table-column prop="corp_name" label="名称"> </el-table-column>
      <!-- <el-table-column prop="role_name" label="角色"> </el-table-column> -->
      <el-table-column prop="state" label="状态">
        <template slot-scope="scope">
          <el-switch v-model="scope.row.state" v-on:on-change="handleCorpState(scope.row)" v-has="'partners:state'"></el-switch>
          <div v-if="scope.row.state">启用</div>
          <div v-else>禁用</div>
        </template>
      </el-table-column>
      <el-table-column prop="share_type" label="分账" width="300px">
        <template slot-scope="scope">
          <el-tag size="medium" v-if="scope.row.share_type === 1">每笔销售金额扣除支付手续费后按{{ scope.row.share_value }}%分账</el-tag>
          <el-tag size="medium" v-else type="success">每笔销售金额扣除支付手续费后按固定值{{ scope.row.share_value }}元分账</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="balance" label="累计分账金额"> </el-table-column>
      <el-table-column label="操作" width="500">
        <template slot-scope="scope">
          <el-button type="text" icon="el-icon-edit" size="mini" v-on:click="showEditCorpDialoge(scope.row.id)" v-has="'partners:edit'">修改</el-button>
          <el-button type="text" icon="el-icon-delete" size="mini" v-on:click="handleDeleteCorp(scope.row.id)" v-has="'partners:remove'">删除</el-button>
          <el-button type="text" icon="el-icon-s-custom" size="mini" v-on:click="handleCorpRegister(scope.row.id)" v-has="'partners:remove'">开户注册</el-button>
          <el-button type="text" icon="el-icon-s-claim" size="mini" v-on:click="showPicCorpDialoge(scope.row.id)" v-has="'partners:remove'">图片认证</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 添加对话框 -->
    <el-dialog title="添加企业分账人" :visible.sync="addCorpDialogeVisible" width="50%" v-on:closed="addCorpDialogeClosed">
      <el-form :model="addCorpForm" :rules="corpFormRules" ref="addCorpFormRef" label-width="150px">
        <el-form-item label="分账网点" prop="station_id">
          <el-select v-model="addCorpForm.station_id" placeholder="请选择分账网点">
            <el-option v-for="item in stationList" :key="item.id" :label="item.name" :value="item.id"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="分账类型" prop="share_type">
          <el-radio-group v-model="addCorpForm.share_type">
            <el-radio v-bind:label="1">按百分比分账</el-radio>
            <el-radio v-bind:label="2" disabled>按固定值分账</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="分账数值" prop="share_value">
          <el-input v-model.number="addCorpForm.share_value" type="number" :max="100" :step="1"></el-input>
        </el-form-item>
        <el-form-item label="企业名称" prop="corp_name">
          <el-input v-model="addCorpForm.corp_name"></el-input>
        </el-form-item>
        <el-form-item label="统一社会信用代码" prop="social_credit_code">
          <el-input v-model="addCorpForm.social_credit_code"></el-input>
        </el-form-item>
        <el-form-item label="营业执照起始日期" prop="license_start_date">
          <el-input v-model="addCorpForm.license_start_date"></el-input>
        </el-form-item>
        <el-form-item label="营业执照结束日期" prop="license_end_date">
          <el-input v-model="addCorpForm.license_end_date"></el-input>
        </el-form-item>
        <el-form-item label="企业经营地址" prop="corp_business_address">
          <el-input v-model="addCorpForm.corp_business_address"></el-input>
        </el-form-item>
        <el-form-item label="企业的注册地址" prop="corp_reg_address">
          <el-input v-model="addCorpForm.corp_reg_address"></el-input>
        </el-form-item>
        <el-form-item label="固定电话" prop="corp_fixed_telephone">
          <el-input v-model="addCorpForm.corp_fixed_telephone"></el-input>
        </el-form-item>
        <el-form-item label="经营范围" prop="business_scope">
          <el-input v-model="addCorpForm.business_scope"></el-input>
        </el-form-item>
        <el-form-item label="法人姓名" prop="legal_name">
          <el-input v-model="addCorpForm.legal_name"></el-input>
        </el-form-item>
        <el-form-item label="法人身份证号码" prop="legal_cert_id">
          <el-input v-model="addCorpForm.legal_cert_id"></el-input>
        </el-form-item>
        <el-form-item label="法人证件开始日期" prop="legal_cert_start_date">
          <el-input v-model="addCorpForm.legal_cert_start_date"></el-input>
        </el-form-item>
        <el-form-item label="法人证件结束日期" prop="legal_cert_end_date">
          <el-input v-model="addCorpForm.legal_cert_end_date"></el-input>
        </el-form-item>
        <el-form-item label="法人手机号" prop="legal_mobile">
          <el-input v-model="addCorpForm.legal_mobile"></el-input>
        </el-form-item>
        <el-form-item label="联系人" prop="contact_name">
          <el-input v-model="addCorpForm.contact_name"></el-input>
        </el-form-item>
        <el-form-item label="联系人电话" prop="contact_mobile">
          <el-input v-model="addCorpForm.contact_mobile"></el-input>
        </el-form-item>
        <el-form-item label="开户银行账户" prop="bank_acct_no">
          <el-input v-model="addCorpForm.bank_acct_no"></el-input>
        </el-form-item>
        <el-form-item label="开户银行账户名" prop="bank_acct_name">
          <el-input v-model="addCorpForm.bank_acct_name"></el-input>
        </el-form-item>
        <el-form-item label="开户银行" prop="bank_code">
          <el-cascader expand-trigger="hover" ref="addCorpBankRef" :options="corpBankData" v-model="addCorpForm.bank_code"> </el-cascader>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addCorpDialogeVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleAddCorp">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 修改对话框 -->
    <el-dialog title="修改分账人" :visible.sync="editCorpDialogeVisible" width="50%" v-on:closed="editCorpDialogeClosed">
      <el-form :model="editCorpForm" :rules="corpFormRules" ref="editCorpFormRef" label-width="150px">
        <el-form-item label="分账网点" prop="station_id">
          <el-select v-model="editCorpForm.station_id" placeholder="请选择分账网点">
            <el-option v-for="item in stationList" :key="item.id" :label="item.name" :value="item.id"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="分账类型" prop="share_type">
          <el-radio-group v-model="editCorpForm.share_type">
            <el-radio v-bind:label="1">按百分比分账</el-radio>
            <el-radio v-bind:label="2" disabled>按固定值分账</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="分账数值" prop="share_value">
          <el-input v-model.number="editCorpForm.share_value" type="number" :max="100" :step="1"></el-input>
        </el-form-item>
        <el-form-item label="企业名称" prop="corp_name">
          <el-input v-model="editCorpForm.corp_name"></el-input>
        </el-form-item>
        <el-form-item label="统一社会信用代码" prop="social_credit_code">
          <el-input v-model="editCorpForm.social_credit_code"></el-input>
        </el-form-item>
        <el-form-item label="营业执照起始日期" prop="license_start_date">
          <el-input v-model="editCorpForm.license_start_date"></el-input>
        </el-form-item>
        <el-form-item label="营业执照结束日期" prop="license_end_date">
          <el-input v-model="editCorpForm.license_end_date"></el-input>
        </el-form-item>
        <el-form-item label="企业经营地址" prop="corp_business_address">
          <el-input v-model="editCorpForm.corp_business_address"></el-input>
        </el-form-item>
        <el-form-item label="企业的注册地址" prop="corp_reg_address">
          <el-input v-model="editCorpForm.corp_reg_address"></el-input>
        </el-form-item>
        <el-form-item label="固定电话" prop="corp_fixed_telephone">
          <el-input v-model="editCorpForm.corp_fixed_telephone"></el-input>
        </el-form-item>
        <el-form-item label="经营范围" prop="business_scope">
          <el-input v-model="editCorpForm.business_scope"></el-input>
        </el-form-item>
        <el-form-item label="法人姓名" prop="legal_name">
          <el-input v-model="editCorpForm.legal_name"></el-input>
        </el-form-item>
        <el-form-item label="法人身份证号码" prop="legal_cert_id">
          <el-input v-model="editCorpForm.legal_cert_id"></el-input>
        </el-form-item>
        <el-form-item label="法人证件开始日期" prop="legal_cert_start_date">
          <el-input v-model="editCorpForm.legal_cert_start_date"></el-input>
        </el-form-item>
        <el-form-item label="法人证件结束日期" prop="legal_cert_end_date">
          <el-input v-model="editCorpForm.legal_cert_end_date"></el-input>
        </el-form-item>
        <el-form-item label="法人手机号" prop="legal_mobile">
          <el-input v-model="editCorpForm.legal_mobile"></el-input>
        </el-form-item>
        <el-form-item label="联系人" prop="contact_name">
          <el-input v-model="editCorpForm.contact_name"></el-input>
        </el-form-item>
        <el-form-item label="联系人电话" prop="contact_mobile">
          <el-input v-model="editCorpForm.contact_mobile"></el-input>
        </el-form-item>
        <el-form-item label="开户银行账户" prop="bank_acct_no">
          <el-input v-model="editCorpForm.bank_acct_no"></el-input>
        </el-form-item>
        <el-form-item label="开户银行账户名" prop="bank_acct_name">
          <el-input v-model="editCorpForm.bank_acct_name"></el-input>
        </el-form-item>
        <el-form-item label="开户银行" prop="bank_code">
          <el-cascader expand-trigger="hover" ref="editCorpBankRef" :options="corpBankData" v-model="editCorpForm.bank_code"> </el-cascader>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editCorpDialogeClosed">取 消</el-button>
        <el-button type="primary" @click="handleEditCorp">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import corpBankData from './corpBankdata'
import _ from 'lodash'
export default {
  data() {
    return {
      stationList: [],
      addCorpDialogeVisible: false,
      addCorpForm: {
        share_type: 1,
        share_value: '',
        user_type: 2,
        station_id: '',
        plat_user_name: '',
        corp_name: '',
        social_credit_code: '',
        license_start_date: '',
        license_end_date: '',
        corp_business_address: '',
        corp_reg_address: '',
        corp_fixed_telephone: '',
        business_scope: '',
        legal_name: '',
        legal_cert_type: '01',
        legal_cert_id: '',
        legal_cert_start_date: '',
        legal_cert_end_date: '',
        legal_mobile: '',
        contact_name: '',
        contact_mobile: '',
        bank_code: [],
        bank_acct_no: '',
        bank_acct_name: ''
      },
      corpFormRules: {
        station_id: [{ required: true, message: '请选择网点', trigger: 'blur' }],
        share_type: [{ required: true, message: '请选择分账类型', trigger: 'change' }],
        share_value: [
          { required: true, message: '请输入分账数值', trigger: 'blur' },
          { type: 'number', message: '必须为数字', trigger: 'blur' }
        ],
        corp_name: [{ required: true, message: '请输入企业名称', trigger: 'blur' }],
        social_credit_code: [{ required: true, message: '请输入统一社会信用代码', trigger: 'blur' }],
        license_start_date: [{ required: true, message: '请输入营业执照起始日期' }],
        license_end_date: [{ required: true, message: '请输入营业执照结束日期' }],
        corp_business_address: [{ required: true, message: '请输入企业经营地址' }],
        corp_reg_address: [{ required: true, message: '请输入企业的注册地址' }],
        corp_fixed_telephone: [{ required: true, message: '请输入固定电话' }],
        business_scope: [{ required: true, message: '请输入经营范围' }],
        legal_name: [{ required: true, message: '请输入营业执照结束日期' }],
        legal_cert_id: [{ required: true, message: '请输入法人身份证号码' }],
        legal_cert_start_date: [{ required: true, message: '请输入法人证件开始日期' }],
        legal_cert_end_date: [{ required: true, message: '请输入法人证件结束日期' }],
        legal_mobile: [{ required: true, message: '请输入法人手机号', trigger: 'blur' }],
        contact_name: [{ required: true, message: '请输入联系人', trigger: 'blur' }],
        contact_mobile: [{ required: true, message: '请输入联系人电话', trigger: 'blur' }],
        bank_code: [{ required: true, message: '请选择开户银行', trigger: 'blur' }],
        bank_acct_no: [{ required: true, message: '请输入开户银行账户', trigger: 'blur' }],
        bank_acct_name: [{ required: true, message: '请输入开户银行账户名', trigger: 'blur' }]
      },

      editCorpDialogeVisible: false,
      editCorpForm: {},
      corpBankData,
      corpPartnerList: []
    }
  },
  mounted() {
    this.getPartnerCorpList()
    this.getStationList()
  },
  methods: {
    getStationList: async function() {
      const { data: res } = await this.$http.get('/PartnerPerson/Station')
      if (res.status !== 1) return this.$message.error('获取网点信息失败')
      this.stationList = res.data
    },
    getPartnerCorpList: async function() {
      const { data: res } = await this.$http.get('/PartnerCorp/List')
      if (res.status !== 1) {
        return this.$message.error('获取分账人列表失败')
      }
      console.log(res.data)
      this.corpPartnerList = res.data
    },

    handleAddCorp: function() {
      this.$refs.addCorpFormRef.validate(async valid => {
        if (!valid) return
        const form = _.cloneDeep(this.addCorpForm)
        const nodeList = this.$refs.addCorpBankRef.getCheckedNodes()
        this.$set(form, 'bank_branch_name', nodeList[0].label)
        this.$set(form, 'bank_branch_code', nodeList[0].value.split('|')[0])
        this.$set(form, 'county_area_code', nodeList[0].value.split('|')[1])
        this.$set(form, 'bank_name', nodeList[0].parent.label)
        this.$set(form, 'bank_code', nodeList[0].parent.value)
        this.$set(form, 'bank_prov', nodeList[0].value.split('|')[2])
        this.$set(form, 'bank_area', nodeList[0].value.split('|')[3])
        const { data: res } = await this.$http.post('/PartnerCorp/Add', form)
        if (res.status !== 1) return this.$message.error('添加失败，' + res.msg)
        this.$message.success('添加成功')
        this.addCorpDialogeVisible = false
        this.getPartnerCorpList()
      })
    },
    addCorpDialogeClosed: function() {
      this.$refs.addCorpFormRef.resetFields()
    },
    showEditCorpDialoge: async function(id) {
      const { data: res } = await this.$http.get(`/PartnerCorp/One?id=${id}`)
      if (res.status !== 1) return this.$message.error('获取分账人信息失败')
      this.editCorpForm = res.data
      this.editCorpForm.bank_code = [res.data.bank_code, res.data.bank_branch_code + '|' + res.data.county_area_code + '|' + res.data.bank_prov + '|' + res.data.bank_area]
      this.editCorpDialogeVisible = true
    },
    handleEditCorp: function() {
      this.$refs.editCorpFormRef.validate(async valid => {
        if (!valid) return
        const form = _.cloneDeep(this.editCorpForm)
        const nodeList = this.$refs.editCorpBankRef.getCheckedNodes()
        this.$set(form, 'bank_branch_name', nodeList[0].label)
        this.$set(form, 'bank_branch_code', nodeList[0].value.split('|')[0])
        this.$set(form, 'county_area_code', nodeList[0].value.split('|')[1])
        this.$set(form, 'bank_name', nodeList[0].parent.label)
        this.$set(form, 'bank_code', nodeList[0].parent.value)
        this.$set(form, 'bank_prov', nodeList[0].value.split('|')[2])
        this.$set(form, 'bank_area', nodeList[0].value.split('|')[3])
        const { data: res } = await this.$http.put('/PartnerCorp/Edit?id=' + form.id, form)
        if (res.status !== 1) {
          return this.$message.error('更新失败：' + res.msg)
        }
        this.editCorpDialogeVisible = false
        this.getPartnerCorpList()
        this.$message.success('更新成功')
      })
    },
    editCorpDialogeClosed: function() {
      this.$refs.editCorpFormRef.resetFields()
      this.editCorpDialogeVisible = false
    },
    handleDeleteCorp: async function(id) {
      const confirmResult = await this.$confirm('此操作将永久删除该分账人, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(err => err)
      if (confirmResult !== 'confirm') {
        return this.$message('已取消删除')
      }
      const { data: res } = await this.$http.delete('/PartnerCorp/Del?id=' + id)
      if (res.status !== 1) {
        return this.$message.error('删除失败')
      }
      this.getTabData()
      this.$message.success('删除成功')
    },
    handleCorpState: async function(row) {
      console.log(row)
      const { data: res } = await this.$http.put(`/PartnerCorp/State?id=${row.id}&state=${row.state}`)
      if (res.status !== 1) {
        row.state = row.state === 0 ? 1 : 0
        this.$message.error('更新状态失败' + res.msg)
      }
      this.$message.success('更新状态成功')
    },
    handleCorpRegister: async function(id) {
      const { data: res } = await this.$http.get('/PartnerCorp/Register?id=' + id)
      if (res.status !== 1) {
        return this.$message.error('开户注册失败：' + res.msg)
      }
    }
  }
}
</script>
<style lang="less" scoped>
.user_name {
  display: flex;
  align-items: center;
}
.renzheng {
  width: 25px;
  // position: absolute;
  // right: 0px;
  // top: 0px;
  background-color: lightgreen;
  margin-left: 10px;
}
</style>
