<template>
  <div class="container">
    <div class="title_section">
      <el-row :gutter="20">
        <el-col :span="8"
          ><div class="grid-content">
            <transition name="el-zoom-in-center">
              <el-card class="box-card" v-show="showCardData">
                <div slot="header" class="clearfix"><i class="el-icon-s-flag flag0"></i><span>今日销售</span></div>
                <div class="item">
                  <div class="item_row">加注笔数：{{ cardData.trade.count }}笔</div>
                  <div class="item_row">加注升数：{{ cardData.trade.qty }}升</div>
                  <div class="item_row">加注金额：{{ cardData.trade.money }}元</div>
                </div>
              </el-card>
            </transition>
          </div></el-col
        >
        <el-col :span="8"
          ><div class="grid-content">
            <transition name="el-zoom-in-top">
              <el-card class="box-card" v-show="showCardData">
                <div slot="header" class="clearfix"><i class="el-icon-s-flag flag1"></i><span>今日收款</span></div>
                <div class="item">
                  <div class="item_row">收款笔数：{{ cardData.pay.count }}笔</div>
                  <div class="item_row">收款金额：{{ cardData.pay.money }}元</div>
                </div>
              </el-card>
            </transition>
          </div></el-col
        >
        <!-- <el-col :span="6"
          ><div class="grid-content">
            <transition name="el-zoom-in-bottom">
              <el-card class="box-card" v-show="showCardData">
                <div slot="header" class="clearfix"><i class="el-icon-s-flag flag2"></i><span>今日充值</span></div>
                <div class="item">
                  <div class="item_row">充值笔数：{{ cardData.charge.count }}笔</div>
                  <div class="item_row">充值金额：{{ cardData.charge.money }}元</div>
                </div>
              </el-card>
            </transition>
          </div></el-col
        > -->
        <el-col :span="8"
          ><div class="grid-content">
            <transition name="el-zoom-in-center">
              <el-card class="box-card" v-show="showCardData">
                <div slot="header" class="clearfix"><i class="el-icon-s-flag flag3"></i><span>今日分账</span></div>
                <div class="item">
                  <div class="item_row">分账笔数：{{ cardData.account.count }}笔</div>
                  <div class="item_row">分账金额：{{ cardData.account.money }}元</div>
                </div>
              </el-card>
            </transition>
          </div></el-col
        >
      </el-row>
    </div>
    <div class="middle_section">
      <el-row>
        <el-card class="chart1" header="最近7日数据汇总" style="height: auto;">
          <div id="main" style="width:100%;height: 500px"></div>
        </el-card>
      </el-row>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    const colors = ['#5793f3', '#d14a61', '#675bba']
    return {
      cardData: {
        trade: {
          count: 0,
          qty: 0,
          money: 0
        },
        pay: {
          count: 0,
          money: 0
        },
        account: {
          count: 0,
          money: 0
        }
      },
      option: {
        color: colors,
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross'
          }
        },
        grid: {
          right: '20%'
        },
        legend: {
          data: ['加注笔数', '加注金额', '加注升数', '收款笔数', '收款金额', '分账笔数', '分账金额']
        },
        xAxis: [
          {
            type: 'category',
            axisLabel: { rotate: 40 },
            axisTick: {
              alignWithLabel: true
            },
            data: []
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: '笔数',
            min: 0,
            max: 300,
            position: 'right',
            offset: 80,
            axisLine: {
              lineStyle: {
                color: colors[0]
              }
            },
            axisLabel: {
              formatter: '{value} 笔'
            }
          },
          {
            type: 'value',
            name: '金额',
            min: 0,
            max: 5000,
            position: 'left',
            axisLine: {
              lineStyle: {
                color: colors[1]
              }
            },
            axisLabel: {
              formatter: '{value} 元'
            }
          },
          {
            type: 'value',
            name: '升数',
            min: 0,
            max: 3000,
            position: 'right',
            axisLine: {
              lineStyle: {
                color: colors[2]
              }
            },
            axisLabel: {
              formatter: '{value} 升'
            }
          }
        ],
        series: [
          {
            name: '加注笔数',
            type: 'bar',
            // smooth: true,
            color: '#ddd',
            data: []
          },
          {
            name: '加注金额',
            type: 'bar',
            // smooth: true,
            yAxisIndex: 1,
            color: 'green',
            data: []
          },
          {
            name: '加注升数',
            type: 'bar',
            // smooth: true,
            yAxisIndex: 2,
            data: []
          },
          {
            name: '收款笔数',
            type: 'bar',
            // smooth: true,
            data: []
          },
          {
            name: '收款金额',
            type: 'bar',
            // smooth: true,
            yAxisIndex: 1,
            data: []
          },
          // {
          //   name: '充值笔数',
          //   type: 'bar',
          //   // smooth: true,
          //   color: '#1989fa',
          //   data: []
          // },
          // {
          //   name: '充值金额',
          //   type: 'bar',
          //   // smooth: true,
          //   color: 'pink',
          //   yAxisIndex: 1,
          //   data: []
          // },
          {
            name: '分账笔数',
            type: 'bar',
            // smooth: true,
            color: 'lightblue',
            data: []
          },
          {
            name: '分账金额',
            type: 'bar',
            // smooth: true,
            color: 'darkgreen',
            yAxisIndex: 1,
            data: []
          }
        ]
      },
      myChart: '',
      showCardData: false,
      showChartData: false
    }
  },
  methods: {
    myEcharts() {
      // 引入 ECharts 主模块
      var echarts = require('echarts/lib/echarts')
      // 引入柱状图
      require('echarts/lib/chart/bar')
      require('echarts/lib/chart/line')
      // 引入组件
      require('echarts/lib/component/tooltip')
      require('echarts/lib/component/grid')
      require('echarts/lib/component/legend')
      // 基于准备好的dom，初始化echarts实例
      this.myChart = echarts.init(document.getElementById('main'))

      // 使用刚指定的配置项和数据显示图表。
      // this.myChart.setOption(this.option)
    },
    getCardData: async function() {
      const { data: res } = await this.$http.get('/Report/CardData')
      console.log(res)
      if (res.status === 1) {
        this.cardData = res.data
        this.showCardData = true
      }
    },
    getChartData: async function() {
      const loading = this.$loading({ target: '#main', lock: true, text: 'Loading', spinner: 'el-icon-loading' })
      const { data: res } = await this.$http.get('/Report/ChartData?day=7')
      this.showChartData = true
      console.log(res)
      loading.close()
      this.option.xAxis[0].data = res.data.date_list
      this.option.yAxis[0].max = res.data.max_count
      this.option.yAxis[1].max = res.data.max_money
      this.option.yAxis[2].max = res.data.max_qty
      this.option.series[0].data = res.data.trade_count_list
      this.option.series[1].data = res.data.trade_money_list
      this.option.series[2].data = res.data.trade_qty_list
      this.option.series[3].data = res.data.pay_count_list
      this.option.series[4].data = res.data.pay_money_list
      // this.option.series[5].data = res.data.charge_count_list
      // this.option.series[6].data = res.data.charge_money_list
      this.option.series[5].data = res.data.account_count_list
      this.option.series[6].data = res.data.account_money_list
      this.myChart.setOption(this.option)
    }
  },
  mounted() {
    this.getCardData()
    this.myEcharts()
    this.getChartData()
  }
}
</script>
<style lang="less" scoped>
.title_section {
  /deep/.grid-content .el-card__header {
    padding: 12px 15px;
  }
  /deep/.grid-content .el-card__body {
    box-sizing: border-box;
    height: 5rem;
    font-size: 0.9rem;
    padding-top: 10px;
  }
  .clearfix {
    display: flex;
    align-items: center;
  }
  .flag0 {
    color: #de4f4fad;
    font-size: 2rem;
  }
  .flag1 {
    color: #b116c36b;
    font-size: 2rem;
  }
  .flag2 {
    color: #d7da3f9e;
    font-size: 2rem;
  }
  .flag3 {
    color: #3aacbf9e;
    font-size: 2rem;
  }
  .el-card {
    height: auto;
    width: auto;
  }
}
.middle_section {
  // margin-top: 15px;
  .chart1 {
    width: 100%;
    // position: relative;
    // left: 40%;
    #main {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
