<template>
  <div class="user_coupon">
    <el-breadcrumb>
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>运营数据</el-breadcrumb-item>
      <el-breadcrumb-item>用户优惠券</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <el-row :gutter="20">
        <el-col :span="8">
          <el-input placeholder="请输入用户进行搜索" v-model="queryParam.queryJson" clearable v-on:clear="getUserCouponList" size="small">
            <el-button slot="append" icon="el-icon-search" v-on:click="getUserCouponList" size="small"></el-button>
          </el-input>
        </el-col>
      </el-row>
      <el-table :data="userCouponList" border stripe>
        <el-table-column type="index" label="#"></el-table-column>
        <el-table-column prop="name" label="用户"> </el-table-column>
        <el-table-column prop="amount" label="券面值(元)"> </el-table-column>
        <el-table-column label="开始时间">
          <template slot-scope="scope">
            {{ scope.row.begin_time | dateformat }}
          </template>
        </el-table-column>
        <el-table-column label="结束时间">
          <template slot-scope="scope">
            {{ scope.row.end_time | dateformat }}
          </template>
        </el-table-column>
        <el-table-column prop="use_limit" label="满多少元可用"> </el-table-column>
        <el-table-column prop="status" label="券状态">
          <template slot-scope="scope">
            <span v-if="scope.row.status === 0" style="color: #4caf50">未使用</span>
            <span v-if="scope.row.status === 1" style="color: #2196f3">已使用</span>
            <span v-if="scope.row.status === 2" style="color: #f44336">已过期</span>
          </template>
        </el-table-column>
        <el-table-column label="发放时间" width="180px">
          <template slot-scope="scope">
            {{ scope.row.add_time | dateformat }}
          </template>
        </el-table-column>
      </el-table>
      <el-pagination :hide-on-single-page="true" @size-change="handleSizeChange" @current-change="handleCurrentChange" v-bind:current-page="queryParam.pagenum" v-bind:page-sizes="this.pageOption" v-bind:page-size="queryParam.pagesize" layout="total, sizes, prev, pager, next, jumper" v-bind:total="total"> </el-pagination>
    </el-card>
  </div>
</template>
<script>
export default {
  data() {
    return {
      queryParam: {
        queryJson: '',
        pagenum: 1,
        pagesize: this.pageOption[0]
      },
      total: 0,
      userCouponList: []
    }
  },
  created() {
    this.getUserCouponList()
  },
  methods: {
    getUserCouponList: async function() {
      const { data: res } = await this.$http.get('/UserCoupon/List', { params: this.queryParam })
      if (res.status !== 1) {
        return this.$message.error('获取用户优惠券列表失败')
      }
      console.log(res.data)
      this.userCouponList = res.data.list
      this.total = res.data.total
    },
    handleSizeChange: function(newSize) {
      this.queryParam.pagesize = newSize
      this.getUserCouponList()
    },
    handleCurrentChange: function(newPage) {
      this.queryParam.pagenum = newPage
      this.getUserCouponList()
    }
  }
}
</script>
