<template>
  <div>
    <el-breadcrumb>
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>运营设置</el-breadcrumb-item>
      <el-breadcrumb-item>尿素入库</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <el-row :gutter="20">
        <el-col :span="12" :offset="6"
          ><el-form :model="addForm" :rules="addFormRules" ref="addFormRef" label-width="100px">
            <el-form-item label="网点" prop="station_id">
              <el-select v-model="addForm.station_id" placeholder="请选择网点" style="width:100%">
                <el-option v-for="item in stationList" :key="item.id" :label="item.name" :value="item.id"> </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="罐号" prop="tank_no">
              <el-input v-model="addForm.tank_no"></el-input>
            </el-form-item>
            <el-form-item label="入库升数" prop="add_qty">
              <el-input v-model="addForm.add_qty"></el-input>
            </el-form-item>
            <el-form-item label="入库前升数" prop="before_qty">
              <el-input v-model="addForm.before_qty"></el-input>
            </el-form-item>
            <el-form-item label="入库后升数" prop="after_qty">
              <el-input v-model="addForm.after_qty"></el-input>
            </el-form-item>
            <el-form-item label="供应商" prop="supplier_name">
              <el-input v-model="addForm.supplier_name"></el-input>
            </el-form-item>
            <el-form-item label="供应商电话" prop="supplier_mobile">
              <el-input v-model="addForm.supplier_mobile"></el-input>
            </el-form-item>
            <el-form-item style="text-align: center">
              <el-button type="primary" @click="addRow">确定</el-button>
              <el-button>取消</el-button>
            </el-form-item>
          </el-form></el-col
        >
      </el-row>
    </el-card>
  </div>
</template>
<script>
export default {
  data() {
    var checkMobile = (rule, value, callback) => {
      const regMobile = /^1([358][0-9]|4[579]|66|7[0135678]|9[89])[0-9]{8}$/
      if (regMobile.test(value)) {
        return callback()
      }
      callback(new Error('请输入正确的手机号'))
    }
    return {
      addForm: {
        tank_no: '',
        add_qty: '',
        before_qty: '',
        after_qty: '',
        supplier_name: '',
        supplier_mobile: '',
        station_id: ''
      },
      addFormRules: {
        station_id: [{ required: true, message: '请选择网点', trigger: 'blur' }],
        tank_no: [{ required: true, message: '请输入罐号', trigger: 'blur' }],
        add_qty: [{ required: true, message: '请输入入库升数', trigger: 'blur' }],
        before_qty: [{ required: true, message: '请输入入库前升数', trigger: 'blur' }],
        after_qty: [{ required: true, message: '请输入入库后升数', trigger: 'blur' }],
        supplier_name: [{ required: true, message: '请输入供应商名称', trigger: 'blur' }],
        supplier_mobile: [
          { required: true, message: '请输入供应商电话', trigger: 'blur' },
          { validator: checkMobile, trigger: 'blur' }
        ]
      },
      stationList: []
    }
  },
  created() {
    this.getStationList()
  },
  methods: {
    addRow: function() {
      this.$refs.addFormRef.validate(async valid => {
        if (!valid) return
        const { data: res } = await this.$http.post('/OilAdd/Add', this.addForm)
        if (res.status !== 1) return this.$message.error('添加失败,' + res.msg)
        this.$message.success('添加成功')
        this.$router.push('/addoil')
      })
    },
    getStationList: async function() {
      const { data: res } = await this.$http.get('/Partner/Station')
      if (res.status !== 1) return this.$message.error('获取网点信息失败')
      this.stationList = res.data
    }
  }
}
</script>
