<template>
  <div>
    <el-breadcrumb>
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>系统设置</el-breadcrumb-item>
      <el-breadcrumb-item>运营商资金帐户</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <el-row>
        <el-col>
          <el-button type="primary" v-on:click="addDialogeVisible = true" v-has="'operationaccount:add'" size="small">添加</el-button>
        </el-col>
      </el-row>
      <el-table :data="operationAccountList" border stripe>
        <el-table-column type="index" label="#"></el-table-column>
        <el-table-column prop="name" label="商户名称"> </el-table-column>
        <el-table-column prop="mcht_no" label="商户号"> </el-table-column>
        <el-table-column prop="store_no" label="门店号"> </el-table-column>
        <el-table-column label="操作" width="180">
          <template slot-scope="scope">
            <el-button type="primary" icon="el-icon-edit" size="mini" v-on:click="showEditDialoge(scope.row.id)" v-has="'operationaccount:edit'"></el-button>
            <el-button type="danger" icon="el-icon-delete" size="mini" v-on:click="deleteRow(scope.row.id)" v-has="'operationaccount:remove'"></el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <!-- 添加对话框 -->
    <el-dialog title="添加资金账户" :visible.sync="addDialogeVisible" width="50%" v-on:closed="addDialogeClosed">
      <el-form :model="addForm" :rules="formRules" ref="addFormRef" label-width="100px">
        <el-form-item label="商户名称" prop="name">
          <el-input v-model="addForm.name"></el-input>
        </el-form-item>
        <el-form-item label="商户号" prop="mcht_no">
          <el-input v-model="addForm.mcht_no"></el-input>
        </el-form-item>
        <el-form-item label="门店号" prop="store_no">
          <el-input v-model="addForm.store_no"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogeVisible = false">取 消</el-button>
        <el-button type="primary" @click="addRow">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 修改对话框 -->
    <el-dialog title="修改资金账户" :visible.sync="editDialogeVisible" width="50%" v-on:closed="editDialogeClosed">
      <el-form :model="editForm" :rules="formRules" ref="editFormRef" label-width="100px">
        <el-form-item label="商户名称" prop="name">
          <el-input v-model="editForm.name"></el-input>
        </el-form-item>
        <el-form-item label="商户号" prop="mcht_no">
          <el-input v-model="editForm.mcht_no"></el-input>
        </el-form-item>
        <el-form-item label="门店号" prop="store_no">
          <el-input v-model="editForm.store_no"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogeClosed">取 消</el-button>
        <el-button type="primary" @click="editSave">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      operationAccountList: [],
      addDialogeVisible: false,
      addForm: {
        name: '',
        mcht_no: '',
        store_no: ''
      },
      formRules: {
        name: [{ required: true, message: '请输入商户名称', trigger: 'blur' }],
        mcht_no: [{ required: true, message: '请输入商户号', trigger: 'blur' }],
        store_no: [{ required: true, message: '请输入门店号', trigger: 'blur' }]
      },
      editDialogeVisible: false,
      editForm: {}
    }
  },
  created() {
    this.getOperationAccountList()
  },
  methods: {
    getOperationAccountList: async function() {
      const { data: res } = await this.$http.get('/OperationAccount/List')
      if (res.status !== 1) {
        return this.$message.error('获取资金账户列表失败')
      }
      console.log(res.data)
      this.operationAccountList = res.data
    },
    addDialogeClosed: function() {
      this.$refs.addFormRef.resetFields()
    },
    addRow: function() {
      this.$refs.addFormRef.validate(async valid => {
        if (!valid) return
        const { data: res } = await this.$http.post('/OperationAccount/Add', this.addForm)
        if (res.status !== 1) return this.$message.error('添加失败，' + res.msg)
        this.$message.success('添加成功')
        this.addDialogeVisible = false
        this.getOperationAccountList()
      })
    },
    showEditDialoge: async function(id) {
      const { data: res } = await this.$http.get(`/OperationAccount/One?id=${id}`)
      if (res.status !== 1) return this.$message.error('获取资金账户信息失败')
      this.editForm = res.data
      this.editDialogeVisible = true
    },
    editDialogeClosed: function() {
      this.$refs.editFormRef.resetFields()
      this.editDialogeVisible = false
    },
    editSave: function() {
      this.$refs.editFormRef.validate(async valid => {
        if (!valid) return
        const { data: res } = await this.$http.put('/OperationAccount/Edit?id=' + this.editForm.id, {
          name: this.editForm.name,
          mcht_no: this.editForm.mcht_no,
          store_no: this.editForm.store_no
        })
        if (res.status !== 1) {
          return this.$message.error('更新失败')
        }
        this.editDialogeVisible = false
        this.getOperationAccountList()
        this.$message.success('更新成功')
      })
    },
    deleteRow: async function(id) {
      const confirmResult = await this.$confirm('此操作将永久删除该资金账户, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(err => err)
      if (confirmResult !== 'confirm') {
        return this.$message('已取消删除')
      }
      const { data: res } = await this.$http.delete('/OperationAccount/Del?id=' + id)
      if (res.status !== 1) {
        return this.$message.error('删除失败')
      }
      this.getOperationAccountList()
      this.$message.success('删除成功')
    }
  }
}
</script>
