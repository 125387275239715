<template>
  <div>
    <el-breadcrumb>
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>系统设置</el-breadcrumb-item>
      <el-breadcrumb-item>加注白名单</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <el-row>
        <el-col>
          <el-button type="primary" v-on:click="addDialogeVisible = true" size="small">添加</el-button>
        </el-col>
      </el-row>
      <el-table :data="whiteList" border stripe>
        <el-table-column type="index" label="#"></el-table-column>
        <el-table-column prop="station_name" label="网点"> </el-table-column>
        <el-table-column prop="name" label="用户"> </el-table-column>
        <!-- <el-table-column prop="state" label="状态">
          <template slot-scope="scope">
            <i-switch size="large" v-model="scope.row.state" v-on:on-change="changeState(scope.row)" v-bind:true-value="1" v-bind:false-value="0">
              <span slot="open">启用</span>
              <span slot="close">禁用</span>
            </i-switch>
            <el-switch v-model="scope.row.state" v-on:change="changeState(scope.row)"></el-switch>
            <div v-if="scope.row.state">启用</div>
            <div v-else>禁用</div>
          </template>
        </el-table-column> -->
        <el-table-column label="操作" width="180">
          <template slot-scope="scope">
            <!-- <el-button type="primary" icon="el-icon-edit" size="mini" v-on:click="showEditDialoge(scope.row.id)" v-has="'partners:edit'"></el-button> -->
            <el-button type="danger" icon="el-icon-delete" size="mini" v-on:click="deleteRow(scope.row.id)"></el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <!-- 添加对话框 -->
    <el-dialog title="添加测试用户" :visible.sync="addDialogeVisible" width="50%" v-on:closed="addDialogeClosed">
      <el-form :model="addForm" :rules="formRules" ref="addFormRef" label-width="100px">
        <el-form-item label="网点" prop="station_name">
          <el-select v-model="addForm.station_id" placeholder="请选择网点">
            <el-option v-for="item in stationList" :key="item.id" :label="item.name" :value="item.id"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="用户" prop="name">
          <el-input v-model="addForm.user_id"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogeVisible = false">取 消</el-button>
        <el-button type="primary" @click="addRow">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: ['station_id'],
  data() {
    return {
      queryParam: {
        station_id: '',
        pagenum: 1,
        pagesize: 5
      },
      total: 0,
      whiteList: [],
      addDialogeVisible: false,
      addForm: {
        station_id: '',
        user_id: ''
      },
      formRules: {
        station_id: [{ required: true, message: '请选择网点', trigger: 'blur' }],
        user_id: [{ required: true, message: '请选择用户', trigger: 'blur' }]
      },
      editDialogeVisible: false,
      editForm: {},
      stationList: []
    }
  },
  created() {
    if (this.station_id) {
      this.queryParam.station_id = this.station_id
    }
    this.getWhiteList()
    this.getStationList()
  },
  methods: {
    getWhiteList: async function() {
      const { data: res } = await this.$http.get('/WhiteList/List', { params: this.queryParam })
      if (res.status !== 1) {
        return this.$message.error('获取白名单列表失败')
      }
      console.log(res.data)
      this.whiteList = res.data.list
    },
    addDialogeClosed: function() {
      this.$refs.addFormRef.resetFields()
    },
    addRow: function() {
      this.$refs.addFormRef.validate(async valid => {
        if (!valid) return
        const { data: res } = await this.$http.post('/WhiteList/Add', this.addForm)
        if (res.status !== 1) return this.$message.error('添加失败，' + res.msg)
        this.$message.success('添加成功')
        this.addDialogeVisible = false
        this.getWhiteList()
      })
    },
    showEditDialoge: async function(id) {
      const { data: res } = await this.$http.get(`/WhiteList/One?id=${id}`)
      if (res.status !== 1) return this.$message.error('获取白名单信息失败')
      this.editForm = res.data
      this.editDialogeVisible = true
    },
    editDialogeClosed: function() {
      this.$refs.editFormRef.resetFields()
      this.editDialogeVisible = false
    },
    deleteRow: async function(id) {
      const confirmResult = await this.$confirm('此操作将永久删除该白名单用户, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(err => err)
      if (confirmResult !== 'confirm') {
        return this.$message('已取消删除')
      }
      const { data: res } = await this.$http.delete('/WhiteList/Del?id=' + id)
      if (res.status !== 1) {
        return this.$message.error('删除失败')
      }
      this.getWhiteList()
      this.$message.success('删除成功')
    },
    changeState: async function(row) {
      console.log(row)
      const { data: res } = await this.$http.put(`/WhiteList/State?id=${row.id}&state=${row.state}`)
      if (res.status !== 1) {
        row.state = row.state === 0 ? 1 : 0
        this.$message.error('更新状态失败' + res.msg)
      }
      this.$message.success('更新状态成功')
    },
    getStationList: async function() {
      const { data: res } = await this.$http.get('/Partner/Station')
      if (res.status !== 1) return this.$message.error('获取网点信息失败')
      this.stationList = res.data
    }
  }
}
</script>
