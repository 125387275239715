<template>
  <div>
    <el-breadcrumb>
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>运营数据</el-breadcrumb-item>
      <el-breadcrumb-item>调价记录</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <el-row :gutter="20">
        <el-col :span="8">
          <el-input placeholder="请输入网点名称或品类名称" v-model="queryParam.queryJson" clearable v-on:clear="getPriceChangeList" size="small">
            <el-button slot="append" icon="el-icon-search" v-on:click="getPriceChangeList" size="small"></el-button>
          </el-input>
        </el-col>
      </el-row>
      <el-table :data="priceChangeList" border stripe>
        <el-table-column type="index" label="#"></el-table-column>
        <!-- <el-table-column prop="company_name" label="企业"> </el-table-column> -->
        <el-table-column prop="station_name" label="网点"> </el-table-column>
        <!-- <el-table-column prop="oil_code" label="品类代码"> </el-table-column> -->
        <el-table-column prop="oil_name" label="品类名称"> </el-table-column>
        <!-- <el-table-column prop="terminal_no" label="枪号"> </el-table-column> -->
        <el-table-column label="价格">
          <template slot-scope="scope">{{ scope.row.price }}元/升</template>
        </el-table-column>
        <el-table-column label="调价时间" width="150">
          <template slot-scope="scope">
            {{ scope.row.add_time | dateformat }}
          </template>
        </el-table-column>
        <el-table-column label="调价结果">
          <template slot-scope="scope">
            <el-tag size="mini" v-if="scope.row.excute_result === 0" type="warning">未执行</el-tag>
            <el-tag size="mini" v-else-if="scope.row.excute_result === 1">成功</el-tag>
            <el-tag size="mini" v-else type="danger">失败</el-tag>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination :hide-on-single-page="true" @size-change="handleSizeChange" @current-change="handleCurrentChange" v-bind:current-page="queryParam.pagenum" v-bind:page-sizes="this.pageOption" v-bind:page-size="queryParam.pagesize" layout="total, sizes, prev, pager, next, jumper" v-bind:total="total"> </el-pagination>
    </el-card>
  </div>
</template>
<script>
export default {
  data() {
    return {
      queryParam: {
        queryJson: '',
        pagenum: 1,
        pagesize: this.pageOption[0]
      },
      total: 0,
      priceChangeList: []
    }
  },
  created() {
    this.getPriceChangeList()
  },
  methods: {
    getPriceChangeList: async function() {
      const { data: res } = await this.$http.get('/PriceChange/List', { params: this.queryParam })
      if (res.status !== 1) {
        return this.$message.error('获取调价列表失败')
      }
      console.log(res.data)
      this.priceChangeList = res.data.list
      this.total = res.data.total
    },
    handleSizeChange: function(newSize) {
      this.queryParam.pagesize = newSize
      this.getPriceChangeList()
    },
    handleCurrentChange: function(newPage) {
      this.queryParam.pagenum = newPage
      this.getPriceChangeList()
    }
  }
}
</script>
