<template>
  <div>
    <el-breadcrumb>
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>加盟管理</el-breadcrumb-item>
      <el-breadcrumb-item>加盟商列表</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <el-row :gutter="20">
        <el-col :span="8">
          <el-input placeholder="请输入内容" v-model="queryParam.queryJson" clearable v-on:clear="getCompanyList">
            <el-button slot="append" icon="el-icon-search" v-on:click="getCompanyList"></el-button>
          </el-input>
        </el-col>
        <el-col :span="6">
          <el-button type="primary" v-on:click="addDialogeVisible = true" v-has="'company:add'">添加</el-button>
        </el-col>
      </el-row>
      <el-table :data="companyList" border stripe>
        <el-table-column type="index" label="#"></el-table-column>
        <el-table-column prop="name" label="名称"> </el-table-column>
        <el-table-column prop="master" label="负责人"> </el-table-column>
        <el-table-column prop="mobile" label="手机号"> </el-table-column>
        <el-table-column prop="company_code" label="企业编码"> </el-table-column>
        <el-table-column label="操作" width="180">
          <template slot-scope="scope">
            <el-button type="primary" icon="el-icon-edit" size="mini" v-on:click="showEditDialoge(scope.row.id)" v-has="'company:edit'"></el-button>
            <el-button type="danger" icon="el-icon-delete" size="mini" v-on:click="deleteRow(scope.row.id)" v-has="'company:remove'"></el-button>
            <el-tooltip effect="dark" content="功能授权" placement="top" v-bind:enterable="false">
              <el-button type="warning" icon="el-icon-setting" size="mini" v-on:click="showMenuDialoge(scope.row)" v-has="'company:allot'"></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" v-bind:current-page="queryParam.pagenum" v-bind:page-sizes="this.pageOption" v-bind:page-size="queryParam.pagesize" layout="total, sizes, prev, pager, next, jumper" v-bind:total="total"> </el-pagination>
    </el-card>
    <!-- 添加对话框 -->
    <el-dialog title="添加加盟商" :visible.sync="addDialogeVisible" width="50%" v-on:closed="addDialogeClosed">
      <el-form :model="addForm" :rules="addFormRules" ref="addFormRef" label-width="70px">
        <el-form-item label="名称" prop="name">
          <el-input v-model="addForm.name"></el-input>
        </el-form-item>
        <el-form-item label="负责人" prop="master">
          <el-input v-model="addForm.master"></el-input>
        </el-form-item>
        <el-form-item label="手机号" prop="mobile">
          <el-input v-model="addForm.mobile"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogeVisible = false">取 消</el-button>
        <el-button type="primary" @click="addRow">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 修改对话框 -->
    <el-dialog title="修改加盟商信息" :visible.sync="editDialogeVisible" width="50%" v-on:closed="editDialogeClosed">
      <el-form :model="editForm" :rules="editFormRules" ref="editFormRef" label-width="70px">
        <el-form-item label="名称" prop="name">
          <el-input v-model="editForm.name"></el-input>
        </el-form-item>
        <el-form-item label="电话" prop="mobile">
          <el-input v-model="editForm.mobile"></el-input>
        </el-form-item>
        <el-form-item label="联系人" prop="master">
          <el-input v-model="editForm.master"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogeClosed">取 消</el-button>
        <el-button type="primary" @click="editSave">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 功能授权对话框 -->
    <el-dialog title="功能授权" :visible.sync="menuDialogeVisible" width="50%" v-on:close="menuDialogeClosed">
      <el-tree :data="menuList" show-checkbox node-key="id" :props="defaultProps" default-expand-all :default-checked-keys="defaultKeys" ref="menuDialogeRef"> </el-tree>
      <span slot="footer" class="dialog-footer">
        <el-button @click="menuDialogeVisible = false">取 消</el-button>
        <el-button type="primary" @click="allotMenu">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data: function() {
    var checkMobile = (rule, value, callback) => {
      const regMobile = /^1([358][0-9]|4[579]|66|7[0135678]|9[89])[0-9]{8}$/
      if (regMobile.test(value)) {
        return callback()
      }
      callback(new Error('请输入正确的手机号'))
    }
    return {
      queryParam: {
        queryJson: '',
        pagenum: 1,
        pagesize: this.pageOption[0]
      },
      companyList: [],
      total: 0,
      editDialogeVisible: false,
      editForm: {},
      editFormRules: {
        name: [
          { required: true, message: '请输入加盟商名称', trigger: 'blur' },
          { min: 3, max: 15, message: '长度在3至15个字符', trigger: 'blur' }
        ],
        master: [
          { required: true, message: '请输入负责人姓名', trigger: 'blur' },
          { min: 2, max: 10, message: '长度在1至10个字符', trigger: 'blur' }
        ],
        mobile: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { validator: checkMobile, trigger: 'blur' }
        ]
      },
      selectedCompany: {},
      menuDialogeVisible: false,
      menuList: [],
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      defaultKeys: [],
      addDialogeVisible: false,
      addForm: {
        name: '',
        master: '',
        mobile: ''
      },
      addFormRules: {
        name: [
          { required: true, message: '请输入加盟商名称', trigger: 'blur' },
          { min: 3, max: 15, message: '长度在3至15个字符', trigger: 'blur' }
        ],
        master: [
          { required: true, message: '请输入负责人姓名', trigger: 'blur' },
          { min: 2, max: 10, message: '长度在1至10个字符', trigger: 'blur' }
        ],
        mobile: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { validator: checkMobile, trigger: 'blur' }
        ]
      }
    }
  },
  created() {
    this.getCompanyList()
  },
  methods: {
    async getCompanyList() {
      const { data: res } = await this.$http.get('/Company/List', { params: this.queryParam })
      if (res.status !== 1) {
        return this.$message.error('获取企业列表失败')
      }
      console.log(res.data)
      this.companyList = res.data.list
      this.total = res.data.total
    },
    handleSizeChange: function(newSize) {
      this.queryParam.pagesize = newSize
      this.getCompanyList()
    },
    handleCurrentChange: function(newPage) {
      this.queryParam.pagenum = newPage
      this.getCompanyList()
    },
    showEditDialoge: async function(id) {
      const { data: res } = await this.$http.get(`/Company/One?id=${id}`)
      if (res.status !== 1) return this.$message.error('获取加盟商信息失败')
      this.editForm = res.data
      this.editDialogeVisible = true
    },
    editDialogeClosed: function() {
      this.$refs.editFormRef.resetFields()
      this.editDialogeVisible = false
    },
    editSave: function() {
      this.$refs.editFormRef.validate(async valid => {
        if (!valid) return
        const { data: res } = await this.$http.put('/Company/Edit?id=' + this.editForm.id, { name: this.editForm.name, master: this.editForm.master, mobile: this.editForm.mobile })
        if (res.status !== 1) {
          return this.$message.error('更新失败')
        }
        this.editDialogeVisible = false
        this.getCompanyList()
        this.$message.success('更新成功')
      })
    },
    deleteRow: async function(id) {
      const confirmResult = await this.$confirm('此操作将永久删除该加盟商, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(err => err)
      if (confirmResult !== 'confirm') {
        return this.$message('已取消删除')
      }
      const { data: res } = await this.$http.delete('/Company/Del?id=' + id)
      if (res.status !== 1) {
        return this.$message.error('删除失败')
      }
      this.getCompanyList()
      this.$message.success('删除成功')
    },
    showMenuDialoge: async function(row) {
      this.selectedCompany = row
      const { data: res } = await this.$http.get('/Company/Menus?type=tree')
      if (res.status !== 1) {
        return this.$message.error('获取功能列表失败')
      }
      console.log(res.data)
      console.log(row)
      this.menuList = res.data
      this.menuDialogeVisible = true
      console.log(this.defaultKeys.length)
      if (row.children.length > 0) this.getLeafKeys(row, this.defaultKeys)
      console.log(this.defaultKeys.length)
    },
    getLeafKeys: function(node, arr) {
      if (node.children.length === 0) {
        return arr.push(node.id)
      }
      node.children.forEach(item => this.getLeafKeys(item, arr))
    },
    allotMenu: async function() {
      var keys = [...this.$refs.menuDialogeRef.getCheckedKeys(), ...this.$refs.menuDialogeRef.getHalfCheckedKeys()]
      console.log(keys)
      var strKeys = keys.join(',')
      const { data: res } = await this.$http.post(`/Company/AllotMenu?id=${this.selectedCompany.id}&rids=${strKeys}`)
      if (res.status !== 1) {
        return this.$message.error('授权失败')
      }
      this.$message.success('授权成功')
      this.getCompanyList()
      this.menuDialogeVisible = false
    },
    menuDialogeClosed: function() {
      this.defaultKeys = []
    },
    addRow: function() {
      this.$refs.addFormRef.validate(async valid => {
        if (!valid) return
        const { data: res } = await this.$http.post('/Company/Add', this.addForm)
        if (res.status !== 1) return this.$message.error('添加失败')
        this.$message.success('添加成功')
        this.addDialogeVisible = false
        this.getCompanyList()
      })
    },
    addDialogeClosed: function() {
      this.$refs.addFormRef.resetFields()
    }
  }
}
</script>
