<template>
  <div>
    <el-breadcrumb>
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>加盟管理</el-breadcrumb-item>
      <el-breadcrumb-item>加盟申请</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <el-row>
        <el-col>
          <el-button type="primary" v-on:click="addDialogeVisible = true" v-has="'apply:add'">添加</el-button>
        </el-col>
      </el-row>
      <el-table :data="applyList" border stripe>
        <el-table-column type="expand">
          <template slot-scope="scope">
            <h3>申请信息：</h3>
            {{ scope.row.detail }}
            <h3>审核说明：</h3>
            {{ scope.row.msg }}
          </template>
        </el-table-column>
        <el-table-column type="index" label="#"></el-table-column>
        <el-table-column prop="name" label="姓名"> </el-table-column>
        <el-table-column prop="mobile" label="手机号"> </el-table-column>
        <el-table-column prop="apply_no" label="编号"> </el-table-column>
        <el-table-column label="状态">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.status === 0">待审核</el-tag>
            <el-tag v-else-if="scope.row.status === 1" type="info">待补充</el-tag>
            <el-tag v-else-if="scope.row.status === 2" type="danger">已撤销</el-tag>
            <el-tag v-else-if="scope.row.status === 3" type="success">审核通过</el-tag>
            <el-tag v-else type="warning">被拒绝</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="msg" label="说明文字"> </el-table-column>
        <el-table-column label="操作" width="300">
          <template slot-scope="scope">
            <el-tooltip effect="dark" content="修改申请" placement="top" v-bind:enterable="false" v-show="scope.row.status === 0 || scope.row.status === 1">
              <el-button type="primary" icon="el-icon-edit" size="mini" v-on:click="showEditDialoge(scope.row.id)" v-has="'apply:edit'"></el-button>
            </el-tooltip>
            <el-tooltip effect="dark" content="撤销申请" placement="top" v-bind:enterable="false" v-show="scope.row.status === 0 || scope.row.status === 1">
              <el-button type="warning" icon="el-icon-delete" size="mini" v-on:click="revoke(scope.row.id)" v-has="'apply:revoke'"></el-button>
            </el-tooltip>
            <el-tooltip effect="dark" content="补充信息" placement="top" v-bind:enterable="false" v-show="scope.row.status === 0 || scope.row.status === 1">
              <el-button type="info" icon="el-icon-refresh-left" size="mini" v-on:click="showModifyDialoge(scope.row.id)" v-has="'apply:addinfo'"></el-button>
            </el-tooltip>
            <el-tooltip effect="dark" content="批准" placement="top" v-bind:enterable="false" v-show="scope.row.status === 0">
              <el-button type="success" icon="el-icon-check" size="mini" v-on:click="approve(scope.row.id)" v-has="'apply:approve'"></el-button>
            </el-tooltip>
            <el-tooltip effect="dark" content="拒绝" placement="top" v-bind:enterable="false" v-show="scope.row.status === 0">
              <el-button type="danger" icon="el-icon-close" size="mini" v-on:click="showRejectDialoge(scope.row.id)" v-has="'apply:reject'"></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <!-- 添加对话框 -->
    <el-dialog title="添加申请" :visible.sync="addDialogeVisible" width="50%" v-on:closed="addDialogeClosed">
      <el-form :model="addForm" :rules="addFormRules" ref="addFormRef" label-width="80px">
        <el-form-item label="姓名" prop="name">
          <el-input v-model="addForm.name"></el-input>
        </el-form-item>
        <el-form-item label="手机号" prop="mobile">
          <el-input v-model="addForm.mobile"></el-input>
        </el-form-item>
        <el-form-item label="申请理由" prop="detail">
          <el-input type="textarea" v-model="addForm.detail"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogeVisible = false">取 消</el-button>
        <el-button type="primary" @click="addRow">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 修改对话框 -->
    <el-dialog title="修改申请" :visible.sync="editDialogeVisible" width="50%" v-on:closed="editDialogeClosed">
      <el-form :model="editForm" :rules="editFormRules" ref="editFormRef" label-width="80px">
        <el-form-item label="姓名" prop="name">
          <el-input v-model="editForm.name"></el-input>
        </el-form-item>
        <el-form-item label="手机号" prop="mobile">
          <el-input v-model="editForm.mobile"></el-input>
        </el-form-item>
        <el-form-item label="申请理由" prop="detail">
          <el-input type="textarea" v-model="editForm.detail"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogeClosed">取 消</el-button>
        <el-button type="primary" @click="editSave">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 补充信息对话框 -->
    <el-dialog title="补充申请" :visible.sync="modifyDialogeVisible" width="50%" v-on:closed="modifyDialogeClosed">
      <el-form :model="modifyForm" :rules="modifyFormRules" ref="modifyFormRef" label-width="80px">
        <el-form-item label="说明" prop="msg">
          <el-input type="textarea" v-model="modifyForm.msg"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="modifyDialogeClosed">取 消</el-button>
        <el-button type="primary" @click="modifySave">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 拒绝对话框 -->
    <el-dialog title="拒绝申请" :visible.sync="rejectDialogeVisible" width="50%" v-on:closed="rejectDialogeClosed">
      <el-form :model="rejectForm" :rules="rejectFormRules" ref="rejectFormRef" label-width="80px">
        <el-form-item label="说明" prop="msg">
          <el-input type="textarea" v-model="rejectForm.msg"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="rejectDialogeClosed">取 消</el-button>
        <el-button type="primary" @click="reject">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    var checkMobile = (rule, value, callback) => {
      const regMobile = /^1([358][0-9]|4[579]|66|7[0135678]|9[89])[0-9]{8}$/
      if (regMobile.test(value)) {
        return callback()
      }
      callback(new Error('请输入正确的手机号'))
    }
    return {
      applyList: [],
      addDialogeVisible: false,
      addForm: {
        name: '',
        mobile: '',
        detail: ''
      },
      addFormRules: {
        name: [
          { required: true, message: '请输入姓名', trigger: 'blur' },
          { min: 2, max: 5, message: '长度在2至5个字符', trigger: 'blur' }
        ],
        mobile: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { validator: checkMobile, trigger: 'blur' }
        ],
        detail: [
          { required: true, message: '请输入申请理由', trigger: 'blur' },
          { min: 20, max: 200, message: '长度在20至200个字符', trigger: 'blur' }
        ]
      },
      editDialogeVisible: false,
      editForm: {},
      editFormRules: {
        name: [
          { required: true, message: '请输入姓名', trigger: 'blur' },
          { min: 2, max: 5, message: '长度在2至5个字符', trigger: 'blur' }
        ],
        mobile: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { validator: checkMobile, trigger: 'blur' }
        ],
        detail: [
          { required: true, message: '请输入申请理由', trigger: 'blur' },
          { min: 20, max: 200, message: '长度在20至200个字符', trigger: 'blur' }
        ]
      },
      modifyDialogeVisible: false,
      modifyForm: {
        id: 0,
        msg: ''
      },
      modifyFormRules: {
        msg: [
          { required: true, message: '请输入说明文字', trigger: 'blur' },
          { min: 5, max: 30, message: '长度在5至30个字符', trigger: 'blur' }
        ]
      },
      rejectDialogeVisible: false,
      rejectForm: {
        id: 0,
        msg: ''
      },
      rejectFormRules: {
        msg: [
          { required: true, message: '请输入说明文字', trigger: 'blur' },
          { min: 5, max: 30, message: '长度在5至30个字符', trigger: 'blur' }
        ]
      }
    }
  },
  created() {
    this.getApplyList()
  },
  methods: {
    async getApplyList() {
      const { data: res } = await this.$http.get('/Apply/List')
      if (res.status !== 1) {
        return this.$message.error('获取列表失败')
      }
      console.log(res.data)
      this.applyList = res.data
    },
    showEditDialoge: async function(id) {
      const { data: res } = await this.$http.get(`/Apply/One?id=${id}`)
      if (res.status !== 1) return this.$message.error('获取信息失败')
      this.editForm = res.data
      this.editDialogeVisible = true
    },
    showModifyDialoge: function(id) {
      this.modifyForm.id = id
      this.modifyDialogeVisible = true
    },
    showRejectDialoge: function(id) {
      this.rejectForm.id = id
      this.rejectDialogeVisible = true
    },
    addDialogeClosed: function() {
      this.$refs.addFormRef.resetFields()
    },
    addRow: function() {
      this.$refs.addFormRef.validate(async valid => {
        if (!valid) return
        const { data: res } = await this.$http.post('/Apply/Add', this.addForm)
        if (res.status !== 1) return this.$message.error('添加失败')
        this.$message.success('添加成功')
        this.addDialogeVisible = false
        this.getApplyList()
      })
    },
    editDialogeClosed: function() {
      this.$refs.editFormRef.resetFields()
      this.editDialogeVisible = false
    },
    editSave: function() {
      this.$refs.editFormRef.validate(async valid => {
        if (!valid) return
        const { data: res } = await this.$http.put('/Apply/Edit?id=' + this.editForm.id, { name: this.editForm.name, mobile: this.editForm.mobile, detail: this.editForm.detail })
        if (res.status !== 1) {
          return this.$message.error('更新失败')
        }
        this.editDialogeVisible = false
        this.getApplyList()
        this.$message.success('更新成功')
      })
    },
    revoke: async function(id) {
      const confirmResult = await this.$confirm('确定要撤销此申请吗, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(err => err)
      if (confirmResult !== 'confirm') {
        return this.$message('已取消删除')
      }
      const { data: res } = await this.$http.put('/Apply/Revoke?id=' + id)
      if (res.status !== 1) {
        return this.$message.error('更新失败')
      }
      this.getApplyList()
      this.$message.success('更新成功')
    },
    modifyDialogeClosed: function() {
      this.$refs.modifyFormRef.resetFields()
      this.modifyDialogeVisible = false
    },
    modifySave: function() {
      this.$refs.modifyFormRef.validate(async valid => {
        if (!valid) return
        const { data: res } = await this.$http.put('/Apply/Modify?id=' + this.modifyForm.id, { msg: this.modifyForm.msg })
        if (res.status !== 1) {
          return this.$message.error('更新失败')
        }
        this.modifyDialogeVisible = false
        this.getApplyList()
        this.$message.success('更新成功')
      })
    },
    approve: async function(id) {
      const { data: res } = await this.$http.put('/Apply/Approve?id=' + id)
      if (res.status !== 1) {
        return this.$message.error('更新失败')
      }
      this.getApplyList()
      this.$message.success('更新成功')
    },
    rejectDialogeClosed: function() {
      this.$refs.rejectFormRef.resetFields()
      this.rejectDialogeVisible = false
    },
    reject: function() {
      this.$refs.rejectFormRef.validate(async valid => {
        if (!valid) return
        const { data: res } = await this.$http.put('/Apply/Reject?id=' + this.rejectForm.id, { msg: this.rejectForm.msg })
        if (res.status !== 1) {
          return this.$message.error('更新失败')
        }
        this.rejectDialogeVisible = false
        this.getApplyList()
        this.$message.success('更新成功')
      })
    }
  }
}
</script>
