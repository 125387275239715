<template>
  <div>
    <el-breadcrumb>
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>权限管理</el-breadcrumb-item>
      <el-breadcrumb-item>角色列表</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <el-row>
        <el-col>
          <el-button type="primary" v-on:click="addDialogeVisible = true" v-has="'roles:add'" size="small">添加角色</el-button>
        </el-col>
      </el-row>
      <el-table :data="roleList" stripe border>
        <el-table-column type="expand">
          <template slot-scope="scope">
            <el-row v-bind:class="[i1 === 0 ? 'bd_top' : '', 'bd_bottom', 'vcenter']" :key="item1.id" v-for="(item1, i1) in scope.row.children">
              <!-- 渲染一级权限 -->
              <el-col :span="5">
                <el-tag :closable="scope.row.type === 3" v-on:close="removeRightsById(scope.row, item1.id)">{{ item1.name }}</el-tag>
                <i class="el-icon-caret-right"></i>
              </el-col>
              <!-- 渲染二级和三级权限 -->
              <el-col :span="19">
                <el-row v-bind:class="[i2 === 0 ? '' : 'bd_top', 'vcenter']" v-for="(item2, i2) in item1.children" :key="item2.id">
                  <!-- 渲染二级菜单 -->
                  <el-col :span="6">
                    <el-tag type="success" :closable="scope.row.type === 3" v-on:close="removeRightsById(scope.row, item2.id)">{{ item2.name }}</el-tag>
                    <i class="el-icon-caret-right"></i>
                  </el-col>
                  <!-- 渲染三级菜单 -->
                  <el-col :span="18">
                    <el-tag v-for="item3 in item2.children" :key="item3.id" type="warning" :closable="scope.row.type === 3" v-on:close="removeRightsById(scope.row, item3.id)">{{ item3.name }}</el-tag>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
            <!-- <pre>
                {{ scope.row }}
            </pre> -->
          </template>
        </el-table-column>
        <el-table-column type="index"></el-table-column>
        <el-table-column prop="name" label="权限名称"></el-table-column>
        <el-table-column prop="desc" label="权限描述"></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope" v-if="scope.row.type === 3">
            <el-button type="primary" icon="el-icon-edit" size="mini" v-on:click="showEditDialoge(scope.row.id)" v-has="'roles:edit'">编辑</el-button>
            <el-button type="danger" icon="el-icon-delete" size="mini" v-on:click="deleteRole(scope.row.id)" v-has="'roles:remove'">删除</el-button>
            <el-button type="warning" icon="el-icon-setting" size="mini" v-on:click="showSetRightsDialoge(scope.row)" v-has="'roles:allot'">分配权限</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <!-- 添加角色对话框 -->
    <el-dialog title="添加角色" :visible.sync="addDialogeVisible" width="50%" v-on:closed="addDialogeClosed">
      <el-form :model="addRoleForm" :rules="addRoleFormRules" ref="addRoleFormRef" label-width="100px">
        <el-form-item label="角色名称" prop="name">
          <el-input v-model="addRoleForm.name"></el-input>
        </el-form-item>
        <el-form-item label="角色描述" prop="desc">
          <el-input v-model="addRoleForm.desc"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogeVisible = false">取 消</el-button>
        <el-button type="primary" @click="addRole">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 分配权限对话框 -->
    <el-dialog title="分配权限" :visible.sync="showSetRightsDialogeVisible" width="50%" v-on:close="onSetRightDialogeClosed">
      <el-tree :data="rightList" show-checkbox node-key="id" :props="defaultProps" default-expand-all :default-checked-keys="defaultKeys" ref="SetRightsDialogeRef"> </el-tree>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showSetRightsDialogeVisible = false">取 消</el-button>
        <el-button type="primary" @click="allotRights">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 修改角色对话框 -->
    <el-dialog title="修改角色" :visible.sync="editDialogeVisible" width="50%" v-on:closed="editDialogeClosed">
      <el-form :model="editRoleForm" :rules="editRoleFormRules" ref="editRoleFormRef" label-width="80px">
        <el-form-item label="角色名称">
          <el-input v-model="editRoleForm.name"></el-input>
        </el-form-item>
        <el-form-item label="角色描述" prop="desc">
          <el-input v-model="editRoleForm.desc"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogeClosed">取 消</el-button>
        <el-button type="primary" @click="editRole">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  created() {
    this.getRoleList()
  },
  data() {
    return {
      roleList: [],
      addDialogeVisible: false,
      addRoleForm: {
        name: '',
        desc: ''
      },
      addRoleFormRules: {
        name: [{ required: true, message: '请输入角色名称', trigger: 'blur' }],
        desc: [{ required: true, message: '请输入角色描述', trigger: 'blur' }]
      },
      showSetRightsDialogeVisible: false,
      rightList: [],
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      defaultKeys: [],
      currentRoleId: 0,
      editDialogeVisible: false,
      editRoleForm: {},
      editRoleFormRules: {
        name: [{ required: true, message: '请输入角色名称', trigger: 'blur' }],
        desc: [{ required: true, message: '请输入角色描述', trigger: 'blur' }]
      }
    }
  },
  methods: {
    getRoleList: async function() {
      const { data: res } = await this.$http.get('/Account/Roles')
      if (res.status !== 1) {
        return this.$message.error('获取角色列表失败')
      }
      this.roleList = res.data
      console.log(res.data)
    },
    addDialogeClosed: function() {
      this.$refs.addRoleFormRef.resetFields()
    },
    addRole: async function() {
      const { data: res } = await this.$http.post('/Account/Roles', { name: this.addRoleForm.name, desc: this.addRoleForm.desc })
      if (res.status !== 1) {
        return this.$message.error('添加角色失败')
      }
      this.$message.success('添加角色成功')
      this.addDialogeVisible = false
      this.getRoleList()
    },
    removeRightsById: async function(role, rightId) {
      const confirmMessage = await this.$confirm('此操作将永久删除该角色的权限, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(err => err)
      if (confirmMessage !== 'confirm') {
        return this.$message('已取消删除')
      }
      const { data: res } = await this.$http.delete(`/Account/Roles?role_id=${role.id}&right_id=${rightId}`)
      if (res.status !== 1) {
        return this.$message('删除权限失败')
      }
      // 这样会折叠
      // this.roleList = this.getRoleList()
      role.children = res.data
      console.log(res.data)
      this.$message.success('删除权限成功')
    },
    showSetRightsDialoge: async function(role) {
      console.log(role)
      this.currentRoleId = role.id
      const { data: res } = await this.$http.get('/Company/Menus?type=tree')
      if (res.status !== 1) {
        return this.$message.error('获取权限列表失败')
      }
      this.rightList = res.data
      console.log(this.rightList)
      if (role.children.length > 0) this.getLeafKeys(role, this.defaultKeys)
      console.log(this.defaultKeys)
      this.showSetRightsDialogeVisible = true
    },
    // 递归获取三级节点id
    getLeafKeys: function(node, arr) {
      if (node.children.length === 0) {
        return arr.push(node.id)
      }
      node.children.forEach(item => this.getLeafKeys(item, arr))
    },
    onSetRightDialogeClosed: function() {
      this.defaultKeys = []
    },
    allotRights: async function() {
      // 拿到选中和半选中的id值
      var keys = [...this.$refs.SetRightsDialogeRef.getCheckedKeys(), ...this.$refs.SetRightsDialogeRef.getHalfCheckedKeys()]
      console.log(keys)
      var strKeys = keys.join(',')
      const { data: res } = await this.$http.post(`/Account/Rights?role_id=${this.currentRoleId}&rids=${strKeys}`)
      if (res.status !== 1) {
        return this.$message.error('更新权限失败:' + res.msg)
      }
      this.$message.success('更新权限成功')
      this.getRoleList()
      this.showSetRightsDialogeVisible = false
    },
    showEditDialoge: async function(id) {
      const { data: res } = await this.$http.get(`/Account/Roles?role_id=${id}`)
      if (res.status !== 1) return this.$message.error('获取角色信息失败')
      this.editRoleForm = res.data
      this.editDialogeVisible = true
    },
    editRole: function() {
      this.$refs.editRoleFormRef.validate(async valid => {
        if (!valid) return
        const { data: res } = await this.$http.put('/Account/RolesModify?role_id=' + this.editRoleForm.id, { name: this.editRoleForm.name, desc: this.editRoleForm.desc })
        if (res.status !== 1) {
          return this.$message.error('更新角色信息失败')
        }
        // 关闭对话框
        this.editDialogeVisible = false
        this.getRoleList()
        // 提示用户
        this.$message.success('更新角色信息成功')
      })
    },
    editDialogeClosed: function() {
      this.$refs.editRoleFormRef.resetFields()
      this.editDialogeVisible = false
    },
    deleteRole: async function(id) {
      const confirmResult = await this.$confirm('此操作将永久删除该角色, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(err => err)
      console.log(confirmResult)
      // 返回promise 可通过async 和await简化操作
      // 点击确定时 res得到字符串confirm
      // 点击取消时 会报错，通过catch接收，res得到的错误信息是字符串cancel
      if (confirmResult !== 'confirm') {
        return this.$message('已取消删除')
      }
      const { data: res } = await this.$http.delete('/Account/RolesDel?id=' + id)
      if (res.status !== 1) {
        return this.$message.error('删除角色失败')
      }
      this.getRoleList()
      this.$message.success('删除角色成功')
    }
  }
}
</script>
<style lang="less" scoped>
.el-tag {
  margin: 7px;
}
.bd_top {
  border-top: 1px solid #eee;
}
.bd_bottom {
  border-bottom: 1px solid #eee;
}
.vcenter {
  display: flex;
  align-items: center;
}
</style>
