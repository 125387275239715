<template>
  <div>
    <el-breadcrumb>
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>运营数据</el-breadcrumb-item>
      <el-breadcrumb-item>提现记录</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <el-alert title="金额为扣除微信收款手续费后的金额" type="warning" show-icon :closable="false" class="tip"> </el-alert>
      <el-form :inline="true" :model="queryParam">
        <el-form-item label="网点">
          <el-select v-model="queryParam.station_id" placeholder="请选择网点" clearable v-on:clear="getCashList" size="small">
            <el-option v-for="item in stationList" :key="item.id" :label="item.name" :value="item.id"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="时间">
          <el-date-picker v-model="queryParam.daterange" type="daterange" align="center" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptions" value-format="yyyy-MM-dd" size="small"> </el-date-picker>
        </el-form-item>
        <el-form-item label="合伙人">
          <el-input placeholder="请输入合伙人姓名" v-model="queryParam.user_name" clearable v-on:clear="getCashList" size="small" />
        </el-form-item>
        <el-form-item label="状态">
          <el-radio-group v-model="queryParam.status" size="mini">
            <el-radio-button :label="0">待审核</el-radio-button>
            <el-radio-button :label="1">审核通过</el-radio-button>
            <el-radio-button :label="2">审核不通过</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item style="margin-left: 15px">
          <el-button type="primary" icon="el-icon-search" @click="getCashList" size="small">搜索</el-button>
          <el-button icon="el-icon-refresh-right" @click="reset" size="small">重置</el-button>
        </el-form-item>
      </el-form>
      <el-table :data="cashList" border stripe>
        <el-table-column type="index" label="#"></el-table-column>
        <el-table-column prop="station_name" label="网点"> </el-table-column>
        <el-table-column prop="name" label="合伙人"> </el-table-column>
        <el-table-column prop="add_time" label="时间" width="150">
          <template slot-scope="scope">
            {{ scope.row.add_time | dateformat }}
          </template>
        </el-table-column>
        <el-table-column prop="amount" label="提现金额（元）"> </el-table-column>
        <el-table-column prop="result" label="状态">
          <template slot-scope="scope">
            <el-tag size="small" v-if="scope.row.result === 0" effect="plain">待审核</el-tag>
            <el-tag size="small" v-else-if="scope.row.result === 1" type="success" effect="plain">审核通过</el-tag>
            <el-tag size="small" v-else type="warning" effect="plain">审核不通过</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="msg" label="说明文字"> </el-table-column>
        <el-table-column label="操作" width="300">
          <template slot-scope="scope">
            <el-button type="text" v-on:click="onDetailClick(scope.row.id)">查看明细</el-button>
            <el-button type="primary" icon="el-icon-check" size="mini" v-on:click="handleApprove(scope.row.id)" v-if="scope.row.show_flag">通过</el-button>
            <el-button type="danger" icon="el-icon-close" size="mini" v-on:click="showRejectDialoge(scope.row.id)" v-if="scope.row.show_flag">不通过</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination :hide-on-single-page="true" @size-change="handleSizeChange" @current-change="handleCurrentChange" v-bind:current-page="queryParam.pagenum" v-bind:page-sizes="this.pageOption" v-bind:page-size="queryParam.pagesize" layout="total, sizes, prev, pager, next, jumper" v-bind:total="total"> </el-pagination>
      <el-dialog title="拒绝申请" :visible.sync="rejectDialogeVisible" width="50%" v-on:closed="rejectDialogeClosed">
        <el-form :model="rejectForm" :rules="rejectFormRules" ref="rejectFormRef" label-width="80px">
          <el-form-item label="说明" prop="msg">
            <el-input type="textarea" v-model="rejectForm.msg"></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="rejectDialogeClosed">取 消</el-button>
          <el-button type="primary" @click="reject">确 定</el-button>
        </span>
      </el-dialog>
      <!-- <div class="station_section">
        <div class="station_box" v-for="item in stations" :key="item.id">
          <div class="left">
            <el-image src="https://img0.baidu.com/it/u=659963298,499112448&fm=26&fmt=auto&gp=0.jpg" fit="contain"></el-image>
          </div>
          <div class="right">
            <div class="right1">
              <ul>
                {{
                  item.name
                }}
                <li>合伙人数量：{{ item.partner_num }}</li>
                <li>累计销售金额：{{ item.partner_num }}</li>
                <li>累计销售笔数：{{ item.total_sale_num }}</li>
                <li>累计分账金额：{{ item.total_transaction_amount }}</li>
                <li>累计分账笔数：{{ item.total_transaction_num }}</li>
              </ul>
            </div>
            <div class="right2">
              <i class="el-icon-d-arrow-right"></i>
            </div>
          </div>
        </div>
      </div> -->
    </el-card>
  </div>
</template>
<script>
export default {
  data() {
    return {
      // stations: [
      //   {
      //     id: 1,
      //     name: '网点1',
      //     partner_num: 3,
      //     total_sale_amount: 21381.5,
      //     total_sale_num: 12378,
      //     total_transaction_amount: 1231,
      //     total_transaction_num: 213
      //   },
      //   {
      //     id: 2,
      //     name: '网点2',
      //     partner_num: 3,
      //     total_sale_amount: 21381.5,
      //     total_sale_num: 12378,
      //     total_transaction_amount: 1231,
      //     total_transaction_num: 213
      //   },
      //   {
      //     id: 3,
      //     name: '网点3',
      //     partner_num: 3,
      //     total_sale_amount: 21381.5,
      //     total_sale_num: 12378,
      //     total_transaction_amount: 1231,
      //     total_transaction_num: 213
      //   },
      //   {
      //     id: 4,
      //     name: '网点4',
      //     partner_num: 3,
      //     total_sale_amount: 21381.5,
      //     total_sale_num: 12378,
      //     total_transaction_amount: 1231,
      //     total_transaction_num: 213
      //   },
      //   {
      //     id: 5,
      //     name: '网点5',
      //     partner_num: 3,
      //     total_sale_amount: 21381.5,
      //     total_sale_num: 12378,
      //     total_transaction_amount: 1231,
      //     total_transaction_num: 213
      //   }
      // ],
      queryParam: {
        station_id: '',
        user_name: '',
        daterange: [],
        status: '',
        pagenum: 1,
        pagesize: this.pageOption[0]
      },
      cashList: [],
      total: 0,
      stationList: [],
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            }
          }
        ],
        disabledDate: time => {
          return time > new Date()
        }
        // onPick: ({ maxDate, minDate }) => {
        //   console.log(this.queryParam.daterange)
        //   console.log(maxDate)
        //   console.log(minDate)
        //   this.maxDate = maxDate
        //   this.minDate = minDate
        // }
      },
      rejectDialogeVisible: false,
      rejectForm: {
        id: 0,
        msg: ''
      },
      rejectFormRules: {
        msg: [
          { required: true, message: '请输入说明文字', trigger: 'blur' },
          { min: 5, max: 30, message: '长度在5至30个字符', trigger: 'blur' }
        ]
      },
      transactionList: []
    }
  },
  created() {
    var beginDate = this.formatDate(new Date().getTime() - 3600 * 1000 * 24 * 30)
    var endDate = this.formatDate(new Date().getTime())
    this.queryParam.daterange.push(beginDate)
    this.queryParam.daterange.push(endDate)
    this.getStationList()
    this.getCashList()
  },
  methods: {
    getCashList: async function() {
      const { data: res } = await this.$http.get('/Cash/List', { params: this.queryParam })
      if (res.status !== 1) {
        return this.$message.error('获取提现列表失败')
      }
      console.log(res.data)
      this.cashList = res.data.list
      this.total = res.data.total
    },
    handleSizeChange: function(newSize) {
      this.queryParam.pagesize = newSize
      this.getCashList()
    },
    handleCurrentChange: function(newPage) {
      this.queryParam.pagenum = newPage
      this.getCashList()
    },
    getStationList: async function() {
      const { data: res } = await this.$http.get('/PartnerPerson/Station')
      if (res.status !== 1) return this.$message.error('获取网点信息失败')
      this.stationList = res.data
    },
    reset: function() {
      this.queryParam.station_id = ''
      this.queryParam.user_name = ''
      this.queryParam.status = ''
      this.queryParam.daterange = []
    },
    formatDate: function(val) {
      const dt = new Date(val)
      const y = dt.getFullYear().toString()
      const m = (dt.getMonth() + 1).toString().padStart(2, '0')
      const d = dt
        .getDate()
        .toString()
        .padStart(2, '0')
      return `${y}-${m}-${d}`
    },
    handleApprove: async function(id) {
      const { data: res } = await this.$http.put('/Cash/Approve?id=' + id)
      if (res.status !== 1) {
        return this.$message.error('操作失败')
      }
      this.$message.success('操作成功')
      this.getCashList()
    },
    showRejectDialoge: function(id) {
      this.rejectForm.id = id
      this.rejectDialogeVisible = true
    },
    rejectDialogeClosed: function() {
      this.$refs.rejectFormRef.resetFields()
      this.rejectDialogeVisible = false
    },
    reject: function() {
      this.$refs.rejectFormRef.validate(async valid => {
        if (!valid) return
        const { data: res } = await this.$http.put('/Cash/Reject?id=' + this.rejectForm.id, { msg: this.rejectForm.msg })
        if (res.status !== 1) {
          return this.$message.error('操作失败')
        }
        this.rejectDialogeVisible = false
        this.getCashList()
        this.$message.success('操作成功')
      })
    },
    onDetailClick: function(id) {
      this.$router.push(`/transaction/${id}`)
    }
  }
}
</script>
<style lang="less" scoped>
.tip {
  margin-bottom: 15px;
}
// .station_section {
//   display: none;
//   flex-wrap: wrap;
//   .station_box {
//     display: flex;
//     width: 45%;
//     background-color: #ddd;
//     margin: 15px 15px 0 0;
//     border: 1px solid #ddd;
//     .left {
//       flex: 1;
//       overflow: hidden;
//       background-color: #fff;
//       display: flex;
//       img {
//         width: 100%;
//       }
//     }
//     .right {
//       flex: 2;
//       display: flex;
//       .right1 {
//         flex: 5;
//         ul {
//           list-style: none;
//           color: #fff;
//           font-weight: 600;
//         }
//       }
//       .right2 {
//         flex: 2;
//         display: flex;
//         align-items: center;
//         justify-content: center;
//         font-size: 1.5rem;
//         background-color: #fff;
//         i {
//           background-color: #ddd;
//           padding: 15px;
//           border-radius: 50%;
//           color: #fff;
//         }
//       }
//     }
//   }
// }
</style>
