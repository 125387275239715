<template>
  <div>
    <el-breadcrumb>
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>运营数据</el-breadcrumb-item>
      <el-breadcrumb-item>入库记录</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <el-row :gutter="20">
        <el-col :span="8">
          <el-input placeholder="请输入内容" v-model="queryParam.queryJson" clearable v-on:clear="getAddOilList" size="small">
            <el-button slot="append" icon="el-icon-search" v-on:click="getAddOilList" size="small"></el-button>
          </el-input>
        </el-col>
        <el-col :span="6">
          <el-button type="primary" v-on:click="showAddDialoge" v-has="'addoil:add'" size="small">添加</el-button>
        </el-col>
      </el-row>
      <el-table :data="addOilList" border stripe>
        <el-table-column type="index" label="#"></el-table-column>
        <!-- <el-table-column prop="company_name" label="企业"> </el-table-column> -->
        <el-table-column prop="station_name" label="网点"> </el-table-column>
        <el-table-column prop="oil_name" label="品类名称"> </el-table-column>
        <el-table-column prop="add_qty" label="入库数量"> </el-table-column>
        <el-table-column prop="before_qty" label="入库前数量"> </el-table-column>
        <el-table-column prop="after_qty" label="入库后数量"> </el-table-column>
        <el-table-column prop="add_time" label="入库时间">
          <template slot-scope="scope">
            {{ scope.row.add_time | dateformat }}
          </template>
        </el-table-column>
        <el-table-column prop="supplier_name" label="供应商"> </el-table-column>
        <el-table-column prop="supplier_mobile" label="供应商电话"> </el-table-column>
      </el-table>
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" v-bind:current-page="queryParam.pagenum" v-bind:page-sizes="this.pageOption" v-bind:page-size="queryParam.pagesize" layout="total, sizes, prev, pager, next, jumper" v-bind:total="total"> </el-pagination>
    </el-card>
    <!-- 添加对话框 -->
    <el-dialog title="尿素入库" :visible.sync="addDialogeVisible" width="50%" v-on:closed="addDialogeClosed">
      <el-form :model="addForm" :rules="addFormRules" ref="addFormRef" label-width="100px">
        <el-form-item label="网点" prop="station_id">
          <el-select v-model="addForm.station_id" placeholder="请选择网点" style="width:100%">
            <el-option v-for="item in stationList" :key="item.id" :label="item.name" :value="item.id"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="入库升数" prop="add_qty">
          <el-input v-model="addForm.add_qty"></el-input>
        </el-form-item>
        <el-form-item label="入库前升数" prop="before_qty">
          <el-input v-model="addForm.before_qty"></el-input>
        </el-form-item>
        <el-form-item label="入库后升数" prop="after_qty">
          <el-input v-model="addForm.after_qty"></el-input>
        </el-form-item>
        <el-form-item label="供应商" prop="supplier_name">
          <el-input v-model="addForm.supplier_name"></el-input>
        </el-form-item>
        <el-form-item label="供应商电话" prop="supplier_mobile">
          <el-input v-model="addForm.supplier_mobile"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogeVisible = false">取 消</el-button>
        <el-button type="primary" @click="addRow">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    var checkMobile = (rule, value, callback) => {
      const regMobile = /^1([358][0-9]|4[579]|66|7[0135678]|9[89])[0-9]{8}$/
      if (regMobile.test(value)) {
        return callback()
      }
      callback(new Error('请输入正确的手机号'))
    }
    return {
      queryParam: {
        queryJson: '',
        pagenum: 1,
        pagesize: this.pageOption[0]
      },
      total: 0,
      addOilList: [],
      addDialogeVisible: false,
      addForm: {
        tank_no: '',
        add_qty: '',
        before_qty: '',
        after_qty: '',
        supplier_name: '',
        supplier_mobile: '',
        station_id: ''
      },
      addFormRules: {
        station_id: [{ required: true, message: '请选择网点', trigger: 'blur' }],
        add_qty: [{ required: true, message: '请输入入库升数', trigger: 'blur' }],
        before_qty: [{ required: true, message: '请输入入库前升数', trigger: 'blur' }],
        after_qty: [{ required: true, message: '请输入入库后升数', trigger: 'blur' }],
        supplier_name: [{ required: true, message: '请输入供应商名称', trigger: 'blur' }],
        supplier_mobile: [
          { required: true, message: '请输入供应商电话', trigger: 'blur' },
          { validator: checkMobile, trigger: 'blur' }
        ]
      },
      stationList: []
    }
  },
  created() {
    this.getAddOilList()
  },
  methods: {
    getAddOilList: async function() {
      const { data: res } = await this.$http.get('/AddOil/List', { params: this.queryParam })
      if (res.status !== 1) {
        return this.$message.error('获取入库列表失败')
      }
      console.log(res.data)
      this.addOilList = res.data.list
      this.total = res.data.total
    },
    handleSizeChange: function(newSize) {
      this.queryParam.pagesize = newSize
      this.getAddOilList()
    },
    handleCurrentChange: function(newPage) {
      this.queryParam.pagenum = newPage
      this.getAddOilList()
    },
    showAddDialoge: function() {
      this.getStationList()
      this.addDialogeVisible = true
    },
    addRow: function() {
      this.$refs.addFormRef.validate(async valid => {
        if (!valid) return
        const { data: res } = await this.$http.post('/AddOil/Add', this.addForm)
        if (res.status !== 1) return this.$message.error('添加失败,' + res.msg)
        this.$message.success('添加成功')
        this.getAddOilList()
      })
    },
    addDialogeClosed: function() {
      this.$refs.addFormRef.resetFields()
    },
    getStationList: async function() {
      const { data: res } = await this.$http.get('/Partner/Station')
      if (res.status !== 1) return this.$message.error('获取网点信息失败')
      this.stationList = res.data
    }
  }
}
</script>
