<template>
  <div>
    <el-breadcrumb>
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>系统设置</el-breadcrumb-item>
      <el-breadcrumb-item>网点管理</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <el-row :gutter="20">
        <el-col :span="8">
          <el-input placeholder="请输入内容" v-model="queryParam.queryJson" clearable v-on:clear="getStationList" size="small">
            <el-button slot="append" icon="el-icon-search" v-on:click="getStationList" size="small"></el-button>
          </el-input>
        </el-col>
        <el-col :span="6">
          <el-button type="primary" v-on:click="addDialogeVisible = true" v-has="'stations:add'" size="small">添加</el-button>
        </el-col>
      </el-row>
      <el-table :data="stationList" border stripe>
        <el-table-column type="expand">
          <template slot-scope="scope">
            <el-form label-position="left" inline class="table-expand">
              <el-form-item label="经度">
                <span>{{ scope.row.lon }}</span>
              </el-form-item>
              <el-form-item label="纬度">
                <span>{{ scope.row.lat }}</span>
              </el-form-item>
              <el-form-item label="加注机品牌">
                <span>{{ scope.row.brand }}</span>
              </el-form-item>
              <el-form-item label="加注机型号">
                <span>{{ scope.row.serial }}</span>
              </el-form-item>
              <el-form-item label="计量方式">
                <span>{{ scope.row.meter === 0 ? '升' : '公斤' }}</span>
              </el-form-item>
              <el-form-item label="地址">
                <span>{{ scope.row.address }}</span>
              </el-form-item>
            </el-form>
          </template>
        </el-table-column>
        <el-table-column type="index" label="#"></el-table-column>
        <!-- <el-table-column prop="company_name" label="所属企业"> </el-table-column> -->
        <el-table-column prop="station_no" label="网点编号"> </el-table-column>
        <el-table-column prop="station_name" label="网点名称" width="120px"> </el-table-column>
        <el-table-column prop="brief_info" label="简称"> </el-table-column>
        <el-table-column prop="master" label="负责人"> </el-table-column>
        <!-- <el-table-column prop="address" label="地址" width="200px"> </el-table-column> -->
        <el-table-column prop="tel" label="电话" width="100px"> </el-table-column>
        <el-table-column prop="oil_name" label="品类名称"> </el-table-column>
        <el-table-column prop="price" label="单价"> </el-table-column>
        <el-table-column prop="cost" label="成本"> </el-table-column>
        <!-- <el-table-column prop="lon" label="经度"> </el-table-column>
        <el-table-column prop="lat" label="纬度"> </el-table-column>
        <el-table-column prop="brand" label="加注机品牌"> </el-table-column>
        <el-table-column prop="serial" label="加注机型号"> </el-table-column> -->
        <el-table-column prop="dts_device_no" label="传输标识"> </el-table-column>
        <el-table-column prop="pay_test_station" label="支付测试">
          <template slot-scope="scope">
            <i-switch size="large" v-model="scope.row.pay_test_station" v-on:on-change="changeState(scope.row)">
              <span slot="open">开启</span>
              <span slot="close">关闭</span>
            </i-switch>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="300">
          <template slot-scope="scope">
            <el-button type="text" icon="el-icon-edit" size="mini" v-on:click="showEditDialoge(scope.row.id)" v-has="'stations:edit'">修改</el-button>
            <el-button type="text" icon="el-icon-delete" size="mini" v-on:click="deleteRow(scope.row.id)" v-has="'stations:remove'">删除</el-button>
            <el-dropdown size="mini" @command="command => handleCommand(command, scope.row)">
              <el-button size="mini" type="text" icon="el-icon-d-arrow-right">更多</el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="showDownDialoge" icon="el-icon-download" v-has="'stations:download'">下载二维码</el-dropdown-item>
                <el-dropdown-item command="showEditPaySettingDialoge" icon="el-icon-setting">支付设置</el-dropdown-item>
                <el-dropdown-item command="partReg" icon="el-icon-open">开通分账</el-dropdown-item>
                <el-dropdown-item command="showWhiteList" icon="el-icon-setting">加注测试白名单</el-dropdown-item>
                <!-- <el-tooltip effect="dark" content="下载二维码" placement="top" v-bind:enterable="false">
                  <el-button type="info" icon="el-icon-download" size="mini" v-on:click="showDownDialoge(scope.row)" v-has="'stations:download'"></el-button>
                </el-tooltip>
                <el-tooltip effect="dark" content="支付设置" placement="top" v-bind:enterable="false">
                  <el-button type="warning" icon="el-icon-setting" size="mini" v-on:click="showEditPaySettingDialoge(scope.row.id)"></el-button>
                </el-tooltip>
                <el-tooltip effect="dark" content="开通分账" placement="top" v-bind:enterable="false">
                  <el-button type="warning" icon="el-icon-setting" size="mini" v-on:click="showEditPaySettingDialoge(scope.row.id)"></el-button>
                </el-tooltip>
                <el-tooltip effect="dark" content="加注测试白名单" placement="top" v-bind:enterable="false">
                  <el-button type="warning" icon="el-icon-view" size="mini" v-on:click="showWhiteList(scope.row.id)"></el-button>
                </el-tooltip> -->
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination :hide-on-single-page="true" @size-change="handleSizeChange" @current-change="handleCurrentChange" v-bind:current-page="queryParam.pagenum" v-bind:page-sizes="this.pageOption" v-bind:page-size="queryParam.pagesize" layout="total, sizes, prev, pager, next, jumper" v-bind:total="total"> </el-pagination>
    </el-card>
    <!-- 添加对话框 -->
    <el-dialog title="添加网点" :visible.sync="addDialogeVisible" width="50%" v-on:closed="addDialogeClosed" v-bind:close-on-click-modal="false">
      <el-form :model="addForm" :rules="addFormRules" ref="addFormRef" label-width="100px">
        <!-- <el-form-item label="网点编号" prop="station_no">
          <el-input v-model="addForm.station_no"></el-input>
        </el-form-item> -->
        <el-form-item label="网点名称" prop="station_name">
          <el-input v-model="addForm.station_name"></el-input>
        </el-form-item>
        <el-form-item label="简称" prop="brief_info">
          <el-input v-model="addForm.brief_info"></el-input>
        </el-form-item>
        <el-form-item label="负责人" prop="master">
          <el-input v-model="addForm.master"></el-input>
        </el-form-item>
        <el-form-item label="省市县" prop="address1">
          <el-cascader :options="CityData" v-model="addForm.address1" clearable></el-cascader>
        </el-form-item>
        <el-form-item label="详细地址" prop="address2">
          <el-input v-model="addForm.address2"></el-input>
        </el-form-item>
        <el-form-item label="电话" prop="tel">
          <el-input v-model="addForm.tel"></el-input>
        </el-form-item>
        <el-form-item label="品类名称" prop="oil_name">
          <el-input v-model="addForm.oil_name"></el-input>
        </el-form-item>
        <el-form-item label="单价" prop="price">
          <el-input v-model="addForm.price"></el-input>
        </el-form-item>
        <el-form-item label="成本" prop="cost">
          <el-input v-model="addForm.cost"></el-input>
        </el-form-item>
        <el-form-item label="计量方式" prop="meter">
          <el-radio-group v-model="addForm.meter">
            <el-radio v-bind:label="0" border>升计量</el-radio>
            <el-radio v-bind:label="1" border>公斤计量</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="经度(高德)" prop="lon">
          <el-input v-model="addForm.lon"></el-input>
        </el-form-item>
        <el-form-item label="纬度(高德)" prop="lat">
          <el-input v-model="addForm.lat"></el-input>
        </el-form-item>
        <el-form-item label="加注机品牌" prop="brand">
          <el-input v-model="addForm.brand"></el-input>
        </el-form-item>
        <el-form-item label="加注机型号" prop="serial">
          <el-input v-model="addForm.serial"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogeVisible = false">取 消</el-button>
        <el-button type="primary" @click="addRow">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 修改对话框 -->
    <el-dialog title="修改网点" :visible.sync="editDialogeVisible" width="50%" v-on:closed="editDialogeClosed" v-bind:close-on-click-modal="false">
      <el-form :model="editForm" :rules="editFormRules" ref="editFormRef" label-width="90px">
        <el-form-item label="网点名称" prop="station_name">
          <el-input v-model="editForm.station_name"></el-input>
        </el-form-item>
        <el-form-item label="网点简称" prop="brief_info">
          <el-input v-model="editForm.brief_info"></el-input>
        </el-form-item>
        <el-form-item label="负责人" prop="master">
          <el-input v-model="editForm.master"></el-input>
        </el-form-item>
        <el-form-item label="电话" prop="tel">
          <el-input v-model="editForm.tel"></el-input>
        </el-form-item>
        <el-form-item label="品类名称" prop="oil_name">
          <el-input v-model="editForm.oil_name"></el-input>
        </el-form-item>
        <el-form-item label="单价" prop="price">
          <el-input v-model="editForm.price"></el-input>
        </el-form-item>
        <el-form-item label="成本" prop="cost">
          <el-input v-model="editForm.cost"></el-input>
        </el-form-item>
        <el-form-item label="数据传输标识" prop="dts_device_no">
          <el-input v-model="editForm.dts_device_no"></el-input>
        </el-form-item>
        <el-form-item label="计量方式" prop="meter">
          <el-radio-group v-model="editForm.meter">
            <el-radio v-bind:label="0" border>升计量</el-radio>
            <el-radio v-bind:label="1" border>公斤计量</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="经度(高德)" prop="lon">
          <el-input v-model="editForm.lon"></el-input>
        </el-form-item>
        <el-form-item label="纬度(高德)" prop="lat">
          <el-input v-model="editForm.lat"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogeClosed">取 消</el-button>
        <el-button type="primary" @click="editSave">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 下载二维码对话框 -->
    <el-dialog title="下载二维码" :visible.sync="downloadDialogeVisible" width="50%">
      <el-form label-position="top" inline class="table-expand qrcode-form" size="mini">
        <el-form-item label="扫码加注链接地址">
          <pre>{{ currentUrl }}</pre>
        </el-form-item>
        <el-form-item label="二维码">
          <div ref="qrCodeRef" class="qrcode"></div>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="downloadDialogeVisible = false" size="mini">取 消</el-button>
        <el-button type="primary" @click="download" size="mini">下载二维码</el-button>
      </span>
    </el-dialog>
    <!-- 支付设置对话框 -->
    <el-dialog title="支付设置" :visible.sync="editPaySettingFormDialogeVisible" width="50%" v-bind:close-on-click-modal="false">
      <el-form :model="editPaySettingForm" :rules="editPaySettingFormRules" ref="editPaySettingFormRef" label-width="100px">
        <!-- <el-form-item label="是否启用" prop="is_use">
          <i-switch size="large" v-model="editPaySettingForm.is_use">
            <span slot="open">启用</span>
            <span slot="close">禁用</span>
          </i-switch>
        </el-form-item>
        <el-form-item label="app_id" prop="app_id">
          <el-input v-model="editPaySettingForm.app_id"></el-input>
        </el-form-item>
        <el-form-item label="app_secret" prop="app_secret">
          <el-input v-model="editPaySettingForm.app_secret"></el-input>
        </el-form-item> -->
        <el-form-item label="mcht_no" prop="mcht_no">
          <el-input v-model="editPaySettingForm.mcht_no"></el-input>
        </el-form-item>
        <el-form-item label="store_no" prop="store_no">
          <el-input v-model="editPaySettingForm.store_no"></el-input>
        </el-form-item>
        <el-form-item label="redirect_url" prop="redirect_url">
          <el-input v-model="editPaySettingForm.redirect_url"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="deletePaySetting" size="mini" v-bind:disabled="this.editPaySettingForm.id <= 0" type="danger">删除设置</el-button>
        <el-button @click="editPaySettingDialogeClosed" size="mini">取 消</el-button>
        <el-button type="primary" @click="editPaySettingSave" size="mini">保存</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import CityData from '../../components/station/citydata'
import QRCode from 'qrcodejs2'
export default {
  data() {
    var checkMobile = (rule, value, callback) => {
      const regMobile = /^1([3589][0-9]|4[579]|66|7[0135678]|9[89])[0-9]{8}$/
      if (regMobile.test(value)) {
        return callback()
      }
      callback(new Error('请输入正确的手机号'))
    }
    return {
      stationList: [],
      queryParam: {
        queryJson: '',
        pagenum: 1,
        pagesize: this.pageOption[0]
      },
      total: 0,
      addDialogeVisible: false,
      addForm: {
        // station_no: '',
        station_name: '',
        brief_info: '',
        master: '',
        address: '',
        address1: [],
        address2: '',
        tel: '',
        lon: '',
        lat: '',
        oil_name: '',
        price: '',
        cost: '',
        brand: '',
        serial: '',
        meter: ''
      },
      addFormRules: {
        // station_no: [
        //   { required: true, message: '请输入网点编号', trigger: 'blur' },
        //   { min: 8, max: 8, message: '长度为8位', trigger: 'blur' }
        // ],
        station_name: [
          { required: true, message: '请输入网点名称', trigger: 'blur' },
          { min: 3, max: 15, message: '长度在3至15位字符', trigger: 'blur' }
        ],
        brief_info: [
          { required: true, message: '请输入简称', trigger: 'blur' },
          { min: 3, max: 8, message: '长度在3至8位字符', trigger: 'blur' }
        ],
        master: [
          { required: true, message: '请输入负责人姓名', trigger: 'blur' },
          { min: 2, max: 10, message: '长度在2至10位字符', trigger: 'blur' }
        ],
        tel: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { validator: checkMobile, trigger: 'blur' }
        ],
        address1: [{ required: true, message: '请选择省市县', trigger: 'blur' }],
        address2: [{ required: true, message: '请输入详细地址', trigger: 'blur' }],
        oil_name: [{ required: true, message: '请输入品类名称', trigger: 'blur' }],
        price: [{ required: true, message: '请输入单价 元/升', trigger: 'blur' }],
        cost: [{ required: true, message: '请输入成本 元/升', trigger: 'blur' }],
        meter: [{ required: true, message: '请选择计量方式', trigger: 'blur' }]
      },
      editDialogeVisible: false,
      editForm: {},
      editFormRules: {
        // lon: [{ required: true, message: '请输入经度', trigger: 'blur' }],
        // lat: [{ required: true, message: '请输入纬度', trigger: 'blur' }],
        station_name: [
          { required: true, message: '请输入网点名称', trigger: 'blur' },
          { min: 3, max: 15, message: '长度在3至15位字符', trigger: 'blur' }
        ],
        brief_info: [
          { required: true, message: '请输入简称', trigger: 'blur' },
          { min: 3, max: 8, message: '长度在3至8位字符', trigger: 'blur' }
        ],
        master: [
          { required: true, message: '请输入负责人姓名', trigger: 'blur' },
          { min: 2, max: 10, message: '长度在2至10位字符', trigger: 'blur' }
        ],
        tel: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { validator: checkMobile, trigger: 'blur' }
        ],
        oil_name: [{ required: true, message: '请输入品类名称', trigger: 'blur' }],
        price: [{ required: true, message: '请输入单价 元/升', trigger: 'blur' }],
        cost: [{ required: true, message: '请输入成本 元/升', trigger: 'blur' }],
        dts_device_no: [{ required: true, message: '请输入数据传输标识', trigger: 'blur' }]
      },
      selectedItem: {},
      CityData,
      downloadDialogeVisible: false,
      url: 'http://energymobile.fuelcloud-censtar.com/#/selfauth?station_no=',
      currentUrl: '',
      currentStationName: '',
      qrCode: null,
      editPaySettingFormDialogeVisible: false,
      editPaySettingForm: {},
      editPaySettingFormRules: {
        // station_id: [{ required: true, message: '请选择网点', trigger: 'blur' }],
        // is_use: [{ required: true, message: '请选择是否启用', trigger: 'blur' }],
        // app_id: [{ required: true, message: '请输入app_id', trigger: 'blur' }],
        // app_secret: [{ required: true, message: '请输入app_secret', trigger: 'blur' }],
        mcht_no: [{ required: true, message: '请输入mcht_no', trigger: 'blur' }],
        store_no: [{ required: true, message: '请输入store_no', trigger: 'blur' }]
        // redirect_url: [{ required: true, message: '请输入redirect_url', trigger: 'blur' }]
      }
    }
  },
  created() {
    this.getStationList()
  },
  methods: {
    async getStationList() {
      const { data: res } = await this.$http.get('/Station/List', { params: this.queryParam })
      if (res.status !== 1) {
        return this.$message.error('获取网点列表失败')
      }
      console.log(res.data)
      this.stationList = res.data.list
      this.total = res.data.total
    },
    addDialogeClosed: function() {
      this.$refs.addFormRef.resetFields()
    },
    addRow: function() {
      this.$refs.addFormRef.validate(async valid => {
        if (!valid) return
        this.addForm.address = this.addForm.address1.join('/') + '/' + this.addForm.address2
        const { data: res } = await this.$http.post('/Station/Add', this.addForm)
        if (res.status !== 1) return this.$message.error('添加失败')
        this.$message.success('添加成功')
        this.addDialogeVisible = false
        this.getStationList()
      })
    },
    showEditDialoge: async function(id) {
      const { data: res } = await this.$http.get(`/Station/One?id=${id}`)
      if (res.status !== 1) return this.$message.error('获取网点信息失败')
      this.editForm = res.data
      this.editDialogeVisible = true
    },
    editDialogeClosed: function() {
      this.$refs.editFormRef.resetFields()
      this.editDialogeVisible = false
    },
    editSave: function() {
      this.$refs.editFormRef.validate(async valid => {
        if (!valid) return
        const { data: res } = await this.$http.put('/Station/Edit?id=' + this.editForm.id, {
          lon: this.editForm.lon,
          lat: this.editForm.lat,
          oil_name: this.editForm.oil_name,
          price: this.editForm.price,
          cost: this.editForm.cost,
          dts_device_no: this.editForm.dts_device_no,
          station_name: this.editForm.station_name,
          brief_info: this.editForm.brief_info,
          master: this.editForm.master,
          tel: this.editForm.tel,
          meter: this.editForm.meter
        })
        if (res.status !== 1) {
          return this.$message.error('更新失败')
        }
        this.editDialogeVisible = false
        this.getStationList()
        this.$message.success('更新成功')
      })
    },
    deleteRow: async function(id) {
      const confirmResult = await this.$confirm('此操作将永久删除该网点, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(err => err)
      if (confirmResult !== 'confirm') {
        return this.$message('已取消删除')
      }
      const { data: res } = await this.$http.delete('/Station/Del?id=' + id)
      if (res.status !== 1) {
        return this.$message.error('删除失败')
      }
      await this.getStationList()
      this.$message.success('删除成功')
    },
    handleSizeChange: function(newSize) {
      this.queryParam.pagesize = newSize
      this.getStationList()
    },
    handleCurrentChange: function(newPage) {
      this.queryParam.pagenum = newPage
      this.getStationList()
    },
    showDownDialoge: async function(row) {
      this.currentUrl = this.url + row.station_no
      this.currentStationName = row.station_name
      this.downloadDialogeVisible = true
      this.qrCodeGeneration()
    },
    qrCodeGeneration: function() {
      if (this.qrCode) {
        this.qrCode.clear()
        this.$refs.qrCodeRef.innerHTML = ''
      }
      this.$nextTick(function() {
        this.qrCode = new QRCode(this.$refs.qrCodeRef, {
          text: this.currentUrl, // 需要转换为二维码的内容
          width: 300,
          height: 300,
          colorDark: '#409EFF',
          colorLight: '#fff',
          correctLevel: QRCode.CorrectLevel.H
        })
      })
    },
    download: function() {
      const myCanvas = this.$refs.qrCodeRef.getElementsByTagName('canvas')
      console.log(myCanvas, 'myCanvas')
      let a = document.createElement('a')
      a.href = myCanvas[0].toDataURL('image/png')
      a.download = this.currentStationName
      a.click()
      a = null
    },
    changeState: async function(row) {
      if (row.pay_test_station) {
        const confirmResult = await this.$confirm('此操作仅用于内部测试, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).catch(err => err)
        if (confirmResult !== 'confirm') {
          row.pay_test_station = !row.pay_test_station
          return this.$message('已取消')
        }
      } else {
        const confirmResult = await this.$confirm('关闭支付测试将清除该网点所有测试交易, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).catch(err => err)
        if (confirmResult !== 'confirm') {
          row.pay_test_station = !row.pay_test_station
          return this.$message('已取消')
        }
        this.deleteTradeTest(row)
      }
      this.changePayTest(row)
    },
    changePayTest: async function(row) {
      console.log(row)
      const { data: res } = await this.$http.put(`/Station/PayTest?id=${row.id}&pay_test_station=${row.pay_test_station}`)
      if (res.status !== 1) {
        row.pay_test_station = !row.pay_test_station
        return this.$message.error('更新状态失败' + res.msg)
      }
      this.$message.success('更新状态成功')
    },
    deleteTradeTest: async function(row) {
      const { data: res } = await this.$http.delete(`/Station/DelTradeTest?id=${row.id}`)
      if (res.status !== 1) {
        return this.$message.error('删除测试数据失败' + res.msg)
      }
      this.$message.success('删除测试数据成功')
    },
    showEditPaySettingDialoge: async function(id) {
      const { data: res } = await this.$http.get(`/Station/GetPaySettingByStationId?id=${id}`)
      if (res.status !== 1) return this.$message.error('获取支付设置信息失败')
      this.editPaySettingForm = res.data
      this.editPaySettingForm.station_id = id
      if (this.editPaySettingForm.id === 0) {
        this.editPaySettingForm.is_use = true
      }
      this.editPaySettingFormDialogeVisible = true
    },
    editPaySettingDialogeClosed: function() {
      this.$refs.editPaySettingFormRef.resetFields()
      this.editPaySettingFormDialogeVisible = false
    },
    editPaySettingSave: function() {
      this.$refs.editPaySettingFormRef.validate(async valid => {
        if (!valid) return
        const { data: res } = await this.$http.put('/Station/EditPaySettingSave?id=' + this.editPaySettingForm.station_id, {
          name: '盒子支付',
          // station_id: this.editPaySettingForm.station_id,
          // is_use: this.editPaySettingForm.is_use,
          // app_id: this.editPaySettingForm.app_id,
          // app_secret: this.editPaySettingForm.app_secret,
          mcht_no: this.editPaySettingForm.mcht_no,
          store_no: this.editPaySettingForm.store_no
          // redirect_url: this.editPaySettingForm.redirect_url
        })
        if (res.status !== 1) {
          return this.$message.error('更新失败')
        }
        this.editPaySettingFormDialogeVisible = false
        this.$message.success('更新成功')
      })
    },
    // deletePaySetting: async function() {
    //   const { data: res } = await this.$http.delete(`/Station/DelPaySetting?id=${this.editPaySettingForm.id}`)
    //   if (res.status !== 1) {
    //     return this.$message.error('删除失败' + res.msg)
    //   }
    //   this.$message.success('删除成功')
    // },
    showWhiteList: function(id) {
      this.$router.push(`/whitelist/${id}`)
    },
    handleCommand(command, row) {
      switch (command) {
        case 'showDownDialoge':
          this.showDownDialoge(row)
          break
        case 'showEditPaySettingDialoge':
          this.showEditPaySettingDialoge(row.id)
          break
        case 'partReg':
          this.partReg(row.id)
          break
        case 'showWhiteList':
          this.showWhiteList(row.id)
          break
        default:
          break
      }
    },
    partReg: async function(id) {
      const { data: res } = await this.$http.put(`/Station/PartReg?id=${id}`)
      if (res.status !== 1) {
        return this.$message.error('开通分账失败' + res.msg)
      }
      this.$message.success('开通分账成功')
    }
  }
}
</script>
<style lang="less" scoped>
.el-cascader {
  width: 100%;
}
/deep/ .table-expand {
  font-size: 0;
}
/deep/ .table-expand label {
  width: 90px;
  // color: #99a9bf;
  font-size: 12px;
}
/deep/ .table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 50%;
}
/deep/ .qrcode-form label {
  width: 200px;
}
/deep/ .qrcode-form .el-form-item {
  width: 100%;
}
/deep/ .qrcode-form .qrcode img {
  margin: 0 auto;
}
</style>
