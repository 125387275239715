<template>
  <div>
    <el-breadcrumb>
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>运营数据</el-breadcrumb-item>
      <el-breadcrumb-item>实时状态</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <el-row class="search">
        <el-col :span="8">
          <el-input placeholder="请输入网点名称或编号进行搜索" v-model="queryInfo.query" clearable v-on:clear="getGunList" size="small">
            <el-button slot="append" icon="el-icon-search" v-on:click="getGunList" size="small"></el-button>
          </el-input>
        </el-col>
      </el-row>
      <div v-if="guns.length === 0">当前无数据</div>
      <div v-else class="gun_box">
        <div class="oil_gun" v-for="(item, index) in guns" :key="index">
          <div class="gun_div">
            <div class="gun_div_btm">
              <span class="btm_left" :class="getColor(index)">{{ item.oil_name }}</span>
              <!-- <span class="btm_right">{{ item.terminal }}号枪</span> -->
              <span class="btm_right">
                <span>{{ item.station_name }}</span>
                <span>网点编号：{{ item.station_no }}</span>
              </span>
            </div>
            <div class="gun_div_top">
              <img class="top_img" :src="getImgSrc(item)" />
              <ul class="top_list">
                <li>
                  <span>金额：</span>
                  <div>
                    <label>{{ item.money }}元</label>
                  </div>
                </li>
                <li>
                  <span>数量：</span>
                  <div>
                    <label>{{ item.qty }}{{ item.meter === 0 ? '升' : '公斤' }}</label>
                  </div>
                </li>
                <li>
                  <span>单价：</span>
                  <div>
                    <label>{{ item.price }}元</label>
                  </div>
                </li>
                <!-- <li>
                  <span>时间：</span>
                  <div>
                    <label>{{ item.update_time | dateformat }}</label>
                  </div>
                </li> -->
              </ul>
            </div>
          </div>
          <div class="control">
            <!-- <el-button type="info" round size="mini" @click="showQuantityDialoge(item, index)">定量</el-button> -->
            <el-button type="info" round size="mini" @click="getGunList" v-has="'terminalstate:list'">刷新</el-button>
            <el-button type="danger" round size="mini" @click="showReadSumDialoge(item, index)" v-has="'terminalstate:pump'">读累计</el-button>
            <el-button type="primary" round size="mini" @click="showChangePriceDialoge(item, index)" v-has="'terminalstate:price'">调价</el-button>
          </div>
        </div>
      </div>
    </el-card>
    <!-- 定量对话框 -->
    <el-dialog title="设置定量" :visible.sync="quantityDialogeVisible" width="50%" v-on:closed="quantityDialogeVisible = false">
      <el-form :model="quantityForm" :rules="formRules" ref="quantityFormRef" label-width="100px">
        <el-form-item label="定量类型" prop="auth_type">
          <el-radio-group v-model="quantityForm.auth_type">
            <el-radio label="1">定金额</el-radio>
            <el-radio label="2">定升数</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="定量数值" prop="auth_value">
          <el-input v-model.number="quantityForm.auth_value"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="quantityDialogeVisible = false">取 消</el-button>
        <el-button type="primary" @click="quantity">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 读累计对话框 -->
    <el-dialog title="读累计" :visible.sync="readSumDialogeVisible" width="50%" v-on:closed="readSumDialogeVisible = false" custom-class="target" @open="openDialoge">
      <el-form :model="readSumForm" label-width="100px">
        <el-form-item label="升累计" prop="sum_qty">
          <el-input v-model="readSumForm.sum_qty" disabled></el-input>
        </el-form-item>
        <el-form-item label="金额累计" prop="sum_money">
          <el-input v-model="readSumForm.sum_money" disabled></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="readSumDialogeVisible = false">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 调价对话框 -->
    <el-dialog title="调价" :visible.sync="priceDialogeVisible" width="50%" v-on:closed="priceDialogeVisible = false">
      <el-form :model="priceForm" :rules="priceFormRules" ref="priceFormRef" label-width="100px">
        <el-form-item label="设置单价" prop="price">
          <el-input v-model="priceForm.price"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="priceDialogeVisible = false">取 消</el-button>
        <el-button type="primary" @click="changePrice">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      guns: [
        // {
        //   // terminal: '01_1',
        //   oil_name: '正星牌尿素',
        //   money: 100.0,
        //   qty: 30.01,
        //   price: 6.5,
        //   update_time: '2020-10-20 10:01',
        //   station_name: '雪松路加注站',
        //   terminal_status: 1
        // }
        // {
        //   terminal: '02_1',
        //   oil_name: '95#汽油',
        //   money: 100.0,
        //   qty: 30.01,
        //   price: 6.5,
        //   update_time: '2020-10-20 10:01'
        // },
        // {
        //   terminal: '03_1',
        //   oil_name: '98#汽油',
        //   money: 100.0,
        //   qty: 30.01,
        //   price: 6.5,
        //   update_time: '2020-10-20 10:01'
        // },
        // {
        //   terminal: '04_1',
        //   oil_name: '0#柴油',
        //   money: 100.0,
        //   qty: 30.01,
        //   price: 6.5,
        //   update_time: '2020-10-20 10:01'
        // },
        // {
        //   terminal: '05_1',
        //   oil_name: '92#汽油',
        //   money: 100.0,
        //   qty: 30.01,
        //   price: 6.5,
        //   update_time: '2020-10-20 10:01'
        // },
        // {
        //   terminal: '06_1',
        //   oil_name: '92#汽油',
        //   money: 100.0,
        //   qty: 30.01,
        //   price: 6.5,
        //   update_time: '2020-10-20 10:01'
        // },
        // {
        //   terminal: '07_1',
        //   oil_name: '92#汽油',
        //   money: 100.0,
        //   qty: 30.01,
        //   price: 6.5,
        //   update_time: '2020-10-20 10:01'
        // },
        // {
        //   terminal: '08_1',
        //   oil_name: '92#汽油',
        //   money: 100.0,
        //   qty: 30.01,
        //   price: 6.5,
        //   update_time: '2020-10-20 10:01'
        // },
        // {
        //   terminal: '09_1',
        //   oil_name: '92#汽油',
        //   money: 100.0,
        //   qty: 30.01,
        //   price: 6.5,
        //   update_time: '2020-10-20 10:01'
        // },
        // {
        //   terminal: '01_1',
        //   oil_name: '92#汽油',
        //   money: 100.0,
        //   qty: 30.01,
        //   price: 6.5,
        //   update_time: '2020-10-20 10:01'
        // },
        // {
        //   terminal: '02_1',
        //   oil_name: '95#汽油',
        //   money: 100.0,
        //   qty: 30.01,
        //   price: 6.5,
        //   update_time: '2020-10-20 10:01'
        // },
        // {
        //   terminal: '03_1',
        //   oil_name: '98#汽油',
        //   money: 100.0,
        //   qty: 30.01,
        //   price: 6.5,
        //   update_time: '2020-10-20 10:01'
        // },
        // {
        //   terminal: '04_1',
        //   oil_name: '0#柴油',
        //   money: 100.0,
        //   qty: 30.01,
        //   price: 6.5,
        //   update_time: '2020-10-20 10:01'
        // },
        // {
        //   terminal: '05_1',
        //   oil_name: '92#汽油',
        //   money: 100.0,
        //   qty: 30.01,
        //   price: 6.5,
        //   update_time: '2020-10-20 10:01'
        // },
        // {
        //   terminal: '06_1',
        //   oil_name: '92#汽油',
        //   money: 100.0,
        //   qty: 30.01,
        //   price: 6.5,
        //   update_time: '2020-10-20 10:01'
        // },
        // {
        //   terminal: '07_1',
        //   oil_name: '92#汽油',
        //   money: 100.0,
        //   qty: 30.01,
        //   price: 6.5,
        //   update_time: '2020-10-20 10:01'
        // },
        // {
        //   terminal: '08_1',
        //   oil_name: '92#汽油',
        //   money: 100.0,
        //   qty: 30.01,
        //   price: 6.5,
        //   update_time: '2020-10-20 10:01'
        // },
        // {
        //   terminal: '09_1',
        //   oil_name: '92#汽油',
        //   money: 100.0,
        //   qty: 30.01,
        //   price: 6.5,
        //   update_time: '2020-10-20 10:01'
        // },
        // {
        //   terminal: '01_1',
        //   oil_name: '92#汽油',
        //   money: 100.0,
        //   qty: 30.01,
        //   price: 6.5,
        //   update_time: '2020-10-20 10:01'
        // },
        // {
        //   terminal: '02_1',
        //   oil_name: '95#汽油',
        //   money: 100.0,
        //   qty: 30.01,
        //   price: 6.5,
        //   update_time: '2020-10-20 10:01'
        // },
        // {
        //   terminal: '03_1',
        //   oil_name: '98#汽油',
        //   money: 100.0,
        //   qty: 30.01,
        //   price: 6.5,
        //   update_time: '2020-10-20 10:01'
        // },
        // {
        //   terminal: '04_1',
        //   oil_name: '0#柴油',
        //   money: 100.0,
        //   qty: 30.01,
        //   price: 6.5,
        //   update_time: '2020-10-20 10:01'
        // },
        // {
        //   terminal: '05_1',
        //   oil_name: '92#汽油',
        //   money: 100.0,
        //   qty: 30.01,
        //   price: 6.5,
        //   update_time: '2020-10-20 10:01'
        // },
        // {
        //   terminal: '06_1',
        //   oil_name: '92#汽油',
        //   money: 100.0,
        //   qty: 30.01,
        //   price: 6.5,
        //   update_time: '2020-10-20 10:01'
        // },
        // {
        //   terminal: '07_1',
        //   oil_name: '92#汽油',
        //   money: 100.0,
        //   qty: 30.01,
        //   price: 6.5,
        //   update_time: '2020-10-20 10:01'
        // },
        // {
        //   terminal: '08_1',
        //   oil_name: '92#汽油',
        //   money: 100.0,
        //   qty: 30.01,
        //   price: 6.5,
        //   update_time: '2020-10-20 10:01'
        // },
        // {
        //   terminal: '09_1',
        //   oil_name: '92#汽油',
        //   money: 100.0,
        //   qty: 30.01,
        //   price: 6.5,
        //   update_time: '2020-10-20 10:01'
        // }
      ],
      img: {
        1: require('../../assets/machtank/oil_gun1.png'),
        2: require('../../assets/machtank/oil_gun2.png'),
        3: require('../../assets/machtank/oil_gun3.png'),
        4: require('../../assets/machtank/oil_gun4.png')
      },
      queryInfo: {
        query: ''
      },
      timer: null,
      quantityDialogeVisible: false,
      quantityForm: {
        station_id: '',
        auth_type: '',
        auth_value: ''
      },
      formRules: {
        auth_type: [{ required: true, message: '请选择定量类型', trigger: 'blur' }],
        auth_value: [{ required: true, message: '请输入定量数值', trigger: 'blur' }]
      },
      readSumDialogeVisible: false,
      station_id: '',
      readSumForm: {
        sum_qty: '',
        sum_money: ''
      },
      priceDialogeVisible: false,
      priceForm: {
        price: ''
      },
      priceFormRules: {
        price: [{ required: true, message: '请设置单价', trigger: 'blur' }]
      }
    }
  },
  methods: {
    getImgSrc(item) {
      // return this.img[(index % 4) + 1]
      // 先根据flag_comm判断通讯是否正常，如果通讯正常再根据flag_card判断是否插卡，否则显示通讯断，如果插卡再根据flag_work判断是否加油，否则显示挂枪状态，如果加油显示加油状态，否则显示插卡状态
      var imgName = 1 // gsBREAK
      // if (item.flag_comm === 0) {
      //   if (item.flag_work === 1) {
      //     imgName = 2 // gsRUN;
      //   } else if (item.flag_card === 1) {
      //     imgName = 3 // gsCARD;
      //   } else {
      //     imgName = 4 // gsHAND;
      //   }
      // }
      switch (item.terminal_status) {
        case 1:
          imgName = 2
          break
        case 2:
          imgName = 4
          break
        case 3:
          imgName = 1
          break
        case 4:
          imgName = 4
          break
        case 5:
          imgName = 4
          break
      }
      return this.img[imgName]
    },
    getColor(index) {
      if (index % 3 === 0) return 'red'
      else if (index % 3 === 1) return 'yellow'
      else return 'green'
    },
    getGunList: async function() {
      const { data: res } = await this.$http.get('/RealState/Guns?queryJson=' + this.queryInfo.query)
      if (res.status !== 1) {
        return this.$message.error('获取油枪数据失败')
      }
      console.log(res.data)
      this.guns = res.data
    },
    showQuantityDialoge: function(item, index) {
      this.quantityDialogeVisible = true
      this.quantityForm.station_id = item.station_id
    },
    // quantity(item, index) {
    //   this.$message(item.terminal + '号枪开机')
    //   this.$set(this.guns[index], 'flag_comm', 0)
    //   this.$set(this.guns[index], 'flag_work', 0)
    //   this.$set(this.guns[index], 'flag_card', 0)
    // },
    quantity: async function() {
      const { data: res } = await this.$http.post('/RealTimeState/Quantity', this.quantityForm)
      if (res.status !== 1) return this.$message.error('发送定量失败')
      this.$message.success('发送定量成功')
    },
    showReadSumDialoge: function(item, index) {
      this.station_id = item.station_id
      this.readSumForm.sum_qty = ''
      this.readSumForm.sum_money = ''
      this.readSumDialogeVisible = true
      console.log(this.station_id)
    },
    openDialoge: async function() {
      const loading = this.$loading({ target: '.target' })
      const { data: res } = await this.$http.post('/RealState/ReadPump?station_id=' + this.station_id)
      console.log(res)
      loading.close()
      if (res.status !== 1) {
        this.readSumDialogeVisible = false
        return this.$message.error('读取累计数失败')
      }
      this.readSumForm.sum_qty = res.data.qty
      this.readSumForm.sum_money = res.data.money
    },
    readSum(item, index) {
      this.$message(item.terminal + '号枪关机')
      this.$set(this.guns[index], 'flag_comm', 1)
    },
    // changePrice(item, index) {
    //   this.$message(item.terminal + '号枪加注')
    //   this.$set(this.guns[index], 'flag_comm', 0)
    //   this.$set(this.guns[index], 'flag_work', 1)
    // }
    showChangePriceDialoge: function(item, index) {
      this.station_id = item.station_id
      this.priceDialogeVisible = true
    },
    changePrice: async function() {
      const { data: res } = await this.$http.post('/RealState/ChangePrice', { station_id: this.station_id, price: this.priceForm.price })
      if (res.status !== 1) return this.$message.error('调价失败')
      this.$message.success('调价成功')
      this.priceDialogeVisible = false
      setTimeout(() => {
        this.getGunList()
      }, 1000)
    }
  },
  created() {
    // const timer = setInterval(() => {
    //   this.getGunList()
    // }, 20000)
    // this.timer = timer
    this.getGunList()
  },
  mounted() {
    // setInterval(this.getGunList, 10000)
  },
  beforeDestroy() {
    this.timer = null
  }
}
</script>
<style lang="less" scoped>
.gun_box {
  display: flex;
  flex-wrap: wrap;
  // justify-content: flex-start;
}
.oil_gun {
  background-color: #474747;
  // width: 17.2rem;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 5px;
  //   margin-right: 15px;
  //   margin-bottom: 15px;
  margin: 5px;
  .gun_div {
    display: flex;
    flex-direction: column;
    color: #fff;
    .gun_div_top {
      display: flex;
      align-items: center;
      font-size: 12px;
      .top_list {
        list-style-type: none;
        padding-left: 0;
        margin-left: 15px;
        li {
          display: flex;
          margin-bottom: 2px;
          line-height: 20px;
          div {
            background-color: #fff;
            width: 120px;
            color: #000;
            text-align: right;
            padding-right: 10px;
          }
        }
      }
    }
    .gun_div_btm {
      display: flex;
      justify-content: space-between;
      font-size: 12px;
      .btm_right {
        display: flex;
        flex-direction: column;
      }
    }
  }
  .control {
    margin-top: 5px;
    display: flex;
    justify-content: space-evenly;
  }
}
.red {
  color: red;
}
.yellow {
  color: #ffff78;
}
.green {
  color: #00ff00;
}
.search {
  margin-bottom: 15px;
}
</style>
