import Vue from 'vue'
import VueRouter from 'vue-router'
// import Login from '../components/Login.vue'
import Login2 from '../components/Login2.vue'
import Home from '../components/Home.vue'
import Welcome from '../components/Welcome.vue'
import Trade from '../components/Trade.vue'
import Users from '../components/user/Users.vue'
import Rights from '../components/power/Rights.vue'
import Roles from '../components/power/Roles.vue'
import Company from '../components/cooperate/Company.vue'
import Apply from '../components/cooperate/Apply.vue'
import Station from '../components/station/Station.vue'
import Oil from '../components/station/Oil.vue'
import Terminal from '../components/station/Terminal.vue'
import Partner from '../components/station/Partner.vue'
import Sharesetting from '../components/station/ShareSetting.vue'
import Charge from '../components/data/Charge.vue'
import Sms from '../components/data/Sms.vue'
import PriceChange from '../components/data/Price.vue'
import AddOil from '../components/data/AddOil.vue'
import Transaction from '../components/data/Transaction.vue'
import PriceStrategy from '../components/setting/PriceStrategy.vue'
import ChargePre from '../components/setting/ChargePre.vue'
import OilAdd from '../components/setting/OilAdd.vue'
import TerminalState from '../components/data/TerminalState.vue'
import DataStatistics from '../components/data/DataStatistics.vue'
import Error from '../components/data/Error.vue'
import Cash from '../components/data/Cash.vue'
import Bill from '../components/data/Bill.vue'
// import CouponTemplate from '../components/data/CouponTemplate.vue'
// import Coupon from '../components/data/Coupon.vue'
import UserCoupon from '../components/data/UserCoupon.vue'
import BigData from '../components/bigdata.vue'
import WhiteList from '../components/station/WhiteList.vue'
import OperationAccount from '../components/station/OperationAccount.vue'
import Config from '../components/config'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    component: Login2
  },
  {
    path: '/bigdata',
    component: BigData
  },
  {
    path: '/home',
    component: Home,
    redirect: '/trade',
    children: [
      { path: '/bigdata', component: BigData },
      { path: '/welcome', component: Welcome },
      { path: '/trade/:day?', component: Trade, props: true },
      { path: '/rights', component: Rights },
      { path: '/users', component: Users },
      { path: '/roles', component: Roles },
      { path: '/company', component: Company },
      { path: '/apply', component: Apply },
      { path: '/stations', component: Station },
      { path: '/oil', component: Oil },
      { path: '/machine', component: Terminal },
      { path: '/partners', component: Partner },
      { path: '/sharesetting', component: Sharesetting },
      { path: '/charge', component: Charge },
      { path: '/sms', component: Sms },
      { path: '/price', component: PriceChange },
      { path: '/addoil', component: AddOil },
      { path: '/transaction/:cash_log_id?', component: Transaction, props: true },
      { path: '/pricestrategy', component: PriceStrategy },
      { path: '/chargestrategy', component: ChargePre },
      { path: '/oiladd', component: OilAdd },
      { path: '/terminalstate', component: TerminalState },
      { path: '/datastatistics', component: DataStatistics },
      { path: '/error', component: Error },
      { path: '/cash', component: Cash },
      { path: '/bill', component: Bill },
      // { path: '/coupontemplate', component: CouponTemplate },
      // { path: '/coupon', component: Coupon }
      { path: '/usercoupon', component: UserCoupon },
      { path: '/whitelist/:station_id?', component: WhiteList },
      { path: '/operationaccount', component: OperationAccount },
      { path: '/config', component: Config }
    ]
  }
]

const router = new VueRouter({
  routes
})

export default router
