<template>
  <div>
    <el-breadcrumb>
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>运营数据</el-breadcrumb-item>
      <el-breadcrumb-item>短信记录</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <el-row :gutter="20">
        <el-col :span="8">
          <el-input placeholder="请输入手机号进行搜索" v-model="queryParam.queryJson" clearable v-on:clear="getSmsList" size="small">
            <el-button slot="append" icon="el-icon-search" v-on:click="getSmsList" size="small"></el-button>
          </el-input>
        </el-col>
      </el-row>
      <el-table :data="chargeList" border stripe>
        <el-table-column type="index" label="#"></el-table-column>
        <el-table-column prop="receive_mobile" label="手机号" width="200"> </el-table-column>
        <el-table-column prop="content" label="短信内容"> </el-table-column>
        <el-table-column prop="result" label="发送结果" width="200">
            <template slot-scope="scope">
            <el-tag size="mini" v-if="scope.row.result === 1" type="primary" effect="plain">成功</el-tag>
            <el-tag size="mini" v-else type="danger" effect="plain">失败</el-tag>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination :hide-on-single-page="true" @size-change="handleSizeChange" @current-change="handleCurrentChange" v-bind:current-page="queryParam.pagenum" v-bind:page-sizes="this.pageOption" v-bind:page-size="queryParam.pagesize" layout="total, sizes, prev, pager, next, jumper" v-bind:total="total"> </el-pagination>
    </el-card>
  </div>
</template>
<script>
export default {
  data() {
    return {
      queryParam: {
        queryJson: '',
        pagenum: 1,
        pagesize: this.pageOption[0]
      },
      total: 0,
      chargeList: []
    }
  },
  created() {
    this.getSmsList()
  },
  methods: {
    getSmsList: async function() {
      const { data: res } = await this.$http.get('/Sms/List', { params: this.queryParam })
      if (res.status !== 1) {
        return this.$message.error('获取短信列表失败')
      }
      console.log(res.data)
      this.chargeList = res.data.list
      this.total = res.data.total
    },
    handleSizeChange: function(newSize) {
      this.queryParam.pagesize = newSize
      this.getSmsList()
    },
    handleCurrentChange: function(newPage) {
      this.queryParam.pagenum = newPage
      this.getSmsList()
    }
  }
}
</script>
