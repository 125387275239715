<template>
  <div class="app-container">
    <el-breadcrumb>
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>系统设置</el-breadcrumb-item>
      <el-breadcrumb-item>参数设置</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <el-form :model="queryParams" ref="queryForm" size="small" :inline="true" v-show="showSearch" label-width="68px">
        <el-form-item label="参数名称" prop="queryJson">
          <el-input v-model="queryParams.queryJson" placeholder="请输入参数名称" clearable @keyup.enter.native="handleQuery" />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
          <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
        </el-form-item>
      </el-form>
      <el-row :gutter="10" class="mb8">
        <el-col :span="1.5">
          <el-button type="primary" plain icon="el-icon-plus" size="mini" @click="handleAdd">新增</el-button>
        </el-col>
      </el-row>
      <el-table v-loading="loading" :data="configList">
        <el-table-column label="参数名称" align="center" prop="config_name" />
        <el-table-column label="参数键名" align="center" prop="config_key" />
        <el-table-column label="参数键值" align="center" prop="config_value" />
        <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
          <template slot-scope="scope">
            <el-button size="mini" type="text" icon="el-icon-edit" @click="handleUpdate(scope.row)">修改</el-button>
            <el-button size="mini" type="text" icon="el-icon-delete" @click="handleDelete(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination :hide-on-single-page="false" @size-change="handleSizeChange" @current-change="handleCurrentChange" v-bind:current-page="queryParams.pageNum" v-bind:page-sizes="this.pageOption" v-bind:page-size="queryParams.pageSize" layout="total, sizes, prev, pager, next, jumper" v-bind:total="total"> </el-pagination>

      <!-- 添加或修改参数配置对话框 -->
      <el-dialog :title="title" :visible.sync="open" width="500px" append-to-body>
        <el-form ref="form" :model="form" :rules="rules" label-width="80px">
          <el-form-item label="参数名称" prop="config_name">
            <el-input v-model="form.config_name" placeholder="请输入参数名称" />
          </el-form-item>
          <el-form-item label="参数键名" prop="config_key">
            <el-input v-model="form.config_key" placeholder="请输入参数键名" />
          </el-form-item>
          <el-form-item label="参数键值" prop="config_value">
            <el-input v-model="form.config_value" type="textarea" placeholder="请输入内容" />
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="submitForm">确 定</el-button>
          <el-button @click="cancel">取 消</el-button>
        </div>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
export default {
  name: 'Config',
  data() {
    return {
      // 遮罩层
      loading: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 参数配置表格数据
      configList: [],
      // 弹出层标题
      title: '',
      // 是否显示弹出层
      open: false,
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        queryJson: ''
      },
      // 表单参数
      form: {},
      // 表单校验
      rules: {
        config_name: [
          { required: true, message: '参数名称不能为空', trigger: 'blur' },
          { min: 1, max: 30, message: '长度在1至30个字符', trigger: 'blur' }
        ],
        config_key: [
          { required: true, message: '参数键名不能为空', trigger: 'blur' },
          { min: 1, max: 50, message: '长度在1至50个字符', trigger: 'blur' }
        ],
        config_value: [
          { required: true, message: '参数键值不能为空', trigger: 'blur' },
          { min: 1, max: 500, message: '长度在1至500个字符', trigger: 'blur' }
        ]
      }
    }
  },
  created() {
    this.getList()
  },
  methods: {
    /** 查询参数配置列表 */
    async getList() {
      this.loading = true
      const { data: res } = await this.$http.get('/TConfig/List', {
        params: this.queryParams
      })
      if (res.status !== 1) return this.$message.error(res.msg)
      this.configList = res.data.list
      this.total = res.data.total
      this.loading = false
    },
    handleSizeChange: function(val) {
      this.queryParams.pageSize = val
      this.getList()
    },
    handleCurrentChange: function(val) {
      this.queryParams.pageNum = val
      this.getList()
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset() {
      this.form = {
        config_id: null,
        config_name: null,
        configKey: null,
        config_value: null
      }
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.$refs.queryForm.resetFields()
      this.handleQuery()
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.reset()
      this.open = true
      this.title = '添加参数配置'
    },
    /** 修改按钮操作 */
    async handleUpdate(row) {
      this.reset()
      const { data: response } = await this.$http.get('/TConfig/One?id=' + row.config_id)
      this.form = response.data
      this.open = true
      this.title = '修改参数配置'
    },
    /** 提交按钮 */
    submitForm() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          if (this.form.config_id != null) {
            await this.$http.put('/TConfig/Edit?id=' + this.form.config_id, this.form)
            this.$message.success('修改成功')
            this.open = false
            this.getList()
          } else {
            await this.$http.post('/TConfig/Add', this.form)
            this.$message.success('新增成功')
            this.open = false
            this.getList()
          }
        }
      })
    },
    /** 删除按钮操作 */
    async handleDelete(row) {
      const confirmResult = await this.$confirm('是否确认删除参数配置名称为"' + row.config_name + '"的数据项？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(err => err)
      if (confirmResult !== 'confirm') {
        return this.$message('已取消删除')
      }
      const { data: res } = await this.$http.delete('/TConfig/Del?id=' + row.config_id)
      if (res.status !== 1) {
        return this.$message.error('删除失败:' + res.msg)
      }
      this.getList()
      this.$message.success('删除成功')
    }
  }
}
</script>
